import { LeadForm } from '../../content-blocks/CustomForm/LeadForm.js';

import './ContactFormPage.scss';

export const ContactFormPage = () => {
  return (
    <div className="form_holder">
      <LeadForm />
    </div>
  );
};
