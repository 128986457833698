import { ContractValiditySummary } from '../../SubscriptionLists/ContractValiditySummary.js';
import { HeaderSectionContent, SectionContent } from '../SectionContent.js';
import { SearchResultRow } from '../SearchResultRow.js';
import {
  corporatePhoneMsg,
  costCenterMsg,
  extensionNumberMsg,
  idNumberMsg,
  numberOfTheBillingAccountMsg,
  referenceMsg,
  simCardNumberMsg,
  t,
} from '../../../common/i18n/index.js';
import { generatePath } from 'react-router-dom';
import { getResultObjectWithHighlightedValues } from '../highlightFunctions.js';
import { isSubscriptionInSfdc } from '../../../common/utils/subscriptionUtils.js';
import { paths } from '../../../common/constants/pathVariables.js';
import type { ClassicSiteResultProps, SearchResultBaseProps } from '../SearchResultRow.js';
import type { SubscriptionHeader } from '../../../generated/api/subscriptionHeader.js';

export interface VoiceSubscriptionProps extends SearchResultBaseProps, ClassicSiteResultProps {
  subscription: SubscriptionHeader;
}

export const VoiceSubscription = ({
  subscription,
  classicSiteUrl,
  matchedFields,
  searchTerms,
  ...rest
}: VoiceSubscriptionProps) => {
  const {
    subscriptionName,
    subscriptionContactName,
    subscriptionPurposeOfUse,
    subscriptionUserFriendlyId,
    billingAccountDisplayId,
    simCardNumber,
    costCenter,
    subscriptionReference,
    corporateNumber,
    extensionNumber,
    subscriptionDisplayId,
  } = getResultObjectWithHighlightedValues(subscription, matchedFields, searchTerms);
  const displayClassicSiteLink = !isSubscriptionInSfdc(subscription);
  const classicSiteLinkTarget = `${classicSiteUrl}/muutos-ja-poisto/`;
  return (
    <SearchResultRow
      icon="sim"
      upperSection={
        <HeaderSectionContent
          left={subscriptionContactName || subscriptionPurposeOfUse || ''}
          right={subscriptionUserFriendlyId}
        />
      }
      lowerSection={
        <SectionContent
          left={subscriptionName || ''}
          right={<ContractValiditySummary subscriptionHeader={subscription} />}
        />
      }
      additionalHighlightableFields={[
        { title: t.VLQ3(simCardNumberMsg), value: simCardNumber, key: 'simCardNumber' },
        { title: t.WL8J(corporatePhoneMsg), value: corporateNumber, key: 'corporateNumber' },
        { title: t.V972(extensionNumberMsg), value: extensionNumber, key: 'extensionNumber' },
        { title: t.GQLW(numberOfTheBillingAccountMsg), value: billingAccountDisplayId, key: 'billingAccountDisplayId' },
        { title: t.QJUW(costCenterMsg), value: costCenter, key: 'costCenter' },
        { title: t.ZLAU(referenceMsg), value: subscriptionReference, key: 'subscriptionReference' },
        { title: t.YTH3(idNumberMsg), value: subscriptionDisplayId, key: 'subscriptionDisplayId' },
      ]}
      matchedFields={matchedFields}
      displayClassicSiteLink={displayClassicSiteLink}
      linkHref={
        displayClassicSiteLink
          ? classicSiteLinkTarget
          : `${generatePath(paths.PS_MOBILE_SUBSCRIPTION, { subscriptionId: subscription.subscriptionDisplayId })}?mdmId=${subscription.accountMasterId}`
      }
      {...rest}
    />
  );
};
