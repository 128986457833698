import { DEFAULT_MAX_ALLOWED_ITEM_QUANTITY_FOR_ORDER } from '../../common/utils/validationUtils.js';
import { SalesType, StockAvailabilityStatus } from '../../generated/api/models.js';
import { getOffer } from './deviceCheckoutUtils.js';
import { getTaxAmount } from '../../common/utils/taxUtils.js';
import { getTotalSums } from '../../common/utils/commercialProductUtils.js';
import { physicalProducts } from '../../common/utils/cartProductUtils.js';
import type { ConfiguredCommercialProduct } from '../../common/types/commercialProduct.js';
import type { OnlineModelCategory, Range } from '../../generated/api/models.js';
import type { OnlineModelsState } from '../../common/types/states.js';
import type { ShoppingCartItemForCheckout } from '../../common/types/checkout.js';

export const getMaxQuantityAllowedForCardPayment = (
  stockAvailabilityRange?: Range,
  stockAvailabilityStatus?: StockAvailabilityStatus
): number => {
  if (!stockAvailabilityRange || !stockAvailabilityRange.lower || !stockAvailabilityStatus) {
    return 0;
  }
  if (stockAvailabilityStatus === StockAvailabilityStatus.SHIPMENTS_ARRIVING) {
    // if range is 25 and above then allow upto default max amount of items which can be added to cart.
    return stockAvailabilityRange.upper || DEFAULT_MAX_ALLOWED_ITEM_QUANTITY_FOR_ORDER;
  } else if (stockAvailabilityStatus === StockAvailabilityStatus.NO_SHIPMENTS_ARRIVING) {
    return stockAvailabilityRange.lower;
  }
  return 0;
};

export const isPhysicalProduct = (shoppingCartItem: ShoppingCartItemForCheckout): boolean =>
  physicalProducts.includes(shoppingCartItem.category.toUpperCase() as OnlineModelCategory);

export const isCategoryValidForCardPayment = (cartItems: Array<ShoppingCartItemForCheckout>): boolean =>
  cartItems.every(isPhysicalProduct);

export const isStockValidForCardPayment = (
  cartItems: Array<ShoppingCartItemForCheckout>,
  onlineModels?: OnlineModelsState
): boolean =>
  cartItems.every(item => {
    const offer = getOffer(item.offerCode, onlineModels);
    return (
      item.quantity <=
      getMaxQuantityAllowedForCardPayment(offer?.stockAvailabilityRange, offer?.stockAvailabilityStatus)
    );
  });

export const isCardPaymentAllowed = (
  cartItems: Array<ShoppingCartItemForCheckout>,
  onlineModels?: OnlineModelsState
): boolean =>
  cartItems.every(cartItem => {
    const commercialProducts = getOffer(cartItem.offerCode, onlineModels)?.commercialProducts;
    const matchingCommercialProducts = commercialProducts?.filter(commercialProduct =>
      cartItem.commercialProductCodes.includes(commercialProduct.commercialProductCode)
    );
    return matchingCommercialProducts?.every(commercialProduct => commercialProduct.prepaidPaymentAllowed);
  });

export const getPriceMetaDataForCardPayment = (
  commercialProducts: ConfiguredCommercialProduct[],
  deliveryCharges?: number
): {
  isPriceTypeValidForCardPayment: boolean;
  totalAmountToPayByCard: number;
} => {
  const totalSums = getTotalSums(commercialProducts, undefined, SalesType.NEW_SALE);

  if (deliveryCharges) {
    totalSums.totalOneTimeCharge += deliveryCharges;
    totalSums.totalOneTimeChargeVat += getTaxAmount(deliveryCharges);
  }
  // card payment is allowed for when basket has only one time charges.
  return {
    isPriceTypeValidForCardPayment: totalSums.totalOneTimeCharge > 0 && !(totalSums.totalMonthlyRecurringCharge > 0),
    totalAmountToPayByCard: totalSums.totalOneTimeCharge + totalSums.totalOneTimeChargeVat,
  };
};
