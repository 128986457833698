import { TypeKeys, generateActionId } from './actionsUtils.js';
import { convertToCommonErrors } from '../../common/utils/errorUtils.js';
import type { Action } from 'redux';
import type { CrudAction, ErrorAction, ErrorActionCreator } from './actionsUtils.js';
import type { GenericError, PostLeadRequest } from '../../generated/api/models.js';

export interface AddLeadAction extends CrudAction<TypeKeys.ADD_LEAD> {
  lead: PostLeadRequest;
}

export interface AddLeadFulfilledAction extends Action {
  type: TypeKeys.ADD_LEAD_FULFILLED;
}

export type AddLeadFailedAction = ErrorAction<TypeKeys.ADD_LEAD_FAILED>;

export interface SetLeadSentStatusAction extends Action {
  type: TypeKeys.SET_LEAD_SENT_STATUS;
  leadSent: boolean | undefined;
}

export const addLead = (lead: PostLeadRequest): AddLeadAction => {
  return {
    id: generateActionId(),
    lead,
    type: TypeKeys.ADD_LEAD,
  };
};

export const addLeadFulfilled = (): AddLeadFulfilledAction => ({
  type: TypeKeys.ADD_LEAD_FULFILLED,
});

export const addLeadFailed: ErrorActionCreator<TypeKeys.ADD_LEAD_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[]
): AddLeadFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    type: TypeKeys.ADD_LEAD_FAILED,
  };
};

export type LeadsActionTypes = AddLeadAction | AddLeadFulfilledAction | AddLeadFailedAction | SetLeadSentStatusAction;
