import { ActionPhase } from '../common/storeUtils.js';
import { EMPLOYEE_SUBSCRIPTION_PAIR_PATH } from '../../components/Employee/Employee.js';
import { TypeKeys } from '../actions/index.js';
import {
  addToActionHistory,
  getPreviousActionState,
  isPreviousActionInProgress,
  updateActionStatePhase,
} from '../common/index.js';
import {
  buildHeaderOnlyItemsQuery,
  filterErrors,
  getItemsQueryForReports,
  mergeArrays,
  mergeArraysWithIdObject,
  processConflictedErrors,
  reduceCrudAction,
  reduceDisplayItemsLoadAction,
  reduceSubscriptionUsage,
  reduceSubscriptions,
  validateLoadActionFulfilledResponseCounts,
} from './reducerUtils.js';
import { convertToCommonErrors, getErrorsFromReview } from '../../common/utils/errorUtils.js';
import {
  getErrorsFromChangePasswordFailedResponse,
  getErrorsFromChangePasswordFormComponent,
} from '../../components/ChangePasswordForm/ChangePasswordForm.js';
import {
  getErrorsFromEmployeeSubscriptionPairingScene,
  getErrorsFromValidateEmployeeSubscriptionResponse,
} from '../../components/EmployeeSubscriptionPairingScene/EmployeeSubscriptionPairingScene.js';
import { getErrorsFromOneTimePasswordComponent } from '../../components/OneTimePasswordForm/OneTimePasswordForm.js';
import { getErrorsFromUpdateUserDetails } from '../../components/UserDetails/UserDetails.js';
import {
  loadEmployeeCustomerOrdersUseSearchService,
  loadSubscriptionActionsUseSearchService,
} from '../common/elasticsearchUtils.js';
import { sortArray } from '../../common/utils/arrayUtils.js';
import type { ActionsHistory, ItemsQuery } from '../common/store.js';
import type { AuthenticatedUserState } from '../../common/types/states.js';
import type {
  AuthenticationResult,
  ConflictedAuthenticationResult,
  Contract,
  CustomerOrder,
  CustomerOrderSearchResponse,
  SubscriptionAction,
  SubscriptionActionSearchResponse,
} from '../../generated/api/models.js';
import type { EmployeeSubscription } from '../../common/types/subscription.js';
import type { SelfServiceActionTypes } from '../actions/index.js';

export const subscriptionUsageType = {
  LOCAL_USAGE: 'localUsage',
  ROAMING_USAGE: 'roamingUsage',
  TEXT_MESSAGES: 'textMessages',
  VOICE_CALLS: 'voiceCalls',
};

const reduceAuthenticationResult = (
  state: AuthenticatedUserState & ActionsHistory,
  authenticationResult: AuthenticationResult
): AuthenticatedUserState & ActionsHistory => {
  return {
    ...state,
    // Logged-in user will always have PERSON type contact
    analyticsConsent: authenticationResult.contact?.person?.analyticsConsent,
    // Account is required in OpenAPI schema but is optional in generated TS code. Bug?
    businessId: authenticationResult.account?.businessId,
    chatToken: authenticationResult.token,
    companyName: authenticationResult.account?.accountName || '',
    contact: authenticationResult.contact,
    costCenter: authenticationResult.contact?.person?.costCenter,
    email: authenticationResult.contact?.person?.email,
    employeeNumber: authenticationResult.contact?.person?.employeeNumber,
    // for example ad-users do not have contact, thus firstName is mandatory..
    firstName: authenticationResult.contact?.person?.firstName || '',
    lastName: authenticationResult.contact?.person?.lastName,
    mdmId: authenticationResult.account?.accountMasterId,
    mobile: authenticationResult.contact?.person?.phoneNumber,
    preferredLanguage: authenticationResult!.contact?.person?.preferredLanguage || 'fi',
    selfServiceInOec: authenticationResult.account?.selfServiceInOec,
    deviceStoreInOec: authenticationResult.account?.deviceStoreInOec,
    userRightsInSalesforce: authenticationResult.account?.userRightsInSalesforce,
    ...authenticationResult.user,
    isFetching: false,
    expiringEppSubscriptions: authenticationResult?.expiringEppSubscriptions,
    secondaryAccounts: authenticationResult.secondaryAccounts,
    enabledFeatureFlags: authenticationResult.enabledFeatureFlags,
    customerLevelPriceOrDiscount: authenticationResult.customerLevelPriceOrDiscount,
    segmentPricingGroup: authenticationResult.segmentPricingGroup,
  };
};

export function authenticatedUserReducer(
  state: (AuthenticatedUserState & ActionsHistory) | undefined,
  action: SelfServiceActionTypes
): (AuthenticatedUserState & ActionsHistory) | null {
  if (typeof state === 'undefined') {
    return null;
  }
  switch (action.type) {
    case TypeKeys.SELECT_USERNAME: {
      return {
        ...state,
        ...reduceCrudAction(action, state),
      };
    }

    case TypeKeys.SELECT_USERNAME_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.SELECT_USERNAME, state!, ActionPhase.IN_PROGRESS, ActionPhase.SUCCESS),
      };
    }

    case TypeKeys.ADD_SUPPORT_CASE_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.SELECT_USERNAME, state!, ActionPhase.IN_PROGRESS, ActionPhase.FAILED),
      };
    }

    case TypeKeys.LOG_OUT:
      return {
        ...state,
        ...reduceCrudAction(action, state),
      };

    case TypeKeys.AUTH_VALIDATE:
      return {
        isFetching: true,
        ...state,
      };

    case TypeKeys.LOGOUT_FULFILLED: {
      return null;
    }

    case TypeKeys.AUTH_VALIDATE_FAILED: {
      if (
        action?.errors &&
        action?.errors[0].source &&
        (action.errors[0].source.error as unknown as ConflictedAuthenticationResult)
      ) {
        // this will be handled in dialogReducer...
        return {
          ...state,
          isFetching: false,
        };
      } else {
        return null;
      }
    }

    case TypeKeys.LOGOUT_FAILED: {
      return null;
    }

    case TypeKeys.CHANGE_PASSWORD: {
      const errors = getErrorsFromChangePasswordFormComponent(action.payload, action.validationErrors);
      if (errors) {
        return {
          ...state,
          errors,
        };
      } else {
        return {
          ...state,
          ...reduceCrudAction(action, state),
          errors: undefined,
          saving: true,
        };
      }
    }

    case TypeKeys.CHANGE_PASSWORD_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.CHANGE_PASSWORD, state, ActionPhase.IN_PROGRESS, ActionPhase.SUCCESS),
        errors: undefined,
        saving: false,
      };
    }

    case TypeKeys.CHANGE_PASSWORD_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.CHANGE_PASSWORD, state!, ActionPhase.IN_PROGRESS, ActionPhase.FAILED),
        errors: getErrorsFromChangePasswordFailedResponse(action.errors),
        saving: false,
      };
    }

    case TypeKeys.GET_USER: {
      return {
        ...state,
        ...reduceCrudAction(action, state),
      };
    }

    case TypeKeys.GET_USER_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.GET_USER, state!, ActionPhase.IN_PROGRESS, ActionPhase.SUCCESS),
        firstName: state.firstName || action.user.firstName || '',
        identityProviderEmail: action.user.email || null,
        identityProviderPhone: action.user.mobile || null,
        lastName: state.lastName || action.user.lastName,
      };
    }

    case TypeKeys.GET_USER_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.GET_USER, state!, ActionPhase.IN_PROGRESS, ActionPhase.FAILED),
        errors: action.errors,
      };
    }

    case TypeKeys.UPDATE_USER_DETAILS: {
      const errors = getErrorsFromUpdateUserDetails(action.payload, action.customerType, action.validationErrors);
      if (
        (state.analyticsConsent !== action.payload.analyticsConsent ||
          state.preferredLanguage !== action.payload.preferredLanguage ||
          state.email !== action.payload.email ||
          state.firstName !== action.payload.firstName ||
          state.lastName !== action.payload.lastName ||
          state.mobile !== action.payload.phoneNumber ||
          state.costCenter !== action.payload.costCenter ||
          state.employeeNumber !== action.payload.employeeNumber) &&
        !errors
      ) {
        return {
          ...state,
          ...reduceCrudAction(action, state),
          errors: undefined,
          saving: true,
        };
      } else {
        return {
          ...state,
          errors: errors,
        };
      }
    }

    case TypeKeys.POST_EPP_REDEEM_TERMINATE_FULFILLED: {
      return {
        ...state,
        expiringEppSubscriptions: state.expiringEppSubscriptions?.filter(
          subscriptionId => subscriptionId !== action.response?.subscriptionId
        ),
      };
    }

    case TypeKeys.UPDATE_USER_DETAILS_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.UPDATE_USER_DETAILS,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        analyticsConsent: action.updatedUserDetails.analyticsConsent
          ? action.updatedUserDetails.analyticsConsent
          : state.analyticsConsent,
        emailVerified:
          action.isUserOnboarded ||
          (state.emailVerified && (state.email === action.updatedUserDetails.email || !!state.businessId)),
        email: action.updatedUserDetails.email,
        employeeNumber: action.updatedUserDetails.employeeNumber,
        firstName: action.updatedUserDetails.firstName,
        costCenter: action.updatedUserDetails.costCenter,
        lastName: action.updatedUserDetails.lastName,
        mobile: action.updatedUserDetails.phoneNumber,
        preferredLanguage: action.updatedUserDetails.preferredLanguage || state.preferredLanguage,
        saving: false,
        errors: undefined,
      };
    }

    case TypeKeys.UPDATE_USER_DETAILS_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.UPDATE_USER_DETAILS,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: filterErrors(processConflictedErrors(action.errors)),
        saving: false,
      };
    }

    // Employee_Subscription_Pairing_Reducers

    case TypeKeys.PROCESS_EMPLOYEE_SUBSCRIPTION_PAIRING: {
      const errors = getErrorsFromEmployeeSubscriptionPairingScene(action.payload, action.validationErrors);
      if (errors) {
        return {
          ...state,
          errors: errors,
        };
      } else {
        return {
          ...state,
          ...reduceCrudAction(action, state),
          errors: undefined,
        };
      }
    }

    case TypeKeys.VALIDATE_EMPLOYEE_SUBSCRIPTION_PAIRING: {
      const errors = getErrorsFromOneTimePasswordComponent(action.payload, action.validationErrors);
      if (errors) {
        return {
          ...state,
          errors: errors,
        };
      } else {
        return {
          ...state,
          ...reduceCrudAction(action, state),
          errors: undefined,
          saving: true,
        };
      }
    }
    case TypeKeys.PROCESS_EMPLOYEE_SUBSCRIPTION_PAIRING_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.PROCESS_EMPLOYEE_SUBSCRIPTION_PAIRING,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
      };
    }

    case TypeKeys.PROCESS_EMPLOYEE_SUBSCRIPTION_PAIRING_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.PROCESS_EMPLOYEE_SUBSCRIPTION_PAIRING,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
      };
    }

    case TypeKeys.VALIDATE_EMPLOYEE_SUBSCRIPTION_PAIRING_FAILED: {
      return {
        ...state!,
        actions: updateActionStatePhase(
          TypeKeys.VALIDATE_EMPLOYEE_SUBSCRIPTION_PAIRING,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: filterErrors(action.errors),
        saving: false,
      };
    }

    case TypeKeys.VALIDATE_EMPLOYEE_SUBSCRIPTION_PAIRING_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.VALIDATE_EMPLOYEE_SUBSCRIPTION_PAIRING,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        errors: getErrorsFromValidateEmployeeSubscriptionResponse(action.response),
        saving: false,
        subscriptions: undefined,
      };
    }

    case TypeKeys.CANCEL_USER_DETAILS_EDIT: {
      return {
        ...state,
        errors: undefined,
        saving: false,
      };
    }

    case TypeKeys.GET_EMPLOYEE_SUBSCRIPTIONS:
      if (isPreviousActionInProgress(action.type, state, 'subscriptionType', action.subscriptionType)) {
        return state;
      } else {
        return {
          ...state,
          actions: addToActionHistory(state, {
            phase: ActionPhase.IN_PROGRESS,
            value: action,
          }),
        };
      }

    case TypeKeys.GET_EMPLOYEE_SUBSCRIPTIONS_FULLFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.GET_EMPLOYEE_SUBSCRIPTIONS,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        subscriptions: reduceSubscriptions(action.response, action.subscriptionType, state.subscriptions),
      };
    }

    case TypeKeys.GET_MOBILEID_CONTRACTS_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.GET_MOBILEID_CONTRACTS,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        contracts: {
          mobileIdContracts: mergeArrays<Contract>(
            'contractNumber',
            'lastModified',
            state.contracts?.mobileIdContracts,
            action.mobileidContracts
          ),
          loadingContracts: false,
        },
      };
    }

    case TypeKeys.GET_EMPLOYEE_SUBSCRIPTIONS_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.GET_EMPLOYEE_SUBSCRIPTIONS,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
      };
    }

    case TypeKeys.CHECK_VALID_EMPLOYEE_SUBSCRIPTION: {
      if (
        state.subscriptions &&
        action.subscriptionToValidate !== undefined &&
        action.subscriptionToValidate !== EMPLOYEE_SUBSCRIPTION_PAIR_PATH &&
        // @ts-ignore
        !state.subscriptions[action.subscriptionType].find(
          (subscription: EmployeeSubscription) => subscription.subscriptionId === action.subscriptionToValidate
        )
      ) {
        return {
          ...state,
          errors: convertToCommonErrors(`subscription ${action.subscriptionToValidate} could not be found`, 404),
        };
      }
      return state;
    }

    case TypeKeys.GET_SUBSCRIPTION_LOCAL_USAGE:
      return {
        ...state,
        ...reduceCrudAction(action, state),
      };

    case TypeKeys.GET_SUBSCRIPTION_LOCAL_USAGE_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.GET_SUBSCRIPTION_LOCAL_USAGE,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        subscriptions: reduceSubscriptionUsage(
          subscriptionUsageType.LOCAL_USAGE,
          action.subscriptionId,
          action.response,
          state.subscriptions
        ),
      };
    }

    case TypeKeys.GET_SUBSCRIPTION_LOCAL_USAGE_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.GET_SUBSCRIPTION_LOCAL_USAGE,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
      };
    }

    case TypeKeys.GET_SUBSCRIPTION_ROAMING_USAGE:
      return {
        ...state,
        ...reduceCrudAction(action, state),
      };

    case TypeKeys.GET_SUBSCRIPTION_ROAMING_USAGE_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.GET_SUBSCRIPTION_ROAMING_USAGE,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        subscriptions: reduceSubscriptionUsage(
          subscriptionUsageType.ROAMING_USAGE,
          action.subscriptionId,
          action.response,
          state.subscriptions
        ),
      };
    }

    case TypeKeys.GET_SUBSCRIPTION_ROAMING_USAGE_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.GET_SUBSCRIPTION_ROAMING_USAGE,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
      };
    }

    case TypeKeys.GET_SUBSCRIPTION_VOICE_CALLS:
      return {
        ...state,
        ...reduceCrudAction(action, state),
      };

    case TypeKeys.GET_SUBSCRIPTION_VOICE_CALLS_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.GET_SUBSCRIPTION_VOICE_CALLS,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        subscriptions: reduceSubscriptionUsage(
          subscriptionUsageType.VOICE_CALLS,
          action.subscriptionId,
          action.response,
          state.subscriptions
        ),
      };
    }

    case TypeKeys.GET_SUBSCRIPTION_VOICE_CALLS_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.GET_SUBSCRIPTION_VOICE_CALLS,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
      };
    }

    case TypeKeys.GET_SUBSCRIPTION_TEXT_MESSAGES:
      return {
        ...state,
        ...reduceCrudAction(action, state),
      };

    case TypeKeys.GET_SUBSCRIPTION_TEXT_MESSAGES_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.GET_SUBSCRIPTION_TEXT_MESSAGES,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        subscriptions: reduceSubscriptionUsage(
          subscriptionUsageType.TEXT_MESSAGES,
          action.subscriptionId,
          action.response,
          state.subscriptions
        ),
      };
    }

    case TypeKeys.GET_SUBSCRIPTION_TEXT_MESSAGES_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.GET_SUBSCRIPTION_TEXT_MESSAGES,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
      };
    }

    case TypeKeys.POLL_FOR_MYSELF_CHANGES: {
      return {
        ...state,
        ...reduceCrudAction(action, state),
      };
    }
    case TypeKeys.POLL_FOR_MYSELF_CHANGES_FAILED: {
      if (state === null) {
        return state;
      }
      const latestChangeRef = action.params?.retainLatestChangeRef ? state.latestChangeRef : undefined;
      return {
        ...state,
        latestChangeRef,
        actions: updateActionStatePhase(
          TypeKeys.POLL_FOR_MYSELF_CHANGES,
          state,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
      };
    }
    case TypeKeys.STOP_POLL_FOR_MYSELF_CHANGES: {
      if (state === null) {
        return state;
      }
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.POLL_FOR_MYSELF_CHANGES,
          state,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
      };
    }
    case TypeKeys.POLL_FOR_MYSELF_CHANGES_FULFILLED: {
      // There might be an authenticationResult in the response
      const defaultState = action.authenticationResult
        ? reduceAuthenticationResult(state, action.authenticationResult)
        : state;
      return {
        ...defaultState,
        latestChangeRef: action.latestChangeRef,
        actions: updateActionStatePhase(
          TypeKeys.POLL_FOR_MYSELF_CHANGES,
          state,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
      };
    }

    // We verify in the validateEpic that authenticationResult exists also for it
    case TypeKeys.PROCESS_ELISAID_LOGIN_FULFILLED:
    case TypeKeys.AUTH_VALIDATE_FULFILLED:
    case TypeKeys.LOG_IN_FULFILLED: {
      return reduceAuthenticationResult(state, action.authenticationResult);
    }

    case TypeKeys.MFA_REQUIRED: {
      return {
        ...state,
        isFetching: false,
      };
    }

    case TypeKeys.INITIATE_EMPLOYEE_ONBOARDING: {
      if (action.validationErrors?.length) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        ...reduceCrudAction(action, state),
      };
    }

    case TypeKeys.INITIATE_EMPLOYEE_ONBOARDING_RESEND_FAILED:
    case TypeKeys.INITIATE_EMPLOYEE_ONBOARDING_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.INITIATE_EMPLOYEE_ONBOARDING,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
      };
    }

    case TypeKeys.INITIATE_EMPLOYEE_ONBOARDING_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.INITIATE_EMPLOYEE_ONBOARDING,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
      };
    }

    case TypeKeys.POST_EPP_MAINTENANCE: {
      return {
        ...state,
        saving: true,
      };
    }

    case TypeKeys.POST_EPP_MAINTENANCE_FAILED: {
      return {
        ...state,
        errors: action.errors,
      };
    }

    case TypeKeys.REVIEW: {
      const errors = getErrorsFromReview(
        action.request,
        action.reviewType,
        action.approve,
        action.rejectReason,
        action.validationErrors
      );
      if (errors) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        ...reduceCrudAction(action, state),
      };
    }

    case TypeKeys.REVIEW_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.REVIEW, state!, ActionPhase.IN_PROGRESS, ActionPhase.SUCCESS),
      };
    }

    case TypeKeys.REVIEW_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.REVIEW, state!, ActionPhase.IN_PROGRESS, ActionPhase.FAILED),
      };
    }

    case TypeKeys.VERIFY_EMAIL_FULFILLED: {
      return {
        ...state,
        emailVerified: true,
      };
    }

    case TypeKeys.RESEND_EMAIL_VERIFICATION_LINK: {
      return {
        ...state,
        ...reduceCrudAction(action, state),
      };
    }

    case TypeKeys.RESEND_EMAIL_VERIFICATION_LINK_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.RESEND_EMAIL_VERIFICATION_LINK,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
      };
    }

    case TypeKeys.RESEND_EMAIL_VERIFICATION_LINK_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.RESEND_EMAIL_VERIFICATION_LINK,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
      };
    }

    case TypeKeys.SET_ACTIVE_ACCOUNT: {
      return {
        ...state,
        activeAccountMasterId: action.activeAccountMasterId,
      };
    }

    case TypeKeys.SWITCH_ACCOUNT: {
      return {
        ...state,
        ...reduceCrudAction(action, state),
      };
    }

    case TypeKeys.SWITCH_ACCOUNT_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.SWITCH_ACCOUNT, state!, ActionPhase.IN_PROGRESS, ActionPhase.SUCCESS),
        subscriptions: undefined,
      };
    }

    case TypeKeys.SWITCH_ACCOUNT_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.SWITCH_ACCOUNT, state!, ActionPhase.IN_PROGRESS, ActionPhase.FAILED),
      };
    }

    case TypeKeys.IDENTIFY_STRONGLY: {
      return {
        ...state,
        ...reduceCrudAction(action, state),
      };
    }

    case TypeKeys.IDENTIFY_STRONGLY_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.IDENTIFY_STRONGLY,
          state,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        isStronglyAuthenticated: true,
      };
    }

    case TypeKeys.IDENTIFY_STRONGLY_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(TypeKeys.IDENTIFY_STRONGLY, state, ActionPhase.IN_PROGRESS, ActionPhase.FAILED),
      };
    }

    case TypeKeys.RESET_ERRORS: {
      return {
        ...state,
        errors: undefined,
      };
    }

    case TypeKeys.LOAD_EMPLOYEE_SUBSCRIPTION_ACTIONS: {
      if (loadSubscriptionActionsUseSearchService(action)) {
        const query: ItemsQuery | undefined = action.reporting
          ? getItemsQueryForReports()
          : buildHeaderOnlyItemsQuery(
              action,
              state.employeeSubscriptionActions,
              state.employeeSubscriptionActions?.total,
              state.employeeSubscriptionActions?.search,
              state.employeeSubscriptionActions?.status
            );

        return {
          ...state,
          actions: query
            ? addToActionHistory(state, {
                phase: ActionPhase.IN_PROGRESS,
                query,
                value: action,
              })
            : state?.actions,
          employeeSubscriptionActions: {
            ...state.employeeSubscriptionActions,
            filter: action.filter,
            search: query?.search,
            sort:
              query?.sort && query?.order
                ? {
                    columnId: query.sort,
                    order: query.order,
                  }
                : state.employeeSubscriptionActions?.sort,
            status: query?.status,
          },
        };
      }
      const getAllItems = !!action.filter || !!action.sort || !!action.search || action.reporting || action.forceLoad;
      const reducedDisplayItem = reduceDisplayItemsLoadAction<
        TypeKeys.LOAD_EMPLOYEE_SUBSCRIPTION_ACTIONS,
        SubscriptionAction
      >(
        action,
        state.employeeSubscriptionActions,
        'subscriptionActionDisplayId',
        getAllItems,
        action.reporting && action.forceLoad ? 0 : undefined,
        action.forceLoad
      );
      return {
        ...state,
        actions: reducedDisplayItem.actions,
        employeeSubscriptionActions: {
          items: action.forceLoad ? undefined : state?.employeeSubscriptionActions?.items,
          total: action.forceLoad ? undefined : state?.employeeSubscriptionActions?.total,
          ...reducedDisplayItem,
        },
      };
    }

    case TypeKeys.LOAD_EMPLOYEE_SUBSCRIPTION_ACTIONS_FULFILLED: {
      const actionStateLoadEmployeeSubs = getPreviousActionState(
        TypeKeys.LOAD_EMPLOYEE_SUBSCRIPTION_ACTIONS,
        state,
        ActionPhase.IN_PROGRESS
      );
      const getSearchResultsForSearchService =
        action.useSearchService && action.query.offset > 0
          ? mergeArraysWithIdObject<SubscriptionActionSearchResponse>(
              'result',
              'subscriptionActionDisplayId',
              'created',
              state.employeeSubscriptionActions!.searchResults,
              action.searchResults
            )
          : action.searchResults;

      return {
        ...state,
        employeeSubscriptionActions: {
          ...state.employeeSubscriptionActions,
          items: sortArray(
            mergeArrays<SubscriptionAction>(
              'subscriptionActionDisplayId',
              'lastModified',
              state.employeeSubscriptionActions?.items,
              action.subscriptionActionResponse
            ),
            state.employeeSubscriptionActions?.sort?.columnId || 'created',
            state.employeeSubscriptionActions?.sort?.order || 'desc'
          ),
          total: action.total,
          actions: updateActionStatePhase(
            TypeKeys.LOAD_EMPLOYEE_SUBSCRIPTION_ACTIONS,
            state!,
            ActionPhase.IN_PROGRESS,
            ActionPhase.SUCCESS
          ),
          errors: action.useSearchService
            ? validateLoadActionFulfilledResponseCounts(
                actionStateLoadEmployeeSubs!.query!,
                action.total,
                action.searchResults?.map(item => item.result),
                state!.errors
              )
            : validateLoadActionFulfilledResponseCounts(
                actionStateLoadEmployeeSubs!.query!,
                action.total,
                action.subscriptionActionResponse,
                state!.errors
              ),
          searchResults: action.useSearchService
            ? getSearchResultsForSearchService
            : state.employeeSubscriptionActions!.searchResults,
        },
      };
    }
    case TypeKeys.LOAD_EMPLOYEE_SUBSCRIPTION_ACTIONS_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.LOAD_EMPLOYEE_SUBSCRIPTION_ACTIONS_FAILED,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
      };
    }

    case TypeKeys.LOAD_EMPLOYEE_CUSTOMER_ORDERS: {
      if (loadEmployeeCustomerOrdersUseSearchService(action)) {
        const query: ItemsQuery | undefined = action.reporting
          ? getItemsQueryForReports()
          : buildHeaderOnlyItemsQuery(
              action,
              state.employeeCustomerOrders,
              state.employeeCustomerOrders?.total,
              state.employeeCustomerOrders?.search,
              state.employeeCustomerOrders?.status
            );

        return {
          ...state,
          actions: query
            ? addToActionHistory(state, {
                phase: ActionPhase.IN_PROGRESS,
                query,
                value: action,
              })
            : state?.actions,
          employeeCustomerOrders: {
            ...state.employeeCustomerOrders,
            filter: action.filter,
            search: query?.search,
            sort:
              query?.sort && query?.order
                ? {
                    columnId: query.sort,
                    order: query.order,
                  }
                : state.employeeCustomerOrders?.sort,
            status: query?.status,
          },
        };
      }

      const getAllItems = !!action.filter || !!action.sort || !!action.search || action.reporting || action.forceLoad;
      const reducedDisplayItem = reduceDisplayItemsLoadAction<TypeKeys.LOAD_EMPLOYEE_CUSTOMER_ORDERS, CustomerOrder>(
        action,
        state.employeeCustomerOrders,
        'customerOrderDisplayId',
        getAllItems,
        action.reporting && action.forceLoad ? 0 : undefined,
        action.forceLoad
      );

      return {
        ...state,
        actions: reducedDisplayItem.actions,
        employeeCustomerOrders: {
          items: action.forceLoad ? undefined : state?.employeeCustomerOrders?.items,
          total: action.forceLoad ? undefined : state?.employeeCustomerOrders?.total,
          ...reducedDisplayItem,
        },
      };
    }

    case TypeKeys.LOAD_EMPLOYEE_CUSTOMER_ORDERS_FULFILLED: {
      const actionStateLoadEmployeeOrders = getPreviousActionState(
        TypeKeys.LOAD_EMPLOYEE_CUSTOMER_ORDERS,
        state!,
        ActionPhase.IN_PROGRESS
      );

      const getSearchResultsForSearchService = action.mergeHeaders
        ? mergeArraysWithIdObject<CustomerOrderSearchResponse>(
            'result',
            'customerOrderDisplayId',
            'lastModified',
            state.employeeCustomerOrders!.searchResults,
            action.searchResults
          )
        : action.searchResults;

      return {
        ...state,
        employeeCustomerOrders: {
          ...state.employeeCustomerOrders,
          items: sortArray(
            mergeArrays<CustomerOrder>(
              'customerOrderId',
              'lastModified',
              state.employeeCustomerOrders?.items,
              action.customerOrders
            ),
            state.employeeCustomerOrders?.sort?.columnId || 'created',
            state.employeeCustomerOrders?.sort?.order || 'desc'
          ),
          total: action.total,
          actions: updateActionStatePhase(
            TypeKeys.LOAD_EMPLOYEE_CUSTOMER_ORDERS,
            state!,
            ActionPhase.IN_PROGRESS,
            ActionPhase.SUCCESS
          ),
          searchResults: action.useSearchService
            ? getSearchResultsForSearchService
            : state.employeeCustomerOrders!.searchResults,
          errors: action.useSearchService
            ? validateLoadActionFulfilledResponseCounts(
                actionStateLoadEmployeeOrders!.query!,
                action.total,
                action.searchResults?.map(item => item.result),
                state?.errors
              )
            : validateLoadActionFulfilledResponseCounts(
                actionStateLoadEmployeeOrders!.query!,
                action.total,
                action.customerOrders,
                state?.errors
              ),
        },
      };
    }

    case TypeKeys.LOAD_EMPLOYEE_CUSTOMER_ORDERS_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.LOAD_EMPLOYEE_CUSTOMER_ORDERS,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
      };
    }

    default:
      return state;
  }
}
