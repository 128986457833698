import { OpenFormBar } from '../OpenFormComponents/OpenFormBar.js';
import { OpenFormListTable } from './OpenFormListTable.js';
import { clearFormsMsg, t } from '../../../common/i18n/index.js';
import { useCallback } from 'react';
import { useOpenFormAsync, useOpenFormState } from '../OpenFormProvider.js';
import { useOpenFormList } from '../OpenFormHooks/useOpenFormList.js';
import { useParams } from 'react-router-dom';
import type { ListId } from '../../../common/constants/pathInterfaces.js';

export const OpenFormListLayout = ({ sizes = [30, 60, 90] }: { sizes?: number[] }) => {
  const { disabled, index } = useOpenFormState();
  const async = useOpenFormAsync();
  const list = useOpenFormList(useParams<ListId>() as ListId);

  const clear = useCallback(() => {
    async.cache.clear();
    async.storage.clear();
    async.actions.list({ index: undefined });
  }, [async]);

  return (
    <OpenFormBar clear={async.storage.length ? clear : undefined} clearText={t.KZJT(clearFormsMsg)}>
      {!list ? null : <OpenFormListTable list={list} index={index} disabled={disabled} sizes={sizes} />}
    </OpenFormBar>
  );
};
