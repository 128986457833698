import { TypeKeys } from './actionsUtils.js';

export interface StartNotificationAction {
  type: TypeKeys.START_NOTIFICATION;
  notificationMsg: string;
  notificationType?: 'default' | 'info' | 'ok' | 'success' | 'warning' | 'danger' | 'error';
}

export const startNotification = (
  notificationMsg: string,
  notificationType?: 'default' | 'info' | 'ok' | 'success' | 'warning' | 'danger' | 'error'
): StartNotificationAction => ({
  type: TypeKeys.START_NOTIFICATION,
  notificationMsg,
  notificationType,
});

export interface CloseNotificationAction {
  type: TypeKeys.CLOSE_NOTIFICATION;
  notificationKey: string;
}

export const closeNotification = (notificationKey: string): CloseNotificationAction => ({
  type: TypeKeys.CLOSE_NOTIFICATION,
  notificationKey,
});

export type NotificationsActionTypes = StartNotificationAction | CloseNotificationAction;
