import { TypeKeys, generateActionId } from './actionsUtils.js';
import { convertToCommonErrorsWithMessage } from '../../common/utils/errorUtils.js';
import type { Action } from 'redux';
import type { CrudAction, ErrorAction, ErrorActionCreator } from './actionsUtils.js';
import type { GenericError, PunchoutOrder } from '../../generated/api/models.js';
import type { PunchoutOrderResponse } from '../../common/utils/punchoutUtils.js';

export interface SubmitPunchoutOrderAction extends CrudAction<TypeKeys.SUBMIT_PUNCHOUT_ORDER> {
  order: Omit<PunchoutOrder, 'punchoutRequestId'>;
}

export const submitPunchoutOrder = (order: Omit<PunchoutOrder, 'punchoutRequestId'>): SubmitPunchoutOrderAction => ({
  id: generateActionId(),
  order,
  type: TypeKeys.SUBMIT_PUNCHOUT_ORDER,
});

export interface SubmitPunchoutOrderFulfilledAction extends Action {
  response: PunchoutOrderResponse;
  type: TypeKeys.SUBMIT_PUNCHOUT_ORDER_FULFILLED;
}

export const submitPunchoutOrderFulfilled = (response: PunchoutOrderResponse): SubmitPunchoutOrderFulfilledAction => {
  return {
    response: response as PunchoutOrderResponse,
    type: TypeKeys.SUBMIT_PUNCHOUT_ORDER_FULFILLED,
  };
};

export type SubmitPunchoutOrderFailedAction = ErrorAction<TypeKeys.SUBMIT_PUNCHOUT_ORDER_FAILED>;

export const submitPunchoutOrderFailed: ErrorActionCreator<TypeKeys.SUBMIT_PUNCHOUT_ORDER_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): SubmitPunchoutOrderFailedAction => {
  const commonErrors = convertToCommonErrorsWithMessage(message, status, errors);
  return {
    errors: commonErrors,
    params,
    type: TypeKeys.SUBMIT_PUNCHOUT_ORDER_FAILED,
  };
};

export type PunchoutActionTypes =
  | SubmitPunchoutOrderAction
  | SubmitPunchoutOrderFulfilledAction
  | SubmitPunchoutOrderFailedAction;
