import { AddressSearch } from '../AddressSearch/AddressSearch.js';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { OnlineModelCategory } from '../../generated/api/models.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { getCommercialAvailability } from '../../selfservice/actions/resourcesActions.js';
import { paths } from '../../common/constants/pathVariables.js';
import { t } from '../../common/i18n/index.js';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import type { AddressSearchResult } from '../AddressSearch/AddressSearch.js';
import type { CommercialAvailabilityResponse } from '../../generated/api/models.js';

import './FixedBroadbandAvailability.scss';

export interface FixedBroadbandAvailabilityProps {
  addressSearchResult?: AddressSearchResult;
  selectedAddressId?: string;
  commercialAvailabilityResult?: CommercialAvailabilityResponse;
}

export const FixedBroadbandAvailability = (props: FixedBroadbandAvailabilityProps) => {
  const { addressSearchResult, selectedAddressId, commercialAvailabilityResult } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const [doNavigate, setDoNavigate] = useState(false);

  const onGetCommercialAvailability = (address: string, postalCode: string) => {
    dispatch(getCommercialAvailability({ address, postalCode, subscriptionType: OnlineModelCategory.FIXED_BROADBAND }));
  };

  useEffect(() => {
    if (!!commercialAvailabilityResult && doNavigate) {
      setDoNavigate(false);
      navigate(paths.ANONYMOUS_FIXED_BROADBAND_ORDER_OFFERS + search, {
        state: {
          searchAddress: addressSearchResult?.match?.addressText,
          postalCode: addressSearchResult?.postalCode,
          previousUrl: location.pathname,
        },
      });
    }
  }, [commercialAvailabilityResult, addressSearchResult, navigate, doNavigate, search]);

  return (
    <div className="of-fixed-broadband-availability">
      <div className={dsClass.GRID_ROW}>
        <div className={`of-fixed-broadband-availability__header ${dsClass.PADDING_3}`}>
          <h1 className={dsClass.H1}>{t.LSWI('Get fast internet for remote work')}</h1>
          <p className={`${dsClass.TEXT_LEAD} ${dsClass.MARGIN_VERTICAL_6}`}>
            <Link to="/netti-pieneen-toimistoon">Elisa Netti Lite</Link>{' '}
            {t.TRS1(
              'is an easy and functional broadband for a home office or a small workplace. Its high-quality internet connection makes your everyday work efficient and smooth. Elisa Netti Lite includes a router that can be used to connect wireless network to your computer, phone, and other smart home devices. You can share the network with several users and with the mobile version you can work remotely wherever you want.'
            )}
          </p>
        </div>

        <div className={`of-fixed-broadband-availability__address-search ${dsClass.PADDING_3} ${dsClass.GRID_ROW}`}>
          <div
            className={`${dsClass.PADDING_6} ${dsClass.BACKGROUND_COLOR_NEUTRAL_200} ${dsClass.GRID_COL_S_6} ${dsClass.GRID_COL_L_6}`}
          >
            <AddressSearch
              addressSearchResult={addressSearchResult}
              initialStreetAddressValue={selectedAddressId ? addressSearchResult?.match?.addressText : undefined}
              initialPostalCodeValue={selectedAddressId ? addressSearchResult?.postalCode : undefined}
              onSubmit={(address: string, postalCode: string) => {
                onGetCommercialAvailability(address, postalCode);
                setDoNavigate(true);
              }}
            />
          </div>
          <div
            className={`of-fixed-broadband-availability__address-search__image-container ${dsClass.GRID_COL_S_6} ${dsClass.GRID_COL_L_6}`}
          >
            <div className={`of-fixed-broadband-availability__address-search--house ${dsClass.MARGIN_VERTICAL_4}`} />
          </div>
        </div>
      </div>

      <div
        className={`of-fixed-broadband-availability__contact ${dsClass.GRID_ROW} ${dsClass.MARGIN_VERTICAL_6} ${dsClass.PADDING_3}`}
      >
        <h2 className={dsClass.H2}>
          {t.C0L4('If you need any additional information about Netti Lite, you can contact our sales:')}
        </h2>
        <div className={dsClass.GRID_ROW}>
          <div
            className={`of-fixed-broadband-availability__column ${dsClass.PADDING_BOTTOM_4} ${dsClass.GRID_COL_S_6} ${dsClass.GRID_COL_XS_4} ${dsClass.GRID_COL_L_6}`}
          >
            <div className="of-fixed-broadband-availability__icon of-fixed-broadband-availability__icon--shopping-bag" />
            <div className="of-fixed-broadband-availability__content">
              <h3 className={dsClass.H3}>{t.JXQZ('Sales')}</h3>
              <div>
                <a href="tel:+35880004411">0800 04411</a>
                <p className={`${dsClass.MARGIN_VERTICAL_0} ${dsClass.TEXT_S} ${dsClass.COLOR_NEUTRAL_600}`}>
                  {t.WIPE('Mon-Fri 8am - 4pm')}
                </p>
              </div>
            </div>
          </div>
          <div
            className={`of-fixed-broadband-availability__column ${dsClass.PADDING_BOTTOM_4} ${dsClass.GRID_COL_S_6} ${dsClass.GRID_COL_XS_4} ${dsClass.GRID_COL_L_6}`}
          >
            <div className="of-fixed-broadband-availability__icon of-fixed-broadband-availability__icon--email" />
            <div className="of-fixed-broadband-availability__content">
              <h3 className={dsClass.H3}>{t.F5M7('Contact sales')}</h3>
              <Link className={dsClass.PADDING_BOTTOM_4} to="/yhteydenotto">
                {t.RFSE('Leave a message for sales')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
