import * as CL from '@design-system/component-library';
import { SubscriptionFilterValue } from '../../common/enums.js';
import { getFilterOptions } from '../SubscriptionLists/subscriptionListUtils.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import type { SubscriptionCategory } from '../../common/enums.js';

export interface TableSubscriptionTypeFilterProps {
  subscriptionCategory: SubscriptionCategory;
}

export const SUBSCRIPTION_TYPE_SEARCH_FILTER_PARAM = 'subscriptionType';

export const TableSubscriptionTypeFilter = ({ subscriptionCategory }: TableSubscriptionTypeFilterProps) => {
  const navigate = useNavigate();
  const { search, pathname, hash } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  return (
    <CL.Dropdown
      id="subtypeoptions"
      name="subtypeoptions"
      className="of-table-header-dropdown"
      items={getFilterOptions()[subscriptionCategory].map(option => ({
        label: option.displayText,
        value:
          (option.subscriptionType?.length ? option.subscriptionType?.join(',') : SubscriptionFilterValue.ALL) || '',
      }))}
      selectedValue={queryParams.get(SUBSCRIPTION_TYPE_SEARCH_FILTER_PARAM) || undefined}
      integrated={true}
      onValueChange={value => {
        const textValue = value.dataset.value || '';
        textValue === SubscriptionFilterValue.ALL
          ? queryParams.delete(SUBSCRIPTION_TYPE_SEARCH_FILTER_PARAM)
          : queryParams.set(SUBSCRIPTION_TYPE_SEARCH_FILTER_PARAM, textValue);
        navigate(`${pathname}?${queryParams}${hash}`);
      }}
    />
  );
};
