import { ActionPhase } from '../common/storeUtils.js';
import { TypeKeys } from '../actions/index.js';
import { getErrorsFromSupportCaseDialog } from '../../components/SupportCaseDialog/SupportCaseDialog.js';
import { reduceCrudAction, reduceDisplayItemsLoadAction } from './reducerUtils.js';
import { updateActionStatePhase } from '../common/index.js';
import type { ActionsHistory } from '../common/store.js';
import type { SelfServiceActionTypes } from '../actions/index.js';
import type { SupportCasesSearchResponse } from '../../generated/api/models.js';
import type { SupportCasesState } from '../../common/types/states.js';

export function supportCaseReducer(
  state: ActionsHistory | undefined | null,
  action: SelfServiceActionTypes
): (SupportCasesState & ActionsHistory) | null {
  if (typeof state === 'undefined') {
    return null;
  }

  switch (action.type) {
    case TypeKeys.ADD_SUPPORT_CASE: {
      if (getErrorsFromSupportCaseDialog(action.supportCase)) {
        return state;
      } else {
        return reduceCrudAction(action, { ...state, ...{ isSubmitting: true, supportCaseSent: false } });
      }
    }

    case TypeKeys.ADD_SUPPORT_CASE_FULFILLED: {
      return {
        ...(state || {}),
        supportCaseSent: true,
        supportCaseFailed: false,
        isSubmitting: false,
        actions: updateActionStatePhase(
          TypeKeys.ADD_SUPPORT_CASE,
          state || {},
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
      };
    }

    case TypeKeys.ADD_SUPPORT_CASE_FAILED: {
      return {
        ...state,
        supportCaseSent: false,
        supportCaseFailed: true,
        isSubmitting: false,
        actions: updateActionStatePhase(TypeKeys.ADD_SUPPORT_CASE, state!, ActionPhase.IN_PROGRESS, ActionPhase.FAILED),
        errors: action.errors,
      };
    }

    case TypeKeys.ADD_ANONYMOUS_SUPPORT_CASE:
      return { ...state, ...{ isSubmitting: true } };

    case TypeKeys.ADD_ANONYMOUS_SUPPORT_CASE_FULFILLED:
    case TypeKeys.ADD_ANONYMOUS_SUPPORT_CASE_FAILED:
      return { ...state, ...{ isSubmitting: false } };

    case TypeKeys.LOAD_OPEN_SUPPORT_CASES_FOR_INVOICES: {
      const itemsState = reduceDisplayItemsLoadAction<
        TypeKeys.LOAD_OPEN_SUPPORT_CASES_FOR_INVOICES,
        SupportCasesSearchResponse
      >(action, state, 'supportCaseDisplayId', !action.limit, action.offset, true);
      return {
        ...itemsState,
        loadSupportCaseInProgress: true,
      };
    }

    case TypeKeys.LOAD_OPEN_SUPPORT_CASES_FOR_INVOICES_FULFILLED: {
      return {
        ...state,
        loadSupportCaseFailed: false,
        loadSupportCaseInProgress: false,
        totalItems: action.total,
        openSupportCases: action.supportCases,
        actions: updateActionStatePhase(
          TypeKeys.LOAD_OPEN_SUPPORT_CASES_FOR_INVOICES,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
      };
    }

    case TypeKeys.LOAD_OPEN_SUPPORT_CASES_FOR_INVOICES_FAILED: {
      return {
        ...state,
        loadSupportCaseFailed: true,
        loadSupportCaseInProgress: false,
        actions: updateActionStatePhase(
          TypeKeys.LOAD_OPEN_SUPPORT_CASES_FOR_INVOICES,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
      };
    }

    case TypeKeys.SET_CASE_SENT_STATUS: {
      return {
        ...state,
        supportCaseSent: action.supportCaseSent,
      };
    }

    case TypeKeys.LOG_OUT: {
      return null;
    }

    default:
      return state;
  }
}
