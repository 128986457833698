import { Anchor } from '../Anchor/Anchor.js';
import { DialogType } from '../../common/enums.js';
import { t } from '../../common/i18n/index.js';
import type { ContactPerson } from '../../generated/api/models.js';
import type { DialogParams } from '../../common/types/dialog.js';

export interface SubscriptionContactInfoBbInvitationProps {
  onShowDialog: (params: DialogParams) => void;
  subscriptionId: string;
  contactPerson?: ContactPerson;
}

export const SubscriptionContactInfoBbInvitation = ({
  onShowDialog,
  subscriptionId,
  contactPerson,
}: SubscriptionContactInfoBbInvitationProps) => {
  const onClick = () => {
    if (contactPerson) {
      return onShowDialog({
        subscriptionId,
        type: DialogType.BB_SUB_EMPLOYEE_INVITATION,
        contactPerson,
      });
    }
    return onShowDialog({
      type: DialogType.BB_SUB_EMPLOYEE_NOT_FOUND,
    });
  };

  return (
    <div className="ea-composite-field-grid__block">
      <div className="ea-fgrid">
        <div className="ea-fgrid__item ea-composite-field-grid__column-0 ea-fgrid__item--phone-10 ea-fgrid__item--tablet-10">
          <div
            id="subscriptionContactInfoHeader"
            className="ea-composite-field ea-composite-field--radio ea-composite-field--read __company-info-label of-subscription-details__company-info-header"
          >
            {t.E45A(`Subscription information in Employee's OmaElisa`)}
          </div>
          <div
            id="subscriptionContactInfo"
            className="ea-composite-field ea-composite-field--radio ea-composite-field--read"
          >
            {t.FR97(
              `In order to get subscription information visible in Employee's OmaElisa check that the person is subscription's user and that the user information includes employee's email and phone number.`
            )}
          </div>
          <div className="of-subscription-details__employee-invitation-link">
            <Anchor onClick={onClick}>{`${t.UT0X('Send invitation to employee')}  >`}</Anchor>
          </div>
        </div>
      </div>
    </div>
  );
};
