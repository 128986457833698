import { Field } from 'formik';
import { InputComponent } from '../FieldComponents/index.js';
import { requiredValidation } from '../validation.js';
import type { OptionalInputComponentProps } from '../FieldComponents/index.js';

interface Props extends OptionalInputComponentProps {
  validate?: (value: string) => string | undefined;
  maxLength?: number;
}

export const SpecialInput = (props: Props) => {
  const usedValidation = props.required ? props.validate || requiredValidation : undefined;
  return <Field {...props} component={InputComponent} validate={usedValidation} />;
};
