import { Esim } from './Esim.js';
import { SimType } from '../../generated/api/models.js';
import { pinCodeMsg, qrCodeGenerationFailureMsg, qrCodeMsg, t } from '../../common/i18n/index.js';
import type { Subscription } from '../../generated/api/models.js';

import './EsimActivation.scss';

export interface EsimPrintoutProps {
  subscription: Subscription;
  readyToPrint?: () => void;
}

export const EsimPrintout = (props: EsimPrintoutProps) => {
  const { subscription, readyToPrint } = props;

  if (!subscription.details || !subscription.details.mobile || subscription.details.mobile.simType !== SimType.ESIM) {
    return <p>{t.BC3P('There is no eSIM ordered for this subscription.')}</p>;
  }

  const mobileDetails = subscription.details.mobile;
  const eSim = new Esim(mobileDetails.eSimQrActivationCode || '');

  return (
    <div>
      <p>
        {t.F0OO('Order number')}: {subscription.deliveryOrderDisplayId || 'N/A'}
        <br />
        {t.U4MA('User')}: {subscription.subscriptionContactName || subscription.subscriptionPurposeOfUse || 'N/A'}
        <br />
        {t.XSQ0('Product name')}: {subscription.subscriptionName}
        <br />
        {t.DBQJ('Subscription number')}: {subscription.subscriptionUserFriendlyId || 'N/A'}
      </p>
      <img
        src={eSim.getQrCodeUrl()}
        alt={t.CAQ7(qrCodeMsg)}
        onError={(e: React.ChangeEvent<HTMLImageElement>) => {
          e.target.alt = t.ARDH(qrCodeGenerationFailureMsg);
          if (readyToPrint) {
            readyToPrint();
          }
        }}
        // eslint-disable-next-line react/no-unknown-property
        onAbort={(e: React.ChangeEvent<HTMLImageElement>) => {
          e.target.alt = t.ARDH(qrCodeGenerationFailureMsg);
          if (readyToPrint) {
            readyToPrint();
          }
        }}
        onLoad={() => {
          if (readyToPrint) {
            readyToPrint();
          }
        }}
      />
      <p>
        {t.AFW3('SM-DP+ address')}: <span className="of-esim-activation__break-word">{eSim.getSmdpAddress()}</span>
        <br />
        {t.R0LP('Activation code')}: <span className="of-esim-activation__break-word">{eSim.getAcToken()}</span>
        <br />
        {t.ALQT(pinCodeMsg)}: 1234
      </p>
    </div>
  );
};
