import { fixedInternetMsg, t } from '../../../common/i18n/index.js';
import type { ProductContent } from './CommercialProductContent.js';

const maximumDownloadAndUploadMsg = 'Download speed max. {0}M, upload speed max. {1}M';
const recommendedForUsersAndDevicesMsg = 'Recommended for {0} users ({1} devices)';
const largeDataTransfersAnd8KMoviesMsg = 'Large data transfers and 8K movies';
const forAllInternetUsersMsg = 'For all internet users';
const streaming4KMoviesAndSeriesMsg = 'Streaming 4K movies and series';
const fourKVideosAndCloudServicesMsg = '4K videos and cloud services';
const fourKVideoCallsAndCloudServicesMsg = '4K video calls and cloud services';
const remoteWorkVideoCallsAndCloudServicesMsg = 'Remote work, video calls and cloud services';
const transferLargeFilesAndShareTheInternetForMultiplePeopleMsg =
  'Transfer large files and share the Internet for multiple people';
const eightKMoviesAndSeriesWithoutInterruptionsMsg = '8K movies and series without interruptions';
const forAllInternetUsersAndDevicesInYourHomeMsg = 'For all internet users and devices in your home';
const transferLargeFilesQuicklyMsg = 'Transfer large files quickly';
const watch4KMoviesAndSeriesMsg = 'Watch 4K movies and series';
const forRegularWorkAndLeisureMsg = 'For regular work and leisure';
const videoCallsAndSeamlessCloudServicesMsg = 'Video calls and seamless cloud services';
const emailsAndLightSurfingMsg = 'Emails and light surfing';
const fastDataTransfer8KMoviesWithoutInterruptionsMsg = 'Fast data transfer, 8K movies without interruptions';
const fastDataTransfer4KVideosAndCloudServicesMsg = 'Fast data transfer, 4K videos and cloud services';
const smoothVideoCallsAndCloudServicesMsg = 'Smooth video calls and cloud services';
const forDemandingWorkAndStreamingServicesMsg = 'For demanding work and active use of streaming services';
const forOccasionalWorkAndInternetUseMsg = 'For occasional work and Internet use';
const forRegularWorkAndLeisureEntertainmentMsg = 'For regular work and leisure entertainment';
const forSmoothWorkCloudAndEntertainmentMsg = 'For smooth work, cloud services and leisure entertainment';

export enum OfferType {
  MOBILE_BROADBAND = 'MOBILE_BROADBAND',
  FIXED_BROADBAND = 'FIXED_BROADBAND',
}

interface FixedBroadbandProductContent {
  offerCodes: string[];
  body: JSX.Element;
}

export interface NettiLiteOfferMapping {
  offerCodes: string[];
  offerType: OfferType;
  title: string;
  slogan: string;
  cardBullets: string[];
  checkoutBullets?: string[];
}

export const nettiLiteOfferSpecification = (): NettiLiteOfferMapping[] => [
  {
    offerCodes: ['5c25cda5-f90f-2949-d66f-3d8bb0f2c038', 'c2e416bd-0352-e32c-e2d0-b779e06740fe'],
    offerType: OfferType.MOBILE_BROADBAND,
    title: '1000M',
    slogan: t.FT2S('Super-fast internet connection for daily work or a small office'),
    cardBullets: [
      t.WBNM(transferLargeFilesAndShareTheInternetForMultiplePeopleMsg),
      t.AJAM(eightKMoviesAndSeriesWithoutInterruptionsMsg),
      t.QSG9(forAllInternetUsersAndDevicesInYourHomeMsg),
    ],
  },
  {
    offerCodes: ['94cc86b5-58d7-60be-d045-dae94a0cf430', '39f931cb-8bca-b1ac-68c3-e8329a7775f1'],
    offerType: OfferType.MOBILE_BROADBAND,
    title: '600M',
    slogan: t.Y9CE(forDemandingWorkAndStreamingServicesMsg),
    cardBullets: [
      t.RBCF(transferLargeFilesQuicklyMsg),
      t.R1D7(watch4KMoviesAndSeriesMsg),
      t.ZVU5(recommendedForUsersAndDevicesMsg, '5-9', '15-20'),
    ],
  },
  {
    offerCodes: ['60b76fb8-735a-3eca-5f69-139570bb2439', '263da02c-c7d9-b845-abb7-9d2b65f7db40'],
    offerType: OfferType.MOBILE_BROADBAND,
    title: '300M',
    slogan: t.VB8M('For regular work and actively surfing the Internet during free time'),
    cardBullets: [
      t.RBCF(transferLargeFilesQuicklyMsg),
      t.H4ZY(fourKVideoCallsAndCloudServicesMsg),
      t.ZVU5(recommendedForUsersAndDevicesMsg, '3-4', '10-15'),
    ],
  },
  {
    offerCodes: ['03722830-9d02-9d7c-ecef-fac4e17ed0ef', 'cf5a923d-c011-8a4a-7580-dc9b3e875a35'],
    offerType: OfferType.MOBILE_BROADBAND,
    title: '100M',
    slogan: t.GF1O('A good basic connection for work and surfing the Internet during free time'),
    cardBullets: [
      t.FF2X(forRegularWorkAndLeisureMsg),
      t.MV0P(videoCallsAndSeamlessCloudServicesMsg),
      t.ZVU5(recommendedForUsersAndDevicesMsg, '1-2', '1-4'),
    ],
  },
  {
    offerCodes: ['ab864cfc-776f-302d-d857-fcd423a447f5', 'cca43200-1f5c-8500-ffb6-a3aac8cc80c5'],
    offerType: OfferType.MOBILE_BROADBAND,
    title: '100M',
    slogan: t.IBNP('A light basic connection for work and surfing the net during free time'),
    cardBullets: [
      t.FF2X(forRegularWorkAndLeisureMsg),
      t.MV0P(videoCallsAndSeamlessCloudServicesMsg),
      t.ZVU5(recommendedForUsersAndDevicesMsg, '1-2', '1-4'),
    ],
  },
  {
    offerCodes: [
      '6204463d-9823-2975-795a-d515651e7bbc',
      '2ce10b29-e4de-6b9b-963e-89d9393f4f34',
      '7cc6c1a9-cb03-a47c-e60f-cb5b5be51d93',
    ],
    offerType: OfferType.FIXED_BROADBAND,
    title: '100M',
    slogan: t.FF2X(forRegularWorkAndLeisureMsg),
    cardBullets: [
      t.ZBPW(remoteWorkVideoCallsAndCloudServicesMsg),
      t.ZVU5(recommendedForUsersAndDevicesMsg, '3-4', '5-9'),
      t.CFX9(maximumDownloadAndUploadMsg, '100', '25'),
    ],
  },
  {
    offerCodes: [
      'f50f3123-ee3b-d085-2b93-a6eed9b5744b',
      '7b649a0e-bb1b-d23a-8680-1d8c55747078',
      '9538107c-9b1b-3cd4-a712-cedf69a5ca0f',
    ],
    offerType: OfferType.FIXED_BROADBAND,
    title: '50M',
    slogan: t.Y2QC(forOccasionalWorkAndInternetUseMsg),
    cardBullets: [
      t.IGOI(emailsAndLightSurfingMsg),
      t.ZVU5(recommendedForUsersAndDevicesMsg, '1-2', '2-4'),
      t.CFX9(maximumDownloadAndUploadMsg, '50', '15'),
    ],
  },
  {
    offerCodes: [
      '3e2f8635-f3f0-80b2-86fb-84d1cb5f26c7',
      '75db8f9f-bcd3-14f1-defa-9ac4c7305ce5',
      '1e811abb-0987-0491-1a43-2caa9a543916',
      '6234dbf1-6a8f-1ed4-6650-ce81fdb25e7b',
      '6002806c-134f-7ef2-3937-ad030f97f9a0',
      'dbac07d0-98e0-4ae4-c91c-4f454c36b464',
    ],
    offerType: OfferType.FIXED_BROADBAND,
    title: '1000M',
    slogan: t.DIPB('Super-fast internet for daily work and leisure entertainment'),
    cardBullets: [
      t.GO7L(largeDataTransfersAnd8KMoviesMsg),
      t.JWZQ(forAllInternetUsersMsg),
      t.CFX9(maximumDownloadAndUploadMsg, '1000', '500'),
    ],
  },
  {
    offerCodes: [
      'efdfdbd7-366f-9504-384d-ee81cfc90ead',
      'e4b6e42a-03ec-a6ef-732b-ca21322cbbf0',
      'fa0bf059-0fc6-e67e-7e52-27e43a8bdc16',
      'cb897e58-165f-92a4-dcc6-1bfb4af0ea61',
      '8c487e67-c62b-a90f-e31e-3784aa42abfe',
      'eeb7bd4e-39e5-8ecd-bb2f-8ccdaf0714dc',
    ],
    offerType: OfferType.FIXED_BROADBAND,
    title: '600M',
    slogan: t.Y9CE(forDemandingWorkAndStreamingServicesMsg),
    cardBullets: [
      t.ONM3(streaming4KMoviesAndSeriesMsg),
      t.ZVU5(recommendedForUsersAndDevicesMsg, '5-9', '15-20'),
      t.CFX9(maximumDownloadAndUploadMsg, '600', '300'),
    ],
  },
  {
    offerCodes: [
      '2d61230e-ccbf-731e-d5bf-a3168da19b7c',
      '04641fd4-0e4a-04e6-0b2d-0a8c33033979',
      '9c33d0db-0ea6-19c2-7181-7e8cd463a08b',
      '64b989ad-5e18-b399-68e6-af689cf500b3',
      'af39c5b2-b8d0-b54a-fbb9-69cc96843618',
      'bdaad831-08e2-4b06-d7c6-dd4ad8e99816',
      'dccd1d26-96eb-06ed-a012-51d4f85c525e',
    ],
    offerType: OfferType.FIXED_BROADBAND,
    title: '300M',
    slogan: t.GAJ8('For regular remote work and actively surfing the Internet during free time'),
    cardBullets: [
      t.MH00(fourKVideosAndCloudServicesMsg),
      t.ZVU5(recommendedForUsersAndDevicesMsg, '3-4', '10-15'),
      t.CFX9(maximumDownloadAndUploadMsg, '300', '150'),
    ],
  },
  {
    offerCodes: [
      '737975ff-99e7-cf6b-d3e9-33865d8c7e60',
      '45059d73-6a1a-5abd-4990-3f36d2580248',
      '422cd74b-0f97-28d1-67d0-c1f1072a2daf',
      '138759bd-d4db-a3da-d47e-20018b18c184',
      '4f0a0b2a-577a-cedb-e78a-7cad6400b450',
      '04a0fe0b-f1b9-cb22-4db0-52dc9dec5625',
    ],
    offerType: OfferType.FIXED_BROADBAND,
    title: '100M',
    slogan: t.FF2X(forRegularWorkAndLeisureMsg),
    cardBullets: [
      t.ZBPW(remoteWorkVideoCallsAndCloudServicesMsg),
      t.ZVU5(recommendedForUsersAndDevicesMsg, '3-4', '5-9'),
      t.CFX9(maximumDownloadAndUploadMsg, '100', '100'),
    ],
  },
  {
    offerCodes: [
      '8de637ec-4f06-865f-3ff0-05ceb246b103',
      '6c1006de-dfa0-80fc-f0a1-5d319017595a',
      'dfd6f21d-d7e5-9336-c576-ac1cd41ee151',
      '0eaf4279-d569-d131-ad97-d21431fb079a',
      '4902c2dc-9da5-3112-f272-7d25c580f8da',
      'f976ba6f-277e-a342-d0fe-df7c61e22fe2',
    ],
    offerType: OfferType.FIXED_BROADBAND,
    title: '50M',
    slogan: t.Y2QC(forOccasionalWorkAndInternetUseMsg),
    cardBullets: [
      t.IGOI(emailsAndLightSurfingMsg),
      t.ZVU5(recommendedForUsersAndDevicesMsg, '1-2', '2-4'),
      t.CFX9(maximumDownloadAndUploadMsg, '50', '50'),
    ],
  },
  {
    offerCodes: ['38508241-a78e-3c20-cb02-27bae0965e90'],
    offerType: OfferType.FIXED_BROADBAND,
    title: '1000M',
    slogan: t.IEFA('Superfast Internet for demanding work and cloud services'),
    cardBullets: [
      t.QKRX(fastDataTransfer8KMoviesWithoutInterruptionsMsg),
      t.JWZQ(forAllInternetUsersMsg),
      t.CFX9(maximumDownloadAndUploadMsg, '1000', '50'),
    ],
  },
  {
    offerCodes: ['4f5d6fcb-4143-a607-3fab-00dd7efe69e6'],
    offerType: OfferType.FIXED_BROADBAND,
    title: '600M',
    slogan: t.Y9CE(forDemandingWorkAndStreamingServicesMsg),
    cardBullets: [
      t.KMZY(fastDataTransfer4KVideosAndCloudServicesMsg),
      t.ZVU5(recommendedForUsersAndDevicesMsg, '5-9', '15-20'),
      t.CFX9(maximumDownloadAndUploadMsg, '600', '50'),
    ],
  },
  {
    offerCodes: ['d302bfc6-0b42-7545-bc19-dba61663a12e'],
    offerType: OfferType.FIXED_BROADBAND,
    title: '300M',
    slogan: t.Y821(forSmoothWorkCloudAndEntertainmentMsg),
    cardBullets: [
      t.KMZY(fastDataTransfer4KVideosAndCloudServicesMsg),
      t.ZVU5(recommendedForUsersAndDevicesMsg, '3-4', '10-15'),
      t.CFX9(maximumDownloadAndUploadMsg, '300', '50'),
    ],
  },
  {
    offerCodes: ['bef3ac3d-d6e1-84df-98c9-34607cf233e4'],
    offerType: OfferType.FIXED_BROADBAND,
    title: '100M',
    slogan: t.W4JJ(forRegularWorkAndLeisureEntertainmentMsg),
    cardBullets: [
      t.NXC5(smoothVideoCallsAndCloudServicesMsg),
      t.ZVU5(recommendedForUsersAndDevicesMsg, '3-4', '5-9'),
      t.CFX9(maximumDownloadAndUploadMsg, '100', '50'),
    ],
  },
  {
    offerCodes: ['b10959ad-6256-e305-8e6e-30f4a6651b11', '352cf1b6-8005-6252-1c69-7cd63a6c6d5a'],
    offerType: OfferType.FIXED_BROADBAND,
    title: '50M',
    slogan: t.Y2QC(forOccasionalWorkAndInternetUseMsg),
    cardBullets: [
      t.IGOI(emailsAndLightSurfingMsg),
      t.ZVU5(recommendedForUsersAndDevicesMsg, '1-2', '2-4'),
      t.CFX9(maximumDownloadAndUploadMsg, '50', '15'),
    ],
  },
  {
    offerCodes: ['485a15d0-16d7-1bca-8477-079e5799070c'],
    offerType: OfferType.FIXED_BROADBAND,
    title: '1000M',
    slogan: t.Y9CE(forDemandingWorkAndStreamingServicesMsg),
    cardBullets: [
      t.QKRX(fastDataTransfer8KMoviesWithoutInterruptionsMsg),
      t.JWZQ(forAllInternetUsersMsg),
      t.CFX9(maximumDownloadAndUploadMsg, '1000', '100'),
    ],
  },
  {
    offerCodes: ['1cbaf60b-d154-1fc6-f7e9-501d81ba6d70'],
    offerType: OfferType.FIXED_BROADBAND,
    title: '600M',
    slogan: t.Y821(forSmoothWorkCloudAndEntertainmentMsg),
    cardBullets: [
      t.KMZY(fastDataTransfer4KVideosAndCloudServicesMsg),
      t.ZVU5(recommendedForUsersAndDevicesMsg, '5-9', '15-20'),
      t.CFX9(maximumDownloadAndUploadMsg, '600', '100'),
    ],
  },
  {
    offerCodes: ['d19f7685-464e-b826-90b1-9228badc0503'],
    offerType: OfferType.FIXED_BROADBAND,
    title: '300M',
    slogan: t.W4JJ(forRegularWorkAndLeisureEntertainmentMsg),
    cardBullets: [
      t.KMZY(fastDataTransfer4KVideosAndCloudServicesMsg),
      t.ZVU5(recommendedForUsersAndDevicesMsg, '3-4', '10-15'),
      t.CFX9(maximumDownloadAndUploadMsg, '300', '100'),
    ],
  },
  {
    offerCodes: ['37796d80-fde0-e46b-b233-89dffbed8714'],
    offerType: OfferType.FIXED_BROADBAND,
    title: '100M',
    slogan: t.Y2QC(forOccasionalWorkAndInternetUseMsg),
    cardBullets: [
      t.NXC5(smoothVideoCallsAndCloudServicesMsg),
      t.ZVU5(recommendedForUsersAndDevicesMsg, '3-4', '5-9'),
      t.CFX9(maximumDownloadAndUploadMsg, '100', '50'),
    ],
  },
  {
    offerCodes: ['d7cdba1d-f338-824d-5029-cd0e5223bf4f'],
    offerType: OfferType.MOBILE_BROADBAND,
    title: '10M',
    slogan: 'This is a test dummy 5G nettilite product',
    cardBullets: [],
  },
  {
    offerCodes: ['80bcca00-0a01-15b8-2c7d-8bf07340041e'],
    offerType: OfferType.FIXED_BROADBAND,
    title: 'Test',
    slogan: 'Devint test product',
    cardBullets: [],
  },
];

export const FixedBroadbandContent = (): ProductContent[] =>
  nettiLiteOfferSpecification()
    .filter(nettiLite => nettiLite.offerType === OfferType.FIXED_BROADBAND)
    .map(nettiLite => ({
      offerCodes: nettiLite.offerCodes,
      offerName: t.JEHN(fixedInternetMsg),
      title: nettiLite.title,
      body: <>{nettiLite.slogan}</>,
    }));

export const nettiLiteMobileBroadbandContent = (): ProductContent[] =>
  nettiLiteOfferSpecification()
    .filter(nettiLite => nettiLite.offerType === OfferType.MOBILE_BROADBAND)
    .map(nettiLite => ({
      offerCodes: nettiLite.offerCodes,
      offerName: t.JEHN(fixedInternetMsg),
      title: nettiLite.title,
      body: <>{nettiLite.slogan}</>,
    }));

export const resolveBullets = (offerCode: string): string[] =>
  nettiLiteOfferSpecification().find(nettiLite => nettiLite.offerCodes.includes(offerCode))?.cardBullets || [];

export const defaultFixedBroadbandProductContent: FixedBroadbandProductContent = {
  offerCodes: [],
  body: <></>,
};

export const fixedBroadbandProductContent = (): FixedBroadbandProductContent[] =>
  nettiLiteOfferSpecification().map(nettiLite => {
    const bullets = nettiLite.checkoutBullets || nettiLite.cardBullets;
    return {
      offerCodes: nettiLite.offerCodes,
      body: (
        <ul className="ea-article-ul">
          {bullets.map((bullet, i) => (
            <li key={`bullet-${i}`}>{bullet}</li>
          ))}
        </ul>
      ),
    };
  });
