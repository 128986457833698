import * as CL from '@design-system/component-library';
import { dsClass } from '../../common/constants/dsClasses.js';

export interface TextWithPopoverProps {
  textContent: string;
  placement: 'top' | 'right' | 'bottom' | 'left';
  i18nTooltipContentText?: string;
  children?: JSX.Element | JSX.Element[];
  className?: string;
}

export const TextWithPopover = ({
  textContent,
  placement,
  i18nTooltipContentText = '',
  children,
  className,
}: TextWithPopoverProps): JSX.Element => {
  const icon = <CL.Icon icon="help" size="m" type="light" color="blue-600" />;

  return (
    <div className={dsClass.DISPLAY_FLEX}>
      <div className={dsClass.MARGIN_RIGHT_2}>{textContent}</div>
      <CL.Popover
        className={className}
        triggerElement={icon}
        placement={placement}
        i18n_popover_contentText={i18nTooltipContentText}
      >
        {children}
      </CL.Popover>
    </div>
  );
};
