import { HttpStatus, RETRYABLE_HTTP_STATUS, callUiApi, prepareUiApiRequest } from '../common/uiApiUtils.js';
import {
  TypeKeys,
  authValidate,
  hardRedirect,
  logOut,
  pollForMyselfChanges,
  pollForMyselfChangesFailed,
  pollForMyselfChangesFulfilled,
} from '../actions/index.js';
import { actionToActionState, getError, getMdmIdHeaderFromUrlParams } from './epicUtils.js';
import { combineEpics, ofType } from 'redux-observable';
import { concat, of } from 'rxjs';
import { concatMap, delay, filter, map, takeUntil } from 'rxjs/operators';
import { getMyselfChangesPrivateMethod } from '../../generated/api/uiApiMethods.js';
import { isInBrowser } from '../../common/utils/ssrUtils.js';
import type { Action } from 'redux';
import type { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import type { AjaxError, AjaxResponse } from 'rxjs/ajax';
import type { EpicDependencies } from './epicUtils.js';
import type { PollForMyselfChangesAction, SelfServiceActionTypes } from '../actions/index.js';
import type { State } from '../common/store.js';

// Makes it possible to use sessionStorage to skip myself changes polling, which is especially useful in TestCafe tests
// to avoid unnecessary timeout during loading of an authenticated page.
// TODO: leo 18.7.2022 - see if `skipMyselfChangesPolling` can be fully removed as a feature, hopefully with PW there's no need for it.
const skipMyselfChangesPolling = (): boolean => {
  if (isInBrowser()) {
    return !!sessionStorage.getItem('skipMyselfChangesPolling');
  }
  return false;
};

const completePollForMyselfChangesEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>
) => action$.ofType(TypeKeys.POLL_FOR_MYSELF_CHANGES_FULFILLED).pipe(map(pollForMyselfChanges));

export const pollForMyselfChangesEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.POLL_FOR_MYSELF_CHANGES)), (action: PollForMyselfChangesAction) =>
    actionToActionState(action, state$, 'authenticated')
  ).pipe(
    filter(() => !skipMyselfChangesPolling()),
    concatMap(() => {
      const latestChangeRef = state$.value.user?.authenticated?.latestChangeRef;

      const errorHandler = (error: AjaxError) => {
        if (RETRYABLE_HTTP_STATUS.includes(error.status)) {
          // Need to just retry again, have a short delay to not DOS the backend
          return concat(
            getError(error, pollForMyselfChangesFailed, { retainLatestChangeRef: 'true' }),
            of(pollForMyselfChanges()).pipe(delay(3000))
          );
        }

        // If the user is an authenticated employee who navigated to NOE do not log out, otherwise the user has to
        // log in again to access EOE. In this case the employee user will be shown an info modal on top of NOE
        // login page to inform what are the alternatives to proceed.
        if (error.status === HttpStatus.FORBIDDEN) {
          return concat(getError(error, pollForMyselfChangesFailed), of(authValidate()));
        }
        // If changes polling fail for any other reason except those retryable ones, we have to take it as fatal and
        // log out the user. Harsh but there is no other way to make sure everything else won't start failing after
        // this too.
        return concat(getError(error, pollForMyselfChangesFailed), of(logOut()));
      };

      const successAction = (response: AjaxResponse) => {
        const location = response.xhr.getResponseHeader('location');

        if (response.status === HttpStatus.NO_CONTENT && location) {
          return hardRedirect(location);
        }

        return pollForMyselfChangesFulfilled(response.response);
      };

      return callUiApi({
        epicDependencies,
        headers: getMdmIdHeaderFromUrlParams(),
        method: getMyselfChangesPrivateMethod({ latestChangeRef }),
        state$,
        successAction,
        successActionGetAjaxResponse: true,
        errorHandler,
      }).pipe(takeUntil(action$.pipe(ofType(TypeKeys.STOP_POLL_FOR_MYSELF_CHANGES))));
    })
  );

export const myselfChangesEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = combineEpics(
  pollForMyselfChangesEpic,
  completePollForMyselfChangesEpic
);
