import { TypeKeys } from './actionsUtils.js';
import { convertToCommonErrors } from '../../common/utils/errorUtils.js';
import type { Action } from 'redux';
import type { ErrorAction } from './actionsUtils.js';
import type { GenericError, StartFixerResponse } from '../../generated/api/models.js';
import type { Locale } from '../../common/i18n/index.js';

export interface StartMobileFixerAction extends Action<TypeKeys.START_MOBILE_FIXER> {
  locale: Locale;
  portal: string;
  subscriptionId: string;
}

export const startMobileFixer = (subscriptionId: string, locale: Locale, portal: string): StartMobileFixerAction => ({
  locale,
  portal,
  subscriptionId,
  type: TypeKeys.START_MOBILE_FIXER,
});

export interface StartMobileFixerFulfilledAction extends Action<TypeKeys.START_MOBILE_FIXER_FULFILLED> {
  response: StartFixerResponse;
}

export const startMobileFixerFulfilled = (resp: StartFixerResponse): StartMobileFixerFulfilledAction => {
  window.location.href = resp.fixerUrl;
  return { response: resp, type: TypeKeys.START_MOBILE_FIXER_FULFILLED };
};

export type StartMobileFixerFailedAction = ErrorAction<TypeKeys.START_MOBILE_FIXER_FAILED>;

export const startMobileFixerFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): StartMobileFixerFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.START_MOBILE_FIXER_FAILED,
});

export type MobileFixerActionTypes =
  | StartMobileFixerAction
  | StartMobileFixerFulfilledAction
  | StartMobileFixerFailedAction;
