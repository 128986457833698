import {
  BARRING_CODE_ORDERS,
  barrings,
  callBarringCodes,
  dataRoamingBarringCodes,
  getAddOnBarringNameByAddOnGuid,
  roamingBarringCodes,
  smsBarringCodes,
} from '../../common/utils/barringUtils.js';
import { t } from '../../common/i18n/index.js';
import type { AddOn } from '../../generated/api/addOn.js';
import type { SubscriptionAddOnBarrings } from '../../common/types/barring.js';
import type { SubscriptionDetailsSelectedAddOns } from '../../generated/api/subscriptionDetailsSelectedAddOns.js';

export interface BarringGroupAvailability {
  areVoiceBarringsAvailable: boolean;
  areSmsBarringsAvailable: boolean;
  areDataBarringsAvailable: boolean;
  areRoamingBarringsAvailable: boolean;
  areLocatingBarringsAvailable: boolean;
}

export interface BarringAvailability {
  availableDataRoamingBarrings: string[];
  availableRoamingBarrings: string[];
}

/**
 * Includes the less-restrictive barrings when a more restrictive barring is selected.
 */
export const getChainedAddOnCodes = (
  selectedAddOns: AddOn[],
  addOnCodesInGroup: string[],
  sortedAddOnCodesInGroup: string[],
  ...hiddenAddOnCodes: string[]
) => {
  const selectedAddOnsInGroup = selectedAddOns.filter(
    addOn => addOnCodesInGroup.includes(addOn.addOnCode) && !hiddenAddOnCodes.includes(addOn.addOnCode)
  );
  const selectedAddOnsInGroupSorted = selectedAddOnsInGroup.filter(addOn =>
    sortedAddOnCodesInGroup.includes(addOn.addOnCode)
  );
  const additionalAddOnsNotSorted = selectedAddOnsInGroup.filter(
    addOn => !sortedAddOnCodesInGroup.includes(addOn.addOnCode)
  );

  let orderedAddOnCodes;
  if (selectedAddOnsInGroupSorted.length > 0) {
    const index = sortedAddOnCodesInGroup.indexOf(selectedAddOnsInGroupSorted[0].addOnCode);
    orderedAddOnCodes = sortedAddOnCodesInGroup
      .slice(0, index + 1)
      .filter(addOnCode => !hiddenAddOnCodes.includes(addOnCode));
  }

  return additionalAddOnsNotSorted.map(({ addOnCode }) => addOnCode).concat(orderedAddOnCodes ?? []);
};

export const getChainedDisplayBarringNames = (
  selectedAddOns: AddOn[],
  addOnCodesInGroup: string[],
  sortedAddOnCodesInGroup: string[],
  ...hiddenAddOnCodes: string[]
) => {
  return getChainedAddOnCodes(selectedAddOns, addOnCodesInGroup, sortedAddOnCodesInGroup, ...hiddenAddOnCodes).map(
    getAddOnBarringNameByAddOnGuid
  );
};

export const resolveBarringAvailabilities = (availableAddOnGuids: string[]): BarringAvailability => {
  return {
    availableDataRoamingBarrings: availableAddOnGuids.filter(guid => dataRoamingBarringCodes.includes(guid)),
    availableRoamingBarrings: availableAddOnGuids.filter(guid => roamingBarringCodes.includes(guid)),
  };
};

export const isAvailable = (code: string, barringAvailabilities: BarringAvailability): boolean => {
  switch (code) {
    case barrings.DATA_ALLOWED_EEA.code:
      return (
        barringAvailabilities.availableDataRoamingBarrings.includes(barrings.DATA_ALLOWED_EVERYWHERE.code) &&
        barringAvailabilities.availableRoamingBarrings.includes(barrings.ALLOWED_EVERYWHERE.code)
      );
    case barrings.DATA_ALLOWED_FINLAND.code:
      return (
        (barringAvailabilities.availableDataRoamingBarrings.includes(barrings.DATA_ALLOWED_EEA.code) ||
          barringAvailabilities.availableDataRoamingBarrings.includes(barrings.DATA_ALLOWED_EVERYWHERE.code)) &&
        (barringAvailabilities.availableRoamingBarrings.includes(barrings.ALLOWED_EEA.code) ||
          barringAvailabilities.availableRoamingBarrings.includes(barrings.ALLOWED_EVERYWHERE.code))
      );
    case barrings.ALLOWED_EEA.code:
      return barringAvailabilities.availableRoamingBarrings.includes(barrings.ALLOWED_EVERYWHERE.code);
    case barrings.ALLOWED_FINLAND.code:
      return (
        barringAvailabilities.availableRoamingBarrings.includes(barrings.ALLOWED_EEA.code) ||
        barringAvailabilities.availableRoamingBarrings.includes(barrings.ALLOWED_EVERYWHERE.code)
      );
    default:
      return true;
  }
};

export const isBarredByDefault = (code: string, barringAvailabilities: BarringAvailability) => {
  switch (code) {
    case barrings.DATA_ALLOWED_EEA.code:
      return (
        !isAvailable(code, barringAvailabilities) || !isAvailable(barrings.ALLOWED_EEA.code, barringAvailabilities)
      );
    case barrings.DATA_ALLOWED_FINLAND.code:
      return (
        !isAvailable(code, barringAvailabilities) || !isAvailable(barrings.ALLOWED_FINLAND.code, barringAvailabilities)
      );
    case barrings.ALLOWED_EEA.code:
      return !isAvailable(code, barringAvailabilities);
    case barrings.ALLOWED_FINLAND.code:
      return !isAvailable(code, barringAvailabilities);
    default:
      return false;
  }
};

export const createInitialBarringFormValues = (
  availableAddOnCodes: string[],
  selectedAddOns: SubscriptionDetailsSelectedAddOns[] = []
) => {
  const selectedAndLessRestrictiveBarringCodes = [
    ...getChainedAddOnCodes(
      selectedAddOns,
      [...callBarringCodes, barrings.CALLS_ALLOWED_FINLAND.code],
      BARRING_CODE_ORDERS.callBarring
    ),
    ...getChainedAddOnCodes(selectedAddOns, smsBarringCodes, BARRING_CODE_ORDERS.smsBarring),
    ...getChainedAddOnCodes(
      selectedAddOns,
      [...dataRoamingBarringCodes, barrings.DATAVARTIJA.code],
      BARRING_CODE_ORDERS.dataRoamingBarring,
      barrings.DATA_ALLOWED_EVERYWHERE.code
    ),
    ...getChainedAddOnCodes(
      selectedAddOns,
      roamingBarringCodes,
      BARRING_CODE_ORDERS.roamingBarring,
      barrings.ALLOWED_EVERYWHERE.code
    ),
    ...selectedAddOns
      .filter(selectedAddon => selectedAddon.addOnCode === barrings.DENY_LOCATING.code)
      .map(({ addOnCode }) => addOnCode),
  ];

  const barringAvailabilities = resolveBarringAvailabilities(availableAddOnCodes);

  return {
    // Call barring
    [barrings.P1.code]: selectedAndLessRestrictiveBarringCodes.includes(barrings.P1.code),
    [barrings.P2.code]: selectedAndLessRestrictiveBarringCodes.includes(barrings.P2.code),
    [barrings.P3.code]: selectedAndLessRestrictiveBarringCodes.includes(barrings.P3.code),
    [barrings.P4.code]: selectedAndLessRestrictiveBarringCodes.includes(barrings.P4.code),
    [barrings.CALLS_ALLOWED_FINLAND.code]: selectedAndLessRestrictiveBarringCodes.includes(
      barrings.CALLS_ALLOWED_FINLAND.code
    ),

    // SMS barring
    [barrings.T1.code]: selectedAndLessRestrictiveBarringCodes.includes(barrings.T1.code),
    [barrings.T2.code]: selectedAndLessRestrictiveBarringCodes.includes(barrings.T2.code),
    [barrings.T3.code]: selectedAndLessRestrictiveBarringCodes.includes(barrings.T3.code),
    [barrings.T4.code]: selectedAndLessRestrictiveBarringCodes.includes(barrings.T4.code),

    // Data roaming barring
    [barrings.DATA_ALLOWED_EEA.code]:
      selectedAndLessRestrictiveBarringCodes.includes(barrings.DATA_ALLOWED_EEA.code) ||
      selectedAndLessRestrictiveBarringCodes.includes(barrings.ALLOWED_EEA.code) ||
      isBarredByDefault(barrings.DATA_ALLOWED_EEA.code, barringAvailabilities),
    [barrings.DATA_ALLOWED_FINLAND.code]:
      selectedAndLessRestrictiveBarringCodes.includes(barrings.DATA_ALLOWED_FINLAND.code) ||
      selectedAndLessRestrictiveBarringCodes.includes(barrings.ALLOWED_FINLAND.code) ||
      isBarredByDefault(barrings.DATA_ALLOWED_FINLAND.code, barringAvailabilities),
    [barrings.DATA_DENIED.code]:
      selectedAndLessRestrictiveBarringCodes.includes(barrings.DATA_DENIED.code) ||
      isBarredByDefault(barrings.DATA_DENIED.code, barringAvailabilities),
    [barrings.DATAVARTIJA.code]:
      selectedAndLessRestrictiveBarringCodes.includes(barrings.DATAVARTIJA.code) ||
      isBarredByDefault(barrings.DATAVARTIJA.code, barringAvailabilities),

    // Roaming barring
    [barrings.ALLOWED_FINLAND.code]:
      selectedAndLessRestrictiveBarringCodes.includes(barrings.ALLOWED_FINLAND.code) ||
      isBarredByDefault(barrings.ALLOWED_FINLAND.code, barringAvailabilities),
    [barrings.ALLOWED_EEA.code]:
      selectedAndLessRestrictiveBarringCodes.includes(barrings.ALLOWED_EEA.code) ||
      isBarredByDefault(barrings.ALLOWED_EEA.code, barringAvailabilities),

    // Locating barrings
    [barrings.DENY_LOCATING.code]: selectedAndLessRestrictiveBarringCodes.includes(barrings.DENY_LOCATING.code),
  };
};

export const resolveCallBarringCode = (formValues: { [code: string]: boolean }): string | undefined => {
  switch (true) {
    case formValues[barrings.P1.code]:
      return barrings.P1.code;
    case formValues[barrings.P2.code]:
      return barrings.P2.code;
    case formValues[barrings.P3.code]:
      return barrings.P3.code;
    case formValues[barrings.P4.code]:
      return barrings.P4.code;
  }

  return undefined;
};

export const resolveBlockCallsToForeignNumbers = (formValues: { [code: string]: boolean }): string | undefined => {
  if (formValues[barrings.CALLS_ALLOWED_FINLAND.code]) {
    return barrings.CALLS_ALLOWED_FINLAND.code;
  }

  return undefined;
};

export const resolveSmsBarringCode = (formValues: { [code: string]: boolean }): string | undefined => {
  switch (true) {
    case formValues[barrings.T1.code]:
      return barrings.T1.code;
    case formValues[barrings.T2.code]:
      return barrings.T2.code;
    case formValues[barrings.T3.code]:
      return barrings.T3.code;
    case formValues[barrings.T4.code]:
      return barrings.T4.code;
  }

  return undefined;
};

export const resolveDataBarringCode = (formValues: { [code: string]: boolean }): string => {
  switch (true) {
    case formValues[barrings.DATA_DENIED.code]:
      return barrings.DATA_DENIED.code;
    case formValues[barrings.DATA_ALLOWED_FINLAND.code]:
      return barrings.DATA_ALLOWED_FINLAND.code;
    case formValues[barrings.DATA_ALLOWED_EEA.code]:
      return barrings.DATA_ALLOWED_EEA.code;
  }

  return barrings.DATA_ALLOWED_EVERYWHERE.code;
};

export const resolveRoamingBarringCode = (formValues: { [code: string]: boolean }): string => {
  switch (true) {
    case formValues[barrings.ALLOWED_FINLAND.code]:
      return barrings.ALLOWED_FINLAND.code;
    case formValues[barrings.ALLOWED_EEA.code]:
      return barrings.ALLOWED_EEA.code;
  }

  return barrings.ALLOWED_EVERYWHERE.code;
};

export const resolveLocatingBarringCode = (formValues: { [code: string]: boolean }): string | undefined => {
  if (formValues[barrings.DENY_LOCATING.code]) {
    return barrings.DENY_LOCATING.code;
  }

  return undefined;
};

export const getSubscriptionAddOnBarrings = (formData: { [key: string]: boolean }): SubscriptionAddOnBarrings => {
  return {
    callBarring: resolveCallBarringCode(formData),
    callBarringAllowedFinland: resolveBlockCallsToForeignNumbers(formData),
    smsBarring: resolveSmsBarringCode(formData),
    roamingBarring: resolveRoamingBarringCode(formData),
    dataRoamingBarring: resolveDataBarringCode(formData),
    dataVartija: formData[barrings.DATAVARTIJA.code] ? barrings.DATAVARTIJA.code : undefined,
    locatingBarring: resolveLocatingBarringCode(formData),
  };
};

export const getTextForActivatingBarring = (barringCode: string) => {
  const texts = {
    [barrings.ALLOWED_EEA.code]: t.VSRK('Use of subscription in EU and EEA countries'),
    [barrings.ALLOWED_EVERYWHERE.code]: t.RHLM('Use of subscription abroad'),
  };
  return texts[barringCode];
};

export const resolveBarringGroupAvailabilities = (availableAddonGuids: string[]): BarringGroupAvailability => ({
  areVoiceBarringsAvailable: [...callBarringCodes, barrings.CALLS_ALLOWED_FINLAND.code].every(c =>
    availableAddonGuids.includes(c)
  ),
  areSmsBarringsAvailable: smsBarringCodes.every(c => availableAddonGuids.includes(c)),
  areDataBarringsAvailable: dataRoamingBarringCodes.some(c => availableAddonGuids.includes(c)),
  areRoamingBarringsAvailable: roamingBarringCodes.some(c => availableAddonGuids.includes(c)),
  areLocatingBarringsAvailable: availableAddonGuids.includes(barrings.DENY_LOCATING.code),
});
