import { prepareElisaIdLoginPublicMethod } from '../../../generated/api/uiApiMethods.js';

interface ElisaIdResponse {
  authCode: string;
  needsMerge: boolean;
}

export const fetchElisaIdAuthCode = async (userName: string) => {
  const method = prepareElisaIdLoginPublicMethod();
  return fetch(method.path, {
    method: method.verb, // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify({ userName }), // body data type must match "Content-Type" header
  })
    .then(res => res.json())
    .then(({ authCode }: ElisaIdResponse) => {
      if (authCode) {
        return Promise.resolve(authCode);
      } else {
        return Promise.reject();
      }
    });
};
