import * as CL from '@design-system/component-library';
import { SystemError, hasSystemError } from '../SystemError/SystemError.js';
import { contactYourCompanyAdminMsg, t } from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import type { CommonError } from '../../common/types/errors.js';

interface AuthorizationErrorProps {
  errors: CommonError[];
}

export const AuthorizationError = ({ errors }: AuthorizationErrorProps) => {
  if (hasSystemError(errors)) {
    return <SystemError errors={errors} />;
  }
  return (
    <CL.Grid className={dsClass.MARGIN_TOP_8}>
      <CL.GridRow justifyCenter gutter={true}>
        <CL.GridCol colWidthXS={3} colWidthM={4} colWidthL={6}>
          <h3>{errors[0].message}</h3>
          <p>{t.CEHN(contactYourCompanyAdminMsg)}</p>
        </CL.GridCol>
      </CL.GridRow>
    </CL.Grid>
  );
};
