import { SubscriptionType } from '../../generated/api/models.js';

const defaultIcon = 'generic-service-filled';

const getDeviceIcon = (deviceType?: string) => {
  switch (deviceType?.toLocaleLowerCase()) {
    case 'phone':
      return 'phone-filled';
    case 'tablet':
      return 'tablet-filled';
    case 'computers':
      return 'laptop-filled';
    case 'network equipment':
      return 'wireless-device-filled';
    case 'accessories':
      return 'headphones-filled';
    case undefined:
      return 'activity-band-filled';
    default:
      return defaultIcon;
  }
};

export const getSubscriptionIcon = (subscriptionType?: SubscriptionType, subscriptionSubType?: string): string => {
  switch (subscriptionType) {
    case SubscriptionType.BROADBAND:
    case SubscriptionType.CORPORATE_NETWORK:
    case SubscriptionType.HOUSING_COMPANY_BROADBAND:
      return 'internet-filled';
    case SubscriptionType.LANDLINE:
    case SubscriptionType.MOBILE_PBX_END_USER_SERVICE:
      return 'phone-number-filled';
    case SubscriptionType.MOBILE:
      return 'sim-filled';
    case SubscriptionType.MOBILE_BROADBAND:
      return 'mobile-broadband-filled';
    case SubscriptionType.MOBILE_M2M:
      return 'mobile-m2m-filled';
    case SubscriptionType.MOBILE_PBX:
      return 'ring-filled';
    case SubscriptionType.SPECIAL_NUMBER:
      return 'specialnumber';
    case SubscriptionType.MOBILE_CERTIFICATE:
      return 'mobiilivarmenne';
    case SubscriptionType.DEVICE:
      return getDeviceIcon(subscriptionSubType);
    default:
      return defaultIcon;
  }
};
