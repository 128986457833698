import { ShowAllAccountsToggle } from './ShowAllAccountsToggle.js';
import { TableUrlParams } from '../Table/index.js';
import { isSearchAllAccounts, setIsSearchAllAccounts } from '../../selfservice/common/localStorageUtils.js';
import { t } from '../../common/i18n/index.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import type { ChangeEvent } from 'react';

export const ShowAllAccountsToggleForListViews = ({ className }: { className?: string }) => {
  const { hash, search, pathname } = useLocation();
  const navigate = useNavigate();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  const handleSearchAllAccountsChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const showAllAccounts = event.currentTarget.checked;
    setIsSearchAllAccounts(showAllAccounts);
    queryParams.set(TableUrlParams.PAGE, '1');
    queryParams.set(TableUrlParams.OFFSET, '0');
    navigate(`${pathname}?${queryParams}${hash}`, { replace: true });
  };

  return (
    <ShowAllAccountsToggle
      className={className}
      handleSearchAllAccountsChange={handleSearchAllAccountsChange}
      isSearchAllAccounts={isSearchAllAccounts()}
      message={t.A0OR('Show all companies')}
      tooltipText={t.A00S('You can see results from all the companies you have admin rights.')}
    />
  );
};
