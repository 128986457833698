import * as CL from '@design-system/component-library';
import { ToggleCheckbox } from '../ToggleCheckbox/ToggleCheckbox.js';
import { activationFeeMsg, startingAtMsg, sumPerMonthMsg, t } from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { formatSum } from '../../common/utils/priceUtils.js';
import type { OnlineModel } from '../../generated/api/models.js';
import type { YritystuplaOfferSelection } from '../FixedBroadbandCheckout/FixedBroadbandCheckout.js';

import './YritysTuplaSelection.scss';

interface YritystuplaSelectionProps {
  model: OnlineModel;
  yritystuplaOffer: YritystuplaOfferSelection;
  onChange: (selection: YritystuplaOfferSelection) => void;
}

export const YritystuplaSelection = ({ model, onChange, yritystuplaOffer }: YritystuplaSelectionProps) => {
  const { enabled, selectedOffer } = yritystuplaOffer;
  const sortedOffers = model.offers
    .filter(offer => offer?.commercialProducts[0].price?.effectivePrice.monthlyRecurringCharge !== undefined)
    .sort(
      (a, b) =>
        // The filter above checks that the prices truly exist here
        b.commercialProducts[0].price!.effectivePrice.monthlyRecurringCharge! -
        a.commercialProducts[0].price!.effectivePrice.monthlyRecurringCharge!
    );
  const cheapestPrice =
    sortedOffers[sortedOffers.length - 1]?.commercialProducts[0]?.price?.effectivePrice?.monthlyRecurringCharge;

  const priceInfo = {
    value: `${t.OD1L(startingAtMsg)} ${t.YO7F(sumPerMonthMsg, formatSum(cheapestPrice) || '')}`,
  };

  const onOfferChange = (newOfferCode: string) => {
    const offer = sortedOffers.find(o => o.offerCode === newOfferCode);
    onChange({ enabled: true, selectedOffer: offer });
  };

  return (
    <ToggleCheckbox
      title={t.LKVJ('Order mobile broadband together with Netti Lite.')}
      initialValue={enabled}
      additionalInformation={t.ZCA8(
        'Discount up to 67% off the regular price, no commitment period. See all discounted speed options'
      )}
      onChange={(checked: boolean) => {
        onChange({ enabled: checked, selectedOffer: selectedOffer || sortedOffers[0] });
      }}
      priceInfo={priceInfo}
    >
      <div className="of-fixed-bb-yritystupla__price">
        <CL.RadioGroup
          onChange={onOfferChange}
          value={selectedOffer?.offerCode || sortedOffers[0].offerCode}
          disabled={!enabled}
        >
          {sortedOffers.map(offer => (
            <CL.RadioV2 className="of-fixed-bb-yritystupla__radio" key={offer.offerCode} value={offer.offerCode}>
              <div
                className={`${dsClass.DISPLAY_FLEX} ${dsClass.JUSTIFY_CONTENT_SPACE_BETWEEN} of-fixed-bb-yritystupla__radio-label`}
              >
                <div>
                  <div>{offer.offerName}</div>
                  <div className={dsClass.TEXT_XS}>
                    {`${t.HWDR(activationFeeMsg)} ${formatSum(
                      offer.commercialProducts[0].price?.effectivePrice.oneTimeCharge
                    )}`}
                  </div>
                </div>
                <div className={dsClass.PADDING_LEFT_2}>
                  {t.YO7F(
                    sumPerMonthMsg,
                    formatSum(offer.commercialProducts[0].price?.effectivePrice.monthlyRecurringCharge)!
                  )}
                </div>
              </div>
            </CL.RadioV2>
          ))}
        </CL.RadioGroup>
      </div>
    </ToggleCheckbox>
  );
};
