import { TypeKeys } from '../actions/index.js';
import { isInBrowser } from '../../common/utils/ssrUtils.js';
import type { Action } from 'redux';
import type {
  RegisterUserErrorFulfilledAction,
  RegisterUserFulfilledAction,
  SelfServiceActionTypes,
} from '../actions/index.js';

const acceptedTerms = new RegExp(['FULFILLED', 'ANALYTICS_EVENT', 'WIZARD_EVENT', 'SET_WIZARD_PHASE'].join('|'));
const excludedTerms = new RegExp(
  ['FAILED', 'ADD_SUPPORT_CASE_FULFILLED', 'ADD_ANONYMOUS_SUPPORT_CASE_FULFILLED'].join('|')
);
const whiteListedActions = [TypeKeys.LOG_IN_FAILED];

export interface AnalyticsDataLayerEvent {
  event: string; // "online-ui" if you're passing along redux events, depends on case if you're not.
  eventAction: string; //  e.g. 'SEARCH_PUBLIC_PAGES_FULFILLED',
  eventCategory: string; // "redux" if it's an online-ui event,
  eventData?: object; // Object associated with the event
  eventLabel?: string; //  whitelisted payload, preferably single string, if many then preferably CSV
  eventNonInteraction: 0 | 1; // 1, unless event is caused by interaction and doesn't already have an interaction event.
  userId?: string; // hash of userName, passed from backend. Analytics requires key to be userId.
  eventValue?: number; // if event has some numerical data related
  formType?: string; // Used to identify type of form that's sent
  target?: string;
}

const getAnalyticsDataLayerDefaultEvent = (action: SelfServiceActionTypes): AnalyticsDataLayerEvent => {
  return {
    event: 'online-ui',
    eventCategory: 'redux',
    eventAction: action.type,
    eventNonInteraction: 1,
    eventLabel: '',
  };
};

const getAnalyticsDataLayerRegistrationEvent = (
  action: RegisterUserErrorFulfilledAction | RegisterUserFulfilledAction
): Partial<AnalyticsDataLayerEvent> => {
  const eventBase = {
    event: 'interaction',
    eventCategory: 'Interactions',
    action: 'registration',
  };
  if (action.type === TypeKeys.REGISTER_USER_ERROR_FULFILLED) {
    return { ...eventBase, target: 'failure' };
  }
  return { ...eventBase, target: action.newUser ? 'success' : 'PasswordChange' };
};

const sanitizeQuery = (query: string) => {
  if (query.length > 50) {
    // Hide novels, but still send something so that we can measure the users' propensity for rambling
    return '[ANON TOO LONG]';
  } else {
    // Hide possible emails and phone numbers
    return query
      .replace(/\b[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+\b/g, '[ANON EMAIL]')
      .replace(/(\d[\s]*){6,}/g, '[ANON NUMBER]');
  }
};

export const getAnalyticsWizardStepEvent = (step: number, wizardName: string) => ({
  event: 'enter_step_in_wizard',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  step_number: step,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  wizard_name: wizardName,
});

// Main filter for which actions are sent to GTM, and processed via getAnalyticsDataLayerEvent below
export const isAnalyticsDataLayerAction = (action: Action) => {
  return (
    isInBrowser() &&
    (whiteListedActions.includes(action.type) || (acceptedTerms.test(action.type) && !excludedTerms.test(action.type)))
  );
};

// Whitelisting of data sent to GTM. Add here your own case, if you need more detailed data of some redux action
// go to analytics via eventValue and/or eventLabel.
export const getAnalyticsDataLayerEvent = (action: SelfServiceActionTypes): Partial<AnalyticsDataLayerEvent> => {
  const defaultEvent = getAnalyticsDataLayerDefaultEvent(action);

  switch (action.type) {
    case TypeKeys.ADD_ANONYMOUS_LEAD_FULFILLED:
      return {
        ...defaultEvent,
        formType: action.formType,
      };
    case TypeKeys.APPLY_ANALYTICS_EVENT:
      return {
        ...defaultEvent,
        eventNonInteraction: 0,
        eventData: action.eventData,
      };
    case TypeKeys.LOG_IN_FULFILLED:
      return {
        ...defaultEvent,
        userId: action.authenticationResult.user.userNameHash,
      };
    case TypeKeys.REGISTER_USER_ERROR_FULFILLED:
    case TypeKeys.REGISTER_USER_FULFILLED:
      return getAnalyticsDataLayerRegistrationEvent(action);
    case TypeKeys.SEARCH_PUBLIC_PAGES_FULFILLED:
      return {
        ...defaultEvent,
        eventValue: action.result.total,
        eventLabel: sanitizeQuery(action.query),
      };
    case TypeKeys.APPLY_WIZARD_EVENT:
      return getAnalyticsWizardStepEvent(action.step, action.name);
    case TypeKeys.GET_CAMPAIGNS_FULFILLED:
      return {
        ...defaultEvent,
        eventLabel: action.params.voucher,
      };
    case TypeKeys.UPSERT_BILLING_ACCOUNT_FULFILLED:
      return {
        ...defaultEvent,
        eventLabel: action.editedSection ? `BILLING_ACCOUNT_${action.editedSection}` : undefined,
      };
    default:
      return defaultEvent;
  }
};
