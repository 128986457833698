import { DialogType } from '../../../common/enums.js';
import { DialogWrapper } from '../../DialogWrapper/index.js';
import { authValidate, closeDialog } from '../../../selfservice/actions/index.js';
import { deepEqual } from '../../../common/utils/objectUtils.js';
import { useDispatch, useSelector } from 'react-redux';
import type { State } from '../../../selfservice/common/store.js';

export const PunchoutDialog = () => {
  const dispatch = useDispatch();
  const dialog = useSelector((state: State) => state.dialog, deepEqual);
  const onCloseDialog = () => dispatch(closeDialog());

  if (!dialog) {
    return null;
  }

  if (dialog?.params?.type) {
    switch (dialog.params.type) {
      case DialogType.DUPLICATE_CONTACT:
        return (
          <DialogWrapper
            buttons={[
              {
                onClick: () => {
                  onCloseDialog();
                  dispatch(authValidate());
                },
                text: 'OK',
              },
            ]}
            onCloseDialog={onCloseDialog}
            header={dialog.params.header}
          >
            <p>{dialog.params.body}</p>
          </DialogWrapper>
        );
    }
  }
  return null;
};
