import { EMAIL_REGEX } from '../../utils/emailUtils.js';
import { FINNISH_PHONE_NUMBER_REGEX } from '../../utils/phoneNumberUtils.js';
import { InputComponent } from '../components/InputComponent.js';
import { fieldCantBeEmptyMsg, invalidValueMsg, t } from '../../../common/i18n/index.js';
import type { OptionalInputComponentProps } from '../components/InputComponent.js';

export const ContactMethod = ({
  name = 'contactMethod',
  label = t.KSRZ('Email or mobile phone number'),
  placeholder = t.KSRZ('Email or mobile phone number'),
  required = true,
  disabled = false,
}: OptionalInputComponentProps) => {
  const validate = (value: string) => {
    if (!value) {
      if (required) {
        return t.VPVR(fieldCantBeEmptyMsg);
      }
    } else if (!(EMAIL_REGEX.test(value) || FINNISH_PHONE_NUMBER_REGEX.test(value))) {
      return t.DUPA(invalidValueMsg);
    }
    return undefined;
  };

  return (
    <InputComponent
      validate={validate}
      name={name}
      type="text"
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      required={required}
    />
  );
};
