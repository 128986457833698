import type { ContactHeader } from '../../../generated/api/contactHeader.js';

export const OpenFormComboboxContact = (c: ContactHeader) => {
  const label = `${c.firstName} ${c.lastName}`;
  return {
    id: c.contactId!,
    value: c.contactId!,
    label: label,
    html: (
      <div>
        <div>{label}</div>
        <div>{[c.email, c.phoneNumber].filter(Boolean).join(' | ')}</div>
      </div>
    ),
  };
};
