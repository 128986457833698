import { BreadCrumbs } from '../../../../../../components/BreadCrumbs/index.js';
import { Loading } from '../../../../../../components/Loading/index.js';
import { OrderSim } from '../../../../../../components/OrderSim/OrderSim.js';
import { SimType } from '../../../../../../generated/api/models.js';
import { deepEqual } from '../../../../../../common/utils/objectUtils.js';
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getSubCategoryFromPath } from '../../../../../../common/utils/employeeUtils.js';
import { getSubscriptionStatus } from '../../../../../../common/utils/subscriptionUtils.js';
import { omaElisaForEmployeeMsg, orderSimMsg, t, voiceSubscriptionsMsg } from '../../../../../../common/i18n/index.js';
import { parseMobileSubscriptionAttrs } from '../../../../../../components/EmployeeSubscriptionDetails/employeeSubscriptionUtils.js';
import { paths } from '../../../../../../common/constants/pathVariables.js';
import { useAuth } from '../../../../AuthProvider.js';
import { useSelector } from 'react-redux';
import { useTitle } from '../../../../../../common/hooks/useTitle.js';
import type { State } from '../../../../../../selfservice/common/store.js';
import type { SubscriptionId } from '../../../../../../common/constants/pathInterfaces.js';

export const VoiceSubOrderSimPath = ({ isEmployee }: { isEmployee: boolean }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const subCategoryFromPath = getSubCategoryFromPath(pathname);
  const { subscriptionId = '' } = useParams<SubscriptionId>();
  const { authenticatedUser } = useAuth();
  const subscription = useSelector((state: State) =>
    state?.user?.authenticated?.subscriptions?.voice?.find(sub => sub.subscriptionId === subscriptionId)
  );
  const { selfService, companyInfo } = useSelector(
    (state: State) => ({
      selfService: state?.selfservice,
      companyInfo: state?.selfservice?.companyInfo,
    }),
    deepEqual
  );

  const mobileNumber: string = subscription ? parseMobileSubscriptionAttrs(subscription).mobileNumber : '';

  const breadCrumbs: JSX.Element = (
    <BreadCrumbs
      breadCrumbPaths={[
        { name: t.H6JV(omaElisaForEmployeeMsg), path: paths.EMPLOYEE_HOME },
        { name: t.AR0B(voiceSubscriptionsMsg), path: paths.EMPLOYEE_VOICE_SUBSCRIPTIONS },
        { name: mobileNumber, path: generatePath(paths.EMPLOYEE_VOICE_SUBSCRIPTION, { subscriptionId }) },
        { name: t.CVON(orderSimMsg) },
      ]}
    />
  );

  useTitle([
    t.H6JV(omaElisaForEmployeeMsg),
    t.AR0B(voiceSubscriptionsMsg),
    subscription?.subscriptionDisplayId || subscription?.subscriptionId || '',
    t.CVON(orderSimMsg),
  ]);

  if (!(subscription && companyInfo && companyInfo.disallowBillableSimChanges !== undefined)) {
    return <Loading />;
  }

  return (
    <div className="of-employee-subscriptions-scene">
      <OrderSim
        breadCrumbs={breadCrumbs}
        category={subCategoryFromPath}
        disallowBillableSimChanges={companyInfo.disallowBillableSimChanges}
        pendingSubscriptionChanges={
          getSubscriptionStatus(subscription, selfService?.pendingSubscriptionActions?.items || []).pendingActions
        }
        simTypeInitialSelection={SimType.ESIM}
        subscription={subscription}
        companyInfo={companyInfo}
        user={authenticatedUser}
        simChangeServiceFee={
          selfService?.subscriptions?.voice?.serviceFees?.find(
            serviceFee => serviceFee.subscriptionDisplayId === subscription?.subscriptionDisplayId
          )?.price
        }
        onClickSubscription={() => navigate(generatePath(paths.EMPLOYEE_VOICE_SUBSCRIPTION, { subscriptionId }))}
        isEmployee={isEmployee}
      />
    </div>
  );
};
