import { isInBrowser } from '../utils/ssrUtils.js';
import { useState } from 'react';
import { useStore } from 'react-redux';
import elisaIdClient from '@elisa-luukku-aspa/elisaid-js-client/dist/elisaid-client.min.js';
import type { State } from '../../selfservice/common/store.js';

const mapElisaIdConfigToClient = ({ clientId, url }: { clientId: string; url: string }) => {
  return { clientId, language: 'fi', ssoUrl: url };
};

export const useElisaIdClient = () => {
  const elisaId = useStore<State>().getState().config.elisaId;
  const [client] = useState(isInBrowser() ? elisaIdClient(mapElisaIdConfigToClient(elisaId!)) : undefined);
  return client;
};
