export class Esim {
  private readonly fullActivationCode: string;

  private readonly protocol: string; // Not sure if it's really a protocol or what..
  private readonly acFormat: string;
  private readonly smdp: string;
  private readonly acToken: string;

  constructor(eSimActivationCode: string) {
    this.fullActivationCode = eSimActivationCode;

    const colon = eSimActivationCode.indexOf(':');
    this.protocol = eSimActivationCode.slice(0, colon);

    const acFormatEnds = eSimActivationCode.indexOf('$', colon + 1);
    this.acFormat = eSimActivationCode.slice(colon + 1, acFormatEnds);

    const smdpEnds = eSimActivationCode.indexOf('$', acFormatEnds + 1);
    this.smdp = eSimActivationCode.slice(acFormatEnds + 1, smdpEnds);

    // There might not be ending '$'-sign, in that case take all the rest of the string
    const acTokenEnds = eSimActivationCode.indexOf('$', smdpEnds + 1);
    this.acToken =
      acTokenEnds === -1 ? eSimActivationCode.slice(smdpEnds + 1) : eSimActivationCode.slice(smdpEnds + 1, acTokenEnds);
  }

  getAcToken(): string {
    return this.acToken;
  }

  getQrCodeUrl(): string {
    if (this.fullActivationCode) {
      return 'https://qr-code.elisa.fi/?data=' + this.fullActivationCode + '&size=150';
    } else {
      return '';
    }
  }

  getSmdpAddress(): string {
    if (this.protocol && this.acFormat && this.smdp) {
      return this.protocol + ':' + this.acFormat + '$' + this.smdp;
    } else {
      return '';
    }
  }
}
