import * as CL from '@design-system/component-library';
import { ModalButtons } from '../Modal/ModalButtons.js';
import { cancelMsg, t } from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { paths } from '../../common/constants/pathVariables.js';
import type { ForgotPasswordProps } from './misc/forgotPasswordInterfaces.js';

import './ForgotPassword.scss';

export const ForgotPasswordInfoStep = (props: ForgotPasswordProps) => {
  return (
    <>
      <div className="of-forgot-password-step__header">
        <h3 className={dsClass.H3_BOOK}>{t.SCZB('Forgotten or expired username')}</h3>
      </div>

      <p>
        {`${t.E90X('You can restore your username on')} `}
        <a href={paths.REGISTER_PAGE}>{t.P4VL('the registration page')}</a>
        {`, ${t.Q2NF('if you have the right to sign your company.')}`}
      </p>

      <p>
        {t.G9X8(
          `If you do not have the right to sign, your company's OmaElisa administrator can return your ID to OmaElisa.`
        )}
      </p>

      <p>
        <b>{t.J083('Note.')}</b>{' '}
        {` ${t.Q6TK(
          'Due to security reasons, we must automatically delete OmaElisa credentials which have not been used during the past 12 months. If you have not logged in to OmaElisa during past 12 months, you have to create new credentials.'
        )} `}
        <a href={paths.REGISTER_PAGE}>{t.P4VL('the registration page')}</a>.
      </p>

      <ModalButtons>
        <CL.Button color="link" id="of-forgot-password-cancel" onClick={props.closeModal} size="l">
          {t.B2V1(cancelMsg)}
        </CL.Button>
        <CL.Link
          buttonColor="primary"
          buttonSize="l"
          id="of-forgot-password-register"
          linkHref={paths.REGISTER_PAGE}
          linkStyle="link-button"
        >
          {t.G83G('Go to registration page')}
        </CL.Link>
      </ModalButtons>
    </>
  );
};
