import * as CL from '@design-system/component-library';
import { CATEGORY_URL } from '../../common/utils/categoryUtils.js';
import { CommonErrorType, SubscriptionCategory } from '../../common/enums.js';
import { CompanySearch, PhoneNumber } from '../../common/react-hook-form/fields/index.js';
import { FormProvider, useForm } from 'react-hook-form';
import { SubscriptionPairingRequest, ValidateSubscriptionPairingResponse } from '../../generated/api/models.js';
import { addEmptyFieldValidationError } from '../../common/utils/errorUtils.js';
import {
  addYourDeviceMsg,
  addYourSubscriptionMsg,
  fieldCantBeEmptyMsg,
  pairingInfoMsg,
  t,
} from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { generatePath, useNavigate } from 'react-router-dom';
import { paths } from '../../common/constants/pathVariables.js';
import { processSubscriptionPairing, resetCompanySearchResult } from '../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import type { CategoryKey } from '../../common/utils/categoryUtils.js';
import type { CommonError } from '../../common/types/errors.js';

type EmployeeSubscriptionPairingSceneProps = {
  category: string;
  mdmId: string;
};

export function getErrorsFromEmployeeSubscriptionPairingScene(
  data: SubscriptionPairingRequest,
  validationErrors?: CommonError[]
): CommonError[] | undefined {
  const errors: CommonError[] = [];
  if (data && data.msisdn === '') {
    addEmptyFieldValidationError(errors, 'msisdn', t.VPVR(fieldCantBeEmptyMsg));
  }
  if (data && data.subscriptionType === SubscriptionPairingRequest.SubscriptionTypeEnum.OTHER && !data.mdmId) {
    addEmptyFieldValidationError(errors, 'mdmId');
  }
  if (errors.length === 0) {
    return validationErrors;
  }
  return errors.concat(validationErrors || []);
}

export const getErrorsFromValidateEmployeeSubscriptionResponse = (
  response: ValidateSubscriptionPairingResponse
): CommonError[] | undefined => {
  const responseStatus = response && response.status;
  if (responseStatus === ValidateSubscriptionPairingResponse.StatusEnum.SUBSCRIPTION_NOT_FOUND) {
    return [
      {
        displayText: [
          t.S81T('Cannot pair subscription'),
          t.QNVB('This mobile number is not owned by a Elisa Corporate customer.'),
        ],
        message: t.W4RA('issue in subscription pairing'),
        type: CommonErrorType.NOT_FOUND,
      },
    ];
  } else if (responseStatus === ValidateSubscriptionPairingResponse.StatusEnum.BUSINESS_ID_MISMATCH) {
    return [
      {
        displayText: [
          t.S81T('Cannot pair subscription'),
          t.KG04('This mobile number is owned by another organisation.'),
        ],
        message: t.W4RA('issue in subscription pairing'),
        type: CommonErrorType.NOT_FOUND,
      },
    ];
  } else if (responseStatus === ValidateSubscriptionPairingResponse.StatusEnum.INVALID_OTP) {
    return [{ message: t.FI1N('Invalid verification code'), type: CommonErrorType.VALIDATION }];
  } else {
    return undefined;
  }
};

const getPairingOptionsForCategory = (
  category: string
): {
  header: string;
  body: string;
  subscriptionType: SubscriptionPairingRequest.SubscriptionTypeEnum;
} => {
  switch (category) {
    case SubscriptionCategory.VOICE:
      return {
        body: t.UT8Z(pairingInfoMsg),
        header: t.P35B(addYourSubscriptionMsg),
        subscriptionType: SubscriptionPairingRequest.SubscriptionTypeEnum.MOBILE,
      };

    case SubscriptionCategory.BROADBAND:
      return {
        body: t.UT8Z(pairingInfoMsg),
        header: t.P35B(addYourSubscriptionMsg),
        subscriptionType: SubscriptionPairingRequest.SubscriptionTypeEnum.OTHER,
      };

    case SubscriptionCategory.DEVICE:
      return {
        body: t.UT8Z(pairingInfoMsg),
        header: t.SQW6(addYourDeviceMsg),
        subscriptionType: SubscriptionPairingRequest.SubscriptionTypeEnum.OTHER,
      };

    default:
      return {
        body: '',
        header: '',
        subscriptionType: SubscriptionPairingRequest.SubscriptionTypeEnum.OTHER,
      };
  }
};

interface PairingSceneFormSubmitProps {
  mdmId: string;
  phoneNumber: string;
  subscriptionType: SubscriptionPairingRequest.SubscriptionTypeEnum;
}

interface PairingSceneCompanyAndPhoneFormProps {
  mdmId?: string;
  subscriptionType: SubscriptionPairingRequest.SubscriptionTypeEnum;
  onSubmitForm: (data: PairingSceneFormSubmitProps) => void;
}

const PairingSceneCompanyAndPhoneForm = ({
  mdmId,
  subscriptionType,
  onSubmitForm,
}: PairingSceneCompanyAndPhoneFormProps) => {
  const dispatch = useDispatch();
  const isMdmId = !(subscriptionType === SubscriptionPairingRequest.SubscriptionTypeEnum.OTHER && !mdmId);
  const methods = useForm({ mode: 'all', defaultValues: { masterId: '', phoneNumber: '' } });
  const onSubmit = ({ masterId, phoneNumber }: { masterId: string; phoneNumber: string }) => {
    const id = isMdmId ? mdmId : masterId;
    dispatch(processSubscriptionPairing({ mdmId: id, msisdn: phoneNumber, subscriptionType }));
    onSubmitForm({ mdmId: id || '', phoneNumber, subscriptionType });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        {!isMdmId && <CompanySearch />}
        <PhoneNumber />
        <CL.Button block className={dsClass.MARGIN_TOP_6} size="m" type="submit">
          {t.F0MY('Next')}
        </CL.Button>
      </form>
    </FormProvider>
  );
};

const EmployeeSubscriptionPairingSceneContent = ({
  category,
  mdmId,
}: EmployeeSubscriptionPairingSceneProps): JSX.Element => {
  const navigate = useNavigate();
  const pairingOptions = getPairingOptionsForCategory(category);

  const onSubmit = (data: PairingSceneFormSubmitProps) => {
    navigate(
      generatePath(paths.EMPLOYEE_SUBSCRIPTIONS_VERIFY_ADD, { categoryUrl: CATEGORY_URL[category as CategoryKey] }),
      {
        state: {
          params: { mdmId: data.mdmId, mobileNumber: data.phoneNumber, subscriptionType: data.subscriptionType },
          prevPath: location.pathname,
        },
      }
    );
  };

  return (
    <PairingSceneCompanyAndPhoneForm
      mdmId={mdmId}
      subscriptionType={pairingOptions.subscriptionType}
      onSubmitForm={onSubmit}
    />
  );
};

export const EmployeeSubscriptionPairingScene = ({ category, mdmId }: EmployeeSubscriptionPairingSceneProps) => {
  const dispatch = useDispatch();
  const pairingOptions = getPairingOptionsForCategory(category);

  useEffect(() => {
    dispatch(resetCompanySearchResult());
  }, [dispatch]);

  return (
    <div className="of-employee__form-container">
      <div className={`${dsClass.MARGIN_HORIZONTAL_3} ${dsClass.MARGIN_TOP_3}`}>
        <h3>{pairingOptions.header}</h3>
        <p>{pairingOptions.body}</p>
        <EmployeeSubscriptionPairingSceneContent mdmId={mdmId} category={category} />
      </div>
    </div>
  );
};
