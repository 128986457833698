/**
 * SFDC Community API
 * # Summary Elisa B2B API for an administrator of a company to query and modify the company information.  # Usage This API is used in the `https://yrityksille.elisa.fi/omaelisa` pages, but it is possible to later on expose this documentation publicly and make the endpoint queryable via external clients.  # Identifiers All entities in the API have a globally unique, internal identifier field named with the format [object_type]Id, e.g. `invoiceId`, which is also used in some of the API endpoint urls, e.g. `/api/v1/selfservice/invoices/{invoiceId}`. This value is not meaningful to the end user but is only used as an internally in the API.  Most entities also have a displayable identifier named in the format [object_type]DisplayId. This value can and should be shown to the end user, as it is also printed in relevant documents. It is also used in some of the public URLs, e.g. `https://yrityksille.elisa.fi/omaelisa/laskut/{invoiceDisplayId}`. Because of the user might land directly into a URL of a single entity with a display id, some of the API endpoints here also support querying via the display id, e.g. `/api/v1/selfservice/invoices?invoiceDisplayId={invoiceDisplayId}`.  The reason for using two identifiers instead of only one display id, is to make sure that every object will always have exactly one REST API URL that will never collide with another. While collisions of display ids should not happen in production, the id fields are guaranteed to be unique even across different test environments. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DeliveryOrderDetails } from './deliveryOrderDetails.js';


export interface DeliveryOrder { 
    /**
     * Internal, unique id of the delivery order. SFDC: csord__Order__c.Id
     */
    deliveryOrderId: string;
    /**
     * Id of the delivery order as shown to the user in the URL. SFDC: csord__Order__c.Order_Number_Elisa__c
     */
    deliveryOrderDisplayId: string;
    /**
     * Internal, unique id of the old subscription for which the device change order has been placed. This id is only present in case of device change orders. SFDC: csord__Subscription__c.Id
     */
    replacedSubscriptionId?: string;
    /**
     * Delivery order status. Internal statuses \'submitted\',\'provisioned\' and \'error\' would be mapped to IN_PROGRESS. SFDC: csord__Order.Status__c 
     */
    status: DeliveryOrder.StatusEnum;
    /**
     * Name shown to the user of this delivery order. SFDC: csord__Order__c.Name
     */
    deliveryOrderName: string;
    /**
     * Sum of one-time charges for the delivery order in cents. This price will be calculated based on discount applied to delivery order. SFDC: csord_Subscription.Final_Service_One_Off_Charges__c.
     */
    oneTimeCharge?: number;
    /**
     * One-time list price for the delivery order in cents. This price will be without any discount. SFDC: csord__Order.Total_Service_One_Off_Charges__c.
     */
    oneTimeListCharge?: number;
    /**
     * Sum of monthly recurring charges for the delivery order in cents. This price will be calculated based on discount applied to order. SFDC: csord_Subscription.Final_Service_Recurring_Charges__c.
     */
    monthlyRecurringCharge?: number;
    /**
     * Recurring list price of the delivery order in cents. This price will be without any discount. SFDC: csord__Order.Total_Service_Recurring_Charges__c.
     */
    monthlyRecurringListCharge?: number;
    /**
     * Billing period in months. SFDC: csord__Order.?
     */
    billingPeriod?: number;
    /**
     * Name of the person who placed the delivery order. SFDC: csord__Order.Ordering_Contact_Person_Name__c
     */
    deliveryOrderContact?: string;
    /**
     * Unix timestamp when the delivery order was placed. SFDC: csord__Order.csord__Order_Date__c.
     */
    deliveryOrderCreated: number;
    /**
     * Unix timestamp when the delivery order was actually delivered. SFDC: csord__order__c.Comptel_Actual_Provision_Date__c.
     */
    deliveryDate?: number;
    /**
     * Type of the delivered product or service. DELIVERY_FEE is a special case, it has no other details than the price. SFDC: csord__Order.csord__Product_type__c.
     */
    productType: DeliveryOrder.ProductTypeEnum;
    /**
     * Internal SFDC ID of user contact for this delivery order. SFDC: csord__Service__c.User_Contact__c.
     */
    userContactId?: string;
    /**
     * Displayable name of user contact for this delivery order. SFDC: csord__Service__c.User_Contact__c -> Contact.Name
     */
    userContactName?: string;
    /**
     * Textual description from user, how this product/subscription is used. SFDC: csord__Order.Purpose_of_Use__c.
     */
    purposeOfUse?: string;
    /**
     * True if mentioned SFDC field\'s value is \'EPP Device\'. SFDC: cspmb__Price_Item__c.Sub_Type__c.
     */
    eppOrder?: boolean;
    /**
     * Total employee monthly recurring charge for the delivery order in cents. This is applicable in case of OmaLaiteLasku only. Found via csord__Order__c. SFDC: csord__Service_Line_Item__c.csord__Total_Price__c
     */
    employeeMonthlyRecurringCharge?: number;
    details?: DeliveryOrderDetails;
    /**
     * Cost centre for this delivery order. SFDC: csord__Service__c.Root_Service__c.Subscription_Cost_Center__c
     */
    costCenter?: string;
    /**
     * Subscription reference number for this delivery order. SFDC: csord__Service__c.Root_Service__c.Subscription_Reference__c
     */
    subscriptionReference?: string;
    /**
     * Unix timestamp of last modification. SFDC: csord__Order__c.LastModifiedDate
     */
    lastModified: number;
    /**
     * Display ID (SUBnnnnnnnnn) of the subscription replacing this new device subscription. SFDC: csord__Subscription__c.csordtelcoa_Subscription_Number__c
     */
    replacedSubscriptionDisplayId?: string;
}
export namespace DeliveryOrder {
    export type StatusEnum = 'PENDING' | 'IN_PROGRESS' | 'COMPLETED' | 'CANCELLED' | 'SUBMITTED_WAITING_FOR_STOCK';
    export const StatusEnum = {
        PENDING: 'PENDING' as StatusEnum,
        IN_PROGRESS: 'IN_PROGRESS' as StatusEnum,
        COMPLETED: 'COMPLETED' as StatusEnum,
        CANCELLED: 'CANCELLED' as StatusEnum,
        SUBMITTED_WAITING_FOR_STOCK: 'SUBMITTED_WAITING_FOR_STOCK' as StatusEnum
    };
    export type ProductTypeEnum = 'BROADBAND' | 'CABLE_TV' | 'CORPORATE_NETWORK' | 'DELIVERY_FEE' | 'DEVICE' | 'E_FAX' | 'FIREWALL' | 'HOUSING_COMPANY_BROADBAND' | 'LAN' | 'LANDLINE' | 'MOBILE' | 'MOBILE_PBX' | 'NEGOTIATION' | 'SOFTWARE_PRODUCT' | 'SPECIAL_NUMBER' | 'MOBILE_PBX_SOLUTION';
    export const ProductTypeEnum = {
        BROADBAND: 'BROADBAND' as ProductTypeEnum,
        CABLE_TV: 'CABLE_TV' as ProductTypeEnum,
        CORPORATE_NETWORK: 'CORPORATE_NETWORK' as ProductTypeEnum,
        DELIVERY_FEE: 'DELIVERY_FEE' as ProductTypeEnum,
        DEVICE: 'DEVICE' as ProductTypeEnum,
        E_FAX: 'E_FAX' as ProductTypeEnum,
        FIREWALL: 'FIREWALL' as ProductTypeEnum,
        HOUSING_COMPANY_BROADBAND: 'HOUSING_COMPANY_BROADBAND' as ProductTypeEnum,
        LAN: 'LAN' as ProductTypeEnum,
        LANDLINE: 'LANDLINE' as ProductTypeEnum,
        MOBILE: 'MOBILE' as ProductTypeEnum,
        MOBILE_PBX: 'MOBILE_PBX' as ProductTypeEnum,
        NEGOTIATION: 'NEGOTIATION' as ProductTypeEnum,
        SOFTWARE_PRODUCT: 'SOFTWARE_PRODUCT' as ProductTypeEnum,
        SPECIAL_NUMBER: 'SPECIAL_NUMBER' as ProductTypeEnum,
        MOBILE_PBX_SOLUTION: 'MOBILE_PBX_SOLUTION' as ProductTypeEnum
    };
}


