/**
 * Online UI API
 * # Summary Elisa B2B Online UI API  # Usage This API is used in the `https://yrityksille.elisa.fi/` pages.  # Identifiers 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OmalaskuAddress } from './omalaskuAddress.js';


/**
 * response status of oma lasku
 */
export interface OmalaskuStatusResponse { 
    /**
     * barring levels on the subscription
     */
    speechBarrings: Array<OmalaskuStatusResponse.SpeechBarringsEnum>;
    /**
     * Current status of omalasku on the subscription
     */
    omaLaskuStatus: OmalaskuStatusResponse.OmaLaskuStatusEnum;
    address: OmalaskuAddress;
}
export namespace OmalaskuStatusResponse {
    export type SpeechBarringsEnum = 'P1' | 'P2' | 'P3' | 'P4' | 'U' | 'R' | 'NONE';
    export const SpeechBarringsEnum = {
        P1: 'P1' as SpeechBarringsEnum,
        P2: 'P2' as SpeechBarringsEnum,
        P3: 'P3' as SpeechBarringsEnum,
        P4: 'P4' as SpeechBarringsEnum,
        U: 'U' as SpeechBarringsEnum,
        R: 'R' as SpeechBarringsEnum,
        NONE: 'NONE' as SpeechBarringsEnum
    };
    export type OmaLaskuStatusEnum = 'Active' | 'Inactive' | 'Activation-Inprogress' | 'Cancellation-Inprogress';
    export const OmaLaskuStatusEnum = {
        Active: 'Active' as OmaLaskuStatusEnum,
        Inactive: 'Inactive' as OmaLaskuStatusEnum,
        Activation_Inprogress: 'Activation-Inprogress' as OmaLaskuStatusEnum,
        Cancellation_Inprogress: 'Cancellation-Inprogress' as OmaLaskuStatusEnum
    };
}


