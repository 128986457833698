import { TypeKeys, generateActionId } from './actionsUtils.js';
import { convertToCommonErrors } from '../../common/utils/errorUtils.js';
import type { AjaxResponse } from 'rxjs/ajax';
import type {
  AuthenticationResult,
  GenericError,
  SubscriptionPairingRequest,
  ValidateSubscriptionPairingRequest,
  ValidateSubscriptionPairingResponse,
} from '../../generated/api/models.js';
import type { CommonError } from '../../common/types/errors.js';
import type { CrudAction, ErrorAction } from './actionsUtils.js';
import type { ElisaIdClientAttrs } from '../../common/utils/elisaIdUtils.js';

// Process ELisa ID Login
export interface ProcessElisaIdLoginAction extends CrudAction<TypeKeys.PROCESS_ELISAID_LOGIN> {
  payload: string;
}

export interface ProcessElisaIdLoginFulfilledAction {
  authenticationResult: AuthenticationResult;
  type: TypeKeys.PROCESS_ELISAID_LOGIN_FULFILLED;
}

export type ProcessElisaIdLoginFailedAction = ErrorAction<TypeKeys.PROCESS_ELISAID_LOGIN_FAILED>;

export const processElisaIdLogin = (data: string): ProcessElisaIdLoginAction => ({
  id: generateActionId(),
  payload: data,
  type: TypeKeys.PROCESS_ELISAID_LOGIN,
});

export const processElisaIdLoginFulfilled = (
  authenticationResult: AuthenticationResult
): ProcessElisaIdLoginFulfilledAction => ({
  authenticationResult,
  type: TypeKeys.PROCESS_ELISAID_LOGIN_FULFILLED,
});

export const processElisaIdLoginFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): ProcessElisaIdLoginFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.PROCESS_ELISAID_LOGIN_FAILED,
});

// get Elisa Id Session
export interface GetElisaIdSessionAction extends CrudAction<TypeKeys.GET_ELISA_ID_SESSION> {
  payload: ElisaIdClientAttrs;
}

export interface GetElisaIdSessionFulfilledAction {
  elisaIdClient?: ElisaIdClientAttrs;
  userName: string;
  type: TypeKeys.GET_ELISA_ID_SESSION_FULFILLED;
}

// Employee Subscription Pairing.

export interface ProcessEmployeeSubscriptionPairingAction
  extends CrudAction<TypeKeys.PROCESS_EMPLOYEE_SUBSCRIPTION_PAIRING> {
  payload: SubscriptionPairingRequest;
  validationErrors?: CommonError[];
}

export const processSubscriptionPairing = (
  data: SubscriptionPairingRequest,
  validationErrors?: CommonError[]
): ProcessEmployeeSubscriptionPairingAction => ({
  id: generateActionId(),
  payload: data,
  type: TypeKeys.PROCESS_EMPLOYEE_SUBSCRIPTION_PAIRING,
  validationErrors: validationErrors,
});

export interface ProcessEmployeeSubscriptionPairingFulfilledAction {
  response: AjaxResponse;
  type: TypeKeys.PROCESS_EMPLOYEE_SUBSCRIPTION_PAIRING_FULFILLED;
}

export const processSubscriptionPairingFulfilled = (
  ajaxResponse: AjaxResponse
): ProcessEmployeeSubscriptionPairingFulfilledAction => ({
  response: ajaxResponse,
  type: TypeKeys.PROCESS_EMPLOYEE_SUBSCRIPTION_PAIRING_FULFILLED,
});

export type ProcessEmployeeSubscriptionPairingFailedAction =
  ErrorAction<TypeKeys.PROCESS_EMPLOYEE_SUBSCRIPTION_PAIRING_FAILED>;

export const processSubscriptionPairingFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): ProcessEmployeeSubscriptionPairingFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.PROCESS_EMPLOYEE_SUBSCRIPTION_PAIRING_FAILED,
});

export interface ValidateSubscriptionPairingPayload extends ValidateSubscriptionPairingRequest {
  redirectPath: string;
}

export interface ValidateEmployeeSubscriptionAction
  extends CrudAction<TypeKeys.VALIDATE_EMPLOYEE_SUBSCRIPTION_PAIRING> {
  payload: ValidateSubscriptionPairingPayload;
  validationErrors?: CommonError[];
}

export const validateSubscription = (
  data: ValidateSubscriptionPairingPayload,
  validationErrors?: CommonError[]
): ValidateEmployeeSubscriptionAction => ({
  id: generateActionId(),
  payload: data,
  type: TypeKeys.VALIDATE_EMPLOYEE_SUBSCRIPTION_PAIRING,
  validationErrors: validationErrors,
});

export type ValidateEmployeeSubscriptionFailedAction =
  ErrorAction<TypeKeys.VALIDATE_EMPLOYEE_SUBSCRIPTION_PAIRING_FAILED>;

export const validateSubscriptionFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): ValidateEmployeeSubscriptionFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.VALIDATE_EMPLOYEE_SUBSCRIPTION_PAIRING_FAILED,
});

export interface ValidateEmployeeSubscriptionFulfilledAction {
  response: ValidateSubscriptionPairingResponse;
  subscriptionType: ValidateSubscriptionPairingRequest.SubscriptionTypeEnum;
  type: TypeKeys.VALIDATE_EMPLOYEE_SUBSCRIPTION_PAIRING_FULFILLED;
  redirectPath: string;
}

export const validateSubscriptionFulfilled = (
  response: ValidateSubscriptionPairingResponse,
  subscriptionType: ValidateSubscriptionPairingRequest.SubscriptionTypeEnum,
  redirectPath: string
): ValidateEmployeeSubscriptionFulfilledAction => ({
  response,
  subscriptionType,
  type: TypeKeys.VALIDATE_EMPLOYEE_SUBSCRIPTION_PAIRING_FULFILLED,
  redirectPath,
});

export type GetElisaIdSessionFailedAction = ErrorAction<TypeKeys.GET_ELISA_ID_SESSION_FAILED>;

export const getElisaIdSession = (elisaIdClient: ElisaIdClientAttrs): GetElisaIdSessionAction => ({
  id: generateActionId(),
  payload: elisaIdClient,
  type: TypeKeys.GET_ELISA_ID_SESSION,
});

export const getElisaIdSessionFulfilled = (
  userName: string,
  elisaIdClient?: ElisaIdClientAttrs
): GetElisaIdSessionFulfilledAction => ({
  type: TypeKeys.GET_ELISA_ID_SESSION_FULFILLED,
  userName,
  elisaIdClient,
});

export const getElisaIdSessionFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): GetElisaIdSessionFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.GET_ELISA_ID_SESSION_FAILED,
});

// get Elisa Id Access Token
export interface GetElisaIdAccessTokenAction extends CrudAction<TypeKeys.GET_ELISA_ID_ACCESS_TOKEN> {
  payload: ElisaIdClientAttrs;
}

export interface GetElisaIdAccessTokenFulfilledAction {
  payload: ElisaIdClientAttrs;
  token: string;
  type: TypeKeys.GET_ELISA_ID_ACCESS_TOKEN_FULFILLED;
}

export type GetElisaIdAccessTokenFailedAction = ErrorAction<TypeKeys.GET_ELISA_ID_ACCESS_TOKEN_FAILED>;

export const getElisaIdAccessToken = (payload: ElisaIdClientAttrs): GetElisaIdAccessTokenAction => ({
  id: generateActionId(),
  payload: payload,
  type: TypeKeys.GET_ELISA_ID_ACCESS_TOKEN,
});

export const getElisaIdAccessTokenFulfilled = (
  token: string,
  payload: ElisaIdClientAttrs
): GetElisaIdAccessTokenFulfilledAction => ({
  payload: payload,
  token: token,
  type: TypeKeys.GET_ELISA_ID_ACCESS_TOKEN_FULFILLED,
});

export const getElisaIdAccessTokenFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): GetElisaIdAccessTokenFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.GET_ELISA_ID_ACCESS_TOKEN_FAILED,
});

// Logout Elisa Id session
export interface ElisaIdLogOutAction extends CrudAction<TypeKeys.ELISA_ID_LOGOUT> {
  payload: ElisaIdClientAttrs;
}

export interface ElisaIdLogOutFulfilledAction {
  type: TypeKeys.ELISA_ID_LOGOUT_FULFILLED;
}

export type ElisaIdLogOutFailedAction = ErrorAction<TypeKeys.ELISA_ID_LOGOUT_FAILED>;

export const elisaIdLogOut = (elisaIdClient: ElisaIdClientAttrs): ElisaIdLogOutAction => ({
  id: generateActionId(),
  payload: elisaIdClient,
  type: TypeKeys.ELISA_ID_LOGOUT,
});

export const elisaIdLogOutFulfilled = (): ElisaIdLogOutFulfilledAction => ({
  type: TypeKeys.ELISA_ID_LOGOUT_FULFILLED,
});

export const elisaIdLogOutFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): ElisaIdLogOutFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.ELISA_ID_LOGOUT_FAILED,
});

export interface UpdateElisaIdSessionAction {
  type: TypeKeys.UPDATE_ELISAID_SESSION;
}

export const updateElisaIdSession = (): UpdateElisaIdSessionAction => ({
  type: TypeKeys.UPDATE_ELISAID_SESSION,
});

// Email Verification

export interface VerifyEmailAction extends CrudAction<TypeKeys.VERIFY_EMAIL> {
  linkToken: string;
}

export interface VerifyEmailFulfilledAction {
  type: TypeKeys.VERIFY_EMAIL_FULFILLED;
}

export type VerifyEmailFailedAction = ErrorAction<TypeKeys.VERIFY_EMAIL_FAILED>;

export const verifyEmail = (linkToken: string): VerifyEmailAction => ({
  id: generateActionId(),
  linkToken: linkToken,
  type: TypeKeys.VERIFY_EMAIL,
});

export const verifyEmailFulfilled = (): VerifyEmailFulfilledAction => ({
  type: TypeKeys.VERIFY_EMAIL_FULFILLED,
});

export const verifyEmailFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): VerifyEmailFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.VERIFY_EMAIL_FAILED,
});

export type ResendEmailVerificationLinkAction = CrudAction<TypeKeys.RESEND_EMAIL_VERIFICATION_LINK>;

export interface ResendEmailVerificationLinkFulfilledAction {
  type: TypeKeys.RESEND_EMAIL_VERIFICATION_LINK_FULFILLED;
}

export type ResendEmailVerificationLinkFailedAction = ErrorAction<TypeKeys.RESEND_EMAIL_VERIFICATION_LINK_FAILED>;

export const resendEmailVerificationLink = (): ResendEmailVerificationLinkAction => ({
  id: generateActionId(),
  type: TypeKeys.RESEND_EMAIL_VERIFICATION_LINK,
});

export const resendEmailVerificationLinkFulfilled = (): ResendEmailVerificationLinkFulfilledAction => ({
  type: TypeKeys.RESEND_EMAIL_VERIFICATION_LINK_FULFILLED,
});

export const resendEmailVerificationLinkFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): ResendEmailVerificationLinkFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.RESEND_EMAIL_VERIFICATION_LINK_FAILED,
});

// Onboard employee actions
export interface EmployeeOnBoardingPayload {
  recipientEmails: string[];
  subscriptionId?: string;
}

export interface InitiateEmployeeOnBoardingAction extends CrudAction<TypeKeys.INITIATE_EMPLOYEE_ONBOARDING> {
  payload: EmployeeOnBoardingPayload;
  validationErrors?: CommonError[];
  resend?: boolean;
}

export interface InitiateEmployeeOnBoardingFulfilledAction {
  type: TypeKeys.INITIATE_EMPLOYEE_ONBOARDING_FULFILLED;
}

export type InitiateEmployeeOnBoardingFailedAction = ErrorAction<TypeKeys.INITIATE_EMPLOYEE_ONBOARDING_FAILED>;

export type InitiateEmployeeOnBoardingResendFailedAction =
  ErrorAction<TypeKeys.INITIATE_EMPLOYEE_ONBOARDING_RESEND_FAILED>;

export const initiateEmployeeOnBoarding = (
  payload: EmployeeOnBoardingPayload,
  validationErrors?: CommonError[],
  resend = false
): InitiateEmployeeOnBoardingAction => ({
  id: generateActionId(),
  payload,
  type: TypeKeys.INITIATE_EMPLOYEE_ONBOARDING,
  validationErrors,
  resend,
});

export const initiateEmployeeOnBoardingFulfilled = (): InitiateEmployeeOnBoardingFulfilledAction => ({
  type: TypeKeys.INITIATE_EMPLOYEE_ONBOARDING_FULFILLED,
});

export const initiateEmployeeOnBoardingFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): InitiateEmployeeOnBoardingFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.INITIATE_EMPLOYEE_ONBOARDING_FAILED,
});

export const initiateEmployeeOnBoardingResendFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): InitiateEmployeeOnBoardingResendFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.INITIATE_EMPLOYEE_ONBOARDING_RESEND_FAILED,
});

export type EmployeeActionTypes =
  | ElisaIdLogOutAction
  | ElisaIdLogOutFulfilledAction
  | ElisaIdLogOutFailedAction
  | GetElisaIdSessionAction
  | GetElisaIdSessionFulfilledAction
  | GetElisaIdSessionFailedAction
  | GetElisaIdAccessTokenAction
  | GetElisaIdAccessTokenFulfilledAction
  | GetElisaIdAccessTokenFailedAction
  | ProcessElisaIdLoginAction
  | ProcessElisaIdLoginFulfilledAction
  | ProcessElisaIdLoginFailedAction
  | ProcessEmployeeSubscriptionPairingAction
  | ProcessEmployeeSubscriptionPairingFailedAction
  | ProcessEmployeeSubscriptionPairingFulfilledAction
  | ValidateEmployeeSubscriptionAction
  | ValidateEmployeeSubscriptionFailedAction
  | ValidateEmployeeSubscriptionFulfilledAction
  | UpdateElisaIdSessionAction
  | VerifyEmailAction
  | VerifyEmailFulfilledAction
  | VerifyEmailFailedAction
  | ResendEmailVerificationLinkAction
  | ResendEmailVerificationLinkFulfilledAction
  | ResendEmailVerificationLinkFailedAction
  | InitiateEmployeeOnBoardingAction
  | InitiateEmployeeOnBoardingFailedAction
  | InitiateEmployeeOnBoardingResendFailedAction
  | InitiateEmployeeOnBoardingFulfilledAction;
