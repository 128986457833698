export const WEEKDAYS: { [s: string]: [string, string, string, string, string, string, string] } = {
  'en-GB': ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  'fi-FI': ['Sunnuntai', 'Maanantai', 'Tiistai', 'Keskiviikko', 'Torstai', 'Perjantai', 'Lauantai'],
  'sv-SE': ['Söndag', 'Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag'],
};

export const WEEKDAYS_SHORT: { [s: string]: [string, string, string, string, string, string, string] } = {
  'en-GB': ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'],
  'fi-FI': ['SU', 'MA', 'TI', 'KE', 'TO', 'PE', 'LA'],
  'sv-SE': ['SÖ', 'MÅ', 'TI', 'ON', 'TO', 'FR', 'LÖ'],
};

export const FIRST_DAY_OF_WEEK = {
  'en-GB': 1,
  'fi-FI': 1,
  'sv-SE': 1,
};

export const MONTHS: {
  [s: string]: [string, string, string, string, string, string, string, string, string, string, string, string];
} = {
  'en-GB': [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  'fi-FI': [
    'Tammikuu',
    'Helmikuu',
    'Maaliskuu',
    'Huhtikuu',
    'Toukokuu',
    'Kesäkuu',
    'Heinäkuu',
    'Elokuu',
    'Syyskuu',
    'Lokakuu',
    'Marraskuu',
    'Joulukuu',
  ],
  'sv-SE': [
    'Januari',
    'Februari',
    'Mars',
    'April',
    'Maj',
    'Juni',
    'Juli',
    'Augusti',
    'September',
    'Oktober',
    'November',
    'December',
  ],
};

export const NEXT_PREV_MONTH_LABELS = {
  'en-GB': { nextMonth: 'Next month', previousMonth: 'Previous month' },
  'fi-FI': { nextMonth: 'Seuraava kuukausi', previousMonth: 'Edellinen kuukausi' },
  'sv-SE': { nextMonth: 'Nästa månad', previousMonth: 'Förra månaden' },
};
