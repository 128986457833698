import { CampaignProductCard } from './CampaignProductCard.js';
import { Impression } from '../Impression/Impression.js';
import { SelectOneList } from '../SelectOneList/index.js';
import { productContentMatchesOffer } from './content/CommercialProductContent.js';
import { useSelector } from 'react-redux';
import type { CampaignPromotion, Offer } from '../../generated/api/models.js';
import type { OfferCard } from './OrderSubscriptionSelection.js';
import type { State } from '../../selfservice/common/store.js';

interface CampaignProductCardGridProps {
  offerCardContents: OfferCard[];
  campaignsFromVoucher?: Array<CampaignPromotion>;
  imagesBaseUrl: string | undefined;
  isChangeOffer: boolean;
  handleProductSeen: (offers: Offer[], isMinuteBased: boolean) => void;
  handleSelectOffer: (offerCard: OfferCard) => void;
  selectedProduct: Offer | undefined;
  campaignPromotions: CampaignPromotion[];
  showSelectButton?: boolean;
  isMultipleCampaigns?: boolean;
}

export const CampaignProductCardGrid = (props: CampaignProductCardGridProps) => {
  const {
    offerCardContents,
    isChangeOffer,
    imagesBaseUrl,
    handleProductSeen,
    handleSelectOffer,
    selectedProduct,
    showSelectButton = false,
    isMultipleCampaigns = false,
  } = props;

  const voucher = useSelector((state: State) => state.campaigns?.voucher);

  return (
    <SelectOneList
      maxItemsAsideTablet={2}
      maxItemsAsideLaptop={3}
      options={offerCardContents.map(offerCardContent => {
        const { offer, content, campaignAssociation, voucherInUse } = offerCardContent;
        // TODO: Currently assuming that offer has one commercialProduct
        const commercialProduct = offer.commercialProducts[0];
        return {
          element: (
            <Impression onImpression={() => handleProductSeen([offer], false)}>
              <CampaignProductCard
                offer={offer}
                content={content}
                offerCampaignAssociation={campaignAssociation}
                commercialProduct={commercialProduct}
                imagesBaseUrl={imagesBaseUrl}
                isChangeOffer={isChangeOffer}
                showSelectButton={showSelectButton}
                isMultipleCampaigns={isMultipleCampaigns}
                voucher={voucherInUse ? voucher : undefined}
              />
            </Impression>
          ),
          isHighlighted: voucherInUse,
          isSelected: selectedProduct?.offerCode
            ? productContentMatchesOffer(content, selectedProduct)
            : content.isDefaultSelected,
          onClick: () => handleSelectOffer(offerCardContent),
        };
      })}
      thickIcon={true}
    />
  );
};
