import { PopoverContent } from './CommercialProductContent.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { t } from '../../../common/i18n/index.js';

export const PuhePopoverContent = () => (
  <PopoverContent>
    <div>
      <b>Optimoituva puhe </b>
      {t.P7PN('is an excellent choice for someone who does not make a lot of calls.')}
    </div>
    <div>
      {t.Y9TT(
        'The subscription includes 100 minutes of calls per month. Any extra minutes will be billed in accordance with the following voice package limits:'
      )}
    </div>
    <div className={`${dsClass.MARGIN_TOP_2} ${dsClass.MARGIN_LEFT_4}`}>
      <ul className={dsClass.LIST_UNORDERED}>
        <li>{t.X7KY('{} minutes included', '100')}</li>
        <li>100-500 min +2,5 €</li>
        <li>500-1000 min +2,5 €</li>
        <li>1000-3500 min +2,5 €</li>
      </ul>
    </div>
    <div className={dsClass.MARGIN_TOP_2}>{t.Q01I('Each used package is shown as its own line on the invoice')}.</div>
  </PopoverContent>
);
