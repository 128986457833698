import { throwError } from '../../common/utils/errorUtils.js';

export function getMimeType(extension: string) {
  switch (extension.toLowerCase()) {
    case 'png':
      return 'image/png';
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg';
    default:
      return throwError(`Unknown extension ${extension}`);
  }
}
