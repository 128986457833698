import { Anchor } from '../Anchor/Anchor.js';
import { VAT_AS_NUMBER, getPriceWithTax } from '../../common/utils/taxUtils.js';
import {
  activeMsg,
  closedMsg,
  contractsMsg,
  devicesMsg,
  formatNumber,
  inDeliveryMsg,
  internetSubscriptionsMsg,
  monthMsg,
  monthsMsg,
  servicesMsg,
  t,
  temporarilyClosedMsg,
  vatMsg,
  voiceSubscriptionsMsg,
} from '../../common/i18n/index.js';
import { formatSum } from '../../common/utils/priceUtils.js';
import { isEppDeviceSubscriptionExpiring } from '../EmployeeSubscriptionDetails/eppSubscriptionUtils.js';
import type * as CL from '@design-system/component-library';
import type {
  Subscription,
  SubscriptionAction,
  SubscriptionDetails,
  SubscriptionStatusType,
} from '../../generated/api/models.js';
import type { SubscriptionStatus } from '../../common/types/subscription.js';

const STATUS_TEXTS = () => ({
  ACTIVATION_CANCELLED: t.UFGV(closedMsg),
  ACTIVE: t.SFRK(activeMsg),
  CLOSED_REPLACED: t.UFGV(closedMsg),
  CREATED: t.W59S(inDeliveryMsg),
  IN_ACTIVATION: t.W59S(inDeliveryMsg),
  SUSPENDED: t.PC3E(temporarilyClosedMsg),
  TERMINATED: t.UFGV(closedMsg),
});

const STATUS_COLORS = {
  ACTIVATION_CANCELLED: 'red',
  ACTIVE: 'linkblue',
  CLOSED_REPLACED: 'red',
  CREATED: 'light-orange',
  IN_ACTIVATION: 'light-orange',
  SUSPENDED: 'red',
  TERMINATED: 'red',
};

type DeviceType = 'accessories' | 'computers' | 'network equipment' | 'phone' | 'tablet';
const DEVICE_TYPE_TEXTS = () => ({
  accessories: t.O0EZ('Accessory'),
  computers: t.IHS4('Computer'),
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'network equipment': t.EG02('Network device'),
  phone: t.OWF1('Mobile phone'),
  tablet: t.ZFAS('Tablet'),
});

export const CATEGORY_TEXTS = () => ({
  broadband: t.SEYV(internetSubscriptionsMsg),
  device: t.JJTM(devicesMsg),
  service: t.MBG9(servicesMsg),
  voice: t.AR0B(voiceSubscriptionsMsg),
  contract: t.SW0J(contractsMsg),
  subscriptions: '',
  domain: '',
  elisadns: '',
  licenses: '',
});

export const getSubscriptionStatusColor = (status?: SubscriptionStatusType) => {
  if (!status) {
    return undefined;
  }
  return STATUS_COLORS[status.toString() as keyof typeof STATUS_COLORS];
};

export const getSubscriptionStatusText = (status?: SubscriptionStatusType) => {
  if (!status) {
    return undefined;
  }
  return STATUS_TEXTS()[status.toString() as SubscriptionStatusType];
};

export const getSubscriptionStatusFieldValue = (subscriptionStatus: SubscriptionStatus, action?: StatusFieldAction) => {
  return (
    <>
      {action ? (
        <>
          {subscriptionStatus.text} | <Anchor onClick={action.link}>{action.text}</Anchor>
        </>
      ) : (
        subscriptionStatus.text
      )}
    </>
  );
};

export const getSubscriptionStatus = (subscriptionStatus: SubscriptionStatus, action?: StatusFieldAction) => {
  return (
    <span className={`ea-disc ea-disc--small ea-disc--${subscriptionStatus.color}`}>
      {getSubscriptionStatusFieldValue(subscriptionStatus, action)}
    </span>
  );
};

export const getSubscriptionStatusFieldCL = (
  subscriptionStatus: SubscriptionStatus,
  label: string,
  action?: StatusFieldAction
): CL.DescriptionItem => {
  return {
    title: label,
    description: (
      <div className={`ea-disc ea-disc--small ea-disc--${subscriptionStatus.color}`}>
        {getSubscriptionStatusFieldValue(subscriptionStatus, action)}
      </div>
    ),
  };
};

export interface StatusFieldAction {
  text: string;
  link: () => void;
}

// TODO: When CompositeList is not used - this can replace the below function
export const getRecurringPriceAsText = (monthlyRecurringCharge?: number, billingPeriod = 0) => {
  const monthText = billingPeriod ? `/${billingPeriod} ${t.XXVX(monthsMsg)}` : `/${t.XXVX(monthMsg)}`;
  return monthlyRecurringCharge ? `${formatSum(monthlyRecurringCharge)}${monthText}` : '—';
};

export const getPeriodicPriceAsText = (monthlyRecurringCharge?: number) => {
  return getRecurringPriceAsText(monthlyRecurringCharge);
};

export const getPriceAsText = (
  charge?: number,
  listCharge?: number,
  periodic?: boolean,
  undefinedValue?: string,
  billingPeriod = 0
): string | undefined => {
  if (charge === undefined) {
    return undefinedValue || formatSum(charge);
  }
  const billingSuffix = billingPeriod ? `/${billingPeriod} ${t.XXVX(monthsMsg)}` : `/${t.XXVX(monthMsg)}`;
  const periodSuffix = periodic ? billingSuffix : '';
  let priceText = formatSum(charge) + periodSuffix;
  if (listCharge !== undefined && charge !== listCharge) {
    priceText += ` (${t.LRAQ('reg.')} ${formatSum(listCharge)}${periodSuffix})`;
  }

  return priceText;
};

export const getPeriodicPriceAsTextFromDetails = (subscriptionDetails?: SubscriptionDetails): string | undefined => {
  if (!subscriptionDetails) {
    return '—';
  }
  const { monthlyRecurringCharge, monthlyRecurringListCharge } = subscriptionDetails;
  if (monthlyRecurringCharge === 0) {
    return '—';
  }
  return getPriceAsText(monthlyRecurringCharge, monthlyRecurringListCharge, true, '—');
};

export const getEmployeePeriodicPriceAsText = (price: number | undefined) => {
  return price
    ? `${getPeriodicPriceAsText(getPriceWithTax(price))} (${t.U8AN('incl.')} ${t.UZ6X(vatMsg)} ${formatNumber(
        VAT_AS_NUMBER
      )}%)`
    : '-';
};

export const getDeviceTypeText = (subscriptionSubType: string) => {
  return DEVICE_TYPE_TEXTS()[subscriptionSubType.toLowerCase() as DeviceType] || '';
};

export const getExpiringEppDeviceSubscriptions = (
  subscriptions: Subscription[],
  subscriptionActions?: SubscriptionAction[]
): Subscription[] => {
  return subscriptions.filter(
    sub =>
      isEppDeviceSubscriptionExpiring(sub) &&
      subscriptionActions?.findIndex(subscriptionAction => subscriptionAction.subscriptionId === sub.subscriptionId) ===
        -1
  );
};
