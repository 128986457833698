import { TypeKeys, generateActionId } from './actionsUtils.js';
import { convertToCommonErrors, convertToCommonErrorsUsingErrorsMessages } from '../../common/utils/errorUtils.js';
import type { Action } from 'redux';
import type { AddressSearchResult } from '../../components/AddressSearch/AddressSearch.js';
import type {
  CommercialAvailabilityResponse,
  Company,
  Domain,
  GenericError,
  NumberRangeResponse,
  PageResponse,
  PageSearchResponse,
  ValidatePortableResponse,
  ValidateSimFreeResponse,
} from '../../generated/api/models.js';
import type { CrudAction, ErrorAction } from './actionsUtils.js';
import type { ResponseHeaders } from '../../common/types/states.js';

export type LoadHolidaysAction = CrudAction<TypeKeys.LOAD_HOLIDAYS>;

export const loadHolidays = (): LoadHolidaysAction => ({
  id: generateActionId(),
  type: TypeKeys.LOAD_HOLIDAYS,
});

export type LoadHolidaysFailedAction = ErrorAction<TypeKeys.LOAD_HOLIDAYS_FAILED>;

export const loadHolidaysFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): LoadHolidaysFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.LOAD_HOLIDAYS_FAILED,
});

export interface LoadHolidaysFulfilledAction extends Action {
  dates: number[];
  type: TypeKeys.LOAD_HOLIDAYS_FULFILLED;
}

export const loadHolidaysFulfilled = (dates: number[]) => ({
  dates: dates,
  type: TypeKeys.LOAD_HOLIDAYS_FULFILLED,
});

export interface DisplayMoreNumbersFromRange extends Action {
  rangeId: string;
  type: TypeKeys.DISPLAY_MORE_NUMBERS_FROM_RANGE;
}

export const displayMoreNumbersFromRange = (rangeId: string): DisplayMoreNumbersFromRange => ({
  rangeId,
  type: TypeKeys.DISPLAY_MORE_NUMBERS_FROM_RANGE,
});

export interface LoadNumberRangeAction extends CrudAction<TypeKeys.LOAD_NUMBER_RANGE> {
  rangeId: string;
}

export const loadNumberRange = (rangeId: string): LoadNumberRangeAction => ({
  id: generateActionId(),
  rangeId,
  type: TypeKeys.LOAD_NUMBER_RANGE,
});

export interface LoadNumberRangeFailedAction extends ErrorAction<TypeKeys.LOAD_NUMBER_RANGE_FAILED> {
  rangeId: string;
}

export const loadNumberRangeFailed = (
  rangeId: string,
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): LoadNumberRangeFailedAction => ({
  errors: convertToCommonErrorsUsingErrorsMessages(message, status, errors),
  params,
  rangeId,
  type: TypeKeys.LOAD_NUMBER_RANGE_FAILED,
});

export interface LoadNumberRangeFulfilledAction extends Action<TypeKeys.LOAD_NUMBER_RANGE_FULFILLED> {
  numberCategory: NumberRangeResponse.NumberCategoryEnum;
  numbers: string[];
  rangeId: string;
}

export const loadNumberRangeFulfilled = (
  rangeId: string,
  numberCategory: NumberRangeResponse.NumberCategoryEnum,
  numbers: string[]
): LoadNumberRangeFulfilledAction => ({
  numberCategory,
  numbers,
  rangeId,
  type: TypeKeys.LOAD_NUMBER_RANGE_FULFILLED,
});

export interface PrereserveNumbersAction extends CrudAction<TypeKeys.PRERESERVE_NUMBERS> {
  initialLoad?: boolean;
  totalAmount?: number;
}

export const prereserveNumbers = (totalAmount?: number, initialLoad?: boolean): PrereserveNumbersAction => ({
  id: generateActionId(),
  initialLoad,
  totalAmount,
  type: TypeKeys.PRERESERVE_NUMBERS,
});

export type PrereserveNumbersFailedAction = ErrorAction<TypeKeys.PRERESERVE_NUMBERS_FAILED>;

export const prereserveNumbersFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): PrereserveNumbersFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.PRERESERVE_NUMBERS_FAILED,
});

export interface PrereserveNumbersFulfilledAction extends Action {
  type: TypeKeys.PRERESERVE_NUMBERS_FULFILLED;
  numbers: string[];
}

export const prereserveNumbersFulfilled = (numbers: string[]): PrereserveNumbersFulfilledAction => ({
  numbers,
  type: TypeKeys.PRERESERVE_NUMBERS_FULFILLED,
});

export interface SelectedCommercialProductsAmountChangeAction extends Action {
  totalAmount?: number;
  type: TypeKeys.SELECTED_COMMERCIAL_PRODUCTS_AMOUNT_CHANGE;
}

export const commercialProductsAmountChange = (totalAmount?: number): SelectedCommercialProductsAmountChangeAction => ({
  totalAmount,
  type: TypeKeys.SELECTED_COMMERCIAL_PRODUCTS_AMOUNT_CHANGE,
});

export interface ResetCompanySearchResultAction extends Action {
  type: TypeKeys.RESET_COMPANY_SEARCH_RESULT;
}

export const resetCompanySearchResult = (): ResetCompanySearchResultAction => ({
  type: TypeKeys.RESET_COMPANY_SEARCH_RESULT,
});

export interface SearchCompanyAction extends CrudAction<TypeKeys.SEARCH_COMPANY> {
  query: string;
}

export const searchCompany = (query: string): SearchCompanyAction => ({
  id: generateActionId(),
  query: query,
  type: TypeKeys.SEARCH_COMPANY,
});

export interface SearchDomainAction extends CrudAction<TypeKeys.SEARCH_DOMAIN> {
  query: string;
}

export const searchDomain = (query: string): SearchDomainAction => ({
  id: generateActionId(),
  query: query,
  type: TypeKeys.SEARCH_DOMAIN,
});

export type SearchDomainFailedAction = ErrorAction<TypeKeys.SEARCH_DOMAIN_FAILED>;

export const searchDomainFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): SearchDomainFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.SEARCH_DOMAIN_FAILED,
});

export type SearchCompanyFailedAction = ErrorAction<TypeKeys.SEARCH_COMPANY_FAILED>;

export const searchCompanyFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): SearchCompanyFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.SEARCH_COMPANY_FAILED,
});

export interface SearchDomainFulfilledAction extends Action {
  result: Domain[];
  type: TypeKeys.SEARCH_DOMAIN_FULFILLED;
}

export const searchDomainFulfilled = (result: Domain[]): SearchDomainFulfilledAction => ({
  result: result,
  type: TypeKeys.SEARCH_DOMAIN_FULFILLED,
});

export interface SearchCompanyFulfilledAction extends Action {
  result: Company[];
  type: TypeKeys.SEARCH_COMPANY_FULFILLED;
}

export const searchCompanyFulfilled = (result: Company[]): SearchCompanyFulfilledAction => ({
  result: result,
  type: TypeKeys.SEARCH_COMPANY_FULFILLED,
});

export interface ResetAddressSearchResultAction extends Action {
  type: TypeKeys.RESET_ADDRESS_SEARCH_RESULT;
}

export const resetAddressSearchResult = (): ResetAddressSearchResultAction => ({
  type: TypeKeys.RESET_ADDRESS_SEARCH_RESULT,
});

export interface SearchAddressAction extends CrudAction<TypeKeys.SEARCH_ADDRESS> {
  query: string;
  postalCode: string;
}

export const searchAddress = (query: string, postalCode: string): SearchAddressAction => ({
  id: generateActionId(),
  query: query,
  postalCode: postalCode,
  type: TypeKeys.SEARCH_ADDRESS,
});

export type SearchAddressFailedAction = ErrorAction<TypeKeys.SEARCH_ADDRESS_FAILED>;

export const searchAddressFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): SearchAddressFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.SEARCH_ADDRESS_FAILED,
});

export interface SearchAddressFulfilledAction extends Action {
  result: AddressSearchResult;
  type: TypeKeys.SEARCH_ADDRESS_FULFILLED;
}

export const searchAddressFulfilled = (result: AddressSearchResult): SearchAddressFulfilledAction => ({
  result: result,
  type: TypeKeys.SEARCH_ADDRESS_FULFILLED,
});

export interface GetCommercialAvailabilityAction extends CrudAction<TypeKeys.GET_COMMERCIAL_AVAILABILITY> {
  address: string;
  postalCode: string;
  subscriptionType: string;
}

export const getCommercialAvailability = (
  action: Omit<GetCommercialAvailabilityAction, 'id' | 'type'>
): GetCommercialAvailabilityAction => ({
  ...action,
  id: generateActionId(),
  type: TypeKeys.GET_COMMERCIAL_AVAILABILITY,
});

export type GetCommercialAvailabilityFailedAction = ErrorAction<TypeKeys.GET_COMMERCIAL_AVAILABILITY_FAILED>;

export const getCommercialAvailabilityFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): GetCommercialAvailabilityFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.GET_COMMERCIAL_AVAILABILITY_FAILED,
});

export interface GetCommercialAvailabilityFulfilledAction extends Action {
  result: CommercialAvailabilityResponse;
  type: TypeKeys.GET_COMMERCIAL_AVAILABILITY_FULFILLED;
}

export const getCommercialAvailabilityFulfilled = (
  result: CommercialAvailabilityResponse
): GetCommercialAvailabilityFulfilledAction => ({
  result: result,
  type: TypeKeys.GET_COMMERCIAL_AVAILABILITY_FULFILLED,
});

export interface ResetSearchPublicPagesResultAction extends Action {
  type: TypeKeys.RESET_SEARCH_PUBLIC_PAGES_RESULT;
}

export const resetSearchPagesSearchResult = (): ResetSearchPublicPagesResultAction => ({
  type: TypeKeys.RESET_SEARCH_PUBLIC_PAGES_RESULT,
});

export interface SearchPublicPagesAction extends CrudAction<TypeKeys.SEARCH_PUBLIC_PAGES> {
  query: string;
  start: number;
}

export const searchPage = (query: string, start = 1): SearchPublicPagesAction => ({
  id: generateActionId(),
  query: query,
  start: start,
  type: TypeKeys.SEARCH_PUBLIC_PAGES,
});

export type SearchPublicPagesFailedAction = ErrorAction<TypeKeys.SEARCH_PUBLIC_PAGES_FAILED>;

export const searchPublicPagesFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): SearchPublicPagesFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.SEARCH_PUBLIC_PAGES_FAILED,
});

export interface SearchPublicPagesFulfilledAction extends Action {
  query: string;
  start: number;
  result: PageSearchResponse;
  type: TypeKeys.SEARCH_PUBLIC_PAGES_FULFILLED;
}

export const searchPublicPagesFulfilled = (
  query: string,
  start: number,
  result: PageSearchResponse
): SearchPublicPagesFulfilledAction => ({
  query,
  start,
  result,
  type: TypeKeys.SEARCH_PUBLIC_PAGES_FULFILLED,
});

export interface ValidatePhoneNumberAction extends Action<TypeKeys.VALIDATE_PHONE_NUMBER> {
  phoneNumber: string;
  validatePortIn: boolean;
}

export interface ValidatePhoneNumberFulfilledAction extends Action<TypeKeys.VALIDATE_PHONE_NUMBER_FULFILLED> {
  phoneNumber: string;
  validatePortIn: boolean;
  response: ValidatePortableResponse;
}

export type ValidatePhoneNumberFailedAction = ErrorAction<TypeKeys.VALIDATE_PHONE_NUMBER_FAILED>;

export const validatePhoneNumber = (phoneNumber: string, validatePortIn: boolean): ValidatePhoneNumberAction => ({
  phoneNumber,
  validatePortIn,
  type: TypeKeys.VALIDATE_PHONE_NUMBER,
});

export const validatePhoneNumberFulfilled = (
  phoneNumber: string,
  validatePortIn: boolean,
  response: ValidatePortableResponse
): ValidatePhoneNumberFulfilledAction => ({
  phoneNumber,
  validatePortIn,
  response,
  type: TypeKeys.VALIDATE_PHONE_NUMBER_FULFILLED,
});

export const validatePhoneNumberFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): ValidatePhoneNumberFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.VALIDATE_PHONE_NUMBER_FAILED,
});

export interface ValidateSimAction extends Action<TypeKeys.VALIDATE_SIM> {
  simCardNumber: string;
}

export interface ValidateSimFulfilledAction extends Action<TypeKeys.VALIDATE_SIM_FULFILLED> {
  iccId: string;
  response: ValidateSimFreeResponse;
}

export type ValidateSimFailedAction = ErrorAction<TypeKeys.VALIDATE_SIM_FAILED>;

export const validateSim = (iccId: string): ValidateSimAction => ({
  simCardNumber: iccId,
  type: TypeKeys.VALIDATE_SIM,
});

export const validateSimFulfilled = (iccId: string, response: ValidateSimFreeResponse): ValidateSimFulfilledAction => ({
  iccId,
  response,
  type: TypeKeys.VALIDATE_SIM_FULFILLED,
});

export const validateSimFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): ValidateSimFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.VALIDATE_SIM_FAILED,
});

export interface GetPublicPageAction extends CrudAction<TypeKeys.GET_PUBLIC_PAGE> {
  pagePath: string;
}

export const getPublicPage = (pagePath: string): GetPublicPageAction => ({
  id: generateActionId(),
  type: TypeKeys.GET_PUBLIC_PAGE,
  pagePath,
});

export interface GetPublicPageNotFoundAction extends CrudAction<TypeKeys.GET_PUBLIC_PAGE_NOT_FOUND> {
  pagePath: string;
}

export const getPublicPageNotFound = (pagePath: string): GetPublicPageNotFoundAction => ({
  id: generateActionId(),
  type: TypeKeys.GET_PUBLIC_PAGE_NOT_FOUND,
  pagePath,
});

export interface GetPublicPageFailedAction extends ErrorAction<TypeKeys.GET_PUBLIC_PAGE_FAILED> {
  pagePath: string;
  pageStatusCode: number;
}

export type PublicPageFailedActionParams = [string, number, GenericError[], { [s: string]: string }];
export const getPublicPageFailed = (
  pagePath: string,
  parameters: PublicPageFailedActionParams
): GetPublicPageFailedAction => {
  const [message, status, errors, params] = parameters;
  return {
    pagePath,
    pageStatusCode: status,
    errors: convertToCommonErrors(message, status, errors),
    params,
    type: TypeKeys.GET_PUBLIC_PAGE_FAILED,
  };
};

export interface GetPublicPageFulfilledAction extends Action<TypeKeys.GET_PUBLIC_PAGE_FULFILLED> {
  headers: ResponseHeaders;
  page: PageResponse;
  pagePath: string;
  pageStatusCode: number;
}

export const getPublicPageFulfilled = (
  headers: ResponseHeaders,
  page: PageResponse,
  pagePath: string,
  pageStatusCode: number
): GetPublicPageFulfilledAction => ({
  headers,
  page,
  pagePath,
  pageStatusCode,
  type: TypeKeys.GET_PUBLIC_PAGE_FULFILLED,
});

export type ResourcesActionTypes =
  | DisplayMoreNumbersFromRange
  | LoadHolidaysAction
  | LoadHolidaysFailedAction
  | LoadHolidaysFulfilledAction
  | LoadNumberRangeAction
  | LoadNumberRangeFailedAction
  | LoadNumberRangeFulfilledAction
  | PrereserveNumbersAction
  | PrereserveNumbersFailedAction
  | PrereserveNumbersFulfilledAction
  | ResetCompanySearchResultAction
  | SearchCompanyAction
  | SearchCompanyFailedAction
  | SearchCompanyFulfilledAction
  | SearchDomainAction
  | SearchDomainFailedAction
  | SearchDomainFulfilledAction
  | ResetAddressSearchResultAction
  | SearchAddressAction
  | SearchAddressFailedAction
  | SearchAddressFulfilledAction
  | GetCommercialAvailabilityAction
  | GetCommercialAvailabilityFailedAction
  | GetCommercialAvailabilityFulfilledAction
  | ResetSearchPublicPagesResultAction
  | SearchPublicPagesAction
  | SearchPublicPagesFailedAction
  | SearchPublicPagesFulfilledAction
  | SelectedCommercialProductsAmountChangeAction
  | ValidatePhoneNumberAction
  | ValidatePhoneNumberFulfilledAction
  | ValidatePhoneNumberFailedAction
  | GetPublicPageAction
  | GetPublicPageFailedAction
  | GetPublicPageFulfilledAction
  | GetPublicPageNotFoundAction
  | ValidateSimAction
  | ValidateSimFulfilledAction
  | ValidateSimFailedAction;
