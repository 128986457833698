import { MobileIdNewContent } from '../../../../../components/MobileId/MobileIdNew.js';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import { getMobileIdContracts } from '../../../../../selfservice/actions/mobileIdContractActions.js';
import { stripActions } from '../../../../../common/utils/stateUtils.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import type { State } from '../../../../../selfservice/common/store.js';

export const MobileIdNewPath = () => {
  const dispatch = useDispatch();
  const subs = useSelector((s: State) => stripActions(s.selfservice?.subscriptions?.contract) || undefined, deepEqual);

  useEffect(() => {
    if (!subs?.mobileIdContracts) {
      dispatch(getMobileIdContracts());
    }
  }, [dispatch, subs?.mobileIdContracts]);

  return <MobileIdNewContent subscriptions={subs} />;
};
