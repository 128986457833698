/**
 * Online UI API
 * # Summary Elisa B2B Online UI API  # Usage This API is used in the `https://yrityksille.elisa.fi/` pages.  # Identifiers 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Object combines SupportCaseHeader data from ES and attachments data from SF
 */
export interface SupportCaseDataBundle { 
    /**
     * Id of the support case as shown to the user. SFDC: Case.CaseNumber.
     */
    supportCaseDisplayId: string;
    /**
     * Feature that the user was interacting with when creating the support case. Editable (by system). SFDC: Case.Additional_Information__c.
     */
    feature?: SupportCaseDataBundle.FeatureEnum;
    /**
     * More specific type under the feature if applicable. Editable (by system). SFDC: Case.Additional_Information__c.
     */
    featureType?: string;
    /**
     * Customer-given subject field for the support case, not mandatory. Editable. SFDC: Case.Subject.
     */
    subject?: string;
    /**
     * Customer-given description field for the support case. Editable. SFDC: Case.Description.
     */
    description?: string;
    /**
     * Unix timestamp of last modification. SFDC: Case.LastModifiedDate.
     */
    lastModified?: number;
    /**
     * Unix timestamp of creation time. SFDC: Case.CreatedDate.
     */
    createdAt?: number;
    /**
     * Status of support case
     */
    status?: SupportCaseDataBundle.StatusEnum;
    /**
     * Invoice number. Applies to \'Ask about the invoice\' case type
     */
    invoiceNumber?: string;
    /**
     * Contact\'s first name
     */
    firstName?: string;
    /**
     * Contact\'s last name
     */
    lastName?: string;
    /**
     * E-mail of the contact request
     */
    email?: string;
    /**
     * External resolution of the case and is visible to the customers. SFDC: Case.Case_Resolution__c
     */
    resolution?: string;
    /**
     * Account master ID of the owner of Support Case
     */
    accountMasterId?: string;
    /**
     * Attachments (\'.xls\', \'.xlsx\', \'.doc\', \'.docx\', \'.txt\', \'.pdf\', \'.jpg\', \'.png\')
     */
    attachments?: Array<string>;
}
export namespace SupportCaseDataBundle {
    export type FeatureEnum = 'INVOICES' | 'BILLING_ACCOUNTS' | 'CUSTOMER_ORDERS' | 'DELIVERY_ORDERS' | 'SUBSCRIPTIONS' | 'CONTACTS' | 'CONTACT_INFO_OFFER_REQUEST' | 'CONTACT_INFO_OPEN_ORDERS' | 'CONTACT_INFO_BILLING' | 'CONTACT_INFO_SERVICES' | 'CONTACT_INFO_CANCEL' | 'CONTACT_INFO_TECHNICAL' | 'CONTACT_INFO_COVERAGE' | 'CONTACT_INFO_PUK' | 'CONTACT_INFO_OTHER' | 'FIXED_BROADBAND_ORDER' | 'DEVICES' | 'ASSETS' | 'OTHER_SYSTEM_ORDERS' | 'BILLING_E_OPERATOR_CHANGES';
    export const FeatureEnum = {
        INVOICES: 'INVOICES' as FeatureEnum,
        BILLING_ACCOUNTS: 'BILLING_ACCOUNTS' as FeatureEnum,
        CUSTOMER_ORDERS: 'CUSTOMER_ORDERS' as FeatureEnum,
        DELIVERY_ORDERS: 'DELIVERY_ORDERS' as FeatureEnum,
        SUBSCRIPTIONS: 'SUBSCRIPTIONS' as FeatureEnum,
        CONTACTS: 'CONTACTS' as FeatureEnum,
        CONTACT_INFO_OFFER_REQUEST: 'CONTACT_INFO_OFFER_REQUEST' as FeatureEnum,
        CONTACT_INFO_OPEN_ORDERS: 'CONTACT_INFO_OPEN_ORDERS' as FeatureEnum,
        CONTACT_INFO_BILLING: 'CONTACT_INFO_BILLING' as FeatureEnum,
        CONTACT_INFO_SERVICES: 'CONTACT_INFO_SERVICES' as FeatureEnum,
        CONTACT_INFO_CANCEL: 'CONTACT_INFO_CANCEL' as FeatureEnum,
        CONTACT_INFO_TECHNICAL: 'CONTACT_INFO_TECHNICAL' as FeatureEnum,
        CONTACT_INFO_COVERAGE: 'CONTACT_INFO_COVERAGE' as FeatureEnum,
        CONTACT_INFO_PUK: 'CONTACT_INFO_PUK' as FeatureEnum,
        CONTACT_INFO_OTHER: 'CONTACT_INFO_OTHER' as FeatureEnum,
        FIXED_BROADBAND_ORDER: 'FIXED_BROADBAND_ORDER' as FeatureEnum,
        DEVICES: 'DEVICES' as FeatureEnum,
        ASSETS: 'ASSETS' as FeatureEnum,
        OTHER_SYSTEM_ORDERS: 'OTHER_SYSTEM_ORDERS' as FeatureEnum,
        BILLING_E_OPERATOR_CHANGES: 'BILLING_E_OPERATOR_CHANGES' as FeatureEnum
    };
    export type StatusEnum = 'OPEN' | 'CANCELLED' | 'RESOLVED' | 'WAITING_FOR_INPUT' | 'IN_PROGRESS' | 'CLOSED' | 'MOVED_ELSEWHERE';
    export const StatusEnum = {
        OPEN: 'OPEN' as StatusEnum,
        CANCELLED: 'CANCELLED' as StatusEnum,
        RESOLVED: 'RESOLVED' as StatusEnum,
        WAITING_FOR_INPUT: 'WAITING_FOR_INPUT' as StatusEnum,
        IN_PROGRESS: 'IN_PROGRESS' as StatusEnum,
        CLOSED: 'CLOSED' as StatusEnum,
        MOVED_ELSEWHERE: 'MOVED_ELSEWHERE' as StatusEnum
    };
}


