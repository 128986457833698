/**
 * Online UI API
 * # Summary Elisa B2B Online UI API  # Usage This API is used in the `https://yrityksille.elisa.fi/` pages.  # Identifiers 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SupportCaseDataBundle } from './supportCaseDataBundle.js';


/**
 * Search response
 */
export interface SupportCasesSearchResponse { 
    result: SupportCaseDataBundle;
    /**
     * List of matched fields, returned only when search was done
     */
    matchedFields?: Array<SupportCasesSearchResponse.MatchedFieldsEnum>;
}
export namespace SupportCasesSearchResponse {
    export type MatchedFieldsEnum = 'SUPPORT_CASE_DISPLAY_ID' | 'CREATED_AT' | 'FEATURE' | 'CONTACT_DETAILS' | 'FIRST_NAME' | 'LAST_NAME' | 'EMAIL' | 'LAST_MODIFIED' | 'STATUS' | 'FEATURE_TYPE' | 'INVOICE_NUMBER' | 'DESCRIPTION' | 'RESOLUTION';
    export const MatchedFieldsEnum = {
        SUPPORT_CASE_DISPLAY_ID: 'SUPPORT_CASE_DISPLAY_ID' as MatchedFieldsEnum,
        CREATED_AT: 'CREATED_AT' as MatchedFieldsEnum,
        FEATURE: 'FEATURE' as MatchedFieldsEnum,
        CONTACT_DETAILS: 'CONTACT_DETAILS' as MatchedFieldsEnum,
        FIRST_NAME: 'FIRST_NAME' as MatchedFieldsEnum,
        LAST_NAME: 'LAST_NAME' as MatchedFieldsEnum,
        EMAIL: 'EMAIL' as MatchedFieldsEnum,
        LAST_MODIFIED: 'LAST_MODIFIED' as MatchedFieldsEnum,
        STATUS: 'STATUS' as MatchedFieldsEnum,
        FEATURE_TYPE: 'FEATURE_TYPE' as MatchedFieldsEnum,
        INVOICE_NUMBER: 'INVOICE_NUMBER' as MatchedFieldsEnum,
        DESCRIPTION: 'DESCRIPTION' as MatchedFieldsEnum,
        RESOLUTION: 'RESOLUTION' as MatchedFieldsEnum
    };
}


