import * as CL from '@design-system/component-library';
import { Link } from 'react-router-dom';
import { ReportStatus } from '../../generated/api/reportStatus.js';
import { downloadReport, generateReport } from '../../common/fetch.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { formatTimeStampToYYYYMMDDHHmm } from '../../common/utils/dateUtils.js';
import { t } from '../../common/i18n/index.js';
import React, { useEffect, useState } from 'react';
import type { ReportItemResponse } from '../../generated/api/reportItemResponse.js';
import type { ReportStatusChange } from '../../generated/api/reportStatusChange.js';
import type { ReportType } from '../../generated/api/reportType.js';

export interface ReportActionProps {
  onlineReportType: ReportType;
  initialReportStatus?: ReportItemResponse;
  latestReportStatusChange?: ReportStatusChange;
}

const generateReportAction = async (
  event: React.SyntheticEvent,
  onlineReportType: ReportType,
  setReportStatus: (reportStatus: ReportItemResponse) => void
) => {
  event.preventDefault();

  setReportStatus({ onlineReportType: onlineReportType, status: ReportStatus.New });
  const response = await generateReport(onlineReportType);
  const responseReportId = (await response.text()) as string;
  if (!response.ok) {
    setReportStatus({
      onlineReportType: onlineReportType,
      status: ReportStatus.Error,
      onlineReportId: responseReportId,
    });
  } else {
    setReportStatus({
      onlineReportType: onlineReportType,
      status: ReportStatus.Running,
      onlineReportId: responseReportId,
    });
  }
};

const exportDataFromURI = (blob: Blob, fileName: string) => {
  const link = document.createElement('a');
  if (link.download !== undefined) {
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

const downloadReportAction = async (event: React.SyntheticEvent, onlineReportType: string) => {
  event.preventDefault();

  const response = await downloadReport(onlineReportType);
  if (response.ok) {
    const blob = await response.blob();
    const header = response.headers.get('Content-Disposition');
    // RegExp for parsing filename from Content-Disposition header
    const fileName = header ? header.split('=')[1].replace(/['"]+/g, '') : 'Report.xlsx';
    exportDataFromURI(blob, fileName);
  }
};

export const ReportAction = ({
  onlineReportType,
  initialReportStatus,
  latestReportStatusChange,
}: ReportActionProps) => {
  const [reportStatus, setReportStatus] = useState<ReportItemResponse | undefined>(initialReportStatus);

  useEffect(() => {
    if (latestReportStatusChange && latestReportStatusChange.onlineReportType === onlineReportType) {
      setReportStatus({
        onlineReportType,
        onlineReportId: latestReportStatusChange.onlineReportId,
        updated: latestReportStatusChange.updated,
        status: latestReportStatusChange.status,
      });
    }
  }, [latestReportStatusChange, onlineReportType]);

  switch (reportStatus?.status) {
    case ReportStatus.New:
    case ReportStatus.Running:
      return (
        <div className={`${dsClass.DISPLAY_FLEX} ${dsClass.ALIGN_ITEMS_CENTER}`}>
          <CL.LoadingSpinner size="xs" className={`${dsClass.MARGIN_RIGHT_2} of-report-list__action`} />
          <div className={`${dsClass.TEXT_XS} ${dsClass.COLOR_NEUTRAL_600}`}>{t.LO81('Report downloading')}</div>
        </div>
      );
    case ReportStatus.Success:
      return (
        <>
          <div>
            <Link to="" onClick={e => downloadReportAction(e, onlineReportType)}>
              {t.APHP('Save file')}
            </Link>
            |
            <Link to="" onClick={e => generateReportAction(e, onlineReportType, setReportStatus)}>
              {t.EB6F('Recreate')}
            </Link>
          </div>
          <div className={dsClass.TEXT_XS}>
            {t.O3X5(
              'File created {}, available for download 24h',
              reportStatus?.updated ? formatTimeStampToYYYYMMDDHHmm(reportStatus.updated) : ''
            )}
          </div>
        </>
      );
    case ReportStatus.Error:
      return (
        <Link to="" onClick={e => generateReportAction(e, onlineReportType, setReportStatus)}>
          <div>{t.T57H('Request a report')}</div>
          <div className={dsClass.COLOR_RED_600}>
            {t.C1R6('An error occurred while generating the report. Please request the report again.')}
          </div>
        </Link>
      );
    default:
      return (
        <Link to="" onClick={e => generateReportAction(e, onlineReportType, setReportStatus)}>
          {t.T57H('Request a report')}
        </Link>
      );
  }
};
