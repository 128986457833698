import * as CL from '@design-system/component-library';
import { InfoText } from './InfoText.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { getActiveAccountMasterId } from '../../selfservice/common/localStorageUtils.js';
import { omaElisaClassicMsg, t } from '../../common/i18n/index.js';
import { setActiveAccount } from '../../selfservice/actions/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import type { State } from '../../selfservice/common/store';

export interface SearchResultRowProps extends SearchResultTargetProps {
  icon: string;
  upperSection: JSX.Element;
  lowerSection: JSX.Element;
  companyName: string;
  activeAccountMasterId?: string;
  accountMasterId?: string;
  displayClassicSiteLink?: boolean;
  additionalHighlightableFields?: AdditionalHighlightableField[];
  matchedFields?: string[];
  setSearchFieldVisible: (visible: boolean) => void;
  onSearchResultClick: () => void;
}

export interface SearchResultBaseProps {
  name?: string;
  companyName: string;
  activeAccountMasterId?: string;
  accountMasterId?: string;
  searchTerms: string[];
  matchedFields: string[];
  setSearchFieldVisible: (visible: boolean) => void;
  onSearchResultClick: () => void;
}

export interface ClassicSiteResultProps {
  classicSiteUrl: string;
}

export interface AdditionalHighlightableField {
  title: string;
  value: string | JSX.Element;
  key: string;
}

interface SearchResultTargetProps {
  linkHref: string;
}

interface ResultRowContainerBaseProps extends SearchResultTargetProps {
  companyName: string;
  children: JSX.Element;
  onSearchResultClick: () => void;
}

type ResultRowContainerClassicSiteProps = ResultRowContainerBaseProps;

interface ResultRowContainerProps extends ResultRowContainerBaseProps {
  setSearchFieldVisible: (visible: boolean) => void;
  activeAccountMasterId?: string;
  accountMasterId?: string;
}

interface ResultContentProps {
  icon: string;
  upperSection: JSX.Element;
  lowerSection: JSX.Element;
  rightSection: JSX.Element;
  additionalHighlightableFields?: AdditionalHighlightableField[];
  matchedFields?: string[];
}

interface RightSectionProps {
  displayClassicSiteLink: boolean;
  linkHref?: string;
  companyName: string;
}

const ResultContent = ({
  icon,
  upperSection,
  lowerSection,
  rightSection,
  additionalHighlightableFields,
  matchedFields,
}: ResultContentProps) => (
  <>
    <div className="result-icon">
      <CL.Icon icon={icon} size="m" />{' '}
    </div>
    <div className={`of-omaelisa-result-row__content ${dsClass.TEXT_S}`}>
      <div className="of-omaelisa-result-row-element-data">
        <div className="data-row">{upperSection}</div>
        <div className="data-row">{lowerSection}</div>
        {additionalHighlightableFields
          ?.filter(field => matchedFields?.includes(field.key) && field.value)
          .map(match => (
            <div className="data-row" key={match.key}>
              <InfoText
                info={
                  <>
                    {match.title}: {match.value}
                  </>
                }
              />
            </div>
          ))}
      </div>
      {rightSection}
    </div>
  </>
);

const ResultRowContainer = ({
  setSearchFieldVisible,
  accountMasterId,
  activeAccountMasterId,
  linkHref,
  companyName,
  children,
  onSearchResultClick,
}: ResultRowContainerProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { consolidatedViews } = useSelector((state: State) => state.config.featureFlags);

  const handleClick = () => {
    if (!linkHref) {
      return;
    }
    setSearchFieldVisible(false);
    onSearchResultClick();
    if (
      !consolidatedViews &&
      accountMasterId &&
      accountMasterId !== (getActiveAccountMasterId() || activeAccountMasterId)
    ) {
      dispatch(setActiveAccount(accountMasterId, false, linkHref, companyName));
    } else {
      navigate(linkHref);
    }
  };

  return (
    <button className="of-omaelisa-result-row" onClick={handleClick}>
      {children}
    </button>
  );
};

const ResultRowContainerClassicSite = ({
  children,
  linkHref,
  onSearchResultClick,
}: ResultRowContainerClassicSiteProps) => {
  const handleClick = () => {
    onSearchResultClick();
    window.location.href = linkHref;
  };

  return (
    <button className="of-omaelisa-result-row" onClick={handleClick}>
      {children}
    </button>
  );
};

const ClassicSiteLink = ({ linkHref }: SearchResultTargetProps) => (
  <div className="classic-link">
    <CL.Link linkStyle="link" linkTarget="_blank" linkHref={linkHref}>
      <CL.Icon icon="external-link" size="s" />
      <span>{t.SLBP(omaElisaClassicMsg)}</span>
    </CL.Link>
  </div>
);

const RightSection = ({ companyName, displayClassicSiteLink, linkHref }: RightSectionProps) => (
  <div
    className={`result-company-name ${dsClass.TEXT_S} ${displayClassicSiteLink ? ' of-omaelisa-result-row__classic' : ''}`}
  >
    {companyName}
    {displayClassicSiteLink && linkHref && <ClassicSiteLink linkHref={linkHref} />}
  </div>
);

export const SearchResultRow = ({
  companyName,
  accountMasterId,
  icon,
  displayClassicSiteLink,
  lowerSection,
  additionalHighlightableFields,
  matchedFields,
  upperSection,
  linkHref,
  setSearchFieldVisible,
  activeAccountMasterId,
  ...rest
}: SearchResultRowProps) => {
  const displayClassicSiteResult = Boolean(displayClassicSiteLink);
  const rightSection = (
    <RightSection displayClassicSiteLink={displayClassicSiteResult} companyName={companyName} linkHref={linkHref} />
  );
  const resultContent = (
    <ResultContent
      icon={icon}
      upperSection={upperSection}
      lowerSection={lowerSection}
      rightSection={rightSection}
      additionalHighlightableFields={additionalHighlightableFields}
      matchedFields={matchedFields}
    />
  );

  if (displayClassicSiteResult) {
    return (
      <ResultRowContainerClassicSite companyName={companyName} linkHref={linkHref} {...rest}>
        {resultContent}
      </ResultRowContainerClassicSite>
    );
  }
  return (
    <ResultRowContainer
      companyName={companyName}
      linkHref={linkHref}
      setSearchFieldVisible={setSearchFieldVisible}
      accountMasterId={accountMasterId}
      activeAccountMasterId={activeAccountMasterId}
      {...rest}
    >
      {resultContent}
    </ResultRowContainer>
  );
};
