import { CustomerOrderList } from '../../../../../components/CustomerOrderList/CustomerOrderList.js';
import {
  EmployeeOrdersBreadcrumb,
  EmployeeOrdersNavi,
  EmployeeOrdersWrapper,
  getFilteredCustomerOrders,
} from '../../../../../components/EmployeeOrdersWrapper/EmployeeOrdersWrapper.js';
import { EmployeeSceneTab } from '../../../../../common/utils/employeeUtils.js';
import { Grid } from '../../../../../components/Grid/Grid.js';
import { generatePath, useNavigate } from 'react-router-dom';
import { loadCustomerOrders, loadSubscriptionActions } from '../../../../../selfservice/actions/index.js';
import { omaElisaForEmployeeMsg, orderRequestsMsg, t } from '../../../../../common/i18n/index.js';
import { onLoadBillingAccounts } from '../../../../../common/utils/dispatcherUtils.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useTitle } from '../../../../../common/hooks/useTitle.js';
import type { State } from '../../../../../selfservice/common/store.js';

export const PendingReviewsPath = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customerOrders = useSelector((state: State) => state.selfservice?.customerOrders);
  const filteredCustomerOrders = getFilteredCustomerOrders(EmployeeSceneTab.ORDERS_REVIEW_PENDING, customerOrders);

  useEffect(() => {
    onLoadBillingAccounts(dispatch);
    dispatch(loadCustomerOrders());
    dispatch(loadSubscriptionActions());
  }, [dispatch]);

  useTitle([t.H6JV(omaElisaForEmployeeMsg), t.Y29L(orderRequestsMsg)]);

  return (
    <EmployeeOrdersWrapper sceneTab={EmployeeSceneTab.ORDERS_REVIEW_PENDING}>
      <EmployeeOrdersBreadcrumb />
      <EmployeeOrdersNavi />
      <Grid>
        <CustomerOrderList
          items={filteredCustomerOrders ? filteredCustomerOrders.items : undefined}
          onClickCustomerOrder={(id: string) => {
            navigate(generatePath(paths.EMPLOYEE_PENDING_REVIEW_ORDER, { subscriptionId: id }));
          }}
          onLoadMoreCustomerOrders={reporting => {
            dispatch(loadCustomerOrders(undefined, undefined, undefined, undefined, reporting));
          }}
          onSearchCustomerOrders={(newSearchValue, status) => {
            dispatch(loadCustomerOrders(undefined, undefined, undefined, newSearchValue, false, undefined, status));
          }}
          onSortCustomerOrders={(newSort, status) => {
            dispatch(loadCustomerOrders(undefined, undefined, newSort, undefined, undefined, undefined, status));
          }}
          search={filteredCustomerOrders?.search}
          sort={filteredCustomerOrders?.sort}
          total={filteredCustomerOrders?.total}
          searchResults={filteredCustomerOrders?.searchResults}
        />
        <p>
          <strong>{t.FZU3(`Can't you see your order here?`)}</strong>{' '}
          {t.QZZR('If you just placed it, no worries. The order will appear here with a little delay.')}
        </p>
      </Grid>
    </EmployeeOrdersWrapper>
  );
};
