import { AddOnExternalLink } from './AddOnExternalLink.js';
import { getAddOnAdditionalInformationMap, getInformationCategoryFromPath } from './subscriptionAddOnUtils.js';
import { getAddOnNamesByCodes } from '../SubscriptionDetails/addOnDependencyUtils.js';
import { t } from '../../common/i18n/index.js';
import { useLocation } from 'react-router-dom';
import type { AddOn } from '../../generated/api/addOn.js';
import type { AddOnEditabilityRecord, BaseRules } from '../SubscriptionDetails/addOnDependencyUtils.js';

interface CheckboxAdditionalInformationProps {
  editRecord?: AddOnEditabilityRecord;
  baseRules: BaseRules;
  subscriptionAddOnCodes: string[];
  addOnCodesToAdd: string[];
  addOnCodesToRemove: string[];
  addOnExternalLinkUrl?: string;
  currentAddOn?: AddOn;
}

export const CheckboxAdditionalInformation = ({
  editRecord,
  baseRules,
  subscriptionAddOnCodes,
  addOnCodesToAdd,
  addOnCodesToRemove,
  addOnExternalLinkUrl,
  currentAddOn,
}: CheckboxAdditionalInformationProps) => {
  const { pathname } = useLocation();
  const informationCategory = getInformationCategoryFromPath(pathname);
  const categories = editRecord?.isEditable ? editRecord.currentCategory : editRecord?.potentialCategory || [];
  const getSelectedCodes = (codesToCheck: string[]) =>
    codesToCheck.filter(codeToCheck =>
      subscriptionAddOnCodes
        .filter(code => !addOnCodesToRemove.includes(code))
        .concat(addOnCodesToAdd)
        .includes(codeToCheck)
    );

  const information = getAddOnAdditionalInformationMap().find(
    info =>
      (info.addOnGroup === currentAddOn?.addOnGroup || info.addOnCode === currentAddOn?.addOnCode) &&
      info.categories.includes(informationCategory)
  )?.information;

  return (
    <>
      {information && <div className="of-add-on-additional-information">{information}</div>}
      {editRecord?.potentialCategory.includes('SYSTEM') && (
        <div>
          {t.J5VG(
            'It is not possible to modify the service in OmaElisa. If necessary, please contact customer service.'
          )}
        </div>
      )}
      {editRecord && categories.includes('DELETE_RELATED') && (
        <div>
          {t.D3MU(
            'The subscription has an additional service ({}), that prevents this service from being activated.',
            getAddOnNamesByCodes(baseRules, editRecord.addOnCodesToDelete).join(', ')
          )}
        </div>
      )}
      {editRecord && (categories.includes('TOO_LESS') || categories.includes('TOO_MANY')) && (
        <div>
          {t.D3MU(
            'The subscription has an additional service ({}), that prevents this service from being activated.',
            getAddOnNamesByCodes(baseRules, getSelectedCodes(editRecord.groupMemberCodes)).join(', ')
          )}
        </div>
      )}
      {addOnExternalLinkUrl && <AddOnExternalLink linkHref={addOnExternalLinkUrl} />}
    </>
  );
};
