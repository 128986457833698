import {
  CompanyInfoContainer,
  CompanyInfoSceneTab,
} from '../../../../../components/CompanyInfoScene/CompanyInfoScene.js';
import { Reports } from '../../../../../components/Reports/Reports.js';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import { fetchReports } from '../../../../../common/fetch.js';
import { isEppSolutionActive } from '../../../../../common/utils/stateUtils.js';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import type { ReportItemResponse } from '../../../../../generated/api/reportItemResponse.js';
import type { State } from '../../../../../selfservice/common/store.js';

export const CompanyInfoReportsPath = () => {
  const companyInfo = useSelector((state: State) => state.selfservice?.companyInfo, deepEqual);
  const latestReportStatusChange = useSelector(
    (state: State) => state.selfservice?.reports?.latestReportStatusChange,
    deepEqual
  );
  const [reportStatuses, setReportStatuses] = useState<ReportItemResponse[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true);
    setError(false);
    fetchReports()
      .then(responseStatuses => setReportStatuses(responseStatuses))
      .catch(() => {
        setError(true);
        setReportStatuses([]);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <CompanyInfoContainer tab={CompanyInfoSceneTab.REPORTS}>
      <Reports
        isEppSolutionActive={isEppSolutionActive(companyInfo) || false}
        reportStatuses={reportStatuses}
        loading={loading}
        error={error}
        latestReportStatusChange={latestReportStatusChange}
      />
    </CompanyInfoContainer>
  );
};
