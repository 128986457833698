import * as CL from '@design-system/component-library';
import { emailMsg, privateCustomersMsg, shopMsg, t } from '../../../common/i18n/index.js';
import type * as CLT from '@design-system/component-library';

export const employeeHeaderAccordions: CLT.HeaderUserMenuProps['userMenuLinkAccordions'] = [
  {
    items: [
      {
        icon: <CL.Icon icon="user" />,
        title: 'OmaElisa',
        url: 'https://verkkoasiointi.elisa.fi',
      },
      {
        icon: <CL.Icon icon="email-address" />,
        title: t.OKYY(emailMsg),
        url: 'https://webmail.elisa.fi',
      },
      {
        icon: <CL.Icon icon="display" />,
        title: 'Elisa Viihde',
        url: 'https://elisaviihde.fi',
      },
      {
        icon: <CL.Icon icon="cart" />,
        title: t.BZYZ(shopMsg),
        url: 'https://elisa.fi/kauppa',
      },
    ],
    onToggle: () => {},
    order: 1,
    title: t.IHET(privateCustomersMsg),
    toggled: false,
  },
];
