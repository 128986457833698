import { IdentityProvider } from '../../generated/api/models.js';
import { isInBrowser } from '../utils/ssrUtils.js';
import { pollForMyselfChanges, stopPollForMyselfChanges } from '../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import type { AuthenticatedUserState } from '../types/states.js';

export const useChangesPoller = (authenticated?: AuthenticatedUserState) => {
  const dispatch = useDispatch();
  // `/myself/changes` polling won't work for punchout (VURBIS and BOOMI).
  const isAuthenticated = Boolean(authenticated?.contact);
  const isPunchout =
    authenticated?.identityProvider === IdentityProvider.BOOMI ||
    authenticated?.identityProvider === IdentityProvider.VURBIS;
  const shouldPollMyselfChanges = isAuthenticated && !isPunchout;

  useEffect(() => {
    const handleVisibilityChange = () =>
      dispatch(document.hidden ? stopPollForMyselfChanges() : pollForMyselfChanges());

    if (isInBrowser() && shouldPollMyselfChanges) {
      // Start polling for changes immediately
      dispatch(pollForMyselfChanges());
      // Add visibility change listener to be able to stop backend polling
      document.addEventListener('visibilitychange', handleVisibilityChange);
      return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
    }
    return undefined;
  }, [dispatch, shouldPollMyselfChanges]);
};
