import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog.js';
import { SubscriptionType } from '../../generated/api/models.js';
import { capitalize } from '../../common/utils/stringUtils.js';
import { continueMsg, t } from '../../common/i18n/index.js';
import type { ChangeSubscriptionContactDialogParams } from '../../common/types/dialog.js';

export const ChangeSubscriptionContactDialog = (props: ChangeSubscriptionContactDialogParams) => {
  const { showOmaLaiteLaskuWarning, subscriptionType, onCancel, onConfirm } = props;
  const subscriptionTypeMsg = subscriptionType === SubscriptionType.DEVICE ? t.ACH1('device') : t.D2UO('subscription');

  return (
    <ConfirmationDialog
      confirmButtonText={t.I62A(continueMsg)}
      header={t.KSXW('Switching user affects {} billing information', subscriptionTypeMsg)}
      body={
        <>
          <p>
            {t.LNHO(
              `{0} reference and the cost center details will be automatically updated according to the new person's information. Please check your {1} billing information if necessary.`,
              capitalize(subscriptionTypeMsg),
              subscriptionTypeMsg
            )}
          </p>
          {showOmaLaiteLaskuWarning ? (
            <div>
              <strong>{t.CNY8('This device has Elisa Oma Laitelasku')}</strong>
              <br />
              <span>
                {t.WMWY(
                  'You are about to change the user for a device that has Elisa Oma Laitelasku service. The remaining employee share will be invoiced from the company.'
                )}
              </span>
              <p>{t.OPI0('The billing account can be changed when confirming the changes.')}</p>
            </div>
          ) : null}
        </>
      }
      onCloseDialog={onCancel}
      onConfirm={onConfirm}
    />
  );
};
