import * as React from 'react';
import { CompositeListSearch } from '../CompositeListSearch/index.js';
import { InputSearch } from '../InputSearch/InputSearch.js';
import { searchMsg, t } from '../../common/i18n/index.js';
import type { ProductGridItem } from './ProductGrid.js';

export const searchFn = (searchString: string) => (item: ProductGridItem) => {
  if (searchString === '') {
    return true;
  }
  const searchKeywords = searchString.toLowerCase().split(' ');
  const searchableKeywords = [...item.name.split(' '), item.manufacturer, ...(item.tags || [])].map(keyword =>
    keyword.toLowerCase()
  );
  return searchKeywords.every(searchKeyword =>
    searchableKeywords.some(searchableKeyword => searchableKeyword.includes(searchKeyword))
  );
};

export interface ProductGridSearchProps {
  onChange: (newValue: string) => void;
  value: string;
}

export interface ProductGridSearchCLProps extends ProductGridSearchProps {
  placeholder: string;
}

export const ProductGridSearch = ({ onChange, value }: ProductGridSearchProps) => (
  <CompositeListSearch placeholder={t.VQE6(searchMsg)} onChange={onChange} value={value} />
);

export const ProductGridSearchCL = ({ onChange, placeholder, value }: ProductGridSearchCLProps) => (
  <div className="of-product-grid-search">
    <InputSearch
      placeholder={placeholder}
      onChange={(e: React.SyntheticEvent<HTMLInputElement>) => onChange(e.currentTarget.value)}
      value={value}
    />
  </div>
);
