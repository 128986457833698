import { NumberRange } from '../../generated/api/models.js';
import {
  companyNumberMsg,
  languageMsg,
  mobilePhoneNumberMsg,
  selectedExtensionNumberingsMsg,
  t,
} from '../../common/i18n/index.js';
import { getLanguageDisplayValue } from '../../common/utils/languageUtils.js';
import type * as CL from '@design-system/component-library';
import type { CallerLineIdType, SubscriptionDetailsPbxSolution } from '../../generated/api/models.js';

const callerLineIdMap = () => ({
  PSTN: t.ZX32(companyNumberMsg),
  MOBILE: t.FRYN(mobilePhoneNumberMsg),
});

const getMappedCallerLineId = (callerLineIdType: CallerLineIdType): string => callerLineIdMap()[callerLineIdType];

export const getPbxFields = (
  numberRange: NumberRange[] = [],
  numberFormatter: (numberRanges: NumberRange[], filterCondition: NumberRange.RangeTypeEnum) => JSX.Element[]
): CL.DescriptionItem[] => [
  {
    description: (
      <>
        <label>{t.I9BN('Reserved corporate numbering space')}</label>
        {numberFormatter(numberRange, NumberRange.RangeTypeEnum.PSTN)}
      </>
    ),
  },
  {
    description: (
      <>
        <label>{t.FIS5(selectedExtensionNumberingsMsg)}</label>
        {numberFormatter(numberRange, NumberRange.RangeTypeEnum.EXTENSION)}
      </>
    ),
  },
];

export const getPbxSettingsFields = (pbxDetails: SubscriptionDetailsPbxSolution): CL.DescriptionItem[] => [
  {
    description: (
      <>
        <label>{t.L3C5(languageMsg)}</label>
        {pbxDetails.solutionLanguage && getLanguageDisplayValue(pbxDetails.solutionLanguage)}
      </>
    ),
  },
  {
    description: (
      <>
        <label>{t.SE27('Number to display for calls')}</label>
        {pbxDetails.callerLineIdForCalls && getMappedCallerLineId(pbxDetails.callerLineIdForCalls)}
      </>
    ),
  },
  {
    description: (
      <>
        <label>{t.AGM7('Number to display for SMS messages')}</label>
        {pbxDetails.callerLineIdForSms && getMappedCallerLineId(pbxDetails.callerLineIdForSms)}
      </>
    ),
  },
];
