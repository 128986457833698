import { AUTH_REDIRECTURI_SESSION_KEY, AUTH_STATE_SESSION_KEY } from '../../../selfservice/common/localStorageUtils.js';
import { fetchElisaIdV2LoginMetadata, fetchElisaIdV2LogoutMetadata } from '../../../common/fetch.js';
import type { NavigateFunction } from 'react-router-dom';

const prepareElisaIdV2AuthUrl = async (noPrompt: boolean, locale?: string): Promise<string> => {
  const elisaIdV2LoginResponse = await fetchElisaIdV2LoginMetadata();
  sessionStorage.setItem(AUTH_STATE_SESSION_KEY, elisaIdV2LoginResponse.state);
  const elisaIdUrl = new URL(elisaIdV2LoginResponse.authUrl);
  const redirectUri = window.location.origin + decodeURIComponent(window.location.pathname);
  sessionStorage.setItem(AUTH_REDIRECTURI_SESSION_KEY, redirectUri);
  elisaIdUrl.searchParams.set('redirect_uri', redirectUri);
  if (locale) {
    elisaIdUrl.searchParams.set('ui_locales', locale);
  }
  if (noPrompt) {
    elisaIdUrl.searchParams.set('prompt', 'none');
  }
  return elisaIdUrl.toString();
};

const prepareElisaIdV2LogoutUrl = async (postLogoutRedirectUri: string) => {
  const elisaIdV2LogoutResponse = await fetchElisaIdV2LogoutMetadata();
  const elisaIdUrl = new URL(elisaIdV2LogoutResponse.logoutUrl);
  elisaIdUrl.searchParams.set('post_logout_redirect_uri', postLogoutRedirectUri);
  elisaIdUrl.searchParams.set('client_id', elisaIdV2LogoutResponse.clientId);
  return elisaIdUrl.toString();
};

export const initiateElisaIdV2LoginWithNoPrompt = async () => {
  window.location.href = await prepareElisaIdV2AuthUrl(true);
};

export const initiateElisaIdV2Login = async (locale: string) => {
  window.location.href = await prepareElisaIdV2AuthUrl(false, locale);
};

export const removeElisaIdQueryParams = (pathname: string, navigate: NavigateFunction) => {
  navigate(pathname, { replace: true });
};

export const logoutElisaIdV2session = async (postLogoutRedirectUri: string) => {
  window.location.href = await prepareElisaIdV2LogoutUrl(postLogoutRedirectUri);
};
