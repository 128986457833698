/**
 * Online UI API
 * # Summary Elisa B2B Online UI API  # Usage This API is used in the `https://yrityksille.elisa.fi/` pages.  # Identifiers 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Turbonappi order\'s status.
 */
export type TurbonappiOrderStatus = 'PAID' | 'PAYMENT_REGISTERED' | 'ACTIVATED' | 'TECHNICAL_ERROR' | 'PAYMENT_ERROR' | 'ACTIVATION_ERROR' | 'NOT_PAID' | 'PAYMENT_TRANSACTION_VALID';

export const TurbonappiOrderStatus = {
    PAID: 'PAID' as TurbonappiOrderStatus,
    PAYMENT_REGISTERED: 'PAYMENT_REGISTERED' as TurbonappiOrderStatus,
    ACTIVATED: 'ACTIVATED' as TurbonappiOrderStatus,
    TECHNICAL_ERROR: 'TECHNICAL_ERROR' as TurbonappiOrderStatus,
    PAYMENT_ERROR: 'PAYMENT_ERROR' as TurbonappiOrderStatus,
    ACTIVATION_ERROR: 'ACTIVATION_ERROR' as TurbonappiOrderStatus,
    NOT_PAID: 'NOT_PAID' as TurbonappiOrderStatus,
    PAYMENT_TRANSACTION_VALID: 'PAYMENT_TRANSACTION_VALID' as TurbonappiOrderStatus
};

