import * as CL from '@design-system/component-library';
import { CursorPagination } from '../CursorPagination/CursorPagination.js';
import { ItemsPerPage, getItemsPerPageFromOptionsOrDefault, getItemsPerPageOptions } from './ItemsPerPage.js';
import { TableUrlParams } from './tableConstants.js';
import { calculateTotalPages } from '../../common/utils/numberUtils.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { t } from '../../common/i18n/index.js';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import './ListPagination.scss';

export interface ListPaginationProps {
  itemsPerPageOptions?: Array<number>;
  totalItems: number;
  cursorNavigation?: boolean;
  prevCursor?: string;
  nextCursor?: string;
  onItemsPerPageUpdated?: (items: number) => void;
}

export const ListPagination = ({
  itemsPerPageOptions,
  totalItems,
  cursorNavigation = false,
  prevCursor,
  nextCursor,
  onItemsPerPageUpdated,
}: ListPaginationProps) => {
  const { hash, pathname, search } = useLocation();
  const navigate = useNavigate();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const currentPage = Number(queryParams.get(TableUrlParams.PAGE) || '1');
  const limit = queryParams.get(TableUrlParams.LIMIT) || undefined;
  const calculatedItemsPerPageOptions = getItemsPerPageOptions(totalItems, itemsPerPageOptions);
  const itemsPerPage = getItemsPerPageFromOptionsOrDefault(limit, calculatedItemsPerPageOptions);

  const resetPagingParams = useCallback(() => {
    queryParams.set(TableUrlParams.PAGE, '1');
    if (cursorNavigation) {
      queryParams.delete(TableUrlParams.CURSOR);
    } else {
      queryParams.set(TableUrlParams.OFFSET, '0');
    }
  }, [cursorNavigation, queryParams]);

  const totalPages = itemsPerPage ? calculateTotalPages(Number(itemsPerPage), totalItems) : 1;

  const setPage = (page: number) => {
    queryParams.set(TableUrlParams.PAGE, page.toString());
    queryParams.set(TableUrlParams.OFFSET, (itemsPerPage ? (page - 1) * itemsPerPage : 0).toString());
    navigate(`${pathname}?${queryParams}${hash}`);
  };

  const setCursor = (page: number, cursor?: string) => {
    queryParams.set(TableUrlParams.PAGE, page.toString());
    cursor !== undefined ? queryParams.set(TableUrlParams.CURSOR, cursor) : queryParams.delete(TableUrlParams.CURSOR);
    navigate(`${pathname}?${queryParams}${hash}`);
  };

  const onNextPageClick = () => {
    if (cursorNavigation) {
      setCursor(currentPage + 1, nextCursor);
    } else {
      setPage(currentPage + 1);
    }
  };

  const onPreviousPageClick = () => {
    if (cursorNavigation) {
      setCursor(Math.max(currentPage - 1, 1), prevCursor);
    } else {
      setPage(currentPage - 1);
    }
  };

  const handleItemsPerPageUpdated = (itemsPerPageSelected: string) => {
    // Return to first page when items per page selection updated
    resetPagingParams();
    queryParams.set(TableUrlParams.LIMIT, itemsPerPageSelected);
    navigate(`${pathname}?${queryParams}${hash}`, { replace: true });

    if (onItemsPerPageUpdated) {
      onItemsPerPageUpdated(Number(itemsPerPageSelected));
    }
  };

  return (
    <div className={dsClass.MARGIN_BOTTOM_4}>
      {totalItems > 0 && (
        <div className="of-list-paging-options">
          <ItemsPerPage
            id="list-pagination-items-per-page"
            currentPage={currentPage}
            cursorNavigation={cursorNavigation}
            itemsPerPage={itemsPerPage}
            itemsPerPageOptions={calculatedItemsPerPageOptions}
            onItemsPerPageUpdated={handleItemsPerPageUpdated}
            totalItems={totalItems}
          />
          {cursorNavigation ? (
            <CursorPagination
              prevCursor={prevCursor}
              nextCursor={nextCursor}
              onNextPageClick={onNextPageClick}
              onPreviousPageClick={onPreviousPageClick}
            />
          ) : (
            <CL.PaginationNav
              currentPage={currentPage}
              i18n_pagination_nextLabel={t.F0MY('Next')}
              i18n_pagination_previousLabel={t.CACI('Previous')}
              onNextPageClick={onNextPageClick}
              onPageSelect={page => setPage(page)}
              onPreviousPageClick={onPreviousPageClick}
              showAmount={5}
              totalPages={totalPages}
            />
          )}
        </div>
      )}
    </div>
  );
};
