import { AuthenticatedUserRole } from '../../generated/api/models.js';
import { ChatResourceLoadStatus } from '../../common/enums.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { isInBrowser } from '../../common/utils/ssrUtils.js';
import { loadingWithThreePeriodsMsg, openChatMsg, t } from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import { refreshChatButtonText } from './chatUtils.js';
import { setScriptLoadStatus, setStyleLoadStatus } from '../../selfservice/actions/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import type { AuthenticatedUserState } from '../../common/types/states.js';
import type { State } from '../../selfservice/common/store.js';

declare global {
  // Resides in typescript/lib/lib.dom.d.ts, but can't be imported
  interface Window {
    // eslint-disable-next-line
    embedded_svc: any;
  }
}

interface UserSpecificConfigProps {
  chatDeployment: string;
  channel: string;
  minimizedText: string;
  buttonId?: string;
  eswLiveAgentDevName?: string;
}

enum ChatStatus {
  NOT_LOADED = 'NOT_LOADED',
  IN_PROGRESS = 'IN_PROGRESS',
  READY_WITH_TOKEN = 'READY_WITH_TOKEN',
  READY = 'READY',
}

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const SnapInChat = () => {
  const dispatch = useDispatch();
  const { authenticatedUser } = useAuth();
  const chatState = useSelector((state: State) => state.snapInsChat, deepEqual);
  const chatConfig = useStore<State>().getState().config.snapInsChat;
  const AUTHENTICATION_WAIT_TIME = 3000; // Milliseconds to wait before starting chat initialization for unauthenticated user
  const [chatStatus, setChatStatus] = useState(ChatStatus.NOT_LOADED);

  const isInitialized = useRef<boolean>(false);

  const isEmployeePortal = () => decodeURI(window?.location?.pathname)?.startsWith(paths.EMPLOYEE_HOME);
  const isAuthenticated = () => !!authenticatedUser?.chatToken;
  const isChatEnabled = () => (isEmployeePortal() ? chatConfig?.employeeEnabled : chatConfig?.adminEnabled);

  const getAuthenticatedEmployeeChatConfig = () => {
    return {
      chatDeployment: 'Sis_nkirjautuneiden_TOE_chat',
      channel: 'Sisäänkirjautuneiden TOE chat',
      minimizedText: t.OZEV(openChatMsg),
      buttonId: chatConfig!.employeeLoggedInButtonId,
      eswLiveAgentDevName: chatConfig!.employeeLoggedInEswLiveAgentDevName,
    };
  };

  const getUserSpecificChatConfig = (): {
    chatDeployment: string;
    channel: string;
    minimizedText: string;
    buttonId?: string;
    eswLiveAgentDevName?: string;
  } => {
    const loggedIn = isAuthenticated();
    if (isEmployeePortal()) {
      if (!loggedIn) {
        return {
          chatDeployment: 'Julkinen_TOE_chat',
          channel: 'Julkinen TOE chat',
          minimizedText: t.OZEV(openChatMsg),
          buttonId: chatConfig!.employeePublicButtonId,
          eswLiveAgentDevName: chatConfig!.employeePublicEswLiveAgentDevName,
        };
      } else {
        return getAuthenticatedEmployeeChatConfig();
      }
    } else if (loggedIn && authenticatedUser?.userRole === AuthenticatedUserRole.KEY_USER) {
      return {
        chatDeployment: 'Chatbot_loggedin_chat',
        channel: 'Chatbot sisäänkirjautuneiden chat',
        minimizedText: t.OZEV(openChatMsg),
        buttonId: chatConfig!.adminLoggedInButtonId,
        eswLiveAgentDevName: chatConfig!.adminLoggedInEswLiveAgentDevName,
      };
    } else if (loggedIn && authenticatedUser?.userRole === AuthenticatedUserRole.EMPLOYEE) {
      return getAuthenticatedEmployeeChatConfig();
    } else {
      return {
        chatDeployment: 'Chatbot_public_chat',
        channel: 'Chatbot Julkinen Chat',
        minimizedText: t.UQDI('Ask Oscar'),
        buttonId: chatConfig!.publicButtonId,
        eswLiveAgentDevName: chatConfig!.publicEswLiveAgentDevName,
      };
    }
  };

  function isExternalResourcesLoaded() {
    return (
      Boolean(window.embedded_svc?.init) &&
      chatState?.scriptLoadStatus === ChatResourceLoadStatus.READY &&
      chatState?.styleLoadStatus === ChatResourceLoadStatus.READY
    );
  }

  // NOTE: We are using same URL for baseUrl in all environments. Using env specific URL caused
  // Firefox to block the content due to "tracking site".
  async function startChat() {
    const userSpecificConfig = getUserSpecificChatConfig();
    window.embedded_svc.init(
      chatConfig!.baseUrl,
      chatConfig!.devEnvUrl,
      chatConfig!.baseUrl,
      chatConfig!.organizationId,
      userSpecificConfig.chatDeployment,
      {
        baseLiveAgentContentURL: chatConfig!.baseLiveAgentContentURL,
        deploymentId: chatConfig!.deploymentId,
        buttonId: userSpecificConfig.buttonId,
        baseLiveAgentURL: chatConfig!.baseLiveAgentURL,
        eswLiveAgentDevName: userSpecificConfig.eswLiveAgentDevName,
        isOfflineSupportEnabled: false,
      }
    );
    isInitialized.current = true;
    if (isAuthenticated()) {
      setChatStatus(ChatStatus.READY_WITH_TOKEN);
      await refreshChatButtonText(chatConfig);
    } else {
      setChatStatus(ChatStatus.READY);
    }
  }

  function setCommonConfiguration(userSpecificConfig: UserSpecificConfigProps) {
    window.embedded_svc.settings.externalScripts = ['CustomEvents_AddButtons'];
    window.embedded_svc.settings.externalStyles = ['CustomEvents_AddButtons_Stylesheet'];
    window.embedded_svc.settings.displayHelpButton = true; // Or false
    window.embedded_svc.settings.language = 'fi'; // For example, enter 'en' or 'en-US'
    window.embedded_svc.settings.disabledMinimizedText = t.MOWN('No agents available'); // (Defaults to Agent Offline)
    window.embedded_svc.settings.loadingText = t.P1Y9(loadingWithThreePeriodsMsg); // (Defaults to Loading)
    window.embedded_svc.settings.storageDomain = 'elisa.fi';
    window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
    window.embedded_svc.settings.entryFeature = 'LiveAgent';
    window.embedded_svc.settings.defaultMinimizedText = userSpecificConfig.minimizedText; // (Defaults to Chat with an Expert)
    window.embedded_svc.settings.buttonId = userSpecificConfig.buttonId;
  }

  function setLoggedInUsersConfiguration(
    userSpecificConfig: UserSpecificConfigProps,
    authUser: AuthenticatedUserState
  ) {
    window.embedded_svc.settings.extraPrechatFormDetails = [
      {
        label: 'Online User Name',
        value: authUser.userName,
        displayToAgent: true,
        transcriptFields: ['SSO_ID__c'],
      },
      {
        label: 'Business ID',
        value: authUser.businessId,
        displayToAgent: true,
        transcriptFields: ['Business_Id__c'],
      },
      {
        label: 'FirstName',
        value: authUser.firstName,
        displayToAgent: true,
        transcriptFields: ['FirstName__c'],
      },
      {
        label: 'LastName',
        value: authUser.lastName,
        displayToAgent: true,
        transcriptFields: ['LastName__c'],
      },
      {
        label: 'Email Address',
        value: authUser.email,
        displayToAgent: true,
        transcriptFields: ['Email__c'],
      },
      {
        label: 'Phone Number',
        value: authUser.mobile,
        displayToAgent: true,
        transcriptFields: ['Phone_Number__c'],
      },
      {
        label: 'Customer System',
        value: 'ONLINE',
        displayToAgent: true,
      },
      {
        label: 'Case Record Type Id',
        value: chatConfig!.caseRecordTypeId,
        displayToAgent: true,
      },
      {
        label: 'Case Origin',
        value: 'Chat', // config.chatRecordTypeId
        displayToAgent: true,
      },
      {
        label: 'Channel',
        value: userSpecificConfig.channel,
        displayToAgent: true,
      },
      {
        label: 'Status',
        value: 'Open',
        displayToAgent: true,
      },
      {
        label: 'Online User Token',
        value: authenticatedUser?.chatToken || 'invalid',
        transcriptFields: ['Online_User_Token__c'],
        displayToAgent: true,
      },
    ];

    window.embedded_svc.settings.extraPrechatInfo = [
      {
        entityFieldMaps: [
          {
            doCreate: false,
            doFind: false,
            fieldName: 'Business_ID__c',
            isExactMatch: true,
            label: 'Business ID',
          },
        ],
        entityName: 'Account',
        saveToTranscript: 'AccountId',
        linkToEntityName: 'Case',
        linkToEntityField: 'AccountId',
      },
      {
        entityFieldMaps: [
          {
            doCreate: false,
            doFind: false,
            fieldName: 'Online_User_Name__c',
            isExactMatch: true,
            label: 'Online User Name',
          },
          {
            doCreate: true,
            doFind: false,
            fieldName: 'FirstName',
            isExactMatch: false,
            label: 'FirstName',
            displayToAgent: true,
            transcriptFields: ['FirstName__c'],
          },
          {
            doCreate: true,
            doFind: false,
            fieldName: 'LastName',
            isExactMatch: false,
            label: 'LastName',
            transcriptFields: ['LastName__c'],
          },
          {
            doCreate: true,
            doFind: false,
            fieldName: 'Email',
            isExactMatch: false,
            label: 'Email',
            transcriptFields: ['Email__c'],
          },
        ],
        entityName: 'Contact',
        linkToEntityName: 'Case',
        saveToTranscript: 'ContactId',
        linkToEntityField: 'ContactId',
      },
      {
        entityFieldMaps: [
          {
            doCreate: true,
            doFind: false,
            fieldName: 'RecordTypeId',
            isExactMatch: true,
            label: 'Case Record Type Id',
          },
          {
            doCreate: true,
            doFind: false,
            fieldName: 'SuppliedEmail',
            isExactMatch: true,
            label: 'Email Address',
          },
          {
            doCreate: true,
            doFind: false,
            fieldName: 'Phone_Number__c',
            isExactMatch: true,
            label: 'Phone Number',
          },
          {
            doCreate: true,
            doFind: false,
            fieldName: 'Origin',
            isExactMatch: true,
            label: 'Case Origin',
          },
          {
            doCreate: true,
            doFind: false,
            fieldName: 'Channel__c',
            isExactMatch: true,
            label: 'Channel',
          },
          {
            doCreate: true,
            doFind: false,
            fieldName: 'Status',
            isExactMatch: true,
            label: 'Status',
          },
        ],
        entityName: 'Case',
        showOnCreate: true,
        saveToTranscript: 'CaseId',
      },
    ];
  }

  function setPublicConfiguration(userSpecificConfig: UserSpecificConfigProps) {
    window.embedded_svc.settings.extraPrechatFormDetails = [
      {
        label: 'Case Origin',
        value: 'Chat',
        displayToAgent: true,
      },
      {
        label: 'Channel',
        value: userSpecificConfig.channel,
        displayToAgent: true,
      },
      {
        label: 'Status',
        value: 'Open',
        displayToAgent: true,
      },
    ];
    window.embedded_svc.settings.extraPrechatInfo = [
      {
        entityFieldMaps: [
          {
            doCreate: true,
            doFind: false,
            fieldName: 'Origin',
            isExactMatch: true,
            label: 'Case Origin',
          },
          {
            doCreate: true,
            doFind: false,
            fieldName: 'Channel__c',
            isExactMatch: true,
            label: 'Channel',
          },
          {
            doCreate: true,
            doFind: false,
            fieldName: 'Status',
            isExactMatch: true,
            label: 'Status',
          },
        ],
        entityName: 'Case',
        showOnCreate: true,
        saveToTranscript: 'CaseId',
      },
    ];

    window.embedded_svc.settings.extraPrechatFormDetails.push({
      label: 'Contact Name',
      value: chatConfig?.anonymousContactId,
      displayToAgent: true,
    });
    window.embedded_svc.settings.extraPrechatInfo[0].entityFieldMaps.push({
      doCreate: true,
      doFind: false,
      fieldName: 'ContactId',
      isExactMatch: true,
      label: 'Contact Name',
    });
  }

  function setChatConfiguration() {
    const userSpecificConfig = getUserSpecificChatConfig();

    setCommonConfiguration(userSpecificConfig);

    if (isAuthenticated() && authenticatedUser) {
      setLoggedInUsersConfiguration(userSpecificConfig, authenticatedUser);
    } else {
      setPublicConfiguration(userSpecificConfig);
    }
  }

  function initSnapInChat() {
    setChatStatus(ChatStatus.IN_PROGRESS);
    if (chatConfig && isInBrowser()) {
      /* eslint-disable */
      window['embedded_svc'] = window['embedded_svc'] || [];
      if (!window['embedded_svc']['settings']) {
        window['embedded_svc']['settings'] = {};
      }
      /* eslint-enable */
      setChatConfiguration();
      // No need to resolve the promise, async function doesn't return anything
      startChat();
    }
  }

  useEffect(() => {
    // There has been issues where external scripts are loaded multiple times and chat cannot be started due to JS failures.
    // The root cause is probably some parent component unmounting and remounting. Component's state cannot be used
    // for checking if resources are loaded, so we have to save the load information in Redux.
    if (isInBrowser() && isChatEnabled() && chatConfig && !chatState?.scriptLoadStatus) {
      dispatch(setScriptLoadStatus(ChatResourceLoadStatus.IN_PROGRESS));
      const script = document.createElement('script');
      document.body.appendChild(script);
      script.src = chatConfig.baseUrl + chatConfig.source;
      script.async = true;
      script.onload = () => {
        dispatch(setScriptLoadStatus(ChatResourceLoadStatus.READY));
      };

      const styles = document.createElement('style');
      document.body.appendChild(styles);
      styles.setAttribute('type', 'text/css');
      // Chat modal's minimize and closing icons are not visible in iPhone without "!important" (https://atlas.elisa.fi/jira/browse/OFI-38735)
      styles.textContent = `
        button[embeddedService-chatHeader_chatHeader] {
          font-size: 9px !important;
        }
        .embeddedServiceHelpButton .helpButton .uiButton {
          background-color: #0019af;
        }
        .embeddedServiceHelpButton .helpButton .uiButton:focus {
          outline: 0px solid #0019af;
        }
        .embeddedServiceSidebar.modalContainer {
          z-index: 10000;
        }`;

      styles.onload = () => {
        dispatch(setStyleLoadStatus(ChatResourceLoadStatus.READY));
      };
      dispatch(setStyleLoadStatus(ChatResourceLoadStatus.IN_PROGRESS));
      // Return undefined instead of cleaning anything when component is unmounted. Script cannot be programmatically
      // removed from browser memory without page refresh and if we remove the style we would need to create additional
      // logic to check if it's removed.
      return undefined;
    }
    return undefined;
  }, []); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      chatStatus !== ChatStatus.IN_PROGRESS &&
      chatStatus !== ChatStatus.READY_WITH_TOKEN &&
      isExternalResourcesLoaded() &&
      isChatEnabled()
    ) {
      (async () => {
        // In some cases e.g. when navigating to public shop page component rendering starts before user data is ready,
        // so we must wait and see if authentication data (chat token) will follow. If initialization is done multiple
        // times in a very short period chat will crash to internal Salesforce script error.
        if (!isAuthenticated()) {
          await sleep(AUTHENTICATION_WAIT_TIME);
          if (isInitialized.current) {
            return;
          }
          initSnapInChat();
        } else {
          initSnapInChat();
        }
      })();
    }
  }, [chatState, chatStatus, authenticatedUser]); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps

  // atm chat does not need return any html, based on react docs null should be ok.
  return null;
};
