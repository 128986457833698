import { type OpenForm } from './useOpenForm.js';
import { OpenFormAction } from '../OpenFormReducer.js';
import { type OpenFormAnswers } from '../OpenFormAnswers.js';
import { getIndex, parsePath } from '../OpenFormUtils.js';
import { paths } from '../../../common/constants/pathVariables.js';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOpenFormDispatch } from '../OpenFormProvider.js';
import type { FormId, Page } from '../../../common/constants/pathInterfaces.js';
import type { OpenFormSection } from '../../../generated/api/openFormSection.js';

export const useOpenFormNavigation = (
  { formId, ...params }: Partial<FormId & Page>,
  preview: boolean,
  form: OpenForm | undefined,
  answers: OpenFormAnswers
): [number, (to: number) => void, () => void, () => void] => {
  const dispatch = useOpenFormDispatch();
  const navigate = useNavigate();
  const max = useMemo(() => (!form?.sections.length ? 0 : form.sections.length + 1), [form]);
  const page = useMemo(() => getIndex(Number(params.page), { max }), [params.page, max]);
  const route = useMemo(() => (preview ? paths.OPENFORM_PREVIEW : paths.OPENFORM_VIEW), [preview]);

  const path = useCallback(
    (to: number) =>
      navigate(parsePath<FormId & Page>(route, { formId, page: getIndex(to, { max }) }), { replace: true }),
    [formId, navigate, max, route]
  );

  const step = useCallback(
    (slice: OpenFormSection[] = []) => Math.abs(slice.findIndex((section, i) => i && answers.isActive(section) && i)),
    [answers]
  );

  useEffect(() => {
    if (formId && max) {
      dispatch(OpenFormAction.page({ formId, page }));
      path(page);
    }
  }, [formId, dispatch, max, page, path]);

  return [
    page,
    path,
    useCallback(() => path(page + step(form?.sections.slice(page - 1))), [form, page, path, step]),
    useCallback(() => path(page - step(form?.sections.slice(0, page).reverse())), [form, page, path, step]),
  ];
};
