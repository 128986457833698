import { GutterStyle, SelectOneList } from '../SelectOneList/SelectOneList.js';
import { prereserveNumbers } from '../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import type { SelectOneListItemProps } from '../SelectOneList/SelectOneList.js';

import './SelectNumberList.scss';

export interface SelectNumberListProps {
  disabledNumbers: string[];
  getNewNumbersLinkText?: string;
  inset?: boolean;
  numbers: string[];
  onSelectNumber: (selectedNumber: string) => void;
  selectedNumber?: string;
  showMoreNumbersLink?: boolean;
}

const prettifyNumber = (inputNumber: string): string => {
  if (inputNumber.charAt(0) === '+') {
    inputNumber = inputNumber.replace('+358', '0'); // Should we replace something else? As +358 international prefix omits 0 national prefix, we will add it here
  }

  return inputNumber.toString().replace(/(\d{3})(\d{3})+(\d{4})/g, '$1 $2 $3');
};

export const isNumberDisabled = (phoneNumber: string, disabledNumbers: string[]): boolean => {
  return disabledNumbers.some((disabledNumber: string) => disabledNumber === phoneNumber);
};

export const SelectNumberList = ({
  disabledNumbers,
  getNewNumbersLinkText,
  inset,
  numbers,
  onSelectNumber,
  selectedNumber,
  showMoreNumbersLink = true,
}: SelectNumberListProps) => {
  const dispatch = useDispatch();
  const options: SelectOneListItemProps[] = numbers.map(phoneNumber => ({
    element: prettifyNumber(phoneNumber),
    isDisabled: isNumberDisabled(phoneNumber, disabledNumbers) && phoneNumber !== selectedNumber,
    isSelected: phoneNumber === selectedNumber,
    onClick: () => !isNumberDisabled(phoneNumber, disabledNumbers) && onSelectNumber(phoneNumber),
  }));

  return (
    <div className={`of-select-number-list ${inset ? 'of-select-number-list--inset' : ''}`}>
      <SelectOneList
        {...{
          gutterStyle: GutterStyle.Minimal,
          maxItemsAsideDesktop: 3,
          maxItemsAsideLaptop: 2,
          maxItemsAsideTablet: 1,
          options: options,
        }}
      />
      {showMoreNumbersLink && getNewNumbersLinkText != null && (
        <button
          className="of-select-number-list__more-numbers-link"
          onClick={() => dispatch(prereserveNumbers(undefined, undefined))}
          type="button"
        >
          {getNewNumbersLinkText}
        </button>
      )}
    </div>
  );
};
