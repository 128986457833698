import { Outlet } from 'react-router-dom';
import { TurbonappiContextProvider } from './TurbonappiContextProvider.js';

export const TurbonappiContextLayout = () => {
  return (
    <TurbonappiContextProvider initialData={{}}>
      <Outlet />
    </TurbonappiContextProvider>
  );
};
