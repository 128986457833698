import { Outlet } from 'react-router-dom';
import { useOpenFormAccount } from './OpenFormHooks/useOpenFormAccount.js';
import { useReload } from './OpenFormHooks/useReload.js';
import type { RealAuthenticatedUserState } from '../../common/types/states.js';

export const OpenFormOutletContext = ({ user }: { user: RealAuthenticatedUserState }) => {
  const [reloading, reload] = useReload();
  const account = useOpenFormAccount();
  return reloading ? null : <Outlet context={{ account, reload, user }} />;
};
