import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import nextId from "react-id-generator";
import Tippy from "@tippy.js/react";

// TODO: should touched be in the state of this component instead of a prop ?
// TODO: rename active prop to selected to match PL?

export const FINNISH_NUMBER_PATTERN = "^(\\+358|0)[0-9-+s()\\s]*$";

class Input extends React.Component {
  state = {
    togglePasswordVisibility: false,
  };
  constructor(props) {
    super(props);
    this.getPrependedIcon = this.getPrependedIcon.bind(this);
    this.getInputAddon = this.getInputAddon.bind(this);
    this.getInputButton = this.getInputButton.bind(this);
  }

  getPrependedIcon() {
    const { icon } = this.props;
    return (
      icon && (
        <span className="ea-input__addon">
          <i className={classnames("ea-icon", `ea-icon--${icon}`)} />
        </span>
      )
    );
  }

  handlePasswordIconToggle = () => {
    this.props.toggleInputType(this.state.togglePasswordVisibility);
    this.setState({
      togglePasswordVisibility: !this.state.togglePasswordVisibility,
    });
  };

  getInputAddon() {
    const { onClearInput, pending, elementType, appendedButtonIcon, disableAutoStatus } = this.props;
    if (elementType === "textarea" || appendedButtonIcon) {
      // textareas or inputs with appended buttons should not have any icons appended
      return null;
    } else if (!pending && onClearInput) {
      return (
        <span className="ea-input__addon" onClick={onClearInput}>
          <i className="ea-icon ea-icon--input-clear" />
        </span>
      );
    } else if (this.props.showHidePasswordIcon) {
      return (
        <span className="ea-input__addon" onClick={() => this.handlePasswordIconToggle()}>
          <i
            className={classnames("ea-icon", "ea-icon--custom", {
              "ea-icon--hidden": !this.state.togglePasswordVisibility,
              "ea-icon--visible": this.state.togglePasswordVisibility,
            })}
          />
        </span>
      );
    }
    return disableAutoStatus ? null : <span className="ea-input__addon ea-input__addon--auto-status" />;
  }

  getInputButton() {
    if (this.props.appendedButtonIcon) {
      return (
        <span
          onClick={this.props.onAppendedButtonClick}
          onKeyPress={this.props.onAppendedButtonClick}
          className="ea-input__button"
          role="button"
          tabIndex={0}
        >
          <i className={`ea-icon ea-icon--${this.props.appendedButtonIcon}`} />
        </span>
      );
    }
    return undefined;
  }

  getPattern(pattern, type) {
    if (pattern) {
      return pattern;
    } else {
      switch (type) {
        case "tel":
          return "^[0-9-+s()]*$";
        case "tel-fin":
          return FINNISH_NUMBER_PATTERN;
      }
    }
  }

  getInputType(type) {
    if (type === "tel-fin") {
      return "tel";
    } else {
      return type;
    }
  }

  getDisabledIcon(disabled, disabledTooltipText) {
    if (!disabled) {
      return "";
    }
    const icon = <span className="ea-input__disabled ea-icon ea-icon--lock" />;
    if (disabledTooltipText) {
      return (
        <Tippy content={disabledTooltipText} className="ea-tooltip" theme="ea-tooltip" arrow maxWidth="300px">
          {icon}
        </Tippy>
      );
    }
    return icon;
  }

  render() {
    const {
      id,
      active,
      appendedButtonIcon,
      className,
      error,
      value,
      elementType,
      pending,
      wide,
      tight,
      tightWithLabel,
      tightBottom,
      label,
      name,
      placeholder,
      type,
      onFocus,
      onChange,
      required,
      autoFocus,
      onKeyDown,
      onBlur,
      touched,
      helpText,
      disabled,
      disabledTooltipText,
      maxLength,
      pattern,
      style,
      autoComplete,
      htmlValidation,
      htmlValidationError,
      optionalText,
      textareaRows,
      readOnly,
      disablePlaceholder,
      alwaysShowLabel,
    } = this.props;

    const hasValue = () => {
      // we consider that input has value only
      // if this value is valid
      const isValid = this.inputElement && this.inputElement.checkValidity();
      return value && value.length > 0 && isValid;
    };

    if (htmlValidation) {
      // Do validation based on CSS pseudo selectors
      const inputProps = {
        id: id ? id : nextId("ea-input__input-"),
        className: "ea-input__input",
        name,
        type: this.getInputType(type),
        value,
        onFocus,
        onBlur,
        onChange,
        label,
        placeholder: !disablePlaceholder ? placeholder || label : null,
        autoFocus,
        onKeyDown,
        disabled,
        maxLength,
        pattern: this.getPattern(pattern, type),
        autoComplete,
        rows: elementType === "textarea" && textareaRows ? textareaRows : undefined,
        readOnly,
      };
      return (
        <div
          style={style}
          className={classnames("ea-input", "ea-input--html-validation", className, {
            "ea-input--disabled": disabled,
            "ea-input--invalid": error,
            "ea-input--wide": wide,
            "ea-input--tight": tight,
            "ea-input--tight-bottom": tightBottom,
            "ea-input--button": appendedButtonIcon,
            "ea-input--help-text": helpText,
            "ea-input--label-visible": (value && value.length !== 0) || alwaysShowLabel,
          })}
        >
          {this.getPrependedIcon()}
          {!required && optionalText ? <span className="ea-input__not-required">{optionalText}</span> : ""}
          {elementType === "textarea" ? <textarea {...inputProps} /> : <input {...inputProps} />}
          {this.getInputAddon()}
          {this.getInputButton()}
          <label htmlFor={inputProps.id} className="ea-input__label">
            {label}
          </label>
          {error ? (
            <span className="ea-input__message">{error}</span>
          ) : (
            <span className="ea-input__error">{htmlValidationError}</span>
          )}
          {
            // possible error overrides the help text
            !error && helpText && <span className="ea-input__message">{helpText}</span>
          }
          {this.getDisabledIcon(disabled, disabledTooltipText)}
        </div>
      );
    }

    const inputProps = {
      ref: (input) => {
        this.inputElement = input;
      },
      id: id ? id : nextId("ea-input__input-"),
      className: "ea-input__input",
      name,
      type: this.getInputType(type),
      value,
      onFocus,
      onBlur,
      onChange,
      label,
      placeholder: !disablePlaceholder ? placeholder || label : null,
      required,
      autoFocus,
      onKeyDown,
      disabled,
      maxLength,
      pattern: this.getPattern(pattern, type),
      autoComplete,
      rows: elementType === "textarea" && textareaRows ? textareaRows : undefined,
      readOnly,
    };

    return (
      <div
        id={this.props.id ? this.props.id : nextId("ea-input-")}
        style={style}
        className={classnames("ea-input", className, {
          "ea-input--selected": active,
          "ea-input--disabled": disabled,
          "ea-input--invalid": touched && error,
          "ea-input--has-value": hasValue(),
          "ea-input--label-visible": (value && value.length !== 0) || alwaysShowLabel,
          "ea-input--pending": pending,
          "ea-input--wide": wide,
          "ea-input--tight": tight,
          "ea-input--tight-with-label": tightWithLabel,
          "ea-input--tight-bottom": tightBottom,
          "ea-input--button": appendedButtonIcon,
          "ea-input--help-text": helpText,
        })}
      >
        <label htmlFor={inputProps.id} className="ea-input__label">
          {label}
        </label>
        {!required && optionalText ? <span className="ea-input__optional">{optionalText}</span> : ""}
        <div className="ea-input__wrapper">
          {this.getPrependedIcon()}
          {elementType === "textarea" ? <textarea {...inputProps} /> : <input {...inputProps} />}
          {this.getInputAddon()}
        </div>
        {this.getInputButton()}

        {helpText && <div className="ea-input__message">{helpText}</div>}
        {error && <div className="ea-input__message">{error}</div>}
      </div>
    );
  }
}

Input.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  elementType: PropTypes.oneOf(["input", "textarea"]),
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  icon: PropTypes.string,
  value: PropTypes.string,
  style: PropTypes.object,
  active: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onClearInput: PropTypes.func,
  error: PropTypes.node,
  pending: PropTypes.bool,
  touched: PropTypes.bool,
  autoFocus: PropTypes.bool,
  helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  wide: PropTypes.bool,
  tight: PropTypes.bool,
  tightWithLabel: PropTypes.bool,
  tightBottom: PropTypes.bool,
  onKeyDown: PropTypes.func,
  disabled: PropTypes.bool,
  disabledTooltipText: PropTypes.string,
  maxLength: PropTypes.number,
  pattern: PropTypes.string,
  autoComplete: PropTypes.string,
  optionalText: PropTypes.string,
  htmlValidation: PropTypes.bool,
  htmlValidationError: PropTypes.string,
  textareaRows: PropTypes.number,
  appendedButtonIcon: PropTypes.string,
  onAppendedButtonClick: PropTypes.func,
  readOnly: PropTypes.bool,
  disableAutoStatus: PropTypes.bool,
  disablePlaceholder: PropTypes.bool,
  alwaysShowLabel: PropTypes.bool,
  showHidePasswordIcon: PropTypes.bool,
  toggleInputType: PropTypes.func,
};

Input.defaultProps = {
  id: null,
  className: "",
  elementType: "input",
  name: null,
  label: null,
  placeholder: null,
  required: false,
  type: "text",
  icon: null,
  value: null,
  style: {},
  active: false,
  onChange: null,
  onFocus: null,
  onBlur: null,
  onClearInput: null,
  error: null,
  pending: false,
  touched: false,
  autoFocus: false,
  helpText: null,
  wide: false,
  tight: false,
  tightWithLabel: false,
  tightBottom: false,
  onKeyDown: null,
  disabled: false,
  disabledTooltipText: null,
  maxLength: null,
  pattern: null,
  autoComplete: null,
  optionalText: "valinnainen",
  htmlValidation: false,
  htmlValidationError: null,
  textareaRows: null,
  appendedButtonIcon: null,
  onAppendedButtonClick: null,
  readOnly: false,
  disableAutoStatus: false,
  disablePlaceholder: false,
  alwaysShowLabel: false,
  showHidePasswordIcon: false,
  toggleInputType: () => {},
};

export default Input;
