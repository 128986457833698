import {
  CompanyInfoContainer,
  CompanyInfoSceneTab,
} from '../../../../../components/CompanyInfoScene/CompanyInfoScene.js';
import { OnboardingRequestList } from '../../../../../components/OnboardingRequestList/OnboardingRequestList.js';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import { loadOnboardingRequests } from '../../../../../selfservice/actions/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import type { State } from '../../../../../selfservice/common/store.js';

export const CompanyInfoInvitesPath = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const onBoardingRequests = useSelector((state: State) => state.selfservice?.onboardingRequests, deepEqual);
  useEffect(() => {
    dispatch(loadOnboardingRequests());
  }, [dispatch, pathname]);
  return (
    <CompanyInfoContainer tab={CompanyInfoSceneTab.INVITES}>
      <OnboardingRequestList onboardingRequests={onBoardingRequests} />
    </CompanyInfoContainer>
  );
};
