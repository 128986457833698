import { TypeKeys, generateActionId } from './actionsUtils.js';
import { convertToCommonErrors } from '../../common/utils/errorUtils.js';
import type { AssociationRecord, DependencyRecord } from '@onlinefirst/cloudsense-add-on-dependency-engine';
import type { CrudAction, ErrorAction } from './actionsUtils.js';
import type { GenericError } from '../../generated/api/models.js';

export type LoadSubscriptionAddOnRulesAction = CrudAction<TypeKeys.LOAD_SUBSCRIPTION_ADD_ON_RULES>;

export const loadSubscriptionAddOnRules = (): LoadSubscriptionAddOnRulesAction => ({
  id: generateActionId(),
  type: TypeKeys.LOAD_SUBSCRIPTION_ADD_ON_RULES,
});

export type LoadSubscriptionAddOnRulesFailedAction = ErrorAction<TypeKeys.LOAD_SUBSCRIPTION_ADD_ON_RULES_FAILED>;

export const loadSubscriptionAddOnRulesFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): LoadSubscriptionAddOnRulesFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.LOAD_SUBSCRIPTION_ADD_ON_RULES_FAILED,
});

export interface LoadSubscriptionAddOnRulesFulfilledAction {
  type: TypeKeys.LOAD_SUBSCRIPTION_ADD_ON_RULES_FULFILLED;
  associations: AssociationRecord[];
  dependencies: DependencyRecord[];
}

export const loadSubscriptionAddOnRulesFulfilled = (
  associations: AssociationRecord[],
  dependencies: DependencyRecord[]
): LoadSubscriptionAddOnRulesFulfilledAction => ({
  associations,
  dependencies,
  type: TypeKeys.LOAD_SUBSCRIPTION_ADD_ON_RULES_FULFILLED,
});

export type LoadSubscriptionAddOnRulesMobilePbxAction = CrudAction<TypeKeys.LOAD_SUBSCRIPTION_ADD_ON_RULES_MOBILE_PBX>;

export const loadSubscriptionAddOnRulesMobilePbx = (): LoadSubscriptionAddOnRulesMobilePbxAction => {
  return {
    id: generateActionId(),
    type: TypeKeys.LOAD_SUBSCRIPTION_ADD_ON_RULES_MOBILE_PBX,
  };
};

export type LoadSubscriptionAddOnRulesMobilePbxFailedAction =
  ErrorAction<TypeKeys.LOAD_SUBSCRIPTION_ADD_ON_RULES_MOBILE_PBX_FAILED>;

export const loadSubscriptionAddOnRulesMobilePbxFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): LoadSubscriptionAddOnRulesMobilePbxFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.LOAD_SUBSCRIPTION_ADD_ON_RULES_MOBILE_PBX_FAILED,
});

export interface LoadSubscriptionAddOnRulesMobilePbxFulfilledAction {
  type: TypeKeys.LOAD_SUBSCRIPTION_ADD_ON_RULES_MOBILE_PBX_FULFILLED;
  associationsMobilePbx: AssociationRecord[];
  dependenciesMobilePbx: DependencyRecord[];
}

export const loadSubscriptionAddOnRulesMobilePbxFulfilled = (
  associationsMobilePbx: AssociationRecord[],
  dependenciesMobilePbx: DependencyRecord[]
): LoadSubscriptionAddOnRulesMobilePbxFulfilledAction => ({
  associationsMobilePbx,
  dependenciesMobilePbx,
  type: TypeKeys.LOAD_SUBSCRIPTION_ADD_ON_RULES_MOBILE_PBX_FULFILLED,
});

export type SubscriptionAddOnRulesActionTypes =
  | LoadSubscriptionAddOnRulesAction
  | LoadSubscriptionAddOnRulesFailedAction
  | LoadSubscriptionAddOnRulesFulfilledAction
  | LoadSubscriptionAddOnRulesMobilePbxAction
  | LoadSubscriptionAddOnRulesMobilePbxFailedAction
  | LoadSubscriptionAddOnRulesMobilePbxFulfilledAction;
