import { HeroHeading, HeroHeadingType } from '../HeroHeading/index.js';
import { LocalNavi } from '../LocalNavi/index.js';
import { SystemError, hasSystemError } from '../SystemError/SystemError.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { myOrdersMsg, t } from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import { resetContacts } from '../../selfservice/actions/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import type { ReactNode } from 'react';
import type { State } from '../../selfservice/common/store.js';

export const EmployeeMyOrdersBreadcrumb = () => {
  return (
    <HeroHeading
      heroHeadingType={HeroHeadingType.EMPLOYEE_ORDERS}
      title={t.EG6F(myOrdersMsg)}
      fullScreen={true}
      center={true}
    />
  );
};

export const EmployeeMyOrdersNavi = () => {
  return (
    <LocalNavi
      categories={[
        {
          children: t.PLW9('My Orders'),
          to: paths.EMPLOYEE_OWN_ORDERS,
        },
        {
          children: t.ZB3L('My redeem requests'),
          to: paths.EMPLOYEE_OWN_CHANGE_REQUESTS,
        },
      ]}
    />
  );
};

export interface EmployeeMyOrdersWrapperProps {
  children: ReactNode;
}
export const EmployeeMyOrdersWrapper = ({ children }: EmployeeMyOrdersWrapperProps) => {
  const dispatch = useDispatch();
  const contacts = useSelector((s: State) => s.selfservice?.contacts, deepEqual);
  const employeeCustomerOrders = useSelector((s: State) => s.user?.authenticated?.employeeCustomerOrders, deepEqual);
  const employeeSubscriptionActions = useSelector(
    (s: State) => s.user?.authenticated?.employeeSubscriptionActions,
    deepEqual
  );
  const errors = employeeCustomerOrders?.errors || contacts?.errors || employeeSubscriptionActions?.errors;

  useEffect(() => {
    return () => {
      dispatch(resetContacts());
    };
  }, [dispatch]);

  if (hasSystemError(errors)) {
    return <SystemError errors={errors} homePath={paths.EMPLOYEE_HOME} />;
  }
  return <div className="of-employee-orders-scene">{children}</div>;
};
