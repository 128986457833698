import { Loading } from '../Loading/index.js';
import { RedeemOrReturn } from './RedeemOrReturn.js';
import { expiringEppSubsNotesMsg, expiringEppSubsWarnMsg, t } from '../../common/i18n/index.js';
import type { DeviceChangeRequest } from '../../common/types/device.js';
import type { EppRedeemTerminateRequestType, Subscription } from '../../generated/api/models.js';

import './DeviceCheckoutCartProduct.scss';

type KeyUserDeviceChangeProps = {
  onSelectSubscriptionForDeviceChange: (
    contactId: string,
    subscriptionId?: string,
    subscriptionBillingAccountId?: string,
    redeemType?: EppRedeemTerminateRequestType
  ) => void;
  selectedPouIndex: string;
  isDeviceChangeAlreadySelected: boolean;
  deviceChangeRequest?: DeviceChangeRequest;
  expiringEppDeviceSubscription?: Subscription[];
  contactId: string;
  subscriptionIdForDeviceChange?: string;
  isDeviceChangeSelectedWithSubscriptionId?: boolean;
  companyName?: string;
  isFirstCartItem: boolean;
};

const getExpiringEppDeviceSubscriptionForSelectedContact = (
  expiringEppDeviceSubscription: Subscription[],
  selectedContactId: string
): Subscription[] => expiringEppDeviceSubscription.filter(sub => sub.subscriptionContactId === selectedContactId);

export const KeyUserDeviceChange = ({
  onSelectSubscriptionForDeviceChange,
  selectedPouIndex,
  isDeviceChangeAlreadySelected,
  deviceChangeRequest,
  expiringEppDeviceSubscription,
  contactId,
  subscriptionIdForDeviceChange,
  isDeviceChangeSelectedWithSubscriptionId,
  isFirstCartItem,
  companyName,
}: KeyUserDeviceChangeProps) => {
  if (!expiringEppDeviceSubscription) {
    return <Loading />;
  } else {
    const expSubs = getExpiringEppDeviceSubscriptionForSelectedContact(expiringEppDeviceSubscription, contactId);

    if (expSubs.length) {
      if (isDeviceChangeAlreadySelected) {
        return <p>{t.WX52(expiringEppSubsWarnMsg)}</p>;
      } else {
        return (
          <div
            className="of-device-checkout-cart-product--purpose-of-use--device-change"
            id={`of-device-checkout-cart-product__device-change-${selectedPouIndex}`}
          >
            <p>{t.TTBU(expiringEppSubsNotesMsg)}</p>
            <RedeemOrReturn
              expiringSubs={expSubs}
              selectedPouIndex={selectedPouIndex}
              onSelectSubscriptionForDeviceChange={onSelectSubscriptionForDeviceChange}
              contactId={contactId}
              deviceChangeRequest={deviceChangeRequest}
              companyName={companyName}
              subscriptionIdForDeviceChange={subscriptionIdForDeviceChange}
              isDeviceChangeSelectedWithSubscriptionId={isDeviceChangeSelectedWithSubscriptionId}
              isFirstCartItem={isFirstCartItem}
            />
          </div>
        );
      }
    }
  }
  return <></>;
};
