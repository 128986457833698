import * as CL from '@design-system/component-library';

import './Icon.scss';

const getColorAndClassname = (color: IconColor): { color?: CL.DsColor; className?: string } => {
  switch (color) {
    case 'light-orange':
      return { color: undefined, className: 'of-icon--color-light-orange' };
    default:
      return { color, className: undefined };
  }
};

export interface IconAttrs extends Omit<CL.IconProps, 'color'> {
  color: IconColor;
}

export type IconColor = CL.DsColor | 'light-orange';

export const Icon = ({ color, ...rest }: IconAttrs): JSX.Element => {
  return <CL.Icon {...rest} {...getColorAndClassname(color)} />;
};
