import { elisaForCompaniesMsg, t } from '../i18n/index.js';
import { isInBrowser } from '../utils/ssrUtils.js';
import { useEffect } from 'react';

const setTitle = (title: string) => {
  if (isInBrowser()) {
    document.title = title;
    const metaElement: HTMLMetaElement | null = document.querySelector('[property="og:title"]');
    if (metaElement) {
      metaElement.content = title;
    }
  }
};

export const useTitle = (titleParts: string[] = []) => {
  useEffect(() => {
    const initialTitle = t.V0XR(elisaForCompaniesMsg);
    const title = [initialTitle, ...titleParts].join(' - ');
    setTitle(title);
  }, [titleParts]);
};
