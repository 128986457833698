import type { Promotion } from '../../generated/api/models.js';

import './PublicPagesSearchPromo.scss';

export interface PromoProps {
  promotion: Promotion;
}

export const PublicPagesSearchPromo = (props: PromoProps) => (
  <div className="of-public-pages-search-promo-row">
    <a className="of-public-pages-search-promo-row-item" href={props.promotion.link} target="_self">
      <div className="of-public-pages-search-promo-row-item-container">
        <div className="of-public-pages-search-promo-row-item-title">{props.promotion.title}</div>
        <div className="of-public-pages-search-promo-row-item-snippet">{props.promotion.body}</div>
      </div>
    </a>
  </div>
);
