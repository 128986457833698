import * as CL from '@design-system/component-library';
import { BreadCrumbs } from '../BreadCrumbs/index.js';
import { FaqAccordion } from '../FaqAccordion/FaqAccordion.js';
import { Link } from 'react-router-dom';
import { Modal } from '../Modal/Modal.js';
import { ModalCloseAndSubmitButtons } from '../Modal/ModalCloseAndSubmitButtons.js';
import { PAGE_MARGINS_AS_PADDING } from '../PageMargins/PageMargins.js';
import { Picture } from '../Picture/Picture.js';
import { PublicSelfServiceBanner } from '../PublicSelfServiceBanner/PublicSelfServiceBanner.js';
import { PublicSubscriptionImageCards } from './PublicSubscriptionImageCards.js';
import { SubscriptionCards } from '../SubscriptionCards/SubscriptionCards.js';
import { SubscriptionSelectionNotification } from '../OrderSubscriptionSelection/SubscriptionSelectionNotification.js';
import { addToCart, loadOnlineModelWithId } from '../../selfservice/actions/index.js';
import {
  addToCartMsg,
  allPricesVatZeroMsg,
  corporateCustomersMsg,
  corporateOmaElisaMsg,
  customerServiceNumberHrefMsg,
  customerServiceNumberLabelMsg,
  customerServiceNumberOpenShortMsg,
  customerServiceNumberPriceMsg,
  employeeOmaElisaMsg,
  pinCodeMsg,
  pukCodeMsg,
  subscriptionsMsg,
  t,
} from '../../common/i18n/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { getVoiceSubscriptionCardContents } from '../OrderSubscriptionSelection/content/VoiceSubscriptionCardContent';
import { isFiveGPlusOffer, isFiveGPlusVoiceOffer } from '../../common/utils/subscriptionUtils.js';
import { isSubCardWithImpression } from '../SubscriptionCards/subscriptionCardsAnalyticsUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { pictureStorageBaseUrl } from '../Picture/contentfulImageProxy.js';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { CommercialProduct } from '../../generated/api/commercialProduct.js';
import type { Offer } from '../../generated/api/offer.js';
import type { OnlineModel } from '../../generated/api/onlineModel.js';
import type { State } from '../../selfservice/common/store.js';
import type { SubscriptionCardProps } from '../SubscriptionCard/SubscriptionCard.js';

import './PublicMobileVoiceSubscriptions.scss';

const ADDITIONAL_SERVICES_BLOCK_ID = 'yritysliittymat_additional_services';
const FAQ_BLOCK_ID = 'yritysliittymat_faq_accordion';
const PAGE_WRAPPER_ID = 'yritysliittymat_page';

const MARKETING_BLOCK_IMAGE_RULES = {
  onPhone: 'calc(100vw - 32px)',
  onPhoneLarge: 'calc(100vw - 48px)',
  onLaptop: 'calc((100vw - 64px) * 0.4)',
  onDesktop: '509px',
};

const MARKETING_BLOCK_IMAGE_SIZES: [number, ...number[]] = [288, 340, 433, 448, 509, 515, 592, 1018];

const scrollIntoViewById = (id: string): void => {
  const target = document.getElementById(id);
  target?.scrollIntoView({ behavior: 'smooth', inline: 'start' });
};

const ScrollToBlockLinkButton = ({ targetId, label }: { targetId: string; label: string }): JSX.Element => (
  <div className={`${dsClass.TEXT_ALIGN_CENTER} ${dsClass.MARGIN_VERTICAL_6}`}>
    <CL.Link
      linkStyle="link-button"
      buttonColor="light"
      buttonSize="l"
      linkHref={`#${targetId}`}
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        scrollIntoViewById(targetId);
        return false;
      }}
    >
      {label}
    </CL.Link>
  </div>
);

const Breadcrumbs = (): JSX.Element => (
  <BreadCrumbs
    breadCrumbPaths={[
      { name: t.KBLX(corporateCustomersMsg), path: '/' },
      { name: t.XQ5R(subscriptionsMsg), path: paths.SUBSCRIPTIONS_PUBLIC },
      { name: t.HV3R('Voice subscriptions') },
    ]}
  />
);

const Header = (): JSX.Element => (
  <header>
    <h1 className={dsClass.H1}>{t.ARE0('Telephone subscriptions for businesses')}</h1>
  </header>
);

const SubHeader = (): JSX.Element => (
  <section className="of-public-mobile-voice-subscriptions__ingress">
    <p className={dsClass.TEXT_L}>
      {t.ARE1(
        'Everything works better with Elisa’s 5G+ and 5G corporate connections. Efficient and super speedy telephone subscriptions for business ensure smooth service. Our fast 5G network ensures a reliable connection and speeds up your company’s day.'
      )}
    </p>
    <p>
      {t.ARDI('Our corporate subscriptions now come with ')}
      <a
        href={`#${ADDITIONAL_SERVICES_BLOCK_ID}`}
        onClick={e => {
          e.preventDefault();
          scrollIntoViewById(ADDITIONAL_SERVICES_BLOCK_ID);
          return false;
        }}
      >
        {t.ARDJ('additional services')}
      </a>
      {t.ARDK(
        ' for an easier everyday life. Mobiiliturva, Matkapuhelinvastaaja and Reissunetti make your everyday life easier and more secure. Select the most suitable subscription for you: Premium, Perus or Mini, and add the services you need.'
      )}
    </p>
  </section>
);

type DetailsModalProps = {
  closeModal: () => void;
  onlineModel: OnlineModel;
  subData: SubscriptionCardProps;
};

const DetailsModal = ({ closeModal, onlineModel, subData }: DetailsModalProps): JSX.Element => {
  const dispatch = useDispatch();
  const subDataOffer = subData.offers!.find(o => o.salesProduct.onlineModelCode === onlineModel.onlineModelCode);
  const [offerCode, setOfferCode] = useState(subDataOffer!.salesProduct.newNumberOfferCode);
  const offerName = `Yritysliittymä 5G${isFiveGPlusVoiceOffer(subDataOffer?.guid) ? '+' : ''}`;
  return (
    <Modal
      closeable
      heading={`${offerName} (${subDataOffer?.text}) \n ${subData.mainHeader}`}
      onClose={closeModal}
      size="m"
    >
      <p>{t.ARG2('Use the fast 4G network when outside the 5G area according to the selected subscription speed.')}</p>
      <h3>{t.OK8K('Order subscription')}</h3>
      <CL.RadioGroup onChange={setOfferCode} value={offerCode}>
        <CL.RadioV2 value={subDataOffer!.salesProduct!.newNumberOfferCode}>
          <div>
            <b>{t.ARFV('New number')}</b>
            {t.ARFX('- select this option if you want your subscription to have a new number.')}
          </div>
        </CL.RadioV2>
        <CL.RadioV2 value={subDataOffer!.salesProduct!.oldNumberOfferCode}>
          <div>
            <b>{t.ARFW('Keep your number')}</b>
            {t.ARFY('- select this option if you want to transfer your current number from another operator to Elisa.')}
          </div>
        </CL.RadioV2>
      </CL.RadioGroup>
      <ModalCloseAndSubmitButtons
        onSubmit={() => {
          const offer = onlineModel.offers.find(o => o.offerCode === offerCode);
          if (offer) {
            dispatch(
              addToCart({
                additionalFields: [],
                commercialProduct: offer.commercialProducts[0],
                isPublicSite: true,
                offer: offer,
                onlineModel: onlineModel,
                quantity: 1,
                selectedAddOnBundles: [],
                productPath: onlineModel.pagePath || '',
              })
            );
          }
          closeModal();
        }}
        submitButtonText={t.JFOS(addToCartMsg)}
      />
    </Modal>
  );
};

const FiveGPlus = (): JSX.Element => (
  <section className="of-public-mobile-voice-subscriptions__marketing-block">
    <div>
      <div>
        <h2>{t.SF00('5G+, a standalone 5G network now available for corporate mobile subscriptions')}</h2>
        <p>{t.SF01('We are the first in Finland to make a standalone 5G network available to our customers.')}</p>
        <p>
          {t.SF02(
            'The standalone 5G network (5G Standalone) is the latest technological development in 5G, enabling more stable and more consistent connections.'
          )}
        </p>
        <p>{t.SF03('With it, the potential of the current 5G network will grow even more fully to utility.')}</p>
        <p>
          {t.SF04(
            '5G+ is Elisa’s best 5G connection and it is also more ecological and energy-efficient than previous technologies.'
          )}
        </p>
        <p>
          {t.SF05(
            '5G+ comes standard with our Perus 300M, Perus 600M and Premium subscriptions. Please note, however, that to take advantage of the standalone network, '
          )}
          <Link to="/kauppa/itsenaisen-verkon-laitteet">{t.SF06('you will also need a phone that supports it')}</Link>.
        </p>
      </div>
      <Picture
        alt="5G+ eli itsenäinen 5G-verkko"
        offerWidthAlternatives={MARKETING_BLOCK_IMAGE_SIZES}
        renderedImageSize={MARKETING_BLOCK_IMAGE_RULES}
        src={`${pictureStorageBaseUrl}4PX4qgCXijwFNSReTwn4dH/i.png`}
      />
    </div>
  </section>
);

const AdditionalServices = (): JSX.Element => (
  <section id={ADDITIONAL_SERVICES_BLOCK_ID}>
    <div>
      <h2>{t.SE02('An easier and more secure everyday life with additional corporate subscription services')}</h2>
      <PublicSubscriptionImageCards
        items={[
          {
            content: (
              <>
                <p>
                  <Link to="/ohje/elisa-matkapuhelinvastaaja">{t.SDX4('Elisa Mobile Phone Voice Mail')}</Link>
                  {t.SDX5(
                    ' adds a voicemail, an Away Attendant and notifications for unanswered calls. You are notified of unanswered calls by SMS.'
                  )}
                </p>
                <p>
                  {t.SDX6(
                    'With the Away Attendant, you can also separate work-related and private calls from each other and direct work-related calls to the desired person during your holiday.'
                  )}
                </p>
              </>
            ),
            image: '3VnhlmvLh4wyRTtJHmH9lD/i.svg',
            imageAlt: 'Vastaaja matkapuhelimeen',
            title: t.SDX3('Mobile voicemail service'),
          },
          {
            content: (
              <>
                <p>
                  <Link to="/mobiiliturva">Elisa Mobiiliturva Yrityksille</Link>
                  {t.SE00(
                    ' improves the data security of browsers. It identifies malicious websites and notifies the user.'
                  )}
                </p>
                <p>
                  {t.SE01(
                    'You will have security against the most common data security threat, phishing, when using 4G or 5G internet.'
                  )}
                </p>
              </>
            ),
            image: '6aTPNsLeSnlXBP6x4Bng7l/i.svg',
            imageAlt: 'Elisa Mobiiliturva yrityksen puhelinliittymään',
            title: 'Mobiiliturva',
          },
          {
            content: (
              <>
                <p>
                  <Link to={paths.USING_YOUR_PHONE_ABROAD}>{t.SE13('Reissunetti and Reissaaja data packages')}</Link>
                  {t.SDWL(' also enable carefree internet use outside EU/EEA countries. ')}
                  {t.SDWO(
                    'Our ready-made data packages enable roaming abroad at a lower cost and help you avoid surprising expenses.'
                  )}
                </p>
                <p>
                  {t.SDWP(
                    'The data packages of the subscriptions are activated automatically when you arrive in the country and invoiced separately: Premium 26,90 € per 14 days, Perus 21,90 € per 14 days ja Mini 10,90 € per day.'
                  )}
                </p>
              </>
            ),
            image: '6VqOrJdM5EWYfZfCaUWeVC/i.png',
            title: t.SDWV('Roaming abroad'),
            imageAlt: 'Roaming ulkomailla',
          },
        ]}
        alignLeft={true}
      />
    </div>
  </section>
);

const ElisaMobileNetwork = (): JSX.Element => (
  <section className="of-public-mobile-voice-subscriptions__marketing-block of-public-mobile-voice-subscriptions__marketing-block--inverse">
    <div>
      <div>
        <h3>{t.ARE3('Super fast 5G network makes work smoother')}</h3>
        <p>
          {t.ARE4('Studies prove that Elisa’s 5G network has been ')}
          <a href={`${pictureStorageBaseUrl}aBqdjo7RSBPYSXfK6m1RS/Boftel_Vertailumittaus_03-2022.pdf`} rel="noreferrer">
            {t.ARE5('proven to have extensive coverage')}
          </a>
          {t.ARE6('. You can even work from your summer house while enjoying a reliable connection!')}
        </p>
        <p>
          <a href={paths.COVERAGE}>{t.ARE7('Check your region’s coverage on the coverage map.')}</a>
        </p>
        <p>
          {t.ARE2(
            'Elisa is also the only operator whose mobile and fixed network subscriptions have been awarded the Key Flag Symbol as a sign of Finnish service. Elisa’s subscription is a great choice when you want to support Finnish services.'
          )}
        </p>
      </div>
      <Picture
        alt="Elisan suomalainen 5G-matkapuhelinverkko"
        offerWidthAlternatives={MARKETING_BLOCK_IMAGE_SIZES}
        renderedImageSize={MARKETING_BLOCK_IMAGE_RULES}
        src={`${pictureStorageBaseUrl}7C6x5QnfW9XPAhG1LP8gL0/i.png`}
      />
    </div>
  </section>
);

const Avainlippu = (): JSX.Element => (
  <section className="of-public-mobile-voice-subscriptions__marketing-block of-public-mobile-voice-subscriptions__avainlippu">
    <div>
      <div>
        <p>
          {t.SDWM('The Key Flag Symbol is a sign of Finnish work and the company’s Finnish operations.')}
          <br />
          {t.SDWN('All Elisa’s corporate and consumer subscriptions (mobile and fixed) have received the symbol.')}
        </p>
      </div>
      <Picture
        alt="Kotimaiset yritysliittymät – Avainlippu on merkki suomalaisesta työstä ja yrityksen kotimaisuudesta"
        offerWidthAlternatives={[288, 313, 433, 448, 593, 604, 616, 1208]}
        renderedImageSize={{
          onPhone: 'min(calc(100vw - 32px), 604px)',
          onPhoneLarge: 'min(calc(100vw - 48px), 604px)',
          onLaptop: '313px',
        }}
        src={`${pictureStorageBaseUrl}BNzbhpmwtx8S0KAGSCvcF/i.png`}
      />
    </div>
  </section>
);

const AdditionalFeatures = (): JSX.Element => (
  <section className={`${dsClass.BACKGROUND_COLOR_NEUTRAL_200_LIGHT} ${PAGE_MARGINS_AS_PADDING}`}>
    <h2>{t.SDWQ('Connect more features to your subscription')}</h2>
    <PublicSubscriptionImageCards
      items={[
        {
          content: (
            <p>
              {t.SDWR('With ')}
              <Link to={paths.OMA_LASKU}>{t.SDWS('Elisa OmaLasku')}</Link>
              {t.SDWX(
                ', you can offer your employees the chance to add more relevant additional services to their phone subscriptions. For example, you can allow calls and texts to toll service numbers.'
              )}
            </p>
          ),
          image: '7L66nqtMJoVflbqzzzmkJk/omalasku-palvelunumerot-2024.png',
          imageAlt: 'Elisa OmaLasku',
          title: 'Elisa OmaLasku',
        },
        {
          content: (
            <p>
              {t.SDWU('Subscribe to the 5G network maximum speed for a day, week or month. ')}
              <Link to={paths.TURBONAPPI}>Elisa Turbonappi</Link>
              {t.SDXD(' brings speed to remote work, work trips and leisure activities.')}
            </p>
          ),
          image: 'qKWTeq9v1iqEYfL35dO7i/omalasku-turbonappi-2024.png',
          imageAlt: 'Elisa Turbonappi',
          title: 'Elisa Turbonappi',
        },
        {
          content: (
            <p>
              {t.SDWY(
                'Connect your voice subscription to your smartwatch or phone using a digital eSIM card. Deploying an '
              )}
              <Link to={paths.ESIM_FOR_COMPANIES}>{t.SE04('Elisa eSIM')}</Link> {t.SE05(' is quick and easy.')}
            </p>
          ),
          image: '7j4ZLbRM3n6vylaz6felqr/i.png',
          imageAlt: 'Elisa eSIM',
          title: 'Elisa eSIM',
        },
        {
          content: (
            <p>
              {t.SDX0(
                'Elisa Yritysliittymä 5G includes Wi-Fi calls (VoWiFi) and 4G calls (VoLTE). This way, you can make calls even in areas with weak coverage. '
              )}
              <Link to={paths.WIFI_CALL_SERVICE}>{t.SE06('Read more about Elisa Wi-Fi call service.')}</Link>
            </p>
          ),
          image: '12blNCBmMZoWPnH5maKTRd/i.png',
          imageAlt: 'Elisa WiFi-puhelinpalvelu',
          title: t.SDWZ('Elisa Wi-Fi call service'),
        },
      ]}
      alignLeft={true}
    />
  </section>
);

const FrequentlyAskedQuestions = (): JSX.Element => (
  <FaqAccordion
    heading={t.ARE9('Corporate subscriptions: frequently asked questions')}
    id={FAQ_BLOCK_ID}
    items={[
      {
        content: (
          <ul className={dsClass.LIST_UNORDERED}>
            <li>
              {t.AREF(
                'The subscriptions also work in the 4G network. When you go outside the 5G area with the Elisa Yritysliittymä 5G+ or 5G subscription, you have access to the 4G network.'
              )}
            </li>
            <li>
              {t.AREG('If your phone only supports the 4G network, your subscription will also use the 4G network.')}{' '}
              <a href={paths.COVERAGE}>{t.ARE8('Check coverage in your area.')}</a>
            </li>
          </ul>
        ),
        heading: t.AREE('Will 5G+ and 5G subscriptions work on 4G?'),
      },
      {
        content: (
          <ul className={dsClass.LIST_UNORDERED}>
            <li>
              {t.AREI('You can order an Elisa corporate subscription when you have the ')}
              <b>{t.AREJ('authority to sign')}</b>
              {t.AREK(' for your company')}.
            </li>
            <li>
              {t.AREL('You need the Corporate OmaElisa login to place an order. If you do not have an account yet, ')}
              <Link to={paths.REGISTER_PAGE}>{t.AREM('create new credentials')}</Link>.
            </li>
          </ul>
        ),
        heading: t.AREH('Who can order a corporate subscription?'),
      },
      {
        content: (
          <>
            <b>{t.AREO('Ordering a new corporate subscription:')}</b>
            <ul className={dsClass.LIST_UNORDERED}>
              <li>
                {t.AREP('Order a new phone subscription for your company on this page or in ')}
                <Link to={paths.SELF_SERVICE_HOME}>{t.AREA(corporateOmaElisaMsg)}</Link>
                {t.AREQ(
                  '. You can order a subscription with a new number or transfer your old number to a new subscription.'
                )}
              </li>
            </ul>
            <b>{t.ARER('To update the current subscription to another subscription package:')}</b>
            <ul className={dsClass.LIST_UNORDERED}>
              <li>
                {t.AREC('Log in to Corporate OmaElisa')}
                {t.ARES(', where you can update your current Elisa phone subscription.')}
              </li>
            </ul>
          </>
        ),
        heading: t.AREN('How do you order a corporate subscription?'),
      },
      {
        content: (
          <ul className={dsClass.LIST_UNORDERED}>
            <li>
              {t.AREU(
                'Elisa Yritysliittymä subscriptions include unlimited data in Sweden, Norway, Denmark, Estonia, Latvia and Lithuania.'
              )}
            </li>
            <li>
              {t.AREV('In addition, each subscription package includes plenty of data in the EU and EEA countries.')}
            </li>
            <li>
              {t.AREW('The administrator can check the subscription’s EU and EEA data volume in ')}
              <Link to={paths.SELF_SERVICE_HOME}>{t.AREB(corporateOmaElisaMsg)}</Link>
              {t.AREX(', and the employee can see it in ')}
              <Link to={paths.EMPLOYEE_HOME}>{t.ARED(employeeOmaElisaMsg)}</Link>.
            </li>
            <li>
              {t.AREY(
                'In other countries, the price varies depending on the destination country. In addition, you can get an inexpensive Reissaaja data package for especially distant countries.'
              )}
            </li>
            <li>
              <Link to={paths.USING_YOUR_PHONE_ABROAD}>{t.AREZ('Read more about using your phone abroad')}</Link>
            </li>
          </ul>
        ),
        heading: t.ARET('How much data can I use abroad?'),
      },
      {
        content: (
          <>
            <ul className={dsClass.LIST_UNORDERED}>
              <li>
                {t.ARF1(
                  'You can keep your current number when you change your mobile subscription to Elisa. During the ordering process, simply select '
                )}
                <b>{t.ARF2('“Keep your number”')}</b>.
              </li>
            </ul>
            <b>{t.ARF3('Do I have to terminate my old subscription myself?')}</b>
            <br />
            <ul className={dsClass.LIST_UNORDERED}>
              <li>
                {t.ARF4(
                  'When you switch over to Elisa from another operator, you do not need to contact your old operator. We will take care of transferring the subscription to Elisa on your behalf.'
                )}
              </li>
            </ul>
          </>
        ),
        heading: t.ARF0('Can I keep my current number if I transfer my subscription to Elisa?'),
      },
      {
        content: (
          <p>
            {t.ARF6('Read instructions for ')}
            <Link to={paths.INSTRUCTION_CHANGE_SUBSCRIPTION_OWNERSHIP}>
              {t.ARF7('changing the ownership of a subscription')}
            </Link>
            .
          </p>
        ),
        heading: t.ARF5('How do I change my subscription ownership?'),
      },
      {
        content: (
          <ul className={dsClass.LIST_UNORDERED}>
            <li>{t.ARF9('After placing an order, you will receive an order confirmation.')}</li>
            <li>
              {t.ARFA('We will deliver the SIM card to you in advance and send the ')}
              <b>{t.ARFB('exact transfer time')}</b>
              {t.ARFC(
                ' to the user’s phone. The message will inform you when the new subscription is functional and the SIM card can be changed to the phone.'
              )}
            </li>
            <li>
              {t.ARFD(
                'If you have changed your subscription from another operator to Elisa, you do not need to contact your old operator. We will take care of the transfer on your behalf.'
              )}
            </li>
            <li>
              <Link to={paths.SUBSCRIPTION_ACTIVATION}>
                {t.ARFE('Read the instructions for deploying the subscription ')}
              </Link>
            </li>
            <li>
              {t.ARFF('If you have any questions, you can also contact ')}
              <Link to={paths.CONTACT_INFO}>{t.ARFG('our customer service')}</Link>.
            </li>
          </ul>
        ),
        heading: t.ARF8('How do I get access to a telephone subscription?'),
      },
      {
        content: (
          <>
            <div className={dsClass.PADDING_BOTTOM_4}>
              <b>{t.ALQT(pinCodeMsg)}</b>
              <ul className={dsClass.LIST_UNORDERED}>
                <li>{t.ARFI('The default PIN code for Elisa’s corporate subscriptions is 1234.')}</li>
                <li>{t.ARFJ('You can change the PIN code to another one in the phone’s settings.')}</li>
              </ul>
            </div>
            <div className={dsClass.PADDING_BOTTOM_4}>
              <b>{t.K5TL(pukCodeMsg)}</b>
              <ul className={dsClass.LIST_UNORDERED}>
                <li>
                  {t.ARFK('If you are an employee, you can find your PUK code in ')}
                  <Link to={paths.EMPLOYEE_HOME}>{t.ARED(employeeOmaElisaMsg)}</Link>.
                </li>
                <li>
                  {t.ARFL(
                    'If you are an entrepreneur or a person with the authority to sign for your company, you can find the PUK code in '
                  )}
                  <Link to={paths.SELF_SERVICE_HOME}>{t.AREB(corporateOmaElisaMsg)}</Link>.
                </li>
              </ul>
            </div>
            <p>
              <Link to={paths.INSTRUCTION_PIN_AND_PUK_CODES}>
                {t.ARFM('Read the instructions for the PIN and PUK codes.')}
              </Link>
            </p>
          </>
        ),
        heading: t.ARFH('Where can I find the PIN and PUK code?'),
      },
      {
        content: (
          <>
            <div className={dsClass.PADDING_BOTTOM_4}>
              <b>{t.QQGQ('Ordering SIM cards')}</b>
              <p>
                {t.ARFO('You can order ')}
                <Link to={paths.ELISA_SIM_CARD_PRODUCT}>{t.ARFP('new SIM cards online')}</Link>
                {t.ARFQ(' or in ')}
                <Link to={paths.SELF_SERVICE_HOME}>{t.AREB(corporateOmaElisaMsg)}</Link>.
              </p>
            </div>
            <div className={dsClass.PADDING_BOTTOM_4}>
              <b>{t.XUME('Activating SIM cards')}</b>
              <p>{t.ARFT('The SIM card can be activated by the company’s administrator or employee.')}</p>
            </div>
            <p>
              {t.ARFR('Read instructions for ')}
              <Link to={paths.INSTRUCTION_SIM_CARD_ORDERING_AND_ACTIVATION}>
                {t.ARFS('ordering and managing SIM cards')}
              </Link>
              .
            </p>
          </>
        ),
        heading: t.ARFN('How do I order and activate new SIM cards?'),
      },
      {
        content: (
          <p>
            {t.SF08(
              'A standalone 5G network provides the best possible and more consistent connection with the latest technology. Since the network is also more energy efficient, it consumes less phone battery. It also enables 5G (Voice over New Radio, VoNR) calls with improved quality.'
            )}
          </p>
        ),
        heading: t.SF07('What are the benefits of standalone 5G?'),
      },
      {
        content: (
          <>
            <p>
              {t.SF10(
                'For now, the standalone 5G network works with a limited number of smartphones, but the selection is growing regularly. Check out our listing of '
              )}
              <Link to="/kauppa/itsenaisen-verkon-laitteet">{t.SF11('devices with 5G+ support')}</Link>.
            </p>
            <p>
              {t.SF12(
                'It is not always necessary to change your phone, as for many newer devices it is enough to keep up to date with software updates.'
              )}
            </p>
          </>
        ),
        heading: t.SF09('Which phones work on a standalone network?'),
      },
      {
        content: (
          <p>
            {t.SF14(
              'A subscription does not update automatically. You can update your phone subscription by logging in to '
            )}
            <Link to={paths.SELF_SERVICE_HOME}>{t.SF15(corporateOmaElisaMsg)}</Link>
            {'. '}
            {t.SF16('If you need more information, ')}
            <Link to={paths.CONTACT_INFO}>{t.SF17('our customer service team will be happy to help')}</Link>.
          </p>
        ),
        heading: t.SF13(
          'My business subscription is without the + feature. Will my current plan update automatically?'
        ),
      },
      {
        content: (
          <p>
            {t.SF19(
              'Yes. If your phone doesn’t support a standalone network, it will use a standard 5G or 4G connection just like before.'
            )}
          </p>
        ),
        heading: t.SF18('Can I use 5G+ on a phone that doesn’t support a standalone network?'),
      },
    ]}
  />
);

const OtherServices = (): JSX.Element => (
  <aside>
    <h2>{t.SDWT('You can also get the following services from Elisa')}</h2>
    <PublicSubscriptionImageCards
      items={[
        {
          content: (
            <p>
              <Link to={paths.PUHERATKAISU_VAKIO}>Elisa Puheratkaisu Vakio</Link>
              {t.SDXE(
                ' is an easy-to-use telephone exchange for entrepreneurs and small businesses. Manage calls to your company better and improve your company’s reach.'
              )}
            </p>
          ),
          image: '3Da234jT6rh62SSeflozl9/i.png?w=104',
          imageAlt: 'Elisa Puheratkaisu Vakio',
          title: 'Elisa Puheratkaisu Vakio',
        },
        {
          content: (
            <p>
              <Link to={paths.RING_MOBILE_SWITCHBOARD}>Elisa Ring</Link>
              {t.SDXG(
                ' is a flexible cloud-based voice and customer service solution that connects customer service and a switchboard in one package.'
              )}
            </p>
          ),
          image: '6FBNX4TTij9QeJd16OpMrm/i.png?w=104',
          imageAlt: t.SDXF('Elisa Ring communications solution'),
          title: t.SDXF('Elisa Ring communications solution'),
        },
        {
          content: (
            <p>
              {t.SDXI('The ')}
              <Link to={paths.LANKANUMERO}>{t.SE07('Elisa Lankapuhelin service')}</Link>
              {t.SE08(
                ' connects calls made to your company’s landline number to your mobile phone. This way, you can easily answer your company’s landline number even on the go.'
              )}
            </p>
          ),
          image: '7uveVdN0ZhHUNPjR6nWNTj/i.png?w=104',
          imageAlt: t.SDXH('Elisa Lankapuhelin service'),
          title: t.SDXH('Elisa Lankapuhelin service'),
        },
      ]}
      alignLeft={true}
    />
  </aside>
);

const CustomerServiceSolutions = () => (
  <aside className={`${dsClass.BACKGROUND_COLOR_BLUE_800} ${PAGE_MARGINS_AS_PADDING}`}>
    <h2 className={dsClass.TEXT_ALIGN_CENTER}>
      {t.SDXX('Elisa also has other useful services for entrepreneurs and larger companies.')}
    </h2>
    <p>
      {t.SDXY('Read more about our ')}
      <Link className={dsClass.COLOR_BLUE_400} to={paths.CUSTOMER_SERVICE_SOLUTIONS}>
        {t.SDXZ('comprehensive customer service solutions.')}
      </Link>
    </p>
  </aside>
);

const InternetSubscriptionsForCompanies = (): JSX.Element => {
  return (
    <aside className="of-internet-subscriptions-banner">
      <div>
        <div>
          <h2>{t.SDXU('Internet subscriptions for companies')}</h2>
          <p>
            {t.SDXV(
              'Are you looking for an internet connection for mobile work, your home office or your company’s office? Elisa has a suitable internet connection to suit your needs.'
            )}
          </p>
        </div>
        <Link
          className={`${dsClass.LINK} ${dsClass.LINK_BUTTON_COLOR_LIGHT} ${dsClass.LINK_BUTTON_SIZE_L} ${dsClass.LINK_STYLE_LINK_BUTTON}`}
          to={paths.INTERNET_SUBSCRIPTIONS_PUBLIC}
        >
          {t.SDXW('Explore internet subscriptions')}
        </Link>
      </div>
      <div>
        <img
          alt="Nettiliittymät yrityskäyttöön"
          src="https://static.elisa.com/v2/image/2tqybbhjs47b/45R92ljlugucd4D9YoDYqj/i.png?h=300"
        />
      </div>
    </aside>
  );
};

const Contact = (): JSX.Element => (
  <section className={`of-public-mobile-voice-subscriptions__contact ${PAGE_MARGINS_AS_PADDING}`}>
    {/* TODO use new CMS Templates' ContactGrid when it's actually possible to use without importing the CSS twice. */}
    <h2>{t.SDWH('Do you have any questions about corporate telephone subscriptions?')}</h2>
    <div>
      {[
        {
          description: t.SDUB('Mon-Fri 8am-4pm | Free of charge'),
          href: 'tel:+35880004411',
          icon: 'phone',
          label: '0800 04411',
          title: t.JXQZ('Sales service'),
        },
        {
          description: `${t.HQWU(customerServiceNumberOpenShortMsg)} | ${t.HQWW(customerServiceNumberPriceMsg)}`,
          href: customerServiceNumberHrefMsg,
          icon: 'phone',
          label: customerServiceNumberLabelMsg,
          title: t.L6NC('Call corporate customer service'),
        },
        {
          href: paths.STORES,
          icon: 'chat',
          label: t.D2W8('Book appointment'),
          title: t.D2W9('Remote meeting with corporate sales'),
        },
      ].map(c => (
        <div key={c.href}>
          <CL.Icon aria-hidden="true" color="neutral-600" icon={c.icon} size="xl" type="regular" />
          <h3 className={dsClass.H4}>{c.title}</h3>
          <a
            className={`${dsClass.LINK} ${dsClass.LINK_BUTTON_COLOR_PRIMARY} ${dsClass.LINK_BUTTON_SIZE_L} ${dsClass.LINK_STYLE_LINK_BUTTON}`}
            href={c.href}
          >
            {c.label}
          </a>
          {c.description && <p className={dsClass.TEXT_S}>{c.description}</p>}
        </div>
      ))}
    </div>
  </section>
);

const Disclaimer = (): JSX.Element => (
  <section>
    <div>
      <h2 className={`${dsClass.H4} ${dsClass.PADDING_BOTTOM_0}`}>{t.TEA3(allPricesVatZeroMsg)}</h2>
      <p className={`${dsClass.TEXT_S} ${dsClass.TEXT_ALIGN_LEFT}`}>
        {t.SDXO(
          'Data transfer rates vary depending on the properties, the location of the device and the network load. Check your area’s coverage on the '
        )}
        <a href={paths.COVERAGE}>{t.SDXP('coverage map')}</a>
        {t.SDXQ(
          '. Nordic countries (except Iceland) and Baltic countries: Sweden, Norway, Denmark, Estonia, Latvia and Lithuania. In Yritysliittymä 5G subscriptions, data exceeding the usage limit is priced according to the '
        )}
        <Link to={paths.USING_YOUR_PHONE_ABROAD}>{t.SDXR('roaming price list')}</Link>
        {t.SDXS(
          '. Subscriptions include 5,000 minutes of voice calls per month to normal-priced Finnish mobile and landline numbers and 100 SMS messages per month. The package does not cover international calls or calls to service numbers subject to an extra fee, such as those with 0700 or 0600 prefixes. When calling abroad or service numbers subject to an extra fee, a mobile network charge (mpc) of EUR 0.199 per minute will also be charged. The prices do not apply to networks subject to special prices (ships, satellites and aircraft).'
        )}
      </p>
    </div>
  </section>
);

const BackToSubscriptions = (): JSX.Element => (
  <ScrollToBlockLinkButton targetId={PAGE_WRAPPER_ID} label={t.SDXT('Back to the top')} />
);

const Subscriptions = () => {
  const modalOpener = useRef<HTMLElement>();
  const subscriptionProductData = getVoiceSubscriptionCardContents(true);
  const [selectedCard, setSelectedCard] = useState<null | string>(null);
  const onlineModels = useSelector((state: State) => state.selfservice?.onlineModels?.items || [], deepEqual);
  const selectedSubData = subscriptionProductData.find(subData => subData.mainHeader === selectedCard);

  const [selectedOfferGuids, setSelectedOfferGuids] = useState<Record<string, string>>(
    subscriptionProductData.reduce((a: Record<string, string>, s: SubscriptionCardProps) => {
      return {
        ...a,
        [s.mainHeader!]: s.selectedOffer!,
      };
    }, {})
  );

  const setSelectedOffer = (card: string, offerGuid: string) =>
    setSelectedOfferGuids(old => ({
      ...old,
      [card]: offerGuid,
    }));

  const handleSelectCard = (subData: SubscriptionCardProps, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (isSubCardWithImpression(subData)) {
      modalOpener.current = e.currentTarget as HTMLElement;
      setSelectedCard(subData.mainHeader);
    }
  };

  const closeModal = useCallback(() => {
    setSelectedCard(null);
    if (modalOpener.current) {
      modalOpener.current.focus();
      modalOpener.current = undefined;
    }
  }, [modalOpener, setSelectedCard]);
  // flatMap removes undefined values
  const selectedOfferGuid = selectedCard && selectedOfferGuids[selectedCard];
  const selectedOnlineModelCode = selectedSubData?.offers?.find(o => o.guid === selectedOfferGuid)?.salesProduct
    ?.onlineModelCode;
  const selectedOnlineModel = onlineModels.find(o => o.onlineModelCode === selectedOnlineModelCode);

  const formatSubscriptionCards = (subscriptionCards: SubscriptionCardProps[]) => {
    return subscriptionCards.map(subData => {
      const subDataOfferGuid = selectedOfferGuids[subData.mainHeader] || subData.offers?.[0].guid;
      const subDataOffer = subData.offers?.find(o => o.guid === subDataOfferGuid);
      const onlineModelCode = subDataOffer?.salesProduct?.onlineModelCode;
      const onlineModel = onlineModels.find(om => om.onlineModelCode === onlineModelCode);

      // old/new number have the same price, so we can just use one of them here.
      const offer = onlineModel?.offers.find(
        (of: Offer) => of.offerCode === subDataOffer?.salesProduct.newNumberOfferCode
      );
      const oneTimePrice = offer?.commercialProducts.reduce(
        (a: number, c: CommercialProduct) => a + (c.oneTimeCharge || 0),
        0
      );
      const monthlyPrice = offer?.commercialProducts.reduce(
        (a: number, c: CommercialProduct) => a + (c.monthlyRecurringCharge || 0),
        0
      );
      const isLoading = offer == null;

      return {
        ...subData,
        buttonText: t.JFOS(addToCartMsg),
        inPublicStore: true,
        isLoading,
        monthlyPrice: monthlyPrice,
        offer: offer,
        onChangeOffer: (offerGuid: string) => setSelectedOffer(subData.mainHeader, offerGuid),
        oneTimePrice: oneTimePrice,
        onlineModel: isLoading ? undefined : onlineModel,
        productName: onlineModel?.onlineModelName,
        selectedOffer: selectedOfferGuids[subData.mainHeader],
      };
    });
  };

  const fiveGPlusSubscriptions = formatSubscriptionCards(subscriptionProductData).filter(card =>
    isFiveGPlusOffer(card.selectedOffer)
  );

  const fiveGSubscriptions = formatSubscriptionCards(subscriptionProductData).filter(
    card => !isFiveGPlusOffer(card.selectedOffer)
  );

  return (
    <section className="of-public-mobile-voice-subscriptions__subscriptions">
      <h2>{t.MCL7('Select a 5G+ voice subscription')}</h2>
      <SubscriptionCards
        buttonText={t.SDWF('Explore and order')}
        listName="Yritysliittymät 5G+"
        onButtonClick={handleSelectCard}
        subscriptions={fiveGPlusSubscriptions}
      />
      <div className="of-subscription-selection-notification--public">
        <SubscriptionSelectionNotification />
        <p className={`of-subscription-selection-notification ${dsClass.TEXT_ALIGN_LEFT} ${dsClass.TEXT_S}`}>
          {t.SE11(
            'The Premium subscription supports all the services. The Mini and Perus subscriptions are available as ready-made bundles on this page. If you want to supplement your Mini or Perus subscription with other additional services, you can order them in '
          )}
          <Link to={paths.SELF_SERVICE_HOME}>{t.AREB(corporateOmaElisaMsg)}</Link>
          {t.SE12('. In OmaElisa, you can also add additional services to your existing subscription. ')}
        </p>
      </div>

      <h2>{t.E86A('Select a 5G subscription')}</h2>
      <SubscriptionCards
        buttonText={t.SDWF('Explore and order')}
        listName="Yritysliittymät 5G"
        onButtonClick={handleSelectCard}
        subscriptions={fiveGSubscriptions}
      />
      {selectedSubData && selectedOnlineModel && (
        <DetailsModal closeModal={closeModal} onlineModel={selectedOnlineModel} subData={selectedSubData} />
      )}
      <div className="of-subscription-selection-notification--public">
        <div className={`of-order-subscription-selection--cards ${dsClass.MARGIN_TOP_2} ${dsClass.TEXT_S}`}>
          ** {t.U7MJ('With optimised voice, you will only pay for what you use. Limits in voice billing: ')}
          {/* Zero-width spaces so this can be broken into multiple lines */}
          <span>100/&#8203;500/&#8203;1000/&#8203;3500</span>
          {t.U7MK(' minutes.')}
        </div>
      </div>
    </section>
  );
};

export const PublicMobileVoiceSubscriptions = (): JSX.Element => {
  const dispatch = useDispatch();
  const onlineModels = useSelector((state: State) => state.selfservice?.onlineModels?.items || [], deepEqual);
  const subscriptionProductData = getVoiceSubscriptionCardContents(true);

  // flatMap removes undefined values
  const onlineModelsCodes = onlineModels.flatMap((onlineModel: OnlineModel) => onlineModel.onlineModelCode);

  /* TODO: rules-of-hooks, copied from CartProductStep, can't see an easy fix */
  useEffect(() => {
    // Only load models not already present
    subscriptionProductData
      .reduce((acc, curr) => {
        curr.offers?.forEach(o => {
          const { onlineModelCode } = o.salesProduct;
          if (!acc.includes(onlineModelCode)) {
            acc.push(onlineModelCode);
          }
        });
        return acc;
      }, [] as string[])
      .forEach(onlineModelCode => {
        if (!onlineModelsCodes.includes(onlineModelCode)) {
          dispatch(loadOnlineModelWithId(onlineModelCode));
        }
      });
  }, [dispatch, subscriptionProductData]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <section className="of-public-mobile-voice-subscriptions of-public-page" id={PAGE_WRAPPER_ID}>
      <Breadcrumbs />
      <Header />
      <SubHeader />
      <Subscriptions />
      <FiveGPlus />
      <AdditionalServices />
      <PublicSelfServiceBanner />
      <ElisaMobileNetwork />
      <div id="GIOSG_CONTENT_PLACEHOLDER"></div>
      <FrequentlyAskedQuestions />
      <AdditionalFeatures />
      <OtherServices />
      <CustomerServiceSolutions />
      <InternetSubscriptionsForCompanies />
      <Avainlippu />
      <Contact />
      <Disclaimer />
      <BackToSubscriptions />
    </section>
  );
};
