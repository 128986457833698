import { TypeKeys, generateActionId } from './actionsUtils.js';
import { convertToCommonErrors } from '../../common/utils/errorUtils.js';
import type { Action } from 'redux';
import type { CrudAction, ErrorAction, ErrorActionCreator } from './actionsUtils.js';
import type { GenericError } from '../../generated/api/models.js';

export interface NotifyShoppingCartChangeAction extends Action {
  type: TypeKeys.NOTIFY_SHOPPING_CART_CHANGE;
}

export const notifyShoppingCartChange = (): NotifyShoppingCartChangeAction => ({
  type: TypeKeys.NOTIFY_SHOPPING_CART_CHANGE,
});

export type SubmitShoppingCartChangeAction = CrudAction<TypeKeys.SUBMIT_SHOPPING_CART_CHANGE>;

export const submitShoppingCartChange = (): SubmitShoppingCartChangeAction => ({
  id: generateActionId(),
  type: TypeKeys.SUBMIT_SHOPPING_CART_CHANGE,
});

export interface SubmitShoppingCartChangeFulfilledAction extends Action {
  shoppingCartId?: string;
  type: TypeKeys.SUBMIT_SHOPPING_CART_CHANGE_FULFILLED;
}

export const submitShoppingCartChangeFulfilled = (shoppingCartId?: string): SubmitShoppingCartChangeFulfilledAction => {
  return {
    shoppingCartId,
    type: TypeKeys.SUBMIT_SHOPPING_CART_CHANGE_FULFILLED,
  };
};

export type SubmitShoppingCartChangeFailedAction = ErrorAction<TypeKeys.SUBMIT_SHOPPING_CART_CHANGE_FAILED>;

export const submitShoppingCartChangeFailed: ErrorActionCreator<TypeKeys.SUBMIT_SHOPPING_CART_CHANGE_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): SubmitShoppingCartChangeFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    params,
    type: TypeKeys.SUBMIT_SHOPPING_CART_CHANGE_FAILED,
  };
};

export type ShoppingCartActionTypes =
  | NotifyShoppingCartChangeAction
  | SubmitShoppingCartChangeAction
  | SubmitShoppingCartChangeFulfilledAction
  | SubmitShoppingCartChangeFailedAction;
