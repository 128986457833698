import { DeliveryOrderDetails } from '../../../../../components/DeliveryOrderDetails/DeliveryOrderDetails.js';
import {
  EmployeeOrdersWrapper,
  getFilteredCustomerOrders,
} from '../../../../../components/EmployeeOrdersWrapper/EmployeeOrdersWrapper.js';
import { EmployeeSceneTab } from '../../../../../common/utils/employeeUtils.js';
import { findItemById } from '../../../../../common/utils/stateUtils.js';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { loadCustomerOrders } from '../../../../../selfservice/actions/index.js';
import { omaElisaForEmployeeMsg, orderRequestsMsg, t } from '../../../../../common/i18n/index.js';
import { onLoadBillingAccounts } from '../../../../../common/utils/dispatcherUtils.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useTitle } from '../../../../../common/hooks/useTitle.js';
import type { DeliveryId, SubscriptionId } from '../../../../../common/constants/pathInterfaces.js';
import type { DeliveryOrder } from '../../../../../generated/api/models.js';
import type { State } from '../../../../../selfservice/common/store.js';

export const PendingReviewItemPath = () => {
  const { subscriptionId, deliveryId } = useParams<SubscriptionId & DeliveryId>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    onLoadBillingAccounts(dispatch);
    dispatch(loadCustomerOrders(subscriptionId, deliveryId));
  }, [dispatch, subscriptionId, deliveryId]);

  const { customerOrders } = useSelector((state: State) => ({
    contacts: state.selfservice?.contacts,
    customerOrders: state.selfservice?.customerOrders,
  }));
  const filteredCustomerOrders = getFilteredCustomerOrders(EmployeeSceneTab.ORDERS_REVIEW_PENDING, customerOrders);
  const customerOrder = findItemById('customerOrderDisplayId', subscriptionId, filteredCustomerOrders);

  const deliveryOrder =
    deliveryId && customerOrder && customerOrder.deliveryOrders
      ? customerOrder.deliveryOrders.find(
          ({ deliveryOrderDisplayId }: DeliveryOrder) => deliveryOrderDisplayId === deliveryId
        )
      : undefined;

  useTitle([
    t.H6JV(omaElisaForEmployeeMsg),
    t.Y29L(orderRequestsMsg),
    customerOrder?.customerOrderDisplayId ?? '',
    deliveryOrder?.deliveryOrderName ?? '',
  ]);

  if (!(customerOrder && deliveryOrder)) {
    return null;
  }

  return (
    <EmployeeOrdersWrapper sceneTab={EmployeeSceneTab.ORDERS_REVIEW_PENDING}>
      <DeliveryOrderDetails
        customerOrder={customerOrder}
        deliveryOrder={deliveryOrder}
        onClickCustomerOrder={() => {
          navigate(generatePath(paths.EMPLOYEE_PENDING_REVIEW_ORDER, { subscriptionId }));
        }}
        breadCrumbPaths={[
          { name: t.H6JV(omaElisaForEmployeeMsg), path: paths.EMPLOYEE_HOME },
          { name: t.Y29L(orderRequestsMsg), path: paths.EMPLOYEE_PENDING_REVIEW_ORDERS },
          {
            name: customerOrder.customerOrderDisplayId,
            path: generatePath(paths.EMPLOYEE_PENDING_REVIEW_ORDER, { subscriptionId }),
          },
          { name: deliveryOrder.deliveryOrderName },
        ]}
      />
    </EmployeeOrdersWrapper>
  );
};
