import { ActionPhase } from '../common/storeUtils.js';
import { TypeKeys } from '../actions/index.js';
import { reduceCrudAction } from './reducerUtils.js';
import { updateActionStatePhase } from '../common/index.js';
import type { ActionsHistory } from '../common/store.js';
import type { PaymentsState } from '../../common/types/states.js';
import type { SelfServiceActionTypes } from '../actions/index.js';

export function paymentsReducer(
  state: (PaymentsState & ActionsHistory) | undefined | null,
  action: SelfServiceActionTypes
): (PaymentsState & ActionsHistory) | null {
  if (typeof state === 'undefined') {
    return null;
  }
  switch (action.type) {
    case TypeKeys.INITIALIZE_PAYMENT: {
      return reduceCrudAction(action, state);
    }

    case TypeKeys.INITIALIZE_PAYMENT_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.INITIALIZE_PAYMENT,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
      };
    }

    case TypeKeys.INITIALIZE_PAYMENT_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.INITIALIZE_PAYMENT,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        transactionId: action.transactionId,
      };
    }

    case TypeKeys.FINALIZE_PAYMENT: {
      return {
        ...reduceCrudAction(action, state),
        paymentCheckInProgress: true,
      };
    }

    case TypeKeys.FINALIZE_PAYMENT_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.FINALIZE_PAYMENT,
          state || { actions: [] },
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        paymentCheckInProgress: false,
      };
    }

    case TypeKeys.FINALIZE_PAYMENT_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.FINALIZE_PAYMENT,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        lastModified: action.lastModified,
        paymentCheckInProgress: false,
      };
    }

    default:
      return state;
  }
}
