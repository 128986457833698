import * as CL from '@design-system/component-library';
import { DEFAULT_COUNTRY_CODE } from '../../common/utils/validationUtils.js';
import { Form, Formik } from 'formik';
import { PhoneNumber, PostalCode, ProvidedInput } from '../../common/formik/Fields/index.js';
import {
  addressConfirmationMsg,
  addressLine2Msg,
  cancelMsg,
  cityMsg,
  companyAddressMsg,
  companyNameMsg,
  continueMsg,
  editMsg,
  homeAddressMsg,
  notMandatoryMsg,
  recipientNameMsg,
  streetAddressMsg,
  t,
} from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { getAddressFromFormikValues } from '../EppMaintenance/eppMaintenanceUtils.js';
import { useEffect, useState } from 'react';
import type { Address, DeliveryAddress } from '../../generated/api/models.js';

import './DeliveryAddressForm.scss';

export type DeliveryAddressFormFormikType = {
  addressLine1: string;
  addressLine2: string;
  postOffice: string;
  postalCode: string;
  recipientName: string;
  phoneNumber: string;
  companyName: string;
  countryCode: string;
  selectedAddressType: DeliveryAddressType;
};

export interface DeliveryAddressFormProps {
  showConfirmAddressCheckbox?: boolean;
  onAddressReady: (value: boolean) => void;
  address?: Address;
  companyName?: string;
  recipientName?: string;
  phoneNumber?: string;
  startInEditMode?: boolean;
  setDeliveryAddress: (deliveryAddress: DeliveryAddress) => void;
}

interface DeliveryAddressFormInternalProps extends Omit<DeliveryAddressFormProps, 'onAddressReady'> {
  formValues: DeliveryAddressFormFormikType;
  selectedAddressType: DeliveryAddressType;
  setSelectedAddressType: (deliveryAddressType: DeliveryAddressType) => void;
  setIsEdit: (value: boolean) => void;
}

interface DeliveryAddressViewModeProps extends DeliveryAddressFormInternalProps {
  onAddressReady: (value: boolean) => void;
}

interface DeliveryAddressEditModeProps extends DeliveryAddressFormInternalProps {
  setFormValues: (formValues: DeliveryAddressFormFormikType) => void;
}

enum DeliveryAddressType {
  COMPANY_ADDRESS = 'COMPANY ADDRESS',
  HOME_ADDRESS = 'HOME ADDRESS',
}

const getCountryCode = (address?: Address) => {
  return address?.countryCode || DEFAULT_COUNTRY_CODE;
};

const CommonFields = () => (
  <>
    <div className="of-delivery-address-form--row">
      <div className="of-delivery-address-form--column">
        <ProvidedInput
          name="recipientName"
          placeholder={t.U9EI(recipientNameMsg)}
          label={t.U9EI(recipientNameMsg)}
          required={true}
          maxLength={140}
        />
      </div>
      <div className="of-delivery-address-form--column">
        <PhoneNumber required={true} />
      </div>
    </div>
    <div className="of-delivery-address-form--row">
      <div className="of-delivery-address-form--column">
        <ProvidedInput
          name="addressLine1"
          placeholder={t.DD38(streetAddressMsg)}
          label={t.DD38(streetAddressMsg)}
          required={true}
          maxLength={40}
        />
      </div>
      <div className="of-delivery-address-form--column">
        <ProvidedInput
          name="addressLine2"
          placeholder={t.O0QI(addressLine2Msg)}
          label={`${t.O0QI(addressLine2Msg)} ${t.NFRH(notMandatoryMsg)}`}
          maxLength={40}
        />
      </div>
    </div>
    <div className="of-delivery-address-form--row">
      <div className="of-delivery-address-form--column">
        <PostalCode required={true} />
      </div>
      <div className="of-delivery-address-form--column">
        <ProvidedInput
          name="postOffice"
          placeholder={t.Z7S5(cityMsg)}
          label={t.Z7S5(cityMsg)}
          required={true}
          maxLength={100}
        />
      </div>
    </div>
  </>
);

const FormFields = ({ selectedAddressType }: { selectedAddressType: DeliveryAddressType }) => {
  if (selectedAddressType === DeliveryAddressType.COMPANY_ADDRESS) {
    return (
      <>
        <div className="of-delivery-address-form--row">
          <div className="of-delivery-address-form--column__company-name">
            <ProvidedInput
              name="companyName"
              placeholder={t.AJ93(companyNameMsg)}
              label={t.AJ93(companyNameMsg)}
              required={true}
              maxLength={256}
            />
          </div>
        </div>
        <CommonFields />
      </>
    );
  }
  return <CommonFields />;
};

type InitialValuesType = {
  address?: Address;
  companyName?: string;
  recipientName?: string;
  phoneNumber?: string;
  selectedAddressType?: DeliveryAddressType;
};
const createAddressFormInitialValues = ({
  address,
  companyName,
  recipientName,
  phoneNumber,
  selectedAddressType,
}: InitialValuesType): DeliveryAddressFormFormikType => {
  return {
    addressLine1: address?.line1 || '',
    addressLine2: address?.line2 || '', // Optional field, the value can be empty
    postOffice: address?.postOffice || '',
    postalCode: address?.postalCode || '',
    recipientName: recipientName || '',
    phoneNumber: phoneNumber || '',
    companyName: companyName || '',
    countryCode: getCountryCode(address),
    selectedAddressType: selectedAddressType || DeliveryAddressType.COMPANY_ADDRESS,
  };
};

const resetFormFields = (
  setFieldValue: (field: string, value: string) => void,
  values?: DeliveryAddressFormFormikType
) =>
  ['addressLine1', 'addressLine2', 'postOffice', 'postalCode', 'recipientName', 'phoneNumber', 'companyName'].forEach(
    (field: keyof DeliveryAddressFormFormikType) => setFieldValue(field, values?.[field] || '')
  );

interface DeliveryTypeTabProps {
  formValues: DeliveryAddressFormFormikType;
  selectedAddressType: DeliveryAddressType;
  setSelectedAddressType: (addressType: DeliveryAddressType) => void;
  setFieldValue: (field: string, value: string) => void;
  resetForm: () => void;
}

const DeliveryTypeTab = ({
  formValues,
  selectedAddressType,
  setSelectedAddressType,
  setFieldValue,
  resetForm,
}: DeliveryTypeTabProps) => {
  const [previousTabValues, setPreviousTabValues] = useState<DeliveryAddressFormFormikType>();
  const companyTab: CL.SelectGroupProductProps = {
    id: DeliveryAddressType.COMPANY_ADDRESS,
    value: DeliveryAddressType.COMPANY_ADDRESS,
    selected: DeliveryAddressType.COMPANY_ADDRESS === selectedAddressType,
    name: t.VOF3(companyAddressMsg),
    icon: <CL.Icon icon="office" size="l" />,
  };

  const homeTab: CL.SelectGroupProductProps = {
    id: DeliveryAddressType.HOME_ADDRESS,
    value: DeliveryAddressType.HOME_ADDRESS,
    selected: DeliveryAddressType.HOME_ADDRESS === selectedAddressType,
    name: t.D4FU(homeAddressMsg),
    icon: <CL.Icon icon="home" size="l" />,
  };

  return (
    <CL.SelectGroup
      className={`${dsClass.PADDING_0} ${dsClass.PADDING_TOP_3} ${dsClass.PADDING_BOTTOM_4}`}
      products={[companyTab, homeTab]}
      onChange={value => {
        if (value && value !== selectedAddressType) {
          setSelectedAddressType(value as DeliveryAddressType);
          resetForm(); // Clears errors but also sets initial values, so we need to set empty values after that
          resetFormFields(setFieldValue, previousTabValues);
          setPreviousTabValues(formValues);
        }
      }}
      value={selectedAddressType}
    />
  );
};

const DeliveryAddressFormEditMode = (props: DeliveryAddressEditModeProps) => {
  const { formValues, setFormValues, selectedAddressType, setIsEdit, setSelectedAddressType } = props;

  return (
    <Formik
      initialValues={formValues}
      onSubmit={(values: DeliveryAddressFormFormikType) => {
        setFormValues({ ...values, selectedAddressType });
        setIsEdit(false);
      }}
    >
      {({ setFieldValue, resetForm, values }) => {
        return (
          <Form noValidate className="of-delivery-address-form">
            <DeliveryTypeTab
              formValues={values}
              selectedAddressType={selectedAddressType}
              setSelectedAddressType={setSelectedAddressType}
              setFieldValue={setFieldValue}
              resetForm={resetForm}
            />
            <FormFields selectedAddressType={selectedAddressType} />
            <div className={dsClass.PADDING_TOP_4}>
              <CL.Button type="submit">{t.I62A(continueMsg)}</CL.Button>
              <CL.Button
                type="button"
                color="light"
                className={dsClass.MARGIN_LEFT_3}
                onClick={() => {
                  setSelectedAddressType(formValues.selectedAddressType);
                  setIsEdit(false);
                }}
              >
                {t.B2V1(cancelMsg)}
              </CL.Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const AddressConfirmation = ({ onAddressConfirmed }: { onAddressConfirmed: (isChecked: boolean) => void }) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    onAddressConfirmed(isChecked);
  }, [isChecked, onAddressConfirmed]);

  return (
    <div className="of-delivery-address-form--address-confirmation">
      <CL.Checkbox
        className={dsClass.COLOR_BLUE}
        checked={isChecked}
        onChange={() => {
          setIsChecked(!isChecked);
        }}
      >
        {t.T9V1(addressConfirmationMsg)}
      </CL.Checkbox>
    </div>
  );
};

const SelectedAddressTypeInfo = ({ deliveryAddressType }: { deliveryAddressType: DeliveryAddressType }) => {
  const iconType = deliveryAddressType === DeliveryAddressType.COMPANY_ADDRESS ? 'office' : 'home';
  const addressInfoText =
    deliveryAddressType === DeliveryAddressType.COMPANY_ADDRESS ? t.A9VQ(companyAddressMsg) : t.S5QX(homeAddressMsg);

  return (
    <>
      <div className="of-delivery-address-form--delivery-address-type--icon">
        <CL.Icon icon={iconType} size="l" />
      </div>
      <div className="of-delivery-address-form--delivery-address-type--text">
        <strong>{addressInfoText}:</strong>
      </div>
    </>
  );
};

const AddressDetails = ({
  formValues,
  selectedAddressType,
}: {
  formValues: DeliveryAddressFormFormikType;
  selectedAddressType: DeliveryAddressType;
}) => (
  <div className="of-delivery-address-form--left">
    <div>{`${
      selectedAddressType === DeliveryAddressType.COMPANY_ADDRESS ? formValues.companyName : formValues.recipientName
    }, `}</div>
    <div>{`${formValues.addressLine1}${formValues.addressLine2 ? ' ' + formValues.addressLine2 : ''}, `}</div>
    <div>{`${formValues.postalCode} ${formValues.postOffice}`}</div>
  </div>
);

const EditButton = ({
  setIsEdit,
  onAddressReady,
}: {
  setIsEdit: (isEdit: boolean) => void;
  onAddressReady: (value: boolean) => void;
}) => (
  <div className="of-delivery-address-form--right">
    <CL.Button
      color="link"
      onClick={() => {
        setIsEdit(true);
        onAddressReady(false);
      }}
    >
      {t.NVPK(editMsg)}
    </CL.Button>
  </div>
);

const DeliveryAddressFormViewMode = ({
  showConfirmAddressCheckbox = false,
  onAddressReady,
  ...rest
}: DeliveryAddressViewModeProps) => {
  const { formValues, setIsEdit, selectedAddressType } = rest;

  return (
    <>
      <div className="of-delivery-address-form">
        <div className="of-delivery-address-form--view-column">
          <SelectedAddressTypeInfo deliveryAddressType={selectedAddressType} />
          <AddressDetails formValues={formValues} selectedAddressType={selectedAddressType} />
          <EditButton setIsEdit={setIsEdit} onAddressReady={onAddressReady} />
        </div>
      </div>
      {showConfirmAddressCheckbox ? <AddressConfirmation onAddressConfirmed={onAddressReady} /> : onAddressReady(true)}
    </>
  );
};

export const DeliveryAddressForm = ({ onAddressReady, startInEditMode, ...rest }: DeliveryAddressFormProps) => {
  const { address, recipientName, phoneNumber, companyName, setDeliveryAddress } = rest;
  const [formValues, setFormValues] = useState(createAddressFormInitialValues(rest));
  const [selectedAddressType, setSelectedAddressType] = useState(DeliveryAddressType.COMPANY_ADDRESS);

  // Internal state variable which holds information if the form is in edit or view mode. Parent component may not have
  // information if the form is being edited even though there is an optional property for setting it (setEditModeInParent).
  const [isEdit, setIsEdit] = useState(startInEditMode || !address || !companyName || !recipientName || !phoneNumber);

  useEffect(() => {
    if (!isEdit) {
      setDeliveryAddress(getAddressFromFormikValues(selectedAddressType, formValues));
    }
  }, [setDeliveryAddress, formValues, selectedAddressType, isEdit]);

  return isEdit ? (
    <DeliveryAddressFormEditMode
      setIsEdit={setIsEdit}
      selectedAddressType={selectedAddressType}
      setSelectedAddressType={setSelectedAddressType}
      formValues={formValues}
      setFormValues={setFormValues}
      {...rest}
    />
  ) : (
    <DeliveryAddressFormViewMode
      formValues={formValues}
      setIsEdit={setIsEdit}
      selectedAddressType={selectedAddressType}
      setSelectedAddressType={setSelectedAddressType}
      onAddressReady={onAddressReady}
      {...rest}
    />
  );
};
