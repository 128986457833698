import * as RP from '../../legacy/react-patterns/source/index.js';
import * as React from 'react';

import './CompositeListSearch.scss';

export interface CompositeListSearchProps {
  columnIds?: string[]; // the column ids whose values we search, undefined means all values
  objectKeys?: string[]; // object keys whose values we search. can be used to search object keys which as not displayed as a column. undefined means no object key is searched
  placeholder: string;
  onChange: (searchString: string) => void;
  value: string;
}

function formatToSearchable(item: string): string {
  const itemWithoutSpaces = item.valueOf().replace(/\s/g, '');
  // is just numbers (first replace +358 with 0)
  if (/^\d+$/.test(itemWithoutSpaces.replace('+358', '0'))) {
    return itemWithoutSpaces + ' ';
  }
  return item + ' ';
}

function searchStringsInElement(elem: JSX.Element): string {
  let result = ' ';
  const props = elem.props;

  if ('children' in props) {
    const child = props.children;
    if (typeof child === 'string') {
      result = result.concat(formatToSearchable(child));
    } else if (Array.isArray(child)) {
      child.forEach(item => {
        if (typeof item === 'string') {
          result = result.concat(formatToSearchable(item));
        } else if (React.isValidElement(item)) {
          result = result.concat(searchStringsInElement(item));
        }
      });
    } else if (React.isValidElement(child)) {
      result = result.concat(searchStringsInElement(child));
    }
  }
  return result;
}

export function valueInCellValueLines(searchWords: string, rowCellValueLines: Array<string | JSX.Element>[]): boolean {
  let searchableContent = '';
  rowCellValueLines.forEach(line => {
    if (Array.isArray(line)) {
      line.forEach(lineItem => {
        if (typeof lineItem === 'string') {
          searchableContent = searchableContent.concat(formatToSearchable(lineItem));
        } else if (React.isValidElement(lineItem)) {
          searchableContent = searchableContent.concat(searchStringsInElement(lineItem));
        }
      });
    }
  });
  return searchWords.split(' ').every(word => {
    return searchableContent.toLowerCase().includes(word.toLowerCase());
  });
}

export const CompositeListSearch = ({ onChange, placeholder, value }: CompositeListSearchProps) => (
  <div className="ea-composite-list-search">
    <RP.Input
      onChange={(e: React.FormEvent<HTMLInputElement>) => onChange && onChange(e.currentTarget.value)}
      icon="search"
      name="search"
      optionalText=""
      placeholder={placeholder}
      type="text"
      value={value}
      disableAutoStatus
    />
  </div>
);
