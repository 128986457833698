const imageProxyOrigin = 'https://static.elisa.com';
const IMAGE_PROXY_REGEX = new RegExp(`${imageProxyOrigin}/(v2/image)`);
const IMAGE_PROXY_LEGACY_REGEX = new RegExp(`${imageProxyOrigin}/(picture-storage)`);
export const pictureStorageBaseUrl = `${imageProxyOrigin}/v2/image/2tqybbhjs47b/`;

export interface ResizeParams {
  width?: number;
  height?: number;
  format?: string;
  quality?: number;
}

/**
 * Online-processor has same logic implemented to extract the fully qualified image url from relative
 * path in the function ``getFullyQualifiedUrl`` in file ImageSitemapUtils.java hence, if
 * required, change the logic at both places to get a consistent result.
 * https://github.devcloud.elisa.fi/onlinefirst/online/blob/master/online-processor/src/main/java/elisa/onlinefirst/online/processor/projections/publicz/ImageSitemapUtils.java
 * NOTE: This function has been placed at the top of this file purposefully to get the function at
 * 1st glance, in case there is need to change
 */
function getFullyQualifiedUrl(imagePath: string): string {
  if (imagePath.startsWith('/static/image/')) {
    return pictureStorageBaseUrl + imagePath.replace('/static/image/', '');
  } else if (imagePath.startsWith('/')) {
    return pictureStorageBaseUrl + imagePath.substring(1);
  } else if (imagePath.startsWith('http')) {
    if (IMAGE_PROXY_REGEX.test(imagePath)) {
      return imagePath;
    } else if (IMAGE_PROXY_LEGACY_REGEX.test(imagePath)) {
      return `${pictureStorageBaseUrl}${imagePath.split('/').slice(-2).join('/')}`;
    } else {
      throw new Error(`Unknown image server URL: ${imagePath}`);
    }
  } else {
    return pictureStorageBaseUrl + imagePath;
  }
}

export function getImageUrl(imagePath: string, resizeParams: ResizeParams = {}): string {
  const queryParams: string[] = [];
  const { width, height, format, quality } = resizeParams;
  if (width) {
    queryParams.push(`w=${width}`);
  }
  if (height) {
    queryParams.push(`h=${height}`);
  }
  if (format) {
    queryParams.push(`fm=${format}`);
  }
  if (quality) {
    queryParams.push(`q=${quality}`);
  }
  const queryString = queryParams.length > 0 ? '?' + queryParams.join('&') : '';
  return getFullyQualifiedUrl(imagePath) + queryString;
}
