import * as CL from '@design-system/component-library';
import { dsClass } from '../../../../common/constants/dsClasses.js';
import type { ImpactedUser } from '../utils.js';

export const formatRecordListViewRow = (row: ImpactedUser, openEdit: (row: ImpactedUser) => void) =>
  ({
    ...row,
    actionButtons: (
      <div key="view" className={`${dsClass.WHITE_SPACE_NOWRAP} of-dns-record-list-row-view`}>
        <>
          <CL.Button
            color="transparent"
            className={`${dsClass.BORDER_STYLE_NONE} ${dsClass.PADDING_VERTICAL_0} ${dsClass.MARGIN_RIGHT_2}`}
            size="s"
            onClick={() => openEdit(row)}
          >
            <CL.Icon icon="edit" color="brand-blue" />
          </CL.Button>
        </>
      </div>
    ),
  }) satisfies CL.KeyRow;
