import { HeroHeading, HeroHeadingType } from '../HeroHeading/index.js';
import { LocalNavi } from '../LocalNavi/index.js';
import { SystemError, hasSystemError } from '../SystemError/SystemError.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { omaElisaForCompaniesMsg, orderHistoryMsg, ordersMsg, redeemRequestsMsg, t } from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import { resetErrors } from '../../selfservice/actions/index.js';
import { stripActions } from '../../common/utils/stateUtils.js';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import type { BreadCrumbList } from '../BreadCrumbs/index.js';
import type { ReactNode } from 'react';
import type { State } from '../../selfservice/common/store.js';

import './CustomerOrders.scss';

export enum CustomerOrdersSceneTab {
  CUSTOMER_ORDERS = 'CUSTOMER_ORDERS',
  REDEEM_REQUESTS = 'REDEEM_REQUESTS',
}

interface CustomerOrdersProps {
  children: ReactNode;
  tab: CustomerOrdersSceneTab;
}

const getBreadCrumbs = (tab: CustomerOrdersSceneTab): BreadCrumbList | undefined => {
  switch (tab) {
    case CustomerOrdersSceneTab.CUSTOMER_ORDERS:
      return [{ name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME }, { name: t.I9C0(ordersMsg) }];
    case CustomerOrdersSceneTab.REDEEM_REQUESTS:
      return [
        { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
        { name: t.Z1M1(redeemRequestsMsg) },
      ];
    default:
      return;
  }
};

export const CustomerOrders = ({ children, tab }: CustomerOrdersProps) => {
  return (
    <div className="of-customer-order-scene">
      <HeroHeading
        breadCrumbPaths={getBreadCrumbs(tab)}
        heroHeadingType={HeroHeadingType.CUSTOMER_ORDERS}
        title={t.GCO3(orderHistoryMsg)}
      />
      <LocalNavi
        categories={[
          {
            children: t.I9C0(ordersMsg),
            to: paths.CUSTOMER_ORDERS,
          },
          {
            children: t.Z1M1(redeemRequestsMsg),
            to: paths.CUSTOMER_ORDER_REDEEM_REQUESTS,
          },
        ]}
      />
      {children}
    </div>
  );
};

interface CustomerOrderSystemErrorProps {
  children: ReactNode;
}

export const CustomerOrderSystemError = ({ children }: CustomerOrderSystemErrorProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const contacts = useSelector((state: State) => state.selfservice?.contacts, deepEqual);
  const customerOrders = useSelector((state: State) => stripActions(state.selfservice?.customerOrders), deepEqual);
  const errors = customerOrders?.errors || contacts?.errors;
  if (hasSystemError(errors)) {
    return (
      <SystemError
        errors={errors}
        onButtonClick={() => {
          navigate(paths.SELF_SERVICE_HOME);
          dispatch(resetErrors());
        }}
      />
    );
  }
  return <>{children}</>;
};
