import * as CL from '@design-system/component-library';
import * as React from 'react';
import { ProductGridHeader } from '../../components/ProductGrid/ProductGridHeader.js';
import { createFilterEvent, createSortEvent } from '../../common/utils/analyticsUtils.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { filterFn } from '../../components/ProductGrid/ProductGridFilter.js';
import { getLocationFromQueryParams, parseQueryParams } from '../../components/ProductGrid/ProductGridUrlUtils.js';
import { searchFn } from '../../components/ProductGrid/ProductGridSearch.js';
import { showMoreMsg, t } from '../../common/i18n/index.js';
import { sortFn } from '../../components/ProductGrid/ProductGridSort.js';
import { useDebounce } from '../../common/hooks/useDebounce.js';
import { useEffect, useState } from 'react';
import { useIdObserver } from '../../components/Impression/useIdObserver.js';
import { useLocation, useNavigate } from 'react-router-dom';
import Templates from '@elisa-luukku-sites/cms-templates';
import type * as CLT from '@design-system/component-library';
import type { Filters } from '../../components/ProductGrid/ProductGridFilter.js';
import type { ProductGridItem } from './CategoryList.js';
import type { ProductGridProps } from '../../components/ProductGrid/ProductGrid.js';
import type { SortType } from '../../components/ProductGrid/ProductGridSort.js';

import './ProductGridContainer.scss';

const SiteTemplates = Templates.default;

export const ProductGridContainer = (props: ProductGridProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { category, items, eCommerceEvents, onAnalyticsEvent, itemLimit, id } = props;
  // This exists to enable filtering in multiple product grids on the same page and remember their settings
  const urlParameterPrefix = id ?? '';
  const itemsPerScroll = itemLimit || 8;
  const urlSearchParams = new URLSearchParams(location.search);
  const [itemsToDisplay, setItemsToDisplay] = useState<number>(itemsPerScroll);
  const debouncedNavigate = useDebounce(url => navigate(url, { replace: true }), 1000);
  const [productGridItems, setProductGridItems] = useState<ProductGridItem[]>(() => {
    const params = parseQueryParams(items, category, urlSearchParams, urlParameterPrefix);
    return items.filter(searchFn(params.search)).filter(filterFn(params.filters)).sort(sortFn(params.sort));
  });

  const [currentParams, setCurrentParams] = useState(
    parseQueryParams(items, category, urlSearchParams, urlParameterPrefix)
  );

  const fetchMoreItems = () => {
    if (itemsToDisplay <= productGridItems.length) {
      setItemsToDisplay(itemsToDisplay + itemsPerScroll);
    }
  };

  const hasMoreItems = () => {
    return itemsToDisplay < productGridItems.length;
  };

  const getSlicedGridItems = (): CLT.ProductGridProduct[] => {
    return productGridItems
      .slice(0, itemsToDisplay)
      .map(i => {
        return i.product;
      })
      .flatMap(item => (item ? [item] : []));
  };

  const onProductClick = (element: HTMLElement, event: React.SyntheticEvent) => {
    // This is not optimal because we are now tied to CL element structure and if that changes, this will break
    const elementId = element.getAttribute('aria-labelledby');
    const gridItem = productGridItems.find(item => {
      return `${item.code}Name` === elementId;
    });
    const position = gridItem ? productGridItems.indexOf(gridItem) : 0;
    if (eCommerceEvents?.onClickListProduct && gridItem?.ref) {
      eCommerceEvents.onClickListProduct('CategoryList', [position], [gridItem.ref]);
    }
    if (gridItem?.pagePath) {
      // Do not use the link click to navigate as that causes a full page reload
      event.preventDefault();
      navigate(gridItem.pagePath);
    }
  };

  const onImpression = (itemId: string) => {
    const gridItem = productGridItems.find(item => {
      return item.code === itemId;
    });
    const position = gridItem ? productGridItems.indexOf(gridItem) : 0;
    if (eCommerceEvents?.onSeeListProduct && gridItem?.ref) {
      eCommerceEvents.onSeeListProduct('CategoryList', [position], [gridItem.ref]);
    }
  };

  const handleSortChange = (sortType: SortType) => {
    const params = parseQueryParams(items, category, urlSearchParams, urlParameterPrefix);
    params.sort = sortType;
    setCurrentParams(params);
    debouncedNavigate(getLocationFromQueryParams(params, urlParameterPrefix));
  };

  const handleFilterChange = (filter: Filters) => {
    const params = parseQueryParams(items, category, urlSearchParams, urlParameterPrefix);
    params.filters = filter;
    setCurrentParams(params);
    debouncedNavigate(getLocationFromQueryParams(params, urlParameterPrefix));
  };

  const handleSearchChange = (searchValue: string) => {
    const params = parseQueryParams(items, category, urlSearchParams, urlParameterPrefix);
    params.search = searchValue;
    setCurrentParams(params);
    debouncedNavigate(getLocationFromQueryParams(params, urlParameterPrefix));
  };

  useIdObserver(
    getSlicedGridItems().map(p => p.productId),
    onImpression
  );

  useEffect(() => {
    setProductGridItems(
      items
        .filter(searchFn(currentParams.search))
        .filter(filterFn(currentParams.filters))
        .sort(sortFn(currentParams.sort))
    );
  }, [currentParams, items, category]);

  return (
    <div className="of-product-grid of-product-grid-container">
      <SiteTemplates.CategoryList
        {...props}
        products={getSlicedGridItems()}
        onProductClick={onProductClick}
        header={
          <ProductGridHeader
            filters={currentParams.filters}
            search={currentParams.search}
            sort={currentParams.sort}
            setFilters={(filter: Filters) => {
              if (onAnalyticsEvent) {
                onAnalyticsEvent(createFilterEvent(filter));
              }
              handleFilterChange(filter);
            }}
            setSearch={handleSearchChange}
            setSort={(sortType: SortType) => {
              if (onAnalyticsEvent) {
                onAnalyticsEvent(createSortEvent(sortType));
              }
              handleSortChange(sortType);
            }}
          />
        }
      />
      {hasMoreItems() && (
        <div className="center-items">
          <CL.Button
            size="l"
            onClick={fetchMoreItems}
            color="light"
            className={`${dsClass.MARGIN_TOP_4} ${dsClass.MARGIN_BOTTOM_4}`}
          >
            {t.H6OA(showMoreMsg)}
          </CL.Button>
        </div>
      )}
    </div>
  );
};
