import { SalesType } from '../../generated/api/models.js';
import { getFilteredCampaignAssociationsBySalesType, isCampaignPresent } from './campaignUtils.js';
import { getTaxAmount, getTaxAmountFromPriceWithTax } from './taxUtils.js';
import type { AddedAddon } from '../types/addon.js';
import type { CampaignAssociation, CommercialProduct } from '../../generated/api/models.js';
import type { ConfiguredCommercialProduct } from '../types/commercialProduct.js';

const getAddedAddOnsOneTimePrice = (addedAddOns?: AddedAddon[]) =>
  addedAddOns ? addedAddOns.reduce((total, addOn) => total + (addOn.oneTimePrice || 0), 0) : 0;

const getAddedAddOnsMonthlyPrice = (addedAddOns?: AddedAddon[]) =>
  addedAddOns ? addedAddOns.reduce((total, addOn) => total + (addOn.monthlyPrice || 0), 0) : 0;

export const getCommercialProductPrices = (
  product: CommercialProduct,
  campaignAssociation?: CampaignAssociation,
  salesType?: SalesType
) => {
  let oneTimeCharge = product.oneTimeCharge;
  let monthlyRecurringCharge = product.monthlyRecurringCharge;
  let oneTimeChargeBeforeDiscount;
  let monthlyRecurringChargeBeforeDiscount;

  const filteredProductCampaignAssociations = getFilteredCampaignAssociationsBySalesType(
    product?.campaignAssociations || [],
    salesType || SalesType.NEW_SALE
  );

  const productCampaignAssociation =
    campaignAssociation ||
    (isCampaignPresent(filteredProductCampaignAssociations) && filteredProductCampaignAssociations![0]);

  const matchingSalesType =
    productCampaignAssociation &&
    (productCampaignAssociation.salesType === salesType || productCampaignAssociation.salesType === SalesType.BOTH);

  if (productCampaignAssociation && matchingSalesType) {
    if (productCampaignAssociation.oneTimeCharge && oneTimeCharge) {
      oneTimeChargeBeforeDiscount = oneTimeCharge;
      oneTimeCharge = oneTimeCharge! - productCampaignAssociation.oneTimeCharge!;
    }
    if (productCampaignAssociation.monthlyRecurringCharge && monthlyRecurringCharge) {
      monthlyRecurringChargeBeforeDiscount = monthlyRecurringCharge;
      monthlyRecurringCharge = monthlyRecurringCharge! - productCampaignAssociation.monthlyRecurringCharge!;
    }
  }
  return {
    oneTimeCharge,
    monthlyRecurringCharge,
    oneTimeChargeBeforeDiscount,
    monthlyRecurringChargeBeforeDiscount,
  };
};

export interface TotalSumsItem {
  totalFixedTermCharge: number;
  totalMonthlyRecurringCharge: number;
  totalOneTimeCharge: number;
  totalMonthlyRecurringChargeVat: number;
  totalOneTimeChargeVat: number;
}
export const getTotalSums = (
  commercialProducts: ConfiguredCommercialProduct[],
  campaignAssociation?: CampaignAssociation,
  salesType?: SalesType,
  priceIncludesVAT?: boolean
): TotalSumsItem =>
  commercialProducts.reduce(
    (acc, cur) => {
      const { oneTimeCharge, monthlyRecurringCharge } = getCommercialProductPrices(
        cur.commercialProduct,
        cur.selectedCampaignAssociation || campaignAssociation,
        salesType
      );

      return {
        totalFixedTermCharge:
          acc.totalFixedTermCharge +
          (cur.commercialProduct && cur.commercialProduct.payments && cur.commercialProduct.payments > 0
            ? (monthlyRecurringCharge || 0) * cur.commercialProduct.payments
            : 0),
        totalMonthlyRecurringCharge:
          acc.totalMonthlyRecurringCharge + (monthlyRecurringCharge || 0) + getAddedAddOnsMonthlyPrice(cur.addedAddOns),
        totalOneTimeCharge: acc.totalOneTimeCharge + (oneTimeCharge || 0) + getAddedAddOnsOneTimePrice(cur.addedAddOns),
        totalMonthlyRecurringChargeVat:
          acc.totalMonthlyRecurringChargeVat +
          (priceIncludesVAT
            ? getTaxAmountFromPriceWithTax(monthlyRecurringCharge || 0)
            : getTaxAmount(monthlyRecurringCharge || 0)) +
          (priceIncludesVAT
            ? getTaxAmountFromPriceWithTax(getAddedAddOnsMonthlyPrice(cur.addedAddOns))
            : getTaxAmount(getAddedAddOnsMonthlyPrice(cur.addedAddOns))),
        totalOneTimeChargeVat:
          acc.totalOneTimeChargeVat +
          (priceIncludesVAT ? getTaxAmountFromPriceWithTax(oneTimeCharge || 0) : getTaxAmount(oneTimeCharge || 0)) +
          (priceIncludesVAT
            ? getTaxAmountFromPriceWithTax(getAddedAddOnsOneTimePrice(cur.addedAddOns))
            : getTaxAmount(getAddedAddOnsOneTimePrice(cur.addedAddOns))),
      };
    },
    {
      totalFixedTermCharge: 0,
      totalMonthlyRecurringCharge: 0,
      totalOneTimeCharge: 0,
      totalMonthlyRecurringChargeVat: 0,
      totalOneTimeChargeVat: 0,
    }
  );

export const getCommercialProductSum = (
  commercialProducts: ConfiguredCommercialProduct[],
  campaignAssociation?: CampaignAssociation,
  salesType?: SalesType
): {
  totalFixedTermCharge: number;
  totalMonthlyRecurringCharge: number;
  totalOneTimeCharge: number;
} => {
  return getTotalSums(commercialProducts, campaignAssociation, salesType);
};

export const suspendedCommercialProductCodes = [
  'a0d2e28a-8ad8-aea3-9929-02aa2498c2fe', // Elisa Hyllyliittymä
  '3ca41f56-58b4-c6ca-5b5c-5e492f8b689e', // Elisa Hyllyliittymä Data
];
