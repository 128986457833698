import { ActionPhase } from '../common/storeUtils.js';
import { TypeKeys } from '../actions/index.js';
import { reduceCrudAction } from './reducerUtils.js';
import { updateActionStatePhase } from '../common/index.js';
import type { ActionsHistory } from '../common/store.js';
import type { ExternalAuthenticationState } from '../../common/types/states.js';
import type { SelfServiceActionTypes } from '../actions/index.js';

export function externalAuthenticationReducer(
  state: (ExternalAuthenticationState & ActionsHistory) | undefined,
  action: SelfServiceActionTypes
): (ExternalAuthenticationState & ActionsHistory) | null {
  if (state === undefined) {
    return null;
  }
  switch (action.type) {
    case TypeKeys.VALIDATE_USER_AUTHORITY: {
      return {
        ...reduceCrudAction(action, state),
      };
    }
    case TypeKeys.VALIDATE_USER_AUTHORITY_FAILED: {
      return {
        ...state,
        errors: action.errors,
      };
    }
    case TypeKeys.EXTERNAL_AUTHENTICATION_FAILED: {
      return { ...state, errors: [action.error] };
    }
    case TypeKeys.IDENTIFY_STRONGLY_FAILED: {
      return { ...state, errors: action.errors };
    }
    case TypeKeys.REGISTER_USER: {
      return {
        ...reduceCrudAction(action, state),
        registrationInProgress: true,
      };
    }
    case TypeKeys.REGISTER_USER_FULFILLED: {
      return {
        ...state,
        registrationCompleted: true,
        registrationInProgress: false,
        actions: updateActionStatePhase(TypeKeys.REGISTER_USER, state!, ActionPhase.IN_PROGRESS, ActionPhase.SUCCESS),
      };
    }
    case TypeKeys.REGISTER_USER_FAILED: {
      return {
        ...state,
        errors: action.errors,
        registrationInProgress: false,
        actions: updateActionStatePhase(TypeKeys.REGISTER_USER, state!, ActionPhase.IN_PROGRESS, ActionPhase.FAILED),
        isInExternalSystem: action.errors?.some(error => error.source?.statusCode === 409),
      };
    }
    case TypeKeys.USER_EXISTS: {
      return { ...reduceCrudAction(action, state) };
    }
    case TypeKeys.USER_EXISTS_FAILED: {
      return {
        ...state,
        validationErrors: { email: true },
        actions: updateActionStatePhase(TypeKeys.USER_EXISTS, state!, ActionPhase.IN_PROGRESS, ActionPhase.FAILED),
      };
    }
    case TypeKeys.USER_EXISTS_FULFILLED: {
      return {
        ...state,
        validationErrors: { email: action.exists },
        actions: updateActionStatePhase(TypeKeys.USER_EXISTS, state!, ActionPhase.IN_PROGRESS, ActionPhase.FAILED),
      };
    }
    default: {
      return state;
    }
  }
}
