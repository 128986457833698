import { Input } from '../../../components/Input/Input.js';
import { TooltipWrapper } from '../../../components/TooltipWrapper/TooltipWrapper.js';
import { createFieldClassName } from '../utils.js';
import { getIn } from 'formik';
import type { CSSProperties, ReactNode } from 'react';
import type { FieldInputProps, FormikComputedProps, FormikHelpers, FormikState, FormikValues } from 'formik';

export interface OptionalInputComponentProps {
  name?: string;
  type?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  readonly?: boolean;
  onChange?: () => void;
  helpText?: string;
  // Migration to CL has started and Formik is already quite wide-spread
  // Only chance to have small PRs is to move to CL gradually
  // When no more of Input components use RP this should be removed
  useCL?: boolean;
}

export interface InputComponentProps {
  field: FieldInputProps<string>;
  form: FormikState<FormikValues> & FormikHelpers<FormikValues> & FormikComputedProps<FormikValues>;
  type: string;
  label: string | JSX.Element;
  disabled: boolean;
  required: boolean;
  readonly?: boolean;
  placeholder?: string;
  style?: CSSProperties;
  children?: ReactNode;
  onFocus?: () => void;
  helpText?: string;
  labelTooltip?: string;
}

export const InputComponent = (props: InputComponentProps) => {
  const { name, onBlur, onChange, value } = props.field;

  return (
    <div id={name}>
      <div className="of-formik-text-input">
        <Input
          error={(getIn(props.form.touched, name) && getIn(props.form.errors, name)) || undefined}
          tooltip={<TooltipWrapper tooltipText={props.labelTooltip} />}
          hint={props.helpText}
          label={props.label || ''}
          onFocus={props.onFocus}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          name={name}
          type={props.type}
          placeholder={props.readonly ? (value ? props.placeholder : '-') : props.placeholder}
          readOnly={props.readonly}
          disabled={props.disabled}
          required={props.required}
          className={createFieldClassName(props.field)}
          style={props.style}
        >
          {props.children}
        </Input>
      </div>
    </div>
  );
};
