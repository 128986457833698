import { Field } from 'formik';
import { InputComponent } from '../FieldComponents/index.js';
import {
  POSTAL_CODE_MAX_LENGTH,
  POSTAL_CODE_REGEX,
  POSTAL_CODE_WITH_PO_BOX_REGEX,
} from '../../utils/validationUtils.js';
import { fieldCantBeEmptyMsg, postalCodeMsg, t } from '../../i18n/index.js';
import type { OptionalInputComponentProps } from '../FieldComponents/index.js';

export interface PostalCodeProps extends OptionalInputComponentProps {
  allowPoBox?: boolean;
}

export const PostalCode = ({ allowPoBox, ...props }: PostalCodeProps) => {
  const translation = t.RUAW(postalCodeMsg);
  const validPostalCodeTester = allowPoBox ? POSTAL_CODE_WITH_PO_BOX_REGEX : POSTAL_CODE_REGEX;

  const validate = (value: string) => {
    if (!value && props.required !== false) {
      return t.VPVR(fieldCantBeEmptyMsg);
    }

    return validPostalCodeTester.test(value) ? undefined : t.AT96('Invalid postal code');
  };

  return (
    <Field
      {...{
        name: 'postalCode',
        label: translation,
        placeholder: translation,
        disabled: false,
        required: props.required ?? true,
        maxLength: POSTAL_CODE_MAX_LENGTH,
        validate,
        ...props,
      }}
      component={InputComponent}
    />
  );
};
