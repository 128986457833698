import {
  authenticationEpic,
  deviceCheckoutEpics,
  employeeEpic,
  resourcesEpic,
  selfserviceEpic,
  userEpic,
} from '../../selfservice/exports.js';
import { combineEpics } from 'redux-observable';
import type { ActionTypes } from './siteActions.js';
import type { Epic } from 'redux-observable';
import type { EpicDependencies } from '../../selfservice/exports.js';

export const siteEpic: Epic<ActionTypes, ActionTypes, object, EpicDependencies> = combineEpics(
  selfserviceEpic,
  employeeEpic,
  deviceCheckoutEpics,
  userEpic,
  resourcesEpic,
  authenticationEpic
);
