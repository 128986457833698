import { eurosToCents } from './priceUtils.js';
import type { AddOn, CommercialProduct } from '../../generated/api/models.js';
import type { AssociationRecord } from '@onlinefirst/cloudsense-add-on-dependency-engine';
import type { ShoppingCartAddOn } from '../types/checkout.js';

export const SQUARETRADE = 'SQUARETRADE';

export const isSquareTradeAddOn = (addOn?: AddOn | ShoppingCartAddOn): boolean => addOn?.addOnType === SQUARETRADE;

export const openingFeesAddonCodes = [
  '1c19a5bb-9a9d-30a3-95e1-81e1f2ce56f4',
  '921c3964-b760-2eab-bfc4-030f586ff9af',
  '921c3964-b760-2eab-bfc4-030f586ff9af',
  'ca0c9593-035f-4d04-89a0-58b897f11e62',
  '1f217d07-afd6-97ba-9be5-88957a241c0d',
  '5e5a6b8b-cf3a-5bf3-2fcd-1224e610eb6e',
  '16cbc1cc-3b29-f9b1-61aa-aa68fc69c4f5',
  '230930af-084f-761d-012b-c7b25f2e1ccd',
  '6577468d-ad5c-6ae5-a486-3e54466b15bd',
  'd088ce64-ac3a-2a12-c338-cc48b1b96d14',
  '7cc7b5bf-e990-aed8-2e5c-330d81a3b0e3',
  '0b3f0db4-2819-c912-8718-7e76f0aa63d1',
];

export const findActiveAddonAssociation = (
  associations: ReadonlyArray<AssociationRecord>,
  addonCode: string,
  groupName?: string
) =>
  associations.find(
    assoc =>
      assoc.cspmb__add_on_price_item__r.guid__c === addonCode &&
      assoc.is_active__c &&
      assoc.cspmb__add_on_price_item__r.cspmb__is_active__c &&
      (groupName ? assoc.cspmb__group__c === groupName : true)
  );

export const newAddon = (
  association: Readonly<AssociationRecord>,
  addOnGroup: string,
  addOnType = 'SERVICE'
): AddOn => {
  const addonPrice = {
    oneTimeCharge: eurosToCents(association.cspmb__add_on_price_item__r.cspmb__one_off_charge__c),
    monthlyRecurringCharge: eurosToCents(association.cspmb__add_on_price_item__r.cspmb__recurring_charge__c),
  };

  return {
    addOnId: association.cspmb__add_on_price_item__c,
    addOnType,
    addOnGroup,
    addOnCode: association.cspmb__add_on_price_item__r.guid__c,
    addOnProductName: association.add_on_price_item_name__c,
    price: { listPrice: addonPrice, effectivePrice: addonPrice }, // AssociationRecord doesn't seem to hase separate effective & list prices, so using same price for both
    campaignAssociations: [],
  };
};

export const getAddonsRecurringPrice = (commercialProduct: CommercialProduct, includeAddons?: boolean) => {
  return ((includeAddons && commercialProduct.associatedAddOns) || [])
    .map(addon => addon.price?.effectivePrice.monthlyRecurringCharge || 0)
    .reduce((acc, cur) => acc + cur, 0);
};
