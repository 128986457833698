import { postMessageToChatConversation } from './fetch.js';
import { setAiChatSessionId } from '../selfservice/common/localStorageUtils.js';
import type { ActionFunctionArgs } from 'react-router-dom';

export const chatConversationAction = async ({ request }: ActionFunctionArgs): Promise<object> => {
  if (request.method === 'POST') {
    const formData = await request.formData();
    const message = formData.get('query') as string;
    const { sessionId } = await postMessageToChatConversation(message);
    setAiChatSessionId(sessionId);
    return {};
  }
  throw new Response('', { status: 405 });
};
