import * as CL from '@design-system/component-library';
import { EppRedeemTerminateRequestType } from '../../generated/api/models.js';
import { dsClass } from '../../common/constants/dsClasses.js';

type RedeemOptionsProps = {
  companyLabel: string;
  employeeLabel: string;
  titleText?: string;
  onChange: (selectedType: EppRedeemTerminateRequestType) => void;
  selectedOption: EppRedeemTerminateRequestType;
  isOmaLaiteLaskuActive?: boolean;
};

export const RedeemOptions = ({
  companyLabel,
  employeeLabel,
  titleText,
  onChange,
  selectedOption,
  isOmaLaiteLaskuActive,
}: RedeemOptionsProps) => {
  return (
    <div>
      {titleText && <h4 className={dsClass.H4}>{titleText}</h4>}
      <div className={dsClass.MARGIN_BOTTOM_5}>
        <CL.RadioGroup value={selectedOption}>
          <CL.RadioV2
            id="redeem-type-company"
            label={companyLabel}
            onChange={() => onChange(EppRedeemTerminateRequestType.COMPANY_REDEEM)}
            value={EppRedeemTerminateRequestType.COMPANY_REDEEM}
          />
          <CL.RadioV2
            id="redeem-type-employee"
            label={employeeLabel}
            onChange={() => onChange(EppRedeemTerminateRequestType.EMPLOYEE_REDEEM_BY_ADMIN)}
            value={EppRedeemTerminateRequestType.EMPLOYEE_REDEEM_BY_ADMIN}
            disabled={isOmaLaiteLaskuActive === undefined ? false : !isOmaLaiteLaskuActive}
          />
        </CL.RadioGroup>
      </div>
    </div>
  );
};
