import { ForgotPassword } from '../../../components/ForgotPassword/ForgotPassword.js';
import { updateForgotPasswordStepNumber } from '../../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useNavigateBack } from '../../../common/hooks/useNavigateBack.js';

export const ForgotPasswordPath = () => {
  const dispatch = useDispatch();
  const navigateBack = useNavigateBack();

  const moveToOmaElisa = () => {
    dispatch(updateForgotPasswordStepNumber(0));
    navigateBack();
  };

  useEffect(() => {
    dispatch(updateForgotPasswordStepNumber(1));
  }, [dispatch]);

  return (
    <div className="of-forgot-password-wrapper">
      <div className="ea-snapper-container">
        <div className="of-forgot-password-wrapper__content">
          <ForgotPassword closeModal={moveToOmaElisa} unmountAction={moveToOmaElisa} />
        </div>
      </div>
    </div>
  );
};
