import * as CL from '@design-system/component-library';
import { Icon } from './Icon.js';
import { ProgressBar } from '../ProgressBar/ProgressBar.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { middleTruncateFileName } from './files.js';
import { t, uploadingMsg } from '../../common/i18n/index.js';
import { useEffect, useState } from 'react';
import type * as CLT from '@design-system/component-library';
import type { UploadStatus } from '../../common/react-hook-form/components/FileInput.js';

import './EditableList.scss';

interface RemovableBadgeProps {
  fileName: string;
  onRemove: (fileName: string) => void;
  invalid: boolean;
}

interface FileWithIconProps {
  text: string;
  icon?: string;
  iconColor?: CLT.DsColor;
  onClick?: () => void;
  className?: string;
  children?: React.ReactNode;
  statusText?: string;
  isUploading: boolean;
}

const FileWithIcon = ({
  text,
  icon,
  onClick,
  className,
  children,
  statusText,
  iconColor = 'blue-800',
  isUploading,
}: FileWithIconProps) => {
  interface ButtonWithChildProps {
    buttonOnClick: () => void;
    children: React.ReactNode;
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const ButtonWithChild = ({ buttonOnClick, children }: ButtonWithChildProps) => (
    <button type="button" aria-label="Remove" onClick={buttonOnClick}>
      {children}
    </button>
  );

  const IconAtRight = () =>
    icon ? (
      <CL.Icon className="of-file-editable-list--file--right-icon" size="s" icon={icon} color={iconColor} />
    ) : null;

  return (
    <li className={`of-file-editable-list--file${className ? ' ' + className : ''}`}>
      {children}
      {!isUploading && <Icon fileName={text} />}
      <span className={`of-file-editable-list--file--file-name ${dsClass.TEXT_S}`}>{middleTruncateFileName(text)}</span>
      {statusText && <span className={`upload-status-text ${dsClass.TEXT_S}`}>- {statusText}</span>}
      {onClick ? (
        <ButtonWithChild buttonOnClick={onClick}>
          <IconAtRight />
        </ButtonWithChild>
      ) : (
        <IconAtRight />
      )}
    </li>
  );
};

const RemovableBadge = ({ fileName, onRemove, invalid }: RemovableBadgeProps) => (
  <FileWithIcon
    text={fileName}
    onClick={() => onRemove(fileName)}
    icon="close"
    className={invalid ? 'invalid' : undefined}
    isUploading={false}
  />
);
export interface EditableListProps {
  files: File[];
  invalidFiles: string[];
  onRemove: (fileName: string) => void;
  uploadStatus: UploadStatus;
}

interface RemovableBadgeWithProgressBarProps {
  text: string;
  progressPercentage: number;
  statusText?: string;
}

const FileWithProgressBar = ({ text, progressPercentage, statusText }: RemovableBadgeWithProgressBarProps) => (
  <FileWithIcon text={text} statusText={statusText} className="no-background uploading" isUploading={true}>
    <ProgressBar progressPercentage={progressPercentage} />
  </FileWithIcon>
);

export const EditableList = ({ files, invalidFiles, onRemove, uploadStatus }: EditableListProps) => {
  const [showFinished, setShowFinished] = useState([] as string[]);

  useEffect(() => {
    files.forEach(file => {
      if (uploadStatus.finished.includes(file.name)) {
        setTimeout(() => {
          setShowFinished(prevState => [...prevState, file.name]);
        }, 2000);
      }
    });
  }, [files, uploadStatus.finished]);

  return (
    <ul className="of-file-editable-list">
      {files.map(file => {
        if (uploadStatus.finished.includes(file.name)) {
          return showFinished.includes(file.name) ? (
            <FileWithIcon
              key={`${file.name}-finished`}
              text={file.name}
              icon="check"
              iconColor="blue-600"
              className="no-background bottom-border"
              isUploading={false}
            />
          ) : (
            <FileWithProgressBar key={`${file.name}-progress`} text={file.name} progressPercentage={100} />
          );
        } else {
          if (uploadStatus.started.includes(file.name)) {
            return (
              <FileWithProgressBar
                key={`${file.name}-uploading`}
                text={file.name}
                progressPercentage={0}
                statusText={t.KW12(uploadingMsg)}
              />
            );
          } else {
            return (
              <RemovableBadge
                key={`${file.name}-badge`}
                fileName={file.name}
                onRemove={onRemove}
                invalid={invalidFiles.includes(file.name)}
              />
            );
          }
        }
      })}
    </ul>
  );
};
