import { ContactRole, CustomerOrderStatus, SubscriptionAction } from '../../generated/api/models.js';
import { SubscriptionCategory } from '../enums.js';
import { paths } from '../constants/pathVariables.js';
import type { ActionsHistory } from '../../selfservice/common/store.js';
import type { AuthenticatedUserState, CustomerOrdersState, SubscriptionActionsState } from '../types/states.js';
import type {
  ContactPerson,
  CustomerOrderAdditionalInfo,
  Subscription,
  SubscriptionActionHeader,
} from '../../generated/api/models.js';

export enum EmployeeSceneTab {
  EMPLOYEE_HOME = 'EMPLOYEE_HOME',
  SUBSCRIPTIONS_DEVICE = 'SUBSCRIPTIONS_DEVICE',
  SUBSCRIPTIONS_VOICE_BROADBAND = 'SUBSCRIPTIONS_VOICE_BROADBAND',
  DEVICE_STORE_PHONE = 'DEVICE_STORE_PHONE',
  ORDERS_REVIEW_PENDING = 'ORDERS_REVIEW_PENDING',
  ORDERS_REVIEW_COMPLETED = 'ORDERS_REVIEW_COMPLETED',
  ORDERS_SUBSCRIPTION_ACTIONS = 'ORDERS_SUBSCRIPTION_ACTIONS',
  ORDERS_SELF_INITIATED = 'ORDERS_SELF_INITIATED',
  SUBSCRIPTIONS_SELF_INITIATED = 'SUBSCRIPTIONS_SELF_INITIATED',
}

const defaultSubscriptionActionsSort = {
  columnId: 'created',
  order: 'desc',
};

export const getEmployeeSceneTab = (pathName: string): EmployeeSceneTab => {
  if (pathName.startsWith(paths.EMPLOYEE_DEVICES)) {
    return EmployeeSceneTab.SUBSCRIPTIONS_DEVICE;
  } else if (pathName.startsWith(paths.EMPLOYEE_SUBSCRIPTIONS)) {
    return EmployeeSceneTab.SUBSCRIPTIONS_VOICE_BROADBAND;
  } else if (pathName.startsWith(paths.EMPLOYEE_VOICE_SUBSCRIPTIONS)) {
    return EmployeeSceneTab.SUBSCRIPTIONS_VOICE_BROADBAND;
  } else if (pathName.startsWith(paths.EMPLOYEE_BROADBAND_SUBSCRIPTIONS)) {
    return EmployeeSceneTab.SUBSCRIPTIONS_VOICE_BROADBAND;
  }
  throw Error(`Erroneous pathName value: ${pathName}`);
};

const isSubscriptionActionTerminateAndPending = (subscriptionAction: SubscriptionAction | SubscriptionActionHeader) => {
  return (
    subscriptionAction.subscriptionActionType === SubscriptionAction.SubscriptionActionTypeEnum.EPP_REDEEM_TERMINATE &&
    subscriptionAction.status === SubscriptionAction.StatusEnum.PENDING_APPROVAL
  );
};

const isSomeSubscriptionActionPendingForApproval = (subscriptionActions: SubscriptionActionsState) => {
  return subscriptionActions.searchResults!.some(item => isSubscriptionActionTerminateAndPending(item.result));
};

export const showSubscriptionActionHint = (subscriptionActions?: SubscriptionActionsState | null): boolean => {
  return subscriptionActions && subscriptionActions.searchResults
    ? isSomeSubscriptionActionPendingForApproval(subscriptionActions)
    : false;
};

export const showCustomerOrdersHint = (customerOrders: CustomerOrdersState | null | undefined): boolean => {
  return customerOrders && customerOrders.items
    ? customerOrders.items?.some(customerOrder => customerOrder.status === CustomerOrderStatus.PENDING_APPROVAL)
    : false;
};

export const getOrdererSubscription = (
  additionalInfo?: Record<string, CustomerOrderAdditionalInfo>,
  orderId?: string
): Subscription[] => {
  if (additionalInfo && orderId) {
    return additionalInfo[orderId]?.ordererSubscriptions;
  }
  return [];
};

export const getOrderContactPerson = (
  additionalInfo?: Record<string, CustomerOrderAdditionalInfo>,
  orderId?: string
): ContactPerson | undefined => (orderId ? additionalInfo?.[orderId]?.ordererContact.person : undefined);

export const getSubCategoryFromPath = (path: string) => {
  const pathname = decodeURI(path);
  const isEmployeeSubscriptionsOrVoice =
    pathname.startsWith(paths.EMPLOYEE_SUBSCRIPTIONS) || pathname.startsWith(paths.EMPLOYEE_VOICE_SUBSCRIPTIONS);
  const isBroadband = pathname.startsWith(paths.EMPLOYEE_BROADBAND_SUBSCRIPTIONS);

  if (isEmployeeSubscriptionsOrVoice) {
    return SubscriptionCategory.VOICE;
  } else if (isBroadband) {
    return SubscriptionCategory.BROADBAND;
  } else {
    return SubscriptionCategory.DEVICE;
  }
};

export const getFilteredSubscriptionAction = (
  subscriptionActions: SubscriptionActionsState | null | undefined
): SubscriptionActionsState | undefined => {
  if (subscriptionActions) {
    const subscriptionActionItems = subscriptionActions.items?.filter(
      subscriptionAction =>
        subscriptionAction.subscriptionActionType === SubscriptionAction.SubscriptionActionTypeEnum.EPP_REDEEM_TERMINATE
    );
    const subscriptionActionSearchResults = subscriptionActions.searchResults?.filter(
      subscriptionAction =>
        subscriptionAction.result.subscriptionActionType ===
        SubscriptionAction.SubscriptionActionTypeEnum.EPP_REDEEM_TERMINATE
    );
    return {
      ...subscriptionActions,
      items: subscriptionActionItems,
      searchResults: subscriptionActionSearchResults,
      sort: subscriptionActions.sort || defaultSubscriptionActionsSort,
    };
  }
  return undefined;
};

export const userHasEmptyOrSMEPriceGroup = (segmentPricingGroup?: string) =>
  !segmentPricingGroup || segmentPricingGroup.toLowerCase() === 'sme pricing group';

export const userHasApproverRole = (
  authenticated: (AuthenticatedUserState & ActionsHistory) | null | undefined
): boolean => Boolean(authenticated?.contact?.person?.roles?.includes(ContactRole.APPROVER));
