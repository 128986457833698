import { GutterStyle, SelectOneList, type SelectOneListItemProps } from '../../SelectOneList/index.js';
import { Loading } from '../../Loading/index.js';
import { NumberFetchingError } from '../NumberFetchingError.js';
import { ShowMoreButton } from './ShowMoreButton.js';
import { companyNumberMsg, t } from '../../../common/i18n/index.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { formatPhoneNumber } from '../../../common/utils/phoneNumberUtils.js';
import type { RangeNumbersState } from '../../../common/types/states.js';

export const CorporateNumberSelect = ({
  rangeNumbers,
  blockedNumbers,
  selectedValue,
  onSelect,
  retryAction,
  onDisplayMoreNumbers,
}: {
  rangeNumbers?: RangeNumbersState;
  blockedNumbers: string[];
  selectedValue?: string;
  onSelect: (field: string, value: string) => void;
  retryAction: () => void;
  onDisplayMoreNumbers: () => void;
}) => {
  if (!rangeNumbers) {
    return null;
  }

  if (rangeNumbers.errors) {
    return (
      <div id="subscription-company-number" className="of-select-number-list__error">
        <NumberFetchingError loading={rangeNumbers.loading} errors={rangeNumbers.errors} retryAction={retryAction} />
      </div>
    );
  }

  const displayedNumbers = rangeNumbers.displayedNumbers || [];

  const isLoading =
    !rangeNumbers.displayedNumbers || !rangeNumbers.allNumbers || rangeNumbers.displayedNumbers.length < 1;

  const displayShowMoreButton =
    rangeNumbers?.allNumbers && rangeNumbers.allNumbers.length > displayedNumbers.length && !isLoading;

  const getOption = (phoneNumber: string): SelectOneListItemProps => ({
    element: (
      <div className="of-attach-pbx-content__number-range-row of-attach-pbx-content__selection of-select-number-list">{`${formatPhoneNumber(
        phoneNumber,
        true
      )}`}</div>
    ),
    isSelected: selectedValue === phoneNumber,
    isDisabled: blockedNumbers.includes(phoneNumber),
    onClick: () => onSelect('corporateNumber', phoneNumber),
  });

  return (
    <div id="subscription-company-number" className={dsClass.MARGIN_TOP_4}>
      <div className={dsClass.FONT_SIZE_LARGE}>{t.ZX32(companyNumberMsg)}:</div>
      <div className="ea-disclaimertext">
        {t.QTLF(
          'Service price €4/month for business numbers (with prefixes such as 010, 020, etc.) and €6.90/month for other numbers.'
        )}
      </div>
      <SelectOneList
        disableIcon={true}
        gutterStyle={GutterStyle.Minimal}
        maxItemsAsideDesktop={4}
        maxItemsAsideLaptop={4}
        maxItemsAsideTablet={3}
        options={displayedNumbers.map(phoneNumber => getOption(phoneNumber))}
      />
      {isLoading && <Loading topPadding={2} text />}
      {displayShowMoreButton && <ShowMoreButton onClick={() => onDisplayMoreNumbers()} />}
    </div>
  );
};
