import { ContactType } from '../../../generated/api/models.js';
import type { Contact } from '../../../generated/api/models.js';

interface ContactInfoProps {
  selectedContact?: Contact;
  billReceiverName?: string;
}

export const ContactInfo = ({ selectedContact, billReceiverName }: ContactInfoProps) =>
  selectedContact?.contactType === ContactType.PERSON ? (
    <div>
      <div>{`${selectedContact.person?.firstName || ''} ${selectedContact.person?.lastName || ''}`}</div>
      <div>{selectedContact.person?.phoneNumber || ''}</div>
      <div>{selectedContact.person?.email || ''}</div>
    </div>
  ) : (
    <div>
      <span>{billReceiverName || ''}</span>
    </div>
  );
