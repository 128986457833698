import { FormProvider, useForm } from 'react-hook-form';
import { PhoneNumberOwnerType, PhoneNumberType, SelectedPurposeOfUseOrContact } from '../../common/enums.js';
import { formatTimestampToDDMMYYYY } from '../../common/utils/dateUtils.js';
import React from 'react';
import type { AddedAddon } from '../../common/types/addon.js';
import type { ConfiguredCommercialProduct } from '../../common/types/commercialProduct.js';
import type { PurposeOfUseOrContact } from '../../common/types/subscription.js';
import type { SimType } from '../../generated/api/simType.js';

export type PurposeOfUseFormValues = {
  contactId?: string;
  purposeOfUse?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  costCenter?: string;
  employeeNumber?: string;
  selected?: SelectedPurposeOfUseOrContact;
};

export interface FormWrapperProps {
  children: JSX.Element;
  selectedCommercialProducts: ConfiguredCommercialProduct[];
  onSuccess: (data: OrderConfiguration, e?: React.BaseSyntheticEvent) => void;
}
export interface AddOnSelectionType {
  addedAddOns?: AddedAddon[];
  removedAddOns?: string[];
}

export interface OrderConfigurationItem {
  numberPublicity: 'true' | 'false';
  additionalFields?: { [key: string]: string };
  selectPhoneNumber?: {
    type: PhoneNumberType;
    existingPhoneNumber?: string;
    newPhoneNumber?: string;
    transferDate?: string;
    numberOwner?: {
      type: PhoneNumberOwnerType;
      permitDonoringOnFixedContract?: boolean;
      personEmail?: string;
    };
  };
  simCardConfiguration?: {
    simCardNumber?: string;
    simType: SimType | 'ACTIVATE_EXISTING';
  };
  purposeOfUseOrContact?: PurposeOfUseFormValues;
  addOns?: AddOnSelectionType;
}

export interface OrderConfiguration {
  configuration: Record<string, OrderConfigurationItem>;
}

const purposeOfUseOrContactToFormValues = (purposeOfUseOrContact: PurposeOfUseOrContact): PurposeOfUseFormValues => {
  return {
    selected: purposeOfUseOrContact.selected || SelectedPurposeOfUseOrContact.CONTACT,
    contactId: purposeOfUseOrContact.contactId,
    purposeOfUse: purposeOfUseOrContact.contactId ? undefined : purposeOfUseOrContact.purposeOfUse,
    firstName: purposeOfUseOrContact.firstName,
    lastName: purposeOfUseOrContact.lastName,
    phoneNumber: purposeOfUseOrContact.phoneNumber,
    email: purposeOfUseOrContact.email,
    costCenter: purposeOfUseOrContact.costCenter,
    employeeNumber: purposeOfUseOrContact.employeeNumber,
  };
};

export const FormWrapper = ({ children, selectedCommercialProducts, onSuccess }: FormWrapperProps) => {
  const methods = useForm({
    mode: 'all',
    shouldFocusError: true,
    // default values do not work when changing amount as initialisation happens only once
    // that's why components need to have good default values as well
    // this basically restores state when back button is pressed
    defaultValues: {
      configuration: selectedCommercialProducts
        .map((cp, index): { index: number; value: OrderConfigurationItem } => {
          if (cp.numberPublicity === undefined) {
            cp.numberPublicity = true;
          }
          // RFH wants strings
          return {
            index: index,
            value: {
              purposeOfUseOrContact: purposeOfUseOrContactToFormValues(cp.purposeOfUseOrContact),
              numberPublicity: cp.numberPublicity ? 'true' : 'false',
              selectPhoneNumber: cp.selectedPhoneNumber && {
                type: cp.selectedPhoneNumber?.type || PhoneNumberType.EXISTING,
                existingPhoneNumber: cp.selectedPhoneNumber?.existingPhoneNumber,
                newPhoneNumber: cp.selectedPhoneNumber?.newPhoneNumber,
                transferDate: cp.selectedPhoneNumber?.transferDate
                  ? formatTimestampToDDMMYYYY(cp.selectedPhoneNumber?.transferDate.getTime())
                  : undefined,
                numberOwner: {
                  type: cp.phoneNumberOwner?.ownerType || PhoneNumberOwnerType.PERSON,
                  permitDonoringOnFixedContract: cp.phoneNumberOwner?.permitDonoringOnFixedContract || false,
                  personEmail: cp.phoneNumberOwner?.personEmail || '',
                },
              },
            },
          };
        })
        .reduce((a, v) => ({ ...a, [v.index]: v.value }), {}),
    },
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSuccess)} noValidate>
        {children}
      </form>
    </FormProvider>
  );
};
