import * as CL from '@design-system/component-library';
import { BillChannelType } from '../../../../common/utils/billingAccountUtils.js';
import { BillingAccountVersion } from '../../billingAccountDetailsEnums.js';
import { DeliveryMethodAndLanguageView } from '../../components/DeliveryMethodAndLanguageView.js';
import { DeliveryMethodDetailsView } from './DeliveryMethodDetailsView.js';
import type {
  BillChannel,
  BillingAccount,
  BillingAccountScheduledChange,
  Contact,
} from '../../../../generated/api/models.js';

export interface BillingAccountDeliveryMethodViewProps {
  activeBillingAccount: BillingAccount;
  billingAccountVersion: BillingAccountVersion;
  billingAccountScheduledChange?: BillingAccountScheduledChange;
  billChannels: BillChannel[];
  contacts: Contact[];
  isHighlighted: (value1?: string, value2?: string) => boolean;
}

const getElectronicInvoiceOperatorName = (billChannels?: BillChannel[], billElectronicOperator?: string) => {
  return billChannels
    ?.find(billChannel => billChannel.type === BillChannelType.NETWORK)
    ?.operators.find(operator => operator.id === billElectronicOperator)?.name;
};

export const BillingAccountDeliveryMethodView = ({
  activeBillingAccount,
  billingAccountScheduledChange,
  billingAccountVersion,
  billChannels,
  contacts,
  isHighlighted,
}: BillingAccountDeliveryMethodViewProps) => {
  const billingAccount =
    billingAccountVersion === BillingAccountVersion.SCHEDULED && billingAccountScheduledChange
      ? billingAccountScheduledChange.billingAccount
      : activeBillingAccount;
  const selectedContact = contacts?.find(({ contactId }) => contactId === billingAccount.billReceiverId);
  const billElectronicOperator = getElectronicInvoiceOperatorName(billChannels, billingAccount.billElectronicOperator);

  return (
    <CL.Grid>
      <DeliveryMethodAndLanguageView
        activeBillingAccount={activeBillingAccount}
        billingAccountVersion={billingAccountVersion}
        billingAccountScheduledChange={billingAccountScheduledChange}
        billChannels={billChannels}
        isHighlighted={isHighlighted}
      />
      <DeliveryMethodDetailsView
        billElectronicOperator={billElectronicOperator}
        activeBillingAccount={activeBillingAccount}
        selectedContact={selectedContact}
        billingAccountVersion={billingAccountVersion}
        billingAccountScheduledChange={billingAccountScheduledChange}
        isHighlighted={isHighlighted}
      />
    </CL.Grid>
  );
};
