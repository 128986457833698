import * as CL from '@design-system/component-library';
import { CompanySelector } from '../CompanySelector/CompanySelector.js';
import { DialogWrapper } from '../DialogWrapper/index.js';
import { continueMsg, selectCompanyMsg, t } from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { paths } from '../../common/constants/pathVariables.js';
import { setActiveAccount } from '../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import type { ChangeEvent, KeyboardEvent } from 'react';
import type { HeaderUserAccount } from '@design-system/component-library';

export interface ShoppingBasketCompanySelectorProps {
  userAccounts: CL.HeaderUserAccount[];
  showInDialog: boolean;
}

export const ShoppingBasketCompanySelector = ({ userAccounts, showInDialog }: ShoppingBasketCompanySelectorProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onCompanySelectorInputChange = (
    _e: ChangeEvent | KeyboardEvent | React.MouseEvent,
    option: Partial<HeaderUserAccount>
  ) => {
    if (option?.accountMasterId) {
      dispatch(setActiveAccount(option.accountMasterId, true, paths.NEW_SHOPPING_BASKET, option.name));
    }
  };

  const onContinue = () => {
    // Reset state after company has been selected
    navigate(paths.NEW_SHOPPING_BASKET, { replace: true });
  };

  const companySelectorInner = (
    <>
      <CompanySelector
        labelText={t.JUY2(selectCompanyMsg)}
        className="of-shopping-basket-select-company"
        userAccounts={userAccounts}
        onInputChange={onCompanySelectorInputChange}
      />
    </>
  );

  return showInDialog ? (
    // TODO: refactor to use SelectCompanyDialog
    <div className="of-shopping-basket-select-company-wrapper">
      <DialogWrapper header={t.BR9A('Please select company first')} onCloseDialog={() => {}}>
        {companySelectorInner}
        <div className={`${dsClass.DISPLAY_FLEX} ${dsClass.JUSTIFY_CONTENT_FLEX_END}`}>
          <CL.Button onClick={onContinue}>{t.I62A(continueMsg)}</CL.Button>
        </div>
      </DialogWrapper>
    </div>
  ) : (
    <div className={`${dsClass.PADDING_BOTTOM_3}`}>
      <div className="of-shopping-basket-select-company-wrapper">{companySelectorInner}</div>
    </div>
  );
};
