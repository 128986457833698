import { TypeKeys, generateActionId } from './actionsUtils.js';
import { convertToCommonErrors } from '../../common/utils/errorUtils.js';
import type { CompositeListSort } from '../../components/CompositeListHeader/CompositeListHeader.js';
import type { DisplayItemsLoadAction, ErrorAction, ErrorActionCreator } from './actionsUtils.js';
import type {
  EmployeeOnboardingRequest,
  GenericError,
  OnboardingRequestsResponse,
} from '../../generated/api/models.js';

export interface LoadOnboardingRequestsAction
  extends DisplayItemsLoadAction<TypeKeys.LOAD_ONBOARDING_REQUESTS, EmployeeOnboardingRequest> {
  forceLoad?: boolean;
}

export const loadOnboardingRequests = (sort?: CompositeListSort, forceLoad = false): LoadOnboardingRequestsAction => ({
  sort,
  id: generateActionId(),
  type: TypeKeys.LOAD_ONBOARDING_REQUESTS,
  forceLoad,
});

export interface LoadOnboardingRequestsFulfilledAction {
  onboardingRequests?: EmployeeOnboardingRequest[];
  totalOnboardingRequests: number;
  type: TypeKeys.LOAD_ONBOARDING_REQUESTS_FULFILLED;
  sort?: CompositeListSort;
}

export const loadOnboardingRequestsFulfilled = (
  response: OnboardingRequestsResponse,
  sort?: CompositeListSort
): LoadOnboardingRequestsFulfilledAction => ({
  onboardingRequests: response.onboardingRequests,
  totalOnboardingRequests: response.total,
  type: TypeKeys.LOAD_ONBOARDING_REQUESTS_FULFILLED,
  sort,
});

export type LoadOnboardingRequestsFailedAction = ErrorAction<TypeKeys.LOAD_ONBOARDING_REQUESTS_FAILED>;

export const loadOnboardingRequestsFailed: ErrorActionCreator<TypeKeys.LOAD_ONBOARDING_REQUESTS_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): LoadOnboardingRequestsFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    params,
    type: TypeKeys.LOAD_ONBOARDING_REQUESTS_FAILED,
  };
};

export type OnboardingRequestActionTypes =
  | LoadOnboardingRequestsAction
  | LoadOnboardingRequestsFulfilledAction
  | LoadOnboardingRequestsFailedAction;
