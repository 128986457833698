import { ConfirmationModal } from '../../../../Modal/ConfirmationModal.js';
import { dsClass } from '../../../../../common/constants/dsClasses.js';
import { saveMsg, t } from '../../../../../common/i18n/index.js';
import type { DnsRecordRequest } from '../../../../../generated/api/dnsRecordRequest.js';

interface DnsRecordSaveConfirmationProps {
  onCancel: () => void;
  onConfirm: () => void;
  row: DnsRecordRequest;
}

export const DnsRecordSaveConfirmation = ({ onCancel, onConfirm, row }: DnsRecordSaveConfirmationProps) => (
  <ConfirmationModal
    heading={t.NWKB('Do you want to save the following changes?')}
    onCancel={onCancel}
    onConfirm={onConfirm}
    confirmText={t.NIWE(saveMsg)}
  >
    <div>
      <div>
        <strong>{t.E9BD('Details of the edit')}:</strong>
      </div>
      <pre className={dsClass.FONT_SIZE_SMALLER} style={{ maxHeight: '50vh' }}>
        {JSON.stringify(row, null, 2)}
      </pre>
    </div>
  </ConfirmationModal>
);
