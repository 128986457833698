import * as CL from '@design-system/component-library';
import { Link, useLocation } from 'react-router-dom';
import { Login } from '../Login/index.js';
import { REDIRECT_PATH_KEY } from '../../common/constants/commonConstants.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { t } from '../../common/i18n/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useSelector } from 'react-redux';
import type { State } from '../../selfservice/common/store.js';

import './LoginAndRegistration.scss';

export const LoginAndRegistration = () => {
  const { pathname } = useLocation();
  const dialog = useSelector((state: State) => state.dialog, deepEqual);

  const { anonymousUser } = useAuth();
  return (
    <div className="of-login-registration-container">
      <div className="of-login-registration-container__login">
        <Login dialog={dialog} />
      </div>
      {!anonymousUser?.mfaInfo && (
        <div className="of-login-registration-container__registration">
          <h3>{t.ELJA('I am a new customer.')}</h3>
          <Link to={`${paths.REGISTER_PAGE}?${REDIRECT_PATH_KEY}=${encodeURIComponent(pathname)}`}>
            <CL.Button size="l">{t.LJTY('Register')}</CL.Button>
          </Link>
        </div>
      )}
    </div>
  );
};
