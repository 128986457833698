import { isInBrowser } from './ssrUtils.js';
import { paths } from '../constants/pathVariables.js';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const getScrollParent = (node: HTMLElement | null): HTMLElement | null => {
  if (node == null) {
    return null;
  }
  if (node.scrollHeight > node.offsetHeight || node === window.document.scrollingElement) {
    return node;
  } else {
    return getScrollParent(node.parentElement);
  }
};

const isOutsideTheScreen = (elementRect: DOMRect): boolean => {
  return elementRect.top >= 0 && elementRect.bottom < window.innerHeight;
};

const getScrollToY = (elementOrY: number | HTMLElement): number => {
  if (typeof elementOrY === 'object') {
    const elementRect = elementOrY.getBoundingClientRect();
    if (isOutsideTheScreen(elementRect)) {
      return -1;
    }

    // In case there's a dialog
    const scrollParent = getScrollParent(elementOrY);
    const yOffset = scrollParent ? scrollParent.scrollTop : window.pageYOffset;
    return elementRect.top + yOffset - window.innerHeight * 0.12;
  }

  return elementOrY;
};

const getScrollerElement = (elementOrY: number | HTMLElement): Window | Element | null => {
  if (typeof elementOrY === 'object') {
    const elementRect = elementOrY.getBoundingClientRect();
    if (isOutsideTheScreen(elementRect)) {
      return null;
    }

    const scrollParent = getScrollParent(elementOrY);
    if (scrollParent) {
      return scrollParent;
    }
  }
  return window;
};

// TODO Remove this at some point when the browser detection is implemented
const isLegacyBrowser = () =>
  window.navigator.userAgent.indexOf('MSIE ') !== -1 || window.navigator.userAgent.indexOf('Trident/') !== -1;

export const scrollTo = (elementOrY: number | HTMLElement) => {
  if (isInBrowser()) {
    const scrollToY = getScrollToY(elementOrY);
    const scrollerElement = getScrollerElement(elementOrY);
    if (scrollerElement === null) {
      return;
    }

    if (isLegacyBrowser()) {
      if (scrollerElement.scrollTo) {
        scrollerElement.scrollTo(0, scrollToY);
      }
    } else {
      scrollerElement.scrollTo({ behavior: 'smooth', top: scrollToY });
    }
  }
};

/**
 * react-router-dom https://reactrouter.com/web/guides/scroll-restoration
 * Suggested solution when pathname is being changed to scroll to top
 * Currently used only in Public site where Router Links are implemented
 * Can likely be moved up in the hierarchy in future when Router usage increases
 */
export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (isInBrowser()) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
};

export const ScrollToTopWhenSearchParamsChange = () => {
  const { search } = useLocation();

  useEffect(() => {
    if (isInBrowser()) {
      window.scrollTo(0, 0);
    }
  }, [search]);

  return null;
};

export const isEmployeePortal = (path?: string) => (path ? decodeURI(path).startsWith(paths.EMPLOYEE_HOME) : false);

const employeePublicPaths = [paths.EMPLOYEE_EMAIL_VERIFICATION];
export const isEmployeePublicPath = (path?: string) =>
  employeePublicPaths.some(publicPath => (path ? decodeURI(path).startsWith(publicPath) : false));
