import { CommercialProductSubType } from '../../generated/api/commercialProductSubType.js';
import { CommercialProductType } from '../../generated/api/commercialProductType.js';
import { EppSolutionResponse } from '../../generated/api/eppSolutionResponse';
import {
  deductibleMsg,
  monthlyChargesMsg,
  monthsMsg,
  noFixedTermMsg,
  oneTimePaymentMsg,
  recurringChargesMsg,
  singleFaresMsg,
  squareTradeDeductibleMsg,
  sumPerMonthMsg,
  t,
  totalWithCurlyBracketsMsg,
  vatPercentageMsg,
} from '../../common/i18n/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { formatSum, formatSumToString } from '../../common/utils/priceUtils.js';
import {
  getShoppingBasketFromLocalStorage,
  setShoppingBasketToLocalStorage,
} from '../../selfservice/common/localStorageUtils.js';
import { isSquareTradeAddOn } from '../../common/utils/addOnUtils.js';
import type { AddOn } from '../../generated/api/addOn.js';
import type { BasketItem, OfferItem, ShoppingBasketType } from '../../common/types/shoppingBasket.js';
import type { CartItemContainer } from 'components/ProductDetails/ProductDetails.js';
import type { ChangePaymentOptions, PaymentOptionData, PriceData, PriceDisplayData } from './types';
import type { CommercialProduct } from '../../generated/api/commercialProduct.js';
import type { DiscountedPrices } from '../../generated/api/discountedPrices.js';
import type { Offer } from '../../generated/api/offer.js';
import type { OnlineModel } from '../../generated/api/onlineModel.js';
import type { Price } from '../../generated/api/price.js';
import type {
  ShoppingCartItemAddon,
  ShoppingCartPaymentOption,
  ShoppingCartPriceType,
  ShoppingCartTotalPrice,
} from '@design-system/component-library';
import EppSolutionStatusEnum = EppSolutionResponse.EppSolutionStatusEnum;
import { EcommerceEventTypeV4 } from '../../common/analytics';
import { createEcommerceItemFromCommercialProducts } from '../../selfservice/common/googleEcommerceEvent';
import { getItemVariant } from '../../common/utils/analyticsUtils';
import { getManufacturer } from '../../common/utils/cartProductUtils';
import { googleEcommerceAddToCart, googleEcommerceRemoveFromCart } from '../../selfservice/actions';

export const getCommercialProductForGuid = (guid: string, model?: OnlineModel): CommercialProduct | undefined => {
  return model?.offers
    .flatMap(offer => offer.commercialProducts)
    .find(commercialProduct => commercialProduct?.commercialProductCode === guid);
};

export const getPriceForSalesProduct = (offer?: Offer): PriceData => {
  return {
    price: {
      monthlyRecurringCharge: offer?.commercialProducts.find(cp => cp.monthlyRecurringCharge)?.monthlyRecurringCharge,
      oneTimeCharge: offer?.commercialProducts.find(cp => cp.oneTimeCharge)?.oneTimeCharge,
      payments: offer?.commercialProducts.find(cp => cp.payments)?.payments,
    },
    isDiscountedPrice: false,
    billingPeriod: offer?.commercialProducts.find(cp => cp.billingPeriod)?.billingPeriod,
  };
};

const emptyPrice = {
  amount: '',
  unit: '',
};

export const getPriceToDisplay = (priceData: PriceData | undefined, quantity: number): ShoppingCartPriceType => {
  if (priceData?.price?.monthlyRecurringCharge && priceData?.billingPeriod && priceData?.billingPeriod !== 1) {
    return {
      amount: formatSumToString(priceData.price.monthlyRecurringCharge * quantity, true),
      unit: `€/${priceData.billingPeriod}
             ${t.XXVX(monthsMsg)}`,
    };
  }
  if (priceData?.price?.monthlyRecurringCharge) {
    return {
      amount: formatSumToString(priceData.price.monthlyRecurringCharge * quantity, true),
      unit: t.YO7F(sumPerMonthMsg, '€'),
    };
  } else if (priceData?.price?.oneTimeCharge) {
    return { amount: formatSumToString(priceData.price.oneTimeCharge * quantity, true), unit: '€' };
  }
  return emptyPrice;
};

export const getPriceFromBasketOfferItem = (offer: OfferItem) => {
  return {
    oneTimeCharge: offer.oneTimePriceInCents,
    monthlyRecurringCharge: offer.periodicPrice?.periodicPriceInCents,
    payments: offer.periodicPrice?.payments,
  };
};

export const findDiscountedPrice = (discountedPrices: DiscountedPrices[], commercialProductCode?: string) => {
  return discountedPrices
    .flatMap(discountedPricesEntry => discountedPricesEntry.prices)
    .find(discountedPrice => discountedPrice.commercialProductCode === commercialProductCode);
};

export const getPriceForGuid = (
  guid: string,
  model: OnlineModel | undefined,
  discountedPrices: DiscountedPrices[]
): PriceData | undefined => {
  const commercialProduct = getCommercialProductForGuid(guid, model);
  if (commercialProduct) {
    const discountedPrice = findDiscountedPrice(discountedPrices, commercialProduct.commercialProductCode);
    return {
      isDiscountedPrice: !!discountedPrice,
      price: discountedPrice ?? commercialProduct.price?.effectivePrice,
    };
  }
  return undefined;
};

export const getPriceForGuidToDisplay = (
  guid: string,
  model: OnlineModel | undefined,
  discountedPricesForModel: DiscountedPrices | undefined,
  quantity: number
): PriceDisplayData => {
  const priceData = getPriceForGuid(guid, model, discountedPricesForModel ? [discountedPricesForModel] : []);
  return {
    isDiscountedPrice: priceData?.isDiscountedPrice || false,
    shoppingCartPrice: priceData ? getPriceToDisplay(priceData, quantity) : emptyPrice,
  };
};

export const getOnlineModelForBasketItem = (onlineModels: OnlineModel[], basketItem: BasketItem) => {
  return onlineModels?.find(model => model.onlineModelCode === basketItem.guid);
};

export const calculateTotalPrices = (
  models: OnlineModel[],
  discountedPrices: DiscountedPrices[],
  items?: BasketItem[]
): ShoppingCartTotalPrice[] => {
  const calculateAddonPricesOneTime = (addOns: string[], onlineModel: OnlineModel): number => {
    return addOns.reduce(
      (total, addOn) =>
        total + (onlineModel.addOns?.find(modelAddOn => modelAddOn.addOnCode === addOn)?.addOnOneTimeCharge ?? 0),
      0
    );
  };

  const calculateAddonPricesMonthly = (addOns: string[], onlineModel: OnlineModel): number =>
    addOns.reduce(
      (total, addOn) =>
        total +
        (onlineModel.addOns?.find(modelAddOn => modelAddOn.addOnCode === addOn)?.addOnMonthlyRecurringCharge ?? 0),
      0
    );

  let billingPeriodMultipleMonths = false;

  const { monthlyRecurringCharges, onetimeCharges } = items?.reduce(
    (acc, basketItem) => {
      const onlineModelForItem = getOnlineModelForBasketItem(models, basketItem);
      if (onlineModelForItem?.category === CommercialProductType.SALES_PRODUCT) {
        const priceData = getPriceForSalesProduct(
          onlineModelForItem.offers.find(offer => offer.offerCode === basketItem.offer.guid)
        );
        if (priceData.billingPeriod && priceData.billingPeriod !== 1) {
          billingPeriodMultipleMonths = true;
        }
        acc.monthlyRecurringCharges += (priceData.price?.monthlyRecurringCharge || 0) * basketItem.quantity;
        acc.onetimeCharges += (priceData.price?.oneTimeCharge || 0) * basketItem.quantity;
      } else {
        const commercialProduct = getCommercialProductForGuid(
          basketItem.offer.commercialProductGuid,
          onlineModelForItem
        );
        const isEpp = commercialProduct?.productSubType === CommercialProductSubType.EPP_DEVICE;
        const discountedPrice = findDiscountedPrice(discountedPrices, commercialProduct?.commercialProductCode);
        const basketPrice = getPriceFromBasketOfferItem(basketItem.offer);
        const price = discountedPrice ?? (isEpp ? basketPrice : commercialProduct?.price?.effectivePrice);
        acc.monthlyRecurringCharges += (price?.monthlyRecurringCharge || 0) * basketItem.quantity;
        acc.onetimeCharges += (price?.oneTimeCharge || 0) * basketItem.quantity;
      }

      acc.onetimeCharges +=
        basketItem.offer.addOns && onlineModelForItem
          ? calculateAddonPricesOneTime(basketItem.offer.addOns, onlineModelForItem) * basketItem.quantity
          : 0;
      acc.monthlyRecurringCharges +=
        basketItem.offer.addOns && onlineModelForItem
          ? calculateAddonPricesMonthly(basketItem.offer.addOns, onlineModelForItem) * basketItem.quantity
          : 0;

      return acc;
    },
    { monthlyRecurringCharges: 0, onetimeCharges: 0 }
  ) || { monthlyRecurringCharges: 0, onetimeCharges: 0 };

  return [
    ...(monthlyRecurringCharges
      ? [
          {
            name: billingPeriodMultipleMonths ? t.J4WW(recurringChargesMsg) : t.P6BC(monthlyChargesMsg),
            amount: formatSumToString(monthlyRecurringCharges, true),
            unit: '€',
          },
        ]
      : []),
    ...(onetimeCharges
      ? [
          {
            amount: formatSumToString(onetimeCharges, true),
            name: t.GOBY(singleFaresMsg),
            unit: '€',
          },
        ]
      : []),
  ];
};

export const getOffer = (offerCode: string, onlineModel: OnlineModel | undefined): Offer | undefined => {
  return onlineModel?.offers.find(offer => offer.offerCode === offerCode);
};

const getPriceOptionsForOffer = (
  offerCode: string,
  selectedCommercialProduct: string,
  onlineModel: OnlineModel | undefined,
  includeEpp: boolean,
  allowNonEppDevicesWithRecurringCharges: boolean,
  allowNonEppDevicesWithOneTimeCharges: boolean,
  discountedPrices: DiscountedPrices[]
): PaymentOptionData[] => {
  const offer = getOffer(offerCode, onlineModel);
  return (
    offer?.commercialProducts
      .filter(cp => cp.active)
      .map(cp => ({
        id: cp.commercialProductCode,
        price: findDiscountedPrice(discountedPrices, cp.commercialProductCode) || cp.price?.effectivePrice,
        isEpp: cp?.productSubType === CommercialProductSubType.EPP_DEVICE,
        selected: cp.commercialProductCode === selectedCommercialProduct,
        hasRecurringCharges: !!cp.price?.effectivePrice.monthlyRecurringCharge,
        hasOneTimeCharges: !!cp.price?.effectivePrice.oneTimeCharge,
      }))
      .filter(paymentOptionData => includeEpp || !paymentOptionData.isEpp)
      .filter(
        paymentOptionData =>
          allowNonEppDevicesWithRecurringCharges || paymentOptionData.isEpp || !paymentOptionData.hasRecurringCharges
      )
      .filter(
        paymentOptionData =>
          allowNonEppDevicesWithOneTimeCharges || paymentOptionData.isEpp || !paymentOptionData.hasOneTimeCharges
      ) || []
  );
};

export const getPaymentLabel = (price: Price | undefined, isEpp: boolean) => {
  if (price?.monthlyRecurringCharge) {
    return `${price.payments}${t.XXVX(monthsMsg)}, ${formatSumToString(price.monthlyRecurringCharge, true)} ${t.YO7F(
      sumPerMonthMsg,
      '€'
    )}${isEpp ? ' (EPP)' : ''}`;
  } else {
    return `${t.ASEI(oneTimePaymentMsg)} ${formatSumToString(price?.oneTimeCharge)}`;
  }
};

export const getPaymentOptions = (
  offerItem: OfferItem,
  onlineModel: OnlineModel | undefined,
  discountedPrices: DiscountedPrices[],
  eppSolution?: EppSolutionResponse
): ShoppingCartPaymentOption[] => {
  if (onlineModel?.category === CommercialProductType.SALES_PRODUCT) {
    // no payment options for sales products
    return [];
  }
  const eppActive = eppSolution?.eppSolutionStatus === EppSolutionStatusEnum.ACTIVE;
  const allowNonEppRecurring = !eppActive || eppSolution?.allowDevicesWithRecurringCharges;
  const allowNonEppOneTime = !eppActive || eppSolution?.allowDevicesWithOneTimeCharges;
  const priceOptions = getPriceOptionsForOffer(
    offerItem.guid,
    offerItem.commercialProductGuid,
    onlineModel,
    eppActive,
    allowNonEppRecurring === undefined ? true : allowNonEppRecurring,
    allowNonEppOneTime === undefined ? true : allowNonEppOneTime,
    discountedPrices
  );
  return (
    priceOptions
      // Sort lowest monthly charge first
      .sort((a, b) => (a.price?.monthlyRecurringCharge || 0) - (b.price?.monthlyRecurringCharge || 0))
      // Sort EPPs first
      .sort((a, b) => Number(b.isEpp) - Number(a.isEpp))
      // Sort one time charges last
      .sort((a, b) => (a.price?.oneTimeCharge || 0) - (b.price?.oneTimeCharge || 0))
      .map(sortedPrice => ({
        id: sortedPrice.id,
        label: getPaymentLabel(sortedPrice.price, sortedPrice.isEpp) || '',
        selected: sortedPrice.selected,
      }))
  );
};

const getAddonDisclaimers = (addOn: AddOn): string[] => {
  const vatMessage = t.A0OJ(vatPercentageMsg, '0');
  if (isSquareTradeAddOn(addOn)) {
    return [`${t.VWSV(deductibleMsg)} ${squareTradeDeductibleMsg}`, t.XJMB(noFixedTermMsg), vatMessage];
  }
  return [vatMessage];
};

const getAddonPriceDisplayData = (addOn: AddOn, quantity: number) => {
  if (addOn.addOnOneTimeCharge) {
    return {
      amount: formatSumToString(addOn.addOnOneTimeCharge * quantity, true),
      unit: '€',
    };
  } else if (addOn.addOnMonthlyRecurringCharge) {
    return {
      amount: formatSumToString(addOn.addOnMonthlyRecurringCharge * quantity, true),
      unit: t.YO7F(sumPerMonthMsg, '€'),
    };
  }
  return {
    amount: '',
    unit: '',
  };
};

export const getAddonsDisplayData = (
  addOns: string[],
  model: OnlineModel,
  quantity: number
): ShoppingCartItemAddon[] => {
  return (
    model.addOns?.flatMap(modelAddOn => {
      if (addOns.includes(modelAddOn.addOnCode)) {
        return [
          {
            disclaimer: modelAddOn && getAddonDisclaimers(modelAddOn),
            id: modelAddOn?.addOnCode,
            name: modelAddOn?.addOnProductName || '',
            price: getAddonPriceDisplayData(modelAddOn, quantity),
            quantity,
          } as ShoppingCartItemAddon,
        ];
      }
      return [];
    }) || []
  );
};

export const getTotalAmount = (basketItems?: BasketItem[]) => {
  return basketItems?.reduce((acc, curr) => acc + curr.quantity, 0) || 0;
};

const isItemsEqualInBasket = (newBasketItem: BasketItem, existingBasketItem: BasketItem) => {
  if (existingBasketItem.guid !== newBasketItem.guid) {
    return false;
  }
  return (
    deepEqual(existingBasketItem.offer, newBasketItem.offer) &&
    deepEqual(existingBasketItem.offer.addOns, newBasketItem.offer.addOns)
  );
};

const findMatchingBasketItem = (
  commercialProductGuid: string,
  existingItem?: BasketItem,
  existingBasketItems?: BasketItem[]
) => {
  return existingBasketItems?.find(
    basketItem =>
      basketItem.offer.commercialProductGuid === commercialProductGuid &&
      deepEqual(existingItem?.offer.addOns, basketItem.offer.addOns)
  );
};

const generateUniqueId = (existingItems: ShoppingBasketType): string => {
  const newId = crypto.randomUUID();
  // Probability is near zero for collision, but handle anyways..
  if (existingItems.items?.find(existingItem => existingItem.id === newId)) {
    return generateUniqueId(existingItems);
  } else {
    return newId;
  }
};

export const addToShoppingBasket = (basketItem: CartItemContainer) => {
  const fromLocalStorage = getShoppingBasketFromLocalStorage();
  const shoppingBasketJson: ShoppingBasketType = fromLocalStorage
    ? JSON.parse(fromLocalStorage)
    : {
        id: crypto.randomUUID(),
        items: [],
        vouchers: [],
      };

  const newItem: BasketItem = {
    id: generateUniqueId(shoppingBasketJson),
    guid: basketItem.onlineModel.onlineModelCode,
    name: basketItem.offer.offerName,
    quantity: basketItem.quantity,
    offer: {
      guid: basketItem.offer.offerCode,
      addOns: basketItem.selectedAddOnBundles.map(addOn => addOn.addOnCode),
      commercialProductGuid: basketItem.commercialProduct.commercialProductCode || '',
      ...(basketItem.commercialProduct.oneTimeCharge && {
        oneTimePriceInCents: basketItem.commercialProduct.oneTimeCharge,
      }),
      ...(basketItem.commercialProduct.monthlyRecurringCharge && {
        periodicPrice: {
          periodicPriceInCents: basketItem.commercialProduct.monthlyRecurringCharge,
          payments: basketItem.commercialProduct.payments,
          period: basketItem.commercialProduct.billingPeriod || 1, // Billing period in months, usually not available so default to 1
        },
      }),
    },
    imageUrl:
      (basketItem.offer.images && basketItem.offer.images.length > 0 && basketItem.offer.images[0]) ||
      basketItem.onlineModel.listingImage ||
      '',
    pageUrl: basketItem.onlineModel.pagePath,
  };

  const existingBasketItem = shoppingBasketJson.items?.find(existingItem =>
    isItemsEqualInBasket(newItem, existingItem)
  );
  if (existingBasketItem) {
    existingBasketItem.quantity += newItem.quantity;
  } else {
    shoppingBasketJson.items = [...(shoppingBasketJson.items || []), newItem];
  }

  setShoppingBasketToLocalStorage(JSON.stringify(shoppingBasketJson));
};

const findBasketItemForId = (basketItems: BasketItem[] | undefined, basketItemId: string) => {
  return basketItems?.find(item => item.id === basketItemId);
};

export const changeQuantityInBasket = (shoppingBasket: ShoppingBasketType, basketItemId: string, quantity: number) => {
  const basketItem = findBasketItemForId(shoppingBasket.items, basketItemId);
  if (basketItem) {
    if (quantity === 0) {
      const index = shoppingBasket.items?.findIndex(item => item.id === basketItemId);
      if (typeof index === 'number' && index >= 0) {
        shoppingBasket.items?.splice(index, 1);
      }
    } else {
      basketItem.quantity = quantity;
    }
  }

  return JSON.stringify(shoppingBasket);
};

export const getAnalyticsEventForAddOrRemoveItem = (
  shoppingBasket: ShoppingBasketType,
  basketItemId: string,
  quantity: number,
  onlineModels: OnlineModel[]
) => {
  const basketItem = findBasketItemForId(shoppingBasket.items, basketItemId);
  if (!basketItem) {
    return;
  }
  const onlineModelForItem = getOnlineModelForBasketItem(onlineModels, basketItem);
  if (!onlineModelForItem) {
    return;
  }
  const commercialProduct = getCommercialProductForGuid(basketItem.offer.commercialProductGuid, onlineModelForItem);
  if (!commercialProduct) {
    return;
  }
  const commercialProducts =
    commercialProduct.productType === CommercialProductType.SALES_PRODUCT
      ? getOffer(basketItem.offer.guid, onlineModelForItem)?.commercialProducts || []
      : [commercialProduct];
  const delta = quantity - basketItem.quantity;

  const ecommerceItem = createEcommerceItemFromCommercialProducts(
    onlineModelForItem.onlineModelCode,
    onlineModelForItem.category ?? commercialProduct.productSubType ?? commercialProduct.productType,
    onlineModelForItem.onlineModelName,
    getItemVariant(EcommerceEventTypeV4.ADD_TO_CART, commercialProduct.commercialProductName) ??
      commercialProduct.commercialProductName,
    commercialProducts,
    Math.abs(delta),
    getManufacturer(onlineModelForItem, commercialProduct.commercialProductName)
  );
  return delta > 0
    ? googleEcommerceAddToCart([ecommerceItem]).event
    : googleEcommerceRemoveFromCart([ecommerceItem]).event;
};

export const changePaymentOption = ({
  basketItem,
  changeToCommercialProductCode,
  onlineModel,
  discountedPrices,
}: ChangePaymentOptions) => {
  const shoppingBasket = JSON.parse(getShoppingBasketFromLocalStorage()) as ShoppingBasketType;
  const itemToChange = findBasketItemForId(shoppingBasket.items, basketItem.id);
  const cp = getCommercialProductForGuid(changeToCommercialProductCode, onlineModel);

  const possibleExistingItem = findMatchingBasketItem(
    changeToCommercialProductCode,
    itemToChange,
    shoppingBasket?.items
  );
  if (itemToChange && possibleExistingItem) {
    // Same item already in basket, increase the current amount..
    possibleExistingItem.quantity = possibleExistingItem.quantity + itemToChange.quantity;
    // ..and delete the changed item from basket
    const index = shoppingBasket.items?.indexOf(itemToChange, 0);
    if (index && index > -1) {
      shoppingBasket.items?.splice(index, 1);
    }
  } else if (itemToChange && cp) {
    const price = getPriceForGuid(cp.commercialProductCode, onlineModel, discountedPrices);
    itemToChange.offer.commercialProductGuid = cp.commercialProductCode;
    if (price?.price?.monthlyRecurringCharge) {
      itemToChange.offer.oneTimePriceInCents = undefined;
      itemToChange.offer.periodicPrice = {
        periodicPriceInCents: price?.price?.monthlyRecurringCharge,
        payments: price?.price?.payments,
        period: cp.billingPeriod || 1, // Billing period in months, usually not available so default to 1
      };
    } else if (price?.price?.oneTimeCharge) {
      itemToChange.offer.periodicPrice = undefined;
      itemToChange.offer.oneTimePriceInCents = price?.price?.oneTimeCharge;
    }
  }
  return JSON.stringify(shoppingBasket);
};

export const getDisclaimerFields = (oneTimePaymentText: string, priceData?: PriceData) => {
  const hasOneTimeCharge = !!priceData?.price?.oneTimeCharge;
  const oneTimeChargeFields = ['', oneTimePaymentText, t.A0OJ(vatPercentageMsg, '0')];
  const payments = priceData?.price?.payments || 0;
  // Corporate VAT is 0
  const monthlyRecurringFields = [
    payments === 0 ? '' : t.CF93('{} month agreement', String(payments)),
    payments === 0
      ? ''
      : t.W1RX(totalWithCurlyBracketsMsg, formatSum(Number(priceData?.price?.monthlyRecurringCharge) * payments) || ''),
    t.A0OJ(vatPercentageMsg, '0'),
  ];
  return [...(hasOneTimeCharge ? oneTimeChargeFields : []), ...(!hasOneTimeCharge ? monthlyRecurringFields : [])];
};

export const isActiveEppPrice = (cpGuid: string, onlineModel?: OnlineModel) => {
  const commercialProduct = getCommercialProductForGuid(cpGuid, onlineModel);
  return commercialProduct?.active && commercialProduct?.productSubType === CommercialProductSubType.EPP_DEVICE;
};
