/**
 * SFDC Anonymous API
 * # Summary Elisa B2B API for an anonymous access to salesforce data.  # Usage This API is used in the `https://yrityksille.elisa.fi/omaelisa` pages, but it is possible to later on expose this documentation publicly and make the endpoint queryable via external clients. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An offer property
 */
export interface OfferProperty { 
    /**
     * Name of this property
     */
    name: string;
    /**
     * Value of this property
     */
    value?: string;
    /**
     * The section of order process this additional property should be entered in
     */
    section?: OfferProperty.SectionEnum;
    /**
     * Type of this additional property
     */
    propertyType?: OfferProperty.PropertyTypeEnum;
    /**
     * Is this additional property required when submitting an order
     */
    required?: boolean;
    /**
     * Order of this additional property
     */
    order?: number;
}
export namespace OfferProperty {
    export type SectionEnum = 'ORDER' | 'ORDER_ITEM';
    export const SectionEnum = {
        ORDER: 'ORDER' as SectionEnum,
        ORDER_ITEM: 'ORDER_ITEM' as SectionEnum
    };
    export type PropertyTypeEnum = 'TEXT' | 'DELIVERY_ADDRESS';
    export const PropertyTypeEnum = {
        TEXT: 'TEXT' as PropertyTypeEnum,
        DELIVERY_ADDRESS: 'DELIVERY_ADDRESS' as PropertyTypeEnum
    };
}


