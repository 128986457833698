import { DnsRecordEditableFields } from '../../../dnsManagementUtils.js';
import { TextInput } from '../../../../../common/react-hook-form/fields/index.js';
import { capitalize } from '../../../../../common/utils/stringUtils.js';
import { t, valueMsg } from '../../../../../common/i18n/index.js';

export const RecordInput = ({
  index,
  name,
  label,
  type,
  validate,
}: {
  index: number;
  name: string;
  label?: string;
  type?: 'text' | 'number';
  validate?: (value: string, values?: object) => string | undefined;
}) => (
  <TextInput
    name={`${DnsRecordEditableFields.values}.${index}.${name}`}
    label={index === 0 ? capitalize(label || t.RPL9(valueMsg)) : undefined}
    required
    type={type ?? 'text'}
    validate={validate}
  />
);
