import { DialogType } from '../../common/enums.js';
import {
  TypeKeys,
  getAuthorizationRequestFailed,
  getAuthorizationRequestFulfilled,
  processAuthorizationRequestFailed,
  processAuthorizationRequestFulfilledAction,
  showDialog,
} from '../actions/index.js';
import { actionToActionState } from './epicUtils.js';
import {
  authorizeExternalAuthorizationRequestPublicMethod,
  getExternalAuthorizationRequestPublicMethod,
} from '../../generated/api/uiApiMethods.js';
import { buildPathVariable, paths } from '../../common/constants/pathVariables.js';
import { callUiApi, prepareUiApiRequest } from '../common/uiApiUtils.js';
import { combineEpics, ofType } from 'redux-observable';
import { concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { replace } from 'redux-first-history';
import type { Action } from 'redux';
import type { ActionAndState, EpicDependencies } from './epicUtils.js';
import type { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import type { AjaxResponse } from 'rxjs/ajax';
import type {
  AuthorizationRequest,
  AuthorizeAuthorizationRequestWithIdServiceCodeRequest,
} from '../../generated/api/models.js';
import type {
  GetAuthorizationRequestAction,
  ProcessAuthorizationRequestAction,
  ProcessAuthorizationRequestFulfilledAction,
  SelfServiceActionTypes,
} from '../actions/index.js';
import type { State } from '../common/store.js';

const getAuthorizationRequestEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(
    action$.pipe(ofType(TypeKeys.GET_AUTHORIZATION_REQUEST)),
    (action: GetAuthorizationRequestAction) => actionToActionState(action, state$, 'authorizationRequest')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const { linkToken, showError } = actionAndState.action as GetAuthorizationRequestAction;
      return callUiApi({
        epicDependencies,
        state$,
        method: getExternalAuthorizationRequestPublicMethod(linkToken),
        successAction: response => getAuthorizationRequestFulfilled(linkToken, response as AuthorizationRequest),
        failureAction: getAuthorizationRequestFailed,
        failureParams: { linkToken: linkToken },
      }).pipe(
        concatMap(fulfilledOrFailAction =>
          fulfilledOrFailAction.type === TypeKeys.GET_AUTHORIZATION_REQUEST_FULFILLED && showError
            ? [fulfilledOrFailAction, showDialog({ type: DialogType.EXTERNAL_AUTHENTICATION_FAIL })]
            : [fulfilledOrFailAction]
        )
      );
    })
  );

const processAuthorizationRequestEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(
    action$.pipe(ofType(TypeKeys.PROCESS_AUTHORIZATION_REQUEST)),
    (action: ProcessAuthorizationRequestAction) => actionToActionState(action, state$, 'authorizationRequest')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const action = actionAndState.action as ProcessAuthorizationRequestAction;
      return callUiApi({
        epicDependencies,
        state$,
        method: authorizeExternalAuthorizationRequestPublicMethod(action.linkToken),
        payload: { idServiceCode: action.authorizationCode } as AuthorizeAuthorizationRequestWithIdServiceCodeRequest,
        successAction: (response: AjaxResponse) =>
          processAuthorizationRequestFulfilledAction(action.linkToken, response.response),
        failureAction: processAuthorizationRequestFailed,
        failureParams: { linkToken: action.linkToken },
      }).pipe(
        concatMap(finalAction => {
          const submitOrderFulfilledAction = finalAction as ProcessAuthorizationRequestFulfilledAction;
          if (submitOrderFulfilledAction.type === TypeKeys.PROCESS_AUTHORIZATION_REQUEST_FULFILLED) {
            return [finalAction, replace(buildPathVariable(paths.AUTHORIZED_NUMBER_PORTING, action.linkToken))];
          }
          return of(finalAction);
        })
      );
    })
  );

export const authorizationRequestEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = combineEpics(
  getAuthorizationRequestEpic,
  processAuthorizationRequestEpic
);
