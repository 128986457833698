import * as CL from '@design-system/component-library';
import * as React from 'react';
import {
  employeeOmaElisaMsg,
  myAccountMsg,
  myOrdersMsg,
  myProductsAndServicesMsg,
  orderRequestsMsg,
  t,
} from '../../../common/i18n/index.js';
import { paths } from '../../../common/constants/pathVariables.js';
import type { NavigateFunction } from 'react-router-dom';

const navigateOrRedirect = (navigate: NavigateFunction, url: string, isPublicSite: boolean) =>
  isPublicSite ? (window.location.href = url) : navigate(url);

export const employeeUserMenuLinks = (navigate: NavigateFunction, hasApproverRole: boolean, isPublicSite: boolean) => {
  return [
    {
      icon: <CL.Icon icon="home" />,
      onClick: (e: React.SyntheticEvent) => {
        e.preventDefault();
        navigateOrRedirect(navigate, paths.EMPLOYEE_HOME, isPublicSite);
      },
      text: t.S0FN(employeeOmaElisaMsg),
      url: paths.EMPLOYEE_HOME,
    },
    {
      icon: <CL.Icon icon="devices" />,
      onClick: (e: React.SyntheticEvent) => {
        e.preventDefault();
        navigateOrRedirect(navigate, paths.EMPLOYEE_SUBSCRIPTIONS, isPublicSite);
      },
      text: t.VWNX(myProductsAndServicesMsg),
      url: paths.EMPLOYEE_SUBSCRIPTIONS,
    },
    {
      icon: <CL.Icon icon="delivery" />,
      onClick: (e: React.SyntheticEvent) => {
        e.preventDefault();
        navigateOrRedirect(navigate, paths.EMPLOYEE_OWN_ORDERS, isPublicSite);
      },
      text: t.EG6F(myOrdersMsg),
      url: paths.EMPLOYEE_OWN_ORDERS,
    },
    ...(hasApproverRole
      ? [
          {
            icon: <CL.Icon icon="invoice" />,
            onClick: (e: React.SyntheticEvent) => {
              e.preventDefault();
              navigateOrRedirect(navigate, paths.EMPLOYEE_PENDING_REVIEW_ORDERS, isPublicSite);
            },
            text: t.Y29L(orderRequestsMsg),
            url: paths.EMPLOYEE_PENDING_REVIEW_ORDERS,
          },
        ]
      : []),
    {
      icon: <CL.Icon icon="new-message" />,
      onClick: (e: React.SyntheticEvent) => {
        e.preventDefault();
        navigateOrRedirect(navigate, paths.EMPLOYEE_OWN_INFO, isPublicSite);
      },
      text: t.SEBD(myAccountMsg),
      url: paths.EMPLOYEE_OWN_INFO,
    },
  ];
};
