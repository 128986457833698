import {
  TypeKeys,
  finalizePaymentFailed,
  finalizePaymentFulfilled,
  initializePaymentFailed,
  initializePaymentFulfilled,
} from '../actions/index.js';
import { actionToActionState } from './epicUtils.js';
import { callUiApi, prepareUiApiRequest } from '../common/uiApiUtils.js';
import { combineEpics, ofType } from 'redux-observable';
import { concatMap } from 'rxjs/operators';
import {
  finalizeInvoicePaymentPrivateMethod,
  initializeInvoicePaymentPrivateMethod,
} from '../../generated/api/uiApiMethods.js';
import { of } from 'rxjs';
import type { Action } from 'redux';
import type { ActionAndState, EpicDependencies } from './epicUtils.js';
import type { ActionState, ActionWithId, State } from '../common/store.js';
import type { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import type { AjaxResponse } from 'rxjs/ajax';
import type {
  FinalizePaymentAction,
  InitializePaymentAction,
  InitializePaymentFulfilledAction,
  SelfServiceActionTypes,
} from '../actions/index.js';

const initializePaymentEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) => {
  return prepareUiApiRequest(action$.pipe(ofType(TypeKeys.INITIALIZE_PAYMENT)), (action: ActionWithId) => {
    return actionToActionState(action, state$, 'payments');
  }).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const action = actionAndState.action as InitializePaymentAction;
      const payload = {
        balance: action.balance,
        paymentMethod: action.paymentMethod,
      };
      const successAction = (response: AjaxResponse) => {
        return initializePaymentFulfilled(response.response);
      };
      const initializePayment$ = callUiApi({
        epicDependencies,
        failureAction: initializePaymentFailed,
        method: initializeInvoicePaymentPrivateMethod(action.invoiceId),
        payload,
        state$,
        successAction,
      });
      return initializePayment$.pipe(
        concatMap((finalAction: InitializePaymentFulfilledAction) => {
          if (finalAction.type === TypeKeys.INITIALIZE_PAYMENT_FULFILLED) {
            window.location.href = finalAction.forwardUrl;
          }
          return of(finalAction);
        })
      );
    })
  );
};

const finalizePaymentEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) => {
  return prepareUiApiRequest(action$.pipe(ofType(TypeKeys.FINALIZE_PAYMENT)), (action: ActionWithId) => {
    return actionToActionState(action, state$, 'payments');
  }).pipe(
    concatMap((actionAndState: { action: FinalizePaymentAction; state: ActionState }) => {
      const { transactionId, invoiceId } = actionAndState.action;
      const successAction = ({ response }: AjaxResponse) => {
        const invoice = state$.value.selfservice?.invoices?.all?.items?.find(i => i.invoiceId === invoiceId);
        return finalizePaymentFulfilled(response, invoice!);
      };

      return callUiApi({
        epicDependencies,
        failureAction: finalizePaymentFailed,
        method: finalizeInvoicePaymentPrivateMethod(invoiceId),
        payload: { transactionId },
        state$,
        successAction,
      });
    })
  );
};

export const paymentsEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = combineEpics(
  initializePaymentEpic,
  finalizePaymentEpic
);
