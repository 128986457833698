import { useEffect, useRef, useState } from 'react';

/**
 * useScrollToBottom: Works best for the responsive chat applications
 * Scrolls down automatically when content height of the container in changing
 * Allow manual scroll up and return back to automatic scrolling after scroll bottoms
 * Works independent of the logic that changes content inside the container
 * Return ref that need to be given to the container
 */
export const useScrollToBottom = (eventNumber: number) => {
  const ref = useRef<HTMLDivElement>(null);
  const [scrollTop, setScrollTop] = useState<number>(0);
  const [scrollHeight, setScrollHeight] = useState<number>(0);
  const [direction, setDirection] = useState<boolean>(true);
  const [hasBottomed, setHasBottomed] = useState<boolean>(true);
  const [eventsCounted, setEventsCounted] = useState<number>(eventNumber);

  useEffect(() => {
    const containerRef = ref;
    if (containerRef.current && (eventNumber === 0 || eventNumber > eventsCounted)) {
      containerRef.current.scrollTo({ behavior: 'smooth', top: containerRef.current.scrollHeight });
      setEventsCounted(eventNumber);
      setScrollTop(0);
      setScrollHeight(0);
      setDirection(true);
      setHasBottomed(true);
    }

    const handleScroll = () => {
      if (containerRef.current) {
        const currentScrollTop = containerRef.current.scrollTop;
        const height = Math.round(scrollHeight - containerRef.current.scrollTop);
        const clientHeight = containerRef.current.clientHeight;

        if (scrollTop > currentScrollTop) {
          setDirection(false);
          setHasBottomed(false);
        }

        if (scrollTop < currentScrollTop) {
          setDirection(true);
        }

        if (height === clientHeight) {
          setHasBottomed(true);
        }

        setScrollHeight(containerRef.current.scrollHeight);
        setScrollTop(containerRef.current.scrollTop);

        if (scrollHeight !== containerRef.current.scrollHeight && direction && hasBottomed) {
          containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
      }
    };

    const mutationObserver = new MutationObserver(handleScroll);

    if (containerRef.current) {
      mutationObserver.observe(containerRef.current, { childList: true, subtree: true, characterData: true });
    }

    return () => {
      if (containerRef.current) {
        mutationObserver.disconnect();
      }
    };
  }, [direction, eventNumber, eventsCounted, hasBottomed, scrollHeight, scrollTop]);

  return ref;
};
