import * as CL from '@design-system/component-library';
import { DialogWrapper } from '../DialogWrapper/index.js';
import { TerminateType } from '../../common/enums.js';
import {
  dialogHeaderMsg,
  optionOneDetailMsg,
  optionOneMsg,
  optionTwoDetailMsg,
  optionTwoMsg,
  titleMsg,
} from './translations.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { t } from '../../common/i18n/index.js';
import { terminateSubscriptionAction } from '../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import type { Subscription } from '../../generated/api/models.js';

enum DetachRingSelection {
  TERMINATE_RING = 'TERMINATE_RING',
  DETACH_RING = 'DETACH_RING',
}

interface DetachRingConfirmationDialogProps {
  subscription: Subscription;
  onCloseDialog: () => void;
}

export const DetachRingConfirmationDialog = (props: DetachRingConfirmationDialogProps) => {
  const dispatch = useDispatch();
  const { subscription, onCloseDialog } = props;

  const pbxConfig = subscription.details?.mobile?.pbxConfiguration;
  const username = pbxConfig?.pbxConfigurationDetails?.ringUserName || '';
  const extensionNumber = pbxConfig?.extensionNumber || '';
  const corporateNumber = pbxConfig?.corporateNumber || '';
  const [selection, setSelection] = useState(DetachRingSelection.TERMINATE_RING);

  const onTerminate = () => {
    const terminateType =
      selection === DetachRingSelection.DETACH_RING ? TerminateType.RING_CONNECTION : TerminateType.RING;
    dispatch(terminateSubscriptionAction(subscription.subscriptionId, Date.now(), true, terminateType));
    onCloseDialog();
  };

  return (
    <DialogWrapper
      buttons={[
        {
          color: 'link',
          text: t.B2V1('Cancel'),
          onClick: onCloseDialog,
        },
        {
          text: t.QVYK('OK'),
          onClick: onTerminate,
        },
      ]}
      closeable
      header={t.EPUZ(dialogHeaderMsg)}
      onCloseDialog={onCloseDialog}
    >
      <>
        <p>{t.GVGJ(titleMsg)}</p>
        <CL.RadioGroup value={selection}>
          <CL.RadioV2
            id="terminate-ring-radio"
            label={t.TTKS(optionOneMsg)}
            value={DetachRingSelection.TERMINATE_RING}
            onChange={() => setSelection(DetachRingSelection.TERMINATE_RING)}
          />
          <CL.RadioV2
            id="detach-ring-radio"
            label={t.G42E(optionTwoMsg)}
            value={DetachRingSelection.DETACH_RING}
            onChange={() => setSelection(DetachRingSelection.DETACH_RING)}
          />
        </CL.RadioGroup>
        {selection === DetachRingSelection.TERMINATE_RING && (
          <p className={dsClass.TEXT_XS}>{t.UVFA(optionOneDetailMsg, username, extensionNumber, corporateNumber)}</p>
        )}
        {selection === DetachRingSelection.DETACH_RING && (
          <p className={dsClass.TEXT_XS}>{t.STA2(optionTwoDetailMsg, username, extensionNumber, corporateNumber)}</p>
        )}
      </>
    </DialogWrapper>
  );
};
