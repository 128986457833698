/**
 * SFDC Community API
 * # Summary Elisa B2B API for an administrator of a company to query and modify the company information.  # Usage This API is used in the `https://yrityksille.elisa.fi/omaelisa` pages, but it is possible to later on expose this documentation publicly and make the endpoint queryable via external clients.  # Identifiers All entities in the API have a globally unique, internal identifier field named with the format [object_type]Id, e.g. `invoiceId`, which is also used in some of the API endpoint urls, e.g. `/api/v1/selfservice/invoices/{invoiceId}`. This value is not meaningful to the end user but is only used as an internally in the API.  Most entities also have a displayable identifier named in the format [object_type]DisplayId. This value can and should be shown to the end user, as it is also printed in relevant documents. It is also used in some of the public URLs, e.g. `https://yrityksille.elisa.fi/omaelisa/laskut/{invoiceDisplayId}`. Because of the user might land directly into a URL of a single entity with a display id, some of the API endpoints here also support querying via the display id, e.g. `/api/v1/selfservice/invoices?invoiceDisplayId={invoiceDisplayId}`.  The reason for using two identifiers instead of only one display id, is to make sure that every object will always have exactly one REST API URL that will never collide with another. While collisions of display ids should not happen in production, the id fields are guaranteed to be unique even across different test environments. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DirectoryDetails } from './directoryDetails.js';


export interface SubscriptionPbxDetails { 
    /**
     * Type of pbx solution. SFDC: csord__Subscription__c.Subscription_Type__c
     */
    pbxType?: SubscriptionPbxDetails.PbxTypeEnum;
    /**
     * If VAKIO, TAVOITETTAVUUSKETJU then manual selection of currently active reachability chain, bypassing the calendar. SFDC: Current_Active_Chain__c.
     */
    currentActiveChain?: SubscriptionPbxDetails.CurrentActiveChainEnum;
    /**
     * If VAKIO, TAVOITETTAVUUSKETJU then numbers to call when subscription busy, during off-work hours, in calling order. SFDC: csord__Service__c.Reachability_Chain_for_Off_Work_Hours__c.
     */
    offWorkReachabilityChain?: Array<string>;
    /**
     * If VAKIO then user selection for Calling Line Identity Change Service. SFDC: csord__Service__c.Caller_ID_Masking__c
     */
    callerLineIdMasking?: SubscriptionPbxDetails.CallerLineIdMaskingEnum;
    /**
     * If VAKIO then display number for Calling Line Identity Change Service if ALWAYS_ON option is selected. Format should be national number without prefix. SFDC: csord__Service__c.Caller_ID_Number__c
     */
    callerLineIdNumber?: string;
    /**
     * User selection for caller line identify for Target Numbers. If TAVOITETTAVUUSKETJU then non mandatory field and ORIGINAL_CLID option shown to customer. If RYHMAKUTSU, VASTAUSSARJA then mandatory field and ORIGINAL_CLID, PSTN_NUMBER_OF_SERVICE shown to customer. SFDC: csord__Service__c.CLID_Option__c
     */
    callerLineIdTargetNumber?: SubscriptionPbxDetails.CallerLineIdTargetNumberEnum;
    /**
     * If RYHMAKUTSU, VASTAUSSARJA then action to perform outside calendar hours. SFDC: csord__Service__c.Closing_hour_action__c
     */
    closeHoursAction?: SubscriptionPbxDetails.CloseHoursActionEnum;
    /**
     * If RYHMAKUTSU, VASTAUSSARJA then mobile number to connect outside calendar hours. SFDC: csord__Service__c.Closing_hour_number__c
     */
    closeHoursNumber?: string;
    /**
     * If RING then ring user id. SFDC: csord__Service__c.Login_UserID__c
     */
    ringUserName?: string;
    /**
     * If RING then End User Service subscription name. SFDC: csord__Subscription__c.Name
     */
    ringServiceLevelName?: string;
    /**
     * If RING then End User Service subscription name. SFDC: csord__Subscription__c.Root_Service__r.Price_Item__r.GUID__c
     */
    ringServiceLevelCode?: string;
    /**
     * If RING then services user has purchased for this ring subscription. Return all services customer has purchased. SFDC: csord__Service__c.Add_On_Price_Item__c.GUID__c
     */
    ringAdditionalServiceCodes?: Array<string>;
    corporateNumberDirectoryDetails?: DirectoryDetails;
}
export namespace SubscriptionPbxDetails {
    export type PbxTypeEnum = 'TAVOITETTAVUUSKETJU' | 'RYHMAKUTSU' | 'VASTAUSSARJA' | 'VAKIO' | 'RING';
    export const PbxTypeEnum = {
        TAVOITETTAVUUSKETJU: 'TAVOITETTAVUUSKETJU' as PbxTypeEnum,
        RYHMAKUTSU: 'RYHMAKUTSU' as PbxTypeEnum,
        VASTAUSSARJA: 'VASTAUSSARJA' as PbxTypeEnum,
        VAKIO: 'VAKIO' as PbxTypeEnum,
        RING: 'RING' as PbxTypeEnum
    };
    export type CurrentActiveChainEnum = 'CALENDAR' | 'WORKING_HOURS' | 'OFF_WORK';
    export const CurrentActiveChainEnum = {
        CALENDAR: 'CALENDAR' as CurrentActiveChainEnum,
        WORKING_HOURS: 'WORKING_HOURS' as CurrentActiveChainEnum,
        OFF_WORK: 'OFF_WORK' as CurrentActiveChainEnum
    };
    export type CallerLineIdMaskingEnum = 'NOT_IN_USE' | 'BY_CALENDAR' | 'ALWAYS_ON';
    export const CallerLineIdMaskingEnum = {
        NOT_IN_USE: 'NOT_IN_USE' as CallerLineIdMaskingEnum,
        BY_CALENDAR: 'BY_CALENDAR' as CallerLineIdMaskingEnum,
        ALWAYS_ON: 'ALWAYS_ON' as CallerLineIdMaskingEnum
    };
    export type CallerLineIdTargetNumberEnum = 'ORIGINAL_CLID' | 'PSTN_NUMBER_OF_SERVICE';
    export const CallerLineIdTargetNumberEnum = {
        ORIGINAL_CLID: 'ORIGINAL_CLID' as CallerLineIdTargetNumberEnum,
        PSTN_NUMBER_OF_SERVICE: 'PSTN_NUMBER_OF_SERVICE' as CallerLineIdTargetNumberEnum
    };
    export type CloseHoursActionEnum = 'PLACEHOLDER' | 'EXIT_TO_NUMBER';
    export const CloseHoursActionEnum = {
        PLACEHOLDER: 'PLACEHOLDER' as CloseHoursActionEnum,
        EXIT_TO_NUMBER: 'EXIT_TO_NUMBER' as CloseHoursActionEnum
    };
}


