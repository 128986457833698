import * as CL from '@design-system/component-library';
import { ExternalAuthenticationProviders } from '../ExternalAuthenticationProviders/ExternalAuthenticationProviders.js';
import { SiteContext } from '../../public/site/SiteContext.js';
import { authenticationMsg, t } from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { formatPhoneNumber } from '../../common/utils/phoneNumberUtils.js';
import { useContext } from 'react';
import type { AuthorizationSceneProps } from './AuthorizationScene.js';
import type { Locale } from '../../common/i18n/index.js';
import type { OwnerChangeRequestState } from '../../common/types/states.js';

export const AuthorizationOwnerChange = ({ authorizationRequest }: AuthorizationSceneProps) => {
  const { siteLanguage } = useContext(SiteContext);

  const ownerChangeRequest = authorizationRequest as OwnerChangeRequestState;
  if (!ownerChangeRequest.mobileNumber || !ownerChangeRequest.newOwnerCompanyName) {
    throw new Error('Erroneous OwnerChangeRequestState');
  }

  return (
    <CL.Grid className={`${dsClass.MARGIN_LEFT_2} ${dsClass.MARGIN_TOP_8}`}>
      <CL.GridRow gutter={true}>
        <CL.GridCol colWidthXS={4} colWidthM={6} colWidthL={12}>
          <h3>{t.C2SK('Confirm change of ownership')}</h3>
          <h4 className={dsClass.MARGIN_BOTTOM_2}>{t.MNN3('Subscription to be handed over')}</h4>
          {formatPhoneNumber(ownerChangeRequest.mobileNumber)}
          <h4 className={`${dsClass.MARGIN_TOP_2} ${dsClass.MARGIN_BOTTOM_2}`}>{t.H3ZA('New owner')}</h4>
          {ownerChangeRequest.newOwnerCompanyName}
          <h3 className={dsClass.PADDING_TOP_4}>{t.AKN3(authenticationMsg)}</h3>
          <p className={dsClass.MARGIN_RIGHT_4}>
            {t.D4Y7(
              'Use personal online banking credentials or a mobile certificate to confirm the transfer of the subscription number'
            )}{' '}
            {formatPhoneNumber(ownerChangeRequest.mobileNumber)} {t.UYCL('to its new owner.')}
          </p>
        </CL.GridCol>
      </CL.GridRow>
      <CL.GridRow className={dsClass.MARGIN_TOP_5}>
        <CL.GridCol colWidthXS={4} colWidthM={6} colWidthL={12}>
          <ExternalAuthenticationProviders
            locale={siteLanguage as Locale}
            uriState={authorizationRequest!.authorizationRequestId}
          />
        </CL.GridCol>
      </CL.GridRow>
    </CL.Grid>
  );
};
