import { InputComponent, type OptionalInputComponentProps } from '../components/InputComponent.js';
import { fieldCantBeEmptyMsg, streetAddressMsg, t } from '../../i18n/index.js';

export const StreetAddress = ({
  name = 'streetAddress',
  label = t.DD38(streetAddressMsg),
  placeholder = undefined,
  required = true,
  disabled = false,
  hint,
}: OptionalInputComponentProps) => {
  const validate = (value: string) => {
    if (!value && required) {
      return t.VPVR(fieldCantBeEmptyMsg);
    }
    return;
  };

  return (
    <InputComponent
      validate={validate}
      name={name}
      type="text"
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      required={required}
      maxLength={100}
      hint={hint}
    />
  );
};
