import * as CL from '@design-system/component-library';
import { AddValueButton, TTLColumn, TypeColumn } from './DnsRecordListRow/DnsRecordListRowEdit.js';
import { DnsRecordEdit } from './DnsRecordListRow/DnsRecordEdit.js';
import {
  DnsRecordEditableDropdown,
  DnsRecordEditableFields,
  getDnsRecordRequest,
  isDnsRecordEditingAllowed,
} from '../../dnsManagementUtils.js';
import { DnsRecordSaveConfirmation } from './DnsRecordListRow/DnsRecordSaveConfirmation.js';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { TextInput } from '../../../../common/react-hook-form/fields/index.js';
import { dsClass } from '../../../../common/constants/dsClasses.js';
import { t } from '../../../../common/i18n/index.js';
import { useState } from 'react';
import { validateHostname } from './Records/fieldValidation.js';
import type { DnsRecordEditableForm } from '../../dnsManagementUtils.js';
import type { DnsRecordEditableValue } from '../../../../generated/api/dnsRecordEditableValue.js';
import type { DnsRecordRequest } from '../../../../generated/api/dnsRecordRequest.js';

import './DnsRecordCreate.scss';

/**
 * If no name has been given by the user we just create the record to the main domain
 * otherwise the given name is used as a subdomain and must be validated
 */
const isValidDomainName = (name: string) => (!name ? undefined : validateHostname(name));

const toFullDomainName = (name: string, domainName: string) => (name ? `${name}.${domainName}.` : `${domainName}.`);

export const DnsRecordCreate = ({
  domainName,
  onCreate,
}: {
  domainName: string;
  onCreate: (payload: DnsRecordRequest) => void;
}) => {
  const defaultValues = {
    name: '',
    ttl: 3600,
    type: DnsRecordEditableDropdown[0].value,
    values: [{}],
  };
  const methods = useForm<DnsRecordEditableForm>({ defaultValues, mode: 'all' });
  const { append, fields, remove, replace } = useFieldArray({
    control: methods.control,
    name: DnsRecordEditableFields.values,
  });
  const [name, type] = methods.watch([DnsRecordEditableFields.name, DnsRecordEditableFields.type]);
  const [savingItem, setSavingItem] = useState<DnsRecordRequest>();

  const getFormValues = (form = methods.getValues()) => ({ ...form, name: toFullDomainName(form.name, domainName) });

  return (
    <FormProvider {...methods}>
      <div className={`${dsClass.BACKGROUND_COLOR_NEUTRAL_200} ${dsClass.PADDING_4} ${dsClass.MARGIN_TOP_6}`}>
        <h3 className={dsClass.MARGIN_TOP_0}>{t.DMJV('Create new record')}</h3>
        <form onSubmit={methods.handleSubmit(() => setSavingItem(getDnsRecordRequest(getFormValues())))}>
          <div className="of-create-dns-record-form-grid">
            <div>
              <TextInput
                name={DnsRecordEditableFields.name}
                hint={toFullDomainName(name, domainName)}
                label={t.PNS1('Hostname')}
                required={false}
                validate={isValidDomainName}
              />
            </div>
            <TypeColumn
              type={type}
              onValueChange={next => {
                replace([{} as DnsRecordEditableValue]);
                methods.setValue('type', next);
              }}
            />
            <DnsRecordEdit type={type} fields={fields} onRemoveValueRow={remove} />
            <TTLColumn />
            <div className={dsClass.MARGIN_TOP_5}>
              <CL.Button
                id="submit"
                type="submit"
                className={`${dsClass.WHITE_SPACE_NOWRAP} ${dsClass.MARGIN_BOTTOM_3}`}
                disabled={!isDnsRecordEditingAllowed(getFormValues(), domainName)}
              >
                {t.TFDQ('Create new')}
              </CL.Button>
            </div>
          </div>
          <AddValueButton type={type} onAddValue={() => append({} as DnsRecordEditableValue)} />
        </form>
        {savingItem && (
          <DnsRecordSaveConfirmation
            onCancel={() => setSavingItem(undefined)}
            onConfirm={() => onCreate(getDnsRecordRequest(getFormValues()))}
            row={savingItem}
          />
        )}
      </div>
    </FormProvider>
  );
};
