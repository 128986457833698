import * as CL from '@design-system/component-library';
import * as React from 'react';
import { Modal } from '../Modal/Modal.js';
import { ModalButtons } from '../Modal/ModalButtons.js';
import { cancelMsg, submitMsg, t } from '../../common/i18n/index.js';
import { useNavigate } from 'react-router-dom';
import type { ModalProps } from '../Modal/Modal.js';
import type { ReactNode } from 'react';

export interface DialogWrapperButton {
  color?: 'primary' | 'linkblue' | 'light' | 'link';
  disabled?: boolean;
  id?: string;
  isLoading?: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  text: string;
  type?: HTMLButtonElement['type'];
}

interface DialogWrapperLink {
  href: string;
  onClick?: () => void;
  text: string;
}

export interface DialogWrapperProps {
  buttons?: DialogWrapperButton[];
  children?: ReactNode;
  closeable?: boolean;
  header?: string;
  links?: DialogWrapperLink[];
  onCloseDialog: () => void;
  size?: ModalProps['size'];
  align?: ModalProps['align'];
}

export const buttonsForSubmitAndBack = (
  onCancel?: () => void,
  onSubmit?: () => void,
  cancelButtonText?: string,
  cancelDisabled?: boolean,
  submitButtonText?: string,
  submitDisabled?: boolean,
  submitting?: boolean
) => {
  const buttons: DialogWrapperButton[] = [];

  if (onCancel) {
    buttons.push({
      color: onSubmit ? 'link' : undefined,
      disabled: cancelDisabled || submitting,
      onClick: onCancel,
      text: cancelButtonText || t.B2V1(cancelMsg),
    });
  }

  if (onSubmit) {
    buttons.push({
      disabled: submitDisabled,
      isLoading: submitting,
      onClick: onSubmit,
      text: submitButtonText || t.FSWU(submitMsg),
      type: 'submit',
    });
  }

  return buttons;
};

export const DialogWrapper = ({
  buttons = [],
  children,
  closeable,
  header,
  links = [],
  onCloseDialog,
  size,
  align,
}: DialogWrapperProps) => {
  const navigate = useNavigate();
  return (
    <Modal closeable={closeable} heading={header} onClose={onCloseDialog} size={size} align={align}>
      {children}
      {Boolean(buttons?.length || links?.length) && (
        <ModalButtons>
          {buttons.map(
            ({ color = 'primary', disabled, onClick, id, isLoading, text, type }: DialogWrapperButton, index) => (
              <CL.Button
                color={color}
                disabled={disabled}
                id={id}
                key={`dialog-button-${index}`}
                loading={isLoading}
                size="l"
                onClick={onClick}
                type={type}
              >
                {text}
              </CL.Button>
            )
          )}
          {links.map(({ href, text }: DialogWrapperLink) => (
            <CL.Link
              key={href}
              linkHref={href}
              linkStyle="link-button"
              onClick={e => {
                e.preventDefault();
                navigate(href);
              }}
            >
              {text}
            </CL.Link>
          ))}
        </ModalButtons>
      )}
    </Modal>
  );
};
