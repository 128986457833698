import { AUTHENTICATED, getConflictingErrors, getDuplicateContactDialogParams } from './authenticatedUserEpic.js';
import { CATEGORY_URL } from '../../common/utils/categoryUtils.js';
import {
  CREATE_NEW_CONTACT,
  getDuplicateContactInformationDialogParams,
  getPossibleDuplicateContactInformationDialogParams,
} from '../../common/utils/contactUtils.js';
import { ContactCreationType } from '../../components/ContactOrPurposeOfUse/ContactsOrPurposeOfUseUtils.js';
import {
  ContactRole,
  ContactType,
  ContractRequest,
  EppRedeemTerminateRequestType,
  SubscriptionType,
} from '../../generated/api/models.js';
import { EMPTY, of, concat as rxjsConcat } from 'rxjs';
import { SelectedPurposeOfUseOrContact } from '../../common/enums.js';
import {
  TypeKeys,
  addSupportCase,
  changeSimCardFailed,
  changeSimCardFulfilled,
  changeSubscriptionBillingAccountFailed,
  changeSubscriptionBillingAccountFulfilled,
  changeSubscriptionPbxConfigurationFailed,
  changeSubscriptionPbxConfigurationFulfilled,
  changeSubscriptionUserInfoFailed,
  changeSubscriptionUserInfoFulfilled,
  loadEmployeeSubscriptionActionsFailed,
  loadEmployeeSubscriptionActionsFulfilled,
  loadSubscriptionActionsFailed,
  loadSubscriptionActionsFulfilled,
  resumeSubscriptionFailedAction,
  resumeSubscriptionFulfilledAction,
  showDialog,
  startNotification,
  suspendSubscriptionFailed,
  suspendSubscriptionFulfilled,
  terminateDeviceSubscriptionFulfilled,
  terminateDeviceSubscriptionServiceFailed,
  terminateSubscriptionActionFailed,
  terminateSubscriptionActionFulfilled,
  upsertContactFailed,
  upsertContactFulfilled,
} from '../actions/index.js';
import {
  actionToActionState,
  getMdmIdFromUrlParams,
  getMdmIdHeaderFromUrlParams,
  isNewDeviceCheckout,
} from './epicUtils.js';
import {
  attachSubscriptionToPbxFailed,
  attachSubscriptionToPbxFulfilled,
  changeOfferFailed,
  changeOfferFulfilled,
  changeSubscriptionAddonsFailed,
  changeSubscriptionAddonsFulfilled,
  changeSubscriptionUserInfoWithDuplicateFailed,
  postEppMaintenanceFailed,
  postEppMaintenanceFulfilled,
  postEppRedeemTerminateFailed,
  postEppRedeemTerminateFulfilled,
} from '../actions/subscriptionActionsActions.js';
import { callUiApi, prepareUiApiRequest } from '../common/uiApiUtils.js';
import {
  changeSubscriptionBillingAccountPrivateMethod,
  changeSubscriptionChangeSimPrivateMethod,
  changeSubscriptionFeaturesPrivateMethod,
  changeSubscriptionMobilePbxPrivateMethod,
  changeSubscriptionResumePrivateMethod,
  changeSubscriptionSuspendPrivateMethod,
  changeSubscriptionTerminateDeviceServicePrivateMethod,
  changeSubscriptionTerminatePrivateMethod,
  changeSubscriptionUserInformationPrivateMethod,
  getSubscriptionActionsPrivateMethod,
  maintenanceEppPrivateMethod,
  postContractPrivateMethod,
  postTerminateContractPrivateMethod,
  redeemOrTerminateEppPrivateMethod,
} from '../../generated/api/uiApiMethods.js';
import { combineEpics, ofType } from 'redux-observable';
import { concat, concatMap, filter, mergeMap, share } from 'rxjs/operators';
import { generatePath } from 'react-router-dom';
import { isEmployeePortal } from '../../common/utils/browserUtils.js';
import { isInBrowser } from '../../common/utils/ssrUtils.js';
import { loadSubscriptionActionsUseSearchService } from '../common/elasticsearchUtils.js';
import { mvOrderingSuccessfulMsg, mvTerminationSuccessfulMsg, t } from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import { push } from 'redux-first-history';
import { removeOmaLaiteLaskuSupportCase } from '../../components/EmployeeSubscriptionDetails/eppSubscriptionUtils.js';
import {
  submitMobileIdContractFailed,
  submitMobileIdContractFulfilled,
  terminateMobileIdContractFailed,
  terminateMobileIdContractFulfilled,
} from '../actions/mobileIdContractActions.js';
import { upsertContact } from './contactEpic.js';
import { upsertPersonBillingAccount } from './billingAccountEpic.js';
import type { Action } from 'redux';
import type { ActionAndState, EpicDependencies } from './epicUtils.js';
import type { ActionWithId, ItemsQuery, State } from '../common/store.js';
import type { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import type { AjaxResponse } from 'rxjs/ajax';
import type {
  AttachSubscriptionToPbxAction,
  AttachSubscriptionToPbxFulfilledAction,
  ChangeOfferAction,
  ChangeOfferFulfilledAction,
  ChangeSubscriptionAddonsAction,
  PostEppMaintenanceAction,
  PostEppMaintenanceFulfilledAction,
  PostEppRedeemTerminateAction,
  PostEppRedeemTerminateFulfilledAction,
  SetSubscriptionDetailForDeviceChangeAction,
} from '../actions/subscriptionActionsActions.js';
import type {
  ChangeSimCardAction,
  ChangeSubscriptionBillingAccountAction,
  ChangeSubscriptionPbxConfigurationAction,
  ChangeSubscriptionUserInfoAction,
  ChangeSubscriptionUserInfoFulfilledAction,
  ErrorAction,
  LoadEmployeeSubscriptionActions,
  LoadSubscriptionActions,
  SelfServiceActionTypes,
  SuspendSubscriptionAction,
  TerminateDeviceServiceAction,
  TerminateSubscriptionAction,
  UpsertContactFulfilledAction,
  UpsertPersonBillingAccountFulfilledAction,
} from '../actions/index.js';
import type {
  Contact,
  ContactUserContact,
  PutContactResponse,
  SubscriptionActionsResponse,
  SubscriptionChangeFeaturesRequest,
  SubscriptionChangeUserInformationRequest,
  SubscriptionPbxConfiguration,
  UserPurposeOfUseDetails,
} from '../../generated/api/models.js';
import type { Observable } from 'rxjs';
import type { PurposeOfUseOrContact } from '../../common/types/subscription.js';
import type {
  SubmitMobileIdContractFailedAction,
  SubmitMobileIdContractFulfilledAction,
  TerminateMobileIdContractAction,
  TerminateMobileIdContractFailedAction,
  TerminateMobileIdContractFulfilledAction,
} from '../actions/mobileIdContractActions.js';

const getNewContactPayload = (purposeOfUseOrContact: PurposeOfUseOrContact): Contact | undefined => {
  if (
    purposeOfUseOrContact.selected === SelectedPurposeOfUseOrContact.CONTACT &&
    (purposeOfUseOrContact.contactId === CREATE_NEW_CONTACT().value ||
      purposeOfUseOrContact.contactId === ContactCreationType.CREATE_NEW_CONTACT)
  ) {
    const userContact: ContactUserContact = {
      email: purposeOfUseOrContact.email,
      firstName: purposeOfUseOrContact.firstName!,
      lastName: purposeOfUseOrContact.lastName!,
      phoneNumber: purposeOfUseOrContact.phoneNumber,
      roles: [ContactRole.ENDUSER_CONTACT],
    };
    if (purposeOfUseOrContact.costCenter || purposeOfUseOrContact.employeeNumber) {
      return {
        contactType: ContactType.PERSON,
        person: {
          ...userContact,
          email: userContact.email!,
          phoneNumber: userContact.phoneNumber!,
          costCenter: purposeOfUseOrContact.costCenter,
          employeeNumber: purposeOfUseOrContact.employeeNumber,
        },
      };
    } else {
      return {
        contactType: ContactType.LIMITED_INFORMATION_PERSON,
        userContact: userContact,
      };
    }
  }
  return undefined;
};

export const changeSubscriptionBillingAccountEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(
    action$.pipe(ofType(TypeKeys.CHANGE_SUBSCRIPTION_BILLING_ACCOUNT)),
    (action: ChangeSubscriptionBillingAccountAction) =>
      actionToActionState(action, state$, 'pendingSubscriptionActions')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      if (!actionAndState.state) {
        throw new Error('invalid action state for changing subscription billing account');
      }
      const { billingAccountId, billingAccountDisplayId, subscription } =
        actionAndState.action as ChangeSubscriptionBillingAccountAction;
      const payload = { billingAccountId };
      const successAction = ({ response }: AjaxResponse) => {
        return changeSubscriptionBillingAccountFulfilled(response, billingAccountId, billingAccountDisplayId);
      };

      return callUiApi({
        epicDependencies,
        failureAction: changeSubscriptionBillingAccountFailed,
        method: changeSubscriptionBillingAccountPrivateMethod(subscription.subscriptionId),
        payload,
        state$,
        successAction,
      });
    })
  );

export const terminateSubscriptionDeviceServiceEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(
    action$.pipe(ofType(TypeKeys.TERMINATE_SUBSCRIPTION_DEVICE_SERVICE)),
    (action: TerminateDeviceServiceAction) => actionToActionState(action, state$, 'pendingSubscriptionActions')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      if (!actionAndState.state) {
        throw new Error('invalid action state for terminating subscription device service');
      }
      const { subscriptionId, addOnCode } = actionAndState.action as TerminateDeviceServiceAction;
      const payload = { addOnCode };
      const accountMasterIdHeader = getMdmIdHeaderFromUrlParams();

      return callUiApi({
        epicDependencies,
        failureAction: terminateDeviceSubscriptionServiceFailed,
        method: changeSubscriptionTerminateDeviceServicePrivateMethod(subscriptionId),
        payload,
        state$,
        successAction: terminateDeviceSubscriptionFulfilled,
        headers: accountMasterIdHeader,
      });
    })
  );

export const terminateSubscription: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.TERMINATE_SUBSCRIPTION)), (action: TerminateSubscriptionAction) =>
    actionToActionState(action, state$, 'pendingSubscriptionActions')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const { subscriptionId, effectiveDate, donateNumber, terminateType } =
        actionAndState.action as TerminateSubscriptionAction;
      const payload = {
        donateNumber,
        effectiveDate,
        terminateType,
      };
      const successAction = (response: AjaxResponse) => terminateSubscriptionActionFulfilled(response.response);

      return callUiApi({
        epicDependencies,
        failureAction: terminateSubscriptionActionFailed,
        method: changeSubscriptionTerminatePrivateMethod(subscriptionId),
        payload,
        state$,
        successAction,
        headers: getMdmIdHeaderFromUrlParams(),
      });
    })
  );

export const suspendSubscriptionActionEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.SUSPEND_SUBSCRIPTION)), (action: ActionWithId) =>
    actionToActionState(action, state$, 'pendingSubscriptionActions')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const subscriptionId = (actionAndState.action as SuspendSubscriptionAction).subscriptionId;
      const successAction = (response: AjaxResponse) => suspendSubscriptionFulfilled(response.response);

      return callUiApi({
        epicDependencies,
        failureAction: suspendSubscriptionFailed,
        method: changeSubscriptionSuspendPrivateMethod(subscriptionId),
        state$,
        successAction,
        headers: getMdmIdHeaderFromUrlParams(),
      });
    })
  );

export const resumeSubscriptionActionEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.RESUME_SUBSCRIPTION)), (action: ActionWithId) =>
    actionToActionState(action, state$, 'pendingSubscriptionActions')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const subscriptionId = (actionAndState.action as SuspendSubscriptionAction).subscriptionId;
      const successAction = (response: AjaxResponse) => resumeSubscriptionFulfilledAction(response.response);
      return callUiApi({
        epicDependencies,
        failureAction: resumeSubscriptionFailedAction,
        method: changeSubscriptionResumePrivateMethod(subscriptionId),
        state$,
        successAction,
        headers: getMdmIdHeaderFromUrlParams(),
      });
    }),
    share()
  );

export const changeSubscriptionUserInfoEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(
    action$.pipe(ofType(TypeKeys.CHANGE_SUBSCRIPTION_USER_INFO)),
    (action: ChangeSubscriptionUserInfoAction) => actionToActionState(action, state$, 'pendingSubscriptionActions')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      if (!actionAndState.state) {
        throw new Error('invalid action state for change subscription user info');
      }
      const { purposeOfUseOrContact, subscriptionId, billingAccountIdForOmaLaiteLaskuRemoval } =
        actionAndState.action as ChangeSubscriptionUserInfoAction;
      let observable: Observable<
        UpsertContactFulfilledAction | ErrorAction<TypeKeys> | ChangeSubscriptionUserInfoAction
      > = of(actionAndState.action as ChangeSubscriptionUserInfoAction);
      const newContact = getNewContactPayload(purposeOfUseOrContact);
      const accountMasterIdHeader = getMdmIdHeaderFromUrlParams();

      if (newContact) {
        observable = upsertContact(
          newContact,
          epicDependencies,
          state$,
          upsertContactFailed,
          (response: PutContactResponse) => {
            return upsertContactFulfilled(response, newContact);
          },
          false,
          false
        );
      }
      return observable.pipe(
        concatMap(action => {
          let userPurposeOfUseDetails: UserPurposeOfUseDetails;
          if (action && action.type === TypeKeys.UPSERT_CONTACT_FULFILLED) {
            userPurposeOfUseDetails = {
              contactId: (action as UpsertContactFulfilledAction).contact.contactId,
            };
          } else if (purposeOfUseOrContact.selected === SelectedPurposeOfUseOrContact.PURPOSEOFUSE) {
            userPurposeOfUseDetails = {
              purposeOfUse: purposeOfUseOrContact.purposeOfUse,
            };
          } else if (
            action?.type === TypeKeys.UPSERT_CONTACT_FAILED &&
            action.errors?.[0].source?.error?.duplicateContact?.contactId
          ) {
            const dialogParams =
              action.errors?.[0].source?.error?.errorType === 'POSSIBLE_CONTACT_DUPLICATE'
                ? getPossibleDuplicateContactInformationDialogParams(action.errors[0].source.error.duplicateContact)
                : getDuplicateContactInformationDialogParams(action.errors[0].source.error.duplicateContact);
            return [
              showDialog(dialogParams),
              changeSubscriptionUserInfoWithDuplicateFailed('Failed to create contact', 500),
            ];
          } else if (action?.type === TypeKeys.UPSERT_CONTACT_FAILED && getConflictingErrors(action.errors)) {
            return [
              showDialog(getDuplicateContactDialogParams()),
              changeSubscriptionUserInfoWithDuplicateFailed('Failed to create contact', 500),
            ];
          } else {
            userPurposeOfUseDetails = {
              contactId: purposeOfUseOrContact.contactId,
            };
          }

          const payload: SubscriptionChangeUserInformationRequest = {
            contactDetails: userPurposeOfUseDetails,
            costCenter: purposeOfUseOrContact.costCenter,
            voiceDirectoryDetails: purposeOfUseOrContact.directoryListingMobileNumberPublicity?.voiceDirectoryDetails,
            pbxDirectoryDetails: purposeOfUseOrContact.directoryListingCorporateNumberPublicity?.pbxDirectoryDetails,
            subscriptionReference: purposeOfUseOrContact.employeeNumber,
          };

          const successAction = ({ response }: AjaxResponse) => {
            return changeSubscriptionUserInfoFulfilled(
              payload,
              response,
              subscriptionId,
              billingAccountIdForOmaLaiteLaskuRemoval
            );
          };

          // if both voice & pbx are changed, sfdc needs separate calls :/
          if (
            purposeOfUseOrContact.directoryListingMobileNumberPublicity?.voiceDirectoryDetails &&
            purposeOfUseOrContact.directoryListingCorporateNumberPublicity?.pbxDirectoryDetails
          ) {
            const firstChangeUserInformation$ = callUiApi({
              epicDependencies,
              failureAction: changeSubscriptionUserInfoFailed,
              method: changeSubscriptionUserInformationPrivateMethod(subscriptionId),
              payload: {
                ...payload,
                pbxDirectoryDetails: undefined,
              },
              state$,
              successAction,
            });

            const secondChangeUserInformation$ = callUiApi({
              epicDependencies,
              failureAction: changeSubscriptionUserInfoFailed,
              method: changeSubscriptionUserInformationPrivateMethod(subscriptionId),
              payload: {
                ...payload,
                voiceDirectoryDetails: undefined,
              },
              state$,
              successAction,
            });

            return firstChangeUserInformation$.pipe(
              concatMap(() => {
                return secondChangeUserInformation$.pipe(
                  concat(
                    of(action).pipe(
                      filter(() => {
                        return action && action.type === TypeKeys.UPSERT_CONTACT_FULFILLED;
                      })
                    )
                  )
                );
              })
            );
          } else {
            const changeUserInformation$ = callUiApi({
              epicDependencies,
              failureAction: changeSubscriptionUserInfoFailed,
              method: changeSubscriptionUserInformationPrivateMethod(subscriptionId),
              payload,
              state$,
              successAction,
              headers: accountMasterIdHeader,
            });
            return changeUserInformation$.pipe(
              concat(
                of(action).pipe(
                  filter(() => {
                    return action && action.type === TypeKeys.UPSERT_CONTACT_FULFILLED;
                  })
                )
              )
            );
          }
        })
      );
    })
  );

export const changeSubscriptionUserInfoFulfilledEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>
) =>
  action$.pipe(
    ofType(TypeKeys.CHANGE_SUBSCRIPTION_USER_INFO_FULFILLED),
    mergeMap((action: ChangeSubscriptionUserInfoFulfilledAction) => {
      if (action.billingAccountIdForOmaLaiteLaskuRemoval) {
        const subscriptionId = state$.value.selfservice?.subscriptions?.device?.items?.find(
          subscription => (subscription.subscriptionId = action.subscriptionId)
        )?.subscriptionDisplayId;
        return of(
          ...([
            addSupportCase(
              removeOmaLaiteLaskuSupportCase(
                action.subscriptionId,
                subscriptionId || '',
                action.billingAccountIdForOmaLaiteLaskuRemoval
              ),
              true
            ),
          ].filter(Boolean) as Action[])
        );
      }
      return EMPTY;
    })
  );

export const changeSubscriptionPbxConfigurationEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(
    action$.pipe(ofType(TypeKeys.CHANGE_SUBSCRIPTION_PBX_CONFIGURATION, TypeKeys.ATTACH_SUBSCRIPTION_TO_PBX)),
    (action: ActionWithId) => actionToActionState(action, state$, 'pendingSubscriptionActions')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const { subscriptionId, pbxConfiguration, type } = actionAndState.action as
        | ChangeSubscriptionPbxConfigurationAction
        | AttachSubscriptionToPbxAction;
      const failureAction =
        type === TypeKeys.CHANGE_SUBSCRIPTION_PBX_CONFIGURATION
          ? changeSubscriptionPbxConfigurationFailed
          : attachSubscriptionToPbxFailed;
      const payload = {
        ...pbxConfiguration,
        pbxConfigurationDetails: {
          ...pbxConfiguration.pbxConfigurationDetails,
          offWorkReachabilityChain: pbxConfiguration.pbxConfigurationDetails.offWorkReachabilityChain
            ? pbxConfiguration.pbxConfigurationDetails.offWorkReachabilityChain.filter(
                phoneNumber => phoneNumber.length > 0
              )
            : undefined,
        },
        workingHoursReachabilityChain: pbxConfiguration.workingHoursReachabilityChain
          ? pbxConfiguration.workingHoursReachabilityChain.filter(phoneNumber => phoneNumber.length > 0)
          : undefined,
      } as SubscriptionPbxConfiguration;
      const successAction = (response: AjaxResponse) => {
        if (type === TypeKeys.ATTACH_SUBSCRIPTION_TO_PBX) {
          return attachSubscriptionToPbxFulfilled(
            response.response,
            (actionAndState.action as AttachSubscriptionToPbxAction).subscriptionDisplayId,
            subscriptionId
          );
        } else {
          return changeSubscriptionPbxConfigurationFulfilled(response.response, subscriptionId);
        }
      };
      const changePbx$ = callUiApi({
        epicDependencies,
        failureAction,
        method: changeSubscriptionMobilePbxPrivateMethod(subscriptionId),
        payload,
        state$,
        successAction,
        headers: getMdmIdHeaderFromUrlParams(),
      });
      return changePbx$.pipe(
        concatMap(action => {
          const mdmId = getMdmIdFromUrlParams();
          if (action.type === TypeKeys.ATTACH_SUBSCRIPTION_TO_PBX_FULFILLED) {
            return [
              action,
              push(
                `${paths.PS_MOBILE_SUBSCRIPTIONS}/${
                  (action as AttachSubscriptionToPbxFulfilledAction).subscriptionDisplayId
                }${mdmId ? `?mdmId=${mdmId}` : ''}`
              ),
            ];
          }
          return [action];
        })
      );
    })
  );

export const changeSimCardEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.CHANGE_SIM_CARD)), (action: ActionWithId) =>
    actionToActionState(action, state$, 'pendingSubscriptionActions')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const simAction = actionAndState.action as ChangeSimCardAction;
      const payload = {
        simType: simAction.simType,
        iccId: simAction.simCardNumber,
        deliveryAddress: simAction.deliveryAddress,
      };
      const successAction = (response: AjaxResponse) =>
        changeSimCardFulfilled(
          simAction.simType,
          response.response,
          simAction.subscriptionId,
          simAction.subscriptionDisplayId,
          simAction.category,
          !!simAction.deliveryAddress
        );
      const changeSim$ = callUiApi({
        epicDependencies,
        failureAction: changeSimCardFailed,
        method: changeSubscriptionChangeSimPrivateMethod(simAction.subscriptionId),
        payload,
        state$,
        successAction,
        headers: getMdmIdHeaderFromUrlParams(),
      });
      return changeSim$.pipe(
        concatMap(action => {
          const subscriptionId = (action as ChangeSimCardAction).subscriptionId;
          const subscriptionDisplayId = (action as ChangeSimCardAction).subscriptionDisplayId;
          const category = (action as ChangeSimCardAction).category;
          const mdmId = getMdmIdFromUrlParams();
          if (
            action.type === TypeKeys.CHANGE_SIM_CARD_FULFILLED &&
            subscriptionId &&
            subscriptionDisplayId &&
            category
          ) {
            return [
              action,
              push(
                isEmployeePortal(window.location.pathname)
                  ? `${paths.EMPLOYEE_HOME}/${CATEGORY_URL[category]}/${subscriptionId}`
                  : `${paths.PS_HOME}/${CATEGORY_URL[category]}/${subscriptionDisplayId}${mdmId ? `?mdmId=${mdmId}` : ''}`
              ),
            ];
          }
          return [action];
        })
      );
    }),
    share()
  );

export const changeSubscriptionAddonsEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) => {
  return prepareUiApiRequest(action$.pipe(ofType(TypeKeys.CHANGE_ADDONS)), (action: ActionWithId) =>
    actionToActionState(action, state$, 'pendingSubscriptionActions')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const { subscription, addOnsToAdd, addOnsToRemove, addOnsToUpdate, addOnAttributes, changeContext } =
        actionAndState.action as ChangeSubscriptionAddonsAction;
      const payload: SubscriptionChangeFeaturesRequest = {
        changeContext,
        addOnChanges: {
          addOnsToAdd: addOnsToAdd.map(addOnGuid => ({
            addOnCode: addOnGuid,
            addOnAttributes: addOnAttributes && addOnAttributes[addOnGuid],
          })),
          addOnsToRemove: addOnsToRemove.map(addOnGuid => ({ addOnCode: addOnGuid })),
          addOnsToUpdate: addOnsToUpdate?.map(addOnGuid => ({
            addOnCode: addOnGuid,
            addOnAttributes: addOnAttributes && addOnAttributes[addOnGuid],
          })),
        },
        msisdn: subscription.subscriptionUserFriendlyId, // Used with assets
        sourceSystem: subscription.sourceSystem,
      };
      const successAction = (response: AjaxResponse) =>
        changeSubscriptionAddonsFulfilled(response.response, addOnsToAdd, addOnsToRemove);
      return callUiApi({
        epicDependencies,
        failureAction: changeSubscriptionAddonsFailed,
        method: changeSubscriptionFeaturesPrivateMethod(subscription.subscriptionId),
        payload,
        state$,
        successAction,
        headers: getMdmIdHeaderFromUrlParams(),
      });
    })
  );
};

export const changeOfferEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) => {
  return prepareUiApiRequest(action$.pipe(ofType(TypeKeys.CHANGE_OFFER)), (action: ActionWithId) =>
    actionToActionState(action, state$, 'pendingSubscriptionActions')
  )
    .pipe(
      concatMap((actionAndState: ActionAndState) => {
        const {
          subscriptionId,
          subscriptionDisplayId,
          subscriptionType,
          commercialProductCode,
          addOnsToAdd,
          addOnsToRemove,
          addOnsToUpdate,
          campaignAssociationCode,
        } = actionAndState.action as ChangeOfferAction;
        const payload = {
          commercialProductCode,
          addOnChanges: {
            addOnsToAdd: addOnsToAdd.map(addOnGuid => ({ addOnCode: addOnGuid })),
            addOnsToRemove: addOnsToRemove.map(addOnGuid => ({ addOnCode: addOnGuid })),
            addOnsToUpdate: addOnsToUpdate.map(addOnGuid => ({ addOnCode: addOnGuid })),
          },
          campaignAssociationCode,
        };
        const successAction = (response: AjaxResponse) =>
          changeOfferFulfilled(response.response, subscriptionDisplayId, subscriptionType);
        return callUiApi({
          epicDependencies,
          failureAction: changeOfferFailed,
          method: changeSubscriptionFeaturesPrivateMethod(subscriptionId),
          payload,
          state$,
          successAction,
          headers: getMdmIdHeaderFromUrlParams(),
        });
      })
    )
    .pipe(
      concatMap((finalAction: ChangeOfferFulfilledAction) => {
        if (finalAction.type === TypeKeys.CHANGE_OFFER_FULFILLED) {
          const mdmId = getMdmIdFromUrlParams();
          if (
            finalAction.subscriptionType === SubscriptionType.MOBILE_BROADBAND ||
            finalAction.subscriptionType === SubscriptionType.MOBILE_M2M
          ) {
            const broadbandRedirectUrl = generatePath(paths.PS_BROADBAND_SUBSCRIPTION, {
              subscriptionId: finalAction.subscriptionDisplayId,
            });
            return [finalAction, push(`${broadbandRedirectUrl}${mdmId ? `?mdmId=${mdmId}` : ''}`)];
          } else {
            if (Object.values(SubscriptionType).includes(finalAction.subscriptionType)) {
              const voiceRedirectUrl = generatePath(paths.PS_MOBILE_SUBSCRIPTION, {
                subscriptionId: finalAction.subscriptionDisplayId,
              });
              return [finalAction, push(`${voiceRedirectUrl}${mdmId ? `?mdmId=${mdmId}` : ''}`)];
            }
          }
        }
        return [finalAction];
      })
    );
};

const postEppMaintenanceEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.POST_EPP_MAINTENANCE)), (action: PostEppMaintenanceAction) =>
    actionToActionState(action, state$, 'pendingSubscriptionActions')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const postEppMaintenanceAction = actionAndState.action as PostEppMaintenanceAction;
      const accountMasterIdHeader = getMdmIdHeaderFromUrlParams();
      return callUiApi({
        epicDependencies,
        state$,
        method: maintenanceEppPrivateMethod(postEppMaintenanceAction.subscriptionId),
        payload: postEppMaintenanceAction.postEppMaintenanceRequest,
        successAction: (response: AjaxResponse) =>
          postEppMaintenanceFulfilled(
            postEppMaintenanceAction.postEppMaintenanceRequest,
            response.response,
            postEppMaintenanceAction.subscriptionId
          ),
        failureAction: postEppMaintenanceFailed,
        headers: accountMasterIdHeader,
      }).pipe(
        concatMap((finalAction: PostEppMaintenanceFulfilledAction) => {
          if (finalAction.type === TypeKeys.POST_EPP_MAINTENANCE_FULFILLED) {
            return [
              finalAction,
              push(isEmployeePortal(window.location.pathname) ? paths.EMPLOYEE_DEVICES : paths.PS_DEVICES),
            ];
          }
          return [finalAction];
        })
      );
    })
  );

export const postEppRedeemTerminate = (
  postEppRedeemTerminateAction: PostEppRedeemTerminateAction,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
): Observable<PostEppRedeemTerminateFulfilledAction | ErrorAction<TypeKeys>> => {
  const accountMasterIdHeader = getMdmIdHeaderFromUrlParams();
  return callUiApi({
    epicDependencies,
    state$,
    method: redeemOrTerminateEppPrivateMethod(postEppRedeemTerminateAction.subscriptionId),
    payload: postEppRedeemTerminateAction.request,
    failureAction: postEppRedeemTerminateFailed,
    successAction: (response: AjaxResponse) =>
      postEppRedeemTerminateFulfilled(
        postEppRedeemTerminateAction.request,
        response.response,
        postEppRedeemTerminateAction.subscriptionId
      ),
    headers: accountMasterIdHeader,
  });
};

const postEppRedeemTerminateEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(
    action$.pipe(ofType(TypeKeys.POST_EPP_REDEEM_TERMINATE)),
    (action: PostEppRedeemTerminateAction) => actionToActionState(action, state$, 'pendingSubscriptionActions')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const postEppRedeemTerminateAction = actionAndState.action as PostEppRedeemTerminateAction;
      let obs: Observable<PostEppRedeemTerminateAction | ErrorAction<TypeKeys>>;
      if (
        postEppRedeemTerminateAction.request.requestType === EppRedeemTerminateRequestType.EMPLOYEE_REDEEM &&
        postEppRedeemTerminateAction.personBillingAddress &&
        postEppRedeemTerminateAction.personBillingEmail
      ) {
        obs = upsertPersonBillingAccount(
          {
            address: postEppRedeemTerminateAction.personBillingAddress,
            email: postEppRedeemTerminateAction.personBillingEmail,
          },
          epicDependencies,
          state$
        );
      } else {
        obs = of(postEppRedeemTerminateAction);
      }
      return obs.pipe(
        concatMap(
          (
            action:
              | PostEppRedeemTerminateFulfilledAction
              | UpsertPersonBillingAccountFulfilledAction
              | ErrorAction<TypeKeys>
          ) => {
            if (action.type === TypeKeys.POST_EPP_REDEEM_TERMINATE) {
              return postEppRedeemTerminate(action as PostEppRedeemTerminateAction, state$, epicDependencies);
            } else if (action.type === TypeKeys.UPSERT_PERSON_BILLING_ACCOUNT_FULFILLED) {
              return rxjsConcat(
                of(action),
                postEppRedeemTerminate(
                  {
                    ...postEppRedeemTerminateAction,
                    request: {
                      ...postEppRedeemTerminateAction.request,
                      employeeRedeem: {
                        ...postEppRedeemTerminateAction.request.employeeRedeem!,
                        personBillingAccountId: (action as UpsertPersonBillingAccountFulfilledAction).response
                          .billingAccountId,
                      },
                    },
                  },
                  state$,
                  epicDependencies
                )
              );
            } else {
              return of(postEppRedeemTerminateFailed('Failed to create Person billing account', 500));
            }
          }
        )
      );
    })
  );

export const postEppRedeemTerminateFulFilledEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>
) => {
  return action$.pipe(
    ofType(TypeKeys.POST_EPP_REDEEM_TERMINATE_FULFILLED),
    filter(() => !isNewDeviceCheckout(window.location.pathname)),
    mergeMap(() => {
      return [push(isEmployeePortal(window.location.pathname) ? paths.EMPLOYEE_DEVICES : paths.PS_DEVICES)];
    })
  );
};

export const setSubscriptionDetailForDeviceChange: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>
) =>
  action$.ofType(TypeKeys.SET_SUBSCRIPTION_DETAIL_FOR_DEVICE_CHANGE).pipe(
    mergeMap((action: SetSubscriptionDetailForDeviceChangeAction) => {
      if (isInBrowser()) {
        const subscriptionInfoForDeviceChange = {
          contactId: action.subscriptionContactId,
          subscriptionId: action.subscriptionId,
        };
        sessionStorage.setItem(
          'subscription-detail-for-device-change',
          JSON.stringify(subscriptionInfoForDeviceChange)
        );
        if (action.redirectUrl) {
          return of(push(action.redirectUrl));
        }
      }
      return EMPTY;
    })
  );

export const resetSubscriptionDetailForDeviceChange: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>
) =>
  action$.ofType(TypeKeys.RESET_SUBSCRIPTION_DETAIL_FOR_DEVICE_CHANGE).pipe(
    mergeMap(() => {
      if (isInBrowser()) {
        sessionStorage.removeItem('subscription-detail-for-device-change');
      }
      return EMPTY;
    })
  );

export const loadSubscriptionActionsEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.LOAD_SUBSCRIPTION_ACTIONS)), (action: LoadSubscriptionActions) =>
    actionToActionState(action, state$, 'subscriptionActions')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const loadSubscriptionActionsAction = actionAndState.action as LoadSubscriptionActions;
      const useSearchService = loadSubscriptionActionsUseSearchService(loadSubscriptionActionsAction);

      if (!actionAndState.state || !actionAndState.state.query) {
        throw new Error('invalid action state for loading subscription actions: subscriptionId missing');
      }

      const query: ItemsQuery = actionAndState.state.query;
      const successAction = (res: SubscriptionActionsResponse) => {
        if (
          loadSubscriptionActionsAction.displayId &&
          (!res.subscriptionActions || res.subscriptionActions.length === 0)
        ) {
          return loadSubscriptionActionsFailed(
            `subscription actions ${loadSubscriptionActionsAction.displayId} could not be found`,
            404
          );
        }
        return loadSubscriptionActionsFulfilled(res, query, useSearchService);
      };

      if (useSearchService) {
        const useSearchServiceMethod = getSubscriptionActionsPrivateMethod({
          ...query,
          useSearchService: true,
        });
        return callUiApi({
          epicDependencies,
          failureAction: loadSubscriptionActionsFailed,
          method: useSearchServiceMethod,
          state$,
          successAction,
        });
      }

      const method = getSubscriptionActionsPrivateMethod({
        offset: query.offset,
        limit: query.limit,
        subscriptionActionDisplayId: loadSubscriptionActionsAction.displayId,
      });
      return callUiApi({
        epicDependencies,
        failureAction: loadSubscriptionActionsFailed,
        method,
        state$,
        successAction,
      });
    })
  );

export const loadEmployeeSubscriptionActionsEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(
    action$.pipe(ofType(TypeKeys.LOAD_EMPLOYEE_SUBSCRIPTION_ACTIONS)),
    (action: LoadEmployeeSubscriptionActions) => actionToActionState(action, state$, AUTHENTICATED)
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const loadEmployeeSubscriptionActionsAction = actionAndState.action as LoadSubscriptionActions;
      const useSearchService = loadSubscriptionActionsUseSearchService(loadEmployeeSubscriptionActionsAction);

      if (!actionAndState.state || !actionAndState.state.query) {
        throw new Error('invalid action state for loading subscription actions: subscriptionId missing');
      }
      const query: ItemsQuery = actionAndState.state.query;
      const successAction = (res: SubscriptionActionsResponse) => {
        if (
          loadEmployeeSubscriptionActionsAction.displayId &&
          (!res.subscriptionActions || res.subscriptionActions.length === 0)
        ) {
          return loadSubscriptionActionsFailed(
            `subscription actions ${loadEmployeeSubscriptionActionsAction.displayId} could not be found`,
            404
          );
        }
        return loadEmployeeSubscriptionActionsFulfilled(res, query, useSearchService);
      };

      if (useSearchService) {
        const useSearchServiceMethod = getSubscriptionActionsPrivateMethod({
          ...query,
          useSearchService: true,
          own: true,
        });
        return callUiApi({
          epicDependencies,
          failureAction: loadEmployeeSubscriptionActionsFailed,
          method: useSearchServiceMethod,
          state$,
          successAction,
        });
      }

      const method = getSubscriptionActionsPrivateMethod({
        offset: query.offset,
        own: true,
        limit: query.limit,
        subscriptionActionDisplayId: loadEmployeeSubscriptionActionsAction.displayId,
      });
      return callUiApi({
        epicDependencies,
        failureAction: loadEmployeeSubscriptionActionsFailed,
        method,
        state$,
        successAction,
      });
    })
  );

export const submitMobileIdContractEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  action$.ofType(TypeKeys.SUBMIT_MOBILEID_CONTRACT).pipe(
    concatMap(() => {
      const successAction = (res: AjaxResponse) => submitMobileIdContractFulfilled(res);

      const payload: ContractRequest = {
        contractType: ContractRequest.ContractTypeEnum.MOBILE_CERTIFICATE,
      };

      return callUiApi({
        epicDependencies,
        failureAction: submitMobileIdContractFailed,
        method: postContractPrivateMethod(),
        payload,
        state$,
        successAction,
      });
    }),
    concatMap((action: SubmitMobileIdContractFulfilledAction | SubmitMobileIdContractFailedAction) => {
      if (action.type === TypeKeys.SUBMIT_MOBILEID_CONTRACT_FULFILLED) {
        return [action, startNotification(t.R37K(mvOrderingSuccessfulMsg)), push(paths.PS_CONTRACTS)];
      }
      return of(action);
    })
  );

export const terminateMobileIdContractEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  action$.ofType(TypeKeys.TERMINATE_MOBILEID_CONTRACT).pipe(
    concatMap((action: TerminateMobileIdContractAction) => {
      const successAction = (res: AjaxResponse) => {
        return terminateMobileIdContractFulfilled(res);
      };

      return callUiApi({
        epicDependencies,
        failureAction: terminateMobileIdContractFailed,
        method: postTerminateContractPrivateMethod(action.contractNumber),
        state$,
        successAction,
        headers: getMdmIdHeaderFromUrlParams(),
      });
    }),
    concatMap((action: TerminateMobileIdContractFulfilledAction | TerminateMobileIdContractFailedAction) => {
      if (action.type === TypeKeys.TERMINATE_MOBILEID_CONTRACT_FULFILLED) {
        return [action, startNotification(t.EKAI(mvTerminationSuccessfulMsg)), push(paths.PS_CONTRACTS)];
      }
      return of(action);
    })
  );

export const subscriptionActionEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = combineEpics(
  changeSubscriptionBillingAccountEpic,
  terminateSubscriptionDeviceServiceEpic,
  terminateSubscription,
  suspendSubscriptionActionEpic,
  resumeSubscriptionActionEpic,
  changeSubscriptionAddonsEpic,
  changeSubscriptionUserInfoEpic,
  changeSubscriptionUserInfoFulfilledEpic,
  changeSubscriptionPbxConfigurationEpic,
  changeSimCardEpic,
  postEppMaintenanceEpic,
  postEppRedeemTerminateEpic,
  setSubscriptionDetailForDeviceChange,
  resetSubscriptionDetailForDeviceChange,
  changeOfferEpic,
  loadSubscriptionActionsEpic,
  postEppRedeemTerminateFulFilledEpic,
  loadEmployeeSubscriptionActionsEpic,
  submitMobileIdContractEpic,
  terminateMobileIdContractEpic
);
