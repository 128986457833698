import * as CL from '@design-system/component-library';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { t } from '../../../common/i18n/index.js';

export const ProductDetailsPriceDisclaimer = () => {
  return (
    <span className={`${dsClass.DISPLAY_FLEX} ${dsClass.MARGIN_TOP_3}`}>
      <span>
        <CL.Icon icon="information" size="m" type="regular" color="white--dark" />
      </span>
      <span className={dsClass.MARGIN_LEFT_2}>
        {t.COPO(
          'The charge does not include the redemption price if you decide to redeem the device after the end of the contract period.'
        )}
      </span>
    </span>
  );
};
