import { ContactListFields, DialogType } from '../enums.js';
import { ContactType } from '../../generated/api/models.js';
import { createNewMsg, t } from '../i18n/index.js';
import type { Contact, ContactPerson, GenericErrorDuplicateContact } from '../../generated/api/models.js';
import type { DialogParams } from '../types/dialog.js';
import type { FormattedContactOption } from '../types/checkout.js';

const toDisplayValue = (firstName: string, lastName: string | undefined) => {
  if (firstName && lastName) {
    return firstName + ' ' + lastName;
  }
  return firstName;
};

export const getDuplicateContactInformationDialogParams = (
  duplicateContact: GenericErrorDuplicateContact
): DialogParams => ({
  type: DialogType.DUPLICATE_CONTACT_INFORMATION,
  duplicateContact,
});

export const getPossibleDuplicateContactInformationDialogParams = (
  duplicateContact: GenericErrorDuplicateContact
): DialogParams => ({
  type: DialogType.POSSIBLE_DUPLICATE_CONTACT_INFORMATION,
  duplicateContact,
});

export const CREATE_NEW_CONTACT = (): FormattedContactOption => ({
  displayValue: t.GFN5(createNewMsg),
  value: 'CREATE_NEW_CONTACT',
});

export const formatContactsToFieldValueOptions = (
  contacts: Contact[] | undefined,
  createNewContact: boolean,
  showEmail?: boolean
): {
  displayOptions: string[];
  options: FormattedContactOption[];
} => {
  const displayOptions: string[] = [];
  const options: FormattedContactOption[] = [];
  if (createNewContact) {
    displayOptions.push(CREATE_NEW_CONTACT().displayValue!);
    options.push(CREATE_NEW_CONTACT());
  }
  if (!contacts) {
    return { displayOptions, options };
  }
  contacts.forEach(contact => {
    if (contact?.person) {
      const displayValue: string = toDisplayValue(contact.person.firstName, contact.person.lastName);
      const value: string = contact.contactId!;
      if (displayValue && value) {
        displayOptions.push(displayValue);
        options.push({ displayValue, value, displaySubValue: showEmail ? contact.person.email : undefined });
      }
    } else if (contact?.userContact) {
      const displayValue: string = toDisplayValue(contact.userContact.firstName, contact.userContact.lastName);
      const value: string = contact.contactId!;
      if (displayValue && value) {
        displayOptions.push(displayValue);
        options.push({ displayValue, value, displaySubValue: showEmail ? contact.userContact.email : undefined });
      }
    }
  });
  return { displayOptions, options };
};

export const getFullNameFromContactPerson = (contactPerson: ContactPerson | undefined) =>
  contactPerson ? `${contactPerson.firstName} ${contactPerson.lastName}` : '';

export const getContactPersonByContactId = (
  contacts: Contact[] | undefined,
  contactId?: string
): ContactPerson | undefined =>
  contactId ? contacts?.find(contact => contact.contactId === contactId)?.person : undefined;

export const getContactFromContactPerson = (contactPerson: ContactPerson): Contact => {
  if (contactPerson.costCenter || contactPerson.employeeNumber) {
    return {
      contactType: ContactType.PERSON,
      person: contactPerson,
    };
  }
  return {
    contactType: ContactType.LIMITED_INFORMATION_PERSON,
    userContact: contactPerson,
  };
};

export const getContactSort = (sort?: string) => {
  const fullNameSort = `${ContactListFields.CONTACT_FIRST_NAME},${ContactListFields.CONTACT_LAST_NAME}`;
  return sort && sort !== ContactListFields.CONTACT_NAME ? sort : fullNameSort;
};
