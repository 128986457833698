import {
  type AvailabilityAddress,
  type BillingAccountData,
  type OpenFormAnswers,
  type Row,
} from '../OpenFormAnswers.js';
import { OF, hasFieldApiName, hasGUID, hasQuestionType } from '../OpenForm.js';
import { OFPageType } from '../../../generated/api/oFPageType.js';
import { OFQuestionType } from '../../../generated/api/oFQuestionType.js';
import { type OpenForm } from './useOpenForm.js';
import {
  billingAccountExtensionNameMsg,
  billingAccountNameMsg,
  contactInfoMsg,
  deliveryMethodMsg,
  eInvoicingAddressMsg,
  eInvoicingOperatorMsg,
  emailInvoiceMsg,
  invoiceLanguageMsg,
  loadingProductInformationMsg,
  payerDetailsMsg,
  referenceMsg,
  t,
} from '../../../common/i18n/index.js';
import { fetchOpenFormSummary } from '../../../common/fetch.js';
import { getAddressText, getValue } from '../OpenFormUtils.js';
import { parseCurrencyToNumber } from '../../../common/utils/priceUtils.js';
import { useEffect, useMemo, useState } from 'react';
import { useOpenFormAsync } from '../OpenFormProvider.js';
import type { BillingAccount } from '../../../generated/api/billingAccount.js';
import type { ContactHeader } from '../../../generated/api/contactHeader.js';
import type { OpenFormCustomerNeedAdditionalInformation } from '../../../generated/api/openFormCustomerNeedAdditionalInformation.js';
import type { OpenFormCustomerNeedRequest } from '../../../generated/api/openFormCustomerNeedRequest.js';
import type { OpenFormProductSummary } from '../../../generated/api/openFormProductSummary.js';
import type { OpenFormQuestion } from '../../../generated/api/openFormQuestion.js';
import type { OpenFormSection } from '../../../generated/api/openFormSection.js';
import type { OpenFormSummaryResponse } from '../../../generated/api/openFormSummaryResponse.js';

export const NEW_BILLING_ACCOUNT = '#' as const;

export class OpenFormSummary {
  public readonly products: OpenFormProductSummary[];
  public readonly services: OpenFormProductSummary[];
  private readonly check: OpenFormSection[];
  private readonly order: OpenFormQuestion[];

  constructor(
    { products }: OpenFormSummaryResponse,
    private readonly form: OpenForm,
    private readonly answers: OpenFormAnswers
  ) {
    this.products = products.flatMap(OpenFormSummary.prepare(form, answers, OFPageType.QUESTION_MAIN_PRODUCTS));
    this.services = products.flatMap(OpenFormSummary.prepare(form, answers, OFPageType.QUESTION_ADDITIONAL_SERVICES));
    this.check = form.getSections(OFPageType.AVAILABILITY_CHECK);
    this.order = form.getQuestions(OFPageType.ORDER_ENRICHMENT);
  }

  get additionalInformation(): OpenFormCustomerNeedAdditionalInformation[] {
    return this.order
      .filter(q => [OF.INSTALLATION_ADDRESS, OF.PRODUCT_LINE, OF.BILLING_ACCOUNT].every(n => n !== q.fieldApiName))
      .map(q => ({ fieldLabel: q.fieldApiName, fieldValue: getValue(this.answers.get(q.guid)?.choices) ?? '' }))
      .concat([
        { fieldLabel: OF.INSTALLATION_ADDRESS, fieldValue: getAddressText(this.installationAddress) ?? '' },
        { fieldLabel: OF.PRODUCT_LINE, fieldValue: String(this.answers.valuesOf('subscriptionTypes').flat()) },
        { fieldLabel: OF.BILLING_ACCOUNT, fieldValue: this.billingAccount?.billingAccountId ?? '' },
        ...(this.billingAccountData
          ? [
              {
                fieldLabel: OF.BILLING_ACCOUNT_DATA,
                fieldValue: JSON.stringify(OpenFormSummary.parseBillingAccountData(this.billingAccountData)),
              },
            ]
          : []),
      ]);
  }

  get billingAccount(): BillingAccount | undefined {
    const context = this.answers.get(this.order.find(hasFieldApiName(OF.BILLING_ACCOUNT))?.guid);
    const value = getValue(context?.choices);
    return value && value !== NEW_BILLING_ACCOUNT ? context?.billingAccount : undefined;
  }

  get billingAccountData(): BillingAccountData | undefined {
    const context = this.answers.get(this.order.find(hasFieldApiName(OF.BILLING_ACCOUNT))?.guid);
    const value = getValue(context?.choices);
    return value && value === NEW_BILLING_ACCOUNT ? context?.billingAccountData : undefined;
  }

  get contactEntries(): [OpenFormQuestion, ContactHeader][] {
    return this.answers
      .entriesOf('contact')
      .map(([guid, contact]): [OpenFormQuestion, ContactHeader] => [this.order.find(hasGUID(guid))!, contact])
      .filter(([question]) => question);
  }

  get deliveryDate(): string | undefined {
    return getValue(this.answers.get(this.order.find(hasQuestionType(OFQuestionType.DATE_OF_DELIVERY))?.guid)?.choices);
  }

  get deliveryTime(): string | undefined {
    return getValue(this.answers.get(this.order.find(hasQuestionType(OFQuestionType.TIME_OF_DELIVERY))?.guid)?.choices);
  }

  get installationAddress(): AvailabilityAddress | undefined {
    return this.answers
      .entriesOf('address')
      .find(
        ([guid]) => this.order.some(hasFieldApiName(OF.INSTALLATION_ADDRESS, guid)) || this.check.some(hasGUID(guid))
      )?.[1];
  }

  get openFormCustomerNeedRequest(): OpenFormCustomerNeedRequest | undefined {
    const main = this.products.find(p => p.productCode);
    if (!main) {
      return undefined;
    }
    return {
      formId: this.form.formId,
      questions: this.form.questions.flatMap(q => (this.answers.visible(q) && this.answers.getAnswers(q)) || []),
      rootProducts: [
        {
          product: OpenFormSummary.product(main),
          childProducts: this.services.map(OpenFormSummary.product),
          additionalInformation: this.additionalInformation,
        },
      ],
    };
  }

  private static parseBillingAccountData(data: BillingAccountData) {
    return Object.keys(data).map(this.parseBillingAccountDataField(data)).filter(Boolean);
  }

  private static parseBillingAccountDataField(data: BillingAccountData) {
    return (key: keyof BillingAccountData) => {
      const field: { key: keyof BillingAccountData; label?: string; value?: string; displayValue?: string } = { key };
      switch (key) {
        case 'billingAccountName': {
          field.label = billingAccountNameMsg;
          field.value = data.billingAccountName;
          break;
        }
        case 'billingAccountExtensionName': {
          field.label = billingAccountExtensionNameMsg;
          field.value = data.billingAccountExtensionName;
          break;
        }
        case 'billingContactId': {
          field.label = contactInfoMsg;
          field.value = data.billingContactId;
          field.displayValue = data.billingContactName;
          break;
        }
        case 'billElectronicAddress': {
          field.label = eInvoicingAddressMsg;
          field.value = data.billElectronicAddress;
          break;
        }
        case 'billElectronicOperator': {
          field.label = eInvoicingOperatorMsg;
          field.value = data.billElectronicOperator;
          break;
        }
        case 'billLanguage': {
          field.label = invoiceLanguageMsg;
          field.value = data.billLanguage;
          break;
        }
        case 'billReceiverEmail': {
          field.label = emailInvoiceMsg;
          field.value = data.billReceiverEmail;
          break;
        }
        case 'customerReference1': {
          field.label = `${referenceMsg} 1`;
          field.value = data.customerReference1;
          break;
        }
        case 'customerReference2': {
          field.label = `${referenceMsg} 2`;
          field.value = data.customerReference2;
          break;
        }
        case 'deliveryMethod': {
          field.label = deliveryMethodMsg;
          field.value = data.deliveryMethod;
          break;
        }
        case 'payerAddress': {
          field.label = 'Payer address';
          field.value = JSON.stringify(data.payerAddress);
          field.displayValue = getAddressText(data.payerAddress);
          break;
        }
        case 'payerBusinessId': {
          field.label = 'Payer business ID';
          field.value = data.payerBusinessId;
          break;
        }
        case 'payerName': {
          field.label = 'Payer name';
          field.value = data.payerName;
          break;
        }
        case 'payerNameExtension': {
          field.label = payerDetailsMsg;
          field.value = data.payerNameExtension;
          break;
        }
        default: {
          return undefined;
        }
      }
      field.displayValue ??= field.value;
      return field;
    };
  }

  private static prepare(form: OpenForm, answers: OpenFormAnswers, type: OFPageType) {
    const choices = form.getChoices(type).map(c => c.guid);
    const rows = answers.valuesOf('row').reduce((acc: Row, row) => Object.assign(acc, row), {});
    return (props: OpenFormProductSummary) =>
      props.choice && choices.includes(props.choice)
        ? {
            ...props,
            oneOffCharge: parseCurrencyToNumber(
              rows[props.choice]?.[OF.ONE_TIME_CHARGE] ?? String(props.oneOffCharge)
            )!,
            recurringCharge: parseCurrencyToNumber(
              rows[props.choice]?.[OF.RECURRING_CHARGE] ?? String(props.recurringCharge)
            )!,
          }
        : [];
  }

  private static product({ productCode, oneOffCharge, recurringCharge }: OpenFormProductSummary) {
    return {
      productCode: productCode,
      oneOffCharge: String(oneOffCharge),
      recurringCharge: String(recurringCharge),
    };
  }
}

export const useOpenFormSummary = (form: OpenForm, answers: OpenFormAnswers) => {
  const [data, setData] = useState<OpenFormSummary | undefined>(undefined);
  const async = useOpenFormAsync();
  const choices = useMemo(
    () =>
      form
        .getQuestions(OFPageType.QUESTION_MAIN_PRODUCTS, OFPageType.QUESTION_ADDITIONAL_SERVICES)
        .flatMap(q => (!hasQuestionType(OFQuestionType.FREE_TEXT)(q) && answers.get(q.guid)?.choices) || [])
        .join(','),
    [form, answers]
  );

  useEffect(() => {
    if (!choices) {
      return;
    }
    const { ctrl, evict } = new async(({ signal }) => fetchOpenFormSummary(choices.split(','), { signal }))
      .resolved(response => setData(new OpenFormSummary(response, form, answers)))
      .rejected(text => async.actions.notification({ text, type: 'error' }))
      .cache(choices)
      .execute(t.VBS1(loadingProductInformationMsg));

    return () => {
      ctrl.abort();
      evict();
    };
  }, [form, answers, async, choices]);

  return data;
};
