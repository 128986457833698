export interface AuxiliaryEsimOrderProgressProps {
  header: string;
  ingress: string;
}

export const AuxiliaryEsimOrderProgress = ({ header, ingress }: AuxiliaryEsimOrderProgressProps) => {
  return (
    <div className="ea-fgrid ea-fgrid--center">
      <div className="ea-fgrid__item ea-fgrid__item--center ea-fgrid__item--block ea-fgrid__item--column">
        <span className="ea-loading" />
        <h2 className="ea-h2">{header}</h2>
        <h4 className="ea-ingresstext">{ingress}</h4>
      </div>
    </div>
  );
};
