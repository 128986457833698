import { DeliveryMethodAndLanguageSelection } from '../../components/DeliveryMethodAndLanguageSelection.js';
import type * as CL from '@design-system/component-library';

interface BillingAccountPaperDeliveryMethodProps {
  deliveryMethodOptions: CL.DropDownItem[];
  disabled?: boolean;
}

export const BillingAccountPaperDeliveryMethodEdit = ({
  deliveryMethodOptions,
  disabled = false,
}: BillingAccountPaperDeliveryMethodProps) => (
  <DeliveryMethodAndLanguageSelection deliveryMethodOptions={deliveryMethodOptions} disabled={disabled} />
);
