import { useCallback, useRef, useState } from 'react';
import type { MutableRefObject } from 'react';

type Column<Row> = {
  align?: 'left' | 'center' | 'right';
  key: keyof Row;
  label: string | null;
  sortable?: boolean;
  sorted?: 'asc' | 'desc';
  visible?: boolean;
};

type Pagination = { page?: number; size?: number; total?: number };
type Refresh<Row> = (sort?: Column<Row>) => void;
type SetSort<Row> = (sort?: Column<Row>) => void;
type SetView = (next: Pagination) => void;
type View = ReturnType<typeof Table.view>;

class Table {
  static cols<Row>(col?: Column<Row>) {
    return col?.sortable
      ? (cols: Column<Row>[]) => cols.map(c => ((c.key === col.key ? (c.sorted ??= 'asc') : delete c.sorted) && c) || c)
      : (cols: Column<Row>[]) => cols;
  }

  static compare<Row>(key: keyof Row, sorted?: 'asc' | 'desc') {
    return sorted === 'desc'
      ? (a: Row, b: Row) => (a[key] <= b[key] ? 1 : -1)
      : (a: Row, b: Row) => (a[key] >= b[key] ? 1 : -1);
  }

  static rows<Row>(col?: Column<Row>, order?: keyof Row) {
    return col?.sortable && col.key
      ? order
        ? (rows: Row[]) => rows.sort(this.compare(order, col.sorted)).sort(this.compare(col.key, col.sorted))
        : (rows: Row[]) => rows.sort(this.compare(col.key, col.sorted))
      : (rows: Row[]) => rows;
  }

  static sorted<Row>(cols: Column<Row>[]): Column<Row> | undefined {
    return cols.find(({ sorted }) => !!sorted);
  }

  static view({ page, total, size }: Pagination) {
    return {
      get end() {
        return Math.min(this.total, this.page * this.size);
      },
      get page() {
        return Math.min(this.pages, Math.max(1, page || 0));
      },
      get pages() {
        return Math.ceil(this.total / this.size);
      },
      get size() {
        return size || 0;
      },
      get start() {
        return Math.max(0, this.page * this.size - this.size) + 1;
      },
      get total() {
        return total || 0;
      },
    };
  }
}

export const useTable = <Row>({
  columns = [],
  ...props
}: {
  columns?: Column<Row>[];
  order?: keyof Row;
  pagination: Required<Pagination>;
  rows: () => Row[];
}): [Column<Row>[], Row[], MutableRefObject<Refresh<Row>>, SetSort<Row>, View, SetView] => {
  const [cols, setCols] = useState(Table.cols(Table.sorted(columns))(columns));
  const [rows, setRows] = useState(Table.rows(Table.sorted(columns), props.order)(props.rows()));
  const [view, setView] = useState(Table.view(props.pagination));

  return [
    cols,
    rows.slice(view.start - 1, view.end),
    useRef(
      useCallback(
        sort => {
          if ((sort ??= Table.sorted(cols))) {
            setCols(Table.cols(sort)(cols));
            setRows(Table.rows(sort, props.order)(props.rows()));
          }
        },
        [props, cols]
      )
    ),
    useCallback(
      sort => {
        if (sort?.sortable && (sort.sorted = sort.sorted === 'desc' ? 'asc' : 'desc')) {
          setCols(Table.cols(sort)(cols));
          setRows(Table.rows(sort, props.order)(props.rows()));
        }
      },
      [props, cols]
    ),
    view,
    useCallback(next => setView(prev => Table.view({ ...prev, ...next })), []),
  ];
};
