export const tabletItemWidth = (maxItemsAside?: 1 | 2 | 3 | 6) => {
  switch (maxItemsAside) {
    case 1: {
      return 6;
    }
    case 2: {
      return 3;
    }
    case 3: {
      return 2;
    }
    case 6: {
      return 1;
    }
    default: {
      return undefined;
    }
  }
};

export const desktopItemWidth = (maxItemsAside?: 1 | 2 | 3 | 4 | 6 | 12) => {
  switch (maxItemsAside) {
    case 1: {
      return 12;
    }
    case 2: {
      return 6;
    }
    case 3: {
      return 4;
    }
    case 4: {
      return 3;
    }
    case 6: {
      return 2;
    }
    case 12: {
      return 1;
    }
    default: {
      return undefined;
    }
  }
};
