import { EnrollmentProgramType, SapProductCategory } from '../../generated/api/models.js';
import { createAvailability } from '../ProductDetails/utils/formatters.js';
import type { ConfiguredCommercialProduct, ConfiguredOffer } from '../../common/types/commercialProduct.js';
import type { EnrollmentProgram, Offer } from '../../generated/api/models.js';
import type { OnlineModelsState } from '../../common/types/states.js';
import type { ShoppingCartItemForCheckout } from '../../common/types/checkout.js';

export const getOffer = (offerCode: string, onlineModels?: OnlineModelsState): Offer | undefined => {
  const matchedOffers = onlineModels?.items?.flatMap(onlineModel =>
    onlineModel.offers.filter(offer => offer.offerCode === offerCode)
  );
  return matchedOffers?.length ? matchedOffers[0] : undefined;
};

export const isEnrollmentProgramCompatible = (
  ePrograms?: EnrollmentProgram[],
  offerCode?: string,
  onlineModels?: OnlineModelsState
): boolean => {
  const offer = offerCode && onlineModels ? getOffer(offerCode, onlineModels) : undefined;
  if (ePrograms && offer && offer?.enrollmentProgramType) {
    return ePrograms.some(
      program => program.enrollmentProgramExternalId && program.enrollmentProgramSystem === offer.enrollmentProgramType
    );
  }
  return false;
};

export const getApplicableEnrollmentProgram = (offerCode: string, onlineModels?: OnlineModelsState | null) => {
  const offer = offerCode && onlineModels ? getOffer(offerCode, onlineModels) : undefined;
  return offer?.enrollmentProgramType;
};

export const isEnrollmentProgramAliasApplicable = (offerCode: string, onlineModels?: OnlineModelsState | null) =>
  getApplicableEnrollmentProgram(offerCode, onlineModels) === EnrollmentProgramType.KME;
// OFI-14811 - Group the checked and unchecked enrollment program order items respectively and A new Order item will be created
export const getOrderItemWithEnrollmentProgram = (orderItems: ConfiguredOffer[]): ConfiguredOffer[] => {
  const newOrderItems: ConfiguredOffer[] = [];
  orderItems.forEach(item => {
    const enrollmentProgramCheckedCommercialProducts: ConfiguredCommercialProduct[] = [];
    const enrollmentProgramUncheckedCommercialProducts: ConfiguredCommercialProduct[] = [];
    item.selectedCommercialProducts.forEach(product => {
      if (product.enrollmentProgramConsent) {
        enrollmentProgramCheckedCommercialProducts.push(product);
      } else {
        enrollmentProgramUncheckedCommercialProducts.push(product);
      }
    });
    if (enrollmentProgramCheckedCommercialProducts.length > 0) {
      const enrollmentProgramCheckedCommercialProductOffer: ConfiguredOffer = {
        ...item,
        selectedCommercialProducts: enrollmentProgramCheckedCommercialProducts,
      };
      newOrderItems.push(enrollmentProgramCheckedCommercialProductOffer);
    }
    if (enrollmentProgramUncheckedCommercialProducts.length > 0) {
      const enrollmentProgramUncheckedCommercialProductOffer: ConfiguredOffer = {
        ...item,
        selectedCommercialProducts: enrollmentProgramUncheckedCommercialProducts,
      };
      newOrderItems.push(enrollmentProgramUncheckedCommercialProductOffer);
    }
    if (!enrollmentProgramCheckedCommercialProducts.length && !enrollmentProgramUncheckedCommercialProducts.length) {
      newOrderItems.push(item);
    }
  });
  return newOrderItems;
};

export const isCartItemBackordered = (offerCode: string, onlineModels?: OnlineModelsState) => {
  if (!offerCode) {
    return undefined;
  }
  const itemOffer = getOffer(offerCode, onlineModels);
  if (itemOffer) {
    const availability = createAvailability(itemOffer);
    return availability?.isBackordered || false;
  }
  return false;
};

export const isAnyCartItemBackordered = (
  cartItems: Array<ShoppingCartItemForCheckout>,
  onlineModels?: OnlineModelsState
) => {
  if (!cartItems) {
    return undefined;
  }
  return cartItems.some(item => {
    return isCartItemBackordered(item.offerCode, onlineModels);
  });
};

const DIRECT_DELIVERY_CATEGORIES = [SapProductCategory.ZBA1, SapProductCategory.ZBA2];

export const cartContainsDirectDeliveryItems = (
  cartItems: Array<ShoppingCartItemForCheckout>,
  onlineModels?: OnlineModelsState
): boolean =>
  cartItems.some(shoppingCartItem => {
    const sapProductCategory = getOffer(shoppingCartItem.offerCode, onlineModels)?.sapProductCategory;
    return sapProductCategory && DIRECT_DELIVERY_CATEGORIES.includes(sapProductCategory);
  });
