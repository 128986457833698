import { Field } from 'formik';
import { TextAreaComponent } from '../FieldComponents/TextAreaComponent.js';
import { fieldCantBeEmptyMsg, messageOrQuestionMsg, t } from '../../i18n/index.js';
import type { OptionalInputComponentProps } from '../FieldComponents/index.js';

interface Props extends OptionalInputComponentProps {
  maxLength?: number;
}

export const Message = (props: Props) => {
  const translation = t.GOS2(messageOrQuestionMsg);
  const validate = (value: string) => {
    if (props.required && !value) {
      return t.VPVR(fieldCantBeEmptyMsg);
    }
    return undefined;
  };

  return (
    <Field
      name="message"
      label={translation}
      placeholder={translation}
      disabled={false}
      {...props}
      validate={validate}
      component={TextAreaComponent}
    />
  );
};
