import { fixedTermEndsMsg, t } from '../../common/i18n/index.js';
import { formatTimestampToUTCDDMMYYYY } from '../../common/utils/dateUtils.js';
import { isCommitmentPeriodActive, isTellusSubscription } from '../../common/utils/subscriptionUtils.js';
import type { SubscriptionHeader } from '../../generated/api/subscriptionHeader.js';

interface ContractValiditySummaryProps {
  subscriptionHeader: SubscriptionHeader;
}

export const ContractValiditySummary = ({ subscriptionHeader }: ContractValiditySummaryProps) => {
  const { commitmentEndDate, contractEndDate } = subscriptionHeader;
  const endDate = isTellusSubscription(subscriptionHeader) ? contractEndDate : commitmentEndDate;
  if (endDate && isCommitmentPeriodActive(endDate)) {
    const formattedEndDate = formatTimestampToUTCDDMMYYYY(endDate);
    return (
      <span>
        {t.VEGK(fixedTermEndsMsg)} {formattedEndDate}
      </span>
    );
  }
  return <>{t.F1JW('Open-ended')}</>;
};
