/**
 * Online UI API
 * # Summary Elisa B2B Online UI API  # Usage This API is used in the `https://yrityksille.elisa.fi/` pages.  # Identifiers 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Request to validate that the given mobile number is eligible for ordering auxiliary
 */
export interface ValidateAuxiliaryEsimOrderRequest { 
    /**
     * User\'s mobile number
     */
    mobileNumber: string;
    /**
     * Order type
     */
    type: ValidateAuxiliaryEsimOrderRequest.TypeEnum;
}
export namespace ValidateAuxiliaryEsimOrderRequest {
    export type TypeEnum = 'APPLE';
    export const TypeEnum = {
        APPLE: 'APPLE' as TypeEnum
    };
}


