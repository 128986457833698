import { Spinner } from '../../public/site/siteUtils.js';
import { TurbonappiBreadCrumbs } from './TurbonappiBreadCrumbs.js';
import { TurbonappiError } from './TurbonappiError.js';
import { TurbonappiOrderStatus } from '../../generated/api/turbonappiOrderStatus.js';
import { paths } from '../../common/constants/pathVariables.js';
import { postTurbonappiActivation } from '../../common/fetch.js';
import { t } from '../../common/i18n/index.js';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type { ReactNode } from 'react';
import type { TurbonappiOrder } from '../../generated/api/turbonappiOrder.js';

import './TurbonappiOrderActivation.scss';

const NETS_TRANSACTION_ID_PARAMETER = 'transactionId';
const NETS_RESPONSE_CODE_PARAMETER = 'responseCode';
const NETS_CANCEL_VALUE = 'Cancel';

const isOrderFailed = (orderStatus?: TurbonappiOrderStatus) => orderStatus !== TurbonappiOrderStatus.PAID;

const ActivationInProgress = () => (
  <>
    <div>
      <h1>{t.DJ1Y('Please wait a moment without closing the browser. Your order is being activated...')}</h1>
    </div>
    <div className="of-turbonappi-order-activation__spinner">
      <Spinner />
    </div>
  </>
);

const RenderWithBreadCrumbs = ({ component }: { component: ReactNode }) => {
  return (
    <>
      <TurbonappiBreadCrumbs additionalCrumb={true} />
      <div className="of-turbonappi-order-activation">
        <div className="of-turbonappi-order-activation__details">{component}</div>
      </div>
    </>
  );
};

// After user has finished the payment in Nets portal, he/she will be redirected to this URL/component.
// If the transaction id is returned and the order is not cancelled, there will be another backend call made
// where Turbonappi service activation and payment capturing is done.
export const TurbonappiOrderActivation = () => {
  const [turbonappiOrder, setTurbonappiOrder] = useState<TurbonappiOrder>();
  const [serverError, setServerError] = useState(false);
  const navigate = useNavigate();
  const orderFailed = isOrderFailed(turbonappiOrder?.status);

  useEffect(() => {
    const processPayment = async () => {
      const transactionId = new URLSearchParams(window.location.search).get(NETS_TRANSACTION_ID_PARAMETER);
      const responseCode = new URLSearchParams(window.location.search).get(NETS_RESPONSE_CODE_PARAMETER);

      // If the user cancels the payment in the Nets portal the general error message is shown
      if (responseCode === NETS_CANCEL_VALUE) {
        setServerError(true);
        return;
      }

      if (transactionId) {
        try {
          const orderResponse = await postTurbonappiActivation({ transactionId });
          setTurbonappiOrder(orderResponse);
        } catch (error) {
          setServerError(true);
        }
      } else {
        setServerError(true);
      }
    };

    processPayment();
    return;
  }, []);

  useEffect(() => {
    if (turbonappiOrder && !orderFailed && !serverError) {
      navigate(paths.TURBONAPPI_VAHVISTUS, { state: { turbonappiOrder } });
    }
  }, [turbonappiOrder, navigate, orderFailed, serverError]);

  if ((turbonappiOrder && orderFailed) || serverError) {
    return <RenderWithBreadCrumbs component={<TurbonappiError />} />;
  }
  return <RenderWithBreadCrumbs component={<ActivationInProgress />} />;
};
