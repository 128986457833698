import { AuthorizationSuccessfulScene } from '../../../../components/AuthorizationSuccessfulScene/AuthorizationSuccessfulScene.js';
import { Navigate } from 'react-router-dom';
import { paths } from '../../../../common/constants/pathVariables.js';
import { useAuth } from '../../AuthProvider.js';

export const AuthorizedNumberPortingPath = () => {
  const { authorizationRequest } = useAuth();

  if (!authorizationRequest) {
    return <Navigate replace to={paths.NOT_FOUND_404} />;
  }

  return <AuthorizationSuccessfulScene />;
};
