import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

type KeyValuePairs<Type> = {
  [Property in keyof Type]?: string;
};

export const useSearchParams = <T extends KeyValuePairs<T>>(): KeyValuePairs<T> => {
  const { search } = useLocation();
  return useMemo(() => Object.fromEntries(new URLSearchParams(search)), [search]) as KeyValuePairs<T>;
};
