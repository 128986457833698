import { BarringsAccordionContent } from '../../../components/BarringsAccordionContent/BarringsAccordionContent';
import { LinkableAccordion } from '../../../components/LinkableAccordion';
import { Loading } from '../../../components/Loading';
import { barringServicesMsg, t } from '../../../common/i18n';
import { dsClass } from '../../../common/constants/dsClasses';
import type { Accordion } from '../../../common/utils/accordionUtils';
import type { AssociationRecord, DependencyRecord } from '@onlinefirst/cloudsense-add-on-dependency-engine';
import type { Subscription } from '../../../generated/api/subscription';
import type { SubscriptionDetails } from '../../../generated/api/subscriptionDetails';

export interface BarringsAccordionProps {
  addOnAssociations?: AssociationRecord[];
  addOnDependencies?: DependencyRecord[];
  details: SubscriptionDetails;
  forceEditing: boolean;
  onEditSectionIfNoActionsPending: (editingSection?: string) => boolean;
  saving?: boolean;
  subscription: Subscription;
}

const getBarringsContent = ({
  addOnAssociations,
  addOnDependencies,
  details,
  forceEditing,
  onEditSectionIfNoActionsPending,
  saving,
  subscription,
}: BarringsAccordionProps) =>
  addOnAssociations && addOnDependencies ? (
    <BarringsAccordionContent
      addOnAssociations={addOnAssociations}
      addOnDependencies={addOnDependencies}
      details={details}
      forceEditing={forceEditing}
      onEditSectionIfNoActionsPending={onEditSectionIfNoActionsPending}
      saving={Boolean(saving)}
      subscription={subscription}
    />
  ) : (
    <div className={dsClass.PADDING_BOTTOM_4}>
      <Loading />
    </div>
  );

export const BarringsAccordion = (props: BarringsAccordionProps) => {
  if (
    !props.addOnAssociations?.some(
      association =>
        association.cspmb__price_item__c === props.subscription.commercialProductId &&
        association.cspmb__add_on_price_item__r?.guid__c
    )
  ) {
    return null;
  }
  return (
    <LinkableAccordion heading={t.ZUCA(barringServicesMsg)} headingLevel="h3" id="barring-services">
      {' '}
      {getBarringsContent(props)}
    </LinkableAccordion>
  );
};

export const getBarringsAccordion = (props: BarringsAccordionProps): Accordion => ({
  headerName: t.ZUCA(barringServicesMsg),
  id: 'barring-services',
  includedData: getBarringsContent(props),
  displayed: true,
});
