import { EmailVerification } from '../../../../components/EmailVerification/EmailVerification.js';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { verifyEmail } from '../../../../selfservice/actions/index.js';
import type { Token } from '../../../../common/constants/pathInterfaces.js';

export const EmailVerificationPath = () => {
  const dispatch = useDispatch();
  const { token = '' } = useParams<Token>();
  useEffect(() => {
    dispatch(verifyEmail(token));
  }, [dispatch, token]);

  return <EmailVerification />;
};
