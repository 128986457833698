import { TextAreaComponent } from '../components/TextAreaComponent.js';
import { fieldCantBeEmptyMsg, messageOrQuestionMsg, t } from '../../../common/i18n/index.js';
import type { OptionalInputComponentProps } from '../components/InputComponent.js';

export const Message = ({
  name = 'message',
  label = t.GOS2(messageOrQuestionMsg),
  placeholder = t.GOS2(messageOrQuestionMsg),
  required = false,
}: OptionalInputComponentProps) => {
  const validate = (value: string) => {
    if (required && !value) {
      return t.VPVR(fieldCantBeEmptyMsg);
    }
    return undefined;
  };

  return (
    <TextAreaComponent
      validate={validate}
      name={name}
      label={label}
      placeholder={placeholder}
      disabled={false}
      required={required}
    />
  );
};
