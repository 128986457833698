import * as CL from '@design-system/component-library';
import { OnlineOrder } from '../../../generated/api/onlineOrder.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { t } from '../../../common/i18n/index.js';

interface OrderShipmentTypeSelectionProps {
  isEmployee?: boolean;
  isDirectDelivery?: boolean;
  showShipmentType?: boolean;
  backOrdered?: boolean;
  shipmentType: OnlineOrder.ShipmentTypeEnum;
  onShipmentTypeSelection: (orderType: OnlineOrder.ShipmentTypeEnum) => void;
}

interface DirectDeliveryButtonProps {
  isEmployee?: boolean;
  isDirectDelivery?: boolean;
  showShipmentType?: boolean;
  shipmentType: OnlineOrder.ShipmentTypeEnum;
  onShipmentTypeSelection: (orderType: OnlineOrder.ShipmentTypeEnum) => void;
}

interface PartialDeliveryButtonProps {
  shipmentType: OnlineOrder.ShipmentTypeEnum;
  onShipmentTypeSelection: (orderType: OnlineOrder.ShipmentTypeEnum) => void;
}

interface OutOfStockTextProperties {
  isDirectDelivery?: boolean;
}

interface DirectDeliveryProductsInCartTextProperties {
  isDirectDelivery?: boolean;
}

const DirectDeliveryButton = ({
  isEmployee,
  shipmentType,
  isDirectDelivery,
  onShipmentTypeSelection,
}: DirectDeliveryButtonProps) =>
  isDirectDelivery && isEmployee ? null : (
    <CL.Button
      className={`${dsClass.BACKGROUND_COLOR_WHITE} ${
        shipmentType === OnlineOrder.ShipmentTypeEnum.TOTAL_DELIVERY ? 'selected' : ''
      } ${isDirectDelivery && dsClass.COLOR_NEUTRAL_400}`}
      key={OnlineOrder.ShipmentTypeEnum.TOTAL_DELIVERY}
      onClick={() => {
        onShipmentTypeSelection(OnlineOrder.ShipmentTypeEnum.TOTAL_DELIVERY);
      }}
      disabled={isDirectDelivery}
    >
      <span className="of-device-checkout-order-shipment-type__list--icon icon icon-total-delivery" />
      <span className="of-device-checkout-order-shipment-type__list--description">
        {t.EADV('Deliver all products in one batch')}
      </span>
    </CL.Button>
  );

const PartialDeliveryButton = ({ shipmentType, onShipmentTypeSelection }: PartialDeliveryButtonProps) => (
  <CL.Button
    className={`${dsClass.BACKGROUND_COLOR_WHITE} ${
      shipmentType === OnlineOrder.ShipmentTypeEnum.PARTIAL_DELIVERY ? 'selected' : ''
    }`}
    key={OnlineOrder.ShipmentTypeEnum.PARTIAL_DELIVERY}
    onClick={() => {
      onShipmentTypeSelection(OnlineOrder.ShipmentTypeEnum.PARTIAL_DELIVERY);
    }}
    size="l"
  >
    <span className="of-device-checkout-order-shipment-type__list--icon icon icon-partial-delivery" />
    <span className="of-device-checkout-order-shipment-type__list--description">
      {t.Q2QT('Allow partial delivery')}
    </span>
  </CL.Button>
);

const OutOfStockText = ({ isDirectDelivery }: OutOfStockTextProperties) =>
  isDirectDelivery ? null : (
    <CL.Disclaimer
      disclaimerType="warning"
      icon={<CL.Icon icon="information" type="light" />}
      text={`${t.EE7J(
        'Your order contains products that are currently out of the stock. Please select between partial and one-batch delivery options.'
      )} ${t.UORO('Please select between partial and one-batch delivery options.')}`}
      visible
    />
  );

const DirectDeliveryProductsInCartText = ({ isDirectDelivery }: DirectDeliveryProductsInCartTextProperties) =>
  isDirectDelivery ? (
    <CL.Disclaimer
      disclaimerType="warning"
      icon={<CL.Icon icon="information" type="light" />}
      text={t.FX59(
        'One batch delivery is not possible, as some products in the cart are delivered from suppliers warehouse'
      )}
      visible
    />
  ) : null;

export const DeviceCheckoutOrderShipmentTypeSelection = ({
  isEmployee = false,
  shipmentType,
  onShipmentTypeSelection,
  isDirectDelivery,
  backOrdered,
}: OrderShipmentTypeSelectionProps) => (
  <div className={`of-device-checkout-order-shipment-type ${!isEmployee ? dsClass.PADDING_TOP_4 : ''} `}>
    <OutOfStockText isDirectDelivery={isDirectDelivery} />
    <div className="styleguide-grid-item-placeholder">{t.AKD6('Device delivery')}:</div>
    <div className="of-device-checkout-order-shipment-type__list">
      <DirectDeliveryButton
        shipmentType={shipmentType}
        onShipmentTypeSelection={onShipmentTypeSelection}
        isDirectDelivery={isDirectDelivery}
        isEmployee={isEmployee}
      />
      <PartialDeliveryButton onShipmentTypeSelection={onShipmentTypeSelection} shipmentType={shipmentType} />
    </div>
    <DirectDeliveryProductsInCartText isDirectDelivery={isDirectDelivery} />
    {shipmentType === OnlineOrder.ShipmentTypeEnum.TOTAL_DELIVERY && (
      <p className={dsClass.TEXT_S}>{t.MPKA('Your order will be shipped as soons as all products are available.')}</p>
    )}
    {shipmentType === OnlineOrder.ShipmentTypeEnum.PARTIAL_DELIVERY && backOrdered && (
      <p className={dsClass.TEXT_S}>
        {t.DQDK('Products will be delivered as soon as they are available. Delivery fee will be charged only once.')}
      </p>
    )}
  </div>
);
