import * as CL from '@design-system/component-library';
import { backMsg, monthMsg, t } from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { getCatalogToBeDisplayed } from '../../common/utils/catalogUtils.js';
import type { CatalogHeader } from '../../generated/api/catalogHeader.js';
import type { CompanyVirtualCatalogs } from './VirtualCatalogReplaceUtils.js';
import type { VirtualCatalogReplaceResponse } from '../../generated/api/virtualCatalogReplaceResponse.js';

import './VirtualCatalogReplaceError.scss';

export interface VirtualCatalogReplaceErrorProps {
  replaceErrorResponse: VirtualCatalogReplaceResponse;
  companyVirtualCatalogs: CompanyVirtualCatalogs[];
}

export const VirtualCatalogReplaceError = ({
  replaceErrorResponse,
  companyVirtualCatalogs,
}: VirtualCatalogReplaceErrorProps) => {
  const allCatalogs = companyVirtualCatalogs
    .map(c => c.virtualCatalogs)
    .flatMap(c => c)
    .map(u => getCatalogToBeDisplayed(u));

  const failedCatalogs = allCatalogs.filter(
    catalog => catalog?.catalogCode && replaceErrorResponse.failed.includes(catalog.catalogCode)
  );

  const getCatalogNameText = (catalog?: CatalogHeader) => {
    return catalog?.contractPeriod
      ? `${catalog?.name} (${catalog.contractPeriod} ${t.XXVX(monthMsg)})`
      : `${catalog?.name}`;
  };

  return (
    <div>
      <h3>
        {failedCatalogs.length} {t.NVVN('device lists failed to copy:')}
      </h3>
      <div>
        {t.YMJF(
          'Following device lists failed to copy. Products were not copied. Check and edit separately the failed device list:'
        )}
      </div>
      <div className={`of-catalog-replace ${dsClass.PADDING_TOP_4} ${dsClass.PADDING_LEFT_4}`}>
        <ul>
          {failedCatalogs.map(catalog => {
            return <li key={catalog?.catalogCode}>{getCatalogNameText(catalog)}</li>;
          })}
        </ul>
      </div>
      <div className={dsClass.PADDING_TOP_4}>
        <CL.Button onClick={() => window.history.back()}>{t.D1E9(backMsg)}</CL.Button>
      </div>
    </div>
  );
};
