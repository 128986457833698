import { HeaderSectionContent, SectionContent } from '../SectionContent.js';
import { SearchResultRow } from '../SearchResultRow.js';
import { billingAccountMsg, t } from '../../../common/i18n/index.js';
import { generatePath } from 'react-router-dom';
import { getResultObjectWithHighlightedValues } from '../highlightFunctions.js';
import { paths } from '../../../common/constants/pathVariables.js';
import type { SearchResultBaseProps } from '../SearchResultRow.js';
import type { SubscriptionHeader } from '../../../generated/api/subscriptionHeader.js';

export interface DomainSubscriptionProps extends SearchResultBaseProps {
  subscription: SubscriptionHeader;
}

export const DomainSubscription = ({ subscription, matchedFields, searchTerms, ...rest }: DomainSubscriptionProps) => {
  const { subscriptionId, subscriptionName, billingAccountDisplayId, oldContractId } =
    getResultObjectWithHighlightedValues(subscription, matchedFields, searchTerms);

  return (
    <SearchResultRow
      icon="globe"
      upperSection={
        <HeaderSectionContent
          left={subscriptionName || ''}
          right={
            subscriptionId ? (
              <>
                {t.YTH3('ID Number')}: {subscriptionId}
              </>
            ) : (
              ''
            )
          }
        />
      }
      lowerSection={
        <SectionContent
          left={
            oldContractId ? (
              <>
                {t.Z223('Billing code')}: {oldContractId}
              </>
            ) : (
              ''
            )
          }
          right={
            billingAccountDisplayId ? (
              <>
                {t.IFT9(billingAccountMsg)}: {billingAccountDisplayId}
              </>
            ) : (
              ''
            )
          }
        />
      }
      matchedFields={matchedFields}
      linkHref={generatePath(paths.PS_ELISA_DNS_DOMAIN, { subscriptionId })}
      {...rest}
    />
  );
};
