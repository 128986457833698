import { AddressSearch } from '../../../AddressSearch/AddressSearch.js';
import { type OpenFormAnswers } from '../../OpenFormAnswers.js';
import { type OpenFormDispatcher } from '../../OpenFormHooks/useOpenFormDispatcher.js';
import { OpenFormGridCol, OpenFormGridRow } from '../../OpenFormComponents/OpenFormGrid.js';
import { deepEqual } from '../../../../common/utils/objectUtils.js';
import { fetchOpenFormAvailability } from '../../../../common/fetch.js';
import { getAddressSearchResult } from '../../OpenFormUtils.js';
import { loadingProductAvailabilityMsg, t } from '../../../../common/i18n/index.js';
import { useCallback } from 'react';
import { useOpenFormAsync } from '../../OpenFormProvider.js';
import { useSelector } from 'react-redux';
import type { OpenFormSection } from '../../../../generated/api/openFormSection.js';
import type { State } from '../../../../selfservice/common/store.js';

export const OpenFormPageAvailability = ({
  dispatcher,
  answers,
  section,
  disabled,
  next,
}: {
  dispatcher: OpenFormDispatcher;
  answers: OpenFormAnswers;
  section: OpenFormSection;
  disabled: boolean;
  next: () => void;
}) => {
  const async = useOpenFormAsync();
  const result =
    useSelector((state: State) => state?.resources?.addressSearchResult, deepEqual) ??
    getAddressSearchResult(answers.get(section.guid)?.address);

  return (
    <OpenFormGridRow className="of-openform__address-check">
      <OpenFormGridCol colWidth={5} className="of-openform__address-check__field-container label--mandatory">
        <AddressSearch
          addressSearchResult={result}
          disabled={disabled}
          heading={<h3>{section.description}</h3>}
          initialPostalCodeValue={result?.postalCode}
          initialStreetAddressValue={result?.match?.addressText}
          onSubmit={useCallback(() => {
            if (result?.match && async.state.form) {
              const { addressId, addressText } = result.match;
              const { formId } = async.state.form;
              const subscriptionTypes = answers.valuesOf('subscriptionTypes').flat();

              new async(() => fetchOpenFormAvailability({ addressId, formId, subscriptionTypes }))
                .resolved(({ address, products }) => {
                  dispatcher.setContext('address', {
                    addressId: address.addressId,
                    addressText: addressText,
                    countryCode: address.countryCode,
                    line1: [address.streetName, address.streetNumber].filter(Boolean).join(' '),
                    line2: [address.stairLetter, address.apartmentNumber].filter(Boolean).join(' '),
                    postalCode: address.postalCode,
                    postOffice: address.postalOfficeName,
                    products: Object.fromEntries(products.map(p => [p.choice, p.isAvailable])),
                  });
                  next();
                })
                .rejected(text => async.actions.notification({ text, type: 'error' }))
                .cache(addressId, formId, String(subscriptionTypes))
                .execute(t.UR0I(loadingProductAvailabilityMsg));
            }
          }, [dispatcher, answers, next, async, result])}
        />
      </OpenFormGridCol>
      <OpenFormGridCol colWidth={4} className="of-openform__address-check__image-container">
        <div aria-disabled={disabled} className="of-openform__address-check__image-container--picture" />
      </OpenFormGridCol>
    </OpenFormGridRow>
  );
};
