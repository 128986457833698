import * as React from 'react';
import classNames from 'classnames';

import './ErrorTooltip.scss';

export type ErrorTooltipProps = React.PropsWithChildren<{
  overlay?: boolean;
  placement?: 'top' | 'bottom';
}>;

export const ErrorTooltip = ({ children, overlay = false, placement = 'top' }: ErrorTooltipProps) => (
  <div className="of-error-tooltip">
    <div
      className={classNames(
        'ea-notification ea-notification--tooltip ea-notification--error',
        `of-error-notification--${placement}`,
        {
          /* eslint-disable @typescript-eslint/naming-convention */
          'ea-notification--tooltip--tip-top': placement === 'bottom',
          'of-error-tooltip--overlay': overlay,
          /* eslint-enable @typescript-eslint/naming-convention */
        }
      )}
    >
      <span className="ea-notification__text">{children}</span>
    </div>
  </div>
);
