import { TypeKeys, generateActionId } from './actionsUtils.js';
import { convertToCommonErrors } from '../../common/utils/errorUtils.js';
import type { Action } from 'redux';
import type { AjaxResponse } from 'rxjs/ajax';
import type { Contract, ContractsResponse, GenericError } from '../../generated/api/models.js';
import type { CrudAction, ErrorAction } from './actionsUtils.js';

export type SubmitMobileIdContractAction = CrudAction<TypeKeys.SUBMIT_MOBILEID_CONTRACT>;

export interface SubmitMobileIdContractFulfilledAction extends Action {
  type: TypeKeys.SUBMIT_MOBILEID_CONTRACT_FULFILLED;
  statusCode?: number;
}

export type SubmitMobileIdContractFailedAction = ErrorAction<TypeKeys.SUBMIT_MOBILEID_CONTRACT_FAILED>;

export interface TerminateMobileIdContractAction extends CrudAction<TypeKeys.TERMINATE_MOBILEID_CONTRACT> {
  contractNumber: string;
}

export interface TerminateMobileIdContractFulfilledAction extends Action {
  type: TypeKeys.TERMINATE_MOBILEID_CONTRACT_FULFILLED;
  statusCode?: number;
}

export type TerminateMobileIdContractFailedAction = ErrorAction<TypeKeys.TERMINATE_MOBILEID_CONTRACT_FAILED>;

export interface GetMobileIdContractsAction extends CrudAction<TypeKeys.GET_MOBILEID_CONTRACTS> {
  contractNumber?: string;
}

export interface GetMobileIdContractsFulfilledAction extends Action {
  type: TypeKeys.GET_MOBILEID_CONTRACTS_FULFILLED;
  total: number;
  mobileidContracts?: Contract[];
}

export type GetMobileIdContractsFailedAction = ErrorAction<TypeKeys.GET_MOBILEID_CONTRACTS_FAILED>;

export const submitMobileIdContract = (): SubmitMobileIdContractAction => ({
  id: generateActionId(),
  type: TypeKeys.SUBMIT_MOBILEID_CONTRACT,
});

export const submitMobileIdContractFulfilled = (response: AjaxResponse): SubmitMobileIdContractFulfilledAction => {
  return {
    type: TypeKeys.SUBMIT_MOBILEID_CONTRACT_FULFILLED,
    statusCode: response.status,
  };
};

export const submitMobileIdContractFailed = (
  message: string,
  status: number,
  errors?: GenericError[]
): SubmitMobileIdContractFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    type: TypeKeys.SUBMIT_MOBILEID_CONTRACT_FAILED,
  };
};

export const terminateMobileIdContract = (contractNumber: string): TerminateMobileIdContractAction => ({
  id: generateActionId(),
  type: TypeKeys.TERMINATE_MOBILEID_CONTRACT,
  contractNumber,
});

export const terminateMobileIdContractFulfilled = (
  response: AjaxResponse
): TerminateMobileIdContractFulfilledAction => {
  return {
    type: TypeKeys.TERMINATE_MOBILEID_CONTRACT_FULFILLED,
    statusCode: response.status,
  };
};

export const terminateMobileIdContractFailed = (
  message: string,
  status: number,
  errors?: GenericError[]
): TerminateMobileIdContractFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    type: TypeKeys.TERMINATE_MOBILEID_CONTRACT_FAILED,
  };
};

export const getMobileIdContracts = (contractNumber?: string): GetMobileIdContractsAction => ({
  id: generateActionId(),
  type: TypeKeys.GET_MOBILEID_CONTRACTS,
  contractNumber: contractNumber,
});

export const getMobileIdContractsFulfilled = (response: ContractsResponse): GetMobileIdContractsFulfilledAction => {
  return {
    type: TypeKeys.GET_MOBILEID_CONTRACTS_FULFILLED,
    total: response.total,
    mobileidContracts: response.contracts,
  };
};

export const getMobileIdContractsFailed = (
  message: string,
  status: number,
  errors?: GenericError[]
): GetMobileIdContractsFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    type: TypeKeys.GET_MOBILEID_CONTRACTS_FAILED,
  };
};

export type MobileIdContractActionTypes =
  | SubmitMobileIdContractAction
  | SubmitMobileIdContractFulfilledAction
  | SubmitMobileIdContractFailedAction
  | TerminateMobileIdContractAction
  | TerminateMobileIdContractFulfilledAction
  | TerminateMobileIdContractFailedAction
  | GetMobileIdContractsAction
  | GetMobileIdContractsFulfilledAction
  | GetMobileIdContractsFailedAction;
