/**
 * SFDC Anonymous API
 * # Summary Elisa B2B API for an anonymous access to salesforce data.  # Usage This API is used in the `https://yrityksille.elisa.fi/omaelisa` pages, but it is possible to later on expose this documentation publicly and make the endpoint queryable via external clients. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OfferBadge { 
    /**
     * Status of offer badge
     */
    active?: boolean;
    /**
     * Text to show user in a badge
     */
    text: string;
    /**
     * Type for choosing badge color
     */
    type: OfferBadge.TypeEnum;
}
export namespace OfferBadge {
    export type TypeEnum = 'SALE' | 'SPECIAL' | 'OUT_OF_STOCK';
    export const TypeEnum = {
        SALE: 'SALE' as TypeEnum,
        SPECIAL: 'SPECIAL' as TypeEnum,
        OUT_OF_STOCK: 'OUT_OF_STOCK' as TypeEnum
    };
}


