import { SubscriptionsBroadband } from '../../../../../components/Subscriptions/SubscriptionsBroadband.js';
import { SubscriptionsLayout } from '../../../../../components/SubscriptionsLayout/SubscriptionsLayout.js';
import { internetSubscriptionsMsg, omaElisaForCompaniesMsg, t } from '../../../../../common/i18n/index.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useState } from 'react';
import type { CommonError } from '../../../../../common/types/errors.js';

export const BroadbandSubsPath = () => {
  const [errors] = useState<CommonError[]>([]);

  return (
    <SubscriptionsLayout
      breadCrumbs={[
        { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
        { name: t.SEYV(internetSubscriptionsMsg) },
      ]}
      errors={errors}
    >
      <SubscriptionsBroadband />;
    </SubscriptionsLayout>
  );
};
