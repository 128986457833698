import * as CL from '@design-system/component-library';
import { EmployeeHomeScene } from '../../../../components/EmployeeHomeScene/EmployeeHomeScene.js';
import { Link, useNavigate } from 'react-router-dom';
import { Picture } from '../../../../components/Picture/Picture.js';
import { deepEqual } from '../../../../common/utils/objectUtils.js';
import { getPublishedCatalogs } from '../../../../common/utils/catalogUtils.js';
import { isUserDetailsMissing } from '../../../../components/Employee/Employee.js';
import {
  loadCompanyInfo,
  loadCustomerOrders,
  loadSubscriptionActions,
  loadVirtualCatalogs,
} from '../../../../selfservice/actions/index.js';
import { paths } from '../../../../common/constants/pathVariables.js';
import { pictureStorageBaseUrl } from '../../../../components/Picture/contentfulImageProxy.js';
import {
  showCustomerOrdersHint,
  showSubscriptionActionHint,
  userHasApproverRole,
} from '../../../../common/utils/employeeUtils.js';
import { t } from '../../../../common/i18n/index.js';
import { useAuth } from '../../AuthProvider.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import type { State } from '../../../../selfservice/common/store.js';

import './EmployeeHomePath.scss';

const TurbonappiBanner = () => (
  <div className="of-employee-home__turbonappi-banner">
    <CL.Link linkHref="https://yrityksille.elisa.fi/turbonappi" linkTarget="_blank">
      <Picture
        alt="Lisävauhtia liittymääsi. Turbonappi tuo lisävauhtia niin töihin, kuin vapaa-aikaan. Kokeile ja huomaa ero! Tutustu palveluun."
        imgClasses="of-employee-home__turbonappi-banner__image"
        offerWidthAlternatives={[1080, 1280]}
        renderedImageSize={{
          onPhone: '100vw',
          onLaptop: '100vw',
        }}
        src={`${pictureStorageBaseUrl}5PqaGEr7DEgsvEb5uGd1hl/i.png`}
      />
    </CL.Link>
  </div>
);

export const EmployeeHomePath = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { authenticatedUser } = useAuth();
  const { customerOrders, subscriptionActions, virtualCatalogItems } = useSelector(
    (state: State) => ({
      customerOrders: state.selfservice?.customerOrders,
      subscriptionActions: state.selfservice?.subscriptionActions,
      virtualCatalogItems: state.selfservice?.virtualCatalogs?.items,
    }),
    deepEqual
  );
  const isCatalogPresent = virtualCatalogItems ? (getPublishedCatalogs(virtualCatalogItems) || []).length > 0 : false;
  const hasAcceptorRole = userHasApproverRole(authenticatedUser);
  const isLinkedWithAccount = Boolean(authenticatedUser?.businessId);
  const showPendingSubscriptionsHint = showSubscriptionActionHint(subscriptionActions);
  const showUpdateUserInfoHint = isUserDetailsMissing(authenticatedUser);
  const showPendingCustomerOrdersHint = showCustomerOrdersHint(customerOrders);
  const showExpiringEppSubscriptionHint = Boolean(authenticatedUser?.expiringEppSubscriptions?.length);

  useEffect(() => {
    if (authenticatedUser?.businessId) {
      dispatch(loadCompanyInfo());
      dispatch(loadVirtualCatalogs());
      // but not this -> As always load both orders and actions on orders, needed for indicator in the tab
      if (userHasApproverRole(authenticatedUser)) {
        dispatch(loadSubscriptionActions({ forceLoad: true }));
        dispatch(loadCustomerOrders(undefined, undefined, undefined, undefined, false, true));
      }
    }
  }, [dispatch, authenticatedUser, navigate]);

  return (
    <>
      <EmployeeHomeScene
        isLinkedWithAccount={isLinkedWithAccount}
        firstName={authenticatedUser?.firstName || ''}
        isCatalogPresent={isCatalogPresent}
        hasApproverRole={hasAcceptorRole}
        showUpdateUserInfoHint={showUpdateUserInfoHint}
        showExpiringEppSubscriptionHint={showExpiringEppSubscriptionHint}
        showPendingApprovalsHint={showPendingSubscriptionsHint || showPendingCustomerOrdersHint}
      />
      <TurbonappiBanner />
      {!isLinkedWithAccount && (
        <div className="of-employee__guide-notification-container">
          <div className="ea-context-notification">
            <div className="ea-context-notification__text">
              <h4 className="ea-h4">{t.I2YS('Important information')}</h4>
              {t.T58Y('Your profile is not associated with any company or business yet. You can either ')}
              <Link to={paths.EMPLOYEE_SUBSCRIPTIONS} rel="noopener noreferrer">
                {t.PDYE('link a company provided subscription ')}
              </Link>
              {t.TQFU('or ask for invitation link from your company admin to sign up to this portal.')}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
