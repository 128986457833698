import * as CL from '@design-system/component-library';
import { closeMsg, t } from '../../common/i18n/index.js';
import React, { useEffect, useId, useRef } from 'react';
import classNames from 'classnames';

import './Modal.scss';

type ModalBaseProps = {
  /* In some cases we know that whatever opened the modal is at the top, so we
     might as well show the modal there. Defaults to center. */
  align?: 'center' | 'top';
  className?: string;
  heading?: string;
  // This is provided wnith whatever event caused the close
  onClose?: (e: React.MouseEvent<HTMLButtonElement> | React.SyntheticEvent<HTMLDialogElement>) => void;
  // TODO do we actually need these sizes? What's wrong with just letting the modal size itself?
  // maybe limit to normal & fullscreen? what did wide do in the old one?
  /* Unlike CL.Modal, we don't have a default size. If no size is defined, the
     modal content defines the modal size (on non-mobile viewports). */
  size?: 's' | 'm' | 'l' | 'xl';
} & React.PropsWithChildren;

type NonCloseableModalProps = {
  /* Do we show a close button & allow closing with ESC or by clicking the BG?
     Also sets the dialog role to alertdialog if false. */
  closeable?: false;
  /* This is provided with whatever event caused the close. Optional for
     non-closeable modals as there shouldn't be anything that would call it. */
  onClose?: (
    e: React.MouseEvent<HTMLDivElement> | React.MouseEvent<HTMLButtonElement> | React.SyntheticEvent<HTMLDialogElement>
  ) => void;
} & ModalBaseProps;

type CloseableModalProps = {
  closeable: true;
  // This is provided with whatever event caused the close
  onClose: (
    e: React.MouseEvent<HTMLDivElement> | React.MouseEvent<HTMLButtonElement> | React.SyntheticEvent<HTMLDialogElement>
  ) => void;
  // If this is moved to CL, i18n_modal_closeButtonTitle should be placed here.
} & ModalBaseProps;

export type ModalProps = CloseableModalProps | NonCloseableModalProps;

export const MODAL_BASE_CLASS = 'of-modal';
export const MODAL_BACKDROP_CLASS = `${MODAL_BASE_CLASS}__backdrop`;
export const MODAL_CLOSE_BUTTON_CLASS = `${MODAL_BASE_CLASS}__close-button`;
export const MODAL_WRAPPER_CLASS = `${MODAL_BASE_CLASS}__wrapper`;
export const MODAL_HEADING_CLASS = `${MODAL_BASE_CLASS}__heading`;
export const MODAL_CONTENT_CLASS = `${MODAL_BASE_CLASS}__content`;
export const MODAL_BUTTONS_CLASS = `${MODAL_BASE_CLASS}__buttons`;

const CloseButton = ({
  dialogId,
  onClose,
}: Pick<CloseableModalProps, 'onClose'> & {
  dialogId: string;
}) => {
  return (
    <button
      aria-controls={dialogId}
      aria-label={t.WOYD(closeMsg)}
      className={MODAL_CLOSE_BUTTON_CLASS}
      onClick={onClose}
      title={t.WOYD(closeMsg)}
      type="button"
    >
      <CL.Icon aria-hidden="true" icon="close" type="regular" />
    </button>
  );
};

/**
 * Modal implemented with the native HTML dialog element.
 *
 * If the Modal isn't closed 'natively' (i.e. open changed to false), focus
 * isn't returned to opener: returning focus to wherever it came from is the
 * responsibility of the parent.
 *
 * If a <form method="dialog" is inserted into the dialog and then submitted,
 * the dialog will close in an uncontrolled fashion. There's no way that I know
 * of to block this: a close event is fired, but it can't be prevented/blocked.
 * The onClose handler can be used to mitigate this issue (i.e. the parent can
 * remove the modal within the handler).
 * @constructor
 */
export const Modal = ({
  align = 'center',
  children,
  className,
  closeable,
  heading,
  onClose,
  size = 'm',
}: ModalProps) => {
  const dialogId = useId();
  const headingId = useId();
  const contentId = useId();
  const dialogRef = useRef<HTMLDialogElement>(null);
  const closeDialogOnBackdropClick = (
    e: React.MouseEvent<HTMLDivElement> | React.MouseEvent<HTMLButtonElement> | React.SyntheticEvent<HTMLDialogElement>
  ) => {
    if (closeable) {
      onClose(e);
    }
  };

  useEffect(() => {
    dialogRef.current?.showModal();
  }, [dialogRef]);

  return (
    <dialog
      aria-describedby={contentId}
      aria-labelledby={headingId}
      className={classNames(
        MODAL_BASE_CLASS,
        `${MODAL_BASE_CLASS}--align-${align}`,
        size && `${MODAL_BASE_CLASS}--size-${size}`,
        className
      )}
      id={dialogId}
      onCancel={e => {
        /* We don't want ESC to close the modal by itself, instead we tell the
           parent about it (if the modal is closeable). */
        e.preventDefault();
        if (closeable) {
          onClose(e);
        }
      }}
      onClose={
        /*
          Modal was closed, probably by a <form method="dialog"> or by someone
          calling .close() on it.
          This can't be blocked (the event is actually fired _after_ the modal
          has closed), so call the onClose handler and let the parent handle
          the situation as they see fit.
        */
        onClose
      }
      ref={dialogRef}
      {...(closeable ? {} : { role: 'alertdialog' })}
    >
      <div className={MODAL_BACKDROP_CLASS} onClick={closeDialogOnBackdropClick} role="presentation"></div>
      <div className={MODAL_WRAPPER_CLASS}>
        {/*
        Browsers move focus inside the opened dialog, usually to the first
        sequentially focusable element inside it (implementations vary).
        Having this as the first item makes sure the browser doesn't focus an
        input or something like that, causing the top of the modal to scroll
        away from view.
        */}
        {closeable && <CloseButton dialogId={dialogId} onClose={onClose} />}
        <h2 className={MODAL_HEADING_CLASS} id={headingId}>
          {heading}
        </h2>
        <div className={MODAL_CONTENT_CLASS} id={contentId}>
          {children}
        </div>
      </div>
    </dialog>
  );
};
