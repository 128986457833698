import { TypeKeys, authValidate } from '../actions/index.js';
import { actionToActionState, getError } from './epicUtils.js';
import { catchError, concatMap, filter, map, mergeMap } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import {
  elisaIdLogOutFailed,
  elisaIdLogOutFulfilled,
  getElisaIdAccessTokenFailed,
  getElisaIdAccessTokenFulfilled,
  getElisaIdSessionFailed,
  getElisaIdSessionFulfilled,
  processElisaIdLogin,
} from '../actions/employeeActions.js';
import { from, of } from 'rxjs';
import { prepareUiApiRequest } from '../common/uiApiUtils.js';
import type { Action } from 'redux';
import type { ActionAndState, EpicDependencies } from './epicUtils.js';
import type { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import type {
  ElisaIdLogOutAction,
  GetElisaIdAccessTokenAction,
  GetElisaIdAccessTokenFulfilledAction,
  GetElisaIdSessionAction,
  GetElisaIdSessionFulfilledAction,
} from '../actions/employeeActions.js';
import type { ErrorAction, ErrorActionCreator, SelfServiceActionTypes } from '../actions/index.js';
import type { Observable } from 'rxjs';
import type { State } from '../common/store.js';

interface ElisaIdClientRequest<A, R> {
  calleeFunction: (param?: object) => Promise<unknown>;
  failureAction: ErrorActionCreator<TypeKeys>;
  successAction: (response: R) => A;
  failureParams?: { [s: string]: string };
  calleeFunctionParams?: object;
}

const makeElisaIdClientCalls = <A, R>(
  elisaIdClientRequest: ElisaIdClientRequest<A, R>
): Observable<A | ErrorAction<TypeKeys>> => {
  return from(elisaIdClientRequest.calleeFunction(elisaIdClientRequest.calleeFunctionParams)).pipe(
    map(elisaIdClientRequest.successAction),
    catchError(ajaxError => getError(ajaxError, elisaIdClientRequest.failureAction, elisaIdClientRequest.failureParams))
  );
};

// Get Elisa Id Session
export const getElisaIdSessionEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.GET_ELISA_ID_SESSION)), (action: GetElisaIdSessionAction) =>
    actionToActionState(action, state$, 'anonymous')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const getElisaIdSessionAction = actionAndState.action as GetElisaIdSessionAction;
      if (!actionAndState.state && !getElisaIdSessionAction.payload) {
        throw new Error('invalid action state for elisa ID Login, userName is missing');
      }
      return makeElisaIdClientCalls({
        calleeFunction: getElisaIdSessionAction.payload.session,
        failureAction: getElisaIdSessionFailed,
        successAction: (ajaxResponse: { accountId: string; mobileId: string }) =>
          getElisaIdSessionFulfilled(ajaxResponse.accountId || ajaxResponse.mobileId, getElisaIdSessionAction.payload),
      });
    })
  );

export const getElisaIdAccessTokenEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.GET_ELISA_ID_ACCESS_TOKEN)), (action: GetElisaIdAccessTokenAction) =>
    actionToActionState(action, state$, 'anonymous')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const getElisaIdAccessTokenAction = actionAndState.action as GetElisaIdAccessTokenAction;
      if (!actionAndState.state && !getElisaIdAccessTokenAction.payload) {
        throw new Error('invalid action state for elisa ID Login, userName is missing');
      }
      return makeElisaIdClientCalls({
        calleeFunction: getElisaIdAccessTokenAction.payload.accessToken,
        failureAction: getElisaIdAccessTokenFailed,
        successAction: (ajaxResponse: { token: string }) =>
          getElisaIdAccessTokenFulfilled(ajaxResponse.token, getElisaIdAccessTokenAction.payload),
      });
    })
  );

// Logout elisa Id session
export const elisaIdLogOutEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.ELISA_ID_LOGOUT)), (action: ElisaIdLogOutAction) =>
    actionToActionState(action, state$, 'anonymous')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const elisaIdLogOutAction = actionAndState.action as ElisaIdLogOutAction;
      if (!actionAndState.state && !elisaIdLogOutAction.payload) {
        throw new Error('invalid action state for elisa ID Login, userName is missing');
      }
      return makeElisaIdClientCalls({
        calleeFunction: elisaIdLogOutAction.payload.logout,
        failureAction: elisaIdLogOutFailed,
        successAction: () => elisaIdLogOutFulfilled(),
      });
    })
  );

export const completeGetElisaIdAccessTokenEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>
) =>
  action$.ofType(TypeKeys.GET_ELISA_ID_ACCESS_TOKEN_FULFILLED).pipe(
    mergeMap((action: GetElisaIdAccessTokenFulfilledAction) => {
      return of(processElisaIdLogin(action.token));
    })
  );

export const completeGetSessionDetailsEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>
) =>
  action$.pipe(
    ofType(TypeKeys.GET_ELISA_ID_SESSION_FULFILLED),
    filter(
      () =>
        !state$.value.user ||
        !state$.value.user.authenticated ||
        !state$.value.user.authenticated.userName ||
        state$.value.user.authenticated.errors !== undefined
    ),
    map((action: GetElisaIdSessionFulfilledAction) => {
      return authValidate(action.elisaIdClient);
    })
  );

export const elisaIdEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = combineEpics(
  getElisaIdSessionEpic,
  getElisaIdAccessTokenEpic,
  elisaIdLogOutEpic,
  completeGetSessionDetailsEpic,
  completeGetElisaIdAccessTokenEpic
);
