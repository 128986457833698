import { Picture } from '../Picture/Picture';
import type { Offer } from '../../generated/api/offer';
import type { OnlineModel } from '../../generated/api/onlineModel';

export const getProductImage = (
  imageUrl: string,
  productName: string,
  offer?: Offer,
  model?: OnlineModel
): JSX.Element => {
  return offer && model ? (
    <Picture
      alt={offer.offerName}
      offerWidthAlternatives={[128]}
      renderedImageSize={{ onPhone: '64px' }}
      src={offer.images && offer.images.length > 0 ? offer.images[0] : model?.listingImage || imageUrl}
    />
  ) : (
    <Picture alt={productName} offerWidthAlternatives={[128]} renderedImageSize={{ onPhone: '64px' }} src={imageUrl} />
  );
};

export const ERROR_BANNER_ID = 'of-shopping-basket-error-banner';
