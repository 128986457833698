import { OF, hasFieldApiName } from '../../OpenForm.js';
import { OpenFormGridCol, OpenFormGridRow } from '../../OpenFormComponents/OpenFormGrid.js';
import { OpenFormItem } from '../../OpenFormComponents/OpenFormItem.js';
import { type OpenFormSummary } from '../../OpenFormHooks/useOpenFormSummary.js';
import { orderingContactMsg, t } from '../../../../common/i18n/index.js';

export const OpenFormSummaryOrderingContact = ({ summary }: { summary?: OpenFormSummary }) => {
  const address = summary?.installationAddress;
  const contact = summary?.contactEntries?.find(([question]) => hasFieldApiName(OF.ORDERING_CONTACT)(question))?.[1];
  return !address && !contact ? null : (
    <OpenFormGridRow>
      <OpenFormGridCol colWidth={9}>
        <h4>{t.IY5V(orderingContactMsg)}</h4>
        <OpenFormGridRow>
          <OpenFormGridCol>
            <OpenFormItem>
              {!contact ? null : (
                <>
                  <div>
                    {contact.firstName} {contact.lastName}
                  </div>
                  <div>{contact.email}</div>
                  <div>{contact.phoneNumber}</div>
                </>
              )}
              {!address ? null : (
                <>
                  <div>{[address.line1, address.line2].filter(Boolean).join(' ')}</div>
                  <div>
                    {address.postalCode} {address.postOffice}
                  </div>
                </>
              )}
            </OpenFormItem>
          </OpenFormGridCol>
        </OpenFormGridRow>
        <hr />
      </OpenFormGridCol>
    </OpenFormGridRow>
  );
};
