import * as CL from '@design-system/component-library';
import { AddOnExternalLink } from '../SubscriptionAdditionalService/AddOnExternalLink.js';
import { CheckboxAdditionalInformation } from '../SubscriptionAdditionalService/CheckboxAdditionalInformation.js';
import { SubscriptionAdditionalService } from '../SubscriptionAdditionalService/SubscriptionAdditionalService.js';
import { ToggleCheckbox } from '../ToggleCheckbox/ToggleCheckbox.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import {
  getAddOnPrice,
  getAddOnStatus,
  getMobileVoiceLanguageOptions,
  isAddonCheckboxEnabled,
  isItemChecked,
  isMobileVoiceAddOn,
} from './addOnDependencyUtils.js';
import { getAddonLinkUrl } from '../SubscriptionAdditionalService/subscriptionAddOnUtils.js';
import { t } from '../../common/i18n/index.js';
import type { AddOnEditabilityRecord, AddOnStatusInfo } from './addOnDependencyUtils.js';
import type { AddOnVisibility } from '../../generated/api/addOnVisibility.js';
import type { AdditionalServicesContentProps } from './AdditionalServicesAccordionContent.js';

type AddOnRowProps = Pick<AdditionalServicesContentProps, 'isAssetWithMobiiliturva' | 'isEmployee' | 'baseRules'> & {
  addOnVisibility: AddOnVisibility;
  showEditView: boolean;
  editabilityRecords: AddOnEditabilityRecord[];
  subscriptionAddOnCodes: string[];
  addOnCodesToAdd: string[];
  addOnCodesToRemove: string[];
  handleAddOnSelectionChange: (checked: boolean, addOnVisibility: AddOnVisibility) => void;
  additionalServicePath?: string;
  selectedLanguage?: string;
  onChangeLanguage: (language?: string) => void;
};

const VoicemailLanguageSelection = ({
  selectedLanguage,
  onChangeLanguage,
}: {
  selectedLanguage?: string;
  onChangeLanguage: (language?: string) => void;
}) => {
  return (
    <CL.Dropdown
      className={`of-subscription-details__addon-attribute ${dsClass.MARGIN_TOP_2} ${dsClass.MARGIN_BOTTOM_0}`}
      key={`addon-mobilevoice-language-dropdown-${selectedLanguage}`}
      items={getMobileVoiceLanguageOptions()}
      label={t.VV3T('Respondent language')}
      selectedValue={selectedLanguage}
      onValueChange={element => onChangeLanguage(element.dataset.value)}
    />
  );
};

export const AddOnRow = ({
  addOnVisibility,
  showEditView,
  editabilityRecords,
  subscriptionAddOnCodes,
  addOnCodesToAdd,
  addOnCodesToRemove,
  isAssetWithMobiiliturva,
  isEmployee,
  handleAddOnSelectionChange,
  baseRules,
  additionalServicePath,
  selectedLanguage,
  onChangeLanguage,
}: AddOnRowProps) => {
  const isInUse =
    isItemChecked(addOnCodesToAdd, addOnCodesToRemove, addOnVisibility.addOnGuidCode, subscriptionAddOnCodes) ||
    isAssetWithMobiiliturva;
  const editRecord = editabilityRecords.find(edit => edit.addOnCode === addOnVisibility.addOnGuidCode);

  const priceInfo = isEmployee
    ? undefined
    : { value: getAddOnPrice(addOnVisibility.addOn?.price?.effectivePrice).text || '-' };

  const addOnStatus: AddOnStatusInfo = getAddOnStatus(isInUse);
  const addOnExternalLinkUrl = getAddonLinkUrl(addOnVisibility);

  if (showEditView) {
    return (
      <div className={dsClass.MARGIN_VERTICAL_2}>
        <ToggleCheckbox
          onChange={checked => handleAddOnSelectionChange(checked, addOnVisibility)}
          title={addOnVisibility.addOn?.addOnProductName || ''}
          additionalInformation={
            <CheckboxAdditionalInformation
              editRecord={editRecord}
              baseRules={baseRules}
              subscriptionAddOnCodes={subscriptionAddOnCodes}
              addOnCodesToAdd={addOnCodesToAdd}
              addOnCodesToRemove={addOnCodesToRemove}
              addOnExternalLinkUrl={addOnExternalLinkUrl}
              currentAddOn={addOnVisibility.addOn}
            />
          }
          priceInfo={priceInfo}
          initialValue={isInUse}
          disabled={!isAddonCheckboxEnabled(subscriptionAddOnCodes, addOnVisibility, isEmployee, editRecord)}
        >
          {isMobileVoiceAddOn(addOnVisibility.addOnGuidCode) && (
            <VoicemailLanguageSelection selectedLanguage={selectedLanguage} onChangeLanguage={onChangeLanguage} />
          )}
        </ToggleCheckbox>
      </div>
    );
  }

  return (
    <SubscriptionAdditionalService
      title={addOnVisibility.addOn?.addOnProductName || ''}
      addOnStatusInfo={addOnStatus}
      priceInfo={priceInfo}
      additionalServicePath={additionalServicePath}
      isEmployee={isEmployee}
    >
      {addOnExternalLinkUrl && <AddOnExternalLink linkHref={addOnExternalLinkUrl} />}
    </SubscriptionAdditionalService>
  );
};
