/**
 * Online UI API
 * # Summary Elisa B2B Online UI API  # Usage This API is used in the `https://yrityksille.elisa.fi/` pages.  # Identifiers 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SubscriptionRedeemReview } from './subscriptionRedeemReview.js';
import { OnlineOrderReview } from './onlineOrderReview.js';


/**
 * Post Online review request
 */
export interface PostReviewRequest { 
    /**
     * type of review request
     */
    reviewType: PostReviewRequest.ReviewTypeEnum;
    /**
     * True or false if review request is approved or rejected, respectively
     */
    approve: boolean;
    /**
     * Id of the contact who is approving or rejecting the review request.
     */
    contactId?: string;
    /**
     * Reason for review request rejection. Mandatory if review request is of ONLINE_ORDER type 
     */
    rejectReason?: string;
    onlineOrder?: OnlineOrderReview;
    subscriptionRedeem?: SubscriptionRedeemReview;
}
export namespace PostReviewRequest {
    export type ReviewTypeEnum = 'ONLINE_ORDER' | 'SUBSCRIPTION_REDEEM';
    export const ReviewTypeEnum = {
        ONLINE_ORDER: 'ONLINE_ORDER' as ReviewTypeEnum,
        SUBSCRIPTION_REDEEM: 'SUBSCRIPTION_REDEEM' as ReviewTypeEnum
    };
}


