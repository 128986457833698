import { TypeKeys, generateActionId } from './actionsUtils.js';
import { convertToCommonErrors } from '../../common/utils/errorUtils.js';
import type { Action } from 'redux';
import type { DisplayItemsLoadAction, ErrorAction, ErrorActionCreator } from './actionsUtils.js';
import type { GenericError, PickupPoint, PickupPointSearchResponse } from '../../generated/api/models.js';

export interface PickupPointSearchAction extends DisplayItemsLoadAction<TypeKeys.SEARCH_PICKUP_POINT, PickupPoint> {
  type: TypeKeys.SEARCH_PICKUP_POINT;
  service: string;
  postalCode: string;
  maxResults: number;
}

export const searchPickupPoint = (service: string, postalCode: string, maxResults: number): PickupPointSearchAction => {
  return {
    id: generateActionId(),
    type: TypeKeys.SEARCH_PICKUP_POINT,
    service,
    postalCode,
    maxResults,
  };
};

export interface PickupPointSearchFulfilledAction extends Action {
  type: TypeKeys.SEARCH_PICKUP_POINT_FULFILLED;
  pickupPoints: PickupPoint[];
}

export const searchPickupPointFulfilled = (response: PickupPointSearchResponse): PickupPointSearchFulfilledAction => {
  return {
    pickupPoints: response.pickupPoints ?? [],
    type: TypeKeys.SEARCH_PICKUP_POINT_FULFILLED,
  };
};

export type PickupPointSearchFailedAction = ErrorAction<TypeKeys.SEARCH_PICKUP_POINT_FAILED>;

export const searchPickupPointFailed: ErrorActionCreator<TypeKeys.SEARCH_PICKUP_POINT_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[]
): PickupPointSearchFailedAction => {
  return {
    errors: convertToCommonErrors(message, status, errors),
    type: TypeKeys.SEARCH_PICKUP_POINT_FAILED,
  };
};

export type PickupPointSearchActionTypes =
  | PickupPointSearchAction
  | PickupPointSearchFulfilledAction
  | PickupPointSearchFailedAction;
