/**
 * Online UI API
 * # Summary Elisa B2B Online UI API  # Usage This API is used in the `https://yrityksille.elisa.fi/` pages.  # Identifiers 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Response of validating one time password and pair subscription with employee
 */
export interface ValidateSubscriptionPairingResponse { 
    /**
     * status of paring subscription with employee
     */
    status: ValidateSubscriptionPairingResponse.StatusEnum;
}
export namespace ValidateSubscriptionPairingResponse {
    export type StatusEnum = 'PAIRED' | 'INVALID_OTP' | 'SUBSCRIPTION_NOT_FOUND' | 'BUSINESS_ID_MISMATCH';
    export const StatusEnum = {
        PAIRED: 'PAIRED' as StatusEnum,
        INVALID_OTP: 'INVALID_OTP' as StatusEnum,
        SUBSCRIPTION_NOT_FOUND: 'SUBSCRIPTION_NOT_FOUND' as StatusEnum,
        BUSINESS_ID_MISMATCH: 'BUSINESS_ID_MISMATCH' as StatusEnum
    };
}


