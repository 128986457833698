import * as CL from '@design-system/component-library';
import { CompanyInfoResponse, SalesType } from '../../generated/api/models.js';
import { ContactOrPurposeOfUse } from '../ContactOrPurposeOfUse/ContactOrPurposeOfUse.js';
import { NettiLiteProductInfoHeader } from './NettiLiteProductInfoHeader.js';
import { SELECTED_COMMERCIAL_PRODUCT_INDEX } from '../../common/constants/commonConstants.js';
import { SelectedPurposeOfUseOrContact } from '../../common/enums.js';
import { activationFeeMsg, nextMsg, sumPerMonthMsg, t } from '../../common/i18n/index.js';
import { clearDuplicateContact, upsertContact } from '../../selfservice/actions/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { formatSum } from '../../common/utils/priceUtils.js';
import { getCommercialProductPrices } from '../../common/utils/commercialProductUtils.js';
import { getNewContactFromPurposeOfUseOrContact } from './FixedBroadbandUtils.js';
import { useDispatch, useSelector } from 'react-redux';
import { useRef, useState } from 'react';
import type { AddOn, CampaignAssociation } from '../../generated/api/models.js';
import type { AddressSearchMatch } from '../AddressSearch/AddressSearch.js';
import type { CompanyInfoState } from '../../common/types/states.js';
import type { ConfiguredOffer } from '../../common/types/commercialProduct.js';
import type { ContactOrPurposeOfUseCallables } from '../ContactOrPurposeOfUse/ContactOrPurposeOfUse.js';
import type { PurposeOfUseOrContact } from '../../common/types/subscription.js';
import type { State } from '../../selfservice/common/store.js';
import type { YritystuplaOfferSelection } from './FixedBroadbandCheckout.js';

import './FixedBroadbandCheckout.scss';

interface FixedBroadbandProductProps {
  addressSearchMatch: AddressSearchMatch;
  onNext: (contact: PurposeOfUseOrContact, additionalAddOns: AddOn[], yritystuplaPoU?: PurposeOfUseOrContact) => void;
  configuredOffer: ConfiguredOffer;
  selectedCampaign?: CampaignAssociation;
  companyInfo?: CompanyInfoState | null;
  selectedPurposeOfUseOrContact?: PurposeOfUseOrContact;
  yritystuplaOffer: YritystuplaOfferSelection;
}

export const FixedBroadbandProduct = (props: FixedBroadbandProductProps) => {
  const dispatch = useDispatch();
  const {
    addressSearchMatch,
    configuredOffer,
    onNext,
    companyInfo,
    selectedPurposeOfUseOrContact,
    selectedCampaign,
    yritystuplaOffer,
  } = props;
  const isInternalCustomer = companyInfo?.customerType === CompanyInfoResponse.CustomerTypeEnum.INTERNAL_CUSTOMERS;

  const contacts = useSelector((state: State) => state.selfservice?.contacts?.items || [], deepEqual);
  const saving = useSelector((state: State) => state.selfservice?.contacts?.saving, deepEqual);
  const selectedDuplicateContact = useSelector(
    (state: State) => state.selfservice?.subscriptionActions?.useDuplicateContact,
    deepEqual
  );

  const contactOrPurposeOfUseRef = useRef<ContactOrPurposeOfUseCallables>(null);
  const yritystuplaPurposeOfUseRef = useRef<ContactOrPurposeOfUseCallables>(null);
  const [selectedContactId, setSelectedContactId] = useState<string>();

  const purposeOfUseOrContact = selectedPurposeOfUseOrContact || {
    selected: SelectedPurposeOfUseOrContact.CONTACT,
    contactId: selectedContactId,
  };

  const configuredCommercialProduct = configuredOffer.selectedCommercialProducts[SELECTED_COMMERCIAL_PRODUCT_INDEX];
  const selectedCommercialProduct = configuredCommercialProduct.commercialProduct;

  const onSubmit = async () => {
    const nettiLiteContactOrPurposeOfUse = await contactOrPurposeOfUseRef.current?.getValues(true);
    const yritystuplaPurposeOfUse = await yritystuplaPurposeOfUseRef.current?.getValues(true);
    const newContact = getNewContactFromPurposeOfUseOrContact(nettiLiteContactOrPurposeOfUse || undefined);

    const validYritystuplaPurpose = !yritystuplaOffer.enabled || Boolean(yritystuplaPurposeOfUse);

    if (selectedDuplicateContact && validYritystuplaPurpose && yritystuplaPurposeOfUse !== false) {
      onNext(selectedDuplicateContact, [], yritystuplaPurposeOfUse);
    } else if (newContact) {
      dispatch(upsertContact(newContact));
    } else if (nettiLiteContactOrPurposeOfUse && validYritystuplaPurpose && yritystuplaPurposeOfUse !== false) {
      onNext(nettiLiteContactOrPurposeOfUse, [], yritystuplaPurposeOfUse);
    }
  };

  const broadbandPrice = getCommercialProductPrices(selectedCommercialProduct, selectedCampaign, SalesType.NEW_SALE);
  const broadbandPeriod = selectedCommercialProduct?.campaignAssociations?.[0]?.fixedTermPeriod || 0;
  const yritystuplaPrice = yritystuplaOffer.selectedOffer?.commercialProducts?.[0]?.price;

  return (
    <div className="of-fixed-bb-checkout">
      <NettiLiteProductInfoHeader
        productName={configuredOffer.offer.offerName}
        productInformation={`${addressSearchMatch.addressText}, ${addressSearchMatch.postalCode}`}
        price={t.YO7F(sumPerMonthMsg, formatSum(broadbandPrice.monthlyRecurringCharge) || '')}
        type="broadband"
      >
        <span className={`${dsClass.FONT_SIZE_SMALL} ${dsClass.DISPLAY_BLOCK}`}>
          {`${t.HWDR(activationFeeMsg)} ${formatSum(broadbandPrice.oneTimeCharge)}`}
        </span>
        {broadbandPeriod ? (
          <span className={`${dsClass.FONT_SIZE_SMALL} ${dsClass.DISPLAY_BLOCK}`}>
            {t.CF93('{} month period', broadbandPeriod.toString())}
          </span>
        ) : null}
      </NettiLiteProductInfoHeader>
      <div
        className={`of-fixed-bb-checkout__installation-info of-fixed-bb-checkout__installation-info--bordered ${dsClass.MARGIN_TOP_5}`}
      >
        <h3>{t.EAAJ('Subscription Details')}</h3>
        <ContactOrPurposeOfUse
          id="1"
          purposeOfUseOrContact={selectedDuplicateContact || purposeOfUseOrContact}
          saving={false}
          contacts={contacts}
          ref={contactOrPurposeOfUseRef}
          required={true}
          isEmailAndPhoneRequired={true}
          enableCostCenterAndReference={true}
          isCostCenterRequired={isInternalCustomer}
          onChangeContact={(contactId?: string) => {
            setSelectedContactId(contactId);
            dispatch(clearDuplicateContact());
          }}
        />
      </div>
      {yritystuplaOffer.enabled && yritystuplaOffer.selectedOffer ? (
        <div className={dsClass.MARGIN_TOP_8}>
          <NettiLiteProductInfoHeader
            productName={yritystuplaOffer.selectedOffer.offerName}
            price={t.YO7F(sumPerMonthMsg, formatSum(yritystuplaPrice?.effectivePrice.monthlyRecurringCharge) || '')}
            type="yritystupla"
          >
            {yritystuplaPrice?.effectivePrice?.oneTimeCharge ? (
              <span
                className={`${dsClass.FONT_SIZE_SMALL} ${dsClass.DISPLAY_BLOCK}`}
              >{`${t.HWDR(activationFeeMsg)} ${formatSum(yritystuplaPrice.effectivePrice.oneTimeCharge)}`}</span>
            ) : null}
          </NettiLiteProductInfoHeader>
          <div
            className={`of-fixed-bb-checkout__installation-info of-fixed-bb-checkout__installation-info--bordered ${dsClass.MARGIN_TOP_5}`}
          >
            <h3>{t.EAAJ('Subscription Details')}</h3>
            <ContactOrPurposeOfUse
              id="yritystupla-purpose-of-use"
              purposeOfUseOrContact={{ selected: SelectedPurposeOfUseOrContact.PURPOSEOFUSE }}
              saving={false}
              ref={yritystuplaPurposeOfUseRef}
              required={true}
              isEmailAndPhoneRequired={false}
              enableCostCenterAndReference={true}
              isCostCenterRequired={isInternalCustomer}
              onlyPurposeOfUse={true}
            />
          </div>
        </div>
      ) : null}

      <div className="ea-m-t-4" />
      <CL.Button className={`${dsClass.MARGIN_TOP_4} of-next-button`} loading={saving} onClick={onSubmit} size="l">
        {t.F0MY(nextMsg).toUpperCase()}
      </CL.Button>
    </div>
  );
};
