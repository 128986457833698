import { COST_CENTER_REGEX } from '../../../common/utils/validationUtils.js';
import { Field } from 'formik';
import { FieldType } from '../../enums.js';
import { InputComponent } from '../FieldComponents/index.js';
import {
  costCenterMsg,
  costCenterTooLong,
  costCenterToolTipMsg,
  fieldCantBeEmptyMsg,
  invalidCostCenterNumberMsg,
  t,
} from '../../i18n/index.js';
import type { OptionalInputComponentProps } from '../FieldComponents/index.js';

type Props = OptionalInputComponentProps & {
  alwaysShowLabel?: boolean;
  labelTooltip?: string;
};

export const CostCenter = (props: Props) => {
  const validate = (value: string) => {
    if (props.required) {
      if (!value) {
        return t.VPVR(fieldCantBeEmptyMsg);
      } else if (!COST_CENTER_REGEX.test(value)) {
        return t.JE8S(invalidCostCenterNumberMsg);
      }
    } else if (value && value.length > 40) {
      return t.DHQ9(costCenterTooLong);
    }
    return undefined;
  };

  return (
    <Field
      {...{
        name: 'costCenter',
        label: props.label || t.QJUW(costCenterMsg),
        placeholder: props.placeholder || ' ',
        disabled: false,
        required: props.required,
        maxLength: props.required ? 4 : 40,
        labelTooltip: props.labelTooltip || t.UE8R(costCenterToolTipMsg),
        type: props.type ? props.type : FieldType.STRING,
        validate,
        ...props,
      }}
      component={InputComponent}
    />
  );
};
