import { ActionPhase } from '../common/storeUtils.js';
import { AuthorizationRequestType } from '../../common/enums.js';
import { TypeKeys } from '../actions/index.js';
import { reduceCrudAction } from './reducerUtils.js';
import { updateActionStatePhase } from '../common/index.js';
import type { ActionsHistory } from '../common/store.js';
import type { AuthorizationRequestState, OwnerChangeRequestState } from '../../common/types/states.js';
import type { SelfServiceActionTypes } from '../actions/index.js';

export function authorizationRequestReducer(
  state: (AuthorizationRequestState & ActionsHistory) | undefined,
  action: SelfServiceActionTypes
): (AuthorizationRequestState & ActionsHistory) | null {
  if (state === undefined) {
    return null;
  }

  switch (action.type) {
    case TypeKeys.GET_AUTHORIZATION_REQUEST: {
      return {
        authorizationRequestId: action.linkToken,
        loading: true,
        // TODO: Atm only type, in future, get from API response (when available).
        type: AuthorizationRequestType.OWNER_CHANGE,
        ...reduceCrudAction(action, state),
      };
    }
    case TypeKeys.GET_AUTHORIZATION_REQUEST_FULFILLED: {
      return {
        ...state,
        loading: false,
        mobileNumber: action.response.mnpAuthorizationRequest!.mobileNumber,
        newOwnerCompanyName: action.response.mnpAuthorizationRequest!.newOwnerCompanyName,
        type: AuthorizationRequestType.OWNER_CHANGE,
      } as OwnerChangeRequestState;
    }
    case TypeKeys.GET_AUTHORIZATION_REQUEST_FAILED: {
      return {
        ...state,
        errors: action.errors,
        loading: false,
      };
    }
    case TypeKeys.PROCESS_AUTHORIZATION_REQUEST: {
      return {
        authorizationRequestId: action.linkToken,
        loading: true,
        type: AuthorizationRequestType.OWNER_CHANGE,
        ...reduceCrudAction(action, state),
      };
    }
    case TypeKeys.PROCESS_AUTHORIZATION_REQUEST_FULFILLED: {
      return {
        actions: updateActionStatePhase(
          TypeKeys.PROCESS_AUTHORIZATION_REQUEST,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        authorizationRequestId: action.linkToken,
        loading: false,
        mobileNumber: action.response.mnpAuthorizationRequest!.mobileNumber,
        newOwnerCompanyName: action.response.mnpAuthorizationRequest!.newOwnerCompanyName,
        type: AuthorizationRequestType.OWNER_CHANGE,
      } as OwnerChangeRequestState;
    }
    case TypeKeys.PROCESS_AUTHORIZATION_REQUEST_FAILED: {
      return {
        actions: updateActionStatePhase(
          TypeKeys.PROCESS_AUTHORIZATION_REQUEST,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        authorizationRequestId: action.linkToken,
        errors: action.errors,
        loading: false,
        type: AuthorizationRequestType.OWNER_CHANGE,
      };
    }
    default: {
      return state;
    }
  }
}
