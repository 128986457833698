import { createContext } from 'react';
import type { Locale } from '../../common/i18n/index.js';

export interface SiteContextType {
  siteBaseUrl: string;
  siteLanguage: string;
  switchPreferredLanguage: (language: string) => void;
  saveUserPreferredLanguage: (language: string) => void;
  saveUserPreferredLocale: (locale: Locale) => void;
}

const defaultContext: SiteContextType = {
  siteBaseUrl: 'https://yrityksille.elisa.fi',
  siteLanguage: 'fi-FI',
  switchPreferredLanguage: (_language: string) => {},
  saveUserPreferredLanguage: (_language: string) => {},
  saveUserPreferredLocale: (_locale: Locale) => {},
};

export const SiteContext = createContext(defaultContext);
