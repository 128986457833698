import { BreadCrumbs } from '../BreadCrumbs/index.js';
import { Loading } from '../Loading/index.js';
import { ProductDetailsEmployeeLoader } from '../ProductDetails/ProductDetailsEmployeeLoader.js';
import { PromotionBlockContainer } from '../../content-blocks/PromotionBlock/PromotionBlockContainer.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import {
  eCommerceClickEvent,
  eCommerceDetailEvent,
  eCommerceImpressionEvent,
} from '../../selfservice/actions/index.js';
import { generatePath } from 'react-router-dom';
import { getProductDetailsContentBlock } from './ContentBlockWrapperUtils.js';
import { getPublishedCatalogs, getSelectedCatalog } from '../../common/utils/catalogUtils.js';
import { omaElisaForEmployeeMsg, shopMsg, t } from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useDispatch, useSelector } from 'react-redux';
import { useRedirectToDeviceStore } from '../../public/site/path/Employee/DeviceStore/EmployeeStore.js';
import Templates from '@elisa-luukku-sites/cms-templates';
import type { Catalog } from '../../generated/api/catalog.js';
import type { Category } from '../../common/types/category.js';
import type { Offer } from '../../generated/api/offer.js';
import type { OnlineModel } from '../../generated/api/onlineModel.js';
import type { OnlineModelHeader } from '../../generated/api/onlineModelHeader.js';
import type { PromotionBlockData } from '../../content-blocks/cmsSchema.js';
import type { State } from '../../selfservice/common/store.js';

export interface EmployeeContentBlockWrapperProps {
  category: Category;
}

// filter out promotions that are not in the catalog
const getPromotionBlockData = (promotionBlockData: PromotionBlockData, catalog: Catalog) => {
  const promotions = promotionBlockData?.promotions.filter(promo =>
    catalog.products.some(prod => prod === promo.productGuid)
  );
  return { ...promotionBlockData, promotions } as PromotionBlockData;
};

// We show only accessories in the selected catalog + set need to set the path
const getOnlineModelHeadersInCatalog = (onlineModelHeaders: OnlineModelHeader[], catalog: Catalog) =>
  onlineModelHeaders
    .filter(header => header.category === 'ACCESSORIES')
    .filter(header => catalog.products.some(prod => prod === header.onlineModelCode))
    .map(onlineModelHeader => {
      return onlineModelHeader?.pagePath
        ? {
            ...onlineModelHeader,
            pagePath: generatePath(paths.EMPLOYEE_DEVICE_STORE_ACCESSORY, {
              deviceName: onlineModelHeader?.pagePath?.substring(1),
            }),
          }
        : onlineModelHeader;
    });

export const EmployeeContentBlockWrapper = ({ category }: EmployeeContentBlockWrapperProps) => {
  useRedirectToDeviceStore();
  const productPage = useSelector((state: State) => state.resources?.cmsPage, deepEqual);

  const { selectedCatalogCode, virtualCatalogItems } = useSelector(
    (state: State) => ({
      selectedCatalogCode: state.selfservice?.virtualCatalogs?.selectedCatalogCode,
      virtualCatalogItems: state.selfservice?.virtualCatalogs?.items,
    }),
    deepEqual
  );
  const catalogs: Catalog[] = getPublishedCatalogs(virtualCatalogItems) || [];
  const catalog = getSelectedCatalog(catalogs, selectedCatalogCode)!;

  const dispatch = useDispatch();

  if (!productPage) {
    return <Loading big={true} topPadding={0} />;
  }

  const productDetailsContentBlock = getProductDetailsContentBlock(productPage);

  if (!productDetailsContentBlock) {
    return null;
  }

  const { productGuid }: { productGuid?: string } = productDetailsContentBlock.fields;
  const onlineModel = productPage.onlineModels.find(om => om.onlineModelCode === productGuid);

  if (!onlineModel) {
    return null;
  }

  return (
    <>
      <div className="of-product-details-employee-loader">
        <div className="of-product-details-employee-loader__breadcrumbs">
          <BreadCrumbs
            breadCrumbPaths={[
              { name: t.H6JV(omaElisaForEmployeeMsg), path: paths.EMPLOYEE_HOME },
              { name: t.BZGP(shopMsg), path: paths.EMPLOYEE_DEVICE_STORE },
              {
                name: category.displayText(),
                path: generatePath(paths.EMPLOYEE_DEVICE_STORE_PRODUCTS, { category: category.urlPath }),
              },
              { name: `${onlineModel.manufacturer} ${onlineModel.onlineModelName}` },
            ]}
          />
        </div>
      </div>
      <ProductDetailsEmployeeLoader onlineModel={onlineModel} category={category} />
      {productPage.contentBlocks
        .filter(
          ({ contentBlockType }) =>
            contentBlockType === 'CB-DescriptionBlock' || contentBlockType === 'CB-PromotionBlock'
        )
        .map(({ contentBlockType, fields }, index) => {
          if (contentBlockType === 'CB-DescriptionBlock') {
            return (
              <div key={`of-product-details-employee-loader__${contentBlockType}-${index}`} className="site">
                <Templates.default.DescriptionBlock {...fields} />
              </div>
            );
          } else {
            const filteredOnlineModelHeaders = getOnlineModelHeadersInCatalog(productPage.onlineModelHeaders, catalog);
            const promotionsBlockData = getPromotionBlockData(fields as PromotionBlockData, catalog);
            const shouldShowPromotionBlock =
              promotionsBlockData.promotions.length > 0 && filteredOnlineModelHeaders.length > 0;

            return shouldShowPromotionBlock ? (
              <div key={`of-product-details-employee-loader__${contentBlockType}-${index}`} className="site">
                <PromotionBlockContainer
                  title="Yhteensopivia lisätarvikkeita"
                  promotions={promotionsBlockData.promotions}
                  onlineModelHeaders={filteredOnlineModelHeaders}
                  eCommerceEvents={{
                    onClickListProduct: (type, position, omhs) => dispatch(eCommerceClickEvent(type, position, omhs)),
                    onSeeListProduct: (type, position, omhs) =>
                      dispatch(eCommerceImpressionEvent(type, position, omhs)),
                    onSeeProduct: (oms: OnlineModel[], offers: Offer[][]) =>
                      dispatch(eCommerceDetailEvent(oms, offers)),
                  }}
                  isEmployeePromotionBlock={true}
                  onAnalyticsEvent={() => {
                    /* NOOP, we don't need this */
                  }}
                />
              </div>
            ) : null;
          }
        })}
    </>
  );
};
