import { BillingAccount } from '../../../../../components/BillingAccount/BillingAccount.js';
import { loadBillChannels, loadContacts } from '../../../../../selfservice/actions/index.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const BillingAccountNewPath = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(loadContacts());
    dispatch(loadBillChannels());
  }, [dispatch]);

  useEffect(() => {
    if (!location.state?.originURL) {
      navigate(paths.BILLING_ACCOUNTS);
    }
  }, [location.state?.originURL, navigate]);

  return <BillingAccount />;
};
