import { DialogType } from '../../common/enums.js';
import { confirmMsg, t, yourOldSIMCardWillNowStopWorkingMsg } from '../../common/i18n/index.js';
import type { DialogParams } from '../../common/types/dialog.js';

export interface OrderEsimConfirmationDialogProps {
  onConfirm: () => void;
  onShowDialog: (params: DialogParams) => void;
}

export const OrderEsimConfirmationDialog = (props: OrderEsimConfirmationDialogProps) => {
  return props.onShowDialog({
    body: (
      <div>
        <p>{t.SJ3Y(yourOldSIMCardWillNowStopWorkingMsg)}</p>
        <p>{t.SJBG('Your eSIM will be delivered soon, after which you can activate it.')}</p>
      </div>
    ),
    confirmButtonText: t.QVYK(confirmMsg),
    header: t.RCF3('Confirm eSIM order'),
    hideClosingCross: false,
    onConfirm: props.onConfirm,
    type: DialogType.GENERIC_CONFIRMATION_DIALOG,
  });
};
