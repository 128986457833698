import { AutoComplete } from '../../../components/AutoComplete/AutoComplete.js';
import { eInvoicingOperatorMsg, fieldCantBeEmptyMsg, noSelectionMsg, t } from '../../i18n/index.js';
import { getElectronicInvoiceOperatorDropDownOptions } from '../../utils/billingAccountUtils.js';
import { getTextError } from '../utils.js';
import { useController, useFormContext } from 'react-hook-form';
import type { BillChannel } from '../../../generated/api/billChannel.js';
import type CLT from '@design-system/component-library';

interface ElectronicOperatorProps {
  billChannels: BillChannel[];
  name: string;
}

export const ElectronicOperator = (props: ElectronicOperatorProps) => {
  const electronicOperators = getElectronicInvoiceOperatorDropDownOptions(props.billChannels);
  const name = props.name;
  const methods = useFormContext();
  const { control, setValue, setError } = methods;
  const rules = { required: { value: true, message: t.VPVR(fieldCantBeEmptyMsg) } };
  const { field, fieldState, formState } = useController({ name, control, rules });
  const keys = name.split('.');
  const value = keys.reduce((current, key) => (current ? current[key] : undefined), formState.defaultValues);
  const defaultValue = electronicOperators.find(i => i.value === value);
  const error = getTextError(formState.isSubmitted, fieldState.isTouched, fieldState.error);

  return (
    <AutoComplete<CLT.DropDownItem>
      className="large-min-height"
      inputRef={field.ref}
      options={electronicOperators}
      defaultOption={defaultValue}
      getUniqueId={i => i.value}
      getDisplayValue={i => i.label}
      onInputBlur={field.onBlur}
      onInputChange={(e, item) => {
        setValue(name, item.value);
        setError(name, { message: undefined });
      }}
      id={`${name}-autocomplete`}
      noOptionsMsg={t.ZW5W(noSelectionMsg)}
      label={t.WVLB(eInvoicingOperatorMsg)}
      config={{ isClearable: false }}
      error={error}
    />
  );
};
