import { ContactDropdown } from './ContactDropdown.js';
import { approverContactMsg, t } from '../../../i18n/index.js';
import { dsClass } from '../../../../common/constants/dsClasses.js';
import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import type { Contact } from '../../../../generated/api/models.js';
import type { DeliveryDetailsFormValues } from '../../../../components/Checkout/steps/DeliveryDetailsStep.js';
import type { Dispatch, SetStateAction } from 'react';

interface ApproverContactProps {
  contacts: Contact[];
  setApproverContact: Dispatch<SetStateAction<string>>;
  setApproverContactValidationErrors: Dispatch<SetStateAction<{ [fieldName: string]: string }>>;
}

export const ApproverContactDropdown = ({
  contacts,
  setApproverContact,
  setApproverContactValidationErrors,
}: ApproverContactProps) => {
  const { values, errors } = useFormikContext<DeliveryDetailsFormValues>();

  useEffect(() => {
    setApproverContactValidationErrors(errors);
    if (values.approverContact) {
      setApproverContact(values.approverContact);
    }
  }, [values.approverContact, errors, setApproverContact, setApproverContactValidationErrors]);

  return (
    <ContactDropdown
      canAddNewContacts={false}
      className={dsClass.MARGIN_0}
      name="approverContact"
      labelText={t.GOZ7(approverContactMsg)}
      contacts={contacts}
    />
  );
};
