import * as CL from '@design-system/component-library';
import { CommonErrorType } from '../../../common/enums.js';
import { ExternalAuthenticationProviders } from '../../ExternalAuthenticationProviders/ExternalAuthenticationProviders.js';
import {
  Locale,
  externalAuthenticationErrorMsg,
  externalAuthenticationRetryMsg,
  t,
} from '../../../common/i18n/index.js';
import { useAuth } from '../../../public/site/AuthProvider.js';

import './PunchoutCheckoutAuth.scss';

export const PunchoutCheckoutAuth = () => {
  const errors = useAuth().externalAuthentication?.errors;
  const authError = errors?.some(e => e.type === CommonErrorType.BAD_REQUEST || e.type === CommonErrorType.FORBIDDEN);

  return (
    <div className="of-punchout-checkout-auth">
      {authError && (
        <CL.NotificationBanner
          className="of-punchout-checkout-auth__error"
          icon={<CL.Icon icon="warning" size="xl" />}
          title={t.UEHC(externalAuthenticationErrorMsg)}
          text={t.Q9YM(externalAuthenticationRetryMsg)}
        />
      )}
      <ExternalAuthenticationProviders
        locale={Locale.FI}
        uriState="punchout"
        headerText={t.URAB('We verify your identity with authentication')}
      />
    </div>
  );
};
