import { AddOnPurpose } from '../../generated/api/models.js';
import type { AddOn, AddOnAssociation, CommercialProduct, Offer, OnlineModel } from '../../generated/api/models.js';
import type { ServiceLevel, ServiceLevelAddon } from './ServiceLevelAndAddonsInterfaces.js';

interface AttachRingDataConverterProps {
  pbxServiceLevels: ServiceLevel[];
  pbxServiceLevelAddons: ServiceLevelAddon[];
}

const getServiceLevels = (availableCommercialProducts: CommercialProduct[], offers: Offer[]): ServiceLevel[] => {
  const commercialProductCodeOfferCodeMapping: Record<string, string> = offers
    .filter(offer => offer.commercialProducts.length === 1)
    .map(offer => ({
      offerCode: offer.offerCode,
      commercialProductCode: offer.commercialProducts[0].commercialProductCode,
    }))
    .reduce(
      (obj, item) => ({
        ...obj,
        [item.commercialProductCode]: item.offerCode,
      }),
      {}
    );
  return availableCommercialProducts.map(commercialProduct => ({
    id: commercialProduct.commercialProductCode,
    offerId: commercialProductCodeOfferCodeMapping[commercialProduct.commercialProductCode],
    commercialProductCode: commercialProduct.commercialProductCode,
    name: commercialProduct.commercialProductName,
    price: commercialProduct.price?.effectivePrice.monthlyRecurringCharge
      ? commercialProduct.price?.effectivePrice.monthlyRecurringCharge
      : 0,
  }));
};

const isAddonMandatory = (addonType: string) => addonType === AddOnPurpose.BY_DEFAULT;

const createServiceLevelAddon = (
  commercialProduct: CommercialProduct,
  addOnAssociation: AddOnAssociation,
  addon: AddOn
): ServiceLevelAddon => ({
  id: addOnAssociation.addOnAssociationId,
  serviceLevelId: commercialProduct.commercialProductCode,
  name: addon.addOnProductName || '',
  addonCode: addOnAssociation.addOnCode,
  addonAssociationId: addOnAssociation.addOnAssociationId,
  addonAssociationCode: addOnAssociation.addOnAssociationCode,
  monthlyPrice:
    addon && addon.price?.effectivePrice.monthlyRecurringCharge
      ? addon.price?.effectivePrice.monthlyRecurringCharge
      : 0,
  oneTimePrice: addon && addon.price?.effectivePrice.oneTimeCharge ? addon.price?.effectivePrice.oneTimeCharge : 0,
  selected: isAddonMandatory(addOnAssociation.addOnPurpose),
  disabled: isAddonMandatory(addOnAssociation.addOnPurpose),
});

const getServiceLevelAddons = (commercialProducts: CommercialProduct[]): ServiceLevelAddon[] => {
  const serviceLevelAddons: ServiceLevelAddon[] = [];
  commercialProducts.forEach(commercialProduct => {
    if (commercialProduct.addOnAssociations && commercialProduct.associatedAddOns) {
      const associatedAddOnsObject: Record<string, AddOn> = commercialProduct.associatedAddOns.reduce(
        (obj, associatedAddOn) => ({ ...obj, [`${associatedAddOn.addOnCode}`]: associatedAddOn }),
        {}
      );
      commercialProduct.addOnAssociations.forEach(addOnAssociation =>
        serviceLevelAddons.push(
          createServiceLevelAddon(
            commercialProduct,
            addOnAssociation,
            associatedAddOnsObject[addOnAssociation.addOnCode]
          )
        )
      );
    }
  });
  return serviceLevelAddons;
};

interface ProductAddon {
  addOnCode: string;
  addOnPurpose: string;
}

const removeNotAllowedAddons = (serviceLevelAddons: ServiceLevelAddon[], listOfNotAllowedAddons: string[]) => {
  return serviceLevelAddons.filter(addon => !listOfNotAllowedAddons.includes(addon.addonCode));
};

const parseAddons = (serviceLevelAddons: ServiceLevelAddon[], listOfAddons: string[], newValue: boolean) =>
  serviceLevelAddons.map(addon => {
    if (listOfAddons.includes(addon.addonCode)) {
      addon.selected = newValue;
      addon.disabled = newValue;
    }
    return addon;
  });

const makeAddonsMandatory = (serviceLevelAddons: ServiceLevelAddon[], listOfMandatoryAddons: string[]) =>
  parseAddons(serviceLevelAddons, listOfMandatoryAddons, true);

const makeAddonsOptional = (serviceLevelAddons: ServiceLevelAddon[], listOfOptionalAddons: string[]) =>
  parseAddons(serviceLevelAddons, listOfOptionalAddons, false);

export const getRingServiceLevelsFromOnlineModel = (
  productCodes: string[],
  productAddons: ProductAddon[],
  onlineModel?: OnlineModel
): AttachRingDataConverterProps => {
  let pbxServiceLevels: ServiceLevel[] = [];
  let pbxServiceLevelAddons: ServiceLevelAddon[] = [];
  if (onlineModel) {
    const availableCommercialProducts = onlineModel.offers.flatMap(offer => offer.commercialProducts);
    pbxServiceLevels = getServiceLevels(
      availableCommercialProducts.filter(commercialProduct =>
        productCodes.includes(commercialProduct.commercialProductCode)
      ),
      onlineModel.offers
    );
    if (pbxServiceLevels?.find(element => element.selected) === undefined) {
      pbxServiceLevels[0].selected = true;
    }
    pbxServiceLevelAddons = getServiceLevelAddons(availableCommercialProducts);
    const listOfNotAllowedAddons = productAddons
      .filter(addon => addon.addOnPurpose === AddOnPurpose.NOT_ALLOWED)
      .map(addon => addon.addOnCode);
    const listOfMandatoryAddons = productAddons
      .filter(addon => addon.addOnPurpose === AddOnPurpose.MANDATORY)
      .map(addon => addon.addOnCode);
    const listOfOptionalAddons = productAddons
      .filter(addon => addon.addOnPurpose === AddOnPurpose.OPTIONAL)
      .map(addon => addon.addOnCode);
    pbxServiceLevelAddons = removeNotAllowedAddons(pbxServiceLevelAddons, listOfNotAllowedAddons);
    pbxServiceLevelAddons = makeAddonsMandatory(pbxServiceLevelAddons, listOfMandatoryAddons);
    pbxServiceLevelAddons = makeAddonsOptional(pbxServiceLevelAddons, listOfOptionalAddons);
  }
  return {
    pbxServiceLevels,
    pbxServiceLevelAddons,
  };
};
