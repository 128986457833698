import * as CL from '@design-system/component-library';
import { Link } from 'react-router-dom';
import { OnlineModelCategory } from '../../../generated/api/models.js';
import { PRODUCT_DETAILS_CLASS } from '../productDetailsConstants.js';
import { RenderHtmlBlock } from '../../../common/utils/htmlUtils.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { getBrandPagePath } from '../../../common/utils/brandUtils.js';
import type { OnlineModel } from '../../../generated/api/models.js';

import './ProductDetailsDescription.scss';

export interface ProductDetailsDescriptionProps {
  onlineModel: OnlineModel;
  isPublicSite: boolean;
  isAvailable?: boolean;
}

/**
 * Create name and description blocks for the ProductDetails
 * Subscription special traits observed from the data:
 * - manufacturer field may contain "N/A" value which is not to be rendered
 * - description field may contain static html content that requires special handling
 */
export const ProductDetailsDescription = (props: ProductDetailsDescriptionProps) => {
  const { onlineModel, isPublicSite, isAvailable } = props;
  const { category, manufacturer, description = '', onlineModelName, offerBadge } = onlineModel;
  const brand = category !== OnlineModelCategory.SALES_PRODUCT ? manufacturer : '';
  const brandPath = isPublicSite ? getBrandPagePath(brand) : null;
  const brandClassName = `${PRODUCT_DETAILS_CLASS}-description__title ${dsClass.H3} ${dsClass.H3_BOOK}`;

  const badge =
    offerBadge && isAvailable ? (
      <CL.Badge
        className={`legacy-badge-${offerBadge.type.toLowerCase()}`}
        color="primary"
        i18n_badge_ariaLabel={offerBadge.text}
        text={offerBadge.text}
      />
    ) : undefined;

  return (
    <div className={`${PRODUCT_DETAILS_CLASS}-description`}>
      <h1 className={`${PRODUCT_DETAILS_CLASS}-description__header ${dsClass.MARGIN_0} ${dsClass.MARGIN_BOTTOM_3}`}>
        {brandPath ? (
          <Link className={brandClassName} to={brandPath}>
            {brand}
          </Link>
        ) : (
          <span className={brandClassName}>{brand}</span>
        )}
        <span
          className={`${PRODUCT_DETAILS_CLASS}-description__model-name ${dsClass.H3} ${dsClass.H3_BOOK}`}
        >{` ${onlineModelName}`}</span>
      </h1>
      {isPublicSite && badge}
      <RenderHtmlBlock type="div" className={`${PRODUCT_DETAILS_CLASS}-description__paragraph`}>
        {description}
      </RenderHtmlBlock>
    </div>
  );
};
