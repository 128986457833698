import { BreadCrumbs } from '../BreadCrumbs/index.js';
import { Loading } from '../Loading/index.js';
import { ProductDetailsEmployeeLoader } from '../ProductDetails/ProductDetailsEmployeeLoader.js';
import { corporateCustomersMsg, shopMsg, t } from '../../common/i18n/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { generatePath } from 'react-router-dom';
import { getProductDetailsContentBlock } from '../EmployeeDeviceStore/ContentBlockWrapperUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useSelector } from 'react-redux';
import Templates from '@elisa-luukku-sites/cms-templates';
import type { CategoryProps } from '../../common/types/category.js';
import type { State } from '../../selfservice/common/store.js';

export const PunchoutContentBlockWrapper = ({ category }: CategoryProps) => {
  const productPage = useSelector((state: State) => state.resources?.cmsPage, deepEqual);
  const selectedCatalogCode = useSelector((state: State) => state.selfservice?.virtualCatalogs?.selectedCatalogCode);

  if (!productPage || !selectedCatalogCode) {
    return <Loading big={true} topPadding={0} />;
  }

  const productDetailsContentBlock = getProductDetailsContentBlock(productPage);
  if (!productDetailsContentBlock) {
    return null;
  }

  const { productGuid }: { productGuid?: string } = productDetailsContentBlock.fields;
  const onlineModel = productPage.onlineModels.find(om => om.onlineModelCode === productGuid);

  if (!onlineModel) {
    return null;
  }

  return (
    <>
      <div className="of-product-details-employee-loader">
        <div className="of-product-details-employee-loader__breadcrumbs">
          <BreadCrumbs
            breadCrumbPaths={[
              { name: t.KBLX(corporateCustomersMsg), path: paths.PUNCHOUT_HOME },
              { name: t.BZGP(shopMsg), path: paths.PUNCHOUT_STORE },
              {
                name: category.displayText(),
                path: generatePath(paths.PUNCHOUT_STORE_PRODUCTS, { category: category.urlPath }),
              },
              { name: `${onlineModel.manufacturer} ${onlineModel.onlineModelName}` },
            ]}
          />
        </div>
        <ProductDetailsEmployeeLoader onlineModel={onlineModel} category={category} />
      </div>
      {productPage.contentBlocks
        .filter(({ contentBlockType }) => contentBlockType === 'CB-DescriptionBlock')
        .map(({ contentBlockType, fields }, index) => {
          return (
            <div key={`of-product-details-employee-loader__${contentBlockType}-${index}`} className="site">
              <Templates.default.DescriptionBlock {...fields} />
            </div>
          );
        })}
    </>
  );
};
