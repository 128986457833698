import * as CL from '@design-system/component-library';
import { dsClass } from '../../common/constants/dsClasses.js';
import { t } from '../../common/i18n/index.js';
import { useState } from 'react';

import './ToggleWithSubComponent.scss';

export interface ToggleWithSubComponentProps {
  name: string;
  title: string;
  infoText?: string;
  initialValue?: boolean;
  disabled?: boolean;
  onToggle: (value: boolean) => void;
  subComponent: JSX.Element;
  showSubComponentWhenToggled?: boolean;
}

export const ToggleWithSubComponent = ({
  name,
  title,
  infoText,
  initialValue = false,
  disabled = false,
  onToggle,
  subComponent,
  showSubComponentWhenToggled = true,
}: ToggleWithSubComponentProps) => {
  const [isToggled, setIsToggled] = useState(initialValue);
  const icon = <CL.Icon className="info-icon" icon="information" type="regular" color="blue-600" />;
  const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsToggled(e.currentTarget.checked);
    onToggle(e.currentTarget.checked);
  };

  return (
    <div className="of-toggle-with-subcomponent" id={name}>
      <div className={`${dsClass.PADDING_TOP_3} ${dsClass.MARGIN_BOTTOM_4}`}>
        <h4 className={dsClass.DISPLAY_INLINE}>
          {title.split(' ').slice(0, -1).join(' ')}{' '}
          <span className={dsClass.WHITE_SPACE_NOWRAP}>
            {title.split(' ').slice(-1)}
            {infoText && (
              <span className="info-icon-container">
                <CL.Tooltip
                  triggerElement={icon}
                  placement="right"
                  i18n_tooltip_contentText={infoText}
                  className="tooltip"
                />
              </span>
            )}
          </span>
        </h4>
      </div>
      <div className={`${dsClass.DISPLAY_FLEX} ${dsClass.ALIGN_ITEMS_CENTER} ${dsClass.PADDING_BOTTOM_4}`}>
        <CL.Toggle
          className={dsClass.MARGIN_BOTTOM_0}
          onToggle={handleToggle}
          value={initialValue}
          disabled={disabled}
        />
        <span className={dsClass.MARGIN_LEFT_2}>{isToggled ? t.M03S('Yes') : t.A37D('No')}</span>
      </div>
      {isToggled === showSubComponentWhenToggled && <div className={dsClass.MARGIN_TOP_1}>{subComponent}</div>}
    </div>
  );
};
