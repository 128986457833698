/**
 * Online UI API
 * # Summary Elisa B2B Online UI API  # Usage This API is used in the `https://yrityksille.elisa.fi/` pages.  # Identifiers 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ValidateSimFreeResponse { 
    /**
     * Whether or not the SIM card number (ICCID) is FREE.
     */
    free: boolean;
    /**
     * Machine-readable error code if FREE is false.
     */
    errorType?: ValidateSimFreeResponse.ErrorTypeEnum;
    /**
     * Human readable (english) error message if FREE is false.
     */
    errorMessage?: string;
}
export namespace ValidateSimFreeResponse {
    export type ErrorTypeEnum = 'ALREADY_IN_USE' | 'INVALID_NUMBER' | 'TECHNICAL_ERROR';
    export const ErrorTypeEnum = {
        ALREADY_IN_USE: 'ALREADY_IN_USE' as ErrorTypeEnum,
        INVALID_NUMBER: 'INVALID_NUMBER' as ErrorTypeEnum,
        TECHNICAL_ERROR: 'TECHNICAL_ERROR' as ErrorTypeEnum
    };
}


