import * as CL from '@design-system/component-library';
import { DEFAULT_COUNTRY_CODE } from '../../common/utils/validationUtils.js';
import { FormProvider, useForm } from 'react-hook-form';
import { PhoneNumber, PostalCode, TextInput } from '../../common/react-hook-form/fields/index.js';
import { addEmptyFieldValidationError } from '../../common/utils/errorUtils.js';
import {
  addressLine2Msg,
  companyAddressMsg,
  companyNameMsg,
  confirmMsg,
  editMsg,
  fieldCantBeEmptyMsg,
  homeAddressMsg,
  postOfficeMsg,
  streetAddressMsg,
  t,
} from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { useEffect, useState } from 'react';
import type { Address } from '../../generated/api/models.js';
import type { CommonError } from '../../common/types/errors.js';

import './DeliveryAddress.scss';

export enum DeliveryAddressType {
  COMPANY_ADDRESS = 'COMPANY ADDRESS',
  HOME_ADDRESS = 'HOME ADDRESS',
}

export interface DeliveryAddressProps {
  address?: Address;
  companyName?: string;
  deliveryAddressType?: DeliveryAddressType;
  errors?: CommonError[];
  isEditModeOn?: boolean;
  submit: (values: AddressWithType, addrType: DeliveryAddressType) => void;
  onUpdateEditMode: (value: boolean) => void;
  recipientName?: string;
  recipientPhoneNumber?: string;
}

export function getErrorsFromAddress(
  address?: Address,
  recipientName?: string,
  recipientPhoneNumber?: string,
  companyName?: string,
  addressType?: DeliveryAddressType,
  validationErrors?: CommonError[]
): CommonError[] | undefined {
  const errors: CommonError[] = [];
  if (address) {
    if (address.line1.length === 0) {
      addEmptyFieldValidationError(errors, 'line1', t.VPVR(fieldCantBeEmptyMsg));
    }
    if (address.postOffice.trim().length === 0) {
      addEmptyFieldValidationError(errors, 'postOffice', t.VPVR(fieldCantBeEmptyMsg));
    }
    if (address.postalCode.length === 0) {
      addEmptyFieldValidationError(errors, 'postalCode', t.VPVR(fieldCantBeEmptyMsg));
    }
    if (recipientName?.length === 0) {
      addEmptyFieldValidationError(errors, 'recipientName', t.VPVR(fieldCantBeEmptyMsg));
    }
    if (recipientPhoneNumber?.length === 0) {
      addEmptyFieldValidationError(errors, 'recipientPhoneNumber', t.VPVR(fieldCantBeEmptyMsg));
    }
    if (addressType && addressType === DeliveryAddressType.COMPANY_ADDRESS && companyName?.trim().length === 0) {
      addEmptyFieldValidationError(errors, 'companyName', t.VPVR(fieldCantBeEmptyMsg));
    }
  }
  if (errors.length === 0) {
    return validationErrors;
  }
  return errors.concat(validationErrors || []);
}

export interface AddressWithType extends Address {
  type: DeliveryAddressType;
  recipientName: string;
  recipientPhoneNumber: string;
  companyName: string;
}

const getCountryCode = (address?: Address) => {
  return address?.countryCode || DEFAULT_COUNTRY_CODE;
};

interface DeliveryAddressFormValues {
  companyName: string;
  recipientName: string;
  recipientPhoneNumber: string;
  line1: string;
  line2?: string;
  postOffice: string;
  postalCode: string;
}

export interface DeliveryAddressFormProps {
  address?: Address;
  companyName?: string;
  deliveryAddressType: DeliveryAddressType;
  recipientName?: string;
  recipientPhoneNumber?: string;
  onSubmit: (values: AddressWithType, addrType: DeliveryAddressType) => void;
  onUpdateEditMode: (isEdit: boolean) => void;
  setEditMode: (isEdit: boolean) => void;
}

const DeliveryAddressForm = ({
  address,
  companyName,
  deliveryAddressType,
  recipientName,
  recipientPhoneNumber,
  onSubmit,
  onUpdateEditMode,
  setEditMode,
}: DeliveryAddressFormProps) => {
  const methods = useForm<DeliveryAddressFormValues>({
    defaultValues: {
      line1: address ? address.line1 : '',
      line2: address ? address.line2 : '',
      postOffice: address ? address.postOffice : '',
      postalCode: address ? address.postalCode : '',
      recipientName: recipientName || '',
      recipientPhoneNumber: recipientPhoneNumber || '',
      companyName: companyName || '',
    },
  });
  const { handleSubmit, getValues, formState } = methods;
  const { isDirty } = formState;

  const onConfirm = (values: DeliveryAddressFormValues) => {
    if (!isDirty) {
      if (onUpdateEditMode) {
        onUpdateEditMode(false);
      }
      setEditMode(false);
    } else {
      const { line1, line2, postOffice, postalCode } = values;
      onSubmit(
        {
          countryCode: getCountryCode(address),
          line1,
          line2,
          type: deliveryAddressType,
          postOffice,
          postalCode,
          recipientName: values.recipientName,
          recipientPhoneNumber: values.recipientPhoneNumber,
          companyName: values.companyName,
        },
        deliveryAddressType
      );
    }
    return true;
  };

  return (
    <FormProvider {...methods}>
      <CL.Grid className={dsClass.MARGIN_TOP_4}>
        {deliveryAddressType === DeliveryAddressType.COMPANY_ADDRESS && (
          <CL.GridRow>
            <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
              <TextInput
                name="companyName"
                label={t.AJ93(companyNameMsg)}
                placeholder={t.AJ93(companyNameMsg)}
                required
              />
            </CL.GridCol>
          </CL.GridRow>
        )}
        <CL.GridRow>
          <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
            <TextInput
              name="recipientName"
              label={t.U9EI('Recipient Name')}
              placeholder={t.U9EI('Recipient Name')}
              required
              maxLength={140}
            />
          </CL.GridCol>
          <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
            <PhoneNumber name="recipientPhoneNumber" label={t.O5R1('Recipient Phone Number')} required />
          </CL.GridCol>
        </CL.GridRow>
        <CL.GridRow>
          <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
            <TextInput
              name="line1"
              label={t.DD38(streetAddressMsg)}
              placeholder={t.DD38(streetAddressMsg)}
              required
              maxLength={40}
            />
          </CL.GridCol>
          <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
            <TextInput
              name="line2"
              label={t.O0QI(addressLine2Msg)}
              placeholder={t.O0QI(addressLine2Msg)}
              maxLength={40}
              required={false}
            />
          </CL.GridCol>
        </CL.GridRow>
        <CL.GridRow>
          <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
            <PostalCode name="postalCode" />
          </CL.GridCol>
          <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
            <TextInput
              name="postOffice"
              label={t.Z7S5(postOfficeMsg)}
              placeholder={t.Z7S5(postOfficeMsg)}
              required
              maxLength={100}
            />
          </CL.GridCol>
        </CL.GridRow>
        <CL.GridRow>
          <CL.GridCol colWidthXS={4}>
            <CL.Button type="submit" onClick={handleSubmit(() => onConfirm(getValues()))}>
              {t.QVYK(confirmMsg)}
            </CL.Button>
          </CL.GridCol>
        </CL.GridRow>
      </CL.Grid>
    </FormProvider>
  );
};

export const DeliveryAddress = ({
  address,
  companyName,
  deliveryAddressType,
  errors,
  isEditModeOn,
  submit,
  onUpdateEditMode,
  recipientName,
  recipientPhoneNumber,
}: DeliveryAddressProps) => {
  const [editMode, setEditMode] = useState(isEditModeOn || !address);
  const [addressWithType, setAddressWithType] = useState<AddressWithType>();

  useEffect(() => {
    if (!isEditModeOn) {
      if (onUpdateEditMode) {
        onUpdateEditMode(!address);
      }
      if (!errors) {
        setEditMode(!address);
      }
    }
    setAddressWithType({
      countryCode: getCountryCode(address),
      line1: address ? address.line1 : '',
      line2: address ? address.line2 : '',
      type: deliveryAddressType || DeliveryAddressType.COMPANY_ADDRESS,
      postOffice: address ? address.postOffice : '',
      postalCode: address ? address.postalCode : '',
      recipientName: recipientName || '',
      recipientPhoneNumber: recipientPhoneNumber || '',
      companyName: companyName || '',
    });
  }, [address]); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeAddressType = (selectedAddressType: DeliveryAddressType) => {
    setAddressWithType({
      countryCode: getCountryCode(address),
      line1: '',
      line2: '',
      type: selectedAddressType,
      postOffice: '',
      postalCode: '',
      recipientName: addressWithType?.recipientName || '',
      recipientPhoneNumber: addressWithType?.recipientPhoneNumber || '',
      companyName: addressWithType?.companyName || '',
    });
  };
  const radioItems = [
    {
      icon: <CL.Icon icon="office" size="l" />,
      id: 'companyAddress',
      name: t.VOF3('Company Address'),
      selected: !addressWithType?.type || addressWithType?.type === DeliveryAddressType.COMPANY_ADDRESS,
    },
    {
      icon: <CL.Icon icon="home" size="l" />,
      id: 'homeAddress',
      name: t.D4FU('Home Address'),
      selected: addressWithType?.type === DeliveryAddressType.HOME_ADDRESS,
    },
  ];
  const onChangeSelectGroup = (id: string) => {
    if (id) {
      onChangeAddressType(
        id === 'companyAddress' ? DeliveryAddressType.COMPANY_ADDRESS : DeliveryAddressType.HOME_ADDRESS
      );
    }
  };
  // These are used only when we're not in edit mode (and thus have an address),
  // so it's ok to just use deliveryAddressType and ignore addressWithType.type.
  const addressInfoText =
    deliveryAddressType === DeliveryAddressType.COMPANY_ADDRESS ? t.A9VQ(companyAddressMsg) : t.S5QX(homeAddressMsg);
  const deliveryRecipientName =
    deliveryAddressType === DeliveryAddressType.COMPANY_ADDRESS ? companyName : recipientName;
  const deliveryIcon = deliveryAddressType === DeliveryAddressType.COMPANY_ADDRESS ? 'office' : 'home';

  return (
    <div className="of-delivery-address">
      {editMode ? (
        <>
          <CL.SelectGroup
            className={dsClass.PADDING_0}
            products={radioItems}
            onChange={onChangeSelectGroup}
            value={radioItems.find(i => i.selected)?.id}
          />
          <DeliveryAddressForm
            address={address}
            companyName={companyName}
            recipientName={recipientName}
            recipientPhoneNumber={recipientPhoneNumber}
            deliveryAddressType={addressWithType ? addressWithType.type : DeliveryAddressType.COMPANY_ADDRESS}
            onSubmit={submit}
            onUpdateEditMode={onUpdateEditMode}
            setEditMode={setEditMode}
          />
        </>
      ) : (
        <div className="of-delivery-address--column">
          <div className="of-delivery-address--icon">
            <CL.Icon icon={deliveryIcon} size="l" />
          </div>
          <div className="of-delivery-address--title">
            <strong>{addressInfoText}:</strong>
          </div>
          <div className="of-delivery-address--left">
            <div>{deliveryRecipientName ? ` ${deliveryRecipientName}, ` : ''}</div>
            <div>{`${address!.line1}${address!.line2 ? ' ' + address!.line2 : ''}, `}</div>
            <div>{`${address!.postalCode} ${address!.postOffice}`}</div>
            <div>{recipientPhoneNumber ? `${t.VG7P('Tel. ')} ${recipientPhoneNumber}` : ''}</div>
          </div>
          <div className="of-delivery-address--right">
            <CL.Button
              color="link"
              onClick={() => {
                if (onUpdateEditMode) {
                  onUpdateEditMode(true);
                }
                setEditMode(true);
              }}
            >
              {t.NVPK(editMsg)}
            </CL.Button>
          </div>
        </div>
      )}
    </div>
  );
};
