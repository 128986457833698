import * as CL from '@design-system/component-library';
import { Catalog } from '../../generated/api/models.js';
import { DetailsWithCard } from '../DetailswithCard/DetailsWithCard.js';
import { DialogWrapper } from '../DialogWrapper/index.js';
import { HeroHeading, HeroHeadingType } from '../HeroHeading/index.js';
import {
  cancelMsg,
  continueMsg,
  contractPeriodMsg,
  contractTypeMsg,
  deviceListMsg,
  deviceShopCardHeaderMsg,
  deviceShopCardSection1HeaderMsg,
  deviceShopCardSection1Msg,
  deviceShopCardSection2HeaderMsg,
  deviceShopCardSection2Msg,
  deviceShopCardSection3HeaderMsg,
  deviceShopCardSection3Msg,
  deviceShopHeaderMsg,
  deviceShopListFooterMsg,
  deviceShopListHeaderMsg,
  deviceShopListInfoMsg,
  deviceShopSubtitleMsg,
  elisaDevicesServiceMsg,
  employeeOmaElisaMsg,
  monthMsg,
  monthlyChargeMsg,
  oneTimePaymentMsg,
  shopMsg,
  t,
} from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { getVatAsString } from '../../common/utils/taxUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { resolveCategoryUrl } from '../../common/utils/catalogUtils.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useEffect, useState } from 'react';

import './EmployeeDeviceStoreHomeScene.scss';

interface EmployeeDeviceStoreHomeSceneProps {
  catalogs: Catalog[];
  onSelectCatalog: (selectedCatalogCode: string) => void;
  clearCartItems: () => void;
  selectedCatalogCode?: string | null;
  hasCartItems: boolean;
  onClickCatalog: (category?: string) => void;
}

const ConfirmationModal = ({ onSubmit, onCancel }: { onSubmit: () => void; onCancel: () => void }) => (
  <DialogWrapper
    buttons={[
      {
        color: 'link',
        onClick: onCancel,
        text: t.B2V1(cancelMsg),
      },
      {
        onClick: onSubmit,
        text: t.I62A(continueMsg),
      },
    ]}
    closeable
    header={t.THL9('The order can only have products from one catalog.')}
    onCloseDialog={onCancel}
  >
    <p>
      {t.NIY2(
        'Complete the current order and then order the products from a different catalog. If you continue to another device list, your shopping cart will be automatically emptied.'
      )}
    </p>
  </DialogWrapper>
);

const DeviceShopInstructions = () => (
  <>
    <h3>{t.XF0E(deviceShopCardHeaderMsg)}</h3>
    <h4 className={`${dsClass.MARGIN_TOP_4} ${dsClass.MARGIN_BOTTOM_0}`}>{t.FA5L(deviceShopCardSection1HeaderMsg)}</h4>
    <p className={`${dsClass.TEXT_S} ${dsClass.MARGIN_TOP_0}`}>{t.JXVW(deviceShopCardSection1Msg)}</p>
    <h4 className={`${dsClass.MARGIN_TOP_4} ${dsClass.MARGIN_BOTTOM_0}`}>{t.XVZ7(deviceShopCardSection2HeaderMsg)}</h4>
    <p className={`${dsClass.TEXT_S} ${dsClass.MARGIN_TOP_0}`}>{t.JGSO(deviceShopCardSection2Msg)}</p>
    <h4 className={`${dsClass.MARGIN_TOP_4} ${dsClass.MARGIN_BOTTOM_0}`}>{t.AWNU(deviceShopCardSection3HeaderMsg)}</h4>
    <p className={`${dsClass.TEXT_S} ${dsClass.MARGIN_TOP_0}`}>{t.WAEN(deviceShopCardSection3Msg, getVatAsString())}</p>
  </>
);

export const EmployeeDeviceStoreHomeScene = (props: EmployeeDeviceStoreHomeSceneProps) => {
  const [selectedCatalogId, setSelectedCatalogId] = useState<string | undefined>();
  const { authenticatedUser } = useAuth();
  const { catalogs, clearCartItems, onClickCatalog, onSelectCatalog, selectedCatalogCode, hasCartItems } = props;
  useEffect(() => {
    if (
      authenticatedUser?.enabledFeatureFlags?.includes('catalogPreSelected') &&
      catalogs.length === 1 &&
      catalogs[0].catalogCode
    ) {
      onClickCatalog(resolveCategoryUrl(catalogs, catalogs[0].catalogCode));
    }
  }, [catalogs, authenticatedUser, onClickCatalog]);

  const columns = [
    { key: 'deviceList', label: t.Z0HF(deviceListMsg), width: '40%' },
    { key: 'contractType', label: t.DQQ1(contractTypeMsg), width: '30%' },
    { key: 'contractPeriod', label: t.D55M(contractPeriodMsg), width: '30%' },
  ];

  const getContractType = (catalog: Catalog) => {
    switch (catalog.productType) {
      case Catalog.ProductTypeEnum.EPP_RECURRING:
        return t.TRE5(elisaDevicesServiceMsg);
      case Catalog.ProductTypeEnum.ONETIME:
        return t.ASEI(oneTimePaymentMsg);
      case Catalog.ProductTypeEnum.RECURRING:
        return t.P0PS(monthlyChargeMsg);
      default:
        return '-';
    }
  };

  const rows = catalogs.map(catalog => {
    return {
      deviceList: catalog.name,
      contractType: getContractType(catalog),
      contractPeriod: catalog.contractPeriod ? `${catalog.contractPeriod} ${t.XXVX(monthMsg)}` : '—',
    };
  });

  const onRowClicks = catalogs.map(catalog => {
    const code = catalog.catalogCode!;
    return () => {
      if (code !== selectedCatalogCode && hasCartItems) {
        setSelectedCatalogId(code);
      } else {
        onSelectCatalog(code);
        onClickCatalog(resolveCategoryUrl(catalogs, code));
      }
    };
  });

  return (
    <div className="of-employee-device-store">
      <HeroHeading
        center={true}
        fullScreen={true}
        breadCrumbPaths={[{ name: t.H6JV(employeeOmaElisaMsg), path: paths.EMPLOYEE_HOME }, { name: t.BZGP(shopMsg) }]}
        heroHeadingType={HeroHeadingType.BLANK}
        title={t.LEO9(deviceShopHeaderMsg)}
        subtitle={t.KB1F(deviceShopSubtitleMsg)}
        smallSubtitle={true}
      />
      <DetailsWithCard cardImage="7B7Qg8qhvIR4tBWxqWTxJy/i.svg" cardContent={<DeviceShopInstructions />}>
        <h2>{t.OTQ4(deviceShopListHeaderMsg)}</h2>
        <p>{t.JNQG(deviceShopListInfoMsg)}</p>
        <CL.Table
          className={dsClass.PADDING_VERTICAL_4}
          columns={columns}
          rows={rows}
          rowClicks={onRowClicks}
          hover={true}
        />
        <p className={`${dsClass.TEXT_S} ${dsClass.MARGIN_BOTTOM_6}`}>{t.VBQK(deviceShopListFooterMsg)}</p>
      </DetailsWithCard>
      {selectedCatalogId && (
        <ConfirmationModal
          onCancel={() => setSelectedCatalogId(undefined)}
          onSubmit={() => {
            clearCartItems();
            onSelectCatalog(selectedCatalogId);
            onClickCatalog(resolveCategoryUrl(catalogs, selectedCatalogId));
          }}
        />
      )}
    </div>
  );
};
