import * as CL from '@design-system/component-library';
import { Checkbox } from '../../../common/react-hook-form/components/Checkbox.js';
import { FormProvider, useForm } from 'react-hook-form';
import { formatRecordListEditRow } from './ImpactedUsersListRow/ImpactedUsersListRowEdit.js';
import { formatRecordListViewRow } from './ImpactedUsersListRow/ImpactedUsersListRowView.js';
import { useEffect, useState } from 'react';

import { type DefaultListSearchParams, Table } from '../../Table/index.js';
import { GridWide } from '../../Grid/Grid.js';
import { ImpactedUserEditableFields } from './utils.js';
import { TypeKeys } from '../../../selfservice/actions/index.js';
import { administratorMsg, emailMsg, numberMsg, t } from '../../../common/i18n/index.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { formatPhoneNumber } from '../../../common/utils/phoneNumberUtils.js';
import { getArrayPropertySorter } from '../../../common/utils/arrayUtils.js';
import { isInBrowser } from '../../../common/utils/ssrUtils.js';
import { updateSaUser } from '../../../common/fetch.js';
import { useDispatch } from 'react-redux';
import { useRevalidator } from 'react-router-dom';
import { useSearchParams } from '../../../common/hooks/useSearchParams.js';
import type { AccountKeyUser } from '../../../generated/api/accountKeyUser.js';
import type { EnableMfa, ImpactedUser } from './utils.js';

const DISCLAIMER_ID = 'dns-record-edit-disclaimer';
const MISSING_DATA_DISCLAIMER_ID = 'missing-data-disclaimer';

interface ReviewImpactedUsersAndSubmitProps {
  accountKeyUsers: Array<AccountKeyUser>;
  onSubmit: () => void;
}

const scrollToDisclaimer = (element: HTMLElement | null) => {
  const disclaimerTop = element?.getBoundingClientRect()?.top;
  if (disclaimerTop && disclaimerTop < 0) {
    element?.scrollIntoView({ behavior: 'smooth' });
  }
};

export const ReviewImpactedUsersAndSubmit = ({ accountKeyUsers, onSubmit }: ReviewImpactedUsersAndSubmitProps) => {
  const reValidator = useRevalidator();
  const dispatch = useDispatch();
  const impactedUsersFormMethods = useForm<ImpactedUser>({ mode: 'all' });
  const enableMfaFormMethods = useForm<EnableMfa>({ mode: 'all' });

  const [editingItemId, setEditingItemId] = useState<string>();
  const [showOnlyOneEditMessage, setShowOnlyOneEditMessage] = useState(false);
  const { sort, order } = useSearchParams<DefaultListSearchParams>();

  useEffect(() => {
    // If the banner is out of the view, scroll to it so that the user can see the warning
    if (showOnlyOneEditMessage && isInBrowser()) {
      const disclaimerElement = document.getElementById(DISCLAIMER_ID);
      scrollToDisclaimer(disclaimerElement);
    } else if (editingItemId && isInBrowser()) {
      const disclaimerElement = document.getElementsByName(ImpactedUserEditableFields.mobile)[0];
      scrollToDisclaimer(disclaimerElement);
      impactedUsersFormMethods.setFocus(ImpactedUserEditableFields.mobile);
    }
  }, [showOnlyOneEditMessage, editingItemId, impactedUsersFormMethods]);

  const onCancelEdit = () => {
    setEditingItemId(undefined);
    setShowOnlyOneEditMessage(false);
  };

  const onOpenEdit = (row: ImpactedUser) => {
    if (editingItemId !== undefined) {
      setShowOnlyOneEditMessage(true);
    } else {
      impactedUsersFormMethods.setValue(ImpactedUserEditableFields.userName, row.userName);
      impactedUsersFormMethods.setValue(ImpactedUserEditableFields.email, row.email);
      impactedUsersFormMethods.setValue(ImpactedUserEditableFields.mobile, row.mobile);

      // Trigger here clears the validation errors that were caused by the previous values
      impactedUsersFormMethods.trigger().then();
      setEditingItemId(row.userName);
    }
  };

  const onSubmitRow = async () => {
    if (editingItemId) {
      try {
        const { userName, email, mobile } = impactedUsersFormMethods.getValues();
        await updateSaUser({
          userName,
          email,
          mobile: formatPhoneNumber(mobile, true),
        });
        dispatch({ type: TypeKeys.UPDATE_SSO_USER_FULFILLED });
      } catch {
        dispatch({ type: TypeKeys.UPDATE_SSO_USER_FAILED });
      } finally {
        setEditingItemId(undefined);
        setShowOnlyOneEditMessage(false);
        reValidator.revalidate();
      }
    }
  };

  const columns = [
    { key: 'userName', label: t.R51P(administratorMsg), sortable: true, width: '20%' },
    { key: 'mobile', label: t.CCB1(numberMsg), sortable: true, width: '20%' },
    { key: 'email', label: t.ZVQ5(emailMsg), sortable: true, width: '30%' },
    { key: 'actionButtons', label: '', sortable: false, width: '30%' },
  ] satisfies CL.Column[];

  const impactedUsers = accountKeyUsers
    .map(row => ({
      userName: row.userName,
      mobile: formatPhoneNumber(row.mobile, true),
      email: row.email,
    }))
    .sort(getArrayPropertySorter(sort, order));

  const keyUserWithMissingData = impactedUsers.filter(user => !user.mobile || !user.email);

  const rows = impactedUsers.map(row =>
    editingItemId === row.userName
      ? formatRecordListEditRow(row, onCancelEdit, impactedUsersFormMethods.formState.isSubmitting)
      : formatRecordListViewRow(row, onOpenEdit)
  ) satisfies CL.KeyRow[];
  const onValidSubmitMfaEnable = () => {
    if (keyUserWithMissingData.length > 0) {
      const disclaimerElement = document.getElementById(MISSING_DATA_DISCLAIMER_ID);
      scrollToDisclaimer(disclaimerElement);
    } else {
      onSubmit();
    }
  };

  const onInvalidSubmitMfaEnable = () => {
    if (keyUserWithMissingData.length > 0) {
      const disclaimerElement = document.getElementById(MISSING_DATA_DISCLAIMER_ID);
      scrollToDisclaimer(disclaimerElement);
    }
  };

  return (
    <GridWide>
      <div>
        <div className="of-impacted-users-list">
          {showOnlyOneEditMessage && (
            <CL.Disclaimer
              id={DISCLAIMER_ID}
              className={dsClass.MARGIN_VERTICAL_3}
              icon={<CL.Icon icon="information" type="filled" aria-hidden="true" />}
              disclaimerType="warning"
              showCloseButton
              onDisclaimerHide={() => setShowOnlyOneEditMessage(false)}
            >
              <strong>{t.NH88('Record editing in progress')}</strong>
              <br />
              <p>{t.SNOO('You can only edit one record at a time. Save or cancel current changes.')}</p>
            </CL.Disclaimer>
          )}
          {keyUserWithMissingData.length > 0 && (
            <CL.Disclaimer
              className={dsClass.MARGIN_VERTICAL_3}
              id={MISSING_DATA_DISCLAIMER_ID}
              icon={<CL.Icon icon="error" type="filled" aria-hidden="true" />}
              disclaimerType="alert"
              title="Tarkista ja korjaa seuraavat kohdat:"
            >
              <ul>
                {keyUserWithMissingData.map(user => (
                  <li key={user.userName}>
                    <CL.Link
                      onClick={() => {
                        onOpenEdit(user);
                      }}
                    >
                      Missing information
                    </CL.Link>
                  </li>
                ))}
              </ul>
            </CL.Disclaimer>
          )}
          <FormProvider {...impactedUsersFormMethods}>
            <form onSubmit={impactedUsersFormMethods.handleSubmit(onSubmitRow)} className={dsClass.MARGIN_VERTICAL_6}>
              <Table
                columns={columns}
                noItemsText={t.VA1O('No records')}
                onSortChange={() => {}}
                rows={rows}
                valignText="top"
              />
            </form>
          </FormProvider>
          <FormProvider {...enableMfaFormMethods}>
            <form onSubmit={enableMfaFormMethods.handleSubmit(onValidSubmitMfaEnable, onInvalidSubmitMfaEnable)}>
              <Checkbox
                name="confirmUserDetails"
                label={t.QHG2('I have checked that the details of all the administrators are correct and up to date.')}
                required={true}
                error={t.EKYF('Confirm you have checked that the details of all the administrators are correct')}
              />
              <div className={dsClass.MARGIN_TOP_6}>
                <CL.Button size="l" type="submit">
                  {t.XFA9('Activate')}
                </CL.Button>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </GridWide>
  );
};
