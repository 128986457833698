import { useEffect, useState } from 'react';

export const useReload = (): [boolean, () => void] => {
  const [reload, setReload] = useState(false);

  useEffect(() => {
    reload && setReload(false);
  }, [reload]);

  return [reload, () => setReload(true)];
};
