import * as CL from '@design-system/component-library';
import * as RP from '../../legacy/react-patterns/source/index.js';
import { Anchor } from '../Anchor/Anchor.js';
import { CompositeListHeader } from '../CompositeListHeader/CompositeListHeader.js';
import { CompositeListRow, getCellLines } from '../CompositeListRow/index.js';
import { CompositeListSearch, valueInCellValueLines } from '../CompositeListSearch/index.js';
import { CompositeListTop } from '../CompositeListTop/index.js';
import { PureComponent } from 'react';
import { getReferencedItemValue } from '../CompositeListRow/compositeListRowUtils.js';
import { t } from '../../common/i18n/index.js';
import classNames from 'classnames';
import type { CompositeListColumn, CompositeListSort } from '../CompositeListHeader/CompositeListHeader.js';
import type { CompositeListSearchProps } from '../CompositeListSearch/index.js';
import type { NonVisibleField } from '../../common/utils/searchFieldUtils.js';

import './CompositeList.scss';

export interface ClickableText {
  onClick: () => void;
  text: string;
}

export interface CompositeListProps<T extends object> {
  classes?: string[];
  columns: CompositeListColumn<T>[];
  emptyListElement?: JSX.Element;
  extraEmptySearchResultLink?: ClickableText;
  headerClasses?: string[];
  items?: T[];
  itemsSearchResultsMatchedFields?: string[][];
  onSelectRow?: (rowId: string, item: T) => void;
  onSort?: (sort: CompositeListSort) => void;
  getExternalLink?: (item: T) => string | undefined;
  getRowId: (item: T) => string;
  mobileTwoColumns?: boolean | ((item: T) => boolean);
  mobileTableLayout?: boolean; // if true, overrides mobileTwoColumns
  showHintIcon?: (item: T) => boolean;
  imagesBaseUrl?: string;
  rowIconImageUrl?: string | ((item: T) => string | undefined);
  rowIcon?: string | ((item: T) => string | undefined);
  rowIconClasses?: string[] | ((item: T) => string[] | undefined);
  rowClasses?: string[] | ((item: T) => string[] | undefined);
  search?: CompositeListSearchProps; // overrides "heading" if set
  sort?: CompositeListSort;
  tight?: boolean; // if set, the composite list does not have left or right paddings
  topClasses?: string[];
  topLeft?: JSX.Element; // alternative to search
  topRight?: JSX.Element | JSX.Element[];
  topRightLink?: ClickableText;
  topRightHideOnMobile?: boolean;
  topWrapOnMobile?: boolean;
  wrapRows?: (rows: JSX.Element[]) => JSX.Element;
  hideHeader?: boolean;
  checkListLayout?: boolean;
  onClickCheckedRow?: (rowId: string, item: T, checked: boolean) => void;
  isItemChecked?: (item: T) => boolean;
  nonVisibleFields?: NonVisibleField[];
  isReadOnly?: (item: T) => boolean;
  onClickSelectAll?: (selected: boolean) => void;
  selectAll?: boolean;
  showSelectAll?: boolean;
}

function getFilteredItems<T extends object>(
  columns: CompositeListColumn<T>[],
  items?: T[],
  search?: CompositeListSearchProps
): T[] | undefined {
  if (!items) {
    return undefined;
  }
  if (!search) {
    return items;
  }
  const { value } = search;
  if (!value || value.length === 0) {
    return items;
  }
  const searchColumns = columns.filter(column => !search.columnIds || search.columnIds.includes(column.columnId));
  const searchKeys = search.objectKeys || [];
  if (searchColumns.length === 0 && searchKeys.length === 0) {
    return items;
  }

  return items.filter(item => {
    const cellValueLines = searchColumns.map(searchColumn =>
      getCellLines<T>(
        item,
        searchColumn.value,
        searchColumn.ref,
        searchColumn.refFormatNumber,
        searchColumn.refFormatString
      )
    );
    searchKeys
      .map(searchKey => {
        const searchValue = getReferencedItemValue(item, searchKey);
        return searchValue && typeof searchValue === 'string' ? [searchValue] : [];
      })
      .forEach(cellLine => cellValueLines.push(cellLine));
    return valueInCellValueLines(value, cellValueLines);
  });
}

function getCompositeListRows<T extends object>(
  columns: CompositeListColumn<T>[],
  getRowId: (item: T) => string,
  items?: T[],
  getExternalLink?: (item: T) => string | undefined,
  onSelectRow?: (rowId: string, item: T) => void,
  rowClasses?: string[] | ((item: T) => string[] | undefined),
  showHintIcon?: (item: T) => boolean,
  imagesBaseUrl?: string,
  rowIconImageUrl?: string | ((item: T) => string | undefined),
  rowIcon?: string | ((item: T) => string | undefined),
  rowIconClasses?: string[] | ((item: T) => string[] | undefined),
  tight?: boolean,
  mobileTwoColumns?: boolean | ((item: T) => boolean),
  mobileTableLayout?: boolean,
  checkListLayout?: boolean,
  onClickCheckedRow?: (rowId: string, item: T, checked: boolean) => void,
  isItemChecked?: (item: T) => boolean,
  searchKeyword?: string,
  nonVisibleFields?: NonVisibleField[],
  isReadOnly?: (item: T) => boolean,
  itemsSearchResultsMatchedFields?: string[][]
) {
  if (items) {
    return items.map((item, index) => {
      const rowIdString = getRowId(item);

      let rowClassesArray: string[] | undefined;
      if (rowClasses) {
        if (Array.isArray(rowClasses)) {
          rowClassesArray = rowClasses;
        } else if (typeof rowClasses === 'function') {
          rowClassesArray = rowClasses(item);
        }
      }

      let rowIconImageUrlString: string | undefined;
      if (rowIconImageUrl) {
        if (typeof rowIconImageUrl === 'string') {
          rowIconImageUrlString = rowIconImageUrl;
        } else if (typeof rowIconImageUrl === 'function') {
          rowIconImageUrlString = rowIconImageUrl(item);
        }
      }

      let hintIconVisible: boolean | undefined;
      if (showHintIcon) {
        hintIconVisible = showHintIcon(item);
      }

      let rowIconString: string | undefined;
      let rowIconClassesArray: string[] | undefined;
      if (rowIcon !== undefined) {
        if (typeof rowIcon === 'string') {
          rowIconString = rowIcon;
        } else if (rowIcon && typeof rowIcon === 'function') {
          rowIconString = rowIcon(item);
        }
        if (rowIconClasses) {
          if (Array.isArray(rowIconClasses)) {
            rowIconClassesArray = rowIconClasses;
          } else if (typeof rowIconClasses === 'function') {
            rowIconClassesArray = rowIconClasses(item);
          }
        }
      }

      let mobileTwoColumnsValue: boolean | undefined;
      if (mobileTwoColumns) {
        if (typeof mobileTwoColumns === 'boolean') {
          mobileTwoColumnsValue = mobileTwoColumns;
        } else if (typeof mobileTwoColumns === 'function') {
          mobileTwoColumnsValue = mobileTwoColumns(item);
        }
      }
      const rowContent = (
        <CompositeListRow
          columns={columns}
          externalLink={getExternalLink && getExternalLink(item)}
          item={item}
          key={`cl_${rowIdString}_${index}`}
          mobileTableLayout={mobileTableLayout}
          mobileTwoColumns={mobileTwoColumnsValue}
          onSelect={onSelectRow ? () => onSelectRow(rowIdString, item) : undefined}
          rowClasses={rowClassesArray}
          showHintIcon={hintIconVisible}
          imagesBaseUrl={imagesBaseUrl}
          rowIconImageUrl={rowIconImageUrlString}
          rowIcon={rowIconString}
          rowIconClasses={rowIconClassesArray}
          rowId={rowIdString}
          tight={tight}
          checkListLayout={checkListLayout}
          searchKeyword={searchKeyword}
          nonVisibleFields={nonVisibleFields}
          isReadOnly={isReadOnly && isReadOnly(item)}
          itemSearchResultMatchedFields={itemsSearchResultsMatchedFields?.[index]}
        />
      );
      return checkListLayout ? (
        <div className="ea-composite-list__rows--checkListLayout" key={`${rowIdString}_${index}`}>
          <RP.FluidGrid>
            <RP.FluidGridItem phone={1} tablet={1}>
              <CL.Checkbox
                id={`${rowIdString}_${index}`}
                name={`${rowIdString}_${index}`}
                key={`${rowIdString}_${index}_${isItemChecked && isItemChecked(item)}`}
                checked={isItemChecked && isItemChecked(item)}
                onChange={onClickCheckedRow ? e => onClickCheckedRow(rowIdString, item, e.target.checked) : undefined}
              />
            </RP.FluidGridItem>
            <RP.FluidGridItem phone={5} tablet={11}>
              <label htmlFor={`${rowIdString}_${index}`}>{rowContent}</label>
            </RP.FluidGridItem>
          </RP.FluidGrid>
        </div>
      ) : (
        rowContent
      );
    });
  } else {
    return ['loading-row-1', 'loading-row-2', 'loading-row-3'].map((id, index) => {
      return (
        <CompositeListRow
          columns={columns}
          key={`cl_${id}_${index}`}
          rowClasses={['ea-composite-list__loading-row']}
          rowId={id}
        />
      );
    });
  }
}

export class CompositeList<T extends object> extends PureComponent<CompositeListProps<T>> {
  render() {
    const {
      classes,
      columns,
      emptyListElement,
      extraEmptySearchResultLink,
      headerClasses,
      getExternalLink,
      getRowId,
      items,
      mobileTableLayout,
      mobileTwoColumns,
      onSelectRow,
      onSort,
      rowClasses,
      showHintIcon,
      imagesBaseUrl,
      rowIconImageUrl,
      rowIcon,
      rowIconClasses,
      search,
      sort,
      tight,
      topClasses,
      topLeft,
      topRight,
      topRightHideOnMobile,
      topRightLink,
      topWrapOnMobile,
      wrapRows,
      hideHeader,
      checkListLayout,
      onClickCheckedRow,
      isItemChecked,
      nonVisibleFields,
      isReadOnly,
      onClickSelectAll,
      selectAll,
      showSelectAll,
      itemsSearchResultsMatchedFields,
    }: CompositeListProps<T> = this.props;

    if (checkListLayout && columns.length > 2) {
      throw new Error('Columns length can not be more than 2 if the layout is checkList');
    }

    const filtered = getFilteredItems(columns, items, search);
    const rows = getCompositeListRows(
      columns,
      getRowId,
      filtered,
      getExternalLink,
      onSelectRow,
      rowClasses,
      showHintIcon,
      imagesBaseUrl,
      rowIconImageUrl,
      rowIcon,
      rowIconClasses,
      tight,
      mobileTwoColumns,
      mobileTableLayout,
      checkListLayout,
      onClickCheckedRow,
      isItemChecked,
      search?.value,
      nonVisibleFields,
      isReadOnly,
      itemsSearchResultsMatchedFields
    );
    const wrappedRows: JSX.Element | undefined = wrapRows && wrapRows(rows);
    const topLeftElement = search ? <CompositeListSearch {...search} /> : topLeft ? topLeft : undefined;
    let topRightElement: JSX.Element | JSX.Element[] | undefined;
    if (topRightLink) {
      topRightElement = (
        <Anchor className="ea-composite-list-top__right-link" onClick={topRightLink.onClick}>
          {topRightLink.text}
          <span className="ea-icon ea-icon--arrow-right" />
        </Anchor>
      );
    } else {
      topRightElement = topRight;
    }

    let emptySearchResultElement: JSX.Element | undefined;
    if (extraEmptySearchResultLink) {
      emptySearchResultElement = (
        <Anchor onClick={extraEmptySearchResultLink.onClick}>{extraEmptySearchResultLink.text}</Anchor>
      );
    }

    return (
      <CL.Grid>
        <CL.GridRow>
          <CL.GridCol colWidthXS={4}>
            <div className={classNames('ea-composite-list', classes)}>
              <div className="ea-composite-list__top">
                <CompositeListTop
                  left={topLeftElement}
                  right={topRightElement}
                  rightHideOnMobile={topRightHideOnMobile}
                  classes={topClasses}
                  wrapOnMobile={topWrapOnMobile}
                />
              </div>
              <div
                className={classNames(
                  'ea-composite-list__header',
                  checkListLayout && 'ea-composite-list__header--checkListLayout',
                  hideHeader && 'ea-composite-list__header--hide'
                )}
              >
                <CompositeListHeader
                  classes={headerClasses}
                  columns={columns}
                  mobileTableLayout={mobileTableLayout}
                  onSort={onSort}
                  sort={sort}
                  tight={tight}
                  checkListLayout={checkListLayout}
                  onClickSelectAll={onClickSelectAll}
                  selectAll={selectAll}
                  showSelectAll={showSelectAll}
                />
              </div>
              {rows.length < 1 && search && search.value ? (
                <div className="ea-composite-list__empty">
                  {t.OQBT('No results for')} <strong>{`"${search.value}"`}</strong>.
                  <span> {emptySearchResultElement}</span>
                </div>
              ) : wrappedRows || rows.length > 0 ? (
                <div className="ea-composite-list__rows">{wrappedRows ? wrappedRows : rows}</div>
              ) : emptyListElement ? (
                <div className="ea-composite-list__empty">{emptyListElement}</div>
              ) : undefined}
            </div>
          </CL.GridCol>
        </CL.GridRow>
      </CL.Grid>
    );
  }
}
