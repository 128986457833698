/**
 * Online UI API
 * # Summary Elisa B2B Online UI API  # Usage This API is used in the `https://yrityksille.elisa.fi/` pages.  # Identifiers 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ValidatePortableResponse { 
    /**
     * Whether or not the number is portable in to Elisa via MNP.
     */
    portable: boolean;
    /**
     * Machine-readable error code if portable is false.
     */
    errorType?: ValidatePortableResponse.ErrorTypeEnum;
    /**
     * Human readable (english) error message if portable is false.
     */
    errorMessage?: string;
}
export namespace ValidatePortableResponse {
    export type ErrorTypeEnum = 'NONPORTABLE_NUMBER' | 'INVALID_NUMBER_FORMAT' | 'TECHNICAL_ERROR';
    export const ErrorTypeEnum = {
        NONPORTABLE_NUMBER: 'NONPORTABLE_NUMBER' as ErrorTypeEnum,
        INVALID_NUMBER_FORMAT: 'INVALID_NUMBER_FORMAT' as ErrorTypeEnum,
        TECHNICAL_ERROR: 'TECHNICAL_ERROR' as ErrorTypeEnum
    };
}


