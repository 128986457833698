import * as CL from '@design-system/component-library';
import { dsClass } from '../../common/constants/dsClasses.js';
import { getCatalogModificationStatus } from '../../common/utils/catalogUtils.js';
import type { VirtualCatalogStatus } from '../../generated/api/virtualCatalogStatus.js';

import './ModificationStatus.scss';

interface ModificationStatusProps {
  status: VirtualCatalogStatus;
  noSubtext?: boolean;
}

export const ModificationStatus = (props: ModificationStatusProps) => {
  const { status, noSubtext } = props;
  const { color, text, subtext } = getCatalogModificationStatus(status);

  return (
    <div className={`of-modification-status ${dsClass.DISPLAY_FLEX} ${dsClass.ALIGN_ITEMS_CENTER}`}>
      <CL.Badge type="status" color={color} className={`of-modification-status-badge-${color}`} />
      <div>
        <div>{text}</div>
        {!noSubtext && <div className={dsClass.FONT_SIZE_SMALLER}>{subtext}</div>}
      </div>
    </div>
  );
};
