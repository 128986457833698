import './PhaseIndicator.scss';

export interface PhaseIndicatorProps {
  phases: { displayName: string }[];
  currentPhaseIndex: number;
}

export const PhaseIndicator = ({ phases, currentPhaseIndex }: PhaseIndicatorProps) => (
  <div className="ea-phaseindicator ea-numbered-discs">
    {phases.map((phase, i) => (
      <div className="ea-phaseindicator__item" key={i}>
        <span
          className={`ea-numbered-discs__item ${currentPhaseIndex >= i ? 'ea-numbered-discs__item--highlight' : ''}`}
        />
        <span className="ea-phaseindicator__text">{phase.displayName}</span>
      </div>
    ))}
  </div>
);
