import { getPbxSettingsFields } from '../subscriptionDetailsPbx';
import { getPbxTechnicalDetailsContent, renderContent } from '../subscriptionDetailsPbxCommon';
import type { Accordion } from '../../../common/utils/accordionUtils';
import type { Subscription } from '../../../generated/api/subscription';
import type { SubscriptionDetailsPbxSolution } from '../../../generated/api/subscriptionDetailsPbxSolution';

export const getMobilePbxAccordion = (
  headerName: string,
  subscriptionInput: Subscription,
  detailsInput?: SubscriptionDetailsPbxSolution
): Accordion => ({
  id: 'barring-services',
  headerName,
  includedData: detailsInput ? getPbxTechnicalDetailsContent(subscriptionInput, detailsInput) : <></>,
  displayed: !!detailsInput,
});

export const getMobilePbxSettingsAccordion = (
  headerName: string,
  detailsInput?: SubscriptionDetailsPbxSolution
): Accordion => ({
  id: 'barring-services-settings',
  headerName,
  includedData: detailsInput ? renderContent(getPbxSettingsFields(detailsInput)) : <></>,
  displayed: !!detailsInput,
});
