import { ContactPublic } from '../../../components/ContactPublic/ContactPublic.js';
import { Navigate } from 'react-router-dom';
import { paths } from '../../../common/constants/pathVariables.js';
import { useAuth } from '../AuthProvider.js';

export const SupportCasePublicPath = () => {
  const { authenticatedUser, ssoSessionValid } = useAuth();
  return ssoSessionValid && authenticatedUser ? (
    // navigate only if MFA is not required, otherwise navigation from MfaOtP form after OTP validated
    <Navigate to={paths.SUPPORT_CASE_NEW} />
  ) : (
    <ContactPublic />
  );
};
