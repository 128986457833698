import * as CL from '@design-system/component-library';

/**
 * Wrap CL.Tooltip inside TooltipWrapper
 * CL.Popover currently broken so it can't be used
 */
export const TooltipWrapper = ({ tooltipText }: { tooltipText?: string }) => {
  if (!tooltipText) {
    return null;
  }

  return (
    <>
      <style>{`.custom-icon { width: 20px; height: 20px; vertical-align: text-bottom }`}</style>
      <CL.Tooltip
        triggerElement={<CL.Icon className="custom-icon" color="secondary-blue-600" icon="information" size="m" />}
        placement="top"
        i18n_tooltip_contentText={tooltipText}
      />
    </>
  );
};
