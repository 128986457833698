import * as CL from '@design-system/component-library';
import { SiteContext } from '../../public/site/SiteContext.js';
import { showAllSubscriptionsMsg, t } from '../../common/i18n/index.js';
import { useContext } from 'react';

interface DisclaimerProps {
  handleShowAllProducts: () => void;
  showAllProducts: boolean;
}

// TODO: Use template strings for formatted strings
export const Disclaimer = ({ handleShowAllProducts, showAllProducts }: DisclaimerProps) => {
  const { siteBaseUrl } = useContext(SiteContext);
  return (
    <>
      {!showAllProducts && (
        <div className="of-order-subscription-selection__show-more">
          <CL.Button color="link" onClick={handleShowAllProducts}>
            <>
              <CL.Icon icon="arrow-down" size="l" /> {t.WLCJ(showAllSubscriptionsMsg)}
            </>
          </CL.Button>
        </div>
      )}
      <div className="of-order-subscription-selection of-order-subscription-selection__disclaimer-text">
        {t.G9OH(
          'The data speed varies depending on the features and location of the terminal, and network traffic volumes.'
        )}
        {t.F8Y3('Check the coverage for your area from the')}{' '}
        <a href="https://elisa.fi/kuuluvuus/" target="_blank" rel="noopener noreferrer">
          {t.NQYM('coverage map')}
        </a>
        .{' '}
        {t.WHGU(
          'Nordic and Baltic countries: Sweden, Norway, Denmark, Estonia, Latvia and Lithuania. Data exceeding the limit of Yritysdata EU subscriptions will be priced'
        )}{' '}
        <a href={`${siteBaseUrl}/verkkovierailu-ulkomailla`} target="_blank" rel="noopener noreferrer">
          {t.YBCH('according to the roaming price list')}
        </a>
        .
      </div>
    </>
  );
};
