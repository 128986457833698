import * as CL from '@design-system/component-library';
import { Link } from 'react-router-dom';
import { dsClass } from '../../../../common/constants/dsClasses.js';
import { t } from '../../../../common/i18n/index.js';

export const DnsDomainDisclaimerMfaRequired = ({ href }: { href: string }) => (
  <CL.Disclaimer
    disclaimerType="info"
    icon={<CL.Icon icon="lock" type="regular" aria-hidden="true" />}
    title={t.ZU8S('Elisa DNS management requires two-factor authentication')}
    text={t.FDUX('You can activate two-factor authentication for your company by contacting customer service.')}
    visible={true}
  >
    <div className={dsClass.MARGIN_TOP_2}>
      <Link to={href}>{t.COTT('Contact customer service')}</Link>
    </div>
  </CL.Disclaimer>
);
