import { ButtonGroup, SubscriptionDetailsButtonType } from '../SubscriptionDetails/subscriptionDetailsButtons.js';
import { DetailsWrapper } from '../DetailsWrapper';
import { Loading } from '../Loading';
import {
  SubscriptionDetailsAccordions,
  SubscriptionDetailsBillingAccount,
} from '../SubscriptionDetails/SubscriptionDetails.js';
import { SubscriptionType } from '../../generated/api/subscriptionType.js';
import { getSubscriptionDetailsPbx } from '../SubscriptionDetails/subscriptionDetailsPbxCommon.js';
import { getSubscriptionDetailsSoftwareProduct } from '../SubscriptionDetails/subscriptionDetailsSoftwareProduct.js';
import { getSubscriptionStatus, isYttSubscription } from '../../common/utils/subscriptionUtils.js';
import { showDialog } from '../../selfservice/actions';
import { useDispatch } from 'react-redux';
import type { DialogParams } from '../../common/types/dialog.js';
import type { Subscription } from '../../generated/api/subscription.js';
import type { SubscriptionDetailsProps } from '../SubscriptionDetails/SubscriptionDetails.js';

export const SubscriptionService = ({
  breadCrumbs,
  category,
  companyInfo,
  pendingSubscriptionActions,
  subscription,
}: SubscriptionDetailsProps) => {
  const dispatch = useDispatch();
  const onShowDialog = (params: DialogParams) => dispatch(showDialog(params));
  const subscriptionStatus = getSubscriptionStatus(subscription, pendingSubscriptionActions);
  const subscriptionDisplayId = subscription ? subscription.subscriptionDisplayId : '…';
  const companyName = companyInfo?.companyName;

  if (!subscription?.details) {
    return (
      <DetailsWrapper
        classes={['of-subscription-details']}
        detailsTop={breadCrumbs}
        heading={subscription?.subscriptionName || '…'}
        headingBottom={subscription?.details?.softwareProduct?.editionName || ''}
        headingTop=""
        heroPicto="generic-service-filled"
        id={`subscription-details-${subscriptionDisplayId}`}
      >
        <Loading />
      </DetailsWrapper>
    );
  }

  const getHeroPicto = (sub: Subscription) => {
    if (sub?.details?.mobilePbx && subscription.subscriptionType === SubscriptionType.MOBILE_PBX) {
      return 'pbx';
    }
    if (sub.details?.mobilePbx && subscription.subscriptionType === SubscriptionType.MOBILE_PBX_LITE) {
      return 'pbx-lite';
    }
    return 'generic-service-filled';
  };

  const softwareServiceButtonTypes = isYttSubscription(subscription?.subscriptionSubType)
    ? [SubscriptionDetailsButtonType.YTT_SUPPORT, SubscriptionDetailsButtonType.SUPPORT_REQUEST]
    : [SubscriptionDetailsButtonType.SUPPORT_REQUEST];

  const subscriptionDetails = (
    <div className="of-subscription-details__content of-subscription-details__content--service">
      {subscription?.details?.mobilePbx
        ? getSubscriptionDetailsPbx(
            subscription,
            subscriptionStatus,
            subscription.details,
            <SubscriptionDetailsBillingAccount subscription={subscription} />
          )
        : getSubscriptionDetailsSoftwareProduct(
            subscription,
            subscriptionStatus,
            subscription.details,
            <SubscriptionDetailsBillingAccount subscription={subscription} />,
            subscription.details.softwareProduct
          )}
      <ButtonGroup
        buttonTypes={
          subscription?.details?.mobilePbx && subscription.subscriptionType === SubscriptionType.MOBILE_PBX
            ? [SubscriptionDetailsButtonType.RING_SERVICE, SubscriptionDetailsButtonType.SUBMIT_SUPPORT_CASE]
            : softwareServiceButtonTypes
        }
        companyInfo={companyInfo}
        subscription={subscription}
        subscriptionStatus={subscriptionStatus}
        onShowDialog={onShowDialog}
      />
      <SubscriptionDetailsAccordions
        subscription={subscription}
        category={category}
        companyInfo={companyInfo}
        pendingSubscriptionActions={pendingSubscriptionActions}
      />
    </div>
  );

  return (
    <DetailsWrapper
      classes={['of-subscription-details']}
      detailsTop={breadCrumbs}
      heading={subscription.subscriptionName}
      headingBottom={
        `${companyName ?? ''}${companyName && subscription.details.softwareProduct?.editionName ? ` | ${subscription.details.softwareProduct.editionName}` : ''}` ||
        ''
      }
      headingTop=""
      heroPicto={getHeroPicto(subscription)}
      id={`subscription-details-${subscriptionDisplayId}`}
    >
      {subscriptionDetails}
    </DetailsWrapper>
  );
};
