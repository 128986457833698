import { TypeKeys, generateActionId } from './actionsUtils.js';
import { convertToCommonErrors } from '../../common/utils/errorUtils.js';
import type { CompositeListSort } from '../../components/CompositeListHeader/CompositeListHeader.js';
import type { DisplayItemsLoadAction, ErrorAction } from './actionsUtils.js';
import type {
  GenericError,
  SubscriptionAction,
  SubscriptionActionSearchResponse,
  SubscriptionActionsResponse,
} from '../../generated/api/models.js';
import type { ItemsQuery } from '../common/store.js';

export interface LoadEmployeeSubscriptionActions
  extends DisplayItemsLoadAction<TypeKeys.LOAD_EMPLOYEE_SUBSCRIPTION_ACTIONS, SubscriptionAction> {
  forceLoad?: boolean;
  useSearchService?: boolean;
}

export interface LoadEmployeeSubscriptionActionsFulfilled {
  subscriptionActionResponse: SubscriptionAction[];
  searchResults: SubscriptionActionSearchResponse[];
  useSearchService?: boolean;
  query: ItemsQuery;
  total: number;
  type: TypeKeys.LOAD_EMPLOYEE_SUBSCRIPTION_ACTIONS_FULFILLED;
}

export type LoadEmployeeSubscriptionActionsFailed = ErrorAction<TypeKeys.LOAD_EMPLOYEE_SUBSCRIPTION_ACTIONS_FAILED>;

export const loadEmployeeSubscriptionActions = (options?: {
  displayId?: string;
  sort?: CompositeListSort;
  search?: string;
  forceLoad?: boolean;
  useSearchService?: boolean;
}): LoadEmployeeSubscriptionActions => ({
  ...options,
  id: generateActionId(),
  type: TypeKeys.LOAD_EMPLOYEE_SUBSCRIPTION_ACTIONS,
});

export const loadEmployeeSubscriptionActionsFulfilled = (
  response: SubscriptionActionsResponse,
  query: ItemsQuery,
  useSearchService?: boolean
): LoadEmployeeSubscriptionActionsFulfilled => ({
  subscriptionActionResponse: response.subscriptionActions,
  searchResults: response.searchResults || [],
  query,
  total: response.total,
  type: TypeKeys.LOAD_EMPLOYEE_SUBSCRIPTION_ACTIONS_FULFILLED,
  useSearchService,
});

export const loadEmployeeSubscriptionActionsFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): LoadEmployeeSubscriptionActionsFailed => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.LOAD_EMPLOYEE_SUBSCRIPTION_ACTIONS_FAILED,
});

export type EmpolyeeSubscriptionActionsActionTypes =
  | LoadEmployeeSubscriptionActions
  | LoadEmployeeSubscriptionActionsFailed
  | LoadEmployeeSubscriptionActionsFulfilled;
