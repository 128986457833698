import * as CL from '@design-system/component-library';
import * as React from 'react';
import { onEnterOrSpaceKeyPress } from '../../common/utils/handlerUtils.js';
import { t } from '../../common/i18n/index.js';
import { useEffect, useState } from 'react';

import './FileUpload.scss';

export interface FileUploadProps {
  allowedFormat: string[];
  className?: string;
  disabled?: boolean;
  getFilesFn: (getFiles: () => File[]) => void;
  initialFiles?: File[]; // For storybook use only
  limit?: number; // Default 1
  validationError?: string;
  fileTypeValidationErrorMessage: string;
}

const mergeFiles = (files: File[], newAddedFiles: File[]) => {
  const existingFileNames = new Set(files.map(file => file.name));
  return [...files, ...newAddedFiles.filter(file => file && !existingFileNames.has(file.name))];
};

const validateFilesTypes = (
  fileList: FileList | null,
  allowedFileTypes: Array<string>,
  errorMessage: string
): { newFiles: File[]; fileTypeValidationError?: string } => {
  const newFiles = fileList?.length
    ? Array.from({ length: fileList.length }, (x, i) => fileList.item(i))
        .filter(file => file && allowedFileTypes.indexOf(file.type) !== -1)
        .map(file => file!)
    : [];
  const fileTypeValidationError = fileList && fileList.length !== newFiles.length ? errorMessage : undefined;
  return {
    newFiles,
    fileTypeValidationError,
  };
};

export const FileUpload = (props: FileUploadProps) => {
  const [highlight, setHighlight] = useState(false);
  const [files, setFiles] = useState(props.initialFiles || []);
  const [validationErrors, setValidationErrors] = useState(() =>
    props.validationError ? [props.validationError] : []
  );
  const fileInputRef = React.createRef<HTMLInputElement>();

  useEffect(() => {
    if (props.validationError) {
      setValidationErrors([props.validationError]);
    }
  }, [props.validationError]);
  props.getFilesFn(() => files);

  const getLimit = () => props.limit || 1;

  const isDisabled = (uploadCount: number) => props.disabled || files.length + uploadCount > getLimit();

  const handleNewFileInput = (fileList: FileList | null) => {
    const { newFiles, fileTypeValidationError } = validateFilesTypes(
      fileList,
      props.allowedFormat,
      props.fileTypeValidationErrorMessage
    );
    setFiles(mergeFiles(files, newFiles));
    if (fileTypeValidationError) {
      setValidationErrors([fileTypeValidationError]);
    }
  };

  const openFileDialog = () => {
    if (isDisabled(1)) {
      return;
    }
    fileInputRef.current!.value = '';
    fileInputRef.current!.click();
    setValidationErrors([]);
  };

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (isDisabled(event.dataTransfer.items.length)) {
      return;
    }
    setValidationErrors([]);
    setHighlight(true);
  };

  const onDragLeave = () => {
    setHighlight(false);
  };

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (isDisabled(event.dataTransfer.files.length)) {
      return;
    }
    handleNewFileInput(event.dataTransfer.files);
    setHighlight(false);
  };

  const onFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isDisabled(event.target.files?.length || 0)) {
      return;
    }
    handleNewFileInput(event.target.files);
  };

  const onRemoveFile = (fileName: string) => {
    setFiles(files.filter(file => file.name !== fileName));
    setValidationErrors([]);
  };

  return (
    <div className={`of-file-upload ${props.className || ''}`}>
      <div
        className={`of-file-upload__dropzone ${highlight ? 'of-file-upload__dropzone--highlight' : ''} ${
          validationErrors.length ? 'of-file-upload__dropzone--error' : ''
        }`}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        onClick={openFileDialog}
        onKeyPress={event => onEnterOrSpaceKeyPress(event, openFileDialog)}
        style={{ cursor: isDisabled(1) ? 'default' : 'pointer' }}
        role="button"
        tabIndex={0}
      >
        <input
          ref={fileInputRef}
          className="of-file-upload__input"
          type="file"
          multiple={getLimit() > 1}
          onChange={onFileInput}
        />
        <div>{t.Q0O6('Drop file here or')}</div>
        <div className="of-file-upload__upload-label">
          <svg viewBox="0 0 45 39">
            <path d="M43.5 38.99h-42a1.5 1.5 0 0 1-1.5-1.502v-12a1.5 1.5 0 0 1 1.5-1.498 1.5 1.5 0 0 1 1.5 1.498V36h39v-10.5a1.5 1.5 0 1 1 3 0v12a1.5 1.5 0 0 1-1.5 1.502zM30.426 13.056L24 5.56V28.49a1.5 1.5 0 1 1-3 0V5.56l-6.425 7.497c-.592.592-1.55.592-2.14 0s-.59-1.55 0-2.14L21.426.423a1.48 1.48 0 0 1 1.119-.421h.01a1.48 1.48 0 0 1 .962.397c.017.015.042.01.058.023l8.992 10.493c.59.6.59 1.55 0 2.14s-1.55.592-2.14.001z" />
          </svg>
          {t.S4DE('Upload file')}
        </div>
      </div>
      <div>
        {files.map(file => {
          return (
            <div key={file.name} className="of-file-upload__file">
              <CL.Icon aria-hidden="true" color="neutral-500" icon="attachment" size="s" type="regular" />
              <span className="of-file-upload__file-name">{file.name}</span>
              <button aria-label={t.S4DF('Remove')} onClick={() => onRemoveFile(file.name)}>
                <CL.Icon aria-hidden="true" color="neutral-500" icon="close" size="m" type="regular" />
              </button>
            </div>
          );
        })}
      </div>
      {validationErrors.map((error, index) => (
        <span key={index} className="of-file-upload__error-message">
          {error}
        </span>
      ))}
    </div>
  );
};
