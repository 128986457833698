import * as CL from '@design-system/component-library';
import { BillingAccountVersion } from '../billingAccountDetailsEnums.js';
import { formatDefinedTimestampToDDMMYYYY } from '../../../common/utils/dateUtils.js';
import { t } from '../../../common/i18n/index.js';
import type { BillingAccountScheduledChange } from '../../../generated/api/billingAccountScheduledChange.js';

export const Disclaimer = ({
  billingAccountScheduledChange,
  billingAccountVersion,
}: {
  billingAccountScheduledChange: BillingAccountScheduledChange;
  billingAccountVersion: BillingAccountVersion;
}) => {
  if (!billingAccountScheduledChange) {
    return null;
  }

  const date = formatDefinedTimestampToDDMMYYYY(billingAccountScheduledChange.scheduledChangeTimestamp);

  switch (billingAccountVersion) {
    case BillingAccountVersion.ACTIVE:
      return (
        <CL.Disclaimer
          title={t.US8M('Billing account has scheduled changes')}
          disclaimerType="info"
          icon={<CL.Icon icon="information" />}
          showCloseButton={false}
          text={`${t.K3GX('Below you can see the current version. Scheduled changes will be published')} ${date}.`}
        />
      );
    case BillingAccountVersion.SCHEDULED:
      return (
        <CL.Disclaimer
          title={`${t.Y91N('Scheduled changes')} ${date}`}
          disclaimerType="warning"
          icon={<CL.Icon icon="calendar-time" />}
          showCloseButton={false}
          text={t.AXSD(
            'Below you can see scheduled changes that will be published {}. You can delete or reschedule the change request. If you would like to still modify these changes, delete the scheduled change and create a new change request.',
            date
          )}
        />
      );
    default:
      return null;
  }
};
