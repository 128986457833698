import { DeliveryAddress } from '../../DeliveryAddress/DeliveryAddress.js';
import { Loading } from '../../Loading/index.js';
import { convertStringMapToCommonErrors } from '../../../common/utils/errorUtils.js';
import { shippingAddressMsg, t } from '../../../common/i18n/index.js';
import { useAuth } from '../../../public/site/AuthProvider.js';
import type { Address } from '../../../generated/api/address.js';
import type { AddressWithType, DeliveryAddressType } from '../../DeliveryAddress/DeliveryAddress.js';
import type { CommonError } from '../../../common/types/errors.js';
import type { DeviceCheckoutDeliveryDetailsType } from '../DeviceCheckoutDeliveryDetailsUtils.js';

interface DeviceCheckoutDeliveryAddressSectionProps {
  deliveryDetails: DeviceCheckoutDeliveryDetailsType;
  setIsEditingAddress: (value: boolean) => void;
  saveDeliveryDetailsToState: (
    deliveryDetails: DeviceCheckoutDeliveryDetailsType,
    validationErrors?: CommonError[]
  ) => void;
  deliveryAddressNeeded?: boolean;
  errors?: CommonError[];
  companyName?: string;
}

export const DeviceCheckoutDeliveryAddressSection = ({
  deliveryDetails,
  companyName,
  errors,
  saveDeliveryDetailsToState,
  deliveryAddressNeeded,
  setIsEditingAddress,
}: DeviceCheckoutDeliveryAddressSectionProps) => {
  const { ssoSessionValid } = useAuth();

  if (!deliveryAddressNeeded) {
    return null;
  }

  return ssoSessionValid && (!deliveryDetails || !deliveryDetails.recipientName) ? (
    <Loading />
  ) : (
    <div className="of-device-checkout-delivery-address">
      <div>{t.IHO6(shippingAddressMsg)}:</div>
      <DeliveryAddress
        address={deliveryDetails.address}
        recipientName={deliveryDetails.recipientName}
        recipientPhoneNumber={deliveryDetails.recipientPhoneNumber}
        deliveryAddressType={deliveryDetails.addressType}
        companyName={deliveryDetails.companyName || companyName}
        errors={errors}
        onUpdateEditMode={value => setIsEditingAddress(value)}
        submit={(
          values: AddressWithType,
          addressType?: DeliveryAddressType,
          validationErrors?: { [s: string]: string }
        ) => {
          saveDeliveryDetailsToState(
            {
              address: {
                countryCode: values.countryCode,
                line1: values.line1,
                line2: values.line2,
                postOffice: values.postOffice,
                postalCode: values.postalCode,
              } as Address,
              addressType: addressType,
              deliveryMethodDescription: deliveryDetails.deliveryMethodDescription,
              deliveryMethodType: deliveryDetails.deliveryMethodType,
              deliveryPrice: deliveryDetails.deliveryPrice,
              recipientName: values.recipientName,
              recipientPhoneNumber: values.recipientPhoneNumber,
              companyName: values.companyName,
              shipmentType: deliveryDetails.shipmentType,
            },
            convertStringMapToCommonErrors(validationErrors)
          );
        }}
      />
    </div>
  );
};
