import { AnalyticsItemCategory, EcommerceEventTypeV4 } from '../analytics.js';
import { FIVE_G_KEY } from '../../components/ProductGrid/ProductGridFilter.js';
import type { FilterEvent, SortEvent } from '../../common/types/analytics.js';
import type { Filters } from '../../components/ProductGrid/ProductGridFilter.js';
import type { SortType } from '../../components/ProductGrid/ProductGridSort.js';

const MBB_ITEM_NAME_PREFIX = 'Yritysdata';

// Extract speed inside brackets and add it after prefix "Mobiililaajakaista"
// E.g. Elisa Yritysdata 5G (600M) -> Mobiililaajakaista 600M
const getMbbItemName = (productName: string) => {
  const match = productName.match(/\(([^)]+)\)/);
  return match ? `${MBB_ITEM_NAME_PREFIX} ${match[1]}` : MBB_ITEM_NAME_PREFIX;
};

export const getItemName = (
  productName: string,
  itemCategory?: AnalyticsItemCategory,
  onlineModelName?: string
): string => {
  switch (itemCategory) {
    case AnalyticsItemCategory.FIXED_BROADBAND: {
      return productName;
    }
    case AnalyticsItemCategory.SALES_PRODUCT: {
      return onlineModelName ?? productName;
    }
    case AnalyticsItemCategory.YRITYS_DATA_LIITTYMA: {
      return getMbbItemName(productName);
    }
    default: {
      // TODO is this still relevant? We're pretty much special casing all
      //  products that follow this format already.
      // Remove possible "Elisa" and speed in parentheses from commercial product name
      // E.g. "Elisa Yritysdata 5G (600M)" -> "Yritysdata 5G"
      const match = productName.match(/^(?:Elisa\s+)?(.*?)\s*\(/);
      return match ? match[1] : productName;
    }
  }
};

export const getItemVariant = (eventType: EcommerceEventTypeV4, commercialProductName: string): string | undefined =>
  eventType !== EcommerceEventTypeV4.SELECT_ITEM && eventType !== EcommerceEventTypeV4.VIEW_ITEM_LIST
    ? commercialProductName
    : undefined;

export const createSortEvent = (sortType: SortType): SortEvent => {
  return {
    event: 'apply_sort',
    sort: sortType.valueOf(),
  };
};

export const createFilterEvent = (filters: Filters): FilterEvent => {
  return {
    event: 'apply_filter',
    filter: {
      brand: filters.FILTER_MANUFACTURER.selectedOptions,
      price: filters.FILTER_COST.selectedOptions,
      os: [...filters.FILTER_OS_COMPUTERS.selectedOptions, ...filters.FILTER_OS_PHONES.selectedOptions],
      fiveG: filters.FILTER_5G.selectedOptions.indexOf(FIVE_G_KEY) !== -1,
      offers: filters.FILTER_OFFERS.selectedOptions.length !== 0,
    },
  };
};
