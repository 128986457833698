import type { SnapInsChatConfig } from '../../common/types/snapInChat.js';

export const logOutFromChat = (isRedirected: boolean) => {
  // Fix for Salesforce chat security issue https://atlas.elisa.fi/jira/browse/OFI-29995
  // Ensures that the chat session is ended and session data deleted when user logs out
  if (window?.embedded_svc) {
    window.embedded_svc.liveAgentAPI?.endChat();
    // Page refresh is needed to end the chat session properly if the user has been logged out because of a timeout https://atlas.elisa.fi/jira/browse/OFI-31162.
    // Check if chat modal was visible and refresh the page if it was. Refresh is only needed when there is no redirect.
    if (
      window.document.querySelector(
        'body > div.modalContainer.sidebarMaximized.layout-docked.embeddedServiceSidebar'
      ) &&
      !isRedirected
    ) {
      window.location.reload();
    }
  }
};

const parseSfdcResponse = (response: string): string | undefined => {
  const RESPONSE_START = '/**/';
  return response.includes(RESPONSE_START) ? response.replace(RESPONSE_START, '') : undefined;
};

const isValidApiCall = (apiCall: string): boolean => {
  const VALID_START = 'embedded_svc.liveAgentAPI.connection.handlePing({';
  return apiCall.startsWith(VALID_START) && window?.embedded_svc?.liveAgentAPI?.connection;
};

// This function creates another function which calls embedded Salesforce code, which again refreshes chat button text.
// The issue with normal Salesforce script functionality is that it uses polling intervals,
// so button text refreshing might take up to 45 seconds.
// Hack but what can you do...
export const createButtonTextRefreshFunction = async (config?: SnapInsChatConfig) => {
  if (config) {
    const buttonTextRefreshUrl = `${config?.baseLiveAgentURL}/rest/Visitor/Availability.jsonp?sid=undefined&r=955&Availability.prefix=Visitor&Availability.ids=[${config?.adminLoggedInButtonId}]&callback=embedded_svc.liveAgentAPI.connection.handlePing&deployment_id=${config?.deploymentId}&org_id=${config?.organizationId}&version=48`;
    const response = await fetch(buttonTextRefreshUrl);
    const responseData = await response.text();
    const parsedApiCall = parseSfdcResponse(responseData);
    if (!parsedApiCall) {
      return undefined;
    }
    return isValidApiCall(parsedApiCall) ? Function(parsedApiCall) : undefined;
  }
  return undefined;
};

export const refreshChatButtonText = async (chatConfig?: SnapInsChatConfig) => {
  const refreshButtonFunction = await createButtonTextRefreshFunction(chatConfig);
  if (refreshButtonFunction) {
    refreshButtonFunction();
  }
};
