import { type Context } from '../OpenFormAnswers.js';
import { OFPageType } from '../../../generated/api/oFPageType.js';
import { OpenFormAction } from '../OpenFormReducer.js';
import { hasGUID } from '../OpenForm.js';
import { useCallback } from 'react';
import { useOpenFormDispatch } from '../OpenFormProvider.js';
import type { OpenFormSection } from '../../../generated/api/openFormSection.js';

export type OpenFormDispatcher = {
  setAnswer: <K extends keyof Context>(value?: string | string[], key?: K, patch?: Context[K]) => void;
  setChoices: (value?: string | string[]) => void;
  setContext: <K extends keyof Context>(key?: K, patch?: Context[K]) => void;
};

export type UseOpenFormDispatcher = (props: { guid: string }) => OpenFormDispatcher;

export const useOpenFormDispatcher = (section: OpenFormSection): UseOpenFormDispatcher => {
  const dispatch = useOpenFormDispatch();
  return useCallback(
    ({ guid }: { guid: string }): OpenFormDispatcher => ({
      setAnswer: <K extends keyof Context>(value?: string | string[], key?: K, patch?: Context[K]) => {
        dispatch(OpenFormAction.choices({ guid, value }));
        dispatch(OpenFormAction.context({ guid, key, patch }));
      },
      setChoices: (value?: string | string[]) => {
        switch (section.pageType) {
          case OFPageType.QUESTION_SUBSCRIPTION_TYPE: {
            dispatch(OpenFormAction.choices({ guid, value }));
            dispatch(
              OpenFormAction.context({
                guid,
                key: value?.length ? 'subscriptionTypes' : undefined,
                patch: section.questions
                  .filter(hasGUID(guid))
                  ?.flatMap(q => q.choices.flatMap(c => (value?.includes(c.guid) && c.linkedValue) || [])),
              })
            );
            break;
          }
          default: {
            dispatch(OpenFormAction.choices({ guid, value }));
          }
        }
      },
      setContext: <K extends keyof Context>(key?: K, patch?: Context[K]) => {
        dispatch(OpenFormAction.context({ guid, key, patch }));
      },
    }),
    [section, dispatch]
  );
};
