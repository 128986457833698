import { DisabledUserCallDetails } from '../../../../../../components/UserCallDetails/DisabledUserCallDetails.js';
import { SubscriptionCategory } from '../../../../../../common/enums.js';
import {
  checkValidEmployeeSubscription,
  getEmployeeSubscriptions,
  loadCompanyInfo,
} from '../../../../../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import type { SubscriptionId } from '../../../../../../common/constants/pathInterfaces.js';

export const VoiceSubCallDetailsPath = () => {
  const dispatch = useDispatch();
  const { subscriptionId = '' } = useParams<SubscriptionId>();

  useEffect(() => {
    dispatch(loadCompanyInfo());
    dispatch(getEmployeeSubscriptions(SubscriptionCategory.VOICE, subscriptionId));
    dispatch(checkValidEmployeeSubscription(SubscriptionCategory.VOICE, subscriptionId));
  }, [dispatch, subscriptionId]);

  return <DisabledUserCallDetails subscriptionId={subscriptionId} />;
};
