import { TypeKeys, generateActionId } from './actionsUtils.js';
import { convertToCommonErrors } from '../../common/utils/errorUtils.js';
import type {
  BillingAccount,
  Catalog,
  Contact,
  GenericError,
  VirtualCatalog,
  VirtualCatalogResponse,
} from '../../generated/api/models.js';
import type { CommonError } from '../../common/types/errors.js';
import type { CompositeListSort } from '../../components/CompositeListHeader/CompositeListHeader.js';
import type { CrudAction, DisplayItemsLoadAction, ErrorAction, ErrorActionCreator } from './actionsUtils.js';

export interface SetSelectedVirtualCatalogAction {
  type: TypeKeys.SET_SELECTED_VIRTUAL_CATALOG;
  selectedCatalogCode: string | null;
}

export const setSelectedVirtualCatalog = (selectedCatalogCode: string | null): SetSelectedVirtualCatalogAction => ({
  selectedCatalogCode,
  type: TypeKeys.SET_SELECTED_VIRTUAL_CATALOG,
});

// LOAD VIRTUAL CATALOGS

export interface LoadVirtualCatalogsAction
  extends DisplayItemsLoadAction<TypeKeys.LOAD_VIRTUAL_CATALOGS, VirtualCatalog> {
  catalogId?: string;
  forceLoad?: boolean;
}

export const loadVirtualCatalogs = (
  forceLoad?: boolean,
  catalogId?: string,
  displayId?: string,
  sort?: CompositeListSort,
  search?: string
): LoadVirtualCatalogsAction => {
  return {
    forceLoad,
    catalogId,
    displayId,
    id: generateActionId(),
    search,
    sort,
    type: TypeKeys.LOAD_VIRTUAL_CATALOGS,
  };
};

export interface LoadVirtualCatalogsFulfilledAction {
  type: TypeKeys.LOAD_VIRTUAL_CATALOGS_FULFILLED;
  catalogs?: VirtualCatalog[];
  catalogId?: string;
  total: number;
}

export const loadVirtualCatalogsFulfilled = (
  response: VirtualCatalogResponse,
  catalogId?: string
): LoadVirtualCatalogsFulfilledAction => {
  return {
    catalogId,
    catalogs: response.items,
    total: response.total,
    type: TypeKeys.LOAD_VIRTUAL_CATALOGS_FULFILLED,
  };
};
export type LoadVirtualCatalogsFailedAction = ErrorAction<TypeKeys.LOAD_VIRTUAL_CATALOGS_FAILED>;

export const loadVirtualCatalogsFailed: ErrorActionCreator<TypeKeys.LOAD_VIRTUAL_CATALOGS_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[]
): LoadVirtualCatalogsFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    type: TypeKeys.LOAD_VIRTUAL_CATALOGS_FAILED,
  };
};

// DELETE VIRTUAL CATALOG
export interface DeleteVirtualCatalogAction extends CrudAction<TypeKeys.DELETE_VIRTUAL_CATALOG> {
  virtualCatalogCode: string;
}

export const deleteVirtualCatalog = (virtualCatalogCode: string): DeleteVirtualCatalogAction => ({
  virtualCatalogCode,
  id: generateActionId(),
  type: TypeKeys.DELETE_VIRTUAL_CATALOG,
});
export interface DeleteVirtualCatalogFulfilledAction {
  virtualCatalogCode: string;
  type: TypeKeys.DELETE_VIRTUAL_CATALOG_FULFILLED;
}

export const deleteVirtualCatalogFulfilled = (virtualCatalogCode: string): DeleteVirtualCatalogFulfilledAction => {
  return {
    virtualCatalogCode,
    type: TypeKeys.DELETE_VIRTUAL_CATALOG_FULFILLED,
  };
};

export type DeleteVirtualCatalogFailedAction = ErrorAction<TypeKeys.DELETE_VIRTUAL_CATALOG_FAILED>;

export const deleteVirtualCatalogFailed: ErrorActionCreator<TypeKeys.DELETE_VIRTUAL_CATALOG_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[]
): DeleteVirtualCatalogFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    type: TypeKeys.DELETE_VIRTUAL_CATALOG_FAILED,
  };
};

// DELETE VIRTUAL CATALOG DRAFT

export interface DeleteVirtualCatalogDraftAction extends CrudAction<TypeKeys.DELETE_VIRTUAL_CATALOG_DRAFT> {
  virtualCatalogCode: string;
  catalogCode: string;
}

export const deleteVirtualCatalogDraft = (
  virtualCatalogCode: string,
  catalogCode: string
): DeleteVirtualCatalogDraftAction => ({
  virtualCatalogCode,
  catalogCode,
  id: generateActionId(),
  type: TypeKeys.DELETE_VIRTUAL_CATALOG_DRAFT,
});

export interface DeleteVirtualCatalogDraftFulfilledAction {
  virtualCatalogCode: string;
  catalogCode: string;
  type: TypeKeys.DELETE_VIRTUAL_CATALOG_DRAFT_FULFILLED;
}

export const deleteVirtualCatalogDraftFulfilled = (
  virtualCatalogCode: string,
  catalogCode: string
): DeleteVirtualCatalogDraftFulfilledAction => {
  return {
    virtualCatalogCode,
    catalogCode,
    type: TypeKeys.DELETE_VIRTUAL_CATALOG_DRAFT_FULFILLED,
  };
};

export type DeleteVirtualCatalogDraftFailedAction = ErrorAction<TypeKeys.DELETE_VIRTUAL_CATALOG_DRAFT_FAILED>;

export const deleteVirtualCatalogDraftFailed: ErrorActionCreator<TypeKeys.DELETE_VIRTUAL_CATALOG_DRAFT_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[]
): DeleteVirtualCatalogDraftFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    type: TypeKeys.DELETE_VIRTUAL_CATALOG_DRAFT_FAILED,
  };
};

// PUBLISH VIRTUAL CATALOG DRAFT
export interface PublishVirtualCatalogDraftAction extends CrudAction<TypeKeys.PUBLISH_VIRTUAL_CATALOG_DRAFT> {
  virtualCatalogCode: string;
  catalog?: Catalog;
}

export const publishVirtualCatalogDraft = (
  virtualCatalogCode: string,
  catalog?: Catalog
): PublishVirtualCatalogDraftAction => ({
  virtualCatalogCode,
  id: generateActionId(),
  type: TypeKeys.PUBLISH_VIRTUAL_CATALOG_DRAFT,
  catalog: catalog,
});

export interface PublishVirtualCatalogDraftFulfilledAction {
  virtualCatalogCode: string;
  type: TypeKeys.PUBLISH_VIRTUAL_CATALOG_DRAFT_FULFILLED;
}

export const publishVirtualCatalogDraftFulfilled = (
  virtualCatalogCode: string
): PublishVirtualCatalogDraftFulfilledAction => {
  return {
    virtualCatalogCode,
    type: TypeKeys.PUBLISH_VIRTUAL_CATALOG_DRAFT_FULFILLED,
  };
};

export type PublishVirtualCatalogDraftFailedAction = ErrorAction<TypeKeys.PUBLISH_VIRTUAL_CATALOG_DRAFT_FAILED>;

export const publishVirtualCatalogDraftFailed: ErrorActionCreator<TypeKeys.PUBLISH_VIRTUAL_CATALOG_DRAFT_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[]
): PublishVirtualCatalogDraftFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    type: TypeKeys.PUBLISH_VIRTUAL_CATALOG_DRAFT_FAILED,
  };
};

// UPSERT VIRTUAL CATALOG

export interface UpsertVirtualCatalogAction extends CrudAction<TypeKeys.UPSERT_VIRTUAL_CATALOG> {
  catalog: Partial<Catalog>;
  publishCatalog: boolean;
  virtualCatalog?: VirtualCatalog;
  newBillingAccount?: BillingAccount;
  newBillingAccountCommonFunction?: Contact;
  newBillingAccountValidationErrors?: CommonError[];
  overridePreviousAction?: boolean;
  billingAccountRequired?: boolean; // billing account is not required in some cases like copy catalog
}

export const upsertVirtualCatalog = (
  catalog: Partial<Catalog>,
  publishCatalog: boolean,
  virtualCatalog?: VirtualCatalog,
  newBillingAccount?: BillingAccount,
  newBillingAccountValidationErrors?: CommonError[],
  overridePreviousAction?: boolean,
  billingAccountRequired?: boolean
): UpsertVirtualCatalogAction => ({
  catalog,
  publishCatalog,
  virtualCatalog,
  id: generateActionId(),
  newBillingAccount,
  newBillingAccountValidationErrors,
  overridePreviousAction,
  type: TypeKeys.UPSERT_VIRTUAL_CATALOG,
  billingAccountRequired,
});

export interface UpsertVirtualCatalogFulfilledAction {
  catalog: Catalog;
  isCreate: boolean;
  virtualCatalog: VirtualCatalog;
  publishCatalog: boolean;
  type: TypeKeys.UPSERT_VIRTUAL_CATALOG_FULFILLED;
}

export const upsertVirtualCatalogFulfilled = (
  isCreate: boolean,
  catalog: Catalog,
  virtualCatalog: VirtualCatalog,
  publishCatalog: boolean
): UpsertVirtualCatalogFulfilledAction => {
  return {
    catalog,
    isCreate,
    virtualCatalog,
    publishCatalog,
    type: TypeKeys.UPSERT_VIRTUAL_CATALOG_FULFILLED,
  };
};

export type UpsertVirtualCatalogFailedAction = ErrorAction<TypeKeys.UPSERT_VIRTUAL_CATALOG_FAILED>;

export const upsertVirtualCatalogFailed: ErrorActionCreator<TypeKeys.UPSERT_VIRTUAL_CATALOG_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[]
): UpsertVirtualCatalogFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    type: TypeKeys.UPSERT_VIRTUAL_CATALOG_FAILED,
  };
};

export interface CopyVirtualCatalogAction extends CrudAction<TypeKeys.COPY_VIRTUAL_CATALOG> {
  catalog: Partial<Catalog>;
  accounts: string[];
}

export const copyVirtualCatalog = (catalog: Partial<Catalog>, accounts: string[]): CopyVirtualCatalogAction => ({
  catalog,
  accounts,
  id: generateActionId(),
  type: TypeKeys.COPY_VIRTUAL_CATALOG,
});

export type CopyVirtualCatalogFailedAction = ErrorAction<TypeKeys.COPY_VIRTUAL_CATALOG_FAILED>;

export const copyVirtualCatalogFailed = (): CopyVirtualCatalogFailedAction => {
  return {
    type: TypeKeys.COPY_VIRTUAL_CATALOG_FAILED,
  };
};

export interface CopyVirtualCatalogFulfilledAction {
  catalog?: Catalog;
  virtualCatalog?: VirtualCatalog;
  accountsFailedToCopyTo?: string[];
  type: TypeKeys.COPY_VIRTUAL_CATALOG_FULFILLED;
}

export const copyVirtualCatalogFulfilled = (
  catalog?: Catalog,
  virtualCatalog?: VirtualCatalog,
  accountsFailedToCopyTo?: string[]
): CopyVirtualCatalogFulfilledAction => {
  return {
    catalog,
    virtualCatalog,
    accountsFailedToCopyTo,
    type: TypeKeys.COPY_VIRTUAL_CATALOG_FULFILLED,
  };
};

export interface ReplaceCatalogFulfilledAction {
  type: TypeKeys.REPLACE_VIRTUAL_CATALOGS_FULFILLED;
}

export type ReplaceCatalogFailedAction = ErrorAction<TypeKeys.REPLACE_VIRTUAL_CATALOGS_FAILED>;

export type CatalogsActionTypes =
  | SetSelectedVirtualCatalogAction
  | LoadVirtualCatalogsAction
  | LoadVirtualCatalogsFulfilledAction
  | LoadVirtualCatalogsFailedAction
  | DeleteVirtualCatalogAction
  | DeleteVirtualCatalogFulfilledAction
  | DeleteVirtualCatalogFailedAction
  | DeleteVirtualCatalogDraftAction
  | DeleteVirtualCatalogDraftFulfilledAction
  | DeleteVirtualCatalogDraftFailedAction
  | PublishVirtualCatalogDraftAction
  | PublishVirtualCatalogDraftFulfilledAction
  | PublishVirtualCatalogDraftFailedAction
  | UpsertVirtualCatalogAction
  | UpsertVirtualCatalogFulfilledAction
  | UpsertVirtualCatalogFailedAction
  | CopyVirtualCatalogAction
  | CopyVirtualCatalogFailedAction
  | CopyVirtualCatalogFulfilledAction
  | ReplaceCatalogFulfilledAction
  | ReplaceCatalogFailedAction;
