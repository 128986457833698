import { useCallback, useRef } from 'react';

export const useDebounce = <T extends (...args: U[]) => void, U = string>(
  func: T,
  delay: number,
  validate?: (args: U[]) => boolean
): T => {
  const timerRef = useRef<ReturnType<typeof setTimeout>>();

  const debouncedFunc = useCallback(
    (...args: Parameters<T>) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      timerRef.current = setTimeout(() => {
        if (validate) {
          if (validate(args)) {
            func(...args);
          }
          return;
        }
        func(...args);
      }, delay);
    },
    [func, delay, validate]
  );

  return debouncedFunc as T;
};
