import { TypeKeys, generateActionId } from './actionsUtils.js';
import { convertToCommonErrors } from '../../common/utils/errorUtils.js';
import type { CategoryKey, CategoryKeyLite } from '../../common/utils/categoryUtils.js';
import type { DisplayItemsLoadAction, ErrorAction } from './actionsUtils.js';
import type {
  GenericError,
  Subscription,
  SubscriptionSearchResponse,
  SubscriptionsResponse,
} from '../../generated/api/models.js';
import type { ItemFilter } from '../../common/types/subscription.js';
import type { ItemsQuery } from '../common/store.js';
import type { SubscriptionsDeviceMultiFilter } from '../../components/SubscriptionLists/SubscriptionTable.js';

export interface LoadSubscriptionsAction extends DisplayItemsLoadAction<TypeKeys.LOAD_SUBSCRIPTIONS, Subscription> {
  category: CategoryKeyLite;
  filter?: ItemFilter<Subscription>;
  multiFilter?: SubscriptionsDeviceMultiFilter;
  getAllItems?: boolean;
  forceLoad?: boolean;
  contactId?: string;
  subscriptionToken?: string;
  listView?: boolean;
  offset?: number;
  sortColumn?: string;
  sortOrder?: string;
  expiringOrExpired?: boolean;
  eppOnly?: boolean;
}

export const loadSubscriptions = (action: Omit<LoadSubscriptionsAction, 'id' | 'type'>): LoadSubscriptionsAction => {
  return {
    ...action,
    id: generateActionId(),
    type: TypeKeys.LOAD_SUBSCRIPTIONS,
  };
};

export interface LoadSubscriptionsFulfilledAction {
  category: CategoryKey;
  query: ItemsQuery;
  totalSubscriptions: number;
  type: TypeKeys.LOAD_SUBSCRIPTIONS_FULFILLED;
  subscriptions?: Subscription[];
  contactId?: string;
  searchResults?: SubscriptionSearchResponse[];
  useSearchService?: boolean;
  mergeHeaders?: boolean;
}

export const loadSubscriptionsFulfilled = (
  { subscriptions, total, searchResults }: SubscriptionsResponse,
  query: ItemsQuery,
  category: CategoryKey,
  contactId?: string,
  useSearchService = false,
  mergeHeaders = false
): LoadSubscriptionsFulfilledAction => ({
  category,
  query,
  subscriptions,
  totalSubscriptions: total,
  type: TypeKeys.LOAD_SUBSCRIPTIONS_FULFILLED,
  contactId,
  searchResults,
  useSearchService,
  mergeHeaders,
});

export type LoadSubscriptionsFailedAction = ErrorAction<TypeKeys.LOAD_SUBSCRIPTIONS_FAILED>;

export const loadSubscriptionsFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): LoadSubscriptionsFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    params,
    type: TypeKeys.LOAD_SUBSCRIPTIONS_FAILED,
  };
};

export type SubscriptionsActionTypes =
  | LoadSubscriptionsAction
  | LoadSubscriptionsFailedAction
  | LoadSubscriptionsFulfilledAction;
