import { isInBrowser } from '../../common/utils/ssrUtils.js';
import { useCallback, useEffect, useRef } from 'react';

export const useIdObserver = (ids: string[], onImpression: (id: string) => void) => {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const sentRef = useRef<{ [key: string]: boolean }>({});
  const timeoutRef = useRef<{ [key: string]: number | undefined }>({});
  const visibilityStartRef = useRef<{ [key: string]: number | null }>({});
  const handleObservation = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        const entryId = entry.target.id;
        if (sentRef.current[entryId]) {
          return;
        }

        const now = new Date().getTime();
        if (entry.isIntersecting) {
          if (visibilityStartRef.current[entryId] == null) {
            visibilityStartRef.current[entryId] = now;
            timeoutRef.current[entryId] = window.setTimeout(() => {
              sentRef.current[entryId] = true;
              onImpression(entryId);
            }, 500);
          }
        } else {
          if (timeoutRef.current[entryId]) {
            window.clearTimeout(timeoutRef.current[entryId]);
            timeoutRef.current[entryId] = undefined;
          }
          visibilityStartRef.current[entryId] = null;
        }
      });
    },
    [onImpression]
  );

  useEffect(() => {
    if (observerRef.current != null) {
      observerRef.current.disconnect();
    }
    if (isInBrowser() && 'IntersectionObserver' in window) {
      const currentObserver = new IntersectionObserver(handleObservation, {
        threshold: 0.5,
      });
      observerRef.current = currentObserver;
      ids.forEach(id => {
        const el = document.getElementById(id);
        if (el) {
          currentObserver.observe(el);
        }
      });
    }

    return () => {
      if (observerRef.current != null) {
        observerRef.current.disconnect();
      }
    };
  }, [ids]); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps
};
