import type { AnchorHTMLAttributes } from 'react';

/**
 ** In general links that take user outside this app should have a blue arrow, so the user isn't surprised.
 ** However, links within blocks of text shouldn't have the blue arrow, so this component doesn't need a blue arrow.
 **
 ** **NOTE: Due to above, `<ExternalLink>` should only be used within text blocks.**
 */
export const ExternalLink = ({ children, ...rest }: AnchorHTMLAttributes<HTMLAnchorElement>) => {
  return (
    <a {...rest} target="_blank" rel="noopener">
      {children}
    </a>
  );
};
