import { TypeKeys } from './actionsUtils.js';
import { convertToCommonErrors } from '../../common/utils/errorUtils.js';
import type { Action } from 'redux';
import type { ErrorAction } from './actionsUtils.js';
import type { GenericError, StartFixerResponse } from '../../generated/api/models.js';
import type { Locale } from '../../common/i18n/index.js';

export interface StartBroadbandFixerAction extends Action<TypeKeys.START_BROADBAND_FIXER> {
  locale: Locale;
  portal: string;
  subscriptionId: string;
}

export const startBroadbandFixer = (
  subscriptionId: string,
  locale: Locale,
  portal: string
): StartBroadbandFixerAction => ({
  locale,
  portal,
  subscriptionId,
  type: TypeKeys.START_BROADBAND_FIXER,
});

export interface StartBroadbandFixerFulfilledAction extends Action<TypeKeys.START_BROADBAND_FIXER_FULFILLED> {
  response: StartFixerResponse;
}

export const startBroadbandFixerFulfilled = (resp: StartFixerResponse): StartBroadbandFixerFulfilledAction => {
  window.location.href = resp.fixerUrl;
  return { response: resp, type: TypeKeys.START_BROADBAND_FIXER_FULFILLED };
};

export type StartBroadbandFixerFailedAction = ErrorAction<TypeKeys.START_BROADBAND_FIXER_FAILED>;

export const startBroadbandFixerFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): StartBroadbandFixerFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.START_BROADBAND_FIXER_FAILED,
});

export type BroadbandFixerActionTypes =
  | StartBroadbandFixerAction
  | StartBroadbandFixerFulfilledAction
  | StartBroadbandFixerFailedAction;
