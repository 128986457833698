/**
 * Online UI API
 * # Summary Elisa B2B Online UI API  # Usage This API is used in the `https://yrityksille.elisa.fi/` pages.  # Identifiers 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CreditCheckResult } from './creditCheckResult.js';
import { OpenOrderStatus } from './openOrderStatus.js';
import { ReportStatusChange } from './reportStatusChange.js';
import { AuxiliaryEsimServiceOrderResult } from './auxiliaryEsimServiceOrderResult.js';
import { OrderStatusResult } from './orderStatusResult.js';
import { CardPaymentResult } from './cardPaymentResult.js';
import { AuthenticationResult } from './authenticationResult.js';
import { SubscriptionAction } from './subscriptionAction.js';


/**
 * Successful changes response
 */
export interface MyselfChangesResponse { 
    /**
     * Reference to the latest updated change in the database. Used to poll for changes. Includes hash for the server to verify.
     */
    latestChangeRef: string;
    authenticationResult?: AuthenticationResult;
    /**
     * Type of change returned. In case only latestRef or latestRef and authenticationResult was returned, this can be missing.
     */
    changeType?: MyselfChangesResponse.ChangeTypeEnum;
    subscriptionAction?: SubscriptionAction;
    creditCheckResult?: CreditCheckResult;
    auxiliaryEsimOpenOrderStatus?: OpenOrderStatus;
    orderStatusResult?: OrderStatusResult;
    auxiliaryEsimServiceOrderResult?: AuxiliaryEsimServiceOrderResult;
    cardPaymentResult?: CardPaymentResult;
    reportStatusChange?: ReportStatusChange;
}
export namespace MyselfChangesResponse {
    export type ChangeTypeEnum = 'SUBSCRIPTION_ACTION' | 'CREDIT_CHECK' | 'ORDER_STATUS' | 'AUXILIARY_ESIM_SERVICE_ORDER_RESULT' | 'AUXILIARY_ESIM_OPEN_ORDER_STATUS' | 'CARD_PAYMENT_RESULT' | 'REPORT_STATUS_CHANGE';
    export const ChangeTypeEnum = {
        SUBSCRIPTION_ACTION: 'SUBSCRIPTION_ACTION' as ChangeTypeEnum,
        CREDIT_CHECK: 'CREDIT_CHECK' as ChangeTypeEnum,
        ORDER_STATUS: 'ORDER_STATUS' as ChangeTypeEnum,
        AUXILIARY_ESIM_SERVICE_ORDER_RESULT: 'AUXILIARY_ESIM_SERVICE_ORDER_RESULT' as ChangeTypeEnum,
        AUXILIARY_ESIM_OPEN_ORDER_STATUS: 'AUXILIARY_ESIM_OPEN_ORDER_STATUS' as ChangeTypeEnum,
        CARD_PAYMENT_RESULT: 'CARD_PAYMENT_RESULT' as ChangeTypeEnum,
        REPORT_STATUS_CHANGE: 'REPORT_STATUS_CHANGE' as ChangeTypeEnum
    };
}


