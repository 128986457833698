import { FormProvider, useForm } from 'react-hook-form';
import React from 'react';
import type { DeliveryAddress } from '../../generated/api/deliveryAddress.js';

interface FormWrapperProps {
  children: JSX.Element;
  defaultAddress: DeliveryAddress;
  onSubmit: (data: DeliveryAddressFormValues, e?: React.BaseSyntheticEvent) => void;
}

export interface DeliveryAddressFormValues {
  deliveryAddress: DeliveryAddress;
}

export const FormWrapper = ({ children, defaultAddress, onSubmit }: FormWrapperProps) => {
  const methods = useForm<DeliveryAddressFormValues>({
    shouldFocusError: true,
    defaultValues: {
      deliveryAddress: {
        companyName: defaultAddress.companyName,
        recipient: defaultAddress.recipient,
        phoneNumber: defaultAddress.phoneNumber,
        address: {
          line1: defaultAddress.address.line1,
          postalCode: defaultAddress.address.postalCode,
          postOffice: defaultAddress.address.postOffice,
          countryCode: defaultAddress.address.countryCode,
        },
      },
    },
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        {children}
      </form>
    </FormProvider>
  );
};
