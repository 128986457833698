import * as CL from '@design-system/component-library';
import { dsClass } from '../../../../../common/constants/dsClasses.js';
import { t } from '../../../../../common/i18n/index.js';
import type { DnsRecordSupportedValue } from '../../../../../generated/api/dnsRecordSupportedValue.js';

// This list is the order in which the columns appear in the UI
// Note that `priority` is not included in this list as it will be shown in the badge (if present)
const getValueColumns = (type: string): (keyof DnsRecordSupportedValue)[] => {
  switch (type) {
    case 'CAA':
      return ['value', 'flag', 'tag'];
    case 'MX':
      return ['host'];
    case 'NAPTR':
      return ['service', 'regexp', 'replacement', 'flags', 'order', 'preference'];
    case 'SOA':
      return ['mname', 'rname', 'serial', 'refresh', 'retry', 'expire', 'ttl'];
    case 'SRV':
      return ['target', 'weight', 'port'];
    default:
      return ['value'];
  }
};

export const RecordValueView = ({ type, values }: { type: string; values: DnsRecordSupportedValue[] }) => (
  <>
    {values.map((value, index) => (
      <div key={index} className="of-dns-record-list-row-edit__view">
        <span className={`${dsClass.PADDING_LEFT_2} ${dsClass.FLOAT_RIGHT}`}>
          {'priority' in value ? (
            <CL.Tooltip
              placement="top"
              triggerElement={
                <CL.Badge
                  className="of-record-view-badge"
                  type="numeric"
                  text={String(value.priority)}
                  color="secondary"
                />
              }
              i18n_tooltip_contentText={t.ZS4S('Priority')}
            />
          ) : null}
        </span>
        {getValueColumns(type).map((column, idx) =>
          column in value ? (
            <span key={idx} className={dsClass.PADDING_RIGHT_2}>
              {value[column]}
            </span>
          ) : null
        )}
      </div>
    ))}
  </>
);
