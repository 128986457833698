import { AdditionalServicesAccordionContent } from '../AdditionalServicesAccordionContent';
import { LinkableAccordion } from '../../../components/LinkableAccordion';
import { additionalServicesMsg, t } from '../../../common/i18n';
import type { Accordion } from 'common/utils/accordionUtils';
import type { AssociationRecord, DependencyRecord } from '@onlinefirst/cloudsense-add-on-dependency-engine';
import type { CategoryKey } from '../../../common/utils/categoryUtils';
import type { Subscription } from '../../../generated/api/subscription';
import type { SubscriptionAction } from '../../../generated/api/subscriptionAction';

export interface AdditionalServicesAccordionProps {
  category: CategoryKey;
  subscription: Subscription;
  isMobileIdEnabledForCompany?: boolean;
  pendingSubscriptionActions?: SubscriptionAction[];
  addOnRulesAssociations?: AssociationRecord[];
  addOnRulesDependencies?: DependencyRecord[];
  pbxSolutions?: Subscription[];
}

export const AdditionalServicesAccordion = ({
  category,
  subscription,
  isMobileIdEnabledForCompany,
  pendingSubscriptionActions,
  addOnRulesAssociations,
  addOnRulesDependencies,
  pbxSolutions,
}: AdditionalServicesAccordionProps) => {
  return (
    <LinkableAccordion heading={t.LXSR(additionalServicesMsg)} headingLevel="h3" id="additional-services">
      <AdditionalServicesAccordionContent
        category={category}
        subscription={subscription}
        isMobileIdEnabledForCompany={isMobileIdEnabledForCompany}
        subscriptionActions={pendingSubscriptionActions}
        addOnAssociations={addOnRulesAssociations}
        addOnDependencies={addOnRulesDependencies}
        isEmployee={false}
        pbxSolutions={pbxSolutions}
      />
    </LinkableAccordion>
  );
};

export const getAdditionalServicesAccordion = ({
  category,
  subscription,
  isMobileIdEnabledForCompany,
  pendingSubscriptionActions,
  addOnRulesAssociations,
  addOnRulesDependencies,
  pbxSolutions,
}: AdditionalServicesAccordionProps): Accordion => {
  return {
    headerName: t.LXSR(additionalServicesMsg),
    id: 'additional-services',
    includedData: (
      <AdditionalServicesAccordionContent
        category={category}
        subscription={subscription}
        isMobileIdEnabledForCompany={isMobileIdEnabledForCompany}
        subscriptionActions={pendingSubscriptionActions}
        addOnAssociations={addOnRulesAssociations}
        addOnDependencies={addOnRulesDependencies}
        isEmployee={false}
        pbxSolutions={pbxSolutions}
      />
    ),
    displayed: true,
  };
};
