import { DnsDomainManagement } from '../../../../../components/DnsManagement/DnsDomainManagement/DnsDomainManagement.js';
import { SubscriptionsLayout } from '../../../../../components/SubscriptionsLayout/SubscriptionsLayout.js';
import { omaElisaForCompaniesMsg, t } from '../../../../../common/i18n/index.js';
import { paths } from '../../../../../common/constants/pathVariables.js';

export const DnsManagementPath = () => (
  <SubscriptionsLayout
    breadCrumbs={[{ name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME }, { name: 'Elisa DNS' }]}
  >
    <DnsDomainManagement />
  </SubscriptionsLayout>
);
