import { OpenFormBar } from '../OpenFormComponents/OpenFormBar.js';
import { OpenFormButton } from '../OpenFormComponents/OpenFormButton';
import { OpenFormGridBlock, OpenFormGridCol, OpenFormGridRow } from '../OpenFormComponents/OpenFormGrid.js';
import { OpenFormIcon } from '../OpenFormComponents/OpenFormIcon';
import {
  confirmationSMSMsg,
  orderSubmittedForProcessingMsg,
  returnToCustomerViewMsg,
  salesFormsEntryReminderMsg,
  salesFormsMsg,
  t,
} from '../../../common/i18n/index.js';
import { parsePath } from '../OpenFormUtils.js';
import { paths } from '../../../common/constants/pathVariables.js';
import { useMemo } from 'react';
import { useOpenFormState } from '../OpenFormProvider.js';
import type { ListId } from '../../../common/constants/pathInterfaces.js';

export const OpenFormThankYou = () => {
  const { list } = useOpenFormState();
  const backPath = useMemo(() => parsePath<ListId>(list?.listId ? paths.OPENFORM_LIST : paths.OPENFORM, list), [list]);

  const handleButtonClick = () =>
    window.parent.parent.postMessage(
      {
        name: 'OpenFormCanvasEvent',
        action: 'redirectToAccount',
      },
      '*'
    );

  return (
    <OpenFormBar back={backPath} backText={t.QF2O(salesFormsMsg)}>
      <OpenFormGridBlock justifyCenterOuter={true}>
        <OpenFormGridCol colWidth={8} className="of-openform__thank-you__container">
          <h2 className="of-openform__thank-you__h2">{t.SOQ1(orderSubmittedForProcessingMsg)}</h2>

          <div className="of-openform__thank-you__paragraph-container">
            <OpenFormGridRow className="of-openform__thank-you__paragraph-row" justifyCenter={false}>
              <OpenFormIcon size="m" icon="check" className="of-openform__thank-you__check" />
              <span>{t.R89M(confirmationSMSMsg)}</span>
            </OpenFormGridRow>
            <OpenFormGridRow className="of-openform__thank-you__paragraph-row" justifyCenter={false}>
              <OpenFormIcon size="m" icon="information" type="regular" className="of-openform__thank-you__check" />
              <span>{t.LDN7(salesFormsEntryReminderMsg)}</span>
            </OpenFormGridRow>
          </div>

          <OpenFormGridRow className="of-openform__thank-you__picture ds-margin-vertical--4"></OpenFormGridRow>

          <OpenFormButton
            className="of-openform__view__thank-you--button"
            color="primary"
            text={t.OW3C(returnToCustomerViewMsg)}
            icon={<OpenFormIcon icon="check" />}
            size="l"
            disabled={false}
            onClick={handleButtonClick}
          />
        </OpenFormGridCol>
      </OpenFormGridBlock>
    </OpenFormBar>
  );
};
