import { atContext, atType, queryInput } from '../constants/namingConventionVariables.js';
import { isInBrowser } from '../utils/ssrUtils.js';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useStore } from 'react-redux';
import type { State } from '../../selfservice/common/store.js';

const getFrontPageJsonLd = (siteBaseUrl: string) =>
  JSON.stringify({
    [atContext]: 'https://schema.org',
    [atType]: 'WebSite',
    name: 'Elisa Yrityksille',
    url: `${siteBaseUrl}/`,
    potentialAction: {
      [atType]: 'SearchAction',
      target: {
        [atType]: 'EntryPoint',
        urlTemplate: `${siteBaseUrl}/haku?q={search_term_string}`,
      },
      [queryInput]: 'required name=search_term_string',
    },
  });

const getPageJsonLd = (siteBaseUrl: string, pagePath?: string) => {
  switch (pagePath) {
    case '/':
      return getFrontPageJsonLd(siteBaseUrl);
    default:
      return '';
  }
};

const setHeadJsonLd = (content: string) => {
  const jsonLdScriptElement = isInBrowser() ? document.getElementById('head-json-ld') : null;
  if (jsonLdScriptElement) {
    jsonLdScriptElement.innerText = content;
  }
};

// TODO we should probably move this whole thing to CMS meta
export const useHeadJsonLd = () => {
  const { pathname } = useLocation();
  const store = useStore<State>();
  const { siteBaseUrl } = store.getState().config;

  useEffect(() => {
    const pageJsonLd = getPageJsonLd(siteBaseUrl, pathname);
    setHeadJsonLd(pageJsonLd);
  }, [pathname, siteBaseUrl]);
};
