import { DeliveryType } from '../../generated/api/deliveryType.js';
import { t } from '../../common/i18n/index.js';
import type { Address } from '../../generated/api/models.js';
import type { CommonError } from '../../common/types/errors.js';
import type { DeliveryAddressType } from '../DeliveryAddress/DeliveryAddress.js';
import type { DeliveryMethod } from '../../generated/api/deliveryMethod.js';
import type { DiscountedPriceItem } from '../../generated/api/discountedPriceItem.js';
import type { OnlineOrder } from '../../generated/api/onlineOrder.js';
import type { PickupPoint } from '../../generated/api/pickupPoint.js';
export enum DeliveryModeType {
  COLLECT_POINT = 'COLLECT_POINT',
  ADDRESS = 'ADDRESS',
}

const POSTI_LOGO_CLASS = 'posti';
const MATKAHUOLTO_LOGO_CLASS = 'matkahuolto';

const POSTI_SEARCH_SERVICE = 'posti';
const MATKAHUOLTO_SEARCH_SERVICE = 'matkahuolto';

export const DELIVERY_METHOD_DETAILS = new Map<string, DeliveryMethodDetails>([
  [
    DeliveryType.POSTI_EXPRESS_BUSINESS,
    {
      additionalInformationLink: '',
      createDescription: () => t.LLQR('Delivery to address. Delivered next working day by 4 PM'),
      id: 'Y3',
      notes: '',
      price: 790,
      type: DeliveryType.POSTI_EXPRESS_BUSINESS,
      image: POSTI_LOGO_CLASS,
      deliveryModeType: DeliveryModeType.ADDRESS,
      allowedForEmployees: true,
    },
  ],
  [
    DeliveryType.POSTI_EXPRESS_MORNING,
    {
      additionalInformationLink: '',
      createDescription: () => t.W3LI('Delivery to address. Delivered next working day by 9 AM'),
      id: 'Y2',
      price: 1190,
      notes: t.HPJ2('Not available for locations north of Oulu'),
      type: DeliveryType.POSTI_EXPRESS_MORNING,
      image: POSTI_LOGO_CLASS,
      deliveryModeType: DeliveryModeType.ADDRESS,
      allowedForEmployees: false,
    },
  ],
  [
    DeliveryType.MATKAHUOLTO_JAKOPAKETTI,
    {
      additionalInformationLink: '',
      createDescription: () => t.ZQ4R('Delivery to address'),
      id: 'M1',
      notes: '',
      price: 790,
      type: DeliveryType.MATKAHUOLTO_JAKOPAKETTI,
      image: MATKAHUOLTO_LOGO_CLASS,
      deliveryModeType: DeliveryModeType.ADDRESS,
      allowedForEmployees: false,
    },
  ],
  [
    DeliveryType.POSTI_ECONOMY,
    {
      additionalInformationLink: '',
      createDescription: () =>
        t.VOW9(
          'Pick up next working day from the post office after 4 PM. Post office arrival notice needed before pick up'
        ),
      id: 'Y4',
      price: 590,
      notes: t.HPJ2('Not available for locations north of Oulu'),
      type: DeliveryType.POSTI_ECONOMY,
      image: POSTI_LOGO_CLASS,
      deliveryModeType: DeliveryModeType.COLLECT_POINT,
      allowedForEmployees: false,
    },
  ],
  [
    DeliveryType.POSTI_SMARTPOST,
    {
      additionalInformationLink: '',
      createDescription: () => t.Z0OY('Pick up at Posti Parcel Point'),
      id: 'P1',
      notes: '',
      price: 590,
      type: DeliveryType.POSTI_SMARTPOST,
      image: POSTI_LOGO_CLASS,
      searchService: POSTI_SEARCH_SERVICE,
      deliveryModeType: DeliveryModeType.COLLECT_POINT,
      allowedForEmployees: true,
    },
  ],
  [
    DeliveryType.POSTI_SMARTPOST_EXPRESS,
    {
      additionalInformationLink: '',
      createDescription: () => t.MIMF('Pick up at Posti Parcel Point next working day after 4 PM'),
      id: 'P2',
      notes: '',
      price: 790,
      type: DeliveryType.POSTI_SMARTPOST_EXPRESS,
      image: POSTI_LOGO_CLASS,
      searchService: POSTI_SEARCH_SERVICE,
      deliveryModeType: DeliveryModeType.COLLECT_POINT,
      allowedForEmployees: false,
    },
  ],
  [
    DeliveryType.MATKAHUOLTO_LAHELLA_PAKETTI,
    {
      additionalInformationLink: '',
      createDescription: () => t.UYL0('Pick up at Matkahuolto Service Point'),
      id: 'M2',
      notes: '',
      price: 590,
      type: DeliveryType.MATKAHUOLTO_LAHELLA_PAKETTI,
      image: MATKAHUOLTO_LOGO_CLASS,
      searchService: MATKAHUOLTO_SEARCH_SERVICE,
      deliveryModeType: DeliveryModeType.COLLECT_POINT,
      allowedForEmployees: false,
    },
  ],
]);

export const getAddressDeliveryMethods = (
  deliveryMethods?: DeliveryMethod[],
  isEmployee?: boolean
): DeliveryMethodDetails[] => {
  return deliveryMethods
    ?.filter(item => DELIVERY_METHOD_DETAILS.get(item.deliveryTypeCode))
    ?.map(items => {
      return { ...DELIVERY_METHOD_DETAILS.get(items.deliveryTypeCode), price: items.price };
    })
    .filter(item => item.deliveryModeType === DeliveryModeType.ADDRESS)
    .filter(item => (isEmployee ? item.allowedForEmployees === true : true)) as DeliveryMethodDetails[];
};

export const getCollectPointDeliveryMethods = (
  deliveryMethods?: DeliveryMethod[],
  isEmployee?: boolean
): DeliveryMethodDetails[] => {
  return deliveryMethods
    ?.filter(item => DELIVERY_METHOD_DETAILS.get(item.deliveryTypeCode))
    ?.map(items => {
      return { ...DELIVERY_METHOD_DETAILS.get(items.deliveryTypeCode), price: items.price };
    })
    .filter(item => item.deliveryModeType === DeliveryModeType.COLLECT_POINT)
    .filter(item => (isEmployee ? item.allowedForEmployees === true : true)) as DeliveryMethodDetails[];
};

export const DELIVERY_MODES: DeliveryMode[] = [
  {
    type: DeliveryModeType.ADDRESS,
    icon: 'delivery',
    createText: () => t.ZQ4R('Delivery to address'),
    getDeliveryMethods: getAddressDeliveryMethods,
  },
  {
    type: DeliveryModeType.COLLECT_POINT,
    icon: 'location',
    createText: () => t.TWHW('Collect point'),
    getDeliveryMethods: getCollectPointDeliveryMethods,
  },
];

export const DEFAULT_DELIVERY_MODE = DELIVERY_MODES[0];

export const getAllDeliveryMethods = (
  deliveryMethods?: DeliveryMethod[],
  isEmployee?: boolean | undefined
): DeliveryMethodDetails[] => {
  return deliveryMethods
    ?.filter(item => DELIVERY_METHOD_DETAILS.get(item.deliveryTypeCode))
    ?.map(item => {
      return { ...DELIVERY_METHOD_DETAILS.get(item.deliveryTypeCode), price: item.price };
    })
    .filter(item => (isEmployee ? item.allowedForEmployees === true : true)) as DeliveryMethodDetails[];
};

export const getDefaultDeliveryMethod = (deliveryMethods?: DeliveryMethod[]): DeliveryMethodDetails => {
  return getAddressDeliveryMethods(deliveryMethods)[0];
};

export interface DeviceCheckoutDeliveryDetailsType {
  address?: Address;
  addressType?: DeliveryAddressType;
  deliveryMethodType: DeliveryType;
  deliveryPrice: number;
  deliveryMethodDescription: string;
  recipientName?: string;
  recipientPhoneNumber?: string;
  pickupPoint?: PickupPoint;
  companyName?: string;
  shipmentType?: OnlineOrder.ShipmentTypeEnum;
}

export interface DeliveryMethodDetails {
  id: string;
  notes: string;
  createDescription: () => string;
  type: DeliveryType;
  additionalInformationLink?: string;
  price: number;
  image: string;
  searchService?: string;
  isDirectDeliveryAllowed?: boolean;
  deliveryModeType?: DeliveryModeType;
  allowedForEmployees?: boolean;
}

export interface DeliveryMode {
  type: DeliveryModeType;
  icon: string;
  createText: () => string;
  getDeliveryMethods: (
    deliveryMethods?: DeliveryMethod[],
    isEmployee?: boolean,
    deliveryModeType?: DeliveryModeType
  ) => DeliveryMethodDetails[];
}

export interface DeviceCheckoutDeliveryDetailsBaseProps {
  deliveryDetails: DeviceCheckoutDeliveryDetailsType;
  discountedBasketLevelPrices?: Array<DiscountedPriceItem>;
  isEmployee?: boolean;
  eppDevicePresentInCart?: boolean;
  saveDeliveryDetailsToState: (
    deliveryDetails: DeviceCheckoutDeliveryDetailsType,
    validationErrors?: CommonError[]
  ) => void;
  footerNote?: string;
  skipDeliveryMethods?: boolean;
  listOfDeliveryMethods?: DeliveryMethod[];
}
