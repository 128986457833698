import * as CL from '@design-system/component-library';
import { CommonErrorType } from '../../common/enums.js';
import { FormProvider, useForm } from 'react-hook-form';
import { PASSWORD_MAX_LENGTH, PASSWORD_REGEX, getMinCharRegex } from '../../common/utils/validationUtils.js';
import { Password } from '../../common/react-hook-form/fields/Password.js';
import {
  addEmptyFieldValidationError,
  addValidationError,
  convertStringMapToCommonErrors,
} from '../../common/utils/errorUtils.js';
import {
  confirmMsg,
  fieldCantBeEmptyMsg,
  incorrectPasswordMsg,
  passwordInstructionErrorMsg,
  passwordsDontMatchMsg,
  t,
} from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { useEffect } from 'react';
import type { CommonError } from '../../common/types/errors.js';

import './ChangePasswordForm.scss';

export type ChangePasswordFormProps = {
  errors?: CommonError[] | null;
  onSubmit: (data: ChangePasswordFormValues, validationErrors?: CommonError[]) => void;
  saving?: boolean;
};

export interface ChangePasswordFormValues {
  confirmNewPassword?: string;
  currentPassword?: string;
  newPassword?: string;
}

export function getErrorsFromChangePasswordFormComponent(
  data: ChangePasswordFormValues,
  validationErrors?: CommonError[]
): CommonError[] | undefined {
  const errors: CommonError[] = [];
  if (data) {
    if (data.confirmNewPassword?.trim() !== data.newPassword?.trim() && !validationErrors) {
      addValidationError(errors, t.JTM7(`Passwords don't match`), '');
      return errors;
    }
    if (data.currentPassword?.trim() === data.newPassword?.trim() && !validationErrors) {
      addValidationError(errors, t.FUDM(`Current password and new password shouldn't be the same`), '');
      return errors;
    }
    if (!data.confirmNewPassword || data.confirmNewPassword.trim().length === 0) {
      addEmptyFieldValidationError(errors, 'confirmNewPassword', t.VPVR(fieldCantBeEmptyMsg));
    }
    if (!data.newPassword || data.newPassword.trim().length === 0) {
      addEmptyFieldValidationError(errors, 'newPassword', t.VPVR(fieldCantBeEmptyMsg));
    }
    if (!data.currentPassword || data.currentPassword.trim().length === 0) {
      addEmptyFieldValidationError(errors, 'currentPassword', t.VPVR(fieldCantBeEmptyMsg));
    }
  }
  if (errors.length === 0) {
    return validationErrors;
  }
  return errors.concat(validationErrors || []);
}

export const getErrorsFromChangePasswordFailedResponse = (ajaxErrors?: CommonError[]): CommonError[] | undefined => {
  if (ajaxErrors && ajaxErrors[0]?.type === CommonErrorType.UNPROCESSABLE_ENTITY) {
    const errors: CommonError[] = [];
    addValidationError(errors, t.NH72(incorrectPasswordMsg), 'currentPassword');
    return errors;
  }
  return ajaxErrors;
};

export const ChangePasswordForm = (props: ChangePasswordFormProps) => {
  const methods = useForm<ChangePasswordFormValues>();
  const { handleSubmit, getValues, setError } = methods;

  const onSubmit = (values: ChangePasswordFormValues, validationErrors?: { [s: string]: string }) => {
    props.onSubmit(values, convertStringMapToCommonErrors(validationErrors));
    return true;
  };

  useEffect(() => {
    props.errors?.forEach(commonError => {
      if (commonError.field) {
        // @ts-ignore
        setError(commonError.field, {
          type: 'custom',
          message: commonError.message,
        });
      }
    });
  }, [props.errors, setError]);

  const showErrors = (errors?: CommonError[]) => {
    return errors
      ?.filter(error => error?.field?.length === 0)
      .map(error => (
        <div className={dsClass.MARGIN_BOTTOM_2} key={error.message}>
          {error.message}
        </div>
      ));
  };

  return (
    <FormProvider {...methods}>
      <CL.Grid className={dsClass.MARGIN_TOP_4}>
        <CL.GridRow>
          <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
            <Password
              disabled={props.saving}
              name="currentPassword"
              label={t.WWTP('Current password')}
              placeholder={t.WWTP('Current password')}
              required={true}
              validate={(value: string) => {
                const validate = (v: string) => getMinCharRegex(8).test(v) && v.length <= PASSWORD_MAX_LENGTH;
                if (!validate(value)) {
                  return t.ZUZG(t.RI4Z('Your password must be at least 8 characters long.'));
                }
                return undefined;
              }}
            />
          </CL.GridCol>
          <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
            <Password
              disabled={props.saving}
              name="newPassword"
              label={t.CSI7('New Password')}
              placeholder={t.CSI7('New Password')}
              required={true}
              validate={(value: string, values: { newPassword: string; confirmNewPassword: string }) => {
                const array: string[] = [];
                if (!PASSWORD_REGEX.test(value)) {
                  array.push(t.ZUZG(passwordInstructionErrorMsg));
                }
                if (values.confirmNewPassword && value !== values.confirmNewPassword) {
                  array.push(t.HWDL(passwordsDontMatchMsg));
                }
                return array.length > 0 ? array.join(' ') : undefined;
              }}
            />
          </CL.GridCol>
        </CL.GridRow>
        <CL.GridRow>
          <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6} className={dsClass.SHOW_S_AND_UP}>
            <span>&nbsp;</span>
          </CL.GridCol>
          <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
            <Password
              disabled={props.saving}
              name="confirmNewPassword"
              label={t.OTX5('Confirm password')}
              placeholder={t.OTX5('Confirm password')}
              required={true}
              validate={(value: string, values: { newPassword: string; confirmNewPassword: string }) => {
                const array: string[] = [];
                if (!PASSWORD_REGEX.test(value)) {
                  array.push(t.ZUZG(passwordInstructionErrorMsg));
                }
                if (values.newPassword && value !== values.newPassword) {
                  array.push(t.HWDL(passwordsDontMatchMsg));
                }
                return array.length > 0 ? array.join(' ') : undefined;
              }}
            />
          </CL.GridCol>
        </CL.GridRow>
        <CL.GridRow>
          <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
            <div className={dsClass.MARGIN_BOTTOM_3}>{showErrors(props.errors || undefined)}</div>
          </CL.GridCol>
        </CL.GridRow>
        <CL.GridRow>
          <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
            <CL.Button type="submit" onClick={handleSubmit(() => onSubmit(getValues()))}>
              {t.QVYK(confirmMsg)}
            </CL.Button>
          </CL.GridCol>
        </CL.GridRow>
      </CL.Grid>
    </FormProvider>
  );
};
