import { ModelType, SubscriptionCardType } from '../../common/enums.js';
import { SalesType } from '../../generated/api/salesType.js';
import { getAddonsRecurringPrice } from '../../common/utils/addOnUtils.js';
import { getCommercialProductPrices } from '../../common/utils/commercialProductUtils.js';
import { getOfferCampaignAssociationAndPromotion } from '../../common/utils/campaignUtils.js';
import { isCommitmentPeriodActive } from '../../common/utils/subscriptionUtils.js';
import type { CampaignAssociation } from '../../generated/api/campaignAssociation.js';
import type { CampaignContextsFromVoucher } from './CampaignProductCard.js';
import type { CampaignPromotion } from '../../generated/api/campaignPromotion.js';
import type { CommercialProduct } from '../../generated/api/commercialProduct.js';
import type { Offer } from '../../generated/api/offer.js';
import type { OnlineModel } from '../../generated/api/onlineModel.js';
import type { SubCardWithAnalyticsV4Props } from '../SubscriptionCards/subscriptionCardsAnalyticsUtils.js';
import type { Subscription } from '../../generated/api/subscription.js';
import type { SubscriptionCardProps } from '../SubscriptionCard/SubscriptionCard.js';

const isChangeOfferAllowed = (
  isChangeOffer: boolean,
  currentCP: CommercialProduct,
  subscription?: Subscription,
  campaignAssociation?: CampaignAssociation
) => {
  // In case of doing a change offer from fixed term campaigns we need to ensure that
  // 1) It's only possible to update to other fixed term campaign
  // 2) It's not possible to update to a cheaper offer
  if (isChangeOffer && isCommitmentPeriodActive(subscription?.details?.commitmentEndDate)) {
    if (!campaignAssociation?.fixedTermPeriod) {
      return false;
    }

    const discountedPrice = getCommercialProductPrices(currentCP, campaignAssociation, SalesType.CHANGE_OFFER);

    if ((subscription?.details?.monthlyRecurringCharge || 0) > (discountedPrice?.monthlyRecurringCharge || 0)) {
      return false;
    }
  }
  return true;
};

export type OfferPerGroup = { groupName: string; offerCode: string; addonCode?: string };

// Should never happen, if offer/onlineModel is missing in this point there are some serious issues
export const validateOfferAndModel = (offer?: Offer, onlineModel?: OnlineModel): void => {
  if (!offer) {
    throw new Error('Cannot create subscription card content, offer missing');
  } else if (!onlineModel) {
    throw new Error('Cannot create subscription card content, online model missing');
  }
};

const hasMatchingOffer = (onlineModelOfferCodes: string[], cardContent: SubscriptionCardProps) => {
  const offerCodes = cardContent.offers?.map(offer => offer.guid) || [];

  switch (cardContent.cardType) {
    case SubscriptionCardType.VOICE:
      return offerCodes.some(offerCode => onlineModelOfferCodes.includes(offerCode));
    case SubscriptionCardType.MOBILE_M2M:
    case SubscriptionCardType.MOBILE_BROADBAND:
      return onlineModelOfferCodes.includes(cardContent.selectedOffer || '');
    default:
      return false;
  }
};

const isEligibleOffer = (offer: Offer, cardContents: SubscriptionCardProps[]): boolean =>
  cardContents.some(
    content =>
      (content.offers && content.offers.some(off => off.guid === offer.offerCode)) ||
      content.selectedOffer === offer.offerCode
  );

export const getInternetAddonCode = (offer: Offer) => {
  return offer.commercialProducts[0].associatedAddOns?.find(
    associatedAddOn => associatedAddOn.addOnGroup === 'Internet'
  )?.addOnCode;
};

export const isOfferAvailable = (offer: Offer, subscription?: Subscription, onlineModelCode?: string) => {
  // For Laitenetti we need to check addons, because every Laitenetti type (S, M, L) has the same Offer
  if (onlineModelCode === ModelType.MobileM2M) {
    const addonCode = getInternetAddonCode(offer);
    return !subscription?.details?.selectedAddOns?.find(addon => addon.addOnCode === addonCode);
  }
  return offer.commercialProducts[0].commercialProductCode !== subscription?.commercialProductCode;
};

export const getSubscriptionCardContent = (
  isChangeOffer: boolean,
  campaignContextsFromVoucher: CampaignContextsFromVoucher,
  campaignPromotions: CampaignPromotion[],
  availableContent: SubscriptionCardProps[],
  selectedOffersPerGroup: OfferPerGroup[],
  setSelectedOffersPerGroup: (offerPerGroup: OfferPerGroup[]) => void,
  selectedOffer?: Offer,
  selectedAddonCode?: string,
  onlineModel?: OnlineModel,
  subscription?: Subscription
): SubCardWithAnalyticsV4Props[] => {
  const eligibleOfferDetails = (onlineModel?.offers || [])
    .filter(offer => isOfferAvailable(offer, subscription, onlineModel?.onlineModelCode))
    .map(offer => {
      const { campaignAssociation, campaignPromotion, voucher } =
        getOfferCampaignAssociationAndPromotion(
          offer,
          isChangeOffer ? SalesType.CHANGE_OFFER : SalesType.NEW_SALE,
          campaignContextsFromVoucher.campaignContexts,
          campaignContextsFromVoucher.voucher,
          campaignPromotions
        ) || {};

      const addOnCode = getInternetAddonCode(offer);

      return {
        campaignAssociation,
        campaignPromotion,
        offer,
        voucherInUse: voucher !== undefined,
        voucher,
        addOnCode,
      };
    })
    .filter(({ offer }) => isEligibleOffer(offer, availableContent))
    .filter(({ offer, campaignAssociation }) =>
      isChangeOfferAllowed(isChangeOffer, offer.commercialProducts[0], subscription, campaignAssociation)
    );

  const eligibleOffers = eligibleOfferDetails.map(offer => offer.offer);
  const eligibleOfferCodes = eligibleOffers.map(offer => offer.offerCode);

  const handleChangeOffer = (guid: string, groupName?: string) =>
    setSelectedOffersPerGroup([
      ...selectedOffersPerGroup.filter(off => off.groupName !== groupName),
      { groupName: groupName || '', offerCode: guid },
    ]);

  const findSelectedOfferByGroup = (groupName: string) =>
    selectedOffersPerGroup.find(off => off.groupName === groupName)?.offerCode || '';

  return availableContent
    .filter(cardContent =>
      // hide Subscription Cards when none of its offers are eligible
      hasMatchingOffer(eligibleOfferCodes, cardContent)
    )
    .map(cardContent => {
      // Update default selected offer for current card if the pre-selected offer is not eligible
      // e.g if the offer being changed is default selected then change selected to first available offer
      const availableOfferButtons = cardContent.offers?.filter(offer => eligibleOfferCodes.includes(offer.guid));
      if (!eligibleOfferCodes.includes(findSelectedOfferByGroup(cardContent.cardName || ''))) {
        handleChangeOffer(availableOfferButtons?.[0]?.guid || '', cardContent.cardName);
      }
      return cardContent;
    })
    .reduce<SubCardWithAnalyticsV4Props[]>((acc, cardContent) => {
      const selectedGuidForGroup = selectedOffersPerGroup.find(off => off.groupName === cardContent.cardName);
      const selectedGuidForGroupCode = selectedGuidForGroup?.addonCode || selectedGuidForGroup?.offerCode || '';

      const selectedOfferDetails = eligibleOfferDetails.find(
        details =>
          details.offer.offerCode === selectedGuidForGroupCode || details.addOnCode === selectedGuidForGroupCode
      );

      if (selectedOfferDetails) {
        const cp = selectedOfferDetails.offer.commercialProducts[0];
        const addonsRecurringPrice = getAddonsRecurringPrice(
          cp,
          cardContent.cardType === SubscriptionCardType.MOBILE_M2M
        );

        const prices = getCommercialProductPrices(
          cp,
          selectedOfferDetails.campaignAssociation,
          selectedOfferDetails.campaignAssociation?.salesType
        );
        const currentCardSelectedOffer = selectedOffersPerGroup.find(off => off.groupName === cardContent.cardName);

        // Offer data is used only for analytics
        validateOfferAndModel(selectedOfferDetails.offer, onlineModel);

        // Define the type explicitly, otherwise TS compiler property check might fail
        const selectedCardWithAnalytics: SubCardWithAnalyticsV4Props = {
          ...cardContent,
          onChangeOffer: (guid: string) => handleChangeOffer(guid, cardContent?.cardName),
          selectedOffer: currentCardSelectedOffer?.offerCode,
          selected:
            currentCardSelectedOffer?.offerCode === selectedOffer?.offerCode &&
            (currentCardSelectedOffer?.addonCode ? currentCardSelectedOffer?.addonCode === selectedAddonCode : true),
          offers: cardContent.offers?.filter(o => eligibleOfferCodes.includes(o.guid)),
          // Safe to use non-null assertion, since offer existence is validated in validateOfferAndModel function
          offer: selectedOfferDetails.offer,
          onlineModel: onlineModel!,
          voucher: selectedOfferDetails.voucher,
          voucherCode: selectedOfferDetails.voucher,
          oneTimePrice: prices.oneTimeCharge,
          originalOneTimePrice: prices.oneTimeChargeBeforeDiscount,
          monthlyPrice: (prices.monthlyRecurringCharge ?? 0) + addonsRecurringPrice,
          originalMonthlyPrice: (prices.monthlyRecurringChargeBeforeDiscount ?? 0) + addonsRecurringPrice,
          campaignAssociation: selectedOfferDetails.campaignAssociation,
          fixedTermMonths: selectedOfferDetails.campaignAssociation?.fixedTermPeriod,
        };
        acc.push(selectedCardWithAnalytics);
      }
      return acc;
    }, []);
};
