import * as CL from '@design-system/component-library';
import { OpenFormGridCol } from './OpenFormGrid.js';
import { useEffect } from 'react';

export const OpenFormDropdown = ({
  colWidth = 12,
  items,
  onChange,
  selectedValue,
  ...props
}: CL.DropdownProps & {
  colWidth?: CL.GridColProps['colWidth'];
  onChange: (value?: string) => void;
}) => {
  useEffect(() => {
    if (!selectedValue && items.length) {
      onChange(items[0].value);
    }
  }, [items, selectedValue, onChange]);

  return (
    <OpenFormGridCol colWidth={colWidth}>
      <CL.Dropdown
        {...props}
        items={items}
        selectedValue={selectedValue}
        onValueChange={el => onChange(el.dataset.value)}
      />
    </OpenFormGridCol>
  );
};
