import * as CL from '@design-system/component-library';
import { Link } from 'react-router-dom';
import { SubscriptionAdditionalServiceStatusEnum } from '../../common/enums.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { showAddonAsLink } from './subscriptionAddOnUtils.js';
import type { AddOnStatusInfo } from '../SubscriptionDetails/addOnDependencyUtils.js';
import type { ReactNode } from 'react';

import './SubscriptionAdditionalService.scss';

interface PriceInfo {
  value: ReactNode;
  additionalText?: ReactNode;
}

export type SubscriptionAdditionalServiceStatus = SubscriptionAdditionalServiceStatusEnum;

const getStatusColor = (serviceStatus: SubscriptionAdditionalServiceStatusEnum) => {
  switch (serviceStatus) {
    case SubscriptionAdditionalServiceStatusEnum.IN_USE:
      return 'ok';
    case SubscriptionAdditionalServiceStatusEnum.NOT_IN_USE:
      return 'secondary';
    case SubscriptionAdditionalServiceStatusEnum.CHANGE_REQUESTED:
      return 'warning';
  }
};

export interface SubscriptionAdditionalServiceProps {
  title: string;
  addOnStatusInfo: AddOnStatusInfo;
  children?: ReactNode;
  priceInfo?: PriceInfo;
  subTitle?: ReactNode;
  additionalServicePath?: string;
  actionButton?: { buttonText: string; onClick: () => void };
  isEmployee?: boolean;
  isMobileId?: boolean;
  mobileIdLinkButton?: ReactNode;
}

const CommonAddonInfo = ({
  title,
  subTitle,
  addOnStatusInfo,
}: {
  title: string;
  subTitle?: ReactNode;
  addOnStatusInfo: AddOnStatusInfo;
}) => (
  <div className={dsClass.TEXT_ALIGN_LEFT}>
    <div className={`${dsClass.DISPLAY_BLOCK} ${dsClass.WHITE_SPACE_NOWRAP}`}>{title}</div>
    {subTitle && <div className={dsClass.FONT_SIZE_SMALL}>{subTitle}</div>}
    <CL.Badge type="status" color={getStatusColor(addOnStatusInfo.status)} />
    <span className={dsClass.FONT_SIZE_SMALL}>{` ${addOnStatusInfo.text}`}</span>
  </div>
);

const AddonInfoWithLink = ({
  title,
  subTitle,
  additionalServicePath,
  addOnStatusInfo,
}: {
  title: string;
  addOnStatusInfo: AddOnStatusInfo;
  subTitle?: ReactNode;
  additionalServicePath?: string;
}) => (
  // Non-null assertion operator (!) can be used, since the existence of additionalServicePath is checked in showAddonAsLink function
  <Link className="of-additional-service__title" to={additionalServicePath!}>
    <CommonAddonInfo title={title} subTitle={subTitle} addOnStatusInfo={addOnStatusInfo} />
  </Link>
);

const AddOnInfoWithoutLink = ({
  title,
  subTitle,
  addOnStatusInfo,
}: {
  title: string;
  subTitle: ReactNode;
  addOnStatusInfo: AddOnStatusInfo;
}) => (
  <div className="of-additional-service__title">
    <CommonAddonInfo title={title} subTitle={subTitle} addOnStatusInfo={addOnStatusInfo} />
  </div>
);

// Show price info for admins, with EOE mobile id show link button
const PriceInfoOrLink = ({
  priceInfo,
  mobileIdLinkButton,
}: {
  priceInfo?: PriceInfo;
  mobileIdLinkButton: ReactNode;
}) => (
  <div className={`${dsClass.DISPLAY_FLEX} ${dsClass.MARGIN_TOP_2} of-additional-service__price`}>
    <div>
      <span className={`${dsClass.DISPLAY_BLOCK} ${dsClass.WHITE_SPACE_NOWRAP}`}>{priceInfo?.value}</span>
      {priceInfo?.additionalText && <span className={dsClass.FONT_SIZE_SMALL}>{priceInfo.additionalText}</span>}
      {mobileIdLinkButton && <span>{mobileIdLinkButton}</span>}
    </div>
  </div>
);

export const SubscriptionAdditionalService = ({
  subTitle,
  actionButton,
  addOnStatusInfo,
  children,
  title,
  priceInfo,
  additionalServicePath,
  isEmployee,
  isMobileId,
  mobileIdLinkButton,
}: SubscriptionAdditionalServiceProps) => {
  return (
    <div
      className={`of-additional-service ${
        addOnStatusInfo?.status === SubscriptionAdditionalServiceStatusEnum.NOT_IN_USE
          ? 'of-additional-service__inactive'
          : ''
      } of-additional-service ${dsClass.PADDING_3}`}
    >
      <div className={`of-additional-service__header ${dsClass.DISPLAY_FLEX} ${dsClass.JUSTIFY_CONTENT_SPACE_BETWEEN}`}>
        {showAddonAsLink(additionalServicePath, isEmployee, addOnStatusInfo?.status) ? (
          <AddonInfoWithLink
            additionalServicePath={additionalServicePath}
            title={title}
            subTitle={subTitle}
            addOnStatusInfo={addOnStatusInfo}
          />
        ) : (
          <AddOnInfoWithoutLink title={title} subTitle={subTitle} addOnStatusInfo={addOnStatusInfo} />
        )}
        {(!isEmployee || isMobileId) && (
          <PriceInfoOrLink priceInfo={priceInfo} mobileIdLinkButton={mobileIdLinkButton} />
        )}
      </div>

      <div
        className={`of-additional-service__header ${dsClass.DISPLAY_FLEX} ${dsClass.JUSTIFY_CONTENT_SPACE_BETWEEN} ${dsClass.MARGIN_TOP_2}`}
      >
        <div className="of-additional-service__title">
          <div className={dsClass.TEXT_ALIGN_LEFT}>{children}</div>
        </div>
        {actionButton && (
          <div className={`${dsClass.DISPLAY_FLEX} ${dsClass.ALIGN_ITEMS_FLEX_END} of-additional-service__action`}>
            <CL.Button type="button" onClick={actionButton.onClick} color="link" size="s">
              {actionButton.buttonText}
            </CL.Button>
          </div>
        )}
      </div>
    </div>
  );
};
