import { EMPTY, of } from 'rxjs';
import { PHONE_NUMBER_REGEX } from '../../common/utils/phoneNumberUtils.js';
import {
  PrereserveNumbersRequest,
  ValidatePortableResponse,
  ValidateSimFreeResponse,
} from '../../generated/api/models.js';
import {
  TypeKeys,
  getCommercialAvailabilityFailed,
  getCommercialAvailabilityFulfilled,
  getPublicPageFailed,
  getPublicPageFulfilled,
  getPublicPageNotFound,
  loadHolidaysFailed,
  loadHolidaysFulfilled,
  loadNumberRangeFailed,
  loadNumberRangeFulfilled,
  prereserveNumbers as prereserveNumbersAction,
  prereserveNumbersFailed,
  prereserveNumbersFulfilled,
  searchAddressFailed,
  searchAddressFulfilled,
  searchCompanyFailed,
  searchCompanyFulfilled,
  searchDomainFailed,
  searchDomainFulfilled,
  searchPublicPagesFailed,
  searchPublicPagesFulfilled,
  validatePhoneNumberFailed,
  validatePhoneNumberFulfilled,
  validateSimFailed,
  validateSimFulfilled,
} from '../actions/index.js';
import { actionToActionState, getMdmIdHeaderFromUrlParams, resolveStatusCode } from './epicUtils.js';
import { callUiApi, prepareUiApiRequest } from '../common/uiApiUtils.js';
import { combineEpics, ofType } from 'redux-observable';
import { concatMap, debounceTime, filter, mergeMap } from 'rxjs/operators';
import {
  domainGetPublicMethod,
  getCommercialAvailabilityPublicMethod,
  getHolidaysPublicMethod,
  getNumberRangePrivateMethod,
  getPagePublicMethod,
  getValidatePortablePrivateMethod,
  getValidateSimFreePrivateMethod,
  prereserveNumbersPrivateMethod,
  searchAddressPublicMethod,
  searchCompaniesPublicMethod,
  searchPagesPublicMethod,
} from '../../generated/api/uiApiMethods.js';
import { invalidPhoneNumberMsg, t } from '../../common/i18n/index.js';
import { isInBrowser } from '../../common/utils/ssrUtils.js';
import { isInvalidSimCardNumber } from '../../components/SimCardDuringNewSubscriptionOrder/SimCardDuringNewSubscriptionOrder.js';
import { paths } from '../../common/constants/pathVariables.js';
import { redirectWithState } from '../common/redirect.js';
import { replace } from 'redux-first-history';
import type { Action } from 'redux';
import type { ActionAndState, EpicDependencies } from './epicUtils.js';
import type { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import type {
  AddressSearchResponse,
  CompanySearchResponse,
  HolidaysResponse,
  NumberRangeResponse,
  PageSearchResponse,
} from '../../generated/api/models.js';
import type { AjaxResponse } from 'rxjs/ajax';
import type {
  GetCommercialAvailabilityAction,
  GetPublicPageAction,
  GetPublicPageFailedAction,
  GetPublicPageFulfilledAction,
  HardRedirectAction,
  LoadHolidaysAction,
  LoadNumberRangeAction,
  PrereserveNumbersAction,
  PublicPageFailedActionParams,
  SearchAddressAction,
  SearchAddressFulfilledAction,
  SearchCompanyAction,
  SearchDomainAction,
  SearchPublicPagesAction,
  SelectedCommercialProductsAmountChangeAction,
  SelfServiceActionTypes,
  ValidatePhoneNumberAction,
  ValidateSimAction,
} from '../actions/index.js';
import type { State } from '../common/store.js';

const prereserveNumbers: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.PRERESERVE_NUMBERS)), (action: PrereserveNumbersAction) =>
    actionToActionState(action, state$, 'resources')
  ).pipe(
    concatMap(() => {
      return callUiApi({
        epicDependencies,
        failureAction: prereserveNumbersFailed,
        method: prereserveNumbersPrivateMethod(),
        payload: {
          count: 6,
          numberPool: PrereserveNumbersRequest.NumberPoolEnum.VOICE,
        },
        state$,
        headers: getMdmIdHeaderFromUrlParams(),
        successAction: (response: AjaxResponse) => prereserveNumbersFulfilled(response.response.numbers),
      });
    })
  );

const commercialProductsAmountChange: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>
) =>
  action$.pipe(
    ofType(TypeKeys.SELECTED_COMMERCIAL_PRODUCTS_AMOUNT_CHANGE),
    mergeMap((action: SelectedCommercialProductsAmountChangeAction) => of(prereserveNumbersAction(action.totalAmount)))
  );

const holidays: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.LOAD_HOLIDAYS)), (action: LoadHolidaysAction) =>
    actionToActionState(action, state$, 'resources')
  ).pipe(
    concatMap(() => {
      return callUiApi({
        method: getHolidaysPublicMethod(),
        state$,
        epicDependencies,
        successAction: (response: HolidaysResponse) => loadHolidaysFulfilled(response.dates),
        failureAction: loadHolidaysFailed,
      });
    })
  );

const loadNumberRange: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.LOAD_NUMBER_RANGE)), action =>
    actionToActionState(action, state$, 'resources')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const action = actionAndState.action as LoadNumberRangeAction;
      const successAction = (response: NumberRangeResponse) =>
        loadNumberRangeFulfilled(action.rangeId, response.numberCategory, response.numbers);
      return callUiApi({
        epicDependencies,
        failureAction: (message, status, errors, params) =>
          loadNumberRangeFailed(action.rangeId, message, status, errors, params),
        method: getNumberRangePrivateMethod(action.rangeId),
        state$,
        successAction,
        headers: getMdmIdHeaderFromUrlParams(),
      });
    })
  );

const searchCompany: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.SEARCH_COMPANY)), action =>
    actionToActionState(action, state$, 'resources')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const { query } = actionAndState.action as SearchCompanyAction;
      return callUiApi({
        method: searchCompaniesPublicMethod({ query }),
        epicDependencies,
        state$,
        successAction: (response: CompanySearchResponse) => searchCompanyFulfilled(response.result),
        failureAction: searchCompanyFailed,
      });
    })
  );

const searchDomain: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.SEARCH_DOMAIN)), action =>
    actionToActionState(action, state$, 'resources')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      return callUiApi({
        epicDependencies,
        failureAction: searchDomainFailed,
        method: domainGetPublicMethod(actionAndState.action as SearchDomainAction),
        state$,
        successAction: searchDomainFulfilled,
      });
    })
  );

const searchAddressEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.SEARCH_ADDRESS)), action =>
    actionToActionState(action, state$, 'resources')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const action = actionAndState.action as SearchAddressAction;
      return callUiApi({
        epicDependencies,
        state$,
        method: searchAddressPublicMethod({ query: action.query, postalCode: action.postalCode }),
        headers: getMdmIdHeaderFromUrlParams(),
        successAction: (response: AddressSearchResponse) =>
          searchAddressFulfilled({ ...response, postalCode: action.postalCode }),
        failureAction: searchAddressFailed,
      });
    })
  );

const getCommercialAvailability: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.GET_COMMERCIAL_AVAILABILITY)), action =>
    actionToActionState(action, state$, 'resources')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const getCommercialAvailabilityAction = actionAndState.action as GetCommercialAvailabilityAction;

      return callUiApi({
        epicDependencies,
        state$,
        method: searchAddressPublicMethod({
          query: getCommercialAvailabilityAction.address,
          postalCode: getCommercialAvailabilityAction.postalCode,
        }),
        headers: getMdmIdHeaderFromUrlParams(),
        successAction: (response: AddressSearchResponse) =>
          searchAddressFulfilled({ ...response, postalCode: getCommercialAvailabilityAction.postalCode }),
        failureAction: getCommercialAvailabilityFailed,
      }).pipe(
        concatMap((action: SearchAddressFulfilledAction) => {
          // Unusual scenario but possible in case the address validation query fired by Search.tsx has not yet
          // detected that the address is indeed invalid
          if (!action.result.match?.addressId) {
            return of(getCommercialAvailabilityFulfilled({ offerCodes: [] }));
          }
          return callUiApi({
            epicDependencies,
            state$,
            method: getCommercialAvailabilityPublicMethod({
              addressId: action.result.match.addressId,
              subscriptionType: getCommercialAvailabilityAction.subscriptionType,
            }),
            headers: getMdmIdHeaderFromUrlParams(),
            successAction: getCommercialAvailabilityFulfilled,
            failureAction: getCommercialAvailabilityFailed,
          });
        })
      );
    })
  );

const searchPublicPages: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.SEARCH_PUBLIC_PAGES)), action =>
    actionToActionState(action, state$, 'resources')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const action = actionAndState.action as SearchPublicPagesAction;
      return callUiApi({
        epicDependencies,
        state$,
        method: searchPagesPublicMethod(action),
        successAction: (response: PageSearchResponse) =>
          searchPublicPagesFulfilled(action.query, action.start, response),
        failureAction: searchPublicPagesFailed,
      });
    })
  );

const validatePhoneNumberEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) => {
  return action$.pipe(
    ofType(TypeKeys.VALIDATE_PHONE_NUMBER),
    // If the number is found in the previously validated list, then we don't want to validate it again
    // On the other hand, if the response we already have isn't validated for portability, then validate again.
    filter(
      (action: ValidatePhoneNumberAction) =>
        !state$.value?.resources?.validatedPhoneNumbers?.some(
          it => it.phoneNumber === action.phoneNumber && (it.portable !== undefined || !action.validatePortIn)
        )
    ),
    debounceTime(400), // This is also set in selfServiceVoiceOrdering.test.ts, so remember to change both.
    concatMap((action: ValidatePhoneNumberAction) => {
      if (action.phoneNumber.match(new RegExp(PHONE_NUMBER_REGEX)) === null) {
        return of(
          validatePhoneNumberFulfilled(action.phoneNumber, action.validatePortIn, {
            errorMessage: t.YLCX(invalidPhoneNumberMsg),
            errorType: ValidatePortableResponse.ErrorTypeEnum.INVALID_NUMBER_FORMAT,
            portable: false,
          })
        );
      }
      if (!action.validatePortIn) {
        return of(
          validatePhoneNumberFulfilled(action.phoneNumber, action.validatePortIn, {
            portable: false,
          })
        );
      }
      return callUiApi({
        epicDependencies,
        state$,
        method: getValidatePortablePrivateMethod({ msisdn: action.phoneNumber }),
        successAction: (response: ValidatePortableResponse) =>
          validatePhoneNumberFulfilled(action.phoneNumber, action.validatePortIn, response),
        failureAction: validatePhoneNumberFailed,
      });
    })
  );
};

const validateSimEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) => {
  return action$.pipe(
    ofType(TypeKeys.VALIDATE_SIM),
    // If the id is found in the previously validated list, then there is no need to validate it again
    filter(
      (action: ValidateSimAction) =>
        !state$.value?.resources?.validatedSims?.some(it => it.simCardNumber === action.simCardNumber)
    ),
    concatMap((action: ValidateSimAction) => {
      if (isInvalidSimCardNumber(action.simCardNumber)) {
        return of(
          validateSimFulfilled(action.simCardNumber, {
            free: false,
            errorType: ValidateSimFreeResponse.ErrorTypeEnum.INVALID_NUMBER,
          })
        );
      }
      return callUiApi({
        epicDependencies,
        state$,
        method: getValidateSimFreePrivateMethod({ iccidNumber: action.simCardNumber }),
        successAction: (response: ValidateSimFreeResponse) => validateSimFulfilled(action.simCardNumber, response),
        failureAction: validateSimFailed,
      });
    })
  );
};

/**
 * Layer for resolving values from UI-API
 */
const preparePublicPageSuccessAction = (ajaxResponse: AjaxResponse, pagePath: string, status: number) => {
  const { response } = ajaxResponse;
  const location = ajaxResponse?.xhr?.getResponseHeader('location');
  return getPublicPageFulfilled(
    location ? { location } : {},
    response || {},
    pagePath,
    resolveStatusCode(pagePath, status)
  );
};

const getPublicPageEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.GET_PUBLIC_PAGE)), action =>
    actionToActionState(action, state$, 'resources')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const { pagePath } = actionAndState.action as GetPublicPageAction;
      return callUiApi({
        epicDependencies,
        failureAction: (...rest: PublicPageFailedActionParams) => getPublicPageFailed(pagePath, rest),
        method: getPagePublicMethod({ path: pagePath }),
        state$,
        successAction: (ajaxRes: AjaxResponse) => preparePublicPageSuccessAction(ajaxRes, pagePath, ajaxRes.status),
        successActionGetAjaxResponse: true,
      });
    })
  );

const getPublicPageNotFoundEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.GET_PUBLIC_PAGE_NOT_FOUND)), action =>
    actionToActionState(action, state$, 'resources')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const { pagePath } = actionAndState.action as GetPublicPageAction;
      return callUiApi({
        epicDependencies,
        failureAction: (...rest: PublicPageFailedActionParams) => getPublicPageFailed(pagePath, rest),
        method: getPagePublicMethod({ path: paths.NOT_FOUND_404 }),
        state$,
        successAction: (ajaxRes: AjaxResponse) => preparePublicPageSuccessAction(ajaxRes, pagePath, 404),
        successActionGetAjaxResponse: true,
      });
    })
  );

const getPublicPageFailedEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>
) =>
  action$.pipe(
    ofType(TypeKeys.GET_PUBLIC_PAGE_FAILED),
    mergeMap((action: GetPublicPageFailedAction) => {
      if (action.pageStatusCode === 404) {
        return of(getPublicPageNotFound(action.pagePath));
      }
      return EMPTY;
    })
  );

const getPublicPageFulfilledEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>
) =>
  action$.pipe(
    ofType(TypeKeys.GET_PUBLIC_PAGE_FULFILLED),
    mergeMap((action: GetPublicPageFulfilledAction) => {
      // Client side handling for redirects
      // Server side handling can be found from renderServerSide in routing.tsx file
      if (isInBrowser() && action.pageStatusCode === 301 && action.headers.location) {
        const siteBaseUrl = new URL(state$.value.config.siteBaseUrl);
        const url = new URL(action.headers.location);
        // Contentful (toUrl) has validation for location, so url will always be full
        // When hostname is below consider hostname internal and treat it like that
        // Replace url so user won't be able to go back to redirect page
        if (url.hostname === siteBaseUrl.hostname) {
          // Redirect to a new pathname in same domain
          return of(replace(`${url.pathname}${url.search}`));
        }
        // Redirect to external domain
        window.location.replace(url.href);
      }
      return EMPTY;
    })
  );

/**
 * Hard redirect is made so that sessionStorage data can be moved to another page
 * where content-security-policy (csp) has now changed and allows analytics to work properly
 */
const getHardRedirect: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>
) => {
  return action$.pipe(
    ofType(TypeKeys.HARD_REDIRECT),
    mergeMap((action: HardRedirectAction) => {
      redirectWithState(state$.value, action.path);
      return EMPTY;
    })
  );
};

export const resourcesEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = combineEpics(
  holidays,
  prereserveNumbers,
  commercialProductsAmountChange,
  loadNumberRange,
  searchCompany,
  searchDomain,
  searchAddressEpic,
  getCommercialAvailability,
  searchPublicPages,
  validatePhoneNumberEpic,
  getPublicPageEpic,
  getPublicPageFailedEpic,
  getPublicPageFulfilledEpic,
  getPublicPageNotFoundEpic,
  getHardRedirect,
  validateSimEpic
);
