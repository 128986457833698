import { CustomerOrderListWithTable } from '../../../../../components/CustomerOrderList/CustomerOrderListWithTable.js';
import {
  CustomerOrderSystemError,
  CustomerOrders,
  CustomerOrdersSceneTab,
} from '../../../../../components/CustomerOrders/CustomerOrders.js';
import { Grid } from '../../../../../components/Grid/Grid.js';
import { t } from '../../../../../common/i18n/index.js';
import { useAuth } from '../../../AuthProvider.js';
import { useLoaderData } from 'react-router-dom';
import { useSearchParams } from '../../../../../common/hooks/useSearchParams.js';
import { useStore } from 'react-redux';
import type { CustomerOrdersResponse } from '../../../../../generated/api/customerOrdersResponse.js';
import type { State } from '../../../../../selfservice/common/store.js';

export const CustomerOrdersPath = () => {
  const config = useStore<State>().getState().config;
  const { authenticatedUser } = useAuth();

  const {
    searchResults: customerOrders,
    resultCountAggregations: aggregations,
    total: totalItems,
  } = useLoaderData() as CustomerOrdersResponse;

  const { search, status } = useSearchParams<{
    offset?: string;
    order?: string;
    search?: string;
    status?: string;
    sort?: string;
  }>();

  return (
    <CustomerOrderSystemError>
      <CustomerOrders tab={CustomerOrdersSceneTab.CUSTOMER_ORDERS}>
        <Grid>
          <div className="ea-context-notification">
            <div className="ea-context-notification__text ">
              <strong>{t.KQ91(`Can't find your order here?`)}</strong>{' '}
              {t.AHYR('Some of your orders being processed can be tracked using the')}&nbsp;
              <a href={`${config.classicSiteUrl}/tilaushistoria`}>{t.RR0O('OmaElisa Classic.')}</a>
            </div>
          </div>
          <CustomerOrderListWithTable
            customerOrders={customerOrders}
            aggregations={aggregations}
            loading={false}
            totalItems={totalItems}
            status={status}
            searchQuery={search}
            authenticatedUser={authenticatedUser}
          />
        </Grid>
      </CustomerOrders>
    </CustomerOrderSystemError>
  );
};
