import * as CL from '@design-system/component-library';
import { Link } from 'react-router-dom';
import { paths } from '../../common/constants/pathVariables.js';
import { t } from '../../common/i18n/index.js';

import './ContactChannelsInfoCard.scss';

const ContactChannelsInfoContent = () => {
  return (
    <>
      <p>
        <Link to={paths.CONTACT_INFO}>{t.PY9Q('Questions about invoicing or paying?')}</Link>
      </p>
      <p>
        <Link to={paths.INSTRUCTIONS}>{t.Y9B3('Instructions and knowledge base')}</Link>
      </p>
    </>
  );
};

export const ContactChannelsInfoCard = () => {
  return (
    <CL.Card
      className="of-contact-channels-info-card"
      type="white"
      heading={<h3>{t.N11H('Help needed?')}</h3>}
      content={<ContactChannelsInfoContent />}
    />
  );
};
