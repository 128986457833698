import { REDIRECT_PATH_KEY } from '../../common/constants/commonConstants.js';
import { RegistrationScene } from './RegistrationScene.js';
import { SiteContext } from '../../public/site/SiteContext.js';
import { checkUserExists, registerUser, searchCompany } from '../../selfservice/actions/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import type { State } from '../../selfservice/common/store.js';

export const userDetailsKey = 'registration-user-details';
export const userCredentialsKey = 'registration-user-credentials';

export const Registration = () => {
  const { siteLanguage } = useContext(SiteContext);
  const externalAuthentication = useAuth().externalAuthentication || { errors: [] };
  const dispatch = useDispatch();
  const location = useLocation();
  const resources = useSelector((state: State) => state.resources, deepEqual);

  const persistedCompany =
    sessionStorage.getItem('registration-company') && JSON.parse(sessionStorage.getItem('registration-company')!);
  const persistedUserDetails =
    sessionStorage.getItem(userDetailsKey) && JSON.parse(sessionStorage.getItem(userDetailsKey)!);
  const persistedUserCredentials =
    sessionStorage.getItem(userCredentialsKey) && JSON.parse(sessionStorage.getItem(userCredentialsKey)!);

  return (
    <RegistrationScene
      errors={externalAuthentication.errors || []}
      step1Attrs={{
        persistedCompany,
        companySearchResult: resources && resources.companySearchResult ? resources.companySearchResult : [],
      }}
      step3Attrs={{
        validationErrors: externalAuthentication.validationErrors || {},
        registrationInProgress: externalAuthentication.registrationInProgress || false,
      }}
      registrationCompleted={persistedUserCredentials ? true : externalAuthentication.registrationCompleted}
      persistedUserCredentials={persistedUserCredentials}
      persistedUserDetails={persistedUserDetails}
      redirectPath={new URLSearchParams(location?.search).get(REDIRECT_PATH_KEY) || undefined}
      storeDetails={(key, details) => {
        if (typeof details === 'object') {
          sessionStorage.setItem(key, JSON.stringify(details));
        } else {
          sessionStorage.setItem(key, details);
        }
      }}
      invalidateAuthSession={() => {
        sessionStorage.removeItem(userDetailsKey);
      }}
      registerUser={(identityCertificate, company, userDetails, newUser) =>
        dispatch(registerUser(identityCertificate, company, userDetails, newUser))
      }
      userExists={(identityCertificate, businessId, email) =>
        dispatch(checkUserExists(identityCertificate, businessId, email))
      }
      navigate={url => (window.location.href = url)}
      step1Dispatchers={{ searchCompany: (query: string) => dispatch(searchCompany(query)) }}
      locale={siteLanguage}
      isInExternalSystem={externalAuthentication.isInExternalSystem}
    />
  );
};
