import { OpenFormGridCol, OpenFormGridRow } from '../../OpenFormComponents/OpenFormGrid.js';
import { OpenFormItem } from '../../OpenFormComponents/OpenFormItem.js';
import { deliveryDetailsMsg, t } from '../../../../common/i18n/index.js';

export const OpenFormSummaryDeliveryDateTime = ({ date, time }: { date?: string; time?: string }) =>
  !date && !time ? null : (
    <OpenFormGridRow>
      <OpenFormGridCol colWidth={9}>
        <h4>{t.GLO4(deliveryDetailsMsg)}</h4>
        <OpenFormGridRow>
          <OpenFormGridCol>
            <OpenFormItem>{`${date ?? ''} ${time ?? ''}`}</OpenFormItem>
          </OpenFormGridCol>
        </OpenFormGridRow>
        <hr />
      </OpenFormGridCol>
    </OpenFormGridRow>
  );
