import { HeaderSectionContent, SectionContent } from '../SectionContent.js';
import { SearchResultRow } from '../SearchResultRow.js';
import { dueDateMsg, paidMsg, t } from '../../../common/i18n/index.js';
import { formatBalance, formatSum } from '../../../common/utils/priceUtils.js';
import { formatTimestampToDDMMYYYY } from '../../../common/utils/dateUtils.js';
import { generatePath } from 'react-router-dom';
import { getStatusColumnInvoiceState } from '../../InvoiceDetails/InvoiceDetails.js';
import { paths } from '../../../common/constants/pathVariables.js';
import type { InvoiceHeader } from '../../../generated/api/invoiceHeader.js';
import type { SearchResultBaseProps } from '../SearchResultRow.js';

export interface InvoiceProps extends SearchResultBaseProps {
  invoice: InvoiceHeader;
}

export const Invoice = ({ invoice, ...rest }: InvoiceProps) => {
  const { balance, sum, due, invoiceDisplayId } = invoice;
  return (
    <SearchResultRow
      icon="invoice"
      upperSection={
        <HeaderSectionContent
          left={`${t.JF65('Invoice')}: ${invoiceDisplayId}`}
          right={`${t.LA93(dueDateMsg)}: ${formatTimestampToDDMMYYYY(due)}`}
        />
      }
      lowerSection={
        <SectionContent
          statusColor={getStatusColumnInvoiceState(balance || 0, due).color}
          left={getStatusColumnInvoiceState(balance || 0, due).text}
          right={`${formatSum(sum)}, ${t.RJ27(paidMsg)} ${formatBalance(invoice.sum, invoice.balance || 0)}`}
        />
      }
      linkHref={`${generatePath(paths.INVOICE, { invoiceId: invoiceDisplayId })}?mdmId=${invoice.accountMasterId}`}
      {...rest}
    />
  );
};
