import { useEffect } from 'react';
import { useSearchParams } from './useSearchParams.js';

export const useOnBoardingId = () => {
  const { onboardingId } = useSearchParams<{ onboardingId: string }>();
  useEffect(() => {
    if (onboardingId) {
      sessionStorage.setItem('onboardingId', onboardingId);
    }
  }, [onboardingId]);
};
