import * as CL from '@design-system/component-library';
import * as React from 'react';
import { onEnterOrSpaceKeyPress } from '../../common/utils/handlerUtils.js';

import './SmallHeader.scss';

export interface SmallHeaderProps {
  onClick?: () => void;
  buttonText?: string;
}

export const SmallHeader = ({ buttonText, onClick }: SmallHeaderProps) => {
  const logo = {
    label: 'Elisa',
    onClick: (_e: React.SyntheticEvent) => {},
    url: '/',
  };
  /* isLoggedIn has no effect here, so let's just set it to false */
  return (
    <CL.Header className="of-small-header" isLoggedIn={false}>
      <CL.HeaderNavigationSection>
        {onClick && (
          <a
            className="of-small-header__button"
            href="/"
            onClick={(event: React.SyntheticEvent) => {
              event.preventDefault();
              onClick();
            }}
            onKeyDown={(event: React.KeyboardEvent) =>
              onEnterOrSpaceKeyPress(event, () => {
                onClick();
              })
            }
          >
            <CL.Icon color="blue-600" icon="arrow-left" size="l" />
            <span className="of-small-header__button-text">{buttonText}</span>
          </a>
        )}
      </CL.HeaderNavigationSection>
      <CL.HeaderNavigationSection>
        <CL.HeaderLogoAndService logo={logo} />
      </CL.HeaderNavigationSection>
      <CL.HeaderNavigationSection>
        {/* This empty section is the easiest way to center the logo */}
      </CL.HeaderNavigationSection>
    </CL.Header>
  );
};
