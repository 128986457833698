import {
  blockAllDataMsg,
  blockAllUseAbroadMsg,
  blockAllUseOutsideEuMsg,
  blockCallsToForeignNumbersMsg,
  blockDataAbroadMsg,
  blockDataOutsideEuMsg,
  entertainmentBarringMsg,
  premiumRateBarringMsg,
  serviceBarringMsg,
  t,
} from '../i18n/index.js';
import type { Barring } from '../../common/types/barring.js';

export const barrings: Record<string, Barring> = {
  // roaming barrings
  ALLOWED_EEA: {
    code: 'a8c865f5-40f0-1988-ceda-9ec0252b0b75',
    type: 'ALLOWED_EEA',
  },
  ALLOWED_EVERYWHERE: {
    code: 'c71a3bb5-51ef-8a80-a1cf-12cba68ada5d',
    type: 'ALLOWED_EVERYWHERE',
  },
  ALLOWED_FINLAND: {
    code: '9c955e38-f561-7fa2-3f6c-f94e763f9fcc',
    type: 'ALLOWED_FINLAND',
  },

  // call barrings
  CALLS_ALLOWED_FINLAND: {
    code: '68397609-c294-9fda-2a79-611919bbda17',
    type: 'CALLS_ALLOWED_FINLAND',
  },
  P1: {
    code: '9043e93a-3e00-f72e-46f7-950ed0d48ae4',
    type: 'P1',
  },
  P2: {
    code: '64840912-d994-c891-41d1-d43c81e2cb42',
    type: 'P2',
  },
  P3: {
    code: '6f9657c6-9763-7986-f6e1-2d36616170fa',
    type: 'P3',
  },
  P4: {
    code: '2c0433ff-dc3d-af93-5af7-9939352b434b',
    type: 'P4',
  },

  // sms barrings
  T1: {
    code: '945049c3-b8d6-bcd0-7851-a3a15e47e7f0',
    type: 'T1',
  },
  T2: {
    code: '945049c3-b8d6-bcd0-7851-a3a15e47e7f1',
    type: 'T2',
  },
  T3: {
    code: '945049c3-b8d6-bcd0-7851-a3a15e47e7f3',
    type: 'T3',
  },
  T4: {
    code: '945049c3-b8d6-bcd0-7851-a3a15e47e7f2',
    type: 'T4',
  },

  // data roaming barrings
  DATAVARTIJA: {
    code: 'd61443bc-eb3e-4c4e-f805-86dd6a1a6416',
    type: 'DATAVARTIJA',
  },
  DATA_ALLOWED_EEA: {
    code: 'a2265248-716d-bfb1-0b6f-7e7c6f76cd72',
    type: 'DATA_ALLOWED_EEA',
  },
  DATA_ALLOWED_EVERYWHERE: {
    code: '1c874bd6-0e86-b08b-df8c-c7ae0c4aa90f',
    type: 'DATA_ALLOWED_EVERYWHERE',
  },
  DATA_ALLOWED_FINLAND: {
    code: '2f61c0d0-00d7-e876-de2b-107b49da4f6c',
    type: 'DATA_ALLOWED_FINLAND',
  },

  // data barring
  DATA_DENIED: {
    code: 'fdead245-00bf-98ae-d3e0-bb5edb09764f',
    type: 'DATA_DENIED',
  },

  // locating barring
  DENY_LOCATING: {
    code: 'b2a5b981-7acb-0080-76d1-11af244bdd23',
    type: 'DENY_LOCATING',
  },
};

export const BARRINGS_DEPENDENCIES: { [s: string]: { [s: string]: string[] } } = {
  [barrings.DATA_ALLOWED_EVERYWHERE.code]: {
    roamingBarring: [barrings.ALLOWED_EVERYWHERE.code],
  },
  [barrings.DATA_ALLOWED_EEA.code]: {
    roamingBarring: [barrings.ALLOWED_EVERYWHERE.code, barrings.ALLOWED_EEA.code],
  },
  [barrings.DATA_ALLOWED_FINLAND.code]: {
    roamingBarring: [barrings.ALLOWED_EVERYWHERE.code, barrings.ALLOWED_EEA.code, barrings.ALLOWED_FINLAND.code],
  },
};

export const BARRING_CODE_ORDERS = {
  callBarring: [barrings.P4.code, barrings.P3.code, barrings.P2.code, barrings.P1.code],
  dataRoamingBarring: [
    barrings.DATA_ALLOWED_EVERYWHERE.code,
    barrings.DATA_ALLOWED_EEA.code,
    barrings.DATA_ALLOWED_FINLAND.code,
    barrings.DATA_DENIED.code,
  ],
  roamingBarring: [barrings.ALLOWED_EVERYWHERE.code, barrings.ALLOWED_EEA.code, barrings.ALLOWED_FINLAND.code],
  smsBarring: [barrings.T4.code, barrings.T3.code, barrings.T2.code, barrings.T1.code],
};
export const callBarringCodes = [barrings.P1.code, barrings.P2.code, barrings.P3.code, barrings.P4.code];

export const smsBarringCodes = [barrings.T1.code, barrings.T2.code, barrings.T3.code, barrings.T4.code];

// The array is ordered so that allowance codes follow from the widest to the narrowest.
// This order is necessary to search for the narrowest and the widest available barring add-on.
export const roamingBarringCodes = [
  barrings.ALLOWED_EVERYWHERE.code,
  barrings.ALLOWED_EEA.code,
  barrings.ALLOWED_FINLAND.code,
];

// The array is ordered so that allowance codes follow from the widest to the narrowest.
// This order is necessary to search for the narrowest and the widest available barring add-on.
export const dataRoamingBarringCodes = [
  barrings.DATA_ALLOWED_EVERYWHERE.code,
  barrings.DATA_ALLOWED_EEA.code,
  barrings.DATA_ALLOWED_FINLAND.code,
  barrings.DATA_DENIED.code,
];

// Get data barring level corresponding to the roaming barring level IF roaming barring is stricter than data barring
export const getDependentDataBarring = (dataRoamingBarring: string, roamingBarring: string) => {
  const dependencies = BARRINGS_DEPENDENCIES[dataRoamingBarring];
  if (!dependencies || dependencies.roamingBarring.includes(roamingBarring)) {
    return dataRoamingBarring;
  }

  return BARRING_CODE_ORDERS.dataRoamingBarring[BARRING_CODE_ORDERS.roamingBarring.indexOf(roamingBarring)];
};

export const getAddOnBarringNameByAddOnGuid = (type: string) => {
  switch (type) {
    case barrings.P1.code:
    case barrings.T1.code:
      return t.U2A0('General service barring');
    case barrings.P2.code:
    case barrings.T2.code:
      return t.Z44Y(serviceBarringMsg);
    case barrings.P3.code:
    case barrings.T3.code:
      return t.K7TH(entertainmentBarringMsg);
    case barrings.P4.code:
    case barrings.T4.code:
      return t.OMJ2(premiumRateBarringMsg);
    case barrings.DATA_ALLOWED_EEA.code:
      return t.SBCJ(blockDataOutsideEuMsg);
    case barrings.DATA_ALLOWED_FINLAND.code:
      return t.L07L(blockDataAbroadMsg);
    case barrings.DATA_DENIED.code:
      return t.CO7W(blockAllDataMsg);
    case barrings.ALLOWED_EEA.code:
      return t.LLHD(blockAllUseOutsideEuMsg);
    case barrings.ALLOWED_FINLAND.code:
      return t.HAWX(blockAllUseAbroadMsg);
    case barrings.DATAVARTIJA.code:
      return t.IY1W('€50 balance limit for data use abroad');
    case barrings.DENY_LOCATING.code:
      return t.UAD1('Block phone tracking');
    case barrings.CALLS_ALLOWED_FINLAND.code:
      return t.RYY6(blockCallsToForeignNumbersMsg);
    default:
      return '--';
  }
};
