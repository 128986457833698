import { Icon } from './Icon.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { middleTruncateFileName } from './files.js';
import type { Key } from 'react';

import './List.scss';

interface BadgeProps {
  key?: Key;
  fileName: string;
}

const Badge = ({ fileName }: BadgeProps) => (
  <li className="of-file-list--file">
    <Icon fileName={fileName} />
    <span className={`of-file-list--file--file-name ${dsClass.TEXT_S}`}>{middleTruncateFileName(fileName)}</span>
  </li>
);

export interface ListProps {
  fileNames: string[];
}

export const List = ({ fileNames }: ListProps) => (
  <ul className="of-file-list">
    {fileNames.map((name, index) => (
      <Badge key={index} fileName={name} />
    ))}
  </ul>
);
