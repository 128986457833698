import * as CL from '@design-system/component-library';
import { CompanyInfo } from './CustomFormFields.js';
import { CompanySearch } from '../../common/react-hook-form/fields/CompanySearch.js';
import { Email } from '../../common/react-hook-form/fields/Email.js';
import { FormProvider, useForm } from 'react-hook-form';
import { Lead } from '../../generated/api/models.js';
import { Message } from '../../common/react-hook-form/fields/Message.js';
import { Name } from '../../common/react-hook-form/fields/Name.js';
import { PhoneNumber } from '../../common/react-hook-form/fields/PhoneNumber.js';
import { addAnonymousLead, clearAnonymousLead } from '../../selfservice/actions/index.js';
import {
  companyDetailsMsg,
  customFormErrorFailedMsg,
  firstNameMsg,
  lastNameMsg,
  submitMsg,
  t,
} from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { isInBrowser } from '../../common/utils/ssrUtils.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import type { CustomFormDataForLead } from './CustomForm.js';
import type { PostAnonymousLeadRequest } from '../../generated/api/models.js';

import './LeadForm.scss';

const LeadThankYouPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <CL.Grid className="of-custom-form">
      <CL.GridRow justifyCenter className={dsClass.MARGIN_BOTTOM_2}>
        <CL.GridCol colWidthXS={4} colWidthS={6} colWidthM={6} colWidthL={6} colWidthXL={6}>
          <h2>{t.S6PJ('Thank you for contacting!')}</h2>
          <CL.Button
            size="l"
            className="continue-button"
            onClick={() => {
              dispatch(clearAnonymousLead());
              navigate('/');
            }}
          >
            {t.PK4G('Continue browsing the site')}
          </CL.Button>
        </CL.GridCol>
      </CL.GridRow>
    </CL.Grid>
  );
};

const mapLeadFormDataToLeadRequest = (values: CustomFormDataForLead, sourceUrl?: string): PostAnonymousLeadRequest => {
  if (!isInBrowser()) {
    throw new Error('Not in browser environment. Unable to send lead-form.');
  }
  return {
    contactDetails: {
      businessId: values.businessId,
      businessName: values.businessName,
      email: values.email,
      phoneNumber: values.phoneNumber,
      firstName: values.firstName,
      lastName: values.lastName,
    },
    lead: {
      description: values.message,
      leadType: Lead.LeadTypeEnum.ONLINE_CONTACT,
      sourceUrl,
    },
  };
};

export const LeadForm = () => {
  const dispatch = useDispatch();
  const { anonymousUser } = useAuth();
  const leadsState = {
    leadSent: anonymousUser?.leadSent,
    leadInProgress: anonymousUser?.leadInProgress,
    leadFailed: anonymousUser?.leadFailed,
  };

  const [searchParams] = useSearchParams();
  const sourceUrl =
    searchParams.get('sourceUrl') || (document?.referrer && new URL(document?.referrer).pathname) || undefined;
  const methods = useForm({ mode: 'all', defaultValues: { firstName: '', lastName: '', email: '', phoneNumber: '' } });
  const onSubmit = (data: CustomFormDataForLead) => {
    dispatch(addAnonymousLead(mapLeadFormDataToLeadRequest(data, sourceUrl)));
  };

  const firstName = t.AIK7(firstNameMsg);
  const lastName = t.Y8OY(lastNameMsg);

  if (leadsState?.leadSent) {
    return <LeadThankYouPage />;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <CL.Grid className="of-custom-form">
          <CL.GridRow justifyCenter className={dsClass.MARGIN_BOTTOM_2}>
            <CL.GridCol colWidthXS={4} colWidthS={6} colWidthM={6} colWidthL={6} colWidthXL={6}>
              <div className={dsClass.MARGIN_BOTTOM_4}>
                <h1>{t.B1SI('Contact our sales')}</h1>
              </div>

              <CompanyInfo title={t.EHOL(companyDetailsMsg)} />
              <CompanySearch />
              <Name name="firstName" label={firstName} placeholder={firstName} />
              <Name name="lastName" label={lastName} placeholder={lastName} />
              <Email />
              <PhoneNumber />
              <Message />

              {leadsState?.leadFailed && (
                <CL.Notification
                  className={dsClass.MARGIN_BOTTOM_4}
                  hasCloseButton={false}
                  text={t.DLRT(customFormErrorFailedMsg)}
                  type="error"
                  visible={true}
                />
              )}
              <CL.Button loading={leadsState?.leadInProgress} size="l" type="submit">
                {t.FSWU(submitMsg)}
              </CL.Button>
            </CL.GridCol>
          </CL.GridRow>
        </CL.Grid>
      </form>
    </FormProvider>
  );
};
