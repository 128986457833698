import { ChangeBillingAccountDialog } from '../../../../../components/ChangeBillingAccountDialog/ChangeBillingAccountDialog.js';
import { CommonErrorType, WizardType } from '../../../../../common/enums.js';
import {
  CompanyInfoContainer,
  CompanyInfoSceneTab,
} from '../../../../../components/CompanyInfoScene/CompanyInfoScene.js';
import { LOCATION_TYPE_REPLACE } from '../../../../../components/VirtualCatalogReplace/VirtualCatalogReplaceUtils.js';
import { Loading } from '../../../../../components/Loading/index.js';
import { SystemError } from '../../../../../components/SystemError/SystemError.js';
import { VirtualCatalogDetails } from '../../../../../components/VirtualCatalogDetails/VirtualCatalogDetails.js';
import { VirtualCatalogReplace } from '../../../../../components/VirtualCatalogReplace/VirtualCatalogReplace.js';
import { deepEqual, isDefined } from '../../../../../common/utils/objectUtils.js';
import { generatePath, useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getCompanyName } from '../../../../../common/utils/accountUtils.js';
import { getDefaultBillingContactId, getDefaultDeliveryMethod } from '../../../../../common/utils/billingAccountUtils';
import { isFeatureEnabledForUser } from '../../../../../common/utils/featureFlagUtils.js';
import { onUpsertVirtualCatalog } from '../../../../../common/utils/dispatcherUtils.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { t } from '../../../../../common/i18n/index.js';
import { toCatalogProductsPerCategory } from '../../../../../common/utils/catalogUtils.js';
import { useAuth } from '../../../AuthProvider.js';
import { useDispatch, useSelector } from 'react-redux';
import type { AuthenticatedUserState } from '../../../../../common/types/states.js';
import type { BillingAccountHeader } from '../../../../../generated/api/billingAccountHeader';
import type { CatalogCode } from '../../../../../common/constants/pathInterfaces.js';
import type { CatalogSearchResponse } from '../../../../../generated/api/catalogSearchResponse.js';
import type { CompanyVirtualCatalogs } from '../../../../../components/VirtualCatalogReplace/VirtualCatalogReplaceUtils.js';
import type { State } from '../../../../../selfservice/common/store.js';
import type { VirtualCatalogDetailsLoaderData } from '../../../../../common/loaders';

export interface CatalogBillingAccountDialogProps {
  mdmId: string;
  billingAccounts: BillingAccountHeader[];
}

const CatalogBillingAccountDialog = ({ mdmId, billingAccounts }: CatalogBillingAccountDialogProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const dialog = useSelector((state: State) => state.dialog);
  const catalog = location.state.catalog;
  const billingAccountId = catalog.billingAccountId;
  const catalogCode = location.state.virtualCatalog.virtualCatalogCode;
  const defaultBillingContactId = getDefaultBillingContactId(billingAccounts);
  const defaultDeliveryMethod = getDefaultDeliveryMethod(billingAccounts);

  return (
    <ChangeBillingAccountDialog
      billingAccountId={billingAccountId}
      changeRequestInProgress={dialog?.submitInProgress}
      headerText={t.H41V('Change billing account details')}
      description={t.AIUJ(
        'Please note that after the change, invoicing will still be based on two invoices for the next payment. Any supplementary services will be transferred with the main product.'
      )}
      detailedView={true}
      searchable={true}
      onBeginCreateBillingAccount={() => {
        const path = generatePath(paths.BILLING_ACCOUNTS_CREATE_NEW_BA);
        const options = {
          state: {
            ...location.state,
            redirectPath: `${location.pathname}?mdmId=${mdmId}`,
            mdmId,
            hideDisclaimerText: true,
            defaultBillingContactId,
            defaultDeliveryMethod,
          },
        };
        navigate(path, options);
      }}
      onCloseDialog={() => {
        navigate(`${generatePath(paths.COMPANY_INFO_CATALOG, { catalogCode })}?mdmId=${mdmId}`);
      }}
      onSubmit={(bid: string) => {
        if (bid !== billingAccountId) {
          onUpsertVirtualCatalog(dispatch)({ ...catalog, billingAccountId: bid }, false, location.state.virtualCatalog);
        }
      }}
    />
  );
};

const getCompanyVirtualCatalogs = (
  authenticatedUser?: AuthenticatedUserState,
  virtualCatalogsForAllCompanies?: CatalogSearchResponse[],
  currentCatalogCode?: string
): CompanyVirtualCatalogs[] => {
  const companyNames = [
    ...(authenticatedUser?.secondaryAccounts?.map(acc => getCompanyName(authenticatedUser, acc.accountMasterId)) || []),
    authenticatedUser?.companyName,
  ].filter(isDefined);

  const filteredCatalogs = virtualCatalogsForAllCompanies?.filter(c => c.result.catalogCode !== currentCatalogCode);

  return companyNames.map(companyName => ({
    companyName,
    virtualCatalogs:
      filteredCatalogs
        ?.filter(catalog => getCompanyName(authenticatedUser, catalog.result.accountMasterId) === companyName)
        .map(catalog => catalog.result) || [],
  }));
};

export const CompanyInfoCatalogPath = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { catalogCode } = useParams<CatalogCode>();
  const { companyInfo, virtualCatalogs } = useSelector((state: State) => state.selfservice || {}, deepEqual);
  const { authenticatedUser } = useAuth();
  const {
    billingAccounts,
    catalog: currentCatalog,
    catalogHeaders: virtualCatalogsForAllCompanies,
    onlineModelHeaders,
  } = useLoaderData() as VirtualCatalogDetailsLoaderData;
  const baHeaders = (billingAccounts.searchResults ?? []).map(e => e.result);
  const draftOrPublishedCatalog = currentCatalog?.draft || currentCatalog?.published;

  const accountMasterId = currentCatalog
    ? virtualCatalogsForAllCompanies?.find(vc => vc.result.catalogCode === currentCatalog.virtualCatalogCode)?.result
        ?.accountMasterId
    : undefined;

  const replaceCatalogsFeatureFlag = 'replaceCatalogs';
  const replaceCatalogs = useSelector((state: State) =>
    isFeatureEnabledForUser(
      replaceCatalogsFeatureFlag,
      state.config.featureFlags,
      authenticatedUser?.enabledFeatureFlags
    )
  );

  if (virtualCatalogs?.errors && virtualCatalogs?.errors[0].type === CommonErrorType.NOT_FOUND) {
    return <SystemError errors={virtualCatalogs?.errors} homePath={paths.SELF_SERVICE_HOME} />;
  }

  return !draftOrPublishedCatalog || !companyInfo?.discountedPrices ? (
    <Loading />
  ) : (
    <>
      {location.state?.type === WizardType.CHANGE_CATALOG_BILLING_ACCOUNT.valueOf() && (
        <CatalogBillingAccountDialog mdmId={accountMasterId!} billingAccounts={baHeaders} />
      )}
      <CompanyInfoContainer tab={CompanyInfoSceneTab.CATALOGS} isSingular={true}>
        {location.state?.type === LOCATION_TYPE_REPLACE ? (
          <VirtualCatalogReplace
            virtualCatalog={currentCatalog}
            catalog={draftOrPublishedCatalog}
            companyName={authenticatedUser?.companyName || ''}
            companyVirtualCatalogs={getCompanyVirtualCatalogs(
              authenticatedUser,
              virtualCatalogsForAllCompanies,
              catalogCode
            )}
          />
        ) : (
          <VirtualCatalogDetails
            catalog={draftOrPublishedCatalog}
            virtualCatalog={currentCatalog}
            products={toCatalogProductsPerCategory(
              draftOrPublishedCatalog,
              true,
              companyInfo?.discountedPrices,
              onlineModelHeaders
            )}
            productsPublished={
              currentCatalog.published
                ? toCatalogProductsPerCategory(
                    currentCatalog.published,
                    true,
                    companyInfo?.discountedPrices,
                    onlineModelHeaders
                  )
                : undefined
            }
            companyName={getCompanyName(authenticatedUser, accountMasterId)}
            onAddMoreProducts={() => {
              if (companyInfo?.discountedPrices) {
                navigate(paths.COMPANY_INFO_CATALOG_LIST_PRODUCTS, {
                  state: {
                    catalog: draftOrPublishedCatalog,
                    virtualCatalog: currentCatalog,
                    products: toCatalogProductsPerCategory(
                      draftOrPublishedCatalog,
                      true,
                      companyInfo?.discountedPrices,
                      onlineModelHeaders
                    ),
                    mdmId: accountMasterId,
                  },
                });
              }
            }}
            inProgressAction={virtualCatalogs?.inProgressAction}
            billingAccounts={baHeaders}
            replaceCatalogs={replaceCatalogs}
            accountMasterId={accountMasterId!}
          />
        )}
      </CompanyInfoContainer>
    </>
  );
};
