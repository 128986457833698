import { AutoComplete } from '../../../components/AutoComplete/AutoComplete.js';
import { TooltipWrapper } from '../../../components/TooltipWrapper/TooltipWrapper.js';
import {
  businessIdOrCompanyMsg,
  companyMustBeChosenMsg,
  informantBISMsg,
  loadingMsg,
  noSearchResults,
  noSelectionMsg,
  t,
} from '../../i18n/index.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { fetchCompanyList } from '../../fetch.js';
import { useController, useFormContext } from 'react-hook-form';
import type { ChangeEvent } from 'react';
import type { Company } from '../../../generated/api/models.js';

interface CompanySearchResult {
  businessId: string;
  businessName: string;
  masterId: string;
  postalAddress: string;
  postCode: string;
  postOffice: string;
}

interface SearchComponentProps<T> {
  name: string;
  label: string;
  placeholder: string;
  disabled?: boolean;
  initialFocus?: boolean;
  helpText?: string;
  noResultsText?: string;
  fieldNames?: CompanySearchResult;
  readOnlyContent?: (props: T) => JSX.Element;
}

export const CompanySearch = ({
  name = 'companySearch',
  label = t.GSAD(businessIdOrCompanyMsg),
  placeholder = t.GSAD(businessIdOrCompanyMsg),
  helpText,
  noResultsText = t.L7CZ(noSearchResults),
  fieldNames = {
    businessId: 'businessId',
    businessName: 'businessName',
    masterId: 'masterId',
    postalAddress: 'postalAddress',
    postCode: 'postCode',
    postOffice: 'postOffice',
  },
  readOnlyContent,
}: Partial<SearchComponentProps<Company>>) => {
  const methods = useFormContext();
  const control = methods.control;
  const { field, formState } = useController({ name: fieldNames.businessId, control, rules: { required: true } });
  const isTouched = formState.touchedFields[fieldNames?.businessId] || formState.isSubmitted;
  const error = isTouched && !field.value ? t.ISNF(companyMustBeChosenMsg) : undefined;
  const defaultValues = formState.defaultValues;
  const requiredOptions = [fieldNames?.businessId, fieldNames?.businessName, fieldNames?.masterId];
  const defaultOption = requiredOptions.every(key => (defaultValues || {})[key]) ? defaultValues : undefined;
  return (
    <>
      <AutoComplete
        inputRef={field.ref}
        options={defaultOption ? [defaultOption] : []}
        getUniqueId={({ businessId }: { businessId: string }) => businessId}
        getDisplayValue={(item: { businessId: string; name: string }) => `${item.name}, ${item.businessId}`}
        onInputChange={(_event: ChangeEvent, _option: Company) => {
          methods.setValue(fieldNames.businessId, _option.businessId);
          methods.setValue(fieldNames.businessName, _option.name);
          methods.setValue(fieldNames.masterId, _option.masterId);
          methods.setValue(fieldNames.postalAddress, _option.postalAddress);
          methods.setValue(fieldNames.postCode, _option.postCode);
          methods.setValue(fieldNames.postOffice, _option.postOffice);
        }}
        onInputBlur={field.onBlur}
        defaultOption={defaultOption}
        error={error}
        id={name}
        label={
          <>
            <label className={dsClass.INPUT_LABELAREA_LABEL} htmlFor={`autocomplete-input-${name}`}>
              {label}
            </label>
            <style>{`.custom-icon { width: 20px; height: 20px; vertical-align: text-bottom }`}</style>
            <TooltipWrapper
              tooltipText={t.REM3(
                `Write at least 3 characters. Continue writing if you can't find your company right away. Example Yritys Oy 1234567-8.`
              )}
            />
          </>
        }
        placeholder={placeholder}
        noOptionsMsg={t.ZW5W(noSelectionMsg)}
        async={{
          loadingMsg: t.KW12(loadingMsg),
          noResultsMsg: noResultsText,
          fetchFn: fetchCompanyList,
        }}
        readOnlyContent={readOnlyContent}
      />
      {!helpText && <p className={dsClass.TEXT_S}>{t.V9OQ(informantBISMsg)}</p>}
    </>
  );
};
