/**
 * Online UI API
 * # Summary Elisa B2B Online UI API  # Usage This API is used in the `https://yrityksille.elisa.fi/` pages.  # Identifiers 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreditCheckRequestData { 
    /**
     * Total charge for EPP items in cents
     */
    basketValueForEpp: number;
    /**
     * Total charge for device items in cents
     */
    basketValueForDevice: number;
    /**
     * Does the basket contain other products than devices (EPP & non-EPP)
     */
    otherProductIndicator: CreditCheckRequestData.OtherProductIndicatorEnum;
    /**
     * Calling system name, needed for business reporting
     */
    callingSystem: CreditCheckRequestData.CallingSystemEnum;
    /**
     * Unique identifier for user, e.g. user name or email address
     */
    userName: string;
}
export namespace CreditCheckRequestData {
    export type OtherProductIndicatorEnum = 'Yes' | 'No';
    export const OtherProductIndicatorEnum = {
        Yes: 'Yes' as OtherProductIndicatorEnum,
        No: 'No' as OtherProductIndicatorEnum
    };
    export type CallingSystemEnum = 'NOE' | 'EOE' | 'Punchout' | 'Auxiliary eSIM';
    export const CallingSystemEnum = {
        NOE: 'NOE' as CallingSystemEnum,
        EOE: 'EOE' as CallingSystemEnum,
        Punchout: 'Punchout' as CallingSystemEnum,
        Auxiliary_eSIM: 'Auxiliary eSIM' as CallingSystemEnum
    };
}


