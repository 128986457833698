import { EmployeeVoiceAndBroadbandSubscriptions } from '../../../../../../components/EmployeeSubscriptions/EmployeeVoiceAndBroadbandSubscriptions.js';
import { SubscriptionCategory } from '../../../../../../common/enums.js';
import { getEmployeeSubscriptions, loadCompanyInfo } from '../../../../../../selfservice/actions/index.js';
import { useAuth } from '../../../../AuthProvider.js';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

export const SubsPath = () => {
  const dispatch = useDispatch();
  const { authenticatedUser } = useAuth();

  useEffect(() => {
    if (authenticatedUser?.businessId) {
      dispatch(loadCompanyInfo());
    }
    dispatch(getEmployeeSubscriptions(SubscriptionCategory.VOICE));
    dispatch(getEmployeeSubscriptions(SubscriptionCategory.BROADBAND));
  }, [dispatch, authenticatedUser]);

  return <EmployeeVoiceAndBroadbandSubscriptions />;
};
