import { BreadCrumbsWithTitle } from '../BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import type { BreadCrumbList } from '../BreadCrumbs/BreadCrumbs.js';

import './DetailsHeading.scss';

export interface DetailsHeadingProps {
  breadcrumbPaths: BreadCrumbList;
  heading: string;
  headingBottom: string;
  headingTop: string;
  heroPicto: string;
}

export const DetailsHeading = ({
  breadcrumbPaths,
  heading,
  headingBottom,
  headingTop,
  heroPicto,
}: DetailsHeadingProps) => {
  return (
    <div className="of-details-heading">
      <BreadCrumbsWithTitle breadCrumbPaths={breadcrumbPaths} />
      <div className="of-details-heading__title">
        <span className={`of-details-heading__hero-picto ea-picto ea-picto--${heroPicto}`} />
        <div className="of-details-heading__hero-right">
          <h2>
            <div className={`of-details-heading__hero-right-top ${dsClass.H3} ${dsClass.H3_BOOK}`}>{headingTop}</div>
            <div className="of-details-heading__main-heading">{heading}</div>
          </h2>
          <span className="of-details-heading__hero-right-bottom">{headingBottom}</span>
        </div>
      </div>
    </div>
  );
};
