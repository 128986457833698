import { OFPageType } from '../../../generated/api/oFPageType.js';
import { type OpenForm } from '../OpenFormHooks/useOpenForm.js';
import { type OpenFormAnswers } from '../OpenFormAnswers.js';
import { OpenFormButton } from '../OpenFormComponents/OpenFormButton.js';
import { OpenFormGridCol, OpenFormGridRow } from '../OpenFormComponents/OpenFormGrid.js';
import { OpenFormIcon } from '../OpenFormComponents/OpenFormIcon.js';
import { OpenFormPageType } from './OpenFormPage/OpenFormPageType.js';
import { type UseFormReturn } from 'react-hook-form';
import { nextMsg, previousMsg, t } from '../../../common/i18n/index.js';
import { useMemo } from 'react';

export const OpenFormViewSection = ({
  methods: { formState },
  form,
  answers,
  disabled,
  valid,
  page,
  next,
  prev,
}: {
  methods: UseFormReturn;
  form: OpenForm;
  answers: OpenFormAnswers;
  disabled: boolean;
  valid: boolean;
  page: number;
  next: () => void;
  prev: () => void;
}) => {
  const section = useMemo(() => form.sections[page - 1], [form, page]);
  const errored = useMemo(() => section && answers.nextErrored(section, formState), [formState, answers, section]);
  const hideNext = section.pageType === OFPageType.AVAILABILITY_CHECK;

  return !section ? null : (
    <div id={section.guid} className="of-openform__view__section">
      <OpenFormPageType answers={answers} section={section} disabled={disabled} next={next} />
      <OpenFormGridRow className="of-openform__view__footer">
        <OpenFormGridCol className="of-openform__view__footer__content">
          <OpenFormButton
            iconPosition="left"
            icon={<OpenFormIcon icon="arrow-left" />}
            text={t.CACI(previousMsg)}
            color="link"
            size="m"
            disabled={disabled || page === 1}
            onClick={prev}
          />
          {hideNext ? null : errored ? (
            <OpenFormButton
              iconPosition="right"
              icon={<OpenFormIcon icon="arrow-right" />}
              text={t.F0MY(nextMsg)}
              color="link"
              size="m"
              disabled={disabled}
              onClick={() => document.getElementById(errored.guid)?.scrollIntoView(true)}
            />
          ) : (
            <OpenFormButton
              iconPosition="right"
              icon={<OpenFormIcon icon="arrow-right" />}
              text={t.F0MY(nextMsg)}
              color="primary"
              size="m"
              disabled={disabled || !valid || !form.sections.slice(0, page).every(answers.isCompleted)}
              onClick={next}
            />
          )}
        </OpenFormGridCol>
      </OpenFormGridRow>
    </div>
  );
};
