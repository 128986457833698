import * as CL from '@design-system/component-library';
import { Table } from '../Table/index.js';
import { createImageUrl } from '../../common/utils/domUtils.js';
import { deviceMsg, priceMsg, t } from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { getStartingPrice } from './CatalogProductSelectionUtils.js';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';
import type { CatalogProduct } from '../../common/utils/catalogUtils.js';
import type { DeviceSelection } from './CatalogProductSelectionFormWrapper.js';

interface CatalogSelectionDeviceTableProps {
  products: CatalogProduct[];
  imagesBaseUrl: string;
}

interface ProductSelectionCheckboxProps {
  product: CatalogProduct;
}

const ProductSelectionCheckbox = ({ product }: ProductSelectionCheckboxProps) => {
  const { watch, setValue } = useFormContext();
  const current = watch(`devices.${product.code}.selected`);
  const onChange = () => {
    setValue(`devices.${product.code}.selected`, !current);
  };
  return (
    <CL.Checkbox
      // TODO: Workaround to force CL.Checkbox to rerender after checked prop update. See https://atlas.elisa.fi/jira/browse/DS-1592.
      key={`devices.${product.code}.selected.${current}}`}
      name={`devices.${product.code}.selected`}
      checked={current}
      onChange={onChange}
      label=""
    />
  );
};

export const CatalogSelectionDeviceTable = ({ products, imagesBaseUrl }: CatalogSelectionDeviceTableProps) => {
  const { setValue, getValues } = useFormContext<DeviceSelection>();
  const [allSelected, setAllSelected] = useState(false);

  const onChangeSelectAll = () => {
    products.forEach(p => {
      setValue(`devices.${p.code}.selected`, !allSelected);
    });
    setAllSelected(!allSelected);
  };

  const columns: CL.Column[] = [
    {
      key: 'selection',
      label: (
        <CL.Checkbox
          checked={allSelected}
          onChange={onChangeSelectAll}
          label={t.A8VA('Select all')}
          className={dsClass.FONT_WEIGHT_BOLD}
        />
      ),
      width: '5%',
    },
    { key: 'img', label: '', width: '10%' },
    {
      key: 'device',
      label: t.TPVQ(deviceMsg),
      width: '60%',
    },
    {
      key: 'startingPrice',
      label: t.V72N(priceMsg),
      width: '20%',
    },
  ];
  const rows = products?.map(prod => {
    return {
      selection: <ProductSelectionCheckbox product={prod} />,
      img: (
        <div className="of-catalog-products-selection__table__images">
          <img
            alt={prod.manufacturer}
            {...createImageUrl({
              format: 'png',
              height: 150,
              imagesBaseUrl,
              originalUrl: prod.imageUrl,
              scalingFactors: [0.5, 1, 1.5],
              width: 150,
            })}
          />
        </div>
      ),
      device: `${prod.manufacturer} ${prod.name}`,
      startingPrice: <div className={dsClass.WHITE_SPACE_NOWRAP}> {getStartingPrice(prod)} </div>,
    };
  });

  const rowClicks = products?.map(prod => {
    const code = prod.code;
    return () => {
      const current = getValues(`devices.${code}.selected`);
      setValue(`devices.${prod.code}.selected`, !current);
    };
  });

  return (
    <div className="of-catalog-products-selection__table">
      <Table
        columns={columns}
        rows={rows || []}
        tableType="bordered"
        noItemsText={t.HUVS('No matching products found')}
        rowClicks={rowClicks}
      />
    </div>
  );
};
