import { Locale } from '../../common/i18n/index.js';
import { onEnterOrSpaceKeyPress } from '../../common/utils/handlerUtils.js';
import type { KeyboardEvent } from 'react';

import './LanguageSelector.scss';

export type LanguageSelectorProps = {
  onChangeLocale: (locale: string) => void;
  selectedLocale: string;
  separator?: string;
};

type LanguageChoiceProps = LanguageSelectorProps & {
  locale: string;
  addSeparator: boolean;
};

const LanguageChoice = ({ selectedLocale, locale, addSeparator, separator, onChangeLocale }: LanguageChoiceProps) => {
  return selectedLocale === locale ? null : (
    <>
      <span
        className="of-language-selector__link"
        onClick={() => onChangeLocale(locale)}
        onKeyPress={(event: KeyboardEvent) => onEnterOrSpaceKeyPress(event, () => onChangeLocale(locale))}
        tabIndex={0}
        role="button"
      >
        {locale.substring(3)}
      </span>
      {addSeparator && (
        <span className="of-language-selector__spacer" aria-hidden="true">
          {separator || '|'}
        </span>
      )}
    </>
  );
};

export const LanguageSelector = (props: LanguageSelectorProps) => {
  const availableLanguages = Object.values(Locale).filter(l => l !== props.selectedLocale);
  return (
    <div aria-label="Language selector" className="of-language-selector">
      {availableLanguages.map((locale: string, index: number, self: string[]) => (
        <LanguageChoice key={index} {...props} locale={locale} addSeparator={index < self.length - 1} />
      ))}
    </div>
  );
};
