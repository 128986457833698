import type { FeatureFlags } from '../../common/types/featureFlags.js';

export const isFeatureEnabled = (featureFlag?: boolean): boolean => featureFlag === undefined || featureFlag;
export const isStrictFeatureEnabled = (featureFlag?: boolean): boolean => featureFlag === true;
export const isFeatureEnabledForUser = (
  featureFlagName: keyof FeatureFlags,
  configFeatureFlags: FeatureFlags,
  userFeatureFlags?: string[]
): boolean =>
  configFeatureFlags[featureFlagName] === undefined ||
  configFeatureFlags[featureFlagName] === true ||
  userFeatureFlags?.includes(featureFlagName) === true;
