import { PublicPage } from '../../../components/PublicPage/PublicPage.js';
import { addAnonymousLead, addToCart, searchCompany } from '../../../selfservice/exports.js';
import {
  applyAnalyticsEvent,
  eCommerceClickEvent,
  eCommerceDetailEvent,
  eCommerceImpressionEvent,
  searchDomain,
} from '../../../selfservice/actions/index.js';
import { deepEqual } from '../../../common/utils/objectUtils.js';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteLoaderData } from 'react-router-dom';
import type { Offer, OnlineModel, PageResponse } from '../../../generated/api/models.js';
import type { State } from '../../../selfservice/exports.js';

export const PublicPageContent = (pageResponse: React.PropsWithChildren<PageResponse>) => {
  const {
    children,
    contentBlocks = [],
    onlineModels = [],
    onlineModelHeaders = [],
    siteStructureNodes = [],
    breadcrumbs = [],
  } = pageResponse;
  const dispatch = useDispatch();
  const resources = useSelector((state: State) => state.resources, deepEqual);
  const { companySearchResult = [], domainSearchResult = [], domainSearchState } = resources || {};

  return (
    <PublicPage
      breadcrumbs={breadcrumbs}
      companySearchResult={companySearchResult}
      contentBlocks={contentBlocks}
      domainSearchResult={domainSearchResult}
      domainSearchState={domainSearchState}
      siteStructureNodes={siteStructureNodes}
      onlineModelHeaders={onlineModelHeaders}
      onlineModels={onlineModels}
      addToCart={cartItemContainer => dispatch(addToCart(cartItemContainer))}
      onAnalyticsEvent={eventData => dispatch(applyAnalyticsEvent(eventData))}
      eCommerceEvents={{
        onClickListProduct: (type, position, omhs) => dispatch(eCommerceClickEvent(type, position, omhs)),
        onSeeListProduct: (type, position, omhs) => dispatch(eCommerceImpressionEvent(type, position, omhs)),
        onSeeProduct: (oms: OnlineModel[], offers: Offer[][]) => dispatch(eCommerceDetailEvent(oms, offers)),
      }}
      onCompanySearch={query => dispatch(searchCompany(query))}
      onDomainSearch={query => dispatch(searchDomain(query))}
      onPostAnonymousLead={lead => dispatch(addAnonymousLead(lead, []))}
      pageId={pageResponse.pageId}
    >
      {children}
    </PublicPage>
  );
};

export const PublicPath = () => {
  const page = useRouteLoaderData('public-path-root') as PageResponse;
  return <PublicPageContent {...page} />;
};
