import { PRODUCT_DETAILS_CLASS } from '../productDetailsConstants.js';
import { createAvailability } from '../utils/formatters.js';
import { t } from '../../../common/i18n/index.js';
import type { Offer } from '../../../generated/api/models.js';

import './ProductDetailsAvailability.scss';

export interface ProductDetailsAvailabilityProps {
  offer: Offer;
}

export const ProductDetailsAvailability = (props: ProductDetailsAvailabilityProps) => {
  const availability = createAvailability(props.offer);

  if (!availability) {
    return null;
  }

  return (
    <div className={`${PRODUCT_DETAILS_CLASS}-availability`}>
      {availability.color && (
        <>
          <label>{t.VC3L('Availability:')}</label>
          <span
            className={`
              ${PRODUCT_DETAILS_CLASS}-availability__icon
              ${PRODUCT_DETAILS_CLASS}-availability__icon--${availability.color}
            `}
          />
        </>
      )}
      <span>{availability.text}</span>
    </div>
  );
};
