import * as CL from '@design-system/component-library';
import { shoppingCartMsg, t } from '../../common/i18n/index.js';
import { useEffect, useRef } from 'react';
import type { ModalOperations } from '@design-system/component-library';

interface SquareTradeErrorModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const SquareTradeErrorModal = ({ isOpen, onClose }: SquareTradeErrorModalProps) => {
  const ref = useRef<ModalOperations>(null);

  useEffect(() => {
    ref?.current?.visible(isOpen);
  }, [isOpen]);

  function handleClose() {
    ref?.current?.visible(false);
    onClose?.();
  }

  return (
    <CL.Modal
      ref={ref}
      id="square-trade-error-modal"
      autoOpen={isOpen}
      onModalClose={onClose}
      size="s"
      heading={t.BE8Q(shoppingCartMsg)}
      content={t.Z85X('SquareTrade is not available and it needs to be removed from the basket.')}
      buttons={[
        <CL.Button key="btnCancel" color="link" onClick={handleClose}>
          OK
        </CL.Button>,
      ]}
    />
  );
};
