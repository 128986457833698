import { ActionPhase } from '../common/storeUtils.js';
import { TypeKeys } from '../actions/index.js';
import { reduceCrudAction } from './reducerUtils.js';
import { updateActionStatePhase } from '../common/index.js';
import type { ActionsHistory } from '../common/store.js';
import type { LeadsState } from '../../common/types/states.js';
import type { SelfServiceActionTypes } from '../actions/index.js';

export function leadReducer(
  state: ActionsHistory | undefined | null,
  action: SelfServiceActionTypes
): (LeadsState & ActionsHistory) | null {
  if (typeof state === 'undefined') {
    return null;
  }

  switch (action.type) {
    case TypeKeys.ADD_LEAD:
      return {
        ...reduceCrudAction(action, state),
        ...{
          leadSent: false,
          leadFailed: false,
          leadInProgress: true,
        },
      };

    case TypeKeys.ADD_LEAD_FULFILLED: {
      return {
        ...state,
        leadSent: true,
        leadFailed: false,
        leadInProgress: false,
        actions: updateActionStatePhase(TypeKeys.ADD_LEAD, state!, ActionPhase.IN_PROGRESS, ActionPhase.SUCCESS),
      };
    }

    case TypeKeys.ADD_LEAD_FAILED: {
      return {
        ...state,
        leadSent: false,
        leadFailed: true,
        leadInProgress: false,
        actions: updateActionStatePhase(TypeKeys.ADD_LEAD, state!, ActionPhase.IN_PROGRESS, ActionPhase.FAILED),
      };
    }

    case TypeKeys.SET_LEAD_SENT_STATUS: {
      return {
        ...state,
        leadSent: action.leadSent,
      };
    }

    case TypeKeys.LOG_OUT: {
      return null;
    }

    default:
      return state;
  }
}
