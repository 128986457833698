import { COST_CENTER_REGEX } from '../../utils/validationUtils.js';
import { InputComponent } from '../components/InputComponent.js';
import {
  costCenterMsg,
  costCenterTooLong,
  fieldCantBeEmptyMsg,
  invalidCostCenterNumberMsg,
  t,
} from '../../i18n/index.js';
import type { OptionalInputComponentProps } from '../components/InputComponent.js';

export const CostCenter = (props: OptionalInputComponentProps) => {
  const {
    name = 'costCenter',
    label = t.QJUW(costCenterMsg),
    placeholder = ' ',
    required = false,
    disabled = false,
    hint,
    readonly,
    tooltip,
  } = props;

  const validate = (value: string) => {
    if (props.required) {
      if (!value) {
        return t.VPVR(fieldCantBeEmptyMsg);
      } else if (required && !COST_CENTER_REGEX.test(value)) {
        return t.JE8S(invalidCostCenterNumberMsg);
      }
    } else if (value && value.length > 40) {
      return t.DHQ9(costCenterTooLong);
    }
    return undefined;
  };

  return (
    <InputComponent
      validate={validate}
      name={name}
      type="text"
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      required={required}
      maxLength={props.required ? 4 : 40}
      hint={hint}
      tooltip={tooltip}
      readonly={readonly}
      {...props}
    />
  );
};
