/**
 * SFDC Anonymous API
 * # Summary Elisa B2B API for an anonymous access to salesforce data.  # Usage This API is used in the `https://yrityksille.elisa.fi/omaelisa` pages, but it is possible to later on expose this documentation publicly and make the endpoint queryable via external clients. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MnpAuthorizationRequest } from './mnpAuthorizationRequest.js';
import { OnboardEmployeeAuthorizationRequest } from './onboardEmployeeAuthorizationRequest.js';
import { VerifyEmailAuthorizationRequest } from './verifyEmailAuthorizationRequest.js';


/**
 * Details of the authorization request
 */
export interface AuthorizationRequest { 
    /**
     * Type of Authorization request
     */
    authorizationRequestType?: AuthorizationRequest.AuthorizationRequestTypeEnum;
    onboardEmployeeAuthorizationRequest?: OnboardEmployeeAuthorizationRequest;
    verifyEmailAuthorizationRequest?: VerifyEmailAuthorizationRequest;
    mnpAuthorizationRequest?: MnpAuthorizationRequest;
}
export namespace AuthorizationRequest {
    export type AuthorizationRequestTypeEnum = 'MNP' | 'VERIFY_EMAIL' | 'ONBOARD_EMPLOYEE' | 'SUBSCRIPTION_REDEEM';
    export const AuthorizationRequestTypeEnum = {
        MNP: 'MNP' as AuthorizationRequestTypeEnum,
        VERIFY_EMAIL: 'VERIFY_EMAIL' as AuthorizationRequestTypeEnum,
        ONBOARD_EMPLOYEE: 'ONBOARD_EMPLOYEE' as AuthorizationRequestTypeEnum,
        SUBSCRIPTION_REDEEM: 'SUBSCRIPTION_REDEEM' as AuthorizationRequestTypeEnum
    };
}


