import { fetchOpenFormList } from '../../../common/fetch.js';
import { getIndex } from '../OpenFormUtils.js';
import { loadingFormListMsg, t } from '../../../common/i18n/index.js';
import { useEffect, useState } from 'react';
import { useOpenFormAsync } from '../OpenFormProvider.js';
import type { ListId } from '../../../common/constants/pathInterfaces.js';
import type { OpenFormListItem } from '../../../generated/api/openFormListItem.js';
import type { OpenFormListResponse } from '../../../generated/api/openFormListResponse.js'; // export type OpenFormList = OpenFormListResponse;

export class OpenFormList {
  constructor(private readonly response: OpenFormListResponse) {}

  get forms(): OpenFormListItem[] {
    return this.response.forms;
  }

  get listId(): string {
    return this.response.listId;
  }
}

export const useOpenFormList = ({ listId }: ListId) => {
  const [data, setData] = useState<OpenFormList | undefined>(undefined);
  const async = useOpenFormAsync();

  useEffect(() => {
    const { ctrl } = new async(({ signal }) => fetchOpenFormList(listId, { signal }))
      .resolved(response => {
        const list = new OpenFormList(response);
        setData(list);
        async.actions.list({ index: list.forms.map(f => [f.id, getIndex(async.storage.getItem(f.id)?.index)]), list });
      })
      .rejected(async.actions.error)
      .cache(listId)
      .execute(t.ZN5B(loadingFormListMsg));

    return () => {
      ctrl.abort();
      async.cache.clear();
      async.actions.list({ index: undefined });
    };
  }, [async, listId]);

  return data;
};
