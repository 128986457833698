import { BreadCrumbs } from '../BreadCrumbs/index.js';
import {
  corporateCustomersMsg,
  orderMsg,
  subscriptionsMsg,
  t,
  turbonappiMorePowerMsg,
  voiceSubscriptionsMsg,
} from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import type { BreadCrumbList } from '../BreadCrumbs/index.js';

export const TurbonappiBreadCrumbs = ({ additionalCrumb }: { additionalCrumb?: true }) => {
  const breadCrumbPaths = additionalCrumb
    ? [
        { name: t.KBLX(corporateCustomersMsg), path: '/' },
        { name: t.XQ5R(subscriptionsMsg), path: paths.SUBSCRIPTIONS_PUBLIC },
        { name: t.HV3R(voiceSubscriptionsMsg), path: paths.ANONYMOUS_MOBILE_VOICE },
        { name: t.Y8GO(turbonappiMorePowerMsg), path: paths.TURBONAPPI },
        { name: t.C001(orderMsg) },
      ]
    : [
        { name: t.KBLX(corporateCustomersMsg), path: '/' },
        { name: t.XQ5R(subscriptionsMsg), path: paths.SUBSCRIPTIONS_PUBLIC },
        { name: t.HV3R(voiceSubscriptionsMsg), path: paths.ANONYMOUS_MOBILE_VOICE },
        { name: t.Y8GO(turbonappiMorePowerMsg) },
      ];
  return <BreadCrumbs breadCrumbPaths={breadCrumbPaths as BreadCrumbList} />;
};
