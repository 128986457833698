import * as CL from '@design-system/component-library';
import { NumberRangeResponse } from '../../generated/api/models.js';
import {
  companyNumberMsg,
  extensionMsg,
  monthlyChargesMsg,
  monthlyFeeOfSubAfterChangesMsg,
  t,
} from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { formatPhoneNumber } from '../../common/utils/phoneNumberUtils.js';
import { formatSum } from '../../common/utils/priceUtils.js';
import type { AssociationRecord } from '@onlinefirst/cloudsense-add-on-dependency-engine';
import type { NumberRangesState } from '../../common/types/states.js';
import type { ServiceLevel, ServiceLevelAddon } from '../AttachRing/ServiceLevelAndAddonsInterfaces.js';
import type { Subscription, SubscriptionPbxConfiguration } from '../../generated/api/models.js';

import './AttachPbxContentConfirmationDialogContent.scss';

export interface AttachPbxContentConfirmationDialogContentProps {
  numberRanges: NumberRangesState;
  pbxConfig: SubscriptionPbxConfiguration;
  voiceSub: Subscription;
  selectedServiceLevelAddons?: ServiceLevelAddon[];
  selectedServiceLevel?: ServiceLevel;
  subscriptionServiceLevelAddons?: ServiceLevelAddon[];
  openingFeeAddons?: AssociationRecord[];
}

const getPbxMonthlyPrice = (corporateNumberRangeId: string, numberRanges: NumberRangesState) => {
  return numberRanges[corporateNumberRangeId].numberCategory === NumberRangeResponse.NumberCategoryEnum.CORPORATE
    ? 400
    : 690;
};

const calculateRingAddonsMonthlyFeesTotal = (serviceLevelAddons?: ServiceLevelAddon[]): number => {
  if (serviceLevelAddons) {
    return serviceLevelAddons
      .filter(addon => addon.selected)
      .map(addon => addon.monthlyPrice)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  } else {
    return 0;
  }
};

const calculateRingAddonsOpeningFeesTotal = (
  selectedServiceLevel?: ServiceLevel,
  openingFeeAddons?: AssociationRecord[]
): number => {
  if (openingFeeAddons && selectedServiceLevel) {
    return (
      openingFeeAddons
        .filter(addon => addon.cspmb__group__c !== 'Elisa Ring avausmaksut')
        .filter(addon => addon.price_item_name__c === selectedServiceLevel.name)
        .map(addon => addon.cspmb__add_on_price_item__r.cspmb__one_off_charge__c || 0)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0) * 100
    );
  } else {
    return 0;
  }
};

const showRingAdditionalText = () => (
  <div>
    <div className={`${dsClass.TEXT_L} ${dsClass.PADDING_TOP_3}`}>
      {t.QPYC(
        'Please note that the following supplementary services are incompatible with the Elisa Ring service and will be automatically closed. The Ring service includes a substitute supplementary service.'
      )}
    </div>
    <div className={`${dsClass.PADDING_TOP_3} ${dsClass.TEXT_XS}`}>
      {t.LG1W(
        'Puheratkaisu Vakio with a regional number, Elisa mobile phone answerer, Elisa WiFi call, Elisa Lankanumero, Call reconnection, Blocking of all outgoing international calls (BOIC), Blocking of all outgoing calls (BAOC), Blocking of outgoing international calls only allowed in Finland (BOIH), Blocking of all incoming calls (BAIC), Blocking of all incoming calls on a foreign network (BIRO), Agreement matters, Vakio usage prices, Puheratkaisu Vakio with a national business number, SMS on corporate number'
      )}
    </div>
  </div>
);

export const AttachPbxContentConfirmationDialogContent = ({
  numberRanges,
  pbxConfig,
  voiceSub,
  selectedServiceLevelAddons,
  selectedServiceLevel,
  openingFeeAddons,
}: AttachPbxContentConfirmationDialogContentProps) => (
  <CL.Grid className={`of-attach-pbx-content-confirmation-dialog ${dsClass.PADDING_VERTICAL_4}`}>
    <CL.GridRow>
      <CL.GridCol
        colWidthXS={2}
        colWidthM={2}
        className={`${dsClass.PADDING_HORIZONTAL_0} ${dsClass.PADDING_BOTTOM_3}`}
      >
        <div className="of-attach-pbx-content-confirmation-dialog__bold">{t.ZX32(companyNumberMsg)}</div>
        <div>{formatPhoneNumber(pbxConfig.corporateNumber, true)}</div>
      </CL.GridCol>
      <CL.GridCol
        colWidthXS={2}
        colWidthM={4}
        className={`${dsClass.PADDING_HORIZONTAL_0} ${dsClass.PADDING_BOTTOM_3}`}
      >
        {pbxConfig.extensionNumber && (
          <>
            <div className="of-attach-pbx-content-confirmation-dialog__bold">{t.NO66(extensionMsg)}</div>
            <div>{pbxConfig.extensionNumber}</div>
          </>
        )}
      </CL.GridCol>
    </CL.GridRow>
    <CL.GridRow>
      <CL.GridCol
        colWidthXS={2}
        colWidthM={3}
        className={`${dsClass.PADDING_HORIZONTAL_0} ${dsClass.PADDING_VERTICAL_2} of-attach-pbx-content-confirmation-dialog__price-label`}
      >
        {t.P6BC(monthlyChargesMsg)}
      </CL.GridCol>
      <CL.GridCol
        colWidthXS={2}
        colWidthM={3}
        className={`${dsClass.PADDING_HORIZONTAL_0} ${dsClass.PADDING_VERTICAL_2} of-attach-pbx-content-confirmation-dialog__price-value`}
      >
        {formatSum(
          getPbxMonthlyPrice(pbxConfig.corporateNumberRangeId!, numberRanges) +
            calculateRingAddonsMonthlyFeesTotal(selectedServiceLevelAddons)
        )}
        /{t.XXVX('month')}
      </CL.GridCol>
    </CL.GridRow>
    <CL.GridRow>
      <CL.GridCol
        colWidthXS={2}
        colWidthM={3}
        className={`${dsClass.PADDING_HORIZONTAL_0} ${dsClass.PADDING_VERTICAL_2} of-attach-pbx-content-confirmation-dialog__price-label-no-border`}
      >
        {t.G3YU('Opening fees total')}
      </CL.GridCol>
      <CL.GridCol
        colWidthXS={2}
        colWidthM={3}
        className={`${dsClass.PADDING_HORIZONTAL_0} ${dsClass.PADDING_VERTICAL_2} of-attach-pbx-content-confirmation-dialog__price-value-no-border`}
      >
        {formatSum(calculateRingAddonsOpeningFeesTotal(selectedServiceLevel, openingFeeAddons))}
      </CL.GridCol>
    </CL.GridRow>
    <CL.GridRow>
      <CL.GridCol
        colWidthXS={4}
        colWidthM={6}
        className={`${dsClass.PADDING_HORIZONTAL_0} ${dsClass.PADDING_TOP_1} ${dsClass.TEXT_XS}`}
      >
        {t.CL9O(
          monthlyFeeOfSubAfterChangesMsg,
          formatSum(
            getPbxMonthlyPrice(pbxConfig.corporateNumberRangeId!, numberRanges) +
              calculateRingAddonsMonthlyFeesTotal(selectedServiceLevelAddons) +
              voiceSub.details!.monthlyRecurringCharge!
          )!
        )}
      </CL.GridCol>
    </CL.GridRow>
    <CL.GridRow>
      <CL.GridCol colWidthXS={4} colWidthM={6} className={`${dsClass.PADDING_HORIZONTAL_0} ${dsClass.TEXT_XS}`}>
        {t.ZGSV('Opening fees will be added to the next invoice.')}
      </CL.GridCol>
    </CL.GridRow>
    <CL.GridRow>
      <CL.GridCol colWidthXS={4} className={dsClass.PADDING_HORIZONTAL_0}>
        {selectedServiceLevelAddons && showRingAdditionalText()}
      </CL.GridCol>
    </CL.GridRow>
  </CL.Grid>
);
