import * as CL from '@design-system/component-library';
import { dsClass } from '../../common/constants/dsClasses.js';
import { t } from '../../common/i18n/index.js';
import type { ChangeEvent, ReactNode } from 'react';

interface ShowAllAccountsToggleProps {
  handleSearchAllAccountsChange: (event: ChangeEvent<HTMLInputElement>) => void;
  isSearchAllAccounts?: boolean;
  children?: ReactNode;
  message?: string;
  tooltipText?: string;
  className?: string;
}
export const ShowAllAccountsToggle = ({
  handleSearchAllAccountsChange,
  isSearchAllAccounts = false,
  children,
  message,
  tooltipText,
  className,
}: ShowAllAccountsToggleProps) => (
  <div className={`of-all-accounts-toggle ${dsClass.DISPLAY_FLEX} ${className || ''}`}>
    <div className={`${dsClass.DISPLAY_FLEX} ${dsClass.ALIGN_ITEMS_CENTER}`}>
      <CL.Toggle
        className={dsClass.MARGIN_BOTTOM_0}
        value={isSearchAllAccounts}
        onToggle={handleSearchAllAccountsChange}
        size="s"
      />
      <span className={`${dsClass.MARGIN_LEFT_2} ${dsClass.TEXT_S}`}>
        {message ? message : t.EPWH('Search all accessible accounts')}
      </span>
      <div className={`${dsClass.MARGIN_LEFT_2} ${dsClass.PADDING_TOP_1}`}>
        <CL.Tooltip
          triggerElement={<CL.Icon icon="information" size="s" type="light" color="black" />}
          placement="right"
          i18n_tooltip_contentText={
            tooltipText
              ? tooltipText
              : t.B0GQ('You can see search result from all the companies you have admin rights.')
          }
        />
      </div>
    </div>
    {children}
  </div>
);
