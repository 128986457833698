import * as CL from '@design-system/component-library';
import { CompanyInfo, CustomFormContactDetails, CustomFormErrorAndButton } from '../CustomFormFields.js';
import { CompanySearch } from '../../../common/react-hook-form/fields/index.js';
import { CustomFormDomainSearch } from '../partials/CustomFormDomainSearch.js';
import { PriceSummary } from '../partials/PriceSummary.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import type { CustomFormProps } from '../CustomForm.js';
import type { Price } from '../../../generated/api/models.js';

const price: Price = {
  monthlyRecurringCharge: 500,
};

export const DomainNameForm = (props: CustomFormProps) => {
  const { leadFailed, leadInProgress, title, enableCompanyFields = true } = props;
  return (
    <CL.Grid className="of-custom-form">
      <CL.GridRow>
        <CL.GridCol className="title" colWidthS={6} colWidthM={6} colWidthL={12} colWidthXL={12}>
          <h2 className={dsClass.H2}>{title}</h2>
        </CL.GridCol>
        <CL.GridCol colWidthS={6} colWidthM={6} colWidthL={6} colWidthXL={6}>
          <CustomFormDomainSearch price={price} />
          <PriceSummary {...props} />
        </CL.GridCol>
        <CL.GridCol colWidthS={6} colWidthM={6} colWidthL={6} colWidthXL={6}>
          {enableCompanyFields && (
            <>
              <CompanyInfo />
              <CompanySearch />
            </>
          )}
          <CustomFormContactDetails />
          <CustomFormErrorAndButton leadInProgress={leadInProgress} leadFailed={leadFailed} />
        </CL.GridCol>
      </CL.GridRow>
    </CL.Grid>
  );
};
