import { ActionPhase } from '../common/storeUtils.js';
import { TypeKeys } from '../actions/index.js';
import { reduceCrudAction } from './reducerUtils.js';
import { updateActionStatePhase } from '../common/index.js';
import type { ActionsHistory } from '../common/store.js';
import type { CompanyInfoState } from '../../common/types/states.js';
import type { SelfServiceActionTypes } from '../actions/index.js';

export function companyInfoReducer(
  state: (CompanyInfoState & ActionsHistory) | undefined | null,
  action: SelfServiceActionTypes
): (CompanyInfoState & ActionsHistory) | null {
  if (typeof state === 'undefined') {
    return null;
  }
  switch (action.type) {
    case TypeKeys.LOAD_COMPANY_INFO: {
      // TODO removing of businessId condition appears to break deviceCheckout and eppDeviceCheckout. This would need to be checked.
      const localState = state?.businessId ? state : reduceCrudAction(action, state || {});
      return {
        ...localState,
        companyInfoLoading: true,
      };
    }
    case TypeKeys.LOAD_COMPANY_INFO_FULFILLED: {
      const uiOptions = action.companyInfo.uiOptions as { toggles?: string[] };
      const toggles = uiOptions?.toggles || [];
      const disallowBillableSimChanges = toggles?.includes('DisallowBillableSimChanges') || false;

      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.LOAD_COMPANY_INFO,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        companyInfoLoading: false,
        address: action.companyInfo.address,
        businessId: action.companyInfo.businessId,
        companyName: action.companyInfo.companyName,
        customerType: action.companyInfo.customerType,
        eppSolution: action.companyInfo.eppSolution,
        blockInvoiceDueSmsReminder: action.companyInfo.blockInvoiceDueSmsReminder,
        mobilePbxSolutions: action.companyInfo.mobilePbxSolutions,
        pbxSolutions: action.companyInfo.pbxSolutions,
        phoneNumber: action.companyInfo.phoneNumber,
        salesChannel: action.companyInfo.salesChannel,
        enrollmentPrograms: action.companyInfo.enrollmentPrograms,
        disallowBillableSimChanges,
        accountManager: action.companyInfo.accountManager,
        toggles,
      };
    }

    case TypeKeys.LOAD_COMPANY_INFO_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.LOAD_COMPANY_INFO,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        companyInfoLoading: false,
      };
    }

    case TypeKeys.GET_MYSELF_SECONDARY_ACCOUNT:
    case TypeKeys.GET_EPP_SOLUTION_JIT_FAILURE_WORKAROUND:
      return {
        ...state,
        ...reduceCrudAction(action, state),
      };

    case TypeKeys.GET_CUSTOMER_LEVEL_DISCOUNTED_PRICES: {
      return {
        ...state,
        ...reduceCrudAction(action, state),
        discountedPricesLoading: true,
      };
    }

    case TypeKeys.GET_EPP_SOLUTION_JIT_FAILURE_WORKAROUND_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.GET_EPP_SOLUTION_JIT_FAILURE_WORKAROUND,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
      };
    }

    case TypeKeys.GET_EPP_SOLUTION_JIT_FAILURE_WORKAROUND_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.GET_EPP_SOLUTION_JIT_FAILURE_WORKAROUND,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
      };
    }

    case TypeKeys.GET_CUSTOMER_LEVEL_DISCOUNTED_PRICES_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.GET_CUSTOMER_LEVEL_DISCOUNTED_PRICES,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        discountedPrices: action.response.discountedPrices,
        discountedBasketLevelPrices: action.response.discountedBasketLevelPrices,
        discountedPricesLoading: false,
      };
    }

    case TypeKeys.GET_CUSTOMER_LEVEL_DISCOUNTED_PRICES_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.GET_CUSTOMER_LEVEL_DISCOUNTED_PRICES,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        discountedPricesLoading: false,
      };
    }

    case TypeKeys.UPDATE_ACCOUNT_SETTINGS: {
      return {
        ...reduceCrudAction(action, state),
        errors: undefined,
      };
    }

    case TypeKeys.UPDATE_ACCOUNT_SETTINGS_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.UPDATE_ACCOUNT_SETTINGS,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        errors: undefined,
        blockInvoiceDueSmsReminder: action.updateAccountRequest.blockInvoiceDueSmsReminder,
      };
    }

    case TypeKeys.UPDATE_ACCOUNT_SETTINGS_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.UPDATE_ACCOUNT_SETTINGS,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
      };
    }

    case TypeKeys.GET_MYSELF_SECONDARY_ACCOUNT_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.GET_MYSELF_SECONDARY_ACCOUNT,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        secondaryAccounts: action.response,
      };
    }

    case TypeKeys.GET_MYSELF_SECONDARY_ACCOUNT_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.GET_MYSELF_SECONDARY_ACCOUNT,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
      };
    }

    case TypeKeys.SWITCH_ACCOUNT_FULFILLED:
    case TypeKeys.LOG_OUT: {
      return null;
    }

    default:
      return state;
  }
}
