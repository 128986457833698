import { Navigate } from 'react-router-dom';
import { NewSupportCasePublic } from '../../../components/NewSupportCasePublic/NewSupportCasePublic.js';
import { SystemError } from '../../../components/SystemError/SystemError.js';
import { paths } from '../../../common/constants/pathVariables.js';
import { useAuth } from '../AuthProvider.js';

export const NewSupportCasePublicPath = () => {
  const { anonymousUser } = useAuth();

  if (anonymousUser?.supportCaseFailed) {
    return <SystemError errors={anonymousUser.errors} homePath="/" />;
  } else if (anonymousUser?.supportCaseSent) {
    return <Navigate to={paths.CONTACT_THANK_YOU} />;
  }
  return <NewSupportCasePublic />;
};
