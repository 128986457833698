import { Navigate } from 'react-router-dom';
import { NewSupportCaseAuthenticated } from '../../../components/NewSupportCaseAuthenticated/NewSupportCaseAuthenticated.js';
import { SystemError } from '../../../components/SystemError/SystemError.js';
import { loadCompanyInfo, setCaseSentStatus } from '../../../selfservice/actions/index.js';
import { paths } from '../../../common/constants/pathVariables.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import type { State } from '../../../selfservice/common/store.js';

interface SupportCaseSentRedirectProps {
  supportCaseSent?: boolean;
}

const SupportCaseSentRedirect = ({ supportCaseSent }: SupportCaseSentRedirectProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (supportCaseSent) {
      dispatch(setCaseSentStatus(false));
    }
  });

  return <Navigate to={paths.SUPPORT_CASES} />;
};

export const NewSupportCaseAuthenticatedPath = () => {
  const dispatch = useDispatch();
  const supportCaseSent = useSelector((state: State) => state.selfservice?.supportCases?.supportCaseSent);
  const supportCaseFailed = useSelector((state: State) => state.selfservice?.supportCases?.supportCaseFailed);
  const errors = useSelector((state: State) => state.selfservice?.supportCases?.errors);

  useEffect(() => {
    dispatch(loadCompanyInfo());
  }, [dispatch]);

  if (supportCaseFailed) {
    return <SystemError errors={errors} homePath={paths.SELF_SERVICE_HOME} />;
  } else if (supportCaseSent) {
    return <SupportCaseSentRedirect supportCaseSent={supportCaseSent} />;
  } else {
    return <NewSupportCaseAuthenticated />;
  }
};
