import { CommonErrorType } from '../../../../../common/enums.js';
import { DnsHistoryList } from '../../../../../components/DnsManagement/DnsRecordManagement/DnsHistoryList/DnsHistoryList.js';
import { DnsRecordManagement } from '../../../../../components/DnsManagement/DnsRecordManagement/DnsRecordManagement.js';
import { SystemError } from '../../../../../components/SystemError/SystemError.js';
import { useLoaderData, useParams } from 'react-router-dom';
import type { DnsRecordsHistoryResponse } from '../../../../../generated/api/dnsRecordsHistoryResponse.js';
import type { SubscriptionId } from '../../../../../common/constants/pathInterfaces.js';

export const DnsRecordsHistoryPath = () => {
  const { subscriptionId } = useParams<SubscriptionId>();
  const historyData = useLoaderData() as DnsRecordsHistoryResponse;

  if (!subscriptionId) {
    return <SystemError errors={[{ type: CommonErrorType.NOT_FOUND, message: 'subscriptionId missing' }]} />;
  }

  return (
    <DnsRecordManagement subscriptionId={subscriptionId} domainName={historyData.domainName}>
      <DnsHistoryList subscriptionId={subscriptionId} historyData={historyData} />
    </DnsRecordManagement>
  );
};
