/**
 * Online UI API
 * # Summary Elisa B2B Online UI API  # Usage This API is used in the `https://yrityksille.elisa.fi/` pages.  # Identifiers 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SearchResultType = 'BILLING_ACCOUNT' | 'SUPPORT_CASE' | 'ORDER' | 'REDEEM_REQUEST' | 'DEVICE' | 'DOMAIN' | 'INTERNET_SUBSCRIPTION' | 'VOICE_SUBSCRIPTION' | 'INVOICE' | 'CONTACT';

export const SearchResultType = {
    BILLING_ACCOUNT: 'BILLING_ACCOUNT' as SearchResultType,
    SUPPORT_CASE: 'SUPPORT_CASE' as SearchResultType,
    ORDER: 'ORDER' as SearchResultType,
    REDEEM_REQUEST: 'REDEEM_REQUEST' as SearchResultType,
    DEVICE: 'DEVICE' as SearchResultType,
    DOMAIN: 'DOMAIN' as SearchResultType,
    INTERNET_SUBSCRIPTION: 'INTERNET_SUBSCRIPTION' as SearchResultType,
    VOICE_SUBSCRIPTION: 'VOICE_SUBSCRIPTION' as SearchResultType,
    INVOICE: 'INVOICE' as SearchResultType,
    CONTACT: 'CONTACT' as SearchResultType
};

