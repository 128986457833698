import { HeroHeading, HeroHeadingType } from '../../HeroHeading/index.js';
import { LocalNavi } from '../../LocalNavi/index.js';
import { NotificationContainer } from '../../NotificationContainer/NotificationContainer.js';
import { Spinner } from '../../../public/site/siteUtils.js';
import { generatePath } from 'react-router-dom';
import { omaElisaForCompaniesMsg, t } from '../../../common/i18n/index.js';
import { paths } from '../../../common/constants/pathVariables.js';
import type { ReactElement } from 'react';

export const DnsRecordManagement = ({
  children,
  domainName,
  subscriptionId,
  historyId,
  spinner,
}: {
  children: ReactElement;
  domainName: string;
  subscriptionId: string;
  historyId?: string;
  spinner?: boolean;
}) => (
  <>
    {!historyId && (
      <>
        <HeroHeading
          breadCrumbPaths={[
            { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
            { name: 'Elisa DNS', path: paths.PS_ELISA_DNS },
            { name: domainName || '' },
          ]}
          heroHeadingType={HeroHeadingType.BLANK}
          title="Elisa DNS"
          subtitle={domainName}
        />
        <NotificationContainer />
        <LocalNavi
          categories={[
            {
              children: t.OF6S('Records'),
              to: generatePath(paths.PS_ELISA_DNS_DOMAIN, { subscriptionId }),
            },
            {
              children: t.CUE7('Edit history'),
              to: generatePath(paths.PS_ELISA_DNS_DOMAIN_HISTORY, { subscriptionId }),
            },
          ]}
          disableSelected={true}
          wide={true}
        />
      </>
    )}
    {spinner ? <Spinner /> : children}
  </>
);
