import { MobileIdDetailsContent } from '../../../../../components/MobileId/MobileIdDetails.js';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import { getMobileIdContracts } from '../../../../../selfservice/actions/mobileIdContractActions.js';
import { stripActions } from '../../../../../common/utils/stateUtils.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import type { ContractDisplayId } from '../../../../../common/constants/pathInterfaces.js';
import type { State } from '../../../../../selfservice/common/store.js';

export const MobileIdDetailsPath = () => {
  const dispatch = useDispatch();
  const { contractDisplayId } = useParams<ContractDisplayId>();
  const subs = useSelector((s: State) => stripActions(s.selfservice?.subscriptions?.contract) || undefined, deepEqual);

  useEffect(() => {
    dispatch(getMobileIdContracts(contractDisplayId));
  }, [dispatch, contractDisplayId]);

  return (
    <MobileIdDetailsContent
      mobileIdContracts={subs?.mobileIdContracts}
      contractNumber={contractDisplayId}
      loadingContracts={subs?.loadingContracts}
    />
  );
};
