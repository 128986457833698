import { ButtonGroup, SubscriptionDetailsButtonType } from '../SubscriptionDetails/subscriptionDetailsButtons.js';
import { DetailsWrapper } from '../DetailsWrapper';
import { Loading } from '../Loading';
import {
  SubscriptionDetailsAccordions,
  SubscriptionDetailsBillingAccount,
  getUpdateButtonType,
} from '../SubscriptionDetails/SubscriptionDetails.js';
import { SubscriptionDetailsMobile } from '../SubscriptionDetails/SubscriptionDetailsMobile.js';
import { SubscriptionPbxDetails } from '../../generated/api/subscriptionPbxDetails.js';
import { SubscriptionStatusType } from '../../generated/api/subscriptionStatusType.js';
import { SubscriptionType } from '../../generated/api/subscriptionType.js';
import { formatPhoneNumber } from '../../common/utils/phoneNumberUtils.js';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { getContactDisplayValue } from '../../common/utils/billingAccountUtils.js';
import { getSubscriptionStatus } from '../../common/utils/subscriptionUtils.js';
import { paths } from '../../common/constants/pathVariables';
import {
  resumeSubscription,
  showDialog,
  showTerminateSubscriptionForm,
  terminateSubscriptionAction,
} from '../../selfservice/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from '../../common/hooks/useSearchParams.js';
import type { AssociationRecord } from '@onlinefirst/cloudsense-add-on-dependency-engine';
import type { DialogParams } from '../../common/types/dialog.js';
import type { State } from '../../selfservice/common/store';
import type { Subscription } from '../../generated/api/subscription.js';
import type { SubscriptionAction } from '../../generated/api/subscriptionAction';
import type { SubscriptionDetailsProps } from '../SubscriptionDetails/SubscriptionDetails.js';

const getVoiceButtonTypes = (
  specialNumber?: SubscriptionPbxDetails.PbxTypeEnum,
  subscription?: Subscription,
  pendingSubscriptionActions?: SubscriptionAction[]
) => {
  const isSuspended = subscription?.subscriptionStatus === SubscriptionStatusType.SUSPENDED;
  const buttons = [
    getUpdateButtonType(
      subscription!,
      getSubscriptionStatus(subscription, pendingSubscriptionActions).isChangeOfferAllowed
    ),
    isSuspended ? SubscriptionDetailsButtonType.RESUME_SUBSCRIPTION : SubscriptionDetailsButtonType.TEMPORARILY_CLOSE,
    SubscriptionDetailsButtonType.SUPPORT_REQUEST,
    SubscriptionDetailsButtonType.TERMINATE,
  ];

  if (subscription?.subscriptionType === SubscriptionType.MOBILE) {
    buttons.push(SubscriptionDetailsButtonType.MOBILE_FIXER);
  }

  if (specialNumber !== undefined) {
    const noResume = [
      SubscriptionPbxDetails.PbxTypeEnum.RYHMAKUTSU,
      SubscriptionPbxDetails.PbxTypeEnum.VASTAUSSARJA,
      SubscriptionPbxDetails.PbxTypeEnum.TAVOITETTAVUUSKETJU,
    ];
    return noResume.includes(specialNumber) ? buttons.slice(2) : buttons.slice(1);
  }
  return buttons;
};
export const SubscriptionVoice = ({
  addOnRules,
  addOnRulesMobilePbx,
  billingAccounts,
  breadCrumbs,
  category,
  companyInfo,
  contacts,
  subscription,
  pendingSubscriptionActions,
  onlineModels,
}: SubscriptionDetailsProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isTerminateSubscriptionForm, showSuspendSubscriptionOptions, terminationInProgress } = useSelector(
    (state: State) => ({
      isTerminateSubscriptionForm:
        state?.selfservice?.pendingSubscriptionActions?.terminateSubscriptionInitiated || false,
      showSuspendSubscriptionOptions:
        state?.selfservice?.pendingSubscriptionActions?.suspendSubscriptionInitiated || false,
      terminationInProgress: state?.selfservice?.pendingSubscriptionActions?.terminationInProgress || false,
    })
  );
  const { search } = useLocation();
  const { mdmId } = useSearchParams<{ mdmId: string }>();
  const selectedContact = contacts?.find(c => c.contactId === subscription?.subscriptionContactId);
  const selectedContactName = getContactDisplayValue(selectedContact) || undefined;
  const companyName = companyInfo?.companyName;
  const onShowDialog = (params: DialogParams) => dispatch(showDialog(params));

  const heroPicto = 'sim';
  const heading = selectedContactName ?? subscription?.subscriptionPurposeOfUse ?? '…';
  let headingBottom: string | JSX.Element = '';
  const subscriptionDisplayId = subscription ? subscription.subscriptionDisplayId : '…';
  const subscriptionStatus = getSubscriptionStatus(subscription, pendingSubscriptionActions);
  const onShowSubscriptionTerminationForm = () => dispatch(showTerminateSubscriptionForm());
  const onResumeSubscription = (subId: string) => dispatch(resumeSubscription(subId));

  if (!subscription?.details) {
    return (
      <DetailsWrapper
        classes={['of-subscription-details']}
        detailsTop={breadCrumbs}
        heading={heading}
        headingBottom={companyName}
        headingTop={subscription?.subscriptionName}
        heroPicto={heroPicto}
        id={`subscription-details-${subscriptionDisplayId}`}
      >
        <Loading />
      </DetailsWrapper>
    );
  }

  const onClickSubscriptionChangeOfferSelection = (subDisplayId: string, subscriptionType: SubscriptionType) => {
    const redirectPath = (() => {
      switch (subscriptionType) {
        case SubscriptionType.MOBILE_M2M:
          return paths.PS_BROADBAND_SUBSCRIPTION_UPDATE_M2M + search;
        case SubscriptionType.MOBILE_BROADBAND:
          return paths.PS_BROADBAND_SUBSCRIPTION_UPDATE + search;
        default:
          return paths.PS_MOBILE_SUBSCRIPTION_UPDATE + search;
      }
    })();

    navigate(generatePath(redirectPath, { subscriptionId: subDisplayId }));
  };

  const specialNumber = subscription.subscriptionType === SubscriptionType.SPECIAL_NUMBER;
  const pbxType = subscription?.details?.specialNumber?.pbxConfigurationDetails?.pbxType;
  const subscriptionDetails = (
    <div className="of-subscription-details__content of-subscription-details__content--mobile">
      <SubscriptionDetailsMobile
        addOnRulesAssociations={addOnRules?.associations[0] as AssociationRecord[]}
        onShowDialog={onShowDialog}
        subscription={subscription}
        subscriptionStatus={subscriptionStatus}
        details={subscription.details}
        showSuspendSubscriptionOptions={showSuspendSubscriptionOptions}
        showTerminateSubscriptionForm={isTerminateSubscriptionForm}
        onTerminateSubscription={(subId, effectiveDate, donateNumber, terminateType) =>
          dispatch(terminateSubscriptionAction(subId, effectiveDate, donateNumber, terminateType))
        }
        onResumeSubscription={(subId: string) => dispatch(resumeSubscription(subId))}
        billingAccountSelection={
          <SubscriptionDetailsBillingAccount
            billingAccounts={billingAccounts}
            subscription={subscription}
            pendingSubscriptionActions={pendingSubscriptionActions}
          />
        }
        mobileDetails={subscription.details.mobile}
        terminationInProgress={terminationInProgress}
      />
      {!isTerminateSubscriptionForm && !showSuspendSubscriptionOptions && (
        <ButtonGroup
          buttonTypes={getVoiceButtonTypes(pbxType, subscription, pendingSubscriptionActions)}
          companyInfo={companyInfo}
          subscription={subscription}
          subscriptionStatus={subscriptionStatus}
          onShowDialog={onShowDialog}
          onShowSubscriptionTerminationForm={onShowSubscriptionTerminationForm}
          onResumeSubscription={onResumeSubscription}
          onClickSubscriptionChangeOfferSelection={onClickSubscriptionChangeOfferSelection}
          mdmId={mdmId}
        />
      )}
      <SubscriptionDetailsAccordions
        addOnRules={addOnRules}
        addOnRulesMobilePbx={addOnRulesMobilePbx}
        category={category}
        contacts={contacts}
        companyInfo={companyInfo}
        pendingSubscriptionActions={pendingSubscriptionActions}
        subscription={subscription}
        onlineModels={onlineModels}
      />
    </div>
  );
  headingBottom = subscription.subscriptionUserFriendlyId
    ? `${subscription.subscriptionUserFriendlyId} | ${companyName}`
    : companyName;
  if (
    subscription.details.mobilePbxEndUserService?.pbxConfigurationDetails.pbxType ===
      SubscriptionPbxDetails.PbxTypeEnum.RING &&
    subscription.details.mobilePbxEndUserService.corporateNumber
  ) {
    headingBottom = `${formatPhoneNumber(subscription.details.mobilePbxEndUserService.corporateNumber, true)} | ${companyName}`;
  }
  if (!specialNumber && subscription.details!.mobile?.pbxConfiguration && subscription.subscriptionUserFriendlyId) {
    const pbxTypeFromDetails = subscription.details!.mobile!.pbxConfiguration.pbxConfigurationDetails.pbxType;
    if (pbxTypeFromDetails && pbxTypeFromDetails.length > 0) {
      headingBottom = (
        <>
          {formatPhoneNumber(subscription.subscriptionUserFriendlyId, true)}
          <span> | </span>
          {pbxTypeFromDetails === SubscriptionPbxDetails.PbxTypeEnum.VAKIO ? 'Vakio' : ''}
          {pbxTypeFromDetails === SubscriptionPbxDetails.PbxTypeEnum.RING ? 'Ring' : ''}{' '}
          {formatPhoneNumber(subscription.details.mobile!.pbxConfiguration.corporateNumber, true)}
          <span> | </span>
          {companyName}
        </>
      );
    }
  }

  return (
    <DetailsWrapper
      classes={['of-subscription-details']}
      detailsTop={breadCrumbs}
      heading={heading}
      headingBottom={headingBottom}
      headingTop={subscription.subscriptionName}
      heroPicto={heroPicto}
      id={`subscription-details-${subscriptionDisplayId}`}
    >
      {subscriptionDetails}
    </DetailsWrapper>
  );
};
