import { AuthenticatedUserRole } from '../../generated/api/models.js';
import {
  TypeKeys,
  addAnonymousLeadFailed,
  addAnonymousLeadFulfilled,
  addAnonymousSupportCaseFailed,
  addAnonymousSupportCaseFulfilled,
  addMissingMobileNumberAndLogInFailed,
  authValidateFailed,
  authValidateFulfilled,
  clearCartItems,
  loadCompanyInfo,
  logInFailed,
  logInFulfilled,
  mfaRequired,
  notifyShoppingCartChange,
  processElisaIdLoginFailed,
  processElisaIdLoginFulfilled,
  resetPasswordFailed,
  resetPasswordFulfilled,
  resolveMissingPrimaryAccountFailed,
  resolveMissingPrimaryAccountFulfilled,
  selectPrimaryAccountAndLogInFailed,
  sendOtpToUserFailed,
  sendOtpToUserFulfilled,
  setActiveAccount,
  validateOtpFailed,
  validateOtpFulfilled,
  verifyEmailFailed,
  verifyEmailFulfilled,
} from '../actions/index.js';
import { actionToActionState, getOnboardingId, isElisaIdV2ProcessNeeded } from './epicUtils.js';
import { callUiApi, prepareUiApiRequest } from '../common/uiApiUtils.js';
import { combineEpics, ofType } from 'redux-observable';
import { concatMap, mergeMap, tap } from 'rxjs/operators';
import {
  createAnonymousLeadPublicMethod,
  createAnonymousSupportCasePublicMethod,
  logInAndAddMissingMobileNumberPublicMethod,
  logInAndSavePrimaryAccountPublicMethod,
  logInPublicMethod,
  processElisaIdLoginPublicMethod,
  processElisaIdV2LoginPublicMethod,
  resetPasswordPublicMethod,
  resolveMissingPrimaryAccountPublicMethod,
  sendOtpToUserPublicMethod,
  validateOtpPublicMethod,
  validatePublicMethod,
  verifyEmailPublicMethod,
} from '../../generated/api/uiApiMethods.js';
import { getAuthRedirectUrl, removeActiveAccountMasterId } from '../common/localStorageUtils.js';
import { isFeatureEnabled } from '../../common/utils/featureFlagUtils';
import { isInBrowser } from '../../common/utils/ssrUtils.js';
import { of } from 'rxjs';
import { push } from 'redux-first-history';
import { pushSupportCaseGAEventToDataLayer } from '../../common/analytics.js';
import type { Action } from 'redux';
import type { ActionAndState, EpicDependencies } from './epicUtils.js';
import type { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import type {
  AddAnonymousLeadAction,
  AddAnonymousSupportCaseAction,
  AddMissingMobileNumberAndLogInAction,
  AuthValidateAction,
  LogInAction,
  LogInFulfilledAction,
  ProcessElisaIdLoginAction,
  ResetPasswordAction,
  ResolveMissingPrimaryAccountAction,
  SelectPrimaryAccountAndLogInAction,
  SelfServiceActionTypes,
  SendOtpToUserAction,
  ValidateOtpAction,
  VerifyEmailAction,
} from '../actions/index.js';
import type { AjaxResponse } from 'rxjs/ajax';
import type { LoginResponse } from '../../generated/api/models.js';
import type { State } from '../common/store.js';

// Do the redirect only from these paths, OEC users should be able to see public pages.
const redirectToOecFromPaths = ['omaelisa', 'kassa', 'työntekijä'];

export const handleRedirectResponse = (ajaxResponse: AjaxResponse, forceRedirect = false) => {
  const redirectUrl = ajaxResponse.xhr.getResponseHeader('Location') as string;
  if (isInBrowser() && window.location?.pathname) {
    // note, path always contains slash so this works
    const firstPath = window.location.pathname.split('/')[1];
    if (forceRedirect || redirectToOecFromPaths.some(redirectPath => firstPath.toLowerCase() === redirectPath)) {
      window.location.href = redirectUrl;
    }
  }
};

// Process Elisa ID Login
export const processElisaIdLoginEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.PROCESS_ELISAID_LOGIN)), (action: ProcessElisaIdLoginAction) =>
    actionToActionState(action, state$, 'anonymous')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const { payload: accessToken } = actionAndState.action as ProcessElisaIdLoginAction;
      if (!actionAndState.state && !accessToken) {
        throw new Error('invalid action state for elisa ID Login, userName is missing');
      }
      removeActiveAccountMasterId();
      if (isElisaIdV2ProcessNeeded(state$)) {
        const redirectUrl = getAuthRedirectUrl();
        if (!redirectUrl) {
          throw new Error('Redirect uri must be available in session storage for making process call');
        }
        return callUiApi({
          epicDependencies,
          state$,
          method: processElisaIdV2LoginPublicMethod({
            authCode: accessToken,
            redirectUrl: redirectUrl,
            onboardingId: getOnboardingId() || undefined,
          }),
          successAction: (response: LoginResponse) => processElisaIdLoginFulfilled(response.result),
          failureAction: processElisaIdLoginFailed,
        });
      } else {
        return callUiApi({
          epicDependencies,
          state$,
          method: processElisaIdLoginPublicMethod({ accessToken, onboardingId: getOnboardingId() || undefined }),
          successAction: (ajaxResponse: AjaxResponse) => processElisaIdLoginFulfilled(ajaxResponse.response.result),
          failureAction: processElisaIdLoginFailed,
        });
      }
    })
  );

// Process SSO Login
export const logInEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.LOG_IN)), (action: LogInAction) =>
    actionToActionState(action, state$, 'anonymous')
  )
    .pipe(
      concatMap((actionAndState: ActionAndState) => {
        const action = actionAndState.action as LogInAction;
        // for primaryAccountSelection & missingMobile & JIT...
        const failureParams = { password: action.password, userName: action.userName };
        const payload = {
          password: action.password,
          userName: action.userName,
        };

        removeActiveAccountMasterId();
        return callUiApi({
          epicDependencies,
          failureAction: logInFailed,
          failureParams,
          method: logInPublicMethod(),
          payload,
          state$,
          successAction: (ajaxResponse: AjaxResponse) => {
            if (ajaxResponse.status === 204) {
              handleRedirectResponse(ajaxResponse, true);
              return logInFailed('Redirected', 204);
            } else if (ajaxResponse.status === 202) {
              return mfaRequired(ajaxResponse.response);
            }
            return logInFulfilled(ajaxResponse.response.result);
          },
        });
      })
    )
    .pipe(
      concatMap(action => {
        return of(action);
      })
    );

// Process SSO select primary account and Login
export const selectPrimaryAccountAndLoginEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(
    action$.pipe(ofType(TypeKeys.SELECT_PRIMARY_ACCOUNT_AND_LOG_IN)),
    (action: SelectPrimaryAccountAndLogInAction) => actionToActionState(action, state$, 'anonymous')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const action = actionAndState.action as SelectPrimaryAccountAndLogInAction;
      const payload = {
        password: action.password,
        primaryAccountMasterId: action.primaryAccountMasterId,
        username: action.userName,
      };
      const successAction = (ajaxResponse: AjaxResponse) => {
        if (ajaxResponse.status === 202) {
          return mfaRequired(ajaxResponse.response);
        }
        return logInFulfilled(ajaxResponse.response.result);
      };
      const failureParams = { userName: action.userName, password: action.password }; // for JIT...

      removeActiveAccountMasterId();
      return callUiApi({
        epicDependencies,
        failureAction: selectPrimaryAccountAndLogInFailed,
        failureParams,
        method: logInAndSavePrimaryAccountPublicMethod(),
        payload,
        state$,
        successAction,
      });
    })
  );

// Adds missing primary account to SSO
export const resolveMissingPrimaryAccountEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(
    action$.pipe(ofType(TypeKeys.RESOLVE_MISSING_PRIMARY_ACCOUNT)),
    (action: ResolveMissingPrimaryAccountAction) => actionToActionState(action, state$, 'anonymous')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const action = actionAndState.action as ResolveMissingPrimaryAccountAction;
      const payload = {
        primaryAccountMasterId: action.primaryAccountMasterId,
      };
      return callUiApi({
        epicDependencies,
        failureAction: resolveMissingPrimaryAccountFailed,
        method: resolveMissingPrimaryAccountPublicMethod(),
        payload,
        state$,
        successAction: resolveMissingPrimaryAccountFulfilled,
      }).pipe(
        concatMap(prevAction => {
          if (prevAction.type === TypeKeys.RESOLVE_MISSING_PRIMARY_ACCOUNT_FULFILLED) {
            // Just better to reload to force another /validate & angular mount selfservice again.
            // Can be switched to return [prevAction, authValidate()] when angular is gone.
            window.location.reload();
          }
          return of(prevAction);
        })
      );
    })
  );

// Add missing mobile number to SSO and Login
export const addMissingMobileNumberAndLoginEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(
    action$.pipe(ofType(TypeKeys.ADD_MISSING_MOBILE_NUMBER_AND_LOG_IN)),
    (action: AddMissingMobileNumberAndLogInAction) => actionToActionState(action, state$, 'anonymous')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const action = actionAndState.action as AddMissingMobileNumberAndLogInAction;
      const payload = {
        password: action.password,
        mobileNumber: action.mobileNumber,
        username: action.userName,
      };
      const successAction = (ajaxResponse: AjaxResponse) => {
        if (ajaxResponse.status === 202) {
          return mfaRequired(ajaxResponse.response);
        }
        return logInFulfilled(ajaxResponse.response.result);
      };
      const failureParams = { userName: action.userName, password: action.password }; // for JIT...

      removeActiveAccountMasterId();
      return callUiApi({
        epicDependencies,
        failureAction: addMissingMobileNumberAndLogInFailed,
        failureParams,
        method: logInAndAddMissingMobileNumberPublicMethod(),
        payload,
        state$,
        successAction,
      });
    })
  );

export const logInFulfilledEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>
) =>
  action$.pipe(
    ofType(TypeKeys.LOG_IN_FULFILLED),
    mergeMap((action: LogInFulfilledAction) => {
      const actionArrays: Action[] = [];
      if (action.authenticationResult.account?.accountMasterId) {
        actionArrays.push(setActiveAccount(action.authenticationResult.account?.accountMasterId));
      }
      if (state$.value.user?.authenticated?.userRole === AuthenticatedUserRole.EMPLOYEE) {
        actionArrays.push(clearCartItems(), push('/'));
      } else {
        actionArrays.push(loadCompanyInfo(), notifyShoppingCartChange());
      }
      return actionArrays;
    })
  );

// Validate the cookie values in JWT and ELISA_SSO_COOKIE
export const authValidateEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.AUTH_VALIDATE)), (action: AuthValidateAction) =>
    actionToActionState(action, state$, 'anonymous')
  )
    .pipe(
      concatMap((actionAndState: ActionAndState) => {
        const authValidateAction = actionAndState.action as AuthValidateAction;
        return callUiApi({
          epicDependencies,
          failureAction: authValidateFailed,
          method: validatePublicMethod(),
          state$,
          successAction: (ajaxResponse: AjaxResponse) => {
            if (ajaxResponse.status === 204) {
              handleRedirectResponse(ajaxResponse);
              return authValidateFailed('Redirected', 204);
            } else if (ajaxResponse.status === 202) {
              return mfaRequired(ajaxResponse.response);
            } else if (ajaxResponse.response.valid && ajaxResponse.response.result) {
              return authValidateFulfilled(ajaxResponse.response.result, authValidateAction.elisaIdClient);
            } else {
              return authValidateFailed('SSO token or JWT not valid', 200);
            }
          },
        });
      })
    )
    .pipe(
      concatMap(action => {
        return of(action);
      })
    );

// Verify email based on link token
export const verifyEmailEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.VERIFY_EMAIL)), (action: VerifyEmailAction) =>
    actionToActionState(action, state$, 'anonymous')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const { linkToken } = actionAndState.action as VerifyEmailAction;
      return callUiApi({
        epicDependencies,
        state$,
        method: verifyEmailPublicMethod({
          legacyIdentityProvider: !isFeatureEnabled(state$.value.config.featureFlags.elisaIdV2),
        }),
        payload: { linkToken },
        successAction: verifyEmailFulfilled,
        failureAction: verifyEmailFailed,
      });
    })
  );

export const addAnonymousLeadEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) => {
  return prepareUiApiRequest(action$.pipe(ofType(TypeKeys.ADD_ANONYMOUS_LEAD)), (action: AddAnonymousLeadAction) =>
    actionToActionState(action, state$, 'anonymous')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      if (!actionAndState.state) {
        throw new Error('invalid action state for adding lead');
      }
      const { lead } = actionAndState.action as AddAnonymousLeadAction;
      return callUiApi({
        epicDependencies,
        state$,
        method: createAnonymousLeadPublicMethod(),
        payload: lead,
        successAction: () => addAnonymousLeadFulfilled(lead),
        failureAction: addAnonymousLeadFailed,
      });
    })
  );
};

export const addAnonymousSupportCaseEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) => {
  return prepareUiApiRequest(
    action$.pipe(ofType(TypeKeys.ADD_ANONYMOUS_SUPPORT_CASE)),
    (action: AddAnonymousSupportCaseAction) => actionToActionState(action, state$, 'anonymous')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      if (!actionAndState.state) {
        throw new Error('invalid action state for adding support case');
      }
      const { anonymousSupportCase } = actionAndState.action as AddAnonymousSupportCaseAction;
      return callUiApi({
        epicDependencies,
        state$,
        method: createAnonymousSupportCasePublicMethod(),
        payload: anonymousSupportCase,
        successAction: addAnonymousSupportCaseFulfilled,
        failureAction: addAnonymousSupportCaseFailed,
      }).pipe(
        tap(() => {
          pushSupportCaseGAEventToDataLayer('form_submit', false, anonymousSupportCase.supportCase.feature);
        })
      );
    })
  );
};

export const sendOtpToUserEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) => {
  return prepareUiApiRequest(action$.pipe(ofType(TypeKeys.SEND_OTP_TO_USER)), (action: SendOtpToUserAction) =>
    actionToActionState(action, state$, 'anonymous')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const { data } = actionAndState.action as SendOtpToUserAction;
      if (!actionAndState.state) {
        throw new Error('invalid action state for generate password');
      }
      return callUiApi({
        method: sendOtpToUserPublicMethod(),
        payload: data,
        state$,
        epicDependencies,
        successAction: sendOtpToUserFulfilled,
        failureAction: sendOtpToUserFailed,
      });
    })
  );
};

export const validateOtpEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) => {
  return prepareUiApiRequest(action$.pipe(ofType(TypeKeys.VALIDATE_OTP)), (action: ValidateOtpAction) =>
    actionToActionState(action, state$, 'anonymous')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const { data } = actionAndState.action as ValidateOtpAction;
      if (!actionAndState.state) {
        throw new Error('invalid action state for validate password');
      }
      return callUiApi({
        method: validateOtpPublicMethod(),
        payload: data,
        state$,
        epicDependencies,
        successAction: validateOtpFulfilled,
        failureAction: validateOtpFailed,
      });
    })
  );
};

export const resetPasswordEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) => {
  return prepareUiApiRequest(action$.pipe(ofType(TypeKeys.RESET_PASSWORD)), (action: ResetPasswordAction) =>
    actionToActionState(action, state$, 'anonymous')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const { data } = actionAndState.action as ResetPasswordAction;
      if (!actionAndState.state) {
        throw new Error('invalid action state for change password and log in');
      }
      return callUiApi({
        method: resetPasswordPublicMethod(),
        payload: data,
        state$,
        epicDependencies,
        successAction: resetPasswordFulfilled,
        failureAction: resetPasswordFailed,
      });
    })
  );
};

export const anonymousUserEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = combineEpics(
  processElisaIdLoginEpic,
  logInEpic,
  logInFulfilledEpic,
  selectPrimaryAccountAndLoginEpic,
  addMissingMobileNumberAndLoginEpic,
  authValidateEpic,
  verifyEmailEpic,
  addAnonymousLeadEpic,
  addAnonymousSupportCaseEpic,
  sendOtpToUserEpic,
  validateOtpEpic,
  resetPasswordEpic,
  resolveMissingPrimaryAccountEpic
);
