import { ActionPhase } from '../common/storeUtils.js';
import { TypeKeys } from '../actions/index.js';
import { mergeArrays, reduceDisplayItemsLoadAction } from './reducerUtils.js';
import { sortArray } from '../../common/utils/arrayUtils.js';
import { updateActionStatePhase } from '../common/index.js';
import type { ActionsHistory } from '../common/store.js';
import type { EmployeeOnboardingRequest } from '../../generated/api/models.js';
import type { OnboardingRequestsState } from '../../common/types/states.js';
import type { SelfServiceActionTypes } from '../actions/index.js';

export function onboardingRequestsReducer(
  state: (OnboardingRequestsState & ActionsHistory) | null,
  action: SelfServiceActionTypes
): (OnboardingRequestsState & ActionsHistory) | null {
  if (typeof state === 'undefined') {
    return null;
  }

  switch (action.type) {
    case TypeKeys.LOAD_ONBOARDING_REQUESTS:
      return {
        ...reduceDisplayItemsLoadAction<TypeKeys.LOAD_ONBOARDING_REQUESTS, EmployeeOnboardingRequest>(
          action,
          state,
          'onboardingDisplayId',
          true,
          action.reporting ? 0 : undefined,
          action.forceLoad
        ),
        total: state ? state.total : undefined,
        items: state?.items,
      };
    case TypeKeys.LOAD_ONBOARDING_REQUESTS_FULFILLED: {
      const itemList = state?.sort
        ? sortArray(
            mergeArrays<EmployeeOnboardingRequest>(
              'employeeEmail',
              state.sort.columnId,
              state.items,
              action.onboardingRequests
            ),
            state.sort.columnId,
            state.sort.order
          )
        : sortArray(
            mergeArrays<EmployeeOnboardingRequest>(
              'employeeEmail',
              'createdDate',
              state!.items,
              action.onboardingRequests
            ),
            'createdDate',
            'desc'
          );

      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.LOAD_ONBOARDING_REQUESTS,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        items: itemList,
        total: action.totalOnboardingRequests,
        sort: state?.sort ? state.sort : { columnId: 'createdDate', order: 'desc' },
      };
    }
    case TypeKeys.LOAD_ONBOARDING_REQUESTS_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.LOAD_ONBOARDING_REQUESTS_FAILED,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
      };
    }
    default:
      return state;
  }
}
