import { getMobileIdContracts } from '../../selfservice/actions/mobileIdContractActions.js';
import { isFeatureEnabled } from '../../common/utils/featureFlagUtils.js';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useEffect } from 'react';
import type { State } from '../../selfservice/common/store.js';

interface SubscriptionProps {
  children: JSX.Element;
}

export const SubscriptionLayout = ({ children }: SubscriptionProps) => {
  const dispatch = useDispatch();
  const store = useStore<State>();
  const config = store.getState().config;
  const mobileIdContracts = useSelector((s: State) => s.selfservice?.subscriptions?.voice?.mobileIdContracts);

  useEffect(() => {
    if (isFeatureEnabled(config?.featureFlags?.mobileId) && !mobileIdContracts) {
      dispatch(getMobileIdContracts());
    }
  }, [config.featureFlags.mobileId, dispatch, mobileIdContracts]);

  return <div className="of-subscriptions-scene">{children}</div>;
};
