import * as CL from '@design-system/component-library';
import * as React from 'react';
import { ErrorTooltip } from '../ErrorTooltip/ErrorTooltip.js';
import { t } from '../../common/i18n/index.js';

type ConfirmationCheckboxProps = React.PropsWithChildren<{
  confirmed: boolean | undefined;
  errorMessage: React.ReactNode;
  id: string;
  onChange: (isConfirmed: boolean) => void;
}>;

const ConfirmationCheckbox = ({ children, confirmed, errorMessage, id, onChange }: ConfirmationCheckboxProps) => (
  <>
    {confirmed === false && (
      <ErrorTooltip placement="top" overlay>
        {errorMessage}
      </ErrorTooltip>
    )}

    <CL.Checkbox
      id={id}
      key={String(confirmed)}
      checked={confirmed}
      onChange={event => onChange(event.currentTarget.checked)}
    >
      {children}
    </CL.Checkbox>
  </>
);

export type EppRedeemConfirmationProps = Pick<ConfirmationCheckboxProps, 'confirmed' | 'onChange'> & {
  className?: string;
};

export const EppRedeemConfirmation = ({ className, ...props }: EppRedeemConfirmationProps) => (
  <div className={className}>
    <ConfirmationCheckbox {...props} id="redeemConfirmation" errorMessage={t.E1N1('Approve the terms.')}>
      <span>
        <strong>{t.IBOB('I understand and approve,')}</strong>{' '}
        {t.AJVW(
          `that the device must be cleared from any data, and factory settings must be applied before taking it to personal use. It's also prohibited to retrieve any company-related data to the device without the company's permission. In case you need help with these matters, please contact Elisa's customer service.`
        )}
      </span>
    </ConfirmationCheckbox>
  </div>
);
