import { HeroHeading, HeroHeadingType } from '../HeroHeading/index.js';
import { LocalNavi } from '../LocalNavi/index.js';
import { SystemError } from '../SystemError/SystemError.js';
import {
  devicesMsg,
  internetSubscriptionsMsg,
  licenseManagementMsg,
  myProductsAndServicesMsg,
  servicesMsg,
  t,
  voiceSubscriptionsMsg,
} from '../../common/i18n/index.js';
import { isFeatureEnabled, isFeatureEnabledForUser } from '../../common/utils/featureFlagUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { resetErrors } from '../../selfservice/actions/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch, useStore } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import type { BreadCrumbList } from '../BreadCrumbs/index.js';
import type { CommonError } from '../../common/types/errors.js';
import type { ConfigState } from '../../common/types/states.js';
import type { LocalNaviItem } from '../LocalNavi/index.js';
import type { ReactNode } from 'react';
import type { State } from '../../selfservice/common/store.js';

interface SubscriptionsProps {
  breadCrumbs?: BreadCrumbList;
  children: ReactNode;
  errors?: CommonError[];
}

export const getDisplayCategories = (config: ConfigState, userFeatureFlags: string[] | undefined) => {
  return [
    {
      children: t.AR0B(voiceSubscriptionsMsg),
      to: paths.PS_MOBILE_SUBSCRIPTIONS,
    },
    {
      children: t.SEYV(internetSubscriptionsMsg),
      to: paths.PS_BROADBAND_SUBSCRIPTIONS,
    },
    {
      children: t.JJTM(devicesMsg),
      to: paths.PS_DEVICES,
    },
    {
      children: t.MBG9(servicesMsg),
      to: paths.PS_SERVICES,
    },
    isFeatureEnabled(config.featureFlags.mobileId) && {
      children: t.SW0J('Contracts'),
      to: paths.PS_CONTRACTS,
    },
    isFeatureEnabledForUser('licenseManagement', config.featureFlags, userFeatureFlags) && {
      children: t.N689(licenseManagementMsg),
      to: paths.PS_LICENSES,
    },
    isFeatureEnabledForUser('dnsManagement', config.featureFlags, userFeatureFlags) && {
      children: 'Elisa DNS',
      to: paths.PS_ELISA_DNS,
    },
  ].filter(Boolean) as LocalNaviItem[];
};

export const SubscriptionsLayout = ({ breadCrumbs, children, errors }: SubscriptionsProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const config = useStore<State>().getState().config;
  const { authenticatedUser } = useAuth();

  if (errors?.length) {
    return (
      <SystemError
        errors={errors}
        onButtonClick={() => {
          dispatch(resetErrors());
          navigate(paths.SELF_SERVICE_HOME);
        }}
      />
    );
  }

  const displayCategories = getDisplayCategories(config, authenticatedUser?.enabledFeatureFlags);

  return (
    <div className="of-subscriptions-scene">
      <HeroHeading
        breadCrumbPaths={breadCrumbs}
        heroHeadingType={HeroHeadingType.SUBSCRIPTIONS}
        title={t.VWNX(myProductsAndServicesMsg)}
      />
      <LocalNavi categories={displayCategories} />
      {children}
    </div>
  );
};
