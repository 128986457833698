import { TypeKeys } from '../actions/index.js';
import type { ActionsHistory, LayoutState } from '../common/store.js';
import type { SelfServiceActionTypes } from '../actions/index.js';

export function layoutReducer(
  state: (LayoutState & ActionsHistory) | undefined,
  action: SelfServiceActionTypes
): (LayoutState & ActionsHistory) | null {
  if (typeof state === 'undefined') {
    return null;
  }

  switch (action.type) {
    case TypeKeys.FOOTER_SET_VISIBILITY: {
      return {
        ...state,
        hideHeaderFooter: !action.footerVisible,
        hasChanged: state === null || state.hideHeaderFooter !== action.footerVisible,
      };
    }

    default:
      return state;
  }
}
