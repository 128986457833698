import * as CL from '@design-system/component-library';
import { Controller, useFormContext } from 'react-hook-form';
import { DATE_FORMAT_REGEX } from '../../utils/validationUtils.js';
import { fieldCantBeEmptyMsg, invalidDateMsg, t } from '../../i18n/index.js';

export interface DatePickerProps {
  id?: string;
  name: string;
  label?: string;
  defaultValue?: string;
  optionalText?: string;
  required?: boolean;
  blockedDates?: Date[];
  disableAllPastDays?: boolean;
  disablePreviousMonths?: boolean;
  disableWeekends?: boolean;
  visibleRange?: [[number, number, number], [number, number, number]];
  validate?: (value: string) => string | undefined;
}

const getDateParts = (value: string): [number, number, number] => {
  const parts = value.split('.');
  return [Number.parseInt(parts[2], 10), Number.parseInt(parts[1], 10), Number.parseInt(parts[0], 10)];
};

export const DatePicker = ({
  required = false,
  name,
  label,
  defaultValue,
  optionalText,
  blockedDates = [],
  disableAllPastDays,
  disablePreviousMonths,
  disableWeekends = false,
  visibleRange = undefined,
  validate,
}: DatePickerProps) => {
  const { control, watch } = useFormContext();
  const hasChanged = watch(name) !== defaultValue;

  return (
    <Controller
      defaultValue={defaultValue}
      name={name}
      control={control}
      rules={{
        required: { value: required, message: t.VPVR(fieldCantBeEmptyMsg) },
        validate: {
          format: value => {
            if (!hasChanged) {
              return true;
            }
            const isValidFormat = DATE_FORMAT_REGEX.test(value);
            if (!isValidFormat) {
              return t.ULMQ(invalidDateMsg);
            }
            // CL Datepicker doesn't pad the numbers...
            const [day, month, year] = value.split('.').map((v: string) => v.padStart(2, '0'));
            const date = new Date(`${year}-${month}-${day}`);
            const isValidDate =
              date.getFullYear().toString() === year &&
              (date.getMonth() + 1).toString().padStart(2, '0') === month.toString() &&
              date.getDate().toString().padStart(2, '0') === day.toString();
            return isValidDate || t.ULMQ(invalidDateMsg);
          },
          custom: value => (validate ? validate(value) : true),
        },
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const date = value ? getDateParts(value) : undefined;
        return (
          <CL.DatePicker
            optional={!required}
            selectedDay={date}
            onValueChange={onChange}
            i18n_datepicker_errorMessage={error?.message}
            disableWeekends={disableWeekends}
            disabledDays={blockedDates.map(blockedDate => [
              blockedDate.getFullYear(),
              blockedDate.getMonth() + 1,
              blockedDate.getDate(),
            ])}
            disableAllPastDays={disableAllPastDays}
            disablePreviousMonths={disablePreviousMonths}
            visibleRange={visibleRange}
            i18n_datepicker_start_label={label || ''}
            i18n_input_optionalText={optionalText}
          />
        );
      }}
    />
  );
};
