import * as CL from '@design-system/component-library';
import { DirectoryDetails } from '../../../generated/api/directoryDetails.js';
import { NumberPrivacyHeader } from './NumberPrivacyHeader.js';
import { SelectRadio } from '../../../common/react-hook-form/components/SelectRadio.js';
import { TextInput } from '../../../common/react-hook-form/fields/index.js';
import {
  companyNameMsg,
  corporatePhoneMsg,
  editMsg,
  nameMsg,
  postOfficeMsg,
  postalCodeMsg,
  streetAddressMsg,
  t,
} from '../../../common/i18n/index.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { getNumberPublicityOptions } from '../UserNumberPublicityUtils.js';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

export interface NumberPrivacyFieldsProps {
  numberPrivacy?: DirectoryDetails.NumberDirectoryEnum;
  directoryDetails?: DirectoryDetails;
  type?: NumberPrivacyType;
}

export enum NumberPrivacyType {
  PRIVATE,
  CORPORATE,
}

interface NumberPrivacyTypeItem {
  type: NumberPrivacyType;
  fieldPrefix: string;
  headerText: string;
  fieldName: string;
}

const numberPrivacyTypes: NumberPrivacyTypeItem[] = [
  {
    type: NumberPrivacyType.CORPORATE,
    fieldPrefix: 'directoryListingCorporateNumberPublicity.pbxDirectoryDetails',
    headerText: t.WL8J(corporatePhoneMsg),
    fieldName: 'numberPrivacyCorporate',
  },
  {
    type: NumberPrivacyType.PRIVATE,
    fieldPrefix: 'directoryListingMobileNumberPublicity.voiceDirectoryDetails',
    headerText: t.OWF1('Mobile phone'),
    fieldName: 'numberPrivacyMobile',
  },
];

export const NumberPrivacyFields = ({ numberPrivacy, directoryDetails, type }: NumberPrivacyFieldsProps) => {
  const [editing, setEditing] = useState(false);
  const { setValue, resetField } = useFormContext();
  const hideNotToDirectoryOption = numberPrivacy !== DirectoryDetails.NumberDirectoryEnum.NOT_TO_DIRECTORY;

  const numberPrivacyType = numberPrivacyTypes.find(item => item.type === type);

  useEffect(() => {
    if (numberPrivacy === DirectoryDetails.NumberDirectoryEnum.SECRET) {
      setValue(`${numberPrivacyType?.fieldPrefix}.numberDirectory`, DirectoryDetails.NumberDirectoryEnum.SECRET);
      setValue(`${numberPrivacyType?.fieldPrefix}.companyName`, undefined);
      setValue(`${numberPrivacyType?.fieldPrefix}.recipientName`, undefined);
      setValue(`${numberPrivacyType?.fieldPrefix}.address`, undefined);
    }
    if (numberPrivacy === DirectoryDetails.NumberDirectoryEnum.CUSTOM) {
      setValue(`${numberPrivacyType?.fieldPrefix}.numberDirectory`, DirectoryDetails.NumberDirectoryEnum.CUSTOM);
      setValue(`${numberPrivacyType?.fieldPrefix}.companyName`, directoryDetails?.companyName);
      setValue(`${numberPrivacyType?.fieldPrefix}.recipientName`, directoryDetails?.recipientName);
      setValue(`${numberPrivacyType?.fieldPrefix}.address`, directoryDetails?.address);
    }
  }, [
    numberPrivacy,
    setValue,
    resetField,
    directoryDetails?.companyName,
    directoryDetails?.recipientName,
    directoryDetails?.address,
    numberPrivacyType,
  ]);

  if (!directoryDetails || !numberPrivacyType) {
    return null;
  }

  return (
    <>
      <div className={`${dsClass.FONT_STYLE_NORMAL} ${dsClass.FONT_WEIGHT_BOLD} ${dsClass.MARGIN_RIGHT_2}`}>
        <NumberPrivacyHeader headerText={numberPrivacyType?.headerText} />
      </div>
      <SelectRadio name={numberPrivacyType.fieldName} items={getNumberPublicityOptions(hideNotToDirectoryOption)} />
      {numberPrivacy === DirectoryDetails.NumberDirectoryEnum.CUSTOM && (
        <>
          {editing ? (
            <>
              <div className={dsClass.PADDING_LEFT_2}>
                <TextInput
                  name={`${numberPrivacyType.fieldPrefix}.companyName`}
                  label={t.AJ93(companyNameMsg)}
                  required={false}
                />
              </div>
              <div className={dsClass.PADDING_LEFT_2}>
                <TextInput
                  name={`${numberPrivacyType.fieldPrefix}.recipientName`}
                  label={t.VGFI(nameMsg)}
                  required={false}
                />
              </div>
              <div className={dsClass.PADDING_LEFT_2}>
                <TextInput
                  name={`${numberPrivacyType.fieldPrefix}.address.line1`}
                  label={t.DD38(streetAddressMsg)}
                  required={false}
                />
              </div>
              <div className={dsClass.PADDING_LEFT_2}>
                <TextInput
                  name={`${numberPrivacyType.fieldPrefix}.address.postalCode`}
                  label={t.RUAW(postalCodeMsg)}
                  required={false}
                />
              </div>
              <div className={dsClass.PADDING_LEFT_2}>
                <TextInput
                  name={`${numberPrivacyType.fieldPrefix}.address.postOffice`}
                  label={t.Z7S5(postOfficeMsg)}
                  required={false}
                />
              </div>
            </>
          ) : (
            <>
              <div className={dsClass.PADDING_LEFT_5}>
                <span>{directoryDetails?.companyName || ''}</span>
              </div>
              <div className={dsClass.PADDING_LEFT_5}>
                <span>{directoryDetails?.recipientName || ''}</span>
              </div>
              <div className={dsClass.PADDING_LEFT_5}>
                <span>{directoryDetails?.address?.line1 || ''}</span>
              </div>
              <div className={dsClass.PADDING_LEFT_5}>
                <span>{`${directoryDetails?.address?.postalCode || ''} ${
                  directoryDetails?.address?.postOffice || ''
                }`}</span>
              </div>
              <div className={`${dsClass.PADDING_LEFT_5} of-usernumber-and-publicity__edit_button`}>
                <CL.Button
                  color="link"
                  onClick={() => {
                    setEditing(true);
                  }}
                  className={dsClass.FONT_SIZE_MEDIUM}
                >
                  {t.NVPK(editMsg)}
                </CL.Button>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
