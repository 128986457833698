import { FixedBroadbandProduct } from '../FixedBroadbandProduct.js';
import type { AddOn } from '../../../generated/api/addOn.js';
import type { AddressSearchMatch } from '../../../components/AddressSearch/AddressSearch.js';
import type { CampaignAssociation } from '../../../generated/api/campaignAssociation.js';
import type { CheckoutStepProps, YritystuplaOfferSelection } from '../FixedBroadbandCheckout.js';
import type { CompanyInfoState } from '../../../common/types/states.js';
import type { ConfiguredOffer } from '../../../common/types/commercialProduct.js';
import type { PurposeOfUseOrContact } from '../../../common/types/subscription.js';

interface StepBroadbandProductProps extends CheckoutStepProps {
  addressSearchMatch: AddressSearchMatch;
  configuredOffer: ConfiguredOffer;
  selectedCampaign?: CampaignAssociation;
  setPurposeOfUseOrContact: React.Dispatch<React.SetStateAction<PurposeOfUseOrContact>>;
  setYritystuplaPurposeOfUse: React.Dispatch<React.SetStateAction<PurposeOfUseOrContact | undefined>>;
  setAdditionalAddOns: React.Dispatch<React.SetStateAction<AddOn[]>>;
  companyInfo?: CompanyInfoState | null;
  selectedPurposeOfUseOrContact?: PurposeOfUseOrContact;
  yritystuplaOffer: YritystuplaOfferSelection;
}

export const StepBroadbandProduct = (props: StepBroadbandProductProps) => {
  const {
    addressSearchMatch,
    configuredOffer,
    selectedCampaign,
    setPurposeOfUseOrContact,
    setYritystuplaPurposeOfUse,
    setAdditionalAddOns,
    enterNextStep,
    companyInfo,
    selectedPurposeOfUseOrContact,
    yritystuplaOffer,
  } = props;

  return (
    <FixedBroadbandProduct
      addressSearchMatch={addressSearchMatch}
      configuredOffer={configuredOffer}
      selectedCampaign={selectedCampaign}
      onNext={(purposeOrContact, addOns, yritystuplaPoU) => {
        setPurposeOfUseOrContact(purposeOrContact);
        setYritystuplaPurposeOfUse(yritystuplaPoU);
        setAdditionalAddOns(addOns);
        enterNextStep(3);
      }}
      companyInfo={companyInfo}
      selectedPurposeOfUseOrContact={selectedPurposeOfUseOrContact}
      yritystuplaOffer={yritystuplaOffer}
    />
  );
};
