import { CardPaymentResponseStatusText } from '../../components/DeviceCheckoutCardPayment/DeviceCheckoutCardPayment.js';
import { DELIVERY_DETAILS_FOR_ONLINE_ORDER_CARD_PAYMENT_SESSION_STORAGE_KEY } from '../common/index.js';
import { EMPTY, of } from 'rxjs';
import {
  TypeKeys,
  finalizeOnlineOrderCardPaymentFailed,
  finalizeOnlineOrderCardPaymentFulfilled,
  initializeOnlineOrderCardPayment,
  initializeOnlineOrderCardPaymentFailed,
  initializeOnlineOrderCardPaymentFulfilled,
} from '../actions/index.js';
import { actionToActionState } from './epicUtils.js';
import { callUiApi, prepareUiApiRequest } from '../common/uiApiUtils.js';
import { combineEpics, ofType } from 'redux-observable';
import { concatMap, mergeMap } from 'rxjs/operators';
import {
  contactUserContactEquals,
  createContacts,
  getDefaultAddOns,
  getNewContacts,
  insertContactIdsIntoOffers,
  mapCommercialProductToOnlineOrderItemUnit,
} from './onlineOrderEpicUtils.js';
import {
  finalizeOnlineOrderCardPaymentPrivateMethod,
  initializeOnlineOrderCardPaymentPrivateMethod,
} from '../../generated/api/uiApiMethods.js';
import { getTotalSums } from '../../common/utils/commercialProductUtils.js';
import { isInBrowser } from '../../common/utils/ssrUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { push } from 'redux-first-history';
import type { Action } from 'redux';
import type { ActionAndState, EpicDependencies } from './epicUtils.js';
import type { ActionWithId, State } from '../common/store.js';
import type { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import type { AjaxResponse } from 'rxjs/ajax';
import type { Contact, OnlineOrder, OnlineOrderItem } from '../../generated/api/models.js';
import type {
  ErrorAction,
  FinalizeOnlineOrderCardPaymentAction,
  InitializeOnlineOrderCardPaymentAction,
  InitializeOnlineOrderCardPaymentFulfilledAction,
  PollForMyselfChangesFulfilledAction,
  SelfServiceActionTypes,
  SetDeliveryDetailsForOnlineOrderCardPayment,
  UpsertContactFulfilledAction,
} from '../actions/index.js';

const insertContactIdsIntoOrderWithCard = (
  cardPaymentAction: InitializeOnlineOrderCardPaymentAction,
  contacts: Contact[]
): InitializeOnlineOrderCardPaymentAction => {
  return {
    ...cardPaymentAction,
    orderItems: insertContactIdsIntoOffers(cardPaymentAction.orderItems, contacts),
  };
};

const initializeOnlineOrderCardPaymentEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) => {
  return prepareUiApiRequest(
    action$.pipe(ofType(TypeKeys.INITIALIZE_ONLINE_ORDER_CARD_PAYMENT)),
    (action: ActionWithId) => {
      return actionToActionState(action, state$, 'onlineOrders');
    }
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const initializeCardPaymentAction = actionAndState.action as InitializeOnlineOrderCardPaymentAction;

      // Create new contacts if included the the order
      const newContacts = getNewContacts(initializeCardPaymentAction.orderItems);
      if (newContacts.length > 0) {
        return createContacts(initializeCardPaymentAction.orderItems, state$, epicDependencies).pipe(
          concatMap((actions: (UpsertContactFulfilledAction | ErrorAction<TypeKeys>)[]): Action[] => {
            // Fail the device orders (submitted from checkout page) if contact creation fails
            // (mobile voice and mbb orders falls back to POU instead of contact in such cases)
            if (actions.find(action => action.type === TypeKeys.UPSERT_CONTACT_FAILED)) {
              return [initializeOnlineOrderCardPaymentFailed('Failed to create Contact', 500)];
            }
            const createdContacts = actions
              .filter(action => action.type === TypeKeys.UPSERT_CONTACT_FULFILLED)
              .map((action: UpsertContactFulfilledAction) => action.contact);

            const updatedAction = insertContactIdsIntoOrderWithCard(initializeCardPaymentAction, createdContacts);

            initializeCardPaymentAction.orderItems.map(item =>
              item.selectedCommercialProducts.map(selectedCp => ({
                ...selectedCp,
                purposeOfUseOrContact:
                  selectedCp.purposeOfUseOrContact!.newContact !== undefined &&
                  actions
                    .filter(action => action.type === TypeKeys.UPSERT_CONTACT_FULFILLED)
                    .find((action: UpsertContactFulfilledAction) => {
                      contactUserContactEquals(
                        (action.contact.person || action.contact.userContact)!,
                        selectedCp.purposeOfUseOrContact!.newContact!
                      );
                    }),
              }))
            );
            return [
              ...actions,
              initializeOnlineOrderCardPayment(
                updatedAction.orderItems,
                updatedAction.deliveryAddress,
                updatedAction.deliveryMethodType,
                true
              ),
            ];
          })
        );
      }

      // Create orderItems stuff
      const orderItems: OnlineOrderItem[] = initializeCardPaymentAction.orderItems.map(item => {
        const orderItem: OnlineOrderItem = {
          // Add default addons
          addOns: getDefaultAddOns(item.selectedCommercialProducts[0]),
          // only supporting one selected commercial product per selected offer (as does the UI) for now
          commercialProductCode: item.selectedCommercialProducts[0].commercialProduct.commercialProductCode,
          commercialProductName: item.selectedCommercialProducts[0].commercialProduct.commercialProductName,
          offerCode: item.offer.offerCode,
          orderItemQuantity: item.selectedCommercialProducts.length,
          orderItemUnits: item.selectedCommercialProducts.map((product, index) =>
            mapCommercialProductToOnlineOrderItemUnit(product, index, initializeCardPaymentAction.deliveryMethodType)
          ),
          enrollmentProgramSelected: item.selectedCommercialProducts[0].enrollmentProgramConsent,
        };
        return orderItem;
      });

      const totalSums = getTotalSums(
        initializeCardPaymentAction.orderItems
          .map(configuredOffer => configuredOffer.selectedCommercialProducts)
          .reduce((prev, next) => prev.concat(next), []) // flattens
      );

      const onlineOrder: Partial<OnlineOrder> = {
        deliveryAddress: initializeCardPaymentAction.deliveryAddress,
        orderItems: orderItems,
        totalFixedTermCharge: totalSums.totalFixedTermCharge,
        totalMonthlyRecurringCharge: totalSums.totalMonthlyRecurringCharge,
        totalOneTimeCharge: totalSums.totalOneTimeCharge,
        totalOneTimeChargeVat: totalSums.totalOneTimeChargeVat,
      };

      const successAction = (response: AjaxResponse) => {
        return initializeOnlineOrderCardPaymentFulfilled(response.response);
      };
      const initializePayment$ = callUiApi({
        epicDependencies,
        failureAction: initializeOnlineOrderCardPaymentFailed,
        method: initializeOnlineOrderCardPaymentPrivateMethod(),
        payload: {
          onlineOrder: onlineOrder,
        },
        state$,
        successAction,
      });
      return initializePayment$.pipe(
        concatMap((finalAction: InitializeOnlineOrderCardPaymentFulfilledAction) => {
          if (finalAction.type === TypeKeys.INITIALIZE_ONLINE_ORDER_CARD_PAYMENT_FULFILLED) {
            window.location.href = finalAction.forwardUrl;
          }
          return of(finalAction);
        })
      );
    })
  );
};

const finalizeOnlineOrderCardPaymentEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) => {
  return prepareUiApiRequest(
    action$.pipe(ofType(TypeKeys.FINALIZE_ONLINE_ORDER_CARD_PAYMENT)),
    (action: ActionWithId) => {
      return actionToActionState(action, state$, 'onlineOrders');
    }
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const action = actionAndState.action as FinalizeOnlineOrderCardPaymentAction;
      if (action.responseStatusText !== CardPaymentResponseStatusText.OK) {
        return [push(`${paths.DEVICE_CHECKOUT_CARD_PAYMENT_COMPLETE}?responseCode=${action.responseStatusText}`)];
      }
      const payload = {
        transactionId: action.transactionId,
      };
      const successAction = (response: AjaxResponse) => {
        return finalizeOnlineOrderCardPaymentFulfilled(response.response);
      };

      return callUiApi({
        epicDependencies,
        failureAction: finalizeOnlineOrderCardPaymentFailed,
        method: finalizeOnlineOrderCardPaymentPrivateMethod(),
        payload,
        state$,
        successAction,
      }).pipe(
        concatMap(prevAction => {
          if (prevAction.type === TypeKeys.FINALIZE_ONLINE_ORDER_CARD_PAYMENT_FAILED) {
            return [prevAction, push(paths.DEVICE_CHECKOUT_CARD_PAYMENT_COMPLETE)];
          }
          return [prevAction];
        })
      );
    })
  );
};

const completeCardPaymentEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>
) =>
  action$.ofType(TypeKeys.POLL_FOR_MYSELF_CHANGES_FULFILLED).pipe(
    mergeMap((action: PollForMyselfChangesFulfilledAction) => {
      if (action.cardPaymentResult && action.cardPaymentResult.status) {
        return [push(`${paths.DEVICE_CHECKOUT_CARD_PAYMENT_COMPLETE}?responseCode=${action.cardPaymentResult.status}`)];
      }
      return EMPTY;
    })
  );

const setDeliveryDetailsForCardPaymentEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>
) =>
  action$.ofType(TypeKeys.SET_DELIVERY_DETAILS_FOR_ONLINE_ORDER_CARD_PAYMENT).pipe(
    mergeMap((action: SetDeliveryDetailsForOnlineOrderCardPayment) => {
      if (isInBrowser()) {
        sessionStorage.setItem(
          DELIVERY_DETAILS_FOR_ONLINE_ORDER_CARD_PAYMENT_SESSION_STORAGE_KEY,
          JSON.stringify(action.deliveryDetails)
        );
      }
      return EMPTY;
    })
  );

const resetDeliveryDetailsForCardPaymentEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>
) =>
  action$.ofType(TypeKeys.LOGOUT_FULFILLED).pipe(
    mergeMap(() => {
      if (isInBrowser()) {
        sessionStorage.removeItem(DELIVERY_DETAILS_FOR_ONLINE_ORDER_CARD_PAYMENT_SESSION_STORAGE_KEY);
      }
      return EMPTY;
    })
  );

export const onlineOrdersCardPaymentEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = combineEpics(
  initializeOnlineOrderCardPaymentEpic,
  finalizeOnlineOrderCardPaymentEpic,
  completeCardPaymentEpic,
  setDeliveryDetailsForCardPaymentEpic,
  resetDeliveryDetailsForCardPaymentEpic
);
