import { TypeKeys, generateActionId } from './actionsUtils.js';
import { convertToCommonErrors } from '../../common/utils/errorUtils.js';
import type { CompositeListSort } from '../../components/CompositeListHeader/CompositeListHeader.js';
import type {
  CustomerOrder,
  CustomerOrderSearchResponse,
  CustomerOrdersResponse,
  GenericError,
} from '../../generated/api/models.js';
import type { DisplayItemsLoadAction, ErrorAction, ErrorActionCreator } from './actionsUtils.js';
import type { ItemsQuery } from '../common/store.js';

export interface LoadEmployeeCustomerOrdersAction
  extends DisplayItemsLoadAction<TypeKeys.LOAD_EMPLOYEE_CUSTOMER_ORDERS, CustomerOrder> {
  forceLoad?: boolean;
  status?: string;
}

export interface LoadEmployeeCustomerOrdersFulfilledAction {
  customerOrders?: CustomerOrder[];
  query: ItemsQuery;
  searchResults?: CustomerOrderSearchResponse[];
  useSearchService?: boolean;
  mergeHeaders?: boolean;
  total: number;
  type: TypeKeys.LOAD_EMPLOYEE_CUSTOMER_ORDERS_FULFILLED;
}

export type LoadEmployeeCustomerOrdersFailedAction = ErrorAction<TypeKeys.LOAD_EMPLOYEE_CUSTOMER_ORDERS_FAILED>;

export const loadEmployeeCustomerOrders = (
  displayId?: string,
  deliveryId?: string,
  sort?: CompositeListSort,
  search?: string,
  reporting = false,
  forceLoad?: boolean,
  status?: string
): LoadEmployeeCustomerOrdersAction => ({
  displayId,
  id: generateActionId(),
  search,
  sort,
  reporting,
  forceLoad,
  status: status || 'ALL',
  type: TypeKeys.LOAD_EMPLOYEE_CUSTOMER_ORDERS,
});
export const loadEmployeeCustomerOrdersFulfilled = (
  response: CustomerOrdersResponse,
  query: ItemsQuery,
  useSearchService?: boolean,
  mergeHeaders?: boolean
): LoadEmployeeCustomerOrdersFulfilledAction => ({
  customerOrders: response.customerOrders,
  query,
  searchResults: response.searchResults,
  useSearchService,
  mergeHeaders,
  total: response.total,
  type: TypeKeys.LOAD_EMPLOYEE_CUSTOMER_ORDERS_FULFILLED,
});

export const loadEmployeeCustomerOrdersFailed: ErrorActionCreator<TypeKeys.LOAD_EMPLOYEE_CUSTOMER_ORDERS_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): LoadEmployeeCustomerOrdersFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    params,
    type: TypeKeys.LOAD_EMPLOYEE_CUSTOMER_ORDERS_FAILED,
  };
};

export type EmployeeCustomerOrdersActionTypes =
  | LoadEmployeeCustomerOrdersAction
  | LoadEmployeeCustomerOrdersFailedAction
  | LoadEmployeeCustomerOrdersFulfilledAction;
