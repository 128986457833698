import * as CL from '@design-system/component-library';
import { dsClass } from '../../common/constants/dsClasses.js';
import { t } from '../../common/i18n/index.js';

import './AddOnExternalLink.scss';

export const AddOnExternalLink = ({ linkHref }: { linkHref: string }) => (
  <CL.Link className={`${dsClass.MARGIN_TOP_1} of-add-on-external-link`} linkHref={linkHref} linkTarget="_blank">
    {`${t.ZOES('Read more about services')} `}
    <CL.Icon icon="external-link" className={`${dsClass.DISPLAY_INLINE_BLOCK} ${dsClass.MARGIN_LEFT_2}`} />
  </CL.Link>
);
