import { ShoppingCart } from '../../../components/ShoppingCart/ShoppingCart.js';
import { useShoppingCart } from '../../../common/hooks/useShoppingCart.js';

interface ShoppingCartProps {
  header?: JSX.Element;
}

export const ShoppingCartPath = ({ header }: ShoppingCartProps) => {
  // Loads cart items from localstorage to state deviceCheckout.cartItems
  useShoppingCart();

  return (
    <div id="shoppingcart">
      {header}
      <ShoppingCart />
    </div>
  );
};
