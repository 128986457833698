import * as CL from '@design-system/component-library';
import { EmployeeDeviceChange } from '../../DeviceCheckout/EmployeeDeviceChange.js';
import { PunchoutCheckoutAuth } from './PunchoutCheckoutAuth.js';
import { PunchoutCheckoutBilling } from './PunchoutCheckoutBilling.js';
import { PunchoutDeliveryDetails } from './PunchoutDeliveryDetails.js';
import { authenticationMsg, deliveryDetailsMsg, t } from '../../../common/i18n/index.js';
import { deepEqual } from '../../../common/utils/objectUtils.js';
import { isEppDevicePresentInCart } from '../../../common/utils/checkoutUtils.js';
import { loadDeliveryMethods } from '../../../selfservice/actions/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import type * as CLT from '@design-system/component-library';
import type { AuthenticatedUserState } from '../../../common/types/states.js';
import type { CommonError } from '../../../common/types/errors.js';
import type { DeviceChangeRequest } from '../../../common/types/device.js';
import type { DeviceCheckoutDeliveryDetailsType } from '../../DeviceCheckoutDeliveryDetails/DeviceCheckoutDeliveryDetailsUtils.js';
import type { EmployeeSubscription } from '../../../common/types/subscription.js';
import type { PersonalBillingDetailsData } from '../../PersonalBillingDetails/PersonalBillingDetails.js';
import type { State } from '../../../selfservice/common/store.js';

type PunchoutCheckoutStepsProps = {
  activeStep: ActiveStep;
  billingInfoConfirmed: boolean;
  deliveryDetails: DeviceCheckoutDeliveryDetailsType;
  deviceSubs: EmployeeSubscription[];
  hideDeviceChangeStep: boolean;
  isBillingTermsAccepted?: boolean;
  isDeviceChangeStepDone: boolean;
  isOmalaiteLasku?: boolean;
  isStronglyAuthenticated?: boolean;
  errors?: CommonError[];
  onDeviceChangeNextStep: (updatedDeviceChangeRequest: DeviceChangeRequest) => void;
  onSaveBillingDetailsAndNext: (values: PersonalBillingDetailsData) => void;
  onSaveDeliveryDetailsAndNext: (updatedDeliveryDetails: DeviceCheckoutDeliveryDetailsType) => void;
  onSetBillingTerms: (termsAccepted: boolean) => void;
  setDeliveryDetailsObj: (updatedDeliveryDetails: DeviceCheckoutDeliveryDetailsType) => void;
  skipDeliveryDetails: boolean;
  skipDeliveryDetailsAndNext: () => void;
  user: AuthenticatedUserState;
};

export enum ActiveStep {
  DEVICE_CHANGE = 'deviceChangeStep',
  AUTH = 'authStep',
  BILLING = 'billingStep',
  DELIVERY_DETAILS = 'deliveryDetailsStep',
}

export const PunchoutCheckoutSteps = ({
  activeStep,
  billingInfoConfirmed,
  deliveryDetails,
  deviceSubs,
  hideDeviceChangeStep,
  isBillingTermsAccepted,
  isDeviceChangeStepDone,
  isOmalaiteLasku,
  isStronglyAuthenticated,
  onDeviceChangeNextStep,
  onSaveBillingDetailsAndNext,
  onSaveDeliveryDetailsAndNext,
  onSetBillingTerms,
  setDeliveryDetailsObj,
  skipDeliveryDetails,
  skipDeliveryDetailsAndNext,
  user,
}: PunchoutCheckoutStepsProps) => {
  const dispatch = useDispatch();
  const cartItems = useSelector((state: State) => state.deviceCheckout?.cartItems || [], deepEqual);
  const eppDevicePresentInCart = isEppDevicePresentInCart(cartItems);

  useEffect(() => {
    if (!skipDeliveryDetails) {
      dispatch(loadDeliveryMethods(eppDevicePresentInCart));
    }
  }, [dispatch, eppDevicePresentInCart, skipDeliveryDetails]);

  useEffect(() => {
    if (skipDeliveryDetails && !isOmalaiteLasku && (isDeviceChangeStepDone || hideDeviceChangeStep)) {
      skipDeliveryDetailsAndNext();
    }
  }, [
    dispatch,
    skipDeliveryDetailsAndNext,
    isOmalaiteLasku,
    skipDeliveryDetails,
    isDeviceChangeStepDone,
    hideDeviceChangeStep,
  ]);

  const deviceChangeSteps: CLT.StepperItem[] = hideDeviceChangeStep
    ? []
    : [
        {
          id: ActiveStep.DEVICE_CHANGE,
          name: t.JN29('Previous device'),
          content: <EmployeeDeviceChange user={user} onNextClick={onDeviceChangeNextStep} deviceSubs={deviceSubs} />,
          completed: isDeviceChangeStepDone,
        },
      ];

  const omaLaiteLaskuSteps: CLT.StepperItem[] = isOmalaiteLasku
    ? [
        {
          id: ActiveStep.AUTH,
          name: t.AKN3(authenticationMsg),
          content: <PunchoutCheckoutAuth />,
          disabled: !isStronglyAuthenticated,
          completed: isStronglyAuthenticated,
        },
        {
          id: ActiveStep.BILLING,
          name: t.NMNN('Oma Laitelasku details'),
          content: isStronglyAuthenticated ? (
            <PunchoutCheckoutBilling
              user={user}
              onClickNext={onSaveBillingDetailsAndNext}
              isBillingTermsAccepted={isBillingTermsAccepted}
              onSetBillingTerms={onSetBillingTerms}
            />
          ) : undefined,
          disabled: !isStronglyAuthenticated,
          completed: billingInfoConfirmed,
        },
      ]
    : [];

  const restSteps: CLT.StepperItem[] = [
    {
      id: ActiveStep.DELIVERY_DETAILS,
      name: t.T78Y(deliveryDetailsMsg),
      content: (
        <PunchoutDeliveryDetails
          onSaveDeliveryDetailsAndNext={onSaveDeliveryDetailsAndNext}
          setDeliveryDetailsObj={setDeliveryDetailsObj}
          deliveryDetails={deliveryDetails}
        />
      ),
      disabled: !billingInfoConfirmed,
    },
    {
      id: 'summaryStep',
      name: t.VDB4('Summary'),
      disabled: true,
    },
    {
      id: 'confirmationStep',
      name: t.R69E('Confirmation'),
      disabled: true,
    },
  ];

  const steps: CLT.StepperItem[] = [...deviceChangeSteps, ...omaLaiteLaskuSteps, ...restSteps];

  return (
    <div className="cl-stepper-wrapper">
      <CL.Stepper steps={steps} activeStep={activeStep}></CL.Stepper>
    </div>
  );
};
