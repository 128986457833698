import { SubscriptionCategory } from '../../common/enums.js'; // WizardType
import { TypeKeys, authValidate, getEmployeeSubscriptions } from '../actions/index.js'; // updateWizard
import { ValidateSubscriptionPairingResponse } from '../../generated/api/models.js';
import { actionToActionState } from './epicUtils.js';
import { callUiApi, prepareUiApiRequest } from '../common/uiApiUtils.js';
import { combineEpics, ofType } from 'redux-observable';
import { concatMap, filter, mergeMap } from 'rxjs/operators';
import {
  processSubscriptionPairingFailed,
  processSubscriptionPairingFulfilled,
  validateSubscriptionFailed,
  validateSubscriptionFulfilled,
} from '../actions/employeeActions.js';
import { push } from 'redux-first-history';
import {
  requestEmployeeOtpForPairingPrivateMethod,
  validateEmployeeOtpAndPairPrivateMethod,
} from '../../generated/api/uiApiMethods.js';
import type { Action } from 'redux';
import type { ActionAndState, EpicDependencies } from './epicUtils.js';
import type { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import type { AjaxResponse } from 'rxjs/ajax';
import type {
  ErrorAction,
  ProcessEmployeeSubscriptionPairingFulfilledAction,
  SelfServiceActionTypes,
  ValidateEmployeeSubscriptionFulfilledAction,
} from '../actions/index.js';
import type {
  ProcessEmployeeSubscriptionPairingAction,
  ValidateEmployeeSubscriptionAction,
} from '../actions/employeeActions.js';
import type { State } from '../common/store.js';

export const processSubscriptionPairingEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(
    action$.pipe(ofType(TypeKeys.PROCESS_EMPLOYEE_SUBSCRIPTION_PAIRING)),
    (action: ProcessEmployeeSubscriptionPairingAction) => actionToActionState(action, state$, 'authenticated')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const processSubscriptionPairing = actionAndState.action as ProcessEmployeeSubscriptionPairingAction;
      return callUiApi({
        epicDependencies,
        state$,
        method: requestEmployeeOtpForPairingPrivateMethod(),
        payload: processSubscriptionPairing.payload,
        successAction: processSubscriptionPairingFulfilled,
        failureAction: processSubscriptionPairingFailed,
      }).pipe(
        concatMap((finalAction: ProcessEmployeeSubscriptionPairingFulfilledAction) => {
          return [finalAction];
        })
      );
    })
  );

export const validateSubscriptionEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(
    action$.pipe(ofType(TypeKeys.VALIDATE_EMPLOYEE_SUBSCRIPTION_PAIRING)),
    (action: ValidateEmployeeSubscriptionAction) => actionToActionState(action, state$, 'authenticated')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const { payload } = actionAndState.action as ValidateEmployeeSubscriptionAction;
      return callUiApi({
        epicDependencies,
        state$,
        method: validateEmployeeOtpAndPairPrivateMethod(),
        payload,
        successAction: (ajaxResponse: AjaxResponse) =>
          validateSubscriptionFulfilled(ajaxResponse.response, payload.subscriptionType, payload.redirectPath),
        failureAction: validateSubscriptionFailed,
      });
    }),
    concatMap((action: ValidateEmployeeSubscriptionFulfilledAction | ErrorAction<TypeKeys>) => {
      // validate invoked again so that login-info considers also freshly paired subscription
      // for example expiringEppSubscriptions, pendingSubscriptionActions needs to be re-calculated
      return action.type === TypeKeys.VALIDATE_EMPLOYEE_SUBSCRIPTION_PAIRING_FULFILLED
        ? [action, authValidate()]
        : [action];
    })
  );

export const completeValidateSubscriptionsEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>
) =>
  action$.pipe(
    ofType(TypeKeys.VALIDATE_EMPLOYEE_SUBSCRIPTION_PAIRING_FULFILLED),
    filter(() =>
      state$.value.user && state$.value.user.authenticated
        ? state$.value.user.authenticated.errors === undefined
        : false
    ),
    mergeMap(action => {
      const validateSubscriptionFulfilledAction = action as ValidateEmployeeSubscriptionFulfilledAction;
      const actionArrays: Action[] = [push(validateSubscriptionFulfilledAction.redirectPath)];
      if (
        !state$.value.user?.authenticated?.companyName &&
        validateSubscriptionFulfilledAction.response.status === ValidateSubscriptionPairingResponse.StatusEnum.PAIRED
      ) {
        actionArrays.push(authValidate());
      }
      // After pairing, load subscriptions
      actionArrays.push(getEmployeeSubscriptions(SubscriptionCategory.VOICE));
      actionArrays.push(getEmployeeSubscriptions(SubscriptionCategory.BROADBAND));
      actionArrays.push(getEmployeeSubscriptions(SubscriptionCategory.DEVICE));
      return actionArrays;
    })
  );

export const employeeSubscriptionPairingEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> =
  combineEpics(processSubscriptionPairingEpic, validateSubscriptionEpic, completeValidateSubscriptionsEpic);
