import * as CL from '@design-system/component-library';
import { Controller, useFormContext } from 'react-hook-form';
import { CustomFormType, updatePrices } from '../CustomFormFields.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { fieldCantBeEmptyMsg, t } from '../../../common/i18n/index.js';
import { formatSum } from '../../../common/utils/priceUtils.js';

export interface CustomFormRadioItem {
  label: string;
  value: string;
  isDefault?: boolean;
  monthlyRecurringCharge?: number;
  oneTimeCharge?: number;
}

interface CustomFormRadioProps {
  title: string;
  options: CustomFormRadioItem[];
}

const priceSummary = (item: CustomFormRadioItem) => {
  return item.monthlyRecurringCharge
    ? `${formatSum(item.monthlyRecurringCharge)}/${t.XXVX('month')}`
    : formatSum(item.oneTimeCharge);
};

export const CustomFormRadio = (props: CustomFormRadioProps) => {
  const { title, options } = props;
  const { setValue, getValues, control } = useFormContext();
  const defaultOption = options.find(item => item.isDefault) || undefined;

  const updatePrice = (item: CustomFormRadioItem) => {
    const periodicPriceMap = getValues('periodicPriceMap');
    const onetimePriceMap = getValues('onetimePriceMap');
    if (item.monthlyRecurringCharge) {
      periodicPriceMap.set(CustomFormType.OPTION, item.monthlyRecurringCharge);
    }
    if (item.oneTimeCharge) {
      onetimePriceMap.set(CustomFormType.OPTION, item.oneTimeCharge);
    }
    updatePrices(setValue, periodicPriceMap, onetimePriceMap);
  };

  const name = CustomFormType.OPTION;
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultOption?.value}
      rules={{ required: { value: true, message: t.VPVR(fieldCantBeEmptyMsg) } }}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const errorProps = error ? { className: dsClass.COLOR_RED_600 } : {};
        return (
          <>
            <CL.RadioGroup label={title} onChange={onChange} value={value} {...errorProps}>
              {options.map((item, index) => (
                <CL.RadioV2
                  className="of-custom-form-radio"
                  id={`${name}-${index}`}
                  key={`${name}-${index}`}
                  onChange={() => updatePrice(item)}
                  value={item.value}
                >
                  {item.label}
                  <span className="price-summary">{priceSummary(item)}</span>
                </CL.RadioV2>
              ))}
            </CL.RadioGroup>
            {error && <CL.InputError>{error?.message}</CL.InputError>}
          </>
        );
      }}
    />
  );
};
