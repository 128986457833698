export enum ProductType {
  SUBSCRIPTION = 'SUBSCRIPTION',
  DEVICE = 'DEVICE',
  PRODUCT = 'PRODUCT',
}

export enum AdditionalFieldType {
  // "Primitive" types
  STRING = 'STRING',
  EMAIL = 'EMAIL',
  PHONE_NUMBER = 'PHONE_NUMBER',
  // Complex types
  NUMBER_PRIVACY_AND_SIM_CARD_SELECTION_FOR_NEW_PHONE_NUMBER = 'NUMBER_PRIVACY_AND_SIM_CARD_SELECTION_FOR_NEW_PHONE_NUMBER',
  NUMBER_PRIVACY_AND_SIM_CARD_SELECTION_FOR_EXISTING_PHONE_NUMBER = 'NUMBER_PRIVACY_AND_SIM_CARD_SELECTION_FOR_EXISTING_PHONE_NUMBER',
  SIM_CARD_SELECTION = 'SIM_CARD_SELECTION',
  NUMBER_AND_NUMBER_PRIVACY_AND_SIM_CARD_SELECTION = 'NUMBER_AND_NUMBER_PRIVACY_AND_SIM_CARD_SELECTION',
}
