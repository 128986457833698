import * as CL from '@design-system/component-library';
import { dsClass } from '../../../common/constants/dsClasses.js';
import type { ReactNode } from 'react';

export interface InfoFieldProps {
  name?: string;
  label: string;
  toolTipText?: string;
  highlight?: boolean;
  value: ReactNode;
}

import '../BillingAccountDetails.scss';

export const InfoFieldWithTooltip = ({ label, toolTipText, value, name, highlight }: InfoFieldProps) => {
  return (
    <div className={dsClass.PADDING_BOTTOM_2} id={name}>
      <label className={dsClass.INPUT_LABELAREA_LABEL}>{label}</label>
      {toolTipText && (
        <span className={`${dsClass.PADDING_LEFT_2} of-billing-account-details tooltip`}>
          <CL.Tooltip
            triggerElement={<CL.Icon color="brand-blue" icon="information" size="s" />}
            placement="top"
            i18n_tooltip_contentText={toolTipText}
            className={dsClass.MARGIN_LEFT_3}
          />
        </span>
      )}
      <div className="of-info-field__value">
        <span className={highlight ? 'of-info-field__highlight of-info-field__highlight-text' : ''}>
          {value ?? '—'}
        </span>
        {highlight && <CL.Icon color="warning" className="of-info-field__highlight" icon="check" />}
      </div>
    </div>
  );
};
