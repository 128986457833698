import { isSquareTradeAddOn } from '../../common/utils/addOnUtils.js';
import { userHasEmptyOrSMEPriceGroup } from '../../common/utils/employeeUtils.js';
import type { ActionsHistory } from '../../selfservice/common/store.js';
import type { AuthenticatedUserState } from '../../common/types/states.js';
import type { ShoppingCartItemForCheckout } from '../../common/types/checkout.js';

export function hasCartForbiddenSquareTradeAddOn(
  cartItems: ShoppingCartItemForCheckout[],
  user?: AuthenticatedUserState & ActionsHistory
): boolean {
  if (userHasEmptyOrSMEPriceGroup(user?.segmentPricingGroup)) {
    return false;
  }

  return cartItems?.some(ci => ci.selectedAddOns?.some(isSquareTradeAddOn) ?? false) ?? false;
}
