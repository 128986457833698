import * as CL from '@design-system/component-library';
import { EppSolutionResponse } from '../../../generated/api/models.js';
import { eppNotAvailableMsg, productHasBeenDiscontinuedMsg, t } from '../../../common/i18n/index.js';

export interface ProductDetailsDiscontinuedProps {
  isAvailable: boolean;
  eppSolutionStatus: string;
  offersWithCommercialProducts: boolean;
}

export const ProductDetailsDiscontinued = ({
  isAvailable,
  eppSolutionStatus,
  offersWithCommercialProducts,
}: ProductDetailsDiscontinuedProps) => {
  if (isAvailable) {
    return null;
  }
  const notificationMessage =
    eppSolutionStatus === EppSolutionResponse.EppSolutionStatusEnum.ACTIVE && offersWithCommercialProducts
      ? t.H0EZ(eppNotAvailableMsg)
      : t.DYMN(productHasBeenDiscontinuedMsg);

  return <CL.Notification hasCloseButton={false} text={notificationMessage} type="info" visible />;
};
