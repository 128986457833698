import * as CL from '@design-system/component-library';
import { useLocation } from 'react-router-dom';
import type { AccordionItemProps, AccordionProps } from '@design-system/component-library';

import './LinkableAccordion.scss';

export type LinkableAccordionProps = AccordionProps & AccordionItemProps;

function isAccordionElementOpen(hash?: string, id?: string, defaultOpen?: boolean): boolean | undefined {
  if (defaultOpen) {
    return true;
  }
  if (id !== undefined && hash) {
    return hash.includes(`${id}`);
  }
  return undefined;
}

export const LinkableAccordion = ({ className, onOpen, onClose, ...rest }: LinkableAccordionProps) => {
  const { hash } = useLocation();

  return (
    <CL.Accordion
      headingLevel={rest.headingLevel}
      className={`of-linkable-accordion ${className || ''}`}
      onOpen={onOpen}
      onClose={onClose}
    >
      <CL.AccordionItem {...rest} defaultOpen={isAccordionElementOpen(hash, rest.id, rest.defaultOpen)} />
    </CL.Accordion>
  );
};
