import { englishMsg, finnishMsg, swedishMsg, t } from '../i18n/index.js';

export const getLanguageOptions = (): { displayValue: string; value: string }[] => {
  return [
    { displayValue: t.EXXX(finnishMsg), value: 'FI' },
    { displayValue: t.CYY2(swedishMsg), value: 'SV' },
    { displayValue: t.R1PB(englishMsg), value: 'EN' },
  ];
};

export const getLanguageDisplayValue = (languageValue: string): string => {
  return getLanguageOptions().find(l => l.value.toLowerCase() === languageValue.toLowerCase())?.displayValue ?? '';
};
