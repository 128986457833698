/**
 * Online UI API
 * # Summary Elisa B2B Online UI API  # Usage This API is used in the `https://yrityksille.elisa.fi/` pages.  # Identifiers 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CreditCheckRequestData } from './creditCheckRequestData.js';
import { OnlineOrderItem } from './onlineOrderItem.js';
import { SalesProductOrderItem } from './salesProductOrderItem.js';
import { DeliveryAddress } from './deliveryAddress.js';


export interface OnlineOrder { 
    /**
     * Request Id generated by the originating system. Online_Order__c.Online_Order_ID__c
     */
    onlineRequestId: string;
    /**
     * Internal, unique id of the billing account. absent in case of card payment. SFDC: Online_Order__c.BA_Number__c.
     */
    billingAccountId?: string;
    /**
     * Internal, unique id of the person billing account in case of omalaitelasku order. SFDC: Online_Order__c.Person_BA_Number__c.
     */
    personBillingAccountId?: string;
    deliveryAddress: DeliveryAddress;
    /**
     * Total monthly recurring charge for the order in cents. SFDC: Online_Order__c.Json_Payload__c. NOTE: Not the same meaning as the field with the same name in CustomerOrder. This field documents the total monthly infinitely recurring charges of the first monthly payment.
     */
    totalMonthlyRecurringCharge: number;
    /**
     * Total one-time charge for the order in cents. SFDC: Online_Order__c.Json_Payload__c.
     */
    totalOneTimeCharge: number;
    /**
     * Total Vat for total one-time charge for the order in cents. SFDC: Online_Order__c.Json_Payload__c.
     */
    totalOneTimeChargeVat: number;
    /**
     * Total charge in cents for order items with fixed term contract = sum of (quantity * recurring charge * payments) for all order items with fixed term contract. SFDC: Online_Order__c.Json_Payload__c.
     */
    totalFixedTermCharge: number;
    /**
     * Total charge in cents for corporate customers = total one time charge + total fixed term charge for all devices in order - fixed term employee share for EPP items
     */
    totalChargeForCorporateCustomer?: number;
    orderItems?: Array<OnlineOrderItem>;
    salesProductsOrderItems?: Array<SalesProductOrderItem>;
    /**
     * Id of the contact person, who is choosen as an approver for this order, However any admin in the account can approve the order. SFDC: Online_Order__c.Approver_Contact__c
     */
    approverContact?: string;
    /**
     * Catalog GUID to which the products in the basket belong when employee orders. SFDC: Online_Order__c.Catalog_GUID__c
     */
    catalogCode?: string;
    /**
     * Id that\'s used to identify sent shopping-cart, that\'s marked as bought
     */
    shoppingCartId?: string;
    /**
     * Reference number associated to card payment of this order. When present, invoice related field i.e. billingAccountId and personBillingAccountId will be absent. SFDC: Online_Order__c.Card_Payment_Reference_Number__c
     */
    cardPaymentReferenceNumber?: string;
    /**
     * Whether user has selected total or partial delivery of items in the order. SFDC: Online_Order__c.Shipment_Type__c
     */
    shipmentType?: OnlineOrder.ShipmentTypeEnum;
    creditCheckRequestData?: CreditCheckRequestData;
    /**
     * Order Number in customer\'s organization. Online_Order__c.External_Order_Reference__c
     */
    externalOrderReference?: string;
}
export namespace OnlineOrder {
    export type ShipmentTypeEnum = 'TOTAL_DELIVERY' | 'PARTIAL_DELIVERY';
    export const ShipmentTypeEnum = {
        TOTAL_DELIVERY: 'TOTAL_DELIVERY' as ShipmentTypeEnum,
        PARTIAL_DELIVERY: 'PARTIAL_DELIVERY' as ShipmentTypeEnum
    };
}


