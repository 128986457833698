import { EppPendingRedeemDisclaimer } from './EppPendingRedeemDisclaimer.js';
import { SubscriptionDetailsDevice } from '../../../generated/api/subscriptionDetailsDevice.js';
import type { Subscription } from '../../../generated/api/subscription.js';
import ReplacementActionEnum = SubscriptionDetailsDevice.ReplacementActionEnum;

interface EppPendingRedeemDisclaimersProps {
  deviceSubscriptions?: Subscription[];
}

export const EppPendingRedeemDisclaimers = (props: EppPendingRedeemDisclaimersProps) => {
  const redeemedDeviceSubscriptions =
    props?.deviceSubscriptions?.filter(s => s.details?.device?.replacementAction === ReplacementActionEnum.REDEEM) ??
    [];

  return (
    <>
      {redeemedDeviceSubscriptions.map(subscription => (
        <EppPendingRedeemDisclaimer key={subscription.subscriptionId} subscription={subscription} />
      ))}
    </>
  );
};
