import { MobileM2MAddonCode, SubscriptionCardType } from '../../../common/enums.js';
import {
  downloadSpeed,
  euDataMsg,
  gBMoMsg,
  kBitsPerSecondMsg,
  mBitsPerSecondMsg,
  numberGSubscriptionsMsg,
  selectMsg,
  t,
  uploadSpeedMsg,
} from '../../../common/i18n/index.js';
import type { MobileBroadbandSubCardProps, SubCardProps } from '../../SubscriptionCard/SubscriptionCard.js';

export const SUBSCRIPTION_CHANGE_SERVICE_FEE = 399;

export const MOBILE_M2M_OFFER_CODE = '9be7de49-3191-b932-127e-811c67ae245f';
const MOBILE_M2M_OFFER_CODE_5G = 'b1ff488a-9672-22c0-f9db-bcd7ec64a4ca';

export const mobileM2MSubscriptionCards4G = (): MobileBroadbandSubCardProps[] => [
  {
    cardName: 'S',
    description: t.A7CC('Sending photos, SMS/voice-controlled devices, property alarms, air source heat pumps'),
    groupHeader: t.J1JB(numberGSubscriptionsMsg, '4'),
    mainHeader: 'Laitenetti S',
    salesProduct: {
      offerCode: '53979407-4c00-5195-ef4e-717b5be43d75',
      onlineModelCode: '2f69704b-a67a-4689-9f3f-403774334eb4',
    },
    selectedAddon: MobileM2MAddonCode.S,
    selectedOffer: MOBILE_M2M_OFFER_CODE,
    services: [
      {
        name: 'Internet',
        guid: MobileM2MAddonCode.S,
        isIncluded: true,
        hidden: true,
      },
    ],
    showContentSeparators: false,
    specifications: [
      {
        name: t.R4O4(downloadSpeed),
        value: { [MOBILE_M2M_OFFER_CODE]: t.B69Q(kBitsPerSecondMsg, '256') },
      },
      {
        name: t.CQQ7(uploadSpeedMsg),
        value: { [MOBILE_M2M_OFFER_CODE]: t.B69Q(kBitsPerSecondMsg, '256') },
      },
      {
        name: euDataMsg,
        value: { [MOBILE_M2M_OFFER_CODE]: `5,5 ${t.U54O(gBMoMsg)}` },
      },
    ],
  },
  {
    cardName: 'M',
    description: t.A7CD(
      'For surveillance and trail cameras (photos and videos), for alarm devices, for GPS trackers, for condition monitoring sensors'
    ),
    groupHeader: t.J1JB(numberGSubscriptionsMsg, '4'),
    mainHeader: 'Laitenetti M',
    salesProduct: {
      offerCode: 'cb570d44-d1d9-b675-8e5e-977d8ba49a2f',
      onlineModelCode: 'cfbeaf16-587f-49ef-12c5-e7b6114c72a4',
    },
    selectedAddon: MobileM2MAddonCode.M,
    selectedOffer: MOBILE_M2M_OFFER_CODE,
    services: [
      {
        name: 'Internet',
        guid: MobileM2MAddonCode.M,
        isIncluded: true,
        hidden: true,
      },
    ],
    showContentSeparators: false,
    specifications: [
      {
        name: t.R4O4(downloadSpeed),
        value: { [MOBILE_M2M_OFFER_CODE]: t.B69Q(kBitsPerSecondMsg, '256') },
      },
      {
        name: t.CQQ7(uploadSpeedMsg),
        value: { [MOBILE_M2M_OFFER_CODE]: t.IJNS(mBitsPerSecondMsg, '5') },
      },
      {
        name: euDataMsg,
        value: { [MOBILE_M2M_OFFER_CODE]: `7,8 ${t.U54O(gBMoMsg)}` },
      },
    ],
  },
  {
    cardName: 'L',
    description: t.A7CE(
      'For surveillance and trail cameras (live video), for mobile routers, for network devices, for alarm devices'
    ),
    groupHeader: t.J1JB(numberGSubscriptionsMsg, '4'),
    mainHeader: 'Laitenetti L',
    salesProduct: {
      offerCode: '3fa28038-e040-dedc-ca6f-8410ea2bf0c1',
      onlineModelCode: 'b5803f86-2f04-b632-c0d6-3375830b5d2b',
    },
    selectedAddon: MobileM2MAddonCode.L,
    selectedOffer: MOBILE_M2M_OFFER_CODE,
    services: [
      {
        name: 'Internet',
        guid: MobileM2MAddonCode.L,
        isIncluded: true,
        hidden: true,
      },
    ],
    showContentSeparators: false,
    specifications: [
      {
        name: t.R4O4(downloadSpeed),
        value: { [MOBILE_M2M_OFFER_CODE]: t.B69Q(kBitsPerSecondMsg, '512') },
      },
      {
        name: t.CQQ7(uploadSpeedMsg),
        value: { [MOBILE_M2M_OFFER_CODE]: t.IJNS(mBitsPerSecondMsg, '10') },
      },
      {
        name: euDataMsg,
        value: { [MOBILE_M2M_OFFER_CODE]: `11 ${t.U54O(gBMoMsg)}` },
      },
    ],
  },
];

export const mobileM2MSubscriptionCards5G = (): MobileBroadbandSubCardProps[] => [
  {
    cardName: '5G',
    description: t.JQD9(
      'For uploading high quality live video. Allows you to benefit from new technological innovations and devices, as well as IOT applications and software.'
    ),
    groupHeader: t.J1JB(numberGSubscriptionsMsg, '5'),
    mainHeader: 'Laitenetti 5G',
    salesProduct: {
      offerCode: '1c7bc1d8-a299-1f3f-48b1-dc79420fa7d8',
      onlineModelCode: '732200f9-f6bd-5de7-32d8-733e9b61864e',
    },
    selectedAddon: MobileM2MAddonCode.fiveG,
    selectedOffer: MOBILE_M2M_OFFER_CODE_5G,
    services: [
      {
        name: 'Internet',
        guid: MobileM2MAddonCode.fiveG,
        isIncluded: true,
        hidden: true,
      },
    ],
    showContentSeparators: false,
    specifications: [
      {
        name: t.R4O4(downloadSpeed),
        value: { [MOBILE_M2M_OFFER_CODE_5G]: t.IJNS(mBitsPerSecondMsg, '50') },
      },
      {
        name: t.CQQ7(uploadSpeedMsg),
        value: { [MOBILE_M2M_OFFER_CODE_5G]: t.IJNS(mBitsPerSecondMsg, '100') },
      },
      {
        name: euDataMsg,
        value: { [MOBILE_M2M_OFFER_CODE_5G]: `28 ${t.U54O(gBMoMsg)}` },
      },
    ],
  },
];

export const getMobileM2MCardContents4G = (isChangeOffer?: boolean): SubCardProps[] =>
  mobileM2MSubscriptionCards4G().map(cardContent => ({
    ...cardContent,
    name: '',
    serviceFee: isChangeOffer ? SUBSCRIPTION_CHANGE_SERVICE_FEE : undefined,
    buttonText: t.QRYV(selectMsg),
    cardType: SubscriptionCardType.MOBILE_M2M,
  }));

export const getMobileM2MCardContents5G = (isChangeOffer?: boolean): SubCardProps[] =>
  mobileM2MSubscriptionCards5G().map(cardContent => ({
    ...cardContent,
    name: '',
    serviceFee: isChangeOffer ? SUBSCRIPTION_CHANGE_SERVICE_FEE : undefined,
    buttonText: t.QRYV(selectMsg),
    cardType: SubscriptionCardType.MOBILE_M2M,
  }));
