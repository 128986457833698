/**
 * SFDC Anonymous API
 * # Summary Elisa B2B API for an anonymous access to salesforce data.  # Usage This API is used in the `https://yrityksille.elisa.fi/omaelisa` pages, but it is possible to later on expose this documentation publicly and make the endpoint queryable via external clients. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * High level categorization of commercial product. MOBILE_PBX_SERVICE_LEVEL mapped to End User Service in SFDC. E.g. MOBILE. SFDC: cspmb__Price_Item__c.cspmb__Product_Definition_Name__c
 */
export type CommercialProductType = 'MOBILE' | 'HANDHELD_DEVICE' | 'MOBILE_PBX_SERVICE_LEVEL' | 'SALES_PRODUCT' | 'FIXED_BROADBAND';

export const CommercialProductType = {
    MOBILE: 'MOBILE' as CommercialProductType,
    HANDHELD_DEVICE: 'HANDHELD_DEVICE' as CommercialProductType,
    MOBILE_PBX_SERVICE_LEVEL: 'MOBILE_PBX_SERVICE_LEVEL' as CommercialProductType,
    SALES_PRODUCT: 'SALES_PRODUCT' as CommercialProductType,
    FIXED_BROADBAND: 'FIXED_BROADBAND' as CommercialProductType
};

