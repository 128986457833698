import { CommercialProductType, OnlineModelCategory } from '../../generated/api/models.js';
import type { AddedAddon } from '../types/addon.js';
import type { ConfiguredCommercialProduct } from '../types/commercialProduct.js';
import type { OnlineModel } from '../../generated/api/models.js';
import type { ShoppingCartAddOn, ShoppingCartItemForCheckout } from '../types/checkout.js';

export const LEGACY_SALES_PRODUCT_NAME = 'Sales Product';

export const enum Category {
  SALES_PRODUCT = 'Sales Product',
  PHONE = 'Phone',
  SELF_SERVICE = 'SELF_SERVICE',
}

const getCommercialProductType = (category: string): CommercialProductType => {
  switch (category) {
    case Category.PHONE:
      return CommercialProductType.HANDHELD_DEVICE;
    case Category.SALES_PRODUCT:
      return CommercialProductType.SALES_PRODUCT;
    default:
      return CommercialProductType.HANDHELD_DEVICE;
  }
};

// For some reason we don't have Elisa as the manufacturer for sub products...
//   we still want to get 'Elisa' for analytics.
export const getManufacturer = (onlineModel: OnlineModel, commercialProductName: string): string | undefined => {
  const { category, manufacturer, onlineModelName } = onlineModel;
  if (
    (manufacturer == null || manufacturer === 'N/A') &&
    category === CommercialProductType.SALES_PRODUCT &&
    (onlineModelName.startsWith('Elisa') || commercialProductName.startsWith('Elisa'))
  ) {
    return 'Elisa';
  }
  return manufacturer;
};

export const physicalProducts = [
  OnlineModelCategory.PHONE,
  OnlineModelCategory.TABLET,
  OnlineModelCategory.COMPUTERS,
  OnlineModelCategory.NETWORK_EQUIPMENT,
  OnlineModelCategory.ACCESSORIES,
];

// CommercialProducts here is two-dimensional array. One corresponds to OrderItems, and other to orderItemUnit (quantity of a OrderItem).
export const getCommercialProducts = (
  cartItems: Array<ShoppingCartItemForCheckout>
): ConfiguredCommercialProduct[][] => {
  return cartItems.map(
    ({
      addOns,
      selectedAddOns,
      category,
      commercialProductCodes,
      productId,
      manufacturer,
      numberPublicities,
      phoneNumberOwners,
      price,
      productName,
      purposeOfUseOrContacts,
      quantity,
      selectedPhoneNumbers,
      simCardConfigurations,
      enrollmentProgramConsents,
      additionalFieldValues,
      enrollmentProgramAliases,
    }: ShoppingCartItemForCheckout) =>
      Array(quantity)
        .fill({
          addedAddOns: selectedAddOns.map((addOn: ShoppingCartAddOn): AddedAddon => {
            return {
              display: addOn.display,
              addOnCode: addOn.addOnCode,
              addOnAssociationCode: addOn.addOnAssociationCode,
              displayName: addOn.addOnProductName,
              oneTimePrice: addOn.addOnOneTimeCharge,
              monthlyPrice: addOn.addOnMonthlyRecurringCharge,
            };
          }),
          commercialProduct: {
            active: true,
            addOnAssociations: addOns,
            commercialProductCode: commercialProductCodes[0],
            commercialProductName: productName,
            monthlyRecurringCharge: price.periodic && price.periodic.price,
            oneTimeCharge: price.onetime && price.onetime.price,
            payments: price.periodic && price.periodic.payments,
            productType: getCommercialProductType(category),
            billingPeriod: price.periodic?.billingPeriod,
          },
          commercialProductCodes,
        })
        .map((configuredCommercialProduct, index) => {
          return {
            ...configuredCommercialProduct,
            productId,
            manufacturer,
            category,
            purposeOfUseOrContact: purposeOfUseOrContacts[index],
            numberPublicity: numberPublicities[index],
            simCardConfiguration: simCardConfigurations[index],
            selectedPhoneNumber: selectedPhoneNumbers[index],
            phoneNumberOwner: phoneNumberOwners[index],
            enrollmentProgramConsent:
              enrollmentProgramConsents !== undefined ? enrollmentProgramConsents[index] : undefined,
            additionalFields: additionalFieldValues[index],
            enrollmentProgramAlias:
              enrollmentProgramAliases !== undefined ? enrollmentProgramAliases[index] : undefined,
          };
        })
  );
};

export const getConfiguredCommercialProducts = (cartItems: Array<ShoppingCartItemForCheckout>) =>
  getCommercialProducts(cartItems).reduce((x, y) => x.concat(y), []);

export const isRetainNumberProduct = (productName: string): boolean => {
  return productName !== undefined && productName.includes('säilytä');
};
