import * as CL from '@design-system/component-library';
import { PopoverContent } from './CommercialProductContent.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { t } from '../../../common/i18n/index.js';

const PublicPageMobiiliturvaPopoverContent = () => (
  <div>
    {t.QAMU(
      'Elisa Mobiiliturva yrityksille is a new kind of data security service for mobile subscriptions. The service identifies malicious websites and gives you digital security when using a mobile subscription.'
    )}
  </div>
);

const NoePageMobiiliturvaPopoverContent = () => (
  <>
    <div>
      <b>Elisa Mobiiliturva yrityksille </b>
      {t.QAMW(
        'is a new kind of data security service which improves the browser security of mobile subscriptions. The service identifies malicious websites automatically and notifies the user.'
      )}
    </div>
    <div className={dsClass.MARGIN_TOP_2}>
      {t.C0WF(
        'You will have digital security against the most common data security threat, phishing, when using 4G or 5G Internet.'
      )}
    </div>
    <div className={dsClass.MARGIN_TOP_2}>
      <CL.Link linkHref="https://yrityksille.elisa.fi/mobiiliturva" linkTarget="_blank">
        {t.R2NG('Read more {} services', 'Elisa Mobiiliturva yrityksille')}
      </CL.Link>
    </div>
  </>
);

export const MobiiliturvaPopoverContent = ({ inPublicStore }: { inPublicStore?: boolean }) => (
  <PopoverContent>
    {inPublicStore ? <PublicPageMobiiliturvaPopoverContent /> : <NoePageMobiiliturvaPopoverContent />}
  </PopoverContent>
);
