import { formatSum, formatSumToString } from '../../common/utils/priceUtils.js';
import { getCommercialProductPrices } from '../../common/utils/commercialProductUtils.js';
import { getVatAsString } from '../../common/utils/taxUtils.js';
import { isCampaignPresent } from '../../common/utils/campaignUtils.js';
import { monthsMsg, pcsMsg, t, totalMsg } from '../../common/i18n/index.js';
import type { AddedAddon } from '../../common/types/addon.js';
import type { CampaignAssociation, CommercialProduct, SalesType } from '../../generated/api/models.js';
import type { ConfiguredCommercialProduct } from '../../common/types/commercialProduct.js';
import type { TotalSumsItem } from '../../common/utils/commercialProductUtils.js';

export type RowItem = {
  amount: string | JSX.Element;
  className?: string;
  key: string;
  monthlyCharge: string | JSX.Element;
  name: string | JSX.Element;
  onetimeCharge: string | JSX.Element;
};
export const getTotalRows = (
  totalSums: TotalSumsItem,
  priceIncludesVAT?: boolean,
  orderContainsBillingPeriodItem = false
): RowItem[] => {
  const { totalMonthlyRecurringCharge, totalMonthlyRecurringChargeVat, totalOneTimeCharge, totalOneTimeChargeVat } =
    totalSums;
  const vatAsString = getVatAsString();
  const monthlyCharge = orderContainsBillingPeriodItem
    ? formatSumToString(totalMonthlyRecurringCharge)
    : t.YO7F('{}/Month', formatSumToString(totalMonthlyRecurringCharge));

  const totalVat = orderContainsBillingPeriodItem
    ? formatSumToString(totalMonthlyRecurringChargeVat)
    : t.YO7F('{}/Month', formatSumToString(totalMonthlyRecurringChargeVat));

  const totalIncludingVat = orderContainsBillingPeriodItem
    ? formatSumToString(totalMonthlyRecurringChargeVat + totalMonthlyRecurringCharge)
    : t.YO7F('{}/Month', formatSumToString(totalMonthlyRecurringChargeVat + totalMonthlyRecurringCharge));

  const totalItem = {
    className: 'of-detailed-summary-table__total-row-item of-detailed-summary-table__total-row-header',
    key: 'total',
    name: (
      <strong>
        {t.CEQ2(totalMsg)} {t.E4TT('(VAT {}%)', priceIncludesVAT ? vatAsString : '0')}
      </strong>
    ),
    amount: '',
    onetimeCharge: <strong>{formatSum(totalOneTimeCharge) || ''}</strong>,
    monthlyCharge: <strong>{monthlyCharge}</strong>,
  };

  if (priceIncludesVAT) {
    return [totalItem];
  }

  return [
    totalItem,
    {
      className: 'of-detailed-summary-table__total-row-item',
      key: 'total-alv',
      name: t.A0OJ('Vat {}%', vatAsString),
      amount: '',
      onetimeCharge: formatSum(totalOneTimeChargeVat) || '',
      monthlyCharge: totalVat,
    },
    {
      className: 'of-detailed-summary-table__total-row-item',
      key: 'total-with-alv',
      name: t.YXMI('Price including taxes'),
      amount: '',
      onetimeCharge: formatSum(totalOneTimeCharge + totalOneTimeChargeVat) || '',
      monthlyCharge: totalIncludingVat,
    },
  ];
};

export const getCommercialProductRowItem = (
  commercialProduct: ConfiguredCommercialProduct,
  amount: number
): RowItem => {
  const billingPeriod = commercialProduct.commercialProduct.billingPeriod;
  const priceSuffix = billingPeriod ? `/${billingPeriod} ${t.XXVX(monthsMsg)}` : '';
  const monthlyCharge = commercialProduct.commercialProduct.monthlyRecurringCharge
    ? `${formatSum(commercialProduct.commercialProduct.monthlyRecurringCharge * amount)}${priceSuffix}`
    : '';
  const onetimeCharge = commercialProduct.commercialProduct.oneTimeCharge
    ? `${formatSum(commercialProduct.commercialProduct.oneTimeCharge * amount)}`
    : '';

  return {
    key: commercialProduct.commercialProduct.commercialProductCode,
    name: commercialProduct.commercialProduct.commercialProductName,
    amount: `${amount} ${t.B3MG(pcsMsg)}`,
    onetimeCharge,
    monthlyCharge,
  };
};

export const getCampaignDiscountRowItem = (
  commercialProduct: CommercialProduct,
  salesType: SalesType,
  campaignDescription?: string,
  campaignAssociation?: CampaignAssociation
): RowItem | undefined => {
  if (!campaignAssociation && !isCampaignPresent(commercialProduct.campaignAssociations)) {
    return undefined;
  }

  const { oneTimeCharge, monthlyRecurringCharge, oneTimeChargeBeforeDiscount, monthlyRecurringChargeBeforeDiscount } =
    getCommercialProductPrices(commercialProduct, campaignAssociation, salesType);

  const onetimeDiscount =
    oneTimeCharge && oneTimeChargeBeforeDiscount ? `-${formatSum(oneTimeChargeBeforeDiscount - oneTimeCharge)}` : '';
  const monthlyDiscount =
    monthlyRecurringCharge && monthlyRecurringChargeBeforeDiscount
      ? `-${formatSum(monthlyRecurringChargeBeforeDiscount - monthlyRecurringCharge)}`
      : '';

  return {
    key: `${commercialProduct.commercialProductCode}-${campaignDescription}`,
    name: campaignDescription || '',
    amount: `1 ${t.B3MG(pcsMsg)}`,
    onetimeCharge: onetimeDiscount,
    monthlyCharge: monthlyDiscount,
  };
};

export const getAddedAddonRowItem = (
  commercialProductCode: string,
  addedAddon: AddedAddon,
  amount: number
): RowItem => ({
  key: `${commercialProductCode}-${addedAddon.addOnCode}`,
  name: addedAddon.displayName,
  amount: `${amount} ${t.B3MG(pcsMsg)}`,
  onetimeCharge: formatSum(addedAddon.oneTimePrice ? addedAddon.oneTimePrice * amount : undefined) || '',
  monthlyCharge: formatSum(addedAddon.monthlyPrice ? addedAddon.monthlyPrice * amount : undefined) || '',
});

export const getDeliveryChargesRow = (deliveryCharges?: number, hideZeroDeliveryCharges?: boolean): RowItem[] => {
  if (hideZeroDeliveryCharges && !deliveryCharges) {
    return [];
  }

  return [
    {
      key: 'delivery-charges',
      name: t.PFDS('Delivery charges'),
      amount: '',
      onetimeCharge: formatSumToString(deliveryCharges || 0),
      monthlyCharge: '',
    },
  ];
};
