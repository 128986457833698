import { dsClass } from '../../common/constants/dsClasses';

interface TableDescriptionProps {
  className?: string;
  description?: React.ReactNode;
  title?: React.ReactNode;
}

export const TableDescription = ({ className, description, title }: TableDescriptionProps) => {
  return (
    <div className={`of-table-description ${className ?? ''}`}>
      {title && <div className={dsClass.TEXT_S}>{title}</div>}
      {description && <div className={dsClass.TEXT_XS}>{description}</div>}
    </div>
  );
};
