import * as CL from '@design-system/component-library';
import * as React from 'react';
import { Anchor } from '../Anchor/Anchor.js';
import { LinkableAccordion } from '../LinkableAccordion/index.js';
import { OnlineModelCategory } from '../../generated/api/models.js';
import {
  addFilterMsg,
  brandMsg,
  emptyFiltersMsg,
  fiveGCompatibilityMsg,
  offerProductsMsg,
  offersMsg,
  osMsg,
  overMsg,
  priceMsg,
  t,
  underMsg,
  viewAllMsg,
} from '../../common/i18n/index.js';
import { desktopItemWidth } from '../../common/utils/gridUtils.js';
import { onEnterOrSpaceKeyPress } from '../../common/utils/handlerUtils.js';
import type { ProductGridItem } from './ProductGrid.js';
import type { Range } from '../../generated/api/models.js';

export enum FilterType {
  FILTER_MANUFACTURER = 'FILTER_MANUFACTURER',
  FILTER_COST = 'FILTER_COST',
  FILTER_OS_PHONES = 'FILTER_OS_PHONES',
  FILTER_OS_COMPUTERS = 'FILTER_OS_COMPUTERS',
  FILTER_5G = 'FILTER_5G',
  FILTER_OFFERS = 'FILTER_OFFERS',
}

export const FIVE_G_KEY = '5G';
const OS_PHONES = ['Android', 'iOS'];
const OS_COMPUTERS = ['Windows', 'macOS'];

export type Filters = { [key in FilterType]: Filter };

type Filter = {
  displayText: () => string;
  options: Array<{ value: () => string; label?: string }>;
  filterFn: (item: ProductGridItem, selectedOptions: string[]) => boolean;
  selectedOptions: string[];
  visible: boolean;
  hasTooManyOptions?: boolean;
};

const PRICE_FILTER_RANGE_SIZE = 300;
const PRICE_FILTER_MAX_OPTIONS = 5;
const MAX_DEFAULT_VISIBLE_OPTIONS = 8;

export const hasActiveFilters = (filters: Filters) => {
  return Object.values(filters)
    .map(f => f.visible)
    .some(e => e);
};

const getRange = (range: string): Required<Range> => {
  const [left, right] = range.split('-');
  return {
    lower: parseInt(left, 10),
    upper: parseInt(right, 10),
  };
};

const getTotalPrice = ({ monthlyRecurringCharge, oneTimeCharge, payments }: ProductGridItem) => {
  return monthlyRecurringCharge !== undefined && payments !== undefined
    ? monthlyRecurringCharge * payments
    : oneTimeCharge;
};

const roundToHundredEuros = (priceInCents: number) => Math.round((priceInCents + 10000) / 10000) * 100;

const createPriceRange = (products: ProductGridItem[], rangeSize: number): Range[] => {
  const prices = products
    .map(getTotalPrice)
    .filter(price => price !== undefined)
    .map(price => price!);
  const minPrice = Math.min(...prices);
  const maxPrice = Math.max(...prices);
  const priceRanges: Range[] = [{ lower: 0, upper: roundToHundredEuros(minPrice) }];
  for (let i = priceRanges[0].upper!; i * 100 < maxPrice; i += rangeSize) {
    if (priceRanges.length === PRICE_FILTER_MAX_OPTIONS - 1) {
      priceRanges.push({ lower: i, upper: roundToHundredEuros(maxPrice) });
      break;
    }
    priceRanges.push({ lower: i, upper: i + rangeSize - 1 });
  }
  return priceRanges;
};

const getUniqueTags = (products: ProductGridItem[]) => {
  return products.flatMap(item => item.tags).filter((e: string, i: number, a: string[]) => a.indexOf(e) === i);
};

const getExistingTags = (acceptedTags: string[], tags: string[]) => {
  return acceptedTags.filter(a => tags.includes(a)).map(a => ({ value: () => a }));
};

export const createPriceFilterLabel = ({ lower, upper }: Range, i: number, size: number) => {
  if (i === 0) {
    return `${t.A5B3(underMsg)} ${upper} €`;
  }
  if (i + 1 === size) {
    return `${t.JX0H(overMsg)} ${lower} €`;
  }
  return `${lower} - ${upper} €`;
};

export const createRangeOptions = (ranges: Range[]) => {
  return ranges.map(({ lower, upper }, i) => ({
    value: () => `${lower}-${upper}`,
    label: createPriceFilterLabel({ lower, upper }, i, ranges.length),
  }));
};

const filterTagsFn = (pgi: ProductGridItem, sos: string[]) => sos.some(s => pgi.tags.includes(s));
export const filterRangeFn = (item: ProductGridItem, selectedOptions: string[]) => {
  const price = getTotalPrice(item);
  return (
    price !== undefined &&
    selectedOptions
      .map(getRange)
      .some(({ lower, upper }) => (price === 0 && lower === 0) || (price >= lower * 100 && price < upper * 100))
  );
};

export const filterSaleOfferBadge = (item: ProductGridItem) => {
  return item.offerBadge?.type === 'SALE';
};

export const createFilters = (products: ProductGridItem[], category: OnlineModelCategory): Filters => {
  const manufacturers = Array.from(new Set(products.map(product => product.manufacturer))).sort();
  const tags = getUniqueTags(products);
  const mobileOS = getExistingTags(OS_PHONES, tags);
  const desktopOS = getExistingTags(OS_COMPUTERS, tags);
  const fiveG = getExistingTags([FIVE_G_KEY], tags);
  const priceRanges = createPriceRange(
    products,
    PRICE_FILTER_RANGE_SIZE * (category === OnlineModelCategory.COMPUTERS ? 2 : 1)
  );
  const offerProducts = products.filter(filterSaleOfferBadge);

  return {
    FILTER_MANUFACTURER: {
      displayText: () => t.GV78(brandMsg),
      options: manufacturers.map(manufacturer => ({ value: () => manufacturer })),
      selectedOptions: [],
      filterFn: (pgi: ProductGridItem, sos: string[]) => sos.includes(pgi.manufacturer),
      visible: manufacturers.length > 1,
      hasTooManyOptions: manufacturers.length > MAX_DEFAULT_VISIBLE_OPTIONS,
    },
    FILTER_COST: {
      displayText: () => t.V72N(priceMsg),
      options: createRangeOptions(priceRanges),
      selectedOptions: [],
      filterFn: filterRangeFn,
      visible: priceRanges.length > 1,
    },
    FILTER_OS_PHONES: {
      displayText: () => t.P00G(osMsg),
      options: mobileOS,
      selectedOptions: [],
      filterFn: filterTagsFn,
      visible: category === OnlineModelCategory.PHONE && mobileOS.length > 1,
    },
    FILTER_OS_COMPUTERS: {
      displayText: () => t.P00G(osMsg),
      options: desktopOS,
      selectedOptions: [],
      filterFn: filterTagsFn,
      visible: category === OnlineModelCategory.COMPUTERS && desktopOS.length > 1,
    },
    FILTER_5G: {
      displayText: () => t.Y1FD(fiveGCompatibilityMsg),
      options: fiveG,
      selectedOptions: [],
      filterFn: filterTagsFn,
      visible: fiveG.length > 0,
    },
    FILTER_OFFERS: {
      displayText: () => t.I1LH(offersMsg),
      options: [{ value: () => t.TJPR(offerProductsMsg), label: t.TJPR(offerProductsMsg) }],
      selectedOptions: [],
      filterFn: filterSaleOfferBadge,
      visible: offerProducts.length > 0,
    },
    // TODO: Add filter for accessory type. e.g. screen guard, cover, head phones, charger etc.
  };
};

export const filterFn = (filters: Filters) => (item: ProductGridItem) =>
  Object.values(filters).every(f => (f.selectedOptions.length === 0 ? true : f.filterFn(item, f.selectedOptions)));

interface ProductGridFilterHeaderProps {
  value: boolean;
  onChange: (newValue: boolean) => void;
}

export const ProductGridFilterHeader = ({ onChange, value }: ProductGridFilterHeaderProps) => (
  <LinkableAccordion
    heading={t.G188(addFilterMsg)}
    id="product-filter-accordion"
    defaultOpen={value}
    onOpen={() => onChange(!value)}
    onClose={() => onChange(!value)}
  />
);

interface ProductGridFilterStatusProps {
  filters: Filters;
  setFilters: (filters: Filters) => void;
}

export const ProductGridFilterStatus = ({ filters, setFilters }: ProductGridFilterStatusProps) => {
  const amount = Object.values(filters).flatMap(({ selectedOptions }) => selectedOptions).length;
  return (
    <a
      {...(amount > 0
        ? {
            className: 'of-product-grid__filter-status-link',
            onClick: () => {
              setFilters(
                Object.entries(filters)
                  .map(([key, value]) => ({ [key]: { ...value, selectedOptions: [] } as Filter }))
                  .reduce((acc, c) => ({ ...acc, ...c }), {}) as Filters
              );
            },
          }
        : { className: 'of-product-grid__filter-status-link disabled' })}
    >
      <span className="filter-amount">{amount}</span>
      <span className="filter-text">{t.IWVK(emptyFiltersMsg)}</span>
    </a>
  );
};

interface FilterOptionsElementProps extends FilterElementProps {
  filter: [string, Filter];
  showAll: boolean;
}

const FilterOptionsElement = ({ filter, filters, onChange, showAll }: FilterOptionsElementProps) => (
  <>
    {filter[1].options
      .slice(0, showAll ? filter[1].options.length : Math.min(filter[1].options.length, MAX_DEFAULT_VISIBLE_OPTIONS))
      .map(option => (
        <CL.Checkbox
          id={option.value()}
          // TODO: Workaround to force CL.Checkbox to rerender after checked prop update. See https://atlas.elisa.fi/jira/browse/DS-1592.
          key={`${option.value()}-${filter[1].selectedOptions.includes(option.value()) ? 'checked' : 'unchecked'}`}
          value={option.value()}
          checked={filter[1].selectedOptions.includes(option.value())}
          onChange={event => {
            if (event.currentTarget.checked) {
              onChange({
                ...filters,
                [filter[0]]: {
                  ...filter[1],
                  selectedOptions: [...filter[1].selectedOptions, event.currentTarget.value],
                },
              });
            } else {
              onChange({
                ...filters,
                [filter[0]]: {
                  ...filter[1],
                  selectedOptions: filter[1].selectedOptions.filter(
                    selectedOption => selectedOption !== event.currentTarget.value
                  ),
                },
              });
            }
          }}
        >
          {option.label || option.value()}
        </CL.Checkbox>
      ))}
  </>
);

interface FilterElementProps {
  filters: Filters;
  onChange: (filters: Filters) => void;
}

const FiltersElement = ({ filters, onChange }: FilterElementProps) => {
  const [showAll, onShowAll] = React.useState(false);
  return (
    <CL.Grid>
      <CL.GridRow className="of-product-grid-filters">
        {Object.entries(filters)
          .filter(filter => filter[1].visible)
          .map(filter => (
            <CL.GridCol colsXS={4} colsS={4} colsM={6} colsL={desktopItemWidth(4)} key={filter[0]}>
              <fieldset>
                <legend>{filter[1].displayText()}</legend>
                <FilterOptionsElement filter={filter} filters={filters} onChange={onChange} showAll={showAll} />
              </fieldset>
              {filter[1].hasTooManyOptions && !showAll && (
                <Anchor className="of-product-grid__filter-content__show-all" onClick={() => onShowAll(true)}>
                  {t.SF4C(viewAllMsg)}
                </Anchor>
              )}
            </CL.GridCol>
          ))}
      </CL.GridRow>
    </CL.Grid>
  );
};

interface ProductGridFilterProps extends FilterElementProps {
  onClose: () => void;
}

export const ProductGridFilter = ({ filters, onChange, onClose }: ProductGridFilterProps) => {
  return (
    <>
      <div className="of-product-grid__filter-content--mobile">
        <label className="of-product-grid__filter-content__label">{t.G188(addFilterMsg)}</label>
        <div
          className="of-product-grid__filter-content__close"
          onClick={onClose}
          onKeyPress={(event: React.KeyboardEvent) => onEnterOrSpaceKeyPress(event, onClose)}
          role="button"
          tabIndex={0}
        >
          <CL.Icon size="m" icon="close" />
        </div>
      </div>
      <FiltersElement filters={filters} onChange={onChange} />
    </>
  );
};
