/**
 * SFDC Anonymous API
 * # Summary Elisa B2B API for an anonymous access to salesforce data.  # Usage This API is used in the `https://yrityksille.elisa.fi/omaelisa` pages, but it is possible to later on expose this documentation publicly and make the endpoint queryable via external clients. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Stock availability status
 */
export type StockAvailabilityStatus = 'SHIPMENTS_ARRIVING' | 'NO_SHIPMENTS_ARRIVING' | 'DIRECT_DELIVERY' | 'UNKNOWN';

export const StockAvailabilityStatus = {
    SHIPMENTS_ARRIVING: 'SHIPMENTS_ARRIVING' as StockAvailabilityStatus,
    NO_SHIPMENTS_ARRIVING: 'NO_SHIPMENTS_ARRIVING' as StockAvailabilityStatus,
    DIRECT_DELIVERY: 'DIRECT_DELIVERY' as StockAvailabilityStatus,
    UNKNOWN: 'UNKNOWN' as StockAvailabilityStatus
};

