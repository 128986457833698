import { BreadCrumbsWithTitle } from '../../../../../../components/BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { ModelType } from '../../../../../../common/enums.js';
import { OrderSubscriptionSelection } from '../../../../../../components/OrderSubscriptionSelection/OrderSubscriptionSelection.js';
import { OrderSubscriptionSelectionHeader } from './OrderSubscriptionSelectionHeader.js';
import { internetSubscriptionsMsg, omaElisaForCompaniesMsg, t } from '../../../../../../common/i18n/index.js';
import { paths } from '../../../../../../common/constants/pathVariables.js';
import { useLoaderData, useRouteLoaderData } from 'react-router-dom';
import type { OrderM2MLoaderData, OrderSubscriptionCommonLoaderData } from 'common/loaders.js';

export const OrderM2MSubSelectionPath = () => {
  const { companyInfo } = useRouteLoaderData('newM2MCommon') as OrderSubscriptionCommonLoaderData;
  const { addOnRules, m2mModel } = useLoaderData() as OrderM2MLoaderData;
  const uiOptions = companyInfo?.uiOptions as { toggles?: string[] };

  return (
    <>
      <OrderSubscriptionSelectionHeader
        breadCrumbs={
          <BreadCrumbsWithTitle
            breadCrumbPaths={[
              { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
              { name: t.SEYV(internetSubscriptionsMsg), path: paths.PS_BROADBAND_SUBSCRIPTIONS },
              { name: t.A7CH('New Laitenetti subscription') },
            ]}
          />
        }
      />
      <OrderSubscriptionSelection
        addOnRules={addOnRules}
        onlineModelType={ModelType.MobileM2M}
        onlineModels={[m2mModel]}
        toggles={uiOptions?.toggles}
      />
    </>
  );
};
