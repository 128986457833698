import * as CL from '@design-system/component-library';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { showMoreMsg, t } from '../../../common/i18n/index.js';

export const ShowMoreButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <div className={`${dsClass.DISPLAY_FLEX} ${dsClass.JUSTIFY_CONTENT_CENTER} ${dsClass.PADDING_TOP_2}`}>
      <CL.Button color="link" onClick={() => onClick()}>
        {t.H6OA(showMoreMsg)}
      </CL.Button>
    </div>
  );
};
