import { CheckoutHeader } from './CheckoutHeader.js';
import { DeviceCheckoutDialog } from '../DeviceCheckout/DeviceCheckoutDialogs.js';
import { NotificationContainer } from '../NotificationContainer/NotificationContainer.js';
import { Outlet, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { SmallHeader } from '../SmallHeader/SmallHeader.js';
import { SubscriptionCategory } from '../../common/enums.js';
import { SystemError, hasSystemError } from '../SystemError/SystemError.js';
import {
  authValidate,
  getElisaIdSession,
  getEmployeeSubscriptions,
  loadCompanyInfo,
  loadVirtualCatalogs,
  processCartItems,
  resetErrors,
} from '../../selfservice/actions/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { getPublishedCatalogs, resolveCategoryUrl } from '../../common/utils/catalogUtils.js';
import { getShoppingCart } from '../../selfservice/common/localStorageUtils.js';
import {
  isCartEmpty,
  isFinalPage,
  isHideDeliveryMethodSelection,
  isNotHavePermissionToBuy,
} from '../../common/utils/checkoutUtils.js';
import { isFeatureEnabled } from '../../common/utils/featureFlagUtils';
import { paths } from '../../common/constants/pathVariables.js';
import { t } from '../../common/i18n/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useElisaIdClient } from '../../common/hooks/useElisaIdClient.js';
import type { ShoppingCartItemForCheckout } from '../../common/types/checkout.js';
import type { State } from '../../selfservice/common/store.js';

import './Checkout.scss';

type CheckoutContext = {
  currentStep: number;
  hideDeliveryMethodSelection: boolean;
  redirectPath: string;
  setCurrentStep: (step: number) => void;
};

export const useCheckout = () => useOutletContext<CheckoutContext>();

const useRedirectToWebShopWhenCartIsEmpty = ({
  cartItems,
  cartItemsJson,
  redirectPath,
}: {
  cartItems: ShoppingCartItemForCheckout[];
  cartItemsJson: string;
  redirectPath: string;
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    // Prevent automatic redirect to web shop when in thank you page as cart is automatically flushed after successful order
    if (isFinalPage(pathname)) {
      return;
    }

    const rawCartItem: [] = JSON.parse(cartItemsJson);

    if (rawCartItem.length === 0 && isCartEmpty(cartItems)) {
      navigate(redirectPath);
    }
  }, [cartItems, cartItemsJson, navigate, redirectPath, pathname]);
};

interface CheckoutProps {
  isEmployee: boolean;
}

export const Checkout = ({ isEmployee }: CheckoutProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cartItemsJson = getShoppingCart();
  const catalogs = useSelector((s: State) => getPublishedCatalogs(s.selfservice?.virtualCatalogs?.items), deepEqual);
  const selectedCatalogCode = useSelector((s: State) => s.selfservice?.virtualCatalogs?.selectedCatalogCode, deepEqual);
  const categoryUrl = resolveCategoryUrl(catalogs || [], selectedCatalogCode || '');
  const employeeDeviceStorePath = `${paths.EMPLOYEE_DEVICE_STORE}${categoryUrl ? `/${categoryUrl}` : ''}`;
  const redirectPath = isEmployee ? employeeDeviceStorePath : paths.WEB_SHOP;
  const elisaIdClient = useElisaIdClient();
  const elisaIdV2 = useSelector((state: State) => state.config.featureFlags.elisaIdV2);

  const { ssoSessionValid, authenticatedUser } = useAuth();

  const { cartItems, errors } = useSelector(
    (state: State) => ({
      cartItems: state.deviceCheckout?.cartItems || [],
      errors:
        state.deviceCheckout?.errors ||
        state.selfservice?.contacts?.errors ||
        state.selfservice?.billChannels?.errors ||
        state.selfservice?.billingAccounts?.errors ||
        authenticatedUser?.errors,
    }),
    deepEqual
  );

  const [currentStep, setCurrentStep] = useState(0);
  const hideDeliveryMethodSelection = isHideDeliveryMethodSelection(cartItems);

  useEffect(() => {
    if (ssoSessionValid && isNotHavePermissionToBuy(isEmployee, ssoSessionValid, authenticatedUser)) {
      navigate(redirectPath);
    }
  }, [isEmployee, ssoSessionValid, navigate, redirectPath, authenticatedUser]);

  useRedirectToWebShopWhenCartIsEmpty({
    cartItems,
    cartItemsJson,
    redirectPath,
  });

  useLayoutEffect(() => {
    if (isEmployee) {
      if (ssoSessionValid) {
        dispatch(loadCompanyInfo());
        dispatch(loadVirtualCatalogs());
        dispatch(getEmployeeSubscriptions(SubscriptionCategory.DEVICE));
      } else if (!isFeatureEnabled(elisaIdV2)) {
        dispatch(getElisaIdSession(elisaIdClient));
      }
    } else {
      // Validate authentication to ensure that user is logged in and update expiring EPP subscriptions
      dispatch(authValidate());
      if (ssoSessionValid) {
        dispatch(loadCompanyInfo());
      } else if (cartItems.length === 0) {
        dispatch(processCartItems(false, false, cartItemsJson));
      }
    }
  }, [dispatch, isEmployee, ssoSessionValid, cartItemsJson, cartItems.length, elisaIdClient, elisaIdV2]);

  if (hasSystemError(errors)) {
    return (
      <div className="of-checkout">
        <SmallHeader
          buttonText={t.PLAW('Return to the shop')}
          onClick={() => {
            dispatch(resetErrors());
            navigate(redirectPath);
          }}
        />
        <SystemError
          errors={errors}
          onButtonClick={() => {
            dispatch(resetErrors());
            navigate(redirectPath);
          }}
        />
      </div>
    );
  }

  return (
    <div className="of-checkout">
      <CheckoutHeader
        isEmployee={isEmployee}
        currentStep={currentStep}
        hideDeliveryMethodSelection={hideDeliveryMethodSelection}
        redirectPath={redirectPath}
      />
      <NotificationContainer />
      <DeviceCheckoutDialog redirectPath={redirectPath} />

      <div className="of-checkout__content">
        <Outlet context={{ currentStep, hideDeliveryMethodSelection, redirectPath, setCurrentStep }} />
      </div>
    </div>
  );
};
