import { ContactType } from '../../generated/api/models.js';
import {
  TypeKeys,
  addRingAdminFailed,
  addRingAdminFulfilled,
  removeRingAdminFailed,
  removeRingAdminFulfilled,
  upsertContactFailed,
  upsertContactFulfilled,
} from '../actions/index.js';
import { actionToActionState } from './epicUtils.js';
import { callUiApi, prepareUiApiRequest } from '../common/uiApiUtils.js';
import { changeSubscriptionMobilePbxSolutionPrivateMethod } from '../../generated/api/uiApiMethods.js';
import { combineEpics, ofType } from 'redux-observable';
import { concatMap } from 'rxjs/operators';
import { paths } from '../../common/constants/pathVariables.js';
import { push } from 'redux-first-history';
import { upsertContact } from './contactEpic.js';
import type { Action } from 'redux';
import type { ActionAndState, EpicDependencies } from './epicUtils.js';
import type { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import type {
  AddRingAdminAction,
  AddRingAdminFulfilledAction,
  ErrorAction,
  RemoveRingAdminAction,
  RemoveRingAdminFulfilledAction,
  SelfServiceActionTypes,
  UpsertContactFulfilledAction,
} from '../actions/index.js';
import type {
  AdminContactDetails,
  Contact,
  PostChangeMobilePbxSolutionRequest,
  PutContactResponse,
} from '../../generated/api/models.js';
import type { Observable } from 'rxjs';
import type { State } from '../common/store.js';

export const removeRingAdminEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.REMOVE_RING_ADMIN)), (action: RemoveRingAdminAction) =>
    actionToActionState(action, state$, 'contacts')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const redirectTo = `${paths.COMPANY_INFO_RING_ADMINS}/`;
      const removeRingAdminAction = actionAndState.action as RemoveRingAdminAction;
      if (removeRingAdminAction.request.removeAdminContacts) {
        const removeAdminContacts: Array<AdminContactDetails> = removeRingAdminAction.request.removeAdminContacts;
        const payload = removeRingAdminAction.request;
        return callUiApi({
          epicDependencies,
          state$,
          method: changeSubscriptionMobilePbxSolutionPrivateMethod(removeRingAdminAction.subscriptionId),
          failureAction: removeRingAdminFailed,
          payload,
          successAction: () =>
            removeRingAdminFulfilled(removeAdminContacts[0].contactId, removeRingAdminAction.subscriptionId),
        }).pipe(
          concatMap((finalAction: RemoveRingAdminFulfilledAction) => {
            return [finalAction, push(redirectTo)];
          })
        );
      } else {
        return [removeRingAdminAction, push(redirectTo)];
      }
    })
  );

export const addRingAdminEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.ADD_RING_ADMIN)), (action: AddRingAdminAction) =>
    actionToActionState(action, state$, 'contacts')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      if (!actionAndState.state) {
        throw new Error('invalid action state for change subscription user info');
      }
      const { newRingAdminPerson, subscriptionId } = actionAndState.action as AddRingAdminAction;
      const newContact: Contact = {
        contactType: ContactType.PERSON,
        person: newRingAdminPerson,
      };
      const observable: Observable<UpsertContactFulfilledAction | ErrorAction<TypeKeys>> = upsertContact(
        newContact,
        epicDependencies,
        state$,
        upsertContactFailed,
        (response: PutContactResponse) => {
          return upsertContactFulfilled(response, newContact);
        }
      );

      return observable.pipe(
        concatMap(action => {
          let adminContactDetails: AdminContactDetails;
          let contact: Contact;
          let payload: PostChangeMobilePbxSolutionRequest;
          if (action && action.type === TypeKeys.UPSERT_CONTACT_FULFILLED) {
            contact = (action as UpsertContactFulfilledAction).contact;
            if (contact.contactId && newRingAdminPerson.userName) {
              adminContactDetails = {
                contactId: contact.contactId,
                userName: newRingAdminPerson.userName,
              };
              payload = {
                addAdminContacts: [adminContactDetails],
              };
              return callUiApi({
                epicDependencies,
                state$,
                method: changeSubscriptionMobilePbxSolutionPrivateMethod(subscriptionId),
                payload,
                successAction: () => addRingAdminFulfilled(contact, subscriptionId),
                failureAction: addRingAdminFailed,
              }).pipe(
                concatMap((finalAction: AddRingAdminFulfilledAction) => {
                  return [finalAction, push(`/omaelisa/yritystiedot/ring-pääkäyttäjät/`)];
                })
              );
            } else {
              return [action];
            }
          } else {
            return [action];
          }
        })
      );
    })
  );

export const ringAdminsEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = combineEpics(
  removeRingAdminEpic,
  addRingAdminEpic
);
