import * as CL from '@design-system/component-library';
import { Controller, useFormContext } from 'react-hook-form';
import { Email } from '../../../common/react-hook-form/fields/Email.js';
import { Name } from '../../../common/react-hook-form/fields/Name.js';
import { PhoneNumber } from '../../../common/react-hook-form/fields/PhoneNumber.js';
import { ToggleWithSubComponent } from '../../ToggleWithSubComponent/ToggleWithSubComponent.js';
import { areYouTheInvoicingContactPersonMsg, firstNameMsg, lastNameMsg, t } from '../../../common/i18n/index.js';
import type { FormEditProps } from './EOperatorChangeRequestForm.js';

const FIRST_NAME_FIELD_NAME = 'billingContactPersonFirstName';
const LAST_NAME_FIELD_NAME = 'billingContactPersonLastName';
const EMAIL_FIELD_NAME = 'billingContactPersonEmail';
const PHONE_FIELD_NAME = 'billingContactPersonPhone';

const FormFields = ({ isEditing }: FormEditProps) => (
  <>
    <h4>{t.ULM3('Fill in details for the invoicing contact person')}</h4>
    <CL.Grid>
      <CL.GridRow>
        <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
          <Name label={t.AIK7(firstNameMsg)} name={FIRST_NAME_FIELD_NAME} placeholder="" readonly={!isEditing} />
        </CL.GridCol>
        <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
          <Name label={t.Y41S(lastNameMsg)} name={LAST_NAME_FIELD_NAME} placeholder="" readonly={!isEditing} />
        </CL.GridCol>
      </CL.GridRow>
      <CL.GridRow>
        <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
          <Email name={EMAIL_FIELD_NAME} placeholder="" readonly={!isEditing} />
        </CL.GridCol>
        <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
          <PhoneNumber
            name={PHONE_FIELD_NAME}
            tooltip={t.V59H('We will send an SMS notification of overdue invoices to this number.')}
            placeholder=""
            readonly={!isEditing}
          />
        </CL.GridCol>
      </CL.GridRow>
    </CL.Grid>
  </>
);

export const IsBillingContactPerson = ({ isEditing = true }: FormEditProps) => {
  const { setValue, clearErrors } = useFormContext();
  const handleToggle = (value: boolean) => {
    if (value) {
      setValue(FIRST_NAME_FIELD_NAME, '');
      setValue(LAST_NAME_FIELD_NAME, '');
      setValue(EMAIL_FIELD_NAME, '');
      setValue(PHONE_FIELD_NAME, '');
      clearErrors([FIRST_NAME_FIELD_NAME, LAST_NAME_FIELD_NAME, EMAIL_FIELD_NAME, PHONE_FIELD_NAME]);
    }
  };
  return (
    <Controller
      name="isBillingContactPerson"
      render={({ field: { onChange } }) => (
        <ToggleWithSubComponent
          name="isBillingContactPerson"
          title={t.P9D3(areYouTheInvoicingContactPersonMsg)}
          infoText={t.VH3U(
            'A contact person in the billing account who is notified about issues related to invoices, including unpaid invoices. Will not be printed on the invoice.'
          )}
          onToggle={value => {
            onChange(value);
            handleToggle(value);
          }}
          subComponent={<FormFields isEditing={isEditing} />}
          showSubComponentWhenToggled={false}
          initialValue={true}
          disabled={!isEditing}
        />
      )}
    />
  );
};
