import { EMPTY } from 'rxjs';
import { TypeKeys } from '../actions/index.js';
import { actionToActionState } from './epicUtils.js';
import { callUiApi, prepareUiApiRequest } from '../common/uiApiUtils.js';
import { clearShoppingCart, removePunchoutDeviceChangeRequest } from '../common/localStorageUtils.js';
import { combineEpics, ofType } from 'redux-observable';
import { concatMap } from 'rxjs/operators';
import { postPunchoutOrderPrivateMethod } from '../../generated/api/uiApiMethods.js';
import { submitPunchoutOrderFailed, submitPunchoutOrderFulfilled } from '../actions/punchoutActions.js';
import type { Action } from 'redux';
import type { ActionAndState, EpicDependencies } from './epicUtils.js';
import type { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import type { AjaxResponse } from 'rxjs/ajax';
import type { InitiateEmployeeOnBoardingAction, SelfServiceActionTypes } from '../actions/index.js';
import type { PunchoutOrderResponse } from '../../common/utils/punchoutUtils.js';
import type { State } from '../common/store.js';
import type { SubmitPunchoutOrderAction, SubmitPunchoutOrderFulfilledAction } from '../actions/punchoutActions.js';

export const submitPunchoutOrderEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(
    action$.pipe(ofType(TypeKeys.SUBMIT_PUNCHOUT_ORDER)),
    (action: InitiateEmployeeOnBoardingAction) => actionToActionState(action, state$, 'punchoutOrders')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const punchoutOrderAction = actionAndState.action as SubmitPunchoutOrderAction;
      return callUiApi({
        epicDependencies,
        state$,
        method: postPunchoutOrderPrivateMethod(),
        payload: punchoutOrderAction.order,
        successAction: (ajaxResponse: AjaxResponse) =>
          submitPunchoutOrderFulfilled(ajaxResponse.response as PunchoutOrderResponse),
        failureAction: submitPunchoutOrderFailed,
      });
    })
  );

export const submitPunchoutOrderFulfilledEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>
) =>
  action$.ofType(TypeKeys.SUBMIT_PUNCHOUT_ORDER_FULFILLED).pipe(
    concatMap((action: SubmitPunchoutOrderFulfilledAction) => {
      const form = document.createElement('form');
      form.setAttribute('hidden', 'true');
      form.setAttribute('method', 'POST');
      form.setAttribute('action', action.response.url);
      Object.keys(action.response.data).forEach(key => {
        const input1 = document.createElement('input');
        input1.setAttribute('type', 'text');
        input1.setAttribute('name', key);
        input1.setAttribute('value', action.response.data[key]);
        form.appendChild(input1);
      });
      document.body.appendChild(form);
      form.submit();
      removePunchoutDeviceChangeRequest();
      clearShoppingCart();
      return EMPTY;
    })
  );

export const punchoutEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = combineEpics(
  submitPunchoutOrderEpic,
  submitPunchoutOrderFulfilledEpic
);
