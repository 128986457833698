import { createRegExpValidator } from '../../../../../common/utils/validationUtils.js';
import { hostnameValidationMsg, ipv4ValidationMsg, ipv6ValidationMsg, t } from '../../../../../common/i18n/index.js';

/**
 * The following RegExp match their `online-ui-api` counterparts
 */
export const validateHostname = createRegExpValidator(
  /^([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9])(\.([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]))*$/,
  t.Z96J(hostnameValidationMsg)
);

export const validateIPv4 = createRegExpValidator(
  /^(([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])$/,
  t.ZEX1(ipv4ValidationMsg)
);

export const validateIPv6 = createRegExpValidator(
  /((([0-9a-fA-F]){1,4}):){7}([0-9a-fA-F]){1,4}/,
  t.S6EV(ipv6ValidationMsg)
);

const validateIntegerPositiveRange = (min: number, max: number) => (value: string) => {
  const num = Number(value);
  return !Number.isNaN(num) && /^[0-9]+$/.test(value) && min <= num && num <= max
    ? undefined
    : `${t.LGDG('Only numbers')} ${min} - ${max}`;
};

export const validatePort = validateIntegerPositiveRange(0, 65535);

export const validatePriority = validateIntegerPositiveRange(0, 65535);

export const validateTtl = validateIntegerPositiveRange(5, 60 * 60 * 24 * 7);

export const validateWeight = validateIntegerPositiveRange(0, 65535);
