import * as CL from '@design-system/component-library';
import { DialogWrapper } from '../DialogWrapper/index.js';
import { Form, Formik } from 'formik';
import { ModalButtons } from '../Modal/ModalButtons.js';
import { SelectContactToUse } from './SelectContactToUse.js';
import {
  backMsg,
  continueMsg,
  duplicateContactDescriptionMsg,
  duplicateContactHeaderMsg,
  t,
} from '../../common/i18n/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import {
  getDuplicateContactsToGetApprovalFromUser,
  getFormFieldValidations,
  getFormInitialValues,
  getTitle,
  isDuplicateContactFromHierarchy,
  isDuplicateContactHasSingleExactDuplicate,
  submitDuplicateCheckSelection,
} from './duplicateContactCheckDialogUtils.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { validateForm } from '../../common/formik/index.js';
import type { State } from '../../selfservice/common/store.js';

import './DuplicateContactsCheckDialog.scss';

export interface DuplicateContactsCheckDialogProps {
  onCloseDialog: () => void;
}

const formId = 'checkout-duplicate-check';

export const DuplicateContactsCheckDialog = ({ onCloseDialog }: DuplicateContactsCheckDialogProps) => {
  const dispatch = useDispatch();
  const { cartItems, duplicateContactCheck, moveToNextStep } = useSelector(
    (state: State) => ({
      cartItems: state.deviceCheckout?.cartItems || [],
      duplicateContactCheck: state.deviceCheckout?.duplicateContactCheck,
      moveToNextStep: state.deviceCheckout?.moveToNextStep,
    }),
    deepEqual
  );

  useEffect(() => {
    if (
      !moveToNextStep &&
      duplicateContactCheck?.duplicateContacts
        ?.map(
          duplicateContact =>
            isDuplicateContactHasSingleExactDuplicate(duplicateContact) ||
            isDuplicateContactFromHierarchy(duplicateContact)
        )
        .reduce((acc, next) => acc && next, true)
    ) {
      // auto submit the duplicate check dialog if there are only single exact duplicate with single contact or single duplicate from hierarchy without prompting user.
      submitDuplicateCheckSelection(dispatch, cartItems, duplicateContactCheck?.duplicateContacts);
    }
  }, [duplicateContactCheck, cartItems, dispatch, moveToNextStep]);

  const onSubmit = (values: object) => {
    submitDuplicateCheckSelection(dispatch, cartItems, duplicateContactCheck?.duplicateContacts, values);
  };

  const duplicateContactSelectionOptions = getDuplicateContactsToGetApprovalFromUser(
    cartItems,
    duplicateContactCheck?.duplicateContacts
  );

  return (
    <DialogWrapper
      closeable
      header={t.CVJF(duplicateContactHeaderMsg)}
      onCloseDialog={onCloseDialog}
      size={duplicateContactSelectionOptions.length > 1 ? 'l' : 'm'}
    >
      <p>{t.WBKW(duplicateContactDescriptionMsg)}</p>
      <Formik
        initialValues={getFormInitialValues(duplicateContactSelectionOptions)}
        onSubmit={onSubmit}
        validate={values => validateForm(values, getFormFieldValidations(duplicateContactSelectionOptions))}
      >
        <Form className="of-duplicate-contact-check" id={formId}>
          <div className="of-duplicate-contact-check__radios">
            {duplicateContactSelectionOptions.map((duplicateContactObj, index) => (
              <div key={duplicateContactObj.duplicateContact.identifier}>
                <SelectContactToUse
                  newContact={duplicateContactObj.newContact}
                  duplicateContact={duplicateContactObj.duplicateContact}
                  title={getTitle(index, duplicateContactSelectionOptions)}
                />
              </div>
            ))}
          </div>
          <ModalButtons>
            <CL.Button color="link" onClick={onCloseDialog}>
              {t.D1E9(backMsg)}
            </CL.Button>
            <CL.Button type="submit" color="primary">
              {t.I62A(continueMsg)}
            </CL.Button>
          </ModalButtons>
        </Form>
      </Formik>
    </DialogWrapper>
  );
};
