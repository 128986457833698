import { ELECTRONIC_ADDRESS_REGEX } from '../../utils/validationUtils.js';
import { Field } from 'formik';
import { InputComponent } from '../FieldComponents/index.js';
import { eInvoicingAddressMsg, invalidEInvoiceAddressMsg, t } from '../../i18n/index.js';

export const BillElectronicAddress = () => {
  const validateElectronicAddress = (value: string) => {
    if (!ELECTRONIC_ADDRESS_REGEX.test(value)) {
      return t.NMXU(invalidEInvoiceAddressMsg);
    }
    return undefined;
  };

  return (
    <Field
      name="billElectronicAddress"
      label={t.OL7B(eInvoicingAddressMsg)}
      placeholder={t.OL7B(eInvoicingAddressMsg)}
      maxLength={40}
      required={true}
      validate={validateElectronicAddress}
      component={InputComponent}
    />
  );
};
