import * as CL from '@design-system/component-library';
import { dsClass } from '../../common/constants/dsClasses.js';
import {
  manageSubscriptionsMsg,
  noQueuingMsg,
  selfServiceMarketingBlockInvoicesBodyMsg,
  selfServiceMarketingBlockSubscriptionsBodyMsg,
  selfServiceMarketingBlockWhyUseMsg,
  t,
} from '../../common/i18n/index.js';

import './SelfServiceMarketingBlock.scss';

// This is a static marketing block which typically comes below login page. This component doesn't have any props or logic at the moment, but there can be localization parameters to this component and of course can be reused .
export const SelfServiceMarketingBlock = () => (
  <CL.Grid className="of-employee__marketing-block">
    <CL.GridRow>
      <CL.GridCol colWidthM={3}>
        <div className="ea-image of-marketing-block__image">
          <img
            src="https://static.elisa.com/v2/image/2tqybbhjs47b/laWYL60UXYizRMAhM5us6/oe-device@2x.png"
            width="630"
            height="314"
            sizes="(max-width: 500px) 400px,(max-width: 1200px) 540px, (max-width: 1400px) 630px, (max-width: 1920px) 1260px"
            alt="Employee portal"
          />
        </div>
      </CL.GridCol>
      <CL.GridCol colWidthM={3} className={dsClass.PADDING_LEFT_4}>
        <h2 className={`ea-h2 ${dsClass.PADDING_BOTTOM_4}`}>{t.K8NA(selfServiceMarketingBlockWhyUseMsg)}</h2>
        <h4 className="ea-h4">{t.JZUK(noQueuingMsg)}</h4>
        <p className={`selfservice-marketing-block__bodytext ${dsClass.PADDING_BOTTOM_2}`}>
          {t.HE85(selfServiceMarketingBlockInvoicesBodyMsg)}
        </p>
        <h4 className="ea-h4">{t.JQO5(manageSubscriptionsMsg)}</h4>
        <p className={`selfservice-marketing-block__bodytext ${dsClass.PADDING_BOTTOM_2}`}>
          {t.QMNH(selfServiceMarketingBlockSubscriptionsBodyMsg)}
        </p>
        <p className={`${dsClass.FONT_SIZE_LARGE} selfservice-marketing-block__bodytext`}>
          <a href="/asioi-yritysten-oma-elisassa">{t.H2HC('Enable the service')}</a>
        </p>
      </CL.GridCol>
    </CL.GridRow>
  </CL.Grid>
);
