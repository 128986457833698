import * as CL from '@design-system/component-library';
import { ConsolidatedListViewSelector } from '../ConsolidatedListViewSelector/ConsolidatedListViewSelector.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { isFeatureEnabled } from '../../common/utils/featureFlagUtils.js';
import { isMultiBiz } from '../../common/utils/accountUtils.js';
import { useStore } from 'react-redux';
import type { AuthenticatedUserState } from '../../common/types/states.js';
import type { State } from '../../selfservice/common/store.js';

export interface ConsolidatedListViewProps {
  authenticatedUser: AuthenticatedUserState | undefined;
  listElement: JSX.Element;
  listPagination?: JSX.Element;
  listSearch: JSX.Element;
  searchFilters?: JSX.Element;
  toggleElement?: JSX.Element;
}

export const ConsolidatedListView = ({
  authenticatedUser,
  listElement,
  listPagination,
  listSearch,
  searchFilters,
  toggleElement,
}: ConsolidatedListViewProps) => {
  const { featureFlags } = useStore<State>().getState().config;
  return (
    <>
      {isMultiBiz(authenticatedUser) && !isFeatureEnabled(featureFlags.consolidatedViews) && toggleElement}
      {(!isMultiBiz(authenticatedUser) || !isFeatureEnabled(featureFlags.consolidatedViews)) && listSearch}
      {isMultiBiz(authenticatedUser) && isFeatureEnabled(featureFlags.consolidatedViews) && (
        <CL.Grid className={dsClass.MARGIN_TOP_3}>
          <CL.GridRow>
            <CL.GridCol
              className={dsClass.PADDING_0}
              colWidthXS={4}
              colWidthS={6}
              colWidthM={3}
              colWidthL={4}
              colWidthXL={4}
            >
              <ConsolidatedListViewSelector authenticatedUser={authenticatedUser} />
            </CL.GridCol>
            <CL.GridCol
              className={dsClass.PADDING_0}
              colWidthXS={4}
              colWidthS={6}
              colWidthM={3}
              colWidthL={7}
              colWidthXL={6}
            >
              {listSearch}
            </CL.GridCol>
          </CL.GridRow>
        </CL.Grid>
      )}
      {searchFilters}
      {listElement}
      {listPagination}
    </>
  );
};
