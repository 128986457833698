import { ApiType, ApiVerb } from '../../generated/api/uiApiMethods.js';
import { catchError, concatMap, delay, filter, map, retryWhen, takeUntil, tap, timeout } from 'rxjs/operators';
import {
  getAccountMasterIdHeader,
  getError,
  getJsonPayloadHeaders,
  getLocaleHeader,
  getUserRoleHeader,
  itemsQueryToQueryParams,
  requireStateInProgress,
} from '../epics/epicUtils.js';
import { ofType } from 'redux-observable';
import type { ActionAndState, EpicDependencies } from '../epics/epicUtils.js';
import type { ActionWithId, ItemsQuery, State } from './store.js';
import type { ActionsObservable, StateObservable } from 'redux-observable';
import type { AjaxError } from 'rxjs/ajax';
import type { ApiMethod } from '../../generated/api/uiApiMethods.js';
import type { ErrorAction, ErrorActionCreator, SelfServiceActionTypes, TypeKeys } from '../actions/index.js';
import type { Observable } from 'rxjs';

const SELFSERVICE_API_BASE_PATH = '/api/v1/selfservice';
export const RETRYABLE_HTTP_STATUS = [502, 504, 0];

export enum HttpStatus {
  FORBIDDEN = 403,
  NO_CONTENT = 204,
}

export const mapPathParamsToPath = (path: string, params: { [p: string]: string | number } | undefined): string => {
  return path
    .split('/')
    .map((fragment: string) => {
      if (fragment.startsWith(':') && params?.[fragment.substring(1)]) {
        return params[fragment.substring(1)];
      }
      return fragment;
    })
    .join('/');
};

const resolveQueryStringFromQueryParams = (queryParams: { [s: string]: string | number }): string => {
  let queryParamsString = '';
  const queryParamsKeys = Object.keys(queryParams);

  if (queryParamsKeys.length > 0) {
    queryParamsString = '?';
    queryParamsString += queryParamsKeys
      .map((name, i) => {
        return `${name}=${encodeURIComponent(queryParams[name].toString())}${
          i < queryParamsKeys.length - 1 ? '&' : ''
        }`;
      })
      .join('');
  }
  return queryParamsString;
};

const resolveQueryParams = <A, R>(uiApiGetRequest: UiApiGetRequest<A, R>): string => {
  return resolveQueryStringFromQueryParams({
    ...(uiApiGetRequest.itemsQuery ? itemsQueryToQueryParams(uiApiGetRequest.itemsQuery) : undefined),
    ...uiApiGetRequest.queryParams,
  });
};

export const resolveUiApiPath = <A, R>(uiApiGetRequest: UiApiGetRequest<A, R>): string => {
  return `${uiApiGetRequest.basePath ? uiApiGetRequest.basePath : SELFSERVICE_API_BASE_PATH}${mapPathParamsToPath(
    uiApiGetRequest.path,
    uiApiGetRequest.pathParams
  )}${resolveQueryParams(uiApiGetRequest)}`;
};

export const prepareUiApiRequest = <T>(
  action$: Observable<SelfServiceActionTypes>,
  mapToStateAction: (action: ActionWithId) => ActionAndState
): Observable<T | ActionAndState> => {
  return action$.pipe(
    map((action: ActionWithId) => mapToStateAction(action)),
    filter(actionAndState => requireStateInProgress(actionAndState))
  );
};

export interface UiApiGetRequest<A, R> {
  basePath?: string;
  epicDependencies: EpicDependencies;
  failureAction: ErrorActionCreator<TypeKeys>;
  failureParams?: { [s: string]: string };
  itemsQuery?: ItemsQuery;
  path: string;
  pathParams?: { [s: string]: string | number };
  queryParams?: { [s: string]: string | number };
  state$: StateObservable<State>;
  successAction: (response: R) => A;
}

export interface UiApiStoreRequest<A, R> extends UiApiGetRequest<A, R> {
  payload?: object;
}

export type UiApiPostRequest<A, R> = UiApiStoreRequest<A, R>;

export const getPublicUiApiHeaders = (state$: StateObservable<State>) => {
  return {
    ...getAccountMasterIdHeader(state$),
    ...getLocaleHeader(),
  };
};

export const getPrivateUiApiHeaders = (state$: StateObservable<State>) => ({
  ...getPublicUiApiHeaders(state$),
  ...getUserRoleHeader(state$),
});

export const getFromUiApiWithRetry = <A, R>(
  uiApiGetRequest: UiApiGetRequest<A[], R>,
  retryUntil: number | TypeKeys | undefined,
  retryDelay: number,
  action$: ActionsObservable<SelfServiceActionTypes>,
  header?: Record<string, string>
): Observable<A | ErrorAction<TypeKeys>> => {
  return uiApiGetRequest.epicDependencies.getJSON(resolveUiApiPath(uiApiGetRequest), header).pipe(
    concatMap(uiApiGetRequest.successAction),
    retryWhen(error =>
      error.pipe(
        delay(retryDelay),
        tap((ajaxError: AjaxError) => {
          if (!RETRYABLE_HTTP_STATUS.includes(ajaxError.status)) {
            throw ajaxError;
          }
        })
      )
    ),
    source => {
      if (retryUntil) {
        if (typeof retryUntil === 'number') {
          return source.pipe(timeout(retryUntil));
        } else {
          return source.pipe(takeUntil(action$.pipe(ofType(retryUntil))));
        }
      } else {
        return source;
      }
    },
    catchError((error: AjaxError) => getError(error, uiApiGetRequest.failureAction))
  );
};

export const postToUiApi = <A, R>(uiApiPostRequest: UiApiPostRequest<A, R>): Observable<A | ErrorAction<TypeKeys>> => {
  return uiApiPostRequest.epicDependencies
    .post(resolveUiApiPath(uiApiPostRequest), uiApiPostRequest.payload, getJsonPayloadHeaders(uiApiPostRequest.state$))
    .pipe(
      map(uiApiPostRequest.successAction),
      catchError(ajaxError => getError(ajaxError, uiApiPostRequest.failureAction, uiApiPostRequest.failureParams))
    );
};

export const postPrivateToUiApi = <A, R>(
  uiApiPostRequest: UiApiPostRequest<A, R>
): Observable<A | ErrorAction<TypeKeys>> => {
  return postToUiApi(uiApiPostRequest);
};

const getHeaders = <A, R>(request: UiApiRequest<A, R>) => {
  return request.method.type === ApiType.PRIVATE
    ? {
        ...getPrivateUiApiHeaders(request.state$),
        ...request.headers,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
      }
    : {
        ...getPublicUiApiHeaders(request.state$),
        ...request.headers,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
      };
};

// /////////////////////////////////////////////////////
//
// Functions based on generated uiApiMethods.ts

export interface UiApiRequestBase<A, R> {
  epicDependencies: EpicDependencies;
  failureParams?: { [s: string]: string };
  method: ApiMethod;
  payload?: object;
  state$: StateObservable<State>;
  successAction: (response: R) => A;
  // If set to true, success action also for GET returns the full AjaxResponse and not just the "response" key inside it
  successActionGetAjaxResponse?: boolean;
  headers?: Record<string, string>;
}

export interface UiApiRequestFailureAction<A, R> extends UiApiRequestBase<A, R> {
  failureAction: ErrorActionCreator<TypeKeys>;
}

export interface UiApiRequestErrorHandling<A, R> extends UiApiRequestBase<A, R> {
  // Alternative error handler for custom logic
  errorHandler: (ajaxError: AjaxError) => Observable<ErrorAction<TypeKeys>>;
}

export type UiApiRequest<A, R> = UiApiRequestFailureAction<A, R> | UiApiRequestErrorHandling<A, R>;

const getErrorHandler = <A, R>(request: UiApiRequest<A, R>) =>
  'errorHandler' in request
    ? request.errorHandler
    : (ajaxError: AjaxError) => getError(ajaxError, request.failureAction, request.failureParams);

export const callUiApi = <A, R>(request: UiApiRequest<A, R>): Observable<A | ErrorAction<TypeKeys>> => {
  const errorHandler = getErrorHandler(request);

  // For private endpoints, we need to set the multi-biz header and employee role header. Best to do it every time
  // to be consistent
  const headers = getHeaders(request);

  switch (request.method.verb) {
    case ApiVerb.GET:
      if (request.payload) {
        throw new Error(`Request payload for GET not implemented, path ${request.method.path}`);
      }
      if (request.successActionGetAjaxResponse) {
        return request.epicDependencies
          .get(request.method.path, headers)
          .pipe(map(request.successAction), catchError(errorHandler));
      }
      return request.epicDependencies
        .getJSON(request.method.path, headers)
        .pipe(map(request.successAction), catchError(errorHandler));
    case ApiVerb.PUT:
      return request.epicDependencies
        .put(request.method.path, request.payload, headers)
        .pipe(map(request.successAction), catchError(errorHandler));
    case ApiVerb.POST:
      return request.epicDependencies
        .post(request.method.path, request.payload, headers)
        .pipe(map(request.successAction), catchError(errorHandler));
    case ApiVerb.DELETE:
      return request.epicDependencies
        .delete(request.method.path, headers)
        .pipe(map(request.successAction), catchError(errorHandler));
    default:
      throw new Error(`Invalid verb ${request.method.verb}, path ${request.method.path}`);
  }
};

export const callGetUiApiWithRetry = <A, R>(
  request: UiApiRequest<A, R>,
  retryUntil: number | TypeKeys | undefined,
  retryDelay: number,
  action$: ActionsObservable<SelfServiceActionTypes>,
  retryableStatus: Array<number>
): Observable<A | ErrorAction<TypeKeys>> => {
  const errorHandler = getErrorHandler(request);
  const headers = getHeaders(request);
  switch (request.method.verb) {
    case ApiVerb.GET:
      if (request.payload) {
        throw new Error(`Request payload for GET not implemented, path ${request.method.path}`);
      }

      return request.epicDependencies.getJSON(request.method.path, headers).pipe(
        map(request.successAction),
        retryWhen(error =>
          error.pipe(
            delay(retryDelay),
            tap((ajaxError: AjaxError) => {
              if (!retryableStatus.includes(ajaxError.status)) {
                throw ajaxError;
              }
            })
          )
        ),
        source => {
          if (retryUntil) {
            if (typeof retryUntil === 'number') {
              return source.pipe(timeout(retryUntil));
            } else {
              return source.pipe(takeUntil(action$.pipe(ofType(retryUntil))));
            }
          } else {
            return source;
          }
        },
        catchError(errorHandler)
      );

    default:
      throw new Error(`Invalid verb ${request.method.verb}, path ${request.method.path}`);
  }
};
