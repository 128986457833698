/**
 * Online UI API
 * # Summary Elisa B2B Online UI API  # Usage This API is used in the `https://yrityksille.elisa.fi/` pages.  # Identifiers 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Credit check decision
 */
export type CreditCheckDecision = 'PASS' | 'COMPANY_NEGATIVE_CREDIT_DECISION' | 'COMPANY_CREDIT_LIMIT_EXCEEDED' | 'EMPLOYEE_NEGATIVE_CREDIT_DECISION' | 'INTERNAL_ERROR' | 'DECLINED' | 'DECLINED_RECOMMEND_CARD_PAYMENT';

export const CreditCheckDecision = {
    PASS: 'PASS' as CreditCheckDecision,
    COMPANY_NEGATIVE_CREDIT_DECISION: 'COMPANY_NEGATIVE_CREDIT_DECISION' as CreditCheckDecision,
    COMPANY_CREDIT_LIMIT_EXCEEDED: 'COMPANY_CREDIT_LIMIT_EXCEEDED' as CreditCheckDecision,
    EMPLOYEE_NEGATIVE_CREDIT_DECISION: 'EMPLOYEE_NEGATIVE_CREDIT_DECISION' as CreditCheckDecision,
    INTERNAL_ERROR: 'INTERNAL_ERROR' as CreditCheckDecision,
    DECLINED: 'DECLINED' as CreditCheckDecision,
    DECLINED_RECOMMEND_CARD_PAYMENT: 'DECLINED_RECOMMEND_CARD_PAYMENT' as CreditCheckDecision
};

