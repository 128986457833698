import { CustomerOrderSystemError } from '../../../../../components/CustomerOrders/CustomerOrders.js';
import { DeliveryOrderDetails } from '../../../../../components/DeliveryOrderDetails/DeliveryOrderDetails.js';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import { findItemById, stripActions } from '../../../../../common/utils/stateUtils.js';
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { loadCustomerOrders } from '../../../../../selfservice/actions/index.js';
import { omaElisaForCompaniesMsg, orderMsg, t } from '../../../../../common/i18n/index.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useSearchParams } from '../../../../../common/hooks/useSearchParams';
import type { DeliveryOrder } from '../../../../../generated/api/models.js';
import type { OrderId, SubscriptionId } from '../../../../../common/constants/pathInterfaces.js';
import type { State } from '../../../../../selfservice/common/store.js';

export const CustomerOrderProductPath = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { orderId, subscriptionId } = useParams<OrderId & SubscriptionId>();
  const customerOrders = useSelector((state: State) => stripActions(state.selfservice?.customerOrders), deepEqual);
  const { mdmId } = useSearchParams<{ mdmId: string }>();
  const customerOrder = findItemById('customerOrderDisplayId', orderId, customerOrders);
  const deliveryOrder =
    subscriptionId && customerOrder && customerOrder.deliveryOrders
      ? customerOrder.deliveryOrders.find(
          ({ deliveryOrderDisplayId }: DeliveryOrder) => deliveryOrderDisplayId === subscriptionId
        )
      : undefined;

  useEffect(() => {
    dispatch(loadCustomerOrders(orderId, subscriptionId));
  }, [dispatch, pathname, orderId, subscriptionId]);

  if (customerOrder && deliveryOrder) {
    return (
      <CustomerOrderSystemError>
        <DeliveryOrderDetails
          customerOrder={customerOrder}
          deliveryOrder={deliveryOrder}
          onClickCustomerOrder={() => {
            navigate(`${generatePath(paths.CUSTOMER_ORDER, { orderId: orderId! })}?mdmId=${mdmId}`);
          }}
          breadCrumbPaths={[
            { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
            { name: t.I9C0(orderMsg), path: paths.CUSTOMER_ORDERS },
            {
              name: customerOrder.customerOrderDisplayId,
              path: `${generatePath(paths.CUSTOMER_ORDER, { orderId: orderId! })}?mdmId=${mdmId}`,
            },
            { name: deliveryOrder.deliveryOrderName },
          ]}
        />
      </CustomerOrderSystemError>
    );
  }
  return null;
};
