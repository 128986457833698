import * as CL from '@design-system/component-library';
import { SubscriptionStatusType, SubscriptionType } from '../../generated/api/models.js';
import {
  billingAccountMsg,
  contractEndDateMsg,
  durationOfContractMsg,
  idNumberMsg,
  monthsMsg,
  notAvailableMsg,
  statusMsg,
  t,
} from '../../common/i18n/index.js';
import { formatTimestampToUTCDDMMYYYY } from '../../common/utils/dateUtils.js';
import { getPbxFields } from './subscriptionDetailsPbx.js';
import { getPbxLiteFields } from './subscriptionDetailsPbxLite.js';
import { getPeriodicPriceAsText, getSubscriptionStatusFieldCL } from './subscriptionDetailsCommon.js';
import type {
  AddOn,
  NumberRange,
  Subscription,
  SubscriptionDetails,
  SubscriptionDetailsPbxSolution,
} from '../../generated/api/models.js';
import type { CompanyInfoState } from '../../common/types/states.js';
import type { SubscriptionStatus } from '../../common/types/subscription.js';

export const getSubscriptionDetailsPbx = (
  subscription: Subscription,
  subscriptionStatus: SubscriptionStatus,
  details: SubscriptionDetails,
  billingAccountSelection?: JSX.Element
) => (
  <CL.Description
    items={[
      getSubscriptionStatusFieldCL(subscriptionStatus, t.ASQT(statusMsg)),
      {
        title: t.YTH3(idNumberMsg),
        description: subscription.subscriptionDisplayId,
      },
      {
        title: t.IFT9(billingAccountMsg),
        description: billingAccountSelection,
      },
      {
        title: t.CAA9(durationOfContractMsg),
        description: details.commitmentPeriod
          ? `${details.commitmentPeriod} ${t.XXVX(monthsMsg)}`
          : t.NN5S(notAvailableMsg),
      },
      {
        title: t.LHJ4(contractEndDateMsg),
        description: details.commitmentEndDate
          ? `${formatTimestampToUTCDDMMYYYY(details.commitmentEndDate)}`
          : t.NN5S(notAvailableMsg),
      },
      {
        title: t.O5CK('Contractual price, VAT 0%'),
        description: getPeriodicPriceAsText(details.monthlyRecurringCharge),
      },
    ]}
  />
);

const renderRangeOfNumbers = (numberRanges: NumberRange[] = [], filterCondition: NumberRange.RangeTypeEnum) =>
  numberRanges
    .filter(numberRange => numberRange.rangeType === filterCondition)
    .map(numberRange => (
      <div key={numberRange.rangeId}>
        {numberRange.startNumber} - {numberRange.endNumber}
      </div>
    ));

export const renderContent = (items: CL.DescriptionItem[]) => <CL.Description items={items} />;

export const getPbxTechnicalDetailsContent = (
  subscription: Subscription,
  mobilePbx: SubscriptionDetailsPbxSolution
) => {
  const fields =
    subscription.subscriptionType === SubscriptionType.MOBILE_PBX_LITE
      ? getPbxLiteFields(mobilePbx, renderRangeOfNumbers, subscription.subscriptionPurposeOfUse)
      : getPbxFields(mobilePbx?.numberRanges, renderRangeOfNumbers);
  return renderContent(fields);
};

const findFromPbxSolutionsByType = (
  companyInfoState: CompanyInfoState,
  subscriptionType: SubscriptionType
): Subscription[] | undefined => {
  return companyInfoState?.pbxSolutions
    ? companyInfoState.pbxSolutions
        .filter(item => item.subscriptionType === subscriptionType)
        .filter(item => item.subscriptionStatus === SubscriptionStatusType.ACTIVE)
    : undefined;
};

export enum ExceptionalAddOnGuid {
  LandLine = 'd071f5c9-9310-859a-5a97-67a8e013bb68',
}

export const isRejectedAddOns = (subscription: Subscription): boolean => {
  const rejectedAddons: AddOn[] | undefined = subscription.details?.selectedAddOns?.filter(
    addon => addon.addOnCode === ExceptionalAddOnGuid.LandLine
  );

  return !!(rejectedAddons && rejectedAddons.length > 0);
};

export const isAttachingRingValid = (subscription: Subscription, companyInfo: CompanyInfoState): boolean => {
  if (subscription.details?.mobile?.pbxConfiguration) {
    return false;
  } else {
    const ringSolutionSubscriptions: Subscription[] | undefined = findFromPbxSolutionsByType(
      companyInfo,
      SubscriptionType.MOBILE_PBX
    );
    if (ringSolutionSubscriptions && ringSolutionSubscriptions.length > 0) {
      return true;
    }
  }

  return false;
};
