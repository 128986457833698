import { FIRST_DAY_OF_WEEK, MONTHS, NEXT_PREV_MONTH_LABELS, WEEKDAYS, WEEKDAYS_SHORT } from './calendar.js';
import { isInBrowser } from '../utils/ssrUtils.js';
import { paths } from '../constants/pathVariables.js';
import { translateFn } from './messages.js';

export enum Locale {
  FI = 'fi-FI',
  SV = 'sv-SE',
  EN = 'en-GB',
}

const LOCALSTORAGE_LOCALE_KEY = 'ofLocale';
const DEFAULT_LOCALE = Locale.FI;

function setDocumentLang(locale: Locale) {
  if (isInBrowser()) {
    document.documentElement.lang = locale.substring(0, 2);
  }
}

export function strToLocale(localeStr?: string): Locale {
  switch (localeStr) {
    default:
    case 'fi':
    case Locale.FI:
      return Locale.FI;

    case 'en':
    case Locale.EN:
      return Locale.EN;

    case 'sv':
    case Locale.SV:
      return Locale.SV;
  }
}

function getFromLocalStorageOrDefault(): Locale {
  if (isInBrowser() && localStorage) {
    return strToLocale(localStorage.getItem(LOCALSTORAGE_LOCALE_KEY) || undefined);
  }
  return DEFAULT_LOCALE;
}

let currentLocale: Locale = getFromLocalStorageOrDefault();
setDocumentLang(currentLocale);

/**
 * Sets the site locale
 * @param locale
 */
export function setSiteLocale(locale: Locale) {
  if (isInBrowser()) {
    setDocumentLang(locale);
  }
  currentLocale = locale;
}

/**
 * Sets the site locale and stores it to local storage
 * @param locale
 */
export function setLocale(locale: Locale) {
  setSiteLocale(locale);
  if (isInBrowser()) {
    localStorage.setItem(LOCALSTORAGE_LOCALE_KEY, locale);
  }
}

/**
 * Returns current locale, e.g. fi-FI
 */
export function getLocale(): Locale {
  return currentLocale;
}

/**
 * Primarily resolve based on /sv and /en paths.
 * Since CMS content is NOT localized use localization only on these paths.
 * Everything else is shown as Locale.FI.
 * Note, language selection is still stored in localstorage.
 */
export const resolveLocaleFromPath = (
  path: string,
  siteLanguage: Locale
): { language: Locale; content: Locale } | undefined => {
  if (path === '/sv' || path.startsWith('/sv/')) {
    return { language: Locale.SV, content: Locale.SV };
  }

  if (path === '/en' || path.startsWith('/en/')) {
    return { language: Locale.EN, content: Locale.EN };
  }

  // For localizedPaths we want to honor the selected language, but override Swedish, since we don't support it.
  const localizedPaths = [
    paths.ANONYMOUS_FIXED_BROADBAND_ORDER,
    paths.ANONYMOUS_MOBILE_BROADBAND,
    paths.ANONYMOUS_MOBILE_VOICE,
    paths.AUTHENTICATION_EXT,
    paths.CONTACT,
    paths.CONTACT_FORM,
    paths.CONTACT_INFO,
    paths.DEVICE_CHECKOUT,
    paths.EMPLOYEE_HOME,
    paths.LAITENETTI,
    paths.PUNCHOUT_HOME,
    paths.REGISTER_PAGE,
    paths.SELF_SERVICE_HOME,
    paths.SHOPPING_CART,
    paths.NEW_SHOPPING_BASKET,
    paths.TURBONAPPI,
  ];

  if (localizedPaths.some(localizedPath => decodeURI(path).startsWith(localizedPath))) {
    if (siteLanguage === Locale.SV) {
      return { language: Locale.FI, content: Locale.FI };
    }
  } else if (siteLanguage !== Locale.FI) {
    return { language: Locale.FI, content: Locale.FI };
  }
  return undefined;
};

/**
 * Returns formatted number based on current locale
 */
export function formatNumber(value: number): string {
  const formatter = new Intl.NumberFormat(getLocale()).format;
  return formatter(value);
}

// Replace all occurrences of {} with the val's, and also supports {0}, {1}, ... , {n} for stricter positional parameter
// replacements (in case for example different language translations use the variables in different order). Mixing both
// {} and {n} in the same string is not supported. Also not supported is inserting (positional) parameters inside other
// parameters.
//
// Always use {} if you don't need {n}.
//
// This variable substitution format matches that SLF4J used widely in the Java code (and is common elsewhere too,
// e.g. https://doc.rust-lang.org/std/fmt/). This brings familiarity between the frontend and backend code bases and
// reduces accidental formatting errors when developing both sides.
export function replaceAll(str: string, ...val: string[]) {
  const positionalRegex = /{[0-9]+}/;
  const shortRegex = /{}/;
  const eitherRegex = /{[0-9]*}/;
  if (val.length) {
    if (shortRegex.test(str)) {
      if (positionalRegex.test(str)) {
        throw Error('Can not mix {} and {n} in same localized string');
      }
      val.forEach(value => {
        str = str.replace(shortRegex, value);
      });
    } else if (positionalRegex.test(str)) {
      val.forEach((value, i) => {
        str = str.replace(new RegExp(`\\{${i}}`, 'g'), value);
      });
    }
  }
  if (eitherRegex.test(str)) {
    throw Error(`All variables specified in the string were not given as parameters: ${str}`);
  }
  return str;
}

export const translate = (eng: string, fin: string, ...val: string[]) => {
  switch (currentLocale) {
    case Locale.EN:
      return replaceAll(eng, ...val);

    case Locale.SV:
    case Locale.FI:
    default:
      return replaceAll(fin, ...val);
  }
};

export const weekDays = () => WEEKDAYS[currentLocale];
export const weekDaysShort = () => WEEKDAYS_SHORT[currentLocale];
export const firstDayOfWeek = () => FIRST_DAY_OF_WEEK[currentLocale];
export const months = () => MONTHS[currentLocale];
export const nextPrevMonthLabels = () => NEXT_PREV_MONTH_LABELS[currentLocale];

export const OPEN_INVOICES_HASH = 'avoimet';
export const PAID_INVOICES_HASH = 'maksetut';

export const t = translateFn(translate);
export * from './shared.js';
