import { createContext, useContext } from 'react';
import { pushToDataLayer } from '../../common/analytics.js';
import type { ReactNode } from 'react';

export interface AnalyticsProps {
  pushToDataLayer: (obj: object) => void;
}

const AnalyticsContext = createContext<AnalyticsProps | undefined>(undefined);

export const AnalyticsProvider = ({ children }: { children?: ReactNode }) => {
  return (
    <AnalyticsContext.Provider
      value={{
        pushToDataLayer,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = (): AnalyticsProps => {
  const context = useContext(AnalyticsContext);
  if (context === undefined) {
    throw new Error('useAnalytics must be used within an AnalyticsProvider');
  } else {
    return context;
  }
};
