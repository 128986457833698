import * as CL from '@design-system/component-library';
import type { ChangeEvent, ReactNode } from 'react';
import type { FieldInputProps } from 'formik';

export interface CheckboxComponentProps {
  checked: boolean;
  id?: string;
  children?: ReactNode;
  disabled?: boolean;
  className?: string;
  value?: string | boolean;
  label?: string;
  name?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  field: FieldInputProps<string>;
}

/**
 * CheckboxComponent can have two modes:
 * When value is not given checkbox has values true/false
 * When value is given checkbox saves all the values in one group (name) to a same array
 */
export const CheckboxComponent = (props: CheckboxComponentProps) => {
  const { checked = false, field, ...rest } = props;
  return <CL.Checkbox checked={checked} {...field} {...rest} />;
};
