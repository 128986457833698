import { Dropdown } from '../components/Dropdown.js';
import { eInvoicingOperatorMsg, t } from '../../i18n/index.js';
import { getElectronicInvoiceOperatorDropDownOptions } from '../../utils/billingAccountUtils.js';
import { useSelector } from 'react-redux';
import type { State } from '../../../selfservice/common/store.js';

export interface EOperatorSelectProps {
  name: string;
  label?: string;
}

export const EOperatorSelect = ({ name, label = t.WVLB(eInvoicingOperatorMsg) }: EOperatorSelectProps) => {
  const billChannels = useSelector((state: State) => state.selfservice?.billChannels);
  const eOperatorOptions = getElectronicInvoiceOperatorDropDownOptions(billChannels?.items);

  return <Dropdown name={name} label={label} items={eOperatorOptions} />;
};
