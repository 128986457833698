import { FiveGMobileBroadbandOfferCode, SubscriptionCardType } from '../../../common/enums';
import { SUBSCRIPTION_CHANGE_SERVICE_FEE } from './SubscriptionCardContent';
import {
  euDataMsg,
  formatNumber,
  gBMoMsg,
  mBitsPerSecondMsg,
  recommendationUsersDevicesMsg,
  selectMsg,
  t,
  uploadSpeedMsg,
} from '../../../common/i18n';
import {
  getMobiiliturvaService,
  getReissaajaService,
  getReissunetti1Service,
  getReissunetti2Service,
} from './ServiceContentCreator';
import type { MobileBroadbandSubCardProps } from '../../SubscriptionCard/SubscriptionCard';

const getMobileBroadbandSubscriptionCards = (inPublicStore?: boolean): MobileBroadbandSubCardProps[] => [
  {
    bullets: [
      t.SDTT('Superfast business use'),
      t.SDTU('Video conferences and 8K movies'),
      t.SDTV('All internet users and devices'),
    ],
    description: t.SDTW('For super-fast business use'),
    cardName: '1000m',
    mainHeader: '1000M',
    salesProduct: {
      offerCode: '59b72848-c05c-5ea0-30bf-b704e0610f61',
      onlineModelCode: 'd52855be-83db-6f80-8c50-32f2f788d1f3',
    },
    selectedOffer: FiveGMobileBroadbandOfferCode.MBB_5G_PLUS_1000_M,
    specifications: [
      {
        name: euDataMsg,
        value: { [FiveGMobileBroadbandOfferCode.MBB_5G_PLUS_1000_M]: `70 ${t.U54O(gBMoMsg)}` },
      },
      {
        name: t.CQQ7(uploadSpeedMsg),
        value: { [FiveGMobileBroadbandOfferCode.MBB_5G_PLUS_1000_M]: t.IJNS(mBitsPerSecondMsg, '100') },
      },
    ],
    services: [getMobiiliturvaService(true, inPublicStore), getReissunetti2Service(true)],
    monthlyPrice: 4499,
    oneTimePrice: 899,
  },
  {
    bullets: [
      t.SDTP('Fast business use'),
      t.SDTQ('Video conferences and 4K movies'),
      t.SDTL(recommendationUsersDevicesMsg, '4+', '15+'),
    ],
    description: t.SDTS('For fast business use'),
    cardName: '600m',
    mainHeader: '600M',
    salesProduct: {
      offerCode: '62418d5e-2279-7565-2a8d-47dd0a3fbfb0',
      onlineModelCode: 'd52855be-83db-6f80-8c50-32f2f788d1f3',
    },
    selectedOffer: FiveGMobileBroadbandOfferCode.MBB_5G_PLUS_600_M,
    specifications: [
      {
        name: euDataMsg,
        value: { [FiveGMobileBroadbandOfferCode.MBB_5G_PLUS_600_M]: `58 ${t.U54O(gBMoMsg)}` },
      },
      {
        name: t.CQQ7(uploadSpeedMsg),
        value: { [FiveGMobileBroadbandOfferCode.MBB_5G_PLUS_600_M]: t.IJNS(mBitsPerSecondMsg, '75') },
      },
    ],
    services: [getMobiiliturvaService(true, inPublicStore), getReissunetti1Service(true)],
    monthlyPrice: 3999,
    oneTimePrice: 899,
  },
  {
    bullets: [
      t.SDTN('Active work'),
      t.SDTO('Video meetings and watching shows'),
      t.SDTL(recommendationUsersDevicesMsg, '3-4', '10-15'),
    ],
    description: t.SDTR('For active internet use'),
    cardName: '300m',
    mainHeader: '300M',
    salesProduct: {
      offerCode: '767004e5-27ea-83d6-37a7-a5ce85209abc',
      onlineModelCode: 'd52855be-83db-6f80-8c50-32f2f788d1f3',
    },
    selectedOffer: FiveGMobileBroadbandOfferCode.MBB_5G_PLUS_300_M,
    specifications: [
      {
        name: euDataMsg,
        value: { [FiveGMobileBroadbandOfferCode.MBB_5G_PLUS_300_M]: `${formatNumber(55.5)} ${t.U54O(gBMoMsg)}` },
      },
      {
        name: t.CQQ7(uploadSpeedMsg),
        value: { [FiveGMobileBroadbandOfferCode.MBB_5G_PLUS_300_M]: t.IJNS(mBitsPerSecondMsg, '50') },
      },
    ],
    services: [getReissunetti1Service(true), getMobiiliturvaService(false, inPublicStore)],
    recommended: true,
    monthlyPrice: 3499,
    oneTimePrice: 899,
  },
  {
    cardName: '100m',
    mainHeader: '100M',
    salesProduct: {
      offerCode: 'd5e5c9db-05ed-fee2-5e55-9c924981fe3e',
      onlineModelCode: 'd52855be-83db-6f80-8c50-32f2f788d1f3',
    },
    selectedOffer: FiveGMobileBroadbandOfferCode.MBB_5G_PLUS_100_M,
    specifications: [
      {
        name: euDataMsg,
        value: { [FiveGMobileBroadbandOfferCode.MBB_5G_PLUS_100_M]: `40 ${t.U54O(gBMoMsg)}` },
      },
      {
        name: t.CQQ7(uploadSpeedMsg),
        value: { [FiveGMobileBroadbandOfferCode.MBB_5G_PLUS_100_M]: t.IJNS(mBitsPerSecondMsg, '50') },
      },
    ],
    bullets: [
      t.SDTJ('Smooth everyday life and work'),
      t.SDTK('Meetings and movies'),
      t.SDTL(recommendationUsersDevicesMsg, '1-2', '1-4'),
    ],
    services: [getReissaajaService(true), getMobiiliturvaService(false, inPublicStore)],
    description: t.SDTM('Smooth everyday use'),
    monthlyPrice: 2999,
    oneTimePrice: 899,
  },
  {
    cardName: '50m',
    mainHeader: '50M',
    salesProduct: {
      offerCode: '32b92ef5-8f03-1898-65c7-a71b3e18e74a',
      onlineModelCode: '7ebe883b-c05f-91df-d1e0-90f3c0218d59',
    },
    selectedOffer: FiveGMobileBroadbandOfferCode.MBB_50_M,
    specifications: [
      {
        name: euDataMsg,
        value: { [FiveGMobileBroadbandOfferCode.MBB_50_M]: `32 ${t.U54O(gBMoMsg)}` },
      },
      {
        name: t.CQQ7(uploadSpeedMsg),
        value: { [FiveGMobileBroadbandOfferCode.MBB_50_M]: t.IJNS(mBitsPerSecondMsg, '25') },
      },
    ],
    bullets: [
      t.SDTJ('Smooth everyday life and work'),
      t.SDTK('Meetings and movies'),
      t.SDTL(recommendationUsersDevicesMsg, '1-2', '1-4'),
    ],
    services: [getReissaajaService(true), getMobiiliturvaService(false, inPublicStore)],
    description: t.KVG8('Video calls, cloud services use and light remote working'),
    monthlyPrice: 2299,
    oneTimePrice: 899,
  },
  {
    cardName: '10m',
    mainHeader: '10M',
    salesProduct: {
      offerCode: '0a0ea02c-3ee1-91d7-f0df-0e904a2d4ef7',
      onlineModelCode: 'e3213739-564b-27ea-acd9-45d03665c60c',
    },
    selectedOffer: FiveGMobileBroadbandOfferCode.MBB_10_M,
    specifications: [
      {
        name: euDataMsg,
        value: { [FiveGMobileBroadbandOfferCode.MBB_10_M]: `20 ${t.U54O(gBMoMsg)}` },
      },
      {
        name: t.CQQ7(uploadSpeedMsg),
        value: { [FiveGMobileBroadbandOfferCode.MBB_10_M]: t.IJNS(mBitsPerSecondMsg, '5') },
      },
    ],
    bullets: [
      t.SDTJ('Smooth everyday life and work'),
      t.SDTK('Meetings and movies'),
      t.SDTL(recommendationUsersDevicesMsg, '1-2', '1-4'),
    ],
    services: [getReissaajaService(true), getMobiiliturvaService(false, inPublicStore)],
    description: t.KVG9('Light internet use, reading emails, sending messages'),
    monthlyPrice: 1499,
    oneTimePrice: 899,
  },
];

export const getMobileBroadbandSubscriptionCardContents = (
  inPublicStore?: boolean,
  isChangeOffer?: boolean
): MobileBroadbandSubCardProps[] => {
  const contents = getMobileBroadbandSubscriptionCards().map(cardContent => ({
    ...cardContent,
    buttonText: t.QRYV(selectMsg),
    // Public side doesn't do updates on subs
    serviceFee: isChangeOffer ? SUBSCRIPTION_CHANGE_SERVICE_FEE : undefined,
    cardType: SubscriptionCardType.MOBILE_BROADBAND,
  }));
  // Public side has the products in reverse order compared to NOE.
  if (inPublicStore) {
    contents.reverse();
  }
  return contents;
};
