import * as CL from '@design-system/component-library';
import { HeaderShoppingCart } from '../../HeaderShoppingCart/HeaderShoppingCart.js';
import { SiteContext } from '../../../public/site/SiteContext.js';
import { englishMsg, suomiMsg } from '../../../common/i18n/index.js';
import { paths } from '../../../common/constants/pathVariables.js';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

export type PunchoutHeaderProps = {
  hideCart?: boolean;
};

export const PunchoutHeader = (props: PunchoutHeaderProps) => {
  const { saveUserPreferredLanguage, siteLanguage } = useContext(SiteContext);
  const navigate = useNavigate();

  const languageSelect = {
    languages: [
      { name: suomiMsg, abbr: 'fi' },
      { name: englishMsg, abbr: 'en' },
    ],
    selectedLanguage: siteLanguage.substring(0, 2),
    onLanguageChange: ({ lang }: { lang: string }) => {
      saveUserPreferredLanguage(lang);
    },
  };

  return (
    <CL.Header
      desktopBreakpointOffset={35}
      className="of-public-header"
      languageSelect={languageSelect}
      isLoggedIn={false}
    >
      <CL.HeaderNavigationSection>
        <CL.HeaderLogoAndService
          logo={{
            label: 'Elisa',
            onClick: e => {
              e.preventDefault();
              navigate(paths.PUNCHOUT_HOME);
            },
            url: '/',
          }}
        />
      </CL.HeaderNavigationSection>
      {!props.hideCart && (
        <CL.HeaderNavigationSection>
          <HeaderShoppingCart toShoppingCart={paths.PUNCHOUT_SHOPPING_CART} toCheckout={paths.PUNCHOUT_CHECKOUT} />
        </CL.HeaderNavigationSection>
      )}
    </CL.Header>
  );
};
