import { Highlight } from '../Highlight/Highlight.js';

type SearchResultHeaderPropertyType = number | string | string[] | number[] | boolean | undefined;

export interface SearchResultHeader {
  [key: string]: SearchResultHeaderPropertyType;
}

interface HighlightedSearchResultHeader {
  [key: string]: string | JSX.Element;
}

const getPropertyValueAsString = (prop: SearchResultHeaderPropertyType): string | undefined =>
  Array.isArray(prop) ? prop.join(', ') : prop?.toString();

export const getResultObjectWithHighlightedValues = (
  searchResultHeader: SearchResultHeader,
  matchedFields: string[],
  searchTerms?: string[]
): HighlightedSearchResultHeader =>
  Object.keys(searchResultHeader).reduce((accumulator: HighlightedSearchResultHeader, fieldName) => {
    const currentPropAsString = getPropertyValueAsString(searchResultHeader[fieldName]);
    if (currentPropAsString) {
      accumulator[fieldName] = matchedFields?.includes(fieldName) ? (
        <Highlight text={currentPropAsString} highlights={searchTerms ?? []} />
      ) : (
        currentPropAsString
      );
    }
    return accumulator;
  }, {});
