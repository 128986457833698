import { SiteContext } from '../../../public/site/SiteContext.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import {
  entertainmentBarringP3Msg,
  premiumRateBarringP4Msg,
  serviceBarringP2Msg,
  t,
  viewBlockedNumbersMsg,
} from '../../../common/i18n/index.js';
import { useContext } from 'react';

export const SmsBarringsTooltipBody = () => (
  <>
    <div className={dsClass.PADDING_TOP_4}>
      <h4 className="ea-h4 ea-h4--no-fluid">{t.P0CE(premiumRateBarringP4Msg)}</h4>
      {t.IH2K('Blocks SMS messages and mobile payments to adult entertainment services.')}
    </div>
    <div className={dsClass.PADDING_TOP_4}>
      <h4 className="ea-h4 ea-h4--no-fluid">{t.P111(entertainmentBarringP3Msg)}</h4>
      {t.JB8R(
        'Blocks SMS messages and mobile payments, e.g. to various TV chats and quizzes, and phone invoice-based purchases from app stores using Android and Windows devices. Also blocks participation in phone votes and marketing campaigns. Includes blocking of calls to entertainment numbers.'
      )}
    </div>
    <div className={dsClass.PADDING_TOP_4}>
      <h4 className="ea-h4 ea-h4--no-fluid">{t.YX3Y(serviceBarringP2Msg)}</h4>
      {t.DMD6(
        'Blocks SMS and mobile payments, e.g. by blocking pizza and travel ticket orders. Also blocks participation in telephone donations and the use of directory enquiries and ordering services (e.g. food or drink vending machines). Includes a block on entertainment and adult entertainment numbers.'
      )}
    </div>
    <div className={dsClass.PADDING_TOP_4}>
      <h4 className="ea-h4 ea-h4--no-fluid">{t.OMO5('General service barring P1')}</h4>
      {t.MZ5C(
        'Blocks SMS messages and mobile payments, e.g. to timetable and information services and banking services. Also blocks ordering of taxis. Includes blocking of adult entertainment, entertainment and service numbers.'
      )}
    </div>
    <div className={dsClass.PADDING_VERTICAL_4}>
      <a
        className="of-barrings-content__link"
        href={`${useContext(SiteContext).siteBaseUrl}/ohje/lisamaksulliset-palvelut-ja-estoluokat`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t.FVU9(viewBlockedNumbersMsg)}
        <i className="ea-icon ea-icon--northeast-arrow" />
      </a>
    </div>
  </>
);
