import * as CL from '@design-system/component-library';
import { CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE } from '../../common/utils/billingAccountUtils.js';
import { DialogWrapper, buttonsForSubmitAndBack } from '../DialogWrapper/index.js';
import { SourceSystem } from '../../generated/api/sourceSystem.js';
import { confirmMsg, t } from '../../common/i18n/index.js';
import { fetchBillingAccounts } from '../../common/fetch.js';
import { getBillingAccountListItems } from '../AddOrSelectBillingAccountsV2/AddOrSelectBillingAccounts.js';
import { getPrimaryMdmId } from '../../common/utils/accountUtils';
import { useAuth } from '../../public/site/AuthProvider';
import { useEffect, useState } from 'react';
import { useSearchParams } from '../../common/hooks/useSearchParams.js';
import type { BillingAccountSearchResponse } from '../../generated/api/billingAccountSearchResponse.js';

export interface ChangeBillingAccountDialogAttrs {
  billingAccountId: string;
  changeRequestInProgress?: boolean;
  displayAddNewOption?: boolean;
  headerText: string;
  description: string | JSX.Element;
  detailedView?: boolean;
  searchable?: boolean;
  onBeginCreateBillingAccount?: () => void;
  onCloseDialog: () => void;
  onSubmit: (bid: string, bdid: string) => void;
}

export type ChangeBillingAccountDialogProps = ChangeBillingAccountDialogAttrs;

export const ChangeBillingAccountDialog = (props: ChangeBillingAccountDialogProps) => {
  const {
    billingAccountId,
    changeRequestInProgress,
    description,
    displayAddNewOption = true,
    headerText,
    onBeginCreateBillingAccount,
    onCloseDialog,
  } = props;

  const [selectedBillingAccountId, setSelectedBillingAccountId] = useState(billingAccountId);
  const [searchText, setSearchText] = useState('');
  const [billingAccountSearchResponses, setBillingAccountSearchResponses] = useState<
    BillingAccountSearchResponse[] | undefined
  >(undefined);
  const { mdmId } = useSearchParams<{ mdmId?: string }>();
  const { authenticatedUser } = useAuth();

  useEffect(() => {
    async function loadBillingAccounts() {
      return await fetchBillingAccounts(
        { useSearchService: true, sourceSystem: SourceSystem.SFDC },
        { mdmId: mdmId || getPrimaryMdmId(authenticatedUser) || undefined }
      );
    }

    loadBillingAccounts().then(res => setBillingAccountSearchResponses(res.searchResults));
  }, [mdmId, authenticatedUser]);

  const onChangeBillingAccountId = (evt: HTMLLIElement) => {
    const selected = evt.dataset.value || selectedBillingAccountId;
    if (selected) {
      if (selected === CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE && onBeginCreateBillingAccount) {
        onBeginCreateBillingAccount();
      } else {
        setSelectedBillingAccountId(selected);
      }
    }
    setSearchText('');
  };

  const onSubmit = () => {
    const billingAccount = billingAccountSearchResponses?.find(
      ba => ba.result.billingAccountId === selectedBillingAccountId
    );
    if (billingAccount) {
      props.onSubmit(selectedBillingAccountId, billingAccount.result.billingAccountDisplayId!);
    }
  };

  const formattedBillingAccounts = getBillingAccountListItems(
    displayAddNewOption,
    billingAccountSearchResponses,
    searchText
  ).map(x => {
    return { ...x, id: x.value };
  });

  return (
    <DialogWrapper
      buttons={buttonsForSubmitAndBack(
        onCloseDialog,
        onSubmit,
        undefined,
        undefined,
        t.QVYK(confirmMsg),
        undefined,
        changeRequestInProgress
      )}
      closeable
      header={headerText}
      onCloseDialog={onCloseDialog}
    >
      {typeof description === 'string' ? <p>{description}</p> : description}
      <CL.Combobox
        onValueSelect={item => {
          // Pressing esc calls onValueSelect without an item, so we have to
          // have this check here...
          if (item) {
            onChangeBillingAccountId(item);
          }
        }}
        items={formattedBillingAccounts}
        selectedValue={selectedBillingAccountId}
        onValueChange={value => {
          if (value) {
            setSearchText(value);
          }
        }}
        selectedItemFormat="label"
        i18n_combobox_buttonAriaLabel={t.HVS2('Selected billing account')}
      />
    </DialogWrapper>
  );
};
