import { BreadCrumbs } from '../BreadCrumbs/BreadCrumbs.js';
import { DialogContainer } from '../SelfService/selfServiceDialogs.js';
import { EmptyOrError, getErrorPropsFromCommonErrors } from '../EmptyOrError/EmptyOrError.js';
import { Outlet, useRevalidator } from 'react-router-dom';
import { ScrollToTopWhenSearchParamsChange } from '../../common/utils/browserUtils.js';
import { corporateCustomersMsg, internetSubscriptionsMsg, subscriptionsMsg, t } from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { paths } from '../../common/constants/pathVariables.js';
import { resetAddressSearchResult, resetCompanySearchResult } from '../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import type { CommonError } from '../../common/types/errors.js';

import './FixedBroadbandOrder.scss';

interface FixedBroadbandOrderProps {
  currentPath: string;
  disableBreadcrumbs: boolean;
  errors?: CommonError[];
}

const getResetHistoryStack = (page: string) => {
  switch (page) {
    case paths.ANONYMOUS_FIXED_BROADBAND_ORDER_CONFIRMATION:
      return -3;
    case paths.ANONYMOUS_FIXED_BROADBAND_ORDER_CHECKOUT:
      return -2;
    default:
      return -1;
  }
};

export const FixedBroadbandOrder = ({ currentPath, disableBreadcrumbs, errors }: FixedBroadbandOrderProps) => {
  const dispatch = useDispatch();
  const revalidator = useRevalidator();

  const reset = () => {
    dispatch(resetAddressSearchResult());
    dispatch(resetCompanySearchResult());
    revalidator.revalidate();
    history.go(getResetHistoryStack(currentPath));
  };

  const apiErrors = getErrorPropsFromCommonErrors(errors);

  return (
    <>
      <ScrollToTopWhenSearchParamsChange />
      <div className="of-fixed-broadband-order">
        {disableBreadcrumbs || (
          <BreadCrumbs
            breadCrumbPaths={[
              { name: t.KBLX(corporateCustomersMsg), path: '/' },
              { name: t.XQ5R(subscriptionsMsg), path: paths.SUBSCRIPTIONS_PUBLIC },
              { name: t.SEYV(internetSubscriptionsMsg), path: paths.INTERNET_SUBSCRIPTIONS_PUBLIC },
              { name: 'Netti Lite', path: paths.NETTI_LITE },
              { name: t.CZ89('Availability') },
            ]}
          />
        )}
        <div className={`of-fixed-broadband-order__content ${dsClass.PADDING_BOTTOM_6}`}>
          {apiErrors ? (
            <div className={dsClass.PADDING_TOP_10}>
              <EmptyOrError
                bottomElement={apiErrors.bottomElement}
                buttonText={apiErrors.buttonText}
                critical={apiErrors.critical}
                id={apiErrors.id}
                onButtonClick={reset}
                text={apiErrors.text}
              />
            </div>
          ) : (
            <Outlet />
          )}
        </div>
        <DialogContainer />
      </div>
    </>
  );
};
