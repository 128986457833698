/**
 * Online UI API
 * # Summary Elisa B2B Online UI API  # Usage This API is used in the `https://yrityksille.elisa.fi/` pages.  # Identifiers 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Request to validate one time password and pair subscription with employee
 */
export interface ValidateSubscriptionPairingRequest { 
    /**
     * MSISDN of the subscription which employee wants to pair or phone number of the contact which is tagged to the subscription employee wants to pair
     */
    msisdn: string;
    /**
     * Type of subscription employee wants to pair
     */
    subscriptionType: ValidateSubscriptionPairingRequest.SubscriptionTypeEnum;
    /**
     * MDM ID of the account where the subscription should be searched
     */
    mdmId?: string;
    /**
     * One time password received by employee
     */
    otp: string;
}
export namespace ValidateSubscriptionPairingRequest {
    export type SubscriptionTypeEnum = 'MOBILE' | 'OTHER';
    export const SubscriptionTypeEnum = {
        MOBILE: 'MOBILE' as SubscriptionTypeEnum,
        OTHER: 'OTHER' as SubscriptionTypeEnum
    };
}


