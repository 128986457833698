import * as CL from '@design-system/component-library';
import { CREATE_NEW_CONTACT_OPTION_VALUE } from '../../common/react-hook-form/fields/ContactDropdown.js';
import { Component } from 'react';
import { ContactRole, SalesType, SimType } from '../../generated/api/models.js';
import {
  Field,
  OrderSubscriptionConfigurationFields,
  getNextAllowedDay,
} from './OrderSubscriptionConfigurationFields.js';
import { FormWrapper } from './FormWrapper.js';
import { ModelType, PhoneNumberOwnerType, SimCardSelection } from '../../common/enums.js';
import { OrderSummary, OrderSummaryType } from '../OrderSummary/OrderSummary.js';
import { PhaseIndicator } from '../PhaseIndicator/index.js';
import { WizardActions } from '../WizardActions/index.js';
import {
  activationFeeMsg,
  deliveryDetailsMsg,
  monthMsg,
  productInformationsMsg,
  subscriptionMsg,
  t,
} from '../../common/i18n/index.js';
import {
  commercialProductsAmountChange,
  prereserveNumbers,
  validatePhoneNumber,
} from '../../selfservice/actions/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { filterAddOnsBasedOnRing, filterAddonsFromRingSubs } from '../../common/utils/ringUtils.js';
import { formatSum } from '../../common/utils/priceUtils.js';
import { getCommercialProductSum } from '../../common/utils/commercialProductUtils.js';
import { isVoiceModel } from '../OrderSubscriptionSelection/utils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { setHistoryState } from '../../common/utils/stateUtils.js';
import { throwError } from '../../common/utils/errorUtils.js';
import classNames from 'classnames';
import type { AddOnSelectionType, OrderConfiguration, PurposeOfUseFormValues } from './FormWrapper.js';
import type { AddedAddon } from '../../common/types/addon.js';
import type { AliasSupplier } from '../Checkout/steps/CartProductsStep.js';
import type { AttachRingProps } from '../AttachRing/AttachRing.js';
import type { CommonError } from '../../common/types/errors.js';
import type { ConfiguredCommercialProduct, ConfiguredOffer } from '../../common/types/commercialProduct.js';
import type { Contact, ContactHeader } from '../../generated/api/models.js';
import type { Dispatch } from 'redux';
import type { Location, NavigateFunction, Search } from 'react-router-dom';
import type { NumberPrivacyModel } from '../NumberPrivacy/NumberPrivacy.js';
import type { OrderSubscriptionStateParams } from '../OrderSubscription/OrderSubscriptionLayout.js';
import type { PhoneNumberOwner, SelectedPhoneNumber, SimCardConfiguration } from '../../common/types/subscription.js';
import type { RingSettingsConfiguration } from '../AttachPbxContent/AttachPbxContent.js';
import type { ValidatedPhoneNumber } from '../../common/utils/phoneNumberUtils.js';

import './OrderSubscriptionConfiguration.scss';

interface OrderSubscriptionConfigurationOwnProps {
  dispatch: Dispatch;
  location: Location;
  search: Search;
  navigate: NavigateFunction;
  orderModelType: ModelType;
  orderStateParams: OrderSubscriptionStateParams;
  holidays: Date[];
  contacts: Contact[] | ContactHeader[];
  phoneNumbers?: string[];
  phoneNumbersLoading?: boolean;
  validatedPhoneNumbers: ValidatedPhoneNumber[];
  companyName: string;
}

type OrderObject = {
  purposeOfUseOrContacts: Record<string, PurposeOfUseFormValues>;
  selectedPhoneNumbers: Record<string, SelectedPhoneNumber | undefined>;
  phoneNumberOwners: Record<string, PhoneNumberOwner | undefined>;
  numberPublicities: Record<string, NumberPrivacyModel | undefined>;
  simCardConfigurations: Record<string, SimCardConfiguration | undefined>;
  addOns: Record<string, AddOnSelectionType | undefined>;
};

export type OrderSubscriptionConfigurationProps = OrderSubscriptionConfigurationOwnProps & Partial<AttachRingProps>;

type PrepareSaveValuesFns = Record<string, AliasSupplier>;

interface OrderSubscriptionConfigurationOwnState {
  errors: CommonError[];
  // We assume that the component isn't rendered at all without this.
  selectedOffer: ConfiguredOffer;
  prepareSaveValuesFns?: PrepareSaveValuesFns;
  isRingSelected: boolean;
}

export const getDate = (value: string): Date => {
  const dateParts = value.split('.');
  return new Date(
    Number.parseInt(dateParts[2], 10),
    Number.parseInt(dateParts[1], 10) - 1,
    Number.parseInt(dateParts[0], 10)
  );
};

const isRecordWithEntries = <T,>(record?: Record<string, T>): record is Record<string, T> =>
  record != null && Object.keys(record).length > 0;

const findAddedAddOns = (stateAddOns?: AddedAddon[], addOnSelection?: AddOnSelectionType) => {
  const productAddOns =
    stateAddOns
      ?.filter(addOn => !addOnSelection?.removedAddOns?.includes(addOn.addOnCode))
      .filter(addOn => !addOnSelection?.addedAddOns?.some(selection => selection.addOnCode === addOn.addOnCode)) || [];
  return [...productAddOns, ...(addOnSelection?.addedAddOns || [])];
};

const getDeliveryOptionsPath = (modelType: ModelType) => {
  switch (modelType) {
    case ModelType.MobileBroadband:
      return paths.PS_BROADBAND_SUBSCRIPTION_DELIVERY_INFO;
    case ModelType.MobileM2M:
      return paths.PS_BROADBAND_SUBSCRIPTION_NEW_M2M_DELIVERY_INFO;
    case ModelType.Voice:
    case ModelType.VoiceSME:
      return paths.PS_MOBILE_SUBSCRIPTION_NEW_DELIVERY_INFO;
    default:
      throwError(`Unknown model type: ${modelType}, cannot determine delivery options path`);
      return undefined;
  }
};

const getDeliveryConfiguredCommercialProduct = (
  product: Readonly<ConfiguredCommercialProduct>,
  isMobileModel: boolean,
  orderObject: Readonly<OrderObject>,
  index: number,
  isRingSub: boolean
): ConfiguredCommercialProduct => {
  const { numberPublicities, phoneNumberOwners, selectedPhoneNumbers, purposeOfUseOrContacts } = orderObject;
  const addedAddonsUnfiltered = findAddedAddOns(product.addedAddOns, orderObject.addOns[index]);
  const addedAddOns: AddedAddon[] = isRingSub ? filterAddonsFromRingSubs(addedAddonsUnfiltered) : addedAddonsUnfiltered;
  const deliveryProduct: ConfiguredCommercialProduct = {
    ...product,
    purposeOfUseOrContact: purposeOfUseOrContacts[index],
    simCardConfiguration: isMobileModel ? orderObject.simCardConfigurations[index] : product.simCardConfiguration,
    addedAddOns: addedAddOns.length > 0 ? addedAddOns : undefined,
  };

  if (deliveryProduct.purposeOfUseOrContact.contactId === CREATE_NEW_CONTACT_OPTION_VALUE) {
    deliveryProduct.purposeOfUseOrContact.newContact = {
      email: deliveryProduct.purposeOfUseOrContact.email!,
      firstName: deliveryProduct.purposeOfUseOrContact.firstName!,
      lastName: deliveryProduct.purposeOfUseOrContact.lastName!,
      phoneNumber: deliveryProduct.purposeOfUseOrContact.phoneNumber!,
      roles: [ContactRole.ENDUSER_CONTACT],
    };
  }

  // Map validation object selected phone number to selected commercial product selected phone number
  if (isRecordWithEntries(selectedPhoneNumbers)) {
    deliveryProduct.selectedPhoneNumber = selectedPhoneNumbers[index];
  }
  if (isRecordWithEntries(numberPublicities)) {
    deliveryProduct.numberPublicity = numberPublicities[index]?.numberPublicity;
  }
  // // Map validation object phone number owners to selected commercial product phone number owners
  if (isRecordWithEntries(phoneNumberOwners)) {
    Object.keys(phoneNumberOwners).forEach(key => {
      const objectIndex = Number(key.replace(/\D/gi, () => ''));
      const phoneNumberOwner = phoneNumberOwners[key];
      if (index === objectIndex && phoneNumberOwner) {
        deliveryProduct.phoneNumberOwner = {
          ownerType: phoneNumberOwner.ownerType,
          permitDonoringOnFixedContract: phoneNumberOwner.permitDonoringOnFixedContract,
          personEmail: phoneNumberOwner.personEmail,
        };

        deliveryProduct.phoneNumberOwner!.ownerType === PhoneNumberOwnerType.PERSON
          ? delete deliveryProduct.phoneNumberOwner!.permitDonoringOnFixedContract
          : delete deliveryProduct.phoneNumberOwner!.personEmail;
      }
    });
  }
  return deliveryProduct;
};

const getDeliveryPurposeOfUserOrContacts = (configuration?: Readonly<OrderConfiguration['configuration']>) => {
  return Object.keys(configuration || {})
    .map((cartItemInstanceId): { cartItemInstanceId: string; value: PurposeOfUseFormValues } => {
      const purposeOfUse = configuration?.[cartItemInstanceId].purposeOfUseOrContact;
      return {
        cartItemInstanceId: cartItemInstanceId,
        value: {
          contactId: purposeOfUse?.contactId,
          purposeOfUse: purposeOfUse?.purposeOfUse,
          firstName: purposeOfUse?.firstName,
          lastName: purposeOfUse?.lastName,
          email: purposeOfUse?.email,
          phoneNumber: purposeOfUse?.phoneNumber,
          costCenter: purposeOfUse?.costCenter,
          employeeNumber: purposeOfUse?.employeeNumber,
          selected: purposeOfUse?.selected,
        },
      };
    })
    .reduce((a, v) => ({ ...a, [v.cartItemInstanceId]: v.value }), {});
};

const getDeliveryNumberPublicities = (configuration?: Readonly<OrderConfiguration['configuration']>) =>
  Object.keys(configuration || {})
    .map((cartItemInstanceId): { cartItemInstanceId: string; value: NumberPrivacyModel } => {
      const numberPublicity =
        configuration?.[cartItemInstanceId].numberPublicity !== undefined
          ? configuration[cartItemInstanceId].numberPublicity === 'true'
          : true; // set default to true

      return {
        cartItemInstanceId: cartItemInstanceId,
        value: {
          numberPublicity: numberPublicity,
        },
      };
    })
    .reduce((a, v) => ({ ...a, [v.cartItemInstanceId]: v.value }), {});

const getDeliverySelectedPhoneNumbers = (configuration?: Readonly<OrderConfiguration['configuration']>) =>
  Object.keys(configuration || {})
    .map((cartItemInstanceId): { cartItemInstanceId: string; value?: SelectedPhoneNumber } => {
      const selectPhoneNumber = configuration?.[cartItemInstanceId]?.selectPhoneNumber;
      return {
        cartItemInstanceId: cartItemInstanceId,
        value: selectPhoneNumber && {
          type: selectPhoneNumber.type,
          existingPhoneNumber: selectPhoneNumber.existingPhoneNumber,
          newPhoneNumber: selectPhoneNumber.newPhoneNumber,
          transferDate: selectPhoneNumber.transferDate ? getDate(selectPhoneNumber.transferDate) : undefined,
        },
      };
    })
    .reduce((a, v) => ({ ...a, [v.cartItemInstanceId]: v.value }), {});

const getDeliveryPhoneNumberOwners = (configuration?: Readonly<OrderConfiguration['configuration']>) =>
  Object.keys(configuration || {})
    .map((cartItemInstanceId): { cartItemInstanceId: string; value?: PhoneNumberOwner } => {
      const numberOwner = configuration?.[cartItemInstanceId]?.selectPhoneNumber?.numberOwner;
      return {
        cartItemInstanceId: cartItemInstanceId,
        value: numberOwner && {
          ownerType: numberOwner.type,
          permitDonoringOnFixedContract: numberOwner?.permitDonoringOnFixedContract,
          personEmail: numberOwner?.personEmail,
        },
      };
    })
    .reduce((a, v) => ({ ...a, [v.cartItemInstanceId]: v.value }), {});

const getDeliverySimCardConfigurations = (configuration?: Readonly<OrderConfiguration['configuration']>) =>
  Object.keys(configuration || {})
    .map((cartItemInstanceId): { cartItemInstanceId: string; value?: SimCardConfiguration } => {
      const simCardConfiguration = configuration?.[cartItemInstanceId]?.simCardConfiguration;
      return {
        cartItemInstanceId: cartItemInstanceId,
        value: simCardConfiguration && {
          simType:
            simCardConfiguration?.simType === 'ACTIVATE_EXISTING' ? SimType.PHYSICAL : simCardConfiguration?.simType,
          simCardNumber: simCardConfiguration?.simCardNumber,
          simSelection:
            simCardConfiguration?.simType === 'ACTIVATE_EXISTING'
              ? SimCardSelection.ACTIVATE_EXISTING
              : SimCardSelection.ORDER_NEW,
        },
      };
    })
    .reduce((a, v) => ({ ...a, [v.cartItemInstanceId]: v.value }), {} as OrderObject['simCardConfigurations']);

const getAddOnsConfigurations = (configuration?: Readonly<OrderConfiguration['configuration']>) =>
  Object.keys(configuration || {})
    .map((cartItemInstanceId): { cartItemInstanceId: string; value?: AddOnSelectionType } => {
      const addedAddOnsConfiguration = configuration?.[cartItemInstanceId]?.addOns;
      return {
        cartItemInstanceId: cartItemInstanceId,
        value: addedAddOnsConfiguration,
      };
    })
    .reduce((a, v) => ({ ...a, [v.cartItemInstanceId]: v.value }), {} as OrderObject['addOns']);

const getRingSettings = (
  phoneNumber: string,
  offer: Readonly<ConfiguredOffer>
): RingSettingsConfiguration | undefined => {
  return offer.selectedCommercialProducts.find(
    product => product?.ringSettingsConfiguration?.corporateNumber === phoneNumber
  )?.ringSettingsConfiguration;
};

// TODO: rename? this component could probably handle all checkouts so its not subscription bound
export class OrderSubscriptionConfiguration extends Component<
  OrderSubscriptionConfigurationProps,
  OrderSubscriptionConfigurationOwnState
> {
  constructor(props: OrderSubscriptionConfigurationProps) {
    super(props);

    // FIXME preferably fire this when the user actually chooses 'Uusi numero'
    // (i.e. move it to SelectPhoneNumber (and see that the loading indicator works))
    if (isVoiceModel(this.props.orderModelType)) {
      this.props.dispatch(prereserveNumbers(undefined, true));
    }
    const selectedOffer = this.props.orderStateParams.selectedOffer!;

    this.state = this.props.location.state
      ? { ...this.props.location.state, errors: [] }
      : {
          errors: [],
          selectedOffer: {
            offer: selectedOffer.offer,
            onlineModelCode: selectedOffer.onlineModelCode,
            onlineModelName: selectedOffer.onlineModelName,
            selectedCommercialProducts: selectedOffer.selectedCommercialProducts.map(selectedCp => ({
              addedAddOns: selectedCp.addedAddOns,
              category: selectedCp.category,
              commercialProduct: selectedCp.commercialProduct,
              numberPublicity: selectedCp.numberPublicity,
              phoneNumberOwner: selectedCp.phoneNumberOwner,
              purposeOfUseOrContact: selectedCp.purposeOfUseOrContact,
              selectedCampaignAssociation: selectedCp.selectedCampaignAssociation,
              selectedPhoneNumber: selectedCp.selectedPhoneNumber && {
                ...selectedCp.selectedPhoneNumber,
                transferDate:
                  (selectedCp.selectedPhoneNumber && selectedCp.selectedPhoneNumber.transferDate) ||
                  getNextAllowedDay(this.props.holidays),
              },
              simCardConfiguration: selectedCp.simCardConfiguration,
              campaignDescription: selectedCp.campaignDescription,
              ringSettingsConfiguration: selectedCp.ringSettingsConfiguration,
              configuredRingOffer: selectedCp.configuredRingOffer,
            })),
            selectedCampaignAssociation: selectedOffer.selectedCampaignAssociation,
            voucher: selectedOffer.voucher,
          },
          isRingSelected: false,
        };
    this.onRingConfigurationChange = this.onRingConfigurationChange.bind(this);
    this.onRingSelectionChange = this.onRingSelectionChange.bind(this);
    this.onAddOnSelectionChange = this.onAddOnSelectionChange.bind(this);
  }

  handleBackClick() {
    history.back();
  }

  handleOnSuccess(data?: Readonly<OrderConfiguration>) {
    const isMobileModel = [
      ModelType.MobileBroadband,
      ModelType.MobileM2M,
      ModelType.Voice,
      ModelType.VoiceSME,
    ].includes(this.props.orderModelType);

    const configuration = data?.configuration;
    const orderObject: OrderObject = {
      purposeOfUseOrContacts: getDeliveryPurposeOfUserOrContacts(configuration),
      numberPublicities: getDeliveryNumberPublicities(configuration),
      selectedPhoneNumbers: getDeliverySelectedPhoneNumbers(configuration),
      phoneNumberOwners: getDeliveryPhoneNumberOwners(configuration),
      simCardConfigurations: getDeliverySimCardConfigurations(configuration),
      addOns: getAddOnsConfigurations(configuration),
    };

    const selectedOffer = {
      ...this.state.selectedOffer,
      selectedCommercialProducts: this.state.selectedOffer.selectedCommercialProducts.map((scp, idx) => {
        return getDeliveryConfiguredCommercialProduct(scp, isMobileModel, orderObject, idx, this.state.isRingSelected);
      }),
    };
    setHistoryState({ selectedOffer }); // Store the 'enriched' selectedOffer, so we have all the needed state present in history

    const deliveryOptionsPath = getDeliveryOptionsPath(this.props.orderModelType);
    if (deliveryOptionsPath) {
      this.props.navigate(deliveryOptionsPath + this.props.search, {
        state: {
          ...this.props.orderStateParams,
          selectedOffer,
        },
      });
    }
  }

  onRingConfigurationChange(
    cartItemInstanceId: string,
    configuredOffer?: Readonly<ConfiguredOffer>,
    pbxConfiguration?: Readonly<RingSettingsConfiguration>
  ) {
    this.setState(previousState => {
      const newState = { ...previousState };
      // FIXME hack to get number.. no cart item uuid here so it works..
      newState.selectedOffer.selectedCommercialProducts[Number(cartItemInstanceId)].configuredRingOffer =
        configuredOffer;
      newState.selectedOffer.selectedCommercialProducts[Number(cartItemInstanceId)].ringSettingsConfiguration =
        pbxConfiguration;
      return newState;
    });
  }

  onRingSelectionChange() {
    this.setState(previousState => ({ ...previousState, isRingSelected: !previousState.isRingSelected }));
  }

  onAddOnSelectionChange(cartItemInstanceId: string, addedAddOns: AddedAddon[]) {
    this.setState(prevState => {
      const updatedState = { ...prevState };
      updatedState.selectedOffer.selectedCommercialProducts[Number(cartItemInstanceId)].addedAddOns = addedAddOns;
      return updatedState;
    });
  }

  handleAmountChange(newAmount: number) {
    if (!newAmount || newAmount <= 0) {
      newAmount = 1;
    }
    const changedOffer = this.state.selectedOffer;
    let updatedProducts = changedOffer.selectedCommercialProducts;
    if (updatedProducts.length < newAmount) {
      while (updatedProducts.length < newAmount) {
        updatedProducts.push({
          // TODO, those [0]'es...
          addedAddOns: updatedProducts[0].addedAddOns,
          category: updatedProducts[0].category,
          commercialProduct: updatedProducts[0].commercialProduct,
          purposeOfUseOrContact: updatedProducts[0].purposeOfUseOrContact,
          campaignDescription: updatedProducts[0].campaignDescription,
        });
      }
    } else if (updatedProducts.length > newAmount) {
      updatedProducts = updatedProducts.slice(0, newAmount);
    }
    if (this.state.prepareSaveValuesFns) {
      // When new amount is equal to current amount of products where are reducing fns
      if (Object.keys(this.state.prepareSaveValuesFns).length >= newAmount) {
        const newPrepareSaveValuesFns = Object.keys(this.state.prepareSaveValuesFns).reduce((prev, current) => {
          let value = { ...prev };
          const valueIndex = Number(current.substring(current.indexOf('[') + 1, current.indexOf(']')));
          if (valueIndex < newAmount) {
            value = { ...value, [current]: this.state.prepareSaveValuesFns![current] };
          }
          return value;
        }, {});
        this.setState(prevState => ({
          ...prevState,
          prepareSaveValuesFns: newPrepareSaveValuesFns,
        }));
      }
    }
    this.setState({
      errors: [],
      selectedOffer: { ...changedOffer, selectedCommercialProducts: updatedProducts },
    });
    this.props.dispatch(commercialProductsAmountChange(newAmount));
  }

  onSelectUseExistingPhoneNumber = (_cartItemInstanceId: string, existingPhoneNumber: string) => {
    this.props.dispatch(validatePhoneNumber(existingPhoneNumber, true));
  };

  render() {
    const { offer, selectedCommercialProducts, selectedCampaignAssociation } = this.state.selectedOffer;
    const ringFilteredProducts = filterAddOnsBasedOnRing(selectedCommercialProducts, this.state.isRingSelected);
    const amount = ringFilteredProducts.length;
    const commercialProduct = offer.commercialProducts[0];

    // Create <amount> copies of the CP
    const totalCommercialProducts: ConfiguredCommercialProduct[] = ringFilteredProducts.map(scp => ({
      addedAddOns: scp.addedAddOns,
      commercialProduct: scp.commercialProduct,
      purposeOfUseOrContact: scp.purposeOfUseOrContact,
    }));

    const totalRingProducts: ConfiguredCommercialProduct[] = ringFilteredProducts
      .filter(product => product.configuredRingOffer)
      .map(product => product.configuredRingOffer!.selectedCommercialProducts[0]);

    const { holidays, phoneNumbers, companyName } = this.props;

    const formFields: Field[] = [];
    if (this.props.orderModelType === ModelType.MobileBroadband) {
      formFields.push(Field.PURPOSE_OF_USE, Field.SIMCARD, Field.ADDONS);
    } else if (this.props.orderModelType === ModelType.MobileM2M) {
      formFields.push(Field.PURPOSE_OF_USE, Field.M2M_SIMCARD);
    } else if (isVoiceModel(this.props.orderModelType)) {
      formFields.push(
        Field.PURPOSE_OF_USE,
        Field.SELECT_PHONE_NUMBER,
        Field.NUMBER_PRIVACY,
        Field.SIMCARD,
        Field.VAKIO,
        Field.ADDONS
      );
    }

    const selectedCommercialProductPrice = getCommercialProductSum(
      ringFilteredProducts,
      selectedCampaignAssociation,
      SalesType.NEW_SALE
    );

    return (
      <FormWrapper
        onSuccess={data => {
          this.handleOnSuccess(data);
        }}
        selectedCommercialProducts={ringFilteredProducts}
      >
        <div className="of-order-product-details">
          <div className="of-order-product-details__phaseindicator-container">
            <PhaseIndicator
              phases={[
                {
                  displayName: t.R4EV(productInformationsMsg),
                },
                {
                  displayName: t.T78Y(deliveryDetailsMsg),
                },
              ]}
              currentPhaseIndex={0}
            />
          </div>
          <CL.Grid className={dsClass.MARGIN_HORIZONTAL_6}>
            <CL.GridRow>
              <CL.GridCol className={dsClass.PADDING_0}>
                <h3>{t.BIS7('Products included in order')}</h3>
              </CL.GridCol>
            </CL.GridRow>
            <CL.GridRow className="of-order-product-details__product-row">
              <CL.GridCol
                colsXL={3}
                colsL={3}
                colsM={1}
                colsS={1}
                colsXS={1}
                className="of-order-product-details__product-row-image"
              >
                <span
                  className={classNames(
                    'ea-picto',
                    this.props.orderModelType === ModelType.MobileBroadband && 'ea-picto--mobilebb',
                    this.props.orderModelType === ModelType.Voice && 'ea-picto--sim',
                    this.props.orderModelType === ModelType.VoiceSME && 'ea-picto--sim',
                    this.props.orderModelType === ModelType.MobileM2M && 'ea-picto--mobile-m2m'
                  )}
                />
              </CL.GridCol>
              <CL.GridCol colsXL={9} colsL={9} colsM={5} colsS={5} colsXS={3}>
                <CL.Grid>
                  <CL.GridRow>
                    <CL.GridCol colsXL={9} colsL={9} colsM={6} colsS={6} colsXS={4}>
                      <div>
                        <h3 className={`of-order-product-details__product-name ${dsClass.MARGIN_BOTTOM_0}`}>
                          {commercialProduct.commercialProductName}
                        </h3>
                        <div>{ringFilteredProducts[0].campaignDescription}</div>
                      </div>
                    </CL.GridCol>
                    <CL.GridCol colsL={3} colsM={6} colsS={6} colsXS={4}>
                      <div className="of-order-product-details__quantity-and-price">
                        <CL.Quantity
                          minValue="1"
                          maxValue="99"
                          currentValue={amount}
                          onChange={(newAmount: number) => this.handleAmountChange(newAmount)}
                        />
                        <h4 className={`${dsClass.MARGIN_TOP_3} ${dsClass.MARGIN_BOTTOM_3}`}>
                          {selectedCommercialProductPrice.totalMonthlyRecurringCharge
                            ? `${formatSum(
                                selectedCommercialProductPrice.totalMonthlyRecurringCharge,
                                true
                              )} €/${t.XXVX(monthMsg)}`
                            : ''}
                        </h4>
                      </div>
                    </CL.GridCol>
                  </CL.GridRow>
                  <CL.GridRow>
                    <CL.GridCol colsXL={12} colsL={12} colsM={6} colsS={6} colsXS={4}>
                      {selectedCommercialProductPrice.totalOneTimeCharge ? (
                        <div className="of-order-product-details__product-row-opening-fee">
                          {t.HWDR(activationFeeMsg)}: {formatSum(selectedCommercialProductPrice.totalOneTimeCharge)}
                        </div>
                      ) : (
                        ''
                      )}
                    </CL.GridCol>
                  </CL.GridRow>
                </CL.Grid>
              </CL.GridCol>
            </CL.GridRow>
          </CL.Grid>
          <CL.Grid className={dsClass.MARGIN_HORIZONTAL_6}>
            {ringFilteredProducts.map((selectedCommercialProduct, i) => (
              <CL.GridRow
                key={`subscription-order-${i}`}
                id={`subscription-order-${i}`}
                className={dsClass.MARGIN_TOP_6}
              >
                <CL.GridCol colsXL={3} colsL={3} className="of-order-product-details__spacer-column"></CL.GridCol>
                <CL.GridCol colsXL={9} colsL={9} colsM={6} colsS={6} colsXS={4}>
                  <div className={`of-order-product-details__checkout-details-container ${dsClass.PADDING_6}`}>
                    {amount > 1 && (
                      <h3 className={`${dsClass.MARGIN_0} ${dsClass.PADDING_BOTTOM_4}`}>
                        {t.P674(subscriptionMsg)} {i + 1}
                      </h3>
                    )}

                    <OrderSubscriptionConfigurationFields
                      fieldsToShow={formFields}
                      holidays={holidays}
                      selectedCommercialProducts={ringFilteredProducts}
                      selectedCommercialProduct={selectedCommercialProduct}
                      phoneNumbers={phoneNumbers || []}
                      phoneNumbersLoading={this.props.phoneNumbersLoading || false}
                      cartItemInstanceId={`${i}`}
                      companyName={companyName}
                      contacts={this.props.contacts}
                      onSelectUseExistingPhoneNumber={this.onSelectUseExistingPhoneNumber}
                      validatedPhoneNumbers={this.props.validatedPhoneNumbers}
                      companyInfo={this.props.companyInfo}
                      onlineModels={this.props.onlineModels}
                      numberRanges={this.props.numberRanges}
                      onRingConfigurationChange={this.onRingConfigurationChange}
                      orderModelType={this.props.orderModelType}
                      ringSettingsConfiguration={getRingSettings(
                        selectedCommercialProduct?.ringSettingsConfiguration?.corporateNumber || '',
                        this.props.orderStateParams.selectedOffer!
                      )}
                      onAddOnSelectionChange={this.onAddOnSelectionChange}
                      isRingSelected={this.state.isRingSelected}
                      onRingSelectionChange={this.onRingSelectionChange}
                    />
                  </div>
                </CL.GridCol>
              </CL.GridRow>
            ))}
          </CL.Grid>
          <CL.Grid className={dsClass.MARGIN_HORIZONTAL_6}>
            <CL.GridRow className={dsClass.MARGIN_TOP_6}>
              <CL.GridCol colsXL={3} colsL={3} className="of-order-product-details__spacer-column"></CL.GridCol>
              <CL.GridCol colsXL={9} colsL={9} colsM={6} colsS={6} colsXS={4}>
                <OrderSummary
                  campaignAssociation={selectedCampaignAssociation}
                  commercialProducts={[...totalCommercialProducts, ...totalRingProducts]}
                  summaryType={OrderSummaryType.DETAILS_CLOSED}
                  campaignDescription={ringFilteredProducts[0].campaignDescription}
                />
              </CL.GridCol>
            </CL.GridRow>
            <CL.GridRow className={`${dsClass.MARGIN_TOP_6} of-order-product-details__actions`}>
              <CL.GridCol colsXL={3} colsL={3} className="of-order-product-details__spacer-column"></CL.GridCol>
              <CL.GridCol colsXL={9} colsL={9} colsM={6} colsS={6} colsXS={4}>
                <WizardActions onBackClick={() => this.handleBackClick()} onForwardClick={() => {}} />
              </CL.GridCol>
            </CL.GridRow>
          </CL.Grid>
        </div>
      </FormWrapper>
    );
  }
}
