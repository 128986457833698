import { dsClass } from '../../common/constants/dsClasses.js';
import type { ReactNode } from 'react';

import './NettiLiteProductInfoHeader.scss';

interface NettiLiteProductInfoHeaderProps {
  children: ReactNode;
  productName: string;
  productInformation?: string;
  price: string;
  type: 'broadband' | 'yritystupla';
}

export const NettiLiteProductInfoHeader = (props: NettiLiteProductInfoHeaderProps) => {
  const { children, price, productName, productInformation, type } = props;

  return (
    <div className="of-netti-lite-product-info-header__wrapper">
      <div
        className={`of-netti-lite-product-info-header__picture of-netti-lite-product-info-header__picture__${type}`}
      />
      <div className="of-netti-lite-product-info-header__price-information">
        <div
          className={`${dsClass.DISPLAY_FLEX} ${dsClass.JUSTIFY_CONTENT_SPACE_BETWEEN} ${dsClass.ALIGN_ITEMS_BASELINE}`}
        >
          <h3>{productName}</h3>
          <h4>{price}</h4>
        </div>
        <span className={dsClass.FONT_SIZE_SMALL}>{productInformation}</span>
        <div className={`of-netti-lite-product-info-header__details ${dsClass.MARGIN_TOP_4}`}>{children}</div>
      </div>
    </div>
  );
};
