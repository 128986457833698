/**
 * Online UI API
 * # Summary Elisa B2B Online UI API  # Usage This API is used in the `https://yrityksille.elisa.fi/` pages.  # Identifiers 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Price } from './price.js';


/**
 * Details of Subscription the user would like to redeem or return. This is only present in case of device change orders.
 */
export interface PunchoutOrderItemDeviceChange { 
    /**
     * unique id of the old subscription for which the device change order has been placed.
     */
    replacedSubscriptionId: string;
    /**
     * Change type requested by the user redeem / return
     */
    changeType: PunchoutOrderItemDeviceChange.ChangeTypeEnum;
    employeeRedeemPrice?: Price;
}
export namespace PunchoutOrderItemDeviceChange {
    export type ChangeTypeEnum = 'REDEEM' | 'RETURN';
    export const ChangeTypeEnum = {
        REDEEM: 'REDEEM' as ChangeTypeEnum,
        RETURN: 'RETURN' as ChangeTypeEnum
    };
}


