import {
  CompanyInfoContainer,
  CompanyInfoSceneTab,
} from '../../../../../components/CompanyInfoScene/CompanyInfoScene.js';
import { CompanySettings } from '../../../../../components/CompanySettings/CompanySettings.js';
import { useLoaderData, useRouteLoaderData } from 'react-router-dom';
import type { CompanyInfoLoaderData } from '../../../../../common/loaders';
import type { MfaDetailsResponse } from '../../../../../generated/api/mfaDetailsResponse';

export const CompanyInfoSettingsPath = () => {
  const companyInfos = useLoaderData() as CompanyInfoLoaderData[];
  const mfaDetails = useRouteLoaderData('settingsRoot') as MfaDetailsResponse;

  return (
    <CompanyInfoContainer tab={CompanyInfoSceneTab.SETTINGS}>
      <CompanySettings companyInfos={companyInfos} mfaDetails={mfaDetails} />
    </CompanyInfoContainer>
  );
};
