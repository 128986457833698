import * as CL from '@design-system/component-library';
import { CaseList } from '../CaseList/CaseList.js';
import { HeroHeading, HeroHeadingType } from '../HeroHeading/index.js';
import { createNewSupportCase, omaElisaForCompaniesMsg, supportCasesMsg, t } from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useLoaderData, useNavigate } from 'react-router-dom';
import type { SupportCasesResponse } from '../../generated/api/supportCasesResponse.js';

import './MessagingCenter.scss';

export const MessagingCenter = () => {
  const navigate = useNavigate();
  const { authenticatedUser } = useAuth();
  const {
    searchResults: supportCases,
    resultCountAggregations: aggregations,
    total: totalItems,
  } = useLoaderData() as SupportCasesResponse;

  return (
    <div className="of-messaging-center">
      <HeroHeading
        breadCrumbPaths={[
          { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
          { name: t.OEZW(supportCasesMsg) },
        ]}
        heroHeadingType={HeroHeadingType.MESSAGING_CENTER}
        title={t.H4F0(supportCasesMsg)}
      />
      <div className="of-messaging-center-content">
        <CL.NotificationBanner
          text={t.OLM4(
            'We are further developing OmaElisa and in this phase you can find your invoice related cases in the list below. In future we will bring more features available.'
          )}
          icon={<CL.Icon icon="information" size="m" type="regular" color="blue-600" />}
        />
        <div className={`create-case-button ${dsClass.MARGIN_TOP_3}`}>
          <CL.Button
            icon={<CL.Icon className={dsClass.MARGIN_RIGHT_2} icon="new-message" />}
            onClick={() => navigate(paths.SUPPORT_CASE_NEW)}
          >
            {t.F1CR(createNewSupportCase)}
          </CL.Button>
        </div>
        <CaseList
          supportCases={supportCases}
          aggregations={aggregations}
          totalItems={totalItems}
          authenticatedUser={authenticatedUser}
        />
      </div>
    </div>
  );
};
