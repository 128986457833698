import { TypeKeys, generateActionId } from './actionsUtils.js';
import { convertToCommonErrors } from '../../common/utils/errorUtils.js';
import type { Action } from 'redux';
import type {
  AuthenticationResult,
  AuxiliaryEsimServiceOrderResult,
  CardPaymentResult,
  CreditCheckResult,
  GenericError,
  MyselfChangesResponse,
  OpenOrderStatus,
  OrderStatusResult,
  ReportStatusChange,
  SubscriptionAction,
} from '../../generated/api/models.js';
import type { CrudAction, ErrorAction, ErrorActionCreator } from './actionsUtils.js';
export type PollForMyselfChangesAction = CrudAction<TypeKeys.POLL_FOR_MYSELF_CHANGES>;

export const pollForMyselfChanges = (): PollForMyselfChangesAction => ({
  id: generateActionId(),
  type: TypeKeys.POLL_FOR_MYSELF_CHANGES,
});

export type PollForMyselfChangesFailedAction = ErrorAction<TypeKeys.POLL_FOR_MYSELF_CHANGES_FAILED>;

export const pollForMyselfChangesFailed: ErrorActionCreator<TypeKeys.POLL_FOR_MYSELF_CHANGES_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): PollForMyselfChangesFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    params,
    type: TypeKeys.POLL_FOR_MYSELF_CHANGES_FAILED,
  };
};

export interface PollForMyselfChangesFulfilledAction extends Action {
  latestChangeRef: string;
  authenticationResult?: AuthenticationResult;
  subscriptionAction?: SubscriptionAction;
  auxiliaryEsimServiceOrderResult?: AuxiliaryEsimServiceOrderResult;
  auxiliaryEsimOpenOrderStatus?: OpenOrderStatus;
  creditCheckResult?: CreditCheckResult;
  orderStatusResult?: OrderStatusResult;
  cardPaymentResult?: CardPaymentResult;
  reportStatusChange?: ReportStatusChange;
  type: TypeKeys.POLL_FOR_MYSELF_CHANGES_FULFILLED;
}

export const pollForMyselfChangesFulfilled = (
  response: MyselfChangesResponse
): PollForMyselfChangesFulfilledAction => ({
  ...response,
  type: TypeKeys.POLL_FOR_MYSELF_CHANGES_FULFILLED,
});

export interface StopPollForMyselfChanges {
  type: TypeKeys.STOP_POLL_FOR_MYSELF_CHANGES;
}

export const stopPollForMyselfChanges = (): StopPollForMyselfChanges => ({
  type: TypeKeys.STOP_POLL_FOR_MYSELF_CHANGES,
});

export type MyselfChangesActionTypes =
  | PollForMyselfChangesAction
  | PollForMyselfChangesFailedAction
  | PollForMyselfChangesFulfilledAction
  | StopPollForMyselfChanges;
