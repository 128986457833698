import { BillingAccountDeliveryMethod, ContactType } from '../../../../generated/api/models.js';
import { BillingAccountVersion } from '../../billingAccountDetailsEnums.js';
import { ElectricDeliveryMethodDetails } from '../../components/ElectricDeliveryMethodDetails.js';
import { EmailDeliveryMethodDetails } from '../../components/EmailDeliveryMethodDetails.js';
import { recipientEmailMsg, recipientMsg, t } from '../../../../common/i18n/index.js';
import type { BillingAccount, BillingAccountScheduledChange, Contact } from '../../../../generated/api/models.js';

export interface DeliveryMethodDetailsViewProps {
  activeBillingAccount: BillingAccount;
  billingAccountVersion: BillingAccountVersion;
  billingAccountScheduledChange?: BillingAccountScheduledChange;
  billElectronicOperator?: string;
  selectedContact?: Contact;
  isHighlighted: (value1?: string, value2?: string) => boolean;
}

export const DeliveryMethodDetailsView = ({
  activeBillingAccount,
  billingAccountVersion,
  billingAccountScheduledChange,
  billElectronicOperator,
  selectedContact,
  isHighlighted,
}: DeliveryMethodDetailsViewProps) => {
  const billingAccount =
    billingAccountVersion === BillingAccountVersion.SCHEDULED && billingAccountScheduledChange
      ? billingAccountScheduledChange.billingAccount
      : activeBillingAccount;

  const receiverType =
    billingAccount.billReceiverType === ContactType.PERSON ? t.SY1D(recipientMsg) : t.W1PP(recipientEmailMsg);
  const billReceiverName = billingAccount.billReceiverName;

  switch (billingAccount.deliveryMethod) {
    case BillingAccountDeliveryMethod.ELECTRONIC:
      return (
        <ElectricDeliveryMethodDetails
          billElectronicOperator={billElectronicOperator}
          billElectronicAddress={billingAccount.billElectronicAddress}
          highlightBillElectronicAddress={isHighlighted(
            activeBillingAccount.billElectronicAddress,
            billingAccountScheduledChange?.billingAccount.billElectronicAddress
          )}
          highlightBillElectronicOperator={isHighlighted(
            activeBillingAccount.billElectronicOperator,
            billingAccountScheduledChange?.billingAccount.billElectronicOperator
          )}
        />
      );
    case BillingAccountDeliveryMethod.EMAIL:
      return (
        <EmailDeliveryMethodDetails
          receiverType={receiverType}
          selectedContact={selectedContact}
          billReceiverName={billReceiverName}
          additionalBillReceiverEmails={billingAccount.additionalBillReceiverEmails}
          highlight={{
            contactInfo:
              isHighlighted(
                activeBillingAccount.billReceiverId,
                billingAccountScheduledChange?.billingAccount.billReceiverId
              ) ||
              isHighlighted(
                activeBillingAccount.billReceiverName,
                billingAccountScheduledChange?.billingAccount.billReceiverName
              ),
            additionalReceivers: activeBillingAccount.additionalBillReceiverEmails?.map(
              (email, index) =>
                isHighlighted(
                  billingAccountScheduledChange?.billingAccount.additionalBillReceiverEmails?.[index],
                  email
                ) ?? false
            ),
          }}
        />
      );
    default:
      return <></>;
  }
};
