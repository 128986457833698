import { TypeKeys, generateActionId } from './actionsUtils.js';
import { convertToCommonErrors } from '../../common/utils/errorUtils.js';
import type { CrudAction, ErrorAction } from './actionsUtils.js';
import type { GenericError, ResolveUserNameConflictRequest } from '../../generated/api/models.js';

export interface SelectUsernameAction extends CrudAction<TypeKeys.SELECT_USERNAME> {
  request: ResolveUserNameConflictRequest;
}

export const selectUsername = (request: ResolveUserNameConflictRequest): SelectUsernameAction => ({
  id: generateActionId(),
  request,
  type: TypeKeys.SELECT_USERNAME,
});

export type SelectUsernameFailedAction = ErrorAction<TypeKeys.SELECT_USERNAME_FAILED>;

export const selectUsernameFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): SelectUsernameFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.SELECT_USERNAME_FAILED,
});

export interface SelectUsernameFulfilledAction {
  type: TypeKeys.SELECT_USERNAME_FULFILLED;
  selectedUsername: string;
}

export const selectUsernameFulfilled = (selectedUsername: string): SelectUsernameFulfilledAction => ({
  selectedUsername,
  type: TypeKeys.SELECT_USERNAME_FULFILLED,
});

export type SelectUsernameActionTypes =
  | SelectUsernameAction
  | SelectUsernameFailedAction
  | SelectUsernameFulfilledAction;
