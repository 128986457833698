import * as CL from '@design-system/component-library';
import { Loading } from '../Loading/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import { t } from '../../common/i18n/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useNavigate } from 'react-router-dom';

import './EmailVerification.scss';

type EmailVerificationContentProps = {
  emailVerified: boolean;
};

export const EmailVerificationContent = ({ emailVerified }: EmailVerificationContentProps) => {
  const navigate = useNavigate();

  if (!emailVerified) {
    return <Loading big={true} bottomPadding={4} />;
  }

  return (
    <div className="of-employee-email-verification">
      <div className="of-employee-email-verification__thumb-up" />
      <h3 className="ea-h3 ea-h3--thick">{t.NID5('Email address verified successfully')}</h3>
      <CL.Button color="link" onClick={() => navigate(paths.EMPLOYEE_HOME)}>
        {t.Z0U9(`Return to the Employee's OmaElisa`)}
      </CL.Button>
    </div>
  );
};

export const EmailVerification = () => {
  const { authenticatedUser } = useAuth();
  return <EmailVerificationContent emailVerified={!!authenticatedUser?.emailVerified} />;
};
