import { Dropdown } from '../components/Dropdown.js';
import { getLanguageOptions } from '../../utils/billingAccountUtils.js';
import { languageMsg, t } from '../../i18n/index.js';

export interface LanguageDropdownProps {
  name: string;
  label?: string;
}

export const LanguageDropdown = ({ name, label }: LanguageDropdownProps) => (
  <Dropdown name={name} label={label || t.L3C5(languageMsg)} items={getLanguageOptions()} />
);
