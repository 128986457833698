import { ActionPhase } from '../common/storeUtils.js';
import { TypeKeys } from '../actions/index.js';
import { reduceCrudAction } from './reducerUtils.js';
import { updateActionStatePhase } from '../common/index.js';
import type { ActionsHistory } from '../common/store.js';
import type { PunchoutOrderState } from '../../common/types/states.js';
import type { SelfServiceActionTypes } from '../actions/index.js';

export function punchoutOrderReducer(
  state: (PunchoutOrderState & ActionsHistory) | undefined,
  action: SelfServiceActionTypes
): (PunchoutOrderState & ActionsHistory) | null {
  if (typeof state === 'undefined') {
    return null;
  }

  switch (action.type) {
    case TypeKeys.SUBMIT_PUNCHOUT_ORDER: {
      return {
        ...reduceCrudAction(action, state),
        saving: true,
      };
    }

    case TypeKeys.SUBMIT_PUNCHOUT_ORDER_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.SUBMIT_PUNCHOUT_ORDER,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        errors: undefined,
        saving: false,
      };
    }

    case TypeKeys.SUBMIT_PUNCHOUT_ORDER_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.SUBMIT_PUNCHOUT_ORDER,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
        saving: false,
      };
    }

    default:
      return state;
  }
}
