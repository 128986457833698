import * as CL from '@design-system/component-library';
import { BillingAccountVersion } from '../billingAccountDetailsEnums.js';
import { GridColumn } from './GridColumn.js';
import { InfoFieldWithTooltip } from './InfoFieldWithTooltip.js';
import { deliveryMethodMsg, invoiceLanguageMsg, t } from '../../../common/i18n/index.js';
import { getDeliveryMethodDropDownOptions, getLanguageOptions } from '../../../common/utils/billingAccountUtils.js';
import type { BillChannel, BillingAccount, BillingAccountScheduledChange } from '../../../generated/api/models.js';

export interface DeliveryMethodAndLanguageViewProps {
  activeBillingAccount: BillingAccount;
  billingAccountVersion: BillingAccountVersion;
  billingAccountScheduledChange?: BillingAccountScheduledChange;
  billChannels?: BillChannel[];
  billLanguage?: string;
  isHighlighted: (value1?: string, value2?: string) => boolean;
}

const getDeliveryMethodName = (billingAccount: BillingAccount, billChannels?: BillChannel[]) =>
  getDeliveryMethodDropDownOptions(billingAccount, billChannels).find(
    option => option.value === billingAccount.deliveryMethod
  )?.label;

export const getLanguage = (billLanguage?: string) =>
  getLanguageOptions().find(option => option.value === billLanguage)?.label;

export const DeliveryMethodAndLanguageView = ({
  activeBillingAccount,
  billingAccountVersion,
  billingAccountScheduledChange,
  billChannels,
  isHighlighted,
}: DeliveryMethodAndLanguageViewProps) => {
  const billingAccount =
    billingAccountVersion === BillingAccountVersion.SCHEDULED && billingAccountScheduledChange
      ? billingAccountScheduledChange.billingAccount
      : activeBillingAccount;

  const deliveryMethod = getDeliveryMethodName(billingAccount, billChannels);
  const language = getLanguage(billingAccount.billLanguage);

  return (
    <CL.GridRow>
      <GridColumn className="of-billing-account-details form-column view-field">
        <InfoFieldWithTooltip
          label={t.G0QN(deliveryMethodMsg)}
          value={deliveryMethod}
          highlight={isHighlighted(
            activeBillingAccount.deliveryMethod,
            billingAccountScheduledChange?.billingAccount.deliveryMethod
          )}
          name="deliveryMethod"
        />
      </GridColumn>
      <GridColumn className="of-billing-account-details form-column view-field">
        <InfoFieldWithTooltip
          label={t.A7DR(invoiceLanguageMsg)}
          value={language}
          highlight={isHighlighted(
            activeBillingAccount.billLanguage,
            billingAccountScheduledChange?.billingAccount.billLanguage
          )}
          name="billLanguage"
        />
      </GridColumn>
    </CL.GridRow>
  );
};
