import { TypeKeys } from '../actions/index.js';
import type { ActionsHistory } from '../common/store.js';
import type { CampaignsState } from '../../common/types/states.js';
import type { SelfServiceActionTypes } from '../actions/index.js';

export function campaignsReducer(
  state: (CampaignsState & ActionsHistory) | null,
  action: SelfServiceActionTypes
): (CampaignsState & ActionsHistory) | null {
  if (state === undefined) {
    return null;
  }

  switch (action.type) {
    case TypeKeys.CLEAR_CAMPAIGNS: {
      return {
        ...state,
        errors: undefined,
        campaigns: undefined,
        campaignContexts: undefined,
        loading: state?.loading || false,
        onlineModelCode: undefined,
        voucher: undefined,
      };
    }

    case TypeKeys.GET_CAMPAIGNS: {
      return {
        ...state,
        errors: undefined,
        campaigns: undefined,
        campaignContexts: undefined,
        loading: true,
        onlineModelCode: action.params.onlineModel,
        voucher: action.params.voucher,
      };
    }

    case TypeKeys.GET_CAMPAIGNS_FAILED: {
      return {
        ...state,
        errors: action.errors,
        campaigns: undefined,
        campaignContexts: undefined,
        loading: false,
      };
    }

    case TypeKeys.GET_CAMPAIGNS_FULFILLED: {
      return {
        ...state,
        errors: undefined,
        campaigns: action.response.campaigns,
        campaignContexts: action.response.campaignContexts,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
}
