export const getIndexFromParent = (parent: string) => {
  return Number(parent.substring(parent!.indexOf('[') + 1, parent.indexOf(']')));
};

export const getTopMostElement = (elements: HTMLElement[]) =>
  elements.sort(
    (element, otherElement) => element.getBoundingClientRect().top - otherElement.getBoundingClientRect().top
  )[0];

export const isOrIsChildOf = (element: HTMLElement, elementToCheck: HTMLElement): boolean => {
  if (element === elementToCheck) {
    return true;
  } else if (elementToCheck.parentElement) {
    return isOrIsChildOf(element, elementToCheck.parentElement);
  } else {
    return false;
  }
};

export const getScrollingDeltaY = (event: WheelEvent | TouchEvent, touchStartY?: number) => {
  const touchEvent = event as TouchEvent;
  if (touchStartY && touchEvent.touches && touchEvent.touches[0].pageY) {
    return touchStartY - touchEvent.touches[0].pageY;
  }

  const wheelEvent = event as WheelEvent;
  if (wheelEvent.deltaY) {
    return wheelEvent.deltaY;
  }
  return undefined;
};

export const createImageUrl = (params: {
  originalUrl: string;
  imagesBaseUrl: string;
  width: number;
  height: number;
  format: string;
  scalingFactors: Array<number>;
}) => {
  const deprecatedUrlRegex = /https:\/\/static\.elisa\.com\/picture-storage\/.+\/(.+)\/((.+)\.(png|jpg|jpeg))/;
  const relativeUrlRegex = /\/static\/image\/(.+)\/((.+)\.(png|jpg|jpeg))/;
  const version2UrlRegex =
    /https:\/\/(?:static\.elisa\.com|static\.yrityksille-uat\.elisa\.fi)\/v2\/image\/(.+)\/(.+)\/((.+)\.(png|jpg|jpeg))/;

  const transformDeprecatedImageUrl = (deprecatedUrl: string) => {
    const match = deprecatedUrl.match(deprecatedUrlRegex);
    if (match !== null) {
      return {
        assetId: match[1],
        fileName: match[2],
        fileSuffix: match[4],
        spaceId: undefined,
      };
    } else {
      return undefined;
    }
  };

  const transformVersion2ImageUrl = (version2Url: string) => {
    const match = version2Url.match(version2UrlRegex);
    if (match !== null) {
      return {
        assetId: match[2],
        fileName: match[3],
        fileSuffix: match[5],
        spaceId: match[1],
      };
    } else {
      return undefined;
    }
  };

  const transformRelativeUrl = (relativeUrl: string) => {
    const match = relativeUrl.match(relativeUrlRegex);
    if (match !== null) {
      return {
        assetId: match[1],
        fileName: match[2],
        fileSuffix: match[4],
        spaceId: undefined,
      };
    } else {
      return undefined;
    }
  };

  const getSrc = (
    originalUrl: string,
    imagesBaseUrl: string,
    width: number,
    height: number,
    format: string
  ): string => {
    if (!originalUrl || !width || !height || !format) {
      return '';
    }
    let imageData;
    let newUrl;
    if (relativeUrlRegex.test(originalUrl)) {
      imageData = transformRelativeUrl(originalUrl);
    } else if (deprecatedUrlRegex.test(originalUrl)) {
      imageData = transformDeprecatedImageUrl(originalUrl);
    } else if (version2UrlRegex.test(originalUrl)) {
      imageData = transformVersion2ImageUrl(originalUrl);
    }
    if (imageData) {
      const fileName = encodeURIComponent(imageData.fileName);
      if (imageData.spaceId) {
        // Strip spaceId from baseUrl, because it already has one by default
        newUrl = `${imagesBaseUrl.substr(0, imagesBaseUrl.lastIndexOf('/'))}/${imageData.spaceId}/${
          imageData.assetId
        }/${fileName}`;
      } else {
        newUrl = `${imagesBaseUrl}/${imageData.assetId}/${fileName}`;
      }
      let optimalFormat;
      // Avoid transforming jpg into png, because in most cases that would
      // increase the image size.
      if (imageData.fileSuffix === 'png') {
        optimalFormat = format;
      } else {
        optimalFormat = 'jpg';
      }
      newUrl = newUrl.replace(/(png|jpg|jpeg)$/i, optimalFormat);
      newUrl = `${newUrl}?w=${width}&h=${height}&fm=${optimalFormat}`;
    } else {
      newUrl = originalUrl;
    }
    return newUrl;
  };

  const calculateScaledSizes = (scalingFactors: Array<number>, width: number, height: number) =>
    scalingFactors.map(scaleFactor => ({
      height: Math.floor(scaleFactor * height),
      width: Math.floor(scaleFactor * width),
    }));

  const getSrcSet = (
    originalUrl: string,
    imagesBaseUrl: string,
    scalingFactors: Array<number>,
    width: number,
    height: number,
    format: string
  ) => {
    return calculateScaledSizes(scalingFactors, width, height)
      .map(scaledSize => {
        const url = getSrc(originalUrl, imagesBaseUrl, scaledSize.width, scaledSize.height, format);
        return `${url} ${scaledSize.width}w`;
      })
      .join(', ');
  };

  return {
    src: getSrc(params.originalUrl, params.imagesBaseUrl, params.width, params.height, params.format),
    srcSet: getSrcSet(
      params.originalUrl,
      params.imagesBaseUrl,
      params.scalingFactors,
      params.width,
      params.height,
      params.format
    ),
  };
};
