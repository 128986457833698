import { FINNISH_PHONE_NUMBER_MAX_LENGTH, PHONE_NUMBER_REGEX } from '../../utils/phoneNumberUtils.js';
import { InputComponent } from '../components/InputComponent.js';
import { fieldCantBeEmptyMsg, invalidValueMsg, phoneNumberMsg, t } from '../../i18n/index.js';
import type { OptionalInputComponentProps } from '../components/InputComponent.js';

const defaultValidate = (required: boolean) => (value: string) => {
  if (!value) {
    if (required) {
      return t.VPVR(fieldCantBeEmptyMsg);
    }
  } else if (!PHONE_NUMBER_REGEX.test(value)) {
    return t.DUPA(invalidValueMsg);
  }
  return undefined;
};

export const PhoneNumber = ({
  name = 'phoneNumber',
  label = t.AGIJ(phoneNumberMsg),
  placeholder = t.AGIJ(phoneNumberMsg),
  required = true,
  disabled = false,
  tooltip,
  validate,
  readonly,
}: OptionalInputComponentProps) => {
  return (
    <InputComponent
      validate={validate || defaultValidate(required)}
      name={name}
      type="text"
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      required={required ?? true}
      tooltip={tooltip}
      maxLength={FINNISH_PHONE_NUMBER_MAX_LENGTH}
      readonly={readonly}
    />
  );
};
