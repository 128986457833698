import * as CL from '@design-system/component-library';
import { ButtonGroupForSubmitAndBack } from '../ButtonGroupForSubmitAndBack/ButtonGroupForSubmitAndBack.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { hideMsg, t, viewAllMsg } from '../../common/i18n/index.js';
import { onEnterOrSpaceKeyPress } from '../../common/utils/handlerUtils.js';
import { resetPagingParams } from './tableUtils.js';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import './SearchFilters.scss';

export interface SearchFilterGroup {
  label: () => string;
  value: string;
  items: SearchFilter[];
  displayAllItems?: boolean;
}

export interface SearchFilter {
  label: () => string;
  value: string;
  checked?: boolean;
  itemsCount?: number;
}

export interface SearchFiltersProps {
  filterGroups: Array<SearchFilterGroup>;
  displayActiveFiltersAsTiles?: boolean;
  visibleFiltersCountPerGroup?: number;
  onModalClose: () => void;
  onFilterChange?: (queryParameters: URLSearchParams) => void;
}

interface ActiveFilterTilesProps {
  filterGroups: Array<SearchFilterGroup>;
  onClearFilters: () => void;
  onFilterItemUpdated: (filterItem: SearchFilter) => void;
  anyFilterSelected: boolean;
}

interface SearchFiltersCheckboxGroupProps {
  filterGroup: SearchFilterGroup;
  visibleFiltersCount?: number;
  onFilterItemUpdated: (filterItem: SearchFilter) => void;
}

interface SearchFiltersGridProps {
  filterGroups: Array<SearchFilterGroup>;
  onFilterItemUpdated: (filterItem: SearchFilter) => void;
  onClearFilters: () => void;
  onViewAllFiltersUpdated: (filterGroup: SearchFilterGroup) => void;
  anyFilterSelected: boolean;
  visibleFiltersCount?: number;
  isLoading?: boolean;
}

interface SearchFiltersModalProps {
  filterGroups: Array<SearchFilterGroup>;
  onFilterItemUpdated: (filterItem: SearchFilter) => void;
  onModalClose: () => void;
  isLoading?: boolean;
  onFilterAndCloseModal: () => void;
  onClearFiltersAndCloseModal: () => void;
  anyFilterSelected: boolean;
}

const DEFAULT_VISIBLE_FILTERS_PER_GROUP = 10;

const ActiveFilterTiles = ({
  filterGroups,
  onClearFilters,
  onFilterItemUpdated,
  anyFilterSelected,
}: ActiveFilterTilesProps) => {
  const activeFilters = filterGroups
    .map(filterGroup => {
      return filterGroup.items
        .filter(item => item.checked)
        .map(item => {
          return {
            label: () => `${filterGroup.label()}: ${item.label()}`,
            value: item.value,
          };
        });
    })
    .flat();

  return (
    <div className={`of-active-filter-tiles-container${anyFilterSelected ? '__active' : ''}`}>
      <div className="of-active-filter-tiles">
        {activeFilters.map(activeFilter => (
          <div
            className="of-active-filter-tiles__tile"
            key={activeFilter.value}
            onClick={() => onFilterItemUpdated(activeFilter)}
            onKeyDown={(event: React.KeyboardEvent) => {
              if (event.key !== 'Tab') {
                onEnterOrSpaceKeyPress(event, () => {
                  onFilterItemUpdated(activeFilter);
                });
              }
            }}
            role="button"
            tabIndex={0}
          >
            <span className={dsClass.MARGIN_RIGHT_1}>{activeFilter.label()}</span>
            <CL.Icon icon="close" size="s" />
          </div>
        ))}
      </div>
      {activeFilters.length > 0 && (
        <div className="of-clear-filters-button">
          <CL.Button className={dsClass.MARGIN_LEFT_3} color="link" size="s" onClick={onClearFilters}>
            {t.HH00('Clear')}
          </CL.Button>
        </div>
      )}
    </div>
  );
};

const SearchFiltersCheckboxGroup = ({
  filterGroup,
  visibleFiltersCount,
  onFilterItemUpdated,
}: SearchFiltersCheckboxGroupProps) => (
  <CL.Checkbox.Group className={dsClass.MARGIN_BOTTOM_1} label={filterGroup.label()}>
    {filterGroup.items.map(
      (filterItem, index) =>
        (visibleFiltersCount === undefined || filterGroup.displayAllItems || index < visibleFiltersCount) && (
          <CL.Checkbox
            // TODO: Workaround to force CL.Checkbox to rerender after checked prop update. See https://atlas.elisa.fi/jira/browse/DS-1592.
            key={`${filterItem.value}-${filterItem.checked === true ? 'checked' : 'unchecked'}`}
            value={filterItem.value}
            checked={filterItem.checked}
            onChange={() => onFilterItemUpdated(filterItem)}
          >
            <div>
              <span>{filterItem.label()}</span>
              {filterItem.itemsCount !== undefined && <span>&nbsp;({filterItem.itemsCount})</span>}
            </div>
          </CL.Checkbox>
        )
    )}
  </CL.Checkbox.Group>
);

const SearchFiltersGrid = ({
  filterGroups,
  onFilterItemUpdated,
  onClearFilters,
  onViewAllFiltersUpdated,
  anyFilterSelected,
  visibleFiltersCount,
}: SearchFiltersGridProps) => (
  <CL.Grid className="of-search-filters-grid">
    <>
      <CL.GridRow
        className={`${dsClass.PADDING_TOP_5} ${dsClass.PADDING_RIGHT_5} ${dsClass.PADDING_LEFT_5} ${dsClass.PADDING_BOTTOM_1}`}
      >
        {filterGroups.map(filterGroup => (
          <CL.GridCol
            key={filterGroup.value}
            className={`of-checkbox-group ${dsClass.PADDING_0} ${dsClass.MARGIN_BOTTOM_4}`}
            colWidthS={6}
            colWidthM={3}
            colWidthL={6}
            colWidthXL={4}
          >
            <SearchFiltersCheckboxGroup
              filterGroup={filterGroup}
              visibleFiltersCount={visibleFiltersCount}
              onFilterItemUpdated={onFilterItemUpdated}
            />
            {visibleFiltersCount && filterGroup.items.length > visibleFiltersCount && (
              <div className="of-toggle-view-items-button">
                <CL.Button color="link" onClick={() => onViewAllFiltersUpdated(filterGroup)}>
                  {filterGroup.displayAllItems ? t.OJPZ(hideMsg) : t.SF4C(viewAllMsg)}
                </CL.Button>
              </div>
            )}
          </CL.GridCol>
        ))}
      </CL.GridRow>
      {anyFilterSelected && (
        <div className={`${dsClass.MARGIN_RIGHT_3} ${dsClass.MARGIN_BOTTOM_3} ${dsClass.TEXT_ALIGN_RIGHT}`}>
          <CL.Button className={dsClass.FONT_SIZE_SMALL} color="link" size="m" onClick={onClearFilters}>
            {t.HH00('Clear')}
          </CL.Button>
        </div>
      )}
    </>
  </CL.Grid>
);

const SearchFiltersModal = ({
  filterGroups,
  onModalClose,
  isLoading,
  onFilterAndCloseModal,
  onFilterItemUpdated,
  onClearFiltersAndCloseModal,
  anyFilterSelected,
}: SearchFiltersModalProps) => (
  <div className="of-search-filters-modal">
    <CL.Modal autoOpen={true} disableBodyScroll={false} onModalClose={onModalClose}>
      <h2>{t.XUFV('Filter')}</h2>
      {isLoading && <CL.LoadingSpinner className="loading-spinner" size="l" />}
      {!isLoading && (
        <>
          {filterGroups.map((filterGroup: SearchFilterGroup) => (
            <div className={dsClass.MARGIN_BOTTOM_4} key={filterGroup.value}>
              <SearchFiltersCheckboxGroup
                filterGroup={{ ...filterGroup, displayAllItems: true }}
                onFilterItemUpdated={onFilterItemUpdated}
              />
            </div>
          ))}
          <div className="of-search-filters-modal__sticky-buttons">
            <ButtonGroupForSubmitAndBack
              className={`${dsClass.PADDING_TOP_4} ${dsClass.TEXT_ALIGN_RIGHT}`}
              submitButtonText={t.X49N('Show')}
              onSubmit={onFilterAndCloseModal}
              cancelDisabled={!anyFilterSelected}
              cancelButtonText={t.HH00('Clear')}
              onCancel={onClearFiltersAndCloseModal}
            />
          </div>
        </>
      )}
    </CL.Modal>
  </div>
);

const processFilterGroups = (filterGroups: SearchFilterGroup[]) => {
  return filterGroups.map(group => ({
    ...group,
    displayAllItems: group.displayAllItems ?? false,
  }));
};

export const SearchFilters = ({
  filterGroups,
  displayActiveFiltersAsTiles,
  visibleFiltersCountPerGroup = DEFAULT_VISIBLE_FILTERS_PER_GROUP,
  onModalClose,
  onFilterChange,
}: SearchFiltersProps) => {
  const navigate = useNavigate();
  const { hash, pathname, search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const [filterGroupsState, setFilterGroupsState] = useState(processFilterGroups(filterGroups));

  useEffect(() => {
    setFilterGroupsState(processFilterGroups(filterGroups));
  }, [filterGroups]);

  const createFilterQuery = (filterGroupsUpdated: Array<SearchFilterGroup>) => {
    const filterQuery = new URLSearchParams();
    filterGroupsUpdated.forEach(filterGroup => {
      const selectedItems = filterGroup.items.filter(item => item.checked).map(item => item.value);
      if (selectedItems.length > 0) {
        filterQuery.set(filterGroup.value, selectedItems.join('|'));
      }
    });
    return filterQuery;
  };

  const filterResults = (filterQuery: URLSearchParams) => {
    filterGroups?.forEach(filter => {
      const filterValue = filterQuery.get(filter.value);
      if (filterValue) {
        queryParams.set(filter.value, filterValue);
      } else {
        queryParams.delete(filter.value);
      }
    });
    // return to first page when filters updated
    resetPagingParams(queryParams);
    navigate(`${pathname}?${queryParams}${hash}`, { replace: true });
    onFilterChange?.(filterQuery);
  };

  const getUpdatedFilterGroups = (filterItem: SearchFilter) =>
    filterGroupsState.map(filterGroup => ({
      ...filterGroup,
      items: filterGroup.items.map(item =>
        item.value === filterItem.value ? { ...item, checked: !item.checked } : item
      ),
    }));

  const updateFilters = (filterItem: SearchFilter) => {
    const updatedFilterGroups = getUpdatedFilterGroups(filterItem);
    setFilterGroupsState(updatedFilterGroups);
  };

  const updateFiltersImmediate = (filterItem: SearchFilter) => {
    const updatedFilterGroups = getUpdatedFilterGroups(filterItem);
    setFilterGroupsState(updatedFilterGroups);
    filterResults(createFilterQuery(updatedFilterGroups));
  };

  const clearFilters = () => {
    const updatedFilterGroups = filterGroupsState.map(filterGroup => {
      return {
        ...filterGroup,
        items: filterGroup.items.map(item => ({
          ...item,
          checked: false,
        })),
      };
    });
    setFilterGroupsState(updatedFilterGroups);
    filterResults(createFilterQuery(updatedFilterGroups));
  };

  const toggleViewAllFilters = (filterGroup: SearchFilterGroup) =>
    setFilterGroupsState(
      filterGroupsState.map(group =>
        group.value === filterGroup.value ? { ...group, displayAllItems: !filterGroup.displayAllItems } : group
      )
    );

  const anyFilterSelected = () => filterGroupsState.some(filterGroup => filterGroup.items.some(item => item.checked));

  const closeModal = () => {
    setFilterGroupsState(filterGroups.map(group => ({ ...group, displayAllItems: group.displayAllItems ?? false })));
    onModalClose();
  };

  const clearFiltersAndCloseModal = () => {
    clearFilters();
    onModalClose();
  };

  const filterAndCloseModal = () => {
    filterResults(createFilterQuery(filterGroupsState));
    onModalClose();
  };

  if (displayActiveFiltersAsTiles) {
    return (
      <ActiveFilterTiles
        filterGroups={filterGroupsState}
        onClearFilters={clearFilters}
        onFilterItemUpdated={(filterItem: SearchFilter) => updateFiltersImmediate(filterItem)}
        anyFilterSelected={anyFilterSelected()}
      />
    );
  } else {
    return (
      <div className="of-search-filters">
        <SearchFiltersGrid
          filterGroups={filterGroupsState}
          onFilterItemUpdated={(filterItem: SearchFilter) => updateFiltersImmediate(filterItem)}
          onClearFilters={clearFilters}
          onViewAllFiltersUpdated={toggleViewAllFilters}
          anyFilterSelected={anyFilterSelected()}
          visibleFiltersCount={visibleFiltersCountPerGroup}
        />
        <SearchFiltersModal
          filterGroups={filterGroupsState}
          onFilterItemUpdated={(filterItem: SearchFilter) => updateFilters(filterItem)}
          onModalClose={closeModal}
          onFilterAndCloseModal={filterAndCloseModal}
          onClearFiltersAndCloseModal={clearFiltersAndCloseModal}
          anyFilterSelected={anyFilterSelected()}
        />
      </div>
    );
  }
};
