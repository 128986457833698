import { TypeKeys, generateActionId } from './actionsUtils.js';
import { convertToCommonErrors } from '../../common/utils/errorUtils.js';
import type { Action } from 'redux';
import type { AnonymousSupportCase, GenericError } from '../../generated/api/models.js';
import type { CommonError } from '../../common/types/errors.js';
import type { CrudAction, ErrorAction, ErrorActionCreator } from './actionsUtils.js';

export interface AddAnonymousSupportCaseAction extends CrudAction<TypeKeys.ADD_ANONYMOUS_SUPPORT_CASE> {
  anonymousSupportCase: AnonymousSupportCase;
  errors?: CommonError[];
}

export interface AddAnonymousSupportCaseFulfilledAction extends Action {
  type: TypeKeys.ADD_ANONYMOUS_SUPPORT_CASE_FULFILLED;
}

export type AddAnonymousSupportCaseFailedAction = ErrorAction<TypeKeys.ADD_ANONYMOUS_SUPPORT_CASE_FAILED>;

export const addAnonymousSupportCase = (
  supportCase: AnonymousSupportCase,
  errors?: CommonError[]
): AddAnonymousSupportCaseAction => ({
  errors: errors,
  id: generateActionId(),
  anonymousSupportCase: supportCase,
  type: TypeKeys.ADD_ANONYMOUS_SUPPORT_CASE,
});

export const addAnonymousSupportCaseFulfilled = (): AddAnonymousSupportCaseFulfilledAction => ({
  type: TypeKeys.ADD_ANONYMOUS_SUPPORT_CASE_FULFILLED,
});

export const addAnonymousSupportCaseFailed: ErrorActionCreator<TypeKeys.ADD_ANONYMOUS_SUPPORT_CASE_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): AddAnonymousSupportCaseFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.ADD_ANONYMOUS_SUPPORT_CASE_FAILED,
});

export type AnonymousSupportCasesActionTypes =
  | AddAnonymousSupportCaseAction
  | AddAnonymousSupportCaseFulfilledAction
  | AddAnonymousSupportCaseFailedAction;
