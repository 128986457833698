import { CaseDetails } from '../CaseDetails/CaseDetails.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useLoaderData } from 'react-router-dom';
import type { SupportCaseLoaderResponse } from '../../common/loaders.js';

export const SupportCasePath = () => {
  const { supportCase, history } = useLoaderData() as SupportCaseLoaderResponse;
  const { authenticatedUser } = useAuth();
  return <CaseDetails supportCase={supportCase} history={history} authenticatedUser={authenticatedUser} />;
};
