import { type Context, OpenFormAnswers } from './OpenFormAnswers.js';
import { type Dispatch } from 'react';
import { type OpenForm } from './OpenFormHooks/useOpenForm.js';
import { type OpenFormList } from './OpenFormHooks/useOpenFormList.js';
import { type OpenFormNotification } from './OpenFormComponents/OpenFormNotifications.js';
import { type OpenFormStorage } from './OpenFormStorage.js';

export type OpenFormActions = {
  [K in keyof typeof OpenFormAction]: (
    ...args: (typeof OpenFormAction)[K] extends (...args: infer P) => unknown ? P : never
  ) => void;
};

export type OpenFormDispatch = Dispatch<Action>;

export type OpenFormState = Required<ReturnType<typeof OpenFormReducer.state>>;

type Action =
  | ReturnType<typeof OpenFormAction.choices>
  | ReturnType<typeof OpenFormAction.confirm>
  | ReturnType<typeof OpenFormAction.context>
  | ReturnType<typeof OpenFormAction.error>
  | ReturnType<typeof OpenFormAction.form>
  | ReturnType<typeof OpenFormAction.list>
  | ReturnType<typeof OpenFormAction.loading>
  | ReturnType<typeof OpenFormAction.notification>
  | ReturnType<typeof OpenFormAction.page>
  | ReturnType<typeof OpenFormAction.valid>;

const type: unique symbol = Symbol('OpenFormAction');

export class OpenFormAction {
  static readonly choices = (payload: { guid: string; value: string | string[] | undefined }) => ({
    [type]: 'choices' as const,
    payload,
  });

  static readonly confirm = (payload: boolean) => ({
    [type]: 'confirm' as const,
    payload,
  });

  static readonly context = <K extends keyof Context>(payload: { guid: string; key?: K; patch?: Context[K] }) => ({
    [type]: 'context' as const,
    payload,
  });

  static readonly error = (payload: Error | string | undefined) => ({
    [type]: 'error' as const,
    payload,
  });

  static readonly form = (payload: { answers: Record<string, Context> | undefined; form: OpenForm | undefined }) => ({
    [type]: 'form' as const,
    payload,
  });

  static readonly list = (payload: { index: [string, number][] | undefined; list?: OpenFormList }) => ({
    [type]: 'list' as const,
    payload,
  });

  static readonly loading = (payload: { id: string; text: string | undefined }) => ({
    [type]: 'loading' as const,
    payload,
  });

  static readonly notification = (payload: OpenFormNotification | undefined) => ({
    [type]: 'notification' as const,
    payload,
  });

  static readonly page = (payload: { formId: string; page: number }) => ({
    [type]: 'page' as const,
    payload,
  });

  static readonly valid = (payload: boolean) => ({
    [type]: 'valid' as const,
    payload,
  });
}

export class OpenFormReducer {
  private constructor(
    readonly answers: OpenFormAnswers = new OpenFormAnswers(),
    readonly confirm: boolean = false,
    readonly disabled: boolean = false,
    readonly error: string | undefined = undefined,
    readonly form: OpenForm | undefined = undefined,
    readonly index: Map<string, number> = new Map(),
    readonly list: OpenFormList | undefined = undefined,
    readonly loading: Map<string, string> = new Map(),
    readonly notifications: Set<OpenFormNotification> = new Set(),
    readonly valid: boolean = false
  ) {}

  static actions(dispatch: OpenFormDispatch) {
    return Object.fromEntries(
      Object.entries(OpenFormAction)
        .filter(([_, prop]) => typeof prop === 'function')
        .map(([name, func]) => [name, (...args: unknown[]) => dispatch(func(...args))])
    ) as OpenFormActions;
  }

  static hook(storage?: OpenFormStorage) {
    const store = storage?.enabled() && OpenFormReducer.store(storage);

    return (state: OpenFormState, action: Action) => {
      state = OpenFormReducer.reduce(state, action);

      switch (action[type]) {
        case 'choices':
        case 'context':
        case 'form':
        case 'page': {
          store && state.form?.formId && store(state.form.formId, state);
          return state;
        }
        case 'confirm':
        case 'loading':
        case 'notification': {
          const disabled = state.confirm || !!state.loading.size || !!state.notifications.size;
          return state.disabled !== disabled ? { ...state, disabled } : state;
        }
        case 'error':
        case 'list':
        case 'valid': {
          return state;
        }
      }
    };
  }

  static reduce(state: OpenFormState, action: Action) {
    switch (action[type]) {
      case 'choices': {
        const { guid, value } = action.payload;
        return value?.length
          ? typeof value === 'string'
            ? { ...state, answers: new OpenFormAnswers(state.answers.assign(guid, 'choices', [value])) }
            : { ...state, answers: new OpenFormAnswers(state.answers.assign(guid, 'choices', value)) }
          : state.answers.delete(guid)
            ? { ...state, answers: new OpenFormAnswers(state.answers) }
            : state;
      }
      case 'confirm': {
        const confirm = action.payload;
        return state.confirm !== confirm ? { ...state, confirm } : state;
      }
      case 'context': {
        const { guid, key, patch } = action.payload;
        return key
          ? { ...state, answers: new OpenFormAnswers(state.answers.assign(guid, key, patch)) }
          : state.answers.delete(guid)
            ? { ...state, answers: new OpenFormAnswers(state.answers) }
            : state;
      }
      case 'error': {
        const error = action.payload;
        try {
          return error
            ? error instanceof Error
              ? { ...state, error: String(error) }
              : { ...state, error: JSON.parse(error) }
            : state.error
              ? { ...state, error }
              : state;
        } catch {
          return { ...state, error };
        }
      }
      case 'form': {
        const { answers, form } = action.payload;
        return { ...state, answers: new OpenFormAnswers(answers), form };
      }
      case 'list': {
        const { index, list = state.list } = action.payload;
        return { ...state, index: new Map(index), list };
      }
      case 'loading': {
        const { id, text } = action.payload;
        return text
          ? { ...state, loading: new Map(state.loading.set(id, text)) }
          : state.loading.delete(id)
            ? { ...state, loading: new Map(state.loading) }
            : state;
      }
      case 'notification': {
        const notification = action.payload;
        return notification
          ? { ...state, notifications: new Set(state.notifications.add(notification)) }
          : state.notifications.delete(state.notifications.values().next().value)
            ? { ...state, notifications: new Set(state.notifications) }
            : state;
      }
      case 'page': {
        const { formId, page } = action.payload;
        return state.index.get(formId) !== page ? { ...state, index: new Map(state.index.set(formId, page)) } : state;
      }
      case 'valid': {
        const valid = action.payload;
        return state.valid !== valid ? { ...state, valid } : state;
      }
    }
  }

  static state() {
    return { ...new OpenFormReducer() } as const;
  }

  static store(storage: OpenFormStorage) {
    return (formId: string, state: OpenFormState) => {
      !state.answers.size
        ? storage.removeItem(formId)
        : storage.setItem(formId, { answers: Object.fromEntries(state.answers), index: state.index.get(formId) });
    };
  }
}
