import { OneTimePasswordForm } from '../../components/OneTimePasswordForm/OneTimePasswordForm.js';
import { SubscriptionCategory } from '../../common/enums.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { validateSubscription } from '../../selfservice/actions/index.js';
import type { CommonError } from '../../common/types/errors.js';

const getPairingRedirectPath = (category: string): string => {
  switch (category) {
    case SubscriptionCategory.VOICE:
      return paths.EMPLOYEE_SUBSCRIPTIONS;
    case SubscriptionCategory.DEVICE:
      return paths.EMPLOYEE_DEVICES;
    case SubscriptionCategory.BROADBAND:
      return paths.EMPLOYEE_BROADBAND_SUBSCRIPTIONS;
    default:
      return paths.EMPLOYEE_SUBSCRIPTIONS;
  }
};

interface EmployeeOneTimePasswordSceneProps {
  category: string;
  errors?: CommonError[];
  saving: boolean;
}

export const EmployeeOneTimePasswordScene = (props: EmployeeOneTimePasswordSceneProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { errors, saving, category } = props;

  return (
    <div className="of-employee__form-container">
      <OneTimePasswordForm
        errors={errors}
        onSubmit={(data, validationErrors) => dispatch(validateSubscription(data, validationErrors))}
        onPreviousStep={() => navigate(state?.prevPath)}
        saving={saving}
        redirectParams={state?.params}
        redirectPath={getPairingRedirectPath(category)}
      />
    </div>
  );
};
