import { LicenseManagement } from '../../../../../components/LicenseManagement/LicenseManagement.js';
import { SubscriptionsLayout } from '../../../../../components/SubscriptionsLayout/SubscriptionsLayout.js';
import { licenseManagementMsg, omaElisaForCompaniesMsg, t } from '../../../../../common/i18n/index.js';
import { paths } from '../../../../../common/constants/pathVariables.js';

export const LicenseManagementPath = () => {
  return (
    <SubscriptionsLayout
      breadCrumbs={[
        { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
        { name: t.MBG9(licenseManagementMsg) },
      ]}
    >
      <LicenseManagement />
    </SubscriptionsLayout>
  );
};
