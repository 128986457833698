import * as CL from '@design-system/component-library';
import { BreadCrumbsWithTitle } from '../../../BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { DetailsWrapper } from '../../../DetailsWrapper/index.js';
import { dsClass } from '../../../../common/constants/dsClasses.js';
import { formatTimeToYYYYMMDDHHmm } from '../../../../common/utils/dateUtils.js';
import { generatePath } from 'react-router-dom';
import { getDnsRecordActionMessage } from '../../dnsManagementUtils.js';
import { omaElisaForCompaniesMsg, t, topicMsg } from '../../../../common/i18n/index.js';
import { paths } from '../../../../common/constants/pathVariables.js';
import type { DnsRecordsHistoryResponse } from '../../../../generated/api/dnsRecordsHistoryResponse.js';

import './DnsHistoryDetails.scss';

export const DnsHistoryDetails = ({
  historyData: { domainName, records = [] },
  subscriptionId,
  historyId,
}: {
  historyData: DnsRecordsHistoryResponse;
  subscriptionId: string;
  historyId: string;
}) => {
  const { action, author, created, name, type, current, previous } = records[0] ?? {};

  const breadCrumbs = (
    <BreadCrumbsWithTitle
      breadCrumbPaths={[
        { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
        { name: 'Elisa DNS', path: paths.PS_ELISA_DNS },
        {
          name: domainName || '',
          path: generatePath(paths.PS_ELISA_DNS_DOMAIN, { subscriptionId }),
        },
        {
          name: t.CUE7('Edit history'),
          path: generatePath(paths.PS_ELISA_DNS_DOMAIN_HISTORY, { subscriptionId }),
        },
        { name: name || '' },
      ]}
    />
  );

  // The latest change to a record is always in `current`, but in `delete`'s case we want to show that as `previous`
  const value = action === 'delete' ? { old: current, new: previous } : { old: previous, new: current };

  const content = (
    <>
      <CL.Description
        className={dsClass.MARGIN_0}
        items={[
          {
            title: t.X5AB(topicMsg),
            description: getDnsRecordActionMessage(action),
          },
          {
            title: t.Q1DM('Time of edit'),
            description: created && formatTimeToYYYYMMDDHHmm(created),
          },
          {
            title: t.Y9S5('Record type'),
            description: type || '-',
          },
          {
            title: t.AD92('Editor'),
            description: author || '-',
          },
        ]}
      />
      {(current || previous) && (
        <>
          <hr className={dsClass.MARGIN_VERTICAL_7} />
          <h3>{t.E9BD('Details of the edit')}</h3>
          <CL.Grid>
            <CL.GridRow>
              <CL.GridCol colsL={6} colsM={3} colsS={3} colsXS={4}>
                <h5>{t.H45W('Old value')}</h5>
                <pre className={dsClass.FONT_SIZE_SMALLER}>{JSON.stringify(value.old, null, 2)}</pre>
              </CL.GridCol>
              <CL.GridCol colsL={6} colsM={3} colsS={3} colsXS={4}>
                <h5>{t.HSJH('New value')}</h5>
                <pre className={dsClass.FONT_SIZE_SMALLER}>{JSON.stringify(value.new, null, 2)}</pre>
              </CL.GridCol>
            </CL.GridRow>
          </CL.Grid>
        </>
      )}
    </>
  );

  return (
    <DetailsWrapper
      classes={['of-dns-history-details']}
      detailsTop={breadCrumbs}
      id={`record-history-${historyId}`}
      heading={`${name}`}
      headingTop={t.TG07('Change')}
      heroPicto="maintenance"
    >
      {content}
    </DetailsWrapper>
  );
};
