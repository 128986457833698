import * as CL from '@design-system/component-library';
import { paths } from '../../common/constants/pathVariables.js';
import { t } from '../../common/i18n/index.js';
import { useNavigate } from 'react-router-dom';
import { useTurbonappiContext } from './TurbonappiContextProvider.js';

import './TurbonappiFrontPageLink.scss';

export const TurbonappiFrontPageLink = () => {
  const navigate = useNavigate();
  const { setTurbonappiData } = useTurbonappiContext();

  return (
    <div className="of-turbonappi-front-page-link">
      <CL.Button
        color="link"
        onClick={() => {
          // TODO this could be done side-effect free by using react-router error boundary and throwing error before this view
          setTurbonappiData({});
          navigate(paths.TURBONAPPI);
        }}
      >
        {t.QULB('Return to product page')}
      </CL.Button>
    </div>
  );
};
