import * as CL from '@design-system/component-library';
import { AuthenticatedUserRole } from '../../generated/api/models.js';
import { EmployeeForbiddenDialog } from './EmployeeForbiddenDialog.js';
import { Login } from '../Login/index.js';
import { Picture } from '../Picture/Picture.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { paths } from '../../common/constants/pathVariables.js';
import { pictureStorageBaseUrl } from '../Picture/contentfulImageProxy.js';
import { t } from '../../common/i18n/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useSelector } from 'react-redux';
import type { State } from '../../selfservice/common/store.js';

import './SelfServiceLogin.scss';

export const SelfServiceLogin = () => {
  const dialog = useSelector((state: State) => state.dialog, deepEqual);
  const { authenticatedUser } = useAuth();
  const isAuthenticatedEmployee =
    authenticatedUser?.companyName &&
    authenticatedUser?.firstName &&
    authenticatedUser?.userRole === AuthenticatedUserRole.EMPLOYEE;

  return (
    <div className="of-self-service-login-scene">
      {isAuthenticatedEmployee && <EmployeeForbiddenDialog />}

      <CL.Grid className="of-self-service-login-scene__top">
        <CL.GridRow>
          <CL.GridCol colsXS={4} colsS={6} colsM={1} colsL={3} colsXL={4} className="empty-col" />
          <CL.GridCol colsXS={4} colsS={6} colsM={4} colsL={6} colsXL={6} className="center-col">
            <div className={dsClass.BACKGROUND_COLOR_WHITE}>
              <Login dialog={dialog} />
            </div>
          </CL.GridCol>
          <CL.GridCol colsXS={4} colsS={6} colsM={1} colsL={3} colsXL={3} className="empty-col" />
        </CL.GridRow>
      </CL.Grid>

      <div className="omaelisa-info">
        <CL.Grid className="info-grid of-self-service-login-scene__bottom">
          <CL.GridRow className="picture-and-text-grid">
            <CL.GridCol colsXS={4} colsS={6} colsM={3} colsL={5} colsXL={6} className="picture-column">
              <Picture
                alt={t.H6X9('OmaElisa on a tablet')}
                offerWidthAlternatives={[630, 1260]}
                renderedImageSize={{
                  onPhone: '80vw',
                  onTablet: '50vw',
                  onLaptop: '45vw',
                  onDesktop: '40vw',
                }}
                src={`${pictureStorageBaseUrl}o2fvvexqusMaQWKwy46AU/oe-tablet%402x.png`}
              />
            </CL.GridCol>
            <CL.GridCol colsXS={4} colsS={6} colsM={1} colsL={1} colsXL={1} className="center-col-picture" />
            <CL.GridCol colsXS={4} colsS={6} colsM={3} colsL={5} colsXL={5} className="text-at-right">
              <h2 className={`${dsClass.H2} ${dsClass.MARGIN_TOP_0}`}>{t.RN1O('Easier everyday life')}</h2>
              <h3 className={`${dsClass.H4} ${dsClass.MARGIN_BOTTOM_0}`}>{t.J378('Take OmaElisa in to use')}</h3>
              <p className={`${dsClass.TEXT_L} ${dsClass.MARGIN_TOP_2}`}>
                {t.M4P9(
                  'Handle subscription and invoice matters fast, at a time that suits you. For example, you can track the status of your invoices and check a PUK code free of charge.'
                )}
              </p>
              <h3 className={`${dsClass.H4} ${dsClass.MARGIN_BOTTOM_0}`}>
                {t.CJT5('Register an account and sign in')}
              </h3>
              <p className={`${dsClass.TEXT_L} ${dsClass.MARGIN_TOP_2}`}>
                {t.WI85('You can easily')} <a href={paths.REGISTER_PAGE}>{t.MI9J('create your own ID')}</a>
                {t.OIUB(', if you have the right to sign on behalf of your business.')}
              </p>
              <p>
                <a href={paths.SELF_SERVICE_COMPANY_INFO}>{t.W6MP('Additional information about OmaElisa')}</a>
              </p>
            </CL.GridCol>
          </CL.GridRow>
        </CL.Grid>
      </div>
    </div>
  );
};
