import { TypeKeys, generateActionId } from './actionsUtils.js';
import { convertToCommonErrors } from '../../common/utils/errorUtils.js';
import type { AccountKeyUser, AccountKeyUsersResponse, GenericError } from '../../generated/api/models.js';
import type { CompositeListSort } from '../../components/CompositeListHeader/CompositeListHeader.js';
import type { DisplayItemsLoadAction, ErrorAction, ErrorActionCreator } from './actionsUtils.js';

/**
 * Managed users.
 */
export type LoadAccountKeyUsersAction = DisplayItemsLoadAction<TypeKeys.LOAD_ACCOUNT_KEY_USERS, AccountKeyUser>;

export interface LoadAccountKeyUsersFulfilledAction {
  type: TypeKeys.LOAD_ACCOUNT_KEY_USERS_FULFILLED;
  accountKeyUsers?: AccountKeyUser[];
}

export const loadAccountKeyUsers = (sort?: CompositeListSort): LoadAccountKeyUsersAction => {
  return {
    id: generateActionId(),
    sort,
    type: TypeKeys.LOAD_ACCOUNT_KEY_USERS,
  };
};

export const loadAccountKeyUsersFulfilled = (response: AccountKeyUsersResponse): LoadAccountKeyUsersFulfilledAction => {
  return {
    accountKeyUsers: response.accountKeyUsers,
    type: TypeKeys.LOAD_ACCOUNT_KEY_USERS_FULFILLED,
  };
};

export type LoadAccountKeyUsersFailedAction = ErrorAction<TypeKeys.LOAD_ACCOUNT_KEY_USERS_FAILED>;

export const loadAccountKeyUsersFailed: ErrorActionCreator<TypeKeys.LOAD_ACCOUNT_KEY_USERS_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[]
): LoadAccountKeyUsersFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    type: TypeKeys.LOAD_ACCOUNT_KEY_USERS_FAILED,
  };
};

export type AccountKeyUsersActionTypes =
  | LoadAccountKeyUsersAction
  | LoadAccountKeyUsersFulfilledAction
  | LoadAccountKeyUsersFailedAction;
