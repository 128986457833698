import { PopoverContent } from './CommercialProductContent.js';
import { t } from '../../../common/i18n/index.js';

export const ReissunettiTwoPopoverContent = () => (
  <PopoverContent>
    <div>
      <strong>Elisa Reissunetti 2 </strong>
      {t.E74C(
        'GB is activated automatically in the target countries and offers a fast data transfer connection using a mobile device or laptop computer at an affordable price in countries outside the EU/EEA. The service has no monthly charge. Instead, pricing is activated based on usage at €26.90 per 14 days.'
      )}
    </div>
  </PopoverContent>
);
