import { Children, cloneElement } from 'react';
import { StepperItem } from '../StepperItem/StepperItem.js';

export interface StepperProps {
  children?: JSX.Element | JSX.Element[];
  className?: string;
}

function updateStepperItemId(item: JSX.Element, i: number): JSX.Element {
  return item.props.id ? item : cloneElement(item, { id: 'ea-stepper__step--' + i });
}

function updateChildIds(children: JSX.Element | JSX.Element[]): JSX.Element | JSX.Element[] {
  return Children.map(children, (child, i) => {
    return child.type === StepperItem ? updateStepperItemId(child, i) : child;
  });
}

export const Stepper = (props: StepperProps) => {
  const children = props.children ? updateChildIds(props.children) : undefined;

  return (
    <div id="ea-stepper-wrapper">
      <div className={`ea-stepper ${props.className || ''}`}>
        <ul>{children}</ul>
      </div>
    </div>
  );
};
