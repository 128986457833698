import { PaymentTypeEnum, getProductPriceData } from '../common/googleEcommerceEvent.js';
import { SupportCase } from '../../generated/api/models.js';
import { TypeKeys, googleEcommercePurchase } from '../actions/index.js';
import { actionToActionState } from './epicUtils.js';
import { combineEpics, ofType } from 'redux-observable';
import { concatMap, mergeMap } from 'rxjs/operators';
import { createBa } from './billingAccountEpic.js';
import { createSupportCaseObservable } from './supportCaseEpic.js';
import { getSupportCaseDescription } from '../../components/FixedBroadbandCheckout/FixedBroadbandSupportCaseUtils.js';
import { of } from 'rxjs';
import { prepareUiApiRequest } from '../common/uiApiUtils.js';
import {
  submitFixedBroadbandOrderFailed,
  submitFixedBroadbandOrderFulfilled,
} from '../actions/fixedBroadbandActions.js';
import type { Action } from 'redux';
import type { ActionAndState, EpicDependencies } from './epicUtils.js';
import type { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import type {
  AddSupportCaseFailedAction,
  AddSupportCaseFulfilledAction,
  SelfServiceActionTypes,
  UpsertBillingAccountFailedAction,
  UpsertBillingAccountFulfilledAction,
} from '../actions/index.js';
import type { State } from '../common/store.js';
import type { SubmitFixedBroadbandOrderAction } from '../actions/fixedBroadbandActions.js';

export const submitFixedBroadbandOrderEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(
    action$.pipe(ofType(TypeKeys.SUBMIT_FIXED_BROADBAND_ORDER)),
    (action: SubmitFixedBroadbandOrderAction) => actionToActionState(action, state$, 'fixedBroadbandOrders')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      if (!actionAndState.state) {
        throw new Error('invalid action state for upserting billing account first phase');
      }

      const currentAction = actionAndState.action as SubmitFixedBroadbandOrderAction;

      if (currentAction.newBillingAccount) {
        return createBa(currentAction, state$, epicDependencies).pipe(
          concatMap((action: UpsertBillingAccountFulfilledAction | UpsertBillingAccountFailedAction) => {
            if (action.type === TypeKeys.UPSERT_BILLING_ACCOUNT_FAILED) {
              return of(submitFixedBroadbandOrderFailed('Failed to create BA', 500));
            } else {
              const orderWithNewBA = {
                fixedBroadbandOrder: {
                  ...currentAction.fixedBroadbandOrder,
                  billingAccountDisplayId: action.billingAccount.billingAccountDisplayId,
                },
              };

              return of({ ...currentAction, ...orderWithNewBA, newBillingAccount: undefined });
            }
          })
        );
      }

      const supportCase: SupportCase = {
        feature: SupportCase.FeatureEnum.FIXED_BROADBAND_ORDER,
        action: 'Kiinteän laajakaistan tilaus / saatavuuskysely',
        url: window.location.pathname,
        description: getSupportCaseDescription(currentAction.fixedBroadbandOrder),
      };

      return createSupportCaseObservable(supportCase, state$, epicDependencies).pipe(
        mergeMap((action: AddSupportCaseFailedAction | AddSupportCaseFulfilledAction) => {
          if (action.type === TypeKeys.ADD_SUPPORT_CASE_FAILED) {
            return of(submitFixedBroadbandOrderFailed('Failed to create support case', 500));
          }

          const { totalTax, totalRevenue, totalShipping } = getProductPriceData([currentAction.configuredOffer]);
          const supportCaseDisplayId = action.response?.supportCaseDisplayId || '';

          return of(
            googleEcommercePurchase(
              supportCaseDisplayId,
              totalRevenue,
              totalTax,
              totalShipping,
              currentAction.eCommerceEventProducts,
              PaymentTypeEnum.INVOICE
            ),
            submitFixedBroadbandOrderFulfilled()
          );
        })
      );
    })
  );

export const fixedBroadbandOrderEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> =
  combineEpics(submitFixedBroadbandOrderEpic);
