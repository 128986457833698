import type {
  FinalizePaymentResponse,
  GenericError,
  InitializePaymentResponse,
  Invoice,
} from '../../generated/api/models.js';

import { TypeKeys, generateActionId } from './actionsUtils.js';
import { convertToCommonErrors } from '../../common/utils/errorUtils.js';
import type { CrudAction, ErrorAction, ErrorActionCreator } from './actionsUtils.js';

export interface InitializePaymentAction extends CrudAction<TypeKeys.INITIALIZE_PAYMENT> {
  balance: number;
  paymentMethod: string;
  invoiceId: string;
}

export const initializePayment = (
  invoiceId: string,
  balance: number,
  paymentMethod: string
): InitializePaymentAction => {
  return {
    balance,
    paymentMethod,
    id: generateActionId(),
    invoiceId,
    type: TypeKeys.INITIALIZE_PAYMENT,
  };
};

export type RegisterInvoiceForPaymentFailedAction = ErrorAction<TypeKeys.INITIALIZE_PAYMENT_FAILED>;

export const initializePaymentFailed: ErrorActionCreator<TypeKeys.INITIALIZE_PAYMENT_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): RegisterInvoiceForPaymentFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    params,
    type: TypeKeys.INITIALIZE_PAYMENT_FAILED,
  };
};

export type InitializePaymentFulfilledAction = {
  forwardUrl: string;
  transactionId: string;
  type: TypeKeys.INITIALIZE_PAYMENT_FULFILLED;
};

export const initializePaymentFulfilled = ({
  forwardUrl,
  transactionId,
}: InitializePaymentResponse): InitializePaymentFulfilledAction => ({
  forwardUrl,
  transactionId,
  type: TypeKeys.INITIALIZE_PAYMENT_FULFILLED,
});

export interface FinalizePaymentAction extends CrudAction<TypeKeys.FINALIZE_PAYMENT> {
  invoiceId: string;
  transactionId: string;
}

export const finalizePayment = (invoiceId: string, transactionId: string): FinalizePaymentAction => {
  return {
    id: generateActionId(),
    invoiceId,
    transactionId,
    type: TypeKeys.FINALIZE_PAYMENT,
  };
};

export type FinalizePaymentFailedAction = ErrorAction<TypeKeys.FINALIZE_PAYMENT_FAILED>;

export const finalizePaymentFailed: ErrorActionCreator<TypeKeys.FINALIZE_PAYMENT_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): FinalizePaymentFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    params,
    type: TypeKeys.FINALIZE_PAYMENT_FAILED,
  };
};

export type FinalizePaymentFulfilledAction = {
  lastModified: number;
  type: TypeKeys.FINALIZE_PAYMENT_FULFILLED;
  invoice: Invoice;
};

export const finalizePaymentFulfilled = (
  { lastModified }: FinalizePaymentResponse,
  invoice: Invoice
): FinalizePaymentFulfilledAction => {
  return {
    lastModified,
    type: TypeKeys.FINALIZE_PAYMENT_FULFILLED,
    invoice,
  };
};

export type PaymentsActionTypes =
  | InitializePaymentAction
  | RegisterInvoiceForPaymentFailedAction
  | InitializePaymentFulfilledAction
  | FinalizePaymentAction
  | FinalizePaymentFailedAction
  | FinalizePaymentFulfilledAction;
