import type { MutableRefObject, Ref } from 'react';

export const MOVEMENT_SPEED = 4;
export const CLEARABLE_CLASS = 'clearable';
export const FOCUSED_CLASS = 'focused';
export const HIGHLIGHTED_CLASS = 'highlighted';
export const ACTIVE_CLASS = 'active';
export const DEBOUNCE_TIMEOUT = 300;
export const QUERY_MIN_LENGTH = 3;

export interface ConfigurationObject {
  isClearable?: boolean;
  isSortable?: boolean;
  isWritable?: boolean;
  movementSpeed?: number;
  debounceTimeout?: number;
  queryMinLength?: number;
}

export type InputTypes = HTMLInputElement | null;
export type OptionTypes = HTMLLIElement | null;
export type OptionsTypes = HTMLUListElement | null;
export type ExternalInputRef = Ref<{ focus: () => void }> | undefined;
export type InputRef = Ref<HTMLInputElement>;
export type OptionRef = MutableRefObject<OptionTypes>;
export type OptionsRef = MutableRefObject<OptionsTypes>;
