/**
 * Online UI API
 * # Summary Elisa B2B Online UI API  # Usage This API is used in the `https://yrityksille.elisa.fi/` pages.  # Identifiers 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AccountHeader } from './accountHeader.js';


/**
 * Conflicted authentication response
 */
export interface ConflictedAuthenticationResult { 
    /**
     * Type of conflict, one per key below.
     */
    type: ConflictedAuthenticationResult.TypeEnum;
    /**
     * Conflicted user names, the user needs to select one of these and re-login
     */
    userNames?: Array<string>;
    /**
     * Conflicted login response for selecting primary account
     */
    availableAccounts?: Array<AccountHeader>;
    /**
     * Name of the company when conflicting type is CONFLICTING_ACCOUNT
     */
    conflictingAccountName?: string;
}
export namespace ConflictedAuthenticationResult {
    export type TypeEnum = 'MOBILE_NUMBER_MISSING' | 'MULTIPLE_USER_NAMES' | 'PRIMARY_ACCOUNT_NOT_SET' | 'CONFLICTING_ACCOUNT' | 'DUPLICATE_CONTACT' | 'DUPLICATE_FROM_HIERARCHY' | 'POSSIBLE_CONTACT_DUPLICATE' | 'CONTACT_SUPPORT' | 'ONBOARDING_LINK_USED_OR_EXPIRED' | 'ONBOARDING_LINK_OLD_OR_INVALID' | 'USER_NOT_FOUND' | 'MOBILE_NUMBER_MISSING_MFA_REQUIRED';
    export const TypeEnum = {
        MOBILE_NUMBER_MISSING: 'MOBILE_NUMBER_MISSING' as TypeEnum,
        MULTIPLE_USER_NAMES: 'MULTIPLE_USER_NAMES' as TypeEnum,
        PRIMARY_ACCOUNT_NOT_SET: 'PRIMARY_ACCOUNT_NOT_SET' as TypeEnum,
        CONFLICTING_ACCOUNT: 'CONFLICTING_ACCOUNT' as TypeEnum,
        DUPLICATE_CONTACT: 'DUPLICATE_CONTACT' as TypeEnum,
        DUPLICATE_FROM_HIERARCHY: 'DUPLICATE_FROM_HIERARCHY' as TypeEnum,
        POSSIBLE_CONTACT_DUPLICATE: 'POSSIBLE_CONTACT_DUPLICATE' as TypeEnum,
        CONTACT_SUPPORT: 'CONTACT_SUPPORT' as TypeEnum,
        ONBOARDING_LINK_USED_OR_EXPIRED: 'ONBOARDING_LINK_USED_OR_EXPIRED' as TypeEnum,
        ONBOARDING_LINK_OLD_OR_INVALID: 'ONBOARDING_LINK_OLD_OR_INVALID' as TypeEnum,
        USER_NOT_FOUND: 'USER_NOT_FOUND' as TypeEnum,
        MOBILE_NUMBER_MISSING_MFA_REQUIRED: 'MOBILE_NUMBER_MISSING_MFA_REQUIRED' as TypeEnum
    };
}


