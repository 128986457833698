import * as CL from '@design-system/component-library';
import { type OpenFormAccount } from '../OpenFormHooks/useOpenFormAccount.js';
import { OpenFormButton } from './OpenFormButton.js';
import { OpenFormIcon } from './OpenFormIcon.js';
import { useCallback } from 'react';
import { useOpenFormState } from '../OpenFormProvider.js';
import { useOutletContext } from 'react-router-dom';

export const OpenFormLoadingIndicator = ({ heading }: { heading?: string }) => {
  const { disabled, form, loading } = useOpenFormState();
  const outlet = useOutletContext<{ account?: OpenFormAccount; reload?: () => void }>();
  const text = heading || (loading?.values().next().value as string | undefined);

  const UpdateIcon = useCallback(
    () =>
      !outlet?.reload ? null : (
        <OpenFormButton
          className="of-openform__title__content__center__name__update"
          icon={<OpenFormIcon icon="update" />}
          color="link"
          size="s"
          disabled={disabled}
          onClick={outlet.reload}
        />
      ),
    [disabled, outlet?.reload]
  );

  return (
    <div className="of-openform__title__content__center">
      {!text ? (
        <CL.Logo aria-disabled={disabled} className="of-openform__title__content__center__elisa-logo" />
      ) : (
        <div className="of-openform__title__content__center__loader">
          <div className="of-openform__title__content__center__loader__text">
            <h4>{text}</h4>
          </div>
          <div className="of-openform__title__content__center__loader__spinner">
            <CL.LoadingSpinner key={text} size="l" />
          </div>
        </div>
      )}
      <div aria-disabled={disabled} className="of-openform__title__content__center__name">
        {!outlet?.account ? null : !form ? (
          <>
            <span className="of-openform__title__content__center__name__account">
              {outlet.account.header.accountName}
              <UpdateIcon />
            </span>
            <span className="of-openform__title__content__center__name__form">
              <>&nbsp;</>
            </span>
          </>
        ) : (
          <>
            <span className="of-openform__title__content__center__name__account">
              {outlet.account.header.accountName}
            </span>
            <span className="of-openform__title__content__center__name__form">
              {form.name}
              <UpdateIcon />
            </span>
          </>
        )}
      </div>
    </div>
  );
};
