import * as CL from '@design-system/component-library';
import { GridColumn } from './GridColumn.js';
import { InfoFieldWithTooltip } from './InfoFieldWithTooltip.js';
import { eInvoicingAddressMsg, eInvoicingOperatorMsg, t } from '../../../common/i18n/index.js';

export interface DeliveryMethodElectricProps {
  billElectronicOperator?: string;
  billElectronicAddress?: string;
  highlightBillElectronicOperator?: boolean;
  highlightBillElectronicAddress?: boolean;
}

export const ElectricDeliveryMethodDetails = ({
  billElectronicOperator,
  billElectronicAddress,
  highlightBillElectronicOperator,
  highlightBillElectronicAddress,
}: DeliveryMethodElectricProps) => {
  return (
    <CL.GridRow>
      <GridColumn className="of-billing-account-details form-column view-field">
        <InfoFieldWithTooltip
          label={t.WVLB(eInvoicingOperatorMsg)}
          value={billElectronicOperator}
          highlight={highlightBillElectronicOperator}
          name="billElectronicOperator"
        />
      </GridColumn>
      <GridColumn className="of-billing-account-details form-column view-field">
        <InfoFieldWithTooltip
          label={t.OL7B(eInvoicingAddressMsg)}
          value={billElectronicAddress}
          highlight={highlightBillElectronicAddress}
          name="billElectronicAddress"
        />
      </GridColumn>
    </CL.GridRow>
  );
};
