import { Tooltip } from '../Tooltip/Tooltip.js';
import type { TooltipProps } from '../Tooltip/Tooltip.js';

import './LabelWithTooltip.scss';

export interface LabelWithTooltipProps extends TooltipProps {
  labelText: string | JSX.Element;
  className?: string;
  fullWidth?: boolean;
}

/**
 * @deprecated Use TextWithPopover instead
 *
 **/
export const LabelWithTooltip = ({
  alignTooltipIconRight,
  children,
  className,
  forceDisplayed,
  fullWidth,
  labelText,
}: LabelWithTooltipProps) => {
  return (
    <div className={`of-label-with-tooltip ${className}`}>
      <div className={`of-label-with-tooltip__label ${fullWidth === true ? 'of-label-with-tooltip__label--full' : ''}`}>
        {labelText}
      </div>
      {children && (
        <Tooltip forceDisplayed={forceDisplayed} alignTooltipIconRight={alignTooltipIconRight}>
          {children}
        </Tooltip>
      )}
    </div>
  );
};
