import { CategoryList } from './CategoryList.js';
import { fetchOnlineModelHeaders } from '../../common/fetch.js';
import { useEffect, useState } from 'react';
import type { CategoryListData } from '../cmsSchema.js';
import type { OnlineModelHeader } from '../../generated/api/onlineModelHeader.js';

export const CategoryListContainer = (categoryListData: CategoryListData) => {
  const { productsWithTags } = categoryListData;

  const [onlineModelHeaders, setOnlineModelHeaders] = useState<Array<OnlineModelHeader>>([]);
  useEffect(() => {
    // To restrict the race condition, due the async response. refer: https://maxrozen.com/race-conditions-fetching-data-react-with-useeffect
    // TODO: extract this as a common fetch hook with this resolution.
    let isFetched = true;
    if (productsWithTags && productsWithTags?.length > 0) {
      fetchOnlineModelHeaders(productsWithTags).then(onlineModelsResponse => {
        if (isFetched) {
          setOnlineModelHeaders(onlineModelsResponse.headers || []);
        }
      });
    }
    return () => {
      isFetched = false;
    };
  }, [productsWithTags]);

  return <CategoryList categoryListData={categoryListData} onlineModelHeaders={onlineModelHeaders} />;
};
