import { AddOnPurpose } from '../../generated/api/models.js';
import type {
  AddOnAssociation,
  Address,
  CommercialProduct,
  DeliveryAddress,
  MobilePbxOrderDetails,
  Subscription,
} from '../../generated/api/models.js';
import type { CompanyInfoState } from '../../common/types/states.js';
import type { ConfiguredCommercialProduct, ConfiguredOffer } from '../../common/types/commercialProduct.js';
import type { ServiceLevel, ServiceLevelAddon } from '../AttachRing/ServiceLevelAndAddonsInterfaces.js';

const modifyServiceLevelAddonToAddOnAssociation = (addon: ServiceLevelAddon): AddOnAssociation => ({
  addOnAssociationCode: addon.addonAssociationCode,
  addOnAssociationId: '',
  addOnCode: addon.addonCode,
  addOnPurpose: AddOnPurpose.BY_DEFAULT,
  display: true,
});

const getAddonsTotalMonthlyRecurringCharge = (addons: ServiceLevelAddon[]) =>
  addons.map(addon => addon.monthlyPrice).reduce((accumulator, currentPrice) => accumulator + currentPrice, 0);

const constructCommercialProduct = (
  selectedServiceLevel: ServiceLevel,
  addonAssociations: AddOnAssociation[],
  addonsMonthlyRecurringCharge: number
): CommercialProduct => ({
  productType: 'MOBILE_PBX_SERVICE_LEVEL',
  active: true,
  commercialProductCode: selectedServiceLevel.id,
  commercialProductName: selectedServiceLevel.name,
  monthlyRecurringCharge: selectedServiceLevel.price + addonsMonthlyRecurringCharge,
  addOnAssociations: addonAssociations,
});

const constructConfiguredCommercialProduct = (
  commercialProduct: CommercialProduct,
  mobilePbxServiceLevelDetails: MobilePbxOrderDetails
): ConfiguredCommercialProduct => ({
  commercialProduct: commercialProduct,
  purposeOfUseOrContact: {},
  mobilePbxServiceLevelDetails,
});

const constructOffer = (commercialProduct: CommercialProduct, offerCode: string) => ({
  offerId: '',
  created: 0,
  offerName: '',
  offerCode: offerCode,
  lastModified: 0,
  commercialProducts: [commercialProduct],
});

const emptyAddress: Address = {
  line1: '',
  postalCode: '',
  postOffice: '',
  countryCode: '',
};

export const constructDeliveryAddress = (
  companyInfo?: CompanyInfoState | null,
  subscription?: Subscription
): DeliveryAddress => {
  return {
    address: companyInfo?.address ? companyInfo.address : emptyAddress,
    companyName: companyInfo?.companyName || '',
    phoneNumber: companyInfo?.phoneNumber || '',
    recipient: subscription?.subscriptionContactName || companyInfo?.companyName || '',
  };
};

export const createOfferFromSelectedAddons = (
  selectedServiceLevel: ServiceLevel,
  selectedServiceLevelAddons: ServiceLevelAddon[],
  mobilePbxServiceLevelDetails: MobilePbxOrderDetails
): ConfiguredOffer => {
  const addonAssociations = selectedServiceLevelAddons.map(modifyServiceLevelAddonToAddOnAssociation);
  const addonsTotalMonthlyRecurringCharge = getAddonsTotalMonthlyRecurringCharge(selectedServiceLevelAddons);
  const commercialProduct = constructCommercialProduct(
    selectedServiceLevel,
    addonAssociations,
    addonsTotalMonthlyRecurringCharge
  );
  const configuredCommercialProduct = constructConfiguredCommercialProduct(
    commercialProduct,
    mobilePbxServiceLevelDetails
  );
  const offer = constructOffer(commercialProduct, selectedServiceLevel.offerId);
  return { selectedCommercialProducts: [configuredCommercialProduct], offer: offer };
};

export const getAccountId = (subscription?: Subscription) =>
  subscription?.billingAccountId ? subscription.billingAccountId : '';
