import { ModelType } from '../enums';
import { SubscriptionType } from '../../generated/api/subscriptionType';
import { getRingServiceLevelsFromOnlineModel } from '../../components/AttachRing/attachRingDataConverter';
import type { CompanyInfoState } from '../types/states';
import type { OnlineModel } from '../../generated/api/onlineModel';
import type { ServiceLevel } from '../../components/AttachRing/ServiceLevelAndAddonsInterfaces';

export const findBestMatchForExtensionNumber = (phoneNumber: string, extensions: string[] = []) => {
  if (extensions.length === 0) {
    return '';
  }

  const lastThreeDigits = phoneNumber.slice(-3);
  const lastTwoDigits = phoneNumber.slice(-2);

  return (
    extensions.find(num => num.toString().slice(-3) === lastThreeDigits) ||
    extensions.find(num => num.toString().slice(-2) === lastTwoDigits) ||
    undefined
  );
};

export const getPbxServiceLevels = (companyInfo?: CompanyInfoState, onlineModels?: OnlineModel[]): ServiceLevel[] => {
  if (companyInfo && companyInfo.pbxSolutions) {
    const ringSolution = companyInfo.pbxSolutions.find(
      pbxSolution => pbxSolution.subscriptionType === SubscriptionType.MOBILE_PBX
    );

    if (ringSolution) {
      const productCodes =
        ringSolution.details?.mobilePbx?.mobilePbxServiceLevelProduct?.userServiceLevelCommercialProductCodes;

      const productAddons = ringSolution.details?.mobilePbx?.mobilePbxServiceLevelProduct?.addOnServiceLevelSelections;

      if (productCodes && productAddons) {
        const getRingServicesModel = (onlineModelsInput: OnlineModel[]): OnlineModel | undefined =>
          onlineModelsInput && onlineModelsInput.find(model => model.onlineModelCode === ModelType.Ring);
        const ringServicesModel = onlineModels ? getRingServicesModel(onlineModels) : undefined;
        const serviceLevelsAndAddons = getRingServiceLevelsFromOnlineModel(
          productCodes,
          productAddons,
          ringServicesModel
        );
        return serviceLevelsAndAddons.pbxServiceLevels;
      }
    }
  }
  return [];
};
