/**
 * Online UI API
 * # Summary Elisa B2B Online UI API  # Usage This API is used in the `https://yrityksille.elisa.fi/` pages.  # Identifiers 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DnsRecordEditableType = 'A' | 'AAAA' | 'CNAME' | 'MX' | 'NS' | 'SRV' | 'TXT';

export const DnsRecordEditableType = {
    A: 'A' as DnsRecordEditableType,
    AAAA: 'AAAA' as DnsRecordEditableType,
    CNAME: 'CNAME' as DnsRecordEditableType,
    MX: 'MX' as DnsRecordEditableType,
    NS: 'NS' as DnsRecordEditableType,
    SRV: 'SRV' as DnsRecordEditableType,
    TXT: 'TXT' as DnsRecordEditableType
};

