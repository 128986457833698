import * as CL from '@design-system/component-library';
import { RecordInput } from './RecordInput.js';
import { t } from '../../../../../common/i18n/index.js';
import { validateHostname, validatePriority } from './fieldValidation.js';

export const MXValueField = ({ index }: { index: number }) => (
  <>
    <CL.GridCol colsXS={3}>
      <RecordInput name="host" validate={validateHostname} index={index} />
    </CL.GridCol>
    <CL.GridCol colsXS={1}>
      <RecordInput label={t.ZS4S('Priority')} name="priority" type="number" validate={validatePriority} index={index} />
    </CL.GridCol>
  </>
);
