import { OpenFormBar } from '../OpenFormComponents/OpenFormBar.js';
import { OpenFormButton } from '../OpenFormComponents/OpenFormButton.js';
import { OpenFormGrid, OpenFormGridRow } from '../OpenFormComponents/OpenFormGrid.js';
import { OpenFormViewSection } from './OpenFormViewSection.js';
import { OpenFormViewSummary } from './OpenFormViewSummary.js';
import { clearFormMsg, salesFormsMsg, t } from '../../../common/i18n/index.js';
import { parsePath } from '../OpenFormUtils.js';
import { paths } from '../../../common/constants/pathVariables.js';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useOpenForm } from '../OpenFormHooks/useOpenForm.js';
import { useOpenFormAsync, useOpenFormState } from '../OpenFormProvider.js';
import { useOpenFormNavigation } from '../OpenFormHooks/useOpenFormNavigation.js';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import type { FormId, ListId, Page } from '../../../common/constants/pathInterfaces.js';

export const OpenFormViewLayout = ({ preview = false }) => {
  const { answers, disabled, list, valid } = useOpenFormState();
  const async = useOpenFormAsync();
  const methods = useFormContext();
  const params = useParams<FormId & Page>();
  const form = useOpenForm(params as FormId);
  const breadcrumbs = useMemo(() => (form?.sections ?? []).map(({ guid }) => guid).concat('summary'), [form]);
  const [page, path, next, prev] = useOpenFormNavigation(params, preview, form, answers);

  const clear = useCallback(() => {
    async.cache.clear();
    async.actions.form({ answers: undefined, form });
    methods.reset({});
    path(1);
  }, [async, methods, form, path]);

  return (
    <OpenFormBar
      back={useMemo(() => parsePath<ListId>(list?.listId ? paths.OPENFORM_LIST : paths.OPENFORM, list), [list])}
      backText={t.QF2O(salesFormsMsg)}
      clear={answers.size ? clear : undefined}
      clearText={t.JQ1V(clearFormMsg)}
      preview={preview}
    >
      {!form ? null : (
        <OpenFormGrid className="of-openform__view">
          <OpenFormGridRow className="of-openform__view__breadcrumbs">
            {breadcrumbs.map((guid, index) => (
              <OpenFormButton
                className={classNames({ ['breadcrumb--incomplete']: index >= page })}
                disabled={disabled}
                color="primary"
                size="l"
                text={index + 1}
                key={guid}
              />
            ))}
          </OpenFormGridRow>
          {page > form.sections.length ? (
            <OpenFormViewSummary
              preview={preview}
              form={form}
              answers={answers}
              disabled={disabled}
              valid={valid}
              clear={clear}
              prev={prev}
            />
          ) : (
            <OpenFormViewSection
              methods={methods}
              form={form}
              answers={answers}
              disabled={disabled}
              valid={valid}
              page={page}
              next={next}
              prev={prev}
            />
          )}
        </OpenFormGrid>
      )}
    </OpenFormBar>
  );
};
