import {
  CommercialProductSubType,
  CommercialProductType,
  EppSolutionResponse,
  OnlineModelCategory,
} from '../../generated/api/models.js';
import { groupBy } from './arrayUtils.js';
import { isNumber } from './objectUtils.js';
import { userHasEmptyOrSMEPriceGroup } from './employeeUtils.js';
import type { ActionsHistory } from '../../selfservice/common/store.js';
import type { AuthenticatedUserState, CompanyInfoState } from '../types/states.js';
import type {
  CommercialProduct,
  DiscountedPriceItem,
  DiscountedPrices,
  Offer,
  OnlineModelHeader,
  Price,
  PricesByType,
} from '../../generated/api/models.js';

/**
 * Useful for finding out best price to use
 * oneTimeCharge populates only if there's price with 1 payment, payments don't populate in this scenario
 * monthlyRecurringCharge populates with the lowest value available determined by the highest payments value available
 * payments populate with the payment value of the lowest priced option
 * cheapest combination will be used to populate monthlyRecurringCharge and payments
 */
export const resolvePrices = (listOfPrices: Array<Price>): Price => {
  const resolveCharge = (m1?: number, m2?: number) => {
    if (m1 !== undefined && m2 !== undefined) {
      return m1 < m2 ? m1 : m2;
    }
    return m1 !== undefined ? m1 : m2;
  };
  const resolvePayments = (prices: Price[], m?: number) =>
    m ? prices.find(price => price?.monthlyRecurringCharge === m)?.payments : undefined;
  const mapPrice = (prices: Price[]): Price => {
    return prices
      .map(price => (price.payments === 1 ? { oneTimeCharge: price.oneTimeCharge } : price))
      .reduce((acc, cur) => {
        const monthlyRecurringCharge = resolveCharge(acc.monthlyRecurringCharge, cur.monthlyRecurringCharge);
        return {
          oneTimeCharge:
            cur.oneTimeCharge && (!cur.payments || cur.payments === 1)
              ? resolveCharge(acc.oneTimeCharge, cur.oneTimeCharge)
              : acc.oneTimeCharge,
          monthlyRecurringCharge: monthlyRecurringCharge,
          payments: resolvePayments([acc, cur], monthlyRecurringCharge),
        };
      }, {});
  };
  return mapPrice(listOfPrices);
};

export const getOnetimePriceFromCommercialProducts = (commercialProducts: CommercialProduct[]): number | undefined => {
  return commercialProducts.find(
    ({ productSubType, payments }) => productSubType !== CommercialProductSubType.EPP_DEVICE && payments === 1
  )?.oneTimeCharge;
};

export const getMonthlyRecurringChargeFromCommercialProducts = (
  commercialProducts: CommercialProduct[]
): number | undefined =>
  commercialProducts.find(({ payments, price }) => payments === 0 && price?.effectivePrice?.monthlyRecurringCharge)
    ?.price?.effectivePrice?.monthlyRecurringCharge;

/**
 * As per current information content-blocks should use DEVICE type
 */
export const selectPricesByDeviceType = (onlineModelHeader: OnlineModelHeader) => {
  const prices = onlineModelHeader.lowestPrices.find(
    priceByType => priceByType.productSubType === CommercialProductSubType.DEVICE
  )?.prices;
  return (prices && prices.length && resolvePrices(prices)) || {};
};

/**
 * We need to note the billingPeriod - at least YTT-products have billing period greater than 0
 */
export const getMonthlyPrice = (monthlyRecurringCharge?: number, billingPeriod?: number) => {
  return monthlyRecurringCharge ? monthlyRecurringCharge / (billingPeriod || 1) : undefined;
};

/**
 * SALES_PRODUCT uses monthly price multiplied by 12 for analytics impression event
 */
export const getComputationalAveragePriceForSubscription = (commercialProducts: CommercialProduct[]) => {
  return (
    Math.min(
      ...commercialProducts.map(o => getMonthlyPrice(o.monthlyRecurringCharge, o.billingPeriod)).filter(isNumber)
    ) * 12
  );
};

/**
 * Non SALES_PRODUCT uses onetime price for analytics impression event
 */
export const getCheapestOnetimePriceFromDeviceCommercialProducts = (commercialProducts: CommercialProduct[]) => {
  return Math.min(...commercialProducts.filter(o => o.oneTimeCharge && o.payments === 1).map(o => o.oneTimeCharge!));
};

/**
 * Products with productType SALES_PRODUCT require special handling
 */
export const getPriceForType = (offers: Offer[], category?: string) => {
  const commercialProducts = offers.flatMap(o => o.commercialProducts);
  if (category !== OnlineModelCategory.SALES_PRODUCT) {
    return getCheapestOnetimePriceFromDeviceCommercialProducts(commercialProducts);
  }
  return getComputationalAveragePriceForSubscription(commercialProducts);
};

export function priceToString(priceInCents?: number): string | undefined {
  return priceInCents === undefined ? undefined : (priceInCents / 100).toFixed(2);
}
export const eurosToCents = (priceInEuros = 0): number => {
  return Math.trunc(priceInEuros * 100);
};

export const centsToEuros = (priceInCents = 0): number => priceInCents / 100;

/**
 * Enter a number in cents, output (money) sum in euros with currency.
 *
 * @param value in cents
 * @param omitCurrency skip the post-euro char
 * @return sum in EUR
 */
export const formatSumToString = (value?: number, omitCurrency = false): string => {
  const val = Math.abs(value || 0);
  const i = Math.floor(val / 100); // Integer part
  const f = val - 100 * i; // Fraction part
  const iStr = `${i}`;
  let formattedString;
  if (iStr.length > 3) {
    formattedString = iStr.substr(-3, 3);
    for (let index = -3; index > -iStr.length; index -= 3) {
      const startIndex = iStr.length >= Math.abs(index - 3) ? index - 3 : -iStr.length;
      const len = iStr.length + startIndex > 3 ? 3 : Math.min(3, iStr.length + index);
      formattedString = iStr.substr(startIndex, len) + ' ' + formattedString;
    }
  } else {
    formattedString = iStr;
  }
  const fStr = f < 10 ? `0${f}` : `${f}`; // Additional pre-zero
  return `${formattedString},${fStr}${!omitCurrency ? ' €' : ''}`; // Currency if needed
};

export const formatSum = (value?: number, omitCurrency = false): string | undefined => {
  if (value === undefined) {
    return undefined;
  }
  return formatSumToString(value, omitCurrency);
};

/**
 * Enter a number in cents, output (money) sum in euros with currency. Output with possible signum '-'.
 * By: Ville :O
 *
 * @param {number} value in cents
 * @param {boolean} omitCurrency skip the post-euro char
 * @return {string} in EUR
 */
export const formatSumWithSignum = (value?: number, omitCurrency?: boolean): string | undefined => {
  if (value === undefined) {
    return undefined;
  }
  const sumStr = formatSum(value, omitCurrency);
  const signumStr = value < 0 ? '-' : '';
  return `${signumStr}${sumStr}`;
};

/**
 * Subscription API returns one-time and reoccurring charge in n.n format, which needs to be reformatted to to n,nn
 *
 * @param {number} value
 * @return {string} formatted price
 */
export const formatNumberToCurrency = (value: number): string => {
  return value.toFixed(2).replace('.', ',');
};

/**
 * Parses price given by user as form input as a number
 * @param {string} value in formatted euro string such as 35,41
 * @return {number} price in cents such as 3541
 */
export const parseCurrencyToNumber = (value?: string): number | undefined =>
  value ? Number((Number(value.replace(',', '.')) * 100).toFixed(0)) : undefined;

/**
 * Format balance to negative format to indicate how much have been paid from total value.
 * E.g. if total is 1000 and balance is 100 then paid will be -900
 * and if total is 1000 and paid is -1000 then paid will be -2000
 * This is because positive balance is counted as there is something to pay and negative balance is counted that total is over paid.
 *
 * @param {number} total total number of invoice or maximum value that need to be paid.
 * @param {number} balance number indicating either how much is paid or how much need to be paid.
 * @param {boolean|undefined} omitCurrency indicating whether to skip post number 'EUR' currency
 * @return {string} in EUR
 */
export const formatBalance = (total: number, balance: number, omitCurrency?: boolean): string | undefined => {
  let value;
  // When positive its sum left to pay
  if (balance > 0) {
    value = Math.abs(balance - total) - Math.abs(balance - total) * 2; // Use times (*) 2 to negate the value
  } else if (balance < 0) {
    // When negative invoice has been over paid
    value = total + Math.abs(balance) - (total + Math.abs(balance)) * 2; // Use  times (*) 2 to negate the value
  } else {
    // When balance is 0, it is paid in full
    value = total - total * 2; // Use times (*) 2 to negate the value
  }

  return formatSum(value, omitCurrency);
};

type PriceWithSubType = Price & {
  productSubType: CommercialProductSubType;
};

const setDiscountsToPrices = (discountPrices: Price[], pricesWithSameSubType: Price[]): Price[] =>
  discountPrices.reduce((discounts, discount) => {
    if (discount.oneTimeCharge !== undefined && (discount.payments === 1 || !discount.payments)) {
      return [
        ...discounts.filter(price => price.payments && price.payments > 1),
        { oneTimeCharge: discount.oneTimeCharge, payments: discount.payments },
      ];
    } else if (discount.monthlyRecurringCharge !== undefined && discount.payments && discount.payments > 1) {
      const recurringChargePayment = discounts?.find(
        price => price.payments && price.payments > 1 && price.monthlyRecurringCharge
      );
      return !recurringChargePayment ||
        // @ts-ignore
        (recurringChargePayment && recurringChargePayment.monthlyRecurringCharge >= discount.monthlyRecurringCharge)
        ? [
            ...discounts.filter(price => price.payments === 1 || !price.payments),
            { payments: discount.payments, monthlyRecurringCharge: discount.monthlyRecurringCharge },
          ]
        : (discounts ?? []);
    }
    return discounts;
  }, pricesWithSameSubType);

const insertDiscountsToLowestPrices = (
  lowestPrices: PricesByType[],
  discountPrices: { [key: string]: PriceWithSubType[] },
  defaultProductType?: CommercialProductType
): PricesByType[] =>
  Object.keys(discountPrices).reduce((prices: PricesByType[], key: string) => {
    const indexOfPricesWithSameSubType = prices?.findIndex(lowestPrice => lowestPrice.productSubType === key);
    return indexOfPricesWithSameSubType !== -1
      ? [
          ...prices.filter(p => p !== prices[indexOfPricesWithSameSubType]),
          {
            ...prices[indexOfPricesWithSameSubType],
            prices: setDiscountsToPrices(discountPrices[key], prices[indexOfPricesWithSameSubType].prices),
          },
        ]
      : [
          ...prices,
          {
            prices: discountPrices[key].map(price => ({
              payments: price.payments,
              oneTimeCharge: price.oneTimeCharge,
              monthlyRecurringCharge: price.monthlyRecurringCharge,
            })),
            productSubType: key as CommercialProductSubType,
            productType: defaultProductType,
          },
        ];
  }, lowestPrices);

const createPriceWithSubTypes = (foundDiscount?: DiscountedPrices): PriceWithSubType[] =>
  foundDiscount?.prices.map(price => {
    const newPrice: PriceWithSubType = {
      payments: price.payments,
      productSubType: price.productSubType.toUpperCase() as CommercialProductSubType,
    };
    price.payments === 1 || !price.payments
      ? (newPrice.oneTimeCharge = price.oneTimeCharge)
      : (newPrice.monthlyRecurringCharge = price.monthlyRecurringCharge);
    return newPrice;
  }) || [];

const setUndefinedToZeroByPayment = (pricesByType: PricesByType[]): PricesByType[] =>
  pricesByType?.map(priceByType => {
    return {
      ...priceByType,
      prices: priceByType.prices.map(price => {
        return {
          payments: price.payments,
          oneTimeCharge: !price.payments || price.payments === 1 ? price.oneTimeCharge || 0 : undefined,
          monthlyRecurringCharge: price.payments && price.payments > 1 ? price.monthlyRecurringCharge || 0 : undefined,
        };
      }),
    };
  });

const eppSolutionSettingsAllowPrice = (
  price?: Price | DiscountedPriceItem,
  subType?: CommercialProductSubType,
  isEppCustomer?: boolean,
  companyInfoState?: CompanyInfoState | null,
  user?: (AuthenticatedUserState & ActionsHistory) | null
) => {
  if (isEppCustomer) {
    return (
      subType === CommercialProductSubType.EPP_DEVICE ||
      (price?.payments && price.payments > 1 && companyInfoState?.eppSolution?.allowDevicesWithRecurringCharges) ||
      ((price?.payments === 1 || !price?.payments) && companyInfoState?.eppSolution?.allowDevicesWithOneTimeCharges)
    );
  }
  if (!userHasEmptyOrSMEPriceGroup(user?.segmentPricingGroup)) {
    return subType !== CommercialProductSubType.EPP_DEVICE && (!price?.payments || price.payments === 1);
  }
  return subType !== CommercialProductSubType.EPP_DEVICE;
};

const accessoriesAllowPrice = (
  price: Price,
  isEppCustomer: boolean | undefined,
  user: (AuthenticatedUserState & ActionsHistory) | null | undefined
) =>
  isEppCustomer || !userHasEmptyOrSMEPriceGroup(user?.segmentPricingGroup)
    ? price?.payments === 1 || !price?.payments
    : true;

const filterDevicesByEPPSolution = (
  category: OnlineModelCategory,
  lowestPrices: PricesByType[],
  companyInfo?: CompanyInfoState | null,
  isEppCustomer?: boolean,
  user?: (AuthenticatedUserState & ActionsHistory) | null
) =>
  lowestPrices.map(priceObject => {
    return {
      ...priceObject,
      prices:
        category === OnlineModelCategory.ACCESSORIES
          ? priceObject.prices.filter(price => accessoriesAllowPrice(price, isEppCustomer, user))
          : priceObject.prices.filter(price =>
              eppSolutionSettingsAllowPrice(price, priceObject.productSubType, isEppCustomer, companyInfo, user)
            ),
    };
  });

/**
 * Replaces lowestPrice-objects price-contents in OnlineModelHeaders with CLP/CLD/Segment pricing discount if such exists for the model.
 * Also removes recurring charges from display if they're not allowed, thus displaying customer specific price in all cases.
 * @param headers online model headers
 * @param companyInfo company info containing discounts and recurring / onetime charge settings
 * @param user user object to check the segment pricing group
 */
export const setDiscountsForOnlineModelHeaders = (
  headers: OnlineModelHeader[],
  companyInfo?: CompanyInfoState | null | undefined,
  user?: (AuthenticatedUserState & ActionsHistory) | null
): OnlineModelHeader[] =>
  headers.map(header => {
    const defaultProductType = header.lowestPrices.length
      ? header.lowestPrices[0].productType
      : CommercialProductType.HANDHELD_DEVICE;
    const lowestPrices = setUndefinedToZeroByPayment(header.lowestPrices);
    const foundDiscount = companyInfo?.discountedPrices?.find(discount => discount.model === header.onlineModelCode);
    const updatedLowestPrices = foundDiscount
      ? insertDiscountsToLowestPrices(
          lowestPrices,
          groupBy(createPriceWithSubTypes(foundDiscount), price => price.productSubType),
          defaultProductType
        )
      : lowestPrices;
    const discountedPrices = filterDevicesByEPPSolution(
      header.category,
      updatedLowestPrices,
      companyInfo,
      companyInfo?.eppSolution?.eppSolutionStatus === EppSolutionResponse.EppSolutionStatusEnum.ACTIVE,
      user
    );
    return { ...header, lowestPrices: discountedPrices };
  });
