import { Grid } from '../../../../../components/Grid/Grid.js';
import { SubscriptionsDevice } from '../../../../../components/Subscriptions/SubscriptionsDevice.js';
import { SubscriptionsLayout } from '../../../../../components/SubscriptionsLayout/SubscriptionsLayout.js';
import { devicesMsg, omaElisaForCompaniesMsg, t } from '../../../../../common/i18n/index.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useState } from 'react';
import { useStore } from 'react-redux';
import type { CommonError } from '../../../../../common/types/errors.js';
import type { State } from '../../../../../selfservice/exports.js';

export const DevicesPath = () => {
  const config = useStore<State>().getState().config;
  const [errors] = useState<CommonError[]>([]);

  return (
    <SubscriptionsLayout
      breadCrumbs={[
        { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
        { name: t.JJTM(devicesMsg) },
      ]}
      errors={errors}
    >
      <Grid>
        <div className="ea-context-notification">
          <div className="ea-context-notification__text ">
            <strong>{t.DV41('Can’t find your device here?')}</strong>{' '}
            {t.F1YQ('Some of your devices can be managed in the')}&nbsp;
            <a href={`${config.classicSiteUrl}/muutos-ja-poisto/`}>{t.RR0O('OmaElisa Classic.')}</a>
          </div>
        </div>
      </Grid>
      <SubscriptionsDevice />
    </SubscriptionsLayout>
  );
};
