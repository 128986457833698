import * as CL from '@design-system/component-library';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { fieldCantBeEmptyMsg, t } from '../../i18n/index.js';
import { useController, useFormContext } from 'react-hook-form';
import type { ReactNode } from 'react';

export type SelectRadioItem = {
  children?: ReactNode;
  label?: string;
  value: string;
  disabled?: boolean;
};

export interface SelectRadioComponentProps {
  defaultValue?: string;
  disabled?: boolean;
  items: SelectRadioItem[];
  label?: string;
  name: string;
}

export const SelectRadio = ({ defaultValue, disabled, items, label, name }: SelectRadioComponentProps) => {
  const { control } = useFormContext();
  const { field, fieldState } = useController({
    name,
    control,
    defaultValue,
    rules: { required: { value: true, message: t.VPVR(fieldCantBeEmptyMsg) } },
  });

  return (
    <>
      <CL.RadioGroup
        className={fieldState.error ? dsClass.COLOR_RED_600 : ''}
        disabled={disabled}
        label={label}
        onChange={value => field.onChange(value)}
        value={field.value}
      >
        {items.map((item, index) => (
          <CL.RadioV2
            value={item.value}
            key={`${name}-${index}`}
            id={`${name}-${index}`}
            label={item.label}
            disabled={item.disabled}
          >
            {item.children}
          </CL.RadioV2>
        ))}
      </CL.RadioGroup>
      {fieldState.error && <CL.InputError>{fieldState.error?.message}</CL.InputError>}
    </>
  );
};
