/**
 * Online UI API
 * # Summary Elisa B2B Online UI API  # Usage This API is used in the `https://yrityksille.elisa.fi/` pages.  # Identifiers 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Authentication level of user session in SSO
 */
export type AuthenticationLevel = 'ANONYMOUS' | 'ONE_FACTOR' | 'TWO_FACTOR' | 'NOT_IMPLEMENTED';

export const AuthenticationLevel = {
    ANONYMOUS: 'ANONYMOUS' as AuthenticationLevel,
    ONE_FACTOR: 'ONE_FACTOR' as AuthenticationLevel,
    TWO_FACTOR: 'TWO_FACTOR' as AuthenticationLevel,
    NOT_IMPLEMENTED: 'NOT_IMPLEMENTED' as AuthenticationLevel
};

