import { ChangeRequestStatus, SubscriptionType } from '../generated/api/models.js';
import { ModelType, SfToggles } from './enums.js';
import {
  activateTurbonappiOrderPublicMethod,
  changeSubscriptionBillingAccountPrivateMethod,
  checkDuplicateContactsPrivateMethod,
  createAdminUserPrivateMethod,
  createAnonymousLeadPublicMethod,
  createAnonymousSupportCasePublicMethod,
  createBillingAccountPrivateMethod,
  createContactPrivateMethod,
  createDnsRecordPrivateMethod,
  createSupportCasePrivateMethod,
  deleteAdminUserPrivateMethod,
  deleteDnsRecordPrivateMethod,
  deprecateVirtualCatalogPrivateMethod,
  domainGetPublicMethod,
  elisaIdV2LogOutPrivateMethod,
  enableMfaPrivateMethod,
  generateNumbersPrivateMethod,
  generateReportPrivateMethod,
  getAccountKeyUsersByServiceAgreementIdPrivateMethod,
  getAddOnRulesPublicMethod,
  getAddOnVisibilityPrivateMethod,
  getAddOnVisibilityPublicMethod,
  getBillChannelsPublicMethod,
  getBillingAccountScheduledChangePrivateMethod,
  getBillingAccountsPrivateMethod,
  getChatHistoryPublicMethod,
  getContactsPrivateMethod,
  getCustomerOrderAdditionalInfoPrivateMethod,
  getCustomerOrdersPrivateMethod,
  getDnsRecordHistoryPrivateMethod,
  getDnsRecordsHistoryPrivateMethod,
  getDnsRecordsPrivateMethod,
  getDocumentsPrivateMethod,
  getExternalAuthenticationMethodsPublicMethod,
  getGeneratedReportPrivateMethod,
  getHolidaysPublicMethod,
  getInvoicesPrivateMethod,
  getLicenseManagementAccountsPrivateMethod,
  getMfaDetailsPrivateMethod,
  getMonthlyUsageDetailsPrivateMethod,
  getMyselfAccountPrivateMethod,
  getMyselfSecondaryAccountsPrivateMethod,
  getOnlineModelPublicMethod,
  getOnlineModelWithEffectivePricesPrivateMethod,
  getOnlineModelsPublicMethod,
  getOpenFormAccountPrivateMethod,
  getOpenFormAvailabilityPrivateMethod,
  getOpenFormListPrivateMethod,
  getOpenFormPrivateMethod,
  getOpenFormSummaryPrivateMethod,
  getOpenSupportCasesPrivateMethod,
  getPagePublicMethod,
  getReportsPrivateMethod,
  getSubscriptionActionsPrivateMethod,
  getSubscriptionAggregationsPrivateMethod,
  getSubscriptionsPrivateMethod,
  getSupportCaseHistoryPrivateMethod,
  getSupportCasePrivateMethod,
  getSupportCasesPrivateMethod,
  getTrainingPublicMethod,
  getVirtualCatalogPrivateMethod,
  getVirtualCatalogsPrivateMethod,
  globalSearchPrivateMethod,
  handleTurbonappiOrderPublicMethod,
  licenseManagementUrlPrivateMethod,
  postChatConversationPublicMethod,
  postCreateInterworksAccountPrivateMethod,
  postFilePrivateMethod,
  postOpenFormCustomerNeedPrivateMethod,
  postScheduledBillingAccountChangePrivateMethod,
  prepareElisaIdV2LoginPublicMethod,
  putScheduledBillingAccountChangePrivateMethod,
  raiseAuthenticationLevelPrivateMethod,
  raiseAuthenticationLevelValidateOtpPrivateMethod,
  replaceVirtualCatalogsPrivateMethod,
  searchCompaniesPublicMethod,
  updateAccountKeyUserPrivateMethod,
  updateBillingAccountPrivateMethod,
  updateContactPrivateMethod,
  updateDnsRecordPrivateMethod,
  updateDraftCatalogPrivateMethod,
  updateMyselfAccountPrivateMethod,
  validateAvailabilityOfNumbersPrivateMethod,
  validateMsisdnForTurbonappiPublicMethod,
  validatePublicMethod,
} from '../generated/api/uiApiMethods.js';
import { createRedirectURL } from './utils/urlUtils.js';
import { emptyAddOnRulesResponse } from './utils/addOnRulesUtils.js';
import {
  getActiveAccountMasterId,
  getAiChatSessionId,
  getIsConsolidatedViewEnabled,
  isSearchAllAccounts,
} from '../selfservice/common/localStorageUtils.js';
import { getSubscriptionTypes } from '../public/common/util/category';
import type {
  AccountKeyUsersResponse,
  AddOnRulesResponse,
  AddOnVisibilityResponse,
  AiChatResponse,
  AuthenticationMethod,
  BillingAccount,
  BillingAccountScheduledChangeResponse,
  BillingAccountScheduledChangeState,
  BillingAccountsResponse,
  Catalog,
  CompanyInfoResponse,
  Contact,
  ContactsDuplicateCheckRequest,
  ContactsDuplicateCheckResponse,
  ContactsResponse,
  CreateInterworksAccountRequest,
  CustomerOrderAdditionalInfo,
  CustomerOrdersResponse,
  DnsRecordRequest,
  DnsRecordsHistoryResponse,
  DnsRecordsResponse,
  ElisaIdV2LogoutResponse,
  FinalizeOnlineOrderCardPaymentRequest,
  HolidaysResponse,
  InitializeOnlineOrderCardPaymentResponse,
  InvoiceDocumentsResponse,
  InvoicesResponse,
  LicenseManagementAccount,
  MfaDetailsResponse,
  MonthlyUsageDetailsResponse,
  NewNumbersResponse,
  OnlineModel,
  OnlineModelsResponse,
  OpenFormAccountResponse,
  OpenFormAvailabilityResponse,
  OpenFormCustomerNeedRequest,
  OpenFormListResponse,
  OpenFormResponse,
  OpenFormSummaryResponse,
  PageResponse,
  PostAnonymousLeadRequest,
  PostFileResponse,
  PrepareElisaIdV2LoginResponse,
  PutContactResponse,
  ReportItemResponse,
  ReportType,
  Subscription,
  SubscriptionAction,
  SubscriptionActionsResponse,
  SubscriptionAggregationsResponse,
  SubscriptionsResponse,
  SupportCase,
  SupportCaseDetails,
  SupportCasesResponse,
  TrainingResponse,
  TurbonappiMsisdnActionResponse,
  TurbonappiMsisdnValidationRequest,
  TurbonappiOrder,
  TurbonappiOrderRequest,
  UpdateAccountKeyUserRequest,
  UpdateAccountRequest,
  ValidateAvailabilityOfNumbersRequest,
  ValidateAvailabilityOfNumbersResponse,
  VirtualCatalogReplaceRequest,
  VirtualCatalogReplaceResponse,
  VirtualCatalogResponse,
} from '../generated/api/models.js';
import type { AddOnVisibilityOperationType, SubscriptionCategory } from './enums.js';
import type { ApiMethod, SortableLimitableQuery } from '../generated/api/uiApiMethods.js';
import type { ContactSupportInfo } from '../components/ContactSupportForm/index.js';
import type { DnsRecordsRequestQuery } from '../components/DnsManagement/dnsManagementUtils.js';
import type { LoaderFunctionArgs } from 'react-router-dom';
import type { SubscriptionTypes } from '../components/OpenForm/OpenFormAnswers.js';
import type { SubscriptionsDeviceMultiFilter } from '../components/SubscriptionLists/SubscriptionTable.js';

export interface CustomerOrdersQuery extends SortableLimitableQuery {
  search?: string;
  status?: string;
  useSearchService?: boolean;
}

export interface SupportCasesQuery extends SortableLimitableQuery {
  search?: string;
  feature?: string;
  status?: string;
}

export interface VirtualCatalogsQuery extends SortableLimitableQuery {
  search?: string;
  searchAllAccounts?: boolean;
}

export interface BillingAccountsQuery extends SortableLimitableQuery {
  useSearchService?: boolean;
  search?: string;
  sourceSystem?: string;
  billingContactId?: string;
}

export interface SubscriptionActionsQuery extends SortableLimitableQuery {
  search?: string;
}

export interface InvoicesQuery extends SortableLimitableQuery {
  balanceRange?: string;
  search?: string;
  useSearchService?: boolean;
  category?: string;
  openInvoicesOnly?: boolean;
}

export interface InvoiceDocumentsQuery extends SortableLimitableQuery {
  useSearchService?: boolean;
  search?: string;
}

interface CallUiApiOpts {
  body?: BodyInit;
  headers?: HeadersInit;
  signal?: AbortSignal;
  mdmId?: string;
  useDefaultMdmId?: boolean;
  noThrow?: boolean;
  jsonContent?: boolean;
}

const callUiApi = async (apiCall: ApiMethod, opts?: CallUiApiOpts) => {
  const { body, signal, useDefaultMdmId = true, noThrow = false, jsonContent = true } = opts ?? {};
  const { path, verb: method } = apiCall;
  const mdmId = opts?.mdmId ?? (useDefaultMdmId ? getActiveAccountMasterId() : undefined);
  const headers = {
    ...opts?.headers,
    ...(jsonContent ? { 'Content-Type': 'application/json' } : {}), // eslint-disable-line @typescript-eslint/naming-convention
    ...(mdmId ? { 'X-API-Account-Master-ID': mdmId } : {}), // eslint-disable-line @typescript-eslint/naming-convention
    ...(mdmId ? { 'X-Elisa-Company-MDM-ID': mdmId } : {}), // eslint-disable-line @typescript-eslint/naming-convention
  };

  const init = { body, method, headers, signal };
  const res = await fetch(path, init);

  if (noThrow || res.ok) {
    return res;
  } else {
    throw res;
  }
};

/**
 * Create only functions in this file that call fetch and use functions from uiApiMethods
 * Do not call these functions inside Epics
 * Do not mix fetch usages with redux usages
 */

export const fetchCompanyList = async (query: string) => {
  return (await (await callUiApi(searchCompaniesPublicMethod({ query }))).json()).result;
};

export const fetchTrainings = async (query: string): Promise<TrainingResponse> => {
  return (await callUiApi(getTrainingPublicMethod(query))).json();
};

export const fetchPublicHolidays = async (opts?: CallUiApiOpts): Promise<HolidaysResponse> => {
  try {
    return (await callUiApi(getHolidaysPublicMethod(), opts)).json();
  } catch (err) {
    throw await err?.text();
  }
};

export const fetchOpenFormAccount = async (opts?: CallUiApiOpts): Promise<OpenFormAccountResponse> => {
  try {
    return (await callUiApi(getOpenFormAccountPrivateMethod(), opts)).json();
  } catch (err) {
    throw await err?.text();
  }
};

export const fetchOpenFormAvailability = async (
  query: { addressId: string; formId: string; subscriptionTypes: SubscriptionTypes },
  opts?: CallUiApiOpts
): Promise<OpenFormAvailabilityResponse> => {
  try {
    return (await callUiApi(getOpenFormAvailabilityPrivateMethod(query), opts)).json();
  } catch (err) {
    throw await err?.text();
  }
};

export const fetchOpenFormList = async (listId: string, opts?: CallUiApiOpts): Promise<OpenFormListResponse> => {
  try {
    return (await callUiApi(getOpenFormListPrivateMethod({ listId }), opts)).json();
  } catch (err) {
    throw await err?.text();
  }
};

export const fetchOpenFormSummary = async (
  choices: string[],
  opts?: CallUiApiOpts
): Promise<OpenFormSummaryResponse> => {
  try {
    return (await callUiApi(getOpenFormSummaryPrivateMethod({ choices }), opts)).json();
  } catch (err) {
    throw await err?.text();
  }
};

export const fetchOpenForm = async (formId: string, opts?: CallUiApiOpts): Promise<OpenFormResponse> => {
  try {
    return (await callUiApi(getOpenFormPrivateMethod({ formId }), opts)).json();
  } catch (err) {
    throw await err?.text();
  }
};

export const postOpenFormCustomerNeed = async (data: OpenFormCustomerNeedRequest): Promise<string> => {
  try {
    return (await callUiApi(postOpenFormCustomerNeedPrivateMethod(), { body: JSON.stringify(data) })).text();
  } catch (err) {
    throw await err?.text();
  }
};

export const putDraftCatalog = async (virtualCatalogCode: string, catalog: Catalog, mdmId?: string) => {
  return await (
    await callUiApi(updateDraftCatalogPrivateMethod(virtualCatalogCode, catalog.catalogCode!), {
      body: JSON.stringify(catalog),
      mdmId,
    })
  ).json();
};

export const fetchPublicPage = async (path: string): Promise<PageResponse> => {
  return (await callUiApi(getPagePublicMethod({ path }), { headers: { redirect: 'manual' } })).json();
};

export const fetchPublicPageLoader = async (args: LoaderFunctionArgs): Promise<PageResponse> => {
  const { origin, pathname } = new URL(args.request.url);
  // Decode scandic letters; otherwise, getPagePublicMethod double encodes it
  const apiCall = getPagePublicMethod({ path: decodeURIComponent(pathname) });
  apiCall.path = origin + apiCall.path;
  const res = await callUiApi(apiCall, { headers: { redirect: 'manual' }, useDefaultMdmId: false, noThrow: true });

  if (res.status === 204) {
    throw new Response(res.body, {
      status: 301,
      headers: new Headers({ location: createRedirectURL(args, res) }),
    });
  }
  if (res.ok) {
    return res.json();
  }
  const status = res.status === 503 ? 503 : 404;
  throw new Response(res.body, { status, statusText: res.statusText });
};

export const createAdminUser = (contact: Contact, mdmId?: string) => {
  return callUiApi(createAdminUserPrivateMethod(contact.contactId!), {
    body: JSON.stringify(contact),
    mdmId,
  });
};

export const upsertContactUser = (
  contact: Contact,
  forceUpsert: boolean,
  bothNamesChanged?: boolean,
  noThrowOnNewContact?: boolean,
  mdmId?: string
) => {
  if (contact.contactId) {
    const apiCall = updateContactPrivateMethod(contact.contactId, { forceUpsert, bothNamesChanged });
    return callUiApi(apiCall, { body: JSON.stringify(contact), mdmId });
  } else {
    return callUiApi(createContactPrivateMethod({ forceUpsert }), {
      body: JSON.stringify(contact),
      noThrow: noThrowOnNewContact,
      mdmId,
    });
  }
};

export const postContact = async (contact: Contact): Promise<PutContactResponse> => {
  return (
    await callUiApi(createContactPrivateMethod(), {
      body: JSON.stringify(contact),
    })
  ).json();
};

export const deleteAdminUser = (contact: Contact, mdmId?: string) => {
  return callUiApi(deleteAdminUserPrivateMethod(contact.contactId!), { body: JSON.stringify(contact), mdmId });
};

export const fetchOnlineModelHeaders = async (productsWithTags: string[]): Promise<OnlineModelsResponse> => {
  return (await callUiApi(getOnlineModelsPublicMethod({ headersOnly: true, tags: productsWithTags }))).json();
};

export const fetchCatalogOnlineModelHeaders = async (category: string): Promise<OnlineModelsResponse> => {
  return (await callUiApi(getOnlineModelsPublicMethod({ headersOnly: true, category: category }))).json();
};

export const fetchOnlineModelsForCategory = async (
  category: string,
  headersOnly: boolean
): Promise<OnlineModelsResponse> => {
  return (await callUiApi(getOnlineModelsPublicMethod({ category, headersOnly }))).json();
};

export const fetchOnlineModelForCode = async (modelCode: string): Promise<OnlineModel> => {
  return (await callUiApi(getOnlineModelPublicMethod(modelCode))).json();
};

export const fetchOnlineModelEffectivePriceForCode = async (
  modelCode: string,
  mdmId?: string
): Promise<OnlineModel> => {
  return (await callUiApi(getOnlineModelWithEffectivePricesPrivateMethod(modelCode), { mdmId })).json();
};

export const fetchOnlineModels = async (guids: string[]): Promise<OnlineModel[]> => {
  const responses = await Promise.all(
    guids.map(guid => callUiApi(getOnlineModelPublicMethod(guid), { noThrow: true }))
  );

  const result = await Promise.all(responses.map(r => (r.ok ? r.json() : undefined)));
  return result.filter(r => !!r);
};

export const fetchDomains = async (query: string, mdmId?: string) => {
  return (await callUiApi(domainGetPublicMethod({ query }), { mdmId })).json();
};

export const fetchDnsRecords = async (
  subId: string,
  query: DnsRecordsRequestQuery,
  mdmId?: string
): Promise<DnsRecordsResponse> => {
  return (await callUiApi(getDnsRecordsPrivateMethod(subId, query), { mdmId })).json();
};

export const fetchDnsRecordsHistory = async (
  subId: string,
  query: DnsRecordsRequestQuery,
  mdmId?: string
): Promise<DnsRecordsHistoryResponse> => {
  return (await callUiApi(getDnsRecordsHistoryPrivateMethod(subId, query), { mdmId })).json();
};

export const fetchDnsRecordHistory = async (subId: string, historyId: number): Promise<DnsRecordsHistoryResponse> => {
  return (await callUiApi(getDnsRecordHistoryPrivateMethod(historyId, subId))).json();
};

export const deleteDnsRecord = async (subscriptionId: string, recordId: number) => {
  return callUiApi(deleteDnsRecordPrivateMethod(recordId, subscriptionId));
};

export const updateDnsRecord = async (subscriptionId: string, recordId: number, payload: DnsRecordRequest) => {
  return callUiApi(updateDnsRecordPrivateMethod(recordId, subscriptionId), { body: JSON.stringify(payload) });
};

export const createDnsRecord = async (subscriptionId: string, payload: DnsRecordRequest) => {
  return callUiApi(createDnsRecordPrivateMethod(subscriptionId), { body: JSON.stringify(payload) });
};

export const postAnonymousLead = (body: PostAnonymousLeadRequest) => {
  return callUiApi(createAnonymousLeadPublicMethod(), {
    body: JSON.stringify(body),
    useDefaultMdmId: false,
    noThrow: true,
  });
};

export const fetchAddOnRules = async (subscriptionType?: string): Promise<AddOnRulesResponse> => {
  const apiCall = getAddOnRulesPublicMethod({ subscriptionType });
  const res = await callUiApi(apiCall, { useDefaultMdmId: false, noThrow: true });

  if (res.ok) {
    return res.json();
  }

  // If add-on rules cannot be fetched, the add-on cannot be sold but the main article is still potentially available for purchase. By returning an empty response, buying the main article is still possible.
  return { ...emptyAddOnRulesResponse };
};

export const fetchNewSalesAddOnsVisibilities = async (cpCode: string): Promise<AddOnVisibilityResponse> => {
  const apiCall = getAddOnVisibilityPublicMethod(cpCode, { operationType: 'NEW_SALES' });
  const res = await callUiApi(apiCall, { useDefaultMdmId: false, noThrow: true });

  if (res.ok) {
    return res.json();
  }

  // If add-on visibilities cannot be fetched, we cannot decide which add-ons are available for selection. The main article is still potentially available for purchase. By returning an empty response, buying the main article is still possible.
  return {
    total: 0,
    addOnVisibilities: [],
  };
};

export const fetchAddOnsVisibilities = async (
  cpCode: string,
  operationType: AddOnVisibilityOperationType,
  mdmId?: string
): Promise<AddOnVisibilityResponse> => {
  return (await callUiApi(getAddOnVisibilityPrivateMethod(cpCode, { operationType: operationType }), { mdmId })).json();
};

export const postTurbonappiValidation = async (
  payload: TurbonappiMsisdnValidationRequest
): Promise<TurbonappiMsisdnActionResponse> => {
  return (await callUiApi(validateMsisdnForTurbonappiPublicMethod(), { body: JSON.stringify(payload) })).json();
};

export const postTurbonappiPaymentInitialization = async (
  payload: TurbonappiOrderRequest
): Promise<InitializeOnlineOrderCardPaymentResponse> => {
  return (await callUiApi(handleTurbonappiOrderPublicMethod(), { body: JSON.stringify(payload) })).json();
};

export const postTurbonappiActivation = async (
  payload: FinalizeOnlineOrderCardPaymentRequest
): Promise<TurbonappiOrder> => {
  return (await callUiApi(activateTurbonappiOrderPublicMethod(), { body: JSON.stringify(payload) })).json();
};

export const postFile = async (file: File): Promise<PostFileResponse> => {
  const body = new FormData();
  body.append('fileData', file);
  return (await callUiApi(postFilePrivateMethod(), { body, jsonContent: false })).json();
};

export const fetchReports = async (): Promise<ReportItemResponse[]> => {
  return (await callUiApi(getReportsPrivateMethod())).json();
};

export const generateReport = (reportType: ReportType) => {
  return callUiApi(generateReportPrivateMethod(reportType), { noThrow: true });
};

export const downloadReport = (reportId: string) => {
  return callUiApi(getGeneratedReportPrivateMethod(reportId), { noThrow: true });
};

export const fetchBillingAccount = async (
  billingAccountDisplayId?: string,
  mdmId?: string
): Promise<BillingAccountsResponse> => {
  const apiCall = getBillingAccountsPrivateMethod({ offset: 0, billingAccountDisplayId });
  return (await callUiApi(apiCall, { useDefaultMdmId: !mdmId, mdmId })).json();
};

export const fetchBillingAccounts = async (
  query?: BillingAccountsQuery,
  opts?: CallUiApiOpts
): Promise<BillingAccountsResponse> => {
  const apiCall = getBillingAccountsPrivateMethod(query);
  return (
    await callUiApi(apiCall, {
      ...opts,
      useDefaultMdmId: getIsConsolidatedViewEnabled() ? false : !isSearchAllAccounts(),
    })
  ).json();
};

export const replaceCatalogs = async (
  payload: VirtualCatalogReplaceRequest
): Promise<VirtualCatalogReplaceResponse> => {
  return (await callUiApi(replaceVirtualCatalogsPrivateMethod(), { body: JSON.stringify(payload) })).json();
};

export const createBillingAccount = async (billingAccount: BillingAccount, mdmId?: string) => {
  return (await callUiApi(createBillingAccountPrivateMethod(), { body: JSON.stringify(billingAccount), mdmId })).json();
};

export const updateBillingAccount = async (billingAccountId: string, billingAccount: BillingAccount) => {
  return (
    await callUiApi(updateBillingAccountPrivateMethod(billingAccountId), { body: JSON.stringify(billingAccount) })
  ).json();
};

export const fetchBillChannels = async (opts?: CallUiApiOpts) => {
  return (await callUiApi(getBillChannelsPublicMethod(), opts)).json();
};

export interface GlobalSearchQuery {
  query: string;
  searchAllAccounts?: boolean;
  searchType?: string[];
}

export const fetchGlobalSearch = async ({ query, searchAllAccounts, searchType }: GlobalSearchQuery) => {
  const apiCall = globalSearchPrivateMethod({ query, searchType });
  return (await callUiApi(apiCall, { useDefaultMdmId: !searchAllAccounts })).json();
};

export const fetchSubscriptionAggregates = async (
  subscriptionCategory: SubscriptionCategory,
  { request }: LoaderFunctionArgs,
  mdmId?: string
): Promise<SubscriptionAggregationsResponse> => {
  const params = new URLSearchParams(new URL(request.url).search);
  const search = params.get('search') || undefined;
  const apiCall = getSubscriptionAggregationsPrivateMethod({ subscriptionCategory, search });
  return (
    await callUiApi(apiCall, {
      useDefaultMdmId: getIsConsolidatedViewEnabled() ? false : !isSearchAllAccounts(),
      mdmId,
    })
  ).json();
};

export type SubscriptionsQuery = SortableLimitableQuery &
  SubscriptionsDeviceMultiFilter & {
    details?: boolean;
    search?: string;
    subscriptionType?: string[];
    useSearchService?: boolean;
    subscriptionContactId?: string;
  };

export const fetchSubscription = async (
  category: SubscriptionCategory,
  subscriptionDisplayId?: string,
  mdmId?: string
): Promise<Subscription> => {
  if (!subscriptionDisplayId) {
    throw new Error('missing subscriptionDisplayId');
  }
  const subscriptionTypes = getSubscriptionTypes(category);
  const response = await callUiApi(
    getSubscriptionsPrivateMethod({
      offset: 0,
      details: true,
      subscriptionType: subscriptionTypes,
      subscriptionDisplayId,
    }),
    {
      useDefaultMdmId: !mdmId,
      mdmId,
    }
  );
  const subscriptionsResponse: SubscriptionsResponse = await response.json();
  const subscription = subscriptionsResponse?.subscriptions?.[0];
  if (!subscription) {
    throw new Response('Subscription not found', { status: 404 });
  }
  return subscription;
};

export const fetchSubscriptions = async (query: SubscriptionsQuery, mdmId?: string): Promise<SubscriptionsResponse> => {
  return (
    await callUiApi(getSubscriptionsPrivateMethod(query), {
      useDefaultMdmId: getIsConsolidatedViewEnabled() ? false : !isSearchAllAccounts(),
      mdmId,
    })
  ).json();
};

export type ContactFetchQuery = SortableLimitableQuery & {
  search?: string;
  useSearchService?: boolean;
};

export const fetchContacts = async (query: ContactFetchQuery, opts?: CallUiApiOpts): Promise<ContactsResponse> => {
  return (
    await callUiApi(getContactsPrivateMethod(query), {
      ...opts,
      useDefaultMdmId: getIsConsolidatedViewEnabled() ? false : !isSearchAllAccounts(),
    })
  ).json();
};

export const fetchExternalAuthMethods = async (): Promise<AuthenticationMethod[] | 'failed'> => {
  try {
    return (await (await callUiApi(getExternalAuthenticationMethodsPublicMethod())).json()).authenticationMethods;
  } catch {
    // This is a special case, we want to have graceful degraded functionality. The extAuthMethods come from 3rd party, if that system has issues we don't want it to impact online-ui more than it has to.
    return 'failed';
  }
};

export const fetchContact = async ({
  contactMasterId,
  mdmId,
}: {
  contactMasterId: string;
  mdmId?: string;
}): Promise<ContactsResponse> => {
  return (await callUiApi(getContactsPrivateMethod({ contactMasterId }), { mdmId })).json();
};

export const fetchContactFromES = async ({
  contactMasterId,
  mdmId,
}: {
  contactMasterId: string;
  mdmId?: string;
}): Promise<ContactsResponse> => {
  return (await callUiApi(getContactsPrivateMethod({ contactMasterId, useSearchService: true }), { mdmId })).json();
};

export const requestMfaOtp = async () => {
  return callUiApi(raiseAuthenticationLevelPrivateMethod());
};

export const sendMfaOtp = async (otp: string): Promise<boolean> => {
  const res = await callUiApi(raiseAuthenticationLevelValidateOtpPrivateMethod(otp), { noThrow: true });

  if (res.ok) {
    return true;
  } else if (res.status === 400) {
    return false;
  }

  throw new Error(res.statusText);
};

export const validateNumbers = async (
  payload: ValidateAvailabilityOfNumbersRequest
): Promise<ValidateAvailabilityOfNumbersResponse> => {
  return (await callUiApi(validateAvailabilityOfNumbersPrivateMethod(), { body: JSON.stringify(payload) })).json();
};

export type GenerateNumbersQuery = {
  count: number;
};

export const generateNumbers = async (query: GenerateNumbersQuery): Promise<NewNumbersResponse> => {
  return (await callUiApi(generateNumbersPrivateMethod(query))).json();
};

export const createSupportCaseAnonymous = async (
  supportCase: SupportCase,
  contactDetails: ContactSupportInfo,
  reCaptchaResponse?: string
) => {
  const body = JSON.stringify({ supportCase, contactDetails, reCaptchaResponse });
  return (await callUiApi(createAnonymousSupportCasePublicMethod(), { body: body })).json();
};

export const createSupportCaseAuthenticated = async (supportCase: SupportCase, mdmId: string) => {
  return (
    await callUiApi(createSupportCasePrivateMethod(), {
      body: JSON.stringify(supportCase),
      mdmId,
      useDefaultMdmId: false,
    })
  ).json();
};

export const fetchSupportCase = async (supportCaseDisplayId: string, mdmId?: string) => {
  const apiCall = getSupportCasePrivateMethod(supportCaseDisplayId);
  return (await callUiApi(apiCall, { useDefaultMdmId: !mdmId, mdmId })).json();
};

export const fetchSupportCases = async (query: SupportCasesQuery, mdmId?: string): Promise<SupportCasesResponse> => {
  return (
    await callUiApi(getSupportCasesPrivateMethod(query), {
      useDefaultMdmId: getIsConsolidatedViewEnabled() ? false : !isSearchAllAccounts(),
      mdmId,
    })
  ).json();
};

export const fetchSupportCaseHistory = async (
  supportCaseDisplayId: string,
  mdmId?: string
): Promise<SupportCaseDetails> => {
  const apiCall = getSupportCaseHistoryPrivateMethod(supportCaseDisplayId);
  return (await callUiApi(apiCall, { mdmId })).json();
};

export const fetchOpenSupportCases = async (
  query?: SupportCasesQuery,
  mdmId?: string
): Promise<SupportCasesResponse> => {
  return (
    await callUiApi(getOpenSupportCasesPrivateMethod(query), {
      useDefaultMdmId: getIsConsolidatedViewEnabled() ? false : !isSearchAllAccounts(),
      mdmId,
    })
  ).json();
};

export const fetchCustomerOrder = async (
  customerOrderDisplayId?: string,
  mdmId?: string
): Promise<CustomerOrdersResponse> => {
  return (
    await callUiApi(getCustomerOrdersPrivateMethod({ offset: 0, customerOrderDisplayId }), {
      useDefaultMdmId: !mdmId,
      mdmId,
    })
  ).json();
};

export const fetchCustomerOrders = async (
  query: CustomerOrdersQuery,
  mdmId?: string
): Promise<CustomerOrdersResponse> => {
  return (
    await callUiApi(getCustomerOrdersPrivateMethod(query), {
      useDefaultMdmId: getIsConsolidatedViewEnabled() ? false : !isSearchAllAccounts(),
      mdmId,
    })
  ).json();
};

export const fetchCustomerOrderAdditionalInfo = async (
  customerOrderId: string,
  mdmId?: string
): Promise<CustomerOrderAdditionalInfo> => {
  return (
    await callUiApi(getCustomerOrderAdditionalInfoPrivateMethod(customerOrderId), {
      useDefaultMdmId: getIsConsolidatedViewEnabled() ? false : !isSearchAllAccounts(),
      mdmId,
    })
  ).json();
};

export const fetchBillingAccountScheduledChange = async (
  billingAccountId: string
): Promise<BillingAccountScheduledChangeResponse> => {
  return (await callUiApi(getBillingAccountScheduledChangePrivateMethod(billingAccountId))).json();
};

export const scheduleBillingAccountChange = async (
  billingAccountId: string,
  scheduledChangeTimestamp: number,
  billingAccount: BillingAccount
): Promise<BillingAccountScheduledChangeState> => {
  const apiCall = postScheduledBillingAccountChangePrivateMethod(billingAccountId);
  return (await callUiApi(apiCall, { body: JSON.stringify({ billingAccount, scheduledChangeTimestamp }) })).json();
};

export const reScheduleBillingAccountChange = async (
  billingAccountId: string,
  scheduledChangState: BillingAccountScheduledChangeState
): Promise<BillingAccountScheduledChangeState> => {
  const body: BillingAccountScheduledChangeState = {
    billingAccountId,
    changeRequestId: scheduledChangState.changeRequestId,
    scheduledChangeTimestamp: scheduledChangState.scheduledChangeTimestamp,
  };
  return (
    await callUiApi(putScheduledBillingAccountChangePrivateMethod(billingAccountId), { body: JSON.stringify(body) })
  ).json();
};

export const cancelChangeRequest = async (
  billingAccountId: string,
  changeRequestId: string
): Promise<BillingAccountScheduledChangeState> => {
  const body: BillingAccountScheduledChangeState = {
    billingAccountId,
    changeRequestId,
    changeRequestStatus: ChangeRequestStatus.CANCELLED,
  };
  return (
    await callUiApi(putScheduledBillingAccountChangePrivateMethod(billingAccountId), { body: JSON.stringify(body) })
  ).json();
};

export const fetchSubscriptionAction = async (
  subscriptionActionDisplayId?: string,
  mdmId?: string
): Promise<SubscriptionActionsResponse> => {
  const apiCall = getSubscriptionActionsPrivateMethod({ subscriptionActionDisplayId });
  return (await callUiApi(apiCall, { useDefaultMdmId: !mdmId, mdmId })).json();
};

export const fetchSubscriptionActions = async (
  query: SubscriptionActionsQuery,
  mdmId?: string
): Promise<SubscriptionActionsResponse> => {
  const apiCall = getSubscriptionActionsPrivateMethod(query);
  return (
    await callUiApi(apiCall, {
      useDefaultMdmId: getIsConsolidatedViewEnabled() ? false : !isSearchAllAccounts(),
      mdmId,
    })
  ).json();
};

export const fetchVirtualCatalog = async (catalogCode: string, mdmId: string) => {
  const apiCall = getVirtualCatalogPrivateMethod(catalogCode);
  return (await callUiApi(apiCall, { mdmId })).json();
};

export const fetchVirtualCatalogs = async (
  query: VirtualCatalogsQuery,
  mdmId?: string
): Promise<VirtualCatalogResponse> => {
  const apiCall = getVirtualCatalogsPrivateMethod(query);
  return (
    await callUiApi(apiCall, {
      useDefaultMdmId: getIsConsolidatedViewEnabled() || query.searchAllAccounts ? false : !isSearchAllAccounts(),
      mdmId,
    })
  ).json();
};

export const deprecateVirtualCatalog = async (virtualCatalogCode: string, mdmId?: string) => {
  return callUiApi(deprecateVirtualCatalogPrivateMethod(virtualCatalogCode), {
    mdmId,
    useDefaultMdmId: false,
  });
};

export const fetchCompanyInfo = async (mdmId?: string): Promise<CompanyInfoResponse> => {
  return (await callUiApi(getMyselfAccountPrivateMethod(), { mdmId, useDefaultMdmId: !mdmId })).json();
};

export const fetchSecondaryAccounts = async (mdmId?: string) => {
  return (await callUiApi(getMyselfSecondaryAccountsPrivateMethod(), { mdmId, useDefaultMdmId: !mdmId })).json();
};

export const fetchCompanyInfoAndEnabledOnlineModels = async (
  mdmId?: string
): Promise<{ companyInfo: CompanyInfoResponse; onlineModels: OnlineModel[] }> => {
  const accountRes = await callUiApi(getMyselfAccountPrivateMethod(), {
    mdmId,
    useDefaultMdmId: !mdmId,
  });
  const companyInfo = await accountRes.json();

  const uiOptions = companyInfo?.uiOptions as { toggles?: string[] };
  const onlineModels = await Promise.all(
    uiOptions?.toggles
      ?.map(toggle => {
        if (toggle === SfToggles.SHOW_YRITYSPUHE) {
          return ModelType.VoiceYrityspuhe;
        } else if (toggle === SfToggles.SHOW_PERUSLIITTYMA_4G) {
          return ModelType.VoicePerusliittyma4g;
        } else if (toggle === SfToggles.SHOW_YRITYSPAKETTI) {
          return ModelType.Yrityspaketti;
        } else if (toggle === SfToggles.SHOW_YRITYSLIITTYMA_OLD) {
          return ModelType.Voice;
        }
        return undefined;
      })
      .filter(Boolean)
      .map(async code => {
        const model = (await callUiApi(getOnlineModelPublicMethod(code!))).json();
        return model;
      }) || []
  );

  if (
    companyInfo?.pbxSolutions?.filter(
      (solution: Subscription) => solution.subscriptionType === SubscriptionType.MOBILE_PBX
    ).length > 0
  ) {
    const response = await callUiApi(validatePublicMethod(), { mdmId });
    const loginResponse = await response.json();
    const ringModel = loginResponse.result.customerLevelPriceOrDiscount
      ? await callUiApi(getOnlineModelWithEffectivePricesPrivateMethod(ModelType.Ring), { mdmId })
      : await callUiApi(getOnlineModelPublicMethod(ModelType.Ring));
    onlineModels.push(await ringModel.json());
  }

  return {
    companyInfo,
    onlineModels,
  };
};

export const updateCompanyInfo = async (updateRequest: UpdateAccountRequest, mdmId: string) => {
  return await callUiApi(updateMyselfAccountPrivateMethod(), { body: JSON.stringify(updateRequest), mdmId });
};

export const getMessagesFromChatHistory = async (sessionId: string) => {
  return await callUiApi(getChatHistoryPublicMethod({ sessionId }));
};

export const postMessageToChatConversation = async (message: string): Promise<AiChatResponse> => {
  return (
    await callUiApi(postChatConversationPublicMethod(), {
      body: JSON.stringify({ message, sessionId: getAiChatSessionId() || '' }),
    })
  ).json();
};

export const createInterworksRedirectUrl = async (iwAccountId: number, accountMasterId: string) => {
  return (
    await callUiApi(licenseManagementUrlPrivateMethod({ accountId: iwAccountId }), { mdmId: accountMasterId })
  ).json();
};

export const createInterworksAccount = async (
  request: CreateInterworksAccountRequest,
  accountMasterId: string
): Promise<number> => {
  return (
    await callUiApi(postCreateInterworksAccountPrivateMethod(), {
      body: JSON.stringify(request),
      mdmId: accountMasterId,
    })
  ).json();
};

export const fetchLicenseManagementAccounts = async (accountMasterId: string): Promise<LicenseManagementAccount[]> => {
  return (
    await callUiApi(getLicenseManagementAccountsPrivateMethod(), {
      mdmId: accountMasterId,
    })
  ).json();
};

export const fetchInvoice = async (invoiceDisplayId?: string, mdmId?: string): Promise<InvoicesResponse> => {
  return (
    await callUiApi(getInvoicesPrivateMethod({ offset: 0, invoiceDisplayId }), {
      useDefaultMdmId: !mdmId,
      mdmId,
    })
  ).json();
};

export const fetchInvoices = async (query?: InvoicesQuery, mdmId?: string): Promise<InvoicesResponse> => {
  return (
    await callUiApi(getInvoicesPrivateMethod(query), {
      useDefaultMdmId: getIsConsolidatedViewEnabled() ? false : !isSearchAllAccounts(),
      mdmId,
    })
  ).json();
};

export const fetchInvoiceDocuments = async (
  query?: InvoiceDocumentsQuery,
  mdmId?: string
): Promise<InvoiceDocumentsResponse> => {
  return (
    await callUiApi(getDocumentsPrivateMethod(query), {
      useDefaultMdmId: getIsConsolidatedViewEnabled() ? false : !isSearchAllAccounts(),
      mdmId,
    })
  ).json();
};

export const changeSubscriptionBillingAccount = async (
  subscriptionId: string,
  billingAccountId: string,
  mdmId?: string
) => {
  const payload = { billingAccountId };
  return (
    await callUiApi(changeSubscriptionBillingAccountPrivateMethod(subscriptionId), {
      body: JSON.stringify(payload),
      mdmId,
      useDefaultMdmId: !mdmId,
    })
  ).json();
};

// TODO: Replace the endpoint called, after the new SFDC API is ready: https://atlas.elisa.fi/jira/browse/OFI-54345
export const fetchPendingSubscriptionActions = async (mdmId?: string): Promise<Array<SubscriptionAction>> => {
  const response = await callUiApi(validatePublicMethod(), {
    useDefaultMdmId: !mdmId,
    mdmId,
  });
  const loginResponse = await response.json();
  return loginResponse?.result?.pendingSubscriptionActions || [];
};

export const fetchDuplicateContactCheck = async (
  contactDuplicateCheck: ContactsDuplicateCheckRequest
): Promise<ContactsDuplicateCheckResponse> => {
  return (
    await callUiApi(checkDuplicateContactsPrivateMethod(), { body: JSON.stringify(contactDuplicateCheck) })
  ).json();
};

export const fetchHolidays = async (): Promise<HolidaysResponse> => {
  return (await callUiApi(getHolidaysPublicMethod())).json();
};

export const fetchSubscriptionMonthlyUsageDetails = async (
  month: string,
  subscriptionId: string
): Promise<MonthlyUsageDetailsResponse> => {
  return (await callUiApi(getMonthlyUsageDetailsPrivateMethod(month, subscriptionId))).json();
};

export const fetchElisaIdV2LoginMetadata = async (): Promise<PrepareElisaIdV2LoginResponse> => {
  return (await callUiApi(prepareElisaIdV2LoginPublicMethod())).json();
};

export const fetchElisaIdV2LogoutMetadata = async (): Promise<ElisaIdV2LogoutResponse> => {
  return (await callUiApi(elisaIdV2LogOutPrivateMethod())).json();
};

export const fetchMfaDetails = async (): Promise<MfaDetailsResponse> => {
  return (await callUiApi(getMfaDetailsPrivateMethod())).json();
};

export const fetchSaUsers = async (saId: string): Promise<AccountKeyUsersResponse> => {
  return (await callUiApi(getAccountKeyUsersByServiceAgreementIdPrivateMethod(saId))).json();
};

export const updateSaUser = async (request: UpdateAccountKeyUserRequest) => {
  return await callUiApi(updateAccountKeyUserPrivateMethod(), { body: JSON.stringify(request) });
};

export const enableMfa = async (saId: string) => {
  return await callUiApi(enableMfaPrivateMethod(saId));
};
