import { FINNISH_PHONE_NUMBER_REGEX } from '../../common/utils/phoneNumberUtils.js';
import { NumberOwnerRHF } from '../NumberOwnerRHF/NumberOwnerRHF.js';
import { PhoneNumberInput } from '../PhoneNumberInput/PhoneNumberInput.js';
import { PhoneNumberOwnerType, PhoneNumberType } from '../../common/enums.js';
import { getFirstError } from '../../common/utils/errorUtils.js';
import { invalidPhoneNumberMsg, t, transferCurrentPhoneNumberMsg } from '../../common/i18n/index.js';
import { useEffect, useState } from 'react';
import type { AliasSupplier } from '../Checkout/steps/CartProductsStep.js';
import type { CommonError } from '../../common/types/errors.js';

export const isInvalidExistingPhoneNumber = (existingPhoneNumber: string): boolean => {
  return !new RegExp(FINNISH_PHONE_NUMBER_REGEX).test(existingPhoneNumber);
};

export interface RetainExistingPhoneNumberProps {
  errors?: CommonError[];
  cartItemInstanceId: string;
  indexNumber: number;
  existingNumberKey: string;
  companyName: string;
  existingNumberPrepareSaveValuesFn: (prepareSaveValues: AliasSupplier) => void;
  existingNumberSetRefCallback: (key: string, ref: HTMLElement | null) => void;
}

export const RetainExistingPhoneNumber = ({
  errors,
  cartItemInstanceId,
  indexNumber,
  existingNumberKey,
  companyName,
  existingNumberPrepareSaveValuesFn,
  existingNumberSetRefCallback,
}: RetainExistingPhoneNumberProps) => {
  const [touched, setTouched] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);
  const [existingNumber, setExistingNumber] = useState('');

  const onFocus = () => {
    setHasFocus(true);
    setTouched(true);
  };

  const onBlur = () => {
    setHasFocus(false);
  };

  const updateExistingNumberPrepareSaveValues = (existingPhoneNumber: string) => {
    existingNumberPrepareSaveValuesFn(() => {
      setTouched(false);
      return {
        obj: {
          type: PhoneNumberType.EXISTING,
          existingPhoneNumber,
          newPhoneNumber: '',
        },
      };
    });
  };

  useEffect(() => {
    updateExistingNumberPrepareSaveValues(existingNumber);
  }, []); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps

  const getError = () => {
    if (hasFocus) {
      return undefined;
    }

    if (existingNumber !== '' && isInvalidExistingPhoneNumber(existingNumber)) {
      return t.YLCX(invalidPhoneNumberMsg);
    }

    if (touched) {
      return undefined;
    }

    return getFirstError(cartItemInstanceId, existingNumberKey, errors)?.message;
  };

  const onChange = (existingPhoneNumber: string) => {
    if (existingPhoneNumber !== undefined) {
      setExistingNumber(existingPhoneNumber);
    }
    updateExistingNumberPrepareSaveValues(existingPhoneNumber);
  };

  return (
    <div
      id={`retain-existing-phone-number-${indexNumber}`}
      className={`of-retain-existing-phone-number of-retain-existing-phone-number-${indexNumber}`}
    >
      <PhoneNumberInput
        error={getError()}
        onChange={newValue => onChange(newValue)}
        onBlur={onBlur}
        onFocus={onFocus}
        value={existingNumber}
        setRefCallback={ref => existingNumberSetRefCallback(existingNumberKey, ref)}
        label={t.VPJ3(transferCurrentPhoneNumberMsg)}
      />
      <NumberOwnerRHF
        {...{
          errors,
          cartItemInstanceId: cartItemInstanceId,
          phoneNumberOwner: {
            ownerType: PhoneNumberOwnerType.PERSON,
          },
          companyName: companyName,
          headerLevel: 'h5',
        }}
      />
    </div>
  );
};
