import { DataLoader } from '../../public/site/siteUtils';
import { IconAndText } from '../../components/IconAndText/IconAndText.js';
import { Link, useNavigate } from 'react-router-dom';
import { SelectCompanyDialog } from './SelectCompanyDialog.js';
import { SelectOneDialog } from '../../components/SelectOneDialog/SelectOneDialog.js';
import {
  fixedBroadbandMsg,
  mobileBroadbandMsg,
  mobileM2MMsg,
  orderNewSimCardMsg,
  phoneSubscriptionMsg,
  simCardOnlyMsg,
  t,
} from '../../common/i18n/index.js';
import { isFeatureEnabled } from '../../common/utils/featureFlagUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { setActiveAccountMasterId } from '../../selfservice/common/localStorageUtils.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import type { State } from '../../selfservice/exports.js';

export interface SelectNewSubscriptionDialogProps {
  onCloseDialog: () => void;
  hideVoice?: boolean;
  hideBroadband?: boolean;
  hideBlankSim?: boolean;
  showBlankSimLink?: boolean;
  mdmId?: string;
}

const getTabletRowLength = (itemCount: number): 1 | 2 => {
  if (itemCount >= 2) {
    return 2;
  }
  return 1;
};

const getLaptopRowLength = (itemCount: number): 1 | 2 | 3 | 4 => {
  if (itemCount > 3) {
    return 4;
  }
  if (itemCount === 3) {
    return 3;
  }
  if (itemCount === 2) {
    return 2;
  }
  return 1;
};

export const SelectNewSubscriptionDialog = ({
  onCloseDialog,
  hideVoice,
  hideBroadband,
  hideBlankSim,
  showBlankSimLink,
  mdmId,
}: SelectNewSubscriptionDialogProps) => {
  const navigate = useNavigate();
  const config = useSelector((state: State) => state.config);
  const { authenticatedUser } = useAuth();
  const [selectCompanyDialogUrl, setSelectCompanyDialogUrl] = useState<string>();
  const isConsolidatedViewsEnabled = isFeatureEnabled(config.featureFlags.consolidatedViews);

  const blankSim = [
    {
      element: (
        <IconAndText icon="order-sim" text={t.MXPB(simCardOnlyMsg)} id="select-new-subscription-dialog-blank-sim" />
      ),
      onClick: () =>
        isConsolidatedViewsEnabled
          ? setSelectCompanyDialogUrl(paths.ORDER_NEW_SIM_CARD)
          : navigate(paths.ORDER_NEW_SIM_CARD),
    },
  ];

  const broadband = [
    {
      element: (
        <IconAndText icon="fixedbb" text={t.QXQ7(fixedBroadbandMsg)} id="select-new-subscription-dialog-fixedbb" />
      ),
      onClick: () =>
        isConsolidatedViewsEnabled
          ? setSelectCompanyDialogUrl(paths.ANONYMOUS_FIXED_BROADBAND_ORDER)
          : navigate(paths.ANONYMOUS_FIXED_BROADBAND_ORDER),
    },
    {
      element: (
        <IconAndText icon="mobilebb" text={t.EQKJ(mobileBroadbandMsg)} id="select-new-subscription-dialog-mobilebb" />
      ),
      link: authenticatedUser?.selfServiceInOec ? `${config.classicSiteUrl}/tilaa-liittyma-ja-laajakaista` : undefined,
      onClick: !authenticatedUser?.selfServiceInOec ? () => navigate(paths.PS_BROADBAND_SUBSCRIPTION_NEW) : undefined,
    },
    {
      element: (
        <IconAndText icon="mobile-m2m" text={t.A7CA(mobileM2MMsg)} id="select-new-subscription-dialog-mobile-m2m" />
      ),
      link: authenticatedUser?.selfServiceInOec ? `${config.classicSiteUrl}/elisa-laitenetti-ya` : undefined,
      onClick: !authenticatedUser?.selfServiceInOec
        ? () => navigate(paths.PS_BROADBAND_SUBSCRIPTION_NEW_M2M)
        : undefined,
    },
  ];

  const voice = [
    {
      element: <IconAndText icon="sim" text={t.R6AR(phoneSubscriptionMsg)} id="select-new-subscription-dialog-voice" />,
      link: authenticatedUser?.selfServiceInOec ? `${config.classicSiteUrl}/tilaa-liittyma-ja-laajakaista` : undefined,
      onClick: !authenticatedUser?.selfServiceInOec
        ? () => navigate(`${paths.PS_MOBILE_SUBSCRIPTION_NEW}${mdmId ? '?mdmId=' + mdmId : ''}`)
        : undefined,
    },
  ];

  const options = [...(hideVoice ? [] : voice), ...(hideBroadband ? [] : broadband), ...(hideBlankSim ? [] : blankSim)];

  return (
    <>
      {selectCompanyDialogUrl ? (
        <SelectCompanyDialog
          onCloseDialog={onCloseDialog}
          onContinue={selectedMdmId => {
            if (selectCompanyDialogUrl === paths.ORDER_NEW_SIM_CARD) {
              setActiveAccountMasterId(selectedMdmId);
            }
            navigate(`${selectCompanyDialogUrl}?mdmId=${selectedMdmId}`);
          }}
        />
      ) : (
        <SelectOneDialog
          header={t.XCEN('Select subscription type')}
          maxItemsAsideTablet={getTabletRowLength(options.length)}
          maxItemsAsideLaptop={getLaptopRowLength(options.length)}
          onCloseDialog={onCloseDialog}
          options={options}
        >
          <DataLoader>
            <>
              {showBlankSimLink && (
                <p>
                  <Link to={paths.ORDER_NEW_SIM_CARD}>{t.QYXA(orderNewSimCardMsg)}</Link>
                </p>
              )}
            </>
          </DataLoader>
        </SelectOneDialog>
      )}
    </>
  );
};
