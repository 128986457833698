import { OnlineModelCategory } from '../../generated/api/models.js';
import { PRODUCT_DETAILS_WIDTH_ALTERNATIVES } from '../../components/ProductDetails/partials/ProductDetailsPicture.js';
import { atContext, atType } from '../../common/constants/namingConventionVariables.js';
import { createOntology } from '../../components/ProductDetails/utils/formatters.js';
import { getImageUrl } from '../../components/Picture/contentfulImageProxy.js';
import { getMidpoint } from '../../components/Picture/Picture.js';
import {
  getMonthlyRecurringChargeFromCommercialProducts,
  getOnetimePriceFromCommercialProducts,
  priceToString,
} from '../../common/utils/priceUtils.js';
import type { CommercialProduct, Offer, OnlineModel } from '../../generated/api/models.js';
import type {
  ItemAvailability,
  OfferItemCondition,
  Product,
  Offer as SchemaOffer,
  UnitPriceSpecification,
  WithContext,
} from 'schema-dts';

const resolvePriceAsString = (commercialProducts: CommercialProduct[]): string | undefined => {
  // Elisa offers most products with a monthly recurring charge. Structured data for product
  // https://developers.google.com/search/docs/advanced/structured-data/product
  // and https://schema.org/Product however only support a single fixed price.
  // First try to resolve that, if it fails fall back to getting the monthly recurring charge
  let price = getOnetimePriceFromCommercialProducts(commercialProducts);
  if (price == null) {
    price = getMonthlyRecurringChargeFromCommercialProducts(commercialProducts);
  }
  return priceToString(price);
};

const RESIZE_PARAMS = { width: getMidpoint(PRODUCT_DETAILS_WIDTH_ALTERNATIVES) };

const getOffers = (category: string | undefined, offers: Array<Offer>, url: string | undefined): Array<SchemaOffer> => {
  return offers
    .map(({ commercialProducts, offerCode, stockAvailabilityRange, properties = [] }) => {
      const gtin = properties.find(p => p.name === 'EAN')?.value;
      const mpn = properties.find(p => p.name === 'Manufacturer Product Id')?.value;

      let priceSpecification: UnitPriceSpecification | undefined;
      let itemCondition: OfferItemCondition | undefined;
      let availability: ItemAvailability | undefined;

      if (category === 'SALES_PRODUCT') {
        priceSpecification = {
          [atType]: 'UnitPriceSpecification',
          priceCurrency: 'EUR',
          name: 'Hinta €/k',
          referenceQuantity: {
            [atType]: 'QuantitativeValue',
            value: '1',
            unitCode: 'MON',
          },
        };
      } else {
        itemCondition = 'https://schema.org/NewCondition';
        availability =
          stockAvailabilityRange?.upper === 0 ? 'https://schema.org/OutOfStock' : 'https://schema.org/InStock';
      }

      return {
        [atType]: 'Offer',
        ...(availability && { availability: availability }),
        ...(itemCondition && { itemCondition: itemCondition }),
        price: resolvePriceAsString(commercialProducts),
        priceCurrency: 'EUR',
        ...(priceSpecification && { priceSpecification: priceSpecification }),
        shippingDetails: {
          [atType]: 'OfferShippingDetails',
          shippingDestination: [
            {
              [atType]: 'DefinedRegion',
              addressCountry: 'FI',
            },
          ],
          shippingRate: {
            [atType]: 'MonetaryAmount',
            currency: 'EUR',
            value: 0, // We assume all shipping is free of charge.
          },
        },
        sku: offerCode,
        url,
        ...(gtin && { gtin: gtin }),
        ...(mpn && { mpn: mpn }),
      };
    })
    .filter(offer => offer.price) as Array<SchemaOffer>;
};

/**
 * Decision drivers for implementation
 * URL should be absolute representing the same image that user sees in screen.
 * Google is not strongly for or against of query parameters. Both should be fine.
 * Price always present onetime price. Offer doesn't take partial prices to account.
 * itemCondition is always set as NewCondition.
 * AggregateOffers not implemented. It's subject to interpretation.
 * Docs: https://developers.google.com/search/docs/advanced/structured-data/product
 */
export const createProductStructuredDataFromOnlineModel = (
  {
    category,
    description,
    listingImage,
    manufacturer,
    offers,
    onlineModelCode,
    onlineModelName,
    pagePath,
  }: OnlineModel,
  siteBaseUrl: string,
  offerCode?: string,
  productName?: string
): WithContext<Product> | undefined => {
  if (!offers.length) {
    return undefined;
  }
  const usedOffers = offerCode ? offers.filter(offer => offer.offerCode === offerCode) : offers;
  // OnlineModel pagePath is the gold standard for the product path, as this
  // JSON can be embedded in any page (i.e. we can't trust that we are in the
  // product page proper).
  const url = pagePath ? `${siteBaseUrl}${pagePath}` : undefined;
  const structuredOffers = getOffers(category, usedOffers, url);
  if (!structuredOffers.length) {
    return undefined;
  }

  const ontology = createOntology(category) || undefined;
  const offerImages = usedOffers.flatMap(offer => offer.images || []);
  // Make sure listingImage is first in image array (and also that it's not listed twice)
  const firstImage = listingImage || offerImages[0];
  const allImages = firstImage ? [firstImage, ...offerImages.filter(img => img !== firstImage)] : [];

  return {
    [atContext]: 'https://schema.org',
    [atType]: 'Product',
    additionalType: ontology,
    // Sales products are assumed to be Elisa products.
    brand: { [atType]: 'Brand', name: category === OnlineModelCategory.SALES_PRODUCT ? 'Elisa' : manufacturer },
    description,
    image: allImages.map(img => getImageUrl(img, RESIZE_PARAMS)),
    name: productName || (offerCode ? offers[0]?.offerName || onlineModelName : onlineModelName),
    offers: structuredOffers,
    sku: offerCode || onlineModelCode,
  };
};
