import { TypeKeys, generateActionId } from './actionsUtils.js';
import { convertToCommonErrors } from '../../common/utils/errorUtils.js';
import type { Action } from 'redux';
import type {
  AuthenticationResult,
  CompanyInfoResponse,
  ConflictedAuthenticationResult,
  ContactPerson,
  GenericError,
  MfaInfo,
  OnlineOrderReview,
  PostReviewRequest,
  StrongAuthRequest,
  SubscriptionAction,
  SubscriptionRedeemReview,
  SubscriptionsResponse,
  UserResponse,
} from '../../generated/api/models.js';
import type { ChangePasswordFormValues } from '../../components/ChangePasswordForm/ChangePasswordForm.js';
import type { CommonError } from '../../common/types/errors.js';
import type { CrudAction, ErrorAction } from './actionsUtils.js';
import type { ElisaIdClientAttrs } from '../../common/utils/elisaIdUtils.js';
import type { SubscriptionCategory } from '../../common/enums.js';

export interface LogInAction extends CrudAction<TypeKeys.LOG_IN> {
  password: string;
  userName: string;
}

export interface LogInFulfilledAction {
  authenticationResult: AuthenticationResult;
  type: TypeKeys.LOG_IN_FULFILLED;
}

export interface LogInFailedAction extends ErrorAction<TypeKeys.LOG_IN_FAILED> {
  response?: ConflictedAuthenticationResult;
}

export const logIn = (userName: string, password: string): LogInAction => ({
  id: generateActionId(),
  password,
  type: TypeKeys.LOG_IN,
  userName,
});

export const logInFulfilled = (authenticationResult: AuthenticationResult): LogInFulfilledAction => ({
  authenticationResult,
  type: TypeKeys.LOG_IN_FULFILLED,
});

export const logInFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string },
  response?: ConflictedAuthenticationResult
): LogInFailedAction => {
  return {
    errors: convertToCommonErrors(message, status, errors),
    params,
    response,
    type: TypeKeys.LOG_IN_FAILED,
  };
};

export interface MfaRequiredAction {
  mfaInfo: MfaInfo;
  type: TypeKeys.MFA_REQUIRED;
}

export const mfaRequired = (mfaInfo: MfaInfo): MfaRequiredAction => ({
  mfaInfo,
  type: TypeKeys.MFA_REQUIRED,
});

export interface SelectPrimaryAccountAndLogInAction extends CrudAction<TypeKeys.SELECT_PRIMARY_ACCOUNT_AND_LOG_IN> {
  userName: string;
  password: string;
  primaryAccountMasterId: string;
}

export interface SelectPrimaryAccountAndLogInFulfilledAction {
  authenticationResult: AuthenticationResult;
  type: TypeKeys.SELECT_PRIMARY_ACCOUNT_AND_LOG_IN_FULFILLED;
}

export interface SelectPrimaryAccountAndLogInFailedAction
  extends ErrorAction<TypeKeys.SELECT_PRIMARY_ACCOUNT_AND_LOG_IN_FAILED> {
  response?: ConflictedAuthenticationResult;
}

export const selectPrimaryAccountAndLogIn = (
  userName: string,
  password: string,
  primaryAccountMasterId: string
): SelectPrimaryAccountAndLogInAction => ({
  id: generateActionId(),
  password,
  primaryAccountMasterId,
  type: TypeKeys.SELECT_PRIMARY_ACCOUNT_AND_LOG_IN,
  userName,
});

export const selectPrimaryAccountAndLogInFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string },
  response?: ConflictedAuthenticationResult
): SelectPrimaryAccountAndLogInFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  response,
  type: TypeKeys.SELECT_PRIMARY_ACCOUNT_AND_LOG_IN_FAILED,
});

export interface ResolveMissingPrimaryAccountAction extends CrudAction<TypeKeys.RESOLVE_MISSING_PRIMARY_ACCOUNT> {
  primaryAccountMasterId: string;
}

export interface ResolveMissingPrimaryAccountFulfilledAction {
  type: TypeKeys.RESOLVE_MISSING_PRIMARY_ACCOUNT_FULFILLED;
}

export interface ResolveMissingPrimaryAccountFailedAction
  extends ErrorAction<TypeKeys.RESOLVE_MISSING_PRIMARY_ACCOUNT_FAILED> {
  response?: ConflictedAuthenticationResult;
}

export const resolveMissingPrimaryAccount = (primaryAccountMasterId: string): ResolveMissingPrimaryAccountAction => ({
  id: generateActionId(),
  primaryAccountMasterId,
  type: TypeKeys.RESOLVE_MISSING_PRIMARY_ACCOUNT,
});

export const resolveMissingPrimaryAccountFulfilled = (): ResolveMissingPrimaryAccountFulfilledAction => ({
  type: TypeKeys.RESOLVE_MISSING_PRIMARY_ACCOUNT_FULFILLED,
});

export const resolveMissingPrimaryAccountFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string },
  response?: ConflictedAuthenticationResult
): ResolveMissingPrimaryAccountFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  response,
  type: TypeKeys.RESOLVE_MISSING_PRIMARY_ACCOUNT_FAILED,
});

export interface AddMissingMobileNumberAndLogInAction
  extends CrudAction<TypeKeys.ADD_MISSING_MOBILE_NUMBER_AND_LOG_IN> {
  userName: string;
  password: string;
  mobileNumber: string;
}

export interface AddMissingMobileNumberAndLogInFulfilledAction {
  authenticationResult: AuthenticationResult;
  type: TypeKeys.ADD_MISSING_MOBILE_NUMBER_AND_LOG_IN_FULFILLED;
}

export type AddMissingMobileNumberAndLogInFailedAction =
  ErrorAction<TypeKeys.ADD_MISSING_MOBILE_NUMBER_AND_LOG_IN_FAILED>;

export const addMissingMobileNumberAndLogIn = (
  userName: string,
  password: string,
  mobileNumber: string
): AddMissingMobileNumberAndLogInAction => ({
  id: generateActionId(),
  password,
  mobileNumber,
  type: TypeKeys.ADD_MISSING_MOBILE_NUMBER_AND_LOG_IN,
  userName,
});

export const addMissingMobileNumberAndLogInFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): AddMissingMobileNumberAndLogInFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.ADD_MISSING_MOBILE_NUMBER_AND_LOG_IN_FAILED,
});

export interface AuthValidateAction extends CrudAction<TypeKeys.AUTH_VALIDATE> {
  elisaIdClient?: ElisaIdClientAttrs;
}

export interface AuthValidateFulfilledAction {
  elisaIdClient?: ElisaIdClientAttrs;
  authenticationResult: AuthenticationResult;
  type: TypeKeys.AUTH_VALIDATE_FULFILLED;
}

export interface AuthValidateFailedAction extends ErrorAction<TypeKeys.AUTH_VALIDATE_FAILED> {
  response?: ConflictedAuthenticationResult;
}

export const authValidate = (elisaIdClient?: ElisaIdClientAttrs): AuthValidateAction => ({
  id: generateActionId(),
  type: TypeKeys.AUTH_VALIDATE,
  elisaIdClient,
});

export const authValidateFulfilled = (
  authenticationResult: AuthenticationResult,
  elisaIdClient?: ElisaIdClientAttrs
): AuthValidateFulfilledAction => ({
  authenticationResult,
  elisaIdClient,
  type: TypeKeys.AUTH_VALIDATE_FULFILLED,
});

export const authValidateFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string },
  response?: ConflictedAuthenticationResult
): AuthValidateFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  response,
  type: TypeKeys.AUTH_VALIDATE_FAILED,
});

// Change User Password
export interface ChangePasswordAction extends CrudAction<TypeKeys.CHANGE_PASSWORD> {
  payload: ChangePasswordFormValues;
  validationErrors?: CommonError[];
}
export type ChangePasswordFailedAction = ErrorAction<TypeKeys.CHANGE_PASSWORD_FAILED>;

export interface ChangePasswordFulfilledAction extends Action {
  type: TypeKeys.CHANGE_PASSWORD_FULFILLED;
}

export const changePasswordAction = (
  request: ChangePasswordFormValues,
  validationErrors?: CommonError[]
): ChangePasswordAction => ({
  id: generateActionId(),
  payload: request,
  type: TypeKeys.CHANGE_PASSWORD,
  validationErrors: validationErrors,
});

export const changePasswordFulfilledAction = (): ChangePasswordFulfilledAction => ({
  type: TypeKeys.CHANGE_PASSWORD_FULFILLED,
});

export const changePasswordFailedAction = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): ChangePasswordFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.CHANGE_PASSWORD_FAILED,
});

// Get User

export type GetUserAction = CrudAction<TypeKeys.GET_USER>;

export interface GetUserFulfilledAction extends Action {
  type: TypeKeys.GET_USER_FULFILLED;
  user: UserResponse;
}

export type GetUserFailedAction = ErrorAction<TypeKeys.GET_USER_FAILED>;

export const getUser = (): GetUserAction => ({
  id: generateActionId(),
  type: TypeKeys.GET_USER,
});

export const getUserFulfilled = (user: UserResponse): GetUserFulfilledAction => ({
  type: TypeKeys.GET_USER_FULFILLED,
  user,
});

export const getUserFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): GetUserFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.GET_USER_FAILED,
});

// Update User Details

export interface UpdateUserDetailsAction extends CrudAction<TypeKeys.UPDATE_USER_DETAILS> {
  payload: ContactPerson;
  customerType?: CompanyInfoResponse.CustomerTypeEnum;
  validationErrors?: CommonError[];
}

export interface UpdateUserDetailsFulfilledAction extends Action {
  updatedUserDetails: ContactPerson;
  isCookieConsentUpdated: boolean;
  isUserOnboarded: boolean;
  type: TypeKeys.UPDATE_USER_DETAILS_FULFILLED;
}

export type UpdateUserDetailsFailedAction = ErrorAction<TypeKeys.UPDATE_USER_DETAILS_FAILED>;

export const updateUserDetails = (
  request: ContactPerson,
  customerType?: CompanyInfoResponse.CustomerTypeEnum,
  validationErrors?: CommonError[]
): UpdateUserDetailsAction => ({
  id: generateActionId(),
  customerType: customerType,
  payload: request,
  type: TypeKeys.UPDATE_USER_DETAILS,
  validationErrors: validationErrors,
});

export const updateUserDetailsFulfilled = (
  updatedUserDetails: ContactPerson,
  isCookieConsentUpdated: boolean,
  isUserOnboarded: boolean
): UpdateUserDetailsFulfilledAction => ({
  isCookieConsentUpdated: isCookieConsentUpdated,
  isUserOnboarded: isUserOnboarded,
  type: TypeKeys.UPDATE_USER_DETAILS_FULFILLED,
  updatedUserDetails,
});

export const updateUserDetailsFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): UpdateUserDetailsFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.UPDATE_USER_DETAILS_FAILED,
});

export interface CancelUserDetailsEdit {
  type: TypeKeys.CANCEL_USER_DETAILS_EDIT;
}

export const cancelUserDetailsEdit = (): CancelUserDetailsEdit => ({
  type: TypeKeys.CANCEL_USER_DETAILS_EDIT,
});

// get user Subscriptions
export interface GetEmployeeSubscriptionsAction extends CrudAction<TypeKeys.GET_EMPLOYEE_SUBSCRIPTIONS> {
  subscriptionItemId?: string;
  subscriptionType: SubscriptionCategory;
}

export interface GetEmployeeSubscriptionsFulfilledAction extends Action {
  response: SubscriptionsResponse;
  subscriptionType: SubscriptionCategory;
  type: TypeKeys.GET_EMPLOYEE_SUBSCRIPTIONS_FULLFILLED;
  subscriptionItemId?: string;
}

export type GetEmployeeSubscriptionsFailedAction = ErrorAction<TypeKeys.GET_EMPLOYEE_SUBSCRIPTIONS_FAILED>;

export const getEmployeeSubscriptions = (
  subscriptionType: SubscriptionCategory,
  subscriptionItemId?: string
): GetEmployeeSubscriptionsAction => ({
  id: generateActionId(),
  subscriptionItemId,
  subscriptionType,
  type: TypeKeys.GET_EMPLOYEE_SUBSCRIPTIONS,
});

export const getEmployeeSubscriptionsFulfilled = (
  response: SubscriptionsResponse,
  subscriptionType: SubscriptionCategory,
  subscriptionItemId?: string
): GetEmployeeSubscriptionsFulfilledAction => ({
  response,
  subscriptionItemId,
  subscriptionType,
  type: TypeKeys.GET_EMPLOYEE_SUBSCRIPTIONS_FULLFILLED,
});

export const getEmployeeSubscriptionsFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): GetEmployeeSubscriptionsFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.GET_EMPLOYEE_SUBSCRIPTIONS_FAILED,
});

export interface CheckValidEmployeeSubscription extends CrudAction<TypeKeys.CHECK_VALID_EMPLOYEE_SUBSCRIPTION> {
  subscriptionType: string;
  subscriptionToValidate?: string;
}

export const checkValidEmployeeSubscription = (
  subscriptionType: SubscriptionCategory,
  subscriptionToValidate?: string
): CheckValidEmployeeSubscription => ({
  id: generateActionId(),
  subscriptionToValidate,
  subscriptionType,
  type: TypeKeys.CHECK_VALID_EMPLOYEE_SUBSCRIPTION,
});

export interface ReviewAction extends CrudAction<TypeKeys.REVIEW> {
  request: OnlineOrderReview | SubscriptionRedeemReview;
  reviewType: PostReviewRequest.ReviewTypeEnum;
  approve: boolean;
  rejectReason?: string;
  validationErrors?: CommonError[];
  customerOrderDisplayId?: string;
  pendingSubscriptionActions?: SubscriptionAction[];
}

export interface ReviewFulfilledAction {
  reviewType: PostReviewRequest.ReviewTypeEnum;
  approved: boolean;
  subscriptionId?: string;
  customerOrderDisplayId?: string;
  pendingSubscriptionActions?: SubscriptionAction[];
  type: TypeKeys.REVIEW_FULFILLED;
}

export type ReviewFailedAction = ErrorAction<TypeKeys.REVIEW_FAILED>;

export const review = (
  request: OnlineOrderReview | SubscriptionRedeemReview,
  reviewType: PostReviewRequest.ReviewTypeEnum,
  approve: boolean,
  rejectReason?: string,
  validationErrors?: CommonError[],
  customerOrderDisplayId?: string,
  pendingSubscriptionActions?: SubscriptionAction[]
): ReviewAction => ({
  request,
  reviewType,
  approve,
  rejectReason,
  validationErrors,
  customerOrderDisplayId,
  pendingSubscriptionActions,
  id: generateActionId(),
  type: TypeKeys.REVIEW,
});

export const reviewFulfilled = (
  reviewType: PostReviewRequest.ReviewTypeEnum,
  approved: boolean,
  subscriptionId?: string,
  customerOrderDisplayId?: string,
  pendingSubscriptionActions?: SubscriptionAction[]
): ReviewFulfilledAction => ({
  reviewType,
  approved,
  subscriptionId,
  customerOrderDisplayId,
  pendingSubscriptionActions,
  type: TypeKeys.REVIEW_FULFILLED,
});

export const reviewFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): ReviewFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.REVIEW_FAILED,
});

export interface SetActiveAccountAction extends Action {
  activeAccountMasterId: string;
  reloadWindow?: boolean;
  redirectUrl?: string;
  switchToAccountName?: string;
  type: TypeKeys.SET_ACTIVE_ACCOUNT;
}

export const setActiveAccount = (
  activeAccountMasterId: string,
  reloadWindow = false,
  redirectUrl?: string,
  switchToAccountName?: string
): SetActiveAccountAction => ({
  activeAccountMasterId,
  reloadWindow,
  redirectUrl,
  switchToAccountName,
  type: TypeKeys.SET_ACTIVE_ACCOUNT,
});

// get user Subscriptions
export type SwitchAccountAction = CrudAction<TypeKeys.SWITCH_ACCOUNT>;

export interface SwitchAccountFulfilledAction extends Action {
  type: TypeKeys.SWITCH_ACCOUNT_FULFILLED;
}

export type SwitchAccountFailedAction = ErrorAction<TypeKeys.SWITCH_ACCOUNT_FAILED>;

export const switchAccount = (): SwitchAccountAction => ({
  id: generateActionId(),
  type: TypeKeys.SWITCH_ACCOUNT,
});

export const switchAccountFulfilled = (): SwitchAccountFulfilledAction => ({
  type: TypeKeys.SWITCH_ACCOUNT_FULFILLED,
});

export const switchAccountFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): SwitchAccountFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.SWITCH_ACCOUNT_FAILED,
});

export interface IdentifyStronglyAction extends CrudAction<TypeKeys.IDENTIFY_STRONGLY> {
  request: StrongAuthRequest;
  state: string;
}

export const identifyStrongly = (request: StrongAuthRequest, state: string): IdentifyStronglyAction => ({
  id: generateActionId(),
  request,
  state,
  type: TypeKeys.IDENTIFY_STRONGLY,
});

export interface IdentifyStronglyFulfilledAction extends Action {
  state: string;
  type: TypeKeys.IDENTIFY_STRONGLY_FULFILLED;
}

export const identifyStronglyFulfilled = (state: string): IdentifyStronglyFulfilledAction => ({
  state,
  type: TypeKeys.IDENTIFY_STRONGLY_FULFILLED,
});

export interface IdentifyStronglyFailedAction extends ErrorAction<TypeKeys.IDENTIFY_STRONGLY_FAILED> {
  state?: string;
}

export const identifyStronglyFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string },
  state?: string
): IdentifyStronglyFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    params,
    state,
    type: TypeKeys.IDENTIFY_STRONGLY_FAILED,
  };
};

export type UserActionTypes =
  | ReviewAction
  | ReviewFailedAction
  | ReviewFulfilledAction
  | ChangePasswordAction
  | ChangePasswordFailedAction
  | ChangePasswordFulfilledAction
  | GetUserAction
  | GetUserFulfilledAction
  | GetUserFailedAction
  | UpdateUserDetailsAction
  | UpdateUserDetailsFulfilledAction
  | UpdateUserDetailsFailedAction
  | CancelUserDetailsEdit
  | GetEmployeeSubscriptionsAction
  | GetEmployeeSubscriptionsFulfilledAction
  | GetEmployeeSubscriptionsFailedAction
  | CheckValidEmployeeSubscription
  | LogInAction
  | LogInFulfilledAction
  | LogInFailedAction
  | MfaRequiredAction
  | SelectPrimaryAccountAndLogInAction
  | SelectPrimaryAccountAndLogInFailedAction
  | SelectPrimaryAccountAndLogInFulfilledAction
  | AddMissingMobileNumberAndLogInAction
  | AddMissingMobileNumberAndLogInFailedAction
  | AddMissingMobileNumberAndLogInFulfilledAction
  | AuthValidateAction
  | AuthValidateFulfilledAction
  | AuthValidateFailedAction
  | SetActiveAccountAction
  | SwitchAccountAction
  | SwitchAccountFulfilledAction
  | SwitchAccountFailedAction
  | ResolveMissingPrimaryAccountAction
  | ResolveMissingPrimaryAccountFulfilledAction
  | ResolveMissingPrimaryAccountFailedAction
  | IdentifyStronglyAction
  | IdentifyStronglyFailedAction
  | IdentifyStronglyFulfilledAction;
