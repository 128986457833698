import * as CL from '@design-system/component-library';
import { LinkableAccordion } from '../../components/LinkableAccordion/index.js';
import { getTaapiTechnologyAsString } from './subscriptionUtils.js';
import { installationAddressMsg, t } from '../i18n/index.js';
import type { SubscriptionDetailsBroadband, SubscriptionDetailsMobile } from '../../generated/api/models.js';

export type Accordion = {
  headerName: string;
  includedData: JSX.Element;
  id: string;
  displayed: boolean;
};

export const AccordionsMain = ({ accordions }: { accordions: Accordion[] }) => (
  <div>
    {accordions
      .filter(accordion => accordion.displayed)
      .map(accordion => (
        <LinkableAccordion key={accordion.id} heading={accordion.headerName} headingLevel="h3" id={accordion.id}>
          {accordion.includedData}
        </LinkableAccordion>
      ))}
  </div>
);

export const TechnicalInformationBroadBand = (broadbandDetails: SubscriptionDetailsBroadband) => (
  <CL.Description
    items={[
      {
        title: t.DI4V(installationAddressMsg),
        description: broadbandDetails.installationAddress,
      },
      {
        title: t.F5BJ('Technology'),
        description: getTaapiTechnologyAsString(broadbandDetails.taapiTechnology),
      },
      {
        title: t.R4O4('Download speed'),
        description: broadbandDetails.downloadSpeed,
      },
      {
        title: t.CQQ7('Upload speed'),
        description: broadbandDetails.uploadSpeed,
      },
      {
        title: t.ECMJ('Device model'),
        description: broadbandDetails.deviceModel,
      },
      {
        title: t.OVGH('Device serial number'),
        description: broadbandDetails.serialNumber,
      },
    ].filter(item => !!item && item.description)}
  />
);

export const TechnicalInformationMobile = (mobileBroadbandDetails?: SubscriptionDetailsMobile) => (
  <>
    {mobileBroadbandDetails && (
      <CL.Description
        items={[
          {
            title: t.ECMJ('Device model'),
            description: mobileBroadbandDetails.deviceModel,
          },
          {
            title: t.OVGH('Device serial number'),
            description: mobileBroadbandDetails.serialNumber,
          },
        ].filter(item => !!item && item.description)}
      />
    )}
  </>
);
