import { AuthenticatedUserRole, IdentityProvider } from '../../generated/api/models.js';
import { AuxiliaryEsimOrderPhaseEnum } from '../../components/AuxiliaryEsimOrderScene/auxiliaryEsimOrderEnums';
import {
  authValidate,
  elisaIdLogOut,
  logIn,
  logOut,
  processElisaIdLogin,
  setAuxiliaryEsimOrderPhase,
} from '../../selfservice/actions/index.js';
import {
  clearMultisimSubscriptionToken,
  getAuthState,
  getMultisimSubscriptionToken,
  removePrimaryMdmId,
  setPrimaryMdmId,
} from '../../selfservice/common/localStorageUtils.js';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { fetchExternalAuthMethods } from '../../common/fetch.js';
import {
  initiateElisaIdV2LoginWithNoPrompt,
  logoutElisaIdV2session,
  removeElisaIdQueryParams,
} from '../common/util/elisaIdLogin.js';
import { isEmployeePortal, isEmployeePublicPath } from '../../common/utils/browserUtils.js';
import { isFeatureEnabled } from '../../common/utils/featureFlagUtils';
import { stripActions, stripLatestChangeRef } from '../../common/utils/stateUtils.js';
import { useChangesPoller } from '../../common/hooks/useChangesPoller.js';
import { useDispatch, useSelector } from 'react-redux';
import { useElisaIdClient } from '../../common/hooks/useElisaIdClient.js';
import { useLocation, useNavigate } from 'react-router-dom';
import type {
  AnonymousUserState,
  AuthorizationRequestState,
  ExternalAuthenticationState,
  RealAuthenticatedUserState,
} from '../../common/types/states.js';
import type { AuthenticationMethod } from '../../generated/api/models.js';
import type { ReactNode } from 'react';
import type { State } from '../../selfservice/common/store.js';

export interface AuthProps {
  anonymousUser?: AnonymousUserState | null | undefined;
  authenticatedUser?: RealAuthenticatedUserState | undefined;
  authorizationRequest?: AuthorizationRequestState | null;
  elisaIdSessionValid: boolean;
  externalAuthMethods: AuthenticationMethod[] | 'failed' | 'loading';
  externalAuthentication?: ExternalAuthenticationState | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  login: (username: string, password: string) => void;
  logout: (redirectTo?: string) => void;
  validateAuth: () => void;
  resetLoginForm: boolean;
  ssoSessionValid: boolean;
  elisaIdV2LoginProcessing?: boolean | undefined;
  preElisaIdV2AuthLoading: boolean;
}

const defaultValues: AuthProps = {
  anonymousUser: undefined,
  authorizationRequest: undefined,
  elisaIdSessionValid: false,
  externalAuthMethods: [],
  externalAuthentication: undefined,
  isAuthenticated: false,
  isLoading: false,
  login: (_username: string, _password: string) => {},
  logout: (_redirectTo: string | undefined) => {},
  validateAuth: () => {},
  resetLoginForm: false,
  ssoSessionValid: false,
  elisaIdV2LoginProcessing: undefined,
  preElisaIdV2AuthLoading: true,
};

/**
 * @deprecated This exists solely for TestProvider and should not be used elsewhere.
 *             Once all auth data and functionality is moved away from redux this can be deleted.
 **/
// eslint-disable-next-line @typescript-eslint/naming-convention
export const useReduxAuthState_DO_NOT_USE = () => {
  const anonymousUser = useSelector((state: State) => stripActions(state.user?.anonymous), deepEqual) || undefined;
  const authenticatedUser =
    useSelector((state: State) => stripLatestChangeRef(stripActions(state.user?.authenticated)), deepEqual) ||
    undefined;
  const authorizationRequest = useSelector((state: State) => state.authentication?.authorizationRequest, deepEqual);
  const externalAuthentication = useSelector((state: State) => state.authentication?.externalAuthentication, deepEqual);
  const elisaIdSessionValid = !!anonymousUser?.elisaIdSessionValid;
  const ssoSessionValid = !!anonymousUser?.ssoSessionValid;
  const resetLoginForm = !!anonymousUser?.resetLoginForm;
  const { companyName, firstName, userRole } = authenticatedUser || {};
  const isAuthenticated = Boolean(companyName && firstName && userRole === AuthenticatedUserRole.KEY_USER);
  const isLoading = authenticatedUser?.isFetching || (authenticatedUser == null && anonymousUser == null);
  const elisaIdV2LoginProcessing = useSelector((state: State) => state.user?.anonymous?.elisaIdV2LoginProcessing);
  const preElisaIdV2AuthLoading = !(anonymousUser?.ssoSessionValid !== undefined || elisaIdV2LoginProcessing === false);

  return {
    anonymousUser,
    authenticatedUser,
    authorizationRequest,
    externalAuthentication,
    elisaIdSessionValid,
    ssoSessionValid,
    resetLoginForm,
    isAuthenticated,
    isLoading,
    elisaIdV2LoginProcessing,
    preElisaIdV2AuthLoading,
  };
};

// NOTE!
// - DO NOT use the `AuthContext` outside of this file, except for tests. It is only exported for tests.
// - We have to use default value because `AuthProvider` is placed very high in the React tree and during errors we render
//   `<RootErrorBoundary>` which uses `AuthProvider`. When that happens the `AuthProvider` is not rendered, thus a default
//   context needs to exist so that  `useAuth()` still works. So the above default values should be reasonable ones.
export const AuthContext = createContext(defaultValues);

export const AuthProvider = ({ children }: { children?: ReactNode }) => {
  const reduxAuth = useReduxAuthState_DO_NOT_USE();
  const [extAuthMethods, setExtAuthMethods] = useState<AuthenticationMethod[] | 'failed' | 'loading'>('loading');
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const elisaIdClient = useElisaIdClient();
  const navigate = useNavigate();
  const authValidateDispatched = useRef(false);
  const elisaIdV2 = useSelector((state: State) => state.config.featureFlags.elisaIdV2);

  useChangesPoller(reduxAuth.authenticatedUser);

  useEffect(() => {
    const fn = async () => setExtAuthMethods(await fetchExternalAuthMethods());
    void fn();
  }, []);

  const login = (username: string, password: string) => {
    dispatch(logIn(username, password));
  };
  const logout = (redirectTo?: string) => {
    removePrimaryMdmId();
    if (reduxAuth.authenticatedUser?.identityProvider === IdentityProvider.ELISA_ID_V2) {
      void logoutElisaIdV2session(
        redirectTo ? window.location.origin + redirectTo : window.location.origin + decodeURIComponent(pathname)
      );
    } else if (reduxAuth.authenticatedUser?.identityProvider === IdentityProvider.ELISA_ID) {
      dispatch(elisaIdLogOut(elisaIdClient));
      dispatch(logOut(redirectTo));
    } else {
      dispatch(logOut(redirectTo));
    }
  };

  const validateAuth = () => {
    dispatch(authValidate());
  };

  const isBeforeAuthProcessing = reduxAuth.elisaIdV2LoginProcessing === undefined && !reduxAuth.ssoSessionValid;

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get('code') && searchParams.get('state') === getAuthState()) {
      if (getMultisimSubscriptionToken()) {
        dispatch(setAuxiliaryEsimOrderPhase(AuxiliaryEsimOrderPhaseEnum.VALIDATE, getMultisimSubscriptionToken()!));
        clearMultisimSubscriptionToken();
      }
      dispatch(processElisaIdLogin(searchParams.get('code')!));
      removeElisaIdQueryParams(window.location.pathname, navigate);
    } else if (
      isFeatureEnabled(elisaIdV2) &&
      isEmployeePortal(pathname) &&
      !isEmployeePublicPath(pathname) &&
      isBeforeAuthProcessing &&
      searchParams.get('error') !== 'login_required'
    ) {
      void initiateElisaIdV2LoginWithNoPrompt();
    } else if (!authValidateDispatched.current && !reduxAuth.elisaIdV2LoginProcessing) {
      dispatch(authValidate());
      authValidateDispatched.current = true;
    }
    if (searchParams.get('error')) {
      removeElisaIdQueryParams(window.location.pathname, navigate);
    }
  }, [dispatch, elisaIdV2, isBeforeAuthProcessing, navigate, pathname, reduxAuth.elisaIdV2LoginProcessing]);

  useEffect(() => {
    const primaryAccount = reduxAuth.authenticatedUser?.contact?.accountContactRelationships?.find(
      acr => acr.isPrimaryAccount
    );
    if (primaryAccount) {
      setPrimaryMdmId(primaryAccount.accountMasterId);
    }
  }, [reduxAuth.authenticatedUser]);

  return (
    <AuthContext.Provider
      value={{
        ...reduxAuth,
        externalAuthMethods: extAuthMethods,

        login,
        logout,
        validateAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthProps => {
  return useContext(AuthContext);
};
