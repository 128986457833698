import { TypeKeys, generateActionId } from './actionsUtils.js';
import { convertToCommonErrors } from '../../common/utils/errorUtils.js';
import type { CompositeListSort } from '../../components/CompositeListHeader/CompositeListHeader.js';
import type { CrudAction, DisplayItemsLoadAction, ErrorAction, ErrorActionCreator } from './actionsUtils.js';
import type {
  CustomerOrder,
  CustomerOrderAdditionalInfo,
  CustomerOrderAggregationsResponse,
  CustomerOrderSearchResponse,
  CustomerOrdersResponse,
  GenericError,
} from '../../generated/api/models.js';
import type { ItemsQuery } from '../common/store.js';

export interface LoadCustomerOrdersAction extends DisplayItemsLoadAction<TypeKeys.LOAD_CUSTOMER_ORDERS, CustomerOrder> {
  forceLoad?: boolean;
  status?: string;
  limit?: number;
  offset?: number;
}

export interface LoadCustomerOrdersFulfilledAction {
  customerOrders?: CustomerOrder[];
  query: ItemsQuery;
  searchResults?: CustomerOrderSearchResponse[];
  resultCountAggregations?: CustomerOrderAggregationsResponse;
  useSearchService?: boolean;
  mergeHeaders?: boolean;
  total: number;
  type: TypeKeys.LOAD_CUSTOMER_ORDERS_FULFILLED;
}

export type LoadCustomerOrdersFailedAction = ErrorAction<TypeKeys.LOAD_CUSTOMER_ORDERS_FAILED>;

export const loadCustomerOrders = (
  displayId?: string,
  deliveryId?: string,
  sort?: CompositeListSort,
  search?: string,
  reporting = false,
  forceLoad?: boolean,
  status?: string,
  offset?: number,
  limit?: number
): LoadCustomerOrdersAction => ({
  displayId,
  id: generateActionId(),
  sort,
  search,
  reporting,
  forceLoad,
  status,
  offset,
  limit,
  type: TypeKeys.LOAD_CUSTOMER_ORDERS,
});

export const loadCustomerOrdersFulfilled = (
  response: CustomerOrdersResponse,
  query: ItemsQuery,
  useSearchService?: boolean,
  mergeHeaders?: boolean
): LoadCustomerOrdersFulfilledAction => {
  return {
    customerOrders: response.customerOrders,
    query,
    searchResults: response.searchResults,
    resultCountAggregations: response.resultCountAggregations,
    useSearchService,
    mergeHeaders,
    total: response.total,
    type: TypeKeys.LOAD_CUSTOMER_ORDERS_FULFILLED,
  };
};

export const loadCustomerOrdersFailed: ErrorActionCreator<TypeKeys.LOAD_CUSTOMER_ORDERS_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): LoadCustomerOrdersFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    params,
    type: TypeKeys.LOAD_CUSTOMER_ORDERS_FAILED,
  };
};

export interface LoadCustomerOrderAdditionalInfoAction
  extends CrudAction<TypeKeys.LOAD_CUSTOMER_ORDER_ADDITIONAL_INFO> {
  customerOrderId: string;
}

export interface LoadCustomerOrderAdditionalInfoFulfilledAction {
  additionalInfo: CustomerOrderAdditionalInfo;
  customerOrderId: string;
  type: TypeKeys.LOAD_CUSTOMER_ORDER_ADDITIONAL_INFO_FULFILLED;
}

export type LoadCustomerOrderAdditionalInfoFailedAction =
  ErrorAction<TypeKeys.LOAD_CUSTOMER_ORDER_ADDITIONAL_INFO_FAILED>;

export const loadCustomerOrderAdditionalInfo = (customerOrderId: string): LoadCustomerOrderAdditionalInfoAction => ({
  customerOrderId,
  id: generateActionId(),
  type: TypeKeys.LOAD_CUSTOMER_ORDER_ADDITIONAL_INFO,
});

export const loadCustomerOrderAdditionalInfoFulfilled = (
  response: CustomerOrderAdditionalInfo,
  customerOrderId: string
): LoadCustomerOrderAdditionalInfoFulfilledAction => {
  return {
    additionalInfo: response,
    customerOrderId,
    type: TypeKeys.LOAD_CUSTOMER_ORDER_ADDITIONAL_INFO_FULFILLED,
  };
};

export type CustomerOrdersActionTypes =
  | LoadCustomerOrdersAction
  | LoadCustomerOrdersFailedAction
  | LoadCustomerOrdersFulfilledAction
  | LoadCustomerOrderAdditionalInfoAction
  | LoadCustomerOrderAdditionalInfoFailedAction
  | LoadCustomerOrderAdditionalInfoFulfilledAction;
