/**
 * SFDC Community API
 * # Summary Elisa B2B API for an administrator of a company to query and modify the company information.  # Usage This API is used in the `https://yrityksille.elisa.fi/omaelisa` pages, but it is possible to later on expose this documentation publicly and make the endpoint queryable via external clients.  # Identifiers All entities in the API have a globally unique, internal identifier field named with the format [object_type]Id, e.g. `invoiceId`, which is also used in some of the API endpoint urls, e.g. `/api/v1/selfservice/invoices/{invoiceId}`. This value is not meaningful to the end user but is only used as an internally in the API.  Most entities also have a displayable identifier named in the format [object_type]DisplayId. This value can and should be shown to the end user, as it is also printed in relevant documents. It is also used in some of the public URLs, e.g. `https://yrityksille.elisa.fi/omaelisa/laskut/{invoiceDisplayId}`. Because of the user might land directly into a URL of a single entity with a display id, some of the API endpoints here also support querying via the display id, e.g. `/api/v1/selfservice/invoices?invoiceDisplayId={invoiceDisplayId}`.  The reason for using two identifiers instead of only one display id, is to make sure that every object will always have exactly one REST API URL that will never collide with another. While collisions of display ids should not happen in production, the id fields are guaranteed to be unique even across different test environments. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SupportCaseOrigin } from './supportCaseOrigin.js';


export interface SupportCase { 
    /**
     * Internal, unique id of the support case. SFDC: Case.Id.
     */
    supportCaseId?: string;
    /**
     * Id of the support case as shown to the user. SFDC: Case.CaseNumber.
     */
    supportCaseDisplayId?: string;
    /**
     * Url where the user was when creating the support case. Editable (by system). SFDC: Case.Additional_Information__c.
     */
    url: string;
    /**
     * Feature that the user was interacting with when creating the support case. Editable (by system). SFDC: Case.Additional_Information__c.
     */
    feature: SupportCase.FeatureEnum;
    /**
     * More specific type under the feature if applicable. Editable (by system). SFDC: Case.Additional_Information__c.
     */
    featureType?: string;
    /**
     * Id of an item in the above feature where the user was when creating the support case. Editable (by system). SFDC: Additional_Information__c, various lookup fields.
     */
    featureItemId?: string;
    /**
     * Action that was done on url above to create the support case. Editable (by system). SFDC: Case.Additional_Information__c.
     */
    action: string;
    /**
     * Email acknowledgement is enough if the case can be handled with supplied information
     */
    emailAcknowledgementOnly?: boolean;
    /**
     * Customer-given subject field for the support case, not mandatory. Editable. SFDC: Case.Subject.
     */
    subject?: string;
    /**
     * Customer-given description field for the support case. Editable. SFDC: Case.Description.
     */
    description?: string;
    /**
     * Unix timestamp of last modification. SFDC: Case.LastModifiedDate.
     */
    lastModified?: number;
    /**
     * Unix timestamp of creation time.
     */
    createdAt?: number;
    /**
     * Status of support case
     */
    status?: SupportCase.StatusEnum;
    /**
     * Invoice number. Applies to \'Ask about the invoice\' case type
     */
    invoiceNumber?: string;
    /**
     * Case ID in customer\'s system if applicable. SFDC: Case.Additional_Information__c.
     */
    externalCaseId?: string;
    caseOrigin?: SupportCaseOrigin;
    /**
     * List of attachments ids
     */
    attachmentIds?: Array<string>;
}
export namespace SupportCase {
    export type FeatureEnum = 'CONTACT_INFO_OFFER_REQUEST' | 'CONTACT_INFO_OPEN_ORDERS' | 'CONTACT_INFO_BILLING' | 'INVOICES' | 'BILLING_E_OPERATOR_CHANGES' | 'CONTACT_INFO_SERVICES' | 'CONTACT_INFO_TECHNICAL' | 'CONTACT_INFO_COVERAGE' | 'CONTACT_INFO_CANCEL' | 'CONTACT_INFO_PUK' | 'CONTACT_INFO_OTHER' | 'BILLING_ACCOUNTS' | 'CUSTOMER_ORDERS' | 'DELIVERY_ORDERS' | 'SUBSCRIPTIONS' | 'CONTACTS' | 'FIXED_BROADBAND_ORDER' | 'DEVICES' | 'ASSETS' | 'OTHER_SYSTEM_ORDERS';
    export const FeatureEnum = {
        CONTACT_INFO_OFFER_REQUEST: 'CONTACT_INFO_OFFER_REQUEST' as FeatureEnum,
        CONTACT_INFO_OPEN_ORDERS: 'CONTACT_INFO_OPEN_ORDERS' as FeatureEnum,
        CONTACT_INFO_BILLING: 'CONTACT_INFO_BILLING' as FeatureEnum,
        INVOICES: 'INVOICES' as FeatureEnum,
        BILLING_E_OPERATOR_CHANGES: 'BILLING_E_OPERATOR_CHANGES' as FeatureEnum,
        CONTACT_INFO_SERVICES: 'CONTACT_INFO_SERVICES' as FeatureEnum,
        CONTACT_INFO_TECHNICAL: 'CONTACT_INFO_TECHNICAL' as FeatureEnum,
        CONTACT_INFO_COVERAGE: 'CONTACT_INFO_COVERAGE' as FeatureEnum,
        CONTACT_INFO_CANCEL: 'CONTACT_INFO_CANCEL' as FeatureEnum,
        CONTACT_INFO_PUK: 'CONTACT_INFO_PUK' as FeatureEnum,
        CONTACT_INFO_OTHER: 'CONTACT_INFO_OTHER' as FeatureEnum,
        BILLING_ACCOUNTS: 'BILLING_ACCOUNTS' as FeatureEnum,
        CUSTOMER_ORDERS: 'CUSTOMER_ORDERS' as FeatureEnum,
        DELIVERY_ORDERS: 'DELIVERY_ORDERS' as FeatureEnum,
        SUBSCRIPTIONS: 'SUBSCRIPTIONS' as FeatureEnum,
        CONTACTS: 'CONTACTS' as FeatureEnum,
        FIXED_BROADBAND_ORDER: 'FIXED_BROADBAND_ORDER' as FeatureEnum,
        DEVICES: 'DEVICES' as FeatureEnum,
        ASSETS: 'ASSETS' as FeatureEnum,
        OTHER_SYSTEM_ORDERS: 'OTHER_SYSTEM_ORDERS' as FeatureEnum
    };
    export type StatusEnum = 'OPEN' | 'CANCELLED' | 'RESOLVED' | 'WAITING_FOR_INPUT' | 'IN_PROGRESS' | 'CLOSED' | 'MOVED_ELSEWHERE';
    export const StatusEnum = {
        OPEN: 'OPEN' as StatusEnum,
        CANCELLED: 'CANCELLED' as StatusEnum,
        RESOLVED: 'RESOLVED' as StatusEnum,
        WAITING_FOR_INPUT: 'WAITING_FOR_INPUT' as StatusEnum,
        IN_PROGRESS: 'IN_PROGRESS' as StatusEnum,
        CLOSED: 'CLOSED' as StatusEnum,
        MOVED_ELSEWHERE: 'MOVED_ELSEWHERE' as StatusEnum
    };
}


