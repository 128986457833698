import { AuthenticatedUserRole, SubscriptionType } from '../../generated/api/models.js';
import { EMPTY, of } from 'rxjs';
import { ModelType, SfToggles } from '../../common/enums.js';
import {
  TypeKeys,
  eppSolutionToValidationRules,
  getCustomerLevelDiscountedPrices,
  getCustomerLevelDiscountedPricesFailed,
  getCustomerLevelDiscountedPricesFulfilled,
  getEppSolutionFailed,
  getEppSolutionFulfilled,
  getMyselfSecondaryAccountFailed,
  getMyselfSecondaryAccountFulfilled,
  loadCompanyInfoFailed,
  loadCompanyInfoFulfilled,
  loadOnlineModelByModelType,
  processCartItems,
  updateAccountSettingsFailed,
  updateAccountSettingsFulfilled,
} from '../actions/index.js';
import { actionToActionState, getMdmIdHeaderFromUrlParams, isNewDeviceCheckout } from './epicUtils.js';
import { callUiApi, prepareUiApiRequest } from '../common/uiApiUtils.js';
import { combineEpics, ofType } from 'redux-observable';
import { concatMap, mergeMap } from 'rxjs/operators';
import {
  getDiscountedPricesPrivateMethod,
  getEppSolutionPrivateMethod,
  getMyselfAccountPrivateMethod,
  getMyselfSecondaryAccountsPrivateMethod,
  updateMyselfAccountPrivateMethod,
} from '../../generated/api/uiApiMethods.js';
import { getShoppingCart } from '../common/localStorageUtils.js';
import { isEmployeePortal } from '../../common/utils/browserUtils.js';
import type { Action } from 'redux';
import type { ActionAndState, EpicDependencies } from './epicUtils.js';
import type { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import type { CompanyInfoResponse } from '../../generated/api/models.js';
import type {
  GetCustomerLevelDiscountedPricesAction,
  GetEppSolutionAction,
  GetMyselfSecondaryAccountAction,
  LoadCompanyInfoAction,
  LoadCompanyInfoFulfilledAction,
  SelfServiceActionTypes,
  UpdateAccountSettingsAction,
} from '../actions/index.js';
import type { State } from '../common/store.js';

export const loadCompanyInfoEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.LOAD_COMPANY_INFO)), (action: LoadCompanyInfoAction) =>
    actionToActionState(action, state$, 'companyInfo')
  ).pipe(
    concatMap(() => {
      return callUiApi({
        epicDependencies,
        failureAction: loadCompanyInfoFailed,
        method: getMyselfAccountPrivateMethod(),
        state$,
        successAction: response => loadCompanyInfoFulfilled(response as CompanyInfoResponse),
      });
    })
  );

export const getEppSolutionEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(
    action$.pipe(ofType(TypeKeys.GET_EPP_SOLUTION_JIT_FAILURE_WORKAROUND)),
    (action: GetEppSolutionAction) => actionToActionState(action, state$, 'companyInfo')
  ).pipe(
    concatMap(() => {
      return callUiApi({
        epicDependencies,
        state$,
        method: getEppSolutionPrivateMethod(),
        successAction: getEppSolutionFulfilled,
        failureAction: getEppSolutionFailed,
      });
    })
  );

export const companyInfoFulfilledEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>
) =>
  action$.ofType(TypeKeys.LOAD_COMPANY_INFO_FULFILLED).pipe(
    mergeMap((action: LoadCompanyInfoFulfilledAction) => {
      const actions = [];
      if (
        !isEmployeePortal(window.location.pathname) &&
        state$.value.user?.authenticated?.userRole !== AuthenticatedUserRole.PUNCHOUT_USER
      ) {
        actions.push(
          processCartItems(
            false,
            false,
            getShoppingCart(),
            eppSolutionToValidationRules(action.companyInfo.eppSolution)
          )
        );
      }
      // Depending on uiOptions, we will want to load more models or even delete other models
      const uiOptions = action.companyInfo.uiOptions as { toggles?: string[] };
      if (uiOptions?.toggles) {
        const toggles: string[] = uiOptions.toggles;
        if (toggles.includes(SfToggles.SHOW_PERUSLIITTYMA_4G)) {
          actions.push(loadOnlineModelByModelType(ModelType.VoicePerusliittyma4g));
        }
        if (toggles.includes(SfToggles.SHOW_YRITYSPUHE)) {
          actions.push(loadOnlineModelByModelType(ModelType.VoiceYrityspuhe));
        }
        if (toggles.includes(SfToggles.SHOW_YRITYSLIITTYMA_OLD)) {
          actions.push(loadOnlineModelByModelType(ModelType.Voice));
        }
        if (toggles.includes(SfToggles.SHOW_YRITYSPAKETTI)) {
          actions.push(loadOnlineModelByModelType(ModelType.Yrityspaketti));
        }
      }
      // load ring model if ring solution is found:
      if (
        action.companyInfo.pbxSolutions &&
        action.companyInfo.pbxSolutions.filter(solution => solution.subscriptionType === SubscriptionType.MOBILE_PBX)
          .length > 0
      ) {
        actions.push(loadOnlineModelByModelType(ModelType.Ring));
      }
      return actions.length ? of(...actions) : EMPTY;
    })
  );

export const getDiscountedPricesEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(
    action$.pipe(ofType(TypeKeys.GET_CUSTOMER_LEVEL_DISCOUNTED_PRICES)),
    (action: GetCustomerLevelDiscountedPricesAction) => actionToActionState(action, state$, 'companyInfo')
  ).pipe(
    concatMap(() => {
      return callUiApi({
        epicDependencies,
        failureAction: getCustomerLevelDiscountedPricesFailed,
        method: getDiscountedPricesPrivateMethod(),
        headers: getMdmIdHeaderFromUrlParams(),
        state$,
        successAction: getCustomerLevelDiscountedPricesFulfilled,
      });
    })
  );

export const loadDiscountOnLogInFulfilledEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>
) =>
  action$.pipe(
    ofType(TypeKeys.LOG_IN_FULFILLED),
    mergeMap(() => {
      if (isNewDeviceCheckout(window.location.pathname)) {
        return of(getCustomerLevelDiscountedPrices());
      }
      return EMPTY;
    })
  );

export const updateAccountSettingsEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.UPDATE_ACCOUNT_SETTINGS)), (action: UpdateAccountSettingsAction) =>
    actionToActionState(action, state$, 'companyInfo')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const updateAccountSettingsAction = actionAndState.action as UpdateAccountSettingsAction;

      return callUiApi({
        epicDependencies,
        failureAction: updateAccountSettingsFailed,
        method: updateMyselfAccountPrivateMethod(),
        payload: updateAccountSettingsAction.updateAccountRequest,
        state$,
        successAction: () => updateAccountSettingsFulfilled(updateAccountSettingsAction.updateAccountRequest),
      });
    })
  );

const getMyselfSecondaryAccountEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(
    action$.pipe(ofType(TypeKeys.GET_MYSELF_SECONDARY_ACCOUNT)),
    (action: GetMyselfSecondaryAccountAction) => actionToActionState(action, state$, 'companyInfo')
  ).pipe(
    concatMap(() => {
      return callUiApi({
        epicDependencies,
        state$,
        method: getMyselfSecondaryAccountsPrivateMethod(),
        successAction: getMyselfSecondaryAccountFulfilled,
        failureAction: getMyselfSecondaryAccountFailed,
      });
    })
  );

export const companyInfoEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = combineEpics(
  loadCompanyInfoEpic,
  getEppSolutionEpic,
  companyInfoFulfilledEpic,
  getDiscountedPricesEpic,
  loadDiscountOnLogInFulfilledEpic,
  updateAccountSettingsEpic,
  getMyselfSecondaryAccountEpic
);
