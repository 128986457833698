import * as CL from '@design-system/component-library';
import * as React from 'react';
import { PublicPagesSearchDisplayedResultItem } from '../PublicPagesSearchDisplayedResultItem/PublicPagesSearchDisplayedResultItem.js';
import { PublicPagesSearchForm } from '../PublicPagesSearchForm/PublicPagesSearchForm.js';
import { PublicPagesSearchMetadata } from '../PublicPagesSearchMetadata/PublicPagesSearchMetadata.js';
import { PublicPagesSearchPromo } from '../PublicPagesSearchPromo/PublicPagesSearchPromo.js';
import { SmallHeader } from '../SmallHeader/SmallHeader.js';
import { Spinner } from '../../public/site/siteUtils.js';
import { capitalize } from '../../common/utils/stringUtils.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import {
  elisaForCompaniesMsg,
  returnToHomePageMsg,
  searchPlaceHolderMsg,
  showMoreMsg,
  t,
} from '../../common/i18n/index.js';
import { isInBrowser } from '../../common/utils/ssrUtils.js';
import { resetSearchPagesSearchResult, searchPage } from '../../selfservice/actions/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import type { PageSearchHit, Promotion } from '../../generated/api/models.js';
import type { State } from '../../selfservice/common/store.js';

import './SearchPublicPages.scss';

export const SEARCH_FORM_WIDTHS = {
  colWidthXS: 3,
  colWidthS: 5,
  colWidthM: 5,
  colWidthL: 8,
  colWidthXL: 7,
} as const;

export const SEARCH_RESULT_WIDTHS = {
  colWidthXS: 4,
  colWidthS: 6,
  colWidthM: 6,
  colWidthL: 8,
  colWidthXL: 7,
} as const;

const SEARCH_RESULTS_ID = 'of-public-pages-search-results';

export const SearchPublicPages = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const [keyword, setKeyword] = useState('');
  const [usedKeyword, setUsedKeyword] = useState('');
  const { pageSearchInProgress, searchPageResult } = useSelector(
    (state: State) => ({
      searchPageResult: state?.resources?.pageSearchResult,
      pageSearchInProgress: state.resources?.pageSearchInProgress,
    }),
    deepEqual
  );
  const pageHits = searchPageResult?.pageHits || [];

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const keywordFromQueryParam: string | null = queryParams.get('q');
    if (keywordFromQueryParam) {
      if (isInBrowser()) {
        document.title = `${capitalize(keywordFromQueryParam)} | ${t.V0XR(elisaForCompaniesMsg)}`;
      }
      setKeyword(keywordFromQueryParam);
      setUsedKeyword(keywordFromQueryParam);
      dispatch(resetSearchPagesSearchResult());
      dispatch(searchPage(keywordFromQueryParam, 1));
    }
  }, [dispatch, search]);

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    navigate(`${location.pathname}?q=${keyword}`);
  };

  const handleLoadMoreResults = () => {
    dispatch(searchPage(keyword, pageHits.length + 1));
  };

  return (
    <>
      <SmallHeader
        {...{
          buttonText: t.ORYE(returnToHomePageMsg),
          onClick: () => {
            navigate('/');
          },
        }}
      />
      <CL.Grid className="of-public-pages-search-scene">
        <CL.GridRow justifyCenter>
          <CL.GridCol {...SEARCH_FORM_WIDTHS}>
            <h1 className={dsClass.MARGIN_VERTICAL_6}>{t.V4GK('Search')}</h1>
            <PublicPagesSearchForm
              handleClear={() => {
                setKeyword('');
              }}
              handleSubmit={handleSubmit}
              onChange={(q: React.ChangeEvent<HTMLInputElement>) => {
                setKeyword(q.target.value);
              }}
              value={keyword}
              placeholder={t.GGLB(searchPlaceHolderMsg)}
              minLength={2}
            />
            {searchPageResult && (
              <PublicPagesSearchMetadata searchPageResult={searchPageResult} usedQueryString={usedKeyword} />
            )}
          </CL.GridCol>
        </CL.GridRow>
        {searchPageResult?.promotions?.map((promo: Promotion, index: number) => (
          <CL.GridRow justifyCenter key={index}>
            <CL.GridCol {...SEARCH_RESULT_WIDTHS}>
              <PublicPagesSearchPromo promotion={promo} key={index} />
            </CL.GridCol>
          </CL.GridRow>
        ))}
        {!!searchPageResult?.pageHits?.length && (
          <CL.GridRow justifyCenter>
            <CL.GridCol {...SEARCH_RESULT_WIDTHS}>
              <ul id={SEARCH_RESULTS_ID}>
                {searchPageResult?.pageHits?.map((pageSearchHit: PageSearchHit, index: number) => (
                  <PublicPagesSearchDisplayedResultItem
                    pageSearchHit={pageSearchHit}
                    queryString={keyword}
                    key={index}
                  />
                ))}
              </ul>
            </CL.GridCol>
          </CL.GridRow>
        )}
        {!searchPageResult && pageSearchInProgress && <Spinner />}
        {!!searchPageResult?.total && searchPageResult.pageHits.length < searchPageResult.total && (
          <CL.GridRow className={dsClass.MARGIN_BOTTOM_8} justifyCenter>
            <CL.GridCol className="of-public-pages-search-results-buttons" {...SEARCH_RESULT_WIDTHS}>
              <CL.Button
                aria-controls={SEARCH_RESULTS_ID}
                color="light"
                loading={pageSearchInProgress}
                onClick={handleLoadMoreResults}
              >
                {t.H6OA(showMoreMsg)}
              </CL.Button>
            </CL.GridCol>
          </CL.GridRow>
        )}
      </CL.Grid>
    </>
  );
};
