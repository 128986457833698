import { AiChat } from '../../../../components/AiChat/AiChat.js';
import { PublicPageContent } from '../PublicPath.js';
import { deepEqual } from '../../../../common/utils/objectUtils.js';
import { isStrictFeatureEnabled } from '../../../../common/utils/featureFlagUtils.js';
import { useRouteLoaderData } from 'react-router-dom';
import { useSelector } from 'react-redux';
import type { PageResponse } from '../../../../generated/api/pageResponse.js';
import type { State } from '../../../../selfservice/common/store.js';

/**
 * This path uses public page content and injects AiChat as children to be located after breadcrumbs
 */
export const InstructionsPath = () => {
  const page = useRouteLoaderData('public-path-root') as PageResponse;
  const featureFlags = useSelector((state: State) => state.config.featureFlags, deepEqual);
  return (
    <PublicPageContent {...page}>
      {isStrictFeatureEnabled(featureFlags.isAiChatEnabled) && <AiChat />}
    </PublicPageContent>
  );
};
