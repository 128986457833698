import { CommonErrorType, DialogType } from '../../../common/enums.js';
import { CreditCheckDecision } from '../../../generated/api/models.js';
import { DialogWrapper } from '../../DialogWrapper/index.js';
import { closeDialog } from '../../../selfservice/actions/index.js';
import { closeMsg, dialogSystemErrorHeaderMsg, t } from '../../../common/i18n/index.js';
import { deepEqual } from '../../../common/utils/objectUtils.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { paths } from '../../../common/constants/pathVariables.js';
import { useDispatch, useSelector } from 'react-redux';
import type { State } from '../../../selfservice/common/store.js';

export const PunchoutCheckoutDialog = () => {
  const dispatch = useDispatch();
  const errors = useSelector((state: State) => state.dialog?.errors, deepEqual);
  const isCctUsed = useSelector((state: State) => state.config.featureFlags.cctPunchout);
  const onCloseDialog = () => dispatch(closeDialog());
  const dialog = useSelector((state: State) => state.dialog, deepEqual);

  if (errors && errors.length > 0) {
    const dialogError = errors[0];
    const headerText =
      isCctUsed && dialogError.type === CommonErrorType.SYSTEM
        ? t.V0VJ(dialogSystemErrorHeaderMsg)
        : t.ZK30('Order confirmation failed');

    const pdfLink =
      isCctUsed &&
      dialogError.category === CreditCheckDecision.EMPLOYEE_NEGATIVE_CREDIT_DECISION &&
      t.KA37('https://elisa.fi/attachment/content/Elisa_Yleiset_sopimusehdot_ENG.pdf');

    return (
      <DialogWrapper
        buttons={
          !isCctUsed
            ? [
                {
                  text: t.WOYD(closeMsg),
                  onClick: onCloseDialog,
                },
              ]
            : undefined
        }
        closeable
        header={headerText}
        links={
          isCctUsed
            ? [
                {
                  href: paths.PUNCHOUT_STORE,
                  text: t.PLAW('Return to the shop'),
                },
              ]
            : undefined
        }
        onCloseDialog={onCloseDialog}
      >
        <p>
          {dialogError.message}
          {pdfLink && (
            <>
              <a href={pdfLink} target="_blank" rel="noreferrer">
                {` ${t.Z3L0('More information')}`}
              </a>
            </>
          )}
        </p>
      </DialogWrapper>
    );
  }
  if (dialog?.params?.type) {
    switch (dialog.params.type) {
      case DialogType.GENERIC_INFO_DIALOG:
        return (
          <DialogWrapper
            buttons={[
              {
                onClick: onCloseDialog,
                text: t.WOYD(closeMsg),
              },
            ]}
            header={dialog.params.header}
            onCloseDialog={onCloseDialog}
          >
            <div className={dsClass.PADDING_TOP_4}>{dialog.params.body}</div>
          </DialogWrapper>
        );
    }
  }
  return null;
};
