import * as CL from '@design-system/component-library';
import { AuthenticatedUserRole } from '../../generated/api/models.js';
import { CompanySelector } from '../CompanySelector/CompanySelector.js';
import { HeaderNotification } from '../HeaderNotification/HeaderNotification.js';
import { HeaderShoppingCart } from '../HeaderShoppingCart/HeaderShoppingCart.js';
import { Link, useLocation, useNavigate, useRouteLoaderData } from 'react-router-dom';
import { LoggedInAsEmployeeNotification, getUserAccounts, siteSelect } from './dynamic/headerFunctions.js';
import { SiteContext } from '../../public/site/SiteContext.js';
import {
  chosenLanguageMsg,
  closeMsg,
  englishMsg,
  logInMsg,
  logOutMsg,
  myAccountMsg,
  myInformationMsg,
  searchMsg,
  suomiMsg,
  svenskaMsg,
  t,
} from '../../common/i18n/index.js';
import { consolidatedDudeMenuLinks, personalUserMenuLinks } from './dynamic/personalUserMenuLinks.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { employeeHeaderAccordions } from './dynamic/employeeHeaderAccordions.js';
import { employeeUserMenuLinks } from '../EmployeeHeaderNew/dynamic/employeeUserMenuLinks.js';
import { headerAccordions } from './dynamic/headerAccordions.js';
import { isFeatureEnabled } from '../../common/utils/featureFlagUtils';
import { paths } from '../../common/constants/pathVariables.js';
import { publicNavigationLinks, publicNavigationLinksWithCurrentProperty } from './dynamic/publicNavigationLinks.js';
import { setActiveAccount } from '../../selfservice/actions/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useContext, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userHasApproverRole } from '../../common/utils/employeeUtils.js';
import type * as CLT from '@design-system/component-library';
import type { BreadCrumbNameAndPath } from '../BreadCrumbs/index.js';
import type { ChangeEvent, KeyboardEvent, MouseEvent } from 'react';
import type { PageResponse, SiteStructureNode } from '../../generated/api/models.js';
import type { State } from '../../selfservice/common/store.js';

import './Header.scss';

type HeaderProps = {
  // CMS pages redirect to language-specific landing page on language change,
  // OmaElisa and Employee portal do not.
  // Public site has swedish, OmaElisa and Employee portal do not.
  cmsPage?: boolean;
  isFullVersion?: boolean;
};

export const getBreadCrumbLinks = (
  breadcrumbIds: number[],
  structure: SiteStructureNode[]
): { name: string; path: string }[] => {
  const result: BreadCrumbNameAndPath[] = [];
  breadcrumbIds.forEach(id => {
    return structure
      .filter(elem => id === elem.id)
      .forEach(({ path, name }) => {
        result.push({ path, name });
      });
  });
  return result;
};

export const Header = ({ cmsPage, isFullVersion = true }: HeaderProps) => {
  const { logout, ssoSessionValid } = useAuth();
  const navigate = useNavigate();
  const { siteBaseUrl, siteLanguage, saveUserPreferredLanguage, switchPreferredLanguage } = useContext(SiteContext);
  const { pathname } = useLocation();
  const page = useRouteLoaderData('public-path-root') as PageResponse | undefined;
  const breadcrumbs = page?.breadcrumbs || [];
  const siteStructureNodes = page?.siteStructureNodes || [];
  const dispatch = useDispatch();
  const menuId = 'public-navigation';
  const hamburgerRef = useRef<HTMLElement>(null);

  const { authenticatedUser, anonymousUser } = useAuth();

  const { classicSiteUrl, ringBaseUrl, featureFlags } = useSelector(
    (state: State) => ({
      classicSiteUrl: state.config.classicSiteUrl,
      ringBaseUrl: state.config.ringBaseUrl,
      featureFlags: state.config.featureFlags,
    }),
    deepEqual
  );

  const userAccounts = getUserAccounts(authenticatedUser);
  const isEmployeeUser = authenticatedUser?.userRole === AuthenticatedUserRole.EMPLOYEE;
  const { firstName = '', lastName = '', email = '', companyName = '' } = authenticatedUser || {};
  const isLoggedIn = Boolean(companyName);
  const navigationItemList = publicNavigationLinksWithCurrentProperty(
    publicNavigationLinks(siteBaseUrl),
    getBreadCrumbLinks(breadcrumbs, siteStructureNodes),
    pathname
  );

  const accordions = isEmployeeUser
    ? employeeHeaderAccordions
    : headerAccordions(navigate, ssoSessionValid, ringBaseUrl);
  const hasApproverRole = userHasApproverRole(authenticatedUser);
  const userMenuLinks = isFeatureEnabled(featureFlags.consolidatedViews)
    ? consolidatedDudeMenuLinks(navigate, classicSiteUrl)
    : personalUserMenuLinks(navigate, classicSiteUrl);
  // only show self-service home link when MFA is not completed
  const filteredPersonalUserMenuLinks =
    anonymousUser?.mfaInfo && !companyName
      ? userMenuLinks?.filter(link => link.url === paths.SELF_SERVICE_HOME)
      : userMenuLinks;
  const accountLink = isFeatureEnabled(featureFlags.consolidatedViews)
    ? [
        <Link key="myAccountLink" to={`${classicSiteUrl}/omat-tiedot`}>
          {t.SEBD(myAccountMsg)}
        </Link>,
      ]
    : [];
  const personalLinks = isEmployeeUser
    ? employeeUserMenuLinks(navigate, hasApproverRole, true)
    : filteredPersonalUserMenuLinks;
  const fullName = `${firstName} ${lastName}`;
  const shouldShowLoggedInAsEmployeeNotification = isEmployeeUser && pathname !== paths.SHOPPING_CART;

  const languageSelect = {
    languages: cmsPage
      ? [
          { name: suomiMsg, abbr: 'fi' },
          { name: svenskaMsg, abbr: 'sv' },
          { name: englishMsg, abbr: 'en' },
        ]
      : [
          { name: suomiMsg, abbr: 'fi' },
          { name: englishMsg, abbr: 'en' },
        ],
    selectedLanguage: siteLanguage.substring(0, 2),
    onLanguageChange: ({ lang }: { lang: string }) => {
      if (cmsPage) {
        switchPreferredLanguage(lang);
      } else {
        saveUserPreferredLanguage(lang);
      }
    },
  };

  // placeholder user until MFA is completed, to avoid null 'fullName' error on logout
  const loggedOutUser =
    !authenticatedUser && anonymousUser?.mfaInfo ? { fullName: '', email: '', name: '' } : undefined;
  const adminUserNameToShow = isFeatureEnabled(featureFlags.consolidatedViews) ? firstName : companyName;

  return (
    <>
      <div className="of-public-header-wrapper">
        <CL.Header
          className="of-public-header"
          desktopBreakpointOffset={35} // update the breakpoint for of-public-header-wrapper as well if you change this
          isLoggedIn={isLoggedIn || anonymousUser?.mfaInfo !== undefined}
          languageSelect={isFullVersion ? languageSelect : undefined}
          siteSelect={siteSelect(siteBaseUrl)}
        >
          <CL.HeaderNavigationSection>
            <CL.HeaderLogoAndService
              logo={{
                label: 'Elisa',
                onClick: e => {
                  e.preventDefault();
                  navigate('/');
                },
                url: '/',
              }}
            />

            {isFullVersion && (
              <CL.HeaderNavigation
                id={menuId}
                languageSelect={languageSelect}
                navigationItemList={navigationItemList}
                onButtonClick={() => {}}
                onLinkClick={(e, { url }) => {
                  const isAbsolute = new RegExp('^([a-z]+://|//)', 'i');
                  if (url && !isAbsolute.test(url)) {
                    e.preventDefault();
                    navigate(url);
                  }
                }}
                requireLogin={false}
                returnFocusToRef={hamburgerRef}
                siteSelect={siteSelect(siteBaseUrl)}
              />
            )}
          </CL.HeaderNavigationSection>
          <CL.HeaderNavigationSection>
            {isFullVersion && (
              <>
                <CL.HeaderSearchButton
                  href={paths.SEARCH_PUBLIC_PAGES}
                  i18nLabel={t.V4GK(searchMsg)}
                  onClick={e => {
                    e.preventDefault();
                    navigate(paths.SEARCH_PUBLIC_PAGES);
                  }}
                />
                <HeaderShoppingCart toShoppingCart={paths.SHOPPING_CART} toCheckout={paths.DEVICE_CHECKOUT} />
              </>
            )}
            <CL.HeaderUserMenu
              i18nButtonLabel={isLoggedIn ? t.MIFJ(myInformationMsg) : t.Z16I(logInMsg)}
              i18nLanguageSwitchSelectedLanguageLabel={t.FT4V(chosenLanguageMsg)}
              i18nLogin={t.Z16I(logInMsg)}
              logout={{ onClick: () => logout(paths.SELF_SERVICE_LOGOUT), title: t.LQ3X(logOutMsg) }}
              notifications={personalLinks}
              personalLinks={
                authenticatedUser && userAccounts.length > 0 && !isEmployeeUser
                  ? [
                      ...accountLink,
                      <CompanySelector
                        id="userMenuCompanySelector"
                        key="headerCompanySelector"
                        onInputChange={(
                          _e: ChangeEvent | KeyboardEvent | MouseEvent,
                          option: Partial<CLT.HeaderUserAccount>
                        ) => {
                          if (option?.accountMasterId) {
                            dispatch(setActiveAccount(option.accountMasterId, true));
                          }
                        }}
                        userAccounts={userAccounts}
                      />,
                    ]
                  : []
              }
              user={
                authenticatedUser
                  ? {
                      email,
                      fullName,
                      name: isEmployeeUser ? fullName : adminUserNameToShow,
                    }
                  : loggedOutUser
              }
              userPronounText={firstName}
              userMenuLinkAccordions={accordions}
            />
            {isFullVersion && (
              <CL.HeaderHamburger
                i18nCloseAlt={t.WOYD(closeMsg)}
                i18nOpenAlt="menu"
                ref={hamburgerRef}
                toggledModalChild={menuId}
              />
            )}
          </CL.HeaderNavigationSection>
        </CL.Header>
      </div>
      <HeaderNotification />
      {shouldShowLoggedInAsEmployeeNotification && LoggedInAsEmployeeNotification}
    </>
  );
};
