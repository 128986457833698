import * as CL from '@design-system/component-library';
import { DeliveryOrder } from '../../generated/api/models.js';
import { DeliveryOrdersComparator, getDeliveryOrderStatusInfo } from './CustomerOrderDetailsUtil.js';
import { Link } from 'react-router-dom';
import { StatusColumn } from '../Table/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { getEmployeePeriodicPriceAsText, getPriceAsText } from '../SubscriptionDetails/subscriptionDetailsCommon.js';
import { monthlyChargesMsg, oneTimePaymentMsg, productsMsg, statusMsg, t } from '../../common/i18n/index.js';
import type { CustomerOrder } from '../../generated/api/models.js';

interface CustomerOrderOrderedProductsListParams {
  buildDeliveryOrderHref: (cid: string, did: string) => string;
  customerOrder: CustomerOrder;
  isEmployee: boolean;
  mdmId?: string;
}

enum DeliveryOrderFields {
  DELIVERY_ORDER_PRODUCT = 'deliveryOrderProduct',
  DELIVERY_ORDER_RECURRING_PRICE = 'created',
  DELIVERY_ORDER_ONE_TIME_PRICE = 'deliveryOrderContactNames',
  DELIVERY_ORDER_STATE = 'deliveryOrders',
}

const columns: CL.Column[] = [
  {
    key: DeliveryOrderFields.DELIVERY_ORDER_PRODUCT,
    label: t.IY4Z(productsMsg),
  },
  {
    key: DeliveryOrderFields.DELIVERY_ORDER_RECURRING_PRICE,
    label: t.P6BC(monthlyChargesMsg),
  },
  {
    key: DeliveryOrderFields.DELIVERY_ORDER_ONE_TIME_PRICE,
    label: t.GOBY(oneTimePaymentMsg),
  },
  {
    key: DeliveryOrderFields.DELIVERY_ORDER_STATE,
    label: t.ASQT(statusMsg),
  },
];

const employeeColumns: CL.Column[] = [
  {
    key: DeliveryOrderFields.DELIVERY_ORDER_PRODUCT,
    label: t.IY4Z(productsMsg),
  },
  {
    key: DeliveryOrderFields.DELIVERY_ORDER_RECURRING_PRICE,
    label: t.P6BC(monthlyChargesMsg),
  },
  {
    key: DeliveryOrderFields.DELIVERY_ORDER_STATE,
    label: t.ASQT(statusMsg),
  },
];

const OrderedProductsTable = (props: CustomerOrderOrderedProductsListParams) => {
  const { isEmployee, customerOrder, buildDeliveryOrderHref, mdmId } = props;
  const tableRows = customerOrder.deliveryOrders.sort(DeliveryOrdersComparator).map(deliveryOrder => ({
    [DeliveryOrderFields.DELIVERY_ORDER_PRODUCT]:
      deliveryOrder.productType === DeliveryOrder.ProductTypeEnum.DELIVERY_FEE ? (
        <div className={dsClass.COLOR_BLUE_600}>{deliveryOrder.deliveryOrderName}</div>
      ) : (
        <Link
          to={
            buildDeliveryOrderHref(customerOrder.customerOrderDisplayId, deliveryOrder.deliveryOrderDisplayId) +
            (mdmId ? `?mdmId=${mdmId}` : '')
          }
        >
          <div className={dsClass.COLOR_BLUE_600}>{deliveryOrder.deliveryOrderName}</div>
          {deliveryOrder.replacedSubscriptionDisplayId && (
            <div className={`${dsClass.TEXT_S} ${dsClass.COLOR_BLACK}`}>{t.OL3F('Replacement')}</div>
          )}
        </Link>
      ),
    [DeliveryOrderFields.DELIVERY_ORDER_RECURRING_PRICE]: (
      <>
        {isEmployee
          ? getEmployeePeriodicPriceAsText(deliveryOrder.employeeMonthlyRecurringCharge)
          : getPriceAsText(
              deliveryOrder.monthlyRecurringCharge,
              deliveryOrder.monthlyRecurringListCharge,
              true,
              '',
              deliveryOrder.billingPeriod
            ) || '—'}
      </>
    ),
    [DeliveryOrderFields.DELIVERY_ORDER_ONE_TIME_PRICE]: (
      <>{getPriceAsText(deliveryOrder.oneTimeCharge, deliveryOrder.oneTimeListCharge) || '—'}</>
    ),
    [DeliveryOrderFields.DELIVERY_ORDER_STATE]: (
      <StatusColumn
        status={getDeliveryOrderStatusInfo(deliveryOrder.status).text}
        color={getDeliveryOrderStatusInfo(deliveryOrder.status).color}
      />
    ),
    active: !!deliveryOrder.replacedSubscriptionDisplayId,
  }));

  return (
    <div className="of-table-wrapper">
      <CL.Table
        columns={isEmployee ? employeeColumns : columns}
        hideHeadersOnMobile={false}
        rows={tableRows || []}
        tableType="bordered"
        hover={true}
      />
    </div>
  );
};

export const CustomerOrderOrderedProductsList = (props: CustomerOrderOrderedProductsListParams) => (
  <>
    <h3 className={`${dsClass.MARGIN_BOTTOM_2} ${dsClass.MARGIN_TOP_4}`}>{t.TBES('Ordered products')}</h3>
    <OrderedProductsTable {...props} />
  </>
);
