import { createElement } from 'react';

interface RenderHtmlBlockProps {
  type: string;
  className: string;
  children: string;
}

export const RenderHtmlBlock = ({ type, className, children }: RenderHtmlBlockProps): JSX.Element => {
  return createElement(type, {
    className,
    dangerouslySetInnerHTML: { __html: children }, // eslint-disable-line @typescript-eslint/naming-convention
  });
};
