export enum EditingSection {
  NONE = 'NONE',
  DETAILS = 'DETAILS',
  SCHEDULE = 'SCHEDULE',
}

export enum BillingAccountVersion {
  ACTIVE = 'ACTIVE',
  SCHEDULED = 'SCHEDULED',
}
