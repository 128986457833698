import { TypeKeys } from '../actions/index.js';
import { actionToActionState } from './epicUtils.js';
import { callUiApi, prepareUiApiRequest } from '../common/uiApiUtils.js';
import { combineEpics, ofType } from 'redux-observable';
import { concatMap } from 'rxjs/operators';
import {
  getSubscriptionLocalUsageFailed,
  getSubscriptionLocalUsageFulfilled,
  getSubscriptionRoamingUsageFailed,
  getSubscriptionRoamingUsageFulfilled,
  getSubscriptionTextMessagesFailed,
  getSubscriptionTextMessagesFulfilled,
  getSubscriptionVoiceCallsFailed,
  getSubscriptionVoiceCallsFulfilled,
} from '../actions/employeeSubscriptionUsageActions.js';
import type { Action } from 'redux';
import type { ActionAndState, EpicDependencies } from './epicUtils.js';
import type { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import type {
  GetSubscriptionLocalUsageAction,
  GetSubscriptionRoamingUsageAction,
  GetSubscriptionTextMessagesAction,
  GetSubscriptionVoiceCallsAction,
} from '../actions/employeeSubscriptionUsageActions.js';
import type { SelfServiceActionTypes } from '../actions/index.js';
import type { State } from '../common/store.js';

import {
  getLocalUsagePrivateMethod,
  getRoamingUsagePrivateMethod,
  getTextMessagesPrivateMethod,
  getVoiceCallsPrivateMethod,
} from '../../generated/api/uiApiMethods.js';
import type {
  LocalUsageResponse,
  RoamingUsageResponse,
  TextMessagesResponse,
  VoiceCallsResponse,
} from '../../generated/api/models.js';

export const getSubscriptionLocalUsageEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(
    action$.pipe(ofType(TypeKeys.GET_SUBSCRIPTION_LOCAL_USAGE)),
    (action: GetSubscriptionLocalUsageAction) => actionToActionState(action, state$, 'authenticated')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const { subscriptionId } = actionAndState.action as GetSubscriptionLocalUsageAction;
      if (!actionAndState.state && !subscriptionId) {
        throw new Error('invalid action state to get local usage , subscriptionId is missing');
      }
      return callUiApi({
        epicDependencies,
        state$,
        method: getLocalUsagePrivateMethod(subscriptionId),
        successAction: (response: LocalUsageResponse) => getSubscriptionLocalUsageFulfilled(subscriptionId, response),
        failureAction: getSubscriptionLocalUsageFailed,
      });
    })
  );

export const getSubscriptionRoamingUsageEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(
    action$.pipe(ofType(TypeKeys.GET_SUBSCRIPTION_ROAMING_USAGE)),
    (action: GetSubscriptionRoamingUsageAction) => actionToActionState(action, state$, 'authenticated')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const { subscriptionId } = actionAndState.action as GetSubscriptionRoamingUsageAction;
      if (!actionAndState.state && !subscriptionId) {
        throw new Error('invalid action state to get roaming usage, subscriptionId is missing');
      }
      return callUiApi({
        epicDependencies,
        state$,
        method: getRoamingUsagePrivateMethod(subscriptionId),
        successAction: (response: RoamingUsageResponse) =>
          getSubscriptionRoamingUsageFulfilled(subscriptionId, response),
        failureAction: getSubscriptionRoamingUsageFailed,
      });
    })
  );

export const getSubscriptionVoiceCallsEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(
    action$.pipe(ofType(TypeKeys.GET_SUBSCRIPTION_VOICE_CALLS)),
    (action: GetSubscriptionVoiceCallsAction) => actionToActionState(action, state$, 'authenticated')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const { subscriptionId } = actionAndState.action as GetSubscriptionVoiceCallsAction;
      if (!actionAndState.state && !subscriptionId) {
        throw new Error('invalid action state to get voice calls, subscriptionId is missing');
      }
      return callUiApi({
        epicDependencies,
        state$,
        method: getVoiceCallsPrivateMethod(subscriptionId),
        successAction: (response: VoiceCallsResponse) => getSubscriptionVoiceCallsFulfilled(subscriptionId, response),
        failureAction: getSubscriptionVoiceCallsFailed,
      });
    })
  );

export const getSubscriptionTextMessagesEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(
    action$.pipe(ofType(TypeKeys.GET_SUBSCRIPTION_TEXT_MESSAGES)),
    (action: GetSubscriptionTextMessagesAction) => actionToActionState(action, state$, 'authenticated')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const { subscriptionId } = actionAndState.action as GetSubscriptionTextMessagesAction;
      if (!actionAndState.state && !subscriptionId) {
        throw new Error('invalid action state to get text messages, subscriptionId is missing');
      }
      return callUiApi({
        epicDependencies,
        state$,
        method: getTextMessagesPrivateMethod(subscriptionId),
        successAction: (response: TextMessagesResponse) =>
          getSubscriptionTextMessagesFulfilled(subscriptionId, response),
        failureAction: getSubscriptionTextMessagesFailed,
      });
    })
  );

export const employeeSubscriptionUsageEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> =
  combineEpics(
    getSubscriptionLocalUsageEpic,
    getSubscriptionRoamingUsageEpic,
    getSubscriptionVoiceCallsEpic,
    getSubscriptionTextMessagesEpic
  );
