import classNames from 'classnames';

import './CompositeListTop.scss';

export interface CompositeListTopProps {
  left?: JSX.Element | JSX.Element[];
  right?: JSX.Element | JSX.Element[];
  rightHideOnMobile?: boolean;
  classes?: string[];
  wrapOnMobile?: boolean;
}

export const CompositeListTop = ({
  classes,
  left,
  right,
  rightHideOnMobile,
  wrapOnMobile,
}: CompositeListTopProps): JSX.Element | null => {
  if (!left && !right) {
    return null;
  }
  return (
    <div
      className={classNames(
        'ea-composite-list-top',
        rightHideOnMobile && 'ea-composite-list-top--right-hide-on-mobile',
        wrapOnMobile && 'ea-composite-list-top--wrap-on-mobile',
        classes
      )}
    >
      {left ? <div className="ea-composite-list-top__left ea-composite-list-top__column">{left}</div> : <div />}
      {right && (
        <div
          className={classNames(
            'ea-composite-list-top__right ea-composite-list-top__column',
            rightHideOnMobile && 'ea-hide--phone-large-and-down'
          )}
        >
          {right}
        </div>
      )}
    </div>
  );
};
