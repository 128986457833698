import { CostCenter } from '../fields/CostCenter';
import { Email, Name, PhoneNumber } from '../fields';
import { FormGridFieldset } from '../FormGridFieldset/FormGridFieldset';
import { Reference } from '../fields/Reference';
import { costCenterToolTipMsg, firstNameMsg, lastNameMsg, referenceToolTipMsg, t } from '../../i18n';

export interface ContactFieldsetValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  costCenter: string;
  employeeNumber: string;
}

interface ContactFieldsetProps {
  readonly: boolean;
}

export const ContactFieldset = ({ readonly }: ContactFieldsetProps) => {
  const placeholder = readonly ? '-' : '';
  return (
    <FormGridFieldset>
      <Name name="firstName" label={t.AIK7(firstNameMsg)} placeholder={placeholder} readonly={readonly} />
      <Name name="lastName" label={t.Y41S(lastNameMsg)} placeholder={placeholder} readonly={readonly} />
      <PhoneNumber label={t.V9K9('Mobile phone number (work)')} placeholder={placeholder} readonly={readonly} />
      <Email label={t.JYZO('Email (work)')} placeholder={placeholder} readonly={readonly} />
      <CostCenter tooltip={`${t.UE8R(costCenterToolTipMsg)}`} placeholder={placeholder} readonly={readonly} />
      <Reference tooltip={t.HBBD(referenceToolTipMsg)} placeholder={placeholder} readonly={readonly} />
    </FormGridFieldset>
  );
};
