import { Locale, getLocale, setSiteLocale } from '../../../common/i18n/index.js';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useOpenFormSearchParameters = (): URLSearchParams => {
  const [search, setSearch] = useSearchParams();

  // Only Finnish supported in the first version
  useEffect(() => {
    switch (search.get('locale')?.substring(0, 2).toUpperCase()) {
      default: {
        getLocale() !== Locale.FI && setSiteLocale(Locale.FI);
        break;
      }
    }
    if (search.has('locale')) {
      search.delete('locale');
      setSearch(search);
    }
  }, [search, setSearch]);

  return search;
};
