import * as CL from '@design-system/component-library';
import { formatTimeStampToYYYYMMDDHHmm } from '../../common/utils/dateUtils.js';
import { getStepperDisplayDataForStatus } from '../../common/utils/supportCaseUtils.js';
import type * as CLT from '@design-system/component-library';
import type { SupportCaseHistory } from '../../generated/api/models.js';

import './CaseHistory.scss';

export interface CaseHistoryProps {
  supportCaseHistory: SupportCaseHistory[];
  supportCaseCreatedAt: string;
}

interface SupportCaseStepperItem {
  stepperItem: CLT.StepperItem;
  status: string;
}

export const CaseHistory = ({ supportCaseHistory, supportCaseCreatedAt }: CaseHistoryProps) => {
  const stepsFromApi: SupportCaseStepperItem[] = supportCaseHistory.map((caseHistory, index) => ({
    stepperItem: {
      id: `stepperItem${index}`,
      name: getStepperDisplayDataForStatus(caseHistory.newValue).name,
      description: getStepperDisplayDataForStatus(caseHistory.newValue).description,
      time: formatTimeStampToYYYYMMDDHHmm(caseHistory.modifiedDate),
      completed: false,
      emptyMarker: true,
      stepType: 'sub',
      icon: <CL.Icon icon="record" />,
    },
    status: caseHistory.newValue,
  }));

  const initialSteps: SupportCaseStepperItem[] = [
    {
      stepperItem: {
        id: 'stepperInitialItem',
        name: getStepperDisplayDataForStatus('Case Created').name,
        description: getStepperDisplayDataForStatus('Case Created').description,
        time: supportCaseCreatedAt,
        icon: <CL.Icon icon="record" />,
        completed: false,
        emptyMarker: true,
        stepType: 'sub',
      },
      status: 'Case Created',
    },
  ];

  const steps = [...initialSteps, ...stepsFromApi];

  // Show description only for last step item
  steps.forEach((step, index) => {
    if (!Object.is(steps.length - 1, index)) {
      step.stepperItem.description = undefined;
    }
  });

  const lastStep = steps?.slice(-1).pop();
  const lastStepDisplayData = getStepperDisplayDataForStatus(lastStep?.status || '');

  if (lastStep?.stepperItem) {
    lastStep.stepperItem.stepType = undefined;
    if (['resolved', 'closed'].includes(lastStep.status.toLowerCase())) {
      lastStep.stepperItem.completed = true;
    } else {
      lastStep.stepperItem.icon = <CL.Icon icon={lastStepDisplayData.icon || ''} type="filled" />;
    }
  }

  return (
    <div className={`case-history ${lastStepDisplayData.lastStatusStyle}`}>
      <CL.Stepper steps={steps.map(item => item.stepperItem)} />
    </div>
  );
};
