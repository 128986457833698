import * as CL from '@design-system/component-library';
import { Picture } from '../../Picture/Picture.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { formatSum } from '../../../common/utils/priceUtils.js';
import { monthMsg, t } from '../../../common/i18n/index.js';
import type { ShoppingCartItemForCheckout } from '../../../common/types/checkout.js';

import './PunchoutProducts.scss';

type PunchoutProductsProps = {
  cartItems: ShoppingCartItemForCheckout[];
};

interface Product {
  id: string;
  name: string;
  recurringPrice?: string;
  singlePrice?: string;
  quantity: number;
  image?: string;
  children?: ChildProduct[];
}

interface ChildProduct {
  id: string;
  name: string;
  recurringPrice?: string;
  singlePrice?: string;
}

const createShoppingCartId = (item: ShoppingCartItemForCheckout) =>
  `${item.offerCode}:${item.commercialProductCodes[0]}`;

const toCartProduct = (cartItem: ShoppingCartItemForCheckout): Product => ({
  id: createShoppingCartId(cartItem),
  name: cartItem.productName,
  image: cartItem.imageListingUrl,
  singlePrice: cartItem.price.onetime && formatSum(cartItem.price.onetime?.price * cartItem.quantity),
  recurringPrice:
    cartItem.price.periodic && `${formatSum(cartItem.price.periodic?.price * cartItem.quantity)}/${t.XXVX(monthMsg)}`,
  quantity: cartItem.quantity,
  children: cartItem.selectedAddOns.map(addOn => ({
    id: addOn.addOnCode,
    name: addOn.addOnProductName,
    singlePrice:
      addOn.addOnOneTimeCharge !== undefined ? formatSum(addOn.addOnOneTimeCharge * cartItem.quantity) : undefined,
    recurringPrice: addOn.addOnMonthlyRecurringCharge
      ? `${formatSum(addOn.addOnMonthlyRecurringCharge * cartItem.quantity)}/${t.XXVX(monthMsg)}`
      : undefined,
  })),
});

export const PunchoutProducts = ({ cartItems }: PunchoutProductsProps) => {
  return (
    <div className="of-punchout-checkout-products">
      <h2 className={`of-sub-header ${dsClass.H2}`}>{t.OT3E('Products ordered')}</h2>
      {cartItems.map(toCartProduct).map((product, index) => (
        <CL.Grid className={dsClass.CART_CONTENT_PRODUCT} key={'product_' + index} id={'product_' + index}>
          <CL.GridRow>
            <CL.GridCol colsXS={4} colsS={2} colsM={1} className={dsClass.CARD_PRODUCT_IMAGE}>
              {product.image ? (
                <Picture
                  src={product.image}
                  alt={product.name}
                  offerWidthAlternatives={[216]}
                  renderedImageSize={{ onPhone: '100vw' }}
                />
              ) : null}
            </CL.GridCol>
            <CL.GridCol colsXS={4} colsS={4} colsM={5}>
              <CL.Grid className={dsClass.CART_PRODUCT_DATA}>
                <CL.GridRow>
                  <CL.GridCol colsXS={4} colsS={6} colsM={4}>
                    <p className={dsClass.CART_PRODUCT_NAME}>{product.name}</p>
                  </CL.GridCol>
                  <CL.GridCol colsXS={4} colsS={6} colsM={1} className={dsClass.CART_PRODUCT_QUANTITY}>
                    <p>{`${product.quantity}${t.UDN6('{} pcs', '')}`}</p>
                  </CL.GridCol>
                  <CL.GridCol colsXS={4} colsS={6} colsM={1} className={dsClass.CARD_PRODUCT_PRICE}>
                    {product.recurringPrice ? (
                      <p className={dsClass.CART_PRODUCT_RECURRINGPRICE}>{product.recurringPrice}</p>
                    ) : null}
                    {product.singlePrice ? (
                      <p className={dsClass.CART_PRODUCT_SINGLEPRICE}>{product.singlePrice}</p>
                    ) : null}
                  </CL.GridCol>
                </CL.GridRow>

                {product.children?.map(child => (
                  <CL.GridRow
                    key={'product_' + child.id}
                    id={'product_' + child.id}
                    className={dsClass.CART_CONTENT_CHILDPRODUCT}
                  >
                    <CL.GridCol colsXS={4} colsS={6} colsM={5}>
                      <p className={dsClass.CART_PRODUCT_NAME}>{child.name}</p>
                    </CL.GridCol>
                    <CL.GridCol colsXS={4} colsS={6} colsM={1} className={dsClass.CARD_PRODUCT_PRICE}>
                      {child.recurringPrice ? (
                        <p className={dsClass.CART_PRODUCT_RECURRINGPRICE}>{child.recurringPrice}</p>
                      ) : null}
                      {child.singlePrice ? (
                        <p className={dsClass.CART_PRODUCT_SINGLEPRICE}>{child.singlePrice}</p>
                      ) : null}
                    </CL.GridCol>
                  </CL.GridRow>
                ))}
              </CL.Grid>
            </CL.GridCol>
          </CL.GridRow>
        </CL.Grid>
      ))}
    </div>
  );
};
