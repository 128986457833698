import * as CL from '@design-system/component-library';
import { Anchor } from '../Anchor/Anchor.js';
import { ModalCloseAndSubmitButtons } from '../Modal/ModalCloseAndSubmitButtons.js';
import { Password } from '../../common/react-hook-form/fields/Password.js';
import { continueMsg, t } from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { updateForgotPasswordStepNumber, validateOtp } from '../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import type { ForgetPasswordWithForm } from './misc/forgotPasswordInterfaces.js';

export const ForgotPasswordEnterOnetimePasswordStep = (props: ForgetPasswordWithForm) => {
  const dispatch = useDispatch();
  const { methods } = props;

  const onSubmit = ({ otp, userName }: { otp: string; userName: string }) => {
    dispatch(
      validateOtp({
        otp,
        userName,
      })
    );
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
      <div className="of-forgot-password-step__header">
        <h3 className={dsClass.H3_BOOK}>{t.GE17('Forgotten Password')}</h3>
      </div>

      <p>{t.KUKS('One-time password was sent to your phone number. Please use it when signing in to the service.')}</p>

      <Password name="otp" type="text" />

      {props.anonymousUser.forgotPasswordErrored && (
        <CL.Notification hasCloseButton={false} text={t.ZK2S('Please check your password')} type="warning" visible />
      )}

      <ModalCloseAndSubmitButtons
        onCancel={props.closeModal}
        onSubmit={() => {}}
        submitButtonText={t.I62A(continueMsg)}
        submitting={props.anonymousUser.forgotPasswordInProgress}
      />
      <Anchor
        className="link"
        onClick={() => {
          dispatch(updateForgotPasswordStepNumber(1));
        }}
      >
        {t.UCAJ('Resend one-time password')}
      </Anchor>
    </form>
  );
};
