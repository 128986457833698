import { FormProvider } from 'react-hook-form';
import { OpenFormAsync } from './OpenFormAsync.js';
import { type OpenFormDispatch, OpenFormReducer, type OpenFormState } from './OpenFormReducer.js';
import { OpenFormStorage } from './OpenFormStorage.js';
import { type ReactNode, createContext, useContext, useEffect, useMemo } from 'react';
import { useOpenFormMethods } from './OpenFormHooks/useOpenFormMethods.js';
import { useOpenFormSearchParameters } from './OpenFormHooks/useOpenFormSearchParameters.js';
import { useRefReducer } from './OpenFormHooks/useRefReducer.js';

const OpenFormContextAsync = createContext(OpenFormAsync);
const OpenFormContextDispatch = createContext({} as OpenFormDispatch);
const OpenFormContextSearchParams = createContext({} as URLSearchParams);
const OpenFormContextState = createContext({} as OpenFormState);

export const useOpenFormAsync = () => useContext(OpenFormContextAsync);
export const useOpenFormDispatch = () => useContext(OpenFormContextDispatch);
export const useOpenFormSearchParams = () => useContext(OpenFormContextSearchParams);
export const useOpenFormState = () => useContext(OpenFormContextState);

export const OpenFormProvider = ({ children }: { children?: ReactNode }) => {
  const storage = useMemo(() => new OpenFormStorage(typeof sessionStorage !== 'undefined' ? sessionStorage : null), []);
  const [state, dispatch] = useRefReducer(OpenFormReducer.hook(storage), OpenFormReducer.state());

  useEffect(() => {
    OpenFormAsync.actions = OpenFormReducer.actions(dispatch);
    OpenFormAsync.state = state;
    OpenFormAsync.storage = storage;
  }, [storage, state, dispatch]);

  return (
    <OpenFormContextSearchParams.Provider value={useOpenFormSearchParameters()}>
      <OpenFormContextState.Provider value={state.current}>
        <OpenFormContextDispatch.Provider value={dispatch}>
          <OpenFormContextAsync.Provider value={OpenFormAsync}>
            <FormProvider {...useOpenFormMethods(state.current, dispatch)}>{children}</FormProvider>
          </OpenFormContextAsync.Provider>
        </OpenFormContextDispatch.Provider>
      </OpenFormContextState.Provider>
    </OpenFormContextSearchParams.Provider>
  );
};
