import * as CL from '@design-system/component-library';
import type { ReactNode } from 'react';

export interface GridColumnProps {
  className?: string;
  key?: string;
  children?: ReactNode;
}

export const GridColumn = (props: GridColumnProps) => <CL.GridCol colsS={6} colsM={3} colsL={6} {...props} />;
