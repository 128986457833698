import * as CL from '@design-system/component-library';
import { pictureStorageBaseUrl } from '../Picture/contentfulImageProxy';
import type { PropsWithChildren, ReactNode } from 'react';

import './DetailsWithCard.scss';

export type DetailsAndCardProps = PropsWithChildren<{
  cardId?: string;
  cardImage: string;
  cardContent: ReactNode;
}>;

export const DetailsWithCard = ({ children, cardId, cardImage, cardContent }: DetailsAndCardProps) => (
  <div className="of-details-with-card">
    <div className="of-details-with-card__details">{children}</div>
    <CL.Card
      id={cardId}
      heading={<img alt="" src={`${pictureStorageBaseUrl}${cardImage}`} />}
      content={cardContent}
      type="dark"
    />
  </div>
);
