import { allPricesVatZeroMsg, monthlyChargesMsg, oneTimePaymentMsg, t, totalMsg } from '../../../common/i18n/index.js';
import { createPrices } from '../CustomFormFields.js';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import type { CustomFormProps } from '../CustomForm.js';

export const PriceSummary = ({ initialValues }: CustomFormProps) => {
  const { register, setValue, watch } = useFormContext();

  useEffect(() => {
    const prices = createPrices(initialValues.periodicPriceMap!, initialValues.onetimePriceMap!);
    setValue('periodicPriceMap', initialValues.periodicPriceMap);
    setValue('onetimePriceMap', initialValues.onetimePriceMap);
    setValue('periodicPrice', prices.periodicPrice);
    setValue('onetimePrice', prices.onetimePrice);
  }, [initialValues.onetimePriceMap, initialValues.periodicPriceMap, setValue]);

  const periodicPriceValue = watch('periodicPrice');
  const onetimePriceValue = watch('onetimePrice');

  return (
    <div className="of-custom-form__prices">
      <input type="hidden" {...register('periodicPriceMap')} />
      <input type="hidden" {...register('onetimePriceMap')} />
      <input type="hidden" {...register('periodicPrice')} />
      <input type="hidden" {...register('onetimePrice')} />
      <h3 className="of-custom-form__prices-title">{t.CEQ2(totalMsg)}</h3>
      <p className="of-custom-form__prices-content">
        <span>{t.P6BC(monthlyChargesMsg)}</span>
        <span>{periodicPriceValue}</span>
      </p>
      <p className="of-custom-form__prices-content">
        <span>{t.GOBY(oneTimePaymentMsg)}</span>
        <span>{onetimePriceValue}</span>
      </p>
      <p className="of-custom-form__prices-disclaimer">
        <span className="disclaimer-text">{t.TEA3(allPricesVatZeroMsg)}</span>
      </p>
    </div>
  );
};
