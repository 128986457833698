import { SalesType } from '../../generated/api/models.js';
import type { CampaignAssociation, CampaignContext, CampaignPromotion, Offer } from '../../generated/api/models.js';

interface CampaignAssociationAndPromotion {
  campaignAssociation: CampaignAssociation;
  campaignPromotion: CampaignPromotion;
  voucher?: string;
}

export const getCampaignDescription = (
  campaigns: CampaignPromotion[],
  campaignPromotionCode?: string
): string | undefined => {
  if (!campaignPromotionCode) {
    return undefined;
  }
  return campaigns.find(campaign => campaign.campaignPromotionCode === campaignPromotionCode)?.description;
};

// only one campaignAssociation will be available on a commercialProduct at a time,
// based  on online effective dates and priority

/**
 * Filters campaignAssociations by given SalesType.
 * Products can have campaigns with different prioritizations in Salesforce and if campaign is added to both salesTypes with same prioritization salesType will be BOTH.
 * To find first prioritized campaign and after that common campaign for both, we need to first look campaigns with specific salesType (NEW_SALE, CHANGE_OFFER).
 */
export const getFilteredCampaignAssociationsBySalesType = (
  campaignAssociations: CampaignAssociation[],
  salesType: SalesType
) => {
  const campaignAssociationsForGivenSalesType = campaignAssociations.filter(ca => ca.salesType === salesType);
  const campaignAssociationsForBothSalesType = campaignAssociations.filter(ca => ca.salesType === SalesType.BOTH);

  return campaignAssociationsForGivenSalesType.length > 0
    ? campaignAssociationsForGivenSalesType
    : campaignAssociationsForBothSalesType;
};

export const isCampaignDateValid = ({ expirationDate, effectiveDate }: CampaignAssociation): boolean => {
  const currentDate = Date.now();
  return Boolean(effectiveDate && expirationDate && effectiveDate <= currentDate && currentDate <= expirationDate);
};
export const validCampaignsPresent = (campaignContexts?: CampaignContext[]) => {
  return campaignContexts?.some(cc => cc.campaignAssociations.some(ca => isCampaignDateValid(ca)));
};
export const isCampaignPresent = (campaignAssociations: CampaignAssociation[] | undefined): boolean =>
  Boolean(campaignAssociations?.[0] && isCampaignDateValid(campaignAssociations[0]));

export const getOfferCampaignAssociation = (
  offer: Offer,
  salesType: SalesType,
  campaignContexts: CampaignContext[] = [],
  voucher?: string
): { campaignAssociation: CampaignAssociation; voucher: string | undefined } | undefined => {
  const commercialProduct = offer.commercialProducts[0];
  const campaignAssociations = commercialProduct?.campaignAssociations || [];

  const campaignsFromProducts = getFilteredCampaignAssociationsBySalesType(campaignAssociations, salesType).map(
    campaignAssociation => ({
      campaignAssociation,
      voucher: undefined,
    })
  );

  const campaignsFromContext = getFilteredCampaignAssociationsBySalesType(
    campaignContexts
      .filter(ctx => ctx.commercialProductCode === commercialProduct.commercialProductCode)
      .flatMap(campaignContext => campaignContext.campaignAssociations),
    salesType
  ).map(campaignAssociation => ({ campaignAssociation, voucher }));

  const allCampaignAssociations = [...campaignsFromProducts, ...campaignsFromContext];
  return allCampaignAssociations
    .sort((c1, c2) => c2.campaignAssociation.monthlyRecurringCharge! - c1.campaignAssociation.monthlyRecurringCharge!)
    .find(campaignAssociationWithVoucher => isCampaignDateValid(campaignAssociationWithVoucher.campaignAssociation));
};

// This only returns one campaign. If there are multiple campaigns then the one with the highest discount will be selected
export const getOfferCampaignAssociationAndPromotion = (
  offer: Offer,
  salesType: SalesType,
  campaignContexts: CampaignContext[] = [],
  voucher?: string,
  campaignsPromotions: CampaignPromotion[] = []
): CampaignAssociationAndPromotion | undefined => {
  const bestCampaignAssociationWithVoucher = getOfferCampaignAssociation(offer, salesType, campaignContexts, voucher);

  if (!bestCampaignAssociationWithVoucher) {
    return undefined;
  }

  const bestCampaignPromotion: CampaignPromotion | undefined = campaignsPromotions.find(
    campaignPromotion =>
      campaignPromotion.campaignPromotionCode ===
      bestCampaignAssociationWithVoucher.campaignAssociation.campaignPromotionCode
  );

  if (!bestCampaignPromotion) {
    return undefined;
  }
  return {
    campaignAssociation: bestCampaignAssociationWithVoucher.campaignAssociation,
    campaignPromotion: bestCampaignPromotion,
    voucher: bestCampaignAssociationWithVoucher.voucher,
  };
};
