import { TypeKeys, generateActionId } from './actionsUtils.js';
import { convertToCommonErrors } from '../../common/utils/errorUtils.js';
import type { Action } from 'redux';
import type {
  CompanyInfoResponse,
  DiscountedPricesResponse,
  EppSolutionResponse,
  GenericError,
  SecondaryAccount,
  UpdateAccountRequest,
} from '../../generated/api/models.js';
import type { CrudAction, ErrorAction, ErrorActionCreator } from './actionsUtils.js';

export type LoadCompanyInfoAction = CrudAction<TypeKeys.LOAD_COMPANY_INFO>;

export interface LoadCompanyInfoFulfilledAction extends Action {
  type: TypeKeys.LOAD_COMPANY_INFO_FULFILLED;
  companyInfo: CompanyInfoResponse;
}

export const loadCompanyInfo = (): LoadCompanyInfoAction => {
  return {
    id: generateActionId(),
    type: TypeKeys.LOAD_COMPANY_INFO,
  };
};

export const loadCompanyInfoFulfilled = (response: CompanyInfoResponse): LoadCompanyInfoFulfilledAction => {
  return {
    companyInfo: response,
    type: TypeKeys.LOAD_COMPANY_INFO_FULFILLED,
  };
};

export type LoadCompanyInfoFailedAction = ErrorAction<TypeKeys.LOAD_COMPANY_INFO_FAILED>;

export const loadCompanyInfoFailed: ErrorActionCreator<TypeKeys.LOAD_COMPANY_INFO_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[]
): LoadCompanyInfoFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    type: TypeKeys.LOAD_COMPANY_INFO_FAILED,
  };
};

export type GetEppSolutionAction = CrudAction<TypeKeys.GET_EPP_SOLUTION_JIT_FAILURE_WORKAROUND>;

export interface GetEppSolutionFulfilledAction extends Action {
  type: TypeKeys.GET_EPP_SOLUTION_JIT_FAILURE_WORKAROUND_FULFILLED;
  companyInfo: { eppSolution: EppSolutionResponse };
}

export type GetEppSolutionFailedAction = ErrorAction<TypeKeys.GET_EPP_SOLUTION_JIT_FAILURE_WORKAROUND_FAILED>;

export const getEppSolutionFulfilled = (response: EppSolutionResponse): GetEppSolutionFulfilledAction => {
  return {
    companyInfo: { eppSolution: response },
    type: TypeKeys.GET_EPP_SOLUTION_JIT_FAILURE_WORKAROUND_FULFILLED,
  };
};

export const getEppSolutionFailed = (
  message: string,
  status: number,
  errors?: GenericError[]
): GetEppSolutionFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    type: TypeKeys.GET_EPP_SOLUTION_JIT_FAILURE_WORKAROUND_FAILED,
  };
};

export type GetCustomerLevelDiscountedPricesAction = CrudAction<TypeKeys.GET_CUSTOMER_LEVEL_DISCOUNTED_PRICES>;

export interface GetCustomerLevelDiscountedPricesFulfilledAction extends Action {
  type: TypeKeys.GET_CUSTOMER_LEVEL_DISCOUNTED_PRICES_FULFILLED;
  response: DiscountedPricesResponse;
}

export type GetDiscountedPricesFailedAction = ErrorAction<TypeKeys.GET_CUSTOMER_LEVEL_DISCOUNTED_PRICES_FAILED>;

export const getCustomerLevelDiscountedPrices = (): GetCustomerLevelDiscountedPricesAction => {
  return {
    id: generateActionId(),
    type: TypeKeys.GET_CUSTOMER_LEVEL_DISCOUNTED_PRICES,
  };
};

export const getCustomerLevelDiscountedPricesFulfilled = (
  response: DiscountedPricesResponse
): GetCustomerLevelDiscountedPricesFulfilledAction => {
  return {
    response,
    type: TypeKeys.GET_CUSTOMER_LEVEL_DISCOUNTED_PRICES_FULFILLED,
  };
};

export const getCustomerLevelDiscountedPricesFailed = (
  message: string,
  status: number,
  errors?: GenericError[]
): GetDiscountedPricesFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    type: TypeKeys.GET_CUSTOMER_LEVEL_DISCOUNTED_PRICES_FAILED,
  };
};

export interface UpdateAccountSettingsAction extends CrudAction<TypeKeys.UPDATE_ACCOUNT_SETTINGS> {
  updateAccountRequest: UpdateAccountRequest;
}

export const updateAccountSettings = (updateAccountRequest: UpdateAccountRequest): UpdateAccountSettingsAction => ({
  id: generateActionId(),
  type: TypeKeys.UPDATE_ACCOUNT_SETTINGS,
  updateAccountRequest,
});

export interface UpdateAccountSettingsFulfilledAction {
  type: TypeKeys.UPDATE_ACCOUNT_SETTINGS_FULFILLED;
  updateAccountRequest: UpdateAccountRequest;
}

export const updateAccountSettingsFulfilled = (
  updateAccountRequest: UpdateAccountRequest
): UpdateAccountSettingsFulfilledAction => ({
  type: TypeKeys.UPDATE_ACCOUNT_SETTINGS_FULFILLED,
  updateAccountRequest: updateAccountRequest,
});

export type UpdateAccountSettingsFailedAction = ErrorAction<TypeKeys.UPDATE_ACCOUNT_SETTINGS_FAILED>;

export const updateAccountSettingsFailed: ErrorActionCreator<TypeKeys.UPDATE_ACCOUNT_SETTINGS_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[]
): UpdateAccountSettingsFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    type: TypeKeys.UPDATE_ACCOUNT_SETTINGS_FAILED,
  };
};

// Load secondary account information
export type GetMyselfSecondaryAccountAction = CrudAction<TypeKeys.GET_MYSELF_SECONDARY_ACCOUNT>;

export interface GetMyselfSecondaryAccountFulfilledAction extends Action {
  response: Array<SecondaryAccount>;
  type: TypeKeys.GET_MYSELF_SECONDARY_ACCOUNT_FULFILLED;
}

export type GetMyselfSecondaryAccountFailedAction = ErrorAction<TypeKeys.GET_MYSELF_SECONDARY_ACCOUNT_FAILED>;

export const getMyselfSecondaryAccount = (): GetMyselfSecondaryAccountAction => ({
  id: generateActionId(),
  type: TypeKeys.GET_MYSELF_SECONDARY_ACCOUNT,
});

export const getMyselfSecondaryAccountFulfilled = (
  response: Array<SecondaryAccount>
): GetMyselfSecondaryAccountFulfilledAction => ({
  response,
  type: TypeKeys.GET_MYSELF_SECONDARY_ACCOUNT_FULFILLED,
});

export const getMyselfSecondaryAccountFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): GetMyselfSecondaryAccountFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.GET_MYSELF_SECONDARY_ACCOUNT_FAILED,
});

export type CompanyInfoActionTypes =
  | LoadCompanyInfoAction
  | LoadCompanyInfoFulfilledAction
  | LoadCompanyInfoFailedAction
  | GetEppSolutionAction
  | GetEppSolutionFulfilledAction
  | GetEppSolutionFailedAction
  | GetCustomerLevelDiscountedPricesAction
  | GetCustomerLevelDiscountedPricesFulfilledAction
  | GetDiscountedPricesFailedAction
  | UpdateAccountSettingsAction
  | UpdateAccountSettingsFulfilledAction
  | UpdateAccountSettingsFailedAction
  | GetMyselfSecondaryAccountAction
  | GetMyselfSecondaryAccountFulfilledAction
  | GetMyselfSecondaryAccountFailedAction;
