import * as CL from '@design-system/component-library';
import { BillingAccount } from './SearchResultTypes/BillingAccount.js';
import { Contact } from './SearchResultTypes/Contact.js';
import { Device } from './SearchResultTypes/Device.js';
import { DomainSubscription } from './SearchResultTypes/DomainSubscription.js';
import { InternetSubscription } from './SearchResultTypes/InternetSubscription.js';
import { Invoice } from './SearchResultTypes/Invoice.js';
import { NoResultsAndSearchTip, SearchTip } from './SearchTip.js';
import { Order } from './SearchResultTypes/Order.js';
import { RedeemRequest } from './SearchResultTypes/RedeemRequest.js';
import { SearchResultType } from '../../generated/api/searchResultType.js';
import { SearchResultTypeFilter } from './SearchResultTypeFilter.js';
import { SearchResultTypeResult } from './SearchResultTypeResult.js';
import { ShowAllAccountsToggle } from '../ShowAllAccountsToggle/ShowAllAccountsToggle.js';
import { SupportCase } from './SearchResultTypes/SupportCase.js';
import { VoiceSubscription } from './SearchResultTypes/VoiceSubscription.js';
import {
  billingAccountsMsg,
  domainsMsg,
  internetSubscriptionsMsg,
  invoicesMsg,
  ordersMsg,
  redeemRequestsMsg,
  searchPlaceHolderMsg,
  supportCasesMsg,
  t,
  usersMsg,
  voiceSubscriptionsMsg,
} from '../../common/i18n/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { fetchGlobalSearch } from '../../common/fetch.js';
import { getCompanyName } from '../../common/utils/accountUtils.js';
import { isFeatureEnabledForUser } from '../../common/utils/featureFlagUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { pushGlobalSearchGAEventToDataLayer } from '../../common/analytics.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDebounce } from '../../common/hooks/useDebounce.js';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import type { BillingAccountSearchHit } from '../../generated/api/billingAccountSearchHit.js';
import type { ChangeEvent } from 'react';
import type { ContactSearchHit } from '../../generated/api/contactSearchHit.js';
import type { CustomerOrderSearchHit } from '../../generated/api/customerOrderSearchHit.js';
import type { GlobalSearchResult } from '../../generated/api/globalSearchResult.js';
import type { InvoiceSearchHit } from '../../generated/api/invoiceSearchHit.js';
import type { RedeemRequestSearchHit } from '../../generated/api/redeemRequestSearchHit.js';
import type { State } from '../../selfservice/common/store.js';
import type { SubscriptionSearchHit } from '../../generated/api/subscriptionSearchHit.js';
import type { SupportCaseSearchHit } from '../../generated/api/supportCaseSearchHit.js';

import './GlobalSearch.scss';

export interface SearchFilter {
  name: string;
  total: number;
  selected: boolean;
  searchResultType: SearchResultType;
}
export interface GlobalSearchProps {
  setSearchFieldVisible: (visible: boolean) => void;
  isMultiBiz: boolean;
}

export interface GlobalSearchViewProps {
  setSearchFieldVisible: (visible: boolean) => void;
  isMultiBiz: boolean;
  handleSearchInputChange: (event: ChangeEvent<HTMLInputElement>) => Promise<void>;
  handleSearchAllAccountsChange: (event: ChangeEvent<HTMLInputElement>) => void;
  searchResult?: GlobalSearchResult;
  isLoading: boolean;
  lastNoResultsInput: string;
  searchAllAccounts: boolean;
  onFiltersChanged: (filters: SearchFilter[]) => void;
}

export interface SearchResultTypesResultsProps {
  searchInput: string;
  isMultiBiz: boolean;
  setSearchFieldVisible: (visible: boolean) => void;
  searchResult: GlobalSearchResult;
  filters: SearchFilter[];
  analyticsResultClickCb: (searchResultsType: SearchResultType) => void;
}

export interface SearchResultsTotalProps {
  total: number;
  filtersVisible?: boolean;
  isMultibiz?: boolean;
}

export interface SearchResultAreaProps {
  setSearchFieldVisible: (visible: boolean) => void;
  isMultiBiz: boolean;
  searchResult?: GlobalSearchResult;
  searchInput: string;
  filters: SearchFilter[];
  isLoading: boolean;
  lastNoResultsInput: string;
  analyticsResultClickCb: (searchResultsType: SearchResultType) => void;
}

export const searchFiltersFromSearchResults = (searchResult?: GlobalSearchResult): SearchFilter[] => {
  return searchResult
    ? [
        {
          name: t.I9C0(ordersMsg),
          total: searchResult.orders?.total || 0,
          selected: false,
          searchResultType: SearchResultType.ORDER,
        },
        {
          name: t.Z1M1(redeemRequestsMsg),
          total: searchResult.redeemRequests?.total || 0,
          selected: false,
          searchResultType: SearchResultType.REDEEM_REQUEST,
        },
        {
          name: t.H4F0(supportCasesMsg),
          total: searchResult.supportCases?.total || 0,
          selected: false,
          searchResultType: SearchResultType.SUPPORT_CASE,
        },
        {
          name: t.ZVMK(billingAccountsMsg),
          total: searchResult.billingAccounts?.total || 0,
          selected: false,
          searchResultType: SearchResultType.BILLING_ACCOUNT,
        },
        {
          name: t.SEYV(internetSubscriptionsMsg),
          total: searchResult.internetSubscriptions?.total || 0,
          selected: false,
          searchResultType: SearchResultType.INTERNET_SUBSCRIPTION,
        },
        {
          name: t.AR0B(voiceSubscriptionsMsg),
          total: searchResult.voiceSubscriptions?.total || 0,
          selected: false,
          searchResultType: SearchResultType.VOICE_SUBSCRIPTION,
        },
        {
          name: t.JJTM('Devices'),
          total: searchResult.deviceSubscriptions?.total || 0,
          selected: false,
          searchResultType: SearchResultType.DEVICE,
        },
        {
          name: t.BE4A(usersMsg),
          total: searchResult.contacts?.total || 0,
          selected: false,
          searchResultType: SearchResultType.CONTACT,
        },
        {
          name: t.WGE3(domainsMsg),
          total: searchResult.domainSubscriptions?.total || 0,
          selected: false,
          searchResultType: SearchResultType.DOMAIN,
        },
        {
          name: t.Y7C0(invoicesMsg),
          total: searchResult.invoices?.total || 0,
          selected: false,
          searchResultType: SearchResultType.INVOICE,
        },
      ].filter(filter => filter.total > 0)
    : [];
};

const SearchResultsTotal = ({ total, filtersVisible, isMultibiz }: SearchResultsTotalProps) => (
  <div
    className={`of-omaelisa--search-result-count ${
      !filtersVisible && isMultibiz ? 'of-omaelisa--search-result-count__multibiz-with-no-filters' : ''
    } ${dsClass.TEXT_XS}`}
  >
    {t.DO9X('Total {} results', String(total))}
  </div>
);

const getSeeAllLinkHref = (searchResultType: SearchResultType, searchInput: string) => {
  const searchInputAppended = `?search=${searchInput}`;
  switch (searchResultType) {
    case 'BILLING_ACCOUNT':
      return paths.BILLING_ACCOUNTS + searchInputAppended;
    case 'DEVICE':
      return paths.PS_DEVICES + searchInputAppended;
    case 'DOMAIN':
      return paths.PS_ELISA_DNS + searchInputAppended;
    case 'INTERNET_SUBSCRIPTION':
      return paths.PS_BROADBAND_SUBSCRIPTIONS + searchInputAppended;
    case 'VOICE_SUBSCRIPTION':
      return paths.PS_MOBILE_SUBSCRIPTIONS + searchInputAppended;
    case 'SUPPORT_CASE':
      return paths.SUPPORT_CASES + searchInputAppended;
    case 'REDEEM_REQUEST':
      return paths.CUSTOMER_ORDER_REDEEM_REQUESTS + searchInputAppended;
    case 'ORDER':
      return paths.CUSTOMER_ORDERS + searchInputAppended;
    case 'CONTACT':
      return paths.COMPANY_INFO_CONTACTS + searchInputAppended;
    case 'INVOICE':
      return paths.INVOICES + searchInputAppended;
  }
};

const SearchResultTypesResults = ({
  searchResult,
  filters,
  searchInput,
  analyticsResultClickCb,
  ...rest
}: SearchResultTypesResultsProps) => {
  const searchTerms = searchInput.split(' ');
  const { authenticatedUser } = useAuth();
  const config = useSelector((state: State) => state.config, deepEqual);

  // if no categories are selected (filter.selected), all categories are shown
  // else if any categories are selected, show category only if selected
  const isSearchResultTypeVisible = (searchResultType: SearchResultType) => {
    if (filters.some(filter => filter.selected)) {
      for (const filter of filters) {
        if (filter.searchResultType === searchResultType && filter.selected) {
          return true;
        }
      }
      return false;
    }
    return true;
  };

  return (
    <>
      {searchResult.orders && searchResult.orders.total > 0 && isSearchResultTypeVisible(SearchResultType.ORDER) && (
        <SearchResultTypeResult
          name={t.I9C0(ordersMsg)}
          total={searchResult.orders.total}
          entities={searchResult.orders.hits.map((hit: CustomerOrderSearchHit) => (
            <Order
              key={hit.result.customerOrderDisplayId}
              customerOrder={hit.result}
              matchedFields={hit.matchedFields}
              companyName={getCompanyName(authenticatedUser, hit.result.accountMasterId)}
              accountMasterId={hit.result.accountMasterId}
              activeAccountMasterId={authenticatedUser?.mdmId}
              searchTerms={searchTerms}
              onSearchResultClick={() => analyticsResultClickCb(SearchResultType.ORDER)}
              {...rest}
            />
          ))}
          seeAllLinkHref={getSeeAllLinkHref(SearchResultType.ORDER, searchInput)}
          {...rest}
        />
      )}
      {searchResult.redeemRequests &&
        searchResult.redeemRequests.total > 0 &&
        isSearchResultTypeVisible(SearchResultType.REDEEM_REQUEST) && (
          <SearchResultTypeResult
            name={t.Z1M1(redeemRequestsMsg)}
            total={searchResult.redeemRequests.total}
            entities={searchResult.redeemRequests.hits.map((hit: RedeemRequestSearchHit) => (
              <RedeemRequest
                key={hit.result.subscriptionActionDisplayId}
                redeemRequest={hit.result}
                matchedFields={hit.matchedFields}
                companyName={getCompanyName(authenticatedUser, hit.result.accountMasterId)}
                accountMasterId={hit.result.accountMasterId}
                activeAccountMasterId={authenticatedUser?.mdmId}
                searchTerms={searchTerms}
                onSearchResultClick={() => analyticsResultClickCb(SearchResultType.REDEEM_REQUEST)}
                {...rest}
              />
            ))}
            seeAllLinkHref={getSeeAllLinkHref(SearchResultType.REDEEM_REQUEST, searchInput)}
            {...rest}
          />
        )}
      {searchResult.supportCases &&
        searchResult.supportCases.total > 0 &&
        isSearchResultTypeVisible(SearchResultType.SUPPORT_CASE) && (
          <SearchResultTypeResult
            name={t.H4F0(supportCasesMsg)}
            total={searchResult.supportCases.total}
            entities={searchResult.supportCases.hits.map((hit: SupportCaseSearchHit) => (
              <SupportCase
                key={hit.result.supportCaseDisplayId}
                supportCase={hit.result}
                matchedFields={hit.matchedFields}
                companyName={getCompanyName(authenticatedUser, hit.result.accountMasterId)}
                accountMasterId={hit.result.accountMasterId}
                activeAccountMasterId={authenticatedUser?.mdmId}
                searchTerms={searchTerms}
                onSearchResultClick={() => analyticsResultClickCb(SearchResultType.SUPPORT_CASE)}
                {...rest}
              />
            ))}
            seeAllLinkHref={getSeeAllLinkHref(SearchResultType.SUPPORT_CASE, searchInput)}
            {...rest}
          />
        )}
      {searchResult.billingAccounts &&
        searchResult.billingAccounts.total > 0 &&
        isSearchResultTypeVisible(SearchResultType.BILLING_ACCOUNT) && (
          <SearchResultTypeResult
            name={t.ZVMK(billingAccountsMsg)}
            total={searchResult.billingAccounts.total}
            entities={searchResult.billingAccounts.hits.map((hit: BillingAccountSearchHit) => (
              <BillingAccount
                key={hit.result.billingAccountDisplayId}
                billingAccount={hit.result}
                classicSiteUrl={config.classicSiteUrl}
                matchedFields={hit.matchedFields}
                companyName={getCompanyName(authenticatedUser, hit.result.accountMasterId)}
                accountMasterId={hit.result.accountMasterId}
                activeAccountMasterId={authenticatedUser?.mdmId}
                searchTerms={searchTerms}
                onSearchResultClick={() => analyticsResultClickCb(SearchResultType.BILLING_ACCOUNT)}
                {...rest}
              />
            ))}
            seeAllLinkHref={getSeeAllLinkHref(SearchResultType.BILLING_ACCOUNT, searchInput)}
            {...rest}
          />
        )}
      {searchResult.internetSubscriptions &&
        searchResult.internetSubscriptions.total > 0 &&
        isSearchResultTypeVisible(SearchResultType.INTERNET_SUBSCRIPTION) && (
          <SearchResultTypeResult
            name={t.SEYV(internetSubscriptionsMsg)}
            total={searchResult.internetSubscriptions.total}
            entities={searchResult.internetSubscriptions.hits.map((hit: SubscriptionSearchHit) => (
              <InternetSubscription
                key={hit.result.subscriptionDisplayId}
                subscription={hit.result}
                classicSiteUrl={config.classicSiteUrl}
                matchedFields={hit.matchedFields}
                companyName={getCompanyName(authenticatedUser, hit.result.accountMasterId)}
                accountMasterId={hit.result.accountMasterId}
                activeAccountMasterId={authenticatedUser?.mdmId}
                searchTerms={searchTerms}
                onSearchResultClick={() => analyticsResultClickCb(SearchResultType.INTERNET_SUBSCRIPTION)}
                {...rest}
              />
            ))}
            seeAllLinkHref={getSeeAllLinkHref(SearchResultType.INTERNET_SUBSCRIPTION, searchInput)}
            {...rest}
          />
        )}
      {searchResult.voiceSubscriptions &&
        searchResult.voiceSubscriptions.total > 0 &&
        isSearchResultTypeVisible(SearchResultType.VOICE_SUBSCRIPTION) && (
          <SearchResultTypeResult
            name={t.AR0B(voiceSubscriptionsMsg)}
            total={searchResult.voiceSubscriptions.total}
            entities={searchResult.voiceSubscriptions.hits.map((hit: SubscriptionSearchHit) => (
              <VoiceSubscription
                key={hit.result.subscriptionDisplayId}
                subscription={hit.result}
                classicSiteUrl={config.classicSiteUrl}
                matchedFields={hit.matchedFields}
                companyName={getCompanyName(authenticatedUser, hit.result.accountMasterId)}
                accountMasterId={hit.result.accountMasterId}
                activeAccountMasterId={authenticatedUser?.mdmId}
                searchTerms={searchTerms}
                onSearchResultClick={() => analyticsResultClickCb(SearchResultType.VOICE_SUBSCRIPTION)}
                {...rest}
              />
            ))}
            seeAllLinkHref={getSeeAllLinkHref(SearchResultType.VOICE_SUBSCRIPTION, searchInput)}
            {...rest}
          />
        )}
      {searchResult.deviceSubscriptions &&
        searchResult.deviceSubscriptions.total > 0 &&
        isSearchResultTypeVisible(SearchResultType.DEVICE) && (
          <SearchResultTypeResult
            name={t.JJTM('Devices')}
            total={searchResult.deviceSubscriptions.total}
            entities={searchResult.deviceSubscriptions.hits.map((hit: SubscriptionSearchHit) => (
              <Device
                key={hit.result.subscriptionDisplayId}
                subscription={hit.result}
                classicSiteUrl={config.classicSiteUrl}
                isEppDevice={!!hit.result.eppSubscription}
                matchedFields={hit.matchedFields}
                companyName={getCompanyName(authenticatedUser, hit.result.accountMasterId)}
                accountMasterId={hit.result.accountMasterId}
                activeAccountMasterId={authenticatedUser?.mdmId}
                searchTerms={searchTerms}
                onSearchResultClick={() => analyticsResultClickCb(SearchResultType.DEVICE)}
                {...rest}
              />
            ))}
            seeAllLinkHref={getSeeAllLinkHref(SearchResultType.DEVICE, searchInput)}
            {...rest}
          />
        )}
      {searchResult.invoices &&
        searchResult.invoices.total > 0 &&
        isSearchResultTypeVisible(SearchResultType.INVOICE) && (
          <SearchResultTypeResult
            name={t.Y7C0(invoicesMsg)}
            total={searchResult.invoices.total}
            entities={searchResult.invoices.hits.map((hit: InvoiceSearchHit) => (
              <Invoice
                key={hit.result.invoiceDisplayId}
                invoice={hit.result}
                matchedFields={hit.matchedFields}
                companyName={getCompanyName(authenticatedUser, hit.result.accountMasterId)}
                accountMasterId={hit.result.accountMasterId}
                activeAccountMasterId={authenticatedUser?.mdmId}
                searchTerms={searchTerms}
                onSearchResultClick={() => analyticsResultClickCb(SearchResultType.INVOICE)}
                {...rest}
              />
            ))}
            seeAllLinkHref={getSeeAllLinkHref(SearchResultType.INVOICE, searchInput)}
            {...rest}
          />
        )}
      {searchResult.contacts &&
        searchResult.contacts.total > 0 &&
        isSearchResultTypeVisible(SearchResultType.CONTACT) && (
          <SearchResultTypeResult
            name={t.BE4A(usersMsg)}
            total={searchResult.contacts.total}
            entities={searchResult.contacts.hits.map((hit: ContactSearchHit) => (
              <Contact
                key={hit.result.contactId}
                contact={hit.result}
                matchedFields={hit.matchedFields}
                companyName={getCompanyName(authenticatedUser, hit.result.accountMasterId)}
                accountMasterId={hit.result.accountMasterId}
                activeAccountMasterId={authenticatedUser?.mdmId}
                searchTerms={searchTerms}
                onSearchResultClick={() => analyticsResultClickCb(SearchResultType.CONTACT)}
                {...rest}
              />
            ))}
            seeAllLinkHref={getSeeAllLinkHref(SearchResultType.CONTACT, searchInput)}
            {...rest}
          />
        )}
      {isFeatureEnabledForUser('dnsManagement', config.featureFlags, authenticatedUser?.enabledFeatureFlags) &&
        searchResult.domainSubscriptions &&
        searchResult.domainSubscriptions.total > 0 &&
        isSearchResultTypeVisible(SearchResultType.DOMAIN) && (
          <SearchResultTypeResult
            name={t.WGE3(domainsMsg)}
            total={searchResult.domainSubscriptions.total}
            entities={searchResult.domainSubscriptions.hits.map((hit: SubscriptionSearchHit) => (
              <DomainSubscription
                key={hit.result.subscriptionDisplayId}
                subscription={hit.result}
                matchedFields={hit.matchedFields}
                companyName={getCompanyName(authenticatedUser, hit.result.accountMasterId)}
                accountMasterId={hit.result.accountMasterId}
                activeAccountMasterId={authenticatedUser?.mdmId}
                searchTerms={searchTerms}
                onSearchResultClick={() => analyticsResultClickCb(SearchResultType.DOMAIN)}
                {...rest}
              />
            ))}
            seeAllLinkHref={getSeeAllLinkHref(SearchResultType.DOMAIN, searchInput)}
            {...rest}
          />
        )}
    </>
  );
};

const SkeletonLoader = () => (
  <div className="skeleton-loader">
    <div className="skeleton-loader__row"></div>
    <div className="skeleton-loader__row"></div>
  </div>
);

const SearchResultsArea = ({
  searchResult,
  searchInput,
  filters,
  isLoading,
  lastNoResultsInput,
  ...rest
}: SearchResultAreaProps) => {
  if (isLoading) {
    return <SkeletonLoader />;
  } else if (searchResult && searchResult.total > 0) {
    return (
      <SearchResultTypesResults searchResult={searchResult} filters={filters} searchInput={searchInput} {...rest} />
    );
  } else if (searchResult && searchResult.total === 0 && searchInput) {
    return <NoResultsAndSearchTip searchTermUsed={lastNoResultsInput} {...rest} />;
  } else {
    return <SearchTip />;
  }
};

export const GlobalSearchView = ({
  setSearchFieldVisible,
  isMultiBiz,
  handleSearchInputChange,
  handleSearchAllAccountsChange,
  searchResult,
  isLoading,
  lastNoResultsInput,
  searchAllAccounts,
  onFiltersChanged,
}: GlobalSearchViewProps) => {
  const [filters, setFilters] = useState<SearchFilter[]>([]);
  const [searchInput, setSearchInput] = useState('');
  const { consolidatedViews } = useSelector((state: State) => state.config.featureFlags);

  const handleSearchInputRef = (inputElement: HTMLInputElement | null) => inputElement?.focus();

  const onSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
    return handleSearchInputChange(event);
  };

  const handleFilterSelected = (name: string, selected: boolean) => {
    const updatedFilters = filters.map(f => {
      if (f.name === name) {
        f.selected = selected;
      }
      return f;
    });
    onFiltersChanged(updatedFilters);
    setFilters(updatedFilters);
  };

  useEffect(() => {
    setFilters(searchFiltersFromSearchResults(searchResult));
  }, [searchResult]);

  const showFilters = !isLoading && filters.length > 1;
  const total =
    !isLoading && searchResult ? (
      <SearchResultsTotal total={searchResult.total} filtersVisible={showFilters} isMultibiz={isMultiBiz} />
    ) : undefined;

  const pushSearchResultTypeClickToDataLayer = (searchResultType: SearchResultType) => {
    pushGlobalSearchGAEventToDataLayer(
      'uoe_search_results_click',
      searchAllAccounts,
      filters.filter(f => f.selected).map(f => f.searchResultType),
      [searchResultType],
      searchResult?.total
    );
  };

  return (
    <div className="of-omaelisa-main-container">
      <div className="of-omaelisa-search">
        <CL.Icon className="search-icon" icon="search" />
        <input
          type="text"
          aria-label="of-search-input"
          onChange={onSearchInputChange}
          placeholder={t.GGLB(searchPlaceHolderMsg)}
          ref={handleSearchInputRef}
        />
        <CL.Button
          className="close-search-button"
          type="button"
          color="link"
          size="l"
          onClick={() => setSearchFieldVisible(false)}
        >
          <CL.Icon icon="close" />
        </CL.Button>
      </div>
      <div className="of-omaelisa-search-results-container">
        <div className={`of-omaelisa-search-filter-container ${dsClass.PADDING_3}`}>
          {isMultiBiz && !consolidatedViews ? (
            <ShowAllAccountsToggle
              isSearchAllAccounts={searchAllAccounts}
              handleSearchAllAccountsChange={handleSearchAllAccountsChange}
            >
              {total}
            </ShowAllAccountsToggle>
          ) : (
            total
          )}
          {searchResult && showFilters && (
            <div className="of-omaelisa-search-filters">
              {filters.map(filter => (
                <SearchResultTypeFilter key={filter.name} filter={filter} setSelected={handleFilterSelected} />
              ))}
            </div>
          )}
        </div>

        <SearchResultsArea
          searchResult={searchResult}
          searchInput={searchInput}
          isMultiBiz={isMultiBiz}
          filters={filters}
          setSearchFieldVisible={setSearchFieldVisible}
          isLoading={isLoading}
          lastNoResultsInput={lastNoResultsInput}
          analyticsResultClickCb={pushSearchResultTypeClickToDataLayer}
        />
      </div>
    </div>
  );
};

export const SEARCH_MIN_CHARS = 2;

export const GlobalSearch = ({ isMultiBiz, setSearchFieldVisible }: GlobalSearchProps) => {
  const zeroResults = { total: 0 };
  const [searchAllAccountsState, setSearchAllAccountsState] = useState(true);
  const [searchInputState, setSearchInputState] = useState('');
  const [searchResultState, setSearchResultState] = useState<GlobalSearchResult>();
  const [debouncedLoading, setDebouncedLoading] = useState(false);
  const [lastNoResultsInput, setLastNoResultsInput] = useState('');
  const [filters, setFilters] = useState<SearchFilter[]>([]);

  // we don't want to show loading state until user has stopped typing
  useEffect(() => {
    if (searchInputState.length >= SEARCH_MIN_CHARS) {
      const timer = setTimeout(() => setDebouncedLoading(true), 500);
      return () => clearTimeout(timer);
    } else {
      setDebouncedLoading(false);
      return () => {};
    }
  }, [searchInputState]);

  const pushSearchResultsViewToDataLayer = useMemo(
    () => () => {
      if (searchResultState) {
        const currentFilters = [
          // in case filters have not updated, parse initial filters from search result
          ...new Set([...searchFiltersFromSearchResults(searchResultState), ...filters].map(f => f.searchResultType)),
        ];

        // we are only interested in value of filters after it has changed because initially no filters are selected
        pushGlobalSearchGAEventToDataLayer(
          'uoe_search_results_view',
          searchAllAccountsState,
          filters.filter(f => f.selected).map(f => f.searchResultType),
          currentFilters,
          searchResultState?.total
        );
      }
    },
    [searchResultState, searchAllAccountsState, filters]
  );

  const debouncedPushSearchResultsViewToDataLayer = useDebounce(pushSearchResultsViewToDataLayer, 1000);

  useEffect(() => {
    debouncedPushSearchResultsViewToDataLayer();
  }, [debouncedPushSearchResultsViewToDataLayer, filters]);

  const search = async (searchInput: string, searchAllAccounts: boolean) => {
    const res = await fetchGlobalSearch({ query: searchInput, searchAllAccounts: searchAllAccounts });

    if (res && res.total === 0) {
      setLastNoResultsInput(searchInput);
    }
    setSearchResultState(res || zeroResults);
    if (searchInputState.length >= SEARCH_MIN_CHARS && searchResultState) {
      // push to data layer here to limit unnecessary events that would be caused by render
      debouncedPushSearchResultsViewToDataLayer();
    }
    setDebouncedLoading(false);
  };
  const doSearch = useDebounce<(...args: (string | boolean)[]) => void, string | boolean>(search, 1000);

  const handleSearchAllAccountsChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const searchAllAccounts = event.currentTarget.checked;
    setSearchAllAccountsState(searchAllAccounts);
    if (searchInputState.length >= SEARCH_MIN_CHARS) {
      setDebouncedLoading(true);
      doSearch(searchInputState, searchAllAccounts);
    }
  };

  const handleSearchInputChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const searchInput = event.target.value.trim();
    if (searchInput === searchInputState) {
      return;
    }

    setSearchInputState(searchInput);

    if (searchInput.length < SEARCH_MIN_CHARS) {
      setSearchResultState(undefined);
    } else {
      doSearch(searchInput, searchAllAccountsState);
    }
  };

  return (
    <GlobalSearchView
      setSearchFieldVisible={setSearchFieldVisible}
      isMultiBiz={isMultiBiz}
      searchResult={searchResultState}
      handleSearchAllAccountsChange={handleSearchAllAccountsChange}
      handleSearchInputChange={handleSearchInputChange}
      isLoading={debouncedLoading}
      lastNoResultsInput={lastNoResultsInput}
      searchAllAccounts={searchAllAccountsState}
      onFiltersChanged={setFilters}
    />
  );
};
