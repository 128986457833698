import { AuxiliaryEsimOrderPhaseEnum } from '../auxiliaryEsimOrderEnums.js';
import { auxiliaryEsimProductPriceMsg, t } from '../../../common/i18n/index.js';
import { onEnterOrSpaceKeyPress } from '../../../common/utils/handlerUtils.js';
import { setAuxiliaryEsimOrderPhase } from '../../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import type { KeyboardEvent } from 'react';

export const AuxiliaryEsimOrderProductSelection = () => {
  const dispatch = useDispatch();
  const onProductSelection = () => {
    dispatch(setAuxiliaryEsimOrderPhase(AuxiliaryEsimOrderPhaseEnum.DETAILS));
  };

  return (
    <div className="ea-color-container ea-color-container--stretch">
      <h3 className="ea-h3">{t.Q53K('Order KelloSIM additional service')}</h3>
      <p>{t.WXB4('Use your smartwatch like as a phone with your existing phone number')}</p>
      <ul className="ea-selectlist-pricelist-narrow" role="tablist">
        <li
          className="ea-selection-tile__list-item"
          onClick={onProductSelection}
          onKeyPress={(event: KeyboardEvent) => onEnterOrSpaceKeyPress(event, () => onProductSelection())}
          tabIndex={0}
          role="tab"
        >
          <div className="ea-price-text">
            <h4 className="ea-h4">{t.FMUV('Elisa KelloSIM service')}</h4>
            <span className="ea-price-text__price">{t.FKVB(auxiliaryEsimProductPriceMsg)}</span>
            <div className="ea-disclaimertext">{t.M6V5('The contract is valid until further notice')}</div>
          </div>
          <span className="ea-icon ea-icon--arrow-right"></span>
        </li>
      </ul>
    </div>
  );
};
