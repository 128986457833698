import { type AvailabilityAddress } from './OpenFormAnswers.js';
import { format, parse } from 'date-fns';
import { generatePath } from 'react-router-dom';
import type { Address } from '../../generated/api/address.js';

export const getAddressSearchResult = (address?: { addressId: string; addressText: string; postalCode: string }) =>
  address
    ? { postalCode: address.postalCode, match: { addressId: address.addressId, addressText: address.addressText } }
    : undefined;

export const getAddressText = (address: AvailabilityAddress | Address | undefined) =>
  address
    ? 'addressText' in address && address.addressText
      ? address.addressText
      : [address.line1, address.line2, address.postalCode, address.postOffice].filter(Boolean).join(' ')
    : undefined;

// Choices are stored in `dd.MM.yyyy` format in storage, but sent to salesforce in `yyyy-MM-dd` format
export const getDate = (date: string | undefined) =>
  date ? format(parse(date, 'dd.MM.yyyy', new Date()), 'yyyy-MM-dd') : undefined;

export const getIndex = (index: number | undefined, { min = 1, max = Number.MAX_SAFE_INTEGER } = {}) =>
  Number.isSafeInteger(index) ? Math.min(max, Math.max(min, index!)) : min;

export const getValue = <T>(input?: T | T[]) => (input ? String(input) : input);

export const isIframe = () => {
  try {
    return !!window.parent.location.href && window.parent !== window.self;
  } catch {
    return true;
  }
};

export const onBlurPrice = (value: string) => Math.max(0, Math.min(Math.abs(Number(value)), 99999.99)).toFixed(2);

export const onChangePrice = (value: string) => value.replace('-', '').replace(',', '.');

export const parsePath = <P extends Record<string, unknown>>(path: string, params?: Record<keyof P, unknown>) =>
  generatePath(path, params);
