import { BillingAccountSourceSystem } from './billingAccountUtils.js';
import { isPOBoxAddress } from './validationUtils.js';
import {
  loadBillingAccounts,
  submitOnlineOrder,
  upsertBillingAccount,
  upsertVirtualCatalog,
} from '../../selfservice/actions/index.js';
import type {
  AddOn,
  BillingAccount,
  Catalog,
  DeliveryAddress,
  OnlineOrder,
  VirtualCatalog,
} from '../../generated/api/models.js';
import type { ChangeSubscriptionBillingAccountWizardParams } from '../types/wizard.js';
import type { CommonError } from '../types/errors.js';
import type { ConfiguredOffer } from '../types/commercialProduct.js';
import type { Dispatch } from 'redux';

export const onSubmitOrder = (dispatch: Dispatch) => {
  return (
    orderItems: ConfiguredOffer[],
    deliveryAddress: DeliveryAddress,
    isCompanyNameRequired?: boolean,
    deliveryAddressValidationErrors?: CommonError[],
    personAccountValidationErrors?: CommonError[],
    billingAccountId?: string,
    newBillingAccount?: BillingAccount,
    newBillingAccountValidationErrors?: CommonError[],
    addOns?: AddOn[],
    setShowPOBoxWarningModal?: (isShown: boolean) => void,
    shipmentType?: OnlineOrder.ShipmentTypeEnum,
    apiVersion?: string,
    attachRingRedirect?: string
  ) => {
    if (setShowPOBoxWarningModal && isPOBoxAddress(deliveryAddress.address.postalCode)) {
      setShowPOBoxWarningModal(true);
    } else {
      dispatch(
        submitOnlineOrder(
          orderItems,
          deliveryAddress,
          undefined,
          undefined,
          undefined,
          isCompanyNameRequired,
          deliveryAddressValidationErrors,
          personAccountValidationErrors,
          billingAccountId,
          newBillingAccount,
          newBillingAccountValidationErrors,
          addOns,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          shipmentType,
          apiVersion,
          attachRingRedirect
        )
      );
    }
  };
};

export const onUpsertBillingAccount = (dispatch: Dispatch, editedSection?: string) => {
  return (
    upsertedBillingAccount: BillingAccount,
    validationErrors?: CommonError[],
    wizardParams?: ChangeSubscriptionBillingAccountWizardParams
  ) => dispatch(upsertBillingAccount(upsertedBillingAccount, validationErrors, wizardParams, undefined, editedSection));
};

export const onUpsertVirtualCatalog = (dispatch: Dispatch) => {
  return (
    upsertedCatalog: Partial<Catalog>,
    publishCatalog: boolean,
    virtualCatalog?: VirtualCatalog,
    newBillingAccount?: BillingAccount,
    newBillingAccountValidationErrors?: CommonError[],
    overridePreviousAction?: boolean,
    billingAccountRequired?: boolean
  ) => {
    dispatch(
      upsertVirtualCatalog(
        upsertedCatalog,
        publishCatalog,
        virtualCatalog,
        newBillingAccount,
        newBillingAccountValidationErrors,
        overridePreviousAction,
        billingAccountRequired
      )
    );
  };
};

export const onLoadBillingAccounts = (dispatch: Dispatch) => {
  dispatch(loadBillingAccounts(undefined, undefined, undefined, true, BillingAccountSourceSystem.SFDC, true));
};
