import { AuthenticatedUserRole } from '../../generated/api/authenticatedUserRole.js';
import { IdentityProvider } from '../../generated/api/identityProvider.js';
import { OpenFormBar } from './OpenFormComponents/OpenFormBar.js';
import { OpenFormOutletContext } from './OpenFormOutletContext.js';
import { SelfServiceLogin } from '../SelfServiceLoginScene/SelfServiceLogin.js';
import { authenticatingMsg, authenticationFailedMsg, t } from '../../common/i18n/index.js';
import { isIframe } from './OpenFormUtils.js';
import { useCallback } from 'react';
import { useOpenFormAsync, useOpenFormSearchParams } from './OpenFormProvider.js';
import type { AuthProps } from '../../public/site/AuthProvider.js';

export const OpenFormOutlet = (props: AuthProps) => {
  const async = useOpenFormAsync();
  const search = useOpenFormSearchParams();

  const idPAuthenticated = useCallback(
    (auth: AuthProps): auth is Required<AuthProps> =>
      IdentityProvider.SALESFORCE === auth.authenticatedUser?.identityProvider &&
      [AuthenticatedUserRole.ADMIN, AuthenticatedUserRole.KEY_USER].includes(auth.authenticatedUser.userRole!) &&
      !!auth.authenticatedUser?.activeAccountMasterId,
    []
  );

  const localAuthenticated = useCallback(
    (auth: AuthProps): auth is Required<AuthProps> =>
      auth.isAuthenticated &&
      window.location.hostname.includes('localhost') &&
      !!auth.authenticatedUser?.activeAccountMasterId,
    []
  );

  switch (true) {
    case props.isLoading: {
      return <OpenFormBar heading={t.TA3J(authenticatingMsg)} />;
    }
    case idPAuthenticated(props):
    case localAuthenticated(props): {
      async.storage.namespace = props.authenticatedUser.activeAccountMasterId;
      return <OpenFormOutletContext user={props.authenticatedUser} />;
    }
    case !isIframe(): {
      return <SelfServiceLogin />;
    }
    case !!search.get('retry'): {
      return <OpenFormBar critical={t.J9TT(authenticationFailedMsg)} />;
    }
    default: {
      search.set('retry', Date.now().toString());
      window.location.assign('?' + search.toString());
      return null;
    }
  }
};
