import { ConfirmationDialog } from '../../ConfirmationDialog/ConfirmationDialog.js';
import { paths } from '../../../common/constants/pathVariables.js';
import { t } from '../../../common/i18n/index.js';
import { useNavigate } from 'react-router-dom';

import './BbEmployeeInvitationDialog.scss';

export interface BbEmployeeNotFoundDialogProps {
  onCloseDialog: () => void;
}

export const BbEmployeeNotFoundDialog = ({ onCloseDialog }: BbEmployeeNotFoundDialogProps) => {
  const navigate = useNavigate();
  return (
    <ConfirmationDialog
      header={t.YA79(`Check employee user's information`)}
      body={
        <div className="of-bb-invitation-employee-not-found">
          <p>{t.TEXW('The subscription contact is missing some information.')}</p>
          <p>{t.SKA0(`Check that user's work phone number and e-mail are up to date.`)}</p>
          <p>{t.TYKY(`Because of the data privacy it's important that the user information is correct.`)}</p>
        </div>
      }
      onCloseDialog={onCloseDialog}
      confirmButtonText={t.JG2T('Modify user information')}
      onConfirm={() => {
        onCloseDialog();
        navigate(paths.COMPANY_INFO_CONTACTS);
      }}
    />
  );
};
