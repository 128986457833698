import * as CL from '@design-system/component-library';
import { dsClass } from '../../common/constants/dsClasses.js';
import { paths } from '../../common/constants/pathVariables.js';
import { t } from '../../common/i18n/index.js';

export const ThankYouContent = () => (
  <div className={`${dsClass.TEXT_ALIGN_CENTER} ${dsClass.PADDING_TOP_10}`}>
    <div className="of-fixed-broadband-order__thumb-up" />
    <h2 className={`${dsClass.H2} ${dsClass.MARGIN_BOTTOM_2}`}>{t.O4R6('Thank you for your order!')}</h2>
    <div>{t.AURX(`We'll get back to you as soon as possible.`)}</div>
    <CL.Button
      color="primary"
      size="l"
      className={dsClass.MARGIN_TOP_8}
      onClick={() => {
        window.location.href = paths.NETTI_LITE;
      }}
    >
      {t.YZQH('Return to store')}
    </CL.Button>
  </div>
);
