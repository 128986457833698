import { AddressSearch } from '../../../AddressSearch/AddressSearch.js';
import { type Context } from '../../OpenFormAnswers.js';
import { type OpenFormDispatcher } from '../../OpenFormHooks/useOpenFormDispatcher.js';
import { OpenFormGridCol, OpenFormGridRow } from '../../OpenFormComponents/OpenFormGrid.js';
import { deepEqual } from '../../../../common/utils/objectUtils.js';
import { getAddressSearchResult, getValue } from '../../OpenFormUtils.js';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import type { State } from '../../../../selfservice/common/store.js';

export const OpenFormQuestionInstallationAddress = ({
  dispatcher,
  context,
  required,
}: {
  dispatcher: OpenFormDispatcher;
  context: Context | undefined;
  required: boolean;
}) => {
  const result =
    useSelector((state: State) => state?.resources?.addressSearchResult, deepEqual) ??
    getAddressSearchResult(context?.address);

  useEffect(() => {
    if (result?.match?.addressId !== getValue(context?.choices)) {
      dispatcher.setAnswer(
        result?.match?.addressId ? [result.match.addressId] : undefined,
        'address',
        context?.address
      );
    }
  }, [dispatcher, context?.address, context?.choices, result?.match]);

  return (
    <OpenFormGridRow className="of-openform__address-check">
      <OpenFormGridCol colWidth={6} className={classNames({ ['label--mandatory']: required })}>
        <AddressSearch
          addressSearchResult={result}
          heading={null}
          hideSubmitButton={true}
          initialPostalCodeValue={result?.postalCode}
          initialStreetAddressValue={result?.match?.addressText}
          onSubmit={() => {}}
        />
      </OpenFormGridCol>
    </OpenFormGridRow>
  );
};
