import * as CL from '@design-system/component-library';
import { DialogWrapper } from '../DialogWrapper/index.js';
import { Form, Formik } from 'formik';
import { ModalCloseAndSubmitButtons } from '../Modal/ModalCloseAndSubmitButtons.js';
import { SiteContext } from '../../public/site/SiteContext.js';
import { SupportCase } from '../../generated/api/models.js';
import {
  askAboutInvoiceMsg,
  confirmMsg,
  movePaymentDateMsg,
  moveThePaymentDateSuccessMsg,
  oneTimePaymentMsg,
  t,
} from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { pushGAEventToDataLayer } from '../../common/analytics.js';
import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import './MovePaymentDateDialog.scss';

export interface MovePaymentDateDialogAttrs {
  featureItemId: string;
  isSubmitInProgress?: boolean;
}

export interface MovePaymentDateDialogDispatchers {
  onCloseDialog: () => void;
  onSubmitDialog: (
    supportCase: SupportCase,
    noSuccessNotification: boolean,
    showInfoDialog: boolean,
    successNotificationMsg: string
  ) => void;
}

export type MovePaymentDateDialogProps = MovePaymentDateDialogAttrs & MovePaymentDateDialogDispatchers;

export const MovePaymentDateDialog = ({
  featureItemId,
  isSubmitInProgress,
  onCloseDialog,
  onSubmitDialog,
}: MovePaymentDateDialogProps): JSX.Element => {
  const { siteBaseUrl } = useContext(SiteContext);
  const { pathname, search } = useLocation();

  useEffect(() => {
    pushGAEventToDataLayer('MOVE_PAYMENT_DATE_MODAL');
  }, [search]);

  return (
    <DialogWrapper closeable header={t.YW9I(movePaymentDateMsg)} onCloseDialog={onCloseDialog}>
      <div className={`${dsClass.MARGIN_TOP_4} support-case-dialog`}>
        <p>
          {t.JKJY(
            `With a payment agreement, you can move the payment date up to 30 days after the invoice's original due date.`
          )}
          {` ${t.PGL0('Please note that the existing payment agreement prevents further sales.')}`}
        </p>
        <p>
          {t.EQID(
            'If one or more invoices are in collection agent, the payment agreement cannot done via self-service.'
          )}
        </p>
        <p>
          <strong>{t.EEE3('NB!')}</strong>&nbsp;
          {t.HBS2(
            `The new due date is not visible in Corporate OmaElisa, but you will receive a confirmation in your email after we have handled the payment agreement request. If you haven't received a confirmation email after 5 business days, contact Corporate Customer Service.`
          )}
        </p>
        <p>
          {t.BNAZ('The transfer of the payment date will be charged according to the')}&nbsp;
          <a target="_blank" href="https://yrityksille.elisa.fi/palveluhinnasto" rel="noopener noreferrer">
            {t.ZUWC('service prices for business customers')}
          </a>
          {t.P2VO('')}.
        </p>
        <p className={dsClass.MARGIN_BOTTOM_5}>
          <a href={t.XY1R('https://yrityksille.elisa.fi/en/invoices')} target="_blank" rel="noopener noreferrer">
            {t.BQ9Y('See more details here')} {`"${t.L7HY('How to get more payment time')}"`}{' '}
            <CL.Icon color="secondary-blue-600" icon="external-link" type="light" />
          </a>
        </p>
        <hr />
        <p>
          {t.ASEI(oneTimePaymentMsg)} <span className="right strong">10,00 €</span>
        </p>
        <hr className={dsClass.MARGIN_BOTTOM_6} />
        <Formik
          onSubmit={() => {
            onSubmitDialog(
              {
                action: t.AM3R(askAboutInvoiceMsg),
                feature: SupportCase.FeatureEnum.INVOICES,
                featureItemId: featureItemId,
                url: `${siteBaseUrl}${pathname}${search}`,
                featureType: t.YW9I(movePaymentDateMsg),
                description: t.VQXC(
                  'Payment agreement order for a maximum of 30 days.\n' +
                    '\n' +
                    'We have taken your request for a payment agreement into consideration. We will check your situation and you will receive a decision on moving the due date and a new payment date to your email within 5 business days. The new payment date does not appear in Corporate OmaElisa. If you do not receive a confirmation message after 5 business days, please contact our Corporate Customer Service.\n' +
                    '\n' +
                    'For concluding a payment agreement, a fee according to the price list will be charged in the next invoice.'
                ),
              },
              false,
              false,
              t.HUJX(moveThePaymentDateSuccessMsg)
            );
          }}
          initialValues={{}}
        >
          <Form noValidate>
            <ModalCloseAndSubmitButtons
              onCancel={onCloseDialog}
              onSubmit={() => {}}
              submitting={isSubmitInProgress}
              submitButtonText={t.QVYK(confirmMsg)}
            />
          </Form>
        </Formik>
      </div>
    </DialogWrapper>
  );
};
