import { ADDON_ANALYTICS_ITEM_CATEGORY } from '../SubscriptionAdditionalService/addOnAnalyticsUtils.js';
import { AnalyticsItemCategory, CURRENCY_CODE_EUR, EcommerceEventTypeV4 } from '../../common/analytics.js';
import { CommercialProductSubType } from '../../generated/api/commercialProductSubType.js';
import { CommercialProductType } from '../../generated/api/commercialProductType.js';
import { DEFAULT_ITEM_BRAND, DEFAULT_PAYMENT_TYPE } from '../../selfservice/common/googleEcommerceEvent.js';
import { VAT_AS_DECIMAL } from '../../common/utils/taxUtils.js';
import { centsToEuros } from '../../common/utils/priceUtils.js';
import { getItemName, getItemVariant } from '../../common/utils/analyticsUtils.js';
import type { AddedAddon } from '../../common/types/addon.js';
import type { CampaignAssociation } from '../../generated/api/campaignAssociation.js';
import type { CommercialProduct } from '../../generated/api/commercialProduct.js';
import type { ConfiguredOffer } from '../../common/types/commercialProduct.js';
import type {
  EcommerceAddToCartEventV4,
  EcommerceBeginCheckoutEventV4,
  EcommerceItem,
  EcommercePurchaseEventV4,
} from '../../common/analytics.js';

// Max bundle ID length in analytics backend
const BUNDLE_ID_MAX_LENGTH = 500;

export enum AnalyticsListNames {
  VOICE_LIST_NAME = 'OmaElisa Yrityksille puhelinliittymät',
  MBB_LIST_NAME = 'OmaElisa Yrityksille laajakaistaliittymät',
  NETTI_LITE_LIST_NAME = 'Nettilite saatavuus',
  LAITE_NETTI_LIST_NAME = 'OmaElisa Yrityksille laitenettiliittymät',
}

export enum AnalyticsVoiceSubscriptionNameBase {
  PREMIUM = 'Yritysliittymä 5G Premium',
  PERUS = 'Yritysliittymä 5G Perus',
  MINI = 'Yritysliittymä 5G Mini',
}

// We have rather limited information about the products available at purchase stage,
//  so we have to use the product name to decide whether it's a sub or not.
const subscriptionProducts = [
  'Laitenetti',
  'Yritysdata',
  // TODO does this really exist?
  'Yritysliittymä 5G New',
  'Yritysliittymä 5G Mini',
  'Yritysliittymä 5G Perus',
  'Yritysliittymä 5G Premium',
];
export const isSubscriptionProduct = (productName: string) =>
  subscriptionProducts.some(name => productName.includes(name));

const getBundleId = (commercialProductCode: string, addedAddOns?: AddedAddon[]): string | undefined => {
  // Only assign a bundle ID to orders with more than one unique item (= has to have addons)
  if (!addedAddOns || addedAddOns.length === 0) {
    return undefined;
  }
  if (addedAddOns && addedAddOns.length > 0) {
    const addOnString = addedAddOns?.map(addOn => addOn.addOnCode).toString();
    // If there is huge pile of addons included, the bundle ID will be truncated to max length 500 characters
    return (commercialProductCode + addOnString).substring(0, BUNDLE_ID_MAX_LENGTH);
  }
  return commercialProductCode;
};

const getCommercialProductYearlyPrice = (
  commercialProducts: CommercialProduct[],
  campaignAssociation?: CampaignAssociation,
  selectedAddonMonthlyRecurringCharge?: number
) => {
  const totalPrice = commercialProducts.reduce((total, commercialProduct) => {
    const monthlyRecurringCharge =
      selectedAddonMonthlyRecurringCharge ?? commercialProduct?.monthlyRecurringCharge ?? 0;
    const campaignDiscount = campaignAssociation?.monthlyRecurringCharge ?? 0;
    const oneTimeCharge = commercialProduct?.oneTimeCharge ?? 0;

    // Calculate the net monthly charge after applying the campaign discount
    const netMonthlyCharge = monthlyRecurringCharge - campaignDiscount;

    // Calculate the total yearly charge before quantity and one-time charges
    const yearlyRecurringCharge = netMonthlyCharge * 12;

    // Calculate the total price including the one-time charge
    return total + yearlyRecurringCharge + oneTimeCharge;
  }, 0);

  return centsToEuros(totalPrice);
};

const getAddOnYearlyPrice = (addOn: AddedAddon) => {
  const monthlyRecurringCharge = addOn?.monthlyPrice || 0;
  const oneTimeCharge = addOn?.oneTimePrice || 0;

  // Calculate the total yearly charge before quantity and one-time charges
  const yearlyRecurringCharge = monthlyRecurringCharge * 12;

  // Calculate the total price including the one-time charge and quantity
  const totalPrice = yearlyRecurringCharge + oneTimeCharge;

  return centsToEuros(totalPrice);
};

const getCumulativePrice = (eCommerceItems: EcommerceItem[]) =>
  eCommerceItems.reduce((total, item) => total + (item.price ?? 0) * (item.quantity ?? 1), 0);

const getProductSubTypeDetails = (
  productType?: CommercialProductType,
  productSubType?: CommercialProductSubType,
  itemListName?: string
): { itemCategory: AnalyticsItemCategory } => {
  if (itemListName === AnalyticsListNames.NETTI_LITE_LIST_NAME) {
    return {
      itemCategory: AnalyticsItemCategory.FIXED_BROADBAND,
    };
  }
  if (productType === CommercialProductType.SALES_PRODUCT) {
    return { itemCategory: AnalyticsItemCategory.SALES_PRODUCT };
  }
  switch (productSubType) {
    case CommercialProductSubType.MOBILE_VOICE:
      return {
        itemCategory: AnalyticsItemCategory.YRITYS_LIITTYMA,
      };
    case CommercialProductSubType.MOBILE_BROADBAND:
      return {
        itemCategory: AnalyticsItemCategory.YRITYS_DATA_LIITTYMA,
      };
    // TODO what exactly is this used for?
    // Laitenetti won't have productSubType
    default:
      return { itemCategory: AnalyticsItemCategory.LAITE_NETTI };
  }
};

// FIXME use this _for all events for the product_
// There are some product cards, that includes multiple product alternatives in one card (currently voice Perus and Mini).
// In those cases all alternatives should have the same item_id.
const getItemId = ({ commercialProductName, commercialProductCode, productType }: CommercialProduct): string => {
  if (productType === CommercialProductType.SALES_PRODUCT) {
    return commercialProductCode;
  }
  if (commercialProductName.includes(AnalyticsVoiceSubscriptionNameBase.PERUS)) {
    return btoa(AnalyticsVoiceSubscriptionNameBase.PERUS);
  } else if (commercialProductName.includes(AnalyticsVoiceSubscriptionNameBase.MINI)) {
    return btoa(AnalyticsVoiceSubscriptionNameBase.MINI);
  }
  return commercialProductCode;
};

export const getEcommerceItemFromCommercialProducts = (
  onlineModelName: string,
  commercialProducts: CommercialProduct[],
  quantity: number,
  eventType: EcommerceEventTypeV4,
  index?: number,
  coupon?: string,
  campaignAssociation?: CampaignAssociation,
  itemListName?: string,
  selectedAddonMonthlyRecurringCharge?: number
): EcommerceItem => {
  const { itemCategory } = getProductSubTypeDetails(
    commercialProducts[0].productType,
    commercialProducts[0].productSubType,
    itemListName
  );
  return {
    coupon,
    ...(index != null ? { index } : {}),
    item_brand: DEFAULT_ITEM_BRAND, // eslint-disable-line @typescript-eslint/naming-convention
    item_category: itemCategory, // eslint-disable-line @typescript-eslint/naming-convention
    item_id: getItemId(commercialProducts[0]), // eslint-disable-line @typescript-eslint/naming-convention
    ...(itemListName != null ? { item_list_name: itemListName } : {}), // eslint-disable-line @typescript-eslint/naming-convention
    item_name: getItemName(commercialProducts[0].commercialProductName, itemCategory, onlineModelName), // eslint-disable-line @typescript-eslint/naming-convention
    // eslint-disable-next-line @typescript-eslint/naming-convention
    item_variant: getItemVariant(eventType, commercialProducts[0].commercialProductName),
    price: getCommercialProductYearlyPrice(
      commercialProducts,
      campaignAssociation,
      selectedAddonMonthlyRecurringCharge
    ),
    quantity,
  };
};

const getEcommerceItemsFromAddedAddonOns = (
  quantity: number,
  eventType: EcommerceEventTypeV4,
  addOns?: AddedAddon[],
  bundleId?: string
): EcommerceItem[] =>
  addOns?.map(addOn => ({
    item_brand: DEFAULT_ITEM_BRAND, // eslint-disable-line @typescript-eslint/naming-convention
    ...(bundleId != null ? { item_bundle_id: bundleId } : {}), // eslint-disable-line @typescript-eslint/naming-convention
    item_category: ADDON_ANALYTICS_ITEM_CATEGORY, // eslint-disable-line @typescript-eslint/naming-convention
    item_id: addOn.addOnCode, // eslint-disable-line @typescript-eslint/naming-convention
    item_name: addOn.displayName, // eslint-disable-line @typescript-eslint/naming-convention
    item_variant: getItemVariant(eventType, addOn.displayName), // eslint-disable-line @typescript-eslint/naming-convention
    price: getAddOnYearlyPrice(addOn),
    quantity,
  })) || [];

const isLaitenettiEvent = (ecommerceItems: EcommerceItem[]) =>
  ecommerceItems.some(item => item.item_category === AnalyticsItemCategory.LAITE_NETTI);

// Laitenetti is a special case which includes addon by default. However, addon should not create a separate ecommerce item.
// Bundle the "actual" Laitenetti product item and addon item to one
const getLaitenettiItems = (eCommerceItems: EcommerceItem[]): EcommerceItem[] => {
  // Laitenetti product item, data not based on addons
  const laiteNettiItem = eCommerceItems.find(item => item.item_category === AnalyticsItemCategory.LAITE_NETTI);
  const yearlyPrice = eCommerceItems.reduce((total, item) => total + (item.price || 0), 0);
  return laiteNettiItem
    ? [{ ...laiteNettiItem, price: yearlyPrice, item_bundle_id: undefined }] // eslint-disable-line @typescript-eslint/naming-convention
    : [];
};

const getEcommerceItems = (
  onlineModelName: string,
  commercialProduct: CommercialProduct,
  quantity: number,
  eventType: EcommerceEventTypeV4,
  addedAddOns?: AddedAddon[],
  coupon?: string,
  campaignAssociation?: CampaignAssociation
): EcommerceItem[] => {
  const bundleId = getBundleId(commercialProduct.commercialProductCode, addedAddOns);
  const ecommerceItems = [
    {
      ...getEcommerceItemFromCommercialProducts(
        onlineModelName,
        [commercialProduct],
        quantity,
        eventType,
        undefined,
        coupon,
        campaignAssociation
      ),
      ...(bundleId != null ? { item_bundle_id: bundleId } : {}), // eslint-disable-line @typescript-eslint/naming-convention
    },
    ...getEcommerceItemsFromAddedAddonOns(quantity, eventType, addedAddOns, bundleId),
  ];

  return isLaitenettiEvent(ecommerceItems) ? getLaitenettiItems(ecommerceItems) : ecommerceItems;
};

export const getSubscriptionAddToCartAnalyticsEvent = (
  onlineModelName: string,
  commercialProduct: CommercialProduct,
  quantity: number,
  addedAddOns?: AddedAddon[],
  voucher?: string,
  campaignAssociation?: CampaignAssociation
): EcommerceAddToCartEventV4 => ({
  event: EcommerceEventTypeV4.ADD_TO_CART,
  ecommerce: {
    currency: CURRENCY_CODE_EUR,
    items: getEcommerceItems(
      onlineModelName,
      commercialProduct,
      quantity,
      EcommerceEventTypeV4.ADD_TO_CART,
      addedAddOns,
      voucher,
      campaignAssociation
    ),
  },
});

export const getBeginCheckoutAnalyticsEvent = (
  onlineModelName: string,
  commercialProduct: CommercialProduct,
  quantity: number,
  addedAddOns?: AddedAddon[],
  voucher?: string,
  campaignAssociation?: CampaignAssociation
): EcommerceBeginCheckoutEventV4 => ({
  event: EcommerceEventTypeV4.BEGIN_CHECKOUT,
  ecommerce: {
    items: getEcommerceItems(
      onlineModelName,
      commercialProduct,
      quantity,
      EcommerceEventTypeV4.BEGIN_CHECKOUT,
      addedAddOns,
      voucher,
      campaignAssociation
    ),
  },
});

export const getSubscriptionPurchaseAnalyticsEvent = (
  configuredOffer: ConfiguredOffer,
  orderId: string
): EcommercePurchaseEventV4 => {
  const configuredCommercialProducts = configuredOffer.selectedCommercialProducts;
  // There is no possibility to purchase different multiple different subscriptions at once, so the commercial
  // products and addons are same in all objects. Just pick the first one in the array.
  const { commercialProduct, addedAddOns, selectedCampaignAssociation } = configuredCommercialProducts[0];
  const coupon = configuredOffer.voucher;
  const quantity = (configuredCommercialProducts && configuredCommercialProducts.length) || 1;
  const eCommerceItems: EcommerceItem[] = getEcommerceItems(
    configuredOffer.onlineModelName!,
    commercialProduct,
    quantity,
    EcommerceEventTypeV4.PURCHASE,
    addedAddOns,
    coupon,
    selectedCampaignAssociation
  );
  const cumulativePrice = getCumulativePrice(eCommerceItems);

  return {
    event: EcommerceEventTypeV4.PURCHASE,
    payment_type: DEFAULT_PAYMENT_TYPE, // eslint-disable-line @typescript-eslint/naming-convention
    ecommerce: {
      transaction_id: orderId, // eslint-disable-line @typescript-eslint/naming-convention
      currency: CURRENCY_CODE_EUR,
      // cumulativePrice is already in euros
      value: cumulativePrice,
      // Not sure why we're doing the parse + toFixed here?
      tax: parseFloat((cumulativePrice * VAT_AS_DECIMAL).toFixed(2)),
      items: eCommerceItems,
      coupon,
      shipping: 0,
    },
  };
};
