import { getUserAccounts } from '../../components/Header/dynamic/headerFunctions.js';
import type { Address, Contact } from '../../generated/api/models.js';
import type { AuthenticatedUserState } from '../types/states.js';

export const hasPhone = (contact: Contact): boolean => {
  if (contact?.person) {
    return Boolean(contact.person.phoneNumber);
  }
  if (contact?.commonFunction) {
    return Boolean(contact.commonFunction.phoneNumber);
  }
  return false;
};

export const companyNameInitials = (companyName: string): string => {
  let result = companyName;
  if (companyName.length > 2) {
    const trimmedName = companyName.trim().replace(/\soy|oy\s|\sab|ab\s|\soyj|tmi\s|-|oyj\s/gi, '');
    const trimmedNameArray = trimmedName.split(/[ ]+/);
    if (trimmedNameArray.length >= 2) {
      result = `${trimmedNameArray[0].substr(0, 1)}${trimmedNameArray[1].substr(0, 1)}`;
    } else {
      result = `${trimmedName.substr(0, 2)}`;
    }
  }
  return result.toUpperCase();
};

export const formatAddress = (address?: Address): string | undefined => {
  if (address === undefined) {
    return undefined;
  }
  return (
    address.line1 + (address.line2 ? ' ' + address.line2 : '') + ', ' + address.postalCode + ', ' + address.postOffice
  );
};

export const getCompanyName = (authenticatedUser?: AuthenticatedUserState, accountMasterId?: string) => {
  if (!accountMasterId) {
    return '';
  }
  const userAccounts = getUserAccounts(authenticatedUser);
  return userAccounts.find(account => account.accountMasterId === accountMasterId)?.name ?? '';
};

export const isMultiBiz = (authenticatedUser?: AuthenticatedUserState): boolean =>
  !!(authenticatedUser && getUserAccounts(authenticatedUser).length > 1);

export const getPrimaryMdmId = (authenticatedUser?: AuthenticatedUserState) =>
  authenticatedUser?.contact?.accountContactRelationships?.find(acr => acr.isPrimaryAccount)?.accountMasterId;
