import { DeliveryOrderDetails } from '../../../../../components/DeliveryOrderDetails/DeliveryOrderDetails.js';
import { EmployeeMyOrdersWrapper } from '../../../../../components/EmployeeMyOrdersWrapper/EmployeeMyOrdersWrapper.js';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import { findItemById, stripActions } from '../../../../../common/utils/stateUtils.js';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { loadEmployeeCustomerOrders } from '../../../../../selfservice/actions/index.js';
import { myOrdersMsg, omaElisaForEmployeeMsg, t } from '../../../../../common/i18n/index.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useEffect } from 'react';
import { useTitle } from '../../../../../common/hooks/useTitle.js';
import type { DeliveryId, SubscriptionId } from '../../../../../common/constants/pathInterfaces.js';
import type { DeliveryOrder } from '../../../../../generated/api/models.js';
import type { State } from '../../../../../selfservice/common/store.js';

export const OwnOrderItemPath = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { subscriptionId, deliveryId } = useParams<SubscriptionId & DeliveryId>();
  const empCustomerOrders = useSelector(
    (s: State) => stripActions(s.user?.authenticated?.employeeCustomerOrders),
    deepEqual
  );
  const featureFlags = useStore<State>().getState().config.featureFlags;

  const empCustomerOrder = findItemById('customerOrderDisplayId', subscriptionId, empCustomerOrders);
  const deliveryOrder = deliveryId
    ? empCustomerOrder?.deliveryOrders.find(
        ({ deliveryOrderDisplayId }: DeliveryOrder) => deliveryOrderDisplayId === deliveryId
      )
    : undefined;

  useEffect(() => {
    dispatch(
      loadEmployeeCustomerOrders(subscriptionId, deliveryId, undefined, undefined, undefined, undefined, undefined)
    );
  }, [dispatch, featureFlags, subscriptionId, deliveryId]);

  useTitle([
    t.H6JV(omaElisaForEmployeeMsg),
    t.EG6F(myOrdersMsg),
    empCustomerOrder?.customerOrderDisplayId ?? '',
    deliveryOrder?.deliveryOrderName ?? '',
  ]);

  if (!(empCustomerOrder && deliveryOrder)) {
    return null;
  }

  return (
    <EmployeeMyOrdersWrapper>
      <DeliveryOrderDetails
        customerOrder={empCustomerOrder}
        deliveryOrder={deliveryOrder}
        breadCrumbPaths={[
          { name: t.H6JV(omaElisaForEmployeeMsg), path: paths.EMPLOYEE_HOME },
          { name: t.EG6F(myOrdersMsg), path: paths.EMPLOYEE_OWN_ORDERS },
          {
            name: empCustomerOrder.customerOrderDisplayId,
            path: generatePath(paths.EMPLOYEE_OWN_ORDER, { subscriptionId }),
          },
          { name: deliveryOrder.deliveryOrderName },
        ]}
        onClickCustomerOrder={() => {
          navigate(generatePath(paths.EMPLOYEE_OWN_ORDER, { subscriptionId }));
        }}
        isEmployee={true}
      />
    </EmployeeMyOrdersWrapper>
  );
};
