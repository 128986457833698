import { dsClass } from '../../common/constants/dsClasses.js';
import { formatSum, formatSumToString } from '../../common/utils/priceUtils.js';
import { getVatAsString } from '../../common/utils/taxUtils.js';
import { monthlyChargesMsg, oneTimePaymentMsg, t, totalMsg } from '../../common/i18n/index.js';
import type { OrderSummaryType } from './OrderSummary.js';
import type { TotalSumsItem } from '../../common/utils/commercialProductUtils.js';

import './SimpleSummary.scss';

export interface SimpleSummaryProps {
  totalSums: TotalSumsItem;
  summaryType: OrderSummaryType;
  priceIncludesVAT?: boolean;
  orderContainsBillingPeriodItem?: boolean;
}

export const SimpleSummary = (props: SimpleSummaryProps) => {
  const {
    priceIncludesVAT,
    totalSums: {
      totalMonthlyRecurringCharge,
      totalMonthlyRecurringChargeVat,
      totalOneTimeCharge,
      totalOneTimeChargeVat,
    },
    orderContainsBillingPeriodItem,
  } = props;

  const vatPrefix = `${priceIncludesVAT ? t.U8AN('incl.') : '+'} ${t.UZ6X('VAT')}`;

  const price = orderContainsBillingPeriodItem
    ? formatSumToString(totalMonthlyRecurringCharge)
    : t.YO7F('{}/Month', formatSumToString(totalMonthlyRecurringCharge));

  const vat = orderContainsBillingPeriodItem
    ? `${vatPrefix} ${formatSumToString(totalMonthlyRecurringChargeVat)}`
    : `${vatPrefix} ${t.YO7F('{}/Month', formatSumToString(totalMonthlyRecurringChargeVat))}`;

  return (
    <table className={`${dsClass.TABLE} ${dsClass.TABLE_CONTENT_VALIGN_TOP} of-simple-summary`}>
      <thead>
        <tr>
          <th colSpan={2} scope="col">
            <span className={dsClass.H3}>{t.CEQ2(totalMsg)}</span>{' '}
            <span className={`${dsClass.TEXT_S} ${dsClass.FONT_WEIGHT_600}`}>
              {t.E4TT('(VAT {}%)', priceIncludesVAT ? getVatAsString() : '0')}
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">{t.P6BC(monthlyChargesMsg)}</th>
          <td>
            <div className={dsClass.H4}>{price}</div>
            <div>{vat}</div>
          </td>
        </tr>
        <tr>
          <th scope="row">{t.GOBY(oneTimePaymentMsg)}</th>
          <td>
            <div className={dsClass.H4}>{formatSum(totalOneTimeCharge)}</div>
            <div>{`${vatPrefix} ${formatSum(totalOneTimeChargeVat)}`}</div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
