import { Dropdown } from '../../../../components/Dropdown/Dropdown.js';
import { Field, useFormikContext } from 'formik';
import { eInvoicingOperatorMsg, selectMsg, t } from '../../../i18n/index.js';
import { getElectronicInvoiceOperatorDropDownOptions } from '../../../../common/utils/billingAccountUtils.js';
import { requiredValidation } from '../../validation.js';
import type { BillChannel } from '../../../../generated/api/models.js';
import type { FormikValues } from 'formik';

interface BillElectronicOperatorProps {
  billChannels?: BillChannel[];
}

const billElectronicOperatorFieldName = 'billElectronicOperator';

export const BillElectronicOperatorDropdown = ({ billChannels }: BillElectronicOperatorProps) => {
  const { setFieldValue, values, errors } = useFormikContext<FormikValues>();
  const electronicInvoiceOptions = getElectronicInvoiceOperatorDropDownOptions(billChannels);

  const setElectronicOperator = (evt: HTMLLIElement) => {
    const newValue = evt.dataset.value;
    if (newValue) {
      setFieldValue(billElectronicOperatorFieldName, newValue);
    }
  };

  return (
    <Field
      id={billElectronicOperatorFieldName}
      name={billElectronicOperatorFieldName}
      label={t.WVLB(eInvoicingOperatorMsg)}
      items={electronicInvoiceOptions}
      selectedValue={values.billElectronicOperator}
      i18n_dropdown_placeholderText={t.QRYV(selectMsg)}
      i18n_dropdown_errorMessage={errors.billElectronicOperator}
      onValueChange={setElectronicOperator}
      validate={requiredValidation}
      component={Dropdown}
    />
  );
};
