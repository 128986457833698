import { Link } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import type { ReactNode } from 'react';

const TYPING_SPEED = 5;

interface ParagraphProps {
  children?: ReactNode;
  paragraph: (string | LinkStats)[];
}

interface ParagraphsProps {
  messages: string[];
}

interface TypingEffectProps extends ParagraphsProps {
  setIsTypingFinished: () => void;
}

interface LinkStats {
  link: string;
  url: string;
  start: number;
  end: number;
}

const calculateColPosition = (col: number, linkPos: LinkStats[]) => {
  for (let i = 0; i < linkPos.length; i++) {
    if (linkPos.length > 0) {
      if (col === linkPos[i].start) {
        return 2;
      }
      if (col === linkPos[i].start + linkPos[i].link.length) {
        return 4 + linkPos[i].url.length;
      }
    }
  }
  return 1;
};

const createParagraph = (message: string, col: number, linkPos: LinkStats[]): (string | LinkStats)[] => {
  const currentMessage = message.substring(0, col);
  const parts = linkPos.reduce((acc, cur, index) => {
    const firstPart = currentMessage.slice(index === 0 ? 0 : linkPos[index - 1].end + 1, cur.start);
    const linkAndUrl = currentMessage.slice(cur.start, cur.end + 1);
    const linkText = cur.link.slice(0, linkAndUrl.length - 1);
    const middlePart = linkAndUrl ? { link: linkText, url: cur.url, start: cur.start, end: cur.end } : '';
    const isLastPart = index === linkPos.length - 1 && currentMessage.length > cur.end;
    const lastPart = isLastPart ? currentMessage.slice(cur.end + 1) : '';
    return [...acc, firstPart, middlePart, lastPart].filter(Boolean);
  }, []);
  if (parts.length === 0) {
    return [currentMessage];
  }
  return parts;
};

const matchAll = (msg: string, regex: RegExp) => {
  return (function* () {
    let match;
    while ((match = regex.exec(msg)) !== null) {
      yield match;
    }
  })();
};

export const useLinkPosition = (messages: string[]) => {
  return useMemo(() => {
    return messages.map((msg: string) => {
      const regex = /\[(.*?)]\((.*?)\)/g;
      // TODO replace with String.matchAll when tsconfig.json target >= es2020 && lib >= es2020
      const matches = [...matchAll(msg, regex)];

      return matches.map(match => ({
        link: match[1],
        url: match[2].trim(),
        start: match.index,
        end: match.index + match[0].length - 1,
      }));
    });
  }, [messages]);
};

/**
 * Used for following current row and column in the flow
 */
export const useRowControl = (
  messages: string[],
  setIsTypingFinished: () => void,
  linkPos: LinkStats[][]
): [number[], boolean] => {
  const [rowAndCol, setRowAndCol] = useState<[number, number]>([0, 0]);
  const isLastRow = rowAndCol[0] === messages.length - 1;
  const isLastCol = rowAndCol[1] === messages[rowAndCol[0]].length;
  const msg = messages[rowAndCol[0]];

  useEffect(() => {
    if (isLastRow && isLastCol) {
      return setIsTypingFinished();
    }

    const timeout = setTimeout(() => {
      if (msg.length !== rowAndCol[1]) {
        setRowAndCol(([r, c]) => {
          return [r, c + calculateColPosition(c, linkPos[r])];
        });
      } else {
        setRowAndCol(([r]) => [r + 1, 0]);
      }
      return;
    }, TYPING_SPEED);
    return () => timeout && clearInterval(timeout);
  }, [isLastCol, isLastRow, linkPos, msg, rowAndCol, setIsTypingFinished]);

  return [rowAndCol, isLastRow && isLastCol];
};

/**
 * Used for creating typing effect that shows one letter at a time: l, lo, lor, lore, lorem, etc.
 */
export const useTypingEffect = (
  messages: string[],
  setIsTypingFinished: () => void
): [(string | LinkStats)[][], boolean] => {
  const linkPos = useLinkPosition(messages);
  const [rowAndCol, isTypingFinished] = useRowControl(messages, setIsTypingFinished, linkPos);

  const paragraphs = messages.reduce(
    (acc, cur, index, arr) =>
      index <= rowAndCol[0]
        ? [...acc, createParagraph(cur, rowAndCol[0] === index ? rowAndCol[1] : arr[index].length, linkPos[index])]
        : acc,
    []
  );
  return [paragraphs, isTypingFinished];
};

const Paragraph = ({ children, paragraph }: ParagraphProps) => {
  return (
    <p>
      {paragraph.map((part: string | LinkStats, i: number) =>
        typeof part === 'string' ? (
          part
        ) : (
          <Link key={i + part.start} to={part.url} target="_blank">
            {part.link}
          </Link>
        )
      )}
      {children}
    </p>
  );
};

export const Paragraphs = ({ messages }: ParagraphsProps) => {
  const linkPos = useLinkPosition(messages);
  return (
    <>
      {messages.reduce(
        (acc, cur, index) => [
          ...acc,
          <Paragraph key={index} paragraph={createParagraph(cur, cur.length, linkPos[index])} />,
        ],
        []
      )}
    </>
  );
};

export const TypingEffect = ({ messages, setIsTypingFinished }: TypingEffectProps) => {
  const [paragraphs, isTypingReady] = useTypingEffect(messages, setIsTypingFinished);
  return (
    <>
      {paragraphs.map((paragraph, i) => {
        const isLastRow = i === paragraphs.length - 1;
        return (
          <Paragraph key={i} paragraph={paragraph}>
            {isLastRow && !isTypingReady && <span className="cursor">|</span>}
          </Paragraph>
        );
      })}
    </>
  );
};
