import * as CL from '@design-system/component-library';
import { DeliveryType, OnlineOrder } from '../../generated/api/models.js';
import { DeviceCheckoutCartProduct } from '../DeviceCheckoutCartProduct/DeviceCheckoutCartProduct.js';
import { DeviceCheckoutListingItem } from '../DeviceCheckout/DeviceCheckoutListingItem.js';
import { FormProvider, useForm } from 'react-hook-form';
import { OrderNewsLetter } from '../OrderNewsLetter/OrderNewsLetter.js';
import { OrderSummary, OrderSummaryType } from '../OrderSummary/OrderSummary.js';
import { PaidAmountSummary } from '../PaidAmountSummary/PaidAmountSummary.js';
import { clearCartItems, clearDeliveryDetails } from '../../selfservice/actions/index.js';
import { clearShoppingBasketFromLocalstorage } from '../../selfservice/common/localStorageUtils.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { formatSum } from '../../common/utils/priceUtils.js';
import { getConfiguredCommercialProducts } from '../../common/utils/cartProductUtils.js';
import { getPriceMetaDataForCardPayment } from '../DeviceCheckout/deviceCheckoutCardPaymentUtils.js';
import { isCartItemBackordered } from '../DeviceCheckout/deviceCheckoutUtils.js';
import { isHideDeliveryMethodSelection } from '../../common/utils/checkoutUtils.js';
import { isStrictFeatureEnabled } from '../../common/utils/featureFlagUtils.js';
import { myOrdersMsg, ordersMsg, t } from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import type { CompanyInfoState, OnlineModelsState } from '../../common/types/states.js';
import type { ContactPerson } from '../../generated/api/models.js';
import type { DeviceCheckoutDeliveryDetailsType } from '../DeviceCheckoutDeliveryDetails/DeviceCheckoutDeliveryDetailsUtils.js';
import type { ShoppingCartItemForCheckout } from '../../common/types/checkout.js';
import type { State } from '../../selfservice/common/store.js';

import './DeviceCheckoutThankYou.scss';

export interface DeviceCheckoutThankYouProps {
  cartItems: Array<ShoppingCartItemForCheckout>;
  companyInfo: CompanyInfoState;
  contactInfo: ContactPerson;
  deliveryDetails: DeviceCheckoutDeliveryDetailsType;
  isEmployee?: boolean;
  isOrderPaidByCard?: boolean;
  onlineModels?: OnlineModelsState;
}

interface ThankYouDeliveryDetailsProps {
  deliveryDetails: DeviceCheckoutDeliveryDetailsType;
  skipDelivery: boolean;
  shipmentType?: OnlineOrder.ShipmentTypeEnum;
}

const isDeliveryToAddress = (deliveryMethodType: DeliveryType) =>
  [
    DeliveryType.MATKAHUOLTO_JAKOPAKETTI,
    DeliveryType.POSTI_EXPRESS_BUSINESS,
    DeliveryType.POSTI_EXPRESS_MORNING,
  ].includes(deliveryMethodType);

const OrdersLink = ({ isEmployee }: { isEmployee?: boolean }) =>
  isEmployee ? (
    <a href={paths.EMPLOYEE_OWN_ORDERS}>{t.EG6F(myOrdersMsg)}</a>
  ) : (
    <a href={paths.CUSTOMER_ORDERS}>{t.I9C0(ordersMsg)}</a>
  );

const ThankYouDeliveryDetails = ({ deliveryDetails, skipDelivery, shipmentType }: ThankYouDeliveryDetailsProps) => {
  const isDelivery = isDeliveryToAddress(deliveryDetails.deliveryMethodType);
  return skipDelivery ? (
    <></>
  ) : (
    <DeviceCheckoutListingItem
      description={
        <>
          <div className={dsClass.TEXT_L}>{deliveryDetails.deliveryMethodDescription}</div>
          {shipmentType === OnlineOrder.ShipmentTypeEnum.TOTAL_DELIVERY && (
            <div className={dsClass.MARGIN_TOP_3}>
              <div className={dsClass.TEXT_L}>{t.BSE3('Delivery')}:</div>
              <div>{t.LMKE('When all products are available')}</div>
            </div>
          )}
        </>
      }
      image={<CL.Icon icon={isDelivery ? 'delivery' : 'location'} size="xl" type="light" />}
      price={<div className={dsClass.H4}>{formatSum(deliveryDetails.deliveryPrice)}</div>}
    />
  );
};

// The form was substantially narrower than the max allowed, so this keeps it
// that way. Actual full width would be 12.
const FULL_WIDTH_ON_DESKTOP = 8;

const HALF_WIDTH_ON_DESKTOP_FULL_WIDTH_ON_OTHERS = {
  colWidthXS: 4,
  colWidthS: 6,
  colWidthM: 3,
  colWidthL: 4,
  colWidthXL: 4,
} as const;

const ThankYouHeading = ({
  contactInfo,
  isEmployee,
}: Pick<DeviceCheckoutThankYouProps, 'contactInfo' | 'isEmployee'>) => (
  <CL.GridRow justifyCenter>
    <CL.GridCol className={dsClass.GRID_COL} colWidth={FULL_WIDTH_ON_DESKTOP}>
      <h1 className={dsClass.H2}>{t.O4R6('Thank you for your order!')}</h1>
      <p>
        {isEmployee
          ? `${t.IT7Q(
              'Your order has been submitted. If your company has allowed receiving order confirmations, you will get a notification to your {} address soon.',
              contactInfo.email
            )} `
          : `${t.ZDQ2('We have sent an order confirmation to your email {}.', contactInfo.email)} `}
        <br />
        {t.CC7C('You can track your orders on ')}
        <OrdersLink isEmployee={isEmployee} />
        {t.X1DF(' page.')}
      </p>
    </CL.GridCol>
  </CL.GridRow>
);

const ThankYouContactInfo = ({
  companyInfo,
  orderDeliveryDetails,
}: {
  companyInfo: CompanyInfoState;
  orderDeliveryDetails: DeviceCheckoutDeliveryDetailsType;
}) => (
  <>
    <CL.GridRow justifyCenter>
      <CL.GridCol className={`${dsClass.GRID_COL} ${dsClass.MARGIN_TOP_3}`} colWidth={FULL_WIDTH_ON_DESKTOP}>
        <h2 className={`of-device-checkout-thankyou__company-details--heading ${dsClass.H3}`}>
          {t.HS3T('Customer details')}
        </h2>
      </CL.GridCol>
    </CL.GridRow>
    <CL.GridRow justifyCenter>
      <CL.GridCol
        className={`${dsClass.GRID_COL} ${dsClass.MARGIN_TOP_3}`}
        {...HALF_WIDTH_ON_DESKTOP_FULL_WIDTH_ON_OTHERS}
      >
        <div>{orderDeliveryDetails.companyName || ''}</div>
        <div>{orderDeliveryDetails.recipientName}</div>
        <div>{orderDeliveryDetails.recipientPhoneNumber}</div>
      </CL.GridCol>
      <CL.GridCol
        className={`${dsClass.GRID_COL} ${dsClass.MARGIN_TOP_3}`}
        {...HALF_WIDTH_ON_DESKTOP_FULL_WIDTH_ON_OTHERS}
      >
        {orderDeliveryDetails.pickupPoint?.address ? (
          <div className="of-device-checkout-thankyou__delivery-details--address">
            <div>{orderDeliveryDetails.pickupPoint.name}</div>
            <div>{orderDeliveryDetails.pickupPoint.address}</div>
          </div>
        ) : (
          orderDeliveryDetails.address && (
            <div>
              <div>{orderDeliveryDetails.address.line1}</div>
              <div>{orderDeliveryDetails.address.line2 || ''}</div>
              <div>
                {orderDeliveryDetails.address.postalCode}, {orderDeliveryDetails.address.postOffice}
              </div>
            </div>
          )
        )}
        <div>
          {t.MPA5('Business Id')} {companyInfo.businessId}
        </div>
      </CL.GridCol>
    </CL.GridRow>
  </>
);

const ThankYouProductsOrdered = ({
  cartItemsState,
  onlineModels,
  orderDeliveryDetails,
}: {
  cartItemsState: ShoppingCartItemForCheckout[];
  onlineModels?: OnlineModelsState;
  orderDeliveryDetails: DeviceCheckoutDeliveryDetailsType;
}) => (
  <>
    <CL.GridRow justifyCenter>
      <CL.GridCol className={`${dsClass.GRID_COL} ${dsClass.MARGIN_TOP_3}`} colWidth={FULL_WIDTH_ON_DESKTOP}>
        <h2 className={`of-device-checkout-thankyou__item-details--heading ${dsClass.H3}`}>
          {t.OT3E('Products ordered')}
        </h2>
      </CL.GridCol>
    </CL.GridRow>
    {cartItemsState.map((cartItem, index) => {
      return (
        <CL.GridRow justifyCenter key={cartItem.offerCode + index}>
          <CL.GridCol colWidth={FULL_WIDTH_ON_DESKTOP}>
            {/*
            This div only exists so that we have the correct border width...
            */}
            <div className="of-device-checkout-thankyou__product-list--bordered">
              <DeviceCheckoutCartProduct
                {...{
                  cartItem: cartItem,
                  getCartItemDomRefFns: () => {},
                  getAdditionalParametersFns: () => {},
                  setGetContactOrPurposeOfUseFn: () => {},
                  onUpdateDeviceChangeSelection: () => {},
                  readOnlyMode: true,
                  updateCartItem: () => {},
                  cartProductIndex: index,
                  initialPurposeOfUseOrContact: {},
                  setInitialPurposeOfUseOrContact: () => {},
                  isCartItemBackordered:
                    isCartItemBackordered(cartItem.offerCode, onlineModels) &&
                    orderDeliveryDetails.shipmentType === OnlineOrder.ShipmentTypeEnum.PARTIAL_DELIVERY,
                  holidays: [],
                  validatedPhoneNumbers: [],
                  phoneNumbers: [],
                  phoneNumbersLoading: false,
                }}
              />
            </div>
          </CL.GridCol>
        </CL.GridRow>
      );
    })}
  </>
);

export const DeviceCheckoutThankYou = ({
  cartItems,
  companyInfo,
  contactInfo,
  deliveryDetails,
  isEmployee,
  isOrderPaidByCard,
  onlineModels,
}: DeviceCheckoutThankYouProps): JSX.Element => {
  const dispatch = useDispatch();
  const [cartItemsState] = useState(cartItems);
  const [skipDelivery, setSkipDelivery] = useState(false);
  const methods = useForm();
  const [orderDeliveryDetails] = useState(deliveryDetails);
  const useShoppingBasket = useSelector((s: State) => isStrictFeatureEnabled(s.config.featureFlags?.shoppingBasket));

  useEffect(() => {
    // Clear the basket after successful order.
    dispatch(clearCartItems());
    dispatch(clearDeliveryDetails());
    setSkipDelivery(isHideDeliveryMethodSelection(cartItems));
  }, [dispatch]); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps

  if (useShoppingBasket) {
    clearShoppingBasketFromLocalstorage();
  }

  // TODO: Update the image of delivery cart, when available
  const cartItemsCommercialProducts = getConfiguredCommercialProducts(cartItemsState);
  const totalAmountPayableByCard = () =>
    getPriceMetaDataForCardPayment(cartItemsCommercialProducts, orderDeliveryDetails.deliveryPrice)
      .totalAmountToPayByCard;

  return (
    <FormProvider {...methods}>
      <form>
        <CL.Grid className={dsClass.GRID}>
          <ThankYouHeading contactInfo={contactInfo} isEmployee={isEmployee} />
          <ThankYouContactInfo companyInfo={companyInfo} orderDeliveryDetails={orderDeliveryDetails} />
          <ThankYouProductsOrdered
            cartItemsState={cartItemsState}
            onlineModels={onlineModels}
            orderDeliveryDetails={orderDeliveryDetails}
          />
          <ThankYouDeliveryDetails
            {...{
              skipDelivery,
              shipmentType: orderDeliveryDetails.shipmentType,
            }}
            deliveryDetails={orderDeliveryDetails}
          />
          <CL.GridRow justifyCenter>
            <CL.GridCol className={dsClass.GRID_COL} colWidth={FULL_WIDTH_ON_DESKTOP}>
              <OrderSummary
                priceIncludesVAT={isEmployee}
                summaryType={OrderSummaryType.DETAILS_CLOSED}
                commercialProducts={cartItemsCommercialProducts}
                deliveryCharges={skipDelivery ? 0 : orderDeliveryDetails.deliveryPrice}
              />
            </CL.GridCol>
          </CL.GridRow>
          {isOrderPaidByCard && (
            <CL.GridRow justifyCenter>
              <CL.GridCol className={`${dsClass.GRID_COL} ${dsClass.PADDING_TOP_4}`} colWidth={FULL_WIDTH_ON_DESKTOP}>
                <PaidAmountSummary amount={totalAmountPayableByCard()} summaryText={t.OTIM('Paid (incl. VAT)')} />
              </CL.GridCol>
            </CL.GridRow>
          )}
          <OrderNewsLetter />
        </CL.Grid>
      </form>
    </FormProvider>
  );
};
