import { TypeKeys } from '../actions/index.js';
import type { ActionsHistory } from '../common/store.js';
import type { SelfServiceActionTypes } from '../actions/index.js';
import type { ShoppingCartState } from '../../common/types/states.js';

export function shoppingCartReducer(
  state: (ShoppingCartState & ActionsHistory) | undefined,
  action: SelfServiceActionTypes
): (ShoppingCartState & ActionsHistory) | null {
  if (typeof state === 'undefined') {
    return null;
  }

  switch (action.type) {
    case TypeKeys.NOTIFY_SHOPPING_CART_CHANGE: {
      return {
        ...state,
        submitsQueued: true,
      };
    }

    case TypeKeys.SUBMIT_SHOPPING_CART_CHANGE: {
      return {
        ...state,
        submitInProgress: true,
        submitsQueued: false,
      };
    }

    case TypeKeys.SUBMIT_SHOPPING_CART_CHANGE_FULFILLED: {
      return {
        ...state,
        submitInProgress: false,
      };
    }

    case TypeKeys.SUBMIT_SHOPPING_CART_CHANGE_FAILED: {
      return {
        ...state,
        submitInProgress: false,
      };
    }

    default:
      return state;
  }
}
