import * as CL from '@design-system/component-library';
import { CompositeList } from '../CompositeList/index.js';
import { Grid } from '../Grid/Grid.js';
import { SubscriptionCategory } from '../../common/enums.js';
import {
  addYourDeviceMsg,
  addressOrNumberMsg,
  deviceShopMsg,
  devicesMsg,
  identifierMsg,
  imeiSlashSerialNumberMsg,
  internetSubscriptionsMsg,
  mobilePhoneNumberMsg,
  nameMsg,
  pukCodeMsg,
  purposeOfUseMsg,
  t,
  voiceSubscriptionsMsg,
} from '../../common/i18n/index.js';
import { formatTimestampToUTCDDMMYYYY } from '../../common/utils/dateUtils.js';
import { getSubscriptionIcon } from '../SubscriptionLists/subscriptionIcons.js';
import { getSubscriptionStatus } from '../../common/utils/subscriptionUtils.js';
import { isEppDeviceSubscriptionExpiring } from '../EmployeeSubscriptionDetails/eppSubscriptionUtils.js';
import { parseMobileSubscriptionAttrs } from '../EmployeeSubscriptionDetails/employeeSubscriptionUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useNavigate } from 'react-router-dom';
import type { CommonError } from '../../common/types/errors.js';
import type { CompositeListColumn } from '../CompositeListHeader/CompositeListHeader.js';
import type { CompositeListProps } from '../CompositeList/CompositeList.js';
import type { EmployeeSubscription } from '../../common/types/subscription.js';
import type { Subscription, SubscriptionAction } from '../../generated/api/models.js';

import './EmployeeSubscriptionsList.scss';

export interface EmployeeSubscriptionsListAttrs {
  columns?: CompositeListColumn<EmployeeSubscription>[];
  errors?: CommonError[];
  isCatalogPresent?: boolean;
  isUserProvisioned?: boolean;
  items?: EmployeeSubscription[];
  total?: number;
  sceneTab?: string;
  category: SubscriptionCategory;
  imagesBaseUrl?: string;
  subscriptionActions?: Array<SubscriptionAction>;
}

// TODO: Move these to dispatchers, when AngularJS router is no longer needed
interface EmployeeSubscriptionsListRoutes {
  onClickEmployeeSubscription: (subscriptionId: string, category: string) => void;
}

export type EmployeeSubscriptionsListProps = EmployeeSubscriptionsListAttrs & EmployeeSubscriptionsListRoutes;

export const createDeviceSubscriptionColumns = (
  subscriptionActions?: SubscriptionAction[]
): CompositeListColumn<EmployeeSubscription>[] => [
  {
    columnId: 'subscriptionName',
    heading: t.JJTM(devicesMsg),
    headingHideOnMobile: true,
    ref: 'subscriptionName',
    wide: true,
    value: subscription =>
      isEppDeviceSubscriptionExpiring(subscription) &&
      !getSubscriptionStatus(subscription, subscriptionActions).pendingActions
        ? [
            <>
              <div>{subscription.subscriptionName}</div>
              <div className="ea-disclaimertext ea-text ea-text--orange">
                {t.TJ9N('The device contract expires')} &nbsp;
                {formatTimestampToUTCDDMMYYYY(subscription.details?.commitmentEndDate)}
              </div>
            </>,
          ]
        : subscription.subscriptionName,
  },
  {
    columnId: 'subscriptionUserFriendlyId',
    heading: t.OE45(imeiSlashSerialNumberMsg),
    headingHideOnMobile: true,
    ref: 'subscriptionUserFriendlyId',
  },
  {
    columnId: 'userName',
    heading: t.Y8KX(purposeOfUseMsg),
    headingHideOnMobile: true,
    value: (subscription: Subscription) =>
      subscription.subscriptionContactName || subscription.subscriptionPurposeOfUse || '',
  },
];

export const createMobileSubscriptionsColumns = (): CompositeListColumn<EmployeeSubscription>[] => [
  {
    columnClasses: ['of-employee-subscriptions-list__name-column'],
    columnId: 'userName',
    heading: t.VGFI(nameMsg),
    headingHideOnMobile: true,
    value: (subscription: Subscription) =>
      subscription.subscriptionContactName || subscription.subscriptionPurposeOfUse || '',
  },
  {
    columnClasses: ['of-employee-subscriptions-list__mobile-column'],
    columnId: 'mobileNumber',
    heading: t.FRYN(mobilePhoneNumberMsg),
    headingHideOnMobile: true,
    value: (subscription: Subscription) => parseMobileSubscriptionAttrs(subscription).mobileNumber,
  },
  {
    columnClasses: ['of-employee-subscriptions-list__puk-column'],
    columnId: 'puk1',
    heading: t.K5TL(pukCodeMsg),
    value: (subscription: Subscription) => parseMobileSubscriptionAttrs(subscription).puk1,
  },
];

export const createBroadbandSubscriptionsColumns = (): CompositeListColumn<EmployeeSubscription>[] => [
  {
    columnClasses: ['of-employee-subscriptions-list__name-column'],
    columnId: 'subscriptionName',
    heading: t.SEYV(internetSubscriptionsMsg),
    headingHideOnMobile: true,
    value: (subscription: Subscription) => subscription.subscriptionName || '',
  },
  {
    columnClasses: ['of-employee-subscriptions-list__puk-column'],
    columnId: 'subscriptionUserFriendlyId',
    heading: t.LK7B(addressOrNumberMsg),
    value: (subscription: Subscription) => subscription.subscriptionUserFriendlyId || '',
  },
  {
    columnClasses: ['of-employee-subscriptions-list__mobile-column'],
    columnId: 'subscriptionPurposeOfUse',
    heading: t.L9QG(identifierMsg),
    headingHideOnMobile: true,
    value: (subscription: Subscription) => subscription.subscriptionContactName || '',
  },
];

const getColumns = (
  category: string,
  subscriptionActions?: SubscriptionAction[]
): CompositeListColumn<EmployeeSubscription>[] => {
  switch (category) {
    case SubscriptionCategory.BROADBAND:
      return createBroadbandSubscriptionsColumns();

    case SubscriptionCategory.DEVICE:
      return createDeviceSubscriptionColumns(subscriptionActions);

    case SubscriptionCategory.VOICE:
      return createMobileSubscriptionsColumns();

    default:
      return [];
  }
};

const EmptyListElement = ({ category }: { category: string }) => {
  switch (category) {
    case SubscriptionCategory.BROADBAND:
      return <span>{t.HDNG('No internet subscriptions')}</span>;

    case SubscriptionCategory.VOICE:
      return <span>{t.SOV8('No voice subscriptions')}</span>;

    case SubscriptionCategory.DEVICE:
      return <span>{t.LRT3('No Devices')}</span>;

    default:
      return <></>;
  }
};

const ListHeading = ({ category }: { category: string }) => {
  switch (category) {
    case SubscriptionCategory.BROADBAND:
      return <h3 className="ea-h3">{t.SEYV(internetSubscriptionsMsg)}</h3>;

    case SubscriptionCategory.VOICE:
      return <h3 className="ea-h3">{t.AR0B(voiceSubscriptionsMsg)}</h3>;

    case SubscriptionCategory.DEVICE:
      return <h3 className="ea-h3">{t.JJTM(devicesMsg)}</h3>;

    default:
      return <></>;
  }
};

const TopRightElement = ({ category, isCatalogPresent }: { category: string; isCatalogPresent: boolean }) => {
  const navigate = useNavigate();
  return (
    <>
      {category === SubscriptionCategory.VOICE && (
        <CL.Button
          color="light"
          className="of-employee-subscriptions-list__button-subscription"
          onClick={() => {
            navigate(paths.EMPLOYEE_VOICE_SUBSCRIPTION_ADD);
          }}
        >
          {t.FIAV('Add voice subscription')}
        </CL.Button>
      )}
      {category === SubscriptionCategory.BROADBAND && (
        <CL.Button
          color="light"
          className="of-employee-subscriptions-list__button-subscription"
          onClick={() => {
            navigate(paths.EMPLOYEE_BROADBAND_SUBSCRIPTION_ADD);
          }}
        >
          {t.W9UE('Add internet subscription')}
        </CL.Button>
      )}
      {category === SubscriptionCategory.DEVICE && isCatalogPresent && (
        <div className="of-employee-subscriptions-list__device_buttons">
          <div className="of-employee-subscriptions-list__device_buttons__button">
            <CL.Button
              color="primary"
              className="of-employee-subscriptions-list__button-device"
              onClick={() => navigate(paths.EMPLOYEE_DEVICE_STORE)}
            >
              {t.C6OR(deviceShopMsg)}
            </CL.Button>
          </div>
          <div className="of-employee-subscriptions-list__device_buttons__button">
            <CL.Button
              color="light"
              className="of-employee-subscriptions-list__button-device"
              onClick={() => {
                navigate(paths.EMPLOYEE_DEVICE_ADD);
              }}
            >
              {t.SQW6(addYourDeviceMsg)}
            </CL.Button>
          </div>
        </div>
      )}
    </>
  );
};

export const EmployeeSubscriptionsList = ({
  isUserProvisioned,
  items,
  onClickEmployeeSubscription,
  category,
  imagesBaseUrl,
  subscriptionActions,
  isCatalogPresent,
}: EmployeeSubscriptionsListProps) => {
  const listProps: CompositeListProps<EmployeeSubscription> = {
    classes: ['of-employee-subscriptions-list'],
    columns: getColumns(category, subscriptionActions),
    getRowId: (subscription: EmployeeSubscription) => subscription.subscriptionId,
    items: isUserProvisioned ? items : [],
    onSelectRow: (id: string) => onClickEmployeeSubscription(id, category),
    showHintIcon: (subscription: Subscription) =>
      isEppDeviceSubscriptionExpiring(subscription) &&
      !getSubscriptionStatus(subscription, subscriptionActions).pendingActions,
    imagesBaseUrl,
    rowIconImageUrl: (subscription: Subscription) => subscription.details?.device?.image,
    rowIcon: (subscription: Subscription) =>
      getSubscriptionIcon(subscription.subscriptionType, subscription.subscriptionSubType),
    topWrapOnMobile: true,
    emptyListElement: <EmptyListElement category={category} />,
    topLeft: <ListHeading category={category} />,
    topRight: <TopRightElement {...{ items, category }} isCatalogPresent={!!isCatalogPresent} />,
  };

  return (
    <Grid>
      <CompositeList {...listProps} />
    </Grid>
  );
};
