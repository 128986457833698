/**
 * SFDC Community API
 * # Summary Elisa B2B API for an administrator of a company to query and modify the company information.  # Usage This API is used in the `https://yrityksille.elisa.fi/omaelisa` pages, but it is possible to later on expose this documentation publicly and make the endpoint queryable via external clients.  # Identifiers All entities in the API have a globally unique, internal identifier field named with the format [object_type]Id, e.g. `invoiceId`, which is also used in some of the API endpoint urls, e.g. `/api/v1/selfservice/invoices/{invoiceId}`. This value is not meaningful to the end user but is only used as an internally in the API.  Most entities also have a displayable identifier named in the format [object_type]DisplayId. This value can and should be shown to the end user, as it is also printed in relevant documents. It is also used in some of the public URLs, e.g. `https://yrityksille.elisa.fi/omaelisa/laskut/{invoiceDisplayId}`. Because of the user might land directly into a URL of a single entity with a display id, some of the API endpoints here also support querying via the display id, e.g. `/api/v1/selfservice/invoices?invoiceDisplayId={invoiceDisplayId}`.  The reason for using two identifiers instead of only one display id, is to make sure that every object will always have exactly one REST API URL that will never collide with another. While collisions of display ids should not happen in production, the id fields are guaranteed to be unique even across different test environments. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NumberRange } from './numberRange.js';
import { CallerLineIdType } from './callerLineIdType.js';
import { MobilePbxServiceLevelProduct } from './mobilePbxServiceLevelProduct.js';


/**
 * Details for subscription type MOBILE_PBX_LITE, Puheratkaisu Vakio.
 */
export interface SubscriptionDetailsPbxSolution { 
    /**
     * Modification of the caller line ID visible to the person called. SFDC: csord__Subscription__c.Caller_line_ID_Service__c.
     */
    callerLineIdService: SubscriptionDetailsPbxSolution.CallerLineIdServiceEnum;
    /**
     * Prefix number used to display regular mobile number when calling. SFDC: csord__Subscription__c.Mobile_Call_Prefix__c.
     */
    mobileCallPrefix?: string;
    /**
     * Prefix number used to display PSTN number when calling. SFDC: csord__Subscription__c.PSTN_Call_Prefix__c.
     */
    pstnCallPrefix?: string;
    /**
     * Whether mobile or PSTN number is called when dialing extension. SFDC: csord__Subscription__c.Extension_Dial_Target__c.
     */
    extensionDialTarget?: SubscriptionDetailsPbxSolution.ExtensionDialTargetEnum;
    /**
     * The landline number presented to the person called if caller line ID modification is selected. SFDC: csord__Subscription__c.Caller_ID_Number__c.
     */
    callerLineIdNumber?: string;
    /**
     * Corporate or extension number ranges in this mobile PBX solution
     */
    numberRanges: Array<NumberRange>;
    /**
     * Language for solution. SFDC: csord__Subscription__c.AddOn_Language__c.
     */
    solutionLanguage?: string;
    callerLineIdForCalls?: CallerLineIdType;
    callerLineIdForSms?: CallerLineIdType;
    mobilePbxServiceLevelProduct?: MobilePbxServiceLevelProduct;
}
export namespace SubscriptionDetailsPbxSolution {
    export type CallerLineIdServiceEnum = 'NOT_IN_USE' | 'EXTERNAL' | 'ALWAYS';
    export const CallerLineIdServiceEnum = {
        NOT_IN_USE: 'NOT_IN_USE' as CallerLineIdServiceEnum,
        EXTERNAL: 'EXTERNAL' as CallerLineIdServiceEnum,
        ALWAYS: 'ALWAYS' as CallerLineIdServiceEnum
    };
    export type ExtensionDialTargetEnum = 'MOBILE' | 'PSTN';
    export const ExtensionDialTargetEnum = {
        MOBILE: 'MOBILE' as ExtensionDialTargetEnum,
        PSTN: 'PSTN' as ExtensionDialTargetEnum
    };
}


