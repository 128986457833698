import * as CL from '@design-system/component-library';
import { GridColumn } from '../../BillingAccountDetails/components/GridColumn.js';
import { NumberPrivacyFields, NumberPrivacyType } from './NumberPrivacyFields.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { useFormContext } from 'react-hook-form';
import type { PbxDirectoryDetails, VoiceDirectoryDetails } from '../../../common/types/subscription.js';

interface UserNumberPrivacyProps {
  pbxType?: string;
  voiceDirectoryDetails?: VoiceDirectoryDetails;
  corporateDirectoryDetails?: PbxDirectoryDetails;
}

export const UserNumberPrivacy = ({
  pbxType,
  corporateDirectoryDetails,
  voiceDirectoryDetails,
}: UserNumberPrivacyProps) => {
  const { watch } = useFormContext();

  const numberPrivacyMobile = watch('numberPrivacyMobile');
  const numberPrivacyCorporate = watch('numberPrivacyCorporate');

  if (!numberPrivacyMobile && !numberPrivacyCorporate) {
    return null;
  }

  return (
    <CL.Grid className={dsClass.MARGIN_TOP_4}>
      <CL.GridRow className="of-usernumber-and-publicity__edit_privacy">
        <GridColumn className={dsClass.PADDING_TOP_2}>
          <NumberPrivacyFields
            numberPrivacy={numberPrivacyMobile}
            directoryDetails={voiceDirectoryDetails}
            type={NumberPrivacyType.PRIVATE}
          />
        </GridColumn>
        <GridColumn className={dsClass.PADDING_TOP_2}>
          {pbxType && (
            <NumberPrivacyFields
              numberPrivacy={numberPrivacyCorporate}
              directoryDetails={corporateDirectoryDetails}
              type={NumberPrivacyType.CORPORATE}
            />
          )}
        </GridColumn>
      </CL.GridRow>
    </CL.Grid>
  );
};
