import { CustomerOrderSystemError } from '../../../../../components/CustomerOrders/CustomerOrders.js';
import { SubscriptionActionDetailsView } from '../../../../../components/SubscriptionActionDetails/SubscriptionActionDetailsView.js';
import { omaElisaForCompaniesMsg, redeemRequestMsg, t } from '../../../../../common/i18n/index.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useLoaderData, useParams } from 'react-router-dom';
import type { RequestId } from '../../../../../common/constants/pathInterfaces.js';
import type { SubscriptionActionsResponse } from '../../../../../generated/api/subscriptionActionsResponse.js';

export const CustomerOrderRedeemRequestPath = () => {
  const { requestId } = useParams<RequestId>();
  const { subscriptionActions } = useLoaderData() as SubscriptionActionsResponse;
  return (
    <CustomerOrderSystemError>
      <SubscriptionActionDetailsView
        breadCrumbPaths={[
          { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
          { name: t.Z1M1(redeemRequestMsg), path: paths.CUSTOMER_ORDER_REDEEM_REQUESTS },
          { name: `${requestId}` },
        ]}
        subscriptionAction={subscriptionActions[0]}
      />
    </CustomerOrderSystemError>
  );
};
