import * as React from 'react';
import { qrCodeGenerationFailureMsg, qrCodeMsg, t } from '../../common/i18n/index.js';

export interface QrCodeImgProps {
  src: string;
}

export const QrCodeImg = (props: QrCodeImgProps) => {
  return (
    <img
      src={props.src}
      alt={t.CAQ7(qrCodeMsg)}
      onError={(e: React.ChangeEvent<HTMLImageElement>) => {
        e.target.alt = t.ARDH(qrCodeGenerationFailureMsg);
      }}
      // eslint-disable-next-line react/no-unknown-property
      onAbort={(e: React.ChangeEvent<HTMLImageElement>) => {
        e.target.alt = t.ARDH(qrCodeGenerationFailureMsg);
      }}
    />
  );
};
