import type { PurposeOfUseOrContact } from '../../common/types/subscription.js';
import type { SelectedPurposeOfUseOrContact } from '../../common/enums.js';

export interface ContactOrPurposeOfUseFormValues {
  selectionTab: SelectedPurposeOfUseOrContact;
  selectContact?: string;
  purposeOfUse?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  costCenter?: string;
  employeeNumber?: string;
}

export const formValuesToPurposeOfUseOrContact = (
  formValues: ContactOrPurposeOfUseFormValues
): PurposeOfUseOrContact => ({
  selected: formValues.selectionTab,
  contactId: formValues?.selectContact ? formValues?.selectContact : undefined,
  purposeOfUse: formValues?.purposeOfUse,
  firstName: formValues?.firstName,
  lastName: formValues?.lastName,
  phoneNumber: formValues?.phoneNumber,
  email: formValues?.email,
  costCenter: formValues?.costCenter,
  employeeNumber: formValues?.employeeNumber,
});

export enum ContactCreationType {
  CREATE_NEW_CONTACT = 'ADD_NEW',
  COPIED_CONTACT = 'COPIED_CONTACT',
  CREATE_NEW_CONTACT_FORCE_UPSERT = 'CREATE_NEW_CONTACT_FORCE_UPSERT',
}
