import { TypeKeys } from '../actions/index.js';
import type { ActionsHistory } from '../common/store.js';
import type { SelfServiceActionTypes } from '../actions/index.js';
import type { SnapInsChatState } from '../../common/types/snapInChat.js';

export function snapInsChatReducer(
  state: (SnapInsChatState & ActionsHistory) | null,
  action: SelfServiceActionTypes
): (SnapInsChatState & ActionsHistory) | null {
  if (typeof state === 'undefined') {
    return null;
  }
  switch (action.type) {
    case TypeKeys.SET_CHAT_SCRIPT_LOAD_STATUS: {
      return {
        ...state,
        scriptLoadStatus: action.scriptLoadStatus,
      };
    }

    case TypeKeys.SET_CHAT_STYLE_LOAD_STATUS: {
      return {
        ...state,
        styleLoadStatus: action.styleLoadStatus,
      };
    }

    default:
      return state;
  }
}
