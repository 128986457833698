import * as CL from '@design-system/component-library';
import { Link } from 'react-router-dom';
import { dsClass } from '../../common/constants/dsClasses.js';

import './HeadingWithButtonStyleLink.scss';

export interface HeadingWithButtonStyleLinkProps {
  headingText: string;
  linkText: string;
  linkHref: string;
}

export const HeadingWithButtonStyleLink = ({ headingText, linkText, linkHref }: HeadingWithButtonStyleLinkProps) => {
  return (
    <div className="heading-with-button-style-link">
      <h3>{headingText}</h3>
      <p>
        <Link
          className={`${dsClass.LINK} ${dsClass.LINK_STYLE_LINK_BUTTON} ${dsClass.LINK_BUTTON_COLOR_LIGHT} ${dsClass.LINK_BUTTON_SIZE_S}`}
          to={linkHref}
        >
          {linkText}{' '}
          <span className={dsClass.LINK_LAST_OBJECT}>
            <CL.Icon icon="arrow-right-alt" />
          </span>
        </Link>
      </p>
    </div>
  );
};
