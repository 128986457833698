import { ActionPhase } from '../common/storeUtils.js';
import { TypeKeys } from '../actions/index.js';
import { reduceDisplayItemsLoadAction } from './reducerUtils.js';
import { updateActionStatePhase } from '../common/index.js';
import type { AccountKeyUser } from '../../generated/api/models.js';
import type { AccountKeyUsersState } from '../../common/types/states.js';
import type { ActionsHistory } from '../common/store.js';
import type { SelfServiceActionTypes } from '../actions/index.js';

export function accountKeyUsersReducer(
  state: (AccountKeyUsersState & ActionsHistory) | undefined | null,
  action: SelfServiceActionTypes
): (AccountKeyUsersState & ActionsHistory) | null {
  if (typeof state === 'undefined') {
    return null;
  }

  switch (action.type) {
    case TypeKeys.LOAD_ACCOUNT_KEY_USERS: {
      const itemsState = reduceDisplayItemsLoadAction<TypeKeys.LOAD_ACCOUNT_KEY_USERS, AccountKeyUser>(
        action,
        state,
        'internalUserId',
        true
      );
      return {
        ...itemsState,
        saving: false,
      };
    }

    case TypeKeys.LOAD_ACCOUNT_KEY_USERS_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.LOAD_ACCOUNT_KEY_USERS,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        items: action.accountKeyUsers,
        saving: false,
      };
    }

    case TypeKeys.LOAD_ACCOUNT_KEY_USERS_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.LOAD_ACCOUNT_KEY_USERS,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
        saving: false,
      };
    }

    case TypeKeys.LOG_OUT: {
      return null;
    }

    default:
      return state;
  }
}
