import * as CL from '@design-system/component-library';
import { Link } from 'react-router-dom';
import { LinkableAccordion } from '../LinkableAccordion/index.js';
import { SubscriptionLink } from '../SubscriptionLists/SubscriptionLink.js';
import {
  addressOrNumberMsg,
  billingAccountMsg,
  devicesMsg,
  idNumberMsg,
  imeiSlashSerialNumberMsg,
  internetSubscriptionsMsg,
  serviceMsg,
  servicesMsg,
  t,
  viewAllMsg,
  voiceSubscriptionsMsg,
} from '../../common/i18n/index.js';
import { formatPhoneNumber } from '../../common/utils/phoneNumberUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import type { ReactNode } from 'react';
import type { SubscriptionSearchResponse } from '../../generated/api/subscriptionSearchResponse.js';

import './SubscriptionsAccordion.scss';

interface SubscriptionsAccordionProps {
  title: string;
  emptyMsg: string;
  link: ReactNode;
  cols: string[];
  rows: Array<ReactNode[]>;
}

const MAX_ITEMS = 20;

export const SubscriptionsAccordion = ({ title, cols, rows, link, emptyMsg }: SubscriptionsAccordionProps) => {
  return (
    <LinkableAccordion
      heading={title}
      badge={t.UDN6('{} pcs', rows.length.toString())}
      className="of-subscriptions-accordion"
    >
      {rows.length > 0 && (
        <CL.Table
          tableType="bordered"
          columns={cols.map((col, i) => ({ key: `c${i}`, label: col }))}
          rows={rows.slice(0, MAX_ITEMS).map(([c0, c1, c2]) => ({ c0, c1, c2 }))}
        />
      )}
      {rows.length === 0 && emptyMsg}
      {rows.length > MAX_ITEMS && <div style={{ margin: '24px 0 0 16px', display: 'inline-block' }}>{link}</div>}
    </LinkableAccordion>
  );
};

interface ContactSubscriptionsAccordionProps {
  subscriptions?: SubscriptionSearchResponse[];
}

export const DeviceSubscriptionsAccordion = ({ subscriptions }: ContactSubscriptionsAccordionProps) => {
  return (
    <SubscriptionsAccordion
      title={t.JJTM(devicesMsg)}
      emptyMsg={t.C9DH('The user has no devices.')}
      cols={[t.TPVQ('Device'), t.OE45(imeiSlashSerialNumberMsg), t.IFT9(billingAccountMsg)]}
      rows={
        subscriptions?.map(sub => [
          <SubscriptionLink subscription={sub.result} path={paths.PS_DEVICE} key={sub.result.subscriptionId} />,
          formatPhoneNumber(sub.result.subscriptionUserFriendlyId || ''),
          sub.result.billingAccountDisplayId || '',
        ]) || []
      }
      link={<Link to={paths.PS_DEVICES}>{t.SF4C(viewAllMsg)}</Link>}
    />
  );
};

export const VoiceSubscriptionsAccordion = ({ subscriptions }: ContactSubscriptionsAccordionProps) => {
  return (
    <SubscriptionsAccordion
      title={t.AR0B(voiceSubscriptionsMsg)}
      emptyMsg={t.PWLF('The user has no voice subscriptions.')}
      cols={[t.P674('Subscription'), t.LK7B(addressOrNumberMsg), t.IFT9(billingAccountMsg)]}
      rows={
        subscriptions?.map(sub => [
          <SubscriptionLink
            subscription={sub.result}
            path={paths.PS_MOBILE_SUBSCRIPTION}
            key={sub.result.subscriptionId}
          />,
          formatPhoneNumber(sub.result.subscriptionUserFriendlyId || ''),
          sub.result.billingAccountDisplayId || '',
        ]) || []
      }
      link={<Link to={paths.PS_MOBILE_SUBSCRIPTIONS}>{t.SF4C(viewAllMsg)}</Link>}
    />
  );
};

export const BroadbandSubscriptionsAccordion = ({ subscriptions }: ContactSubscriptionsAccordionProps) => {
  return (
    <SubscriptionsAccordion
      title={t.SEYV(internetSubscriptionsMsg)}
      emptyMsg={t.W3P0('The user has no internet subscriptions.')}
      cols={[t.P674('Subscription'), t.LK7B(addressOrNumberMsg), t.IFT9(billingAccountMsg)]}
      rows={
        subscriptions?.map(sub => [
          <SubscriptionLink
            subscription={sub.result}
            path={paths.PS_BROADBAND_SUBSCRIPTION}
            key={sub.result.subscriptionId}
          />,
          formatPhoneNumber(sub.result.subscriptionUserFriendlyId || ''),
          sub.result.billingAccountDisplayId || '',
        ]) || []
      }
      link={<Link to={paths.PS_BROADBAND_SUBSCRIPTIONS}>{t.SF4C(viewAllMsg)}</Link>}
    />
  );
};

export const ServiceSubscriptionsAccordion = ({ subscriptions }: ContactSubscriptionsAccordionProps) => {
  return (
    <SubscriptionsAccordion
      title={t.MBG9(servicesMsg)}
      emptyMsg={t.FD7W('The user has no services.')}
      cols={[t.CDME(serviceMsg), t.YTH3(idNumberMsg), t.IFT9(billingAccountMsg)]}
      rows={
        subscriptions?.map(sub => [
          <SubscriptionLink subscription={sub.result} path={paths.PS_SERVICE} key={sub.result.subscriptionId} />,
          formatPhoneNumber(sub.result.subscriptionUserFriendlyId || ''),
          sub.result.billingAccountDisplayId || '',
        ]) || []
      }
      link={<Link to={paths.PS_SERVICE}>{t.SF4C(viewAllMsg)}</Link>}
    />
  );
};
