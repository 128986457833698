import { CustomerOrderDetails } from '../../../../../components/CustomerOrderDetails/CustomerOrderDetails.js';
import { CustomerOrderSystemError } from '../../../../../components/CustomerOrders/CustomerOrders.js';
import { generatePath, useLoaderData, useParams } from 'react-router-dom';
import { loadContacts } from '../../../../../selfservice/actions/index.js';
import { omaElisaForCompaniesMsg, ordersMsg, t } from '../../../../../common/i18n/index.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useDispatch } from 'react-redux';
import { useSearchParams } from '../../../../../common/hooks/useSearchParams';
import type { CustomerOrderAdditionalInfo } from '../../../../../generated/api/customerOrderAdditionalInfo.js';
import type { CustomerOrderLoaderData } from '../../../../../common/loaders.js';
import type { OrderId } from '../../../../../common/constants/pathInterfaces.js';
import type { Subscription } from '../../../../../generated/api/subscription.js';

const getOrdererSubscription = (
  additionalInfo?: Record<string, CustomerOrderAdditionalInfo>,
  orderId?: string
): Subscription[] | undefined => {
  if (additionalInfo && orderId) {
    return additionalInfo[orderId]?.ordererSubscriptions;
  }
  return undefined;
};

export const CustomerOrderPath = () => {
  const dispatch = useDispatch();
  const { orderId } = useParams<OrderId>();
  const { customerOrder, additionalInfo, companyInfo, contacts, billingAccounts } =
    useLoaderData() as CustomerOrderLoaderData;
  const ordererSubscriptions =
    orderId && additionalInfo ? getOrdererSubscription({ [orderId]: additionalInfo }, orderId) : undefined;
  const { mdmId } = useSearchParams<{ mdmId: string }>();

  return (
    <CustomerOrderSystemError>
      <CustomerOrderDetails
        buildDeliveryOrderHref={(cid, did) =>
          generatePath(paths.CUSTOMER_ORDER_PRODUCT, { orderId: cid, subscriptionId: did })
        }
        contacts={contacts?.searchResults}
        customerOrder={customerOrder}
        deviceSubscriptions={ordererSubscriptions}
        breadCrumbPaths={[
          { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
          { name: t.I9C0(ordersMsg), path: paths.CUSTOMER_ORDERS },
          { name: customerOrder?.customerOrderDisplayId || '…' },
        ]}
        customerType={companyInfo?.customerType}
        onShowContacts={displayId => dispatch(loadContacts(displayId))}
        billingAccounts={billingAccounts.searchResults}
        mdmId={mdmId!}
      />
    </CustomerOrderSystemError>
  );
};
