export type ImpactedUser = {
  userName: string;
  email: string;
  mobile: string;
};

export type EnableMfa = {
  optedForMfaEnable: boolean;
};

export const ImpactedUserEditableFields = {
  userName: 'userName',
  email: 'email',
  mobile: 'mobile',
} as const;
