import { ActionBlock } from '../ActionBlock/ActionBlock.js';
import { PageMargins } from '../../components/PageMargins/PageMargins.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import type { ActionGroupData } from '../cmsSchema.js';

import './ActionGroup.scss';

export const ActionGroup = ({ actionBlocks = [] }: ActionGroupData) => (
  <PageMargins
    as="padding"
    className={`${dsClass.BACKGROUND_COLOR_NEUTRAL_200_LIGHT} ${dsClass.PADDING_VERTICAL_2}`}
    tag="nav"
  >
    <ul className="of-action-group">
      {actionBlocks.map((contentItem, i) => (
        <ActionBlock key={i} {...contentItem} />
      ))}
    </ul>
  </PageMargins>
);
