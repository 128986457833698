import * as CL from '@design-system/component-library';
import {
  billingAccountMsg,
  elisaDevicesServiceMsg,
  idNumberMsg,
  imeiSlashSerialNumberMsg,
  priceMsg,
  serviceMsg,
  t,
} from '../../common/i18n/index.js';
import { formatSum } from '../../common/utils/priceUtils.js';
import { getDeviceCommitmentFields } from '../EmployeeSubscriptionDetails/eppSubscriptionUtils.js';
import { getPeriodicPriceAsText } from './subscriptionDetailsCommon.js';
import { getPriceWithTax, getVatAsString } from '../../common/utils/taxUtils.js';
import type { DescriptionItem } from '@design-system/component-library';
import type { Subscription, SubscriptionDetails, SubscriptionDetailsDevice } from '../../generated/api/models.js';

export const getSubscriptionDetailsDevice = (
  subscription: Subscription,
  details: SubscriptionDetails,
  deviceDetails: SubscriptionDetailsDevice,
  billingAccountSelection?: JSX.Element
) => {
  const getPriceValue = (
    sub: Subscription,
    subscriptionDetailsDevice: SubscriptionDetailsDevice
  ): string | undefined => {
    if (!subscriptionDetailsDevice.omaLaiteLasku) {
      if (sub.details?.contractTerm === 1 && sub.details.oneTimeCharge) {
        return formatSum(sub.details.oneTimeCharge);
      } else if (sub.details?.monthlyRecurringCharge && !sub.details?.oneTimeCharge) {
        return getPeriodicPriceAsText(sub.details?.monthlyRecurringCharge);
      } else if (!sub.details?.monthlyRecurringCharge && sub.details?.oneTimeCharge) {
        return formatSum(sub.details?.oneTimeCharge);
      }
      return getPeriodicPriceAsText(sub.details?.monthlyRecurringCharge);
    }
    return undefined;
  };

  const items: DescriptionItem[] = [
    { title: t.IFT9(billingAccountMsg), description: billingAccountSelection },
    { title: t.YTH3(idNumberMsg), description: subscription.subscriptionDisplayId },
    { title: t.OE45(imeiSlashSerialNumberMsg), description: deviceDetails.serialNumber },
    {
      title: t.CDME(serviceMsg),
      description: deviceDetails.eppSubscription ? t.TRE5(elisaDevicesServiceMsg) : undefined,
    },
    ...getDeviceCommitmentFields(subscription),
    {
      title: t.V72N(priceMsg),
      description: getPriceValue(subscription, deviceDetails),
    },
    {
      title: t.G664('Company share'),
      description: deviceDetails.omaLaiteLasku
        ? `${formatSum(deviceDetails.omaLaiteLaskuPrices!.corporateShare)}/kk (${t.UZ6X('VAT')} 0%)`
        : undefined,
    },
    {
      title: t.LYF6('Employee share'),
      description: deviceDetails.omaLaiteLasku
        ? `${formatSum(getPriceWithTax(deviceDetails.omaLaiteLaskuPrices!.employeeShare || 0))}/kk (${t.U8AN(
            'incl.'
          )} ${t.UZ6X('VAT')} ${getVatAsString()}%)`
        : undefined,
    },
  ];

  const filtered = items.filter(value => value.description);

  return (
    <CL.Description
      className="of-subscription-details__description"
      id="subscription-details-device"
      items={filtered}
    />
  );
};
