import { Fragment } from 'react';
import { multiwordSearchRegexStr } from '../../common/utils/stringUtils.js';

export interface HighlightProps {
  text: string;
  highlights: string[];
}

export const Highlight = ({ text, highlights }: HighlightProps) => {
  const regexStr = multiwordSearchRegexStr(highlights);
  if (regexStr) {
    const regex = new RegExp(`(${regexStr})`, 'gi');
    const parts = text.split(regex);
    return (
      <>
        {parts
          .filter(e => e)
          .map((e, i) => (regex.test(e) ? <mark key={i}>{e}</mark> : <Fragment key={i}>{e}</Fragment>))}
      </>
    );
  } else {
    // Empty `regexStr` string needs special handling, as it would always return true for `regex.test()`.
    return <>{text}</>;
  }
};
