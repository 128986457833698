import { Field } from 'formik';
import { InputComponent } from '../FieldComponents/index.js';
import { requiredValidation } from '../validation.js';
import { streetAddressMsg, t } from '../../i18n/index.js';
import type { OptionalInputComponentProps } from '../FieldComponents/index.js';

export const StreetAddress = (props: OptionalInputComponentProps) => {
  const translation = t.DD38(streetAddressMsg);

  return (
    <Field
      {...{
        name: props.name || 'streetAddress',
        label: translation,
        placeholder: translation,
        disabled: false,
        required: true,
        maxLength: 100,
        validate: requiredValidation,
        ...props,
      }}
      component={InputComponent}
    />
  );
};
