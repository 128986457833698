import * as CL from '@design-system/component-library';
import { Email, Name, PhoneNumber, PostalCode, TextInput } from '../../common/react-hook-form/fields/index.js';
import { addEmptyFieldValidationError } from '../../common/utils/errorUtils.js';
import {
  customerMsg,
  editMsg,
  emailAddressMsg,
  emailMsg,
  firstNameMsg,
  homeAddressLine1Msg,
  homeAddressLine2Msg,
  invoiceDeliveryMethodMsg,
  lastNameMsg,
  mobilePhoneNumberMsg,
  postOfficeMsg,
  postalCodeMsg,
  t,
} from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { useEffect, useState } from 'react';
import type { Address, BillingAccount } from '../../generated/api/models.js';
import type { AuthenticatedUserState } from '../../common/types/states.js';
import type { CommonError } from '../../common/types/errors.js';

import './PersonalBillingDetails.scss';

export const getErrorsFromUpsertPersonBillingAccount = (
  payerAddress?: Address,
  personalBillingEmail?: string,
  validationErrors?: CommonError[]
): CommonError[] | undefined => {
  const errors: CommonError[] = [];
  if (payerAddress?.line1.length === 0) {
    addEmptyFieldValidationError(errors, 'line1');
  }
  if (payerAddress?.postOffice.trim().length === 0) {
    addEmptyFieldValidationError(errors, 'postOffice');
  }
  if (payerAddress?.postalCode.length === 0) {
    addEmptyFieldValidationError(errors, 'postalCode');
  }
  if (personalBillingEmail?.length === 0) {
    addEmptyFieldValidationError(errors, 'email');
  }
  if (errors.length === 0) {
    return validationErrors;
  }
  return errors.concat(validationErrors ?? []);
};

export interface PersonalBillingDetailsData {
  email: string;
  firstName: string;
  lastName: string;
  mobile: string;
  personalBillingAddress?: Address;
}

export interface PersonalBillingDetailsProps {
  title?: string;
  fieldPrefix?: string;
  data: PersonalBillingDetailsData;
  editing: boolean;
  enableEditing?: boolean;
}

export const personalBillingAddressDetailsObjToAddress = (obj: PersonalBillingDetailsData): Address => {
  return {
    countryCode: obj?.personalBillingAddress?.countryCode ?? 'FIN',
    line1: obj?.personalBillingAddress?.line1 ?? '',
    line2: obj?.personalBillingAddress?.line2,
    postOffice: obj?.personalBillingAddress?.postOffice ?? '',
    postalCode: obj?.personalBillingAddress?.postalCode ?? '',
  };
};

export const createPersonBillingDetailsData = (
  user: AuthenticatedUserState,
  billingAccount?: BillingAccount
): PersonalBillingDetailsData => ({
  firstName: user.firstName,
  lastName: user.lastName ?? '',
  mobile: user.mobile ?? '',
  email: billingAccount?.billReceiverEmail ?? user.email!,
  personalBillingAddress: {
    line1: billingAccount?.payerAddress.line1 ?? '',
    line2: billingAccount?.payerAddress.line2 ?? '',
    postalCode: billingAccount?.payerAddress.postalCode ?? '',
    postOffice: billingAccount?.payerAddress.postOffice ?? '',
    countryCode: billingAccount?.payerAddress.countryCode ?? 'FIN',
  },
});

const EditorRow = ({ children }: { children?: JSX.Element | JSX.Element[] }) => (
  <CL.GridRow className={dsClass.MARGIN_0}>
    <CL.GridCol colWidthXS={4} colWidthS={4} colWidthL={5} className={dsClass.PADDING_LEFT_0}>
      {children}
    </CL.GridCol>
  </CL.GridRow>
);

const PersonalBillingDetailsForm = ({ fieldPrefix = '' }: { fieldPrefix?: string }) => (
  <CL.Grid>
    <EditorRow>
      <Name disabled label={t.AIK7(firstNameMsg)} name={`${fieldPrefix}firstName`} required />
    </EditorRow>
    <EditorRow>
      <Name disabled label={t.Y41S(lastNameMsg)} name={`${fieldPrefix}lastName`} required />
    </EditorRow>
    <EditorRow>
      <PhoneNumber disabled label={t.FRYN(mobilePhoneNumberMsg)} name={`${fieldPrefix}mobile`} required />
    </EditorRow>
    <EditorRow>
      <TextInput
        label={t.B5VB(homeAddressLine1Msg)}
        name={`${fieldPrefix}personalBillingAddress.line1`}
        maxLength={40}
        required
      />
    </EditorRow>
    <EditorRow>
      <TextInput
        label={t.H51P(homeAddressLine2Msg)}
        name={`${fieldPrefix}personalBillingAddress.line2`}
        maxLength={40}
        required={false}
      />
    </EditorRow>
    <EditorRow>
      <PostalCode
        label={t.RUAW(postalCodeMsg)}
        name={`${fieldPrefix}personalBillingAddress.postalCode`}
        placeholder=""
        required
      />
    </EditorRow>
    <EditorRow>
      <TextInput
        label={t.Z7S5(postOfficeMsg)}
        name={`${fieldPrefix}personalBillingAddress.postOffice`}
        maxLength={100}
        required
      />
    </EditorRow>
    <CL.GridRow className={dsClass.MARGIN_0}>
      <CL.GridCol className={dsClass.PADDING_LEFT_0}>
        <>
          <h4>{t.H272(invoiceDeliveryMethodMsg)}</h4>{' '}
          <div>
            {' '}
            {t.XVHQ(emailMsg)}
            <p>
              {t.K0AG(
                'If your share of the monthly fee for the new device is less than €5/month, you will receive an invoice when there is at least €5 to pay, or at 3 month intervals. You can change the invoice delivery type into an e-invoice later via your own netbank.'
              )}
            </p>
          </div>
        </>
      </CL.GridCol>
    </CL.GridRow>
    <EditorRow>
      <Email label={t.ZVQ5(emailAddressMsg)} name={`${fieldPrefix}email`} maxLength={80} required />
    </EditorRow>
  </CL.Grid>
);

export const PersonalBillingDetails = (props: PersonalBillingDetailsProps) => {
  const { editing, data, fieldPrefix, enableEditing } = props;
  const [editMode, setEditMode] = useState(editing);
  useEffect(() => {
    setEditMode(editing);
  }, [editing]);

  // TODO: Display billing delivery Options
  return (
    <div className="of-device-checkout-personal-billing-details">
      <h4>{props.title ?? t.ZC7D(customerMsg)}</h4>
      {editMode ? (
        <PersonalBillingDetailsForm fieldPrefix={fieldPrefix} />
      ) : (
        <div>
          <span>
            {`${data.firstName} ${data.lastName}`}
            <br />
          </span>
          <span>
            {`${data.mobile}`}
            <br />
          </span>
          <span>
            {`${data.email}`}
            <br />
          </span>
          <span>
            {`${data.personalBillingAddress?.line1}${
              data.personalBillingAddress?.line2 ? ' ' + data.personalBillingAddress?.line2 : ''
            },`}
            <br />
          </span>
          <span>
            {`${data.personalBillingAddress?.postalCode} ${data.personalBillingAddress?.postOffice}`}
            <br />
          </span>
          {data.personalBillingAddress && enableEditing && (
            // The condition here needs to be revisited, when user is allowed to edit existing personalBillingDetails
            <div className="of-device-checkout-personal-billing-details__actions--edit">
              <CL.Button
                color="link"
                onClick={() => {
                  setEditMode(true);
                }}
              >
                {t.NVPK(editMsg)}
              </CL.Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
