import { Field } from 'formik';
import { InputComponent } from '../FieldComponents/index.js';
import { NAME_FORMAT_REGEX } from '../../../common/utils/nameUtils.js';
import { fieldCantBeEmptyMsg, invalidValueMsg, nameMsg, t } from '../../i18n/index.js';
import type { OptionalInputComponentProps } from '../FieldComponents/index.js';

export const Name = (props: OptionalInputComponentProps) => {
  const translation = t.VGFI(nameMsg);
  const validate = (value: string) => {
    if (!value) {
      return t.VPVR(fieldCantBeEmptyMsg);
    } else if (!NAME_FORMAT_REGEX.test(value)) {
      return t.DUPA(invalidValueMsg);
    }
    return undefined;
  };

  return (
    <Field
      {...{
        name: 'name',
        label: translation,
        placeholder: translation,
        disabled: false,
        required: true,
        validate,
        ...props,
      }}
      component={InputComponent}
    />
  );
};
