import * as CL from '@design-system/component-library';
import { SeparateCharacterInput } from '../SeparateCharacterInput/SeparateCharacterInput.js';
import { addEmptyFieldValidationError } from '../../common/utils/errorUtils.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { fieldCantBeEmptyMsg, t } from '../../common/i18n/index.js';
import { processSubscriptionPairing } from '../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import type { CommonError } from '../../common/types/errors.js';
import type { SubscriptionPairingRequest } from '../../generated/api/subscriptionPairingRequest.js';
import type { ValidateSubscriptionPairingPayload } from '../../selfservice/actions/index.js';

import './OneTimePasswordForm.scss';

const OTP_LENGTH = 4;

interface OneTimePasswordFormRedirectParams {
  mdmId?: string;
  mobileNumber: string;
  subscriptionType: SubscriptionPairingRequest.SubscriptionTypeEnum;
}

export type OneTimePasswordFormProps = {
  defaultValue?: string;
  errors?: CommonError[] | null;
  onSubmit: (data: ValidateSubscriptionPairingPayload, validationErrors?: CommonError[]) => void;
  onPreviousStep: () => void;
  saving: boolean;
  redirectParams: OneTimePasswordFormRedirectParams;
  redirectPath: string;
};

export function getErrorsFromOneTimePasswordComponent(
  data: ValidateSubscriptionPairingPayload,
  validationErrors?: CommonError[]
): CommonError[] | undefined {
  const errors: CommonError[] = [];
  if (data) {
    if (data.otp.length < OTP_LENGTH) {
      addEmptyFieldValidationError(errors, 'otp', t.VPVR(fieldCantBeEmptyMsg));
    }
  }
  if (errors.length === 0) {
    return validationErrors;
  }
  return errors.concat(validationErrors || []);
}

export const OneTimePasswordForm = ({
  redirectParams,
  errors,
  saving,
  redirectPath,
  onSubmit,
  onPreviousStep,
}: OneTimePasswordFormProps) => {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState('');

  const otpObj = {
    mdmId: redirectParams.mdmId,
    msisdn: redirectParams.mobileNumber,
    otp: '',
    subscriptionType: redirectParams.subscriptionType,
    redirectPath: redirectPath,
  };

  return (
    <div className="of-one-time-password-form">
      <div className="of-employee__form-column" color="default">
        <h3 className="ea-h3 ea-h3--thick">{t.HHDQ('Please enter the verification code you received.')}</h3>
        <p className={dsClass.FONT_SIZE_LARGE}>
          {t.QOTV('Confirmation code was sent to number {} via SMS.', redirectParams.mobileNumber)}
        </p>
        {errors?.map((error, index) => (
          <span key={index} className="of-employee__form-error">
            {error.message}
          </span>
        ))}

        <div id="oneTimePassword" className="of-one-time-password-form__separate-character-input">
          <SeparateCharacterInput
            numberOfCharacters={OTP_LENGTH}
            onChangeInput={val => {
              setOtp(val);
            }}
            pattern="^[0-9]*$"
          />
        </div>
        <div className="of-one-time-password-form__otp-submit">
          <CL.Button
            className="of-one-time-password-form__submit-button"
            loading={saving}
            onClick={() => {
              onSubmit({ ...otpObj, otp });
            }}
            size="l"
            type="submit"
          >
            {t.KIRR('SUBMIT')}
          </CL.Button>
        </div>

        <div>
          <div>
            <CL.Button
              color="link"
              onClick={() => {
                dispatch(
                  processSubscriptionPairing({
                    mdmId: redirectParams.mdmId,
                    msisdn: redirectParams.mobileNumber,
                    subscriptionType: redirectParams.subscriptionType,
                  })
                );
              }}
            >
              {t.BSHJ('Resend confirmation code')}
            </CL.Button>
          </div>
          <div>
            <CL.Button color="link" onClick={onPreviousStep}>
              {t.H32M('Re-enter phone number')}
            </CL.Button>
          </div>
        </div>
      </div>
    </div>
  );
};
