import * as CL from '@design-system/component-library';
import { DialogWrapper } from '../DialogWrapper/index.js';
import { DuplicateContact } from '../../generated/api/duplicateContact.js';
import { FormProvider, useForm } from 'react-hook-form';
import { ModalButtons } from '../Modal/ModalButtons.js';
import { SelectRadio } from '../../common/react-hook-form/components/SelectRadio.js';
import {
  backMsg,
  cancelMsg,
  chooseThisContactMsg,
  contactAlreadyExistsMsg,
  contactCanBeFoundInCompanyListingMsg,
  contactDetailsYouAddedMsg,
  continueMsg,
  duplicateContactDescriptionMsg,
  duplicateContactHeaderMsg,
  foundUserMsg,
  t,
  userTextMsg,
} from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import type { Contact } from '../../generated/api/contact.js';
import type { ContactHeader } from '../../generated/api/contactHeader.js';
import type { ContactPerson } from '../../generated/api/contactPerson.js';

const DcType = DuplicateContact.DuplicateTypeEnum;

interface DuplicateContactsProps {
  contact: Contact;
  duplicateContacts: DuplicateContact[];
  onClose: () => void;
  onSelectOldContact: (ch: ContactHeader) => void;
  onSelectNewContact: (cp: ContactPerson) => void;
  isFirstDisabled?: boolean;
  isFirstSelected?: boolean;
}

interface RadioChildrenProps {
  index: number;
  messages: string[];
}

const pickDate = (item: ContactHeader) => item.lastModified || item.created || 0;
const getLatestXItems = (items: ContactHeader[], count: number) => {
  return items.sort((a, b) => pickDate(b) - pickDate(a)).slice(0, count);
};

const RadioChildren = ({ index, messages }: RadioChildrenProps) => (
  <label htmlFor={`duplicateContact-${index}`}>
    <span>{messages[0]}</span>
    <br />
    <span className={`${dsClass.TEXT_S} ${dsClass.COLOR_NEUTRAL_600_LIGHT}`}>{messages[1]}</span>
  </label>
);

export const DuplicateContactFormForSingle = ({
  duplicateContacts,
  onClose,
  onSelectOldContact,
}: DuplicateContactsProps) => {
  const foundContact = duplicateContacts[0].contacts![0];

  return (
    <>
      <p>{t.HFUO(contactCanBeFoundInCompanyListingMsg)}</p>
      <strong>{t.N0UD(foundUserMsg)}:</strong>
      <br />
      <span>{`${foundContact?.firstName} / ${foundContact?.lastName}`}</span>
      <br />
      <span>{foundContact?.phoneNumber}</span>
      <br />
      <span>{foundContact?.email}</span>

      <ModalButtons>
        <CL.Button color="link" onClick={onClose}>
          {t.B2V1(cancelMsg)}
        </CL.Button>
        <CL.Button
          onClick={() => {
            onSelectOldContact(foundContact);
            onClose();
          }}
          type="button"
          color="primary"
        >
          {t.T51P(chooseThisContactMsg)}
        </CL.Button>
      </ModalButtons>
    </>
  );
};

export const getContactLabel = (c: ContactPerson | ContactHeader) => {
  return `${c.firstName} ${c.lastName}, ${c.email}, ${c.phoneNumber}`;
};

export const DuplicateContactFormForMultiple = ({
  contact,
  duplicateContacts,
  onSelectOldContact,
  onSelectNewContact,
  onClose,
  isFirstDisabled,
  isFirstSelected,
}: DuplicateContactsProps) => {
  const person = contact.person;
  const dcs = getLatestXItems(duplicateContacts[0].contacts || [], 3);

  const methods = useForm({
    mode: 'onChange',
    defaultValues: { duplicateContact: isFirstSelected ? 'created' : undefined },
  });

  const contacts = [
    {
      children: <RadioChildren index={0} messages={[getContactLabel(person!), t.OAGG(contactDetailsYouAddedMsg)]} />,
      value: 'created',
      disabled: Boolean(isFirstDisabled),
    },
    ...dcs.map((dc, i) => ({
      children: <RadioChildren index={i + 1} messages={[getContactLabel(dc), t.N0UD(foundUserMsg)]} />,
      value: dc.contactId || '',
    })),
  ];

  return (
    <>
      <p>{t.WBKW(duplicateContactDescriptionMsg)}</p>
      <FormProvider {...methods}>
        <form
          className={dsClass.MARGIN_LEFT_0}
          onSubmit={methods.handleSubmit(({ duplicateContact: dc }) => {
            if (dc === 'created') {
              onSelectNewContact(person!);
            } else {
              onSelectOldContact(dcs.find(i => i.contactId === dc)!);
            }
            onClose();
          })}
          noValidate
        >
          <SelectRadio name="duplicateContact" label={t.U4MA(userTextMsg)} items={contacts} />
          <ModalButtons>
            <CL.Button color="link" onClick={onClose}>
              {t.D1E9(backMsg)}
            </CL.Button>
            <CL.Button type="submit" color="primary">
              {t.I62A(continueMsg)}
            </CL.Button>
          </ModalButtons>
        </form>
      </FormProvider>
    </>
  );
};

const DuplicateContactSelection = (props: DuplicateContactsProps) => {
  const dc = props.duplicateContacts[0];

  if (dc.duplicateType === DcType.DUPLICATE_CONTACT) {
    if (dc.contacts?.length === 1) {
      return <DuplicateContactFormForSingle {...props} />;
    } else {
      return <DuplicateContactFormForMultiple {...props} isFirstDisabled />;
    }
  } else if (dc.duplicateType === DcType.POSSIBLE_CONTACT_DUPLICATE) {
    return <DuplicateContactFormForMultiple {...props} isFirstSelected />;
  }
  return null;
};

export const DuplicateContactsDialog = (props: DuplicateContactsProps) => {
  if (props.duplicateContacts.length === 0) {
    return null;
  }

  const dc = props.duplicateContacts[0];
  return (
    <DialogWrapper
      closeable
      header={
        dc.duplicateType === DcType.DUPLICATE_CONTACT && dc.contacts?.length === 1
          ? t.USHS(contactAlreadyExistsMsg)
          : t.CVJF(duplicateContactHeaderMsg)
      }
      onCloseDialog={props.onClose}
    >
      <DuplicateContactSelection {...props} />
    </DialogWrapper>
  );
};
