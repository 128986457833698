import { InvoiceDetails } from '../../../../../components/InvoiceDetails/InvoiceDetails.js';
import { InvoiceSystemError } from '../../../../../components/Invoices/Invoices.js';
import { Navigate, useLoaderData, useSearchParams } from 'react-router-dom';
import { getInvoicePaymentMdmId, removeInvoicePaymentMdmId } from '../../../../../selfservice/common/localStorageUtils';
import { useStore } from 'react-redux';
import type { InvoiceLoaderResponse } from '../../../../../common/loaders.js';
import type { State } from '../../../../../selfservice/common/store.js';

export const InvoicePath = () => {
  const store = useStore<State>();
  const config = store.getState().config;
  const [search, _] = useSearchParams();
  const { invoice, billChannels, openSupportCases, billingAccount } = useLoaderData() as InvoiceLoaderResponse;

  if (!search.get('mdmId')) {
    // returning from payment we don't have mdmId search param
    const paymentMdmId = getInvoicePaymentMdmId();
    if (paymentMdmId) {
      removeInvoicePaymentMdmId();
      return <Navigate to={`?${search}&mdmId=${paymentMdmId}`} />;
    }
    if (!search.get('transactionId')) {
      // transactionId means we are processing payment
      // during payment processing we do not have mdmId search param
      // in all other situations mdmId search param should be present, so we throw error here
      throw new Error('missing mdmId');
    }
  }
  return (
    <InvoiceSystemError>
      <InvoiceDetails
        config={config}
        billChannels={billChannels}
        billingAccount={billingAccount}
        invoice={invoice}
        openSupportCases={openSupportCases}
        mdmId={search.get('mdmId')!}
      />
    </InvoiceSystemError>
  );
};
