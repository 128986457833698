import { getSubscriptionIcon } from './subscriptionIcons.js';
import type { Subscription, SubscriptionHeader } from '../../generated/api/models.js';

export const SubscriptionIcon = ({ subscription }: { subscription: Subscription | SubscriptionHeader }) => (
  <span
    className={`ea-icon ea-icon--${getSubscriptionIcon(
      subscription.subscriptionType,
      subscription.subscriptionSubType
    )}`}
  ></span>
);
