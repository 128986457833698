import './HoverTooltip.scss';

export interface HoverTooltipProps {
  tooltipText: string;
  children?: JSX.Element;
}

export const HoverTooltip = ({ tooltipText, children }: HoverTooltipProps) => {
  return (
    <div className="hovertooltip">
      {children}
      <span className="tooltiptext ea-disclaimertext">{tooltipText}</span>
    </div>
  );
};
