import { ActionPhase } from '../common/storeUtils.js';
import { TypeKeys } from '../actions/index.js';
import { reduceDisplayItemsLoadAction } from './reducerUtils.js';
import { updateActionStatePhase } from '../common/index.js';
import type { ActionsHistory } from '../common/store.js';
import type { PickupPoint } from '../../generated/api/models.js';
import type { PickupPointSearchState } from '../../common/types/states.js';
import type { SelfServiceActionTypes } from '../actions/index.js';

export function pickupPointSearchReducer(
  state: (PickupPointSearchState & ActionsHistory) | null,
  action: SelfServiceActionTypes
): (PickupPointSearchState & ActionsHistory) | null {
  if (typeof state === 'undefined') {
    return {};
  }

  switch (action.type) {
    case TypeKeys.SEARCH_PICKUP_POINT:
      return {
        ...reduceDisplayItemsLoadAction<TypeKeys.SEARCH_PICKUP_POINT, PickupPoint>(
          action,
          state,
          'name',
          true,
          action.reporting ? 0 : undefined,
          true
        ),
        items: action.postalCode === state?.postalCode && action.service === state?.service ? state?.items || [] : [],
        service: action.service,
        postalCode: action.postalCode,
      };
    case TypeKeys.SEARCH_PICKUP_POINT_FULFILLED:
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.SEARCH_PICKUP_POINT,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        errors: [],
        items: action.pickupPoints,
        service: state?.service,
        postalCode: state?.postalCode,
      };
    case TypeKeys.SEARCH_PICKUP_POINT_FAILED:
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.SEARCH_PICKUP_POINT,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
        items: [],
      };
    default:
      return state;
  }
}
