import type { AutoCompleteProps } from './AutoComplete.js';
import type { ConfigurationObject, OptionRef, OptionsRef } from './config.js';
import type { InputValueAndId } from './useAutoComplete.js';

export const findPreviousSibling = ({ optionRef, optionsRef }: { optionRef: OptionRef; optionsRef: OptionsRef }) => {
  if (!optionRef.current?.previousSibling) {
    return { current: optionsRef.current?.lastChild instanceof HTMLLIElement ? optionsRef.current.lastChild : null };
  }
  return {
    current: optionRef.current.previousSibling instanceof HTMLLIElement ? optionRef.current.previousSibling : null,
  };
};
export const findNextSibling = ({ optionRef, optionsRef }: { optionsRef: OptionsRef; optionRef: OptionRef }) => {
  if (!optionRef.current?.nextSibling) {
    return { current: optionsRef.current?.firstChild instanceof HTMLLIElement ? optionsRef.current.firstChild : null };
  }
  return { current: optionRef.current.nextSibling instanceof HTMLLIElement ? optionRef.current.nextSibling : null };
};
export const findLaterSibling = (
  { optionRef, optionsRef }: { optionsRef: OptionsRef; optionRef: OptionRef },
  movementSpeed: number
) => {
  const ref = { current: optionRef.current || optionsRef.current?.firstChild };
  let i = 0;
  while (ref.current && ref.current.nextSibling && i < movementSpeed) {
    ref.current = ref.current.nextSibling;
    i++;
  }
  return { current: ref.current instanceof HTMLLIElement ? ref.current : null };
};
export const findEarlierSibling = (
  { optionRef, optionsRef }: { optionsRef: OptionsRef; optionRef: OptionRef },
  movementSpeed: number
) => {
  const ref = { current: optionRef.current || optionsRef.current?.firstChild };
  let i = 0;
  while (ref.current && ref.current.previousSibling && i < movementSpeed) {
    ref.current = ref.current.previousSibling;
    i++;
  }
  return { current: ref.current instanceof HTMLLIElement ? ref.current : null };
};
export const findFirstSibling = ({ optionsRef }: { optionsRef: OptionsRef }) => {
  return {
    current: optionsRef.current?.firstChild instanceof HTMLLIElement ? optionsRef.current.firstChild : null,
  };
};
export const findLastSibling = ({ optionsRef }: { optionsRef: OptionsRef }) => ({
  current: optionsRef.current?.lastChild instanceof HTMLLIElement ? optionsRef.current.lastChild : null,
});
export const eventTargetToHtmlListElement = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => ({
  current: event.target instanceof HTMLLIElement ? event.target : null,
});
export const createOptionId = (id: string, index: number) => `combo-box-${id}-${index}`;
const sort = <T>(options: T[], getDisplayValue: (value: T) => string) => {
  return options
    .map(option => option)
    .sort((optionA, optionB) => {
      const textA = getDisplayValue(optionA);
      const textB = getDisplayValue(optionB);
      if (textA < textB) {
        return -1;
      }
      if (textA > textB) {
        return 1;
      }
      return 0;
    });
};
export const sortOptions = <T>(options: T[], getDisplayValue: (value: T) => string, config: ConfigurationObject) => {
  if (config.isSortable) {
    return sort(options, getDisplayValue);
  }
  return options;
};

export const createOptionValueAndId = <T>({ getDisplayValue, getUniqueId }: AutoCompleteProps<T>, option?: T) => {
  return option ? { value: getDisplayValue(option), id: getUniqueId(option) } : { value: '', id: undefined };
};
export const getInitialOption = <T>(props: AutoCompleteProps<T>): T | undefined => {
  if (props.defaultOption) {
    const id = props.getUniqueId(props.defaultOption);
    return props.options.find(item => id === props.getUniqueId(item));
  }
  return;
};
export const getInitialValue = <T>(props: AutoCompleteProps<T>): InputValueAndId => {
  const option = getInitialOption(props);
  return option ? createOptionValueAndId(props, option) : { value: '', id: undefined };
};
export const filterOptions = <T>(
  isFilter: boolean,
  inputValue: string,
  options: T[],
  getDisplayValue: (option: T) => string
) => {
  return isFilter
    ? options.filter(opt => {
        const value = inputValue.toLowerCase();
        return getDisplayValue(opt).toLowerCase().includes(value);
      })
    : options;
};

export const mergeTwoLists = <T>(list1: T[], list2: T[], getUniqueId: (item: T) => string | number) => {
  const uniqueIds = new Set(list1.map(item => getUniqueId(item)));
  return [...list1, ...list2.filter(item => !uniqueIds.has(getUniqueId(item)))];
};

export const resetValuesToUndefined = <T extends object>(obj: T): { [K in keyof T]: undefined } => {
  const result: { [K in keyof T]?: undefined } = {};

  Object.keys(obj).forEach(key => {
    result[key as keyof T] = undefined;
  });

  return result as { [K in keyof T]: undefined };
};
