/**
 * Online UI API
 * # Summary Elisa B2B Online UI API  # Usage This API is used in the `https://yrityksille.elisa.fi/` pages.  # Identifiers 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ContactHeader } from './contactHeader.js';


/**
 * Duplicate contact response
 */
export interface DuplicateContactResult { 
    /**
     * Type of conflict, one per key below.
     */
    type: DuplicateContactResult.TypeEnum;
    /**
     * Message about the duplicate contact conflict.
     */
    message?: string;
    /**
     * SFDC Internal Id of record that caused the error.Such as contact Ids in case of duplicate detection.
     */
    recordIds?: Array<string>;
    duplicateContact?: ContactHeader;
}
export namespace DuplicateContactResult {
    export type TypeEnum = 'DUPLICATE_CONTACT' | 'DUPLICATE_FROM_HIERARCHY' | 'POSSIBLE_CONTACT_DUPLICATE' | 'CONTACT_SUPPORT';
    export const TypeEnum = {
        DUPLICATE_CONTACT: 'DUPLICATE_CONTACT' as TypeEnum,
        DUPLICATE_FROM_HIERARCHY: 'DUPLICATE_FROM_HIERARCHY' as TypeEnum,
        POSSIBLE_CONTACT_DUPLICATE: 'POSSIBLE_CONTACT_DUPLICATE' as TypeEnum,
        CONTACT_SUPPORT: 'CONTACT_SUPPORT' as TypeEnum
    };
}


