import { Anchor } from '../Anchor/Anchor.js';
import { Link } from 'react-router-dom';
import { dsClass } from '../../common/constants/dsClasses.js';
import type { AnchorHTMLAttributes, ReactNode } from 'react';
import type { LinkProps } from 'react-router-dom';

import './NavigationMenu.scss';

type Props = { id: string; icon: ReactNode; label: ReactNode } & (
  | ({ linkType?: 'internal' } & LinkProps)
  | ({ linkType: 'external' } & AnchorHTMLAttributes<HTMLAnchorElement>)
  | { linkType: 'clickable'; onClick: () => void }
);

const IconAndLabel = ({ icon, label }: { icon: ReactNode; label: ReactNode }) => (
  <>
    <span className="of-navigation-menu__icon">{icon}</span>
    <span className="of-navigation-menu__text">{label}</span>
  </>
);

const LinkElement = (props: Props) => {
  const { id, icon, label, linkType, onClick, ...rest } = props;
  const linkClasses = `of-navigation-menu__link ${dsClass.COLOR_BRAND_BLUE}`;

  switch (linkType) {
    case undefined:
    case 'internal':
      return (
        <Link to={props.to} id={id} className={linkClasses} onClick={onClick} {...rest}>
          <IconAndLabel icon={icon} label={label} />
        </Link>
      );
    case 'clickable':
      return (
        <Anchor id={id} className={linkClasses} onClick={onClick} {...rest}>
          <IconAndLabel icon={icon} label={label} />
        </Anchor>
      );
    case 'external':
      return (
        <a href={props.href} id={id} className={linkClasses} onClick={onClick} {...rest}>
          <IconAndLabel icon={icon} label={label} />
        </a>
      );
  }
};

export const NavigationMenuItem = (props: Props) => (
  <li key={props.id} className="of-navigation-menu__item">
    <LinkElement {...props} />
  </li>
);

export const NavigationMenu = ({ children }: { children: ReactNode }) => (
  <nav>
    <ul className="of-navigation-menu">{children}</ul>
  </nav>
);
