import { Field } from 'formik';
import { InputComponent } from '../FieldComponents/index.js';
import { referenceMsg, t } from '../../i18n/index.js';

interface BillingAccountReferenceProps {
  number: number;
}
export const BillingAccountReference = (props: BillingAccountReferenceProps) => {
  const { number } = props;
  const label = `${t.ZLAU(referenceMsg)} ${number}`;
  const name = `customerReference${number}`;
  return (
    <Field
      {...{
        label,
        name,
        maxLength: 100,
      }}
      component={InputComponent}
    />
  );
};
