import { Fragment } from 'react';
import { Highlight } from '../../components/Highlight/Highlight.js';

export type NonVisibleField = {
  name: string;
  getTitle: () => string;
};

const canTextBeHighlighted = (text: string, minLength = 2): boolean => text.length >= minLength;

const getMatchedFields = (fieldsToLookIn: NonVisibleField[], fieldsToLookFor: string[]) => {
  return fieldsToLookIn.filter(field => fieldsToLookFor.includes(field.name));
};

const formatMatchedItemValue = (value: Array<string> | string) => {
  if (Array.isArray(value)) {
    return value.join(', ');
  }
  return value;
};

export const highlightKeyword = (text: string, keyword?: string): string | JSX.Element =>
  keyword && canTextBeHighlighted(keyword) ? <Highlight text={text} highlights={keyword.split(/\s/)} /> : text;

export const getHighlightedFields = <T extends object>(
  data: T,
  fieldsToLookFor: string[],
  keywordToFind: string,
  fieldsToLookIn: NonVisibleField[]
): JSX.Element[] => {
  return getMatchedFields(fieldsToLookIn, fieldsToLookFor)
    .map(matchedField => ({
      // @ts-ignore
      itemValue: data[matchedField.name],
      itemTitle: matchedField.getTitle(),
    }))
    .map(matchedData => (
      <Fragment key={matchedData.itemValue}>
        <span>{matchedData.itemTitle}: </span>
        {highlightKeyword(formatMatchedItemValue(matchedData.itemValue), keywordToFind)}
        <span> </span>
      </Fragment>
    ));
};
