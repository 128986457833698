import { SubscriptionPbxDetails } from '../../generated/api/subscriptionPbxDetails.js';

export interface PbxWorkingHoursConfig {
  workingHoursWeekdayStart?: string;
  workingHoursWeekdayEnd?: string;
  workingHoursWeekendStart?: string;
  workingHoursWeekendEnd?: string;
  workingHoursSaturdayStart?: string;
  workingHoursSaturdayEnd?: string;
  workingHoursSundayStart?: string;
  workingHoursSundayEnd?: string;
}

/**
 * These PBX types have Saturday and Sunday time ranges compressed into one field, Weekend.
 * Others will have Saturday and Sunday separately.
 */
export const weekEndTypes: Readonly<Array<SubscriptionPbxDetails.PbxTypeEnum | undefined>> = [
  SubscriptionPbxDetails.PbxTypeEnum.VAKIO,
  SubscriptionPbxDetails.PbxTypeEnum.VASTAUSSARJA,
  SubscriptionPbxDetails.PbxTypeEnum.TAVOITETTAVUUSKETJU,
] as const;

export const getDisplayFormat = (hours: number, minutes: number) => {
  const date = new Date();

  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(0);

  // IE11 produces some magic UTF characters with this function, those need to be replaced, thus the .replace
  return date
    .toLocaleTimeString(undefined, { hour: '2-digit', hour12: false, minute: '2-digit' })
    .replace(/[^0-9.:]/g, '');
};

export const convertFromDisplayFormatToValueFormat = (displayFormat?: string) => {
  if (displayFormat === undefined) {
    return undefined;
  }

  const normalizedDisplayFormat = displayFormat.replace('.', ':');

  // zero-padding, required by firefox at least
  if (normalizedDisplayFormat.length === 4 && normalizedDisplayFormat.split(':')[0].length < 2) {
    return `0${normalizedDisplayFormat}`;
  }

  return normalizedDisplayFormat;
};

export const convertValueFormatToDisplayFormat = (valueFormat?: string) => {
  if (valueFormat === undefined) {
    return undefined;
  }

  const [hours, minutes] = valueFormat.replace('.', ':').split(':');

  return getDisplayFormat(parseInt(hours, 10), parseInt(minutes, 10));
};
