export enum FileTypeIcon {
  FILE_DOC = 'file-doc',
  FILE_GENERAL = 'file-general',
  FILE_PDF = 'file-pdf',
  FILE_TXT = 'file-txt',
  FILE_XLS = 'file-xls',
  PICTURE = 'picture',
}

export const fileTypeIcon = (fileType: string | undefined): FileTypeIcon => {
  switch (fileType) {
    case 'doc':
    case 'docx':
      return FileTypeIcon.FILE_DOC;
    case 'pdf':
      return FileTypeIcon.FILE_PDF;
    case 'jpeg':
    case 'jpg':
    case 'png':
      return FileTypeIcon.PICTURE;
    case 'txt':
      return FileTypeIcon.FILE_TXT;
    case 'xls':
    case 'xlsx':
      return FileTypeIcon.FILE_XLS;
    default:
      return FileTypeIcon.FILE_GENERAL;
  }
};

export const fileNameParts = (fileName: string): { namePart: string; typePart: string | undefined } => {
  const parts = fileName.split('.');
  const typePart = parts.pop();
  const namePart = parts.join('.');
  return { namePart, typePart };
};

// Middle truncation e.g. for Max 20 characters (15 first characters) + (...) + (5 last characters) + (.file type)
export const middleTruncateFileName = (fileName: string) => {
  const { namePart, typePart } = fileNameParts(fileName);
  const typeOrEmptyString = typePart ? `.${typePart}` : '';
  const prettyName = namePart.length > 20 ? `${namePart.slice(0, 15)}...${namePart.slice(-5)}` : namePart;
  return `${prettyName}${typeOrEmptyString}`;
};
