import * as CL from '@design-system/component-library';
import { CloseButton, InputSearch } from '../InputSearch/InputSearch.js';
import { searchMsg, t } from '../../common/i18n/index.js';
import { useRef } from 'react';
import type { InputHTMLAttributes, RefObject, SyntheticEvent } from 'react';

import './PublicPagesSearchForm.scss';

export interface SearchInputGroupProps extends InputHTMLAttributes<HTMLInputElement> {
  handleClear: () => void;
  isSubmitDisabled?: boolean;
}

export interface SearchFormProps {
  handleSubmit: (event: SyntheticEvent<HTMLElement>) => void;
}

export const PublicPagesSearchInputGroup = (props: SearchInputGroupProps) => {
  const { handleClear, isSubmitDisabled, minLength = 1, ...inputProps } = props;
  const ref = useRef() as RefObject<HTMLInputElement>;
  return (
    <CL.InputGroup className="of-public-pages-search-form">
      <InputSearch {...inputProps} ref={ref} name="query" type="search" minLength={minLength}>
        {inputProps.value !== '' && (
          <CloseButton
            onClick={(e: SyntheticEvent<HTMLButtonElement>) => {
              if ((e.target as HTMLButtonElement)?.type !== 'submit') {
                ref.current?.focus();
                handleClear?.();
              }
            }}
          />
        )}
      </InputSearch>
      <CL.Button
        disabled={(typeof inputProps.value === 'string' && inputProps.value.length < minLength) || isSubmitDisabled}
        size="l"
        type="submit"
      >
        {t.VQE6(searchMsg)}
      </CL.Button>
    </CL.InputGroup>
  );
};

export const PublicPagesSearchForm = (props: SearchInputGroupProps & SearchFormProps) => {
  const { handleSubmit, ...rest } = props;
  return (
    <form onSubmit={handleSubmit} role="search" noValidate>
      <PublicPagesSearchInputGroup {...rest} />
    </form>
  );
};
