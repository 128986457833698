import * as CL from '@design-system/component-library';
import { backMsg, offersNotAvailableMsg, t } from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';

export const OffersNotAvailable = () => {
  return (
    <div className={`of-offers-not-available ${dsClass.MARGIN_2}`}>
      <p>{t.QZM5(offersNotAvailableMsg)}</p>
      <p>
        <CL.Button onClick={() => window.history.back()}>{t.D1E9(backMsg)}</CL.Button>
      </p>
    </div>
  );
};
