import { EmployeeDeviceSubscriptions } from '../../../../../../components/EmployeeSubscriptions/EmployeeDeviceSubscriptions.js';
import { SubscriptionCategory } from '../../../../../../common/enums.js';
import {
  getEmployeeSubscriptions,
  loadCompanyInfo,
  loadVirtualCatalogs,
} from '../../../../../../selfservice/actions/index.js';
import { useAuth } from '../../../../AuthProvider.js';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

export const DevicesPath = () => {
  const dispatch = useDispatch();
  const { authenticatedUser } = useAuth();

  useEffect(() => {
    if (authenticatedUser?.businessId) {
      dispatch(loadCompanyInfo());
      dispatch(loadVirtualCatalogs());
    }
    dispatch(getEmployeeSubscriptions(SubscriptionCategory.DEVICE));
  }, [dispatch, authenticatedUser]);

  return <EmployeeDeviceSubscriptions />;
};
