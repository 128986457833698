import { Subscription } from '../../generated/api/models.js';
import { SubscriptionCategory } from '../../common/enums.js';
import {
  TypeKeys,
  loadSubscriptions,
  sendAuxiliaryEsimOrderFailed,
  sendAuxiliaryEsimOrderFulfilled,
  validateAuxiliaryEsimOrder,
  validateAuxiliaryEsimOrderFailed,
  validateAuxiliaryEsimOrderFulfilled,
} from '../actions/index.js';
import { actionToActionState } from './epicUtils.js';
import { callUiApi, prepareUiApiRequest } from '../common/uiApiUtils.js';
import { combineEpics, ofType } from 'redux-observable';
import { concatMap, filter, flatMap, map } from 'rxjs/operators';
import {
  postAuxiliaryEsimOrderPrivateMethod,
  validateAuxiliaryEsimOrderPrivateMethod,
} from '../../generated/api/uiApiMethods.js';
import type { Action } from 'redux';
import type { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import type { AjaxResponse } from 'rxjs/ajax';
import type { EpicDependencies } from './epicUtils.js';
import type {
  GetAuxiliaryEsimOrderSubscriptionsFulfilledAction,
  LoadSubscriptionsFulfilledAction,
  SelfServiceActionTypes,
  SendAuxiliaryEsimOrderAction,
  ValidateAuxiliaryEsimOrderAction,
} from '../actions/index.js';
import type { State } from '../common/store.js';
import type { ValidateAuxiliaryEsimOrderResponse } from '../../generated/api/models.js';

export const validateAuxiliaryEsimOrderEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  action$.pipe(
    ofType(TypeKeys.AUXILIARY_ESIM_ORDER_VALIDATE),
    flatMap((action: ValidateAuxiliaryEsimOrderAction) => {
      const { subscription } = action as ValidateAuxiliaryEsimOrderAction;
      const payload = {
        mobileNumber: subscription?.details?.mobile?.mobileNumber,
        type: 'APPLE',
      };
      return callUiApi({
        epicDependencies,
        failureAction: validateAuxiliaryEsimOrderFailed,
        method: validateAuxiliaryEsimOrderPrivateMethod(),
        payload,
        state$,
        successAction: (response: AjaxResponse) =>
          validateAuxiliaryEsimOrderFulfilled(response.response as ValidateAuxiliaryEsimOrderResponse),
      });
    })
  );

export const validateOnFulfilledSubscriptionEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>
) =>
  action$.pipe(
    ofType(TypeKeys.AUXILIARY_ESIM_ORDER_GET_SUBSCRIPTION_FULFILLED),
    map((action: GetAuxiliaryEsimOrderSubscriptionsFulfilledAction) => validateAuxiliaryEsimOrder(action.subscription))
  );

export const sendOrderEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(
    action$.pipe(ofType(TypeKeys.AUXILIARY_ESIM_ORDER_SEND)),
    (action: SendAuxiliaryEsimOrderAction) => actionToActionState(action, state$, 'auxiliaryEsimOrder')
  ).pipe(
    concatMap(() => {
      const state = state$.value.selfservice?.auxiliaryEsimOrder;
      const payload = {
        subscriptionToken: state?.subscriptionToken,
        primaryImsi: state?.subscription?.details?.mobile?.imsi,
        mobileNumber: state?.subscription?.details?.mobile?.mobileNumber,
        sourceSystem: Subscription.SourceSystemEnum.TELLUS,
      };
      const successAction = (response: AjaxResponse) => sendAuxiliaryEsimOrderFulfilled(response.response);

      return callUiApi({
        epicDependencies,
        failureAction: sendAuxiliaryEsimOrderFailed,
        method: postAuxiliaryEsimOrderPrivateMethod(),
        payload,
        state$,
        successAction,
      });
    })
  );

export const checkSubscriptions: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>
) =>
  action$.pipe(
    ofType(TypeKeys.LOAD_SUBSCRIPTIONS_FULFILLED),
    filter(() => !!state$.value.selfservice?.auxiliaryEsimOrder?.subscriptionToken),
    map((action: LoadSubscriptionsFulfilledAction) => {
      if (action.subscriptions?.length === 1) {
        const subscription = action.subscriptions[0];
        return { subscription, type: TypeKeys.AUXILIARY_ESIM_ORDER_GET_SUBSCRIPTION_FULFILLED };
      } else {
        return { type: TypeKeys.AUXILIARY_ESIM_ORDER_GET_SUBSCRIPTION_FAILED };
      }
    })
  );

export const loadSubscriptionsOnFulfilledLoginEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>
) =>
  action$.pipe(
    ofType(TypeKeys.PROCESS_ELISAID_LOGIN_FULFILLED),
    filter(() => !!state$.value.selfservice?.auxiliaryEsimOrder?.subscriptionToken),
    map(() =>
      loadSubscriptions({
        category: SubscriptionCategory.VOICE,
        subscriptionToken: state$.value.selfservice?.auxiliaryEsimOrder?.subscriptionToken,
      })
    )
  );

export const auxiliaryEsimOrderEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = combineEpics(
  checkSubscriptions,
  loadSubscriptionsOnFulfilledLoginEpic,
  sendOrderEpic,
  validateAuxiliaryEsimOrderEpic,
  validateOnFulfilledSubscriptionEpic
);
