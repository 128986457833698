// Provides simple Redux state storing and recovery over browser redirect.

import { isInBrowser } from '../../common/utils/ssrUtils.js';
import type { State } from './store.js';

const savedStateKey = 'saved-state';

// Stores state as JSON in browser's local storage and performs redirect.
export function redirectWithState(state: State, path: string) {
  if (!isInBrowser()) {
    return;
  }
  window.sessionStorage.setItem(savedStateKey, JSON.stringify(state));
  window.location.assign(path);
}

// Restores state from browser's local storage.
export function restoreRedirectedState(initialState: State): State {
  if (!isInBrowser()) {
    return initialState;
  }
  const savedState = window.sessionStorage.getItem(savedStateKey);

  if (!savedState) {
    return initialState;
  }

  // Data is removed to prevent loading it again next time browser reloads the site.
  // That would cause application to behave unexpectedly.
  sessionStorage.removeItem(savedStateKey);

  const loadedState = JSON.parse(savedState) as State;
  const combinedState = { ...initialState, ...loadedState };

  // Restoring router to the original content would make the browser
  // return to the origin of the redirection. To prevent that
  // it is overwritten here.
  combinedState.router = initialState.router;

  return combinedState;
}
