import { DeliveryAddressType } from '../DeliveryAddress/DeliveryAddress.js';
import type { AuthenticatedUserState } from '../../common/types/states.js';
import type { DeliveryAddress } from '../../generated/api/models.js';
import type { DeliveryAddressFormFormikType } from '../DeliveryAddressForm/DeliveryAddressForm.js';

export const getAddressFromFormikValues = (
  type: DeliveryAddressType,
  formikValues?: DeliveryAddressFormFormikType
): DeliveryAddress => {
  if (formikValues) {
    return {
      address: {
        line1: formikValues.addressLine1,
        line2: formikValues.addressLine2,
        postalCode: formikValues.postalCode,
        postOffice: formikValues.postOffice,
        countryCode: formikValues.countryCode,
      },
      companyName: type === DeliveryAddressType.COMPANY_ADDRESS ? formikValues.companyName : '',
      recipient: formikValues.recipientName,
      phoneNumber: formikValues.phoneNumber,
    };
  }
  throw Error('Empty Formik reference');
};

export const getFullNameFromAuthenticatedUser = (user?: AuthenticatedUserState | null) =>
  user ? user.firstName + (user.lastName ? ' ' + user.lastName : '') : '';
