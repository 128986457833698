import { BILLING_ACCOUNT_MAX_LENGTH } from '../../utils/validationUtils.js';
import { Field } from 'formik';
import { InputComponent } from '../FieldComponents/index.js';
import { billingAccountNameMsg, fieldCantBeEmptyMsg, fieldValueTooLong, t } from '../../i18n/index.js';
import type { OptionalInputComponentProps } from '../FieldComponents/index.js';

export const BillingAccountName = (props: OptionalInputComponentProps) => {
  const translation = t.RH6T(billingAccountNameMsg);

  const validate = (value: string) => {
    if (!value) {
      return t.VPVR(fieldCantBeEmptyMsg);
    }
    if (value.length > BILLING_ACCOUNT_MAX_LENGTH) {
      return t.XOYE(fieldValueTooLong, `${BILLING_ACCOUNT_MAX_LENGTH}`);
    }
    return undefined;
  };

  return (
    <Field
      label={t.RH6T(billingAccountNameMsg)}
      name="billingAccountName"
      placeholder={translation}
      disabled={false}
      required={props.required ?? true}
      maxLength={BILLING_ACCOUNT_MAX_LENGTH}
      validate={validate}
      component={InputComponent}
    />
  );
};
