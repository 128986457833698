import * as CL from '@design-system/component-library';
import { Picture } from '../Picture/Picture.js';
import { continueShoppingMsg, quantityMsg, t } from '../../common/i18n/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import {
  getAddonsDisplayData,
  getCartItemId,
  getProductDisclaimers,
  getProductPriceDisplayData,
  getTotalProductQuantity,
  resolveTotalPrices,
} from '../../selfservice/common/shopping-cart/shoppingCartFunctions.js';
import { isEmployeeUser } from '../ProductDetails/utils/productDetailsEmployeeUtils.js';
import { setCartUpdated } from '../../selfservice/actions/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import type { ShoppingCartItemForCheckout } from '../../common/types/checkout.js';
import type { State } from '../../selfservice/common/store.js';

export interface HeaderShoppingCartProps {
  toShoppingCart: string;
  toCheckout: string;
}

export const HeaderShoppingCart = (props: HeaderShoppingCartProps) => {
  const { toCheckout, toShoppingCart } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { authenticatedUser } = useAuth();

  const { cartItems, cartUpdated, latestItemAddedToCart, latestItemAddedToCartTimestamp } = useSelector(
    (state: State) => {
      return {
        cartItems: state.deviceCheckout?.cartItems || [],
        cartUpdated: state.deviceCheckout?.cartUpdated,
        latestItemAddedToCart: state.deviceCheckout?.latestItemAddedToCart || null,
        latestItemAddedToCartTimestamp: state.deviceCheckout?.latestItemAddedToCartTimestamp || 0,
      };
    },
    deepEqual
  );

  type ProductImageProps = {
    product: ShoppingCartItemForCheckout;
  };

  const ProductImage = ({ product }: ProductImageProps) => (
    <Picture
      src={product.imageListingUrl}
      alt={product.productName}
      offerWidthAlternatives={[94]}
      renderedImageSize={{ onPhone: '100vw' }}
    />
  );

  const resolveAriaSummary = (addedAmount: number, totalAmount: number) => {
    if (addedAmount) {
      const ariaSummaryPartOne =
        addedAmount === 1
          ? t.WDX0('One item as added to shopping cart')
          : t.IMA5('{} items added to shopping cart', String(addedAmount));

      const ariaSummaryPartTwo =
        totalAmount === 1
          ? t.HTQL('There is now one item in shopping cart')
          : t.UE2G('There are now {} items in shopping cart', String(totalAmount));

      return `${ariaSummaryPartOne}. ${ariaSummaryPartTwo}.`;
    } else {
      return '';
    }
  };

  const resolveI18nSummary = (totalAmount: number) => {
    if (totalAmount) {
      if (totalAmount > 1) {
        return t.ZE9V('There are {} items in your cart', String(totalAmount));
      } else {
        return t.CYZV('There is 1 item in your cart');
      }
    } else {
      return '';
    }
  };

  const getProducts = (product: ShoppingCartItemForCheckout) => {
    if (product) {
      return [
        {
          addons: getAddonsDisplayData(product.selectedAddOns, product.quantity),
          disclaimer: getProductDisclaimers(product.price, product.quantity, true, isEmployeeUser(authenticatedUser)),
          id: getCartItemId(product),
          name: product.productName,
          quantity: product.quantity,
          price: getProductPriceDisplayData(product.price, product.quantity),
          image: <ProductImage product={product} />,
        },
      ];
    } else {
      return [];
    }
  };

  const products = latestItemAddedToCart ? getProducts(latestItemAddedToCart) : [];
  const totalProductQuantity = getTotalProductQuantity(cartItems);
  const addedQuantity = latestItemAddedToCart?.quantity || 0;
  const ariaSummary = resolveAriaSummary(addedQuantity, totalProductQuantity);
  const i18nClose = t.VLZR(continueShoppingMsg);
  const i18nQuantity = t.M0W7(quantityMsg);
  const i18nSubmit = t.VVQP('To shopping cart');
  const i18nSummary = resolveI18nSummary(totalProductQuantity);
  const i18nTitle = t.VJK6('Added to shopping cart');
  const i18nButtonLabel = t.YXF1('Shopping cart');
  const totals = resolveTotalPrices(cartItems);

  // Popup can't easily be made fixed/sticky, so we have to scroll to make it visible
  useEffect(() => {
    if (cartUpdated && cartItems.length > 0) {
      dispatch(setCartUpdated(false));
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [cartUpdated, cartItems, dispatch]);

  return (
    <CL.HeaderShoppingCart
      ariaSummary={ariaSummary}
      i18nClose={i18nClose}
      i18nQuantity={i18nQuantity}
      i18nSubmit={i18nSubmit}
      i18nSummary={i18nSummary}
      i18nTitle={i18nTitle}
      i18nButtonLabel={i18nButtonLabel}
      lastChange={latestItemAddedToCartTimestamp}
      onSubmit={() =>
        navigate(toShoppingCart, {
          state: { previousPathname: location.pathname, toCheckout: toCheckout },
        })
      }
      products={products}
      totals={totals}
      totalProductQuantity={totalProductQuantity}
    />
  );
};
