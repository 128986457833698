/**
 * Online UI API
 * # Summary Elisa B2B Online UI API  # Usage This API is used in the `https://yrityksille.elisa.fi/` pages.  # Identifiers 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Enumerator of all the possible report status.
 */
export type ReportStatus = 'New' | 'Success' | 'Running' | 'Error';

export const ReportStatus = {
    New: 'New' as ReportStatus,
    Success: 'Success' as ReportStatus,
    Running: 'Running' as ReportStatus,
    Error: 'Error' as ReportStatus
};

