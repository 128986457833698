export enum StepperItemStyle {
  DONE,
  ACTIVE,
  HEADER_ONLY,
  DISABLED,
}

export interface StepperItemProps {
  id?: string;
  stepperStyle: StepperItemStyle;
  headerText?: string;
  children?: JSX.Element | JSX.Element[];
  clickable?: boolean;
}

function getStepperItemAsClassName(state: StepperItemStyle): string {
  switch (state) {
    case StepperItemStyle.ACTIVE:
      return 'ea-stepper__step--active';
    case StepperItemStyle.DISABLED:
      return 'ea-stepper__step--disabled';
    case StepperItemStyle.HEADER_ONLY:
      return 'ea-stepper__step--disabled-heading';
    case StepperItemStyle.DONE:
      return 'ea-stepper__step--done';
  }
}

export const StepperItem = ({ id, stepperStyle, headerText, children, clickable }: StepperItemProps) => {
  const itemClass = getStepperItemAsClassName(stepperStyle);

  return (
    <li id={id} className={`ea-stepper__step ${itemClass} ${clickable ? 'ea-stepper__step--clickable' : ''}`}>
      <div className="ea-stepper__contents-section">
        <div className="ea-stepper__line-container">
          <div className="ea-stepper__line" />
        </div>
        {headerText ? <h4 className="ea-h4 ea-stepper__header">{headerText}</h4> : undefined}
        <div className="ea-stepper__contents">{children}</div>
      </div>
    </li>
  );
};
