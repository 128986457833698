import { type OpenFormAnswers } from '../../OpenFormAnswers.js';
import { OpenFormGridCol, OpenFormGridRow } from '../../OpenFormComponents/OpenFormGrid.js';
import { OpenFormQuestionType } from '../OpenFormQuestion/OpenFormQuestionType.js';
import { OpenFormSectionLabel } from '../../OpenFormComponents/OpenFormSectionLabel.js';
import { type UseOpenFormDispatcher } from '../../OpenFormHooks/useOpenFormDispatcher.js';
import type { OpenFormSection } from '../../../../generated/api/openFormSection.js';

export const OpenFormPageQuestion = ({
  dispatcher,
  answers,
  section,
  disabled,
}: {
  dispatcher: UseOpenFormDispatcher;
  answers: OpenFormAnswers;
  section: OpenFormSection;
  disabled: boolean;
}) => (
  <OpenFormGridRow>
    <OpenFormSectionLabel section={section} />
    {section.questions.filter(answers.visible).map(question => (
      <OpenFormGridRow id={question.guid} key={question.guid}>
        <OpenFormGridCol className="of-openform__view__choices">
          <OpenFormQuestionType
            dispatcher={dispatcher(question)}
            answers={answers}
            question={question}
            disabled={disabled}
          />
        </OpenFormGridCol>
      </OpenFormGridRow>
    ))}
  </OpenFormGridRow>
);
