import * as CL from '@design-system/component-library';
import { dsClass } from '../../common/constants/dsClasses.js';
import { formatSum } from '../../common/utils/priceUtils.js';

import './PaidAmountSummary.scss';

export const PaidAmountSummary = ({ amount, summaryText }: { amount: number; summaryText: string }) => {
  return (
    <CL.Grid>
      <CL.GridRow className="of-paid-amount-summary">
        <CL.GridCol className={dsClass.NO_GUTTER} colWidthS={6} colWidthM={3}>
          <div className={dsClass.H3}>{summaryText}</div>
        </CL.GridCol>
        <CL.GridCol className={dsClass.NO_GUTTER} colWidthS={6} colWidthM={3}>
          <div className={`${dsClass.H3} of-paid-amount-summary__amount`}>{formatSum(amount)}</div>
        </CL.GridCol>
      </CL.GridRow>
    </CL.Grid>
  );
};
