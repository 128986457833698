import { allPricesVatZeroMsg, t } from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { formatSum } from '../../common/utils/priceUtils.js';
import { getAddOnPrice } from './addOnDependencyUtils.js';
import type { AddOn } from '../../generated/api/addOn.js';

import './AddOnChangeConfirmationDialogContent.scss';

const AddedAddOns = ({ addedAddOns, isEmployee }: { addedAddOns: AddOn[]; isEmployee?: boolean }) => (
  <>
    {addedAddOns.length > 0 && (
      <div className={`${dsClass.FONT_WEIGHT_BOLD} ${dsClass.MARGIN_TOP_3}`}>{t.KB82('To be added')}</div>
    )}
    {addedAddOns.map((added, i) => (
      <span key={`added-items-${i}`}>
        <div className={`${dsClass.DISPLAY_FLEX} ${dsClass.JUSTIFY_CONTENT_SPACE_BETWEEN}`}>
          <div className={`${dsClass.DISPLAY_FLEX} ${dsClass.ALIGN_ITEMS_FLEX_START}`}>{added.addOnProductName}</div>
          <div className={`${dsClass.DISPLAY_FLEX} ${dsClass.ALIGN_ITEMS_FLEX_END}`}>
            {!isEmployee && getAddOnPrice(added.price?.effectivePrice).text}
          </div>
        </div>
      </span>
    ))}
  </>
);

const RemovedAddOns = ({ removedAddOns, isEmployee }: { removedAddOns: AddOn[]; isEmployee?: boolean }) => (
  <>
    {removedAddOns.length > 0 && (
      <div className={`${dsClass.FONT_WEIGHT_BOLD} ${dsClass.MARGIN_TOP_3}`}>{t.R8IE('To be removed')}</div>
    )}
    {removedAddOns.map((removed, i) => (
      <span key={`removed-items-${i}`}>
        <div className={`${dsClass.DISPLAY_FLEX} ${dsClass.JUSTIFY_CONTENT_SPACE_BETWEEN}`}>
          <div className={`${dsClass.DISPLAY_FLEX} ${dsClass.ALIGN_ITEMS_FLEX_START}`}>{removed.addOnProductName}</div>
          <div className={`${dsClass.DISPLAY_FLEX} ${dsClass.ALIGN_ITEMS_FLEX_END}`}>
            {!isEmployee && getAddOnPrice(removed.price?.effectivePrice).text}
          </div>
        </div>
      </span>
    ))}
  </>
);

const TotalChangeFee = ({ serviceFee, isEmployee }: { serviceFee?: number; isEmployee: boolean }) =>
  isEmployee ? null : (
    <>
      <div className={`${dsClass.DISPLAY_FLEX} ${dsClass.JUSTIFY_CONTENT_SPACE_BETWEEN} ${dsClass.MARGIN_TOP_3}`}>
        <div className={`${dsClass.DISPLAY_FLEX} ${dsClass.ALIGN_ITEMS_FLEX_START}`}>
          {t.OKLP('Change fees in total')}
        </div>
        <div className={`${dsClass.DISPLAY_FLEX} ${dsClass.ALIGN_ITEMS_FLEX_END}`}>{formatSum(serviceFee) ?? '-'}</div>
      </div>
      <div className={dsClass.FONT_SIZE_SMALL}>{t.QAKO('Change fees will be added to the next invoice')}</div>
    </>
  );

const PaymentInfo = ({ updatedPrice, isEmployee }: { updatedPrice?: number; isEmployee: boolean }) =>
  isEmployee ? null : (
    <>
      <div className={dsClass.MARGIN_TOP_2}>
        {t.YZJ3('Monthly subscription fees after ordering ')} {formatSum(updatedPrice) ?? '-'}
      </div>
      <div className={dsClass.MARGIN_TOP_2}>{t.TEA3(allPricesVatZeroMsg)}</div>
    </>
  );

const RemovalInfo = ({ removedAddOns, isEmployee }: { removedAddOns: AddOn[]; isEmployee: boolean }) =>
  isEmployee ? null : (
    <>
      {removedAddOns.length > 0 && (
        <div className={dsClass.MARGIN_VERTICAL_3}>
          {t.J3XJ(
            'You will receive a final invoice for the terminated service, if usage or monthly fees have not yet been invoiced.'
          )}
        </div>
      )}
    </>
  );

const ActivationInfo = () => (
  <div className={`of-addon-change-confirmation-dialog__border-top ${dsClass.MARGIN_VERTICAL_2}`}>
    <div className={dsClass.MARGIN_TOP_2}>
      {t.I6FA('Please note that it may take several minutes to activate the changes.')}
    </div>
  </div>
);

export const AddOnChangeConfirmationDialogContent = ({
  addedAddOns,
  removedAddOns,
  isEmployee,
  updatedPrice,
  serviceFee,
}: {
  addedAddOns: AddOn[];
  removedAddOns: AddOn[];
  isEmployee: boolean;
  updatedPrice?: number;
  serviceFee?: number;
}) => (
  <div
    className={`of-addon-change-confirmation-dialog of-addon-change-confirmation-dialog__border-top ${dsClass.MARGIN_TOP_2}`}
  >
    <AddedAddOns addedAddOns={addedAddOns} isEmployee={isEmployee} />
    <RemovedAddOns removedAddOns={removedAddOns} isEmployee={isEmployee} />
    <TotalChangeFee serviceFee={serviceFee} isEmployee={isEmployee} />
    <PaymentInfo updatedPrice={updatedPrice} isEmployee={isEmployee} />
    <RemovalInfo removedAddOns={removedAddOns} isEmployee={isEmployee} />
    <ActivationInfo />
  </div>
);
