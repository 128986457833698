import { TypeKeys, searchPickupPointFailed, searchPickupPointFulfilled } from '../actions/index.js';
import { actionToActionState } from './epicUtils.js';
import { callUiApi, prepareUiApiRequest } from '../common/uiApiUtils.js';
import { combineEpics, ofType } from 'redux-observable';
import { concatMap, filter } from 'rxjs/operators';
import { searchForPickupPointPrivateMethod } from '../../generated/api/uiApiMethods.js';
import type { Action } from 'redux';
import type { ActionAndState, EpicDependencies } from './epicUtils.js';
import type { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import type { PickupPointSearchAction, SelfServiceActionTypes } from '../actions/index.js';
import type { PickupPointSearchResponse } from '../../generated/api/models.js';
import type { State } from '../common/store.js';

export const searchPickupPointsEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(
    action$.pipe(
      ofType(TypeKeys.SEARCH_PICKUP_POINT),
      filter((action: PickupPointSearchAction) => !!action.postalCode)
    ),
    (action: PickupPointSearchAction) => actionToActionState(action, state$, 'pickupPointSearch')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const searchPickupPointAction = actionAndState.action as PickupPointSearchAction;
      const successAction = (res: PickupPointSearchResponse): SelfServiceActionTypes => {
        if (!res.pickupPoints) {
          return searchPickupPointFailed(
            `Postal code search "${searchPickupPointAction.postalCode}" could not return anything`,
            404,
            undefined
          );
        }
        return searchPickupPointFulfilled({ pickupPoints: res.pickupPoints });
      };

      const method = searchForPickupPointPrivateMethod({
        service: searchPickupPointAction.service,
        postalCode: searchPickupPointAction.postalCode,
        maxResults: `${searchPickupPointAction.maxResults}`,
      });

      return callUiApi({
        epicDependencies,
        failureAction: searchPickupPointFailed,
        failureParams: {},
        method,
        state$,
        successAction,
      });
    })
  );

export const pickupPointSearchEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> =
  combineEpics(searchPickupPointsEpic);
