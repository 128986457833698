import * as React from 'react';

import './SeparateCharacterInput.scss';

export enum SeparateCharacterInputType {
  NUMBER = 'NUMBER',
}

export type SeparateCharacterInputProps = {
  disabled?: boolean;
  inputType?: SeparateCharacterInputType;
  numberOfCharacters: number;
  onChangeInput: (data: string, index?: number) => void;
  pattern: string;
  value?: string; // length should be equal to number of characters
};

export interface SeparateCharacterInputInternalState {
  fieldValue: string[];
}

export class SeparateCharacterInput extends React.Component<
  SeparateCharacterInputProps,
  SeparateCharacterInputInternalState
> {
  private inputRef: React.RefObject<HTMLInputElement>[];

  constructor(props: SeparateCharacterInputProps) {
    super(props);
    let i = 0;
    this.inputRef = [];
    while (i < props.numberOfCharacters) {
      this.inputRef.push(React.createRef());
      i++;
    }
    this.state = {
      fieldValue: props.value
        ? props.value.split('', props.numberOfCharacters)
        : new Array<string>(props.numberOfCharacters).fill(''),
    };
  }

  componentDidMount() {
    if (this.inputRef[0].current && (!this.props.value || this.props.value.length <= 0)) {
      this.inputRef[0].current.focus();
    }
  }

  render() {
    const { pattern } = this.props;
    const getInputType = (): string => {
      if (this.props.inputType) {
        if (this.props.inputType === SeparateCharacterInputType.NUMBER) {
          return 'tel';
        }
      }
      return 'text';
    };
    const InputFields = this.state.fieldValue.map((name, index) => (
      <input
        disabled={this.props.disabled}
        type={getInputType()}
        pattern={getInputType() === 'tel' ? '/^[0-9]{1}$/' : pattern}
        key={index}
        ref={this.inputRef[index]}
        maxLength={1}
        value={name}
        onChange={(e: React.FormEvent<HTMLInputElement>) => {
          if (e.currentTarget.value.match(pattern)) {
            this.setState(
              {
                fieldValue: this.state.fieldValue.map((val, i) => (i === index ? e.currentTarget.value : val)),
              },
              () => this.props.onChangeInput(this.state.fieldValue.join(''), index)
            );
            if (e.currentTarget.value !== '' && index < this.state.fieldValue.length - 1) {
              const nextBox = this.inputRef[index + 1].current;
              if (nextBox) {
                nextBox.focus();
              }
            }
          }
        }}
        onKeyDown={(e: React.KeyboardEvent & React.FormEvent<HTMLInputElement>) => {
          if (e.keyCode === 8 && e.currentTarget.value === '' && index > 0) {
            const prevBox = this.inputRef[index - 1].current;
            if (prevBox) {
              prevBox.focus();
            }
          }
        }}
      />
    ));

    return <div className="ea-separate-character-input">{InputFields}</div>;
  }
}
