import { AuthenticatedUserRole, CreditCheckDecision } from '../../generated/api/models.js';
import { CommonErrorType } from '../../common/enums.js';
import { DialogWrapper } from '../DialogWrapper/index.js';
import { LoadingDialog } from '../LoadingDialog/LoadingDialog.js';
import {
  closeMsg,
  companyCreditDecisionNegativeMsg,
  companyCreditLimitExceededAdminMsg,
  companyCreditLimitExceededEmployeeMsg,
  creditDecisionFailureMsg,
  creditDecisionFailureRecommendCardPaymentMsg,
  employeeCreditCheckFailedMsg,
  processingOrderMsg,
  t,
} from '../../common/i18n/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { isEppDevicePriceSubType } from '../../common/utils/checkoutUtils.js';
import { isPhysicalProduct } from './deviceCheckoutCardPaymentUtils.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useSelector } from 'react-redux';
import type { DialogState } from '../../common/types/states.js';
import type { State } from '../../selfservice/common/store.js';

const ELISA_CONTACT_TERM_LINK = 'https://yrityksille.elisa.fi/ohje/elisan-sopimusehdot-yritysasiakkaille';

const isCreditCheckFailure = (errorMessage: string) =>
  errorMessage === CreditCheckDecision.COMPANY_CREDIT_LIMIT_EXCEEDED ||
  errorMessage === CreditCheckDecision.COMPANY_NEGATIVE_CREDIT_DECISION ||
  errorMessage === CreditCheckDecision.EMPLOYEE_NEGATIVE_CREDIT_DECISION ||
  errorMessage === CreditCheckDecision.DECLINED ||
  errorMessage === CreditCheckDecision.DECLINED_RECOMMEND_CARD_PAYMENT;

type CreditCheckError = { message: string; pdfLink?: string };

const getEmployeeCreditCheckErrorCrede = (creditCheckDecision: string): CreditCheckError => {
  return creditCheckDecision === CreditCheckDecision.EMPLOYEE_NEGATIVE_CREDIT_DECISION
    ? {
        message: t.ZE11(employeeCreditCheckFailedMsg),
        pdfLink: t.KA37('https://elisa.fi/attachment/content/Elisa_Yleiset_sopimusehdot_ENG.pdf'),
      }
    : { message: t.GCDV(companyCreditLimitExceededEmployeeMsg) };
};

const getAdminCreditCheckErrorCct = (creditCheckDecision: string): CreditCheckError => {
  return creditCheckDecision === CreditCheckDecision.DECLINED_RECOMMEND_CARD_PAYMENT
    ? {
        message: t.R8HE(creditDecisionFailureRecommendCardPaymentMsg),
        pdfLink: ELISA_CONTACT_TERM_LINK,
      }
    : {
        message: t.WXZC(creditDecisionFailureMsg),
        pdfLink: ELISA_CONTACT_TERM_LINK,
      };
};

const getEmployeeCreditCheckErrorCct = (creditCheckDecision: string): CreditCheckError => {
  return creditCheckDecision === CreditCheckDecision.EMPLOYEE_NEGATIVE_CREDIT_DECISION
    ? {
        message: t.ZE11(employeeCreditCheckFailedMsg),
        pdfLink: t.KA37('https://elisa.fi/attachment/content/Elisa_Yleiset_sopimusehdot_ENG.pdf'),
      }
    : { message: t.GCDV(companyCreditLimitExceededEmployeeMsg) };
};

const getAdminCreditCheckErrorCrede = (creditCheckDecision: string): CreditCheckError => {
  switch (creditCheckDecision) {
    case CreditCheckDecision.EMPLOYEE_NEGATIVE_CREDIT_DECISION:
      return {
        message: t.ZE11(employeeCreditCheckFailedMsg),
        pdfLink: t.KA37('https://elisa.fi/attachment/content/Elisa_Yleiset_sopimusehdot_ENG.pdf'),
      };
    case CreditCheckDecision.COMPANY_NEGATIVE_CREDIT_DECISION:
      return {
        message: t.I2Y9(companyCreditDecisionNegativeMsg),
        pdfLink: ELISA_CONTACT_TERM_LINK,
      };
    case CreditCheckDecision.COMPANY_CREDIT_LIMIT_EXCEEDED:
      return { message: t.LIHP(companyCreditLimitExceededAdminMsg) };
    default:
      return { message: t.GCDV(companyCreditLimitExceededEmployeeMsg) };
  }
};

const getCreditCheckError = ({
  creditCheckFailure,
  hasEppDevices,
  hasPhysicalProducts,
  userRole,
  isCctUsed,
}: {
  creditCheckFailure: string;
  hasEppDevices: boolean;
  hasPhysicalProducts: boolean;
  userRole?: AuthenticatedUserRole;
  isCctUsed?: boolean;
}): CreditCheckError => {
  const isAdminOrKeyUser = userRole === AuthenticatedUserRole.ADMIN || userRole === AuthenticatedUserRole.KEY_USER;
  const contractTerms = isAdminOrKeyUser ? ELISA_CONTACT_TERM_LINK : undefined;

  if (isCctUsed) {
    return isAdminOrKeyUser
      ? getAdminCreditCheckErrorCct(creditCheckFailure)
      : getEmployeeCreditCheckErrorCct(creditCheckFailure);
  }
  if (hasEppDevices) {
    return isAdminOrKeyUser
      ? getAdminCreditCheckErrorCrede(creditCheckFailure)
      : getEmployeeCreditCheckErrorCrede(creditCheckFailure);
  } else {
    // regardless of an employee or admin, we give the same error in this case
    if (creditCheckFailure === CreditCheckDecision.EMPLOYEE_NEGATIVE_CREDIT_DECISION) {
      return {
        message: t.ZE11(employeeCreditCheckFailedMsg),
        pdfLink: t.KA37('https://elisa.fi/attachment/content/Elisa_Yleiset_sopimusehdot_ENG.pdf'),
      };
    }

    if (hasPhysicalProducts) {
      return {
        message: t.R8HE(creditDecisionFailureRecommendCardPaymentMsg),
        pdfLink: contractTerms,
      };
    }
    return {
      message: t.WXZC(creditDecisionFailureMsg),
      pdfLink: contractTerms,
    };
  }
};

const useShoppingCart = (): { hasEppDevices: boolean; hasPhysicalProducts: boolean } => {
  const shoppingCartItems = useSelector((state: State) => state.deviceCheckout?.cartItems ?? [], deepEqual);
  const hasEppDevices = shoppingCartItems.some(({ price }) => isEppDevicePriceSubType(price));
  const hasPhysicalProducts = shoppingCartItems.some(isPhysicalProduct);

  return { hasEppDevices, hasPhysicalProducts };
};

const CreditCheckDialog = ({
  creditCheckFailure,
  redirectPath,
  onCloseDialog,
}: {
  creditCheckFailure: string;
  redirectPath: string;
  onCloseDialog: () => void;
}) => {
  const { authenticatedUser } = useAuth();
  const isCctUsed = useSelector((state: State) => state.config.featureFlags.cctOnlineOrders);
  const { hasEppDevices, hasPhysicalProducts } = useShoppingCart();
  const { message, pdfLink } = getCreditCheckError({
    creditCheckFailure,
    hasEppDevices,
    hasPhysicalProducts,
    userRole: authenticatedUser?.userRole,
    isCctUsed,
  });

  const isClosable = isCctUsed
    ? creditCheckFailure === CreditCheckDecision.DECLINED_RECOMMEND_CARD_PAYMENT &&
      authenticatedUser?.userRole !== AuthenticatedUserRole.EMPLOYEE
    : hasPhysicalProducts && !hasEppDevices;

  return (
    <DialogWrapper
      buttons={
        isClosable
          ? [
              {
                text: t.WOYD(closeMsg),
                onClick: onCloseDialog,
              },
            ]
          : undefined
      }
      closeable={isClosable}
      header={t.ZK30('Finalizing the order failed')}
      links={
        !isClosable
          ? [
              {
                href: redirectPath,
                text: t.PLAW('Return to the shop'),
              },
            ]
          : undefined
      }
      onCloseDialog={onCloseDialog}
    >
      <p>
        {message}
        {pdfLink && (
          <>
            {' '}
            <a href={pdfLink} target="_blank" rel="noreferrer">
              {t.Z3L0('More information')}
            </a>
          </>
        )}
      </p>
    </DialogWrapper>
  );
};

export type SubmitOrderDialogProps = {
  dialog?: DialogState;
  redirectPath: string;
  onClickHome: () => void;
  onCloseDialog: () => void;
};

export const SubmitOrderDialog = (props: SubmitOrderDialogProps) => {
  if (props.dialog?.errors && props.dialog.errors.length > 0) {
    const dialogError = props.dialog.errors[0];

    if (isCreditCheckFailure(dialogError.message)) {
      return (
        <CreditCheckDialog
          creditCheckFailure={dialogError.message}
          redirectPath={props.redirectPath}
          onCloseDialog={props.onCloseDialog}
        />
      );
    }

    const loadingDialogError = {
      buttonAction: props.onClickHome,
      buttonText: t.PLAW('Return to the shop'),
      header:
        dialogError.type === CommonErrorType.SYSTEM ? t.V0VJ('Oops, something went wrong') : t.N3V5('What a shame'),
      message: dialogError.message,
    };

    return <LoadingDialog error={loadingDialogError} loadingText={t.RW6O(processingOrderMsg)} />;
  }
  return <LoadingDialog loadingText={t.RW6O(processingOrderMsg)} />;
};
