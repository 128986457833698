import * as CL from '@design-system/component-library';
import * as React from 'react';
import { Input } from '../../Input/Input.js';
import { POSTAL_CODE_REGEX } from '../../../common/utils/validationUtils.js';
import { deepEqual } from '../../../common/utils/objectUtils.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { searchPickupPoint } from '../../../selfservice/actions/index.js';
import { t } from '../../../common/i18n/index.js';
import { useDispatch, useSelector } from 'react-redux';
import type { PickupPoint } from '../../../generated/api/models.js';
import type { State } from '../../../selfservice/common/store.js';

import './PickupPointSearch.scss';

interface PickupPointSearchProps {
  service: string;
  selectedPickupPoint?: PickupPoint;
  onSelectPickupPoint: (pickupPointId: PickupPoint) => void;
  onChangePostalCode: (code: string) => void;
  defaultPostalCode?: string;
}

const SEARCH_MAX_ROWS = 15;

export const PickupPointSearch = ({
  service,
  selectedPickupPoint,
  onSelectPickupPoint,
  onChangePostalCode,
  defaultPostalCode,
}: PickupPointSearchProps) => {
  const dispatch = useDispatch();
  const [maxResults, setMaxResults] = React.useState(SEARCH_MAX_ROWS);
  const [postalCode, setPostalCode] = React.useState(defaultPostalCode || '');

  const suggestedPickupPoints = useSelector((state: State) => state.pickupPointSearch?.items || [], deepEqual);
  const isPostalCodeValid = (input: string): boolean => {
    return POSTAL_CODE_REGEX.test(input);
  };
  const handlePostalCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchInput = event.target.value;
    if (isPostalCodeValid(searchInput)) {
      setPostalCode(searchInput);
      setMaxResults(SEARCH_MAX_ROWS);
    }
  };

  React.useEffect(() => {
    if (isPostalCodeValid(postalCode)) {
      onChangePostalCode(postalCode);
      dispatch(searchPickupPoint(service, postalCode, maxResults));
    }
  }, [dispatch, service, postalCode, maxResults]); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="of-device-checkout-delivery-details__pickup">
      <Input
        defaultValue={defaultPostalCode}
        label={t.RUAW('Postal code')}
        onChange={handlePostalCodeChange}
        onFocus={handlePostalCodeChange}
      />
      <CL.SelectGroup
        className={dsClass.MARGIN_BOTTOM_6}
        onChange={pickupPointId => {
          const selection = suggestedPickupPoints.find(pp => pp.id === pickupPointId);
          if (selection) {
            onSelectPickupPoint(selection);
          }
        }}
        products={suggestedPickupPoints.map(pp => ({
          description: pp.address,
          id: pp.id,
          name: pp.name,
        }))}
        type="column"
        value={selectedPickupPoint?.id}
      />
      {maxResults === suggestedPickupPoints.length && (
        <CL.Button color="link" onClick={() => setMaxResults(max => max + 5)}>
          <>
            {t.H6OA('Show more')}
            <CL.Icon icon="arrow-down" />
          </>
        </CL.Button>
      )}
    </div>
  );
};
