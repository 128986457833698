import * as CL from '@design-system/component-library';
import { Catalog } from '../../generated/api/catalog.js';
import { CheckboxTree } from '../CheckboxTree/ChecboxTree.js';
import { SelectRadio } from '../../common/react-hook-form/components/SelectRadio.js';
import { Spinner } from '../../public/site/siteUtils.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { getCatalogModificationStatus, getCatalogStatus } from '../../common/utils/catalogUtils.js';
import { monthMsg, t } from '../../common/i18n/index.js';
import { useFormContext } from 'react-hook-form';
import type { CheckboxTreeItem } from '../CheckboxTree/ChecboxTree.js';
import type { VirtualCatalog } from '../../generated/api/virtualCatalog.js';

export interface VirtualCatalogReplaceProductsProps {
  treeItems: CheckboxTreeItem[];
  catalog: Catalog;
  virtualCatalog: VirtualCatalog;
  isSaving: boolean;
}

export enum CatalogReplaceOperation {
  PUBLISH = 'PUBLISH',
  KEEP_STATUS = 'KEEP_STATUS',
}

export type FormValues = {
  selectedCatalogs: string[];
  selectedSavingOption: CatalogReplaceOperation;
};

const hasReplaceableCatalogs = (treeItems: CheckboxTreeItem[]) => treeItems[0] && treeItems[0].children?.length;

interface CatalogProductsTreeProps {
  treeItems: CheckboxTreeItem[];
  isSaving: boolean;
}

const savingOptions = [
  { label: t.YWE4('Publish changes immediately to selected lists'), value: CatalogReplaceOperation.PUBLISH },
  { label: t.H041('Keep the existing statuses of the selected lists'), value: CatalogReplaceOperation.KEEP_STATUS },
];

const CatalogProductsTree = ({ treeItems, isSaving }: CatalogProductsTreeProps) => {
  const methods = useFormContext();

  if (isSaving) {
    return <Spinner />;
  }

  return hasReplaceableCatalogs(treeItems) ? (
    <>
      <CheckboxTree
        data={treeItems}
        onChange={(selectedValues: string[]) => methods.setValue('selectedCatalogs', selectedValues)}
      />
      <h4 className={`${dsClass.PADDING_TOP_2} ${dsClass.MARGIN_BOTTOM_4}`}>{t.F5YW('Publication of changes')}</h4>
      <SelectRadio name="selectedSavingOption" items={savingOptions} />
    </>
  ) : (
    <CL.Disclaimer
      disclaimerType="info"
      className={dsClass.TEXT_M}
      icon={<CL.Icon icon="warning" />}
      text={t.F1G3('No Catalogs to be copied to')}
      visible={true}
    />
  );
};

export const VirtualCatalogReplaceProducts = ({
  treeItems,
  catalog,
  virtualCatalog,
  isSaving,
}: VirtualCatalogReplaceProductsProps) => {
  const headingText =
    catalog.productType === Catalog.ProductTypeEnum.EPP_RECURRING
      ? t.YU6F('Copy the product selection of the device list to other Elisa Device Service lists')
      : t.E0TK('Copy the product selection of the device list to other one-time lists');

  const statusText = getCatalogModificationStatus(getCatalogStatus(virtualCatalog)).text;
  const status = statusText ? `, ${statusText}` : '';

  const catalogInfoText = catalog?.contractPeriod
    ? `${catalog?.name} (${catalog?.contractPeriod} ${t.XXVX(monthMsg)}${status})`
    : `${catalog?.name} (${t.QYSL('One-time fee')}${status})`;

  return (
    <>
      <h3>{headingText}</h3>
      <div className={dsClass.PADDING_TOP_2}>
        {t.DVXR(
          'Select below the device lists to which you want to copy the product selection of this list. Please note that copying the product selection will replace all products in the selected device lists. This does not affect the device list settings.'
        )}
      </div>
      <div>
        <h4 className={`${dsClass.PADDING_TOP_2} ${dsClass.MARGIN_BOTTOM_1}`}>{t.DJO2('Selected catalog')}</h4>
        <div>{catalogInfoText}</div>
      </div>
      <h4 className={`${dsClass.PADDING_TOP_2} ${dsClass.MARGIN_BOTTOM_4}`}>
        {t.X7RU('Select the device lists to which you want to copy the product selection.')}
      </h4>
      <CatalogProductsTree treeItems={treeItems} isSaving={isSaving} />
    </>
  );
};
