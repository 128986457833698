import { TypeKeys } from './actionsUtils.js';

export interface FooterVisibilityAction {
  type: TypeKeys.FOOTER_SET_VISIBILITY;
  footerVisible: boolean;
}

export const setFooterVisibility = (footerVisible: boolean): FooterVisibilityAction => ({
  footerVisible,
  type: TypeKeys.FOOTER_SET_VISIBILITY,
});

export type LayoutActionTypes = FooterVisibilityAction;
