import { BreadCrumbsWithTitle } from '../../../../../../components/BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { ModelType } from '../../../../../../common/enums.js';
import { OrderSubscriptionSelection } from '../../../../../../components/OrderSubscriptionSelection/OrderSubscriptionSelection.js';
import { OrderSubscriptionSelectionHeader } from './OrderSubscriptionSelectionHeader.js';
import {
  newSubscriptionMsg,
  omaElisaForCompaniesMsg,
  t,
  voiceSubscriptionsMsg,
} from '../../../../../../common/i18n/index.js';
import { paths } from '../../../../../../common/constants/pathVariables.js';
import { useLoaderData, useRouteLoaderData } from 'react-router-dom';
import type { OnlineModel } from '../../../../../../generated/api/onlineModel.js';
import type { OrderSubscriptionCommonLoaderData, OrderVoiceSubLoaderData } from '../../../../../../common/loaders.js';

import './OrderSelectionPath.scss';

export const OrderVoiceSubSelectionPath = () => {
  const { companyInfo, onlineModels } = useRouteLoaderData('newVoiceSubCommon') as OrderSubscriptionCommonLoaderData;
  const { voiceModel, addOnRules } = useLoaderData() as OrderVoiceSubLoaderData;
  const uiOptions = companyInfo?.uiOptions as { toggles?: string[] };

  const findMinuteModels = (model: OnlineModel) =>
    model.onlineModelCode === ModelType.VoiceYrityspuhe || model.onlineModelCode === ModelType.VoicePerusliittyma4g;

  const isOptionalVoiceModel = (model: OnlineModel) =>
    model.onlineModelCode === ModelType.Voice || model.onlineModelCode === ModelType.Yrityspaketti;

  const voiceModels = onlineModels.filter(isOptionalVoiceModel).concat(voiceModel);

  return (
    <>
      <OrderSubscriptionSelectionHeader
        breadCrumbs={
          <BreadCrumbsWithTitle
            breadCrumbPaths={[
              { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
              { name: t.AR0B(voiceSubscriptionsMsg), path: paths.PS_MOBILE_SUBSCRIPTIONS },
              { name: t.OK8J(newSubscriptionMsg) },
            ]}
          />
        }
      />
      <OrderSubscriptionSelection
        onlineModelType={ModelType.VoiceSME}
        onlineModels={voiceModels}
        minuteModels={onlineModels?.filter(findMinuteModels)}
        addOnRules={addOnRules}
        toggles={uiOptions?.toggles}
      />
    </>
  );
};
