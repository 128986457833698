import { Link } from 'react-router-dom';
import { dsClass } from '../../common/constants/dsClasses.js';

import './Table.scss';

export interface TableTopButton {
  label: string;
  onClick: () => void;
}

export interface TableTopLink {
  label: string;
  to: string;
}

export const TableTopActions = ({ buttons, links }: { buttons?: TableTopButton[]; links?: TableTopLink[] }) => (
  <div className={`of-table__input-bar ${dsClass.DISPLAY_FLEX} ${dsClass.JUSTIFY_CONTENT_FLEX_END}`}>
    {buttons?.map(({ label, onClick }, i) => (
      <button
        className={`${dsClass.LINK} ${dsClass.LINK_STYLE_LINK_BUTTON} ${dsClass.LINK_BUTTON_COLOR_PRIMARY} ${dsClass.LINK_BUTTON_SIZE_M}`}
        key={i}
        onClick={onClick}
      >
        <span>{label}</span>
      </button>
    ))}
    {links?.map(({ label, to }, i) => (
      <Link
        className={`${dsClass.LINK} ${dsClass.LINK_STYLE_LINK_BUTTON} ${dsClass.LINK_BUTTON_COLOR_PRIMARY} ${dsClass.LINK_BUTTON_SIZE_M}`}
        key={i}
        to={to}
      >
        {label}
      </Link>
    ))}
  </div>
);
