/**
 * Online UI API
 * # Summary Elisa B2B Online UI API  # Usage This API is used in the `https://yrityksille.elisa.fi/` pages.  # Identifiers 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Current status of an account
 */
export type AccountStatus = 'FOUNDED' | 'ACTIVE' | 'PASSIVE' | 'TERMINATED';

export const AccountStatus = {
    FOUNDED: 'FOUNDED' as AccountStatus,
    ACTIVE: 'ACTIVE' as AccountStatus,
    PASSIVE: 'PASSIVE' as AccountStatus,
    TERMINATED: 'TERMINATED' as AccountStatus
};

