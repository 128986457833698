import { HeroHeading, HeroHeadingType } from '../../../../../components/HeroHeading/index.js';
import { LocalNavi } from '../../../../../components/LocalNavi/index.js';
import { ServiceContracts } from '../../../../../components/SubscriptionLists/ServiceContracts.js';
import {
  contractsMsg,
  myProductsAndServicesMsg,
  omaElisaForCompaniesMsg,
  t,
} from '../../../../../common/i18n/index.js';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import { getDisplayCategories } from '../../../../../components/SubscriptionsLayout/SubscriptionsLayout.js';
import { getMobileIdContracts } from '../../../../../selfservice/actions/mobileIdContractActions.js';
import { isFeatureEnabled } from '../../../../../common/utils/featureFlagUtils.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { stripActions } from '../../../../../common/utils/stateUtils.js';
import { useAuth } from '../../../AuthProvider.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSearchParams } from '../../../../../common/hooks/useSearchParams.js';
import type { DefaultListSearchParams } from '../../../../../components/Table/index.js';
import type { State } from '../../../../../selfservice/common/store.js';

export const ContractsPath = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { authenticatedUser } = useAuth();
  const { config, subs } = useSelector(
    (state: State) => ({
      config: state.config,
      subs: stripActions(state.selfservice?.subscriptions?.contract) || undefined,
    }),
    deepEqual
  );
  const { search, offset = '0' } = useSearchParams<DefaultListSearchParams>();

  useEffect(() => {
    if (!subs?.mobileIdContracts) {
      dispatch(getMobileIdContracts());
    }
  }, [dispatch, pathname, subs?.mobileIdContracts]);

  return (
    <div className="of-subscriptions-scene">
      <HeroHeading
        breadCrumbPaths={[
          { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
          { name: t.SW0J(contractsMsg) },
        ]}
        heroHeadingType={HeroHeadingType.SUBSCRIPTIONS}
        title={t.VWNX(myProductsAndServicesMsg)}
      />
      <LocalNavi categories={getDisplayCategories(config, authenticatedUser?.enabledFeatureFlags)} />
      <ServiceContracts
        offset={Number(offset)}
        search={search}
        mobileIdContracts={subs?.mobileIdContracts}
        isMobileIdEnabled={isFeatureEnabled(config.featureFlags.mobileId)}
        isLoading={subs?.loadingContracts || false}
      />
    </div>
  );
};
