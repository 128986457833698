import * as CL from '@design-system/component-library';
import { dsClass } from '../../common/constants/dsClasses.js';
import { t } from '../../common/i18n/index.js';

import './OrderNewsLetter.scss';

const TEXT_SIZES = {
  colWidthXS: 4,
  colWidthS: 6,
  colWidthM: 3,
  colWidthL: 3,
  colWidthXL: 3,
} as const;

const FORM_SIZES = {
  colWidthXS: 4,
  colWidthS: 6,
  colWidthM: 3,
  colWidthL: 5,
  colWidthXL: 5,
} as const;

export const OrderNewsLetter = () => {
  return (
    <CL.Grid className={`${dsClass.GRID} of-order-newsletter`}>
      <CL.GridRow justifyCenter>
        <CL.GridCol className={dsClass.GRID_COL} {...TEXT_SIZES}>
          <h3>{t.ZV9E('Stay informed!')}</h3>
          <p>{t.W77N('Subscribe to our newsletter.')}</p>
        </CL.GridCol>
        <CL.GridCol className={dsClass.GRID_COL} {...FORM_SIZES}>
          <iframe title="uutiskirje" src="https://cloud.viestinta.elisa.fi/sa-newsletter" />
        </CL.GridCol>
      </CL.GridRow>
    </CL.Grid>
  );
};
