import * as React from 'react';
import { ConfirmationDialog } from '../ConfirmationDialog/index.js';
import { EMAIL_REGEX } from '../../common/utils/emailUtils.js';
import { t } from '../../common/i18n/index.js';
import type { CommonError } from '../../common/types/errors.js';
import type { RingEmailDialogParams } from '../../common/types/dialog.js';

import './RingEmailDialog.scss';

export type RingEmailDialogProps = {
  errors?: CommonError[];
  onCloseDialog: () => void;
  params: RingEmailDialogParams;
};

export const RingEmailDialog = ({ errors, onCloseDialog, params }: RingEmailDialogProps) => {
  const [newRingUsername, setNewRingUsername] = React.useState('');
  const isNewRingUsernameValidEmail = newRingUsername === '' || EMAIL_REGEX.test(newRingUsername);
  return (
    <ConfirmationDialog
      body={
        <div>
          <p>
            {t.A8Y9(
              `eIP Exchange O365 addOn requires user's Office 365 email address in order to work. This email address will also be used as Ring username.`
            )}
          </p>
          <p>{t.TVWC('Email can also be added and modified later in the OmaRing service.')}</p>
          <p>
            <input
              id="ring-username-input"
              className="ea-input__input"
              name="username"
              type="text"
              placeholder={t.OKYY('Email address')}
              onChange={(e: React.FormEvent<HTMLInputElement>) => setNewRingUsername(e.currentTarget.value)}
            />
          </p>
        </div>
      }
      errors={errors}
      onCloseDialog={onCloseDialog}
      onConfirm={() => params.onConfirm(newRingUsername)}
      disableConfirm={!isNewRingUsernameValidEmail}
      confirmButtonText={t.I62A('Continue')}
      header={t.G39I('Email address is needed')}
    />
  );
};
