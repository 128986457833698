import { Icon } from '../Icon/Icon.js';
import { Input } from '../Input/Input.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { t } from '../../common/i18n/index.js';
import { useDebounce } from '../../common/hooks/useDebounce.js';
import { useEffect, useState } from 'react';
import type { RefObject } from 'react';

import './Search.scss';

export interface SearchProps {
  className?: string;
  debounceTime?: number;
  disabled?: boolean;
  emptyResultsLabel?: string;
  initialFocus?: boolean;
  initialValue?: string;
  inputLabel?: string;
  inputPlaceholder?: string;
  inputRef?: RefObject<HTMLInputElement>;
  minimumCharacters?: number;
  searchResults: SearchResult[];
  showSuccessIcon?: boolean;
  validationError?: string;
  value: string;
  setValue: (searchValue: string) => void;
  onSearch: (searchText: string) => void;
}

export interface SearchResult {
  key: string;
  displayText: string;
}

/**
 * @deprecated don't add any new use cases for this component
 */
export const Search = ({
  setValue,
  value,
  debounceTime = 300,
  disabled,
  emptyResultsLabel,
  initialValue,
  inputLabel,
  inputPlaceholder,
  inputRef,
  minimumCharacters = 3,
  onSearch,
  searchResults,
  showSuccessIcon,
  validationError,
}: SearchProps) => {
  /*
    Note: Debounced Search function here relies on the `onSearch` having a constant (referential) identity and will be recreated
    whenever the identity (or the debounceTime) changes. This might result in the debounce function firing immediately if the
    `onSearch` identity keeps changing on component rerenders in the parent component.
  */
  const debouncedSearch = useDebounce(onSearch, debounceTime);

  const [focus, setFocus] = useState(false);
  const [showError, setShowError] = useState(false);
  const label = inputLabel || 'Search';

  const length = Math.max(value.length, initialValue?.length || 0);

  const getEmptyResultError = () => (showError ? emptyResultsLabel || t.OQBT('No results found') : undefined);
  const getValidationError = () => (!focus && validationError ? validationError : undefined);

  useEffect(() => {
    setShowError(length >= minimumCharacters && validationError !== undefined);
  }, [validationError, length, minimumCharacters]);

  const shouldShowError = showError || false;
  const error = shouldShowError ? getEmptyResultError() || getValidationError() : undefined;

  return (
    <div className="of-search__container">
      <div className={`${dsClass.POSITION_RELATIVE} ${dsClass.MARGIN_0}`}>
        <Input
          type="text"
          name="search"
          autoComplete="off"
          className={dsClass.MARGIN_0}
          disabled={disabled}
          data-testid="search-input"
          error={error}
          label={label}
          onBlur={() => setFocus(false)}
          onFocus={() => setFocus(true)}
          onChange={event => {
            setValue(event.currentTarget.value);
            if (event.currentTarget.value.length >= minimumCharacters) {
              debouncedSearch(event.currentTarget.value);
            }
          }}
          placeholder={inputPlaceholder || label}
          ref={inputRef}
          value={value}
        />
        {showSuccessIcon && (
          <span className={`of-search__success-icon ${dsClass.POSITION_ABSOLUTE}`}>
            <Icon icon="check" color="green-600" />
          </span>
        )}
      </div>
      <ul
        className={`of-search__result-list ${
          searchResults.length > 0 && focus && length >= minimumCharacters ? 'of-search__result-list_open' : ''
        }`}
      >
        {searchResults.map(searchResult => (
          <li key={searchResult.key}>
            <button
              onMouseDown={event => {
                // This prevents onBlur of the input from happening
                event.preventDefault();
              }}
              onClick={() => {
                setValue(searchResult.displayText);
                onSearch(searchResult.displayText);
              }}
            >
              {searchResult.displayText}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};
