import { CommonErrorType } from '../enums.js';
import { PostReviewRequest } from '../../generated/api/models.js';
import { fieldCantBeEmptyMsg, t } from '../i18n/index.js';
import { isFeatureEnabled } from './featureFlagUtils.js';
import { useStore } from 'react-redux';
import type { Address, GenericError, OnlineOrderReview, SubscriptionRedeemReview } from '../../generated/api/models.js';
import type { CommonError } from '../types/errors.js';
import type { State } from '../../selfservice/common/store.js';

/**
 * Aim to suppress Error in a cases where supplied data is invalid
 * Shouldn't be used in cases where component throws errors if developer hasn't given all parameters
 */
export const throwError = (msg: string) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const store = useStore<State>();
  if (isFeatureEnabled(store.getState().config.featureFlags.throwErrors)) {
    throw new Error(msg);
  } else {
    // eslint-disable-next-line
    console.error(msg);
  }
  return null;
};

export const addValidationError = (errors: CommonError[], message: string, field: string) => {
  errors.push({
    field,
    message,
    type: CommonErrorType.VALIDATION,
  });
};
export const addValidationErrorWithParent = (errors: CommonError[], message: string, field: string, parent: string) => {
  errors.push({
    field,
    message,
    parent,
    type: CommonErrorType.VALIDATION,
  });
};

export const addEmptyFieldValidationError = (errors: CommonError[], field: string, errorText?: string) => {
  addValidationError(errors, errorText ? errorText : t.VPVR('The field cannot be empty'), field);
};

export const addEmptyFieldValidationErrorWithParent = (errors: CommonError[], field: string, parent: string) => {
  addValidationErrorWithParent(errors, t.VPVR(fieldCantBeEmptyMsg), field, parent);
};

export const addErrorsFromUpsertAddress = (errors: CommonError[], address: Address, key: string) => {
  // Payer address
  if (!address.line1 || address.line1.length === 0) {
    addEmptyFieldValidationError(errors, `${key}.line1`);
  }
  if (!address.postalCode || address.postalCode.length === 0) {
    addEmptyFieldValidationError(errors, `${key}.postalCode`);
  }
  if (!address.postOffice || address.postOffice.length === 0) {
    addEmptyFieldValidationError(errors, `${key}.postOffice`);
  }
};
export const getElementsWithErrors = (domRefs: { [key: string]: HTMLElement }, errors: CommonError[]) => {
  return Object.keys(domRefs)
    .filter(
      field =>
        errors.find(
          error => error.field === field || (error.field !== undefined && error.field.split('[')[0] === field)
        ) !== undefined
    )
    .reduce((acc: Array<HTMLElement>, curr) => acc.concat(domRefs[curr]), []);
};

export function getErrorsFromReview(
  request: OnlineOrderReview | SubscriptionRedeemReview,
  reviewType: PostReviewRequest.ReviewTypeEnum,
  approve: boolean,
  rejectReason?: string,
  validationErrors?: CommonError[]
): CommonError[] | undefined {
  const errors: CommonError[] = [];
  if (reviewType === PostReviewRequest.ReviewTypeEnum.ONLINE_ORDER) {
    if (!approve) {
      if (rejectReason !== undefined && rejectReason.trim().length === 0) {
        addEmptyFieldValidationError(errors, 'rejectReason');
      }
    } else {
      if (
        (request as OnlineOrderReview).billingAccountId === undefined ||
        (request as OnlineOrderReview).billingAccountId === ''
      ) {
        addEmptyFieldValidationError(errors, 'billingAccountCombobox');
      }
    }
  }
  if (errors.length === 0) {
    return validationErrors;
  }
  return errors.concat(validationErrors || []);
}

export function getErrorsByParentIndex(cartItemInstanceId: string, errors?: CommonError[]): CommonError[] | undefined {
  if (!errors || errors.length <= 0) {
    return undefined;
  }

  return errors.filter(error => {
    // get the cartItemInstanceId from parent
    return error.parent
      ? error.parent.substring(error.parent.indexOf('[') + 1, error.parent.indexOf(']')) === cartItemInstanceId
      : false;
  });
}

export function getFirstError(
  cartItemInstanceId: string,
  field: string,
  errors?: CommonError[]
): CommonError | undefined {
  return getErrorsByParentIndex(cartItemInstanceId, errors)?.filter(error => error.field === field)[0];
}

export const convertToCommonErrors = (message: string, statusCode?: number, errors?: GenericError[]): CommonError[] => {
  // If errors is type of object then return system error with with source as given errors object.
  // This could be e.g. 409 Conflict when specific error response is returned with error body.
  if (errors !== null && typeof errors === 'object' && !Array.isArray(errors)) {
    return [{ type: CommonErrorType.SYSTEM, message, source: { statusCode, error: errors } }];
  }
  // If no object representing the error is found, resolve the error type based on HTTP status code.
  if (statusCode) {
    // If errors (body of error response) is null check respectful status code.
    if (statusCode === 404) {
      return [{ type: CommonErrorType.NOT_FOUND, message, source: { statusCode } }];
    } else if (statusCode === 401 || statusCode === 403 || statusCode === 409) {
      return [{ type: CommonErrorType.FORBIDDEN, message, source: { statusCode } }];
    } else if (statusCode === 400) {
      return [{ type: CommonErrorType.BAD_REQUEST, message, source: { statusCode } }];
    } else if (statusCode === 422) {
      return [{ type: CommonErrorType.UNPROCESSABLE_ENTITY, message, source: { statusCode } }];
    } else if (statusCode === 204) {
      return [{ type: CommonErrorType.SYSTEM, message, source: { statusCode } }];
    }
  }
  // 500 error will be usually mapped to this when there is no body from the error.
  return [{ message, type: CommonErrorType.SYSTEM }];
};
export const convertToCommonErrorsWithMessage = (
  message: string,
  statusCode?: number,
  errors?: GenericError[]
): CommonError[] | undefined => {
  if (errors !== null && Array.isArray(errors)) {
    if (statusCode === 403) {
      return [{ type: CommonErrorType.FORBIDDEN, message, source: { statusCode, error: errors[0] } }];
    } else if (statusCode === 422) {
      return [{ type: CommonErrorType.UNPROCESSABLE_ENTITY, message, source: { statusCode, error: errors[0] } }];
    }
  }
  return convertToCommonErrors(message, statusCode, errors);
};
// Converts CommonErrors to a map with field as key and message as value. If field is missing,
// the key '__generic_error__' is used
export const convertToErrorStringMap = (errors?: CommonError[]): { [s: string]: string } | undefined => {
  if (!errors) {
    return undefined;
  }
  return errors.reduce((stringMap: { [s: string]: string }, { field, message }: CommonError) => {
    if (field) {
      if (stringMap[field]) {
        stringMap[field] += ' ' + message;
      } else {
        stringMap[field] = message;
      }
    } else {
      if (stringMap.__generic_error__) {
        stringMap.__generic_error__ += ' ' + message;
      } else {
        stringMap.__generic_error__ = message;
      }
    }
    return stringMap;
  }, {});
};
export const convertStringMapToCommonErrors = (stringMap?: { [s: string]: string }): CommonError[] | undefined => {
  if (!stringMap) {
    return undefined;
  }
  const keys = Object.keys(stringMap);
  return keys.map((key: string) => {
    if (key === '__generic_error__') {
      return { type: CommonErrorType.SYSTEM, message: stringMap[key] };
    }
    return { type: CommonErrorType.VALIDATION, field: key, message: stringMap[key] };
  });
};

export const convertToCommonErrorsUsingErrorsMessages = (
  rawMessage: string,
  statusCode?: number,
  errors?: GenericError[]
): CommonError[] => {
  const message = errors?.map(error => error.message).join('\n') ?? rawMessage;
  return convertToCommonErrors(message, statusCode, errors);
};
