import { TypeKeys } from '../actions/index.js';
import { actionToActionState } from './epicUtils.js';
import { callUiApi, prepareUiApiRequest } from '../common/uiApiUtils.js';
import { concatMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { resolveUserNameConflictPrivateMethod } from '../../generated/api/uiApiMethods.js';
import { selectUsernameFailed, selectUsernameFulfilled } from '../actions/selectUsernameActions.js';
import type { Action } from 'redux';
import type { ActionAndState, EpicDependencies } from './epicUtils.js';
import type { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import type { SelectUsernameAction } from '../actions/selectUsernameActions.js';
import type { SelfServiceActionTypes } from '../actions/index.js';
import type { State } from '../common/store.js';

export const selectUsernameEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.SELECT_USERNAME)), (action: SelectUsernameAction) =>
    actionToActionState(action, state$, 'authenticated')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      if (!actionAndState.state) {
        throw new Error('invalid action state for selecting username');
      }
      const selectUserNameAction: SelectUsernameAction = actionAndState.action as SelectUsernameAction;
      const successAction = () => {
        return selectUsernameFulfilled(selectUserNameAction.request.userName);
      };

      return callUiApi({
        epicDependencies,
        failureAction: selectUsernameFailed,
        method: resolveUserNameConflictPrivateMethod(),
        payload: selectUserNameAction.request,
        state$,
        successAction,
      });
    })
  );
