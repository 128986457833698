import { PRODUCT_DETAILS_CLASS } from './productDetailsConstants.js';
import { ProductDetails } from './ProductDetails.js';
import { applyDiscounts } from './utils/productDetailsUtils.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useSelector } from 'react-redux';
import type { CartItemContainer } from './ProductDetails.js';
import type { OnlineModel } from '../../generated/api/models.js';
import type { ProductDetailsData } from '../../content-blocks/cmsSchema.js';
import type { State } from '../../selfservice/common/store.js';

import './ProductDetailsPublicLoader.scss';

export interface ProductDetailsPublicLoaderProps extends ProductDetailsData {
  addToCart: (cartItemContainer: CartItemContainer) => void;
  onlineModel: OnlineModel;
}

export const ProductDetailsPublicLoader = ({
  eCommerceEvents,
  addToCart,
  onlineModel,
}: ProductDetailsPublicLoaderProps) => {
  const { authenticatedUser, isAuthenticated, ssoSessionValid } = useAuth();
  const companyInfo = useSelector((state: State) => state.selfservice?.companyInfo, deepEqual);
  const modelWithDiscounts = companyInfo?.discountedPrices
    ? applyDiscounts(onlineModel, companyInfo.discountedPrices)
    : onlineModel;

  return (
    <div className={`${PRODUCT_DETAILS_CLASS}-public-loader`}>
      <ProductDetails
        onlineModel={modelWithDiscounts}
        addToCart={addToCart}
        eCommerceEvents={eCommerceEvents}
        isPublicSite={true}
        isEmployee={false}
        user={isAuthenticated ? authenticatedUser : undefined}
        companyInfo={companyInfo ?? undefined}
        showOfferAndPrices={ssoSessionValid ? !!companyInfo?.businessId && !!companyInfo?.discountedPrices : true}
      />
    </div>
  );
};
