import { OrderSubscriptionDeliveryOptions } from '../../../../../../components/OrderSubscription/OrderSubscriptionLayout.js';
import { applyWizardEvent } from '../../../../../../selfservice/actions/index.js';
import { paths } from '../../../../../../common/constants/pathVariables.js';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useLoaderData, useLocation, useNavigate, useRouteLoaderData } from 'react-router-dom';
import type { OrderSubscriptionCommonLoaderData, OrderSubscriptionDeliveryOptionsLoaderData } from 'common/loaders.js';

export const OrderBroadbandSubDeliveryOptionsPath = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { companyInfo } = useRouteLoaderData('newMobileBBCommon') as OrderSubscriptionCommonLoaderData;
  const { billingAccounts, billChannels } = useLoaderData() as OrderSubscriptionDeliveryOptionsLoaderData;

  useEffect(() => {
    if (!state?.selectedOffer) {
      navigate(paths.PS_BROADBAND_SUBSCRIPTION_NEW);
    }
  }, [navigate, state]);
  useEffect(() => {
    dispatch(applyWizardEvent(3, 'NEW_MOBILE_BROADBAND'));
  }, [dispatch]);

  return (
    <OrderSubscriptionDeliveryOptions
      companyInfo={companyInfo}
      billingAccounts={billingAccounts}
      billChannels={billChannels}
    />
  );
};
