/**
 * SFDC Community API
 * # Summary Elisa B2B API for an administrator of a company to query and modify the company information.  # Usage This API is used in the `https://yrityksille.elisa.fi/omaelisa` pages, but it is possible to later on expose this documentation publicly and make the endpoint queryable via external clients.  # Identifiers All entities in the API have a globally unique, internal identifier field named with the format [object_type]Id, e.g. `invoiceId`, which is also used in some of the API endpoint urls, e.g. `/api/v1/selfservice/invoices/{invoiceId}`. This value is not meaningful to the end user but is only used as an internally in the API.  Most entities also have a displayable identifier named in the format [object_type]DisplayId. This value can and should be shown to the end user, as it is also printed in relevant documents. It is also used in some of the public URLs, e.g. `https://yrityksille.elisa.fi/omaelisa/laskut/{invoiceDisplayId}`. Because of the user might land directly into a URL of a single entity with a display id, some of the API endpoints here also support querying via the display id, e.g. `/api/v1/selfservice/invoices?invoiceDisplayId={invoiceDisplayId}`.  The reason for using two identifiers instead of only one display id, is to make sure that every object will always have exactly one REST API URL that will never collide with another. While collisions of display ids should not happen in production, the id fields are guaranteed to be unique even across different test environments. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WorkingHours } from './workingHours.js';
import { SubscriptionPbxDetails } from './subscriptionPbxDetails.js';


export interface SubscriptionPbxConfiguration { 
    /**
     * SFDC internal ID of the PBX solution subscription. SFDC: csord__Subscription__c.Parent_Subscription__c.
     */
    pbxSolutionId: string;
    /**
     * Subscription id (SUBnnnnnnnnn) of the voice subscription to attach. This is haack to get around the fact that GET /v1/selfservice/subscriptions/{subscriptionId} is missing (See JIRA ISSUE HERE). TODO: remove this field when endpoint is available.
     */
    subscriptionDisplayId?: string;
    /**
     * Corporate number (landline number) for this subscription in PBX. SFDC: csord__Service__c.PSTN_NUMBER__c.
     */
    corporateNumber: string;
    /**
     * Extension number (short number) for this subscription in PBX. SFDC: csord__Service__c.Extension_Number__c.
     */
    extensionNumber?: string;
    /**
     * Number of seconds after which the next number in chain is called and for special numbers ring timeout for each number. SFDC: csord__Subscription__c.Delay_in_Seconds_for_Chain_Calls__c.
     */
    delayForChainCalls?: number;
    /**
     * Salesforce internal id of PSTN range. SFDC: csord__Service__c.PSTN_Range_Id__c
     */
    corporateNumberRangeId?: string;
    /**
     * Salesforce internal id of Extension range. SFDC: csord__Service__c.PSTN_Range_Id__c.Extension_Range_Id__c
     */
    extensionRangeId?: string;
    /**
     * Numbers to call when subscription busy, in calling order. If VAKIO, TAVOITETTAVUUSKETJU then numbers to call during working hours. If RYHMAKUTSU then target numbers to call, max 8 target numbers are allowed. If VASTAUSSARJA then target numbers to call, max 20 target numbers are allowed.  SFDC: csord__Service__c.Reachability_Chain_for_working_hours__c OR csord__Service__c.Target_Numbers__c.
     */
    workingHoursReachabilityChain?: Array<string>;
    /**
     * Action when this subscription is busy. If VAKIO, TAVOITETTAVUUSKETJU then NEXT_NUMBER_IN_CHAIN, BUSY_TONE, and CONNECT_TO_NUMBER options are allowed. If RYHMAKUTSU then BUSY_TONE and CONNECT_TO_NUMBER options are allowed. If VASTAUSSARJA then STANDARD_PROMPT and CONNECT_TO_NUMBER are allowed. SFDC: csord__Service__c.Busy_Action__c.
     */
    busyAction?: SubscriptionPbxConfiguration.BusyActionEnum;
    /**
     * Number to connect to if busyAction CONNECT_TO_NUMBER is chosen. SFDC: csord__Subscription__c.Connect_to_Number__c.
     */
    connectToNumber?: string;
    /**
     * Start time and end time of the working hours. SFDC: csord__Service__c.Availability__c.
     */
    workingHours?: Array<WorkingHours>;
    pbxConfigurationDetails: SubscriptionPbxDetails;
}
export namespace SubscriptionPbxConfiguration {
    export type BusyActionEnum = 'NEXT_NUMBER_IN_CHAIN' | 'BUSY_TONE' | 'CONNECT_TO_NUMBER' | 'STANDARD_PROMPT';
    export const BusyActionEnum = {
        NEXT_NUMBER_IN_CHAIN: 'NEXT_NUMBER_IN_CHAIN' as BusyActionEnum,
        BUSY_TONE: 'BUSY_TONE' as BusyActionEnum,
        CONNECT_TO_NUMBER: 'CONNECT_TO_NUMBER' as BusyActionEnum,
        STANDARD_PROMPT: 'STANDARD_PROMPT' as BusyActionEnum
    };
}


