import { ActionPhase } from '../../../../../../selfservice/common/storeUtils.js';
import { EmptyOrError, getNotFoundError } from '../../../../../../components/EmptyOrError/index.js';
import { EppMaintenanceEmployee } from '../../../../../../components/EppMaintenance/EppMaintenanceEmployee.js';
import { Loading } from '../../../../../../components/Loading/index.js';
import { SubscriptionCategory } from '../../../../../../common/enums.js';
import {
  checkValidEmployeeSubscription,
  getEmployeeSubscriptions,
  loadCompanyInfo,
} from '../../../../../../selfservice/actions/index.js';
import { deepEqual } from '../../../../../../common/utils/objectUtils.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import type { GetEmployeeSubscriptionsAction } from '../../../../../../selfservice/actions/index.js';
import type { State } from '../../../../../../selfservice/common/store.js';
import type { SubscriptionId } from '../../../../../../common/constants/pathInterfaces.js';

export const DeviceSupportRequestPath = () => {
  const dispatch = useDispatch();
  const { subscriptionId } = useParams<SubscriptionId>();
  const subsAction = useSelector(
    (state: State) =>
      state?.user?.authenticated?.actions?.find(
        action => (action.value as GetEmployeeSubscriptionsAction).subscriptionItemId === subscriptionId
      ),
    deepEqual
  );
  const subscription = useSelector(
    (state: State) =>
      state?.user?.authenticated?.subscriptions?.device?.find(sub => sub.subscriptionId === subscriptionId),
    deepEqual
  );

  useEffect(() => {
    dispatch(loadCompanyInfo());
    dispatch(getEmployeeSubscriptions(SubscriptionCategory.DEVICE, subscriptionId));
    dispatch(checkValidEmployeeSubscription(SubscriptionCategory.DEVICE, subscriptionId));
  }, [dispatch, subscriptionId]);

  if (subsAction?.phase === ActionPhase.IN_PROGRESS) {
    return <Loading />;
  }

  if (!subscription) {
    return <EmptyOrError {...getNotFoundError()} />;
  }

  return <EppMaintenanceEmployee subscription={subscription} subscriptionId={subscriptionId!} />;
};
