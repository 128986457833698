import * as CL from '@design-system/component-library';
import * as React from 'react';
import { DialogType } from '../../common/enums.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { showDialog } from '../../selfservice/actions/index.js';
import { t } from '../../common/i18n/index.js';
import { useDispatch } from 'react-redux';
import { useFormikContext } from 'formik';
import { validateForm } from '../../common/formik/index.js';
import type { ContactHeader, CustomerOrder } from '../../generated/api/models.js';
import type { CustomerOrderDetailsFormValues } from './CustomerOrderDetails.js';
import type { FormikValues } from 'formik';
import type { ReviewOnlineOrderDialogParams } from '../../common/types/dialog.js';

import './CustomerOrderDetails.scss';

interface CustomerOrderFooterParams {
  customerOrder: CustomerOrder;
  ordererContact?: ContactHeader;
  isChecked?: boolean;
}

// a device change order placed by employee will always have a 'replacedSubscriptionId' and 'userContactId'.
// We have to show list of active contracts only for employee placed device change order currently.
// This shall change once admin can place device change order from online.
const isDeviceChangeOrder = (customerOrdersDetail: CustomerOrder) =>
  customerOrdersDetail?.deliveryOrders.some(deliveryOrder => deliveryOrder.replacedSubscriptionId);

interface ApproveOrDenyParams {
  submitApproveOrReject: (e: React.SyntheticEvent) => void;
  isValid: boolean;
  isChecked?: boolean;
}

const ApproveOrDeny = ({ submitApproveOrReject, isValid, isChecked }: ApproveOrDenyParams) => {
  return (
    <div className="of-customer-order-details__sticky-footer__buttons">
      <CL.Button size="l" color="light" onClick={submitApproveOrReject} disabled={!isValid} id="reject">
        {t.ONSX('Reject')}
      </CL.Button>

      <CL.Button
        size="l"
        className="of-customer-order-details__order-action"
        onClick={submitApproveOrReject}
        disabled={!(isValid && isChecked)}
        id="approve"
      >
        {t.P5RQ('Accept')}
      </CL.Button>
    </div>
  );
};

export const CustomerOrderFooter = ({ customerOrder, ordererContact, isChecked }: CustomerOrderFooterParams) => {
  const dispatch = useDispatch();
  const { values, isValid } = useFormikContext<CustomerOrderDetailsFormValues>();
  const formValues: CustomerOrderDetailsFormValues = values;

  const getReviewOnlineOrderDialogParams = (approved: boolean): ReviewOnlineOrderDialogParams => {
    const onlineOrderReview = {
      billingAccountId: formValues.billingAccountId,
      costCenter: formValues.costCenter,
      subscriptionReference: formValues.employeeNumber,
      onlineOrderId: customerOrder?.onlineOrderId || '',
    };
    return {
      onlineOrderReview,
      approved,
      customerOrder: customerOrder,
      type: DialogType.REVIEW_ONLINE_ORDER,
      ordererEmail: ordererContact?.email,
    };
  };

  const showApproveOrRejectDialog = (e: React.SyntheticEvent) => {
    validateForm(values as FormikValues, { billingAccountId: { required: true } });
    const target = e.currentTarget.id || '';
    if (target === 'reject') {
      dispatch(showDialog(getReviewOnlineOrderDialogParams(false)));
    } else if (target === 'approve') {
      dispatch(showDialog(getReviewOnlineOrderDialogParams(true)));
    }
  };

  return (
    <div className="of-customer-order-details__sticky-footer">
      {isDeviceChangeOrder(customerOrder) ? (
        <>
          <ApproveOrDeny submitApproveOrReject={showApproveOrRejectDialog} isValid={isValid} isChecked={isChecked} />
          <div
            className={`of-customer-order-details__sticky-footer__text ${dsClass.FONT_SIZE_SMALL} ${dsClass.PADDING_LEFT_2}`}
          >
            <span>
              <CL.Icon icon="information" type="filled" size="m" color="orange" />
            </span>
            <span className={`${dsClass.COLOR_ORANGE} ${dsClass.PADDING_LEFT_1}`}>
              {t.GVYP('Please note that the user has')}{' '}
              <span className="of-customer-order-details__sticky-footer__text__underline">
                {t.WZ0H('valid device agreements.')}
              </span>
            </span>
          </div>
        </>
      ) : (
        <div className="of-customer-order-details__sticky-footer">
          <ApproveOrDeny submitApproveOrReject={showApproveOrRejectDialog} isValid={isValid} isChecked={isChecked} />
        </div>
      )}
    </div>
  );
};
