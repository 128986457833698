import { Fragment } from 'react';
import { contentMsg, t } from '../../common/i18n/index.js';
import { getHighlightedFields } from '../../common/utils/searchFieldUtils.js';
import type { NonVisibleField } from '../../common/utils/searchFieldUtils.js';

/*
 * Adds support of references to object's sub values like 'details.mobile.simNumber'
 */
export const getReferencedItemValue = <T extends object>(item: T, ref: string): string => {
  if (ref.indexOf('.') > -1) {
    const newRefs = ref.split('.');
    const refValue = newRefs.shift() || '';
    // @ts-ignore
    return item && getReferencedItemValue(item[refValue], newRefs.join('.'));
  } else {
    // @ts-ignore
    return item && item[ref];
  }
};

export const getSearchResultsSubRow = <T extends object>(
  item?: T,
  itemSearchResultMatchedFields?: string[],
  searchKeyword?: string,
  nonVisibleFields?: NonVisibleField[]
) => {
  let highlightedFields: JSX.Element[] = [];
  if (item && searchKeyword && nonVisibleFields && itemSearchResultMatchedFields?.length) {
    highlightedFields = getHighlightedFields(item, itemSearchResultMatchedFields, searchKeyword, nonVisibleFields);
  }
  return highlightedFields.length ? (
    <div className="ea-disclaimertext sub-row">
      <strong>{t.NBNH(contentMsg)}: </strong>
      {highlightedFields.map((element, index) => (
        <Fragment key={index}>
          {index > 0 && ', '}
          {element}
        </Fragment>
      ))}
    </div>
  ) : undefined;
};
