import * as reducers from '../reducers/index.js';
import { ajax } from 'rxjs/ajax';
import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { createEpicMiddleware } from 'redux-observable';
import { map } from 'rxjs/operators';
import { resourcesInitialState } from '../../common/utils/stateUtils.js';
import { restoreRedirectedState } from './redirect.js';
import type {
  AccountKeyUsersState,
  AnonymousUserState,
  AuthenticatedUserState,
  AuthorizationRequestState,
  BillChannelsState,
  BillingAccountsState,
  CampaignsState,
  CatalogsState,
  CompanyInfoState,
  ConfigState,
  ContactsState,
  CustomerOrdersState,
  DialogState,
  ErrorableState,
  ExternalAuthenticationState,
  FixedBroadbandOrderState,
  InvoiceDocumentsState,
  InvoicesState,
  LeadsState,
  NotificationsState,
  OnboardingRequestsState,
  OnlineModelHeadersState,
  OnlineModelsState,
  OnlineOrderState,
  PaymentsState,
  PickupPointSearchState,
  PunchoutOrderState,
  ReportsState,
  ResourcesState,
  ShoppingCartState,
  SubscriptionActionsState,
  SubscriptionAddOnRulesState,
  SubscriptionsState,
  SupportCasesState,
  VirtualCatalogsState,
} from '../../common/types/states.js';
import type { Action, AnyAction, Reducer, Store } from 'redux';
import type { ActionPhase } from './storeUtils.js';
import type { AjaxRequest, AjaxResponse } from 'rxjs/ajax';
import type {
  AuxiliaryEsimOrderFailureEnum,
  AuxiliaryEsimOrderPhaseEnum,
} from '../../components/AuxiliaryEsimOrderScene/auxiliaryEsimOrderEnums.js';
import type { AuxiliaryEsimServiceOrderResult, BillingAccount, Subscription } from '../../generated/api/models.js';
import type { CommonError } from '../../common/types/errors.js';
import type {
  DeliveryMethods,
  DuplicateContactCheck,
  ShoppingCartItemForCheckout,
} from '../../common/types/checkout.js';
import type { DeviceCheckoutDeliveryDetailsType } from '../../components/DeviceCheckoutDeliveryDetails/DeviceCheckoutDeliveryDetailsUtils.js';
import type { EpicDependencies } from '../epics/epicUtils.js';
import type { EpicMiddleware, Options } from 'redux-observable';
import type { IHistoryContext, RouterState } from 'redux-first-history';
import type { InvoiceReducersWrapper, SubscriptionsReducersWrapper } from '../reducers/index.js';
import type { SnapInsChatState } from '../../common/types/snapInChat.js';
import type { SubmitOnlineOrderAction } from '../actions/index.js';

// By default, use the same URL and headers, this is the right call browser-side
export const DEFAULT_AJAX_HOOK: (
  url: string,
  headers?: Record<string, string>
) => { url: string; headers?: Record<string, string>; createXHR?: () => XMLHttpRequest } = (url, headers) => {
  return {
    url,
    headers,
  };
};

export interface ItemsQuery {
  limit?: number;
  offset: number;
  order?: string;
  sort?: string;
  search?: string;
  status?: string;
  filterText?: string;
}

export interface ActionWithId extends Action {
  id: number;
  displayId?: string;
}

export interface ActionState<T = unknown> {
  query?: ItemsQuery;
  phase: ActionPhase;
  value: ActionWithId & T;
}

export interface ActionsHistory<T = unknown> {
  actions?: ActionState<T>[];
}

interface Invoices {
  all: (InvoicesState & ActionsHistory) | null;
  open: (InvoicesState & ActionsHistory) | null;
  paid: (InvoicesState & ActionsHistory) | null;
}

export interface Subscriptions {
  broadband?: (SubscriptionsState & ActionsHistory) | null;
  device?: (SubscriptionsState & ActionsHistory) | null;
  domain?: (SubscriptionsState & ActionsHistory) | null;
  service?: (SubscriptionsState & ActionsHistory) | null;
  voice?: (SubscriptionsState & ActionsHistory) | null;
  contract?: (SubscriptionsState & ActionsHistory) | null;
}

export interface SelfServiceState {
  accountKeyUsers?: (AccountKeyUsersState & ActionsHistory) | null;
  auxiliaryEsimOrder?: (AuxiliaryEsimOrderState & ActionsHistory) | null;
  billChannels?: (BillChannelsState & ActionsHistory) | null;
  billingAccounts?: (BillingAccountsState & ActionsHistory) | null;
  catalogs?: (CatalogsState & ActionsHistory) | null;
  companyInfo?: CompanyInfoState | null;
  contacts?: (ContactsState & ActionsHistory) | null;
  customerOrders?: (CustomerOrdersState & ActionsHistory) | null;
  fixedBroadbandOrders?: FixedBroadbandOrderState | null;
  invoices?: Invoices;
  invoiceDocuments?: (InvoiceDocumentsState & ActionsHistory) | null;
  onboardingRequests?: (OnboardingRequestsState & ActionsHistory) | null;
  onlineModelHeaders?: OnlineModelHeadersState | null;
  onlineModels?: OnlineModelsState | null;
  onlineOrders?: (OnlineOrderState & ActionsHistory<SubmitOnlineOrderAction>) | null;
  payments?: PaymentsState | null;
  pendingSubscriptionActions?: SubscriptionActionsState | null;
  punchoutOrders?: PunchoutOrderState | null;
  reports?: ReportsState | null;
  subscriptionActions?: (SubscriptionActionsState & ActionsHistory) | null;
  subscriptionAddOnRules?: SubscriptionAddOnRulesState | null;
  subscriptions?: Subscriptions;
  leads?: (LeadsState & ActionsHistory) | null;
  supportCases?: (SupportCasesState & ActionsHistory) | null;
  virtualCatalogs?: (VirtualCatalogsState & ActionsHistory) | null;
}

export interface AuthenticationState {
  authorizationRequest?: AuthorizationRequestState | null;
  externalAuthentication?: ExternalAuthenticationState | null;
}

export interface DeviceCheckoutState {
  billingAccount?: BillingAccount;
  cartItems: Array<ShoppingCartItemForCheckout>;
  cartUpdated: boolean;
  errors?: CommonError[];
  deliveryDetails?: DeviceCheckoutDeliveryDetailsType;
  moveToNextStep?: boolean;
  onlineOrderId?: string;
  selectedBaId?: string;
  duplicateContactCheck?: DuplicateContactCheck;
  deliveryMethods?: DeliveryMethods;
  latestItemAddedToCart?: ShoppingCartItemForCheckout;
  latestItemAddedToCartTimestamp?: number;
}

export interface LayoutState {
  hasChanged: boolean;
  hideHeaderFooter: boolean;
}

export interface UserState {
  /**
   * @deprecated Use `anonymous` from `AuthProvider` instead, e.g. `useAuth()`.
   * NOTE!
   *   If you need auth stuff, use `AuthProvider`.
   *   If you need non-auth stuff, then continue to use this deprecated redux store (e.g. `useSelector()`).
   **/
  anonymous?: (AnonymousUserState & ActionsHistory) | null;
  /**
   * @deprecated Use `authenticated` from `AuthProvider` instead, e.g. `useAuth()`.
   * NOTE!
   *   If you need auth stuff, use `AuthProvider`.
   *   If you need non-auth stuff, then continue to use this deprecated redux store (e.g. `useSelector()`).
   **/
  authenticated?: (AuthenticatedUserState & ActionsHistory) | null;
}

export interface AuxiliaryEsimOrderState extends ErrorableState {
  errorReason?: AuxiliaryEsimOrderFailureEnum;
  onlineRequestId?: string;
  orderResult?: AuxiliaryEsimServiceOrderResult;
  phase: AuxiliaryEsimOrderPhaseEnum;
  subscription?: Subscription;
  subscriptionToken?: string;
}

export interface State {
  authentication?: AuthenticationState;
  campaigns?: CampaignsState | null;
  config: ConfigState;
  deviceCheckout?: DeviceCheckoutState | null;
  dialog?: DialogState | null;
  lastAction: Action;
  layout?: LayoutState | null;
  notifications?: NotificationsState | null;
  payments?: PaymentsState | null;
  pickupPointSearch?: PickupPointSearchState | null;
  resources?: ResourcesState | null;
  router?: RouterState | null;
  selfservice?: SelfServiceState;
  shoppingCart?: ShoppingCartState | null;
  snapInsChat?: SnapInsChatState | null;
  user?: (UserState & ActionsHistory) | null;
}

// Here we can add more initial state, this must be called by all locations that create a store.
// At least some tests override some state, which is why 'initialState' is last.
export const addExtraState = (initialState: State) => ({
  resources: resourcesInitialState(),
  ...initialState,
});

interface SiteReducers {
  accountKeyUsersReducer: (s: AccountKeyUsersState, a: Action) => AccountKeyUsersState | null;
  anonymousUserReducer: (s: AnonymousUserState, a: Action) => AnonymousUserState | null;
  authenticatedUserReducer: (s: AuthenticatedUserState, a: Action) => AuthenticatedUserState | null;
  authorizationRequestReducer: (s: AuthorizationRequestState, a: Action) => AuthorizationRequestState | null;
  auxiliaryEsimOrderReducer: (s: AuxiliaryEsimOrderState, a: Action) => AuxiliaryEsimOrderState | null;
  billChannelsReducer: (s: BillChannelsState, a: Action) => BillChannelsState | null;
  billingAccountsReducer: (s: BillingAccountsState, a: Action) => BillingAccountsState | null;
  campaignsReducer: (s: CampaignsState, a: Action) => CampaignsState | null;
  catalogsReducer: (s: CatalogsState, a: Action) => CatalogsState | null;
  companyInfoReducer: (s: CompanyInfoState, a: Action) => CompanyInfoState | null;
  contactsReducer: (s: ContactsState, a: Action) => ContactsState | null;
  customerOrdersReducer: (s: CustomerOrdersState, a: Action) => CustomerOrdersState | null;
  deviceCheckoutReducer: (s: DeviceCheckoutState, a: Action) => DeviceCheckoutState | null;
  dialogReducer: (s: DialogState, a: Action) => DialogState | null;
  externalAuthenticationReducer: (s: ExternalAuthenticationState, a: Action) => ExternalAuthenticationState | null;
  fixedBroadbandOrderReducer: (s: FixedBroadbandOrderState, a: Action) => FixedBroadbandOrderState | null;
  invoiceDocumentsReducers: (s: InvoiceDocumentsState, a: Action) => InvoiceDocumentsState | null;
  invoicesReducers: InvoiceReducersWrapper;
  layoutReducer: (s: LayoutState, a: Action) => LayoutState | null;
  leadReducer: (s: ActionsHistory, a: Action) => ActionsHistory | null;
  notificationsReducer: (s: NotificationsState, a: Action) => NotificationsState | null;
  onboardingRequestsReducer: (s: OnboardingRequestsState, a: Action) => OnboardingRequestsState | null;
  onlineModelHeadersReducer: (s: OnlineModelHeadersState, a: Action) => OnlineModelHeadersState | null;
  onlineModelsReducer: (s: OnlineModelsState, a: Action) => OnlineModelsState | null;
  onlineOrderReducer: (s: OnlineOrderState, a: Action) => OnlineOrderState | null;
  paymentsReducer: (s: PaymentsState, a: Action) => PaymentsState | null;
  pendingSubscriptionActionsReducer: (s: SubscriptionActionsState, a: Action) => SubscriptionActionsState | null;
  pickupPointSearchReducer: (s: PickupPointSearchState, a: Action) => PickupPointSearchState | null;
  punchoutOrderReducer: (s: PunchoutOrderState, a: Action) => PunchoutOrderState | null;
  reportsReducer: (s: ReportsState, a: Action) => ReportsState | null;
  resourcesReducer: (s: ResourcesState, a: Action) => ResourcesState | null;
  shoppingCartReducer: (s: ShoppingCartState, a: Action) => ShoppingCartState | null;
  snapInsChatReducer: (s: SnapInsChatState, a: Action) => SnapInsChatState | null;
  subscriptionActionsReducer: (s: SubscriptionActionsState, a: Action) => SubscriptionActionsState | null;
  subscriptionAddOnRulesReducer: (s: SubscriptionAddOnRulesState, a: Action) => SubscriptionAddOnRulesState | null;
  subscriptionsReducers: SubscriptionsReducersWrapper;
  supportCaseReducer: (s: ActionsHistory, a: Action) => ActionsHistory | null;
  virtualCatalogsReducer: (s: VirtualCatalogsState, a: Action) => VirtualCatalogsState | null;
}

// When a store is created, an @@INIT action is dispatched so that every reducer returns their initial state.
// This effectively populates the initial state tree.
export const lastAction: Action<string> = { type: '@@INIT' };

export const combineSiteReducers = (siteReducers: SiteReducers, iHistoryContext?: IHistoryContext): Reducer<State> => {
  return combineReducers({
    authentication: combineReducers({
      authorizationRequest: siteReducers.authorizationRequestReducer,
      externalAuthentication: siteReducers.externalAuthenticationReducer,
    }),
    campaigns: siteReducers.campaignsReducer,
    config: (state: ConfigState) => {
      if (typeof state === 'undefined') {
        // Because of this issue:
        //
        // https://github.com/reduxjs/redux/issues/2578#issuecomment-455662584
        //
        // ATM we are required to do this here to make it possible to have config as non-mandatory
        // because combineReducers will call this method before initialState with:
        //
        // (undefined, {type : "SOME_RANDOMIZED_ACTION_TYPE"})
        //
        // and expects a valid payload in response. Only after that, the right initialState is passed in
        return {
          classicSiteUrl: '',
          externalAuthentication: { clientId: '', baseUrl: '' },
          featureFlags: {},
          imagesBaseUrl: '',
          siteBaseUrl: '',
          ringBaseUrl: '',
        };
      }
      return state;
    },
    deviceCheckout: siteReducers.deviceCheckoutReducer,
    dialog: siteReducers.dialogReducer,
    lastAction: (actionOrUndefined: Action | undefined, action: Action) => action,
    layout: siteReducers.layoutReducer,
    notifications: siteReducers.notificationsReducer,
    payments: siteReducers.paymentsReducer,
    pickupPointSearch: siteReducers.pickupPointSearchReducer,
    resources: siteReducers.resourcesReducer,
    router: iHistoryContext?.routerReducer as (s: RouterState, a: Action) => RouterState,
    selfservice: combineReducers({
      accountKeyUsers: siteReducers.accountKeyUsersReducer,
      auxiliaryEsimOrder: siteReducers.auxiliaryEsimOrderReducer,
      billChannels: siteReducers.billChannelsReducer,
      billingAccounts: siteReducers.billingAccountsReducer,
      catalogs: siteReducers.catalogsReducer,
      companyInfo: siteReducers.companyInfoReducer,
      contacts: siteReducers.contactsReducer,
      customerOrders: siteReducers.customerOrdersReducer,
      fixedBroadbandOrders: siteReducers.fixedBroadbandOrderReducer,
      invoiceDocuments: siteReducers.invoiceDocumentsReducers,
      invoices: combineReducers(siteReducers.invoicesReducers),
      leads: siteReducers.leadReducer,
      onboardingRequests: siteReducers.onboardingRequestsReducer,
      onlineModelHeaders: siteReducers.onlineModelHeadersReducer,
      onlineModels: siteReducers.onlineModelsReducer,
      onlineOrders: siteReducers.onlineOrderReducer,
      pendingSubscriptionActions: siteReducers.pendingSubscriptionActionsReducer,
      punchoutOrders: siteReducers.punchoutOrderReducer,
      reports: siteReducers.reportsReducer,
      subscriptionActions: siteReducers.subscriptionActionsReducer,
      subscriptionAddOnRules: siteReducers.subscriptionAddOnRulesReducer,
      subscriptions: combineReducers(siteReducers.subscriptionsReducers),
      supportCases: siteReducers.supportCaseReducer,
      virtualCatalogs: siteReducers.virtualCatalogsReducer,
    }),
    shoppingCart: siteReducers.shoppingCartReducer,
    snapInsChat: siteReducers.snapInsChatReducer,
    user: combineReducers({
      anonymous: siteReducers.anonymousUserReducer,
      authenticated: siteReducers.authenticatedUserReducer,
    }),
  });
};

const createSiteEpicMiddleware = (
  ajaxHook: (
    url: string,
    headers?: Record<string, string>
  ) => { url: string; headers?: Record<string, string>; createXHR?: () => XMLHttpRequest }
): EpicMiddleware<Action, Action, State, EpicDependencies> => {
  const mapResponse = map((x: AjaxResponse) => x.response);
  const dependencies = {
    get: (url: string, headers?: Record<string, string>) => {
      const request: AjaxRequest = {
        method: 'GET',
        ...ajaxHook(url, headers),
      };
      // this has been fixed in rxjs v7 (https://github.com/ReactiveX/rxjs/issues/2835) but we are still on v6 so this needs to be handled explicitly
      if (headers?.Accept === 'application/xml') {
        request.responseType = 'xml';
      }
      return ajax(request);
    },
    getJSON: (url: string, headers?: Record<string, string>) => {
      return mapResponse(
        ajax({
          method: 'GET',
          ...ajaxHook(url, headers),
        })
      );
    },
    post: (url: string, body?: object, headers?: Record<string, string>) => {
      return ajax({
        method: 'POST',
        body,
        ...ajaxHook(url, headers),
      });
    },
    put: (url: string, body?: object, headers?: Record<string, string>) => {
      return ajax({
        method: 'PUT',
        body,
        ...ajaxHook(url, headers),
      });
    },
    delete: (url: string, headers?: Record<string, string>) => {
      return ajax({
        method: 'DELETE',
        ...ajaxHook(url, headers),
      });
    },
  };
  const options: Options = { dependencies };
  return createEpicMiddleware(options);
};

// TODO: Remove this when elastic search feature goes live.
const customCombineReducers = (iHistoryContext: IHistoryContext) => {
  return (states: State, actions: AnyAction): State => {
    const latestState = combineSiteReducers(reducers, iHistoryContext)(states, actions);

    return {
      ...latestState,
      selfservice: {
        ...latestState.selfservice,
        billingAccounts: {
          ...latestState.selfservice?.billingAccounts,
          saving: latestState.selfservice?.billingAccounts?.saving || false,
          loading: latestState.selfservice?.billingAccounts?.loading || false,
        },
        customerOrders: {
          ...latestState.selfservice?.customerOrders,
        },
      },
    };
  };
};

export const configureStoreWithMiddleware = (
  iHistoryContext: IHistoryContext,
  initialState: State
): { store: Store<State>; epicMiddleware: EpicMiddleware<Action, Action, State, EpicDependencies> } => {
  const epicMiddleware = createSiteEpicMiddleware(DEFAULT_AJAX_HOOK);
  const store = configureStore({
    preloadedState: addExtraState(restoreRedirectedState(initialState)),
    reducer: customCombineReducers(iHistoryContext),
    middleware: [iHistoryContext.routerMiddleware, epicMiddleware],
    devTools: process.env.NODE_ENV === 'development',
  });
  return {
    store,
    epicMiddleware,
  };
};
