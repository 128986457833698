import * as CL from '@design-system/component-library';
import { FileTypeIcon, fileNameParts, fileTypeIcon } from './files.js';
import { t } from '../../common/i18n/index.js';

import './Icon.scss';

interface IconProps {
  fileName: string;
}

const getIconLabel = (iconName: FileTypeIcon) => {
  switch (iconName) {
    case FileTypeIcon.FILE_DOC:
      return t.K3VL('Word document');
    case FileTypeIcon.FILE_PDF:
      return t.K3VM('PDF document');
    case FileTypeIcon.FILE_TXT:
      return t.K3VN('Text document');
    case FileTypeIcon.FILE_XLS:
      return t.K3VO('Excel document');
    case FileTypeIcon.PICTURE:
      return t.K3VP('Picture');
    default:
      return t.K3VQ('File');
  }
};

export const Icon = ({ fileName }: IconProps) => {
  const iconName = fileTypeIcon(fileNameParts(fileName).typePart);
  const iconLabel = getIconLabel(iconName);
  return <CL.Icon aria-label={iconLabel} className="of-file-icon" icon={iconName} size="s" />;
};
