import { REDIRECT_PATH_KEY } from '../../common/constants/commonConstants.js';
import {
  TypeKeys,
  hardRedirect,
  logIn,
  registerUserErrorFulfilled,
  registerUserFailed,
  registerUserFulfilled,
  userExistsFailed,
  userExistsFulfilled,
  validateUserAuthorityFailed,
  validateUserAuthorityFulfilled,
} from '../actions/index.js';
import { actionToActionState } from './epicUtils.js';
import { callUiApi, prepareUiApiRequest } from '../common/uiApiUtils.js';
import {
  checkSsoUserExistsPublicMethod,
  registerExternalAuthenticationUserPublicMethod,
  validateExternalAuthenticationUserAuthorityPublicMethod,
} from '../../generated/api/uiApiMethods.js';
import { combineEpics, ofType } from 'redux-observable';
import { concatMap, debounceTime } from 'rxjs/operators';
import { of } from 'rxjs';
import { paths } from '../../common/constants/pathVariables.js';
import type { Action } from 'redux';
import type { ActionAndState, EpicDependencies } from './epicUtils.js';
import type { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import type { AjaxResponse } from 'rxjs/ajax';
import type { CheckUserExistsRequest, ValidateUserAuthorityRequest } from '../../generated/api/models.js';
import type {
  RegisterUserAction,
  SelfServiceActionTypes,
  UserExistsAction,
  ValidateUserAuthorityAction,
  ValidateUserAuthorityFailedAction,
  ValidateUserAuthorityFulfilledAction,
} from '../actions/index.js';
import type { State } from '../common/store.js';

const validateUserAuthorityEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.VALIDATE_USER_AUTHORITY)), (action: ValidateUserAuthorityAction) =>
    actionToActionState(action, state$, 'externalAuthentication')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const action = actionAndState.action as ValidateUserAuthorityAction;
      return callUiApi({
        epicDependencies,
        state$,
        method: validateExternalAuthenticationUserAuthorityPublicMethod(),
        payload: {
          idServiceCode: action.authorizationCode,
          businessId: action.businessId,
        } as ValidateUserAuthorityRequest,
        successAction: (response: AjaxResponse) =>
          validateUserAuthorityFulfilled(response.response, action.redirectPath),
        failureAction: (message, status, errors, params) =>
          validateUserAuthorityFailed(message, status, errors, params, action.redirectPath),
      });
    })
  );

export const validateUserAuthorityFulfilledEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>
) => {
  return action$.pipe(
    ofType(TypeKeys.VALIDATE_USER_AUTHORITY_FULFILLED),
    concatMap((action: ValidateUserAuthorityFulfilledAction) => {
      sessionStorage.setItem('registration-user-details', JSON.stringify(action.response));
      return of(
        hardRedirect(
          action.redirectPath
            ? `${paths.REGISTER_PAGE}?${REDIRECT_PATH_KEY}=${encodeURIComponent(action.redirectPath)}`
            : paths.REGISTER_PAGE
        )
      );
    })
  );
};

export const validateUserAuthorityFailedEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>
) => {
  return action$.pipe(
    ofType(TypeKeys.VALIDATE_USER_AUTHORITY_FAILED),
    concatMap((action: ValidateUserAuthorityFailedAction) => {
      return of(
        hardRedirect(
          action.redirectPath
            ? `${paths.REGISTER_PAGE}?${REDIRECT_PATH_KEY}=${encodeURIComponent(action.redirectPath)}`
            : paths.REGISTER_PAGE
        )
      );
    })
  );
};

const registerUserEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.REGISTER_USER)), (action: RegisterUserAction) =>
    actionToActionState(action, state$, 'externalAuthentication')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const action = actionAndState.action as RegisterUserAction;
      const { ...payload } = action;
      return callUiApi({
        epicDependencies,
        state$,
        method: registerExternalAuthenticationUserPublicMethod(),
        payload,
        successAction: () => registerUserFulfilled(action.newUser),
        failureAction: registerUserFailed,
      }).pipe(
        concatMap(fulfilledOrFailedAction => {
          if (fulfilledOrFailedAction.type === TypeKeys.REGISTER_USER_FULFILLED) {
            sessionStorage.setItem(
              'registration-user-credentials',
              JSON.stringify({ email: payload.userDetails.email })
            );
            if (payload.userDetails.email && payload.userDetails.password) {
              return [fulfilledOrFailedAction, logIn(payload.userDetails.email, payload.userDetails.password)];
            }
            return [fulfilledOrFailedAction];
          }
          return [registerUserErrorFulfilled(), fulfilledOrFailedAction];
        })
      );
    })
  );

const userExistsEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.USER_EXISTS)), (action: UserExistsAction) =>
    actionToActionState(action, state$, 'externalAuthentication')
  ).pipe(
    debounceTime(400),
    concatMap((actionAndState: ActionAndState) => {
      const payload: CheckUserExistsRequest = actionAndState.action as UserExistsAction;

      return callUiApi({
        epicDependencies,
        state$,
        method: checkSsoUserExistsPublicMethod(),
        payload,
        successAction: (response: AjaxResponse) => userExistsFulfilled(response.response),
        failureAction: userExistsFailed,
      });
    })
  );

export const externalAuthenticationEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = combineEpics(
  validateUserAuthorityEpic,
  validateUserAuthorityFulfilledEpic,
  validateUserAuthorityFailedEpic,
  registerUserEpic,
  userExistsEpic
);
