import * as CL from '@design-system/component-library';
import { Email } from '../../common/react-hook-form/fields/Email.js';
import { FormProvider, useForm } from 'react-hook-form';
import { PhoneNumber } from '../../common/react-hook-form/fields/PhoneNumber.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { getTurbonappiAddToCartEvent } from './turbonappiAnalyticsUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { pushToDataLayer } from '../../common/analytics.js';
import { t } from '../../common/i18n/index.js';
import { useNavigate } from 'react-router-dom';
import { useTurbonappiContext } from './TurbonappiContextProvider.js';
import type { TurbonappiData, TurbonappiFormData } from './TurbonappiContextProvider.js';
import type { TurbonappiOffer } from '../../generated/api/turbonappiOffer.js';

import './TurbonappiOrderForm.scss';

const WaitingInfo = () => (
  <div className="of-turbonappi-form__waiting-info">
    <div>
      <strong>{t.P3N8('Just a minute...')}</strong>
    </div>
    <div>{t.HG7X('We are making sure that Turbonappi can be added to your subscription')}</div>
  </div>
);

const isLoading = (turbonappiData?: TurbonappiData, offer?: TurbonappiOffer) =>
  turbonappiData?.isValidationLoading && offer && offer === turbonappiData?.offer;

// This form is embedded in SubscriptionCard component
export const TurbonappiOrderForm = ({ turbonappiOffer }: { turbonappiOffer?: TurbonappiOffer }) => {
  const navigate = useNavigate();
  const { validateMsisdnForTurbonappi, turbonappiData } = useTurbonappiContext();
  const methods = useForm({ mode: 'all' });

  const onSubmit = async (data: TurbonappiFormData) => {
    pushToDataLayer(getTurbonappiAddToCartEvent(turbonappiOffer));
    const valid: boolean = await validateMsisdnForTurbonappi(data);
    if (valid) {
      navigate(paths.TURBONAPPI_TILAUS);
    }
  };

  return (
    <div className="of-turbonappi-form">
      {isLoading(turbonappiData, turbonappiOffer) ? (
        <div className="of-turbonappi-form__loading">
          <CL.LoadingSpinner className={dsClass.MARGIN_BOTTOM_4} size="xl" />
          <WaitingInfo />
        </div>
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
            <div className="of-turbonappi-form__fields">
              <PhoneNumber name="msisdn" label={`${t.QIKB('Enter your phone number')} *`} />
              <Email label={`${t.SBDY('Enter your email address')} *`} />
              <input type="hidden" {...methods.register('offer')} value={turbonappiOffer} />
            </div>
            <CL.Button color="primary" type="submit">
              {t.MAEZ('Choose a package')}
            </CL.Button>
          </form>
        </FormProvider>
      )}
    </div>
  );
};
