import { SourceSystem } from '../../../generated/api/sourceSystem.js';
import { fetchBillingAccounts } from '../../../common/fetch.js';
import { loadingBillingAccountsMsg, t } from '../../../common/i18n/index.js';
import { useEffect, useState } from 'react';
import { useOpenFormAsync } from '../OpenFormProvider.js';
import type { BillingAccountHeader } from '../../../generated/api/billingAccountHeader.js';

const query = { sourceSystem: SourceSystem.MIPA, useSearchService: true } as const;

export const useOpenFormBillingAccountHeaders = () => {
  const [data, setData] = useState<BillingAccountHeader[] | undefined>(undefined);
  const async = useOpenFormAsync();

  useEffect(() => {
    const { ctrl } = new async(({ signal }) => fetchBillingAccounts(query, { signal }))
      .resolved(({ searchResults }) => setData(searchResults?.map(({ result }) => result)))
      .rejected(text => async.actions.notification({ text, type: 'error' }))
      .cache(query.sourceSystem, query.useSearchService)
      .execute(t.RCPW(loadingBillingAccountsMsg));

    return () => ctrl.abort();
  }, [async]);

  return data;
};
