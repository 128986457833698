import { Highlight } from './Highlight.js';
import { escapeRegExpChars } from '../../common/utils/stringUtils.js';

interface HighlightFormattedPhoneNumberProps {
  formattedPhoneNumber: string;
  search?: string;
}

export const HighlightFormattedPhoneNumber = ({ formattedPhoneNumber, search }: HighlightFormattedPhoneNumberProps) => {
  const searchTerms = search?.split(/\s/);
  const escapedSearchTerms = searchTerms?.map(term => escapeRegExpChars(term));
  // Get all terms matching into phoneNumber
  const matchingTerms = escapedSearchTerms?.filter(term =>
    new RegExp(term, 'gi').test(formattedPhoneNumber.replace(/\s/g, ''))
  );
  if (!matchingTerms || matchingTerms.length === 0) {
    return <>{formattedPhoneNumber}</>;
  }
  // Use the longest matching term in case of multiple search terms matching into phone number
  const longestMatchingTerm = matchingTerms?.sort((a, b) => b.length - a.length)[0];
  // Calculate the positions of spaces in the formatted phone number by creating an array of the indices where spaces
  // occur in the formatted phone number. Keep track of the number of spaces encountered so far, which is subtracted
  // from the current index. This gives the position of the space in the original, (not formatted) phone number.
  let spacesCount = 0;
  const spacePositions = Array.from(formattedPhoneNumber).reduce((positions: number[], char, index) => {
    if (char === ' ') {
      positions.push(index - spacesCount);
      spacesCount++;
    }
    return positions;
  }, []);
  // Insert spaces at the same positions in the longest matching term to highlight it correctly
  const formattedMatchingTerm = Array.from(longestMatchingTerm?.replace(/\\/g, '') || [])
    .map((char, index) => {
      if (spacePositions.includes(index)) {
        return ` ${char}`;
      }
      return char;
    })
    .join('');
  return <Highlight text={formattedPhoneNumber} highlights={[formattedMatchingTerm]} />;
};
