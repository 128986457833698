import { AddOnRow } from '../SubscriptionDetails/AddOnRow.js';
import { Controller, useFormContext } from 'react-hook-form';
import { Loading } from '../Loading/index.js';
import { additionalServicesMsg, invalidValueMsg, t } from '../../common/i18n/index.js';
import {
  calculateBaseDependency,
  checkCurrentCombination,
  filterVisibilitiesBasedOnRing,
  getAddOnFromRules,
  getBaseRules,
  getDefaultSelectedLanguage,
  isMobileVoiceAddOn,
} from '../SubscriptionDetails/addOnDependencyUtils.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { separatelyHandledFixedBroadbandAddOnCodes } from '../FixedBroadbandProductSelection/fixedBroadbandAddOnUtils.js';
import { useAddOnRules } from '../../common/hooks/useAddOnRules.js';
import { useAddOnVisibility } from '../../common/hooks/useAddOnVisibility.js';
import { useEffect, useState } from 'react';
import type { AddOnVisibility } from '../../generated/api/addOnVisibility.js';
import type { AddOnVisibilityOperationType } from '../../common/enums.js';
import type { AddedAddon } from '../../common/types/addon.js';
import type { AssociationRecord, DependencyRecord } from '@onlinefirst/cloudsense-add-on-dependency-engine';
import type { BaseRules } from '../SubscriptionDetails/addOnDependencyUtils.js';
import type { CommercialProduct } from '../../generated/api/commercialProduct.js';
import type { SubscriptionAction } from '../../generated/api/subscriptionAction.js';

import './OrderSubscriptionConfiguration.scss';

interface AdditionalServicesCheckoutContentProps {
  subscriptionActions?: SubscriptionAction[];
  addOnAssociations?: AssociationRecord[];
  addOnDependencies?: DependencyRecord[];
  commercialProduct: CommercialProduct;
  isRingSelected: boolean;
  cartItemInstanceId: string;
  productAddOns?: AddedAddon[];
  onAddOnSelectionChange?: (cartItemInstanceId: string, addedAddOns: AddedAddon[]) => void;
  showSeparator?: boolean;
  preFetchedAddOnVisibilities?: AddOnVisibility[];
  hideHeader?: boolean;
  loadNettiRules?: boolean;
  addOnVisibilityOperationType: AddOnVisibilityOperationType;
}

interface AddOnCheckoutContentProps {
  baseRules: BaseRules;
  addOnVisibilities: AddOnVisibility[];
  cartItemInstanceId: string;
  productAddOnCodes: string[]; // Addons, that are not visible in the form
  preSelectedAddOnCodes: string[];
  initialLanguage?: string;
  onAddOnSelectionChange?: (cartItemInstanceId: string, addedAddOns: AddedAddon[]) => void;
}

const getCheckedAddOnCodes = (baseList: string[], currentItem: string, checked: boolean) => {
  return checked ? [...baseList, currentItem] : baseList.filter(item => item !== currentItem);
};

const getAddOnItemsFromCodes = (
  addOnCodes: string[],
  addOnVisibilities: AddOnVisibility[],
  baseRules: BaseRules,
  selectedLanguage?: string
): AddedAddon[] =>
  addOnCodes.map(addOnCode => {
    const addOnItemFromVisibility = addOnVisibilities.find(visibility => visibility.addOnGuidCode === addOnCode)?.addOn;
    const addOnItemFromAssociation = getAddOnFromRules(
      addOnCode,
      baseRules.commercialProductCode,
      baseRules.addOnAssociations
    );
    const addOnItem = addOnItemFromVisibility || addOnItemFromAssociation;
    return {
      addOnCode: addOnCode,
      displayName: addOnItem?.addOnProductName || '',
      display: addOnItem?.display,
      addOnAssociationCode:
        baseRules.addOnAssociations
          .filter(assoc => assoc.cspmb__price_item__r.guid__c === baseRules.commercialProductCode)
          .find(assoc => assoc.cspmb__add_on_price_item__r.guid__c === addOnCode)?.guid__c || '',
      addOnAttributes: selectedLanguage && isMobileVoiceAddOn(addOnCode) ? { language: selectedLanguage } : undefined,
      monthlyPrice: addOnItem?.price?.effectivePrice.monthlyRecurringCharge,
      oneTimePrice: addOnItem?.price?.effectivePrice.oneTimeCharge,
    };
  });

const getCurrentAddonCodes = (
  subscriptionAddOnCodes: string[],
  addedAddOnCodes: string[],
  addOnVisibility: AddOnVisibility,
  checked: boolean
): string[] => {
  // Combine all addons that are currently included, non-editables and the ones that are selected in the form
  const combinedAddOnCodes = new Set([...subscriptionAddOnCodes, ...addedAddOnCodes]);
  // If the passed addon is checked, return all addons. If not, exclude it from the list.
  return checked
    ? Array.from(combinedAddOnCodes)
    : Array.from(combinedAddOnCodes).filter(addOnCode => addOnCode !== addOnVisibility.addOnGuidCode);
};

const AddOnCheckoutContent = ({
  baseRules,
  addOnVisibilities,
  cartItemInstanceId,
  preSelectedAddOnCodes,
  initialLanguage,
  onAddOnSelectionChange,
  productAddOnCodes,
}: AddOnCheckoutContentProps) => {
  const [addedAddOnCodes, setAddedAddOnCodes] = useState(preSelectedAddOnCodes);
  const [removedAddOnCodes, setRemovedAddOnCodes] = useState<string[]>([]);
  // All addon codes included in the subscription, visible and hidden. If addon can be controlled (checked/unchecked)
  // in the form, the result will be reflected to this array
  const [subscriptionAddOnCodes, setSubscriptionAddOnCodes] = useState<string[]>(
    Array.from(new Set([...preSelectedAddOnCodes, ...productAddOnCodes]))
  );
  const [editabilityRecords, setEditabilityRecords] = useState(
    calculateBaseDependency(baseRules, subscriptionAddOnCodes, addOnVisibilities, false, false)
  );
  const [isValidCombination, setIsValidCombination] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useState(initialLanguage);
  const { setValue, setError, clearErrors } = useFormContext();

  const formName = `configuration.${cartItemInstanceId}.addOns.addedAddOns`;

  useEffect(() => {
    const currentCombination = checkCurrentCombination(baseRules, [], [], subscriptionAddOnCodes, addOnVisibilities);

    if (currentCombination.isValid) {
      clearErrors(formName);
    } else {
      setError(formName, { type: 'custom', message: t.DUPA(invalidValueMsg) });
    }
    setIsValidCombination(currentCombination.isValid);
  }, [addOnVisibilities, addedAddOnCodes, baseRules, clearErrors, formName, setError, subscriptionAddOnCodes]);

  const handleRemovedAddOns = (addOnCode: string, checked: boolean) => {
    if (!checked && preSelectedAddOnCodes.includes(addOnCode)) {
      const uncheckedAddOnCodes = [...removedAddOnCodes, addOnCode];
      setValue(`configuration.${cartItemInstanceId}.addOns.removedAddOns`, uncheckedAddOnCodes);
      setRemovedAddOnCodes(uncheckedAddOnCodes);
    }
  };

  const handleLanguageChange = (language?: string) => {
    setValue(formName, getAddOnItemsFromCodes(addedAddOnCodes, addOnVisibilities, baseRules, language));
    setSelectedLanguage(language);
  };

  const handleAddOnSelection = (checked: boolean, visibility: AddOnVisibility) => {
    const checkedAddOnCodes = getCheckedAddOnCodes(addedAddOnCodes, visibility.addOnGuidCode, checked);
    const languageIfCheckedOrUnchecked = checked ? getDefaultSelectedLanguage() : undefined;
    const language = isMobileVoiceAddOn(visibility.addOnGuidCode) ? languageIfCheckedOrUnchecked : selectedLanguage;
    const currentAddOns = getCurrentAddonCodes(subscriptionAddOnCodes, checkedAddOnCodes, visibility, checked);
    const editability = calculateBaseDependency(baseRules, currentAddOns, addOnVisibilities, false, false);
    setSubscriptionAddOnCodes(currentAddOns);
    const addedAddOnItems = getAddOnItemsFromCodes(checkedAddOnCodes, addOnVisibilities, baseRules, language);
    setValue(formName, addedAddOnItems);
    onAddOnSelectionChange?.(cartItemInstanceId, addedAddOnItems);
    setSelectedLanguage(language);
    setAddedAddOnCodes(checkedAddOnCodes);
    setEditabilityRecords(editability);
    handleRemovedAddOns(visibility.addOnGuidCode, checked);
  };

  return (
    <Controller
      name={formName}
      defaultValue={
        initialLanguage
          ? getAddOnItemsFromCodes(addedAddOnCodes, addOnVisibilities, baseRules, initialLanguage)
          : undefined
      }
      rules={{ validate: () => (isValidCombination ? undefined : t.DUPA(invalidValueMsg)) }}
      shouldUnregister={true}
      render={({ fieldState: { error } }) => (
        <div className="of-order-product-details__addon-selections">
          {addOnVisibilities
            .filter(addOnVisibility => addOnVisibility.selfServiceEditable)
            .map((addOnVisibility, i) => (
              <AddOnRow
                key={`checkout-addon-${i}`}
                addOnVisibility={addOnVisibility}
                showEditView={true}
                editabilityRecords={editabilityRecords}
                subscriptionAddOnCodes={subscriptionAddOnCodes}
                addOnCodesToAdd={addedAddOnCodes}
                addOnCodesToRemove={[]}
                isAssetWithMobiiliturva={false}
                isEmployee={false}
                handleAddOnSelectionChange={(checked, visibility) => handleAddOnSelection(checked, visibility)}
                baseRules={baseRules}
                selectedLanguage={selectedLanguage}
                onChangeLanguage={(language?: string) => handleLanguageChange(language)}
              />
            ))}
          {error && <div className={dsClass.INPUTERROR}>{error.message}</div>}
        </div>
      )}
    />
  );
};

export const AdditionalServicesCheckoutContent = ({
  commercialProduct,
  isRingSelected,
  cartItemInstanceId,
  productAddOns,
  onAddOnSelectionChange,
  showSeparator,
  preFetchedAddOnVisibilities,
  hideHeader,
  loadNettiRules,
  addOnVisibilityOperationType,
  addOnAssociations,
  addOnDependencies,
}: AdditionalServicesCheckoutContentProps) => {
  const loadSubscriptionRules = [...(addOnAssociations || []), ...(addOnDependencies || [])].length === 0;
  const { associations, dependencies, addOnRulesLoading } = useAddOnRules(loadNettiRules, loadSubscriptionRules);

  const { addOnVisibilities, addOnVisibilitiesLoading } = useAddOnVisibility(
    commercialProduct.commercialProductCode,
    addOnVisibilityOperationType,
    preFetchedAddOnVisibilities
  );

  const isAllDataLoaded = !addOnVisibilitiesLoading && !addOnRulesLoading;

  if (!isAllDataLoaded) {
    return <Loading />;
  }

  const baseRules: BaseRules = getBaseRules(
    commercialProduct,
    addOnAssociations || associations,
    addOnDependencies || dependencies
  );

  const ordinaryAddOnVisibilities = addOnVisibilities?.filter(
    a => !separatelyHandledFixedBroadbandAddOnCodes.includes(a.addOnGuidCode)
  );
  const visibilitiesConsideringRing = filterVisibilitiesBasedOnRing(isRingSelected, ordinaryAddOnVisibilities);
  const productAddOnCodes =
    productAddOns
      ?.map(addOn => addOn.addOnCode)
      .filter(
        addOnCode => !visibilitiesConsideringRing.map(visibility => visibility.addOnGuidCode).includes(addOnCode)
      ) || [];
  const preSelectedAddOnCodes =
    productAddOns
      ?.map(addOn => addOn.addOnCode)
      .filter(addOnCode =>
        visibilitiesConsideringRing.map(visibility => visibility.addOnGuidCode).includes(addOnCode)
      ) || [];

  const initialLanguage = preSelectedAddOnCodes?.some(code => isMobileVoiceAddOn(code))
    ? getDefaultSelectedLanguage()
    : undefined;

  return (
    <>
      {visibilitiesConsideringRing.some(visibility => visibility.selfServiceEditable) && (
        <div>
          {!hideHeader && (
            <div className={dsClass.MARGIN_TOP_5}>
              {showSeparator && <hr className={`${dsClass.MARGIN_BOTTOM_5} ${dsClass.MARGIN_TOP_5}`} />}
              <div className={`${dsClass.H4} ${dsClass.MARGIN_BOTTOM_5}`}>{t.LXSR(additionalServicesMsg)}</div>
            </div>
          )}
          <AddOnCheckoutContent
            baseRules={baseRules}
            addOnVisibilities={visibilitiesConsideringRing}
            cartItemInstanceId={cartItemInstanceId}
            productAddOnCodes={productAddOnCodes}
            preSelectedAddOnCodes={preSelectedAddOnCodes}
            initialLanguage={initialLanguage}
            onAddOnSelectionChange={onAddOnSelectionChange}
          />
        </div>
      )}
    </>
  );
};
