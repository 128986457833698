import * as CL from '@design-system/component-library';
import { type SubscriptionCategory, SubscriptionFilterValue } from '../../common/enums.js';
import { TableUrlParams } from '../Table/index.js';
import { getFilterOptions } from './subscriptionListUtils.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSearchParams } from '../../common/hooks/useSearchParams.js';
import { useState } from 'react';
import type { DefaultListSearchParams } from '../Table/index.js';

import './SubscriptionTable.scss';

interface SubscriptionListFilterOptionsProps {
  category: SubscriptionCategory;
  setSelectedSubType: (value?: string) => void;
  selectedFilter?: string;
}

export const SubscriptionListFilterOptions = ({
  category,
  setSelectedSubType,
  selectedFilter,
}: SubscriptionListFilterOptionsProps) => (
  <CL.Dropdown
    id="subtypeoptions"
    name="subtypeoptions"
    className="of-table-header-dropdown"
    items={getFilterOptions()[category].map(option => ({ label: option.displayText, value: option.filterValue || '' }))}
    selectedValue={selectedFilter}
    integrated={true}
    onValueChange={value => {
      const textValue = value.dataset.value || '';
      setSelectedSubType(textValue === SubscriptionFilterValue.ALL ? undefined : textValue);
    }}
  />
);

export interface SubscriptionsDeviceMultiFilter {
  commitmentPeriod?: string;
  deviceUser?: string;
  eppFilter?: string;
  expiringFilter?: string;
  paymentType?: string;
  subscriptionSubType?: string;
}

export type SubscriptionDeviceTableSearchParams = DefaultListSearchParams & SubscriptionsDeviceMultiFilter;

export const useFilterUpdate = (): [string | undefined, (value?: string) => void] => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { offset = '0' } = useSearchParams<DefaultListSearchParams>();
  const [selectedFilter, setSelectedFilter] = useState<string | undefined>(undefined);

  const onFilterUpdated = (filterValue: string) => {
    if (Number(offset) > 0) {
      const queryParams = new URLSearchParams(location.search);
      queryParams.set(TableUrlParams.PAGE, '1');
      queryParams.set(TableUrlParams.OFFSET, '0');
      navigate(`${pathname}?${queryParams}`, { replace: true });
    }
    setSelectedFilter(filterValue);
  };
  return [selectedFilter, onFilterUpdated];
};

export const useMultiFilterUpdate = (
  initialFilter: SubscriptionsDeviceMultiFilter = {}
): [SubscriptionsDeviceMultiFilter, (value?: SubscriptionsDeviceMultiFilter) => void] => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { offset = '0' } = useSearchParams<DefaultListSearchParams>();
  const [selectedFilter, setSelectedFilter] = useState(initialFilter);

  const onFilterUpdated = (filterValue: SubscriptionsDeviceMultiFilter) => {
    if (Number(offset) > 0) {
      const queryParams = new URLSearchParams(location.search);
      queryParams.set(TableUrlParams.PAGE, '1');
      queryParams.set(TableUrlParams.OFFSET, '0');
      navigate(`${pathname}?${queryParams}`, { replace: true });
    }
    setSelectedFilter(filterValue);
  };
  return [selectedFilter, onFilterUpdated];
};
