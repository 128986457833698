import { CommonErrorType } from '../../common/enums.js';
import { TypeKeys, generateActionId } from './actionsUtils.js';
import { convertToCommonErrors } from '../../common/utils/errorUtils.js';
import { t } from '../../common/i18n/index.js';
import type { Action } from 'redux';
import type { AuthorizationRequest, GenericError } from '../../generated/api/models.js';
import type { CrudAction, ErrorAction, ErrorActionCreator } from './actionsUtils.js';

export interface GetAuthorizationRequestAction extends CrudAction<TypeKeys.GET_AUTHORIZATION_REQUEST> {
  linkToken: string;
  showError?: boolean;
}

export const getAuthorizationRequest = (linkToken: string, showError?: boolean): GetAuthorizationRequestAction => {
  return {
    id: generateActionId(),
    linkToken,
    showError,
    type: TypeKeys.GET_AUTHORIZATION_REQUEST,
  };
};

export interface GetAuthorizationRequestFulfilledAction extends Action {
  linkToken: string;
  response: AuthorizationRequest;
  type: TypeKeys.GET_AUTHORIZATION_REQUEST_FULFILLED;
}

export const getAuthorizationRequestFulfilled = (
  linkToken: string,
  response: AuthorizationRequest
): GetAuthorizationRequestFulfilledAction => {
  return {
    linkToken,
    response: response,
    type: TypeKeys.GET_AUTHORIZATION_REQUEST_FULFILLED,
  };
};

export interface GetAuthorizationRequestFailedAction extends ErrorAction<TypeKeys.GET_AUTHORIZATION_REQUEST_FAILED> {
  linkToken: string;
}

export const getAuthorizationRequestFailed: ErrorActionCreator<TypeKeys.GET_AUTHORIZATION_REQUEST_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): GetAuthorizationRequestFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  if (status === 400 || status === 404) {
    return {
      errors: [
        {
          message: t.C8BR('The link has expired or it is invalid.'),
          type: CommonErrorType.VALIDATION,
        },
      ],
      linkToken: params!.linkToken,
      type: TypeKeys.GET_AUTHORIZATION_REQUEST_FAILED,
    };
  }
  return {
    errors: commonErrors,
    linkToken: params!.linkToken,
    type: TypeKeys.GET_AUTHORIZATION_REQUEST_FAILED,
  };
};

export interface ProcessAuthorizationRequestAction extends CrudAction<TypeKeys.PROCESS_AUTHORIZATION_REQUEST> {
  authorizationCode: string;
  linkToken: string;
}

export const processAuthorizationRequest = (
  linkToken: string,
  authorizationCode: string
): ProcessAuthorizationRequestAction => {
  return {
    authorizationCode,
    id: generateActionId(),
    linkToken,
    type: TypeKeys.PROCESS_AUTHORIZATION_REQUEST,
  };
};

export interface ProcessAuthorizationRequestFulfilledAction extends Action {
  linkToken: string;
  response: AuthorizationRequest;
  type: TypeKeys.PROCESS_AUTHORIZATION_REQUEST_FULFILLED;
}

export const processAuthorizationRequestFulfilledAction = (
  linkToken: string,
  response: AuthorizationRequest
): ProcessAuthorizationRequestFulfilledAction => {
  return {
    linkToken,
    response: response,
    type: TypeKeys.PROCESS_AUTHORIZATION_REQUEST_FULFILLED,
  };
};

export interface ProcessAuthorizationRequestFailedAction
  extends ErrorAction<TypeKeys.PROCESS_AUTHORIZATION_REQUEST_FAILED> {
  linkToken: string;
}

export const processAuthorizationRequestFailed: ErrorActionCreator<TypeKeys.PROCESS_AUTHORIZATION_REQUEST_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): ProcessAuthorizationRequestFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    linkToken: params!.linkToken,
    type: TypeKeys.PROCESS_AUTHORIZATION_REQUEST_FAILED,
  };
};

export type AuthorizationRequestActionTypes =
  | GetAuthorizationRequestAction
  | GetAuthorizationRequestFulfilledAction
  | GetAuthorizationRequestFailedAction
  | ProcessAuthorizationRequestAction
  | ProcessAuthorizationRequestFulfilledAction
  | ProcessAuthorizationRequestFailedAction;
