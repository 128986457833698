import { SourceSystem } from '../../../generated/api/sourceSystem.js';
import { fetchBillingAccounts } from '../../../common/fetch.js';
import { loadingBillingAccountsMsg, t } from '../../../common/i18n/index.js';
import { useEffect, useState } from 'react';
import { useOpenFormAsync } from '../OpenFormProvider.js';
import type { BillingAccount } from '../../../generated/api/billingAccount.js';

const query = { sourceSystem: SourceSystem.MIPA } as const;

export const useOpenFormBillingAccounts = () => {
  const [data, setData] = useState<BillingAccount[] | undefined>(undefined);
  const async = useOpenFormAsync();

  useEffect(() => {
    const { ctrl } = new async(({ signal }) => fetchBillingAccounts(query, { signal }))
      .resolved(({ billingAccounts }) => setData(billingAccounts))
      .cache(query.sourceSystem)
      .execute(t.RCPW(loadingBillingAccountsMsg));

    return () => ctrl.abort();
  }, [async]);

  return data;
};
