import { AutoComplete } from '../../../components/AutoComplete/AutoComplete.js';
import { billingAccountInvoiceTypes } from '../../utils/billingAccountUtils.js';
import { invoiceTypeMsg, noSelectionMsg, t } from '../../i18n/index.js';
import { useController, useFormContext } from 'react-hook-form';
import type { BaInvoiceType } from '../../utils/billingAccountUtils.js';

interface InvoiceTypeProps {
  name: string;
}

export const InvoiceType = (props: InvoiceTypeProps) => {
  const invoiceTypes = billingAccountInvoiceTypes;
  const name = props.name;
  const methods = useFormContext();
  const { control, setValue } = methods;
  const { field, formState } = useController({ name, control });
  const defaultValues = formState.defaultValues;
  const keys = name.split('.');
  const value = keys.reduce((current, key) => (current ? current[key] : undefined), defaultValues);
  const defaultValue = invoiceTypes.find(i => i.value === value);

  return (
    <AutoComplete<BaInvoiceType>
      className="large-min-height"
      inputRef={field.ref}
      options={invoiceTypes}
      defaultOption={defaultValue}
      getUniqueId={i => i.value}
      getDisplayValue={i => i.displayValue()}
      onInputBlur={field.onBlur}
      onInputChange={(e, item) => {
        setValue(name, item.value);
      }}
      id={`${name}-autocomplete`}
      noOptionsMsg={t.ZW5W(noSelectionMsg)}
      label={t.UW4H(invoiceTypeMsg)}
      config={{ isClearable: false }}
    />
  );
};
