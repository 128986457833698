import { SortType } from './ProductGridSort.js';
import { createFilters } from './ProductGridFilter.js';
import type { FilterType, Filters } from './ProductGridFilter.js';
import type { OnlineModelCategory } from '../../generated/api/onlineModelCategory.js';
import type { ProductGridItem } from './ProductGrid.js';

export enum ProductGridUrlParams {
  SEARCH = 'search',
  SORT = 'sort',
}

interface QueryParams {
  search: string;
  filters: Filters;
  sort: SortType;
}

export const getFiltersFromUrlSearchParams = (
  items: ProductGridItem[],
  category: OnlineModelCategory,
  urlSearchParams: URLSearchParams,
  prefix: string
): Filters => {
  const createdFilters = createFilters(items, category);
  Object.keys(createdFilters).forEach((key: FilterType) => {
    const specificKey = prefix.concat(key);
    if (urlSearchParams.has(specificKey)) {
      createdFilters[key].selectedOptions = urlSearchParams.getAll(specificKey);
    }
  });
  return createdFilters;
};

export const getLocationFromQueryParams = (params: QueryParams, prefix: string): string => {
  const updatedUrlParams = new URLSearchParams(location.search);
  updatedUrlParams.set(prefix.concat(ProductGridUrlParams.SEARCH), params.search);
  updatedUrlParams.set(prefix.concat(ProductGridUrlParams.SORT), params.sort);
  Object.keys(params.filters).forEach((key: FilterType) => {
    const urlKey = prefix.concat(key);
    updatedUrlParams.delete(urlKey);
    if (params.filters[key].selectedOptions.length > 0) {
      params.filters[key].selectedOptions.forEach((option: string) => updatedUrlParams.append(urlKey, option));
    }
  });
  return `${location.pathname}?${updatedUrlParams}`;
};

export const parseQueryParams = (
  items: ProductGridItem[],
  category: OnlineModelCategory,
  urlSearchParams: URLSearchParams,
  prefix: string
): QueryParams => {
  return {
    sort: (urlSearchParams.get(prefix.concat(ProductGridUrlParams.SORT)) as SortType) ?? SortType.ORDER_NEWEST,
    search: urlSearchParams.get(prefix.concat(ProductGridUrlParams.SEARCH)) ?? '',
    filters: getFiltersFromUrlSearchParams(items, category, urlSearchParams, prefix),
  };
};
