import { Modal } from './Modal.js';
import { ModalCloseAndSubmitButtons } from './ModalCloseAndSubmitButtons.js';
import { closeMsg, t } from '../../common/i18n/index.js';

import './PoBoxValidationWarningModal.scss';

export const POBoxWarningModal = ({ setShowModal }: { setShowModal: (isShown: boolean) => void }) => (
  <Modal
    closeable
    onClose={() => setShowModal(false)}
    size="s"
    className="po-box-validation-warning-modal"
    heading={t.JS1B('Check the delivery address')}
  >
    {t.NTUU('The product cannot be delivered to a PO Box. Change the address and try again.')}
    <ModalCloseAndSubmitButtons onCancel={() => setShowModal(false)} cancelButtonText={t.WOYD(closeMsg)} />
  </Modal>
);
