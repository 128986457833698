import { FixedBroadbandAvailability } from '../../../../components/FixedBroadbandAvailability/FixedBroadbandAvailability.js';
import { deepEqual } from '../../../../common/utils/objectUtils.js';
import { useSelector } from 'react-redux';
import type { State } from '../../../../selfservice/common/store.js';

export const FixedBroadbandAvailabilityPath = () => {
  const { addressSearchResult, commercialAvailabilityResult } = useSelector(
    (state: State) => ({
      addressSearchResult: state?.resources?.addressSearchResult,
      commercialAvailabilityResult: state?.resources?.commercialAvailabilityResult,
    }),
    deepEqual
  );

  return (
    <FixedBroadbandAvailability
      addressSearchResult={addressSearchResult}
      selectedAddressId={addressSearchResult?.match?.addressId}
      commercialAvailabilityResult={commercialAvailabilityResult}
    />
  );
};
