import * as CL from '@design-system/component-library';
import * as React from 'react';
import { desktopItemWidth, tabletItemWidth } from '../../common/utils/gridUtils.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { onEnterOrSpaceKeyPress } from '../../common/utils/handlerUtils.js';
import classNames from 'classnames';

import './SelectOneList.scss';

export enum GutterStyle {
  Minimal = 'minimal',
  Normal = 'normal',
}

export interface SelectOneListItemProps {
  element: React.ReactNode;
  isHighlighted?: boolean;
  isSelected?: boolean;
  isDisabled?: boolean;
  link?: string;
  onClick?: () => void;
}

export interface SelectOneListProps {
  gutterStyle?: GutterStyle;
  maxItemsAsideDesktop?: 1 | 2 | 3 | 4 | 6 | 12;
  maxItemsAsideLaptop?: 1 | 2 | 3 | 4 | 6 | 12;
  maxItemsAsideTablet?: 1 | 2 | 3 | 6;
  options: SelectOneListItemProps[];
  thickIcon?: boolean;
  disableIcon?: boolean;
  fullWidth?: boolean;
}

const LIST_CLASS = 'of-select-one-list';
const LIST_ITEM_CLASS = 'of-select-one-list__item';

export const SelectOneList = ({
  disableIcon,
  gutterStyle,
  options,
  maxItemsAsideDesktop,
  maxItemsAsideLaptop,
  maxItemsAsideTablet,
  thickIcon,
  fullWidth = true,
}: SelectOneListProps) => {
  const tablet = tabletItemWidth(maxItemsAsideTablet);
  const laptop = desktopItemWidth(maxItemsAsideLaptop);
  const desktop = desktopItemWidth(maxItemsAsideDesktop);

  return (
    <div className={`${LIST_CLASS} ${LIST_CLASS}--gutter-${gutterStyle || GutterStyle.Normal}`} role="radiogroup">
      <CL.Grid className={dsClass.GRID}>
        <CL.GridRow>
          {options.map((option, index) => {
            const linkAttributes = option.link ? { href: option.link } : { onClick: option.onClick };

            return (
              <CL.GridCol
                colsXS={4}
                colsS={6}
                colsM={tablet}
                colsL={laptop}
                colsXL={desktop}
                className={!fullWidth ? `${LIST_ITEM_CLASS}--column` : ''}
                key={`${LIST_CLASS}-${index}`}
              >
                <a
                  aria-checked={option.isSelected}
                  className={classNames(
                    LIST_ITEM_CLASS,
                    option.isHighlighted && `${LIST_ITEM_CLASS}--highlighted`,
                    option.isSelected && `${LIST_ITEM_CLASS}--selected`,
                    option.isDisabled && `${LIST_ITEM_CLASS}--disabled`
                  )}
                  href={linkAttributes.href}
                  onClick={linkAttributes.onClick}
                  onKeyPress={(event: React.KeyboardEvent) =>
                    onEnterOrSpaceKeyPress(event, () => {
                      linkAttributes.onClick?.();
                    })
                  }
                  role="radio"
                  tabIndex={0}
                >
                  {!disableIcon && option.isSelected && (
                    <CL.Icon icon="check" type={thickIcon ? 'filled' : 'regular'} />
                  )}
                  <div>{option.element}</div>
                </a>
              </CL.GridCol>
            );
          })}
        </CL.GridRow>
      </CL.Grid>
    </div>
  );
};
