import * as CL from '@design-system/component-library';
import { Icon } from '../Icon/Icon.js';
import { customerMsg, nameOfCatalogMsg, selectedApproverMsg, statusMsg, t } from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { getCustomerOrderStatusInfoDS, orderContainsBillingPeriod } from './CustomerOrderDetailsUtil.js';
import { getPriceAsText } from '../SubscriptionDetails/subscriptionDetailsCommon.js';
import type { CustomerOrder } from '../../generated/api/models.js';

interface CustomerOrderInfoParams {
  customerOrder: CustomerOrder;
  isEmployee: boolean;
}

export const CustomerOrderInfo = ({ customerOrder, isEmployee }: CustomerOrderInfoParams) => {
  const customerOrderStatusInfo = getCustomerOrderStatusInfoDS(
    customerOrder.status ? customerOrder.status.toString() : undefined
  );
  const isBillingPeriodOrder = orderContainsBillingPeriod(customerOrder);

  const recurringChargesTitle = isBillingPeriodOrder
    ? t.ORQE('Recurring charges, VAT 0%')
    : t.E0K4('Total monthly charges, VAT 0%');

  const fieldsLeft: CL.DescriptionItem[] = [
    {
      title: t.ASQT(statusMsg),
      description: (
        <div className="of-status-icon">
          <Icon icon="record" type="filled" size="s" color={customerOrderStatusInfo.color} />{' '}
          {customerOrderStatusInfo.text}
        </div>
      ),
    },
  ];

  if (customerOrder.customerOrderContact !== undefined) {
    fieldsLeft.push({
      title: t.ZC7D(customerMsg),
      description: customerOrder.customerOrderContact,
    });
  }

  if (customerOrder.selectedApprover !== undefined) {
    fieldsLeft.push({
      title: t.HSCA(selectedApproverMsg),
      description: customerOrder.selectedApprover,
    });
  }

  if (customerOrder.catalogName !== undefined) {
    fieldsLeft.push({
      title: t.Z0HF(nameOfCatalogMsg),
      description: customerOrder.catalogName || undefined,
    });
  }

  const fieldsRight: CL.DescriptionItem[] = [
    {
      title: recurringChargesTitle,
      description: getPriceAsText(
        customerOrder.totalMonthlyRecurringCharge,
        customerOrder.totalMonthlyRecurringListCharge,
        !isBillingPeriodOrder
      ),
    },
    {
      title: t.TE4L('Total one-time payments'),
      description: getPriceAsText(customerOrder.totalOneTimeCharge, customerOrder.totalOneTimeListCharge),
    },
  ];

  const visibleFields = isEmployee ? fieldsLeft : fieldsLeft.concat(fieldsRight);

  return <CL.Description items={visibleFields} className={dsClass.PADDING_BOTTOM_5} />;
};
