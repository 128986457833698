import * as CL from '@design-system/component-library';
import { Impression } from '../Impression/Impression.js';
import { Link } from 'react-router-dom';
import { OfferBadge } from '../OfferBadge/OfferBadge.js';
import { Picture } from '../Picture/Picture.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { formatSum } from '../../common/utils/priceUtils.js';
import {
  monthMsg,
  noProductsMsg,
  orMsg,
  startingAtMsg,
  t,
  totalPricesMsg,
  totalWithCurlyBracketsMsg,
} from '../../common/i18n/index.js';
import { valueOrZero } from '../ProductDetails/utils/productDetailsUtils.js';
import type { ECommerceEvents } from '../PublicPage/types.js';
import type { ProductGridItem } from './ProductGrid.js';

import './ProductGrid.scss';

interface ProductGridItemsProps {
  onAnalyticsEvent?: (eventData: object) => void;
  eCommerceEvents?: ECommerceEvents;
  productGridItems: ProductGridItem[];
  inEOE?: boolean;
}

interface ProductGridLinkProps {
  productGridItem: ProductGridItem;
  onClick: () => void;
  inEOE?: boolean;
}

const ProductGridLinkItem = ({
  children,
  onClick,
  productGridItem,
}: ProductGridLinkProps & { children: JSX.Element }) => {
  return (
    <>
      {productGridItem.pagePath && (
        <Link className="product-grid-link-item" onClick={onClick} to={productGridItem.pagePath || ''}>
          {children}
        </Link>
      )}
    </>
  );
};

const ProductGridLink = ({ onClick, productGridItem, inEOE }: ProductGridLinkProps) => {
  const createMainPrice = (item: ProductGridItem) => {
    const createPeriodicPrice = () => (
      <>{`${t.SH61(startingAtMsg)} ${formatSum(item.monthlyRecurringCharge)}/${t.XXVX(monthMsg)}`}</>
    );
    const createOneTimePrice = () => (item.oneTimeCharge ? formatSum(item.oneTimeCharge) : '');
    return item.monthlyRecurringCharge !== undefined ? createPeriodicPrice() : createOneTimePrice();
  };

  const createSidePrice = (item: ProductGridItem) => {
    const createAlternativePrice = () => (
      <>
        {t.K4LF(orMsg)} {formatSum(item.oneTimeCharge)}
      </>
    );
    const createWholePrice = () =>
      item.monthlyRecurringCharge !== undefined && item.payments ? (
        <>
          {inEOE
            ? t.OP4E(
                totalPricesMsg,
                `${formatSum(item.monthlyRecurringCharge) || ''}/${t.XXVX(monthMsg)}`,
                formatSum(item.monthlyRecurringCharge * item.payments) || ''
              )
            : t.W1RX(totalWithCurlyBracketsMsg, formatSum(item.monthlyRecurringCharge * item.payments) || '')}
        </>
      ) : (
        ''
      );
    return item.monthlyRecurringCharge !== undefined && item.oneTimeCharge
      ? createAlternativePrice()
      : createWholePrice();
  };
  const isProductFullyPaidByEmployer = (item: ProductGridItem) => {
    return valueOrZero(item.monthlyRecurringCharge) === 0 && valueOrZero(item.oneTimeCharge) === 0;
  };

  return (
    <ProductGridLinkItem onClick={onClick} productGridItem={productGridItem}>
      <>
        {inEOE && isProductFullyPaidByEmployer(productGridItem) && (
          <CL.Badge className="of_product_price_badge" text={t.Y9JE('Paid by employer')} color="warning" />
        )}
        <div className="product-grid-item__image">
          <Picture
            alt={productGridItem.name}
            loading="lazy"
            src={productGridItem.imageUrl}
            offerWidthAlternatives={[160, 300, 360, 720, 1080]}
            renderedImageSize={{
              onPhone: '33vw',
              onLaptop: '300px',
            }}
            square
          />
        </div>
        <div className="product-grid-item__text">
          <OfferBadge className="product-grid-item__promotion" offerBadge={productGridItem.offerBadge} />
          <h2 className={`product-grid-item__product-name ${dsClass.FONT_SIZE_LARGE} of-product-name`}>
            {`${productGridItem.manufacturer} ${productGridItem.name}`}
          </h2>
          <h3 className={`product-grid-item__price ${dsClass.TEXT_LEAD} of-product-price`}>
            {createMainPrice(productGridItem)}
          </h3>
          <span className={dsClass.TEXT_XS}>{createSidePrice(productGridItem)}</span>
        </div>
      </>
    </ProductGridLinkItem>
  );
};

const CATEGORY_LIST = 'CategoryList';

export const ProductGridItems = ({ eCommerceEvents, productGridItems, inEOE }: ProductGridItemsProps) => {
  const results = productGridItems.map((item: ProductGridItem, index) => {
    const productGridLink = (
      <ProductGridLink
        onClick={() => {
          if (eCommerceEvents?.onClickListProduct && item.ref) {
            eCommerceEvents.onClickListProduct(CATEGORY_LIST, [index], [item.ref]);
          }
        }}
        productGridItem={item}
        inEOE={inEOE}
      />
    );
    return (
      <CL.GridCol
        key={item.code}
        colWidthS={3}
        colWidthM={3}
        colWidthL={4}
        colWidthXL={3}
        data-testid="product-grid-item"
      >
        {eCommerceEvents?.onSeeListProduct ? (
          <Impression
            onImpression={() => {
              if (eCommerceEvents.onSeeListProduct && item.ref) {
                eCommerceEvents.onSeeListProduct(CATEGORY_LIST, [index], [item.ref]);
              }
            }}
          >
            {productGridLink}
          </Impression>
        ) : (
          productGridLink
        )}
      </CL.GridCol>
    );
  });

  return results.length ? (
    <CL.Grid className="grid-items">
      <CL.GridRow>{results}</CL.GridRow>
    </CL.Grid>
  ) : (
    <div className="of-product-grid__no-products">{t.GXAQ(noProductsMsg)}</div>
  );
};
