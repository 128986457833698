import { devicesMsg, t, voiceAndBroadbandMsg } from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import type { Subscription } from '../../generated/api/models.js';

interface ParsedMobileSubscriptionAttrs {
  mobileNumber: string;
  puk1: string;
  puk2: string;
  simCardNumber?: string;
}

export const parseMobileSubscriptionAttrs = (subscription: Subscription): ParsedMobileSubscriptionAttrs => {
  return {
    mobileNumber: subscription.details?.mobile?.mobileNumber || subscription.subscriptionUserFriendlyId || '',
    puk1: subscription.details?.mobile?.puk1 || '',
    puk2: subscription.details?.mobile?.puk2 || '',
    simCardNumber: subscription.details?.mobile?.simCardNumber || undefined,
  };
};

export const getSubscriptionsDisplayCategories = (showExpiringEppSubscriptionHint: boolean) => [
  {
    children: t.JPRH(voiceAndBroadbandMsg),
    to: paths.EMPLOYEE_SUBSCRIPTIONS,
  },
  {
    children: t.JJTM(devicesMsg),
    to: paths.EMPLOYEE_DEVICES,
    hint: showExpiringEppSubscriptionHint,
  },
];
