import * as CL from '@design-system/component-library';
import { CompanySearch, Email, Name, PhoneNumber } from '../../common/react-hook-form/fields/index.js';
import { businessIdOrCompanyMsg, firstNameMsg, informantBISMsg, lastNameMsg, t } from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import type { ContactSupportFormValues } from './ContactSupportForm.js';
import type { UseFormGetValues } from 'react-hook-form';

export interface ContactInfoFieldsProps {
  readonly: boolean;
  readonlyValues?: UseFormGetValues<ContactSupportFormValues>;
}

/**
 * Form where user can input their contact details
 */
export const ContactSupportInfoFields = ({ readonly, readonlyValues }: ContactInfoFieldsProps) => {
  const firstNameLabel = t.AIK7(firstNameMsg);
  const lastNameLabel = t.Y8OY(lastNameMsg);

  return (
    <div className={dsClass.PADDING_BOTTOM_6}>
      <div className={`contact-info_company-name ${dsClass.MARGIN_BOTTOM_4}`}>
        {readonly && readonlyValues ? (
          <div className={dsClass.INPUT}>
            <div className={dsClass.INPUT_LABELAREA}>
              <label className={dsClass.INPUT_LABELAREA_LABEL}>{t.GSAD(businessIdOrCompanyMsg)}</label>
            </div>
            <div className={dsClass.INPUT_INPUTAREA}>
              <CL.Icon className={dsClass.POSITION_ABSOLUTE} icon="search" />
              <span className={dsClass.PADDING_LEFT_5}>{`${readonlyValues().businessName}, ${
                readonlyValues().businessId
              }`}</span>
              <p className={`${dsClass.MARGIN_TOP_2} ${dsClass.TEXT_S}`}>{t.V9OQ(informantBISMsg)}</p>
            </div>
          </div>
        ) : (
          <CompanySearch />
        )}
      </div>
      <div className="contact-info-details">
        <Name name="firstName" label={firstNameLabel} placeholder={firstNameLabel} readonly={readonly} />
        <Name name="lastName" label={lastNameLabel} placeholder={lastNameLabel} readonly={readonly} />
        <Email readonly={readonly} />
        <PhoneNumber readonly={readonly} />
      </div>
    </div>
  );
};
