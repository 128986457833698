import {
  CustomerOrderSystemError,
  CustomerOrders,
  CustomerOrdersSceneTab,
} from '../../../../../components/CustomerOrders/CustomerOrders.js';
import { RedeemRequestList } from '../../../../../components/SubscriptionAction/RedeemRequestList.js';
import { useAuth } from '../../../AuthProvider.js';
import { useLoaderData } from 'react-router-dom';
import type { SubscriptionActionsResponse } from '../../../../../generated/api/subscriptionActionsResponse.js';

export const CustomerOrderRedeemRequestsPath = () => {
  const { authenticatedUser } = useAuth();
  const { searchResults: redeemRequests, total: totalItems } = useLoaderData() as SubscriptionActionsResponse;

  return (
    <CustomerOrderSystemError>
      <CustomerOrders tab={CustomerOrdersSceneTab.REDEEM_REQUESTS}>
        <RedeemRequestList
          redeemRequests={redeemRequests}
          totalItems={totalItems}
          authenticatedUser={authenticatedUser}
        />
      </CustomerOrders>
    </CustomerOrderSystemError>
  );
};
