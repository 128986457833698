import { getShoppingCart } from '../../selfservice/common/localStorageUtils.js';
import { processCartItems } from '../../selfservice/actions/index.js';
import { useDispatch, useStore } from 'react-redux';
import { useEffect } from 'react';
import type { State } from '../../selfservice/common/store.js';

export const useShoppingCart = () => {
  const dispatch = useDispatch();
  const cartItems = useStore<State>().getState().deviceCheckout?.cartItems;
  // Set possible localstorage cartItems to state
  useEffect(() => {
    if (!cartItems) {
      dispatch(processCartItems(false, false, getShoppingCart()));
    }
  }, [dispatch, cartItems]);
};
