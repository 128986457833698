import { AuthenticatedUserRole, CommercialProductSubType } from '../../generated/api/models.js';
import {
  activationFeeMsg,
  alvZeroMsg,
  changeSubscriptionTypeFeeMsg,
  fixedTermMsg,
  monthMsg,
  noFixedTermMsg,
  t,
} from '../../common/i18n/index.js';
import { formatSum } from '../../common/utils/priceUtils.js';
import { getCommercialProductPrices } from '../../common/utils/commercialProductUtils.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import type { CampaignAssociation, CommercialProduct, SalesType } from '../../generated/api/models.js';

export interface ProductCardFooterProps {
  offerCampaignAssociation?: CampaignAssociation;
  commercialProduct: CommercialProduct;
  showCommitmentText: boolean;
  salesType: SalesType;
}

export const ProductCardFooter = ({
  commercialProduct,
  offerCampaignAssociation,
  showCommitmentText,
  salesType,
}: ProductCardFooterProps) => {
  const { oneTimeCharge, oneTimeChargeBeforeDiscount } = getCommercialProductPrices(
    commercialProduct,
    offerCampaignAssociation,
    salesType
  );
  const commitmentText = offerCampaignAssociation?.fixedTermPeriod
    ? `${offerCampaignAssociation.fixedTermPeriod} ${t.XXVX(monthMsg)} ${t.XL11(fixedTermMsg)}`
    : t.XJMB(noFixedTermMsg);
  const isMobileVoiceOrMobileBbProduct =
    commercialProduct.productSubType === CommercialProductSubType.MOBILE_VOICE ||
    commercialProduct.productSubType === CommercialProductSubType.MOBILE_BROADBAND;
  const { authenticatedUser } = useAuth();
  const isEmployee = authenticatedUser?.userRole === AuthenticatedUserRole.EMPLOYEE;
  return (
    <>
      <div>{showCommitmentText && commitmentText}</div>
      <div>
        <span>{`${t.HWDR(activationFeeMsg)} `}</span>
        {oneTimeChargeBeforeDiscount && (
          <span className="price-text--line-through">{`${formatSum(oneTimeChargeBeforeDiscount)} `}</span>
        )}
        <span>{formatSum(oneTimeCharge)}</span>
        {isMobileVoiceOrMobileBbProduct && (
          <>
            <br />
            <span>{` (${t.YG89(changeSubscriptionTypeFeeMsg)})`}</span>
          </>
        )}
        {!isEmployee && (
          <>
            <br />
            <span>{t.S8TX(alvZeroMsg)}</span>
          </>
        )}
      </div>
    </>
  );
};
