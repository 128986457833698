import {
  TypeKeys,
  initiateEmployeeOnBoardingFailed,
  initiateEmployeeOnBoardingFulfilled,
  initiateEmployeeOnBoardingResendFailed,
  loadOnboardingRequests,
  loadOnboardingRequestsFailed,
  loadOnboardingRequestsFulfilled,
} from '../actions/index.js';
import { actionToActionState } from './epicUtils.js';
import { callUiApi, prepareUiApiRequest } from '../common/uiApiUtils.js';
import { combineEpics, ofType } from 'redux-observable';
import { concatMap, mergeMap } from 'rxjs/operators';
import {
  getOnboardingRequestsPrivateMethod,
  initiateEmployeeOnBoardingPrivateMethod,
} from '../../generated/api/uiApiMethods.js';
import { of } from 'rxjs';
import type { Action } from 'redux';
import type { ActionAndState, EpicDependencies } from './epicUtils.js';
import type { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import type {
  InitiateEmployeeOnBoardingAction,
  LoadOnboardingRequestsAction,
  SelfServiceActionTypes,
} from '../actions/index.js';
import type { State } from '../common/store.js';

export const initiateEmployeeOnboarding: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(
    action$.pipe(ofType(TypeKeys.INITIATE_EMPLOYEE_ONBOARDING)),
    (action: InitiateEmployeeOnBoardingAction) => actionToActionState(action, state$, 'authenticated')
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const initiateEmployeeOnboardingAction = actionAndState.action as InitiateEmployeeOnBoardingAction;
      const failureAction = initiateEmployeeOnboardingAction.resend
        ? initiateEmployeeOnBoardingResendFailed
        : initiateEmployeeOnBoardingFailed;
      return callUiApi({
        epicDependencies,
        state$,
        method: initiateEmployeeOnBoardingPrivateMethod(),
        payload: initiateEmployeeOnboardingAction.payload,
        successAction: initiateEmployeeOnBoardingFulfilled,
        failureAction,
      });
    })
  );

export const employeeOnboardingFullfilledEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>
) =>
  action$.ofType(TypeKeys.INITIATE_EMPLOYEE_ONBOARDING_FULFILLED).pipe(
    mergeMap(() => {
      return of(loadOnboardingRequests(state$.value.selfservice?.onboardingRequests?.sort, true));
    })
  );

export const loadOnboardingRequestsEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.LOAD_ONBOARDING_REQUESTS)), (action: LoadOnboardingRequestsAction) =>
    actionToActionState(action, state$, 'onboardingRequests')
  ).pipe(
    concatMap(() => {
      return callUiApi({
        epicDependencies,
        failureAction: loadOnboardingRequestsFailed,
        method: getOnboardingRequestsPrivateMethod(),
        state$,
        successAction: loadOnboardingRequestsFulfilled,
      });
    })
  );

export const employeeOnboardingEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = combineEpics(
  initiateEmployeeOnboarding,
  employeeOnboardingFullfilledEpic,
  loadOnboardingRequestsEpic
);
