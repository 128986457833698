import * as CL from '@design-system/component-library';
import { ContactCreationType, formValuesToPurposeOfUseOrContact } from './ContactsOrPurposeOfUseUtils.js';
import { ContactOrPurposeOfUseSelector } from './components/ContactOrPurposeOfUseSelector.js';
import { CopyUserInfoButton } from './components/CopyUserInfoButton.js';
import { CostCenterAndEmployeeNumber } from './components/CostCenterAndEmployeeNumber.js';
import { Form, Formik } from 'formik';
import { SelectedPurposeOfUseOrContact } from '../../common/enums.js';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { scrollToFirstError } from '../../common/formik/index.js';
import { t, userInformationAddedMsg, userInformationUpdatedMsg } from '../../common/i18n/index.js';
import type { Contact } from '../../generated/api/models.js';
import type { ContactOrPurposeOfUseFormValues } from './ContactsOrPurposeOfUseUtils.js';
import type { FormikProps } from 'formik';
import type { ForwardedRef } from 'react';
import type { PurposeOfUseOrContact } from '../../common/types/subscription.js';

import './ContactOrPurposeOfUse.scss';

export interface ContactOrPurposeOfUseProps {
  id: string;
  isCopiedUserInfo?: boolean; // Signifies if the purposeOfUseOrContact prop is a result of copy user info

  // Data
  contacts?: Contact[];
  // Initial values. If this changes after first load, then form is reinitialized
  purposeOfUseOrContact: PurposeOfUseOrContact;

  // State
  saving: boolean;

  // Handlers
  onChangeContact?: (selectedContactId?: string) => void;
  onCopyUserInfo?: (purposeOfUseOrContact: PurposeOfUseOrContact) => void;

  // Validations
  required?: boolean;
  isEmailAndPhoneRequired?: boolean;
  isCostCenterRequired?: boolean;

  // Config
  enableCostCenterAndReference?: boolean;
  onlyPurposeOfUse?: boolean;
}

export interface ContactOrPurposeOfUseCallables {
  // Gives the form values if valid. Otherwise scrolls to error if specified and returns false.
  getValues: (scrollToError: boolean) => Promise<PurposeOfUseOrContact | false>;
}

const purposeOfUseOrContactToFormValues = (
  purposeOfUseOrContact: PurposeOfUseOrContact
): ContactOrPurposeOfUseFormValues => ({
  selectionTab: purposeOfUseOrContact.selected || SelectedPurposeOfUseOrContact.CONTACT,
  selectContact: purposeOfUseOrContact.contactId || '',
  purposeOfUse: purposeOfUseOrContact.purposeOfUse || '',
  firstName: purposeOfUseOrContact.firstName || '',
  lastName: purposeOfUseOrContact.lastName || '',
  phoneNumber: purposeOfUseOrContact.phoneNumber || '',
  email: purposeOfUseOrContact.email || '',
  costCenter: purposeOfUseOrContact.costCenter || '',
  employeeNumber: purposeOfUseOrContact.employeeNumber || '',
});

interface CopyUserInfoSuccessMessageProps {
  purposeOfUseOrContact: PurposeOfUseOrContact;
}
const CopyUserInfoSuccessMessage = ({ purposeOfUseOrContact }: CopyUserInfoSuccessMessageProps) => (
  <div className="copy-user-info-success-message">
    <CL.Icon icon="updateRegular" size="s" />
    {purposeOfUseOrContact.contactId === ContactCreationType.COPIED_CONTACT
      ? t.ER2E(userInformationAddedMsg)
      : t.AOIV(userInformationUpdatedMsg)}
  </div>
);

export const ContactOrPurposeOfUse = forwardRef(
  (
    {
      id,
      saving,
      purposeOfUseOrContact,
      isCopiedUserInfo,
      contacts,
      isEmailAndPhoneRequired,
      isCostCenterRequired,
      onChangeContact,
      onCopyUserInfo,
      required,
      enableCostCenterAndReference,
      onlyPurposeOfUse,
    }: ContactOrPurposeOfUseProps,
    ref: ForwardedRef<ContactOrPurposeOfUseCallables>
  ) => {
    const formikRef = useRef<FormikProps<ContactOrPurposeOfUseFormValues>>(null);

    // Needed as Formik's isSubmitting / setSubmitting mechanism doesn't work well with Redux
    useEffect(() => {
      if (formikRef.current?.isSubmitting && !saving) {
        formikRef.current.setSubmitting(false);
      }
    }, [saving]);

    useImperativeHandle(ref, () => ({
      getValues: async scrollToError => {
        if (!formikRef.current) {
          throw new Error('Formik ref should be present at this point');
        }
        // Done to trigger the validation which sets the errors to fields
        await formikRef.current.submitForm();
        // Get a fresh formik reference which has validation result
        const formik = formikRef.current;
        if (formik.isValid) {
          return formValuesToPurposeOfUseOrContact(formik.values);
        } else {
          if (scrollToError) {
            scrollToFirstError(id, formik.errors);
          }
          return false;
        }
      },
    }));

    return (
      <Formik
        initialValues={purposeOfUseOrContactToFormValues(purposeOfUseOrContact)}
        onSubmit={() => {}}
        innerRef={formikRef}
        validateOnMount={true}
        enableReinitialize={true} // Needed to support copy user info feature
      >
        {formik => (
          <Form className="of-contact-or-purpose-of-use of-contact-or-purpose-of-use" id={id}>
            {isCopiedUserInfo && !formik.dirty && (
              <CopyUserInfoSuccessMessage purposeOfUseOrContact={purposeOfUseOrContact} />
            )}
            <ContactOrPurposeOfUseSelector
              id={id}
              formattedContacts={contacts}
              isEmailAndPhoneRequired={isEmailAndPhoneRequired}
              onChangeContact={onChangeContact}
              required={required}
              onlyPurposeOfUse={onlyPurposeOfUse}
            />
            {enableCostCenterAndReference && (
              <CostCenterAndEmployeeNumber isCostCenterRequired={isCostCenterRequired} contacts={contacts} />
            )}
            {onCopyUserInfo && (
              <CopyUserInfoButton
                onCopyUserInfo={onCopyUserInfo}
                isEmailAndPhoneRequired={isEmailAndPhoneRequired}
                isCostCenterRequired={isCostCenterRequired}
              />
            )}
          </Form>
        )}
      </Formik>
    );
  }
);
