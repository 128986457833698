import { TypeKeys, generateActionId } from './actionsUtils.js';
import { convertToCommonErrors } from '../../common/utils/errorUtils.js';
import type { Action } from 'redux';
import type { ChangeMobileSimParameters, GenericError } from '../../generated/api/models.js';
import type { CrudAction, ErrorAction } from './actionsUtils.js';

export interface GetServiceFeeAction extends CrudAction<TypeKeys.GET_SERVICE_FEE> {
  serviceType: string;
  resourceId: string;
  changeMobileSimParameters?: ChangeMobileSimParameters;
}

export interface GetServiceFeeFulfilledAction extends Action {
  type: TypeKeys.GET_SERVICE_FEE_FULFILLED;
  subscriptionDisplayId: string;
  price: number;
}

export type GetServiceFeeFailedAction = ErrorAction<TypeKeys.GET_SERVICE_FEE_FAILED>;

export const getServiceFee = (
  serviceType: string,
  resourceId: string,
  changeMobileSimParameters?: ChangeMobileSimParameters
): GetServiceFeeAction => {
  return {
    type: TypeKeys.GET_SERVICE_FEE,
    id: generateActionId(),
    changeMobileSimParameters,
    serviceType,
    resourceId,
  };
};

export const getServiceFeeFulfilled = (subscriptionDisplayId: string, price: number): GetServiceFeeFulfilledAction => {
  return {
    type: TypeKeys.GET_SERVICE_FEE_FULFILLED,
    subscriptionDisplayId,
    price,
  };
};

export const getServiceFeeFailed = (
  message: string,
  status: number,
  errors?: GenericError[]
): GetServiceFeeFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    type: TypeKeys.GET_SERVICE_FEE_FAILED,
  };
};

export type ServiceFeeActionTypes = GetServiceFeeAction | GetServiceFeeFulfilledAction | GetServiceFeeFailedAction;
