import { ContactType } from '../../generated/api/contactType.js';
import { DialogWrapper } from '../DialogWrapper/index.js';
import { cancelMsg, confirmMsg, t } from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { upsertContact } from '../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import type { CommonError } from '../../common/types/errors.js';
import type { ContactPerson } from '../../generated/api/contactPerson.js';

export interface YttContactChangeDialogProps {
  onCloseDialog: () => void;
  contactPerson: ContactPerson;
  values: ContactPerson;
  contactType: ContactType;
  contactId?: string;
  commonErrors?: CommonError[];
  onConfirm: () => void;
}

export const YttContactChangeDialog = ({
  onCloseDialog,
  onConfirm,
  contactPerson,
  values,
  contactType,
  contactId,
  commonErrors,
}: YttContactChangeDialogProps) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  return (
    <DialogWrapper
      buttons={[
        {
          color: 'link',
          onClick: onCloseDialog,
          text: t.B2V1(cancelMsg),
        },
        {
          onClick: () => {
            dispatch(
              upsertContact(
                {
                  person: { ...contactPerson, ...values },
                  contactType: ContactType[contactType],
                  contactId: contactId,
                },
                true,
                true,
                false,
                commonErrors
              )
            );
            setLoading(true);
            onConfirm();
          },
          text: t.QVYK(confirmMsg),
          isLoading: loading,
        },
      ]}
      closeable
      header={t.V02H('Please verify, this will change the credentials for Elisa Yritystietoturva')}
      onCloseDialog={onCloseDialog}
    >
      <div>
        {t.TXTH(
          'This user is the administrator of Elisa Yritystietoturva. Note that if you change their name, mobile phone number, or email address, the administrator credentials to Elisa Yritystietoturva will also change, and the administrator will be logged out of the software.'
        )}
      </div>
      <div className={dsClass.MARGIN_TOP_4}>
        {t.N2OE(
          'The administrator will then receive new credentials from F-Secure via email. They will need to use the new credentials to log back in.'
        )}
      </div>
    </DialogWrapper>
  );
};
