import { Dropdown } from '../Dropdown/Dropdown.js';
import {
  MINUTE_OFFER_DISCLAIMERS,
  getDataPackageItemsByOffer,
  getMinuteBasedSubscriptionItems,
  getSmsPackageItemsByOffer,
} from './minuteBasedSubscriptionUtil.js';
import {
  dataPackageMsg,
  selectDataPackageMsg,
  selectMsg,
  selectSmsPackageMsg,
  subscriptionTypeMsg,
  t,
} from '../../common/i18n';
import { dsClass } from '../../common/constants/dsClasses.js';
import type { AssociationRecord } from '@onlinefirst/cloudsense-add-on-dependency-engine';
import type { OnlineModel } from '../../generated/api/models.js';

import './MinuteBasedSubscriptions.scss';

export interface MinuteSubscription {
  offer?: string;
  dataPackage?: string;
  dataRoaming?: string;
  smsPackage?: string;
}

interface MinuteBasedSubscriptionsProps {
  minuteModels: OnlineModel[] | undefined;
  minuteSubObj: MinuteSubscription;
  addOnAssociations?: AssociationRecord[];
  handleSelectMinuteBasedProduct: (product: MinuteSubscription, models: OnlineModel[] | undefined) => void;
}

export const MinuteBasedSubscriptions = (props: MinuteBasedSubscriptionsProps) => {
  const { minuteModels, minuteSubObj, addOnAssociations, handleSelectMinuteBasedProduct } = props;
  const minuteBasedSubscriptionItems = getMinuteBasedSubscriptionItems(minuteModels);

  const dataPackageItemsByOffer = getDataPackageItemsByOffer(addOnAssociations);
  const dataPackageItems = dataPackageItemsByOffer[minuteSubObj?.offer ?? ''];

  const smsPackageItemsByOffer = getSmsPackageItemsByOffer(addOnAssociations);
  const smsPackageItems = smsPackageItemsByOffer[minuteSubObj?.offer ?? ''];

  const subscriptionTypeDisclaimer = MINUTE_OFFER_DISCLAIMERS().find(
    (d: { offerCode: string; disclaimer: string }) => d.offerCode === minuteSubObj?.offer
  )?.disclaimer;

  const dataPackageDisclaimer = dataPackageItems?.find(d => d.value === minuteSubObj?.dataPackage)?.disclaimer;
  const smsPackageDisclaimer = smsPackageItems?.find(d => d.value === minuteSubObj?.smsPackage)?.disclaimer;

  const onSubscriptionTypeChanged = (listElement: HTMLLIElement) => {
    handleSelectMinuteBasedProduct(
      {
        offer: listElement.dataset.value,
        dataPackage: undefined,
        smsPackage: undefined,
      },
      minuteModels
    );
  };

  const onDataPackageChanged = (listElement: HTMLLIElement) => {
    handleSelectMinuteBasedProduct(
      {
        offer: minuteSubObj?.offer,
        dataPackage: listElement.dataset.value,
        smsPackage: minuteSubObj?.smsPackage,
      },
      minuteModels
    );
  };

  const onSmsPackageChanged = (listElement: HTMLLIElement) => {
    handleSelectMinuteBasedProduct(
      {
        offer: minuteSubObj?.offer,
        dataPackage: minuteSubObj?.dataPackage,
        smsPackage: listElement.dataset.value,
      },
      minuteModels
    );
  };

  if (minuteBasedSubscriptionItems.length < 1) {
    return null;
  }

  return (
    <div className={`of-minute-based-subscriptions ${dsClass.PADDING_BOTTOM_7}`}>
      <h3 className="of-subscription-header">{t.RYGM('Minute-rate subscriptions')}</h3>
      <h4 className={dsClass.PADDING_TOP_4}>{t.QH8P(subscriptionTypeMsg)}</h4>
      <Dropdown
        items={minuteBasedSubscriptionItems}
        i18n_dropdown_placeholderText={t.QRYV(selectMsg)}
        onValueChange={onSubscriptionTypeChanged}
        selectedValue={minuteSubObj?.offer ?? ''}
      />
      <p className={dsClass.TEXT_EXTRA_SMALL}>{subscriptionTypeDisclaimer}</p>
      {dataPackageItems && (
        <>
          <h4 className={dsClass.PADDING_TOP_4}>{t.VSE5(dataPackageMsg)}</h4>
          <Dropdown
            items={dataPackageItems}
            i18n_dropdown_placeholderText={t.UTC5(selectDataPackageMsg)}
            onValueChange={onDataPackageChanged}
            selectedValue={minuteSubObj?.dataPackage ?? ''}
          />
          <p className={dsClass.TEXT_EXTRA_SMALL}>{dataPackageDisclaimer}</p>
        </>
      )}
      {smsPackageItems && (
        <>
          <h4 className={dsClass.PADDING_TOP_4}>{t.QCAF('SMS package')}</h4>
          <Dropdown
            items={smsPackageItems}
            i18n_dropdown_placeholderText={t.ZZWN(selectSmsPackageMsg)}
            onValueChange={onSmsPackageChanged}
            selectedValue={minuteSubObj?.smsPackage ?? ''}
          />
          <p className={dsClass.TEXT_EXTRA_SMALL}>{smsPackageDisclaimer}</p>
        </>
      )}
    </div>
  );
};
