import * as CL from '@design-system/component-library';
import {
  Locale,
  contactInfoMsg,
  cookieSettingsMsg,
  copyrightMsg,
  elisaCorporationMsg,
  elisaShopsMsg,
  privacyPolicyMsg,
  t,
  termsAndConditionsMsg,
  termsOfUseMsg,
} from '../../common/i18n/index.js';
import { SiteContext } from '../../public/site/SiteContext.js';
import { useContext } from 'react';

import './Footer.scss';

interface FooterProps {
  isMaintenanceModeForCmsPages: boolean;
}

export const Footer = ({ isMaintenanceModeForCmsPages = false }: FooterProps) => {
  const { siteBaseUrl, siteLanguage } = useContext(SiteContext);
  const corporationLink = siteLanguage === Locale.FI ? 'https://elisa.fi/yhtiotieto/' : 'https://elisa.com/corporate/';
  const publicPageLinks = !isMaintenanceModeForCmsPages
    ? [
        {
          megastack: [
            {
              title: t.SSBV(elisaShopsMsg),
              url: `${siteBaseUrl}/myymalat`,
            },
          ],
        },
        {
          megastack: [
            {
              title: t.GJS9(contactInfoMsg),
              url: `${siteBaseUrl}/yhteystiedot`,
            },
          ],
        },
      ]
    : [];

  return (
    <CL.Footer
      className="of-footer"
      logo={<CL.Footer.Logo linkHref={siteBaseUrl} />}
      socialLinks={
        <CL.Footer.SocialLinks
          links={{
            facebook: 'https://www.facebook.com/elisasuomi',
            twitter: 'https://twitter.com/elisaoyj',
            instagram: 'https://www.instagram.com/elisaoyj',
            youtube: 'https://www.youtube.com/user/elisa',
            linkedin: 'https://www.linkedin.com/company/elisa',
          }}
        />
      }
      links={
        <CL.Footer.Links
          links={[
            {
              megastack: [
                {
                  title: 'Elisa.fi',
                  url: '//elisa.fi',
                },
              ],
            },
            {
              megastack: [
                {
                  title: t.FM7B(elisaCorporationMsg),
                  url: corporationLink,
                },
              ],
            },
            ...publicPageLinks,
          ]}
        />
      }
      disclaimerLinks={
        <CL.Footer.DisclaimerLinks
          links={{
            TOUL: {
              TOULText: t.TMN1(termsOfUseMsg), // eslint-disable-line @typescript-eslint/naming-convention
              TOULUrl: 'https://elisa.fi/kayttoehdot/', // eslint-disable-line @typescript-eslint/naming-convention
            },
            contract: {
              contractText: t.BCPB(termsAndConditionsMsg),
              contractUrl: 'https://elisa.fi/sopimusehdot/',
            },
            privacy: {
              privacyText: t.X924(privacyPolicyMsg),
              privacyUrl: 'https://elisa.fi/tietosuojakaytanto/',
            },
            cookiesettings: {
              text: t.VOV7(cookieSettingsMsg),
            },
            copyright: {
              copyrightText: t.YLFT(copyrightMsg),
            },
          }}
        />
      }
    />
  );
};
