import { Field } from 'formik';
import { InputComponent } from '../FieldComponents/index.js';
import { requiredValidation } from '../validation.js';

export interface ProvidedInputComponentProps {
  name: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  helpText?: string;
  maxLength?: number;
  type?: string;
  optionalText?: string;
}

/**
 * Represents input field which is configured by unknown data
 * Always uses InputComponent as the underlying component
 */
export const ProvidedInput = (props: ProvidedInputComponentProps) => (
  <Field {...props} component={InputComponent} validate={props.required ? requiredValidation : undefined} />
);
