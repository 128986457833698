import { DataLoader } from '../siteUtils.js';
import { SmallHeader } from '../../../components/SmallHeader/SmallHeader.js';
import {
  TrainingEnrollment,
  setAdditionalInformationSchema,
} from '../../../components/TrainingEnrollment/TrainingEnrollment.js';
import { backMsg, t } from '../../../common/i18n/index.js';
import { deepEqual } from '../../../common/utils/objectUtils.js';
import { useAuth } from '../AuthProvider.js';
import { useLoaderData } from 'react-router-dom';
import { useSelector } from 'react-redux';
import type { LeadsState } from '../../../common/types/states.js';
import type { State } from '../../../selfservice/exports.js';
import type { TrainingResponse } from '../../../generated/api/models.js';

export const TrainingEnrollmentPath = () => {
  const { anonymousUser } = useAuth();
  const anonymousLead = {
    leadSent: anonymousUser?.leadSent,
    leadFailed: anonymousUser?.leadFailed,
    leadInProgress: anonymousUser?.leadInProgress,
  };
  const authenticatedLead = useSelector((state: State) => {
    return (state.selfservice as { leads: LeadsState }).leads;
  }, deepEqual);
  const leadsState = authenticatedLead ? authenticatedLead : anonymousLead;
  const { authenticatedUser } = useAuth();
  const trainingData = useLoaderData() as TrainingResponse;

  return (
    <>
      <SmallHeader buttonText={t.D1E9(backMsg)} onClick={() => window.history.back()} />
      <DataLoader>
        <div id="of-training">
          {trainingData.trainingContent?.length && (
            <TrainingEnrollment
              leadsState={leadsState}
              contactId={authenticatedUser?.contact?.contactId}
              trainingResponse={trainingData}
              trainingSchema={trainingData.trainingContent}
              defaultValues={
                authenticatedUser?.businessId
                  ? {
                      businessId: authenticatedUser.businessId,
                      businessName: authenticatedUser.companyName,
                      firstName: authenticatedUser.firstName,
                      lastName: authenticatedUser.lastName || '',
                      phoneNumber: authenticatedUser.mobile || '',
                      email: authenticatedUser.email || '',
                      additionalInformation: '',
                      trainingId: trainingData.trainingContent[0].id,
                    }
                  : {
                      trainingId: trainingData.trainingContent[0].id,
                    }
              }
              additionalInformationSchema={setAdditionalInformationSchema(trainingData.additionalInformation || {})}
            />
          )}
        </div>
      </DataLoader>
    </>
  );
};
