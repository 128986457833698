import { CommonErrorType } from '../../../../../common/enums.js';
import { DnsHistoryDetails } from '../../../../../components/DnsManagement/DnsRecordManagement/DnsHistoryDetails/DnsHistoryDetails.js';
import { DnsRecordManagement } from '../../../../../components/DnsManagement/DnsRecordManagement/DnsRecordManagement.js';
import { SystemError } from '../../../../../components/SystemError/SystemError.js';
import { useLoaderData, useParams } from 'react-router-dom';
import type { DnsRecordsHistoryResponse } from '../../../../../generated/api/dnsRecordsHistoryResponse.js';
import type { HistoryId, SubscriptionId } from '../../../../../common/constants/pathInterfaces.js';

export const DnsRecordHistoryPath = () => {
  const { subscriptionId, historyId } = useParams<SubscriptionId & HistoryId>();
  const historyData = useLoaderData() as DnsRecordsHistoryResponse;

  if (!subscriptionId) {
    return <SystemError errors={[{ type: CommonErrorType.NOT_FOUND, message: 'subscriptionId missing' }]} />;
  }

  if (!historyId) {
    return <SystemError errors={[{ type: CommonErrorType.NOT_FOUND, message: 'historyId missing' }]} />;
  }

  return (
    <DnsRecordManagement subscriptionId={subscriptionId} historyId={historyId} domainName={historyData.domainName}>
      <DnsHistoryDetails subscriptionId={subscriptionId} historyId={historyId} historyData={historyData} />
    </DnsRecordManagement>
  );
};
