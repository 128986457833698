import * as CL from '@design-system/component-library';
import * as React from 'react';
import { ButtonGroupForSubmitAndBack } from '../ButtonGroupForSubmitAndBack/ButtonGroupForSubmitAndBack.js';
import { DialogType } from '../../common/enums.js';
import { SimCardNumberInput } from '../SimCardNumberInput/SimCardNumberInput.js';
import { SubscriptionStatusType } from '../../generated/api/models.js';
import {
  billableChangesDisabledMsg,
  confirmMsg,
  invalidSIMCardNumberMsg,
  returnToSubscriptionPageMsg,
  simChangePendingMsg,
  subChangePendingHeaderMsg,
  t,
  youCanMakeChangesAfterSubActivatedMsg,
} from '../../common/i18n/index.js';
import { formatSum } from '../../common/utils/priceUtils.js';
import { isInvalidSimCardNumber } from '../SimCardDuringNewSubscriptionOrder/SimCardDuringNewSubscriptionOrder.js';
import { simCardChangeAllowed } from '../../common/utils/subscriptionUtils.js';
import type { CategoryKey } from '../../common/utils/categoryUtils.js';
import type { DialogParams } from '../../common/types/dialog.js';
import type { Subscription } from '../../generated/api/models.js';

import './PhysicalSimActivation.scss';

const PhysicalSimInstructions = ({
  simChangeServiceFee,
  isEmployee,
}: {
  simChangeServiceFee?: number;
  isEmployee?: boolean;
}) => (
  <div className="ea-context-notification of-physical-sim-activation__instructions">
    <div className="ea-context-notification__text">
      <div className="of-physical-sim-activation__container">
        <div className="of-physical-sim-activation__instructions-text">
          <b>{t.MYT5('SIM card number is printed on the new SIM card.')}</b>{' '}
          {isEmployee
            ? t.I8GJ(
                'Please restart your device after activating the new SIM card. The initial PIN code is 1234. Old SIM card stops working after the new one is activated.'
              )
            : t.N6BZ(
                'Please restart your device after activating the new SIM card. The initial PIN code is 1234. Price for activation is {} (VAT 0%). Old SIM card stops working after the new one is activated.',
                formatSum(simChangeServiceFee) || ''
              )}
        </div>
        <div className="of-physical-sim-activation__physical-sim">
          <span className="of-physical-sim-activation__picto ea-picto ea-picto--physical-sim" />
        </div>
      </div>
    </div>
  </div>
);

export interface PhysicalSimActivationProps {
  category: CategoryKey;
  disallowBillableSimChanges: boolean;
  pendingSubscriptionChanges: boolean;
  subscription: Subscription;
  simChangeServiceFee?: number;
  isEmployee?: boolean;

  onClickSubscription: () => void;
  onShowDialog: (params: DialogParams) => void;
}

interface PhysicalSimActivationOwnState {
  error?: string;
  simCardNumber: string;
}

export class PhysicalSimActivation extends React.Component<PhysicalSimActivationProps, PhysicalSimActivationOwnState> {
  constructor(props: PhysicalSimActivationProps) {
    super(props);
    this.state = {
      error: undefined,
      simCardNumber: '',
    };
  }

  onChange = (simCardNumber: string) => {
    this.setState({
      simCardNumber: simCardNumber,
    });
  };

  onFocus = () => {
    this.setState({ error: undefined });
  };

  onBlur = () => {
    if (this.state.simCardNumber !== '' && isInvalidSimCardNumber(this.state.simCardNumber)) {
      this.setState({ error: t.SDXB(invalidSIMCardNumberMsg) });
    } else {
      this.setState({ error: undefined });
    }
  };

  onSave = () => {
    const { category, subscription, pendingSubscriptionChanges, onShowDialog } = this.props;
    const { simCardNumber } = this.state;

    if (isInvalidSimCardNumber(simCardNumber)) {
      this.setState({ error: t.SDXB(invalidSIMCardNumberMsg) });
      return;
    }

    if (!simCardChangeAllowed(subscription)) {
      onShowDialog({
        header: t.I9KT(subChangePendingHeaderMsg),
        body: t.O7PT(simChangePendingMsg),
        type: DialogType.GENERIC_INFO_DIALOG,
      });
      return;
    }

    if (subscription.subscriptionStatus === SubscriptionStatusType.SUSPENDED) {
      onShowDialog({
        body: <>{t.J07S(youCanMakeChangesAfterSubActivatedMsg)}</>,
        subscriptionId: subscription.subscriptionId,
        type: DialogType.SUBSCRIPTION_RESUME_FROM_TEMPORARILY_CLOSE,
      });
      return;
    }

    if (pendingSubscriptionChanges) {
      onShowDialog({ type: DialogType.SUBSCRIPTION_ACTION_PENDING });
      return;
    }

    onShowDialog({
      category,
      simCardNumber,
      subscriptionDisplayId: subscription.subscriptionDisplayId,
      subscriptionId: subscription.subscriptionId,
      type: DialogType.CHANGE_SIM_CARD_CONFIRMATION,
    });
  };

  render() {
    const { simCardNumber, error } = this.state;

    if (this.props.disallowBillableSimChanges) {
      return (
        <>
          <div className="of-physical-sim-activation__info">
            <p>{t.UQ4L(billableChangesDisabledMsg)}</p>
          </div>
          <div className="of-physical-sim-activation__actions">
            <CL.Button color="link" onClick={this.props.onClickSubscription}>
              {t.D30R(returnToSubscriptionPageMsg)}
            </CL.Button>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="of-physical-sim-activation__activation">
          <SimCardNumberInput
            error={error}
            onChange={this.onChange}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            value={simCardNumber}
          />
          <PhysicalSimInstructions
            simChangeServiceFee={this.props.simChangeServiceFee}
            isEmployee={this.props.isEmployee}
          />
        </div>
        <ButtonGroupForSubmitAndBack
          className="of-physical-sim-activation__actions"
          onCancel={this.props.onClickSubscription}
          submitButtonText={t.QVYK(confirmMsg)}
          onSubmit={this.onSave}
        />
      </>
    );
  }
}
