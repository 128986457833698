import * as CL from '@design-system/component-library';
import { cancelMsg, submitMsg, t } from '../../common/i18n/index.js';

import './ButtonGroupSubmitAndBack.scss';

export interface ButtonGroupForSubmitAndBackProps {
  className?: string;
  submitDisabled?: boolean;
  cancelDisabled?: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  submitting?: boolean;
  submitButtonText?: string;
  cancelButtonText?: string;
  submitOnLeft?: boolean;
  size?: 's' | 'm' | 'l';
}

export const ButtonGroupForSubmitAndBack = ({
  className,
  submitDisabled,
  cancelDisabled,
  onSubmit,
  onCancel,
  submitting,
  submitButtonText,
  cancelButtonText,
  submitOnLeft,
  size = 'm',
}: ButtonGroupForSubmitAndBackProps) => {
  return (
    <div
      className={`${className || ''} of-button-group-submit-and-back ${
        submitOnLeft ? 'of-button-group-submit-and-back__reverse' : ''
      }`}
    >
      <CL.Button
        color="link"
        onClick={onCancel}
        disabled={cancelDisabled || submitting}
        className={submitOnLeft ? 'margin-left' : ''}
        size={size}
      >
        {cancelButtonText || t.B2V1(cancelMsg)}
      </CL.Button>
      <CL.Button
        type="submit"
        className={submitOnLeft ? '' : 'margin-left'}
        disabled={submitDisabled}
        onClick={onSubmit}
        loading={submitting}
        size={size}
      >
        {submitButtonText || t.FSWU(submitMsg)}
      </CL.Button>
    </div>
  );
};
