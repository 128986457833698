/**
 * Online UI API
 * # Summary Elisa B2B Online UI API  # Usage This API is used in the `https://yrityksille.elisa.fi/` pages.  # Identifiers 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Status of the order. Introduced for Auxiliary eSim Orders but to be used in future for Online Orders as well
 */
export type OrderStatus = 'PASS' | 'FAIL';

export const OrderStatus = {
    PASS: 'PASS' as OrderStatus,
    FAIL: 'FAIL' as OrderStatus
};

