import { ConsolidatedListView } from '../ConsolidatedListView/ConsolidatedListView.js';
import { ContractValiditySummary } from '../SubscriptionLists/ContractValiditySummary.js';
import {
  DEFAULT_ITEMS_PER_PAGE,
  type DefaultListSearchParams,
  ListPagination,
  ListSearch,
  SearchFilters,
  Table,
  TableTopActions,
} from '../Table/index.js';
import { HighlightFormattedPhoneNumber } from '../Highlight/HighlightFormattedPhoneNumber.js';
import { SelectNewSubscriptionDialog } from '../../components/Dialogs/SelectNewSubscriptionDialog.js';
import { ShowAllAccountsToggleForListViews } from '../ShowAllAccountsToggle/ShowAllAccountsToggleForListViews.js';
import { SubscriptionHiddenFields } from '../SubscriptionLists/SubscriptionHiddenFields.js';
import { SubscriptionLink } from '../SubscriptionLists/SubscriptionLink.js';
import { SubscriptionPbxType } from '../../generated/api/models.js';
import {
  billingAccountMsg,
  companyMsg,
  contractMsg,
  identifierMsg,
  numberMsg,
  orderMsg,
  subscriptionMsg,
  t,
} from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { formatPhoneNumber } from '../../common/utils/phoneNumberUtils.js';
import { getCompanyName, getPrimaryMdmId } from '../../common/utils/accountUtils.js';
import { getVoiceSubscriptionTypeFilterGroup } from './SubscriptionsVoiceFilters.js';
import { highlightKeyword } from '../../common/utils/searchFieldUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { setDefaultItemsPerPage } from '../Table/tableUtils.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useLoaderData } from 'react-router-dom';
import { useMultiAccountRowClick } from '../../common/hooks/useMultiAccountRowClick.js';
import { useSearchParams } from '../../common/hooks/useSearchParams.js';
import { useState } from 'react';
import type { AuthenticatedUserState } from '../../common/types/states.js';
import type { MultiAccountRowClickFunction } from '../../common/hooks/useMultiAccountRowClick.js';
import type { SearchFilterGroup } from '../Table/SearchFilters.js';
import type { SubscriptionHeader, SubscriptionSearchResponse } from '../../generated/api/models.js';
import type { SubscriptionsLoaderData } from '../../common/loaders.js';

const PhoneNumberDetails = ({
  subscriptionHeader: { pbxType, subscriptionUserFriendlyId, corporateNumber },
  search,
}: {
  subscriptionHeader: SubscriptionHeader;
  search?: string;
}) => {
  const phoneNumber = (
    <span key="subscriptionUserFriendlyId">
      {subscriptionUserFriendlyId && (
        <HighlightFormattedPhoneNumber
          formattedPhoneNumber={formatPhoneNumber(subscriptionUserFriendlyId)}
          search={search}
        />
      )}
    </span>
  );
  if (pbxType && corporateNumber) {
    const corporateNumberElement = (
      <HighlightFormattedPhoneNumber formattedPhoneNumber={formatPhoneNumber(corporateNumber, true)} search={search} />
    );
    return (
      <>
        {phoneNumber}
        <span key="corporateNumber" className={dsClass.DISPLAY_BLOCK}>
          {pbxType === SubscriptionPbxType.VAKIO ? (
            <small>
              {highlightKeyword('Vakio', search)}: {corporateNumberElement}
            </small>
          ) : (
            <small>
              {highlightKeyword('Ring', search)}: {corporateNumberElement}
            </small>
          )}
        </span>
      </>
    );
  }
  return phoneNumber;
};

const getVoiceRows = (
  searchResults: SubscriptionSearchResponse[] = [],
  path: string,
  search?: string,
  authenticatedUser?: AuthenticatedUserState,
  onRowClick?: MultiAccountRowClickFunction
) => {
  return searchResults.map(({ result: subscriptionHeader, matchedFields }) => ({
    subscriptionName: (
      <SubscriptionLink subscription={subscriptionHeader} path={path} search={search} onRowClick={onRowClick}>
        <SubscriptionHiddenFields
          subscriptionHeader={subscriptionHeader}
          search={search}
          matchedFields={matchedFields}
        />
      </SubscriptionLink>
    ),
    purposeOfUseOrContactName: highlightKeyword(
      subscriptionHeader.subscriptionPurposeOfUse || subscriptionHeader.subscriptionContactName || '',
      search
    ),
    subscriptionUserFriendlyId: <PhoneNumberDetails subscriptionHeader={subscriptionHeader} search={search} />,
    billingAccountDisplayId: matchedFields?.includes('billingAccountDisplayId')
      ? highlightKeyword(subscriptionHeader.billingAccountDisplayId || '', search)
      : subscriptionHeader.billingAccountDisplayId || '',
    contractValidity: <ContractValiditySummary subscriptionHeader={subscriptionHeader} />,
    company: getCompanyName(authenticatedUser, subscriptionHeader.accountMasterId),
  }));
};

export const SUBSCRIPTION_TYPE_SEARCH_FILTER_PARAM = 'subscriptionType';

export interface SubscriptionsVoiceFilter {
  subscriptionType?: string;
  mdmId?: string;
}

export type SubscriptionVoiceSearchParams = DefaultListSearchParams & SubscriptionsVoiceFilter;

export const SubscriptionsVoice = () => {
  const [filtersOpen, setFiltersOpen] = useState(false);
  const { authenticatedUser } = useAuth();
  const [showSelectNewSubscriptionDialog, setShowSelectNewSubscriptionDialog] = useState(false);
  const { subscriptions, aggregations } = useLoaderData() as SubscriptionsLoaderData;
  const onRowClick = useMultiAccountRowClick();
  const searchParams = useSearchParams<SubscriptionVoiceSearchParams>();
  const { mdmId, search, subscriptionType } = searchParams;

  const voiceColumns = [
    {
      key: 'subscriptionName',
      label: t.P674(subscriptionMsg),
      sortable: true,
    },
    { key: 'purposeOfUseOrContactName', label: t.L9QG(identifierMsg), sortable: true },
    { key: 'subscriptionUserFriendlyId', label: t.CCB1(numberMsg), sortable: true },
    { key: 'billingAccountDisplayId', label: t.IFT9(billingAccountMsg), sortable: true },
    { key: 'contractValidity', label: t.C8I6(contractMsg), sortable: false },
    { key: 'company', label: t.KJTS(companyMsg), sortable: false },
  ];

  const searchFilterGroups: SearchFilterGroup[] = [
    getVoiceSubscriptionTypeFilterGroup(subscriptionType, aggregations.voiceAggregations?.subscriptionType),
  ].filter((filterGroup?: SearchFilterGroup): filterGroup is SearchFilterGroup => filterGroup !== undefined);

  return (
    <div className="of-subscription-table">
      {showSelectNewSubscriptionDialog && (
        <SelectNewSubscriptionDialog
          onCloseDialog={() => setShowSelectNewSubscriptionDialog(false)}
          hideBroadband={true}
          mdmId={mdmId || getPrimaryMdmId(authenticatedUser)}
        />
      )}
      <TableTopActions
        buttons={[
          {
            label: t.AQOL(orderMsg),
            onClick: () => setShowSelectNewSubscriptionDialog(true),
          },
        ]}
      />
      <ConsolidatedListView
        authenticatedUser={authenticatedUser}
        listElement={
          <Table
            columns={voiceColumns}
            isLoading={false}
            noItemsText={t.IL9T('No phone subscriptions')}
            rows={getVoiceRows(
              subscriptions?.searchResults,
              paths.PS_MOBILE_SUBSCRIPTION,
              search,
              authenticatedUser,
              onRowClick
            )}
          />
        }
        listSearch={
          <ListSearch
            filtersOpen={filtersOpen}
            setFiltersOpen={setFiltersOpen}
            onSearch={() => setDefaultItemsPerPage(new URLSearchParams(searchParams), DEFAULT_ITEMS_PER_PAGE)}
          />
        }
        searchFilters={
          <SearchFilters
            displayActiveFiltersAsTiles={!filtersOpen}
            filterGroups={searchFilterGroups}
            onModalClose={() => setFiltersOpen(false)}
          />
        }
        listPagination={<ListPagination totalItems={subscriptions?.total || 0} />}
        toggleElement={<ShowAllAccountsToggleForListViews className={dsClass.MARGIN_BOTTOM_4} />}
      />
    </div>
  );
};
