import { MoreResults } from './MoreResults.js';
import { TooMuchResults } from './SearchTip.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { useState } from 'react';

export interface SearchResultTypeResultProps {
  name: string;
  total: number;
  entities: JSX.Element[];
  isMultiBiz: boolean;
  seeAllLinkHref: string;
  setSearchFieldVisible: (visible: boolean) => void;
}

const DEFAULT_RESULTS_VISIBLE = 5;
const MAX_RESULTS = 20;

export const SearchResultTypeResult = ({ name, total, entities, ...rest }: SearchResultTypeResultProps) => {
  const [displayedItemsCount, setDisplayedItemsCount] = useState(DEFAULT_RESULTS_VISIBLE);
  return (
    <div className="of-omaelisa-search-result-type-container">
      <div className="of-omaelisa-search-result-type-container__total">
        <span className={`${dsClass.TEXT_XS} ${dsClass.TEXT_TRANSFORM_UPPERCASE}`}>
          {name} ({total})
        </span>
      </div>
      {entities.slice(0, displayedItemsCount)}
      {total > displayedItemsCount && displayedItemsCount < MAX_RESULTS && (
        <MoreResults
          displayShowMoreButton={true}
          onShowMoreClick={() => setDisplayedItemsCount(displayedItemsCount + DEFAULT_RESULTS_VISIBLE)}
          {...rest}
        />
      )}
      {total > MAX_RESULTS && displayedItemsCount === MAX_RESULTS && (
        <>
          <TooMuchResults {...rest} />
          <MoreResults {...rest} />
        </>
      )}
    </div>
  );
};
