import * as CL from '@design-system/component-library';
import { GridColumn } from '../BillingAccountDetails/components/GridColumn.js';
import { InfoFieldWithTooltip } from '../BillingAccountDetails/components/InfoFieldWithTooltip.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { referenceMsg, t } from '../../common/i18n/index.js';

import './BillingAccountReferenceNumbers.scss';

export interface BillingAccountReferenceNumbersProps {
  customerReferences: {
    customerReference1: string;
    customerReference2: string;
    customerReference3: string;
    customerReference4: string;
  };
  highlight?: {
    highlight1?: boolean;
    highlight2?: boolean;
    highlight3?: boolean;
    highlight4?: boolean;
  };
}

const getReadValue = (value?: string) => value || '-';

const ViewField = ({ i, value, highlight }: { i: number; value?: string; highlight?: boolean }) => (
  <GridColumn className="of-billing-account-details form-column view-field">
    <InfoFieldWithTooltip
      label={`${t.ZLAU(referenceMsg)} ${i}`}
      value={getReadValue(value)}
      highlight={highlight}
      name="billLanguage"
    />
  </GridColumn>
);

const ViewContent = ({ customerReferences, highlight }: BillingAccountReferenceNumbersProps) => {
  if (Object.keys(customerReferences).length === 0) {
    return <span>{t.CD11('No invoice reference information')}</span>;
  }
  return (
    <CL.Grid className={dsClass.PADDING_0}>
      <CL.GridRow>
        <ViewField i={1} value={customerReferences.customerReference1} highlight={highlight?.highlight1} />
        <ViewField i={2} value={customerReferences.customerReference2} highlight={highlight?.highlight2} />
      </CL.GridRow>
      <CL.GridRow>
        <ViewField i={3} value={customerReferences.customerReference3} highlight={highlight?.highlight3} />
        <ViewField i={4} value={customerReferences.customerReference4} highlight={highlight?.highlight4} />
      </CL.GridRow>
    </CL.Grid>
  );
};

export const BillingAccountReferenceNumbersView = (props: BillingAccountReferenceNumbersProps) => {
  return (
    <div className="of-billing-account-reference-numbers">
      <ViewContent customerReferences={props.customerReferences} highlight={props.highlight} />
    </div>
  );
};
