import { Grid } from '../Grid/Grid.js';
import { InvoiceDueSmsReminderSetting, anyCompanyHasSmsReminderSettingVisible } from './InvoiceDueSmsReminderSetting';
import { LinkableAccordion } from '../LinkableAccordion/index.js';
import { MfaSetting } from '../MFA/MfaSetting.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { t } from '../../common/i18n/index.js';
import type { CompanyInfoLoaderData } from '../../common/loaders';
import type { MfaDetailsResponse } from '../../generated/api/mfaDetailsResponse.js';

import './CompanySettings.scss';

export interface CompanySettingsProps {
  companyInfos?: CompanyInfoLoaderData[];
  mfaDetails: MfaDetailsResponse;
}

export const CompanySettings = ({ companyInfos, mfaDetails }: CompanySettingsProps) => {
  return (
    <div className={dsClass.MARGIN_TOP_6}>
      <Grid>
        <h3 className={dsClass.DESCRIPTION_TITLE}>{t.QI2P('Settings')}</h3>
        <LinkableAccordion heading={t.QQFY('Two-factor authentication')} id="mfa-setting" defaultOpen={true}>
          <MfaSetting mfaDetails={mfaDetails} />
        </LinkableAccordion>
        {companyInfos && anyCompanyHasSmsReminderSettingVisible(companyInfos) && (
          <LinkableAccordion
            heading={t.T4MT('Block invoice due SMS-reminder')}
            id="block-invoice-due-reminder-setting"
            defaultOpen={true}
          >
            <InvoiceDueSmsReminderSetting companyInfos={companyInfos} />
          </LinkableAccordion>
        )}
      </Grid>
    </div>
  );
};
