import { TypeKeys, generateActionId } from './actionsUtils.js';
import { convertToCommonErrors, convertToCommonErrorsWithMessage } from '../../common/utils/errorUtils.js';
import type { Action } from 'redux';
import type {
  AddOn,
  Address,
  BillingAccount,
  CompanyInfoResponse,
  Contact,
  ContactPerson,
  CreditCheckResponse,
  DeliveryAddress,
  DeliveryType,
  FinalizeOnlineOrderCardPaymentResponse,
  GenericError,
  InitializePaymentResponse,
  OnlineOrder,
} from '../../generated/api/models.js';
import type { AjaxResponse } from 'rxjs/ajax';
import type { CommonError } from '../../common/types/errors.js';
import type { ConfiguredOffer } from '../../common/types/commercialProduct.js';
import type { CreditDecision, WizardType } from '../../common/enums.js';
import type { CrudAction, ErrorAction, ErrorActionCreator } from './actionsUtils.js';
import type { DeviceChangeOrRedeemRequest, DeviceChangeRequest } from '../../common/types/device.js';
import type { DeviceCheckoutDeliveryDetailsType } from '../../components/DeviceCheckoutDeliveryDetails/DeviceCheckoutDeliveryDetailsUtils.js';

export interface SubmitOnlineOrderAction extends CrudAction<TypeKeys.SUBMIT_ONLINE_ORDER> {
  addOns?: AddOn[];
  approverContact?: string;
  approverContactValidationErrors?: CommonError[];
  contactPerson?: ContactPerson;
  contactPersonValidationErrors?: CommonError[];
  customerType?: CompanyInfoResponse.CustomerTypeEnum;
  deliveryAddress: DeliveryAddress;
  isCompanyNameRequired?: boolean;
  deliveryAddressValidationErrors?: CommonError[];
  personAccountValidationErrors?: CommonError[];
  deliveryMethodType?: DeliveryType;
  billingAccountId?: string;
  newBillingAccount?: BillingAccount;
  newBillingAccountValidationErrors?: CommonError[];
  newBillingAccountCommonFunction?: Contact;
  items: ConfiguredOffer[];
  overridePreviousAction?: boolean;
  personBillingAccountId?: string;
  personBillingAddress?: Address;
  deviceChangeRequest?: DeviceChangeOrRedeemRequest;
  shipmentType?: OnlineOrder.ShipmentTypeEnum;
  personBillingEmail?: string;
  wizardType?: WizardType;
  apiVersion?: string;
  attachRingRedirect?: string;
}

export const submitOnlineOrder = (
  orderItems: ConfiguredOffer[],
  deliveryAddress: DeliveryAddress,
  contactPerson?: ContactPerson,
  contactPersonValidationErrors?: CommonError[],
  customerType?: CompanyInfoResponse.CustomerTypeEnum,
  isCompanyNameRequired?: boolean,
  deliveryAddressValidationErrors?: CommonError[],
  personAccountValidationErrors?: CommonError[],
  billingAccountId?: string,
  newBillingAccount?: BillingAccount,
  newBillingAccountValidationErrors?: CommonError[],
  addOns?: AddOn[],
  overridePreviousAction?: boolean,
  deliveryMethodType?: DeliveryType,
  approverContact?: string,
  approverContactValidationErrors?: CommonError[],
  personBillingAccountId?: string,
  personBillingEmail?: string,
  personBillingAddress?: Address,
  deviceChangeRequest?: DeviceChangeRequest,
  shipmentType?: OnlineOrder.ShipmentTypeEnum,
  apiVersion?: string,
  attachRingRedirect?: string
): SubmitOnlineOrderAction => ({
  addOns,
  approverContact,
  approverContactValidationErrors,
  billingAccountId,
  deliveryAddress,
  contactPerson,
  contactPersonValidationErrors,
  customerType,
  isCompanyNameRequired,
  deliveryAddressValidationErrors,
  personAccountValidationErrors,
  deliveryMethodType,
  id: generateActionId(),
  items: orderItems,
  newBillingAccount,
  newBillingAccountValidationErrors,
  overridePreviousAction,
  personBillingAccountId,
  personBillingEmail,
  personBillingAddress,
  deviceChangeRequest,
  shipmentType,
  type: TypeKeys.SUBMIT_ONLINE_ORDER,
  apiVersion,
  attachRingRedirect,
});

export interface SubmitOrderFulfilledAction extends Action {
  orderId: string;
  type: TypeKeys.SUBMIT_ONLINE_ORDER_FULFILLED;
}

export const submitOnlineOrderFulfilled = (response: AjaxResponse): SubmitOrderFulfilledAction => {
  return {
    orderId: response.response.onlineId ? response.response.onlineId : response.response.onlineRequestId, // V2 stack of online-order, returns onlineRequestId in response.
    type: TypeKeys.SUBMIT_ONLINE_ORDER_FULFILLED,
  };
};

export type SubmitOrderFailedAction = ErrorAction<TypeKeys.SUBMIT_ONLINE_ORDER_FAILED>;

export const submitOnlineOrderFailed: ErrorActionCreator<TypeKeys.SUBMIT_ONLINE_ORDER_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[]
): SubmitOrderFailedAction => {
  const commonErrors = convertToCommonErrorsWithMessage(message, status, errors);
  return {
    errors: commonErrors,
    type: TypeKeys.SUBMIT_ONLINE_ORDER_FAILED,
  };
};

export interface PollForCreditCheckAction extends CrudAction<TypeKeys.POLL_FOR_CREDIT_CHECK> {
  orderId: string;
}

export const pollForCreditCheck = (orderId: string): PollForCreditCheckAction => ({
  id: generateActionId(),
  orderId: orderId,
  type: TypeKeys.POLL_FOR_CREDIT_CHECK,
});

export interface PollForCreditCheckFulfilledAction extends Action {
  creditDecision: CreditDecision;
  type: TypeKeys.POLL_FOR_CREDIT_CHECK_FULFILLED;
}

export interface PollForCreditCheckV2FulfilledAction extends Action {
  onlineOrderId: string;
  response: CreditCheckResponse;
  type: TypeKeys.POLL_FOR_CREDIT_CHECK_V2_FULFILLED;
}

export const pollForCreditCheckV2Fulfilled = (
  response: CreditCheckResponse,
  onlineOrderId: string
): PollForCreditCheckV2FulfilledAction => ({
  onlineOrderId,
  response,
  type: TypeKeys.POLL_FOR_CREDIT_CHECK_V2_FULFILLED,
});

export type PollForCreditCheckFailedAction = ErrorAction<TypeKeys.POLL_FOR_CREDIT_CHECK_FAILED>;

export const pollForCreditCheckFailed: ErrorActionCreator<TypeKeys.POLL_FOR_CREDIT_CHECK_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[]
): PollForCreditCheckFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    type: TypeKeys.POLL_FOR_CREDIT_CHECK_FAILED,
  };
};

// Online order card payment actions

export interface InitializeOnlineOrderCardPaymentAction
  extends CrudAction<TypeKeys.INITIALIZE_ONLINE_ORDER_CARD_PAYMENT> {
  orderItems: ConfiguredOffer[];
  deliveryAddress: DeliveryAddress;
  deliveryMethodType?: DeliveryType;
  overridePreviousAction?: boolean;
}

export const initializeOnlineOrderCardPayment = (
  orderItems: ConfiguredOffer[],
  deliveryAddress: DeliveryAddress,
  deliveryMethodType?: DeliveryType,
  overridePreviousAction?: boolean
): InitializeOnlineOrderCardPaymentAction => {
  return {
    deliveryAddress,
    deliveryMethodType,
    id: generateActionId(),
    orderItems,
    overridePreviousAction,
    type: TypeKeys.INITIALIZE_ONLINE_ORDER_CARD_PAYMENT,
  };
};

export type InitializeOnlineOrderCardPaymentFailedAction =
  ErrorAction<TypeKeys.INITIALIZE_ONLINE_ORDER_CARD_PAYMENT_FAILED>;

export const initializeOnlineOrderCardPaymentFailed: ErrorActionCreator<
  TypeKeys.INITIALIZE_ONLINE_ORDER_CARD_PAYMENT_FAILED
> = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): InitializeOnlineOrderCardPaymentFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    params,
    type: TypeKeys.INITIALIZE_ONLINE_ORDER_CARD_PAYMENT_FAILED,
  };
};

export type InitializeOnlineOrderCardPaymentFulfilledAction = {
  forwardUrl: string;
  transactionId: string;
  type: TypeKeys.INITIALIZE_ONLINE_ORDER_CARD_PAYMENT_FULFILLED;
};

export const initializeOnlineOrderCardPaymentFulfilled = ({
  forwardUrl,
  transactionId,
}: InitializePaymentResponse): InitializeOnlineOrderCardPaymentFulfilledAction => ({
  forwardUrl,
  transactionId,
  type: TypeKeys.INITIALIZE_ONLINE_ORDER_CARD_PAYMENT_FULFILLED,
});

export interface FinalizeOnlineOrderCardPaymentAction extends CrudAction<TypeKeys.FINALIZE_ONLINE_ORDER_CARD_PAYMENT> {
  responseStatusText: string;
  transactionId: string;
}

export const finalizeOnlineOrderCardPayment = (
  responseStatusText: string,
  transactionId: string
): FinalizeOnlineOrderCardPaymentAction => {
  return {
    id: generateActionId(),
    responseStatusText,
    transactionId,
    type: TypeKeys.FINALIZE_ONLINE_ORDER_CARD_PAYMENT,
  };
};

export type FinalizeOnlineOrderCardPaymentFailedAction =
  ErrorAction<TypeKeys.FINALIZE_ONLINE_ORDER_CARD_PAYMENT_FAILED>;

export const finalizeOnlineOrderCardPaymentFailed: ErrorActionCreator<
  TypeKeys.FINALIZE_ONLINE_ORDER_CARD_PAYMENT_FAILED
> = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): FinalizeOnlineOrderCardPaymentFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    params,
    type: TypeKeys.FINALIZE_ONLINE_ORDER_CARD_PAYMENT_FAILED,
  };
};

export type FinalizeOnlineOrderCardPaymentFulfilledAction = {
  response: FinalizeOnlineOrderCardPaymentResponse;
  type: TypeKeys.FINALIZE_ONLINE_ORDER_CARD_PAYMENT_FULFILLED;
};

export const finalizeOnlineOrderCardPaymentFulfilled = (
  response: FinalizeOnlineOrderCardPaymentResponse
): FinalizeOnlineOrderCardPaymentFulfilledAction => {
  return {
    response,
    type: TypeKeys.FINALIZE_ONLINE_ORDER_CARD_PAYMENT_FULFILLED,
  };
};

export type SetDeliveryDetailsForOnlineOrderCardPayment = {
  deliveryDetails: DeviceCheckoutDeliveryDetailsType;
  type: TypeKeys.SET_DELIVERY_DETAILS_FOR_ONLINE_ORDER_CARD_PAYMENT;
};

export const setDeliveryDetailsForOnlineOrderCardPayment = (
  deliveryDetails: DeviceCheckoutDeliveryDetailsType
): SetDeliveryDetailsForOnlineOrderCardPayment => {
  return {
    deliveryDetails,
    type: TypeKeys.SET_DELIVERY_DETAILS_FOR_ONLINE_ORDER_CARD_PAYMENT,
  };
};

export type OnlineOrderActionTypes =
  | PollForCreditCheckAction
  | PollForCreditCheckFailedAction
  | PollForCreditCheckFulfilledAction
  | SubmitOnlineOrderAction
  | SubmitOrderFulfilledAction
  | SubmitOrderFailedAction
  | PollForCreditCheckV2FulfilledAction
  | InitializeOnlineOrderCardPaymentAction
  | InitializeOnlineOrderCardPaymentFailedAction
  | InitializeOnlineOrderCardPaymentFulfilledAction
  | FinalizeOnlineOrderCardPaymentAction
  | FinalizeOnlineOrderCardPaymentFailedAction
  | FinalizeOnlineOrderCardPaymentFulfilledAction
  | SetDeliveryDetailsForOnlineOrderCardPayment;
