import { dsClass } from '../../common/constants/dsClasses.js';

import './IconAndText.scss';

interface IconAndTextProps {
  text: string;
  icon: string;
  id?: string;
}

export const IconAndText = ({ text, icon, id }: IconAndTextProps) => (
  <div className={`ea-icon-and-text ${dsClass.PADDING_VERTICAL_4} ${dsClass.PADDING_HORIZONTAL_3}`} id={id}>
    <div className={'ea-icon-and-text__icon ea-icon-and-text__icon--' + icon} />
    <div className="ea-icon-and-text__text">{text}</div>
  </div>
);
