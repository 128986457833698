import { EmployeeSubscriptionsList } from '../EmployeeSubscriptionsList/EmployeeSubscriptionsList.js';
import { HeroHeading, HeroHeadingType } from '../HeroHeading/index.js';
import { LocalNavi } from '../LocalNavi/index.js';
import { MissingSubscriptionInfo } from './MissingSubscriptionInfo.js';
import { SubscriptionCategory } from '../../common/enums.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { devicesMsg, omaElisaForEmployeeMsg, t } from '../../common/i18n/index.js';
import { generatePath, useNavigate } from 'react-router-dom';
import { getSubscriptionsDisplayCategories } from '../EmployeeSubscriptionDetails/employeeSubscriptionUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useSelector } from 'react-redux';
import { useTitle } from '../../common/hooks/useTitle.js';
import type { State } from '../../selfservice/common/store.js';

import './EmployeeSubscriptions.scss';

export const EmployeeDeviceSubscriptions = () => {
  const navigate = useNavigate();
  const { authenticatedUser } = useAuth();
  const deviceSubs = useSelector((state: State) => state.user?.authenticated?.subscriptions?.device, deepEqual);
  const selfService = useSelector((state: State) => state.selfservice, deepEqual);
  const imagesBaseUrl = useSelector((state: State) => state.config?.imagesBaseUrl);
  const isCatalogPresent = (selfService?.virtualCatalogs?.items?.filter(catalog => catalog.published) ?? []).length > 0;
  const subscriptionActions = selfService?.pendingSubscriptionActions?.items || [];
  const showExpiringEppSubscriptionHint = !!authenticatedUser?.expiringEppSubscriptions?.length;

  useTitle([t.H6JV(omaElisaForEmployeeMsg), t.JJTM(devicesMsg)]);

  return (
    <div className="of-employee-subscriptions">
      <HeroHeading
        center={true}
        fullScreen={true}
        heroHeadingType={HeroHeadingType.EMPLOYEE_DEVICES}
        title={t.NP53('My devices')}
      />
      <LocalNavi categories={getSubscriptionsDisplayCategories(showExpiringEppSubscriptionHint)} />
      <EmployeeSubscriptionsList
        items={deviceSubs}
        category={SubscriptionCategory.DEVICE}
        isCatalogPresent={isCatalogPresent}
        isUserProvisioned={authenticatedUser?.businessId !== undefined}
        imagesBaseUrl={imagesBaseUrl}
        subscriptionActions={subscriptionActions}
        onClickEmployeeSubscription={(subscriptionId: string) =>
          navigate(generatePath(paths.EMPLOYEE_DEVICE, { subscriptionId }))
        }
      />
      <MissingSubscriptionInfo
        onClickEmployeeDetails={() => navigate(paths.EMPLOYEE_OWN_INFO)}
        subscriptionCategory={SubscriptionCategory.DEVICE}
      />
    </div>
  );
};
