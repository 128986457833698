import { CommonErrorType } from '../../../../../common/enums.js';
import { DnsRecordList } from '../../../../../components/DnsManagement/DnsRecordManagement/DnsRecordList/DnsRecordList.js';
import { DnsRecordManagement } from '../../../../../components/DnsManagement/DnsRecordManagement/DnsRecordManagement.js';
import { SystemError } from '../../../../../components/SystemError/SystemError.js';
import { TypeKeys } from '../../../../../selfservice/actions/index.js';
import { createDnsRecord, deleteDnsRecord, updateDnsRecord } from '../../../../../common/fetch.js';
import { pushDomainRecordEventToDataLayer } from '../../../../../common/analytics.js';
import { useDispatch } from 'react-redux';
import { useLoaderData, useParams, useRevalidator } from 'react-router-dom';
import { useState } from 'react';
import type { DnsRecordRequest } from '../../../../../generated/api/dnsRecordRequest.js';
import type { DnsRecordsResponse } from '../../../../../generated/api/dnsRecordsResponse.js';
import type { DomainRecordEvent } from '../../../../../common/analytics.js';
import type { SubscriptionId } from '../../../../../common/constants/pathInterfaces.js';

export const DnsRecordsPath = () => {
  const { subscriptionId } = useParams<SubscriptionId>();
  const recordsData = useLoaderData() as DnsRecordsResponse;
  const revalidator = useRevalidator();
  const dispatch = useDispatch();
  const [processingSubmit, setProcessingSubmit] = useState(false);

  if (!subscriptionId) {
    return <SystemError errors={[{ type: CommonErrorType.NOT_FOUND, message: 'subscriptionId missing' }]} />;
  }

  const onSubmit = async (
    actionEvent: DomainRecordEvent,
    submitFn: () => Promise<Response>,
    successType: TypeKeys,
    failureType: TypeKeys
  ) => {
    setProcessingSubmit(true);
    try {
      await submitFn();
      dispatch({ type: successType });
      pushDomainRecordEventToDataLayer(actionEvent);
    } catch {
      dispatch({ type: failureType });
    } finally {
      setProcessingSubmit(false);
      revalidator.revalidate();
    }
  };

  const onCreate = async (payload: DnsRecordRequest) =>
    onSubmit(
      'add',
      () => createDnsRecord(subscriptionId, payload),
      TypeKeys.ADD_DNS_RECORD_FULFILLED,
      TypeKeys.ADD_DNS_RECORD_FAILED
    );

  const onUpdate = async (recordId: number, payload: DnsRecordRequest) =>
    onSubmit(
      'modify',
      () => updateDnsRecord(subscriptionId, recordId, payload),
      TypeKeys.UPDATE_DNS_RECORD_FULFILLED,
      TypeKeys.UPDATE_DNS_RECORD_FAILED
    );

  const onDelete = async (recordId: number) =>
    onSubmit(
      'remove',
      () => deleteDnsRecord(subscriptionId, recordId),
      TypeKeys.DELETE_DNS_RECORD_FULFILLED,
      TypeKeys.DELETE_DNS_RECORD_FAILED
    );

  return (
    <DnsRecordManagement subscriptionId={subscriptionId} domainName={recordsData.domainName} spinner={processingSubmit}>
      <DnsRecordList recordsData={recordsData} onCreate={onCreate} onUpdate={onUpdate} onDelete={onDelete} />
    </DnsRecordManagement>
  );
};
