import { dsClass } from '../../common/constants/dsClasses.js';
import { formatNumberToCurrency, formatSum } from '../../common/utils/priceUtils.js';
import { monthlyFeeOfSubAfterChangesMsg, t } from '../../common/i18n/index.js';
import type { AddedAddon } from '../../common/types/addon.js';

import './ChangeOfferConfirmationDialogContent.scss';

export interface ChangeOfferConfirmationDialogContentProps {
  addOnsToRemoveDisplayed: AddedAddon[];
  newProductMonthlyRecurringCharge: number;
}

export const ChangeOfferConfirmationDialogContent = (props: ChangeOfferConfirmationDialogContentProps) => {
  const { addOnsToRemoveDisplayed, newProductMonthlyRecurringCharge } = props;

  return (
    <div className={`of-change-offer-dialog ${dsClass.MARGIN_TOP_6}`}>
      {addOnsToRemoveDisplayed.map(addOn => (
        <div key={addOn.addOnCode} className="row">
          <div className="column">{addOn.displayName}</div>
          <div className={`column ${dsClass.FONT_WEIGHT_BOLD}`}>
            {addOn.monthlyPrice && formatNumberToCurrency(addOn.monthlyPrice) + ' € /' + t.XXVX('month')}
            {addOn.oneTimePrice && formatNumberToCurrency(addOn.oneTimePrice) + ' €'}
          </div>
        </div>
      ))}
      <div className="row disclaimer">
        {t.CL9O(monthlyFeeOfSubAfterChangesMsg, formatSum(newProductMonthlyRecurringCharge)!)}
      </div>
    </div>
  );
};
