import * as CL from '@design-system/component-library';
import { DialogType } from '../../../common/enums';
import { LinkableAccordion } from '../../../components/LinkableAccordion';
import { editMsg, t } from '../../../common/i18n';
import type { DialogParams } from '../../../common/types/dialog';
import type { Subscription } from '../../../generated/api/subscription';

export interface RingUserAccordionProps {
  subscription: Subscription;
  subscriptionPendingStatus: boolean;
  onShowDialog: (params: DialogParams) => void;
}

const getRingUserAccordionContent = (
  subscription: Subscription,
  subscriptionPendingStatus: boolean,
  onShowDialog: (params: DialogParams) => void
) => {
  return (
    <div>
      <CL.Description
        items={[
          {
            title: t.U4MA('User'),
            description: subscription.subscriptionPurposeOfUse,
          },
        ]}
      />
      <div>
        <CL.Button
          size="l"
          color="light"
          id="ea-composite-field-grid__edit-button"
          onClick={() =>
            subscriptionPendingStatus
              ? onShowDialog({ type: DialogType.SUBSCRIPTION_ACTION_PENDING })
              : onShowDialog({
                  subscriptionId: subscription.subscriptionId,
                  type: DialogType.RING_SUBSCRIPTION_UPDATE_USER,
                })
          }
        >
          {t.NVPK(editMsg)}
        </CL.Button>
      </div>
    </div>
  );
};

export const RingUserAccordion = ({
  subscription,
  subscriptionPendingStatus,
  onShowDialog,
}: RingUserAccordionProps) => (
  <LinkableAccordion heading={t.U4MA('User')} headingLevel="h3" id="ring-subscription-user">
    {getRingUserAccordionContent(subscription, subscriptionPendingStatus, onShowDialog)}
  </LinkableAccordion>
);
