import { DialogWrapper } from '../DialogWrapper/index.js';
import { SelectOneList } from '../SelectOneList/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import type { SelectOneListProps } from '../SelectOneList/index.js';

export interface SelectOneDialogProps extends SelectOneListProps {
  children?: JSX.Element;
  header: string;
  onCloseDialog: () => void;
}

export const SelectOneDialog = ({
  children,
  header,
  maxItemsAsideDesktop,
  maxItemsAsideLaptop,
  maxItemsAsideTablet,
  onCloseDialog,
  options,
}: SelectOneDialogProps) => (
  <DialogWrapper closeable header={header} onCloseDialog={onCloseDialog} size="l">
    <div className={dsClass.PADDING_TOP_4}>
      <SelectOneList {...{ options, maxItemsAsideTablet, maxItemsAsideLaptop, maxItemsAsideDesktop }} />
    </div>
    {children}
  </DialogWrapper>
);
