import { AddOnVisibilityOperationType } from '../../common/enums.js';
import { AdditionalServicesCheckoutContent } from '../OrderSubscriptionConfiguration/AdditionalServicesCheckoutContent.js';
import { AuthenticatedUserRole, CommercialProductSubType } from '../../generated/api/models.js';
import { CampaignSelectTile } from './CampaignSelectTile.js';
import { FixedBroadbandInstallationService } from './FixedBroadbandInstallationService.js';
import { FixedBroadbandYritysWifiAddOn } from './FixedBroadbandYritysWifiAddOn.js';
import { FormProvider, useForm } from 'react-hook-form';
import { OrderSummary, OrderSummaryType } from '../OrderSummary/OrderSummary.js';
import { SELECTED_COMMERCIAL_PRODUCT_INDEX } from '../../common/constants/commonConstants.js';
import { WizardActions } from '../WizardActions/index.js';
import { YritystuplaSelection } from './YritysTuplaSelection.js';
import { clearCampaigns, googleEcommerceAddToCart } from '../../selfservice/actions/index.js';
import {
  createAddOnAssociationRecordArray,
  createAddOnDependencyRecordArray,
} from '../../common/utils/addOnRulesUtils.js';
import { createNettiliteEcommerceItemsFromConfiguredOffer } from '../../selfservice/common/googleEcommerceEvent.js';
import {
  defaultFixedBroadbandProductContent,
  fixedBroadbandProductContent,
} from '../OrderSubscriptionSelection/content/NettiLiteOfferSpecification.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import {
  findComboInstallationServiceAddOn,
  findOnlineInstallationService,
  findYritysWifiDeliveryFeeAddOn,
  findYritysWifiInstallationServiceAddOn,
  findYritysWifiService,
} from './fixedBroadbandAddOnUtils.js';
import { getAddedAddOns, getGFastDevicePackageAddon } from '../FixedBroadbandCheckout/FixedBroadbandUtils.js';
import { isIpAddressGroupAddOn } from '../../public/site/path/FixedBroadbandOrder/fixedBroadbandAddOnUtil.js';
import { t } from '../../common/i18n/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import type {
  AddOnVisibility,
  CampaignAssociation,
  CampaignContext,
  Offer,
  OnlineModel,
} from '../../generated/api/models.js';
import type { AddedAddon } from '../../common/types/addon.js';
import type { CampaignSelectOption } from './CampaignSelectTile.js';
import type { CommercialProductAddOnVisibilitiesMap } from '../../common/utils/addOnVisibilityUtils.js';
import type { ConfiguredOffer } from '../../common/types/commercialProduct.js';
import type { Dispatch, SetStateAction } from 'react';
import type { FixedBBCheckoutLoaderData } from '../../common/loaders.js';
import type { FixedBroadbandInstallationAddOnOptions } from './FixedBroadbandInstallationService.js';
import type {
  InstallationServiceAddOnSelection,
  YritystuplaOfferSelection,
} from '../FixedBroadbandCheckout/FixedBroadbandCheckout.js';
import type { IpAddressAddOnSelection } from './FixedBroadbandIpAddOn.js';
import type { OrderConfiguration } from '../OrderSubscriptionConfiguration/FormWrapper.js';
import type { YritysWifiSelection } from './FixedBroadbandYritysWifiAddOn.js';

import './FixedBroadbandProductSelection.scss';

export interface FixedBroadbandProductSelectionProps {
  eCommerceProductDetailEvent: () => void;
  onNext: () => void;
  selectedOffer: Offer;
  yritystuplaModel?: OnlineModel;
  yritysWifiSelection: YritysWifiSelection;
  setYritysWifiSelection: (yritysWifiSelection: YritysWifiSelection) => void;
  configuredOffer: ConfiguredOffer;
  selectedCampaign?: CampaignAssociation;
  campaignVoucher?: string;
  campaignContextsFromVoucher: CampaignContext[];
  campaignOptions: CampaignSelectOption[];
  installationServiceSelection: InstallationServiceAddOnSelection;
  setInstallationServiceSelection: Dispatch<SetStateAction<InstallationServiceAddOnSelection>>;
  ipAddressAddOnSelection: IpAddressAddOnSelection;
  setIpAddressAddOnSelection: Dispatch<SetStateAction<IpAddressAddOnSelection>>;
  setGFastDeviceIncluded: Dispatch<SetStateAction<boolean>>;
  yritystuplaOffer: YritystuplaOfferSelection;
  setYritystuplaOffer: Dispatch<SetStateAction<YritystuplaOfferSelection>>;
  setCampaingAutoSelect: Dispatch<SetStateAction<boolean>>;
  commercialProductAddOnVisibilities?: CommercialProductAddOnVisibilitiesMap;
  onAddOnSelectionChange: (addedAddOns: AddedAddon[]) => void;
  publicAddOnVisibilities?: AddOnVisibility[];
  fixedBBCheckoutLoaderData: FixedBBCheckoutLoaderData;
}

const gFastDevicePackageAddon = getGFastDevicePackageAddon();

const createFixedBroadbandInstallationAddOnOptions = (
  selectedCommercialProductCode?: string,
  commercialProductAddOnVisibilities?: CommercialProductAddOnVisibilitiesMap
): FixedBroadbandInstallationAddOnOptions => ({
  nettiLiteInstallationServiceAddOn: getAddedAddOns([
    findOnlineInstallationService(selectedCommercialProductCode, commercialProductAddOnVisibilities),
  ])?.[0],
  yritysWifiInstallationServiceAddOn: getAddedAddOns([
    findYritysWifiInstallationServiceAddOn(selectedCommercialProductCode, commercialProductAddOnVisibilities),
  ])?.[0],
  comboInstallationServiceAddOn: getAddedAddOns([
    findComboInstallationServiceAddOn(selectedCommercialProductCode, commercialProductAddOnVisibilities),
  ])?.[0],
});

export const FixedBroadbandProductSelection = ({
  eCommerceProductDetailEvent,
  onNext,
  selectedOffer,
  yritysWifiSelection,
  setYritysWifiSelection,
  configuredOffer,
  yritystuplaModel,
  selectedCampaign,
  campaignVoucher,
  campaignContextsFromVoucher,
  campaignOptions,
  installationServiceSelection,
  setInstallationServiceSelection,
  setGFastDeviceIncluded,
  yritystuplaOffer,
  setYritystuplaOffer,
  setCampaingAutoSelect,
  commercialProductAddOnVisibilities,
  onAddOnSelectionChange,
  publicAddOnVisibilities,
  fixedBBCheckoutLoaderData,
}: FixedBroadbandProductSelectionProps) => {
  const { logout } = useAuth();
  const dispatch = useDispatch();
  const { authenticatedUser } = useAuth();
  const isEmployeeUser = authenticatedUser?.userRole === AuthenticatedUserRole.EMPLOYEE;

  const [initialEventSent, setInitialEventSent] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [installationAddOnOptions, setInstallationAddOnOptions] = useState<FixedBroadbandInstallationAddOnOptions>();
  const [yritysWifiAddOn, setYritysWifiAddOn] = useState<AddedAddon>();
  const [yritysWifiDeliveryFeeAddOn, setYritysWifiDeliveryFeeAddOn] = useState<AddedAddon>();
  const productContent =
    fixedBroadbandProductContent().find(product => product.offerCodes.includes(selectedOffer.offerCode)) ||
    defaultFixedBroadbandProductContent;

  const selectedCommercialProduct =
    configuredOffer?.selectedCommercialProducts?.[SELECTED_COMMERCIAL_PRODUCT_INDEX]?.commercialProduct;
  const selectedCommercialProductCode = selectedCommercialProduct?.commercialProductCode;
  const isMobileBroadbandProduct =
    selectedCommercialProduct?.productSubType === CommercialProductSubType.MOBILE_BROADBAND;

  const installationServiceAddOnCampaign = campaignContextsFromVoucher.find(
    campaign => campaign.addOnCode === installationAddOnOptions?.nettiLiteInstallationServiceAddOn?.addOnCode
  );

  const yritysWifiAddOnChanged = (selection: YritysWifiSelection) => {
    setYritysWifiSelection({
      ...selection,
      selectedAddOn: yritysWifiAddOn,
    });

    if (!selection.enabled) {
      // If YritysWIFI is not selected, then the only allowed installation option
      // is for the main product. If any of the other installation options were
      // selected before, then they must be replaced with the only available option.
      setInstallationServiceSelection({
        ...installationServiceSelection,
        selectedAddOn: installationAddOnOptions?.nettiLiteInstallationServiceAddOn,
      });
    }
  };
  const { addOnRules, mobilePbxAddOnRules, nettiAddOnRules, netti4GAddOnRules } = fixedBBCheckoutLoaderData;

  const associations = createAddOnAssociationRecordArray(
    addOnRules,
    mobilePbxAddOnRules,
    nettiAddOnRules,
    netti4GAddOnRules
  );
  const dependencies = createAddOnDependencyRecordArray(
    addOnRules,
    mobilePbxAddOnRules,
    nettiAddOnRules,
    netti4GAddOnRules
  );

  useEffect(() => {
    setGFastDeviceIncluded(
      selectedOffer.commercialProducts?.some(cp =>
        cp.associatedAddOns?.some(addon => addon.addOnCode === gFastDevicePackageAddon.addOnCode)
      )
    );
  }, [selectedOffer.commercialProducts, setGFastDeviceIncluded]);

  useEffect(() => {
    if (!initialEventSent) {
      eCommerceProductDetailEvent();
      setInitialEventSent(true);
    }
  }, [initialEventSent, eCommerceProductDetailEvent]);

  useEffect(() => {
    const shouldUpdateInstallationServiceAddOn =
      selectedCommercialProductCode && commercialProductAddOnVisibilities?.[selectedCommercialProductCode];
    if (shouldUpdateInstallationServiceAddOn) {
      setInstallationAddOnOptions(
        createFixedBroadbandInstallationAddOnOptions(selectedCommercialProductCode, commercialProductAddOnVisibilities)
      );
    }
  }, [selectedCommercialProductCode, commercialProductAddOnVisibilities]);

  useEffect(() => {
    const shouldUpdateYritysWifiAddOn =
      selectedCommercialProductCode && commercialProductAddOnVisibilities?.[selectedCommercialProductCode];

    if (shouldUpdateYritysWifiAddOn) {
      setYritysWifiAddOn(
        getAddedAddOns([findYritysWifiService(selectedCommercialProductCode, commercialProductAddOnVisibilities)])?.[0]
      );

      setYritysWifiDeliveryFeeAddOn(
        getAddedAddOns([
          findYritysWifiDeliveryFeeAddOn(selectedCommercialProductCode, commercialProductAddOnVisibilities),
        ])?.[0]
      );
    }
  }, [selectedCommercialProductCode, commercialProductAddOnVisibilities]);

  // filter out services which needs additional selection and has already been specified explicitly, display IP address addons first
  const preLoadedVisibilities = publicAddOnVisibilities
    ?.filter(visibility => installationServiceSelection?.selectedAddOn?.addOnCode !== visibility.addOnGuidCode)
    .sort(
      (a, b) =>
        (isIpAddressGroupAddOn(a.addOn?.addOnGroup) ? 0 : 1) - (isIpAddressGroupAddOn(b.addOn?.addOnGroup) ? 0 : 1)
    );

  const methods = useForm<OrderConfiguration>({ mode: 'all', shouldFocusError: true });

  return (
    <div className="of-fixed-bb-product-selection">
      <strong className={`${dsClass.DISPLAY_INLINE_BLOCK} ${dsClass.MARGIN_BOTTOM_3}`}>
        {t.X2HQ('Select contract')}
      </strong>
      <CampaignSelectTile options={campaignOptions} />
      {formSubmitted && selectedCampaign === null && <p className={dsClass.INPUTERROR}>{t.X2HQ('Select contract')}</p>}
      <div className={`${dsClass.PADDING_TOP_3} ${dsClass.MARGIN_TOP_3}`}>{productContent.body}</div>
      <p className={dsClass.PADDING_VERTICAL_3}>
        <strong>{t.EY5B('New wireless broadband router will be delivered to defined address.')}</strong>
        {` ${t.U84N(
          'You can install the router by yourself or order Netti Lite installation service along your subscription order.'
        )}`}
      </p>
      <strong className={`${dsClass.MARGIN_BOTTOM_2} ${dsClass.PADDING_BOTTOM_1} ${dsClass.DISPLAY_INLINE_BLOCK}`}>
        {t.UU5G('Select additional services')}
      </strong>
      {yritystuplaModel && (
        <div className={dsClass.MARGIN_VERTICAL_2}>
          <YritystuplaSelection
            model={yritystuplaModel}
            yritystuplaOffer={yritystuplaOffer}
            onChange={setYritystuplaOffer}
          />
        </div>
      )}

      {preLoadedVisibilities && (
        <FormProvider {...methods}>
          <form noValidate>
            <AdditionalServicesCheckoutContent
              commercialProduct={selectedCommercialProduct}
              isRingSelected={false}
              cartItemInstanceId={`${SELECTED_COMMERCIAL_PRODUCT_INDEX}`}
              productAddOns={
                configuredOffer?.selectedCommercialProducts?.[SELECTED_COMMERCIAL_PRODUCT_INDEX]?.addedAddOns
              }
              onAddOnSelectionChange={(_cartIndex, addedAddOns) => onAddOnSelectionChange(addedAddOns)}
              preFetchedAddOnVisibilities={preLoadedVisibilities}
              hideHeader={true}
              loadNettiRules={false}
              addOnVisibilityOperationType={AddOnVisibilityOperationType.NEW_SALES}
              addOnAssociations={associations}
              addOnDependencies={dependencies}
            />
          </form>
        </FormProvider>
      )}
      {yritysWifiAddOn && yritysWifiDeliveryFeeAddOn && (
        <FixedBroadbandYritysWifiAddOn
          yritysWifiAddOn={yritysWifiAddOn}
          yritysWifiAddOnSelection={yritysWifiSelection}
          yritysWifiDeliveryFeeAddOn={yritysWifiDeliveryFeeAddOn}
          onChange={yritysWifiAddOnChanged}
        />
      )}
      {installationAddOnOptions?.nettiLiteInstallationServiceAddOn && (
        <div className={dsClass.MARGIN_VERTICAL_2}>
          <FixedBroadbandInstallationService
            addOnOptions={installationAddOnOptions}
            selectedInstallationAddOn={installationServiceSelection.selectedAddOn}
            installationAddOnChanged={selectedAddOn => {
              setInstallationServiceSelection({
                ...installationServiceSelection,
                selectedAddOn,
              });
            }}
            enableYritysWifiInstallationOptions={yritysWifiSelection.enabled}
            numberOfMeshDevices={yritysWifiSelection.quantity}
            campaignContextsFromVoucher={installationServiceAddOnCampaign}
            campaignVoucher={campaignVoucher}
            showRecommendedDisclaimer={isMobileBroadbandProduct}
            onChange={enabled =>
              setInstallationServiceSelection({
                enabled,
                selectedAddOn:
                  installationServiceSelection.selectedAddOn ??
                  installationAddOnOptions?.nettiLiteInstallationServiceAddOn,
              } as InstallationServiceAddOnSelection)
            }
          />
        </div>
      )}
      <div className={dsClass.MARGIN_TOP_5}>
        <OrderSummary
          commercialProducts={configuredOffer.selectedCommercialProducts}
          summaryType={OrderSummaryType.DETAILS_CLOSED}
        />
      </div>
      <WizardActions
        classes={[dsClass.PADDING_VERTICAL_3]}
        onBackClick={() => history.back()}
        onForwardClick={methods.handleSubmit(() => {
          setFormSubmitted(true);

          // Clear voucher if selected campaign do not contain voucher
          const selectedCampaignOption = campaignOptions.find(option => option.isSelected);
          if (!selectedCampaignOption?.voucher) {
            if (!selectedCampaign) {
              setCampaingAutoSelect(false);
            }

            dispatch(clearCampaigns());
          }

          if (selectedCampaign !== null) {
            if (isEmployeeUser) {
              // If user is logged in as employee, logout and proceed to checkout
              logout(undefined);
            }
            dispatch(googleEcommerceAddToCart(createNettiliteEcommerceItemsFromConfiguredOffer(configuredOffer)));
            onNext();
          }
        })}
      />
    </div>
  );
};
