import { TypeKeys, loadBillChannelsFailed, loadBillChannelsFulfilled } from '../actions/index.js';
import { actionToActionState } from './epicUtils.js';
import { callUiApi, prepareUiApiRequest } from '../common/uiApiUtils.js';
import { combineEpics, ofType } from 'redux-observable';
import { concatMap } from 'rxjs/operators';
import { getBillChannelsPublicMethod } from '../../generated/api/uiApiMethods.js';
import type { Action } from 'redux';
import type { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import type { EpicDependencies } from './epicUtils.js';
import type { LoadBillChannelsAction, SelfServiceActionTypes } from '../actions/index.js';
import type { State } from '../common/store.js';

export const loadBillChannelEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.LOAD_BILL_CHANNELS)), (action: LoadBillChannelsAction) =>
    actionToActionState(action, state$, 'billChannels')
  ).pipe(
    concatMap(() => {
      return callUiApi({
        epicDependencies,
        state$,
        method: getBillChannelsPublicMethod(),
        successAction: loadBillChannelsFulfilled,
        failureAction: loadBillChannelsFailed,
      });
    })
  );

export const billChannelEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> =
  combineEpics(loadBillChannelEpic);
