import { Controller, useFormContext, useFormState } from 'react-hook-form';
import { Dropdown as DropdownComponent } from '../../../components/Dropdown/Dropdown.js';
import { fieldCantBeEmptyMsg, selectMsg, t } from '../../i18n/index.js';
import { getTextError } from '../utils.js';
import type * as CLT from '@design-system/component-library';

export interface DropdownComponentProps {
  name: string;
  id?: string;
  label?: string;
  items: CLT.DropDownItem[];
  optional?: boolean;
  disabled?: boolean;
  onValueChange?: (value?: string) => void;
  selectedValue?: string;
}

export const Dropdown = ({
  name,
  id,
  label,
  items,
  optional = false,
  disabled = false,
  onValueChange = () => {},
  selectedValue,
}: DropdownComponentProps) => {
  const { control } = useFormContext();
  const formState = useFormState({ control });

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: { value: true, message: t.VPVR(fieldCantBeEmptyMsg) } }}
      render={({ field: { onChange, value }, fieldState: { isTouched, error } }) => (
        <DropdownComponent
          i18n_dropdown_placeholderText={t.QRYV(selectMsg)}
          label={label}
          items={items}
          id={id}
          onValueChange={element => {
            const newValue = element.dataset.value;
            onChange(newValue);
            onValueChange(newValue);
          }}
          selectedValue={selectedValue ?? value}
          i18n_dropdown_errorMessage={getTextError(formState.isSubmitted, isTouched, error)}
          optional={optional}
          disabled={disabled}
        />
      )}
    />
  );
};
