import { CREATE_NEW_CONTACT_OPTION_VALUE } from '../../../common/formik/Fields/index.js';
import { ContactDropdown } from '../../../common/react-hook-form/fields/ContactDropdown.js';
import { CreateContact } from './CreateContact.js';
import { Loading } from '../../Loading/index.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { getCompanyName } from '../../../common/utils/accountUtils.js';
import { selectMsg, t } from '../../../common/i18n/index.js';
import { useAuth } from '../../../public/site/AuthProvider.js';
import { useFormContext, useFormState } from 'react-hook-form';
import { useSearchParams } from '../../../common/hooks/useSearchParams.js';
import { useSelector } from 'react-redux';
import type { Contact } from '../../../generated/api/contact.js';
import type { ContactHeader } from '../../../generated/api/contactHeader.js';
import type { State } from '../../../selfservice/common/store.js';

interface CreateOrSelectContactProps {
  contacts?: Contact[] | ContactHeader[];
  fieldPrefix?: string;
  isEmailMandatory?: boolean;
  isPhoneNumberMandatory?: boolean;
  onContactChange?: (selectedContactId: string) => void;
}

export const CreateOrSelectContact = ({
  contacts,
  fieldPrefix = '',
  isEmailMandatory = true,
  isPhoneNumberMandatory = true,
  onContactChange,
}: CreateOrSelectContactProps) => {
  const { resetField, setValue, watch } = useFormContext();
  const { defaultValues } = useFormState();
  const { mdmId } = useSearchParams<{ mdmId?: string }>();
  const { authenticatedUser } = useAuth();
  const companyNameFromState = useSelector((state: State) => state?.selfservice?.companyInfo?.companyName);
  const companyName = getCompanyName(authenticatedUser, mdmId) || companyNameFromState;

  const contactId = watch(`${fieldPrefix}contactId`);

  if (!contacts) {
    return <Loading />;
  }
  const onChangeContact = (value: string) => {
    const defaultContactId = defaultValues?.[`${fieldPrefix}contactId`];
    if (value === defaultContactId) {
      resetField(`${fieldPrefix}costCenter`);
      resetField(`${fieldPrefix}employeeNumber`);
    } else if (value === CREATE_NEW_CONTACT_OPTION_VALUE) {
      setValue(`${fieldPrefix}costCenter`, '');
      setValue(`${fieldPrefix}employeeNumber`, '');
    } else {
      const selectedContact = contacts?.find(c => c.contactId === value);
      const contact = selectedContact as Contact;
      const contactHeader = selectedContact as ContactHeader;
      if (selectedContact && 'person' in selectedContact) {
        setValue(`${fieldPrefix}costCenter`, contact?.person?.costCenter ?? '');
        setValue(`${fieldPrefix}employeeNumber`, selectedContact?.person?.employeeNumber ?? '');
      } else {
        setValue(`${fieldPrefix}costCenter`, contactHeader?.costCenter ?? '');
        setValue(`${fieldPrefix}employeeNumber`, contactHeader?.referenceOrAdditionalInformation ?? '');
      }
    }
    if (onContactChange) {
      onContactChange(value);
    }
  };

  return (
    <>
      <div className="of-purpose-of-use__contact_dropdown">
        <ContactDropdown
          contacts={contacts}
          name={`${fieldPrefix}contactId`}
          createNewDialog={false}
          labelText={`${companyName}${t.S6U5('s users')}`}
          labelClass={`${dsClass.COLOR_NEUTRAL_600} ${dsClass.FONT_SIZE_SMALLER} ${dsClass.FONT_STYLE_NORMAL}`}
          placeHolderText={t.QRYV(selectMsg)}
          canAddNewContacts={true}
          onChangeContact={onChangeContact}
        />
      </div>
      {contactId === CREATE_NEW_CONTACT_OPTION_VALUE && (
        <CreateContact
          companyName={companyName}
          fieldPrefix={fieldPrefix}
          isEmailMandatory={isEmailMandatory}
          isPhoneNumberMandatory={isPhoneNumberMandatory}
        />
      )}
    </>
  );
};
