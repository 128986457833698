import * as CL from '@design-system/component-library';
import { CallCategory } from '../../generated/api/callCategory.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { eurosToCents, formatSum } from '../../common/utils/priceUtils.js';
import { formatTimeStampToYYYYMMDDHHmm } from '../../common/utils/dateUtils.js';
import { getUsageDetails, toBytes, toDuration, toPieces } from './subscriptionUsageUtil.js';
import { numberMsg, t } from '../../common/i18n/index.js';
import { useState } from 'react';
import type { MonthlyUsageDetailsResponse } from '../../generated/api/monthlyUsageDetailsResponse.js';
import type { UsageItemDetail } from '../../generated/api/usageItemDetail.js';

import './SubscriptionUsageTable.scss';

const mapUsageItemDetailToTableRow = (
  usageItemDetail: UsageItemDetail,
  quantityMapper: (quantity: number) => string
) => (
  <tr key={`${usageItemDetail.callCategory}-${Math.random()}`}>
    <td className={dsClass.BORDER_STYLE_NONE}></td>
    <td>{formatTimeStampToYYYYMMDDHHmm(usageItemDetail.dateAndTime)}</td>
    <td>{usageItemDetail.receiver}</td>
    <td>{quantityMapper(usageItemDetail.quantity)}</td>
    <td>{formatSum(eurosToCents(usageItemDetail.amount)) ?? '-'}</td>
  </tr>
);

const mapCallUsageItemDetailToTableRow = (usageItemDetail: UsageItemDetail) =>
  mapUsageItemDetailToTableRow(usageItemDetail, toDuration);

const mapTextUsageItemDetailToTableRow = (usageItemDetail: UsageItemDetail) =>
  mapUsageItemDetailToTableRow(usageItemDetail, toPieces);

const mapDataUsageItemDetailToTableRow = (usageItemDetail: UsageItemDetail) =>
  mapUsageItemDetailToTableRow(usageItemDetail, toBytes);

const ExpandableTableRow = ({
  toggleVisibility,
  isExpanded,
  title,
  totalQuantity,
  totalAmount,
}: {
  toggleVisibility: () => void;
  isExpanded: boolean;
  title: string;
  totalQuantity: string;
  totalAmount: string;
}) => (
  <thead>
    <tr
      className={`${dsClass.TABLE_ROW_IS_CLICKABLE} of-subscription-usage-table__expandable-row`}
      onClick={() => toggleVisibility()}
    >
      <th className="of-subscription-usage-table__expand-icon">
        <CL.Icon icon={isExpanded ? 'remove' : 'add'} color="blue-600" />
      </th>
      <th className={`of-subscription-usage-table__data-column ${dsClass.COLOR_BLUE_600}`}>{title}</th>
      <th className="of-subscription-usage-table__data-column"></th>
      <th className="of-subscription-usage-table__data-column">{totalQuantity}</th>
      <th className="of-subscription-usage-table__data-column">{totalAmount}</th>
    </tr>
  </thead>
);

const TitleRow = ({ title }: { title: string }) => (
  <tr>
    <td className={dsClass.BORDER_STYLE_NONE}></td>
    <td colSpan={4}>
      <div className="of-subscription-usage-table__subtitle">{title}</div>
    </td>
  </tr>
);

const HeaderRow = () => (
  <thead>
    <tr className="of-subscription-usage-table__header-row">
      <th className={dsClass.BORDER_STYLE_NONE}></th>
      <th className={dsClass.FONT_WEIGHT_INITIAL}>{t.AOQB('Time')}</th>
      <th className={dsClass.FONT_WEIGHT_INITIAL}>{t.CCB1(numberMsg)}</th>
      <th className={dsClass.FONT_WEIGHT_INITIAL}>{t.M0W7('Amount')}</th>
      <th className={dsClass.FONT_WEIGHT_INITIAL}>{t.ZLQO('Price (vat 0%)')}</th>
    </tr>
  </thead>
);

const EmptyRow = () => (
  <tr>
    <td className={dsClass.BORDER_STYLE_NONE}></td>
    <td>-</td>
    <td>-</td>
    <td>-</td>
    <td>-</td>
  </tr>
);

interface UsageItemDetailTotals {
  totalQuantity: number;
  totalAmount: number;
}

const usageItemDetailSummarizer = (totals: UsageItemDetailTotals, usageItemDetail: UsageItemDetail) => ({
  totalQuantity: totals.totalQuantity + usageItemDetail.quantity,
  totalAmount: totals.totalAmount + eurosToCents(usageItemDetail.amount),
});

const usageItemDetailQuantityCountingSummarizer = (
  totals: UsageItemDetailTotals,
  usageItemDetail: UsageItemDetail
) => ({
  totalQuantity: totals.totalQuantity + 1,
  totalAmount: totals.totalAmount + eurosToCents(usageItemDetail.amount),
});

interface SubscriptionUsageTableProps {
  details: MonthlyUsageDetailsResponse;
  isRoaming: boolean;
}

export const SubscriptionUsageTable = ({ details, isRoaming }: SubscriptionUsageTableProps) => {
  const initialSummary: UsageItemDetailTotals = Object.freeze({ totalQuantity: 0, totalAmount: 0 });
  const [tableSectionVisibilities, setTableSectionVisibilities] = useState({
    [CallCategory.CALL]: false,
    [CallCategory.SMS]: false,
    [CallCategory.DATA]: false,
  });

  const toggleVisibility = (callCategory: CallCategory) => {
    setTableSectionVisibilities({
      ...tableSectionVisibilities,
      [callCategory]: !tableSectionVisibilities[callCategory],
    });
  };

  const voiceCallsIncludedInQuota = getUsageDetails(details, CallCategory.CALL, !isRoaming, true);
  const voiceCallsNotIncludedInQuota = getUsageDetails(details, CallCategory.CALL, !isRoaming, false);
  const voiceCallSummary = details.voiceCalls
    .filter(d => d.isLocal === !isRoaming)
    .reduce(usageItemDetailSummarizer, initialSummary);

  const textsIncludedInQuota = getUsageDetails(details, CallCategory.SMS, !isRoaming, true);
  const textsNotIncludedInQuota = getUsageDetails(details, CallCategory.SMS, !isRoaming, false);
  const textsSummary = details.textMessages
    .filter(d => d.isLocal === !isRoaming)
    .reduce(usageItemDetailQuantityCountingSummarizer, initialSummary);

  const dataIncludedInQuota = getUsageDetails(details, CallCategory.DATA, !isRoaming, true);
  const dataNotIncludedInQuota = getUsageDetails(details, CallCategory.DATA, !isRoaming, false);
  const dataSummary = details.data
    .filter(d => d.isLocal === !isRoaming)
    .reduce(usageItemDetailSummarizer, initialSummary);

  return (
    <CL.Table className="of-subscription-usage-table">
      <ExpandableTableRow
        toggleVisibility={() => toggleVisibility(CallCategory.CALL)}
        isExpanded={tableSectionVisibilities[CallCategory.CALL]}
        title={t.ORN1('Calls')}
        totalQuantity={toDuration(voiceCallSummary.totalQuantity)}
        totalAmount={formatSum(voiceCallSummary.totalAmount) ?? '-'}
      />
      {tableSectionVisibilities[CallCategory.CALL] && (
        <>
          <tbody>
            <TitleRow title={t.HBFB('Calls included in the package')} />
          </tbody>
          {voiceCallsIncludedInQuota.length > 0 && <HeaderRow />}
          <tbody>
            {voiceCallsIncludedInQuota.length > 0 ? (
              voiceCallsIncludedInQuota.map(mapCallUsageItemDetailToTableRow)
            ) : (
              <EmptyRow />
            )}
            <TitleRow title={t.X9W7('Chargeable calls')} />
          </tbody>
          {voiceCallsNotIncludedInQuota.length > 0 && <HeaderRow />}
          <tbody>
            {voiceCallsNotIncludedInQuota.length > 0 ? (
              voiceCallsNotIncludedInQuota.map(mapCallUsageItemDetailToTableRow)
            ) : (
              <EmptyRow />
            )}
          </tbody>
        </>
      )}
      <ExpandableTableRow
        toggleVisibility={() => toggleVisibility(CallCategory.SMS)}
        isExpanded={tableSectionVisibilities[CallCategory.SMS]}
        title={t.LIU7('Messages')}
        totalQuantity={toPieces(textsSummary.totalQuantity)}
        totalAmount={formatSum(textsSummary.totalAmount) ?? '-'}
      />
      {tableSectionVisibilities[CallCategory.SMS] && (
        <>
          <tbody>
            <TitleRow title={t.ZVP0('Messages included in the package')} />
          </tbody>
          {textsIncludedInQuota.length > 0 && <HeaderRow />}
          <tbody>
            {textsIncludedInQuota.length > 0 ? (
              textsIncludedInQuota.map(mapTextUsageItemDetailToTableRow)
            ) : (
              <EmptyRow />
            )}
            <TitleRow title={t.FHIZ('Chargeable messages')} />
          </tbody>
          {textsNotIncludedInQuota.length > 0 && <HeaderRow />}
          <tbody>
            {textsNotIncludedInQuota.length > 0 ? (
              textsNotIncludedInQuota.map(mapTextUsageItemDetailToTableRow)
            ) : (
              <EmptyRow />
            )}
          </tbody>
        </>
      )}
      <ExpandableTableRow
        toggleVisibility={() => toggleVisibility(CallCategory.DATA)}
        isExpanded={tableSectionVisibilities[CallCategory.DATA]}
        title={t.T2IH('Data')}
        totalQuantity={toBytes(dataSummary.totalQuantity)}
        totalAmount={formatSum(dataSummary.totalAmount) ?? '-'}
      />
      {tableSectionVisibilities[CallCategory.DATA] && (
        <>
          <tbody>
            <TitleRow title={t.PFSQ('Data included in the package')} />
          </tbody>
          {dataIncludedInQuota.length > 0 && <HeaderRow />}
          <tbody>
            {dataIncludedInQuota.length > 0 ? dataIncludedInQuota.map(mapDataUsageItemDetailToTableRow) : <EmptyRow />}
            <TitleRow title={t.R0OK('Chargeable data')} />
          </tbody>
          {dataNotIncludedInQuota.length > 0 && <HeaderRow />}
          <tbody>
            {dataNotIncludedInQuota.length > 0 ? (
              dataNotIncludedInQuota.map(mapDataUsageItemDetailToTableRow)
            ) : (
              <EmptyRow />
            )}
          </tbody>
        </>
      )}
    </CL.Table>
  );
};
