import { CommonErrorType } from '../../common/enums.js';
import { ConfirmationDialog } from '../ConfirmationDialog/index.js';
import { EMAIL_REGEX } from '../../common/utils/emailUtils.js';
import { FileUpload } from '../FileUpload/FileUpload.js';
import { Form, Formik, useFormikContext } from 'formik';
import { MultipleEmails } from '../../common/formik/Fields/index.js';
import { closeDialog, initiateEmployeeOnBoarding } from '../../selfservice/actions/index.js';
import { convertToErrorStringMap } from '../../common/utils/errorUtils.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { fieldCantBeEmptyMsg, pleaseCheckEmailMsg, t } from '../../common/i18n/index.js';
import { useDispatch } from 'react-redux';
import { useEffect, useRef } from 'react';
import type { CommonError } from '../../common/types/errors.js';
import type { EmployeeOnBoardingPayload } from '../../selfservice/actions/index.js';

import './OnboardEmployeeDialog.scss';

export interface OnboardEmployeeSceneProps {
  errors?: CommonError[];
  isLoading?: boolean;
}

const validateAndInitiateOnboarding = (
  input: string | null | undefined,
  field: string,
  onInitiateEmployeeOnBoarding: (payload: EmployeeOnBoardingPayload, validationErrors?: CommonError[]) => void
) => {
  const recipientEmails = input ? input.split(/\r\n|\n|\r/).filter(Boolean) : [];
  const validationErrors: CommonError[] = [];
  if (!recipientEmails.length) {
    validationErrors.push({
      field,
      message: field === 'emails' ? t.VPVR(fieldCantBeEmptyMsg) : t.L1QB('The file could not be read or is empty.'),
      type: CommonErrorType.VALIDATION,
    });
  } else if (recipientEmails.filter(email => EMAIL_REGEX.test(email)).length !== recipientEmails.length) {
    validationErrors.push({
      field,
      message:
        field === 'emails'
          ? t.ROMH(pleaseCheckEmailMsg)
          : t.PCNO('The file contains one or more invalid email addresses.'),
      type: CommonErrorType.VALIDATION,
    });
  } else if (recipientEmails.length > 500) {
    validationErrors.push({
      field,
      message:
        field === 'emails'
          ? t.HYGU('This field can contain up to {} email addresses.', '500')
          : t.VJYS('The file can contain up to 500 email addresses.'),
      type: CommonErrorType.VALIDATION,
    });
  }
  onInitiateEmployeeOnBoarding({ recipientEmails }, validationErrors);
};

export const OnboardEmployeeDialog = ({ errors, isLoading }: OnboardEmployeeSceneProps) => {
  const dispatch = useDispatch();
  const emailsRef = useRef<string | null>('');
  const getFilesRef = useRef<() => File[]>();

  const onInitiateEmployeeOnBoarding = (payload: EmployeeOnBoardingPayload, validationErrors?: CommonError[]) => {
    dispatch(initiateEmployeeOnBoarding(payload, validationErrors));
  };

  const initialValues = {
    emails: '',
  };

  const MultilineEmail = () => {
    const context = useFormikContext<{ emails: string | null }>();
    useEffect(() => {
      emailsRef.current = context.values.emails;
    }, [context.values.emails]);
    return <MultipleEmails />;
  };

  const dialogValidationErrors = errors
    ? convertToErrorStringMap(errors.filter(err => err.type === CommonErrorType.VALIDATION))
    : undefined;
  const otherErrors = errors ? errors.filter(err => err.type !== CommonErrorType.VALIDATION) : undefined;

  const fileUploadObj = 'fileUpload';
  return (
    <ConfirmationDialog
      header={t.NYPL('Send a link')}
      body={
        <>
          <p>
            {t.L78N('An employee can use the link to log into the device store and select a device.')}
            <br />
            {t.FHD7(
              `You will receive confirmation of the employee's device agreement, which you must review and approve.`
            )}
          </p>
          <p>
            {t.M248('You can provide more than one email address at a time or attach e.g. a')}
            <strong> {t.ZB19('csv or text file')}</strong>{' '}
            {t.MRGG('containing email addresses (max. 500). Please note that xlsx file extension is not accepted.')}
          </p>
          <a
            href="https://static.elisa.com/v2/image/2tqybbhjs47b/3EzvYxsNmUAOeJExZlSp7O/employee-onboarding-invitation-tempate.csv"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t.FUAJ('Download here csv-example for email addresses.')}
          </a>
          <Formik initialValues={initialValues} onSubmit={() => {}}>
            <Form className={`of-onboard-employee ${dsClass.MARGIN_TOP_3}`}>
              <MultilineEmail />
              <FileUpload
                className="of-onboard-employee__file-upload"
                getFilesFn={getFiles => (getFilesRef.current = getFiles)}
                limit={1}
                validationError={dialogValidationErrors && dialogValidationErrors[fileUploadObj]}
                allowedFormat={['text/csv', 'text/rtf', 'text/plain', '', 'application/vnd.ms-excel']} // https://christianwood.net/csv-file-upload-validation/
                fileTypeValidationErrorMessage={t.QENF(
                  'Invalid file format. Please save your file either in .csv or .txt format.'
                )}
              />
            </Form>
          </Formik>
        </>
      }
      isLoading={isLoading}
      onCloseDialog={() => dispatch(closeDialog())}
      onConfirm={() => {
        const files = getFilesRef.current!();
        if (files.length === 1) {
          const fileReader = new FileReader();
          fileReader.onloadend = () => {
            validateAndInitiateOnboarding(
              fileReader.result as string | null,
              fileUploadObj,
              onInitiateEmployeeOnBoarding
            );
          };
          fileReader.readAsText(files[0]);
        } else {
          validateAndInitiateOnboarding(emailsRef.current, 'emails', onInitiateEmployeeOnBoarding);
        }
      }}
      errors={otherErrors}
    />
  );
};
