import * as CL from '@design-system/component-library';
import * as React from 'react';
import { Input } from '../../components/Input/Input.js';
import { simCardNumberMsg, t } from '../../common/i18n/index.js';

import './SimCardNumberInput.scss';

export interface SimCardNumberInputProps {
  error?: string;
  onChange: (simCardNumber: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  value: string;
  onAppendedButtonClick?: () => void;
}

export const SimCardNumberInput = (props: SimCardNumberInputProps): JSX.Element => {
  const hasButton = !!props.onAppendedButtonClick;
  return (
    <div className={`of-simcard-number-input${hasButton ? ' of-simcard-number-input__with-button' : ''}`}>
      <Input
        type="text"
        required={true}
        maxLength={14}
        hint={t.P0AZ('14 digits from your SIM card')}
        placeholder={t.VLQ3(simCardNumberMsg)}
        error={props.error}
        name="simCardNumber"
        onChange={(e: React.FormEvent<HTMLInputElement>) => props.onChange(e.currentTarget.value)}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        value={props.value}
      >
        {hasButton && (
          <CL.Button size="l" color="linkblue" onClick={props.onAppendedButtonClick} className="of-simcard-help-icon">
            <CL.Icon icon="help" size="l" type="regular" />
          </CL.Button>
        )}
      </Input>
    </div>
  );
};
