/**
 * Online UI API
 * # Summary Elisa B2B Online UI API  # Usage This API is used in the `https://yrityksille.elisa.fi/` pages.  # Identifiers 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Request body of invoice registration
 */
export interface InitializePaymentRequest { 
    /**
     * Amount of invoice payment in cents
     */
    balance: number;
    /**
     * Type of the bank selected for payment
     */
    paymentMethod: InitializePaymentRequest.PaymentMethodEnum;
}
export namespace InitializePaymentRequest {
    export type PaymentMethodEnum = 'AKTIA' | 'ALANDSBANKEN' | 'DANSKE_BANK' | 'HANDELSBANKEN' | 'NORDEA' | 'OMA_SP' | 'OSUUSPANKKI' | 'POP_PANKKI' | 'S_PANKKI' | 'SAASTOPANKKI';
    export const PaymentMethodEnum = {
        AKTIA: 'AKTIA' as PaymentMethodEnum,
        ALANDSBANKEN: 'ALANDSBANKEN' as PaymentMethodEnum,
        DANSKE_BANK: 'DANSKE_BANK' as PaymentMethodEnum,
        HANDELSBANKEN: 'HANDELSBANKEN' as PaymentMethodEnum,
        NORDEA: 'NORDEA' as PaymentMethodEnum,
        OMA_SP: 'OMA_SP' as PaymentMethodEnum,
        OSUUSPANKKI: 'OSUUSPANKKI' as PaymentMethodEnum,
        POP_PANKKI: 'POP_PANKKI' as PaymentMethodEnum,
        S_PANKKI: 'S_PANKKI' as PaymentMethodEnum,
        SAASTOPANKKI: 'SAASTOPANKKI' as PaymentMethodEnum
    };
}


