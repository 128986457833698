import { Field } from 'formik';
import { InputComponent } from '../FieldComponents/index.js';
import { cityMsg, t } from '../../i18n/index.js';
import { requiredValidation } from '../validation.js';
import type { OptionalInputComponentProps } from '../FieldComponents/index.js';

export const PostOffice = (props: OptionalInputComponentProps) => {
  const translation = t.J0YE(cityMsg);

  return (
    <Field
      {...{
        name: props.name || 'postOffice',
        label: translation,
        placeholder: translation,
        disabled: false,
        required: true,
        maxLength: 100,
        validate: requiredValidation,
        ...props,
      }}
      component={InputComponent}
    />
  );
};
