import * as CL from '@design-system/component-library';
import { dsClass } from '../../common/constants/dsClasses.js';
import { showMoreMsg, t } from '../../common/i18n/index.js';
import { useNavigate } from 'react-router-dom';

export interface MoreResultsProps {
  displayShowMoreButton?: boolean;
  onShowMoreClick?: () => void;
  isMultiBiz: boolean;
  seeAllLinkHref: string;
  setSearchFieldVisible: (visible: boolean) => void;
}

export const MoreResults = ({
  displayShowMoreButton,
  onShowMoreClick,
  isMultiBiz,
  seeAllLinkHref,
  setSearchFieldVisible,
}: MoreResultsProps) => {
  const navigate = useNavigate();
  const onSeeAllClick = () => {
    setSearchFieldVisible(false);
    navigate(seeAllLinkHref);
  };

  return (
    <div
      className={`${dsClass.PADDING_LEFT_3} ${dsClass.PADDING_RIGHT_3} ${dsClass.DISPLAY_FLEX} ${
        displayShowMoreButton ? dsClass.JUSTIFY_CONTENT_SPACE_BETWEEN : dsClass.JUSTIFY_CONTENT_FLEX_END
      }`}
    >
      {displayShowMoreButton && onShowMoreClick && (
        <CL.Button color="link" onClick={onShowMoreClick} size="s">
          <>
            {t.H6OA(showMoreMsg)}
            <CL.Icon icon="arrow-down" type="filled" />
          </>
        </CL.Button>
      )}
      {!isMultiBiz && (
        <CL.Button color="link" onClick={onSeeAllClick} size="s">
          <>
            {t.G090('See all')}
            <CL.Icon icon="arrow-right-alt" type="filled" />
          </>
        </CL.Button>
      )}
    </div>
  );
};
