/**
 * Online UI API
 * # Summary Elisa B2B Online UI API  # Usage This API is used in the `https://yrityksille.elisa.fi/` pages.  # Identifiers 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Address } from './address.js';


/**
 * Request body for create a new account in interworks
 */
export interface CreateInterworksAccountRequest { 
    billingAddress: Address;
    /**
     * Phone number of the contact managing this interworks account
     */
    contactPhoneNumber: string;
    /**
     * Email of the contact managing this interworks account
     */
    contactEmail: string;
    /**
     * First name of the contact person managing billing of the interworks account
     */
    billingContactFirstName: string;
    /**
     * Last name of the contact person managing billing of the interworks account
     */
    billingContactLastName: string;
    /**
     * Email of the contact person managing billing of the interworks account
     */
    billingContactEmail: string;
    /**
     * Phone number of the contact person managing billing of the interworks account
     */
    billingContactPhoneNumber: string;
    /**
     * Delivery method of invoices for the billing of the interworks account
     */
    billingDeliveryMethod: CreateInterworksAccountRequest.BillingDeliveryMethodEnum;
    /**
     * Electronic operator name if bill deliveryMethod is ELECTRONIC. Format varies, for example HELSFIHH.
     */
    billingElectronicOperator?: string;
    /**
     * Electronic operator address if bill deliveryMethod is ELECTRONIC.
     */
    billingElectronicAddress?: string;
    /**
     * Billing address name. e.g. Home
     */
    billingAddressAlias: string;
}
export namespace CreateInterworksAccountRequest {
    export type BillingDeliveryMethodEnum = 'EMAIL' | 'ELECTRONIC';
    export const BillingDeliveryMethodEnum = {
        EMAIL: 'EMAIL' as BillingDeliveryMethodEnum,
        ELECTRONIC: 'ELECTRONIC' as BillingDeliveryMethodEnum
    };
}


