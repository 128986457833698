import { fetchOpenForm } from '../../../common/fetch.js';
import { loadingFormMsg, t } from '../../../common/i18n/index.js';
import { useEffect, useState } from 'react';
import { useOpenFormAsync } from '../OpenFormProvider.js';
import type { FormId } from '../../../common/constants/pathInterfaces.js';
import type { OFPageType } from '../../../generated/api/oFPageType.js';
import type { OpenFormChoice } from '../../../generated/api/openFormChoice.js';
import type { OpenFormQuestion } from '../../../generated/api/openFormQuestion.js';
import type { OpenFormResponse } from '../../../generated/api/openFormResponse.js';
import type { OpenFormSection } from '../../../generated/api/openFormSection.js';

export class OpenForm {
  constructor(private readonly response: OpenFormResponse) {}

  get formId(): string {
    return this.response.formId;
  }

  get name(): string {
    return this.response.name;
  }

  get questions(): OpenFormQuestion[] {
    return this.response.sections.flatMap(s => s.questions);
  }

  get sections(): OpenFormSection[] {
    return this.response.sections;
  }

  getChoices(...types: OFPageType[]): OpenFormChoice[] {
    return this.sections.flatMap(s => (types.every(p => p !== s.pageType) ? [] : s.questions.flatMap(q => q.choices)));
  }

  getQuestions(...types: OFPageType[]): OpenFormQuestion[] {
    return this.sections.flatMap(s => (types.every(p => p !== s.pageType) ? [] : s.questions));
  }

  getSections(...types: OFPageType[]): OpenFormSection[] {
    return this.sections.flatMap(s => (types.every(p => p !== s.pageType) ? [] : s));
  }
}

export const useOpenForm = ({ formId }: FormId) => {
  const [data, setData] = useState<OpenForm | undefined>(undefined);
  const async = useOpenFormAsync();

  useEffect(() => {
    const { ctrl } = new async(({ signal }) => fetchOpenForm(formId, { signal }))
      .resolved(response => {
        const form = new OpenForm(response);
        setData(form);
        async.actions.form({ answers: async.storage.getItem(formId)?.answers, form });
      })
      .rejected(async.actions.error)
      .cache(formId)
      .execute(t.DR5T(loadingFormMsg));

    return () => {
      ctrl.abort();
      async.cache.clear();
      async.actions.form({ answers: undefined, form: undefined });
    };
  }, [async, formId]);

  return data;
};
