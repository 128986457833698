import * as CL from '@design-system/component-library';
import { ForgotPasswordEnterContactDetailsStep } from './ForgotPasswordEnterContactDetailsStep.js';
import { ForgotPasswordEnterOnetimePasswordStep } from './ForgotPasswordEnterOnetimePasswordStep.js';
import { ForgotPasswordEnterSamePasswordTwiceStep } from './ForgotPasswordEnterSamePasswordTwiceStep.js';
import { ForgotPasswordFinishProcessStep } from './ForgotPasswordFinishProcessStep.js';
import { ForgotPasswordInfoStep } from './ForgotPasswordInfoStep.js';
import { FormProvider, useForm } from 'react-hook-form';
import { employeeOmaElisaMsg, manageEmployeeSubscriptionMsg, t } from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import type { ForgotPasswordProps } from './misc/forgotPasswordInterfaces.js';

import './ForgotPassword.scss';

export const ForgotPassword = (props: ForgotPasswordProps) => {
  const { anonymousUser } = useAuth();
  const step = anonymousUser?.forgotPasswordStepNumber;
  const methods = useForm({ mode: 'all' });

  if (!anonymousUser) {
    return null;
  }

  return (
    <div className="of-forgot-password-children" id="of-forgot-password">
      <CL.Notification
        hasCloseButton={false}
        link={{ href: paths.EMPLOYEE_HOME, text: `${t.ZPZR(employeeOmaElisaMsg)}.` }}
        text={t.JW6Y(manageEmployeeSubscriptionMsg)}
        type="info"
        visible
      />
      <FormProvider {...methods}>
        {step === 1 && (
          <ForgotPasswordEnterContactDetailsStep
            closeModal={props.closeModal}
            anonymousUser={anonymousUser}
            methods={methods}
          />
        )}
        {step === 2 && (
          <ForgotPasswordEnterOnetimePasswordStep
            closeModal={props.closeModal}
            anonymousUser={anonymousUser}
            methods={methods}
          />
        )}
        {step === 3 && (
          <ForgotPasswordEnterSamePasswordTwiceStep
            closeModal={props.closeModal}
            anonymousUser={anonymousUser}
            methods={methods}
          />
        )}
        {step === 4 && (
          <ForgotPasswordFinishProcessStep
            closeModal={props.closeModal}
            anonymousUser={anonymousUser}
            unmountAction={props.unmountAction}
            methods={methods}
          />
        )}
      </FormProvider>
      {step === 5 && <ForgotPasswordInfoStep closeModal={props.closeModal} />}
    </div>
  );
};
