import { EMAIL_REGEX } from '../../../common/utils/emailUtils.js';
import { Field } from 'formik';
import { InputComponent } from '../FieldComponents/index.js';
import { emailMsg, fieldCantBeEmptyMsg, invalidValueMsg, t } from '../../i18n/index.js';
import type { OptionalInputComponentProps } from '../FieldComponents/index.js';

export const Email = (props: OptionalInputComponentProps) => {
  const translation = t.ZVQ5(emailMsg);
  const validate = (value: string) => {
    if (!value) {
      if (props.required !== false) {
        return t.VPVR(fieldCantBeEmptyMsg);
      }
    } else if (!EMAIL_REGEX.test(value)) {
      return t.DUPA(invalidValueMsg);
    }
    return undefined;
  };

  return (
    <Field
      {...{
        name: 'email',
        label: translation,
        placeholder: translation,
        disabled: false,
        required: props.required ?? true,
        validate,
        ...props,
      }}
      component={InputComponent}
    />
  );
};
