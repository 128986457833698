import { ContactCreationType } from '../ContactOrPurposeOfUse/ContactsOrPurposeOfUseUtils.js';
import { DuplicateContact } from '../../generated/api/models.js';
import { SelectedPurposeOfUseOrContact } from '../../common/enums.js';
import { closeDialog, submitDuplicateContactCheck } from '../../selfservice/actions/index.js';
import { formatPhoneNumber } from '../../common/utils/phoneNumberUtils.js';
import { t, userTextMsg } from '../../common/i18n/index.js';
import type { ContactHeader, ContactPerson } from '../../generated/api/models.js';
import type { Dispatch } from 'redux';
import type { PurposeOfUseOrContact } from '../../common/types/subscription.js';
import type { ShoppingCartItemForCheckout } from '../../common/types/checkout.js';

interface ItemIdentifier {
  itemIndex: number;
  purposeOfUseOrContactIndex: number;
}

interface SelectedContact extends ItemIdentifier {
  purposeOfUseOrContact: PurposeOfUseOrContact;
}

interface DuplicateContactSelectionOptions {
  newContact: ContactPerson;
  duplicateContact: DuplicateContact;
}

const SEPERATOR = '#';

export const isDuplicateContactHasSingleExactDuplicate = (duplicateContact: DuplicateContact): boolean => {
  return (
    duplicateContact.contacts?.length === 1 &&
    duplicateContact.duplicateType === DuplicateContact.DuplicateTypeEnum.DUPLICATE_CONTACT
  );
};

export const isDuplicateContactFromHierarchy = (duplicateContact: DuplicateContact): boolean => {
  return duplicateContact.duplicateType === DuplicateContact.DuplicateTypeEnum.DUPLICATE_FROM_HIERARCHY;
};

const parseItemIdentifier = (identifier: string): ItemIdentifier => {
  const identifiers = identifier.split(SEPERATOR);
  return {
    itemIndex: parseInt(identifiers[0].trim(), 10),
    purposeOfUseOrContactIndex: parseInt(identifiers[1].trim(), 10),
  };
};

export const getItemIdentifier = (itemIndex: number, purposeOfUseOrContactIndex: number): string =>
  `${itemIndex}${SEPERATOR}${purposeOfUseOrContactIndex}`;

export const getCartItemsWithSelectedContacts = (
  cartItems: ShoppingCartItemForCheckout[],
  selectedContacts: SelectedContact[]
): ShoppingCartItemForCheckout[] => {
  return cartItems.map((item, itemIndex) => {
    return {
      ...item,
      purposeOfUseOrContacts: item.purposeOfUseOrContacts.map((puc, pucIndex) => {
        const selectedContact =
          puc.contactId === ContactCreationType.COPIED_CONTACT
            ? selectedContacts.find(
                sc =>
                  sc.itemIndex === 0 &&
                  sc.purposeOfUseOrContactIndex === 0 &&
                  sc.purposeOfUseOrContact.contactId !== ContactCreationType.CREATE_NEW_CONTACT_FORCE_UPSERT
              )
            : selectedContacts.find(sc => sc.itemIndex === itemIndex && sc.purposeOfUseOrContactIndex === pucIndex);
        if (selectedContact) {
          if (selectedContact.purposeOfUseOrContact.contactId === ContactCreationType.CREATE_NEW_CONTACT_FORCE_UPSERT) {
            return {
              ...puc,
              contactId: ContactCreationType.CREATE_NEW_CONTACT_FORCE_UPSERT,
            };
          } else {
            return selectedContact.purposeOfUseOrContact;
          }
        }
        return puc;
      }),
    };
  });
};

export const submitDuplicateCheckSelection = (
  dispatch: Dispatch,
  cartItems: ShoppingCartItemForCheckout[],
  duplicateContacts: DuplicateContact[] = [],
  values?: object
) => {
  const selectedContacts: SelectedContact[] = duplicateContacts.map(duplicateContact => {
    const itemIdentifier = parseItemIdentifier(duplicateContact.identifier);
    const selectedContact: SelectedContact = {
      ...itemIdentifier,
      purposeOfUseOrContact: {
        selected: SelectedPurposeOfUseOrContact.CONTACT,
      },
    };
    if (isDuplicateContactHasSingleExactDuplicate(duplicateContact)) {
      // Auto selecting the duplicate contact which has only 1 exact duplicate contact without asking user to select.
      selectedContact.purposeOfUseOrContact.contactId =
        duplicateContact.contacts && duplicateContact.contacts[0].contactId;
    } else if (isDuplicateContactFromHierarchy(duplicateContact)) {
      // For duplicate contact from hierarchy it is not possible to select contact from hierarchy, hence forcefully creating new contact.
      selectedContact.purposeOfUseOrContact.contactId = ContactCreationType.CREATE_NEW_CONTACT_FORCE_UPSERT;
    } else {
      // selection from the user input
      // @ts-ignore
      selectedContact.purposeOfUseOrContact.contactId = values && values[duplicateContact.identifier];
    }
    return selectedContact;
  });
  dispatch(submitDuplicateContactCheck(getCartItemsWithSelectedContacts(cartItems, selectedContacts)));
  dispatch(closeDialog());
};

export const getDuplicateContactsToGetApprovalFromUser = (
  cartItems: ShoppingCartItemForCheckout[],
  duplicateContacts: DuplicateContact[] = []
): DuplicateContactSelectionOptions[] => {
  const duplicateContactsWithMultipleContacts =
    duplicateContacts.filter(
      // Duplicate contacts with only 1 exact duplicates and duplicate contact from hierarchy should be auto selected and hence does not need to be prompted to user to select.
      duplicateContact =>
        !isDuplicateContactHasSingleExactDuplicate(duplicateContact) &&
        !isDuplicateContactFromHierarchy(duplicateContact)
    ) || [];
  return duplicateContactsWithMultipleContacts
    .map(duplicateContact => {
      const itemIdentifier = parseItemIdentifier(duplicateContact.identifier);
      return {
        newContact:
          cartItems[itemIdentifier.itemIndex].purposeOfUseOrContacts[itemIdentifier.purposeOfUseOrContactIndex]
            .newContact,
        duplicateContact,
      };
    })
    .filter(options => options.newContact !== undefined)
    .map(options => ({
      newContact: options.newContact!,
      duplicateContact: options.duplicateContact,
    }));
};

export const getFormInitialValues = (duplicateContacts: DuplicateContactSelectionOptions[]) =>
  duplicateContacts.reduce((acc, cur) => {
    if (cur.duplicateContact.duplicateType === DuplicateContact.DuplicateTypeEnum.POSSIBLE_CONTACT_DUPLICATE) {
      return { ...acc, [cur.duplicateContact.identifier]: ContactCreationType.CREATE_NEW_CONTACT_FORCE_UPSERT };
    } else {
      return { ...acc, [cur.duplicateContact.identifier]: '' };
    }
  }, {});

export const getFormFieldValidations = (
  duplicateContacts: { newContact: ContactPerson; duplicateContact: DuplicateContact }[]
) => duplicateContacts.reduce((acc, cur) => ({ ...acc, [cur.duplicateContact.identifier]: { required: true } }), {});

export const getContactLabel = ({
  firstName = '',
  lastName = '',
  email = '',
  phoneNumber = '',
}: ContactPerson | ContactHeader): string => `${firstName} ${lastName}, ${email}, ${formatPhoneNumber(phoneNumber)}`;

export const getTitle = (
  index: number,
  duplicateContacts: { newContact: ContactPerson; duplicateContact: DuplicateContact }[]
): string => `${t.U4MA(userTextMsg)}${duplicateContacts.length > 1 ? ` ${index + 1}` : ''}`;
