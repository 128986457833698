import * as CL from '@design-system/component-library';
import * as React from 'react';
import { t } from '../../common/i18n/index.js';

interface RegistrationStep4Props {
  redirectUri: string;
  redirectButtonText: string;
}

export const RegistrationStep4 = ({
  redirectUri,
  redirectButtonText,
}: React.PropsWithChildren<RegistrationStep4Props>) => (
  <div className="of-registration-step of-registration-scene-step4">
    <div id="of-registration-step4-registration-completed">
      {t.WG7P('OmaElisa-credentials were succesfully created.')}
    </div>
    <div>
      <CL.Link buttonColor="primary" buttonSize="l" linkHref={redirectUri} linkStyle="link-button">
        {redirectButtonText}
      </CL.Link>
    </div>
  </div>
);
