import { BillingAccountDeliveryMethod } from '../../generated/api/models.js';
import { eInvoiceMsg, emailInvoiceMsg, paperInvoiceMsg, t } from '../../common/i18n/index.js';
import { formatAddress } from '../../common/utils/accountUtils.js';
import type { Address, BillingAccount } from '../../generated/api/models.js';

const InvoiceDeliveryMethod = ({
  deliveryMethod,
  billReceiverName,
  payerAddress,
  billElectronicAddress,
  billElectronicOperator,
}: {
  deliveryMethod: BillingAccountDeliveryMethod;
  billReceiverName?: string;
  payerAddress: Address;
  billElectronicOperator?: string;
  billElectronicAddress?: string;
}) => {
  switch (deliveryMethod) {
    case BillingAccountDeliveryMethod.EMAIL:
      return (
        <>
          <span>{billReceiverName}</span>
          <br />
          <span>{t.NNV9(emailInvoiceMsg)}</span>
        </>
      );
    case BillingAccountDeliveryMethod.PAPER:
      return (
        <>
          <span>{formatAddress(payerAddress)}</span>
          <br />
          <span>{t.TER4(paperInvoiceMsg)}</span>
        </>
      );
    case BillingAccountDeliveryMethod.ELECTRONIC:
      return (
        <>
          <span>{billElectronicOperator + ' ' + billElectronicAddress}</span>
          <br />
          <span key="deliveryMethod">{t.IK1D(eInvoiceMsg)}</span>
        </>
      );
    default:
      throw new Error('Invalid delivery method');
  }
};

export const BillingAccountInfo = ({ billingAccount }: { billingAccount: BillingAccount }) => (
  <div className="of-order-delivery-options__billing-account-info">
    <span key="payerName">{billingAccount.payerName}</span>
    <br />
    <InvoiceDeliveryMethod
      deliveryMethod={billingAccount.deliveryMethod}
      payerAddress={billingAccount.payerAddress}
      billElectronicAddress={billingAccount.billElectronicAddress}
      billElectronicOperator={billingAccount.billElectronicOperator}
      billReceiverName={billingAccount.billReceiverName}
    />
  </div>
);
