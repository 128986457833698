import { ProductDetails } from './ProductDetails.js';
import { addToCart } from '../../selfservice/actions/index.js';
import { applyDiscounts } from './utils/productDetailsUtils.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { getPublishedCatalogs, getSelectedCatalog } from '../../common/utils/catalogUtils.js';
import { isEmployeeUser } from './utils/productDetailsEmployeeUtils.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch, useSelector } from 'react-redux';
import type { CartItemContainer } from './ProductDetails.js';
import type { Catalog, OnlineModel } from '../../generated/api/models.js';
import type { Category } from '../../common/types/category.js';
import type { State } from '../../selfservice/common/store.js';

import './ProductDetailsEmployeeLoader.scss';

interface ProductDetailsEmployeeLoaderProps {
  onlineModel: OnlineModel;
  category: Category;
}

export const ProductDetailsEmployeeLoader = (props: ProductDetailsEmployeeLoaderProps) => {
  const dispatch = useDispatch();
  const { onlineModel } = props;
  const { authenticatedUser } = useAuth();
  const { companyInfo, selectedCatalogCode, virtualCatalogItems } = useSelector(
    (state: State) => ({
      selectedCatalogCode: state.selfservice?.virtualCatalogs?.selectedCatalogCode,
      virtualCatalogItems: state.selfservice?.virtualCatalogs?.items,
      companyInfo: state.selfservice?.companyInfo || undefined,
    }),
    deepEqual
  );
  const discountedPrices = companyInfo?.discountedPrices;
  const catalogs: Catalog[] = getPublishedCatalogs(virtualCatalogItems) || [];
  const catalog = getSelectedCatalog(catalogs, selectedCatalogCode);
  const model = discountedPrices ? applyDiscounts(onlineModel, discountedPrices) : onlineModel;

  return (
    <ProductDetails
      addToCart={(cartItemContainer: CartItemContainer) => dispatch(addToCart(cartItemContainer))}
      isPublicSite={false}
      isEmployee={isEmployeeUser(authenticatedUser)}
      onlineModel={model}
      user={authenticatedUser}
      catalog={catalog}
      showOfferAndPrices={!!discountedPrices}
      companyInfo={companyInfo}
    />
  );
};
