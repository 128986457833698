import { TypeKeys, generateActionId } from './actionsUtils.js';
import { convertToCommonErrors } from '../../common/utils/errorUtils.js';
import type { CrudAction, ErrorAction } from './actionsUtils.js';
import type {
  GenericError,
  LocalUsageResponse,
  RoamingUsageResponse,
  TextMessagesResponse,
  VoiceCallsResponse,
} from '../../generated/api/models.js';

export interface GetSubscriptionLocalUsageAction extends CrudAction<TypeKeys.GET_SUBSCRIPTION_LOCAL_USAGE> {
  subscriptionId: string;
}

export interface GetSubscriptionLocalUsageFulfilledAction {
  subscriptionId: string;
  type: TypeKeys.GET_SUBSCRIPTION_LOCAL_USAGE_FULFILLED;
  response: LocalUsageResponse;
}

export type GetSubscriptionLocalUsageFailedAction = ErrorAction<TypeKeys.GET_SUBSCRIPTION_LOCAL_USAGE_FAILED>;

export const getSubscriptionLocalUsage = (subscriptionId: string): GetSubscriptionLocalUsageAction => ({
  id: generateActionId(),
  subscriptionId,
  type: TypeKeys.GET_SUBSCRIPTION_LOCAL_USAGE,
});

export const getSubscriptionLocalUsageFulfilled = (
  subscriptionId: string,
  response: LocalUsageResponse
): GetSubscriptionLocalUsageFulfilledAction => ({
  response,
  subscriptionId,
  type: TypeKeys.GET_SUBSCRIPTION_LOCAL_USAGE_FULFILLED,
});

export const getSubscriptionLocalUsageFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): GetSubscriptionLocalUsageFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.GET_SUBSCRIPTION_LOCAL_USAGE_FAILED,
});

export interface GetSubscriptionRoamingUsageAction extends CrudAction<TypeKeys.GET_SUBSCRIPTION_ROAMING_USAGE> {
  subscriptionId: string;
}

export interface GetSubscriptionRoamingUsageFulfilledAction {
  subscriptionId: string;
  type: TypeKeys.GET_SUBSCRIPTION_ROAMING_USAGE_FULFILLED;
  response: RoamingUsageResponse;
}

export type GetSubscriptionRoamingUsageFailedAction = ErrorAction<TypeKeys.GET_SUBSCRIPTION_ROAMING_USAGE_FAILED>;

export const getSubscriptionRoamingUsage = (subscriptionId: string): GetSubscriptionRoamingUsageAction => ({
  id: generateActionId(),
  subscriptionId,
  type: TypeKeys.GET_SUBSCRIPTION_ROAMING_USAGE,
});

export const getSubscriptionRoamingUsageFulfilled = (
  subscriptionId: string,
  response: RoamingUsageResponse
): GetSubscriptionRoamingUsageFulfilledAction => ({
  response,
  subscriptionId,
  type: TypeKeys.GET_SUBSCRIPTION_ROAMING_USAGE_FULFILLED,
});

export const getSubscriptionRoamingUsageFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): GetSubscriptionRoamingUsageFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.GET_SUBSCRIPTION_ROAMING_USAGE_FAILED,
});

export interface GetSubscriptionVoiceCallsAction extends CrudAction<TypeKeys.GET_SUBSCRIPTION_VOICE_CALLS> {
  subscriptionId: string;
}

export interface GetSubscriptionVoiceCallsFulfilledAction {
  subscriptionId: string;
  type: TypeKeys.GET_SUBSCRIPTION_VOICE_CALLS_FULFILLED;
  response: VoiceCallsResponse;
}

export type GetSubscriptionVoiceCallsFailedAction = ErrorAction<TypeKeys.GET_SUBSCRIPTION_VOICE_CALLS_FAILED>;

export const getSubscriptionVoiceCalls = (subscriptionId: string): GetSubscriptionVoiceCallsAction => ({
  id: generateActionId(),
  subscriptionId,
  type: TypeKeys.GET_SUBSCRIPTION_VOICE_CALLS,
});

export const getSubscriptionVoiceCallsFulfilled = (
  subscriptionId: string,
  response: VoiceCallsResponse
): GetSubscriptionVoiceCallsFulfilledAction => ({
  response,
  subscriptionId,
  type: TypeKeys.GET_SUBSCRIPTION_VOICE_CALLS_FULFILLED,
});

export const getSubscriptionVoiceCallsFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): GetSubscriptionVoiceCallsFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.GET_SUBSCRIPTION_VOICE_CALLS_FAILED,
});

export interface GetSubscriptionTextMessagesAction extends CrudAction<TypeKeys.GET_SUBSCRIPTION_TEXT_MESSAGES> {
  subscriptionId: string;
}

export interface GetSubscriptionTextMessagesFulfilledAction {
  subscriptionId: string;
  type: TypeKeys.GET_SUBSCRIPTION_TEXT_MESSAGES_FULFILLED;
  response: TextMessagesResponse;
}

export type GetSubscriptionTextMessagesFailedAction = ErrorAction<TypeKeys.GET_SUBSCRIPTION_TEXT_MESSAGES_FAILED>;

export const getSubscriptionTextMessages = (subscriptionId: string): GetSubscriptionTextMessagesAction => ({
  id: generateActionId(),
  subscriptionId,
  type: TypeKeys.GET_SUBSCRIPTION_TEXT_MESSAGES,
});

export const getSubscriptionTextMessagesFulfilled = (
  subscriptionId: string,
  response: TextMessagesResponse
): GetSubscriptionTextMessagesFulfilledAction => ({
  response,
  subscriptionId,
  type: TypeKeys.GET_SUBSCRIPTION_TEXT_MESSAGES_FULFILLED,
});

export const getSubscriptionTextMessagesFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): GetSubscriptionTextMessagesFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.GET_SUBSCRIPTION_TEXT_MESSAGES_FAILED,
});
export type EmployeeSubscriptionUsageActionTypes =
  | GetSubscriptionLocalUsageAction
  | GetSubscriptionLocalUsageFulfilledAction
  | GetSubscriptionLocalUsageFailedAction
  | GetSubscriptionRoamingUsageAction
  | GetSubscriptionRoamingUsageFulfilledAction
  | GetSubscriptionRoamingUsageFailedAction
  | GetSubscriptionVoiceCallsAction
  | GetSubscriptionVoiceCallsFulfilledAction
  | GetSubscriptionVoiceCallsFailedAction
  | GetSubscriptionTextMessagesAction
  | GetSubscriptionTextMessagesFulfilledAction
  | GetSubscriptionTextMessagesFailedAction;
