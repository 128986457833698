import { SubscriptionType } from '../../generated/api/models.js';
import { TypeKeys } from '../actions/index.js';
import { actionToActionState } from './epicUtils.js';
import { callUiApi, prepareUiApiRequest } from '../common/uiApiUtils.js';
import { combineEpics, ofType } from 'redux-observable';
import { concatMap } from 'rxjs/operators';
import { getAddOnRulesPublicMethod } from '../../generated/api/uiApiMethods.js';
import {
  loadSubscriptionAddOnRulesFailed,
  loadSubscriptionAddOnRulesFulfilled,
  loadSubscriptionAddOnRulesMobilePbxFailed,
  loadSubscriptionAddOnRulesMobilePbxFulfilled,
} from '../actions/subscriptionAddOnRulesActions.js';
import type { Action } from 'redux';
import type { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import type { AddOnRulesResponse } from '../../generated/api/models.js';
import type { AssociationRecord, DependencyRecord } from '@onlinefirst/cloudsense-add-on-dependency-engine';
import type { EpicDependencies } from './epicUtils.js';
import type {
  LoadSubscriptionAddOnRulesAction,
  LoadSubscriptionAddOnRulesMobilePbxAction,
} from '../actions/subscriptionAddOnRulesActions.js';
import type { SelfServiceActionTypes } from '../actions/index.js';
import type { State } from '../common/store.js';

export const loadSubscriptionAddOnRules: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(
    action$.pipe(ofType(TypeKeys.LOAD_SUBSCRIPTION_ADD_ON_RULES)),
    (action: LoadSubscriptionAddOnRulesAction) => actionToActionState(action, state$, 'subscriptionAddOnRules')
  ).pipe(
    concatMap(() => {
      const method = getAddOnRulesPublicMethod();
      return callUiApi({
        method,
        state$,
        epicDependencies,
        successAction: response =>
          loadSubscriptionAddOnRulesFulfilled(
            (response as AddOnRulesResponse).associations[0] as AssociationRecord[],
            (response as AddOnRulesResponse).dependencies[0] as DependencyRecord[]
          ),
        failureAction: loadSubscriptionAddOnRulesFailed,
      });
    })
  );

export const loadSubscriptionAddOnRulesMobilePbx: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(
    action$.pipe(ofType(TypeKeys.LOAD_SUBSCRIPTION_ADD_ON_RULES_MOBILE_PBX)),
    (action: LoadSubscriptionAddOnRulesMobilePbxAction) => actionToActionState(action, state$, 'subscriptionAddOnRules')
  ).pipe(
    concatMap(() => {
      const method = getAddOnRulesPublicMethod({
        subscriptionType: SubscriptionType.MOBILE_PBX,
      });

      return callUiApi({
        method,
        state$,
        epicDependencies,
        successAction: response =>
          loadSubscriptionAddOnRulesMobilePbxFulfilled(
            (response as AddOnRulesResponse).associations[0] as AssociationRecord[],
            (response as AddOnRulesResponse).dependencies[0] as DependencyRecord[]
          ),
        failureAction: loadSubscriptionAddOnRulesMobilePbxFailed,
      });
    })
  );

export const subscriptionAddOnRulesEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = combineEpics(
  loadSubscriptionAddOnRules,
  loadSubscriptionAddOnRulesMobilePbx
);
