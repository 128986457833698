import * as CL from '@design-system/component-library';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { TextInput } from '../../../common/react-hook-form/fields/TextInput.js';
import { ToggleWithSubComponent } from '../../ToggleWithSubComponent/ToggleWithSubComponent.js';
import {
  addBillingAccountMsg,
  billingAccountNumberMissing,
  doesTheChangeApplyToAllBillingAccountsForThisBusinessIDMsg,
  numberOfTheBillingAccountMsg,
  t,
} from '../../../common/i18n/index.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { useEffect } from 'react';
import type { FormEditProps } from './EOperatorChangeRequestForm.js';

const BILLING_ACCOUNTS_FIELD_NAME = 'billingAccounts';

interface BillingAccountItem {
  billingAccountId: string;
}

const validateCustomerNumberOrBillingAccount = (value: string, currentBillingAccounts: Array<BillingAccountItem>) => {
  if (!value) {
    return t.WHKI(billingAccountNumberMissing);
  }
  if (currentBillingAccounts.filter(item => item.billingAccountId === value).length > 1) {
    return t.WG8A('Billing account or customer number has already been given');
  }
  return undefined;
};

const FormFields = ({ isEditing = true }: FormEditProps) => {
  const { fields, append, remove } = useFieldArray({
    name: BILLING_ACCOUNTS_FIELD_NAME,
  });
  const { getValues } = useFormContext();
  const values = getValues();

  useEffect(() => {
    if (fields.length === 0) {
      append({ billingAccountId: '' });
    }
  }, [append, fields.length]);

  const addField = () => {
    if (fields.length < 10) {
      append({ billingAccountId: '' });
    }
  };

  return (
    <CL.Grid className={dsClass.MARGIN_BOTTOM_3}>
      {fields.map((field, index) => (
        <CL.GridRow key={field.id}>
          <CL.GridCol colWidthXS={3} colWidthS={3} colWidthL={6}>
            <TextInput
              name={`${BILLING_ACCOUNTS_FIELD_NAME}.${index}.billingAccountId`}
              label={t.GQLW(numberOfTheBillingAccountMsg)}
              tooltip={t.HW60(
                'You can find the number of the billing account on the latest invoice, in the upper right corner. If there is no billing account on the invoice, please add the customer number shown on the invoice.\n\nTo add billing accounts, click on the “Add billing account” button. You can add up to 10 billing accounts.'
              )}
              required={false}
              hint={t.BKPE('Fill in the changing billing account ')}
              validate={(value: string) => validateCustomerNumberOrBillingAccount(value, values.billingAccounts)}
              readonly={!isEditing}
            />
          </CL.GridCol>
          <div className={`${dsClass.PADDING_TOP_4} ${dsClass.PADDING_LEFT_1}`}>
            {index !== 0 && isEditing && (
              <CL.Button type="button" color="link" size="l" onClick={() => remove(index)}>
                <CL.Icon icon="delete" />
              </CL.Button>
            )}
          </div>
        </CL.GridRow>
      ))}
      {fields.length < 10 && isEditing && (
        <CL.GridRow>
          <CL.GridCol colWidthXS={3} colWidthS={3} colWidthL={6}>
            <CL.Button
              className={`${dsClass.PADDING_LEFT_2} ${dsClass.PADDING_RIGHT_2} ${dsClass.MARGIN_BOTTOM_4}`}
              type="button"
              color="link"
              size="l"
              onClick={addField}
            >
              <span className={`${dsClass.DISPLAY_FLEX} ${dsClass.ALIGN_ITEMS_CENTER}`}>
                <CL.Icon icon="add" />
                <span className={dsClass.MARGIN_LEFT_1}>{t.PTGG(addBillingAccountMsg)}</span>
              </span>
            </CL.Button>
          </CL.GridCol>
        </CL.GridRow>
      )}
    </CL.Grid>
  );
};

export const ChooseWhetherAllBillingAccountsApply = ({ isEditing = true }: FormEditProps) => {
  const { setValue, clearErrors } = useFormContext();
  const handleToggle = (value: boolean) => {
    if (value) {
      setValue(BILLING_ACCOUNTS_FIELD_NAME, []);
      clearErrors(BILLING_ACCOUNTS_FIELD_NAME);
    }
  };
  return (
    <Controller
      name="allBillingAccountsApply"
      render={({ field: { onChange } }) => (
        <ToggleWithSubComponent
          name="allBillingAccountsApply"
          title={t.V5EW(doesTheChangeApplyToAllBillingAccountsForThisBusinessIDMsg)}
          infoText={t.WL1E(
            'Yes: the change will be made to all Elisa’s billing accounts where this business ID is the payer. Billing accounts on Elisa’s consumer side with this business ID are also taken account of.\n\n' +
              'No: the change will only apply to the billing accounts you have provided.'
          )}
          onToggle={value => {
            onChange(value);
            handleToggle(value);
          }}
          subComponent={<FormFields isEditing={isEditing} />}
          showSubComponentWhenToggled={false}
          initialValue={true}
          disabled={!isEditing}
        />
      )}
    />
  );
};
