import type { Contact } from '../../generated/api/models.js';

/*
 * Email regex:
 * [Name, single character or multiple, set of special characters allowed but not at start or end, no double dots] + @ +
 * [Domain, single character or multiple, only '-' and '.' specials characters allowed but not at start or end, no double dots] + . +
 * [Top level domain, only letters, minimum of 2. NOTE! might need check later, but currently all of them are 2 or longer]
 */
export const EMAIL_REGEX =
  /^((?:(?=.)|(?!.*?\.\.)[\w][\w.!#$%&*+=?^_'`{|}~/-]*)[\w])+@(((?=.)|(?!.*?\.\.)[\w][\w.-])*[\w])+\.[A-Za-z]{2,}$/;
export const EMAIL_MAX_LENGTH = 80;

export const hasEmail = (contact: Contact): boolean => {
  if (contact?.person) {
    return Boolean(contact.person.email);
  }
  if (contact?.commonFunction) {
    return Boolean(contact.commonFunction.email);
  }
  return false;
};
