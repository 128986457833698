import { Icon } from '../Icon/Icon.js';
import type { IconColor } from '../Icon/Icon.js';

export interface StatusProps {
  color: IconColor;
  text: string | JSX.Element;
}

export const Status = ({ color, text }: StatusProps) => (
  <div className="info-text">
    {color && <Icon className="status-icon" icon="record" type="filled" size="s" color={color} />}
    <span>{text}</span>
  </div>
);
