import { SubscriptionsLayout } from '../../../../../components/SubscriptionsLayout/SubscriptionsLayout.js';
import { SubscriptionsVoice } from '../../../../../components/Subscriptions/SubscriptionsVoice.js';
import { omaElisaForCompaniesMsg, t, voiceSubscriptionsMsg } from '../../../../../common/i18n/index.js';
import { paths } from '../../../../../common/constants/pathVariables.js';

export const MobileSubsPath = () => {
  return (
    <SubscriptionsLayout
      breadCrumbs={[
        { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
        { name: t.AR0B(voiceSubscriptionsMsg) },
      ]}
    >
      <SubscriptionsVoice />;
    </SubscriptionsLayout>
  );
};
