import * as CL from '@design-system/component-library';
import { ModelType } from '../../common/enums.js';
import { ProductCard, ProductCardFooter } from '../ProductCard/index.js';
import { SalesType } from '../../generated/api/models.js';
import { formatSum } from '../../common/utils/priceUtils.js';
import { getAddonsRecurringPrice } from '../../common/utils/addOnUtils.js';
import { getCommercialProductPrices } from '../../common/utils/commercialProductUtils.js';
import { getOfferCampaignAssociationAndPromotion } from '../../common/utils/campaignUtils.js';
import { isCommitmentPeriodActive } from '../../common/utils/subscriptionUtils.js';
import { m2mContent, productContentMatchesOffer } from './content/CommercialProductContent.js';
import { monthMsg, t } from '../../common/i18n/index.js';
import type {
  CampaignAssociation,
  CampaignContext,
  CampaignPromotion,
  CommercialProduct,
  Offer,
  OnlineModel,
  Subscription,
} from '../../generated/api/models.js';
import type { OfferCard } from './OrderSubscriptionSelection.js';
import type { ProductContent, ProductContentWithAddonCode } from './content/CommercialProductContent.js';

export interface CampaignContextsFromVoucher {
  voucher?: string;
  campaignContexts: CampaignContext[];
}

export const getOfferCardContent = (
  onlineModel: OnlineModel,
  subscription: Subscription | undefined,
  isChangeOffer: boolean,
  campaignContextsFromVoucher: CampaignContextsFromVoucher,
  showAllProducts: boolean,
  campaignPromotions: CampaignPromotion[],
  availableContent: ProductContent[]
): OfferCard[] => {
  const allOffers = onlineModel?.offers || [];
  // Custom priority array to sort the offers based on offerName.
  const sortPriority = ['kuitu', 'kamo', 'g.fast', '5g', 'vsdl', '4g', ''];
  return allOffers
    .filter(offer => {
      if (onlineModel?.onlineModelCode === ModelType.MobileM2M && subscription?.details?.selectedAddOns) {
        // M2M/Laitenetti needs special filtering when doing change offer (e.g. subscription is defined).
        // Different types of Laitenetti (S, M and L) subs can only be identified by addon, we have those codes in `m2mContent`.
        const offerAddonCodes = offer.commercialProducts[0].associatedAddOns?.map(addon => addon.addOnCode);
        const m2mAddonCodes = m2mContent().map(product => product.addOnCode);
        const subAddonCodes = subscription.details.selectedAddOns.map(addon => addon.addOnCode);
        const subTypeIdentifyingAddon = subAddonCodes?.find(addonCode => m2mAddonCodes.includes(addonCode));

        return subTypeIdentifyingAddon && !offerAddonCodes?.includes(subTypeIdentifyingAddon);
      } else {
        return offer.commercialProducts[0].commercialProductCode !== subscription?.commercialProductCode;
      }
    })
    .sort((a, b) => {
      return (
        availableContent.findIndex(val => val.offerCodes.includes(a.offerCode)) -
        availableContent.findIndex(val => val.offerCodes.includes(b.offerCode))
      );
    })
    .map(
      (
        offer
      ): {
        campaignAssociation: CampaignAssociation | undefined;
        campaignPromotion: CampaignPromotion | undefined;
        content?: ProductContent | ProductContentWithAddonCode;
        offer: Offer;
        onlineModel: OnlineModel;
        voucherInUse: boolean;
        voucher?: string;
      } => {
        const { campaignAssociation, campaignPromotion, voucher } =
          getOfferCampaignAssociationAndPromotion(
            offer,
            isChangeOffer ? SalesType.CHANGE_OFFER : SalesType.NEW_SALE,
            campaignContextsFromVoucher.campaignContexts,
            campaignContextsFromVoucher.voucher,
            campaignPromotions
          ) || {};

        return {
          campaignAssociation,
          campaignPromotion,
          content: availableContent.find(e => productContentMatchesOffer(e, offer)),
          offer,
          onlineModel,
          voucherInUse: voucher !== undefined,
          voucher: voucher,
        };
      }
    )
    .filter((offerCard): offerCard is OfferCard => offerCard.content !== undefined)
    .filter(offerCard => {
      // In case of doing a change offer from fixed term campaigns we need to ensure that
      // 1) It's only possible to update to other fixed term campaign
      // 2) It's not possible to update to a cheaper offer
      if (isChangeOffer && isCommitmentPeriodActive(subscription?.details?.commitmentEndDate)) {
        if (!offerCard?.campaignAssociation?.fixedTermPeriod) {
          return false;
        }

        const discountedPrice = getCommercialProductPrices(
          offerCard.offer.commercialProducts[0],
          offerCard.campaignAssociation,
          SalesType.CHANGE_OFFER
        );

        if ((subscription?.details?.monthlyRecurringCharge || 0) > (discountedPrice?.monthlyRecurringCharge || 0)) {
          return false;
        }
      }
      return true;
    })
    .sort((a, b) => {
      return (
        sortPriority.findIndex(val => a.offer?.offerName?.toLowerCase().includes(val)) -
        sortPriority.findIndex(val => b.offer?.offerName.toLowerCase().includes(val))
      );
    })
    .slice(0, showAllProducts ? undefined : 3);
};

export interface ProductPriceTexts {
  priceText: string;
  priceBeforeDiscount?: string;
}

export const getProductPriceText = (
  cp: CommercialProduct,
  includeAddons: boolean,
  campaignAssociation?: CampaignAssociation,
  isChangeOffer?: boolean
): ProductPriceTexts => {
  const addonsRecurringPrice = getAddonsRecurringPrice(cp, includeAddons);
  const salesType = isChangeOffer ? SalesType.CHANGE_OFFER : SalesType.NEW_SALE;
  const cpPrice = getCommercialProductPrices(cp, campaignAssociation, salesType);

  return {
    priceText: `${formatSum((cpPrice.monthlyRecurringCharge || 0) + addonsRecurringPrice, true)} €/${t.XXVX(monthMsg)}`,
    priceBeforeDiscount: cpPrice.monthlyRecurringChargeBeforeDiscount
      ? `${formatSum(cpPrice.monthlyRecurringChargeBeforeDiscount + addonsRecurringPrice, true)} €/${t.XXVX(monthMsg)}`
      : undefined,
  };
};

interface CampaignProductCardProps {
  commercialProduct: CommercialProduct;
  content: ProductContent;
  imagesBaseUrl?: string;
  isChangeOffer: boolean;
  isMultipleCampaigns: boolean;
  offer: Offer;
  offerCampaignAssociation?: CampaignAssociation;
  showSelectButton: boolean;
  voucher?: string;
}

export const HeaderContent = (props: { initialHeaderContent?: JSX.Element; voucher: string | undefined }) => {
  const { initialHeaderContent, voucher } = props;
  if (initialHeaderContent) {
    return <div>{initialHeaderContent}</div>;
  }
  if (voucher) {
    return <CL.Badge color="warning" text={voucher} />;
  }

  return null;
};

export const CampaignProductCard = (props: CampaignProductCardProps) => {
  const {
    commercialProduct,
    content,
    imagesBaseUrl,
    isChangeOffer,
    isMultipleCampaigns,
    offer,
    offerCampaignAssociation,
    showSelectButton,
    voucher,
  } = props;

  return (
    <ProductCard
      offer={offer}
      body={content.body || <></>}
      className={voucher ? 'voucher' : ''}
      title={content.title}
      // TODO: Currently assuming that offer has one commercialProduct
      footer={
        <ProductCardFooter
          showCommitmentText={!isMultipleCampaigns}
          offerCampaignAssociation={offerCampaignAssociation}
          commercialProduct={commercialProduct}
          salesType={isChangeOffer ? SalesType.CHANGE_OFFER : SalesType.NEW_SALE}
        />
      }
      imgSrc={content.imageUrl && `${imagesBaseUrl || '.'}/${content.imageUrl}`}
      headerContent={<HeaderContent initialHeaderContent={content.headerContent} voucher={voucher} />}
      showSelectButton={showSelectButton}
      isMultipleCampaigns={isMultipleCampaigns}
      priceHighlight={voucher != null}
      productName={
        <>
          <h4 className="ea-h4">
            {offer.offerName}
            <br />
            {content.productNameAddon}
          </h4>
          <small>{content.specsText}</small>
        </>
      }
      {...getProductPriceText(commercialProduct, 'addOnCode' in content, offerCampaignAssociation, isChangeOffer)}
    />
  );
};
