import * as CL from '@design-system/component-library';
import { CompanyInfo, CustomFormContactDetails, CustomFormErrorAndButton } from '../CustomFormFields.js';
import { CompanySearch, TextInput } from '../../../common/react-hook-form/fields/index.js';
import { CustomFormDomainSearch } from '../partials/CustomFormDomainSearch.js';
import { CustomFormRadio } from '../partials/CustomFormRadio.js';
import { PriceSummary } from '../partials/PriceSummary.js';
import { additionalInformationMsg, domainMsg, selectProductMsg, t } from '../../../common/i18n/index.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import type { CustomFormProps } from '../CustomForm.js';
import type { CustomFormRadioItem } from '../partials/CustomFormRadio.js';
import type { Price } from '../../../generated/api/price.js';

const domainPrice: Price = {
  monthlyRecurringCharge: 500,
};

const hostingOptions: CustomFormRadioItem[] = [
  {
    value: 'Elisa Yritysweb',
    label: 'Elisa Yritysweb',
    monthlyRecurringCharge: 1500,
    oneTimeCharge: undefined,
    isDefault: true,
  },
];

export const defaultHostingOption = (): CustomFormRadioItem | undefined => {
  return hostingOptions.find(it => it.isDefault);
};

export const CorporateWebHostingForm = (props: CustomFormProps) => {
  const { leadFailed, leadInProgress, title, enableCompanyFields = true } = props;
  return (
    <CL.Grid className="of-custom-form">
      <CL.GridRow>
        <CL.GridCol className="title" colWidthS={6} colWidthM={6} colWidthL={12} colWidthXL={12}>
          <h2 className={dsClass.H2}>{title}</h2>
        </CL.GridCol>
        <CL.GridCol colWidthS={6} colWidthM={6} colWidthL={6} colWidthXL={6}>
          <CustomFormRadio title={t.V9HK(selectProductMsg)} options={hostingOptions} />
          <CustomFormDomainSearch price={domainPrice} />
          <PriceSummary {...props} />
        </CL.GridCol>
        <CL.GridCol colWidthS={6} colWidthM={6} colWidthL={6} colWidthXL={6}>
          {enableCompanyFields && (
            <>
              <CompanyInfo />
              <CompanySearch />
            </>
          )}
          <CustomFormContactDetails />
          <h3 className={dsClass.H3}>{t.GZUK(additionalInformationMsg)}</h3>
          <TextInput name="domainName" required={true} label={t.GWJI(domainMsg)} placeholder={t.GWJI(domainMsg)} />
          <CustomFormErrorAndButton leadInProgress={leadInProgress} leadFailed={leadFailed} />
        </CL.GridCol>
      </CL.GridRow>
    </CL.Grid>
  );
};
