import * as CL from '@design-system/component-library';
import { CompanyInfo, CustomFormContactDetails, CustomFormErrorAndButton } from '../CustomFormFields.js';
import { CompanySearch } from '../../../common/react-hook-form/fields/index.js';
import { CustomFormQuantity } from '../partials/CustomFormQuantity.js';
import { InputComponent } from '../../../common/react-hook-form/components/InputComponent.js';
import { PriceSummary } from '../partials/PriceSummary.js';
import {
  additionalInformationMsg,
  amountOfLicensesToOrderMsg,
  existingDomainInputMsg,
  mainUserEmailMsg,
  mainUserNameMsg,
  mainUserPhoneMsg,
  t,
} from '../../../common/i18n/index.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import type { CustomFormField } from '../CustomFormFields.js';
import type { CustomFormProps } from '../CustomForm.js';

const quantityFields: CustomFormField[] = [
  {
    id: 'm365-10',
    name: 'm365-1',
    value: 'Microsoft 365 Business Basic\nKuukausitilaus',
    monthlyRecurringCharge: 760,
  },
  {
    id: 'm365-11',
    name: 'm365-1',
    value: 'Microsoft 365 Business Basic\nVuositilaus',
    monthlyRecurringCharge: 630,
  },
  {
    id: 'm365-12',
    name: 'm365-1',
    value: 'Microsoft 365 Business Standard\nKuukausitilaus',
    monthlyRecurringCharge: 1399,
  },
  {
    id: 'm365-13',
    name: 'm365-1',
    value: 'Microsoft 365 Business Standard\nVuositilaus',
    monthlyRecurringCharge: 1170,
  },
  {
    id: 'm365-14',
    name: 'm365-1',
    value: 'Microsoft 365 Business Premium\nKuukausitilaus',
    monthlyRecurringCharge: 2485,
  },
  {
    id: 'm365-15',
    name: 'm365-1',
    value: 'Microsoft 365 Business Premium\nVuositilaus',
    monthlyRecurringCharge: 2065,
  },
  {
    id: 'm365-16',
    name: 'm365-1',
    value: 'Microsoft 365 E3\nKuukausitilaus',
    monthlyRecurringCharge: 4730,
  },
  {
    id: 'm365-17',
    name: 'm365-1',
    value: 'Microsoft 365 E3\nVuositilaus',
    monthlyRecurringCharge: 3945,
  },
  {
    id: 'm365-18',
    name: 'm365-1',
    value: 'Microsoft 365 E5\nKuukausitilaus',
    monthlyRecurringCharge: 7160,
  },
  {
    id: 'm365-19',
    name: 'm365-1',
    value: 'Microsoft 365 E5\nVuositilaus',
    monthlyRecurringCharge: 5961,
  },
];

export const Microsoft365Form = (props: CustomFormProps) => {
  const { leadFailed, leadInProgress, title, enableCompanyFields = true } = props;
  return (
    <CL.Grid className="of-custom-form">
      <CL.GridRow>
        <CL.GridCol className="title" colWidthS={6} colWidthM={6} colWidthL={12} colWidthXL={12}>
          <h2 className={dsClass.H2}>{title}</h2>
        </CL.GridCol>
        <CL.GridCol colWidthS={6} colWidthM={6} colWidthL={5} colWidthXL={5}>
          <CustomFormQuantity title={t.FW1W(amountOfLicensesToOrderMsg)} quantityFields={quantityFields} />
          <PriceSummary {...props} />
        </CL.GridCol>
        <CL.GridCol colWidthL={2} colWidthXL={2} />
        <CL.GridCol colWidthS={6} colWidthM={6} colWidthL={5} colWidthXL={5}>
          {enableCompanyFields && (
            <>
              <CompanyInfo />
              <CompanySearch />
            </>
          )}
          <CustomFormContactDetails />
          <h3 className={dsClass.H3}>{t.GZUK(additionalInformationMsg)}</h3>
          <InputComponent
            validate={() => undefined}
            name="mainUserName"
            required={true}
            disabled={false}
            type="text"
            label={t.WLNI(mainUserNameMsg)}
            placeholder={t.WLNI(mainUserNameMsg)}
          ></InputComponent>
          <InputComponent
            validate={() => undefined}
            name="mainUserEmail"
            required={true}
            disabled={false}
            type="text"
            label={t.CI5R(mainUserEmailMsg)}
            placeholder={t.CI5R(mainUserEmailMsg)}
          ></InputComponent>
          <InputComponent
            validate={() => undefined}
            name="mainUserPhone"
            required={false}
            disabled={false}
            type="text"
            label={t.BMK4(mainUserPhoneMsg)}
            placeholder={t.BMK4(mainUserPhoneMsg)}
          ></InputComponent>
          <InputComponent
            validate={() => undefined}
            name="existingDomain"
            required={false}
            disabled={false}
            type="text"
            label={t.PETH(existingDomainInputMsg)}
            placeholder={t.PETH(existingDomainInputMsg)}
          ></InputComponent>
          <CustomFormErrorAndButton leadInProgress={leadInProgress} leadFailed={leadFailed} />
        </CL.GridCol>
      </CL.GridRow>
    </CL.Grid>
  );
};
