import { Contract, SubscriptionType } from '../../generated/api/models.js';
import {
  DEFAULT_ITEMS_PER_PAGE,
  ListPagination,
  ListSearch,
  Table,
  TableTopActions,
  tableSearch,
} from '../Table/index.js';
import { Link, generatePath, useNavigate } from 'react-router-dom';
import { MobileIdDisabled } from '../MobileId/MobileIdDisabled.js';
import { allServicesMsg, idNumberMsg, orderMsg, statusMsg, t } from '../../common/i18n/index.js';
import { getContractStatus } from '../MobileId/MobileIdDetails.js';
import { getPaginatedItems } from './subscriptionListUtils.js';
import { getSubscriptionIcon } from './subscriptionIcons.js';
import { paths } from '../../common/constants/pathVariables.js';
import { setDefaultItemsPerPage } from '../Table/tableUtils.js';
import { useSearchParams } from '../../common/hooks/useSearchParams.js';
import type { DefaultListSearchParams, TableTopButton } from '../Table/index.js';
import type { KeyRow } from '@design-system/component-library';

interface ServiceContractsProps {
  isLoading: boolean;
  isMobileIdEnabled?: boolean;
  mobileIdContracts?: Contract[];
  offset: number;
  search?: string;
  total?: number;
  itemsPerPage?: number;
}

const getContractRows = (mobileIdContracts: Contract[] = []): KeyRow[] =>
  mobileIdContracts
    .filter(
      contract =>
        contract.contractStatus === Contract.ContractStatusEnum.ACTIVATED ||
        contract.contractStatus === Contract.ContractStatusEnum.TERMINATED
    )
    .map(contract => ({
      icon: (
        <div
          className={`of-subscription-table__icon-column of-icon--${getSubscriptionIcon(
            SubscriptionType.MOBILE_CERTIFICATE
          )}`}
        ></div>
      ),
      subscriptionName: (
        <Link
          to={generatePath(paths.PS_CONTRACT_MOBILE_ID, { contractDisplayId: contract.contractNumber })}
          className="of-subscription-table__text-column"
        >
          {contract.contractName}
        </Link>
      ),
      subscriptionStatus: getContractStatus()[contract.contractStatus],
      subscriptionUserFriendlyId: contract.contractNumber,
    }));

export const ServiceContracts = ({
  isLoading,
  isMobileIdEnabled,
  mobileIdContracts,
  offset,
  search,
  itemsPerPage,
}: ServiceContractsProps) => {
  const navigate = useNavigate();
  const searchParams = useSearchParams<DefaultListSearchParams>();

  if (!isMobileIdEnabled) {
    return <MobileIdDisabled />;
  }

  const tableTopButtons: TableTopButton[] = [
    {
      label: t.AQOL(orderMsg),
      onClick: () => navigate(paths.PS_CONTRACT_MOBILE_ID_NEW),
    },
  ];

  const contractColumns = [
    { key: 'icon', label: '' },
    { key: 'subscriptionName', label: t.NX0T(allServicesMsg) },
    { key: 'subscriptionUserFriendlyId', label: t.YTH3(idNumberMsg), sortable: false },
    { key: 'subscriptionStatus', label: t.ASQT(statusMsg), sortable: false },
  ];

  const contractRows = getContractRows(mobileIdContracts);
  const rows = search ? tableSearch(search, contractRows) : contractRows;

  return (
    <div className="of-subscription-table">
      <TableTopActions buttons={tableTopButtons} />
      <ListSearch onSearch={() => setDefaultItemsPerPage(new URLSearchParams(searchParams), DEFAULT_ITEMS_PER_PAGE)} />
      <Table
        columns={contractColumns}
        isLoading={isLoading}
        noItemsText={t.XZA8('No services')}
        rows={getPaginatedItems(itemsPerPage, offset, rows)}
      />
      <ListPagination totalItems={rows.length} />
    </div>
  );
};
