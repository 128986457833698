import { BUSINESS_ID_REGEX } from '../../utils/validationUtils.js';
import { InputComponent } from '../components/InputComponent.js';
import { businessIdMsg, fieldCantBeEmptyMsg, invalidValueMsg, t } from '../../../common/i18n/index.js';
import type { OptionalInputComponentProps } from '../components/InputComponent.js';

export const BusinessId = ({
  name = 'businessId',
  label = t.MPA5(businessIdMsg),
  placeholder = t.MPA5(businessIdMsg),
  required = true,
  disabled = false,
  ...rest
}: OptionalInputComponentProps) => {
  const validate = (value: string) => {
    if (required && !value) {
      return t.VPVR(fieldCantBeEmptyMsg);
    } else if (!BUSINESS_ID_REGEX.test(value)) {
      return t.DUPA(invalidValueMsg);
    }
    return undefined;
  };

  return (
    <InputComponent
      validate={validate}
      name={name}
      type="text"
      label={label}
      placeholder={placeholder}
      required={required}
      disabled={disabled}
      {...rest}
    />
  );
};
