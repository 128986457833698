import { CompanyInfoResponse } from '../../../generated/api/companyInfoResponse';
import { LinkableAccordion } from '../../../components/LinkableAccordion';
import { SubscriptionContactInfoBbInvitation } from '../SubscriptionContactInfoBbInvitation';
import { SubscriptionPbxDetails } from '../../../generated/api/subscriptionPbxDetails';
import { SubscriptionType } from '../../../generated/api/subscriptionType';
import { UserAndNumberPublicity } from '../../../components/UserAndNumberPublicity/UserAndNumberPublicity';
import { getContactPersonByContactId } from '../../../common/utils/contactUtils';
import { showBroadbandInvitationInfo } from '../BroadBandInvitation/bbInvitationUtils';
import type { Accordion } from '../../../common/utils/accordionUtils';
import type { CompanyInfoState } from '../../../common/types/states';
import type { Contact } from '../../../generated/api/contact';
import type { DialogParams } from '../../../common/types/dialog';
import type {
  DirectoryListingCorporateNumberPublicity,
  DirectoryListingMobileNumberPublicity,
  DirectoryListingNumberPublicity,
  PurposeOfUseOrContact,
  SubscriptionStatus,
} from '../../../common/types/subscription';
import type { Subscription } from '../../../generated/api/subscription';

export interface PurposeOfUseAccordionProps {
  heading: string;
  subscription: Subscription;
  subscriptionStatus: SubscriptionStatus;
  contacts?: Contact[];
  onShowDialog: (params: DialogParams) => void;
  siteBaseUrl: string;
  companyInfo?: CompanyInfoState | null;
  duplicateContactFound?: boolean;
  editingSection?: string;
  saving?: boolean;
}

const getDirectoryListingNumberPublicity = (
  inputSubscription: Subscription
): DirectoryListingNumberPublicity | undefined =>
  inputSubscription &&
  inputSubscription.details &&
  inputSubscription.details.mobile &&
  inputSubscription.details.mobile.directoryListing && {
    address: inputSubscription.details.mobile.directoryAddress,
    companyName: inputSubscription.details.mobile.companyName,
    numberDirectory: inputSubscription.details.mobile.directoryListing,
    recipientName: inputSubscription.details.mobile.recipientName,
  };

const getDirectoryListingMobileNumberPublicity = (
  inputSubscription: Subscription
): DirectoryListingMobileNumberPublicity | undefined => {
  if (inputSubscription?.details?.mobile?.directoryListing) {
    return {
      voiceDirectoryDetails: {
        address: inputSubscription.details.mobile.directoryAddress,
        companyName: inputSubscription.details.mobile.companyName,
        numberDirectory: inputSubscription.details.mobile.directoryListing,
        recipientName: inputSubscription.details.mobile.recipientName,
      },
    };
  } else {
    return {
      voiceDirectoryDetails: undefined,
    };
  }
};

const getDirectoryListingCorporateNumberPublicity = (
  inputSubscription: Subscription
): DirectoryListingCorporateNumberPublicity | undefined => {
  if (
    inputSubscription?.details?.mobile?.pbxConfiguration?.pbxConfigurationDetails.corporateNumberDirectoryDetails &&
    (inputSubscription.details.mobile.pbxConfiguration.pbxConfigurationDetails.pbxType ===
      SubscriptionPbxDetails.PbxTypeEnum.RING ||
      inputSubscription.details.mobile.pbxConfiguration.pbxConfigurationDetails.pbxType ===
        SubscriptionPbxDetails.PbxTypeEnum.VAKIO)
  ) {
    return {
      pbxDirectoryDetails: {
        address:
          inputSubscription.details.mobile.pbxConfiguration.pbxConfigurationDetails.corporateNumberDirectoryDetails
            .address,
        companyName:
          inputSubscription.details.mobile.pbxConfiguration.pbxConfigurationDetails.corporateNumberDirectoryDetails
            .companyName,
        numberDirectory:
          inputSubscription.details.mobile.pbxConfiguration.pbxConfigurationDetails.corporateNumberDirectoryDetails
            .numberDirectory,
        recipientName:
          inputSubscription.details.mobile.pbxConfiguration.pbxConfigurationDetails.corporateNumberDirectoryDetails
            .recipientName,
      },
    };
  } else {
    return undefined;
  }
};

const getUserOrPurposeOfUseAccordionContent = (
  contacts: Contact[] | undefined,
  purposeOfUseObj: PurposeOfUseOrContact,
  subscription: Subscription,
  subscriptionStatus: SubscriptionStatus,
  onShowDialog: (params: DialogParams) => void,
  siteBaseUrl: string,
  companyInfo?: CompanyInfoState | null,
  duplicateContactFound = false,
  editingSection?: string,
  saving?: boolean
) => {
  const pbxType = subscription.details?.mobile?.pbxConfiguration
    ? subscription.details.mobile.pbxConfiguration.pbxConfigurationDetails.pbxType
    : undefined;
  const getNumberPrivacyCorporate = () => {
    if (
      subscription.subscriptionType === SubscriptionType.MOBILE &&
      purposeOfUseObj.directoryListingCorporateNumberPublicity &&
      !pbxType
    ) {
      return purposeOfUseObj.directoryListingCorporateNumberPublicity.pbxDirectoryDetails?.numberDirectory;
    } else if (
      subscription.subscriptionType === SubscriptionType.MOBILE &&
      purposeOfUseObj.directoryListingCorporateNumberPublicity &&
      (pbxType === SubscriptionPbxDetails.PbxTypeEnum.RING || pbxType === SubscriptionPbxDetails.PbxTypeEnum.VAKIO)
    ) {
      return purposeOfUseObj.directoryListingCorporateNumberPublicity.pbxDirectoryDetails?.numberDirectory;
    }
    return undefined;
  };

  const isDeviceSubscription = subscription.subscriptionType === SubscriptionType.DEVICE;
  const isInternalCustomer = companyInfo?.customerType === CompanyInfoResponse.CustomerTypeEnum.INTERNAL_CUSTOMERS;
  const isEppSubscription = !!subscription.details?.device?.eppSubscription;
  const isCostCenterMandatory = isDeviceSubscription && isInternalCustomer;
  const isEmailMandatory = isDeviceSubscription && (isEppSubscription || isInternalCustomer);
  const isPhoneNumberMandatory = isDeviceSubscription && (isEppSubscription || isInternalCustomer);
  const currentEditingSection = duplicateContactFound ? 'purposeOfUse' : editingSection;
  const forceEditing = duplicateContactFound || !!editingSection;

  return (
    <div>
      <UserAndNumberPublicity
        subscription={subscription}
        subscriptionStatus={subscriptionStatus}
        contacts={contacts}
        editingSection={currentEditingSection}
        forceEditing={forceEditing}
        purposeOfUseOrContact={purposeOfUseObj}
        numberPrivacyMobile={
          subscription.subscriptionType === SubscriptionType.MOBILE &&
          purposeOfUseObj.directoryListingMobileNumberPublicity
            ? purposeOfUseObj.directoryListingMobileNumberPublicity.voiceDirectoryDetails?.numberDirectory
            : undefined
        }
        numberPrivacyCorporate={getNumberPrivacyCorporate()}
        pbxType={pbxType}
        companyInfo={companyInfo}
        isCostCenterMandatory={isCostCenterMandatory}
        isEmailMandatory={isEmailMandatory}
        isPhoneNumberMandatory={isPhoneNumberMandatory}
        siteBaseUrl={siteBaseUrl}
        isSaving={Boolean(saving)}
      />
      {showBroadbandInvitationInfo(subscription) && (
        <SubscriptionContactInfoBbInvitation
          onShowDialog={onShowDialog}
          subscriptionId={subscription.subscriptionId}
          contactPerson={getContactPersonByContactId(contacts, subscription?.subscriptionContactId)}
        />
      )}
    </div>
  );
};

const createPurposeOfUseObj = (subscription: Subscription) => ({
  contactId: subscription.subscriptionContactId,
  contactName: subscription.subscriptionContactName,
  directoryListingNumberPublicity: getDirectoryListingNumberPublicity(subscription),
  directoryListingMobileNumberPublicity: getDirectoryListingMobileNumberPublicity(subscription),
  directoryListingCorporateNumberPublicity: getDirectoryListingCorporateNumberPublicity(subscription),
  purposeOfUse: subscription.subscriptionPurposeOfUse,
  costCenter: subscription.costCenter,
  employeeNumber: subscription.subscriptionReference,
});

export const PurposeOfUseAccordion = ({
  heading,
  subscription,
  subscriptionStatus,
  contacts,
  onShowDialog,
  siteBaseUrl,
  companyInfo,
  duplicateContactFound,
  editingSection,
  saving,
}: PurposeOfUseAccordionProps) => {
  const purposeOfUseObj = createPurposeOfUseObj(subscription);
  const userOrPurposeOfUseAccordionContent = getUserOrPurposeOfUseAccordionContent(
    contacts,
    purposeOfUseObj,
    subscription,
    subscriptionStatus,
    onShowDialog,
    siteBaseUrl,
    companyInfo,
    duplicateContactFound,
    editingSection,
    saving
  );

  return (
    <LinkableAccordion heading={heading} headingLevel="h3" id="user-or-use-purpose">
      {userOrPurposeOfUseAccordionContent}
    </LinkableAccordion>
  );
};

export const getPurposeOfUseAccordion = ({
  heading,
  subscription,
  subscriptionStatus,
  contacts,
  onShowDialog,
  siteBaseUrl,
  companyInfo,
  duplicateContactFound,
  editingSection,
  saving,
}: PurposeOfUseAccordionProps): Accordion => {
  const purposeOfUseObj = createPurposeOfUseObj(subscription);
  const userOrPurposeOfUseAccordionContent = getUserOrPurposeOfUseAccordionContent(
    contacts,
    purposeOfUseObj,
    subscription,
    subscriptionStatus,
    onShowDialog,
    siteBaseUrl,
    companyInfo,
    duplicateContactFound,
    editingSection,
    saving
  );

  return {
    headerName: heading,
    id: 'user-or-use-purpose',
    includedData: userOrPurposeOfUseAccordionContent,
    displayed: true,
  };
};
