import {
  SubscriptionDeviceCommitmentPeriodFilter,
  SubscriptionDeviceEppFilter,
  SubscriptionDeviceExpiringFilter,
  SubscriptionDevicePaymentTypeFilter,
  SubscriptionDeviceUserFilter,
  SubscriptionFilterValue,
} from '../../common/enums.js';
import {
  accessoriesMsg,
  computersMsg,
  networkingHardwareMsg,
  purposeOfUseMsg,
  t,
  tabletsMsg,
} from '../../common/i18n/index.js';
import type { CommitmentPeriodAggregations } from '../../generated/api/commitmentPeriodAggregations.js';
import type { DeviceUserAggregations } from '../../generated/api/deviceUserAggregations.js';
import type { EppDevicesAggregations } from '../../generated/api/eppDevicesAggregations.js';
import type { ExpiringAggregations } from '../../generated/api/expiringAggregations.js';
import type { PaymentTypeAggregations } from '../../generated/api/paymentTypeAggregations.js';
import type { SearchFilterGroup } from '../Table/SearchFilters.js';
import type { SubTypeAggregations } from '../../generated/api/subTypeAggregations.js';

export const getTypeFilterGroup: (
  subscriptionSubType?: string,
  aggregations?: SubTypeAggregations
) => SearchFilterGroup = (subscriptionSubType = '', aggregations) => {
  const enabledSubTypes = subscriptionSubType.split('|');
  return {
    label: () => t.XI4V('Device type'),
    value: 'subscriptionSubType',
    items: [
      {
        label: () => t.U17V('Mobile phones'),
        checked: enabledSubTypes.includes(SubscriptionFilterValue.PHONE),
        value: SubscriptionFilterValue.PHONE,
        itemsCount: aggregations?.phone,
      },
      {
        label: () => t.XS42(tabletsMsg),
        checked: enabledSubTypes.includes(SubscriptionFilterValue.TABLET),
        value: SubscriptionFilterValue.TABLET,
        itemsCount: aggregations?.tablet,
      },
      {
        label: () => t.MCW3(computersMsg),
        checked: enabledSubTypes.includes(SubscriptionFilterValue.COMPUTERS),
        value: SubscriptionFilterValue.COMPUTERS,
        itemsCount: aggregations?.computers,
      },
      {
        label: () => t.J954(accessoriesMsg),
        checked: enabledSubTypes.includes(SubscriptionFilterValue.ACCESSORIES),
        value: SubscriptionFilterValue.ACCESSORIES,
        itemsCount: aggregations?.accessories,
      },
      {
        label: () => t.DMM5(networkingHardwareMsg),
        checked: enabledSubTypes.includes(SubscriptionFilterValue.NETWORK_EQUIPMENT),
        value: SubscriptionFilterValue.NETWORK_EQUIPMENT,
        itemsCount: aggregations?.networkEquipment,
      },
      {
        label: () => t.A4V5('Other devices'),
        checked: enabledSubTypes.includes(SubscriptionFilterValue.OTHERS),
        value: SubscriptionFilterValue.OTHERS,
        itemsCount: aggregations
          ? (aggregations.CPE || 0) +
            (aggregations.elisaYritysgurunAsiantuntijapalvelut || 0) +
            (aggregations.elisaYritystietoturva || 0)
          : undefined,
      },
    ],
  };
};

export const getEppFilterGroup: (eppFilter?: string, aggregations?: EppDevicesAggregations) => SearchFilterGroup = (
  eppFilter = '',
  aggregations
) => {
  const enabledEppFilters = eppFilter.split('|');
  return {
    label: () => t.TRE5('Device as Service'),
    value: 'eppFilter',
    items: [
      {
        label: () => t.EY6A('All'),
        checked: enabledEppFilters.includes(SubscriptionDeviceEppFilter.All),
        value: SubscriptionDeviceEppFilter.All,
        itemsCount: aggregations?.all,
      },
      {
        label: () => t.HC1K('In the basic contract period'),
        checked: enabledEppFilters.includes(SubscriptionDeviceEppFilter.BasicContractPeriod),
        value: SubscriptionDeviceEppFilter.BasicContractPeriod,
        itemsCount: aggregations?.basicContractPeriod,
      },
      {
        label: () => t.XOVM('In the extension period'),
        checked: enabledEppFilters.includes(SubscriptionDeviceEppFilter.ExtensionPeriod),
        value: SubscriptionDeviceEppFilter.ExtensionPeriod,
        itemsCount: aggregations?.extensionPeriod,
      },
    ],
  };
};

export const getExpiringFilterGroup: (
  expiringFilter?: string,
  aggregations?: ExpiringAggregations
) => SearchFilterGroup = (expiringFilter = '', aggregations) => {
  const enabledExpiringFilters = expiringFilter.split('|');
  return {
    label: () => t.UTIR('Contract ends'),
    value: 'expiringFilter',
    items: [
      {
        label: () => t.NOM4('Ends in 2 weeks'),
        checked: enabledExpiringFilters.includes(SubscriptionDeviceExpiringFilter.ExpiringIn2Weeks),
        value: SubscriptionDeviceExpiringFilter.ExpiringIn2Weeks,
        itemsCount: aggregations?.expiringIn2Weeks,
      },
    ],
  };
};

export const getPaymentTypeFilterGroup: (
  paymentType?: string,
  aggregations?: PaymentTypeAggregations
) => SearchFilterGroup = (paymentTypeFilter = '', aggregations) => {
  const enabledPaymentTypeFilters = paymentTypeFilter.split('|');
  return {
    label: () => t.ML02('Purchased devices'),
    value: 'paymentType',
    items: [
      {
        label: () => t.QYSL('One-time fee'),
        checked: enabledPaymentTypeFilters.includes(SubscriptionDevicePaymentTypeFilter.Onetime),
        value: SubscriptionDevicePaymentTypeFilter.Onetime,
        itemsCount: aggregations?.onetime,
      },
      {
        label: () => t.K755('Monthly fee'),
        checked: enabledPaymentTypeFilters.includes(SubscriptionDevicePaymentTypeFilter.Monthly),
        value: SubscriptionDevicePaymentTypeFilter.Monthly,
        itemsCount: aggregations?.monthly,
      },
    ],
  };
};

export const getCommitmentPeriodFilterGroup: (
  commitmentPeriod?: string,
  aggregations?: CommitmentPeriodAggregations
) => SearchFilterGroup = (commitmentPeriodFilter = '', aggregations) => {
  const enabledCommitmentPeriodFilters = commitmentPeriodFilter.split('|');
  return {
    label: () => t.D55M('Duration of the contract'),
    value: 'commitmentPeriod',
    items: [
      {
        label: () => t.O109('{} Month', '12'),
        checked: enabledCommitmentPeriodFilters.includes(SubscriptionDeviceCommitmentPeriodFilter.Period12Months),
        value: SubscriptionDeviceCommitmentPeriodFilter.Period12Months,
        itemsCount: aggregations?.period12,
      },
      {
        label: () => t.O109('{} Month', '24'),
        checked: enabledCommitmentPeriodFilters.includes(SubscriptionDeviceCommitmentPeriodFilter.Period24Months),
        value: SubscriptionDeviceCommitmentPeriodFilter.Period24Months,
        itemsCount: aggregations?.period24,
      },
      {
        label: () => t.O109('{} Month', '36'),
        checked: enabledCommitmentPeriodFilters.includes(SubscriptionDeviceCommitmentPeriodFilter.Period36Months),
        value: SubscriptionDeviceCommitmentPeriodFilter.Period36Months,
        itemsCount: aggregations?.period36,
      },
    ],
  };
};

export const getDeviceUserFilterGroup: (
  deviceUser?: string,
  aggregations?: DeviceUserAggregations
) => SearchFilterGroup = (deviceUserFilter = '', aggregations) => {
  const enabledDeviceUserFilters = deviceUserFilter.split('|');
  return {
    label: () => t.Y8KX(purposeOfUseMsg),
    value: 'deviceUser',
    items: [
      {
        label: () => t.RP6P('Shared use devices'),
        checked: enabledDeviceUserFilters.includes(SubscriptionDeviceUserFilter.Shared),
        value: SubscriptionDeviceUserFilter.Shared,
        itemsCount: aggregations?.shared,
      },
      {
        label: () => t.PDVP('Devices in personal use'),
        checked: enabledDeviceUserFilters.includes(SubscriptionDeviceUserFilter.Personal),
        value: SubscriptionDeviceUserFilter.Personal,
        itemsCount: aggregations?.personal,
      },
    ],
  };
};
