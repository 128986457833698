import * as CL from '@design-system/component-library';
import { BILLING_DELIVERY_METHOD, BillingFieldset } from './BillingFieldset.js';
import { BillingContactFieldset } from './BillingContactFieldset.js';
import { ButtonGroupForSubmitAndBack } from '../../ButtonGroupForSubmitAndBack/ButtonGroupForSubmitAndBack.js';
import {
  COMPANY_POSTAL_ADDRESS_FIELD_NAME,
  COMPANY_POSTAL_CODE_FIELD_NAME,
  COMPANY_POST_OFFICE_FIELD_NAME,
  CompanyDetailsFieldset,
} from './CompanyDetailsFieldset.js';
import { CompanyContactFieldset } from './CompanyContactFieldset.js';
import { CreateInterworksAccountRequest } from '../../../generated/api/createInterworksAccountRequest.js';
import { DEFAULT_COUNTRY_CODE } from '../../../common/utils/validationUtils.js';
import { DialogType } from '../../../common/enums.js';
import { FormProvider, useForm } from 'react-hook-form';
import { Lead } from '../../../generated/api/lead';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { isInBrowser } from '../../../common/utils/ssrUtils';
import {
  licenseManagementRedirectFailedHeaderMsg,
  licenseManagementRedirectFailedMsg,
  t,
} from '../../../common/i18n/index.js';
import { postAnonymousLead } from '../../../common/fetch.js';
import { pushLicenseManagementGAEventToDataLayer } from '../../../common/analytics';
import { showDialog } from '../../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import type { BILLING_ADDRESS_ALIAS, BILLING_BILL_E_ADDRESS, BILLING_BILL_E_OPERATOR } from './BillingFieldset.js';
import type {
  BILLING_CONTACT_EMAIL_FIELD_NAME,
  BILLING_CONTACT_FIRST_NAME_FIELD_NAME,
  BILLING_CONTACT_LAST_NAME_FIELD_NAME,
  BILLING_CONTACT_PHONE_FIELD_NAME,
} from './BillingContactFieldset.js';
import type { BillChannel } from '../../../generated/api/billChannel.js';
import type { COMPANY_MICROSOFT_TENANT_PREFIX_NAME } from './CompanyDetailsFieldset.js';
import type { CONTACT_EMAIL_FIELD_NAME, CONTACT_PHONE_FIELD_NAME } from './CompanyContactFieldset.js';
import type { PostAnonymousLeadRequest } from '../../../generated/api/postAnonymousLeadRequest';

interface NewInterworksAccountFormProps {
  accountMasterId: string;
  businessId: string;
  companyName: string;
  billChannels: BillChannel[];
  onCancel: () => void;
  firstName?: string;
  lastName?: string;
}

interface NewInterworksAccountFormValues {
  [COMPANY_POSTAL_ADDRESS_FIELD_NAME]: string;
  [COMPANY_POSTAL_CODE_FIELD_NAME]: string;
  [COMPANY_POST_OFFICE_FIELD_NAME]: string;
  [COMPANY_MICROSOFT_TENANT_PREFIX_NAME]: string;
  [CONTACT_EMAIL_FIELD_NAME]: string;
  [CONTACT_PHONE_FIELD_NAME]: string;
  [BILLING_CONTACT_FIRST_NAME_FIELD_NAME]: string;
  [BILLING_CONTACT_LAST_NAME_FIELD_NAME]: string;
  [BILLING_CONTACT_EMAIL_FIELD_NAME]: string;
  [BILLING_CONTACT_PHONE_FIELD_NAME]: string;
  [BILLING_DELIVERY_METHOD]: CreateInterworksAccountRequest.BillingDeliveryMethodEnum;
  [BILLING_BILL_E_OPERATOR]: string;
  [BILLING_BILL_E_ADDRESS]: string;
  [BILLING_ADDRESS_ALIAS]: string;
}

const NewInterworksAccountFormDefaultValues = {
  [BILLING_DELIVERY_METHOD]: CreateInterworksAccountRequest.BillingDeliveryMethodEnum.EMAIL,
};

const getNewIwAccountLeadRequest = (
  data: CreateInterworksAccountRequest,
  firstName: string,
  lastName: string,
  businessId: string,
  accountMasterId: string,
  companyName: string
): PostAnonymousLeadRequest => {
  if (!isInBrowser()) {
    throw new Error('Not in browser environment. Unable to send lead form for iw account.');
  }
  const values = data as CreateInterworksAccountRequest;
  return {
    contactDetails: {
      businessId: businessId,
      businessName: companyName,
      email: values.contactEmail,
      phoneNumber: values.contactPhoneNumber,
      firstName: firstName,
      lastName: lastName,
    },
    lead: {
      formParameters: {
        newInterworksAccountForm: JSON.stringify(data).replace(/"+/g, ''),
      },
      leadType: Lead.LeadTypeEnum.ONLINE_ORDER,
      sourceUrl: window.location.href,
    },
  };
};

export const NewInterworksAccountForm = (props: NewInterworksAccountFormProps) => {
  const methods = useForm<NewInterworksAccountFormValues>({
    mode: 'all',
    defaultValues: NewInterworksAccountFormDefaultValues,
  });
  const dispatch = useDispatch();
  const { handleSubmit } = methods;

  const onSubmit = async (re: NewInterworksAccountFormValues, _: string) => {
    try {
      const req: CreateInterworksAccountRequest = {
        billingAddress: {
          line1: re[COMPANY_POSTAL_ADDRESS_FIELD_NAME],
          postalCode: re[COMPANY_POSTAL_CODE_FIELD_NAME],
          postOffice: re[COMPANY_POST_OFFICE_FIELD_NAME],
          countryCode: DEFAULT_COUNTRY_CODE,
        },
        ...re,
      };
      // Prepare Anonymous Lead request
      const leadRequest = getNewIwAccountLeadRequest(
        req,
        props.firstName || '',
        props.lastName || '',
        props.businessId,
        props.accountMasterId,
        props.companyName
      );
      await postAnonymousLead(leadRequest);
      dispatch(
        showDialog({
          body: (
            <>
              <span>
                {t.SZBZ(
                  'If you have an existing 365 service (tenant) from another service provider, click the following link to accept this invitation and authorize Elisa Oyj - CSP to be your Microsoft Cloud Solution Provider and accept the Microsoft Customer Agreement.'
                )}
              </span>
              <CL.Link
                linkHref="https://admin.microsoft.com/Adminportal/Home?invType=ResellerRelationship&partnerId=d11dbe02-4edd-4edf-882f-dc2431a03ada&msppId=0#/partners/invitation"
                linkTarget="_blank"
              >
                https://admin.microsoft.com/Adminportal/Home?invType=ResellerRelationship&partnerId=d11dbe02-4edd-4edf-882f-dc2431a03ada&msppId=0#/partners/invitation
              </CL.Link>
              <span>
                {t.H4R7(
                  'Note: User with Global Admin permission is required to accept the relationship. The username is usually admin@XXXX.onmicrosoft.com.'
                )}
              </span>
              <p>
                {t.LSIF(
                  'If you want to use a new Microsoft account, just wait, and we will contact you when everything is ready.'
                )}
              </p>
            </>
          ),
          header: t.H69X('Request submitted successfully'),
          type: DialogType.GENERIC_INFO_DIALOG,
        })
      );
      pushLicenseManagementGAEventToDataLayer('new_iw_account_form_submitted');
    } catch (error) {
      dispatch(
        showDialog({
          body: <>{t.NMRJ(licenseManagementRedirectFailedMsg)}</>,
          header: t.DT3P(licenseManagementRedirectFailedHeaderMsg),
          type: DialogType.GENERIC_INFO_DIALOG,
        })
      );
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(async data => {
          await onSubmit(data, props.accountMasterId);
        })}
        noValidate
      >
        <div className={dsClass.MARGIN_BOTTOM_4}>
          <h3>{t.NF7W('Complete your account information')}</h3>
          <p>{t.G013('In order to use the licence management portal, complete the following information')}</p>
        </div>
        <CompanyDetailsFieldset companyName={props.companyName} businessId={props.businessId} />
        <CompanyContactFieldset />
        <BillingContactFieldset />
        <BillingFieldset billChannels={props.billChannels} />
        <ButtonGroupForSubmitAndBack
          className={`${dsClass.PADDING_TOP_4} ${dsClass.TEXT_ALIGN_RIGHT}`}
          submitButtonText="Submit"
          onSubmit={() => {}}
          cancelButtonText="Cancel"
          onCancel={props.onCancel}
          submitting={methods.formState.isSubmitting}
        />
      </form>
    </FormProvider>
  );
};
