import { ActionPhase } from '../common/storeUtils.js';
import { TypeKeys } from '../actions/index.js';
import { getErrorsFromUpsertBillingAccount } from '../../common/utils/billingAccountUtils.js';
import { reduceCrudAction } from './reducerUtils.js';
import { updateActionStatePhase } from '../common/index.js';
import type { ActionsHistory } from '../common/store.js';
import type { FixedBroadbandOrderState } from '../../common/types/states.js';
import type { SelfServiceActionTypes } from '../actions/index.js';
import type { SubmitFixedBroadbandOrderAction } from '../actions/fixedBroadbandActions.js';

export const getBillingAccountErrorsFromAction = (action: SubmitFixedBroadbandOrderAction) => {
  if (!action.newBillingAccount) {
    return undefined;
  }
  return getErrorsFromUpsertBillingAccount(
    action.newBillingAccount,
    action.newBillingAccountValidationErrors,
    action.newBillingAccountCommonFunction
  );
};

export function fixedBroadbandOrderReducer(
  state: (FixedBroadbandOrderState & ActionsHistory) | undefined,
  action: SelfServiceActionTypes
): (FixedBroadbandOrderState & ActionsHistory) | null {
  if (typeof state === 'undefined') {
    return null;
  }

  switch (action.type) {
    case TypeKeys.SUBMIT_FIXED_BROADBAND_ORDER: {
      const billingAccountErrors = getBillingAccountErrorsFromAction(action);

      if (billingAccountErrors) {
        return {
          ...state,
          errors: undefined,
        };
      }

      return {
        ...reduceCrudAction(action, state),
        saving: true,
        supportCaseSent: false,
      };
    }

    case TypeKeys.SUBMIT_FIXED_BROADBAND_ORDER_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.SUBMIT_FIXED_BROADBAND_ORDER,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        errors: undefined,
        saving: false,
        supportCaseSent: true,
      };
    }

    case TypeKeys.SUBMIT_FIXED_BROADBAND_ORDER_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.SUBMIT_FIXED_BROADBAND_ORDER,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
        saving: false,
        supportCaseSent: false,
      };
    }

    default:
      return state;
  }
}
