import type { AddOn } from '../../generated/api/addOn.js';
import type { AddOnRule } from '../types/addOnRule.js';
import type { AddOnVisibility } from '../../generated/api/addOnVisibility.js';

export type AddOnGroupMap = {
  [addOnGroup: string]: AddOn[];
};

export type CommercialProductAddOnVisibilitiesMap = {
  [commercialProductCode: string]: AddOnGroupMap;
};

export interface AddOnVisibilitiesWithCommercialProductCode {
  commercialProductCode: string;
  addOnVisibilities: AddOnVisibility[];
}

export const createReducingGroupRenamer =
  (
    reducedGroupToAddOnGroups: {
      [reducedGroupName: string]: string[];
    } = {}
  ): ((addOn: AddOn) => string) =>
  (addOn: AddOn): string => {
    for (const [reducedGroupName, addOnGroups] of Object.entries(reducedGroupToAddOnGroups)) {
      if (addOnGroups.includes(addOn.addOnGroup)) {
        return reducedGroupName;
      }
    }

    return addOn.addOnGroup;
  };

const findAddOnsByCommercialProductCode = (addOnRules: AddOnRule[], commercialProductCode: string): AddOn[] =>
  addOnRules
    .filter(r => r.commercialProductCode === commercialProductCode)
    .map(r => r.addOn)
    .filter(Boolean) as AddOn[];

const groupAddOnsByGroupName = (visibleAddOns: AddOn[], groupRenamerFn: (addOn: AddOn) => string) =>
  visibleAddOns.reduce(
    (groups, addOn) => {
      const groupName = groupRenamerFn(addOn);

      if (groupName in groups) {
        groups[groupName].push(addOn);
      } else {
        groups[groupName] = [addOn];
      }

      return groups;
    },
    {} as {
      [groupName: string]: AddOn[];
    }
  );

const addOnProductNameComparator = (left: AddOn, right: AddOn): number => {
  const nameOnLeft = left?.addOnProductName ?? '';

  return nameOnLeft.localeCompare(right?.addOnProductName ?? '');
};

export const createCommercialProductAddOnVisibilitiesMap = (
  addOnVisibilitiesWithCommercialProductCodes: AddOnVisibilitiesWithCommercialProductCode[],
  addOnRules: AddOnRule[],
  groupRenamerFn: (addOn: AddOn) => string
): CommercialProductAddOnVisibilitiesMap => {
  const visibleAddOnCodes = new Set<string>(
    addOnVisibilitiesWithCommercialProductCodes.flatMap(a =>
      a.addOnVisibilities.map(addOnVisibility => addOnVisibility.addOnGuidCode)
    )
  );

  return addOnVisibilitiesWithCommercialProductCodes.reduce((commercialProductAddOnVisibilitiesMap, v) => {
    const allAddOns = findAddOnsByCommercialProductCode(addOnRules, v.commercialProductCode);
    const visibleAddOns = allAddOns.filter((a: AddOn) => visibleAddOnCodes.has(a.addOnCode));
    const addOnsByGroupName = groupAddOnsByGroupName(visibleAddOns, groupRenamerFn);

    Object.values(addOnsByGroupName).forEach(addOns => addOns.sort(addOnProductNameComparator));

    return {
      ...commercialProductAddOnVisibilitiesMap,
      [v.commercialProductCode]: addOnsByGroupName,
    };
  }, {});
};
