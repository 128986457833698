// This matches the Salesforce rule:
// IF(AND(NOT(ISBLANK(Phone)),LEFT(Phone, 1) == "0"), NOT(
// REGEX( Phone , "([0]{1})([0-9]+)" )
// && LEN(SUBSTITUTE(Phone , " ", "") ) < 14
// && LEN(SUBSTITUTE(Phone , " ", "") ) > 4
// ),IF(NOT(ISBLANK(Phone)), NOT(REGEX( Phone , "(([\+])([1-9]{1}))([0-9]+)" )
// && LEN(SUBSTITUTE(Phone , " ", "") ) < 17
// && LEN(SUBSTITUTE(Phone , " ", "") ) > 7
// ),False))
export const PHONE_NUMBER_REGEX = /^(\+[1-9](?:\x20?[0-9]\x20?){6,14}|0(?:\x20?[0-9]\x20?){4,12})$/;
export const FINNISH_PHONE_NUMBER_REGEX = /^((\+358|0)(?:\x20?[0-9]\x20?){4,12})$/;
export const FINNISH_PHONE_NUMBER_MAX_LENGTH = 16;

export const isValidPhoneNumber = (input: string) => {
  return PHONE_NUMBER_REGEX.test(input);
};

// Formats the spacing of most common finnish phone numbers
export const formatPhoneNumber = (phoneNumber: string, forceLocalFormat?: boolean) => {
  // null & empty check
  if (!phoneNumber) {
    return phoneNumber;
  }
  let normalizedPhoneNumber = phoneNumber;
  let isNormalized = false;
  // We are supporting +358 and 358 prefixes currently because both are returned from SFDC.
  // in the future one prefix should only be used.
  if (/^\+?358.*/.test(phoneNumber)) {
    // treat +358 as zero with regards to formatting the spaces
    normalizedPhoneNumber = normalizedPhoneNumber.replace(/\+?358/, '0');
    isNormalized = true;
  }
  let firstSpaceIndex;
  let secondSpaceIndex;
  if (/^0[2-3|5-9][1-9]\d*/.test(normalizedPhoneNumber)) {
    // Landline 0[2-3|5-9] XXXXX
    firstSpaceIndex = 2;
    secondSpaceIndex = 5;
  } else if (/^01[3-9]\d*/.test(normalizedPhoneNumber)) {
    // Landline 01[3-9] XXXXX
    firstSpaceIndex = 3;
    secondSpaceIndex = 6;
  } else if (/^0[1-9][0-6]\d*/.test(normalizedPhoneNumber)) {
    if (/^0[1-9]00\d*/.test(normalizedPhoneNumber)) {
      // 0X00...
      firstSpaceIndex = 4;
      secondSpaceIndex = 7;
    } else {
      // Assuming the normal form 050 123 4567
      firstSpaceIndex = 3;
      secondSpaceIndex = 6;
    }
  }

  const splitPhoneNumber = normalizedPhoneNumber.trim().split('');
  if (
    firstSpaceIndex &&
    secondSpaceIndex &&
    splitPhoneNumber[firstSpaceIndex] &&
    splitPhoneNumber[secondSpaceIndex] &&
    splitPhoneNumber[firstSpaceIndex] !== ' ' &&
    splitPhoneNumber[secondSpaceIndex] !== ' '
  ) {
    splitPhoneNumber[firstSpaceIndex] = ` ${splitPhoneNumber[firstSpaceIndex]}`;
    splitPhoneNumber[secondSpaceIndex] = ` ${splitPhoneNumber[secondSpaceIndex]}`;
  }

  if (isNormalized && !forceLocalFormat) {
    // revert the +358 if it was present
    splitPhoneNumber[0] = '+358 ';
  }
  return splitPhoneNumber.join('');
};

export const forceInternationalPhoneNumberFormat = (phoneNumber: string | undefined) =>
  phoneNumber !== undefined ? phoneNumber.replace(/^0/, '+358').replace(/^358/, '+358') : undefined;

export interface ValidatedPhoneNumber {
  phoneNumber: string;
  valid: boolean;
  portable?: boolean;
  error?: string;
}
