import { type FieldValues, type FormState } from 'react-hook-form';
import { OFPageType } from '../../generated/api/oFPageType.js';
import { OFQuestionType } from '../../generated/api/oFQuestionType.js';
import { OpenFormRules } from './OpenFormRules.js';
import { getDate } from './OpenFormUtils.js';
import { hasGUID, hasQuestionType } from './OpenForm.js';
import type { Address } from '../../generated/api/address.js';
import type { BillingAccount } from '../../generated/api/billingAccount.js';
import type { BillingAccountDeliveryMethod } from '../../generated/api/billingAccountDeliveryMethod.js';
import type { ContactHeader } from '../../generated/api/contactHeader.js';
import type { OpenFormChoice } from '../../generated/api/openFormChoice.js';
import type { OpenFormCustomerNeedQuestion } from '../../generated/api/openFormCustomerNeedQuestion.js';
import type { OpenFormQuestion } from '../../generated/api/openFormQuestion.js';
import type { OpenFormSection } from '../../generated/api/openFormSection.js';

export type AvailabilityAddress = Address & {
  addressId: string;
  addressText: string;
  products: Record<string, boolean>;
};

export type BillingAccountData = {
  accountMasterId?: string;
  billElectronicAddress?: string;
  billElectronicOperator?: string;
  billFormatType?: BillingAccount.BillFormatTypeEnum;
  billLanguage?: 'FI' | 'SV' | 'EN';
  billReceiverEmail?: string;
  billingAccountName?: string;
  billingContactEmail?: string;
  billingContactId?: string;
  billingContactName?: string;
  billingContactPhone?: string;
  billingAccountExtensionName?: string;
  customerReference1?: string;
  customerReference2?: string;
  deliveryMethod?: BillingAccountDeliveryMethod;
  payerAddress?: Address;
  payerBusinessId?: string;
  payerName?: string;
  payerNameExtension?: string;
};

export type Choices = string[];

export type Row<
  ChoiceGUID extends string = string,
  HeaderName extends string = string,
  ChoiceValue extends string = string,
> = Record<ChoiceGUID, Record<HeaderName, ChoiceValue>>;

export type SubscriptionTypes = string[];

export type Context = {
  address?: AvailabilityAddress;
  billingAccount?: BillingAccount;
  billingAccountData?: BillingAccountData;
  choices?: Choices;
  contact?: ContactHeader;
  row?: Row;
  subscriptionTypes?: SubscriptionTypes;
};

export class OpenFormAnswers extends Map<string, Context> {
  public readonly visible: <T extends OpenFormChoice | OpenFormQuestion>(target: T) => target is T;

  constructor(answers?: OpenFormAnswers | Record<string, Context>) {
    super(!answers ? undefined : answers instanceof OpenFormAnswers ? answers : Object.entries(answers));
    this.visible = OpenFormRules.isVisible(this);
  }

  assign<K extends keyof Context>(id: string, key: K, patch?: Context[K]) {
    return this.set(id, { ...this.get(id), [key]: patch });
  }

  entriesOf<K extends keyof Context>(key: K): [string, NonNullable<Context[K]>][] {
    return Array.from(this).reduce(
      (acc, [guid, context]) => (context?.[key] && acc.push([guid, context[key]]) && acc) || acc,
      [] as [string, NonNullable<Context[K]>][]
    );
  }

  get(key: string | undefined) {
    return key === undefined ? key : super.get(key);
  }

  valuesOf<K extends keyof Context>(key: K): NonNullable<Context[K]>[] {
    return Array.from(this.values()).reduce(
      (acc, context) => (context?.[key] && acc.push(context[key]) && acc) || acc,
      [] as NonNullable<Context[K]>[]
    );
  }

  readonly getAnswers = (question: OpenFormQuestion): OpenFormCustomerNeedQuestion | undefined => {
    const choices = this.get(question.guid)?.choices;
    if (!choices?.length) {
      return undefined;
    }
    switch (question.type) {
      case 'LIST_OF_OBJECTS_MULTI_SELECT':
      case 'LIST_OF_OBJECTS_SINGLE_SELECT':
      case 'MULTI_SELECT':
      case 'SINGLE_SELECT': {
        return { guid: question.guid, answers: choices.map(choice => ({ guid: choice })) };
      }
      case 'ADDITIONAL_INFORMATION_BACKOFFICE':
      case 'ADDITIONAL_INFORMATION_FIELD_ENGINEER':
      case 'BA_SELECTION':
      case 'INSTALLATION_ADDRESS':
      case 'INSTALLATION_CONTACT':
      case 'DELIVERY_CONTACT':
      case 'FAULT_INCIDENT_CONTACT':
      case 'ORDERING_CONTACT':
      case 'TECHNICAL_CONTACT':
      case 'TIME_OF_DELIVERY': {
        return { guid: question.guid, answers: choices.map(choice => ({ value: choice })) };
      }
      case 'DATE_OF_DELIVERY': {
        return { guid: question.guid, answers: choices.map(choice => ({ value: getDate(choice) })) };
      }
      case 'FREE_TEXT': {
        switch (question.dataType) {
          case 'DATE_FIELD': {
            return { guid: question.guid, answers: choices.map(choice => ({ value: getDate(choice) })) };
          }
          case 'NUMBER_INTEGER': {
            return { guid: question.guid, answers: choices.map(choice => ({ value: Number(choice).toFixed() })) };
          }
          case 'EMAIL':
          case 'PHONE':
          case 'TEXT':
          case 'TIME_FIELD': {
            return { guid: question.guid, answers: choices.map(choice => ({ value: choice })) };
          }
        }
      }
    }
  };

  readonly hasAnswer = (question: OpenFormQuestion) => {
    const choices = this.get(question.guid)?.choices;
    if (!choices?.length) {
      return false;
    }
    switch (question.type) {
      case 'ADDITIONAL_INFORMATION_BACKOFFICE':
      case 'ADDITIONAL_INFORMATION_FIELD_ENGINEER':
      case 'BA_SELECTION':
      case 'DATE_OF_DELIVERY':
      case 'DELIVERY_CONTACT':
      case 'FAULT_INCIDENT_CONTACT':
      case 'FREE_TEXT':
      case 'INSTALLATION_ADDRESS':
      case 'INSTALLATION_CONTACT':
      case 'ORDERING_CONTACT':
      case 'TECHNICAL_CONTACT':
      case 'TIME_OF_DELIVERY': {
        return choices.every(choice => choice.length);
      }
      case 'LIST_OF_OBJECTS_MULTI_SELECT':
      case 'LIST_OF_OBJECTS_SINGLE_SELECT':
      case 'MULTI_SELECT':
      case 'SINGLE_SELECT': {
        return choices.every(choice => question.choices.some(hasGUID(choice)));
      }
    }
  };

  readonly isActive = (section: OpenFormSection) => {
    if ([OFPageType.AVAILABILITY_CHECK, OFPageType.ORDER_ENRICHMENT].includes(section.pageType)) {
      return true;
    }
    return section.questions.some(
      q => this.visible(q) && (hasQuestionType(OFQuestionType.FREE_TEXT)(q) || q.choices.some(this.visible))
    );
  };

  readonly isCompleted = (section: OpenFormSection) =>
    section.questions.filter(q => this.visible(q) && q.isMandatory).every(q => this.hasAnswer(q));

  readonly nextErrored = (section: OpenFormSection, formState: FormState<FieldValues>) =>
    section.questions.find(q => this.visible(q) && ((q.isMandatory && !this.hasAnswer(q)) || formState.errors[q.guid]));
}
