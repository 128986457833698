import { BlankHeading } from '../BlankHeading/BlankHeading.js';
import { BreadCrumbsWithTitle } from '../../components/BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { NewSupportCase } from '../NewSupportCase/NewSupportCase.js';
import { omaElisaForCompaniesMsg, supportCasesMsg, t } from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';

import './NewSupportCaseAuthenticated.scss';

export const NewSupportCaseAuthenticated = () => {
  return (
    <div className="of-create-case-authenticated">
      <BlankHeading
        detailsTop={
          <BreadCrumbsWithTitle
            breadCrumbPaths={[
              { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
              { name: t.OEZW(supportCasesMsg), path: paths.SUPPORT_CASES },
              { name: t.TFDQ('Create new') },
            ]}
          />
        }
        title={t.CZNY('Create a message or support case')}
      />
      <div className="content">
        <NewSupportCase selfService={true} />
      </div>
    </div>
  );
};
