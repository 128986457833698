import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

class FluidGrid extends React.Component {
  static propTypes = {
    /** Class names you want to give to the component */
    className: PropTypes.string,
    /** Component's content. Automatically detected. */
    children: PropTypes.node,
    /** Whether borders are drawn around the grid elements */
    bordered: PropTypes.bool,
    /** */
    animated: PropTypes.bool,
    /** Whether the grid is displayed as a column (true) or grid (false) of elements. */
    column: PropTypes.bool,
    /** Spacing between items, "padding" */
    gutter: PropTypes.oneOf(["none", "minimal", "normal"]),
  };

  render() {
    const { className, gutter, children, bordered, animated, column, ...props } = this.props;

    const classes = [
      "ea-fgrid",
      { "ea-fgrid--gutter-mini": gutter === "minimal" },
      { "ea-fgrid--gutter": gutter === "normal" },
      { "ea-fgrid--bordered": bordered },
      { "ea-fgrid--animated": animated },
      { "ea-fgrid--column": column },
    ];

    return (
      <div className={classnames([classes, className])} {...props}>
        {children}
      </div>
    );
  }
}

FluidGrid.defaultProps = {
  className: "",
  children: null,
  bordered: false,
  column: false,
  animated: false,
  gutter: null,
};

export default FluidGrid;
