import {
  FORM_GRID_FULL_WIDTH,
  FormGridFieldset,
} from '../../../common/react-hook-form/FormGridFieldset/FormGridFieldset.js';
import { POSTAL_CODE_MAX_LENGTH } from '../../../common/utils/validationUtils.js';
import { PostOffice } from '../../../common/react-hook-form/fields/PostOffice.js';
import { PostalCode, TextInput } from '../../../common/react-hook-form/fields/index.js';
import { StreetAddress } from '../../../common/react-hook-form/fields/StreetAddress.js';
import {
  businessIdMsg,
  companyDetailsMsg,
  companyNameMsg,
  postOfficeMsg,
  postalAddressMsg,
  postalCodeMsg,
  t,
} from '../../../common/i18n/index.js';
import { dsClass } from '../../../common/constants/dsClasses.js';

export interface CompanyDetailsProps {
  companyName: string;
  businessId: string;
}
export const COMPANY_POSTAL_ADDRESS_FIELD_NAME = 'line1';
export const COMPANY_POSTAL_CODE_FIELD_NAME = 'postalCode';
export const COMPANY_POST_OFFICE_FIELD_NAME = 'postOffice';

export const COMPANY_MICROSOFT_TENANT_PREFIX_NAME = 'microsoftTenantPrefix';

export const CompanyDetailsFieldset = ({ companyName, businessId }: CompanyDetailsProps) => {
  return (
    <FormGridFieldset legend={t.EHOL(companyDetailsMsg)}>
      <div className={dsClass.MARGIN_BOTTOM_4}>
        <h5 className={dsClass.MARGIN_0}>{t.AJ93(companyNameMsg)}</h5>
        <span>{companyName}</span>
      </div>
      <div className={dsClass.MARGIN_BOTTOM_4}>
        <h5 className={dsClass.MARGIN_0}>{t.MPA5(businessIdMsg)}</h5>
        <span>{businessId}</span>
      </div>
      <StreetAddress label={t.NDWK(postalAddressMsg)} name={COMPANY_POSTAL_ADDRESS_FIELD_NAME} />
      <PostalCode
        allowPoBox={true}
        label={t.RUAW(postalCodeMsg)}
        maxLength={POSTAL_CODE_MAX_LENGTH}
        name={COMPANY_POSTAL_CODE_FIELD_NAME}
        placeholder=""
      />
      <PostOffice label={t.Z7S5(postOfficeMsg)} name={COMPANY_POST_OFFICE_FIELD_NAME} />
      <div className={FORM_GRID_FULL_WIDTH}>
        <TextInput
          label={t.K10A('Existing Microsoft domain/tenant name')}
          placeholder={t.CO0D('Leave empty of you want a new tenant')}
          name={COMPANY_MICROSOFT_TENANT_PREFIX_NAME}
          required={false}
        />
      </div>
    </FormGridFieldset>
  );
};
