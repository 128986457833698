/**
 * SFDC Anonymous API
 * # Summary Elisa B2B API for an anonymous access to salesforce data.  # Usage This API is used in the `https://yrityksille.elisa.fi/omaelisa` pages, but it is possible to later on expose this documentation publicly and make the endpoint queryable via external clients. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Enrollment program name associated with the offer. Value as NOT_SET means no enrollmentProgram type set in salesforce. SFDC: Product Configuration attribute with name \'Enrollment Program Type\' 
 */
export type EnrollmentProgramType = 'DEP' | 'KME' | 'ZEROTOUCH' | 'AUTOPILOT' | 'UNKNOWN' | 'NOT_SET';

export const EnrollmentProgramType = {
    DEP: 'DEP' as EnrollmentProgramType,
    KME: 'KME' as EnrollmentProgramType,
    ZEROTOUCH: 'ZEROTOUCH' as EnrollmentProgramType,
    AUTOPILOT: 'AUTOPILOT' as EnrollmentProgramType,
    UNKNOWN: 'UNKNOWN' as EnrollmentProgramType,
    NOT_SET: 'NOT_SET' as EnrollmentProgramType
};

