import { SiteContext } from '../../../public/site/SiteContext.js';
import {
  blockAllDataMsg,
  blockDataAbroadMsg,
  blockDataOutsideEuMsg,
  furtherDetailPhonesAbroadMsg,
  t,
} from '../../../common/i18n/index.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { useContext } from 'react';

export const DataRoamingTooltipBody = () => (
  <>
    <div className={dsClass.PADDING_TOP_4}>
      <h4 className="ea-h4 ea-h4--no-fluid">{t.SBCJ(blockDataOutsideEuMsg)}</h4>
      {t.GY75(
        'Blocks the use of mobile data and MMS outside the EU and EEA countries. A block ensures that you do not accidentally use mobile data.'
      )}
    </div>
    <div className={dsClass.PADDING_TOP_4}>
      <h4 className="ea-h4 ea-h4--no-fluid">{t.L07L(blockDataAbroadMsg)}</h4>
      {t.VNWG('Blocks the use of mobile data and MMS abroad.')}
    </div>
    <div className={dsClass.PADDING_TOP_4}>
      <h4 className="ea-h4 ea-h4--no-fluid">{t.CO7W(blockAllDataMsg)}</h4>
      {t.IVJX('Blocks use of mobile data. Phone calls and SMS work normally.')}
    </div>
    <div className={dsClass.PADDING_VERTICAL_4}>
      <a
        className="of-barrings-content__link"
        href={`${useContext(SiteContext).siteBaseUrl}/verkkovierailu-ulkomailla`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t.XHYX(furtherDetailPhonesAbroadMsg)}
        <i className="ea-icon ea-icon--northeast-arrow" />
      </a>
    </div>
  </>
);
