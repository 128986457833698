import {
  AddOnPurpose,
  CommercialProductType,
  DeliveryType,
  OnlineOrderItemUnit,
  SimType,
} from '../../generated/api/models.js';
import { ContactCreationType } from '../../components/ContactOrPurposeOfUse/ContactsOrPurposeOfUseUtils.js';
import { PhoneNumberOwnerType, PhoneNumberType, SimCardSelection } from '../../common/enums.js';
import { forkJoin } from 'rxjs';
import { getContactFromContactPerson } from '../../common/utils/contactUtils.js';
import { isCampaignPresent } from '../../common/utils/campaignUtils.js';
import { isRetainNumberProduct } from '../../common/utils/cartProductUtils.js';
import { t } from '../../common/i18n/index.js';
import { upsertContact } from './contactEpic.js';
import { upsertContactFailed, upsertContactFulfilled } from '../actions/index.js';
import type {
  AddOn,
  CampaignAssociation,
  CommercialProduct,
  Contact,
  ContactPerson,
  ContactUserContact,
  MobilePbxNewSubscriptionDetails,
  PutContactResponse,
} from '../../generated/api/models.js';
import type { ConfiguredCommercialProduct, ConfiguredOffer } from '../../common/types/commercialProduct.js';
import type { EpicDependencies } from './epicUtils.js';
import type { ErrorAction, TypeKeys, UpsertContactFulfilledAction } from '../actions/index.js';
import type { Observable } from 'rxjs';
import type { PurposeOfUseOrContact, SimCardConfiguration } from '../../common/types/subscription.js';
import type { State } from '../common/store.js';
import type { StateObservable } from 'redux-observable';

const convertToUtcMidnight = (date: Date): number => {
  return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
};

const mapVoiceSubProps = (product: ConfiguredCommercialProduct): Partial<OnlineOrderItemUnit> | undefined => {
  const selectedPhoneNumer = product.selectedPhoneNumber;
  return (
    selectedPhoneNumer && {
      differentDonor: product.phoneNumberOwner!.ownerType === PhoneNumberOwnerType.PERSON,
      donorEmail: product.phoneNumberOwner!.personEmail,
      fixedTermContract: product.phoneNumberOwner!.permitDonoringOnFixedContract,
      mobileNumber:
        selectedPhoneNumer.type === PhoneNumberType.EXISTING
          ? selectedPhoneNumer.existingPhoneNumber!.replace(/\s/g, '')
          : selectedPhoneNumer.newPhoneNumber!.replace(/\s/g, ''),
      numberPorting:
        selectedPhoneNumer.type === PhoneNumberType.EXISTING
          ? OnlineOrderItemUnit.NumberPortingEnum.PORT_IN
          : OnlineOrderItemUnit.NumberPortingEnum.NEW_NUMBER,
      numberPublicity: product.numberPublicity,
      portInDate: selectedPhoneNumer.transferDate ? convertToUtcMidnight(selectedPhoneNumer.transferDate) : undefined,
    }
  );
};

export const contactUserContactEquals = (a: ContactUserContact, b: ContactUserContact) =>
  a.firstName === b.firstName && a.lastName === b.lastName;

const getSimCardText = (simConf: SimCardConfiguration | undefined): string => {
  const orderEsimText = 'Tilaa eSIM';
  const activateExistingSimCardText = 'Aktivoi SIM-kortti';
  const orderNewSimCardText = 'Tilataan uusi SIM-kortti';
  let simCardText = orderNewSimCardText;
  if (simConf && simConf.simType === SimType.ESIM) {
    simCardText = orderEsimText;
  }
  // TODO: leo 13.4.2022 - this seems to be only needed for public site orders. Currently public site Laitenetti orders
  //  go via OEC, if Laitenetti ordering from public site will be changed to be handled by online-ui, then this will need changes.
  //  Same thing in checkoutUtils.tsx.
  if (simConf && simConf.simType === SimType.PHYSICAL) {
    simCardText =
      simConf.simSelection === SimCardSelection.ORDER_NEW
        ? orderNewSimCardText
        : `${activateExistingSimCardText}: ${simConf.simCardNumber}`;
  }
  return simCardText;
};

const getRetainExistingNumberText = (product: ConfiguredCommercialProduct, index: number): string | undefined => {
  const ownerEmailMsg = 'Omistajan email';
  const companyIsOwnerMsg = 'Yritys omistaa liittymän';
  const companyAuthorizesPortingMsg =
    ' ja antaa luvan numeron siirtoon vaikka siinä olisi määräaikainen sopimus kesken';
  const subscriptionMsg = 'LIITTYMÄ';
  if (isRetainNumberProduct(product.commercialProduct.commercialProductName)) {
    const retainNumberText = `${t.TOL1('Retain Existing Number')}: ${product.selectedPhoneNumber?.existingPhoneNumber}`;
    const ownerText =
      product.phoneNumberOwner?.ownerType === PhoneNumberOwnerType.PERSON
        ? `${ownerEmailMsg}: ${product.phoneNumberOwner.personEmail}`
        : `${companyIsOwnerMsg}${
            (product.phoneNumberOwner?.permitDonoringOnFixedContract && companyAuthorizesPortingMsg) ?? ''
          }`;
    return `${subscriptionMsg} ${index + 1}: ${retainNumberText}, ${ownerText}`;
  }
  return undefined;
};

const getAdditionalParameter = (product: ConfiguredCommercialProduct, index: number): { [key: string]: string } => {
  const additionalParameter = product.simCardConfiguration && { simCard: getSimCardText(product.simCardConfiguration) };
  const retainNumberText = getRetainExistingNumberText(product, index);
  if (retainNumberText) {
    return {
      ...additionalParameter,
      ...product.additionalFields,
      [product.commercialProduct.commercialProductName]: retainNumberText,
    };
  }
  return {
    ...additionalParameter,
    ...product.additionalFields,
  };
};

const getDeliveryType = (
  commercialProduct: CommercialProduct,
  simConf?: SimCardConfiguration,
  deliveryMethodType?: DeliveryType
): DeliveryType => {
  if (commercialProduct.productType === CommercialProductType.SALES_PRODUCT && !simConf) {
    // No deliverable for sales products without sim (such as yritystietoturva)
    return DeliveryType.NONE;
  } else {
    if (!simConf?.simType) {
      return deliveryMethodType || DeliveryType.NONE;
    } else if (simConf.simSelection === SimCardSelection.ACTIVATE_EXISTING || simConf.simType === SimType.ESIM) {
      return DeliveryType.NONE;
    } else {
      return DeliveryType.LETTER;
    }
  }
};

const getAddOnCampaignAssociationCode = (addOnCode: string, addOns?: AddOn[]) => {
  const matchingAddonCampaignAssociation = addOns?.find(addOn => addOn.addOnCode === addOnCode)?.campaignAssociations;
  return matchingAddonCampaignAssociation && matchingAddonCampaignAssociation[0]?.campaignAssociationCode;
};

export const createContacts = (
  configuredOffers: ConfiguredOffer[],
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
): Observable<(UpsertContactFulfilledAction | ErrorAction<TypeKeys>)[]> => {
  return forkJoin(
    configuredOffers
      .flatMap(item =>
        item.selectedCommercialProducts.map(
          configuredCommercialProduct => configuredCommercialProduct.purposeOfUseOrContact
        )
      )
      .filter(purposeOfUseOrContact => purposeOfUseOrContact.newContact !== undefined)
      .reduce((purposeOfUseOrContacts: PurposeOfUseOrContact[], current: PurposeOfUseOrContact) => {
        // reducing purposeOfUseOrContacts with unique new contacts having same first name and last name
        if (!purposeOfUseOrContacts.find(puc => contactUserContactEquals(puc.newContact!, current.newContact!))) {
          purposeOfUseOrContacts.push(current);
        }
        return purposeOfUseOrContacts;
      }, [])
      .map(purposeOfUseOrContact => ({
        contactCreationType: purposeOfUseOrContact.contactId!,
        contact: getContactFromContactPerson(purposeOfUseOrContact.newContact!),
      }))
      .map(newContact =>
        upsertContact(
          newContact.contact,
          epicDependencies,
          state$,
          upsertContactFailed,
          (response: PutContactResponse) => {
            return upsertContactFulfilled(response, newContact.contact);
          },
          newContact.contactCreationType === ContactCreationType.CREATE_NEW_CONTACT_FORCE_UPSERT
        )
      )
  );
};

export const insertContactIdsIntoOffers = (configuredOffers: ConfiguredOffer[], contacts: Contact[]) => {
  return configuredOffers.map(item => ({
    ...item,
    selectedCommercialProducts: item.selectedCommercialProducts.map(selectedCp => {
      const newContact =
        selectedCp.purposeOfUseOrContact.newContact ||
        (selectedCp.purposeOfUseOrContact.contactId === ContactCreationType.COPIED_CONTACT
          ? ({
              firstName: selectedCp.purposeOfUseOrContact.firstName,
              lastName: selectedCp.purposeOfUseOrContact.lastName,
            } as ContactUserContact)
          : undefined);
      if (newContact !== undefined) {
        const matchingContact = contacts.find(contact =>
          contactUserContactEquals((contact.person || contact.userContact)!, newContact)
        );
        if (matchingContact !== undefined) {
          return {
            ...selectedCp,
            purposeOfUseOrContact: {
              contactId: matchingContact.contactId!,
              costCenter: selectedCp.purposeOfUseOrContact?.costCenter,
              employeeNumber: selectedCp.purposeOfUseOrContact?.employeeNumber,
            },
          };
        } else {
          // Contact creation has failed. Insert users name into purposeOfUse (old way),
          // because we don't want to fail the order because of this
          return {
            ...selectedCp,
            purposeOfUseOrContact: {
              purposeOfUse: `${newContact.firstName} ${newContact.lastName}`,
              costCenter: selectedCp.purposeOfUseOrContact?.costCenter,
              employeeNumber: selectedCp.purposeOfUseOrContact?.employeeNumber,
            },
          };
        }
      } else {
        return selectedCp;
      }
    }),
  }));
};

export const mapCommercialProductToOnlineOrderItemUnit = (
  product: ConfiguredCommercialProduct,
  index: number,
  deliveryMethodType?: DeliveryType,
  contactPerson?: ContactPerson
): OnlineOrderItemUnit => {
  const voiceProps: Partial<OnlineOrderItemUnit> | undefined = mapVoiceSubProps(product);

  let salesProductProps: Partial<OnlineOrderItemUnit> | undefined;
  if (product.commercialProduct.productType === CommercialProductType.SALES_PRODUCT) {
    salesProductProps = {
      numberPublicity: product.numberPublicity,
      additionalParameters: getAdditionalParameter(product, index),
    };
  }

  let mobilePbxNewSubscriptionDetails: MobilePbxNewSubscriptionDetails | undefined = undefined;
  if (product.configuredRingOffer) {
    const ringProduct = product.configuredRingOffer.selectedCommercialProducts[0];
    mobilePbxNewSubscriptionDetails = {
      serviceLevelCommercialProductCode: ringProduct.commercialProduct.commercialProductCode,
      offerCode: product.configuredRingOffer?.offer.offerCode,
      mobilePbxAddOns: ringProduct.commercialProduct.addOnAssociations,
      pbxDetails: ringProduct.mobilePbxServiceLevelDetails,
    };
  }

  const simConf = product.simCardConfiguration;
  return {
    // TODO: support for other delivery types
    deliveryType: getDeliveryType(product.commercialProduct, simConf, deliveryMethodType),
    purposeOfUse: product.purposeOfUseOrContact.purposeOfUse,
    costCenter: product.purposeOfUseOrContact.costCenter || contactPerson?.costCenter || '',
    subscriptionReference: product.purposeOfUseOrContact.employeeNumber || contactPerson?.employeeNumber,
    simType: simConf && (simConf?.simType || SimType.PHYSICAL),
    simCardNumber:
      simConf && simConf.simSelection === SimCardSelection.ACTIVATE_EXISTING ? simConf.simCardNumber : undefined,
    userContact: product.purposeOfUseOrContact.contactId,
    ...voiceProps,
    ...salesProductProps,
    mobilePbxServiceLevelDetails: product.mobilePbxServiceLevelDetails,
    mobilePbxNewSubscriptionDetails,
  };
};

export const getNewContacts = (configuredOffers: ConfiguredOffer[]) => {
  return configuredOffers
    .map(item =>
      item.selectedCommercialProducts.map(configuredOffer => configuredOffer.purposeOfUseOrContact.newContact)
    )
    .reduce((prev, next) => prev.concat(next)) // flattens
    .filter(newContact => newContact !== undefined);
};

export const getSalesProductsOrderItems = (configuredOffers: ConfiguredOffer[], deliveryMethodType?: DeliveryType) => {
  return configuredOffers
    .filter(
      item => item.selectedCommercialProducts[0].commercialProduct.productType === CommercialProductType.SALES_PRODUCT
    )
    .map(item => {
      return {
        commercialProductCodes: item.selectedCommercialProducts[0].commercialProductCodes!,
        offerCode: item.offer.offerCode,
        orderItemQuantity: item.selectedCommercialProducts.length,
        orderItemUnits: item.selectedCommercialProducts.map((product, index) =>
          mapCommercialProductToOnlineOrderItemUnit(product, index, deliveryMethodType)
        ),
      };
    });
};

export const getDefaultAddOns = (
  configuredCommercialProduct: ConfiguredCommercialProduct,
  addOns?: AddOn[],
  campaignAssociations?: Array<CampaignAssociation>
) => {
  return (
    configuredCommercialProduct.commercialProduct.addOnAssociations
      ?.filter(
        addOnAssociation =>
          addOnAssociation.addOnPurpose === AddOnPurpose.BY_DEFAULT ||
          configuredCommercialProduct.addedAddOns?.find(
            addedAddOn => addOnAssociation.addOnCode === addedAddOn.addOnCode
          )
      )
      .map(addOnAssociation => ({
        addOnAssociationCode: addOnAssociation.addOnAssociationCode,
        addOnCode: addOnAssociation.addOnCode,
        addOnAttributes: configuredCommercialProduct.addedAddOns?.find(
          addedAddOn => addOnAssociation.addOnCode === addedAddOn.addOnCode
        )?.addOnAttributes,
      }))
      .map(orderItemAddOns => ({
        ...orderItemAddOns,
        campaignAssociationCode: isCampaignPresent(campaignAssociations)
          ? getAddOnCampaignAssociationCode(orderItemAddOns.addOnCode, addOns)
          : undefined,
      })) || []
  );
};
