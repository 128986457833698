import { type OpenFormAnswers } from '../../OpenFormAnswers.js';
import { type OpenFormDispatcher } from '../../OpenFormHooks/useOpenFormDispatcher.js';
import { OpenFormQuestionBillingAccount } from './OpenFormQuestionBillingAccount.js';
import { OpenFormQuestionContact } from './OpenFormQuestionContact.js';
import { OpenFormQuestionDate } from './OpenFormQuestionDate.js';
import { OpenFormQuestionInput } from './OpenFormQuestionInput.js';
import { OpenFormQuestionInstallationAddress } from './OpenFormQuestionInstallationAddress.js';
import { OpenFormQuestionListOfObjects } from './OpenFormQuestionListOfObjects.js';
import { OpenFormQuestionSelectGroup } from './OpenFormQuestionSelectGroup.js';
import type { OpenFormQuestion } from '../../../../generated/api/openFormQuestion.js';

export const OpenFormQuestionType = ({
  dispatcher,
  answers,
  question,
  disabled,
}: {
  dispatcher: OpenFormDispatcher;
  answers: OpenFormAnswers;
  question: OpenFormQuestion;
  disabled: boolean;
}) => {
  switch (question.type) {
    case 'LIST_OF_OBJECTS_MULTI_SELECT': {
      return (
        <OpenFormQuestionListOfObjects
          dispatcher={dispatcher}
          choices={question.choices.filter(answers.visible)}
          context={answers.get(question.guid)}
          disabled={disabled}
          required={question.isMandatory}
          multiselect={true}
          headers={question.listColumns}
          label={question.label}
        />
      );
    }
    case 'LIST_OF_OBJECTS_SINGLE_SELECT': {
      return (
        <OpenFormQuestionListOfObjects
          dispatcher={dispatcher}
          choices={question.choices.filter(answers.visible)}
          context={answers.get(question.guid)}
          disabled={disabled}
          required={question.isMandatory}
          multiselect={false}
          headers={question.listColumns}
          label={question.label}
        />
      );
    }
    case 'MULTI_SELECT': {
      return (
        <OpenFormQuestionSelectGroup
          dispatcher={dispatcher}
          choices={question.choices.filter(answers.visible)}
          disabled={disabled}
          required={question.isMandatory}
          multiselect={true}
          label={question.label}
          name={question.guid}
          selected={answers.get(question.guid)?.choices}
        />
      );
    }
    case 'SINGLE_SELECT': {
      return (
        <OpenFormQuestionSelectGroup
          dispatcher={dispatcher}
          choices={question.choices.filter(answers.visible)}
          disabled={disabled}
          required={question.isMandatory}
          multiselect={false}
          label={question.label}
          name={question.guid}
          selected={answers.get(question.guid)?.choices}
        />
      );
    }
    case 'BA_SELECTION': {
      return (
        <OpenFormQuestionBillingAccount
          dispatcher={dispatcher}
          disabled={disabled}
          required={question.isMandatory}
          label={question.label}
          context={answers.get(question.guid)}
        />
      );
    }
    case 'DATE_OF_DELIVERY': {
      return (
        <OpenFormQuestionDate
          disabled={disabled}
          required={question.isMandatory}
          label={question.label}
          name={question.guid}
          offset={question.offset}
          selected={answers.get(question.guid)?.choices}
        />
      );
    }
    case 'INSTALLATION_ADDRESS': {
      return (
        <OpenFormQuestionInstallationAddress
          dispatcher={dispatcher}
          context={answers.get(question.guid)}
          required={question.isMandatory}
        />
      );
    }
    case 'INSTALLATION_CONTACT':
    case 'DELIVERY_CONTACT':
    case 'FAULT_INCIDENT_CONTACT':
    case 'ORDERING_CONTACT':
    case 'TECHNICAL_CONTACT': {
      return (
        <OpenFormQuestionContact
          dispatcher={dispatcher}
          disabled={disabled}
          required={question.isMandatory}
          label={question.label}
          selected={answers.get(question.guid)?.choices}
        />
      );
    }
    case 'TIME_OF_DELIVERY': {
      return (
        <OpenFormQuestionInput
          disabled={disabled}
          required={question.isMandatory}
          label={question.label}
          name={question.guid}
          type="time"
        />
      );
    }
    case 'ADDITIONAL_INFORMATION_BACKOFFICE':
    case 'ADDITIONAL_INFORMATION_FIELD_ENGINEER': {
      return (
        <OpenFormQuestionInput
          disabled={disabled}
          required={question.isMandatory}
          label={question.label}
          name={question.guid}
          type="textarea"
        />
      );
    }
    case 'FREE_TEXT': {
      switch (question.dataType) {
        case 'DATE_FIELD': {
          return (
            <OpenFormQuestionDate
              disabled={disabled}
              required={question.isMandatory}
              label={question.label}
              name={question.guid}
              offset={question.offset}
              selected={answers.get(question.guid)?.choices}
            />
          );
        }
        case 'EMAIL': {
          return (
            <OpenFormQuestionInput
              disabled={disabled}
              required={question.isMandatory}
              label={question.label}
              name={question.guid}
              type="email"
            />
          );
        }
        case 'NUMBER_INTEGER': {
          return (
            <OpenFormQuestionInput
              disabled={disabled}
              required={question.isMandatory}
              label={question.label}
              name={question.guid}
              type="int"
            />
          );
        }
        case 'PHONE': {
          return (
            <OpenFormQuestionInput
              disabled={disabled}
              required={question.isMandatory}
              label={question.label}
              name={question.guid}
              type="tel"
            />
          );
        }
        case 'TIME_FIELD': {
          return (
            <OpenFormQuestionInput
              disabled={disabled}
              required={question.isMandatory}
              label={question.label}
              name={question.guid}
              type="time"
            />
          );
        }
        case 'TEXT': {
          return (
            <OpenFormQuestionInput
              disabled={disabled}
              required={question.isMandatory}
              label={question.label}
              name={question.guid}
              type="text"
            />
          );
        }
      }
    }
  }
};
