/**
 * Online UI API
 * # Summary Elisa B2B Online UI API  # Usage This API is used in the `https://yrityksille.elisa.fi/` pages.  # Identifiers 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ShoppingCartItem } from './shoppingCartItem.js';


export interface ShoppingCart { 
    /**
     * Type of shopping cart
     */
    type: ShoppingCart.TypeEnum;
    /**
     * Items for the Shopping Cart
     */
    items: Array<ShoppingCartItem>;
}
export namespace ShoppingCart {
    export type TypeEnum = 'ACCOUNT' | 'EMPLOYEE';
    export const TypeEnum = {
        ACCOUNT: 'ACCOUNT' as TypeEnum,
        EMPLOYEE: 'EMPLOYEE' as TypeEnum
    };
}


