import { EmployeeSubscriptionDetailsDevice } from '../../../../../../components/EmployeeSubscriptionDetails/EmployeeSubscriptionDetailsDevice.js';
import { SubscriptionCategory } from '../../../../../../common/enums.js';
import {
  checkValidEmployeeSubscription,
  getEmployeeSubscriptions,
  loadCompanyInfo,
} from '../../../../../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import type { SubscriptionId } from '../../../../../../common/constants/pathInterfaces.js';

export const DevicePath = () => {
  const dispatch = useDispatch();
  const { subscriptionId = '' } = useParams<SubscriptionId>();

  useEffect(() => {
    dispatch(loadCompanyInfo());
    dispatch(getEmployeeSubscriptions(SubscriptionCategory.DEVICE, subscriptionId));
    dispatch(checkValidEmployeeSubscription(SubscriptionCategory.DEVICE, subscriptionId));
  }, [dispatch, subscriptionId]);

  return <EmployeeSubscriptionDetailsDevice subscriptionId={subscriptionId} />;
};
