import {
  BARRINGS_DEPENDENCIES,
  barrings,
  dataRoamingBarringCodes,
  getDependentDataBarring,
  roamingBarringCodes,
} from '../../common/utils/barringUtils.js';
import { BarringsEditor } from './BarringsEditor.js';
import { BarringsView } from './BarringsView.js';
import { resolveBarringGroupAvailabilities } from './barringAccordionUtil.js';
import { useState } from 'react';
import type { AssociationRecord, DependencyRecord } from '@onlinefirst/cloudsense-add-on-dependency-engine';
import type { Subscription, SubscriptionDetails } from '../../generated/api/models.js';

import './BarringsAccordionContent.scss';

export interface BarringsAccordionContentProps {
  addOnAssociations: AssociationRecord[];
  addOnDependencies: DependencyRecord[];
  details: SubscriptionDetails;
  forceEditing: boolean;
  onEditSectionIfNoActionsPending: (editingSection?: string) => boolean;
  saving: boolean;
  subscription: Subscription;
}

export const BarringsAccordionContent = ({
  addOnAssociations,
  addOnDependencies,
  details,
  forceEditing,
  onEditSectionIfNoActionsPending,
  saving,
  subscription,
}: BarringsAccordionContentProps) => {
  const [isEditing, setIsEditing] = useState(forceEditing);
  const availableAddonGuids =
    addOnAssociations
      ?.filter(association => association.cspmb__price_item__c === subscription.commercialProductId)
      ?.map(association => association.cspmb__add_on_price_item__r.guid__c) ?? [];
  const availableDataRoamingBarrings = availableAddonGuids.filter(guid => dataRoamingBarringCodes.includes(guid));
  const availableRoamingBarrings = availableAddonGuids.filter(guid => roamingBarringCodes.includes(guid));
  const selectedAddOnCodes = details?.selectedAddOns?.map(addOn => addOn.addOnCode) ?? [];
  const defaultRoamingBarring = roamingBarringCodes.find(code => availableRoamingBarrings.includes(code));
  const barringGroupAvailabilities = resolveBarringGroupAvailabilities(availableAddonGuids);

  if (defaultRoamingBarring && !selectedAddOnCodes.includes(defaultRoamingBarring)) {
    selectedAddOnCodes.push(defaultRoamingBarring);
  }

  const roamingBarring =
    selectedAddOnCodes.find(code => roamingBarringCodes.includes(code)) || barrings.ALLOWED_FINLAND.code;

  const defaultDataRoamingBarring = getDependentDataBarring(
    dataRoamingBarringCodes.find(code => availableDataRoamingBarrings.includes(code)) || barrings.DATA_DENIED.code,
    roamingBarring
  );

  const dependentRoamingBarrings = BARRINGS_DEPENDENCIES[defaultDataRoamingBarring];
  if (
    defaultDataRoamingBarring &&
    !selectedAddOnCodes.includes(defaultDataRoamingBarring) &&
    (!dependentRoamingBarrings || dependentRoamingBarrings.roamingBarring.includes(roamingBarring))
  ) {
    selectedAddOnCodes.push(defaultDataRoamingBarring);
  }

  const onEditWanted = () => {
    if (onEditSectionIfNoActionsPending('addonsBarrings')) {
      setIsEditing(true);
    }
  };

  return (
    <>
      {isEditing ? (
        <BarringsEditor
          addOnAssociations={addOnAssociations}
          addOnDependencies={addOnDependencies}
          barringGroupAvailabilities={barringGroupAvailabilities}
          details={details}
          subscription={subscription}
          onEditCompleted={() => setIsEditing(false)}
        />
      ) : (
        <BarringsView
          barringGroupAvailabilities={barringGroupAvailabilities}
          details={details}
          disableEditing={saving}
          onEditWanted={onEditWanted}
        />
      )}
    </>
  );
};
