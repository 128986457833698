import * as CL from '@design-system/component-library';
import { PopoverContent } from './CommercialProductContent.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { t } from '../../../common/i18n/index.js';

const PublicPageMobileVoicemailPopoverContent = () => (
  <div>
    {t.TO3V(
      'With Elisa’s Mobile Phone Voice Mail, you will have access to the Away Attendant, notifications for unanswered calls and voice mail, all in one package.'
    )}
  </div>
);

const NoePageMobileVoicemailPopoverContent = () => (
  <>
    <div>
      <b>Elisa Matkapuhelinvastaaja </b>
      {t.TO3U('includes the Away Attendant (Lomavastaaja), notifications for unanswered calls and voice mail.')}
    </div>
    <div className={dsClass.MARGIN_TOP_2}>
      {t.ZJLK(
        'A text message will inform you of a call you did not receive because the phone was not connected to the network or it was turned off, for example, during a flight.'
      )}
    </div>
    <div className={dsClass.MARGIN_TOP_2}>
      {t.R9HM(
        'With the Away Attendant, you can distinguish between incoming work and private calls and ensure that the incoming call reaches the desired person during an absence.'
      )}
    </div>
    <div className={dsClass.MARGIN_TOP_2}>
      <CL.Link linkHref="https://yrityksille.elisa.fi/ohje/elisa-matkapuhelinvastaaja" linkTarget="_blank">
        {t.R2NG('Read more {} services', 'Elisa Matkapuhelinvastaaja')}
      </CL.Link>
    </div>
  </>
);

export const MobileVoicemailPopoverContent = ({ inPublicStore }: { inPublicStore?: boolean }) => (
  <PopoverContent>
    {inPublicStore ? <PublicPageMobileVoicemailPopoverContent /> : <NoePageMobileVoicemailPopoverContent />}
  </PopoverContent>
);
