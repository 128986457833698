import { DialogType } from '../../common/enums.js';
import { DialogWrapper } from '../DialogWrapper/index.js';
import { DuplicateContactsCheckDialog } from '../DuplicateContactsCheckDialog/DuplicateContactsCheckDialog.js';
import { SubmitOrderDialog } from './SubmitOrderDialog.js';
import { UpsertContactDialog } from '../UpsertContactDialog/UpsertContactDialog.js';
import { addContactMsg, continueMsg, corporateMessagePopUpHeaderMsg, t } from '../../common/i18n/index.js';
import { closeDialog, resetErrors, showDialog, upsertContact } from '../../selfservice/actions/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { getPublishedCatalogs } from '../../common/utils/catalogUtils.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import type {
  AddContactDialogParams,
  DuplicateContactDialogParams,
  GenericInfoDialogParams,
  UpsertContactDialogParams,
} from '../../common/types/dialog.js';
import type { CommonError } from '../../common/types/errors.js';
import type { CompanyInfoResponse, Contact } from '../../generated/api/models.js';
import type { DialogState } from '../../common/types/states.js';
import type { State } from '../../selfservice/common/store.js';

interface DeviceCheckoutDialogProps {
  redirectPath: string;
}

interface DeviceCheckoutInnerDialogProps {
  dialog?: DialogState;
  redirectPath: string;
  onClickHome: () => void;
  imagesBaseUrl?: string;
  onCloseDialog: () => void;
  onUpsertContact: (
    contact: Contact,
    validationErrors?: CommonError[],
    params?: object,
    customerType?: CompanyInfoResponse.CustomerTypeEnum
  ) => void;
}

const AddContactDialogElement = ({ dialog, onCloseDialog, onUpsertContact }: DeviceCheckoutInnerDialogProps) => {
  if (!dialog) {
    return null;
  }
  const params = dialog?.params as UpsertContactDialogParams;
  return (
    <UpsertContactDialog
      errors={dialog.errors}
      header={t.CSTF(addContactMsg)}
      isSubmitInProgress={dialog.submitInProgress}
      onCloseDialog={onCloseDialog}
      onSubmitDialog={(contact: Contact, validationErrors?: CommonError[]) =>
        onUpsertContact(contact, validationErrors, params)
      }
    />
  );
};

const GenericInfoDialog = ({ dialog, onCloseDialog }: DeviceCheckoutInnerDialogProps) => {
  const params = dialog?.params as GenericInfoDialogParams;
  return (
    <DialogWrapper
      buttons={[{ onClick: onCloseDialog, text: t.WOYD(continueMsg) }]}
      header={params.header}
      onCloseDialog={onCloseDialog}
      size={params.wide ? 'l' : 'm'}
    >
      <div className={dsClass.PADDING_TOP_4}>{params.body}</div>
    </DialogWrapper>
  );
};

const DuplicateContactDialog = ({ dialog, onCloseDialog, onClickHome }: DeviceCheckoutInnerDialogProps) => {
  const params = dialog?.params as DuplicateContactDialogParams;
  return (
    <DialogWrapper
      buttons={[
        {
          onClick: () => {
            onCloseDialog();
            if (onClickHome) {
              onClickHome();
            }
          },
          text: 'OK',
        },
      ]}
      onCloseDialog={onCloseDialog}
      header={params.header}
    >
      <p>{params.body}</p>
    </DialogWrapper>
  );
};

const SimCardHelpDialogElement = ({ onCloseDialog, imagesBaseUrl }: DeviceCheckoutInnerDialogProps) => (
  <DialogWrapper
    buttons={[{ onClick: onCloseDialog, text: `${t.WOYD('Close')}` }]}
    closeable
    header={t.IPFH('Where can I find the SIM-card number?')}
    onCloseDialog={onCloseDialog}
  >
    <>
      <img
        className={dsClass.PADDING_TOP_4}
        src={`${imagesBaseUrl || '.'}/7exVmyAO7Cak8ig8i86yWm/sim-card-guide.png?w=420`}
        alt={t.T0FF('Locations of SIM card numbers')}
      />
      <p>
        {t.Z7K0(
          'You can find the SIM card number in the locations indicated by the image. Restart your device after activating the card. The initial PIN code for the new SIM card is 1234.'
        )}
      </p>
    </>
  </DialogWrapper>
);

export const DeviceCheckoutDialog = ({ redirectPath }: DeviceCheckoutDialogProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { dialog, catalogs, selectedCatalogCode, imagesBaseUrl } = useSelector(
    (state: State) => ({
      dialog: state.dialog || undefined,
      imagesBaseUrl: state.config.imagesBaseUrl,
      catalogs: getPublishedCatalogs(state.selfservice?.virtualCatalogs?.items),
      selectedCatalogCode: state.selfservice?.virtualCatalogs?.selectedCatalogCode,
    }),
    deepEqual
  );
  useEffect(() => {
    const corporateMsg = catalogs?.find(catalog => catalog.catalogCode === selectedCatalogCode)?.corporateMessage;
    if (corporateMsg) {
      dispatch(
        showDialog({
          body: corporateMsg,
          header: t.WHAA(corporateMessagePopUpHeaderMsg),
          type: DialogType.GENERIC_INFO_DIALOG,
        })
      );
    }
  }, [dispatch, catalogs?.length, selectedCatalogCode]); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps

  const onClickHome = () => {
    dispatch(resetErrors());
    navigate(redirectPath);
  };
  const onCloseDialog = () => dispatch(closeDialog());
  const onUpsertContact = (
    contact: Contact,
    validationErrors?: CommonError[],
    params?: AddContactDialogParams,
    customerType?: CompanyInfoResponse.CustomerTypeEnum
  ) => dispatch(upsertContact(contact, false, false, false, validationErrors, params, customerType));

  const dialogProps: DeviceCheckoutInnerDialogProps = {
    dialog,
    onClickHome,
    onCloseDialog,
    imagesBaseUrl,
    onUpsertContact,
    redirectPath,
  };

  const dialogPropsForDuplicateContactDialogProps: DeviceCheckoutInnerDialogProps = {
    onCloseDialog,
    onClickHome: () => {},
    onUpsertContact: () => {},
    redirectPath,
    dialog: {
      params: {
        type: DialogType.GENERIC_EMPTY_DIALOG,
        header: t.RAQ0('We found a user with similar contact details'),
        body: t.RY1J(`We recommend to use unique email and phone number or select user from your company's user list.`),
      },
    },
  };

  if (dialog?.params?.type) {
    switch (dialog.params.type) {
      case DialogType.SUBMIT_ORDER:
        return <SubmitOrderDialog {...dialogProps} />;

      case DialogType.ADD_CONTACT:
        return <AddContactDialogElement {...dialogProps} />;

      case DialogType.ORDER_SIM_CARD_NUMBER_HELP:
        return <SimCardHelpDialogElement {...dialogProps} />;

      case DialogType.GENERIC_INFO_DIALOG:
        return <GenericInfoDialog {...dialogProps} />;

      // DUPLICATE_CONTACT type is for showing the dialog to user when the single contact creation fails with duplicate response error
      case DialogType.DUPLICATE_CONTACT:
        return <DuplicateContactDialog {...dialogProps} />;

      // While creating new user, show this dialog in case of duplicate and allow returning to the form
      case DialogType.DUPLICATE_CONTACT_INFORMATION:
        return <GenericInfoDialog {...dialogPropsForDuplicateContactDialogProps} />;

      // DUPLICATE_CONTACTS_CHECK dialog type is for showing dialog box to user at step 2 as a result of doing duplicate check on new contact creation request. this does not create any new contact
      case DialogType.DUPLICATE_CONTACTS_CHECK:
        return <DuplicateContactsCheckDialog onCloseDialog={onCloseDialog} />;
    }
  }
  return null;
};
