import { DialogContainer } from './selfServiceDialogs.js';
import { NotificationContainer } from '../NotificationContainer/NotificationContainer.js';
import { SmallHeader } from '../SmallHeader/SmallHeader.js';
import { SnapInChat } from '../SnapInChat/SnapInChat.js';
import { useSelector } from 'react-redux';
import type { ReactNode } from 'react';
import type { State } from '../../selfservice/common/store.js';

import './SelfService.scss';

export interface SelfServiceChildren {
  children: ReactNode;
}

export const SelfService = (props: SelfServiceChildren) => {
  const isVisible = useSelector((state: State) => Boolean(state?.layout?.hideHeaderFooter));

  return (
    <div className="of-selfservice" data-testid="of-selfservice">
      {isVisible && <SmallHeader />}
      <NotificationContainer />
      <DialogContainer />
      {props.children}
      <SnapInChat />
    </div>
  );
};
