export const formatTimestampToYYYYMMDD = (timestamp?: number): string | undefined => {
  if (timestamp === undefined) {
    return undefined;
  }
  const d = new Date(timestamp);
  return '' + d.getFullYear() + ('0' + (d.getMonth() + 1)).slice(-2) + ('0' + d.getDate()).slice(-2);
};

// UTC version, used to compare self-service API future dates such as invoice "due"
export const formatTimestampToUTCYYYYMMDD = (timestamp?: number): string | undefined => {
  if (timestamp === undefined) {
    return undefined;
  }
  const d = new Date(timestamp);
  return '' + d.getUTCFullYear() + ('0' + (d.getUTCMonth() + 1)).slice(-2) + ('0' + d.getUTCDate()).slice(-2);
};

const formatToDDMMYYYY = (timestamp: number): string => {
  const d = new Date(timestamp);
  return `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`;
};

export const formatTimestampToDDMMYYYY = (timestamp?: number): string | undefined => {
  if (timestamp === undefined) {
    return undefined;
  }
  return formatToDDMMYYYY(timestamp);
};

export const formatDefinedTimestampToDDMMYYYY = (timestamp: number): string => {
  return formatToDDMMYYYY(timestamp);
};

// UTC version, used in the self-service API for future dates such as invoice "due"
export const formatTimestampToUTCDDMMYYYY = (timestamp?: number): string | undefined => {
  if (timestamp === undefined) {
    return undefined;
  }
  const d = new Date(timestamp);
  return `${d.getUTCDate()}.${d.getUTCMonth() + 1}.${d.getUTCFullYear()}`;
};

export const formatTimeToYYYYMMDDHHmm = (timestamp: string) => {
  const d = new Date(timestamp);
  const date = `${d.getUTCDate()}.${d.getUTCMonth() + 1}.${d.getUTCFullYear()}`;
  const time = `${d.getHours()}:${('0' + d.getMinutes()).slice(-2)}:${('0' + d.getSeconds()).slice(-2)}`;
  return `${date} ${time}`;
};

export const formatTimeStampToYYYYMMDDHHmm = (timestamp: number): string => {
  const d = new Date(timestamp);
  const date = `${d.getUTCDate()}.${d.getUTCMonth() + 1}.${d.getUTCFullYear()}`;
  const time = `${d.getHours()}:${('0' + d.getMinutes()).slice(-2)}`;
  return `${date} ${time}`;
};

export const formatTimeStampToHHmm = (timestamp: number): string => {
  const d = new Date(timestamp);
  return `${('0' + d.getHours()).slice(-2)}:${('0' + d.getMinutes()).slice(-2)}`;
};

export const isTimestampDateInFuture = (timestamp: number, timeStampToCompare?: number): boolean =>
  timeStampToCompare ? timeStampToCompare < timestamp : Date.now() < timestamp;

export const isWeekend = (date: Date): boolean => [0, 6].some(day => day === date.getDay());

export const isEqualDate = (one: Date, another: Date): boolean =>
  one.getDate() === another.getDate() &&
  one.getMonth() === another.getMonth() &&
  one.getFullYear() === another.getFullYear();

export const isNotAllowedDate = (date: Date, blockedDates: Date[]): boolean =>
  isWeekend(date) || blockedDates.some(blockedDate => isEqualDate(blockedDate, date));

/**
 * Returns Date without hours, minutes, seconds and millis.
 * @param time number, additionally can specify milliseconds of time of date.
 */
export const getNormalizedDate = (time?: number): Date => {
  let date;
  if (time) {
    date = new Date(time);
  } else {
    date = new Date();
  }
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date;
};

export const getNormalizedDateFromDateString = (dateStr: string) => {
  const [day, month, year] = dateStr.split('.');
  return getNormalizedDate(new Date(`${year}-${month}-${day}`).getTime());
};

export const setNextWeekdayIfWeekend = (date: Date) => {
  while (isWeekend(date)) {
    date.setDate(date.getDate() + 1);
  }
};

export const getNormalizedAdjustedDate = (
  addDays?: number,
  addMonths?: number,
  addYears?: number,
  shouldSetNextWeekdayIfWeekend?: boolean
) => {
  const date = getNormalizedDate();
  if (addYears) {
    date.setFullYear(date.getFullYear() + addYears);
  }
  if (addMonths) {
    date.setMonth(date.getMonth() + addMonths);
  }
  if (addDays) {
    date.setDate(date.getDate() + addDays);
  }

  if (shouldSetNextWeekdayIfWeekend) {
    setNextWeekdayIfWeekend(date);
  }

  return date;
};

export const getLocalizedDateWithLeadingZeroes = (date: Date, locale: string) =>
  date.toLocaleDateString(locale, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
