import * as CL from '@design-system/component-library';
import { BillingAccountsAccordion } from '../BillingAccountsAccordion/BillingAccountsAccordion.js';
import {
  BroadbandSubscriptionsAccordion,
  DeviceSubscriptionsAccordion,
  ServiceSubscriptionsAccordion,
  VoiceSubscriptionsAccordion,
} from '../SubscriptionsAccordion/SubscriptionsAccordion.js';
import { ButtonGroupForSubmitAndBack } from '../ButtonGroupForSubmitAndBack/ButtonGroupForSubmitAndBack.js';
import { ContactForm } from '../ContactForm/ContactForm.js';
import { ContactStatus } from '../../generated/api/contactStatus.js';
import { ContactType } from '../../generated/api/contactType.js';
import { DialogType } from '../../common/enums.js';
import { FormikProvider, useFormik } from 'formik';
import { UserProfile } from '../../generated/api/userProfile.js';
import { UserRolesAndAdminRights } from '../UserRolesAndAdminRights/UserRolesAndAdminRights.js';
import { confirmMsg, dataSourcesForContactDetailsMsg, editMsg, t } from '../../common/i18n/index.js';
import { containsYttSubscription } from '../../common/utils/subscriptionUtils.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { isFeatureEnabled } from '../../common/utils/featureFlagUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { showDialog, upsertContact, upsertContactFailed } from '../../selfservice/actions/index.js';
import { upsertContactUser } from '../../common/fetch.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch, useSelector } from 'react-redux';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import type { ConfigState } from '../../common/types/states.js';
import type { Contact } from '../../generated/api/contact.js';
import type { ContactCommonFunction } from '../../generated/api/contactCommonFunction.js';
import type { ContactPerson } from '../../generated/api/contactPerson.js';
import type { ContactSubscriptionsLoaderData } from '../../common/loaders.js';
import type { DialogParams } from '../../common/types/dialog.js';
import type { FormikValues } from 'formik';
import type { State } from '../../selfservice/common/store.js';

interface ContactDetailsFormProps {
  config: ConfigState;
  contact: Contact;
  mdmId: string;
}

interface DialogMessageProps {
  topParagraph: string;
  bottomParagraph?: string;
}

const DialogMessage = (props: DialogMessageProps) => {
  return (
    <>
      <div className={dsClass.PADDING_BOTTOM_4}>{props.topParagraph}</div>
      {props.bottomParagraph && <div className={dsClass.PADDING_BOTTOM_4}>{props.bottomParagraph}</div>}
    </>
  );
};

const getFormInitialValues = (contact: Contact) => {
  if (contact.contactType === ContactType.COMMON_FUNCTION) {
    return {
      name: contact.commonFunction?.name,
      email: contact.commonFunction?.email,
      phoneNumber: contact.commonFunction?.phoneNumber,
    };
  } else {
    return {
      firstName: contact.person?.firstName,
      lastName: contact.person?.lastName,
      email: contact.person?.email,
      phoneNumber: contact.person?.phoneNumber,
      costCenter: contact.person?.costCenter,
      employeeNumber: contact.person?.employeeNumber,
    };
  }
};

// A function to generate correct contact payload. If the contactType is common function and also person
// object is sent, then SFDC returns an exception. Same for contactType of Person, commonFunction needs to be
// left out. Also, same happens if the value for either one is undefined.
// todo: Perhaps prune out the undefined person or common function in online-ui-api
const generateContactBasedOnContactType = (
  contact: Contact,
  contactStatus?: ContactStatus,
  formValues?: FormikValues,
  person?: ContactPerson
): Contact => {
  const { contactId, contactType } = contact;
  if (contactType === ContactType.COMMON_FUNCTION) {
    const commonFunction = formValues as ContactCommonFunction;
    return { contactId, contactType, contactStatus, commonFunction };
  } else {
    const personForUpdate = formValues ? { ...person, ...(formValues as ContactPerson) } : contact.person;
    return { contactId, contactType, contactStatus, person: personForUpdate };
  }
};

export const ContactDetailsForm = ({ config, contact, mdmId }: ContactDetailsFormProps) => {
  const { device, voice, broadband, service, billingAccounts } = useLoaderData() as ContactSubscriptionsLoaderData;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { authenticatedUser } = useAuth();
  const [isEdit, setIsEdit] = useState(false);
  const [currentContact, setCurrentContact] = useState<Contact>();
  const [isRemoveUserSubmitting, setIsRemoveUserSubmitting] = useState(false);
  const { submitting } = useSelector(
    (state: State) => ({
      submitting: state.selfservice?.contacts?.saving,
    }),
    deepEqual
  );
  const contactType = contact.contactType || ContactType.PERSON;
  const { contactId, person } = contact;
  const hasBillingAccounts = billingAccounts.length > 0;

  const onShowDialog = (params: DialogParams) => dispatch(showDialog(params));

  const removeUser = async (onCloseDialog: () => void) => {
    onCloseDialog();
    setIsRemoveUserSubmitting(true);
    try {
      await upsertContactUser(generateContactBasedOnContactType(contact, ContactStatus.PASSIVE), true, undefined);
      setIsEdit(false);
      navigate(paths.COMPANY_INFO_CONTACTS);
    } catch (err) {
      dispatch(upsertContactFailed('Failed to remove contact', 500));
    } finally {
      setIsRemoveUserSubmitting(false);
    }
  };

  const showConfirmRemoveUserDefaultDialog = () => {
    onShowDialog({
      header: t.PWYT('Do you really want to remove user?'),
      confirmButtonText: t.ZK7G('Remove user'),
      body: (
        <>
          <div className={dsClass.PADDING_BOTTOM_4}>
            {t.YEX6('User will be completely removed from the system. Operation cannot be undone.')}
          </div>
          <div className={dsClass.PADDING_BOTTOM_4}>
            {t.TCGR(
              `Please note that the user's association with the company will also be removed, and afterward, they won't be able to view their subscriptions or devices related to that company in Employee OmaElisa.`
            )}
          </div>
        </>
      ),
      onConfirm: removeUser,
      type: DialogType.GENERIC_CONFIRMATION_DIALOG,
    });
  };

  const showConfirmRemoveUserManagedInSalesforceDialog = () => {
    onShowDialog({
      header: t.FBDO('User has administrator rights'),
      confirmButtonText: t.ZK7G('Remove user'),
      body: (
        <>
          <div className={dsClass.PADDING_BOTTOM_4}>
            {t.MSD6(
              'Please note that the user you are about to remove has administrator rights in OmaElisa for Companies, and these rights will also be removed during deletion.'
            )}
          </div>
        </>
      ),
      onConfirm: removeUser,
      type: DialogType.GENERIC_CONFIRMATION_DIALOG,
    });
  };

  const showUserNotManagedInSalesforceDialog = () => {
    onShowDialog({
      header: t.WNUM('User can not be removed'),
      buttonText: t.B2V1('Cancel'),
      body: (
        <div className={dsClass.PADDING_BOTTOM_4}>
          {t.OJDL(
            'The user can not be removed because they have administrator rights in OmaElisa for Companies. Please remove administrator rights in'
          )}{' '}
          <a href={`${config.classicSiteUrl}`}>{t.JS80('OmaElisa Classic')}</a>.
        </div>
      ),
      type: DialogType.GENERIC_INFO_DIALOG,
    });
  };

  const confirmRemoveContact = (userProfile?: string, userRightsInSalesforce?: boolean) => {
    if (userProfile === UserProfile.KEY_USER && userRightsInSalesforce) {
      showConfirmRemoveUserManagedInSalesforceDialog();
    } else if (userProfile === UserProfile.KEY_USER && !userRightsInSalesforce) {
      showUserNotManagedInSalesforceDialog();
    } else {
      showConfirmRemoveUserDefaultDialog();
    }
  };

  const removeContact = () => {
    if (hasBillingAccounts || contact.isPartOfBilling || contact.hasSubscriptions) {
      onShowDialog({
        header: t.WNUM('The user can not be removed'),
        buttonText: t.WOYD('Close'),
        body: (
          <DialogMessage
            topParagraph={t.VR6E(
              'The user can not be removed because they have active subscriptions or the user is a contact person or a recipient on a billing account.'
            )}
            bottomParagraph={t.WT42(
              'Please update subscriptions user details and/or billing accounts contact details.'
            )}
          />
        ),
        type: DialogType.GENERIC_INFO_DIALOG,
      });
    } else {
      confirmRemoveContact(contact.person?.userProfile, authenticatedUser?.userRightsInSalesforce);
    }
  };

  const onSubmit = (values: FormikValues) => {
    const isYtt = containsYttSubscription(service.map(res => res.result));
    const isPerson = contact.contactType === ContactType.PERSON && !!person;
    // This is by default the original contact, and if changed, then the updated value
    const comparedContact = currentContact || contact;
    const wholeNameChanged =
      comparedContact.person?.firstName !== values.firstName && comparedContact.person?.lastName !== values.lastName;
    const nameEmailOrPhoneChanged =
      comparedContact.person?.firstName !== values.firstName ||
      comparedContact.person?.lastName !== values.lastName ||
      comparedContact.person?.email !== values.email ||
      comparedContact.person?.phoneNumber !== values.phoneNumber;

    if (isPerson && wholeNameChanged) {
      onShowDialog({
        contactPerson: person,
        values: values as ContactPerson,
        contactType,
        contactId,
        onConfirm: () => {
          setIsEdit(false);
          setCurrentContact(generateContactBasedOnContactType(contact, contact.contactStatus, values, person));
        },
        type: DialogType.UPDATE_CONTACT_DUPLICATE,
      });
      return;
    }
    if (isPerson && isYtt && nameEmailOrPhoneChanged) {
      onShowDialog({
        contactPerson: person,
        values: values as ContactPerson,
        contactType,
        contactId,
        onConfirm: () => {
          setIsEdit(false);
          setCurrentContact(generateContactBasedOnContactType(contact, contact.contactStatus, values, person));
        },
        type: DialogType.UPDATE_CONTACT_YTT_WARNING,
      });
      return;
    }
    const newContact = generateContactBasedOnContactType(contact, contact.contactStatus, values, person);
    setCurrentContact(newContact);
    dispatch(upsertContact(newContact, true));
    setIsEdit(false);
  };

  const onReset = () => {
    setIsEdit(false);
  };

  const formik = useFormik({
    initialValues: getFormInitialValues(currentContact || contact),
    onReset,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <div className="of-contact-details__content">
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <ContactForm contact={contact} isEdit={isEdit} />
          {!isEdit && (
            <CL.Button
              data-testid="edit-contact-button"
              className="of-contact-details__editButton"
              type="button"
              color="light"
              onClick={() => setIsEdit(true)}
            >
              {t.NVPK(editMsg)}
            </CL.Button>
          )}
          {isEdit && (
            <div className="of-contact-details__options">
              <ButtonGroupForSubmitAndBack
                onCancel={formik.resetForm}
                onSubmit={formik.submitForm}
                submitButtonText={t.QVYK(confirmMsg)}
                submitting={submitting}
                submitDisabled={isRemoveUserSubmitting}
                submitOnLeft={true}
              />
              {contact.person?.userName !== authenticatedUser?.userName && (
                <div>
                  <CL.Button
                    className="of-contact-details__removeButton"
                    color="light"
                    loading={isRemoveUserSubmitting}
                    onClick={removeContact}
                  >
                    {t.MEIL('Remove user')}
                  </CL.Button>
                </div>
              )}
            </div>
          )}
        </form>
      </FormikProvider>
      <>
        {(isFeatureEnabled(config.featureFlags.showUserRightsAccordion) ||
          authenticatedUser?.enabledFeatureFlags?.includes('showUserRightsAccordion')) &&
          contact.contactType !== ContactType.COMMON_FUNCTION && (
            <UserRolesAndAdminRights
              ignoreAccordion={false}
              isNewDisconnectedContact={false}
              contact={contact}
              onShowDialog={onShowDialog}
              authenticatedUser={authenticatedUser!}
              mdmId={mdmId}
            />
          )}
        <DeviceSubscriptionsAccordion subscriptions={device} />
        <VoiceSubscriptionsAccordion subscriptions={voice} />
        <BroadbandSubscriptionsAccordion subscriptions={broadband} />
        <ServiceSubscriptionsAccordion subscriptions={service} />
        {hasBillingAccounts && <BillingAccountsAccordion config={config} billingAccounts={billingAccounts} />}
      </>
      <div className="of-contact-details__disclaimertext ea-disclaimertext ea-text--grey">
        {t.U3UL(dataSourcesForContactDetailsMsg)}
      </div>
    </div>
  );
};
