import { Action } from 'history';
import type { Blocker, History, Listener, To } from 'history';
import type { Router as RemixRouter } from '@remix-run/router';

// TODO: leo 17.3.2023 - DELETE as soon as redux is gone, we only need to create this fake history for redux.
export const createFakeHistory = (router: RemixRouter): History => {
  return {
    action: Action.Pop,
    location: {
      state: '',
      key: 'default',
      pathname: router.state.location.pathname,
      search: router.state.location.search,
      hash: router.state.location.hash,
    },
    back(): void {},
    block(_blocker: Blocker): () => void {
      // Do nothing, we don't support blocking.
      return function () {};
    },
    createHref(to: To): string {
      if (typeof to === 'string') {
        return router.createHref(new URL(to));
      } else {
        return router.createHref({
          pathname: to.pathname || '',
          search: to.search || '',
          hash: to.hash || '',
          state: '',
          key: '',
        });
      }
    },
    forward(): void {},
    go(_delta: number): void {},
    listen(_listener: Listener) {
      // Do nothing, we no longer dispatch LOCATION_CHANGE events to Redux.
      return () => {};
    },
    push(to: To, state?: unknown): void {
      router.navigate(to, { state, replace: false }).then();
    },
    replace(to: To, state?: unknown): void {
      router.navigate(to, { state, replace: true }).then();
    },
  };
};
