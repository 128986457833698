import * as CL from '@design-system/component-library';
import { type Choices } from '../../OpenFormAnswers.js';
import { type OpenFormDispatcher } from '../../OpenFormHooks/useOpenFormDispatcher.js';
import { OpenFormGridCol, OpenFormGridRow } from '../../OpenFormComponents/OpenFormGrid.js';
import { getValue } from '../../OpenFormUtils.js';
import classNames from 'classnames';
import type { OpenFormChoice } from '../../../../generated/api/openFormChoice.js';

export const OpenFormQuestionSelectGroup = ({
  dispatcher,
  choices,
  disabled,
  required,
  multiselect,
  label,
  name,
  selected,
}: {
  dispatcher: OpenFormDispatcher;
  choices: OpenFormChoice[];
  disabled: boolean;
  required: boolean;
  multiselect: boolean;
  label: string;
  name: string;
  selected: Choices | undefined;
}) => {
  const selectedValue = getValue(selected);
  return !choices.length ? null : (
    <OpenFormGridRow>
      <OpenFormGridCol colWidth={6} className={classNames({ ['label--mandatory']: required })}>
        <CL.SelectGroup
          key={name + selectedValue}
          label={label}
          multiselect={multiselect}
          onChange={v => dispatcher.setChoices(multiselect && Array.isArray(v) && v.includes('[]') ? undefined : v)}
          onClick={e =>
            !multiselect && !disabled && e.target.defaultValue === selectedValue && dispatcher.setChoices(undefined)
          }
          products={choices.map(choice => ({ disabled, id: choice.guid, name: getValue(choice.values) }))}
          type="column"
          value={multiselect ? selected : selectedValue}
        />
      </OpenFormGridCol>
    </OpenFormGridRow>
  );
};
