import { ConfirmationDialog } from '../../ConfirmationDialog/ConfirmationDialog.js';
import { getFullNameFromContactPerson } from '../../../common/utils/contactUtils.js';
import { initiateEmployeeOnBoarding } from '../../../selfservice/actions/index.js';
import { paths } from '../../../common/constants/pathVariables.js';
import { submitMsg, t, userTextMsg } from '../../../common/i18n/index.js';
import { useDispatch } from 'react-redux';
import type { ContactPerson } from '../../../generated/api/models.js';

import './BbEmployeeInvitationDialog.scss';

export interface BbEmployeeInvitationDialogProps {
  onCloseDialog: () => void;
  contactPerson?: ContactPerson;
  subscriptionId?: string;
}

export const BbEmployeeInvitationDialog = ({
  contactPerson,
  onCloseDialog,
  subscriptionId,
}: BbEmployeeInvitationDialogProps) => {
  const dispatch = useDispatch();

  return (
    <ConfirmationDialog
      header={t.YA79(`Check employee user's information`)}
      body={
        <div className="of-bb-employee-invitation">
          <p>{t.YIDW(`The subscription will be shown in Employee's OmaElisa based on the user information.`)}</p>
          <p>
            {t.BTTL(
              'Employee can see the subscription basic information and use broadband fixer to issue diagnostics.'
            )}
          </p>
          <p>{t.TYKY(`Because of the data privacy it's important that the user information is correct.`)}</p>
          <div>
            <strong>{`${t.U4MA(userTextMsg)}:`}</strong>
            <div className="of-bb-employee-invitation__contact-person-info">
              <span>{getFullNameFromContactPerson(contactPerson)}</span>
              <span>{contactPerson?.email}</span>
              <span>{contactPerson?.phoneNumber}</span>
              <span>
                <a href={paths.COMPANY_INFO_CONTACTS}>{t.ABUM('Modify user information')}</a>
              </span>
            </div>
          </div>
        </div>
      }
      confirmButtonText={t.FSWU(submitMsg)}
      onCloseDialog={onCloseDialog}
      disableConfirm={!contactPerson?.email}
      onConfirm={() =>
        dispatch(initiateEmployeeOnBoarding({ recipientEmails: [contactPerson!.email], subscriptionId }))
      }
    />
  );
};
