import { dsClass } from '../../common/constants/dsClasses.js';

import './Sticker.scss';

export type StickerProps = {
  saving?: string;
  text?: string;
};

export const Sticker = ({ saving, text }: StickerProps) => {
  if (!saving && !text) {
    return null;
  }
  return (
    <div className="of-sticker">
      {text && <div className={saving ? dsClass.TEXT_XS : dsClass.TEXT_L}>{text}</div>}
      {saving && <div className={text ? dsClass.TEXT_S : dsClass.TEXT_L}>{saving}</div>}
    </div>
  );
};
