import { CURRENCY_CODE_EUR, EcommerceEventTypeV4 } from '../../common/analytics.js';
import { DEFAULT_ITEM_BRAND, DEFAULT_PAYMENT_TYPE } from '../../selfservice/common/googleEcommerceEvent.js';
import { VAT_AS_DECIMAL } from '../../common/utils/taxUtils.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import type { AddOn } from '../../generated/api/addOn.js';
import type {
  EcommerceAddToCartEventV4,
  EcommerceItem,
  EcommercePurchaseEventV4,
  EcommerceRemoveAdditionalServicesEventV4,
} from '../../common/analytics.js';

export const ADDON_ANALYTICS_ITEM_CATEGORY = 'Additional Services';

// Create unique id for analytics events. Used as transaction id and bundle id (if needed).
export const useAnalyticsId = (): string => {
  const { authenticatedUser } = useAuth();
  return btoa(Date.now().toString() + authenticatedUser?.userName);
};

// Yearly charge in euros is the used format in analytics side
const getAddOnPrice = (addOn: AddOn) =>
  ((addOn.price?.effectivePrice.monthlyRecurringCharge || 0) * 12 + (addOn.price?.effectivePrice?.oneTimeCharge || 0)) /
  100;

const getAddOnPrices = (addOns: AddOn[]): number => addOns.reduce((total, addOn) => total + getAddOnPrice(addOn), 0);

const getEcommerceItems = (addOns: AddOn[], analyticsId: string): EcommerceItem[] =>
  addOns?.map(addOn => ({
    item_id: addOn.addOnCode, // eslint-disable-line @typescript-eslint/naming-convention
    item_name: addOn.addOnProductName, // eslint-disable-line @typescript-eslint/naming-convention
    item_category: ADDON_ANALYTICS_ITEM_CATEGORY, // eslint-disable-line @typescript-eslint/naming-convention
    item_brand: DEFAULT_ITEM_BRAND, // eslint-disable-line @typescript-eslint/naming-convention
    price: getAddOnPrice(addOn),
    quantity: 1,
    // If there are multiple addons, bundle id has to be included to event data
    item_bundle_id: addOns.length > 1 ? analyticsId : undefined, // eslint-disable-line @typescript-eslint/naming-convention
  })) || [];

export const getAddonAddToCartAnalyticsEvent = (addOns: AddOn[], analyticsId: string): EcommerceAddToCartEventV4 => ({
  event: EcommerceEventTypeV4.ADD_TO_CART,
  ecommerce: {
    currency: CURRENCY_CODE_EUR,
    items: getEcommerceItems(addOns, analyticsId),
  },
});

export const getAddOnPurchaseAnalyticsEvent = (addOns: AddOn[], analyticsId: string): EcommercePurchaseEventV4 => {
  const value = getAddOnPrices(addOns);
  return {
    event: EcommerceEventTypeV4.PURCHASE,
    payment_type: DEFAULT_PAYMENT_TYPE, // eslint-disable-line @typescript-eslint/naming-convention
    ecommerce: {
      transaction_id: analyticsId, // eslint-disable-line @typescript-eslint/naming-convention
      currency: CURRENCY_CODE_EUR,
      value,
      tax: parseFloat((value * VAT_AS_DECIMAL).toFixed(2)),
      items: getEcommerceItems(addOns, analyticsId),
    },
  };
};

export const getRemovedAddOnsAnalyticsEvent = (
  addOns: AddOn[],
  analyticsId: string
): EcommerceRemoveAdditionalServicesEventV4 => ({
  event: EcommerceEventTypeV4.REMOVE_ADDITIONAL_SERVICES,
  ecommerce: {
    currency: CURRENCY_CODE_EUR,
    items: getEcommerceItems(addOns, analyticsId),
  },
});
