import * as CL from '@design-system/component-library';
import { closeMsg, t } from '../../../common/i18n/index.js';

export interface AuxiliaryEsimOrderManageProps {
  onClose: () => void;
}

export const AuxiliaryEsimOrderManage = ({ onClose }: AuxiliaryEsimOrderManageProps) => {
  return (
    <div className="ea-color-container ea-color-container--stretch">
      <span className="ea-icon ea-icon--information ea-icon--large" />
      <h4 className="ea-h4">{t.LTEZ(`Please contact your organization's ordering contact person`)}</h4>
      <p>
        {t.MAYV('Managing of KelloSIM is not possible via phone.')}{' '}
        {t.JL3U(`Please contact your organization's ordering contact person.`)}
      </p>
      <CL.Button block onClick={onClose} size="m">
        {t.WOYD(closeMsg)}
      </CL.Button>
    </div>
  );
};
