import * as CL from '@design-system/component-library';
import * as React from 'react';
import { CompositeList } from '../CompositeList/index.js';
import { DialogType } from '../../common/enums.js';
import { EmployeeOnboardingRequest } from '../../generated/api/models.js';
import { Grid } from '../Grid/Grid.js';
import { HoverTooltip } from '../HoverToolTip/HoverTooltip.js';
import { emailAddressMsg, t } from '../../common/i18n/index.js';
import {
  formatTimeStampToHHmm,
  formatTimeStampToYYYYMMDDHHmm,
  formatTimestampToDDMMYYYY,
} from '../../common/utils/dateUtils.js';
import { initiateEmployeeOnBoarding, loadOnboardingRequests, showDialog } from '../../selfservice/actions/index.js';
import { onEnterOrSpaceKeyPress } from '../../common/utils/handlerUtils.js';
import { useDispatch } from 'react-redux';
import type { CommonError } from '../../common/types/errors.js';
import type { CompositeListColumn } from '../CompositeListHeader/index.js';
import type { CompositeListProps } from '../CompositeList/index.js';
import type { DialogParams } from '../../common/types/dialog.js';
import type { EmployeeOnBoardingPayload } from '../../selfservice/actions/index.js';
import type { OnboardingRequestsState } from '../../common/types/states.js';

import './OnboardingRequestList.scss';

export interface OnboardingRequestListAttrs {
  onboardingRequests?: OnboardingRequestsState | null;
}

export type OnboardingRequestListProps = OnboardingRequestListAttrs;

interface ExtendedOnboardingRequest extends EmployeeOnboardingRequest {
  newState: string;
  color: string;
  sentDate: string;
  sentTime: string;
  expires: string;
}

export const getOnboardingRequestExtendedFields = (
  onboardingRequest: EmployeeOnboardingRequest
): { newState: string; color: string; expires: string; sentDate: string; sentTime: string } => {
  const sentDate = formatTimestampToDDMMYYYY(onboardingRequest.createdDate) || '';
  const expireDate = formatTimeStampToYYYYMMDDHHmm(onboardingRequest.expirationDate);
  const sentTime = formatTimeStampToHHmm(onboardingRequest.createdDate);
  const expiresText = t.GBFN('Until {}', expireDate);

  switch (onboardingRequest.status) {
    case EmployeeOnboardingRequest.StatusEnum.ACTIVE:
      return {
        color: 'green',
        newState: t.SFRK('Active'),
        expires: expiresText,
        sentDate,
        sentTime,
      };
    case EmployeeOnboardingRequest.StatusEnum.EXPIRED:
      return {
        color: 'red',
        newState: t.PJQL('Expired'),
        expires: '',
        sentDate,
        sentTime,
      };
    default:
      return {
        color: 'grey',
        newState: t.G87R('Used'),
        expires: '',
        sentDate,
        sentTime,
      };
  }
};

export const getStatusColumn = (
  onboardingRequest: ExtendedOnboardingRequest,
  onInitiateEmployeeOnBoardingResend: (
    payload: { recipientEmails: string[] },
    validationErrors?: CommonError[]
  ) => void,
  onShowDialog: (params: DialogParams) => void
) => {
  const handleResend = () => {
    onShowDialog({ type: DialogType.SENDING_ONBOARDING_REQUEST });
    onInitiateEmployeeOnBoardingResend(
      { recipientEmails: onboardingRequest.employeeEmail.split(/\r\n|\n|\r/) },
      undefined
    );
  };

  switch (onboardingRequest.status) {
    case EmployeeOnboardingRequest.StatusEnum.ACTIVE:
      return [
        <div key={0} className="of-onboarding-request-list active-state">
          <div>{onboardingRequest.newState}</div>
          <div className="ea-disclaimertext of-onboarding-request-list disclaimer">{onboardingRequest.expires}</div>
        </div>,
      ];
    case EmployeeOnboardingRequest.StatusEnum.EXPIRED:
      return [
        <div key={0} className="of-onboarding-request-list expired-state">
          <span>{onboardingRequest.newState}</span>
          <HoverTooltip tooltipText={t.HVWH('Resend')}>
            <span
              aria-label="resend"
              className="ea-icon ea-icon--small ea-icon--resend"
              onClick={handleResend}
              onKeyPress={(event: React.KeyboardEvent) => onEnterOrSpaceKeyPress(event, handleResend)}
              role="button"
              tabIndex={0}
            />
          </HoverTooltip>
        </div>,
      ];
    default:
      return [<span key={0}>{onboardingRequest.newState}</span>];
  }
};

export const getSentColumn = (onboardingRequest: ExtendedOnboardingRequest) => {
  return [
    <div key={0} className="of-onboarding-request-list active-state">
      <div>{onboardingRequest.sentDate}</div>
      <div className="ea-disclaimertext of-onboarding-request-list disclaimer">{onboardingRequest.sentTime}</div>
    </div>,
  ];
};

const getSendLinkButton = (onShowDialog: (params: DialogParams) => void) => {
  return (
    <CL.Button color="light" size="l" onClick={() => onShowDialog({ type: DialogType.ONBOARD_EMPLOYEE })}>
      {t.ANG6('Send invite link')}
    </CL.Button>
  );
};

// Sorting below -> Sorting in case of status-field: (as status shown is then not the same as in state),
// -> What are other solutions?
const getExtededOnboardingRequests = (onboardingRequests: OnboardingRequestsState) => {
  const itemList: ExtendedOnboardingRequest[] | undefined = onboardingRequests.items?.map(item => {
    const extededFields = getOnboardingRequestExtendedFields(item);
    return { ...item, ...extededFields };
  });
  if (onboardingRequests.sort?.columnId !== 'status') {
    return itemList;
  }
  return onboardingRequests.sort?.order === 'asc'
    ? itemList?.sort((a, b) => (a.newState < b.newState ? -1 : 1))
    : itemList?.sort((a, b) => (a.newState > b.newState ? -1 : 1));
};

export const OnboardingRequestList = ({ onboardingRequests }: OnboardingRequestListProps) => {
  const dispatch = useDispatch();
  const onShowDialog = (params: DialogParams) => dispatch(showDialog(params));
  const [keyword, setKeyword] = React.useState('');
  const onInitiateEmployeeOnBoardingResend = (payload: EmployeeOnBoardingPayload, validationErrors?: CommonError[]) =>
    dispatch(initiateEmployeeOnBoarding(payload, validationErrors, true));

  const extendedList = onboardingRequests ? getExtededOnboardingRequests(onboardingRequests) : undefined;

  const catalogColumns: CompositeListColumn<ExtendedOnboardingRequest>[] = [
    {
      columnId: 'employeeEmail',
      heading: t.ZVQ5(emailAddressMsg),
      highlightSearchKeywords: true,
      ref: `employeeEmail`,
      sortable: true,
    },
    {
      columnId: 'createdDate',
      heading: t.E9I4('Sent'),
      ref: 'createdDate',
      sortable: true,
      value: (onboardingRequest: ExtendedOnboardingRequest) => getSentColumn(onboardingRequest),
    },
    {
      columnId: 'onboardedBy',
      heading: t.RVSW('Sender'),
      ref: 'onboardedBy',
      sortable: true,
    },
    {
      columnId: 'status',
      heading: t.ASQT('Status'),
      ref: 'status',
      sortable: true,
      semiWide: true,
      value: (onboardingRequest: ExtendedOnboardingRequest) =>
        getStatusColumn(onboardingRequest, onInitiateEmployeeOnBoardingResend, onShowDialog),
      valueLineClasses: (onboardingRequest: ExtendedOnboardingRequest) => {
        return ['ea-disc', 'ea-disc--small', `ea-disc--${onboardingRequest.color}`];
      },
    },
  ];

  const listProps: CompositeListProps<ExtendedOnboardingRequest> = {
    classes: ['of-onboarding-request-list'],
    columns: catalogColumns,
    emptyListElement: <span>{t.D3IO('No invites')}</span>,
    getRowId: (employeeOnboardingRequest: EmployeeOnboardingRequest) => employeeOnboardingRequest.uniqueId,
    items: extendedList,
    onSelectRow: () => {},
    onSort: newSort => dispatch(loadOnboardingRequests(newSort)),
    sort: onboardingRequests?.sort,
    rowIcon: 'send-filled',
    topRight: getSendLinkButton(onShowDialog),
    topWrapOnMobile: true,
    search: {
      columnIds: ['employeeEmail'],
      onChange: setKeyword,
      placeholder: t.ELIW('Search by email address'),
      value: keyword,
    },
  };

  return (
    <Grid>
      <h2 className="ea-h3 ea-h3--thick ea-m-t-7 ea-m-b-3">{t.IU7C('Invites to Employee OmaElisa')}</h2>
      <CompositeList {...listProps} />
    </Grid>
  );
};
