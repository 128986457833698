import { TableUrlParams } from './tableConstants.js';

export const resetPagingParams = (queryParams: URLSearchParams, cursorNavigation?: boolean) => {
  queryParams.set(TableUrlParams.PAGE, '1');
  if (cursorNavigation) {
    queryParams.delete(TableUrlParams.CURSOR);
  } else {
    queryParams.set(TableUrlParams.OFFSET, '0');
  }
};

export const setDefaultItemsPerPage = (queryParams: URLSearchParams, defaultItemsPerPageOption: number) =>
  queryParams.set(TableUrlParams.LIMIT, defaultItemsPerPageOption.toString());
