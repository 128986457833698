import * as CL from '@design-system/component-library';
import { Loading } from '../Loading/index.js';
import { NumberFetchingError } from './NumberFetchingError.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { extensionMsg, t } from '../../common/i18n/index.js';
import { findBestMatchForExtensionNumber } from '../../common/utils/pbxUtils.js';
import { loadNumberRange } from '../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import type { CommonError } from '../../common/types/errors.js';
import type { NumberRange } from '../../generated/api/models.js';
import type { NumberRangesState } from '../../common/types/states.js';

interface ExtensionNumberProps {
  extensionNumberRanges: NumberRange[];
  updateExtensionRangeId: (newRangeId: string | undefined) => void;
  updateExtensionNumber: (newNumber: string | undefined) => void;
  isRing: boolean;
  selectedExtensionRangeId?: string;
  selectedExtensionNumber?: string;
  selectedCorporateNumber?: string;
  numberRanges?: NumberRangesState;
  blockedExtensionNumbers?: string[];
}

export const ExtensionNumberAndRangeContent = (props: ExtensionNumberProps) => {
  const {
    blockedExtensionNumbers,
    extensionNumberRanges,
    selectedExtensionNumber,
    selectedCorporateNumber,
    updateExtensionNumber,
    selectedExtensionRangeId,
    updateExtensionRangeId,
    isRing,
    numberRanges,
  } = props;
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState<CommonError[]>([]);
  const [extensionNumbers, setExtensionNumbers] = useState<string[]>([]);

  const getDisplayNumberRangeDisplayText = (numberRange: NumberRange) =>
    `${numberRange.startNumber} – ${numberRange.endNumber}`;

  useEffect(() => {
    if (!selectedExtensionRangeId || !numberRanges) {
      return setIsLoading(true);
    }
    const selectedNumberRange = numberRanges[selectedExtensionRangeId];
    if (!selectedNumberRange) {
      return setIsLoading(true);
    }

    const { displayedNumbers, loading, errors: numberRangeErrors } = selectedNumberRange;
    if (numberRangeErrors) {
      return setErrors(numberRangeErrors);
    }

    if (displayedNumbers && !loading) {
      const availableExtensionNumbers: string[] =
        displayedNumbers?.filter(
          number => (Number(number) < 700 || Number(number) >= 900) && !blockedExtensionNumbers?.includes(number)
        ) || [];

      setExtensionNumbers(isRing ? availableExtensionNumbers : displayedNumbers || []);
      setIsLoading(false);
    }
  }, [numberRanges, selectedExtensionRangeId, blockedExtensionNumbers, isRing]);

  if (extensionNumberRanges.find(numberRange => numberRange.rangeId === selectedExtensionRangeId) === undefined) {
    return null;
  }

  const onSelectCorporateNumber = (number?: string) => {
    if (number) {
      const bestMatch = findBestMatchForExtensionNumber(number, extensionNumbers);
      updateExtensionNumber(bestMatch || extensionNumbers[0]);
    }
  };

  // Do not show extension number selection if Vakio
  if (!isRing) {
    return null;
  }

  return (
    <div className={dsClass.MARGIN_TOP_4}>
      <div className={dsClass.FONT_SIZE_LARGE}>{t.UM9T('Extension numbers')}:</div>
      <div className="of-attach-pbx-content__sub-numbers">
        <div className="of-attach-pbx-content__sub-number">
          <CL.Dropdown
            key={selectedExtensionRangeId}
            items={extensionNumberRanges
              .map(numberRange => ({
                label: getDisplayNumberRangeDisplayText(numberRange),
                value: numberRange.rangeId,
              }))
              .sort((optionA, optionB) => {
                return optionA.value > optionB.value ? 1 : -1;
              })}
            label={t.ESVA('Extension number range')}
            selectedValue={selectedExtensionRangeId}
            onValueChange={element => {
              const newValue = element.attributes.getNamedItem('data-value')?.value;
              updateExtensionRangeId(newValue);
              onSelectCorporateNumber(selectedCorporateNumber);
            }}
          />
        </div>
        {numberRanges && selectedExtensionRangeId && errors?.length ? (
          <NumberFetchingError
            errors={errors}
            loading={numberRanges[selectedExtensionRangeId].loading}
            retryAction={() => dispatch(loadNumberRange(selectedExtensionRangeId!))}
          />
        ) : null}
        {isLoading ? (
          <Loading topPadding={1} text />
        ) : (
          <div className="of-attach-pbx-content__sub-number">
            <CL.Dropdown
              key={selectedExtensionNumber}
              items={extensionNumbers.map(number => ({
                label: number,
                value: number,
              }))}
              label={t.NO66(extensionMsg)}
              selectedValue={selectedExtensionNumber}
              onValueChange={element => {
                updateExtensionNumber(element.attributes.getNamedItem('data-value')?.value);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
