import * as React from 'react';

export interface SelectGroupItemProps {
  children: React.ReactNode;
  title?: string;
  icon?: React.ReactNode;
  hidden?: boolean;
  id?: string;
  selected?: boolean;
}

export const SelectGroupItem = (props: SelectGroupItemProps) => <>{props.children}</>;
