import { ActionPhase } from '../common/storeUtils.js';
import { TypeKeys } from '../actions/index.js';
import { reduceCrudAction } from './reducerUtils.js';
import { updateActionStatePhase } from '../common/index.js';
import type { ActionsHistory } from '../common/store.js';
import type { BillChannel } from '../../generated/api/models.js';
import type { BillChannelsState } from '../../common/types/states.js';
import type { SelfServiceActionTypes } from '../actions/index.js';

const sortChannelsByName = (billChannels?: BillChannel[]) => {
  if (billChannels) {
    billChannels.forEach(channel => {
      channel.operators.sort((operateOne, operateTwo) => {
        return operateOne.name.toLowerCase() < operateTwo.name.toLowerCase() ? -1 : 0;
      });
    });
    return billChannels;
  }
  return undefined;
};

export function billChannelsReducer(
  state: (BillChannelsState & ActionsHistory) | null,
  action: SelfServiceActionTypes
): (BillChannelsState & ActionsHistory) | null {
  if (typeof state === 'undefined') {
    return null;
  }

  switch (action.type) {
    case TypeKeys.LOAD_BILL_CHANNELS: {
      if (state && state.items) {
        return state;
      }
      return reduceCrudAction(action, state);
    }

    case TypeKeys.LOAD_BILL_CHANNELS_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.LOAD_BILL_CHANNELS,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
      };
    }

    case TypeKeys.LOAD_BILL_CHANNELS_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.LOAD_BILL_CHANNELS,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        items: sortChannelsByName(action.billChannels),
      };
    }

    case TypeKeys.RESET_ERRORS: {
      return {
        ...state,
        errors: undefined,
      };
    }

    default:
      return state;
  }
}
