import * as CL from '@design-system/component-library';
import { dsClass } from '../../common/constants/dsClasses.js';
import { forwardRef, useId } from 'react';
import type { InputHTMLAttributes, ReactNode, Ref } from 'react';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  children?: ReactNode;
  error?: string;
  hint?: ReactNode;
  label?: ReactNode;
  tooltip?: ReactNode;
  wrapperClassName?: string;
}

export const Input = forwardRef((props: InputProps, ref: Ref<HTMLInputElement>) => {
  const id = useId();
  const { children, error, hint, label, tooltip, wrapperClassName, ...rest } = props;

  return (
    <div
      className={[
        dsClass.INPUT,
        error && dsClass.INPUT_ERROR,
        rest.disabled && dsClass.INPUT_DISABLED,
        rest.readOnly && dsClass.INPUT_READONLY,
        wrapperClassName,
      ]
        .filter(i => i)
        .join(' ')}
    >
      <div className={dsClass.INPUT_LABELAREA}>
        {label && (
          <>
            <label id={`${id}-label`} className={dsClass.INPUT_LABELAREA_LABEL} htmlFor={`${id}-input`}>
              {label}
            </label>
            {tooltip && tooltip}
          </>
        )}
      </div>
      <div className={dsClass.INPUT_INPUTAREA}>
        <input
          {...rest}
          ref={ref}
          id={`${id}-input`}
          className={[dsClass.INPUT_INPUTAREA_INPUT, props.className].filter(i => i).join(' ')}
          aria-labelledby={props.label ? `${id}-label` : undefined}
          aria-invalid={Boolean(error)}
          aria-errormessage={error ? `${id}-error` : undefined}
          aria-describedby={[error && `${id}-error`, hint && `${id}-hint`].filter(i => i).join(' ')}
        />
        {children}
      </div>
      {error && (
        <p
          id={`${id}-error`}
          className={`${dsClass.INPUTERROR} ${dsClass.DISPLAY_INLINE_FLEX} ${dsClass.ALIGN_ITEMS_CENTER}`}
          role="alert"
          aria-live="polite"
        >
          <CL.Icon color="error" icon="error" size="s" className={dsClass.MARGIN_RIGHT_1} />
          <span>{error}</span>
        </p>
      )}
      {hint && (
        <p id={`${id}-hint`} className={dsClass.INPUTHELP}>
          {hint}
        </p>
      )}
    </div>
  );
});
