/**
 * SFDC Community API
 * # Summary Elisa B2B API for an administrator of a company to query and modify the company information.  # Usage This API is used in the `https://yrityksille.elisa.fi/omaelisa` pages, but it is possible to later on expose this documentation publicly and make the endpoint queryable via external clients.  # Identifiers All entities in the API have a globally unique, internal identifier field named with the format [object_type]Id, e.g. `invoiceId`, which is also used in some of the API endpoint urls, e.g. `/api/v1/selfservice/invoices/{invoiceId}`. This value is not meaningful to the end user but is only used as an internally in the API.  Most entities also have a displayable identifier named in the format [object_type]DisplayId. This value can and should be shown to the end user, as it is also printed in relevant documents. It is also used in some of the public URLs, e.g. `https://yrityksille.elisa.fi/omaelisa/laskut/{invoiceDisplayId}`. Because of the user might land directly into a URL of a single entity with a display id, some of the API endpoints here also support querying via the display id, e.g. `/api/v1/selfservice/invoices?invoiceDisplayId={invoiceDisplayId}`.  The reason for using two identifiers instead of only one display id, is to make sure that every object will always have exactly one REST API URL that will never collide with another. While collisions of display ids should not happen in production, the id fields are guaranteed to be unique even across different test environments. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EppSolutionResponse } from './eppSolutionResponse.js';
import { Address } from './address.js';
import { AccountManager } from './accountManager.js';
import { Subscription } from './subscription.js';
import { EnrollmentProgram } from './enrollmentProgram.js';


/**
 * Successful company info response
 */
export interface CompanyInfoResponse { 
    /**
     * Business id for the company. SFDC: Account.Business_Id__c
     */
    businessId: string;
    /**
     * Name of the company. SFDC: Account.name
     */
    companyName: string;
    address: Address;
    /**
     * Phone number for the company. SFDC: Account.Phone
     */
    phoneNumber?: string;
    /**
     * List of mobile PBX lite (Puheratkaisu Vakio) solutions company has.
     */
    pbxSolutions?: Array<Subscription>;
    eppSolution?: EppSolutionResponse;
    /**
     * Miscellaneous company-specific options for controlling online behavior. E.g. SFDC: Account.OnlineProductOptions
     */
    uiOptions?: object;
    /**
     * True company has active mobile pbx solution. SFDC: csord__Subscription__c
     */
    mobilePbxSolutions?: boolean;
    /**
     * The company can be mapped as either \'Internal Customers\' or \'Others\' type of customer. SFDC : Account.Customer_Type__c. @TODO this shall be a mandatory field once this is in production from SFDC side
     */
    customerType?: CompanyInfoResponse.CustomerTypeEnum;
    /**
     * Enrollment program details
     */
    enrollmentPrograms?: Array<EnrollmentProgram>;
    /**
     * Unix timestamp in milliseconds of last modification. SFDC: Account.LastModifiedDate
     */
    lastModified: number;
    /**
     * Sales Channel of Account owner. Used to determine if certain settings like blockInvoiceDueSmsReminder are shown to end user or not. SFDC: OwnerId.Sales_Channel_Pick_list__c
     */
    salesChannel?: CompanyInfoResponse.SalesChannelEnum;
    /**
     * Indicates whether invoice due SMS reminder is blocked or not. SFDC: Block_Invoice_Due_SMS_Reminder__c
     */
    blockInvoiceDueSmsReminder?: boolean;
    accountManager?: AccountManager;
    /**
     * Indicates whether the account is blacklisted for accessing interworks (license management portal). SFDC: Account.Blacklisted_from_IW__c
     */
    isBlacklistedFromInterworks?: boolean;
}
export namespace CompanyInfoResponse {
    export type CustomerTypeEnum = 'INTERNAL_CUSTOMERS' | 'OTHERS';
    export const CustomerTypeEnum = {
        INTERNAL_CUSTOMERS: 'INTERNAL_CUSTOMERS' as CustomerTypeEnum,
        OTHERS: 'OTHERS' as CustomerTypeEnum
    };
    export type SalesChannelEnum = 'ALUEMYYNTI' | 'CIS_SALES' | 'CUSTOMER_SERVICE' | 'EDUSTAJA_YA' | 'HY_MYYMALAT' | 'INTERNATIONAL_IOT' | 'ITBU' | 'LIPE' | 'NO_SALES_CHANNEL' | 'SERVICE_MANAGEMENT' | 'SUURASIAKASMYYNTI' | 'TELESALES' | 'YRITYSGURU' | 'YRITYSPISTE' | 'UNKNOWN';
    export const SalesChannelEnum = {
        ALUEMYYNTI: 'ALUEMYYNTI' as SalesChannelEnum,
        CIS_SALES: 'CIS_SALES' as SalesChannelEnum,
        CUSTOMER_SERVICE: 'CUSTOMER_SERVICE' as SalesChannelEnum,
        EDUSTAJA_YA: 'EDUSTAJA_YA' as SalesChannelEnum,
        HY_MYYMALAT: 'HY_MYYMALAT' as SalesChannelEnum,
        INTERNATIONAL_IOT: 'INTERNATIONAL_IOT' as SalesChannelEnum,
        ITBU: 'ITBU' as SalesChannelEnum,
        LIPE: 'LIPE' as SalesChannelEnum,
        NO_SALES_CHANNEL: 'NO_SALES_CHANNEL' as SalesChannelEnum,
        SERVICE_MANAGEMENT: 'SERVICE_MANAGEMENT' as SalesChannelEnum,
        SUURASIAKASMYYNTI: 'SUURASIAKASMYYNTI' as SalesChannelEnum,
        TELESALES: 'TELESALES' as SalesChannelEnum,
        YRITYSGURU: 'YRITYSGURU' as SalesChannelEnum,
        YRITYSPISTE: 'YRITYSPISTE' as SalesChannelEnum,
        UNKNOWN: 'UNKNOWN' as SalesChannelEnum
    };
}


