import { TypeKeys, generateActionId } from './actionsUtils.js';
import { convertToCommonErrors } from '../../common/utils/errorUtils.js';
import type { Action } from 'redux';
import type { AuxiliaryEsimOrderPhaseEnum } from '../../components/AuxiliaryEsimOrderScene/auxiliaryEsimOrderEnums.js';
import type { CrudAction, ErrorAction, ErrorActionCreator } from './actionsUtils.js';
import type {
  ElisaIdLogOutAction,
  ElisaIdLogOutFailedAction,
  ElisaIdLogOutFulfilledAction,
  GetElisaIdAccessTokenAction,
  GetElisaIdAccessTokenFailedAction,
  GetElisaIdAccessTokenFulfilledAction,
  GetElisaIdSessionAction,
  GetElisaIdSessionFailedAction,
  GetElisaIdSessionFulfilledAction,
  ProcessElisaIdLoginAction,
  ProcessElisaIdLoginFailedAction,
  ProcessElisaIdLoginFulfilledAction,
  UpdateElisaIdSessionAction,
} from './employeeActions.js';
import type {
  GenericError,
  PostAuxiliaryEsimOrderResponse,
  Subscription,
  ValidateAuxiliaryEsimOrderResponse,
} from '../../generated/api/models.js';
import type {
  LoadSubscriptionsAction,
  LoadSubscriptionsFailedAction,
  LoadSubscriptionsFulfilledAction,
} from './subscriptionsActions.js';

export interface GetAuxiliaryEsimOrderSubscriptionsFailedAction extends Action {
  type: TypeKeys.AUXILIARY_ESIM_ORDER_GET_SUBSCRIPTION_FAILED;
}

export interface GetAuxiliaryEsimOrderSubscriptionsFulfilledAction extends Action {
  subscription: Subscription;
  type: TypeKeys.AUXILIARY_ESIM_ORDER_GET_SUBSCRIPTION_FULFILLED;
}

export interface ValidateAuxiliaryEsimOrderAction extends CrudAction<TypeKeys.AUXILIARY_ESIM_ORDER_VALIDATE> {
  subscription: Subscription;
}

export interface ValidateAuxiliaryEsimOrderFulfilledAction extends Action {
  response: ValidateAuxiliaryEsimOrderResponse;
  type: TypeKeys.AUXILIARY_ESIM_ORDER_VALIDATE_FULFILLED;
}

export type ValidateAuxiliaryEsimOrderFailedAction = ErrorAction<TypeKeys.AUXILIARY_ESIM_ORDER_VALIDATE_FAILED>;

export const validateAuxiliaryEsimOrder = (subscription: Subscription): ValidateAuxiliaryEsimOrderAction => ({
  id: generateActionId(),
  subscription,
  type: TypeKeys.AUXILIARY_ESIM_ORDER_VALIDATE,
});

export const validateAuxiliaryEsimOrderFulfilled = (
  response: ValidateAuxiliaryEsimOrderResponse
): ValidateAuxiliaryEsimOrderFulfilledAction => ({
  response,
  type: TypeKeys.AUXILIARY_ESIM_ORDER_VALIDATE_FULFILLED,
});

export const validateAuxiliaryEsimOrderFailed: ErrorActionCreator<TypeKeys.AUXILIARY_ESIM_ORDER_VALIDATE_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): ValidateAuxiliaryEsimOrderFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    params,
    type: TypeKeys.AUXILIARY_ESIM_ORDER_VALIDATE_FAILED,
  };
};

export type SendAuxiliaryEsimOrderAction = CrudAction<TypeKeys.AUXILIARY_ESIM_ORDER_SEND>;

export type SendAuxiliaryEsimOrderFailedAction = ErrorAction<TypeKeys.AUXILIARY_ESIM_ORDER_SEND_FAILED>;

export interface SendAuxiliaryEsimOrderFulfilledAction extends Action {
  response: PostAuxiliaryEsimOrderResponse;
  type: TypeKeys.AUXILIARY_ESIM_ORDER_SEND_FULFILLED;
}

export const sendAuxiliaryEsimOrder = (): SendAuxiliaryEsimOrderAction => ({
  id: generateActionId(),
  type: TypeKeys.AUXILIARY_ESIM_ORDER_SEND,
});

export const sendAuxiliaryEsimOrderFailed: ErrorActionCreator<TypeKeys.AUXILIARY_ESIM_ORDER_SEND_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): SendAuxiliaryEsimOrderFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    params,
    type: TypeKeys.AUXILIARY_ESIM_ORDER_SEND_FAILED,
  };
};

export const sendAuxiliaryEsimOrderFulfilled = (
  response: PostAuxiliaryEsimOrderResponse
): SendAuxiliaryEsimOrderFulfilledAction => ({
  response,
  type: TypeKeys.AUXILIARY_ESIM_ORDER_SEND_FULFILLED,
});

export interface ChangeAuxiliaryEsimOrderPhaseAction extends Action {
  phase: AuxiliaryEsimOrderPhaseEnum;
  subscriptionToken?: string;
  type: TypeKeys.AUXILIARY_ESIM_ORDER_SET_PHASE;
}

export const setAuxiliaryEsimOrderPhase = (
  phase: AuxiliaryEsimOrderPhaseEnum,
  subscriptionToken?: string
): ChangeAuxiliaryEsimOrderPhaseAction => {
  return {
    phase,
    subscriptionToken,
    type: TypeKeys.AUXILIARY_ESIM_ORDER_SET_PHASE,
  };
};

export type AuxiliaryEsimOrderActionTypes =
  | ChangeAuxiliaryEsimOrderPhaseAction
  | GetAuxiliaryEsimOrderSubscriptionsFailedAction
  | GetAuxiliaryEsimOrderSubscriptionsFulfilledAction
  | SendAuxiliaryEsimOrderAction
  | SendAuxiliaryEsimOrderFailedAction
  | SendAuxiliaryEsimOrderFulfilledAction
  | ValidateAuxiliaryEsimOrderAction
  | ValidateAuxiliaryEsimOrderFailedAction
  | ValidateAuxiliaryEsimOrderFulfilledAction
  | ElisaIdLogOutAction
  | ElisaIdLogOutFulfilledAction
  | ElisaIdLogOutFailedAction
  | GetElisaIdSessionAction
  | GetElisaIdSessionFulfilledAction
  | GetElisaIdSessionFailedAction
  | GetElisaIdAccessTokenAction
  | GetElisaIdAccessTokenFulfilledAction
  | GetElisaIdAccessTokenFailedAction
  | ProcessElisaIdLoginAction
  | ProcessElisaIdLoginFulfilledAction
  | ProcessElisaIdLoginFailedAction
  | UpdateElisaIdSessionAction
  | LoadSubscriptionsAction
  | LoadSubscriptionsFailedAction
  | LoadSubscriptionsFulfilledAction;
