import { SelectedPurposeOfUseOrContact } from '../../../common/enums.js';
import { SpecialInput } from '../../../common/formik/Fields/SpecialInput.js';
import { purposeOfUseExampleMsg, purposeOfUseMsg, t } from '../../../common/i18n/index.js';
import { useFormikContext } from 'formik';
import type { ContactOrPurposeOfUseFormValues } from '../ContactsOrPurposeOfUseUtils.js';

interface PurposeOfUseFormProps {
  required?: boolean;
}

export const PurposeOfUseForm = ({ required }: PurposeOfUseFormProps) => {
  const {
    values: { selectionTab },
  } = useFormikContext<ContactOrPurposeOfUseFormValues>();

  if (selectionTab !== SelectedPurposeOfUseOrContact.PURPOSEOFUSE) {
    return null;
  }
  return (
    <div className="of-contact-or-purpose-of-use__purpose_of_use_form">
      <SpecialInput
        name="purposeOfUse"
        helpText={t.TTZ4(purposeOfUseExampleMsg)}
        maxLength={100}
        placeholder={t.Y8KX(purposeOfUseMsg)}
        label=""
        required={required}
      />
    </div>
  );
};
