import { BillingAccountDeliveryMethod } from '../../../../generated/api/billingAccountDeliveryMethod.js';
import {
  OpenFormBillingAccount,
  OpenFormBillingInvoiceElectronic,
  OpenFormBillingInvoiceEmail,
  OpenFormBillingInvoicePaper,
  OpenFormContact,
} from '../../OpenFormComponents/OpenFormFormatted.js';
import { OpenFormGridCol, OpenFormGridRow } from '../../OpenFormComponents/OpenFormGrid.js';
import { OpenFormItem } from '../../OpenFormComponents/OpenFormItem.js';
import { type OpenFormSummary } from '../../OpenFormHooks/useOpenFormSummary.js';
import {
  billingContactMsg,
  billingDetailsMsg,
  deliveryMethodMsg,
  newBillingAgreementMsg,
  selectedBillingAccountMsg,
  t,
} from '../../../../common/i18n/index.js';

export const OpenFormSummaryBillingAccount = ({ summary }: { summary?: OpenFormSummary }) => {
  const billing = summary?.billingAccountData ?? summary?.billingAccount;
  const contact = summary?.contactEntries.find(([_, c]) => c.contactId === billing?.billingContactId)?.[1];
  return !billing ? null : (
    <OpenFormGridRow>
      <OpenFormGridCol colWidth={9}>
        <h4>{t.RPMR(billingDetailsMsg)}</h4>
        <OpenFormGridRow key={billing.billingAccountName}>
          <OpenFormGridCol colWidth={4}>
            <OpenFormItem
              title={
                'billingAccountDisplayId' in billing
                  ? t.HVS2(selectedBillingAccountMsg)
                  : t.NCIJ(newBillingAgreementMsg)
              }
            >
              <OpenFormBillingAccount billing={billing} />
            </OpenFormItem>
          </OpenFormGridCol>
          <OpenFormGridCol colWidth={4}>
            <OpenFormItem title={t.XZH6(deliveryMethodMsg)}>
              {billing.deliveryMethod === BillingAccountDeliveryMethod.ELECTRONIC ? (
                <OpenFormBillingInvoiceElectronic billing={billing} />
              ) : billing.deliveryMethod === BillingAccountDeliveryMethod.EMAIL ? (
                <OpenFormBillingInvoiceEmail billing={billing} />
              ) : billing.deliveryMethod === BillingAccountDeliveryMethod.PAPER ? (
                <OpenFormBillingInvoicePaper address={billing.payerAddress} />
              ) : null}
            </OpenFormItem>
          </OpenFormGridCol>
          <OpenFormGridCol colWidth={4}>
            <OpenFormItem title={t.EY5Q(billingContactMsg)}>
              <OpenFormContact billing={billing} contact={contact} />
            </OpenFormItem>
          </OpenFormGridCol>
        </OpenFormGridRow>
        <hr />
      </OpenFormGridCol>
    </OpenFormGridRow>
  );
};
