import * as CL from '@design-system/component-library';
import { type Choices } from '../../OpenFormAnswers.js';
import { OpenFormComboboxContact } from '../../OpenFormComponents/OpenFormComboboxContact.js';
import { type OpenFormDispatcher } from '../../OpenFormHooks/useOpenFormDispatcher.js';
import { OpenFormGridCol, OpenFormGridRow } from '../../OpenFormComponents/OpenFormGrid.js';
import { getValue } from '../../OpenFormUtils.js';
import { selectMsg, t } from '../../../../common/i18n/index.js';
import { useMemo } from 'react';
import { useOpenFormContactHeaders } from '../../OpenFormHooks/useOpenFormContactHeaders.js';
import classNames from 'classnames';

export const OpenFormQuestionContact = ({
  dispatcher,
  disabled,
  required,
  label,
  selected,
}: {
  dispatcher: OpenFormDispatcher;
  disabled: boolean;
  required: boolean;
  label: string;
  selected: Choices | undefined;
}) => {
  const contacts = useOpenFormContactHeaders();
  return (
    <OpenFormGridRow>
      <OpenFormGridCol colWidth={6} className={classNames({ ['label--mandatory']: required })}>
        <CL.Combobox
          label={label}
          i18n_combobox_placeholderText={t.QRYV(selectMsg)}
          disabled={disabled}
          items={useMemo(() => (contacts ?? []).map(OpenFormComboboxContact), [contacts])}
          selectedValue={getValue(selected)}
          onValueSelect={el =>
            dispatcher.setAnswer(
              el?.dataset.value,
              'contact',
              contacts?.find(c => c.contactId === el?.dataset.value)
            )
          }
        />
      </OpenFormGridCol>
    </OpenFormGridRow>
  );
};
