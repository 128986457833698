/**
 * Online UI API
 * # Summary Elisa B2B Online UI API  # Usage This API is used in the `https://yrityksille.elisa.fi/` pages.  # Identifiers 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DnsRecordSupportedType = 'A' | 'AAAA' | 'CAA' | 'CNAME' | 'DS' | 'MX' | 'NAPTR' | 'NS' | 'PTR' | 'SOA' | 'SPF' | 'SRV' | 'TXT';

export const DnsRecordSupportedType = {
    A: 'A' as DnsRecordSupportedType,
    AAAA: 'AAAA' as DnsRecordSupportedType,
    CAA: 'CAA' as DnsRecordSupportedType,
    CNAME: 'CNAME' as DnsRecordSupportedType,
    DS: 'DS' as DnsRecordSupportedType,
    MX: 'MX' as DnsRecordSupportedType,
    NAPTR: 'NAPTR' as DnsRecordSupportedType,
    NS: 'NS' as DnsRecordSupportedType,
    PTR: 'PTR' as DnsRecordSupportedType,
    SOA: 'SOA' as DnsRecordSupportedType,
    SPF: 'SPF' as DnsRecordSupportedType,
    SRV: 'SRV' as DnsRecordSupportedType,
    TXT: 'TXT' as DnsRecordSupportedType
};

