import { Field } from 'formik';
import { TextAreaComponent } from '../FieldComponents/TextAreaComponent.js';
import { fieldCantBeEmptyMsg, t } from '../../i18n/index.js';

export const MultipleEmails = () => {
  const validate = (value: string) => {
    const maxEmails = 500;
    if (!value) {
      return t.VPVR(fieldCantBeEmptyMsg);
    }
    const recipientEmails = value ? value.split(/\r\n|\n|\r/).filter(Boolean) : [];
    if (maxEmails && recipientEmails.length > maxEmails) {
      return t.HYGU('This field can contain up to {} email addresses.', maxEmails.toString());
    }
    return undefined;
  };

  return (
    <Field
      name="emails"
      rows={4}
      label={t.RD75('Email address or addresses')}
      placeholder={t.HVK9('Add each email address to its own row')}
      required={true}
      component={TextAreaComponent}
      validate={validate}
    />
  );
};
