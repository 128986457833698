import { DialogType } from '../../common/enums.js';
import { DialogWrapper } from '../DialogWrapper/index.js';
import { MissingMobileDialog } from '../MissingMobileDialog/MissingMobileDialog.js';
import { SelectPrimaryAccountDialog } from '../SelectPrimaryAccountDialog/SelectPrimaryAccountDialog.js';
import { SelectUsernameDialog } from '../SelectUsernameDialog/SelectUsernameDialog.js';
import {
  addMissingMobileNumberAndLogIn,
  closeDialog,
  resolveMissingPrimaryAccount,
  selectPrimaryAccountAndLogIn,
  selectUsername,
} from '../../selfservice/actions/index.js';
import { createUserRightsInSalesforceDialog } from '../SelfService/selfServiceDialogs.js';
import { paths } from '../../common/constants/pathVariables.js';
import { t } from '../../common/i18n/index.js';
import { useDispatch, useStore } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import type { DialogState } from '../../common/types/states.js';
import type { State } from '../../selfservice/common/store.js';

interface LoginDialogProps {
  dialog?: DialogState | null;
  userName?: string;
  setUserName?: (username: string) => void;
  setPassword?: (password: string) => void;
}

export const LoginDialog = ({ dialog, userName, setUserName, setPassword }: LoginDialogProps) => {
  const store = useStore<State>();
  const config = store.getState().config;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  if (
    dialog?.params?.type === DialogType.SELECT_PRIMARY_COMPANY &&
    dialog.params.availableAccounts &&
    dialog.params.availableAccounts.length > 0
  ) {
    const params = dialog.params;
    return (
      <SelectPrimaryAccountDialog
        accounts={params.availableAccounts}
        onSelectPrimaryAccount={selected =>
          params.username && params.password
            ? dispatch(selectPrimaryAccountAndLogIn(params.username, params.password, selected.accountMasterId))
            : dispatch(resolveMissingPrimaryAccount(selected.accountMasterId))
        }
        errors={dialog.errors}
        isLoading={dialog.submitInProgress}
        onCloseDialog={() => dispatch(closeDialog())}
      />
    );
  } else if (dialog?.params?.type === DialogType.SELECT_USERNAME) {
    const params = dialog.params;
    return (
      <SelectUsernameDialog
        onCloseDialog={() => dispatch(closeDialog())}
        usernames={params.usernames}
        onContinue={selected => {
          dispatch(
            selectUsername({
              userName: selected,
            })
          );
          if (userName !== selected && setUserName && setPassword) {
            setUserName(selected);
            setPassword('');
          }
        }}
        errors={dialog.errors}
        isLoading={dialog.submitInProgress}
      />
    );
  } else if (dialog?.params?.type === DialogType.MFA_REQUIRED_PHONE) {
    return (
      <DialogWrapper
        buttons={[
          {
            text: t.NYU3('Contact customer service'),
            onClick: () => {
              dispatch(closeDialog());
              navigate(paths.CONTACT_NEW_MESSAGE);
            },
          },
        ]}
        closeable={true}
        onCloseDialog={() => {}}
        header={t.VP3Y('Your contact details is missing a mobile phone number. Please contact customer service.')}
      >
        <p>
          {t.VP3X(
            'Your company has a two-factor authentication in place, but we were unable to find your phone number in your contact details. Please contact customer service to add your phone number to your contact details.'
          )}
        </p>
      </DialogWrapper>
    );
  } else if (
    dialog?.params?.type === DialogType.MISSING_MOBILE_NUMBER &&
    dialog.params.username &&
    dialog.params.password
  ) {
    const params = dialog.params;
    return (
      <MissingMobileDialog
        errors={dialog.errors}
        isLoading={dialog.submitInProgress}
        onContinue={mobileNumber => {
          if (params.username && params.password) {
            dispatch(addMissingMobileNumberAndLogIn(params.username, params.password, mobileNumber));
          }
        }}
        onCloseDialog={() => dispatch(closeDialog())}
      />
    );
  } else if (dialog?.params?.type === DialogType.USER_RIGHTS_IN_SALESFORCE) {
    return createUserRightsInSalesforceDialog(config.classicSiteUrl);
  }
  return null;
};
