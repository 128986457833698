import * as CL from '@design-system/component-library';
import { CommercialProductSubType, OnlineModelCategory } from '../../../generated/api/models.js';
import { PRODUCT_DETAILS_CLASS } from '../productDetailsConstants.js';
import { contractPriceMsg, noContractPriceMsg, paymentPeriodMsg, t } from '../../../common/i18n/index.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { formatPrice, formatPriceSummary, paymentBoxLabel, priceSummarySuffix } from '../utils/formatters.js';
import { getDefaultPaymentTerm, getEffectiveVAT } from '../utils/productDetailsUtils.js';
import { userHasEmptyOrSMEPriceGroup } from '../../../common/utils/employeeUtils.js';
import type { CommercialProduct, DiscountedPrices, EppSolutionResponse } from '../../../generated/api/models.js';

import './ProductDetailsPayment.scss';

export interface ProductDetailsPaymentProps {
  paymentMethod: number;
  setPaymentMethod: (priceOption: number) => void;
  subType: string;
  setSubType: (subType: string) => void;
  commercialProducts: CommercialProduct[];
  commercialProduct: CommercialProduct;
  quantity: number;
  setCommercialProduct: (cp: CommercialProduct) => void;
  onlineModelCategory?: string;
  discountedPrices?: DiscountedPrices;
  eppSolution?: EppSolutionResponse;
  segmentPricingGroup?: string;
  isEmployee?: boolean;
}

export const ProductDetailsPayment = ({
  paymentMethod,
  setPaymentMethod,
  subType,
  setSubType,
  commercialProducts,
  commercialProduct,
  quantity,
  setCommercialProduct,
  onlineModelCategory,
  discountedPrices,
  eppSolution,
  segmentPricingGroup,
  isEmployee,
}: ProductDetailsPaymentProps) => {
  /**
   * No chance for subscriptions to choose payment option
   */
  if (onlineModelCategory === OnlineModelCategory.SALES_PRODUCT) {
    return null;
  }

  const productHasDiscountedPrices = () => {
    const allCommercialProductCodes = commercialProducts.map(cp => cp.commercialProductCode);
    return discountedPrices?.prices.some(price => allCommercialProductCodes.includes(price.commercialProductCode));
  };

  const isDiscountedPrice = discountedPrices?.prices.some(
    price => price.commercialProductCode === commercialProduct.commercialProductCode
  );

  const segmentPriceGroupMsg =
    productHasDiscountedPrices() &&
    !isEmployee &&
    !userHasEmptyOrSMEPriceGroup(segmentPricingGroup) &&
    eppSolution?.allowDevicesWithRecurringCharges &&
    commercialProduct.productSubType !== CommercialProductSubType.EPP_DEVICE
      ? commercialProduct.payments !== 1
        ? `, ${t.VQOB(noContractPriceMsg)}`
        : `, ${t.GHE2(contractPriceMsg)}`
      : '';

  const selected = commercialProducts
    .map((p, i) => {
      if (
        paymentMethod === p.payments &&
        p.productSubType === subType &&
        p.monthlyRecurringCharge === commercialProduct.monthlyRecurringCharge &&
        p.oneTimeCharge === commercialProduct.oneTimeCharge
      ) {
        return `${i}`;
      }
      return;
    })
    .filter(Boolean)
    .shift();
  const products = commercialProducts.map((p, i) => {
    return {
      id: `${i}`,
      name: paymentBoxLabel(p),
      description: `${formatPrice(p, quantity)}${priceSummarySuffix(p)}`,
    };
  });

  return (
    <div className={`${PRODUCT_DETAILS_CLASS}-payment`}>
      <label className={`${PRODUCT_DETAILS_CLASS}-payment__label`}>{t.EM2Q(paymentPeriodMsg)}</label>
      <CL.SelectGroup
        className={dsClass.PADDING_0}
        // Below key is needed to force re-mounting `CL.SelectGroup`, otherwise when the price options change the `selected` isn't updated.
        key={JSON.stringify(commercialProducts)}
        products={products}
        onChange={(numberAsString: string) => {
          if (numberAsString) {
            const cp = commercialProducts[parseInt(numberAsString, 10)];
            setPaymentMethod(cp.payments || getDefaultPaymentTerm(onlineModelCategory));
            setSubType(cp.productSubType!);
            setCommercialProduct(cp);
          }
        }}
        value={selected}
      />
      <label className={`${PRODUCT_DETAILS_CLASS}-payment__info`}>
        {commercialProduct.payments && formatPriceSummary(commercialProduct, getEffectiveVAT(isEmployee || false))}
        {isDiscountedPrice && !segmentPricingGroup && !isEmployee && ` ${t.GHE2(contractPriceMsg)}`}
        {segmentPriceGroupMsg}
      </label>
    </div>
  );
};
