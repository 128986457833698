import * as CL from '@design-system/component-library';
import { addMsg, quantityMsg, removeMsg, subtractMsg, t } from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import type { ShoppingCartItemAddon } from '@design-system/component-library';

export interface ShoppingBasketAddonProps {
  addOns: ShoppingCartItemAddon[];
}

/**
 * This component is doing closely the same as in Design System (v. 9.1.0)
 * file ShoppingCart.tsx --> function ShoppingCartAddon(). At least the dom structure and style classes should be same.
 * That's how this is looking same than DS version.
 *
 * This is because new Shopping Basket needs to customise ShoppingCartEntries and addons that DS Shopping cart shows.
 * Originated from here: https://atlas.elisa.fi/jira/browse/OFI-53793
 * TODO: these modifications could be contributed to DS side at some point
 */

export const ShoppingBasketAddon = ({ addOns }: ShoppingBasketAddonProps) => (
  <>
    {addOns.map(addOn => (
      <li key={addOn.id} className={`${dsClass.SHOPPINGCART_PRODUCT} ${dsClass.SHOPPINGCART_PRODUCT_ADDON}`}>
        <div className={dsClass.SHOPPINGCART_PRODUCT_INFO}>
          <div className={dsClass.SHOPPINGCART_PRODUCT_IMAGE} />
          <div className={dsClass.SHOPPINGCART_PRODUCT_DETAILS}>
            <div className={dsClass.SHOPPINGCART_PRODUCT_NAME_AND_DESCRIPTION}>
              <h4 className={dsClass.SHOPPINGCART_PRODUCT_NAME}>{addOn.name}</h4>
            </div>
            <div className={dsClass.SHOPPINGCART_PRODUCT_PRICE_OPTIONS}></div>

            <div className={dsClass.SHOPPINGCART_PRODUCT_QUANTITY}>
              <CL.Quantity
                disabled
                i18n_quantity_deleteAriaLabel={t.R3VE(removeMsg)}
                i18n_quantity_minusAriaLabel={t.C2KQ(subtractMsg)}
                i18n_quantity_plusAriaLabel={t.VKFM(addMsg)}
                i18n_quantity_quantityAriaLabel={t.M0W7(quantityMsg)}
                currentValue={addOn.quantity}
                minValue={1}
                removeLessThanMin
              />
            </div>
            <div className={dsClass.SHOPPINGCART_PRODUCT_PRICE_AND_DISCLAIMER}>
              <div className={dsClass.SHOPPINGCART_PRODUCT_PRICE}>
                <span className={dsClass.SHOPPINGCART_PRICE_AMOUNT}>{addOn.price.amount}</span>
                <span className={dsClass.SHOPPINGCART_PRICE_UNIT}>{addOn.price.unit}</span>
              </div>
              <div className={dsClass.SHOPPINGCART_PRODUCT_DISCLAIMER}>
                {(addOn.disclaimer || []).map((row, rowIndex) => (
                  <div key={rowIndex}>{row}</div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </li>
    ))}
  </>
);
