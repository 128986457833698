/**
 * Online UI API
 * # Summary Elisa B2B Online UI API  # Usage This API is used in the `https://yrityksille.elisa.fi/` pages.  # Identifiers 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Feature that the user was interacting with when creating the support case. Editable (by system). SFDC: Case.Additional_Information__c.
 */
export type Feature = 'CONTACT_INFO_OFFER_REQUEST' | 'CONTACT_INFO_OPEN_ORDERS' | 'CONTACT_INFO_BILLING' | 'INVOICES' | 'BILLING_E_OPERATOR_CHANGES' | 'CONTACT_INFO_SERVICES' | 'CONTACT_INFO_TECHNICAL' | 'CONTACT_INFO_COVERAGE' | 'CONTACT_INFO_CANCEL' | 'CONTACT_INFO_PUK' | 'CONTACT_INFO_OTHER' | 'BILLING_ACCOUNTS' | 'CUSTOMER_ORDERS' | 'DELIVERY_ORDERS' | 'SUBSCRIPTIONS' | 'CONTACTS' | 'FIXED_BROADBAND_ORDER' | 'DEVICES' | 'ASSETS' | 'OTHER_SYSTEM_ORDERS';

export const Feature = {
    CONTACT_INFO_OFFER_REQUEST: 'CONTACT_INFO_OFFER_REQUEST' as Feature,
    CONTACT_INFO_OPEN_ORDERS: 'CONTACT_INFO_OPEN_ORDERS' as Feature,
    CONTACT_INFO_BILLING: 'CONTACT_INFO_BILLING' as Feature,
    INVOICES: 'INVOICES' as Feature,
    BILLING_E_OPERATOR_CHANGES: 'BILLING_E_OPERATOR_CHANGES' as Feature,
    CONTACT_INFO_SERVICES: 'CONTACT_INFO_SERVICES' as Feature,
    CONTACT_INFO_TECHNICAL: 'CONTACT_INFO_TECHNICAL' as Feature,
    CONTACT_INFO_COVERAGE: 'CONTACT_INFO_COVERAGE' as Feature,
    CONTACT_INFO_CANCEL: 'CONTACT_INFO_CANCEL' as Feature,
    CONTACT_INFO_PUK: 'CONTACT_INFO_PUK' as Feature,
    CONTACT_INFO_OTHER: 'CONTACT_INFO_OTHER' as Feature,
    BILLING_ACCOUNTS: 'BILLING_ACCOUNTS' as Feature,
    CUSTOMER_ORDERS: 'CUSTOMER_ORDERS' as Feature,
    DELIVERY_ORDERS: 'DELIVERY_ORDERS' as Feature,
    SUBSCRIPTIONS: 'SUBSCRIPTIONS' as Feature,
    CONTACTS: 'CONTACTS' as Feature,
    FIXED_BROADBAND_ORDER: 'FIXED_BROADBAND_ORDER' as Feature,
    DEVICES: 'DEVICES' as Feature,
    ASSETS: 'ASSETS' as Feature,
    OTHER_SYSTEM_ORDERS: 'OTHER_SYSTEM_ORDERS' as Feature
};

