/**
 * SFDC Anonymous API
 * # Summary Elisa B2B API for an anonymous access to salesforce data.  # Usage This API is used in the `https://yrityksille.elisa.fi/omaelisa` pages, but it is possible to later on expose this documentation publicly and make the endpoint queryable via external clients. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * SAP product category of the offer. If NORM, then offer is available from Elisa hub. If ZBA1 offer available only as direct delivery, and if ZBA2, then as direct delivery and from Elisa hub
 */
export type SapProductCategory = 'NORM' | 'ZBA1' | 'ZBA2' | 'NOT_SET';

export const SapProductCategory = {
    NORM: 'NORM' as SapProductCategory,
    ZBA1: 'ZBA1' as SapProductCategory,
    ZBA2: 'ZBA2' as SapProductCategory,
    NOT_SET: 'NOT_SET' as SapProductCategory
};

