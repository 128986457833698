import { ExposedFunctions } from '../site/exposedFunctions.js';
import { Provider } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Spinner } from '../site/siteUtils.js';
import { configureStoreWithMiddleware } from '../../selfservice/exports.js';
import { createFakeHistory } from '../common/fakeHistory.js';
import { createReduxHistoryContext } from 'redux-first-history';
import { siteEpic } from '../site/siteEpic.js';
import { siteRoutes } from '../site/SitePath.js';
import type { IHistoryContext } from 'redux-first-history';
import type { State } from '../../selfservice/exports.js';

export interface BrowserAppProps {
  callback: () => void;
  initialState: State;
}

export const BrowserApp = ({ initialState, callback }: BrowserAppProps) => {
  callback();
  const router = createBrowserRouter(siteRoutes);
  const iHistoryContext: IHistoryContext = createReduxHistoryContext({ history: createFakeHistory(router) });
  const { epicMiddleware, store } = configureStoreWithMiddleware(iHistoryContext, initialState);
  // It's important to run epicMiddleware before history so initial LOCATION_CHANGE event gets registered by Epic
  epicMiddleware.run(siteEpic);
  iHistoryContext.createReduxHistory(store);

  return (
    <Provider store={store}>
      <ExposedFunctions store={store} />
      <RouterProvider router={router} fallbackElement={<Spinner />} />
    </Provider>
  );
};
