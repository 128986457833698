import * as CL from '@design-system/component-library';
import { closeMsg, t } from '../../../common/i18n/index.js';

import './AuxiliaryEsimOrderSuccess.scss';

export interface AuxiliaryEsimOrderSuccessProps {
  onClose: () => void;
}

export const AuxiliaryEsimOrderSuccess = ({ onClose }: AuxiliaryEsimOrderSuccessProps) => {
  return (
    <div>
      <div className="ea-color-container ea-color-container--light-green of-auxiliary-esim-order-success">
        <div className="ea-color-container__content">
          <div className="of-icon--success-image"></div>
          <h4 className="ea-h4">{t.QUDL('Order successful')}</h4>
          {t.S8K2('The update may take few minutes and then you can start using your watch.')}
        </div>
      </div>
      <CL.Button block size="m" onClick={onClose}>
        {t.WOYD(closeMsg)}
      </CL.Button>
    </div>
  );
};
