/**
 * Online UI API
 * # Summary Elisa B2B Online UI API  # Usage This API is used in the `https://yrityksille.elisa.fi/` pages.  # Identifiers 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MobilePbxNewSubscriptionDetails } from './mobilePbxNewSubscriptionDetails.js';
import { SimType } from './simType.js';
import { DeliveryType } from './deliveryType.js';
import { MobilePbxOrderDetails } from './mobilePbxOrderDetails.js';


export interface OnlineOrderItemUnit { 
    /**
     * Textual description from user, how the ordered product/subscription is used. Mutually exclusive with userContact. SFDC: Online_Order__c.Json_Payload__c.
     */
    purposeOfUse?: string;
    /**
     * Internal unique Id of the contact that becomes the user of the subscription for this ordered item. Mutually exclusive with purposeOfUse. SFDC: Online_Order__c.Json_Payload__c.
     */
    userContact?: string;
    deliveryType: DeliveryType;
    simType?: SimType;
    /**
     * ICC i.e. number of an existing SIM to be activated for this subscription. If set, the order is considered to be non-remote sale, i.e. SIM card is not sent. DeliveryType should be NONE in this case. SFDC: Online_Order__c.ICCID__c.
     */
    simCardNumber?: string;
    /**
     * Set to true when number should be in public directories. SFDC: attribute in Online_Order__c.
     */
    numberPublicity?: boolean;
    /**
     * Mobile number (MSISDN) for the upcoming subscription. Either written by user (MNP IN) or chosen from free numbers. SFDC: Online_Order__c.MSISDN__c.
     */
    mobileNumber?: string;
    /**
     * Whether or not this number should be ported in, or a new number to be reserved. SFDC: Online_Order__c.MNP_Type__c.
     */
    numberPorting?: OnlineOrderItemUnit.NumberPortingEnum;
    /**
     * False if the ordering party owns the number (subscription) in the donating operator, true if it\'s owned by a person. MNP_Header__c.Donor_details__c.
     */
    differentDonor?: boolean;
    /**
     * Email address of the donor (owner of the subscription). If set, email will be sent to this address to authorize the order. SFDC: Authorization_Request__c.Email_Address__c.
     */
    donorEmail?: string;
    /**
     * Whether or not the user authorizes porting in even if the contract is fixed term in the donating operator. SFDC: MNP_Header__c.Contract_Type_At_Donor__c.
     */
    fixedTermContract?: boolean;
    /**
     * Timestamp UTC midnight of the date when the number porting should take place. SFDC: MNP_Header__c.Ordered_Transfer_Time__c.
     */
    portInDate?: number;
    mobilePbxNewSubscriptionDetails?: MobilePbxNewSubscriptionDetails;
    mobilePbxServiceLevelDetails?: MobilePbxOrderDetails;
    /**
     * List of additional parameters for Sales Products to be added to the Online Order Item. Ex: \'adminUserName\', \'10M_Olemassa_Olevan_Liittyman_Tiedot\' etc. SFDC: Online_Order_Item__c.Additional_Parameters__c
     */
    additionalParameters?: { [key: string]: string; };
    /**
     * Cost centre for this order item unit. SFDC: Online_Order__c.Json_Payload__c
     */
    costCenter?: string;
    /**
     * Subscription reference number for this order item unit. SFDC: Online_Order__c.Json_Payload__c
     */
    subscriptionReference?: string;
}
export namespace OnlineOrderItemUnit {
    export type NumberPortingEnum = 'NEW_NUMBER' | 'PORT_IN';
    export const NumberPortingEnum = {
        NEW_NUMBER: 'NEW_NUMBER' as NumberPortingEnum,
        PORT_IN: 'PORT_IN' as NumberPortingEnum
    };
}


