import * as CL from '@design-system/component-library';
import { dsClass } from '../../common/constants/dsClasses.js';
import { isMultiBiz } from '../../common/utils/accountUtils';
import { myAccountMsg, t } from '../../common/i18n/index.js';
import type { AuthenticatedUserState } from '../../common/types/states';
import type { SupportCaseContactInfo } from './types.js';

export interface PrefilledContactSupportDetailsProps {
  contactInfo: SupportCaseContactInfo;
  consolidatedViewsEnabled?: boolean;
  authenticatedUser?: AuthenticatedUserState;
}

/**
 * Contact details of a user
 *
 * These are prefilled and not part of the form as we likely
 * get them from user's account details.
 */
export const PrefilledContactSupportDetails = ({
  contactInfo,
  consolidatedViewsEnabled,
  authenticatedUser,
}: PrefilledContactSupportDetailsProps): JSX.Element => (
  <div className={dsClass.PADDING_BOTTOM_6}>
    {isMultiBiz(authenticatedUser) && consolidatedViewsEnabled ? (
      <div>
        <CL.Label>{t.SEBD(myAccountMsg)}</CL.Label>
      </div>
    ) : (
      <div className="contact-info_company-name">
        <strong>{contactInfo.businessName}</strong>
      </div>
    )}
    <div>{`${contactInfo.firstName} ${contactInfo.lastName}`}</div>
    <div>{contactInfo.email}</div>
    <div>{contactInfo.phoneNumber}</div>
    {!consolidatedViewsEnabled && <div>{contactInfo.businessId}</div>}
  </div>
);
