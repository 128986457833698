import { BreadCrumbs, getBreadCrumbLinks } from '../BreadCrumbs/index.js';
import { CategoryListContainer } from '../../content-blocks/CategoryList/CategoryListContainer.js';
import { CustomForm } from '../../content-blocks/CustomForm/CustomForm.js';
import { ProductDetailsPublicLoader } from '../ProductDetails/ProductDetailsPublicLoader.js';
import { PromotionBlockContainer } from '../../content-blocks/PromotionBlock/PromotionBlockContainer.js';
import { SnapInChat } from '../SnapInChat/SnapInChat.js';
import { throwError } from '../../common/utils/errorUtils.js';
import Templates from '@elisa-luukku-sites/cms-templates';
import type { CategoryListData, CustomFormData, PromotionBlockData } from '../../content-blocks/cmsSchema.js';
import type { ContentBlockProps, PublicPageProps } from './types.js';
import type { OnlineModel } from '../../generated/api/models.js';
import type { ProductDetailsPublicLoaderProps } from '../ProductDetails/ProductDetailsPublicLoader.js';

import '@elisa-luukku-sites/cms-templates/index.content-blocks.css';

const SiteTemplates = Templates.default;

const getOnlineModelById = (id: string, onlineModels: OnlineModel[]) => {
  const onlineModel = onlineModels.find(om => id === om.onlineModelCode);
  if (onlineModel) {
    return onlineModel;
  }
  return throwError(`Unable to load onlineModel for the id: ${id}`);
};

type ContentBlockType =
  | 'accordion'
  | 'contactGrid'
  | 'descriptionBlock'
  | 'fluidHero'
  | 'freeHTML'
  | 'iconGrid'
  | 'marketingBlock'
  | 'marketingSquares'
  | 'multiColumnTextContainer'
  | 'offerCards'
  | 'productHero'
  | 'promotionBlock'
  | 'textboxContainer'
  | 'categoryList'
  | 'customForm'
  | 'productDetails'
  | 'tableLayout';

type ContentBlockContent = (props: React.PropsWithChildren<ContentBlockProps>) => JSX.Element | null;

const contentBlockComponents: { [T in ContentBlockType]: ContentBlockContent } = {
  accordion: ({ content }) => <SiteTemplates.Accordion {...content} />,
  categoryList: ({ content, ...rest }) => <CategoryListContainer {...(content as CategoryListData)} {...rest} />,
  contactGrid: ({ content }) => <SiteTemplates.ContactGrid {...content} />,
  customForm: ({ content, sitesId, ...rest }) => (
    <CustomForm {...({ ...content, id: sitesId } as CustomFormData)} {...rest} />
  ),
  descriptionBlock: ({ content }) => <SiteTemplates.DescriptionBlock {...content} />,
  fluidHero: ({ content }) => <SiteTemplates.FluidHero {...content} />,
  freeHTML: ({ content }) => <SiteTemplates.FreeHTML {...content} />,
  iconGrid: ({ content }) => <SiteTemplates.IconGrid {...content} />,
  marketingBlock: ({ content }) => <SiteTemplates.MarketingBlock {...content} />,
  marketingSquares: ({ content }) => <SiteTemplates.MarketingSquares {...content} />,
  multiColumnTextContainer: ({ content }) => <SiteTemplates.MultiColumnTextContainer {...content} />,
  offerCards: ({ content }) => <SiteTemplates.OfferCards {...content} />,
  productDetails: ({ content, onlineModels, ...rest }) => {
    const data = content as ProductDetailsPublicLoaderProps;
    const onlineModel = getOnlineModelById(data.productGuid, onlineModels);
    if (!onlineModel) {
      return null;
    }
    return <ProductDetailsPublicLoader {...data} {...rest} onlineModel={onlineModel} />;
  },
  productHero: ({ content }) => <SiteTemplates.ProductHero {...content} />,
  promotionBlock: ({ content, onlineModelHeaders, ...rest }) => {
    return (
      <PromotionBlockContainer {...(content as PromotionBlockData)} {...rest} onlineModelHeaders={onlineModelHeaders} />
    );
  },
  textboxContainer: ({ content }) => <SiteTemplates.TextboxContainer {...content} />,
  tableLayout: ({ content }) => <SiteTemplates.TableLayout {...content} />,
};

const ContentBlock = (props: ContentBlockProps) => {
  const contentBlockTypeLowerCase = props.contentBlockType.toLowerCase();
  for (const [name, LocalComponent] of Object.entries(contentBlockComponents)) {
    if (`cb-${name.toLowerCase()}` === contentBlockTypeLowerCase) {
      return <LocalComponent {...props} />;
    }
  }
  return null;
};

export const PublicPage = (props: PublicPageProps) => {
  return (
    <>
      <div className="page site cms-page of-public-page" data-page-id={props.pageId}>
        <BreadCrumbs breadCrumbPaths={getBreadCrumbLinks(props.breadcrumbs, props.siteStructureNodes)} />
        {props.children}
        {props.contentBlocks.map((contentBlockProps, index) => {
          const { contentBlockType, fields, contentBlockId } = contentBlockProps;
          const id = 'id' in fields && typeof fields.id === 'string' ? fields.id : 'block-' + contentBlockId;
          return (
            <div
              id={id}
              key={`of-content-block-${contentBlockId}-${index}`}
              data-content-block-id={contentBlockId}
              data-content-block-type={contentBlockType}
            >
              <ContentBlock
                sitesId={id}
                contentBlockType={contentBlockType}
                content={{ ...fields, id: `content-${id}` }}
                {...props}
              />
            </div>
          );
        })}
      </div>
      <SnapInChat />
    </>
  );
};
