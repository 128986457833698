import { TypeKeys } from '../actions/index.js';
import type { ActionsHistory } from '../common/store.js';
import type { OnlineModelHeadersState } from '../../common/types/states.js';
import type { SelfServiceActionTypes } from '../actions/index.js';

export function onlineModelHeadersReducer(
  state: (OnlineModelHeadersState & ActionsHistory) | null,
  action: SelfServiceActionTypes
): (OnlineModelHeadersState & ActionsHistory) | null {
  if (state === undefined) {
    return null;
  }

  switch (action.type) {
    case TypeKeys.LOAD_ONLINE_MODELS: {
      return {
        ...state,
        errors: undefined,
      };
    }

    case TypeKeys.LOAD_ONLINE_MODELS_FAILED: {
      return {
        ...state,
        errors: action.errors,
      };
    }

    case TypeKeys.LOAD_ONLINE_MODELS_FULFILLED: {
      if (!action.headersOnly) {
        return state;
      }
      return {
        ...state,
        errors: undefined,
        items: {
          ...state?.items,
          [action.category]: action.response.headers || [],
        },
      };
    }
    default: {
      return state;
    }
  }
}
