import { type BillingAccountData } from '../OpenFormAnswers.js';
import { fetchOpenFormAccount } from '../../../common/fetch.js';
import { loadingAccountMsg, t } from '../../../common/i18n/index.js';
import { useEffect, useState } from 'react';
import { useOpenFormAsync } from '../OpenFormProvider.js';
import type { AccountHeader } from '../../../generated/api/accountHeader.js';
import type { Address } from '../../../generated/api/address.js';
import type { OpenFormAccountResponse } from '../../../generated/api/openFormAccountResponse.js';

export class OpenFormAccount {
  constructor(private readonly response: OpenFormAccountResponse) {}

  get address(): Address {
    return this.response.address;
  }

  get billingAccountData(): BillingAccountData {
    return {
      accountMasterId: this.header.accountMasterId,
      payerAddress: this.address,
      payerBusinessId: this.header.businessId,
      payerName: this.header.accountName,
    };
  }

  get header(): AccountHeader {
    return this.response.header;
  }
}

export const useOpenFormAccount = () => {
  const [data, setData] = useState<OpenFormAccount | undefined>(undefined);
  const async = useOpenFormAsync();

  useEffect(() => {
    const { ctrl } = new async(({ signal }) => fetchOpenFormAccount({ signal }))
      .resolved(response => setData(new OpenFormAccount(response)))
      .rejected(async.actions.error)
      .cache()
      .execute(t.OA8P(loadingAccountMsg));

    return () => ctrl.abort();
  }, [async]);

  return data;
};
