import type { SubscriptionPbxConfiguration } from '../../generated/api/subscriptionPbxConfiguration.js';

/**
 * Dropdown needs values to be strings and not numbers; otherwise either the initial
 * selection is not visible or the new selection is not visible.
 */
export interface DropdownKludgeSubscriptionPbxConfiguration
  extends Omit<SubscriptionPbxConfiguration, 'delayForChainCalls'> {
  delayForChainCalls?: string;
}

export const fromKludgyConfig = (config: DropdownKludgeSubscriptionPbxConfiguration): SubscriptionPbxConfiguration => ({
  ...JSON.parse(JSON.stringify(config)),
  delayForChainCalls: config.delayForChainCalls ? Number(config.delayForChainCalls) : undefined,
});

export const toKludgyConfig = (config: SubscriptionPbxConfiguration): DropdownKludgeSubscriptionPbxConfiguration => ({
  ...JSON.parse(JSON.stringify(config)),
  delayForChainCalls: config.delayForChainCalls ? `${config.delayForChainCalls}` : undefined,
});
