import { AuthorizationError } from './AuthorizationError.js';
import { AuthorizationOwnerChange } from './AuthorizationOwnerChange.js';
import { AuthorizationRequestType } from '../../common/enums.js';
import { Spinner } from '../../public/site/siteUtils.js';
import type { AuthorizationRequestState } from '../../common/types/states.js';

export interface AuthorizationSceneProps {
  authorizationRequest: AuthorizationRequestState | null;
}

export const AuthorizationScene = ({ authorizationRequest }: AuthorizationSceneProps) => {
  if (!authorizationRequest || authorizationRequest.loading) {
    return <Spinner />;
  }

  if (authorizationRequest.errors) {
    return <AuthorizationError errors={authorizationRequest.errors} />;
  }

  if (authorizationRequest.type === AuthorizationRequestType.OWNER_CHANGE) {
    return <AuthorizationOwnerChange authorizationRequest={authorizationRequest} />;
  }
  return null;
};
