import { costCenterMsg, idNumberMsg, subscriptionReferenceMsg, t } from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { highlightKeyword } from '../../common/utils/searchFieldUtils.js';
import type { SubscriptionHeader } from '../../generated/api/models.js';

interface SubscriptionHiddenFieldsProps {
  subscriptionHeader: SubscriptionHeader;
  matchedFields?: string[];
  search?: string;
}

interface HiddenField {
  name: 'subscriptionDisplayId' | 'costCenter' | 'subscriptionReference';
  getTitle: () => string;
}

export const SubscriptionHiddenFields = ({
  matchedFields,
  subscriptionHeader,
  search,
}: SubscriptionHiddenFieldsProps) => {
  // These are the fields that are normally hidden in the subscription table. The field is only shown if the search matches it.
  const subscriptionHiddenFields: HiddenField[] = [
    {
      name: 'subscriptionDisplayId',
      getTitle: () => t.YTH3(idNumberMsg),
    },
    {
      name: 'costCenter',
      getTitle: () => t.QJUW(costCenterMsg),
    },
    {
      name: 'subscriptionReference',
      getTitle: () => t.P87N(subscriptionReferenceMsg),
    },
  ];
  return (
    <>
      {subscriptionHiddenFields
        .filter(hidden => matchedFields?.includes(hidden.name))
        .map((hiddenFields: HiddenField) => {
          const field = subscriptionHeader[hiddenFields.name];
          if (field) {
            return (
              <div
                key={hiddenFields.name}
                className={`${dsClass.FONT_WEIGHT_400} ${dsClass.FONT_SIZE_SMALL} ${dsClass.FONT_STYLE_NORMAL}`}
              >
                {hiddenFields.getTitle()}: {highlightKeyword(field, search)}
              </div>
            );
          }
          return null;
        })}
    </>
  );
};
