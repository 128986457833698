import { NEW_BILLING_ACCOUNT } from '../OpenFormHooks/useOpenFormSummary.js';
import {
  billingAccountExtensionNameMsg,
  billingAccountNameMsg,
  newBillingAgreementMsg,
  t,
} from '../../../common/i18n/index.js';
import type { BillingAccountHeader } from '../../../generated/api/billingAccountHeader.js';

export const OpenFormComboboxBillingAccount = (ba: typeof NEW_BILLING_ACCOUNT | BillingAccountHeader) => {
  if (ba === NEW_BILLING_ACCOUNT) {
    return {
      id: NEW_BILLING_ACCOUNT,
      value: NEW_BILLING_ACCOUNT,
      label: t.NCIJ(newBillingAgreementMsg),
      html: <>{t.NCIJ(newBillingAgreementMsg)}</>,
    };
  }
  const label = [ba.billingAccountDisplayId, ba.payerNameExtension ?? ba.payerName].filter(Boolean).join(' ');
  return {
    id: ba.billingAccountId!,
    value: ba.billingAccountId!,
    label: label,
    html: (
      <div>
        <div className="billing-account__label-container">
          <h5 className="billing-account__label-h5">{label}</h5>
        </div>
        {!ba.billingAccountName ? null : (
          <div>
            {t.RH6T(billingAccountNameMsg)}: {ba.billingAccountName}
          </div>
        )}
        {!ba.billingAccountExtensionName ? null : (
          <div>
            {t.KUTS(billingAccountExtensionNameMsg)}: {ba.billingAccountExtensionName}
          </div>
        )}
      </div>
    ),
  };
};
