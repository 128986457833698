import { SiteContext } from '../../public/site/SiteContext.js';
import { t } from '../../common/i18n/index.js';
import { useContext } from 'react';
import classNames from 'classnames';

import './AppStoreBadges.scss';

type BadgeAnchor = Omit<
  React.ComponentPropsWithRef<'a'>,
  'aria-label' | 'children' | 'className' | 'name' | 'target' | 'title'
>;

export interface AppStoreBadgesProps extends React.ComponentPropsWithRef<'div'> {
  apple?: BadgeAnchor;
  google?: BadgeAnchor;
}

/*
- https://developer.apple.com/app-store/marketing/guidelines/
- https://partnermarketinghub.withgoogle.com/brands/google-play/visual-identity/badge-guidelines/?folder=65628
- Google's finnish badge's AI version is busted: it's in english (unlike the PNG version, which we're using)
*/
export const AppStoreBadges = ({ apple, className, google, ...wrapperProps }: AppStoreBadgesProps) => {
  const { siteLanguage } = useContext(SiteContext);
  if (!apple && !google) {
    return null;
  }
  const inFinnish = siteLanguage.startsWith('fi');
  const appleSrc = inFinnish ? '14hqphUgQHEXB422qXbKff' : '5P7WSplDuizlP368vuT0WZ';
  const googleSrc = inFinnish ? '4Quac9tfHQ9MAA3xakXvuN' : '3bnUrPtia4qbnCBDm2fMfO';
  return (
    <div {...wrapperProps} className={classNames('of-app-store-badges', className)}>
      {apple && (
        <a {...apple} className="of-app-store-badges__apple" target="_blank">
          <img
            alt={t.Z9IW('Download on the App Store')}
            height={40}
            src={`https://static.elisa.com/v2/image/2tqybbhjs47b/${appleSrc}/i.svg`}
            width={inFinnish ? 143 : 120}
          />
        </a>
      )}
      {google && (
        <a {...google} className="of-app-store-badges__google" target="_blank">
          <img
            alt={t.Z9IX('Get it on Google Play')}
            height={40}
            src={`https://static.elisa.com/v2/image/2tqybbhjs47b/${googleSrc}/i.png?h=40`}
            srcSet={`https://static.elisa.com/v2/image/2tqybbhjs47b/${googleSrc}/i.png?h=80 2x`}
            width={135}
          />
        </a>
      )}
    </div>
  );
};
