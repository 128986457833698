import { EmployeeSubscriptionsList } from '../EmployeeSubscriptionsList/EmployeeSubscriptionsList.js';
import { HeroHeading, HeroHeadingType } from '../HeroHeading/index.js';
import { LocalNavi } from '../LocalNavi/index.js';
import { MissingSubscriptionInfo } from './MissingSubscriptionInfo.js';
import { SubscriptionCategory } from '../../common/enums.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { generatePath, useNavigate } from 'react-router-dom';
import { getSubscriptionsDisplayCategories } from '../EmployeeSubscriptionDetails/employeeSubscriptionUtils.js';
import { omaElisaForEmployeeMsg, t, voiceAndBroadbandMsg } from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useSelector } from 'react-redux';
import { useTitle } from '../../common/hooks/useTitle.js';
import type { State } from '../../selfservice/common/store.js';

import './EmployeeSubscriptions.scss';

export const EmployeeVoiceAndBroadbandSubscriptions = () => {
  const navigate = useNavigate();
  const { authenticatedUser } = useAuth();
  const { voiceSubscriptions, broadbandSubscriptions, isCatalogPresent, subscriptionActions, imagesBaseUrl } =
    useSelector(
      (state: State) => ({
        voiceSubscriptions: state?.user?.authenticated?.subscriptions?.voice,
        broadbandSubscriptions: state?.user?.authenticated?.subscriptions?.broadband,
        isCatalogPresent:
          (state?.selfservice?.virtualCatalogs?.items?.filter(catalog => catalog.published) ?? []).length > 0,
        subscriptionActions: state?.selfservice?.pendingSubscriptionActions?.items || [],
        imagesBaseUrl: state?.config?.imagesBaseUrl,
      }),
      deepEqual
    );

  useTitle([t.H6JV(omaElisaForEmployeeMsg), t.JPRH(voiceAndBroadbandMsg)]);

  return (
    <div className="of-employee-subscriptions">
      <HeroHeading
        center={true}
        fullScreen={true}
        heroHeadingType={HeroHeadingType.EMPLOYEE_SUBSCRIPTIONS}
        title={t.K31E('My subscriptions')}
      />
      <LocalNavi
        categories={getSubscriptionsDisplayCategories(!!authenticatedUser?.expiringEppSubscriptions?.length)}
      />
      <EmployeeSubscriptionsList
        items={voiceSubscriptions}
        category={SubscriptionCategory.VOICE}
        isCatalogPresent={isCatalogPresent}
        isUserProvisioned={authenticatedUser?.businessId !== undefined}
        imagesBaseUrl={imagesBaseUrl}
        subscriptionActions={subscriptionActions}
        onClickEmployeeSubscription={(subscriptionId: string) =>
          navigate(generatePath(paths.EMPLOYEE_VOICE_SUBSCRIPTION, { subscriptionId }))
        }
      />
      <EmployeeSubscriptionsList
        items={broadbandSubscriptions}
        category={SubscriptionCategory.BROADBAND}
        isCatalogPresent={isCatalogPresent}
        isUserProvisioned={authenticatedUser?.businessId !== undefined}
        imagesBaseUrl={imagesBaseUrl}
        subscriptionActions={subscriptionActions}
        onClickEmployeeSubscription={(subscriptionId: string) =>
          navigate(generatePath(paths.EMPLOYEE_BROADBAND_SUBSCRIPTION, { subscriptionId }))
        }
      />
      <MissingSubscriptionInfo
        onClickEmployeeDetails={() => navigate(paths.EMPLOYEE_OWN_INFO)}
        subscriptionCategory={SubscriptionCategory.VOICE}
      />
    </div>
  );
};
