import { type OpenForm } from '../OpenFormHooks/useOpenForm.js';
import { type OpenFormAnswers } from '../OpenFormAnswers.js';
import { OpenFormButton } from '../OpenFormComponents/OpenFormButton.js';
import { OpenFormGridCol, OpenFormGridRow } from '../OpenFormComponents/OpenFormGrid.js';
import { OpenFormIcon } from '../OpenFormComponents/OpenFormIcon.js';
import { OpenFormSummaryBillingAccount } from './OpenFormSummary/OpenFormSummaryBillingAccount.js';
import { OpenFormSummaryDeliveryDateTime } from './OpenFormSummary/OpenFormSummaryDeliveryDateTime.js';
import { OpenFormSummaryOrderingContact } from './OpenFormSummary/OpenFormSummaryOrderingContact.js';
import { OpenFormSummaryOtherContacts } from './OpenFormSummary/OpenFormSummaryOtherContacts.js';
import { OpenFormSummaryProductTable } from './OpenFormSummary/OpenFormSummaryProductTable.js';
import { confirmMsg, previousMsg, submittingFormMsg, summaryMsg, t } from '../../../common/i18n/index.js';
import { parsePath } from '../OpenFormUtils.js';
import { paths } from '../../../common/constants/pathVariables.js';
import { postOpenFormCustomerNeed } from '../../../common/fetch.js';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOpenFormAsync } from '../OpenFormProvider.js';
import { useOpenFormSummary } from '../OpenFormHooks/useOpenFormSummary.js';
import type { FormId } from '../../../common/constants/pathInterfaces.js';

export const OpenFormViewSummary = ({
  preview,
  form,
  answers,
  disabled,
  valid,
  clear,
  prev,
}: {
  preview: boolean;
  form: OpenForm;
  answers: OpenFormAnswers;
  disabled: boolean;
  valid: boolean;
  clear: () => void;
  prev: () => void;
}) => {
  const async = useOpenFormAsync();
  const navigate = useNavigate();
  const summary = useOpenFormSummary(form, answers);

  const submit = useCallback(() => {
    const request = summary?.openFormCustomerNeedRequest;
    request &&
      new async(() => postOpenFormCustomerNeed(request))
        .resolved(() => {
          clear();
          navigate(parsePath<FormId>(paths.OPENFORM_VIEW_COMPLETED, { formId: form.formId }));
        })
        .rejected(text => async.actions.notification({ text, type: 'error' }))
        .execute(t.JNCH(submittingFormMsg));
  }, [form, clear, async, navigate, summary]);

  return (
    <div className="of-openform__view__summary">
      <OpenFormGridRow>
        <h3>{t.QF5T(summaryMsg)}</h3>
      </OpenFormGridRow>
      <OpenFormSummaryOrderingContact summary={summary} />
      <OpenFormSummaryBillingAccount summary={summary} />
      <OpenFormSummaryOtherContacts summary={summary} />
      <OpenFormSummaryDeliveryDateTime date={summary?.deliveryDate} time={summary?.deliveryTime} />
      <OpenFormSummaryProductTable products={summary?.products} services={summary?.services} />
      <OpenFormGridRow className="of-openform__view__footer">
        <OpenFormGridCol className="of-openform__view__footer__content">
          <OpenFormButton
            iconPosition="left"
            icon={<OpenFormIcon icon="arrow-left" />}
            text={t.CACI(previousMsg)}
            color="link"
            size="m"
            disabled={disabled}
            onClick={prev}
          />
          {preview ? null : (
            <OpenFormButton
              iconPosition="right"
              icon={<OpenFormIcon icon="check" />}
              text={t.QVYK(confirmMsg)}
              color="primary"
              size="m"
              disabled={disabled || !valid || !form.sections.every(answers.isCompleted)}
              onClick={submit}
            />
          )}
        </OpenFormGridCol>
      </OpenFormGridRow>
    </div>
  );
};
