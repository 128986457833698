import { ProductGridContainer } from './ProductGridContainer.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { resolvePrices, setDiscountsForOnlineModelHeaders } from '../../common/utils/priceUtils.js';
import { toProductGridProduct } from '../common/cmsTemplatesUtils.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import type * as CLT from '@design-system/component-library';
import type { CatalogProduct } from '../../common/utils/catalogUtils.js';
import type { CategoryListData } from '../cmsSchema.js';
import type { OnlineModelHeader } from '../../generated/api/onlineModelHeader.js';
import type { State } from '../../selfservice/common/store.js';

export type ProductGridItem = CatalogProduct & { product?: CLT.ProductGridProduct };
export const CategoryList = ({
  categoryListData,
  onlineModelHeaders,
}: {
  categoryListData: CategoryListData;
  onlineModelHeaders: OnlineModelHeader[];
}) => {
  const companyInfo = useSelector((state: State) => state.selfservice?.companyInfo, deepEqual);
  const { authenticatedUser } = useAuth();
  const { onAnalyticsEvent, eCommerceEvents, infinityScroll, numberOfItems, title, titleTag, content } =
    categoryListData;

  const discountedOnlineModelHeaders = useMemo(() => {
    const mapOnlineModeHeaderToCatalogProduct = (headers: OnlineModelHeader[]): CatalogProduct[] => {
      return headers
        .filter(onlineModelHeader => !onlineModelHeader.tags?.includes('Hide from list'))
        .map(onlineModelHeader => {
          const {
            created,
            listingImage,
            manufacturer,
            offerBadge,
            onlineModelCode,
            onlineModelName,
            pagePath,
            tags = [],
          } = onlineModelHeader;
          return {
            created,
            code: onlineModelCode,
            imageUrl: listingImage,
            manufacturer,
            name: onlineModelName,
            offerBadge,
            pagePath,
            ref: onlineModelHeader,
            tags,
            ...resolvePrices(onlineModelHeader.lowestPrices.flatMap(prices => prices.prices)),
            product: toProductGridProduct(authenticatedUser, onlineModelHeader),
          };
        });
    };
    return [
      ...mapOnlineModeHeaderToCatalogProduct(
        setDiscountsForOnlineModelHeaders(onlineModelHeaders, companyInfo, authenticatedUser)
      ),
    ];
  }, [onlineModelHeaders, companyInfo, authenticatedUser]);

  if (onlineModelHeaders.length === 0) {
    return null;
  }

  // Due to how the sites-templates are now built we no longer get the contentBlockId at this level
  // Thus we have to build some other value to differentiate between multiple category lists on a page
  // The aforementioned functionality is required for the persistence of search/filter/sort params on categorylists
  const contentBlockId = categoryListData.productsWithTags?.toString()?.replace(',', '');

  return (
    <ProductGridContainer
      items={discountedOnlineModelHeaders}
      content={content}
      title={title}
      titleTag={titleTag}
      category={onlineModelHeaders[0].category}
      onAnalyticsEvent={onAnalyticsEvent}
      eCommerceEvents={eCommerceEvents}
      infiniteScrollActive={infinityScroll}
      itemLimit={numberOfItems}
      id={contentBlockId}
    />
  );
};
