import { AuthenticatedUserRole } from '../../generated/api/models.js';
import { EMPTY, of } from 'rxjs';
import {
  TypeKeys,
  submitShoppingCartChange,
  submitShoppingCartChangeFailed,
  submitShoppingCartChangeFulfilled,
} from '../actions/index.js';
import { callUiApi } from '../common/uiApiUtils.js';
import { combineEpics, ofType } from 'redux-observable';
import { createShoppingCartPrivateMethod, updateShoppingCartPrivateMethod } from '../../generated/api/uiApiMethods.js';
import { flatMap } from 'rxjs/operators';
import {
  getShoppingCart,
  getShoppingCartId,
  removeShoppingCartId,
  setShoppingCartId,
} from '../common/localStorageUtils.js';
import type { Action } from 'redux';
import type { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import type {
  BundleItemsEntity,
  CartItemType,
  PeriodicEntity,
} from '../common/shopping-cart/shoppingCartInterfaces.js';
import type { EpicDependencies } from './epicUtils.js';
import type { SelfServiceActionTypes, SubmitShoppingCartChangeFulfilledAction } from '../actions/index.js';
import type { State } from '../common/store.js';

interface LookupObject {
  price: number;
  payments: number;
  path: string;
}

interface ShoppingCartResponse {
  shoppingCartId?: string;
}

const createLookupObject = (bundleItems: BundleItemsEntity): LookupObject | null => {
  if ('price' in bundleItems) {
    if (bundleItems.price.onetime?.price !== 0) {
      return {
        price: bundleItems.price.onetime.price,
        payments: 0,
        path: 'onetime',
      };
    }

    if (bundleItems.price.periodic?.length && bundleItems.price.periodic![0].price !== 0) {
      return {
        price: bundleItems.price.periodic[0].price,
        payments: bundleItems.price.periodic[0].payments,
        path: 'periodic',
      };
    }
  }
  return null;
};

/**
 * TODO remove getCommercialProductCode related code once new ShoppingCart is saved to localStorage
 * @param item
 */
const getCommercialProductCode = (item: CartItemType) => {
  if ('bundleItems' in item && item.bundleItems.length) {
    const bundleItem = item.bundleItems[0];
    const lookupObject = createLookupObject(bundleItem);

    if (lookupObject) {
      const price = bundleItem.price;
      if (lookupObject.path === 'onetime' && price.onetime.price === lookupObject.price) {
        return price.guids[0];
      }

      if (lookupObject.path === 'periodic' && price.periodic?.length) {
        const entity = price.periodic?.[0] as PeriodicEntity;
        if (entity.price === lookupObject.price && entity.payments === lookupObject.payments) {
          return price.guids[0];
        }
      }
    }
  }
  return '';
};

const createShoppingCartForUiApi = () => {
  const shoppingCart: CartItemType[] = JSON.parse(getShoppingCart());
  return {
    type: 'ACCOUNT',
    items: shoppingCart.map(item => {
      const product = item.bundleItems[0].product;
      return {
        commercialProductCode: getCommercialProductCode(item),
        offerCode: product.guid,
        quantity: item.quantity,
      };
    }),
  };
};

export const notifyShoppingCartChangeEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>
) => {
  return action$.pipe(
    ofType(TypeKeys.NOTIFY_SHOPPING_CART_CHANGE),
    flatMap(() => {
      if (!state$.value.shoppingCart?.submitInProgress) {
        return of(submitShoppingCartChange());
      }
      return EMPTY;
    })
  );
};

export const processShoppingCartSubmitQueueEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) => {
  return action$.pipe(
    ofType(TypeKeys.SUBMIT_SHOPPING_CART_CHANGE),
    flatMap(() => {
      const authUserState = state$.value.user?.authenticated;
      const isAuthenticated =
        authUserState?.userRole &&
        ![AuthenticatedUserRole.EMPLOYEE, AuthenticatedUserRole.PUNCHOUT_USER].includes(authUserState.userRole);

      if (isAuthenticated) {
        const shoppingCartId = getShoppingCartId();
        const payload = createShoppingCartForUiApi();
        if (!shoppingCartId && payload.items.length === 0) {
          // if there is no shopping cart id created and there are no items in the shopping cart, then there is no need to create a shopping cart with empty items
          return EMPTY;
        }
        return callUiApi({
          method: shoppingCartId ? updateShoppingCartPrivateMethod(shoppingCartId) : createShoppingCartPrivateMethod(),
          payload,
          state$,
          epicDependencies,
          successAction: res =>
            submitShoppingCartChangeFulfilled(
              shoppingCartId ? undefined : (res as { response: ShoppingCartResponse }).response.shoppingCartId
            ),
          failureAction: submitShoppingCartChangeFailed,
        });
      }
      return EMPTY;
    })
  );
};

export const processShoppingCartSubmitQueueFulfilledEpic: Epic<
  SelfServiceActionTypes,
  Action,
  State,
  EpicDependencies
> = (action$: ActionsObservable<SelfServiceActionTypes>, state$: StateObservable<State>) => {
  return action$.pipe(
    ofType(TypeKeys.SUBMIT_SHOPPING_CART_CHANGE_FULFILLED),
    flatMap((action: SubmitShoppingCartChangeFulfilledAction) => {
      if (action.shoppingCartId) {
        setShoppingCartId(action.shoppingCartId);
      }
      if (state$.value.shoppingCart?.submitsQueued === true) {
        return of(submitShoppingCartChange());
      }
      return EMPTY;
    })
  );
};

export const cleanUpAfterOnlineOrderEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>
) => {
  return action$.pipe(
    ofType(TypeKeys.SUBMIT_ONLINE_ORDER_FULFILLED),
    flatMap(() => {
      removeShoppingCartId();
      return EMPTY;
    })
  );
};

export const shoppingCartEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = combineEpics(
  notifyShoppingCartChangeEpic,
  processShoppingCartSubmitQueueEpic,
  processShoppingCartSubmitQueueFulfilledEpic,
  cleanUpAfterOnlineOrderEpic
);
