import { TextArea } from '../../../components/TextArea/TextArea.js';
import { getIn } from 'formik';
import type { FieldInputProps, FormikComputedProps, FormikHelpers, FormikState, FormikValues } from 'formik';
import type { ReactNode, TextareaHTMLAttributes } from 'react';

interface TextAreaComponentProps extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'form'> {
  field: FieldInputProps<string>;
  form: FormikState<FormikValues> & FormikHelpers<FormikValues> & FormikComputedProps<FormikValues>;
  children?: ReactNode;
  label?: string;
  error?: string;
}

export const TextAreaComponent = (props: TextAreaComponentProps) => {
  const { field, form, ...rest } = props;
  const { label } = props;
  return (
    <div id={field.name}>
      <div className="of-formik-text-input">
        <TextArea
          {...rest}
          name={field.name}
          value={field.value}
          onBlur={field.onBlur}
          onChange={field.onChange}
          label={label}
          error={(getIn(form.touched, field.name) && getIn(form.errors, field.name)) || undefined}
          minLength={props.minLength}
          rows={props.rows || 3}
        />
      </div>
    </div>
  );
};
