import * as CL from '@design-system/component-library';
import { DialogType } from '../../common/enums.js';
import { Field, Form, Formik } from 'formik';
import { InputComponent } from '../../common/formik/FieldComponents/index.js';
import { ProvidedDropdown, ProvidedInput, ProvidedRadio, validateForm } from '../../common/formik/index.js';
import { addDays } from 'date-fns';
import { dsClass } from '../../common/constants/dsClasses.js';
import { formatTimestampToDDMMYYYY, isNotAllowedDate } from '../../common/utils/dateUtils.js';
import { nextMsg, operatorOfSubscriptionMsg, selectMsg, t } from '../../common/i18n/index.js';
import { showDialog } from '../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import type { DialogParams } from '../../common/types/dialog.js';
import type { FormikProps } from 'formik';

import './FixedBroadbandSetupDate.scss';

enum Operator {
  DNA = 'DNA',
  ELISA = 'Elisa',
  TELIA = 'Telia',
}

interface FixedBroadbandSetupDateProps {
  onNext: (values: FixedBroadbandInstallationDetails) => void;
  isMobileProduct: boolean;
  holidays: Date[];
}

export interface FixedBroadbandInstallationDetails {
  installationDate: string;
  existingBroadband: string;
  existingBroadbandOperator?: string;
  existingSubscriptionContractNumber?: string;
  currentOperator: string;
}

export const FixedBroadbandSetupDate = ({ onNext, isMobileProduct, holidays }: FixedBroadbandSetupDateProps) => {
  const dispatch = useDispatch();

  const onShowDialog = (params: DialogParams) => dispatch(showDialog(params));

  const showDayPickerDialog = (formik: FormikProps<FixedBroadbandInstallationDetails>) => {
    onShowDialog({
      currentlySelectedDate: undefined,
      isDayDisabled: [{ before: addDays(new Date(), 7) }, (date: Date) => isNotAllowedDate(date, holidays)],
      onSelectDay: (date: Date) => {
        const formattedDate = formatTimestampToDDMMYYYY(date.getTime());
        formik.setFieldValue('installationDate', formattedDate);
      },
      type: DialogType.DAY_PICKER_DIALOG,
    });
  };

  const initialValues: FixedBroadbandInstallationDetails = {
    existingSubscriptionContractNumber: '',
    existingBroadband: '',
    existingBroadbandOperator: '',
    currentOperator: '',
    installationDate: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => {
        onNext(values);
      }}
      validate={formikValues => {
        return validateForm(formikValues, { existingBroadband: { required: !isMobileProduct } });
      }}
    >
      {formik => (
        <Form>
          {isMobileProduct ? null : (
            <>
              <h3 className="ea-h3">
                {t.WLQ1('Do you want to replace the existing broadband connection in the given address?')}
              </h3>
              <ProvidedRadio
                name="existingBroadband"
                radioButtons={[
                  {
                    label: t.A37D('No'),
                    value: 'no',
                  },
                  {
                    label: t.M03S('Yes'),
                    value: 'yes',
                  },
                ]}
                withError={true}
              />
            </>
          )}
          {formik.values.existingBroadband === 'yes' && (
            <>
              <ProvidedDropdown
                label={t.A5G4(operatorOfSubscriptionMsg)}
                required={true}
                name="existingBroadbandOperator"
                placeholderText={t.QRYV(selectMsg)}
                items={[
                  { value: 'elisa', label: Operator.ELISA },
                  { value: 'dna', label: Operator.DNA },
                  { value: 'telia', label: Operator.TELIA },
                  { value: 'other', label: t.C6WY('Other') },
                ]}
              />
              {formik.values.existingBroadbandOperator === 'other' && (
                <ProvidedInput label="" name="currentOperator" placeholder={t.SKP7('Other operator')} required={true} />
              )}
              <ProvidedInput
                label=""
                name="existingSubscriptionContractNumber"
                placeholder={t.REA8('Contract number')}
                required={true}
              />
            </>
          )}
          <h3 className="ea-h3">{t.RF14('Preferred installation date of subscription')}</h3>
          <div>
            {t.D55A('If installation date is left empty then the broadband will be delivered as soon as possible.')}
          </div>
          <div className={dsClass.PADDING_BOTTOM_4}>
            {t.Z8CO(
              'If the subscription replaces an existing subscription, enter the termination date of the existing subscription here.'
            )}
          </div>
          <div className="of-select-phone-number__transfer-date-input">
            <Field
              onFocus={() => showDayPickerDialog(formik)}
              component={InputComponent}
              name="installationDate"
              type="text"
              style={{ paddingLeft: '48px' }}
            >
              <span
                style={{
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  padding: '12px 8px 12px 17px',
                  width: '48px',
                  height: '48px',
                }}
              >
                <CL.Icon icon="calendar" />
              </span>
            </Field>
          </div>
          <CL.Button size="l" className="of-next-button" type="submit">
            {t.F0MY(nextMsg).toUpperCase()}
          </CL.Button>
        </Form>
      )}
    </Formik>
  );
};
