import * as CL from '@design-system/component-library';
import { Dropdown } from '../../common/react-hook-form/components/Dropdown.js';
import { NumberRange } from '../../generated/api/numberRange.js';
import { PbxWorkingHours } from '../PbxWorkingHours/PbxWorkingHours.js';
import { ReachabilityChain } from './reachabilitychain/ReachabilityChain.js';
import { SubscriptionPbxDetails } from '../../generated/api/subscriptionPbxDetails.js';
import {
  cancelMsg,
  confirmMsg,
  numberConversionToBeDisplayedMsg,
  numberToDisplayMsg,
  officeHourChainMsg,
  outOfOfficeChainMsg,
  t,
} from '../../common/i18n/index.js';
import {
  closeHoursAction,
  getClidMaskingOptions,
  getClidOptions,
  getCurrentActiveChainItems,
  getReachabilityChainLabel,
} from './pbxTimeSettingsUtil.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { formatPhoneNumber } from '../../common/utils/phoneNumberUtils.js';
import { useWatch } from 'react-hook-form';
import type { CommonError } from '../../common/types/errors.js';
import type { PbxTimeSettingFormData } from './pbxTimeSettingsUtil.js';
import type { Subscription } from '../../generated/api/subscription.js';

export interface PbxTimeSettingsEditorProps {
  pbxSolution: Subscription;
  timeSettings: PbxTimeSettingFormData;
  errors?: CommonError[];
  saving?: boolean;
  onEditCompleted: () => void;
}

const getAllNumbersFromAllRanges = (numberRanges: NumberRange[]) =>
  numberRanges
    .reduce((allNumbers: string[], numberRange) => {
      const startNumber = Number.parseInt(numberRange.startNumber, 10);
      const endNumber = Number.parseInt(numberRange.endNumber, 10);
      const size = endNumber + 1 - startNumber;
      return allNumbers.concat(
        Array.from(
          { length: size },
          (_element, i) =>
            // Reinsert leading plus or zero if it was present before converting to number
            ((numberRange.startNumber.startsWith('+') && '+') || '') +
            ((numberRange.startNumber.startsWith('0') && '0') || '') +
            (startNumber + i).toString()
        )
      );
    }, [])
    .sort();

export const PbxTimeSettingsEditor = ({
  pbxSolution,
  timeSettings,
  errors = [],
  saving = false,
  onEditCompleted,
}: PbxTimeSettingsEditorProps) => {
  const { currentActiveChain, callerLineIdMasking } = useWatch();
  const { pbxType } = timeSettings.pbxConfigurationDetails;
  const specialNumber =
    pbxSolution?.details?.specialNumber &&
    pbxSolution?.details?.specialNumber?.pbxConfigurationDetails.pbxType !== SubscriptionPbxDetails.PbxTypeEnum.VAKIO;

  const isCallerLineIdNumberRequired =
    callerLineIdMasking !== SubscriptionPbxDetails.CallerLineIdMaskingEnum.NOT_IN_USE;

  const callerIdNumbers = getAllNumbersFromAllRanges(
    pbxSolution.details?.mobilePbx?.numberRanges?.filter(
      numberRange => numberRange.rangeType === NumberRange.RangeTypeEnum.PSTN
    ) ?? []
  ).map(rangeNumber => ({ label: formatPhoneNumber(rangeNumber, true), value: rangeNumber }));

  return (
    <div className="of-pbx-time-settings">
      {timeSettings.pbxConfigurationDetails.closeHoursAction && (
        <div className="of-pbx-time-settings__item">
          <h4>{t.RDSM('During period of absence')}</h4>
          <div>{closeHoursAction(timeSettings.pbxConfigurationDetails.closeHoursAction)}</div>
          <div>{timeSettings.pbxConfigurationDetails.closeHoursNumber}</div>
        </div>
      )}

      <div id="current-active-chain" className="of-pbx-time-settings__item">
        <h4>{t.N4VX('Reachability service in use')}</h4>
        <Dropdown name="currentActiveChain" items={getCurrentActiveChainItems()} />
      </div>

      {(pbxType === SubscriptionPbxDetails.PbxTypeEnum.VAKIO ||
        pbxType === SubscriptionPbxDetails.PbxTypeEnum.TAVOITETTAVUUSKETJU) && (
        <div id="callerline-id-masking" className="of-pbx-time-settings__item">
          <h4>{t.O1MZ(numberConversionToBeDisplayedMsg)}</h4>
          <Dropdown name="callerLineIdMasking" items={getClidMaskingOptions(pbxType)} />
        </div>
      )}

      {specialNumber && (
        <div className="of-pbx-time-settings__item">
          <h4>{t.FL34(numberToDisplayMsg)}</h4>
          <Dropdown name="callerLineIdTargetNumber" items={getClidOptions(pbxType)} />
        </div>
      )}

      {isCallerLineIdNumberRequired && pbxType === SubscriptionPbxDetails.PbxTypeEnum.VAKIO && (
        <div id="callerline-id-number" className="of-pbx-time-settings__item">
          <h4>{t.FL34(numberToDisplayMsg)}</h4>
          <Dropdown name="callerLineIdNumber" items={callerIdNumbers} />
        </div>
      )}

      {currentActiveChain === SubscriptionPbxDetails.CurrentActiveChainEnum.CALENDAR && (
        <div id="pbx-working-hours">
          <div className={`of-pbx-time-settings__section-title ${dsClass.PADDING_TOP_7}`}>
            <h4 className={dsClass.MARGIN_BOTTOM_0}>{t.YYCY('Office-hour')}</h4>
            <CL.Popover triggerElement={<CL.Icon icon="help" size="m" type="light" color="blue-600" />} placement="top">
              <>
                <h4>{t.A9V3('Duration of the office-hour chain')}</h4>
                {t.HHRE(
                  'Specifies the period of validity of the office-hour chain. The out-of-office chain is used at other times. If you want an out-of-office chain for the entire weekend, set the time from 00:00 to 00:00.'
                )}
              </>
            </CL.Popover>
          </div>
          <div className="of-pbx-time-settings__section">
            <PbxWorkingHours
              pbxWorkingHoursConfig={timeSettings}
              errors={errors}
              pbxType={pbxType}
              editing={true}
              hidden={currentActiveChain !== SubscriptionPbxDetails.CurrentActiveChainEnum.CALENDAR}
            />
          </div>
        </div>
      )}

      <div className="of-pbx-time-settings__number-chain">
        <h4>{getReachabilityChainLabel(pbxType)}</h4>
        <ReachabilityChain
          label={t.WXDM(officeHourChainMsg)}
          name="workingHoursReachabilityChain"
          numbers={timeSettings.workingHoursReachabilityChain}
          editing={true}
          errors={errors}
          pbxType={pbxType}
        />
        <ReachabilityChain
          label={t.XGWK(outOfOfficeChainMsg)}
          name="offWorkReachabilityChain"
          numbers={timeSettings.offWorkReachabilityChain}
          editing={true}
          errors={errors}
          pbxType={pbxType}
        />
      </div>

      <div className="of-pbx-time-settings__actions">
        <CL.Button color="primary" type="submit" loading={saving}>
          {t.QVYK(confirmMsg)}
        </CL.Button>
        <CL.Button color="light" onClick={onEditCompleted}>
          {t.B2V1(cancelMsg)}
        </CL.Button>
      </div>
    </div>
  );
};
