import { ELECTRONIC_ADDRESS_REGEX } from '../../utils/validationUtils.js';
import { InputComponent } from '../components/InputComponent.js';
import { eInvoicingAddressMsg, fieldCantBeEmptyMsg, invalidEInvoiceAddressMsg, t } from '../../i18n/index.js';
import type { OptionalInputComponentProps } from '../components/InputComponent.js';

export const ElectronicAddress = (props: OptionalInputComponentProps) => {
  const {
    name = 'electronicAddress',
    label = t.OL7B(eInvoicingAddressMsg),
    placeholder = undefined,
    required = true,
    disabled = false,
    hint,
    readonly,
    tooltip,
  } = props;

  const validate = (value: string) => {
    if (!value && required) {
      return t.VPVR(fieldCantBeEmptyMsg);
    } else if (!ELECTRONIC_ADDRESS_REGEX.test(value)) {
      return t.NMXU(invalidEInvoiceAddressMsg);
    }
    return;
  };

  return (
    <InputComponent
      validate={validate}
      name={name}
      type="text"
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      required={required}
      hint={hint}
      tooltip={tooltip}
      readonly={readonly}
      {...props}
    />
  );
};
