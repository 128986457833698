import {
  EmployeeMyOrdersBreadcrumb,
  EmployeeMyOrdersNavi,
  EmployeeMyOrdersWrapper,
} from '../../../../../components/EmployeeMyOrdersWrapper/EmployeeMyOrdersWrapper.js';
import { Grid } from '../../../../../components/Grid/Grid.js';
import {
  SubscriptionActionList,
  SubscriptionActionListType,
} from '../../../../../components/SubscriptionActionList/SubscriptionActionList.js';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import { generatePath, useNavigate } from 'react-router-dom';
import { loadEmployeeSubscriptionActions } from '../../../../../selfservice/actions/index.js';
import { myOrdersMsg, omaElisaForEmployeeMsg, t } from '../../../../../common/i18n/index.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useTitle } from '../../../../../common/hooks/useTitle.js';
import type { CompositeListSort } from '../../../../../components/CompositeListHeader/index.js';
import type { State } from '../../../../../selfservice/common/store.js';

export const OwnChangeRequestsPath = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const empSubActions = useSelector((s: State) => s.user?.authenticated?.employeeSubscriptionActions, deepEqual);

  useTitle([t.H6JV(omaElisaForEmployeeMsg), t.EG6F(myOrdersMsg)]);

  useEffect(() => {
    dispatch(loadEmployeeSubscriptionActions());
  }, [dispatch]);

  return (
    <EmployeeMyOrdersWrapper>
      <EmployeeMyOrdersBreadcrumb />
      <EmployeeMyOrdersNavi />
      <Grid>
        <SubscriptionActionList
          items={empSubActions?.searchResults?.map(subscriptionAction => subscriptionAction.result)}
          onClickSubscriptionAction={(id: string) => {
            navigate(generatePath(paths.EMPLOYEE_OWN_CHANGE_REQUEST, { subscriptionId: id }));
          }}
          onLoadMoreSubscriptionActions={() => dispatch(loadEmployeeSubscriptionActions())}
          onSearchSubscriptionActions={(newSearchValue: string) =>
            dispatch(loadEmployeeSubscriptionActions({ search: newSearchValue }))
          }
          onSortSubscriptionActions={(newSort: CompositeListSort) =>
            dispatch(loadEmployeeSubscriptionActions({ sort: newSort }))
          }
          search={empSubActions?.search}
          sort={empSubActions?.sort}
          loadMore={
            empSubActions?.searchResults?.length !== undefined &&
            empSubActions?.total !== undefined &&
            empSubActions?.searchResults?.length < empSubActions?.total
          }
          type={SubscriptionActionListType.MY_SUBSCRIPTIONS}
          searchResults={empSubActions?.searchResults}
        />
      </Grid>
    </EmployeeMyOrdersWrapper>
  );
};
