import * as CL from '@design-system/component-library';
import { AuthenticatedUserRole, EppRedeemTerminateRequestType } from '../../generated/api/models.js';
import { Checkbox } from '../../common/react-hook-form/components/Checkbox.js';
import { DeviceChangeOption } from '../../common/enums.js';
import { FormProvider, useForm } from 'react-hook-form';
import { GutterStyle, SelectOneList } from '../SelectOneList/index.js';
import { Loading } from '../Loading/Loading.js';
import { SelectRadio } from '../../common/react-hook-form/components/SelectRadio.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { getRedeemPrice } from '../EppRedeem/EppRedeemUtils.js';
import { getSelectedDeviceChangeTitleSuffix, isEppDevicePriceSubType } from '../../common/utils/checkoutUtils.js';
import { nextMsg, t } from '../../common/i18n/index.js';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import type { AuthenticatedUserState } from '../../common/types/states.js';
import type { CheckoutStepItem } from '../../components/Checkout/CheckoutSteps.js';
import type { DeviceChangeOrRedeemRequest, DeviceChangeRequest } from '../../common/types/device.js';
import type { EmployeeSubscription } from '../../common/types/subscription.js';
import type { State } from '../../selfservice/common/store.js';
import type { Subscription } from '../../generated/api/models.js';

import './EmployeeDeviceChange.scss';

export interface DeviceChangeStepProps {
  user: AuthenticatedUserState;
  onNextClick: (updatedDeviceChangeRequest?: DeviceChangeOrRedeemRequest) => void;
  deviceSubs: EmployeeSubscription[];
}

const getReplaceableDevices = (
  deviceSubs: EmployeeSubscription[],
  expiringEppSubscriptions?: Array<string>,
  userRole?: AuthenticatedUserRole
): EmployeeSubscription[] | undefined => {
  if (userRole === AuthenticatedUserRole.PUNCHOUT_USER) {
    return deviceSubs;
  }
  if (expiringEppSubscriptions) {
    return deviceSubs.filter(subs => expiringEppSubscriptions.includes(subs.subscriptionId));
  }
  return undefined;
};

const getDeviceRedeemPrice = (
  subscriptionId: string | undefined,
  replaceableDevices: EmployeeSubscription[] | undefined
): number => {
  if (replaceableDevices && subscriptionId) {
    const selectedDev = replaceableDevices.find(subscription => subscription.subscriptionId === subscriptionId);
    return Number(selectedDev?.details?.device?.eppModificationPrices?.redeemOC?.totalEmployeePrice);
  }
  return 0;
};

const getSubscriptionDisplayId = (
  subscriptionId: string | undefined,
  replaceableDevices: EmployeeSubscription[] | undefined
) => {
  if (replaceableDevices && subscriptionId) {
    const replacedSubscriptions = replaceableDevices.filter(subs => subs.subscriptionId === subscriptionId);
    return replacedSubscriptions[0].subscriptionDisplayId;
  }
  return subscriptionId;
};

const ReplaceableDeviceLabel = ({ subscription }: { subscription: Subscription }): JSX.Element => {
  const eppModificationPrices = subscription.details?.device?.eppModificationPrices;
  return (
    <div className="of-employee-device-change__redeemable-devices-option">
      <p className="of-employee-device-change__redeemable-devices-option-label">{subscription.subscriptionName}</p>
      <p className="of-employee-device-change__redeemable-devices-option-sublabel">
        {eppModificationPrices && (
          <>
            {t.N3YX('redemption price')}:
            {getRedeemPrice(EppRedeemTerminateRequestType.EMPLOYEE_REDEEM, eppModificationPrices)}
          </>
        )}
      </p>
    </div>
  );
};

interface DeviceChangeData {
  redeemableDevice?: string;
  replacementDeviceCommercialProductCode?: string;
  deviceChangeOption?: DeviceChangeOption;
  noDeviceChangeOpted?: boolean;
  isRedeemConfirmed?: boolean;
}

export const EmployeeDeviceChange = ({ user, onNextClick, deviceSubs }: DeviceChangeStepProps): JSX.Element => {
  const cartItems = useSelector((state: State) => state.deviceCheckout?.cartItems || [], deepEqual);
  const eppCartItems = cartItems.filter(cartItem => isEppDevicePriceSubType(cartItem.price));
  const replaceableDevices = getReplaceableDevices(deviceSubs, user.expiringEppSubscriptions, user.userRole);
  const methods = useForm<DeviceChangeData>({ values: { noDeviceChangeOpted: false, isRedeemConfirmed: false } });
  const { watch, setValue, resetField } = methods;

  const deviceChangeOptionValue = watch('deviceChangeOption');
  const redeemableDeviceValue = watch('redeemableDevice');
  const replacementDeviceCommercialProductCodeValue = watch('replacementDeviceCommercialProductCode');
  const noDeviceChangeOpted = watch('noDeviceChangeOpted');

  useEffect(() => {
    if (noDeviceChangeOpted) {
      resetField('redeemableDevice');
      resetField('replacementDeviceCommercialProductCode');
      setValue('deviceChangeOption', DeviceChangeOption.NONE);
    }
  }, [noDeviceChangeOpted, setValue, resetField]);

  const onNextClickHandler = (data: DeviceChangeData): void => {
    const { deviceChangeOption, replacementDeviceCommercialProductCode, isRedeemConfirmed, redeemableDevice } = data;

    if (deviceChangeOption === DeviceChangeOption.REDEEM && isRedeemConfirmed) {
      onNextClick({
        deviceChangeOption,
        replacedSubscriptionId: redeemableDevice,
        deviceChangeRedeemPrice: getDeviceRedeemPrice(redeemableDevice, replaceableDevices),
        redeemRequestType: EppRedeemTerminateRequestType.EMPLOYEE_REDEEM,
        subscriptionDisplayId: getSubscriptionDisplayId(redeemableDevice, replaceableDevices),
      });
    } else if (deviceChangeOption === DeviceChangeOption.RETURN) {
      onNextClick({
        deviceChangeOption,
        replacedSubscriptionId: redeemableDevice,
        replacementDeviceCommercialProductCode:
          replacementDeviceCommercialProductCode || eppCartItems[0].commercialProductCodes[0],
      });
    } else if (deviceChangeOption === DeviceChangeOption.NONE) {
      onNextClick({
        deviceChangeOption,
      });
    }
  };

  const showReplaceableDevices = (): boolean =>
    deviceChangeOptionValue === DeviceChangeOption.REDEEM || deviceChangeOptionValue === DeviceChangeOption.RETURN;

  const nextButtonDisabled = (): boolean =>
    !(
      (deviceChangeOptionValue === DeviceChangeOption.REDEEM && redeemableDeviceValue) ||
      (deviceChangeOptionValue === DeviceChangeOption.RETURN &&
        redeemableDeviceValue &&
        (replacementDeviceCommercialProductCodeValue || eppCartItems?.length === 1)) ||
      deviceChangeOptionValue === DeviceChangeOption.NONE
    );

  const RedeemReturnOption = (label: string, deviceChangeOption: DeviceChangeOption) => ({
    element: <div className="redeem-or-return-selection">{label}</div>,
    isSelected: deviceChangeOptionValue === deviceChangeOption,
    onClick: () => {
      setValue('deviceChangeOption', deviceChangeOption);
      resetField('noDeviceChangeOpted');
      resetField('isRedeemConfirmed');
    },
  });

  return (
    <div className="of-employee-device-change">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onNextClickHandler)}>
          <h4 className="ea-h4">{t.LUOE('Would you like to redeem or return your old device?')}</h4>
          <SelectOneList
            disableIcon={true}
            gutterStyle={GutterStyle.Minimal}
            maxItemsAsideDesktop={2}
            maxItemsAsideLaptop={2}
            maxItemsAsideTablet={2}
            options={[
              RedeemReturnOption(t.YTNE('Redeem'), DeviceChangeOption.REDEEM),
              RedeemReturnOption(t.OMNZ('Return'), DeviceChangeOption.RETURN),
            ]}
          />
          <div className="of-employee-device-change__no-device-change">
            <Checkbox
              key={`noDeviceChangeOpted_${noDeviceChangeOpted}`}
              name="noDeviceChangeOpted"
              label={t.U8IP(
                'I will not redeem or return the device. I want to have a new agreement in addition to the previous agreements. Previous agreements will remain unchanged in accordance with the agreement period.'
              )}
            />
          </div>
          {replaceableDevices && showReplaceableDevices() && (
            <div className="of-employee-device-change__redeemable-devices">
              {deviceChangeOptionValue === DeviceChangeOption.REDEEM && (
                <h4 className="ea-h4">{t.UDSQ('Redeemable device')}</h4>
              )}
              {deviceChangeOptionValue === DeviceChangeOption.RETURN && (
                <>
                  <h4 className="ea-h4">{t.B9LR('Device to be returned')}</h4>
                  <div className={dsClass.PADDING_BOTTOM_4}>
                    {t.OZLC(
                      ' We will send a freight bag for the device that is to be returned. The device should ' +
                        'be returned without delay. It is recommended that the device is reset to factory settings ' +
                        'before the return.'
                    )}
                  </div>
                </>
              )}
              <SelectRadio
                name="redeemableDevice"
                items={replaceableDevices.map(subscription => ({
                  value: subscription.subscriptionId + '',
                  children: <ReplaceableDeviceLabel subscription={subscription} />,
                }))}
              />
              {deviceChangeOptionValue === DeviceChangeOption.REDEEM && (
                <Checkbox name="isRedeemConfirmed" required={true} error={t.E1N1('Approve the terms.')}>
                  <span>
                    <strong>{t.IBOB('I understand and approve,')}</strong>{' '}
                    {t.AJVW(
                      `that the device must be cleared from any data, and factory settings must be applied before taking it to personal use. It's also prohibited to retrieve any company-related data to the device without the company's permission. In case you need help with these matters, please contact Elisa's customer service.`
                    )}
                  </span>
                </Checkbox>
              )}
            </div>
          )}
          {showReplaceableDevices() && !replaceableDevices?.length && <Loading />}
          {deviceChangeOptionValue === DeviceChangeOption.RETURN && eppCartItems && eppCartItems.length > 1 && (
            <div className="of-employee-device-change__map-devices">
              <h4 className="ea-h4">{t.N4QO('Select a new device to replace the device you want to return')}</h4>
              <SelectRadio
                name="replacementDeviceCommercialProductCode"
                items={eppCartItems.map(item => ({
                  value: item.commercialProductCodes[0],
                  label: item.productName,
                }))}
              />
            </div>
          )}
          <div className="of-employee-device-change__actions">
            <CL.Button size="l" type="submit" disabled={nextButtonDisabled()}>
              {t.F0MY(nextMsg)}
            </CL.Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export const employeeDeviceChangeStep = (
  props: DeviceChangeStepProps,
  deviceChangeRequest?: DeviceChangeRequest
): CheckoutStepItem => {
  const selectedDeviceChangeTittleSuffix = getSelectedDeviceChangeTitleSuffix(deviceChangeRequest);
  return {
    id: 'employeeDeviceChangeStep',
    content: <EmployeeDeviceChange {...props} />,
    name: t.JN29('Previous device'),
    completedName: `${t.JN29('Previous device')}: ${selectedDeviceChangeTittleSuffix} `,
  };
};
