import * as CL from '@design-system/component-library';
import { BillingAccountStatus } from '../../../../generated/api/models.js';
import { ContactInfo } from '../../components/ContactInfo.js';
import { EditBillingAccountButton } from '../../components/EditBillingAccountButton.js';
import { GridColumn } from '../../components/GridColumn.js';
import { InfoFieldWithTooltip } from '../../components/InfoFieldWithTooltip.js';
import {
  activeMsg,
  billingAccountExtensionNameMsg,
  billingAccountNameMsg,
  businessIdMsg,
  contactForInvoiceMsg,
  invoiceDeliveryMethodMsg,
  invoiceTypeMsg,
  mailingAddressMsg,
  payerAdditionalDetailsInfoMsg,
  payerDetailsMsg,
  payerMsg,
  payerNameInfoMsg,
  referenceInformationMsg,
  statusMsg,
  t,
} from '../../../../common/i18n/index.js';
import { billingAccountInvoiceTypes } from '../../../../common/utils/billingAccountUtils.js';
import { dsClass } from '../../../../common/constants/dsClasses.js';
import { formatSumWithSignum } from '../../../../common/utils/priceUtils.js';
import type {
  BillChannel,
  BillingAccount,
  BillingAccountScheduledChange,
  Contact,
} from '../../../../generated/api/models.js';

import { BillingAccountDeliveryMethodView } from '../deliveryMethod/BillingAccountDeliveryMethodView.js';
import { BillingAccountReferenceNumbersView } from '../../../BillingAccountReferenceNumbers/BillingAccountReferenceNumbersView.js';
import { BillingAccountVersion, EditingSection } from '../../billingAccountDetailsEnums.js';
import { useLocation } from 'react-router-dom';

export interface BillingAccountFormViewProps {
  activeBillingAccount: BillingAccount;
  contacts: Contact[];
  setNewEditingSection: (newSection: EditingSection) => void;
  editableInClassic?: boolean;
  editingSection: EditingSection;
  showScheduledChange?: boolean;
  onDeleteClick: () => void;
  billingAccountScheduledChange?: BillingAccountScheduledChange;
  billingAccountVersion: BillingAccountVersion;
  billChannels: BillChannel[];
}

const getBillingAccountState = (
  billingAccountStatus: BillingAccountStatus | undefined
): { color: CL.DsColor; text: string } => {
  switch (billingAccountStatus) {
    case BillingAccountStatus.OPEN:
      return {
        color: 'blue-600--light',
        text: t.SFRK(activeMsg),
      };

    case BillingAccountStatus.CANCELLED:
      return {
        color: 'red-600',
        text: t.PEKP('Cancelled'),
      };

    case BillingAccountStatus.SCHEDULED:
      return {
        color: 'orange-700',
        text: t.N4LE('Scheduled change'),
      };

    default:
      throw new Error(`Unknown billing account status ${billingAccountStatus}`);
  }
};

export const BillingAccountDetailsView = ({
  activeBillingAccount,
  contacts,
  setNewEditingSection,
  editableInClassic,
  editingSection,
  showScheduledChange,
  onDeleteClick,
  billingAccountScheduledChange,
  billingAccountVersion,
  billChannels,
}: BillingAccountFormViewProps) => {
  const location = useLocation();
  const billingAccount =
    showScheduledChange && billingAccountScheduledChange
      ? billingAccountScheduledChange.billingAccount
      : activeBillingAccount;

  const isHighlighted = (value1 = '', value2 = ''): boolean =>
    Boolean(
      billingAccountVersion === BillingAccountVersion.SCHEDULED && billingAccountScheduledChange && value1 !== value2
    );

  const accountState = getBillingAccountState(billingAccount.billingAccountStatus);

  const mailingAddress = ` ${billingAccount.payerAddress.line1}, ${billingAccount.payerAddress.postalCode} ${billingAccount.payerAddress.postOffice}`;
  const activeMailingAddress = ` ${activeBillingAccount.payerAddress.line1}, ${activeBillingAccount.payerAddress.postalCode} ${activeBillingAccount.payerAddress.postOffice}`;
  const scheduledMailingAddress = ` ${billingAccountScheduledChange?.billingAccount.payerAddress.line1}, ${billingAccountScheduledChange?.billingAccount.payerAddress.postalCode} ${billingAccountScheduledChange?.billingAccount.payerAddress.postOffice}`;

  const accountBalance = formatSumWithSignum(billingAccount.accountBalance);
  const activeAccountBalance = formatSumWithSignum(activeBillingAccount.accountBalance);
  const scheduledAccountBalance = formatSumWithSignum(billingAccountScheduledChange?.billingAccount.accountBalance);

  const accountType = billingAccountInvoiceTypes
    .find(item => item.value === billingAccount.billFormatType)
    ?.displayValue();
  const activeAccountType = billingAccountInvoiceTypes
    .find(item => item.value === activeBillingAccount.billFormatType)
    ?.displayValue();
  const scheduledAccountType = billingAccountInvoiceTypes
    .find(item => item.value === billingAccountScheduledChange?.billingAccount.billFormatType)
    ?.displayValue();

  const selectedContact = contacts?.find(({ contactId }) => contactId === billingAccount.billingContactId);
  const billingAccountStateValue = (
    <>
      <span className={dsClass.PADDING_RIGHT_2}>
        <CL.Icon color={accountState.color} type="filled" icon="record" size="s" />
      </span>
      <span>{accountState.text || '—'}</span>
    </>
  );

  const Buttons = () => {
    if (showScheduledChange) {
      return (
        <>
          <CL.GridRow>
            <GridColumn className="of-billing-account-details of-billing-account-details__button form-column view-field edit-button">
              <CL.Button
                id={`${name}reschedule`}
                color="light"
                onClick={() => setNewEditingSection(EditingSection.SCHEDULE)}
              >
                {t.N2RK('Reschedule')}
              </CL.Button>
            </GridColumn>
          </CL.GridRow>
          <CL.GridRow>
            <GridColumn className="of-billing-account-details of-billing-account-details__button form-column view-field edit-button">
              <CL.Button id={`${name}delete`} color="light" onClick={onDeleteClick}>
                {t.VT06('Delete scheduled changes')}
              </CL.Button>
            </GridColumn>
          </CL.GridRow>
        </>
      );
    }

    return (
      <CL.GridRow>
        <GridColumn className="of-billing-account-details of-billing-account-details__button form-column edit-button">
          <EditBillingAccountButton
            editableInClassic={editableInClassic}
            setNewEditingSection={() => setNewEditingSection(EditingSection.DETAILS)}
            name="billingAccountDetails"
            editingSection={editingSection}
          />
        </GridColumn>
      </CL.GridRow>
    );
  };

  return (
    <div className="of-billing-account-details">
      <CL.Grid>
        {location?.state?.showExpirationWarning && (
          <CL.GridRow>
            <CL.GridCol
              colsS={6}
              colsM={6}
              colsL={12}
              className={`${dsClass.PADDING_0} ${dsClass.MARGIN_TOP_2} ${dsClass.MARGIN_BOTTOM_6}`}
            >
              <CL.Disclaimer
                disclaimerType="warning"
                icon={<CL.Icon icon="notification" />}
                text={t.W6DS(
                  'Please note that this billing account will be cancelled {} if no products or services are ordered for it before that.',
                  location?.state?.expirationDate
                )}
              />
            </CL.GridCol>
          </CL.GridRow>
        )}
        <CL.GridRow>
          <CL.GridCol colsS={6} colsM={6} colsL={9}>
            <CL.Grid className={dsClass.PADDING_0}>
              <CL.GridRow>
                <GridColumn className="of-billing-account-details form-column view-field">
                  <InfoFieldWithTooltip
                    label={t.ASQT(statusMsg)}
                    toolTipText={t.DREM(
                      'The status indicates whether the billing agreement is active, ie in use, or passive, ie expired.'
                    )}
                    value={billingAccountStateValue}
                    name="billingAccountState"
                  />
                </GridColumn>
                <GridColumn className="of-billing-account-details form-column view-field">
                  <InfoFieldWithTooltip
                    label={t.K6HI(mailingAddressMsg)}
                    toolTipText={t.DRYH(`The payer's postal address is printed on the invoice.`)}
                    value={mailingAddress}
                    name="mailingAddress"
                    highlight={isHighlighted(activeMailingAddress, scheduledMailingAddress)}
                  />
                </GridColumn>
              </CL.GridRow>
              <CL.GridRow>
                <GridColumn className="of-billing-account-details form-column view-field">
                  <InfoFieldWithTooltip
                    label={t.PB6S(payerMsg)}
                    toolTipText={t.SEZ9(payerNameInfoMsg)}
                    value={billingAccount.payerName || ''}
                    name="payerName"
                    highlight={isHighlighted(
                      activeBillingAccount.payerName,
                      billingAccountScheduledChange?.billingAccount.payerName
                    )}
                  />
                </GridColumn>
                <GridColumn className="of-billing-account-details form-column view-field">
                  <InfoFieldWithTooltip
                    label={t.UW4H(invoiceTypeMsg)}
                    toolTipText={t.HEKT(
                      'The name of the invoice template, determines the layout and specification level of the invoice.'
                    )}
                    value={accountType}
                    name="accountType"
                    highlight={isHighlighted(activeAccountType, scheduledAccountType)}
                  />
                </GridColumn>
              </CL.GridRow>
              <CL.GridRow>
                <GridColumn className="of-billing-account-details form-column view-field">
                  <InfoFieldWithTooltip
                    label={t.YLAI(payerDetailsMsg)}
                    toolTipText={t.O83E(payerAdditionalDetailsInfoMsg)}
                    value={billingAccount.payerNameExtension}
                    name="payerNameExtension"
                    highlight={isHighlighted(
                      activeBillingAccount.payerNameExtension,
                      billingAccountScheduledChange?.billingAccount.payerNameExtension
                    )}
                  />
                </GridColumn>
                <GridColumn className="of-billing-account-details form-column view-field">
                  <InfoFieldWithTooltip
                    label={t.RH6T(billingAccountNameMsg)}
                    toolTipText={t.JKJJ(
                      'Mandatory name information given to the Billing Agreement. Does not print on the invoice.'
                    )}
                    value={billingAccount.billingAccountName}
                    name="billingAccountName"
                    highlight={isHighlighted(
                      activeBillingAccount.billingAccountName,
                      billingAccountScheduledChange?.billingAccount.billingAccountName
                    )}
                  />
                </GridColumn>
              </CL.GridRow>
              <CL.GridRow>
                <GridColumn className="of-billing-account-details form-column view-field">
                  <InfoFieldWithTooltip
                    label={t.MPA5(businessIdMsg)}
                    toolTipText={t.S8N1('The business ID of the payer, printed on the invoice.')}
                    value={billingAccount.payerBusinessId}
                    name="payerBusinessId"
                    highlight={isHighlighted(
                      activeBillingAccount.payerBusinessId,
                      billingAccountScheduledChange?.billingAccount.payerBusinessId
                    )}
                  />
                </GridColumn>
                <GridColumn className="of-billing-account-details form-column view-field">
                  <InfoFieldWithTooltip
                    label={t.KUTS(billingAccountExtensionNameMsg)}
                    toolTipText={t.OBHZ(
                      'Billing Agreement Name Add-on, helps identify the billing agreement. Does not print on invoice.'
                    )}
                    value={billingAccount.billingAccountExtensionName}
                    name="billingAccountExtensionName"
                    highlight={isHighlighted(
                      activeBillingAccount.billingAccountExtensionName,
                      billingAccountScheduledChange?.billingAccount.billingAccountExtensionName
                    )}
                  />
                </GridColumn>
              </CL.GridRow>
              <CL.GridRow>
                <GridColumn className="of-billing-account-details form-column view-field">
                  <InfoFieldWithTooltip
                    label={t.VYZS('Contact person')}
                    toolTipText={t.VH3U(contactForInvoiceMsg)}
                    value={selectedContact && <ContactInfo selectedContact={selectedContact} />}
                    name="selectedContact"
                    highlight={isHighlighted(
                      activeBillingAccount.billingContactId,
                      billingAccountScheduledChange?.billingAccount.billingContactId
                    )}
                  />
                </GridColumn>
                <GridColumn className="of-billing-account-details form-column view-field">
                  <InfoFieldWithTooltip
                    label={t.G9X4('Account balance')}
                    toolTipText={t.QL4P(
                      'The total amount of open invoices in the billing agreement and any overpayments.'
                    )}
                    value={accountBalance}
                    name="accountBalance"
                    highlight={isHighlighted(activeAccountBalance, scheduledAccountBalance)}
                  />
                </GridColumn>
              </CL.GridRow>
              <CL.GridRow>
                <h4 className="of-billing-account-details__delivery-methods" id="deliveryMethodDetails">
                  {t.H272(invoiceDeliveryMethodMsg)}
                </h4>
              </CL.GridRow>
              <BillingAccountDeliveryMethodView
                activeBillingAccount={activeBillingAccount}
                billingAccountVersion={billingAccountVersion}
                billingAccountScheduledChange={billingAccountScheduledChange}
                billChannels={billChannels}
                contacts={contacts}
                isHighlighted={isHighlighted}
              />
              <CL.GridRow>
                <h4 id="referenceInformation">{t.J9AR(referenceInformationMsg)}</h4>
              </CL.GridRow>
              <BillingAccountReferenceNumbersView
                customerReferences={{
                  customerReference1: billingAccount.customerReference1 || '',
                  customerReference2: billingAccount.customerReference2 || '',
                  customerReference3: billingAccount.customerReference3 || '',
                  customerReference4: billingAccount.customerReference4 || '',
                }}
                highlight={{
                  highlight1: isHighlighted(
                    activeBillingAccount.customerReference1,
                    billingAccountScheduledChange?.billingAccount.customerReference1
                  ),
                  highlight2: isHighlighted(
                    activeBillingAccount.customerReference2,
                    billingAccountScheduledChange?.billingAccount.customerReference2
                  ),
                  highlight3: isHighlighted(
                    activeBillingAccount.customerReference3,
                    billingAccountScheduledChange?.billingAccount.customerReference3
                  ),
                  highlight4: isHighlighted(
                    activeBillingAccount.customerReference4,
                    billingAccountScheduledChange?.billingAccount.customerReference4
                  ),
                }}
              />
            </CL.Grid>
          </CL.GridCol>
          <CL.GridCol colsS={6} colsM={6} colsL={3}>
            <Buttons />
          </CL.GridCol>
        </CL.GridRow>
      </CL.Grid>
    </div>
  );
};
