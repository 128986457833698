import * as CL from '@design-system/component-library';
import { SubscriptionPbxConfiguration } from '../../generated/api/subscriptionPbxConfiguration.js';
import { SubscriptionPbxDetails } from '../../generated/api/subscriptionPbxDetails.js';
import { companyNumberMsg, connectToNumberMsg, editMsg, extensionMsg, t } from '../../common/i18n/index.js';
import { formatPhoneNumber } from '../../common/utils/phoneNumberUtils.js';
import type { DropdownKludgeSubscriptionPbxConfiguration } from './PbxNumberSettingsUtil.js';

const resolveBusyActionLocalization = (
  busyAction?: SubscriptionPbxConfiguration.BusyActionEnum,
  connectToNumber?: string
) => {
  switch (busyAction) {
    case SubscriptionPbxConfiguration.BusyActionEnum.NEXT_NUMBER_IN_CHAIN:
      return t.EGRR('Next extension number');
    case SubscriptionPbxConfiguration.BusyActionEnum.BUSY_TONE:
      return t.J5SL('Dial tone');
    case SubscriptionPbxConfiguration.BusyActionEnum.CONNECT_TO_NUMBER:
      return `${t.HS6F(connectToNumberMsg)} ${formatPhoneNumber(connectToNumber ?? '')}`;
    default:
      return undefined;
  }
};

export interface PbxNumberSettingsViewProps {
  subscriptionPbxConfiguration: DropdownKludgeSubscriptionPbxConfiguration;
  onStartEdit: () => boolean;
}

export const PbxNumberSettingsView = ({ subscriptionPbxConfiguration, onStartEdit }: PbxNumberSettingsViewProps) => (
  <div>
    <div className="of-pbx-number-settings__row">
      <div id="corporate-number" className="of-pbx-number-settings__row-item">
        <CL.Description
          items={[
            {
              title: t.ZX32(companyNumberMsg),
              description: formatPhoneNumber(subscriptionPbxConfiguration.corporateNumber, true),
            },
          ]}
        />
      </div>
      <div id="extension-number" className="of-pbx-number-settings__row-item">
        <CL.Description
          items={[{ title: t.NO66(extensionMsg), description: subscriptionPbxConfiguration.extensionNumber }]}
        />
      </div>
    </div>
    <div className="of-pbx-number-settings__row">
      <div id="busy-action" className="of-pbx-number-settings__row-item">
        <CL.Description
          items={[
            {
              title: `${
                subscriptionPbxConfiguration.pbxConfigurationDetails.pbxType ===
                SubscriptionPbxDetails.PbxTypeEnum.RYHMAKUTSU
                  ? t.H9TQ('When the group')
                  : t.WMAS('When the number')
              } ${t.HXOW('is busy')}`,
              description: resolveBusyActionLocalization(
                subscriptionPbxConfiguration.busyAction,
                subscriptionPbxConfiguration.connectToNumber
              ),
            },
          ]}
        />
      </div>
      <div id="alarm-time" className="of-pbx-number-settings__row-item">
        <CL.Description
          items={[
            {
              title: t.DTYT('Callout time'),
              description: `${subscriptionPbxConfiguration.delayForChainCalls} s`,
            },
          ]}
        />
      </div>
    </div>
    <div className="of-pbx-number-settings__actions">
      <CL.Button id="pbx-number-settings__edit-button" onClick={onStartEdit} size="m" color="light">
        {t.NVPK(editMsg)}
      </CL.Button>
    </div>
  </div>
);
