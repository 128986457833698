import { Fragment, PureComponent } from 'react';
import { createImageUrl } from '../../common/utils/domUtils.js';
import { getReferencedItemValue, getSearchResultsSubRow } from './compositeListRowUtils.js';
import { highlightKeyword } from '../../common/utils/searchFieldUtils.js';
import { isArray, isFunction, isNumber, isString } from 'util';
import classNames from 'classnames';
import type { CompositeListColumn } from '../CompositeListHeader/CompositeListHeader.js';
import type { NonVisibleField } from '../../common/utils/searchFieldUtils.js';

import './CompositeListRow.scss';

export type CompositeListRowProps<T extends object> = {
  columns: CompositeListColumn<T>[];
  externalLink?: string;
  item?: T;
  mobileTableLayout?: boolean; // overrides mobileTwoColumns
  mobileTwoColumns?: boolean;
  rowClasses?: string[];
  rowId: string;
  showHintIcon?: boolean;
  imagesBaseUrl?: string;
  rowIconImageUrl?: string;
  rowIcon?: string; // Icon that is added to the left of the row on mobile or to the left of the first cell on desktop
  rowIconClasses?: string[]; // Optional extra classes to row icon
  onSelect?: (rowId: string, item: T) => void;
  tight?: boolean;
  checkListLayout?: boolean;
  searchKeyword?: string;
  nonVisibleFields?: NonVisibleField[];
  itemSearchResultMatchedFields?: string[];
  isReadOnly?: boolean;
};

/**
  This function auto adds `<mark>` tag to highlight matching text with searchKeyWord for content derived from ref.
  highlighting should be handled within `value` argument when it is used over ref.
 */
export function getCellLines<T extends object>(
  item: T,
  value?: string | string[] | JSX.Element[] | ((item: T, searchKeyword?: string) => string | string[] | JSX.Element[]),
  ref?: string,
  refFormatNumber?: (value: number | undefined, ref: string, item: T) => string | undefined,
  refFormatString?: (value: string | undefined, ref: string, item: T) => string | undefined,
  searchKeyword?: string
): Array<string | JSX.Element> {
  if (value !== undefined) {
    if (isArray(value)) {
      return value;
    } else if (isString(value)) {
      return [value];
    } else if (isFunction(value)) {
      const functionValue = value as (item: T, searchKeyword?: string) => string | string[] | JSX.Element[];
      const dynamicValue = functionValue(item, searchKeyword);
      if (isArray(dynamicValue)) {
        return dynamicValue;
      } else if (isString(dynamicValue)) {
        return [dynamicValue];
      }
    }
  } else if (ref !== undefined) {
    const itemValue = getReferencedItemValue(item, ref);
    if (itemValue === null) {
      return [];
    } else if (refFormatNumber && (itemValue === undefined || isNumber(itemValue))) {
      const formattedNumber = refFormatNumber(itemValue, ref, item);
      if (formattedNumber !== undefined) {
        return [formattedNumber];
      } else {
        return [];
      }
    } else if (refFormatString && (itemValue === undefined || isString(itemValue))) {
      const formattedString = refFormatString(itemValue, ref, item);
      if (formattedString !== undefined) {
        return [highlightKeyword(formattedString, searchKeyword)];
      } else {
        return [];
      }
    }
    return [highlightKeyword(itemValue, searchKeyword)];
  }
  throw new Error('CompositeListColumn must have either ref or value');
}

function getValueLineClassArray<T extends object>(
  item: T,
  valueLineIndex: number,
  classes?: string[] | ((item: T, valueLineIndex: number) => string[])
): string[] | undefined {
  if (classes) {
    if (isArray(classes)) {
      return classes;
    } else if (isFunction(classes)) {
      return classes(item, valueLineIndex);
    }
  }
  return undefined;
}

function getValueColumnClassArray<T extends object>(
  item: T,
  classes: string[] | ((item: T) => string[])
): string[] | undefined {
  if (classes) {
    if (isArray(classes)) {
      return classes;
    } else if (isFunction(classes)) {
      return classes(item);
    }
  }
  return undefined;
}

function getPostfixIconClasses<T extends object>(
  item: T,
  postfixIcon?: string | ((item: T) => string | undefined)
): string[] | undefined {
  if (postfixIcon) {
    let postfixIconString: string | undefined;
    if (isString(postfixIcon)) {
      postfixIconString = postfixIcon;
    } else if (isFunction(postfixIcon)) {
      postfixIconString = postfixIcon(item);
    }
    if (postfixIconString) {
      return ['ea-composite-list-row__cell-postfix-icon', 'ea-icon', `ea-icon--${postfixIconString}`];
    }
  }
  return undefined;
}

export class CompositeListRow<T extends object> extends PureComponent<CompositeListRowProps<T>> {
  constructor(props: CompositeListRowProps<T>) {
    super(props);
    this.onClickRow = this.onClickRow.bind(this);
  }

  onClickRow(): boolean {
    const { externalLink, item, onSelect, rowId } = this.props;
    if (externalLink) {
      return true;
    }
    if (onSelect && item) {
      onSelect(rowId, item);
    }
    return false;
  }

  render() {
    const {
      externalLink,
      columns,
      item,
      mobileTableLayout,
      mobileTwoColumns,
      onSelect,
      rowClasses,
      showHintIcon,
      imagesBaseUrl,
      rowIconImageUrl,
      rowIcon,
      rowIconClasses,
      rowId,
      tight,
      checkListLayout,
      searchKeyword,
      nonVisibleFields,
      itemSearchResultMatchedFields,
      isReadOnly,
    } = this.props;
    const allRowIconClasses: undefined | string[] =
      rowIcon && !rowIconImageUrl
        ? ['ea-icon', 'ea-icon--font-reset', `ea-icon--${rowIcon}`].concat(rowIconClasses || [])
        : undefined;

    const subRowContent = getSearchResultsSubRow(item, itemSearchResultMatchedFields, searchKeyword, nonVisibleFields);

    const data = (
      <div
        className={classNames(
          'ea-composite-list-row',
          (externalLink !== undefined || onSelect !== undefined) && !isReadOnly && 'ea-composite-list-row--interactive',
          tight && 'ea-composite-list-row--tight',
          checkListLayout && 'ea-composite-list-row--checkListLayout',
          mobileTableLayout && 'ea-composite-list-row--mobile-table-layout',
          mobileTwoColumns && !mobileTableLayout && 'ea-composite-list-row--mobile-two-columns',
          rowClasses,
          (allRowIconClasses || rowIconImageUrl) && 'ea-composite-list-row--with-icon'
        )}
        id={`row-${rowId}`}
      >
        {allRowIconClasses && (
          <span
            className={classNames(allRowIconClasses, 'ea-composite-list-row__mobile-icon', 'ea-hide--tablet-and-up')}
          />
        )}
        {columns.map(
          (
            {
              columnId,
              columnClasses,
              heading,
              headingHideOnMobile,
              highlightSearchKeywords,
              multiLine,
              postfixIcon,
              ref,
              refFormatNumber,
              refFormatString,
              value,
              valueColumnClasses,
              valueLineClasses,
              wide,
              semiWide,
            },
            columnIndex
          ) => {
            const cellLines: Array<string | JSX.Element> = item
              ? getCellLines<T>(
                  item,
                  value,
                  ref,
                  refFormatNumber,
                  refFormatString,
                  itemSearchResultMatchedFields?.includes(columnId) || highlightSearchKeywords
                    ? searchKeyword
                    : undefined
                )
              : [];
            const maxLines: number | undefined = multiLine?.max;
            const postfixIconClasses = item ? getPostfixIconClasses<T>(item, postfixIcon) : undefined;
            return (
              <div
                className={classNames(
                  'ea-composite-list-row__cell',
                  columnClasses,
                  postfixIconClasses && 'ea-composite-list-row__cell--with-postfix-icon',
                  !headingHideOnMobile && !mobileTableLayout && 'ea-composite-list-row__cell--with-mobile-heading',
                  wide && 'ea-composite-list-row__cell--wide',
                  semiWide && 'ea-composite-list-row__cell--semi-wide',
                  (cellLines.length === 0 || (cellLines.length === 1 && cellLines[0] === undefined)) &&
                    'ea-composite-list-row__cell--no-value',
                  item && valueColumnClasses && getValueColumnClassArray<T>(item, valueColumnClasses)
                )}
                key={`ea-cell-${rowId}-${columnIndex}`}
                id={`row-${rowId}-column-${columnId}`}
              >
                {!headingHideOnMobile && !mobileTableLayout && (
                  <label className={classNames('ea-hide--tablet-and-up')}>{heading}</label>
                )}
                <div className="ea-composite-list-row__cell-value">
                  {cellLines.slice(0, maxLines).map((val: string | JSX.Element, cellLineIndex: number) => {
                    const key = `clrcl-${rowId}-${columnIndex}-${cellLineIndex}`;
                    const classes = classNames(
                      'ea-composite-list-row__cell-value-line',
                      allRowIconClasses &&
                        columnIndex === 0 &&
                        cellLineIndex === 0 &&
                        allRowIconClasses.concat('ea-composite-list-row__cell-value-line--icon'),
                      (showHintIcon || rowIconImageUrl) &&
                        columnIndex === 0 &&
                        cellLineIndex === 0 &&
                        'ea-composite-list-row__cell-value-line--icon',
                      rowIconImageUrl &&
                        columnIndex === 0 &&
                        cellLineIndex === 0 &&
                        'ea-composite-list-row__cell-value-line--custom-image-icon',
                      item ? getValueLineClassArray<T>(item, cellLineIndex, valueLineClasses) : undefined
                    );

                    return (
                      <Fragment key={cellLineIndex}>
                        {rowIconImageUrl && imagesBaseUrl && columnIndex === 0 && cellLineIndex === 0 && (
                          <img
                            className={classNames('of-icon--custom-image')}
                            alt=""
                            {...createImageUrl({
                              format: 'png',
                              height: 30,
                              imagesBaseUrl,
                              originalUrl: rowIconImageUrl,
                              scalingFactors: [0.5, 1, 1.5],
                              width: 30,
                            })}
                          />
                        )}
                        {showHintIcon && columnIndex === 0 && cellLineIndex === 0 ? (
                          <span className={classNames('of-icon--exclamation-mark')} />
                        ) : null}
                        <span className={classes} key={key}>
                          {val}
                        </span>
                      </Fragment>
                    );
                  })}
                  {multiLine && maxLines !== undefined && cellLines.length > maxLines && (
                    <span className="ea-composite-list-row__cell-value-more">
                      + {`${cellLines.length - maxLines} `}
                      {cellLines.length - maxLines > 1 ? multiLine.maxExceededPlural : multiLine.maxExceededSingular}
                    </span>
                  )}
                </div>
                {postfixIconClasses && <span className={classNames(postfixIconClasses)} />}
              </div>
            );
          }
        )}
        {subRowContent}
      </div>
    );

    if (isReadOnly) {
      return <div className="ea-composite-list-row__link-wrapper">{data}</div>;
    }

    return (
      <a className="ea-composite-list-row__link-wrapper" href={externalLink || undefined} onClick={this.onClickRow}>
        {data}
      </a>
    );
  }
}
