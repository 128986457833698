import { EmptyOrError, getErrorPropsFromCommonErrors } from '../EmptyOrError/index.js';
import { useNavigate } from 'react-router-dom';
import type { CommonError } from '../../common/types/errors.js';

export interface SystemErrorProps {
  homePath?: string;
  includeValidationErrors?: boolean;
  onButtonClick?: () => void;
  errors?: CommonError[] | null;
}

export const hasSystemError = (errors?: CommonError[] | null) => Boolean(getErrorPropsFromCommonErrors(errors));

export const SystemError = (props: SystemErrorProps) => {
  const { homePath, includeValidationErrors = false, errors } = props;
  const navigate = useNavigate();

  const onButtonClick = props.onButtonClick ?? (homePath ? () => navigate(homePath) : undefined);
  const errorProps = getErrorPropsFromCommonErrors(errors, includeValidationErrors, onButtonClick);

  return errorProps ? <EmptyOrError {...errorProps} /> : null;
};
