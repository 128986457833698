import * as React from 'react';
import type { ReactNode } from 'react';

/**
 * Main motivation for creating the Anchor element is to solve jsx-a11y errors in faster phase without any significant style changes which are hard to arrange during vacation period
 * Big issue in online codebase are links that are used for actions when they should always be used for navigation
 * This implementation should not be used in any of the new features as it's valid reason for rejecting the PR
 * @deprecated
 */
export const Anchor = ({
  children,
  className,
  id,
  onClick,
}: {
  children: ReactNode;
  className?: string;
  id?: string;
  onClick: () => void;
}) => {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      className={className}
      href=""
      id={id}
      onClick={(event: React.SyntheticEvent) => {
        event.preventDefault();
        onClick();
      }}
      style={{ cursor: 'pointer' }}
    >
      {children}
    </a>
  );
};
