/**
 * Online UI API
 * # Summary Elisa B2B Online UI API  # Usage This API is used in the `https://yrityksille.elisa.fi/` pages.  # Identifiers 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Singular message object
 */
export interface AiChatMessage { 
    /**
     * Content for the message
     */
    content: string;
    /**
     * Role for user or assistant
     */
    role: AiChatMessage.RoleEnum;
    /**
     * Unix timestamp for creation time
     */
    createdAt: number;
}
export namespace AiChatMessage {
    export type RoleEnum = 'user' | 'assistant';
    export const RoleEnum = {
        user: 'user' as RoleEnum,
        assistant: 'assistant' as RoleEnum
    };
}


