import * as CL from '@design-system/component-library';
import { OpenFormAction } from '../OpenFormReducer.js';
import { OpenFormIcon } from './OpenFormIcon.js';
import { useOpenFormDispatch } from '../OpenFormProvider.js';

export type OpenFormNotification = {
  hide?: () => void;
  icon?: string;
  show?: () => void;
  text: string;
  timer?: number;
  type?: CL.NotificationProps['type'];
};

export const OpenFormNotifications = ({ notifications }: { notifications: Set<OpenFormNotification> }) => {
  const dispatch = useOpenFormDispatch();
  const notification = notifications.values().next().value as OpenFormNotification | undefined;
  if (!notification) {
    return null;
  }
  const { hide, show, text, timer, type = 'info', icon = type } = notification;
  return (
    <CL.Notification
      className="of-openform__title__content__notifications"
      icon={icon && <OpenFormIcon icon={icon} size="m" />}
      key={String([text, type, icon])}
      text={text}
      timer={timer}
      type={type}
      visible={true}
      hasCloseButton={true}
      onNotificationShow={show}
      onNotificationHide={() => hide?.() || dispatch(OpenFormAction.notification(undefined))}
    />
  );
};
