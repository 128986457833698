import * as CL from '@design-system/component-library';
import { SelectOneList } from '../SelectOneList/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { formatSum } from '../../common/utils/priceUtils.js';
import { monthMsg, t } from '../../common/i18n/index.js';

import './CampaignSelectTile.scss';

export type CampaignSelectOption = {
  period: number | undefined;
  price: number;
  onClick: () => void;
  isSelected: boolean;
  voucher?: string;
};

interface CampaignSelectTileProps {
  options: CampaignSelectOption[];
}

export const CampaignSelectTile = ({ options }: CampaignSelectTileProps) => {
  const selectOneOptions = options.map(option => ({
    element: (
      <div
        className={`of-campaign-select-tile__item ${
          option.isSelected ? 'of-campaign-select-tile__item__selected' : ''
        }`}
      >
        {option.voucher && (
          <CL.Badge className="of-campaign-select-tile__badge" color="warning" text={option.voucher} />
        )}
        <span>
          {option.period
            ? `${option.period} ${t.XXVX(monthMsg)} ${t.MS5F('fixed term')}`
            : `${t.E86B('No commitment')}`}
        </span>
        <strong
          className={`${dsClass.FONT_WEIGHT_600} ${dsClass.FONT_SIZE_LARGE} ${option.voucher ? 'price-text--highlight' : ''}`}
        >
          {`${formatSum(option.price)}/${t.XXVX(monthMsg)}`}
        </strong>
      </div>
    ),
    onClick: option.onClick,
    isSelected: option.isSelected,
  }));

  return (
    <div className="of-campaign-select-tile">
      <SelectOneList
        disableIcon={true}
        options={selectOneOptions}
        maxItemsAsideDesktop={4}
        maxItemsAsideLaptop={4}
        maxItemsAsideTablet={2}
      />
    </div>
  );
};
