/**
 * SFDC Community API
 * # Summary Elisa B2B API for an administrator of a company to query and modify the company information.  # Usage This API is used in the `https://yrityksille.elisa.fi/omaelisa` pages, but it is possible to later on expose this documentation publicly and make the endpoint queryable via external clients.  # Identifiers All entities in the API have a globally unique, internal identifier field named with the format [object_type]Id, e.g. `invoiceId`, which is also used in some of the API endpoint urls, e.g. `/api/v1/selfservice/invoices/{invoiceId}`. This value is not meaningful to the end user but is only used as an internally in the API.  Most entities also have a displayable identifier named in the format [object_type]DisplayId. This value can and should be shown to the end user, as it is also printed in relevant documents. It is also used in some of the public URLs, e.g. `https://yrityksille.elisa.fi/omaelisa/laskut/{invoiceDisplayId}`. Because of the user might land directly into a URL of a single entity with a display id, some of the API endpoints here also support querying via the display id, e.g. `/api/v1/selfservice/invoices?invoiceDisplayId={invoiceDisplayId}`.  The reason for using two identifiers instead of only one display id, is to make sure that every object will always have exactly one REST API URL that will never collide with another. While collisions of display ids should not happen in production, the id fields are guaranteed to be unique even across different test environments. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SubscriptionType } from './subscriptionType.js';
import { EppDeviceStatusType } from './eppDeviceStatusType.js';
import { SubscriptionPbxType } from './subscriptionPbxType.js';
import { SourceSystem } from './sourceSystem.js';
import { SubscriptionStatusType } from './subscriptionStatusType.js';


export interface SubscriptionHeader { 
    /**
     * Internal, unique id of the subscription. SFDC: csord__Subscription__c.Id
     */
    subscriptionId: string;
    /**
     * Subscription id (SUBnnnnnnnnn) shown to the user. SFDC: csord__Subscription__c.csordtelcoa_Subscription_Number__c or Asset__c.Order_Number__c
     */
    subscriptionDisplayId?: string;
    /**
     * Name shown to the user of the subscription. SFDC: csord__Subscription__c.Name, Asset.Name
     */
    subscriptionName?: string;
    /**
     * Unix timestamp in milliseconds of creation. SFDC: csord__Subscription__c.CreatedDate
     */
    created?: number;
    /**
     * Unix timestamp of last modification. SFDC: csord__Subscription__c.LastModifiedDate
     */
    lastModified?: number;
    /**
     * Optional cost centre for this subscription. SFDC: csord__Subscription__c.Cost_Center__c
     */
    costCenter?: string;
    /**
     * Optional reference number for this subscription. SFDC: csord__Subscription__c.Subscription_Reference__c
     */
    subscriptionReference?: string;
    /**
     * Date of the end of fixed term commitment period if any. UTC midnight of the end date. SFDC: csord__Subscription__c.Commitment_end_date__c.
     */
    commitmentEndDate?: number;
    /**
     * If the subscription is non EPP Device then the date will be csord__Subscription__c.Fixed_Term_Contract_End_Date__c. If the subscription is EPP Device then the date will be csord__Subscription__c.Commitment_end_date__c and if the subscription is EPP Device + Extension Period then date will be csord__Subscription__c.Extension_End_Date__c
     */
    contractEndDate?: number;
    /**
     * Number of months of fixed term contract period if any. SFDC: csord__Subscription__c.Contract_Term__c
     */
    contractTerm?: number;
    /**
     * Billing account id shown to the user. This id is missing for Employee profile users. SFDC: csord__Subscription__c.Billing_Account__c.Billing_Account_Number__c
     */
    billingAccountDisplayId?: string;
    /**
     * Purpose of use of the subscription, used when the subscription isn\'t attached to any person but instead to e.g. a location or organizational unit. SFDC: csord__Subscription__c.Purpose_of_Use__c.  NOTE: \'Online-tilaus\' is the default name which should never be sent out but instead interpreted as empty value! UI field \'tunniste\' in BROADBAND subpage.
     */
    subscriptionPurposeOfUse?: string;
    subscriptionType?: SubscriptionType;
    /**
     * More specific type of the subscription. For DEVICE this is the category of the device, say TABLET. SFDC: cscfga__Attribute__c.cscfga__Value__c, found with Attribute name \'Type\', found with csord__Subscription__c.csordtelcoa_Product_Configuration__c
     */
    subscriptionSubType?: string;
    /**
     * Serial number (IMEI or other) of the device to be delivered. SFDC: csord__Subscription.Serial_Number__c.
     */
    serialNumber?: string;
    sourceSystem?: SourceSystem;
    /**
     * Boolean flag to indicate if the subscription is migrated from other sources or not. Undefined in case of assets
     */
    migrated?: boolean;
    subscriptionStatus?: SubscriptionStatusType;
    /**
     * True if mentioned SFDC field\'s value is \'EPP Device\'. SFDC: cspmb__Price_Item__c.Sub_Type__c.
     */
    eppSubscription?: boolean;
    /**
     * Refers to the endUserContact of Subscription. SFDC: csord__Subscription__c.End_User_Contact__c
     */
    endUserContact?: string;
    /**
     * Refers to the UserContact of Subscription. SFDC: csord__Subscription__c.User_Contact__c
     */
    userContact?: string;
    /**
     * Account master ID of the account for which order is placed
     */
    accountMasterId?: string;
    /**
     * User friendly identifier of the subscription. Varies depending on subscriptionType: phone number, address (fixed line) sub-id (software product), serial number (device, fixed internet). Not required because e.g. not all accessories have any user friendly id. SFDC: UserFriendlyID__c
     */
    subscriptionUserFriendlyId?: string;
    /**
     * Sum of monthly recurring charge for the subscription in cents. This price will be calculated based on discount applied to subscription. SFDC: csord_Subscription.Final_Service_Recurring_Charges__c.
     */
    monthlyRecurringCharge?: number;
    /**
     * Sum of one-time charges for the subscription in cents. This price will be calculated based on discount applied to subscription. SFDC: csord_Subscription.Final_Service_One_Off_Charges__c.
     */
    oneTimeCharge?: number;
    /**
     * Recurring list price of the subscription in cents. This price will be without any discount. SFDC: csord_Subscription.csord__Total_Service_Recurring_Charges__c.
     */
    monthlyRecurringListCharge?: number;
    /**
     * Number of months of fixed term commitment period if any. SFDC: csord__Subscription__c.Commitment_period__c.
     */
    commitmentPeriod?: number;
    /**
     * Date of the start of fixed term commitment period if any. UTC midnight of the start date. SFDC: csord__Subscription__c.Commitment_start_date__c.
     */
    commitmentStartDate?: number;
    eppDeviceStatus?: EppDeviceStatusType;
    /**
     * Source system of the enrollment program. SFDC: csord__Subscription__c.Asset.Enrollment_Program_Type__c.
     */
    enrollmentProgramSystem?: SubscriptionHeader.EnrollmentProgramSystemEnum;
    /**
     * External Id of the enrollment program. SFDC: Account.BA_External_ID__c.External_ID__c.
     */
    enrollmentProgramExternalId?: string;
    /**
     * Monthly charges paid by company
     */
    corporateShare?: number;
    /**
     * Monthly charges paid by employee
     */
    employeeShare?: number;
    /**
     * Internal, unique id of the billing account. This id is missing for Employee profile users. SFDC: csord__Subscription__c.Billing_Account__c.Id
     */
    billingAccountId?: string;
    /**
     * Name of the contact that this subscription is assigned to. For SOFTWARE_PRODUCT, this is the admin user, and contact id is for now missing. SFDC: csord__Subscription__c.User_Contact__c, Asset.User_Contact__c.
     */
    subscriptionContactName?: string;
    /**
     * Id for user of the subscription. If this is missing but contact name is given, the contact is not linkable. SFDC: csord__Subscription__c.User_Contact__c.
     */
    subscriptionContactId?: string;
    /**
     * Internal ID of the delivery order this subscription was ordered with. SFDC: csord__Order__c.Id, found with csord__Subscription.csord__Order__c.
     */
    deliveryOrderId?: string;
    /**
     * Indicates the change type performed on the subscription. It can have the values :New, Extension Period, EPP Device Change, Others. SFDC: csord__subscription__c.Subscription_Type__c.
     */
    changeType?: string;
    /**
     * Subscriptions SIM card number (=ICCID). SFDC: csord__subscription__c.ICCID__c
     */
    simCardNumber?: string;
    pbxType?: SubscriptionPbxType;
    /**
     * Vakio / Ring corporate number
     */
    corporateNumber?: string;
    /**
     * Extension number for this subscription in PBX. SFDC: Extension_Number__c.
     */
    extensionNumber?: string;
    /**
     * Fixed broadband installation address concatenated in one string. SFDC: Easy_Address__r.Name.
     */
    installationAddress?: string;
    /**
     * Laskutuskoodi. SFDC: Old_Contract_Id__c.
     */
    oldContractId?: string;
    /**
     * DNS Management Disallowed. SFDC: DNS_Management_Disallowed__c
     */
    dnsManagementDisallowed?: boolean;
}
export namespace SubscriptionHeader {
    export type EnrollmentProgramSystemEnum = 'DEP' | 'KME' | 'ZEROTOUCH' | 'AUTOPILOT';
    export const EnrollmentProgramSystemEnum = {
        DEP: 'DEP' as EnrollmentProgramSystemEnum,
        KME: 'KME' as EnrollmentProgramSystemEnum,
        ZEROTOUCH: 'ZEROTOUCH' as EnrollmentProgramSystemEnum,
        AUTOPILOT: 'AUTOPILOT' as EnrollmentProgramSystemEnum
    };
}


