import { Addons } from './Addons.js';
import { ServiceLevelsDropdown } from './ServiceLevelsDropdown.js';
import { getAssociationsAvailability } from '@onlinefirst/cloudsense-add-on-dependency-engine';
import { useState } from 'react';
import type {
  AssociationAvailability,
  AssociationRecord,
  DependencyRecord,
} from '@onlinefirst/cloudsense-add-on-dependency-engine';
import type { OnlineModel } from '../../generated/api/models.js';
import type { ServiceLevel, ServiceLevelAddon } from './ServiceLevelAndAddonsInterfaces.js';

const toggleAddonSelection = (addons: ServiceLevelAddon[], addonId: string) =>
  addons.map(addon => {
    if (addon.id === addonId) {
      addon.selected = !addon.selected;
    }
    return addon;
  });

interface ServiceLevelAndAddonsProps {
  associations?: AssociationRecord[];
  dependencies?: DependencyRecord[];
  ringModels?: OnlineModel[];
  inputServiceLevels?: ServiceLevel[];
  inputAddons?: ServiceLevelAddon[];
  openingFeeAddons?: AssociationRecord[];
  updateComponentValues: (
    pbxServiceLevels?: {
      pbxServiceLevelAddons?: ServiceLevelAddon[];
      pbxServiceLevels?: ServiceLevel[];
    },
    pbxServiceLevelAddons?: ServiceLevelAddon[]
  ) => void;
  // updateComponentValues: Function;
  onChange?: (ref?: string, value?: object) => void;
  editing?: boolean;
}

export const ServiceLevelAndAddons = ({
  associations,
  dependencies,
  ringModels,
  inputServiceLevels,
  inputAddons,
  openingFeeAddons,
  updateComponentValues,
  onChange,
  editing = true,
}: ServiceLevelAndAddonsProps) => {
  const [serviceLevels, setServiceLevels] = useState(inputServiceLevels);
  const [addons, setAddons] = useState(inputAddons);

  const selectedService = serviceLevels?.find(element => element.selected) || (serviceLevels && serviceLevels[0]);

  const addonsForSelectedService =
    selectedService && addons?.filter(addon => addon.serviceLevelId === selectedService.id);

  const updateAddonsState = (addonId: string) => {
    if (addons) {
      setAddons(toggleAddonSelection(addons, addonId));
      onChange?.('pbxServiceLevelAddons', addons);
    }

    updateComponentValues({
      pbxServiceLevels: serviceLevels,
      pbxServiceLevelAddons: addons,
    });
  };

  const selectedAddOns =
    (addonsForSelectedService &&
      addonsForSelectedService.filter(addOn => addOn.selected).map(addOn => addOn.addonCode)) ||
    [];

  const activeRingOffer = ringModels?.[0].offers.filter(offer => offer.offerName === selectedService?.name);

  const ringCommercialProductId = activeRingOffer && activeRingOffer[0].commercialProducts[0].commercialProductId;

  const selectedAssociationsId =
    associations &&
    associations
      .filter(assoc => {
        return assoc.cspmb__price_item__c === ringCommercialProductId;
      })
      .filter(assoc => {
        return selectedAddOns?.includes(assoc.cspmb__add_on_price_item__r.guid__c);
      })
      .map(assoc => assoc.id);

  let availabilities: AssociationAvailability[] = [];

  if (ringCommercialProductId && associations && dependencies && selectedAssociationsId) {
    availabilities = getAssociationsAvailability(
      ringCommercialProductId,
      associations,
      dependencies,
      selectedAssociationsId
    );
  }

  const incompatibleAddonIds = availabilities
    .filter(item => !item.isAvailable || item.isExcluded)
    .map(item => {
      return item.assoc.cspmb__add_on_price_item__r.guid__c;
    });

  const updateServiceLevelsState = (serviceLevelsParam: ServiceLevel[]) => {
    setServiceLevels(serviceLevelsParam);
    onChange?.('pbxServiceLevels', serviceLevelsParam);

    updateComponentValues({
      pbxServiceLevels: serviceLevelsParam,
      pbxServiceLevelAddons: addons,
    });
  };

  const renderComponent = () => (
    <div>
      <ServiceLevelsDropdown
        editing={editing}
        serviceLevels={serviceLevels}
        setServiceLevels={updateServiceLevelsState}
      />
      <Addons
        addonsList={addonsForSelectedService}
        editing={editing}
        openingFeeAddons={openingFeeAddons}
        incompatibleAddonIds={incompatibleAddonIds}
        selectedCheckBoxIdCallback={updateAddonsState}
      />
    </div>
  );

  return <div>{renderComponent()}</div>;
};
