import { AutoComplete } from '../../../components/AutoComplete/AutoComplete.js';
import { getDeliveryMethodDropDownOptions, getEmptyBillingAccount } from '../../utils/billingAccountUtils.js';
import { invoiceDeliveryMethodMsg, noSelectionMsg, t } from '../../i18n/index.js';
import { useController, useFormContext } from 'react-hook-form';
import type { BillChannel } from '../../../generated/api/billChannel.js';
import type CLT from '@design-system/component-library';

interface DeliveryMethodProps {
  billChannels: BillChannel[];
  name: string;
  customDeliveryMethodOptions?: Array<CLT.DropDownItem>;
}

export const DeliveryMethod = (props: DeliveryMethodProps) => {
  const name = props.name;
  const deliveryMethodOptions =
    props.customDeliveryMethodOptions ??
    getDeliveryMethodDropDownOptions(getEmptyBillingAccount(''), props.billChannels);
  const methods = useFormContext();
  const { control, setValue, setError } = methods;
  const { field, formState } = useController({ name, control });
  const keys = name.split('.');
  const value = keys.reduce((current, key) => (current ? current[key] : undefined), formState.defaultValues);
  const defaultValue = deliveryMethodOptions.find(i => i.value === value);

  return (
    <AutoComplete<CLT.DropDownItem>
      className="large-min-height"
      inputRef={field.ref}
      options={deliveryMethodOptions}
      defaultOption={defaultValue}
      getUniqueId={i => i.value}
      getDisplayValue={i => i.label}
      onInputBlur={field.onBlur}
      onInputChange={(e, item) => {
        setValue(name, item.value);
        setError(name, { message: undefined });
      }}
      id={`${name}-autocomplete`}
      noOptionsMsg={t.ZW5W(noSelectionMsg)}
      label={t.H272(invoiceDeliveryMethodMsg)}
      config={{ isClearable: false, isSortable: false }}
    />
  );
};
