import { InputComponent } from '../components/InputComponent.js';
import { POSTAL_CODE_REGEX, POSTAL_CODE_WITH_PO_BOX_REGEX } from '../../utils/validationUtils.js';
import { fieldCantBeEmptyMsg, postalCodeMsg, t } from '../../i18n/index.js';
import type { OptionalInputComponentProps } from '../components/InputComponent.js';

export interface PostalCodeProps extends OptionalInputComponentProps {
  allowPoBox?: boolean;
}

export const PostalCode = ({
  name = 'postalCode',
  label = t.RUAW(postalCodeMsg),
  placeholder = t.RUAW(postalCodeMsg),
  required = true,
  allowPoBox = false,
  readonly = false,
  disabled = false,
}: PostalCodeProps) => {
  const validPostalCodeTester = allowPoBox ? POSTAL_CODE_WITH_PO_BOX_REGEX : POSTAL_CODE_REGEX;
  const validate = (value: string) => {
    if (!value && required !== false) {
      return t.VPVR(fieldCantBeEmptyMsg);
    }
    return validPostalCodeTester.test(value) ? undefined : t.AT96('Invalid postal code');
  };

  return (
    <InputComponent
      validate={validate}
      name={name}
      type="text"
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      required={required}
      readonly={readonly}
    />
  );
};
