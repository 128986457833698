import { CompositeList } from '../CompositeList/CompositeList.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { formatSum } from '../../common/utils/priceUtils.js';
import { noAdditionalServicesMsg, t } from '../../common/i18n/index.js';
import type { AddOn, DeliveryOrderDetails } from '../../generated/api/models.js';
import type { CompositeListProps } from '../CompositeList/CompositeList.js';

export const getPeriodicPriceAsText = (monthlyRecurringCharge?: number) => {
  return monthlyRecurringCharge ? `${formatSum(monthlyRecurringCharge)}/kk` : '—';
};

const createAddonDetails = (details: DeliveryOrderDetails): JSX.Element => {
  const addOnListProps: CompositeListProps<AddOn> = {
    columns: [
      {
        columnId: 'addOnProductName',
        ref: 'addOnProductName',
      },
      {
        columnId: 'addOnMonthlyRecurringCharge',
        ref: 'addOnMonthlyRecurringCharge',
        refFormatNumber: getPeriodicPriceAsText,
      },
    ],
    emptyListElement: <span>{t.EIYK(noAdditionalServicesMsg)}</span>,
    getRowId: (addOn: AddOn) => addOn.addOnCode,
    items: details.selectedAddOns ? details.selectedAddOns.filter(addon => addon.display === true) : [],
  };
  return <CompositeList {...addOnListProps} />;
};

interface DeliveryOrderDetailsAddonsProps {
  details: DeliveryOrderDetails;
}

export const DeliveryOrderDetailsAddons = ({ details }: DeliveryOrderDetailsAddonsProps) => {
  return (
    <div className="of-delivery-order-details--section" key="of-delivery-order--section-addons">
      <h3 className={dsClass.H3}>{t.FK2F('Supplementary services ordered')}</h3>
      {createAddonDetails(details)}
    </div>
  );
};
