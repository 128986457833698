import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { LabelWithTooltip } from '../LabelWithTooltip/LabelWithTooltip.js';
import { SIM_CARD_REGEX } from '../../common/utils/validationUtils.js';
import { SelectRadio } from '../../common/react-hook-form/components/SelectRadio.js';
import { SimCardNumberInput } from '../SimCardNumberInput/SimCardNumberInput.js';
import { SimType } from '../../generated/api/models.js';
import {
  activateSimCardMsg,
  fieldCantBeEmptyMsg,
  invalidSIMCardNumberMsg,
  orderEsimMsg,
  orderNewSimCardMsg,
  simCardMsg,
  simCardOneSimPerSubscriptionMsg,
  t,
} from '../../common/i18n/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { getLuhnRemainder } from '../../common/utils/simCardUtils.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { validateSim } from '../../selfservice/actions/index.js';
import type { SelectRadioItem } from '../../common/react-hook-form/components/SelectRadio.js';
import type { SimCardConfiguration } from '../../common/types/subscription.js';
import type { State } from '../../selfservice/common/store.js';
import type { ValidatedSim } from '../../common/types/sim.js';

import './SimCardDuringNewSubscriptionOrder.scss';

export enum SimOption {
  ORDER_NEW_PHYSICAL,
  ORDER_NEW_ESIM,
  ORDER_NEW_PHYSICAL_MINI_MICRO,
  ORDER_NEW_PHYSICAL_NANO,
  ACTIVATE_EXISTING_PHYSICAL,
}

export const ACTIVATE_EXISTING = 'ACTIVATE_EXISTING';

export interface SimCardDuringNewSubscriptionOrderProps {
  hideHeader?: boolean;
  cartItemInstanceId: string;
  indexNumber?: number;
  onClickSimCardNumberHelp: () => void;
  showSimSizeTooltip?: boolean;
  simCardConfiguration: SimCardConfiguration;
  simOptions: SimOption[];
  /**
   * Sim card numbers used in the cart to avoid same number in two subs.
   */
  simCardNumbers: string[];
}

export const isInvalidSimCardNumber = (simCardNumber: string): boolean =>
  !simCardNumber.match(new RegExp(SIM_CARD_REGEX)) || getLuhnRemainder(simCardNumber) !== 0;

export const validateSimCardNumber = (validatedSims: ValidatedSim[], simCardNumbers: string[]) => (value: string) => {
  if (value && isInvalidSimCardNumber(value)) {
    return t.SDXB(invalidSIMCardNumberMsg);
  }

  // These errors will be shown immediately after the user has entered the SIM card number
  const validationErrors = validatedSims.find(sim => sim.simCardNumber === value)?.error;
  if (validationErrors) {
    return validationErrors;
  }

  if (simCardNumbers.filter(otherSimCardNumber => otherSimCardNumber === value).length > 1) {
    return t.RFH9(simCardOneSimPerSubscriptionMsg);
  }

  return undefined;
};

export const SimCardDuringNewSubscriptionOrder = (props: SimCardDuringNewSubscriptionOrderProps) => {
  const {
    hideHeader = false,
    cartItemInstanceId,
    indexNumber,
    onClickSimCardNumberHelp,
    showSimSizeTooltip = false,
    simOptions,
    simCardConfiguration,
    simCardNumbers,
  } = props;

  const validatedSims = useSelector((state: State) => state?.resources?.validatedSims || [], deepEqual);

  const dispatch = useDispatch();

  const { control, watch, setValue } = useFormContext();

  const simCardNumber = useWatch({
    name: `configuration.${cartItemInstanceId}.simCardConfiguration.simCardNumber`,
  });
  useEffect(() => {
    if (simCardNumber) {
      if (!isInvalidSimCardNumber(simCardNumber)) {
        dispatch(validateSim(simCardNumber));
      }
    }
  }, [dispatch, simCardNumber]);

  const simItems: SelectRadioItem[] = [];
  if (simOptions.includes(SimOption.ORDER_NEW_PHYSICAL_MINI_MICRO)) {
    simItems.push({
      label: t.A7CJ('Order new (Mini + Micro)'),
      value: SimType.PHYSICAL_MINI_MICRO,
    });
  }
  if (simOptions.includes(SimOption.ORDER_NEW_PHYSICAL_NANO)) {
    simItems.push({
      label: t.A7CK('Order new (Nano)'),
      value: SimType.PHYSICAL_NANO,
    });
  }
  if (simOptions.includes(SimOption.ORDER_NEW_PHYSICAL)) {
    simItems.push({
      label: t.QYXA(orderNewSimCardMsg),
      value: SimType.PHYSICAL,
    });
  }
  if (simOptions.includes(SimOption.ORDER_NEW_ESIM)) {
    simItems.push({
      children: (
        <LabelWithTooltip labelText={t.DD3Q(orderEsimMsg)}>
          {t.MF9P(
            'eSIM is a digital SIM card. At the moment eSIM compatible devices are the newest iPhones, iPads and Samsung Galaxy Watches.'
          )}
        </LabelWithTooltip>
      ),
      value: SimType.ESIM,
    });
  }
  if (simOptions.includes(SimOption.ACTIVATE_EXISTING_PHYSICAL)) {
    simItems.push({
      label: t.M1E7(activateSimCardMsg),
      value: ACTIVATE_EXISTING,
    });
  }

  const selectedType = watch(`configuration.${cartItemInstanceId}.simCardConfiguration.simType`);
  const defaultValue =
    simCardConfiguration.simSelection === ACTIVATE_EXISTING ? ACTIVATE_EXISTING : simCardConfiguration?.simType;
  useEffect(() => {
    if (selectedType === undefined && defaultValue !== undefined) {
      setValue(`configuration.${cartItemInstanceId}.simCardConfiguration.simType`, defaultValue);
    }
  }, [cartItemInstanceId, selectedType, defaultValue, setValue]);

  return (
    <div
      id={`simCardConfiguration-${cartItemInstanceId}`}
      className={`of-sim-card-selection of-sim-card-selection-${indexNumber || '0'}`}
      role="group"
    >
      {hideHeader || (
        <LabelWithTooltip className="ea-h4" labelText={t.PIZC(simCardMsg)}>
          {showSimSizeTooltip
            ? t.A7CL(
                'Please check which SIM-card type fits into your device. Available choices are a combo card Mini (2FF) + Micro (3FF) or a Nano (4FF) card.'
              )
            : undefined}
        </LabelWithTooltip>
      )}
      <SelectRadio
        name={`configuration.${cartItemInstanceId}.simCardConfiguration.simType`}
        defaultValue={defaultValue}
        items={simItems}
      />
      {selectedType === SimType.ESIM && (
        <div className="of-sim-card-selection__esim-info">
          <a href="https://elisa.fi/esim/" target="_blank" rel="noopener noreferrer">
            {t.MC5L('eSIM compatible devices')}
          </a>
        </div>
      )}
      {selectedType === ACTIVATE_EXISTING && (
        <Controller
          name={`configuration.${cartItemInstanceId}.simCardConfiguration.simCardNumber`}
          control={control}
          shouldUnregister={true}
          defaultValue={simCardConfiguration.simCardNumber || ''}
          rules={{
            required: { value: true, message: t.VPVR(fieldCantBeEmptyMsg) },
            validate: validateSimCardNumber(validatedSims, simCardNumbers),
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <SimCardNumberInput
              error={error?.message}
              onChange={onChange}
              value={value}
              onAppendedButtonClick={onClickSimCardNumberHelp}
            />
          )}
        />
      )}
    </div>
  );
};
