import * as CL from '@design-system/component-library';
import { CostCenter, Reference, resetField } from '../../../common/formik/index.js';
import { GridColumn } from '../../BillingAccountDetails/components/GridColumn.js';
import {
  costCenterAndReferenceMsg,
  costCenterMsg,
  costCenterToolTipMsg,
  notMandatoryMsg,
  referenceIdMsg,
  referenceToolTipMsg,
  t,
} from '../../../common/i18n/index.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import type { Contact } from '../../../generated/api/contact.js';
import type { ContactOrPurposeOfUseFormValues } from '../ContactsOrPurposeOfUseUtils.js';

interface CostCenterAndEmployeeNumberProps {
  isCostCenterRequired?: boolean;
  contacts?: Contact[];
}

export const CostCenterAndEmployeeNumber = ({ isCostCenterRequired, contacts }: CostCenterAndEmployeeNumberProps) => {
  const {
    dirty,
    values: { selectContact, costCenter, employeeNumber },
    setFieldValue,
    setFieldTouched,
    setFieldError,
  } = useFormikContext<ContactOrPurposeOfUseFormValues>();

  // Sets new cost center and employee number from contact when contact changes
  useEffect(() => {
    const selectedContact = selectContact && contacts?.find(c => c.contactId === selectContact);
    if (selectedContact) {
      resetField(
        'costCenter',
        dirty ? selectedContact.person?.costCenter || '' : costCenter || selectedContact.person?.costCenter || '',
        { setFieldValue, setFieldTouched, setFieldError }
      );
      resetField(
        'employeeNumber',
        dirty
          ? selectedContact.person?.employeeNumber || ''
          : employeeNumber || selectedContact.person?.employeeNumber || '',
        { setFieldValue, setFieldTouched, setFieldError }
      );
    }
  }, [selectContact, setFieldValue, setFieldTouched, setFieldError]); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={dsClass.PADDING_TOP_2}>
      <h5 className={`${dsClass.MARGIN_BOTTOM_1} ${dsClass.MARGIN_TOP_3}`}>{t.JBEM(costCenterAndReferenceMsg)}</h5>
      <CL.Grid className={`of-contact-or-purpose-of-use__grid ${dsClass.PADDING_TOP_2}`}>
        <CL.GridRow className="of-contact-or-purpose-of-use__grid_column of-contact-or-purpose-of-use__cost_center_employee_number">
          <GridColumn>
            <CostCenter
              required={isCostCenterRequired}
              labelTooltip={t.UE8R(costCenterToolTipMsg)}
              label={`${t.L2OG(costCenterMsg)}${!isCostCenterRequired ? ' ' + t.NFRH(notMandatoryMsg) : ''}`}
              type="text"
            />
          </GridColumn>
          <GridColumn>
            <Reference
              label={`${t.CWR5(referenceIdMsg)} ${t.NFRH(notMandatoryMsg)}`}
              labelTooltip={t.HBBD(referenceToolTipMsg)}
              type="text"
            />
          </GridColumn>
        </CL.GridRow>
      </CL.Grid>
    </div>
  );
};
