import * as React from 'react';
import { DialogWrapper } from '../DialogWrapper/index.js';
import { Dropdown } from '../Dropdown/Dropdown.js';
import { confirmMsg, multipleRingSolutionErrorMsg, t } from '../../common/i18n/index.js';
import type { AccountKeyUser, Subscription } from '../../generated/api/models.js';
import type { CommonError } from '../../common/types/errors.js';
import type { ConfigState } from '../../common/types/states.js';

export interface AddRingAdminDialogAttrs {
  errors?: CommonError[];
  accountKeyUsers: AccountKeyUser[];
  ringPbxSolutions: Subscription[];
  config: ConfigState;
  changeRequestInProgress?: boolean;
  onCloseDialog: () => void;
  onSubmitDialog: (userName: string) => void;
}

export interface AddRingAdminDialogState {
  selectedUserName?: string;
}

export type AddRingAdminDialogProps = AddRingAdminDialogAttrs;

const getUserDisplayName = (user: AccountKeyUser) => {
  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`;
  }
  return user.displayName ? user.displayName : user.userName;
};

const getDropdownOptions = (accountKeyUsers: AccountKeyUser[]) =>
  accountKeyUsers.map((user, idx) => ({
    id: user.userName,
    label: getUserDisplayName(user),
    value: `${idx}`,
  }));

export class AddRingAdminDialog extends React.Component<AddRingAdminDialogProps, AddRingAdminDialogState> {
  constructor(props: AddRingAdminDialogProps) {
    super(props);
    this.onChangeUser = this.onChangeUser.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {};
  }
  onChangeUser(selectedUserName?: string) {
    this.setState({ selectedUserName });
  }
  onSubmit() {
    const { accountKeyUsers, ringPbxSolutions, onSubmitDialog } = this.props;
    const { selectedUserName } = this.state;
    const accountKeyUser = accountKeyUsers.find(keyUser => keyUser.userName === selectedUserName);
    if (ringPbxSolutions && ringPbxSolutions.length > 1) {
      throw new Error(multipleRingSolutionErrorMsg + ringPbxSolutions.map(sub => sub.subscriptionId).join());
    }
    const subscription = ringPbxSolutions[0];
    if (accountKeyUser && subscription) {
      onSubmitDialog(accountKeyUser.userName);
    }
  }
  render() {
    const { accountKeyUsers, config, changeRequestInProgress, onCloseDialog } = this.props;
    const dropdownOptions = getDropdownOptions(accountKeyUsers);

    return (
      <DialogWrapper
        buttons={[
          {
            color: 'link',
            onClick: onCloseDialog,
            text: t.B2V1('Cancel'),
          },
          {
            isLoading: changeRequestInProgress,
            onClick: this.onSubmit,
            text: t.QVYK(confirmMsg),
          },
        ]}
        closeable
        header={t.O243('Add a Ring admin')}
        onCloseDialog={onCloseDialog}
      >
        <p>
          {t.ISDG('Select Ring Administrator from the list. You can also add new people to the list.')}
          <a href={`${config.classicSiteUrl}/kayttajat/`}>{t.EM4I(' in old OmaElisa service.')}</a>
        </p>
        <div id="select-ring-sso-name-combobox">
          <Dropdown
            items={dropdownOptions}
            onValueChange={element => {
              const newValue = element.attributes.getNamedItem('data-value')?.value;
              const option = dropdownOptions.find(o => o.value === newValue);
              this.onChangeUser(option?.id);
            }}
            i18n_dropdown_placeholderText={t.YC7L('Add a Ring admin')}
          />
        </div>
      </DialogWrapper>
    );
  }
}
