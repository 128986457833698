import {
  areYouMakingTheUpdateOnBehalfOfAnotherCompanyOrHousingCompanyMsg,
  areYouTheInvoicingContactPersonMsg,
  businessIdMsg,
  companyNameMsg,
  customerNumberMsg,
  deliveryMethodMsg,
  doesTheChangeApplyToAllBillingAccountsForThisBusinessIDMsg,
  eInvoicingAddressMsg,
  eInvoicingOperatorMsg,
  emailAddressMsg,
  emailMsg,
  firstNameMsg,
  invoiceLanguageMsg,
  invoicingDetailsMsg,
  lastNameMsg,
  numberOfTheBillingAccountMsg,
  payerDetailsMsg,
  payerMsg,
  phoneNumberMsg,
  postOfficeMsg,
  postalAddressMsg,
  postalCodeMsg,
  selectEffectiveDateMsg,
  t,
  willPayersNameOrAddressChangeMsg,
  willTheChangeTakeEffectImmediatelyMsg,
} from '../../common/i18n/index.js';
import { getLanguageDisplayValue } from '../../common/utils/languageUtils.js';
import type { ContactSupportFormValues } from './ContactSupportForm.js';
import type { ContactSupportInfo } from './types.js';
import type { EOperatorChangeRequestFormValues } from './EOperatorChange/EOperatorChangeRequestForm.js';

/**
 * Convert ContactSupportFormValues to LeadContactDetails
 * Convert ContactSupportFormValues to SupportCaseContactDetails
 */
export const mapContactSupportFormValuesToContactDetails = ({
  businessId,
  businessName,
  firstName,
  lastName,
  email,
  phoneNumber,
}: ContactSupportFormValues) => ({
  businessId,
  businessName,
  firstName,
  lastName,
  phoneNumber,
  email,
});

export const truncateSourceUrl = (url: string): string => {
  const sourceUrlMaxLength = 255;
  return url.split('?')[0].substring(0, sourceUrlMaxLength - 1);
};

export const getEOperatorChangeRequestFormValuesAsSingleString = (
  values: EOperatorChangeRequestFormValues,
  contactValues: ContactSupportInfo
) => {
  const getTranslatedBooleanValue = (value: boolean) => (value ? t.M03S('Yes') : t.A37D('No'));

  return `${t.CACT(willTheChangeTakeEffectImmediatelyMsg)}: ${getTranslatedBooleanValue(
    values.willChangeTakeEffectImmediately
  )}\n${
    !values.willChangeTakeEffectImmediately ? `${t.NDRY(selectEffectiveDateMsg)}: ${values.effectiveDate}\n` : ''
  }${t.A0OK(areYouMakingTheUpdateOnBehalfOfAnotherCompanyOrHousingCompanyMsg)}: ${getTranslatedBooleanValue(
    values.actingOnBehalfOfCompany
  )}\n${
    values.actingOnBehalfOfCompany
      ? `${t.AJ93(companyNameMsg)}: ${values.actingOnBehalfOfCompanyBusinessName}\n${t.MPA5(businessIdMsg)}: ${
          values.actingOnBehalfOfCompanyBusinessId
        }\n${t.A00L(customerNumberMsg)}: ${values.actingOnBehalfOfCompanyCustomerNumber}\n`
      : ''
  }${t.V5EW(doesTheChangeApplyToAllBillingAccountsForThisBusinessIDMsg)}: ${getTranslatedBooleanValue(
    values.allBillingAccountsApply
  )}\n${
    !values.allBillingAccountsApply
      ? `${t.GQLW(numberOfTheBillingAccountMsg)}: ${values.billingAccounts
          ?.map(ba => ba.billingAccountId)
          .join(', ')}\n`
      : ''
  }${contactValues.firstName ? `${t.AIK7(firstNameMsg)}: ${contactValues.firstName}\n` : ''}${
    contactValues.lastName ? `${t.Y41S(lastNameMsg)}: ${contactValues.lastName}\n` : ''
  }${contactValues.email ? `${t.OKYY(emailAddressMsg)}: ${contactValues.email}\n` : ''}${
    contactValues.phoneNumber ? `${t.AGIJ(phoneNumberMsg)}: ${contactValues.phoneNumber}\n` : ''
  }${t.RPMR(invoicingDetailsMsg)}\n${
    values.customerNumber ? `${t.A00L(customerNumberMsg)}: ${values.customerNumber}\n` : ''
  }${t.G0QN(deliveryMethodMsg)}: ${values.deliveryMethod}\n${t.A7DR(invoiceLanguageMsg)}: ${getLanguageDisplayValue(
    values.billLanguage
  )}\n${t.WVLB(eInvoicingOperatorMsg)}: ${values.eOperator}\n${t.OL7B(eInvoicingAddressMsg)}: ${
    values.eInvoiceAddress
  }\n${t.P9D3(areYouTheInvoicingContactPersonMsg)}: ${getTranslatedBooleanValue(values.isBillingContactPerson)}\n${
    !values.isBillingContactPerson
      ? `${t.IHRV('Invoicing contact person details')}\n${t.AIK7(firstNameMsg)}: ${
          values.billingContactPersonFirstName
        }\n${t.Y41S(lastNameMsg)}: ${values.billingContactPersonLastName}\n${t.ZVQ5(emailMsg)}: ${
          values.billingContactPersonEmail
        }\n${t.AGIJ(phoneNumberMsg)}: ${values.billingContactPersonPhone}\n`
      : ''
  }${t.GMMI(willPayersNameOrAddressChangeMsg)}: ${getTranslatedBooleanValue(values.payerNameOrAddressChange)}\n${
    values.payerNameOrAddressChange
      ? `${t.PB6S(payerMsg)}: ${values.payerName}\n${
          values.payerAdditionalInfo ? `${t.YLAI(payerDetailsMsg)}: ${values.payerAdditionalInfo}\n` : ''
        }${t.NDWK(postalAddressMsg)}: ${values.payerPostalAddress}\n${t.RUAW(postalCodeMsg)}: ${
          values.payerPostalCode
        }\n${t.Z7S5(postOfficeMsg)}: ${values.payerPostOffice}\n`
      : ''
  }`.slice(0, -1);
};
