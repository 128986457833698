import * as CL from '@design-system/component-library';
import { DialogWrapper } from '../DialogWrapper/index.js';
import { Link } from 'react-router-dom';
import { closeMsg, t } from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import type { CommonError } from '../../common/types/errors.js';

import './NameChangeConfirmationDialog.scss';

export type NameChangeConfirmationDialogProps = {
  onCloseDialog: () => void;
  errors?: CommonError[];
};

export const NameChangeConfirmationDialog = ({ onCloseDialog, errors }: NameChangeConfirmationDialogProps) => {
  return (
    <DialogWrapper
      buttons={[{ onClick: onCloseDialog, text: t.WOYD(closeMsg) }]}
      closeable
      header={t.A2WA('You cannot change a user into another person')}
      onCloseDialog={onCloseDialog}
    >
      <p>
        {t.A2WB(
          `You are changing the person's first and last name. If the person is changing into another, delete the current user and `
        )}
        <Link to={paths.COMPANY_INFO_CONTACTS_NEW}>{t.A2WC('create a new user')}</Link>
        {t.ILLN(' instead.')}
      </p>
      <p>
        {t.A2WD('If both first and last names are incorrect, but it is the same person, please contact our ')}
        <Link to={paths.SUPPORT_CASE_NEW}>{t.MMUE('customer service')}</Link>
        {t.Z7CU(' for name correction.')}
      </p>
      {errors &&
        errors.map((error, i) => (
          <CL.Notification key={i} hasCloseButton={false} text={error.message} type="warning" visible />
        ))}
    </DialogWrapper>
  );
};
