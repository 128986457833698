import { OpenFormGridRow } from './OpenFormGrid.js';
import type { OpenFormSection } from '../../../generated/api/openFormSection.js';

export const OpenFormSectionLabel = ({ section }: { section: OpenFormSection }) => (
  <OpenFormGridRow>
    <OpenFormGridRow>
      <h3>{!section?.label ? null : section.label}</h3>
    </OpenFormGridRow>
    <OpenFormGridRow className="of-openform__section-label__description">
      <div>{!section?.description ? null : section.description}</div>
    </OpenFormGridRow>
  </OpenFormGridRow>
);
