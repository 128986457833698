import {
  type Dispatch,
  type MutableRefObject,
  type Reducer,
  type ReducerAction,
  type ReducerState,
  useReducer,
  useRef,
} from 'react';

export const useRefReducer = <S, A>(
  reducer: Reducer<S, A>,
  initialState: S
): [MutableRefObject<ReducerState<(s: S, a: A) => S>>, Dispatch<ReducerAction<(s: S, a: A) => S>>] => {
  const ref = useRef(initialState);
  return [ref, useReducer((state: S, action: A) => (ref.current = reducer(state, action)), initialState)[1]];
};
