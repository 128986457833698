import * as CL from '@design-system/component-library';
import { showLessMsg, showMoreMsg, t } from '../../common/i18n/index.js';
import { useState } from 'react';

import './ExpandableText.scss';

export interface ExpandableTextProps {
  text: string;
  lineClamp: number;
}

export const ExpandableText = ({ text, lineClamp }: ExpandableTextProps) => {
  const textStyle = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    WebkitLineClamp: lineClamp,
  };
  const [collapsed, setCollapsed] = useState(true);
  return (
    <>
      <div style={collapsed ? textStyle : undefined} className="expandable-text">
        {text}
      </div>
      <CL.Button className="show-more-button" color="link" onClick={() => setCollapsed(!collapsed)}>
        {collapsed ? t.H6OA(showMoreMsg) : t.DDHX(showLessMsg)}
      </CL.Button>
    </>
  );
};
