import { PhoneNumber } from '../../../common/react-hook-form/fields/index.js';
import { SubscriptionPbxDetails } from '../../../generated/api/models.js';
import { callDestinationMsg, t } from '../../../common/i18n/index.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { formatPhoneNumber } from '../../../common/utils/phoneNumberUtils.js';
import type { CommonError } from '../../../common/types/errors.js';

import './ReachabilityChain.scss';

const getNumberChainRow = (
  label: string,
  name: string,
  numbers: string[],
  edit?: boolean,
  errors?: CommonError[],
  setRefCallback?: (key: string, ref: HTMLElement | null) => void,
  vakioStyle?: boolean
) => {
  const genericErrors = errors?.filter(error => !error.field!.startsWith(`${name}[`));
  const fieldErrors = errors?.filter(error => error.field!.startsWith(`${name}[`));
  const numbersTrough = Array.from(
    {
      length: vakioStyle ? 4 : 8,
    },
    (_, index) => index
  );

  const getFieldErrorMessage = (index: number) => {
    const error = fieldErrors?.find(fieldError => fieldError?.field?.startsWith(`${name}[${index}`));
    return error?.message;
  };

  return (
    <div
      id={`of-number-chain-row-${name}`}
      className={`of-number-chain__row ${vakioStyle ? '' : 'of-number-chain__row--special-number'}`}
      ref={edit && setRefCallback ? domRef => setRefCallback(name, domRef) : undefined}
    >
      {vakioStyle && (
        <div
          className={`of-number-chain__column of-number-chain__column--header ${
            edit ? 'of-number-chain__column--fullwidth' : ''
          }`}
        >
          {label}
        </div>
      )}
      {edit && genericErrors && genericErrors.length > 0 && (
        <div className="of-number-chain__column of-number-chain__column--header of-number-chain__generic-errors of-number-chain__column--fullwidth">
          {genericErrors.map(genericError => (
            <span className="of-number-chain__generic-error" key={genericError.message}>
              {genericError.message}
            </span>
          ))}
        </div>
      )}
      {numbersTrough.map(index => {
        return (
          <div
            id={`of-number-chain-column-${index}`}
            className={`of-number-chain__column ${edit ? 'of-number-chain__column--edit' : ''} ${
              vakioStyle ? '' : 'of-number-chain__column--special-number'
            }`}
            key={index}
          >
            {!edit && (
              <div
                className={`of-number-chain__mobile-title ${dsClass.COLOR_NEUTRAL_600} ${
                  vakioStyle ? '' : 'of-number-chain__mobile-title--special-number'
                }`}
              >
                {t.A6M3(callDestinationMsg)} {index + 1}
              </div>
            )}
            <div className="of-number-chain__mobile-edit">
              {edit ? (
                <>
                  <PhoneNumber
                    name={`${name}[${index}]`}
                    label={`${t.A6M3(callDestinationMsg)} ${index + 1}`}
                    required={false}
                  />
                  <div className={`of-error ${dsClass.TEXT_SMALL} ${dsClass.TEXT_COLOR_ERROR}`}>
                    {getFieldErrorMessage(index)}
                  </div>
                </>
              ) : (
                (numbers[index] && formatPhoneNumber(numbers[index])) || '—'
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export interface ReachabilityChainProps {
  label: string;
  name: string;
  numbers?: string[];
  editing?: boolean;
  errors?: CommonError[];
  setRefCallback?: (key: string, ref: HTMLElement | null) => void;
  pbxType?: SubscriptionPbxDetails.PbxTypeEnum;
}

export const ReachabilityChain = ({
  label,
  name,
  editing,
  errors,
  numbers,
  setRefCallback,
  pbxType,
}: ReachabilityChainProps) => {
  const vakioStyle =
    !pbxType ||
    pbxType === SubscriptionPbxDetails.PbxTypeEnum.TAVOITETTAVUUSKETJU ||
    pbxType === SubscriptionPbxDetails.PbxTypeEnum.VAKIO;
  return (
    <div className={`of-number-chain ${editing ? 'of-number-chain--editing' : ''}`}>
      {getNumberChainRow(
        label,
        name,
        numbers ?? [],
        editing,
        errors?.filter(error => error?.field?.startsWith(name)),
        setRefCallback,
        vakioStyle
      )}
    </div>
  );
};
