import * as CL from '@design-system/component-library';
import { PRODUCT_DETAILS_CLASS } from '../productDetailsConstants.js';
import { Picture } from '../../Picture/Picture.js';
import { getOfferPicture } from '../utils/productDetailsUtils.js';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import type { Offer } from '../../../generated/api/models.js';
import type { PictureConfig } from '../../Picture/Picture.js';

import './ProductDetailsPicture.scss';

export interface ProductDetailsPictureProps {
  pictures: PictureData[];
  offer: Offer;
}

export interface PictureData {
  id: string;
  name: string;
  src: string;
  offerIds?: string[];
}

export const PRODUCT_DETAILS_WIDTH_ALTERNATIVES: PictureConfig['offerWidthAlternatives'] = [45, 110, 275, 450];

export const ProductDetailsPictureComponent = (props: ProductDetailsPictureProps) => {
  const { pictures, offer } = props;
  const [activePicture, setActivePicture] = useState(getOfferPicture(pictures, offer) || pictures[0]);

  useEffect(() => {
    const picture = getOfferPicture(pictures, offer);
    if (picture) {
      setActivePicture(picture);
    }
  }, [pictures, offer]);

  return (
    <div className={`${PRODUCT_DETAILS_CLASS}-picture`}>
      <CL.Grid>
        <CL.GridRow>
          <CL.GridCol colWidthL={2} />
          <CL.GridCol colWidthL={2} colWidthS={1} colWidthXS={1}>
            {pictures.map(picture => {
              const isActive = activePicture.id === picture.id;
              return (
                <div
                  key={picture.id}
                  className={classNames(
                    `${PRODUCT_DETAILS_CLASS}-picture__side-container`,
                    isActive ? `${PRODUCT_DETAILS_CLASS}-picture__side-container--selected` : ''
                  )}
                >
                  <div className={`${PRODUCT_DETAILS_CLASS}-picture__side-container-picture`}>
                    <Picture
                      key={picture.id}
                      alt={picture.name}
                      src={picture.src}
                      offerWidthAlternatives={PRODUCT_DETAILS_WIDTH_ALTERNATIVES}
                      renderedImageSize={{
                        onPhone: 'calc(90vw * 0.1666 - 10px)',
                        onTablet: 'calc(90vw * 0.8333 * 0.9167 * 0.1666 - 10px)',
                        onLaptop: '120px',
                      }}
                      square
                      handlers={
                        !isActive
                          ? {
                              onMouseEnter: () => setActivePicture(picture),
                              onTouchEnd: () => setActivePicture(picture),
                            }
                          : {}
                      }
                    />
                  </div>
                </div>
              );
            })}
          </CL.GridCol>

          <CL.GridCol
            className={`${PRODUCT_DETAILS_CLASS}-picture__main-container`}
            colWidthL={8}
            colWidthS={5}
            colWidthXS={3}
          >
            <div className="square-container">
              <div className="square-container__wrapper">
                <Picture
                  key={activePicture.id}
                  alt={activePicture.name}
                  src={activePicture.src}
                  offerWidthAlternatives={[300, 450, 600, 750, 900, 1050, 1120]}
                  renderedImageSize={{
                    onPhone: '72vw',
                    onPhoneLarge: '70vw',
                    onTablet: 'calc(min(1400px, 100vw) * 0.9 * 0.9167 * 0.8333  - 20px)',
                    onLaptop: 'calc(min(1400px, 100vw) * 0.9 * 0.5833 * 0.6666)',
                  }}
                  square
                />
              </div>
            </div>
          </CL.GridCol>
        </CL.GridRow>
      </CL.Grid>
    </div>
  );
};

export const ProductDetailsPicture = (props: ProductDetailsPictureProps) => {
  if (!props.pictures.length) {
    return null;
  }

  return <ProductDetailsPictureComponent {...props} />;
};
