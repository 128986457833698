import * as CL from '@design-system/component-library';
import { ModalButtons } from './ModalButtons.js';
import { cancelMsg, submitMsg, t } from '../../common/i18n/index.js';

type ModalCloseAndSubmitButtonsProps = {
  cancelButtonText?: string;
  cancelDisabled?: boolean;
  onCancel?: () => void;
  onSubmit?: () => void;
  submitButtonText?: string;
  submitDisabled?: boolean;
  submitting?: boolean;
};

export const ModalCloseAndSubmitButtons = ({
  cancelButtonText,
  cancelDisabled,
  onCancel,
  onSubmit,
  submitDisabled,
  submitButtonText,
  submitting,
}: ModalCloseAndSubmitButtonsProps) => (
  <ModalButtons>
    {onCancel && (
      <CL.Button
        color={onSubmit ? 'link' : undefined}
        disabled={cancelDisabled || submitting}
        onClick={onCancel}
        size="l"
      >
        {cancelButtonText ?? t.B2V1(cancelMsg)}
      </CL.Button>
    )}
    {onSubmit && (
      <CL.Button disabled={submitDisabled} onClick={onSubmit} loading={submitting} size="l" type="submit">
        {submitButtonText ?? t.FSWU(submitMsg)}
      </CL.Button>
    )}
  </ModalButtons>
);
