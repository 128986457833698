import * as CL from '@design-system/component-library';
import { type ChangeEvent, type FocusEvent, useCallback, useMemo } from 'react';
import { type Context } from '../../OpenFormAnswers.js';
import { Input } from '../../../Input/Input.js';
import { OF } from '../../OpenForm.js';
import { OpenFormCheckbox } from '../../OpenFormComponents/OpenFormCheckbox.js';
import { type OpenFormDispatcher } from '../../OpenFormHooks/useOpenFormDispatcher.js';
import { OpenFormGridCol, OpenFormGridRow } from '../../OpenFormComponents/OpenFormGrid.js';
import { onBlurPrice, onChangePrice } from '../../OpenFormUtils.js';
import classNames from 'classnames';
import type { OpenFormChoice } from '../../../../generated/api/openFormChoice.js';
import type { OpenFormListColumn } from '../../../../generated/api/openFormListColumn.js';

export const OpenFormQuestionListOfObjects = ({
  dispatcher,
  choices,
  context,
  disabled,
  required,
  multiselect,
  headers,
  label,
}: {
  dispatcher: OpenFormDispatcher;
  choices: OpenFormChoice[];
  context: Context | undefined;
  disabled: boolean;
  required: boolean;
  multiselect: boolean;
  headers: OpenFormListColumn[];
  label: string;
}) => {
  const row = useMemo(() => context?.row, [context?.row]);
  const set = useMemo(() => new Set(context?.choices ?? []), [context?.choices]);

  // If there are both `name` and `description__c` columns, we'll render them in a special way.
  const hasNameAndDescription = headers.some(h => h.name === 'name') && headers.some(h => h.name === OF.DESCRIPTION);

  const isNameFieldWithDescription = useCallback(
    (header: OpenFormListColumn) => hasNameAndDescription && header.name === 'name',
    [hasNameAndDescription]
  );

  const getRowHeadersAndValues = useCallback(
    (values: string[]) => {
      const index = headers.findIndex(header => header.name === OF.DESCRIPTION);
      return {
        index: index,
        headers: hasNameAndDescription ? headers.filter(header => header.name !== OF.DESCRIPTION) : headers,
        values: hasNameAndDescription ? values.filter((_, idx) => idx !== index) : values,
      };
    },
    [headers, hasNameAndDescription]
  );

  const rowCells = useCallback(
    ({ guid, values }: OpenFormChoice) => {
      const visible = getRowHeadersAndValues(values);

      return visible.values.map((value, index) => {
        const { isReadOnly, name, prefix, suffix } = visible.headers[index] ?? {};

        if (isReadOnly ?? true) {
          return (
            <div className="of-openform__view__list-of-objects-table__cell-readonly" key={guid + index}>
              <div>{value}</div>
              {isNameFieldWithDescription(visible.headers[index]) ? <div>{values[visible.index]}</div> : null}
            </div>
          );
        }

        const onBlur = (e: FocusEvent<HTMLInputElement>) =>
          dispatcher.setContext('row', { ...row, [guid]: { ...row?.[guid], [name]: onBlurPrice(e.target.value) } });

        const onChange = (e: ChangeEvent<HTMLInputElement>) =>
          dispatcher.setContext('row', { ...row, [guid]: { ...row?.[guid], [name]: onChangePrice(e.target.value) } });

        return (
          <div key={guid + name} className="of-openform__view__list-of-objects-table__cell-editable">
            {!prefix ? null : prefix}
            <Input
              disabled={disabled}
              required={required}
              value={row?.[guid]?.[name] ?? value}
              size={8}
              type="text"
              autoComplete="off"
              inputMode="decimal"
              maxLength={8}
              onBlur={onBlur}
              onChange={onChange}
              onClick={e => set.has(guid) && e.stopPropagation()}
            />
            {!suffix ? null : suffix}
          </div>
        );
      });
    },
    [dispatcher, disabled, required, row, set, isNameFieldWithDescription, getRowHeadersAndValues]
  );

  const rowClick = useCallback(
    ({ guid, values }: OpenFormChoice) => {
      switch (true) {
        case disabled: {
          return;
        }
        case set.has(guid): {
          set.delete(guid);
          return dispatcher.setChoices(Array.from(set));
        }
        case !!headers?.length: {
          !multiselect && set.clear();
          set.add(guid);
          return dispatcher.setAnswer(Array.from(set), 'row', {
            ...row,
            [guid]: values.reduce(
              (acc, value, i) => (headers[i]?.name && { [headers[i].name]: value, ...acc }) || acc,
              { ...row?.[guid] }
            ),
          });
        }
      }
    },
    [dispatcher, disabled, headers, multiselect, row, set]
  );

  return !choices.length ? null : (
    <OpenFormGridRow>
      <OpenFormGridCol colWidth={9} className={classNames({ ['label--mandatory']: required })}>
        <div className="of-openform__view__list-of-objects-labelarea">
          <label>{label}</label>
        </div>
        <CL.Table
          showHeaders={true}
          valignText="middle"
          className="of-openform__view__list-of-objects-table"
          tableType="bordered"
          hover={true}
          columns={[
            { align: 'center', key: 'checkbox', label: null },
            ...headers
              .filter(header => !hasNameAndDescription || header.name !== OF.DESCRIPTION)
              .map((header, index) => ({
                align: (!index ? 'left' : 'right') as 'left' | 'center' | 'right',
                key: String(index),
                label: (header.label ?? null) as string | null,
              })),
          ]}
          rows={choices.map(choice => ({
            active: set.has(choice.guid),
            checkbox: <OpenFormCheckbox value={choice.guid} checked={set.has(choice.guid)} disabled={disabled} />,
            ...Object.fromEntries(Object.entries(rowCells(choice))),
          }))}
          rowClicks={choices.map(choice => () => rowClick(choice))}
        />
      </OpenFormGridCol>
    </OpenFormGridRow>
  );
};
