import * as CL from '@design-system/component-library';
import { dsClass } from '../../../common/constants/dsClasses.js';
import type { FieldInputProps, FormikComputedProps, FormikHelpers, FormikState, FormikValues } from 'formik';

export interface DropdownSearchComponentProps {
  className?: string;
  form: FormikState<FormikValues> & FormikHelpers<FormikValues> & FormikComputedProps<FormikValues>;
  field: FieldInputProps<string>;
  items: CL.ComboboxItem[];
  selectedValue: string;
  valueField: string;
  tooltipText?: string;
  labelText: string;
  placeHolderText?: string;
  onValueChange: (event: HTMLLIElement) => void;
  onSearch: (newValue: string) => void;
  optional?: boolean;
  onBlur?: (errorMsg?: string) => void;
  labelClass?: string;
}

export const DropdownSearchComponent = ({
  className,
  items,
  selectedValue,
  tooltipText,
  labelText,
  onValueChange,
  form,
  onSearch,
  optional,
  field,
  labelClass,
  placeHolderText,
}: DropdownSearchComponentProps) => {
  const { name } = field;
  const fieldError = form.errors[name];

  return (
    <>
      <div className={dsClass.INPUT_LABELAREA}>
        <label className={`${dsClass.INPUT_LABELAREA_LABEL} ${labelClass}`} htmlFor={name}>
          {labelText}
        </label>
        {tooltipText && (
          <span className={`${dsClass.PADDING_LEFT_2} of-formik__label tooltip`}>
            <CL.Tooltip
              triggerElement={<CL.Icon color="brand-blue" icon="information" size="s" />}
              placement="top"
              i18n_tooltip_contentText={tooltipText}
              className={dsClass.MARGIN_LEFT_3}
            />
          </span>
        )}
      </div>
      <CL.Combobox
        name={name}
        className={className}
        id={name}
        items={items}
        onValueChange={onSearch}
        onValueSelect={onValueChange}
        selectedValue={selectedValue}
        i18n_combobox_buttonAriaLabel={labelText}
        i18n_combobox_errorMessage={fieldError?.toString()}
        i18n_combobox_placeholderText={placeHolderText}
        optional={optional}
      />
    </>
  );
};
