import * as CL from '@design-system/component-library';
import { BlankHeading } from '../BlankHeading/BlankHeading.js';
import { BreadCrumbsWithTitle } from '../BreadCrumbsWithTitle/BreadCrumbsWithTitle.js';
import { Grid } from '../Grid/Grid.js';
import { MfaAlreadyEnabledNotification, SupportCaseNotification } from './common.js';
import { MfaEnableConfirmation } from './MfaEnableConfirmation/MfaEnableConfirmation.js';
import { ReviewImpactedUsersAndSubmit } from './ReviewImpactedUsersAndSubmit/ReviewImpactedUsersAndSubmit.js';
import { dsClass } from '../../common/constants/dsClasses';
import { fetchSaUsers } from '../../common/fetch.js';
import { isMfaAlreadyEnabled, isMfaManagementAllowed } from '../../common/utils/mfaUtils.js';
import {
  mfaEnableAllCompaniesInSaMsg,
  mfaEnableOECUsersMsg,
  omaElisaForCompaniesMsg,
  settingsMsg,
  t,
} from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useEffect, useState } from 'react';
import { useRouteLoaderData } from 'react-router-dom';
import type { AccountKeyUser } from '../../generated/api/accountKeyUser.js';
import type { MfaDetailsResponse } from '../../generated/api/mfaDetailsResponse.js';

const getSa = (mfaDetails: MfaDetailsResponse) => mfaDetails.saIds[0];
export const MfaSelfService = () => {
  const mfaDetails = useRouteLoaderData('settingsRoot') as MfaDetailsResponse;
  const [users, setUsers] = useState<Array<AccountKeyUser> | undefined>(undefined);
  // Item that the user is currently saving (for save confirmation modal)
  const [confirmEnable, setConfirmEnable] = useState<boolean>(false);

  useEffect(() => {
    if (isMfaManagementAllowed(mfaDetails)) {
      fetchSaUsers(getSa(mfaDetails)).then(res => setUsers(res.accountKeyUsers || []));
    }
  }, [mfaDetails]);

  const getContent = (): JSX.Element => {
    if (isMfaManagementAllowed(mfaDetails)) {
      if (!users) {
        return <CL.LoadingSpinner size="xl" />;
      }
      return (
        <div>
          <div className={`ds-h3 ${dsClass.MARGIN_BOTTOM_3}`}>{t.LG40('Confirm administrators details')}</div>
          <span>{t.QGS0(mfaEnableOECUsersMsg)}</span>
          <ReviewImpactedUsersAndSubmit accountKeyUsers={users || []} onSubmit={() => setConfirmEnable(true)} />
        </div>
      );
    } else if (isMfaAlreadyEnabled(mfaDetails)) {
      return <MfaAlreadyEnabledNotification />;
    } else {
      return <SupportCaseNotification />;
    }
  };

  return (
    <>
      <BlankHeading
        detailsTop={
          <BreadCrumbsWithTitle
            breadCrumbPaths={[
              { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
              { name: t.QI2P(settingsMsg), path: paths.COMPANY_INFO_SETTINGS },
              { name: t.QQFY('Two-factor authentication') },
            ]}
          />
        }
        title={t.QQFY('Two-factor authentication')}
      />
      <Grid>
        <p className={dsClass.MARGIN_VERTICAL_6}>{t.A30I(mfaEnableAllCompaniesInSaMsg)}</p>
        {getContent()}
      </Grid>
      {confirmEnable && <MfaEnableConfirmation onCancel={() => setConfirmEnable(false)} saId={getSa(mfaDetails)} />}
    </>
  );
};
