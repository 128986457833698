import { ActionPhase } from '../common/storeUtils.js';
import { CardPaymentResponseStatusText } from '../../components/DeviceCheckoutCardPayment/DeviceCheckoutCardPayment.js';
import { TypeKeys } from '../actions/index.js';
import { getErrorsFromCheckout } from '../../common/utils/checkoutUtils.js';
import { getErrorsFromUpdateUserDetails } from '../../components/UserDetails/UserDetails.js';
import { getErrorsFromUpsertBillingAccount } from '../../common/utils/billingAccountUtils.js';
import { getErrorsFromUpsertDeliveryAddress } from '../../components/OrderDeliveryOptions/OrderDeliveryOptions.js';
import { reduceCrudAction } from './reducerUtils.js';
import { updateActionStatePhase } from '../common/index.js';
import type { ActionsHistory } from '../common/store.js';
import type { OnlineOrderState } from '../../common/types/states.js';
import type { SelfServiceActionTypes } from '../actions/index.js';

export function onlineOrderReducer(
  state: (ActionsHistory & OnlineOrderState) | undefined | null,
  action: SelfServiceActionTypes
): (OnlineOrderState & ActionsHistory) | null {
  if (typeof state === 'undefined') {
    return null;
  }

  switch (action.type) {
    case TypeKeys.SUBMIT_ONLINE_ORDER: {
      if (action.overridePreviousAction) {
        const actions = updateActionStatePhase(
          TypeKeys.SUBMIT_ONLINE_ORDER,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        );

        return {
          actions: actions
            ? [
                ...actions,
                {
                  phase: ActionPhase.IN_PROGRESS,
                  value: action,
                },
              ]
            : undefined,
          saving: true,
        };
      }

      if (action.contactPerson) {
        const contactPersonErrors = getErrorsFromUpdateUserDetails(
          action.contactPerson,
          action.customerType,
          action.contactPersonValidationErrors
        );
        if (contactPersonErrors) {
          return {
            ...state,
            saving: false,
            errors: contactPersonErrors,
          };
        }
      }

      const deliveryAddressErrors = getErrorsFromUpsertDeliveryAddress(
        action.deliveryAddress,
        action.isCompanyNameRequired,
        action.deliveryAddressValidationErrors
      );
      const deviceCheckoutErrors = getErrorsFromCheckout(
        action.approverContact,
        action.personBillingAddress,
        action.personBillingEmail
      );

      let billingAccountHasErrors = false;
      if (action.newBillingAccount) {
        billingAccountHasErrors = !!getErrorsFromUpsertBillingAccount(
          action.newBillingAccount,
          action.newBillingAccountValidationErrors,
          action.newBillingAccountCommonFunction
        );
      }
      if (deviceCheckoutErrors) {
        return {
          ...state,
          saving: false,
        };
      }
      if (billingAccountHasErrors || deliveryAddressErrors) {
        if (deliveryAddressErrors) {
          return {
            ...state,
            errors: deliveryAddressErrors,
            saving: false,
          };
        }
        return {
          ...state,
          errors: undefined,
          saving: false,
        };
      } else {
        return {
          ...reduceCrudAction(action, state),
          saving: true,
        };
      }
    }

    case TypeKeys.SUBMIT_ONLINE_ORDER_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.SUBMIT_ONLINE_ORDER,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        saving: false,
      };
    }

    case TypeKeys.SUBMIT_ONLINE_ORDER_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.SUBMIT_ONLINE_ORDER,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        saving: false,
      };
    }

    case TypeKeys.POLL_FOR_CREDIT_CHECK: {
      return {
        ...reduceCrudAction(action, state),
        saving: true,
      };
    }

    case TypeKeys.POLL_FOR_CREDIT_CHECK_FULFILLED:
    case TypeKeys.POLL_FOR_CREDIT_CHECK_V2_FULFILLED: {
      const actions = updateActionStatePhase(
        TypeKeys.POLL_FOR_CREDIT_CHECK,
        state!,
        ActionPhase.IN_PROGRESS,
        ActionPhase.SUCCESS
      );

      return {
        ...state,
        actions,
        saving: false,
      };
    }

    case TypeKeys.POLL_FOR_CREDIT_CHECK_FAILED: {
      // Cannot know the state of the credit check -> cannot know if the order is processed or not
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.POLL_FOR_CREDIT_CHECK,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        saving: false,
      };
    }

    case TypeKeys.INITIALIZE_ONLINE_ORDER_CARD_PAYMENT: {
      if (action.overridePreviousAction) {
        const actions = updateActionStatePhase(
          TypeKeys.INITIALIZE_ONLINE_ORDER_CARD_PAYMENT,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        );

        return {
          actions: actions
            ? [
                ...actions,
                {
                  phase: ActionPhase.IN_PROGRESS,
                  value: action,
                },
              ]
            : undefined,
          saving: true,
        };
      }
      return {
        ...reduceCrudAction(action, state),
        saving: true,
      };
    }

    case TypeKeys.INITIALIZE_ONLINE_ORDER_CARD_PAYMENT_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.INITIALIZE_ONLINE_ORDER_CARD_PAYMENT,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        saving: false,
      };
    }

    case TypeKeys.INITIALIZE_ONLINE_ORDER_CARD_PAYMENT_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.INITIALIZE_ONLINE_ORDER_CARD_PAYMENT,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        saving: false,
      };
    }

    case TypeKeys.FINALIZE_ONLINE_ORDER_CARD_PAYMENT: {
      return {
        ...reduceCrudAction(action, state),
        saving: action.responseStatusText === CardPaymentResponseStatusText.OK,
      };
    }

    case TypeKeys.FINALIZE_ONLINE_ORDER_CARD_PAYMENT_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.FINALIZE_ONLINE_ORDER_CARD_PAYMENT,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        saving: false,
      };
    }

    case TypeKeys.FINALIZE_ONLINE_ORDER_CARD_PAYMENT_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.FINALIZE_ONLINE_ORDER_CARD_PAYMENT,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        saving: false,
      };
    }

    default:
      return state;
  }
}
