import * as React from 'react';
import { paths } from '../../common/constants/pathVariables.js';
import { t } from '../../common/i18n/index.js';
import { useNavigate } from 'react-router-dom';
import type { PageSearchResponse } from '../../generated/api/models.js';

import './PublicPagesSearchMetadata.scss';

export interface MetaProps {
  searchPageResult: PageSearchResponse;
  usedQueryString: string;
}

export const PublicPagesSearchMetadata = ({ usedQueryString, searchPageResult }: MetaProps) => {
  const navigate = useNavigate();
  const correctedQuery = searchPageResult.spelling?.correctedQuery;
  return (
    <div className="of-public-pages-search-metadata" role="status">
      <div>
        {t.O4R1('Using the keyword')} {`"${usedQueryString}"`} {t.FXKU('resulted in')} {searchPageResult.total}{' '}
        {t.V2S1('hits.')}
      </div>
      {correctedQuery && (
        <div className="of-public-pages-search-metadata-suggestion">
          {t.L0B1('Did you mean:')}{' '}
          <a
            href={`${paths.SEARCH_PUBLIC_PAGES}?q=${correctedQuery}`}
            onClick={(event: React.SyntheticEvent) => {
              event.preventDefault();
              navigate(`${paths.SEARCH_PUBLIC_PAGES}?q=${correctedQuery}`, { replace: true });
            }}
          >
            {correctedQuery}
          </a>
        </div>
      )}
    </div>
  );
};
