import * as CL from '@design-system/component-library';
import { dsClass } from '../../common/constants/dsClasses.js';
import type { ReactNode } from 'react';

type FaqAccordionProps = {
  heading: string;
  id?: string;
  items: {
    content: ReactNode;
    heading: string;
  }[];
};

// TODO use new CMS component when available
export const FaqAccordion = ({ heading, id, items }: FaqAccordionProps): JSX.Element => (
  <section aria-label={heading} id={id}>
    <div className={dsClass.TEXT_ALIGN_LEFT} itemScope itemType="https://schema.org/FAQPage">
      <h2 className={dsClass.H3}>{heading}</h2>
      <CL.Accordion headingLevel="h3">
        {items.map(item => (
          <CL.AccordionItem heading={item.heading} key={item.heading}>
            <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
              <meta itemProp="name" content={item.heading} />
              <div itemProp="acceptedAnswer" itemScope itemType="https://schema.org/Answer">
                <div itemProp="text">{item.content}</div>
              </div>
            </div>
          </CL.AccordionItem>
        ))}
      </CL.Accordion>
    </div>
  </section>
);
