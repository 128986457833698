import { TypeKeys } from './actionsUtils.js';
import type { DialogParams } from '../../common/types/dialog.js';

export interface ShowDialogAction {
  params: DialogParams;
  type: TypeKeys.SHOW_DIALOG;
}

export const showDialog = (params: DialogParams): ShowDialogAction => ({
  params,
  type: TypeKeys.SHOW_DIALOG,
});

export interface CloseDialogAction {
  type: TypeKeys.CLOSE_DIALOG;
}

export const closeDialog = (): CloseDialogAction => ({
  type: TypeKeys.CLOSE_DIALOG,
});

export type DialogActionTypes = ShowDialogAction | CloseDialogAction;
