import { OF, hasFieldApiName } from '../../OpenForm.js';
import { OpenFormGridCol, OpenFormGridRow } from '../../OpenFormComponents/OpenFormGrid.js';
import { OpenFormItem } from '../../OpenFormComponents/OpenFormItem.js';
import { type OpenFormSummary } from '../../OpenFormHooks/useOpenFormSummary.js';
import { contactsMsg, t } from '../../../../common/i18n/index.js';

export const OpenFormSummaryOtherContacts = ({ summary }: { summary?: OpenFormSummary }) => {
  const contacts = summary?.contactEntries?.filter(([question]) => !hasFieldApiName(OF.ORDERING_CONTACT)(question));
  return !contacts?.length ? null : (
    <OpenFormGridRow>
      <OpenFormGridCol colWidth={9}>
        <h4>{t.WA1M(contactsMsg)}</h4>
        <OpenFormGridRow justifyCenter={false} className="of-openform__view__summary__other-contacts">
          {contacts.map(([question, contact]) => (
            <OpenFormGridCol colWidth={4} key={question.guid + contact.contactId}>
              <OpenFormItem title={question.label}>
                <div>
                  {contact.firstName} {contact.lastName}
                </div>
                <div>{contact.email}</div>
                <div>{contact.phoneNumber}</div>
              </OpenFormItem>
            </OpenFormGridCol>
          ))}
        </OpenFormGridRow>
        <hr />
      </OpenFormGridCol>
    </OpenFormGridRow>
  );
};
