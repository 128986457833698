import * as CL from '@design-system/component-library';
import { CheckboxTree } from '../CheckboxTree/ChecboxTree';
import { CompanyInfoResponse } from '../../generated/api/companyInfoResponse';
import { FormProvider, useForm } from 'react-hook-form';
import { StatusColumn, Table } from '../Table';
import { cancelMsg, companyMsg, confirmMsg, editMsg, inUseMsg, notInUseMsg, t } from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses';
import { startNotification } from '../../selfservice/actions';
import { updateCompanyInfo } from '../../common/fetch';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import type { CompanyInfoLoaderData } from '../../common/loaders';

import './CompanySettings.scss';

export interface BlockInvoiceDueReminderProps {
  companyInfos: CompanyInfoLoaderData[];
}

const isBlockInvoiceDueReminderSettingVisible = (salesChannel?: CompanyInfoResponse.SalesChannelEnum) =>
  salesChannel &&
  [
    CompanyInfoResponse.SalesChannelEnum.EDUSTAJA_YA,
    CompanyInfoResponse.SalesChannelEnum.TELESALES,
    CompanyInfoResponse.SalesChannelEnum.YRITYSGURU,
    CompanyInfoResponse.SalesChannelEnum.YRITYSPISTE,
  ].includes(salesChannel);

export const anyCompanyHasSmsReminderSettingVisible = (companyInfos?: CompanyInfoLoaderData[]) => {
  return companyInfos?.find(companyInfo => isBlockInvoiceDueReminderSettingVisible(companyInfo?.salesChannel));
};

export interface SmsReminderSettingFormValues {
  selectedCompanies: string[];
}

export type SmsReminderSettingFormProps = SmsReminderSettingFormValues & {
  companyInfos: CompanyInfoLoaderData[];
  onSubmit: (formValues: SmsReminderSettingFormValues) => void;
  setIsEdit: (isEdit: boolean) => void;
};

export const SmsReminderSettingForm = ({
  setIsEdit,
  onSubmit,
  selectedCompanies,
  companyInfos,
}: SmsReminderSettingFormProps) => {
  const methods = useForm<SmsReminderSettingFormValues>({
    mode: 'all',
    defaultValues: {
      selectedCompanies: selectedCompanies,
    },
  });

  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = methods;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <CheckboxTree
          data={companyInfos.map(companyInfo => ({
            label: companyInfo.companyName,
            value: companyInfo.mdmId,
          }))}
          onChange={(selectedValues: string[]) => setValue('selectedCompanies', selectedValues)}
          initialValues={selectedCompanies}
        />
        <div className={dsClass.MARGIN_TOP_5}>
          <CL.Button type="submit" loading={isSubmitting}>
            {t.QVYK(confirmMsg)}
          </CL.Button>
          <CL.Button
            color="link"
            onClick={() => setIsEdit(false)}
            disabled={isSubmitting}
            className={dsClass.MARGIN_LEFT_2}
          >
            {t.B2V1(cancelMsg)}
          </CL.Button>
        </div>
      </form>
    </FormProvider>
  );
};

export interface SMsReminderSettingViewProps {
  companyInfos: CompanyInfoLoaderData[];
  setIsEdit: (isEdit: boolean) => void;
}

export const SMsReminderSettingView = ({ companyInfos, setIsEdit }: SMsReminderSettingViewProps) => {
  const columns = [
    {
      key: 'company',
      label: t.KJTS(companyMsg),
      sortable: false,
    },
    {
      key: 'status',
      label: t.UNK4('Status'),
      sortable: false,
    },
  ];

  const rows = companyInfos
    ?.filter(companyInfo => isBlockInvoiceDueReminderSettingVisible(companyInfo?.salesChannel))
    .map(companyInfo => ({
      company: companyInfo.companyName,
      status: (
        <StatusColumn
          color={companyInfo.blockInvoiceDueSmsReminder ? 'green-600' : 'neutral-400'}
          status={companyInfo.blockInvoiceDueSmsReminder ? t.V34H(inUseMsg) : t.MUF5(notInUseMsg)}
        />
      ),
    }));

  if (!rows) {
    return null;
  }

  return (
    <>
      <p>
        {t.KOL4(
          `If you no longer wish to receive reminders about unpaid invoices, you can block SMS reminder. Blocking applies to all billing accounts.`
        )}
      </p>
      <Table columns={columns} rows={rows} />
      <CL.Button color="light" onClick={() => setIsEdit(true)} className={dsClass.MARGIN_TOP_5}>
        {t.NVPK(editMsg)}
      </CL.Button>
    </>
  );
};

export const InvoiceDueSmsReminderSetting = ({ companyInfos }: BlockInvoiceDueReminderProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);

  const handleSubmit = async ({ selectedCompanies }: SmsReminderSettingFormValues) => {
    await Promise.all(
      companyInfos
        .map(companyInfo => ({
          selected: selectedCompanies.includes(companyInfo.mdmId),
          mdmId: companyInfo.mdmId,
        }))
        .map(companySelection =>
          updateCompanyInfo({ blockInvoiceDueSmsReminder: companySelection.selected }, companySelection.mdmId)
        )
    );
    setIsEdit(false);
    navigate('');
    dispatch(startNotification(t.FFJ2('Changes were successfully saved')));
  };

  return isEdit ? (
    <SmsReminderSettingForm
      selectedCompanies={companyInfos
        .filter(companyInfo => companyInfo.blockInvoiceDueSmsReminder === true)
        .map(companyInfo => companyInfo.mdmId)}
      companyInfos={companyInfos}
      onSubmit={handleSubmit}
      setIsEdit={setIsEdit}
    />
  ) : (
    <SMsReminderSettingView companyInfos={companyInfos} setIsEdit={setIsEdit} />
  );
};
