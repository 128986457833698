import { ActionPhase } from '../common/storeUtils.js';
import { CustomerOrderStatus, PostReviewRequest } from '../../generated/api/models.js';
import { TypeKeys } from '../actions/index.js';
import { addToActionHistory, updateActionStatePhase } from '../common/index.js';
import {
  buildHeaderOnlyItemsQuery,
  getItemsQueryForReports,
  mergeArrays,
  reduceCrudAction,
  reduceDisplayItemsLoadAction,
} from './reducerUtils.js';
import { loadCustomerOrdersUseSearchService } from '../common/elasticsearchUtils.js';
import { sortArray } from '../../common/utils/arrayUtils.js';
import type { ActionsHistory, ItemsQuery } from '../common/store.js';
import type { CustomerOrder } from '../../generated/api/models.js';
import type { CustomerOrdersState } from '../../common/types/states.js';
import type { SelfServiceActionTypes } from '../actions/index.js';

export function customerOrdersReducer(
  state: (CustomerOrdersState & ActionsHistory) | null,
  action: SelfServiceActionTypes
): (CustomerOrdersState & ActionsHistory) | null {
  if (typeof state === 'undefined') {
    return null;
  }

  switch (action.type) {
    case TypeKeys.LOAD_CUSTOMER_ORDERS: {
      if (loadCustomerOrdersUseSearchService(action)) {
        const query: ItemsQuery | undefined = action.reporting
          ? getItemsQueryForReports()
          : buildHeaderOnlyItemsQuery(action, state, state?.total, state?.search, state?.status);

        return {
          ...state,
          actions: query
            ? addToActionHistory(state, {
                phase: ActionPhase.IN_PROGRESS,
                query,
                value: action,
              })
            : state?.actions,
          filter: action.filter,
          search: query?.search,
          sort:
            query?.sort && query?.order
              ? {
                  columnId: query.sort,
                  order: query.order,
                }
              : state?.sort,
          status: query?.status,
          loading: true,
        };
      }
      const getAllItems = !!action.filter || !!action.sort || !!action.search || action.reporting;
      return {
        ...reduceDisplayItemsLoadAction<TypeKeys.LOAD_CUSTOMER_ORDERS, CustomerOrder>(
          action,
          state,
          'customerOrderDisplayId',
          getAllItems,
          action.reporting && action.forceLoad ? 0 : undefined,
          action.forceLoad
        ),
        items: action.forceLoad ? undefined : state ? state.items : undefined,
        total: action.forceLoad ? undefined : state ? state.total : undefined,
      };
    }

    case TypeKeys.LOAD_CUSTOMER_ORDERS_FAILED: {
      return {
        ...state,
        loading: false,
        actions: updateActionStatePhase(
          TypeKeys.LOAD_CUSTOMER_ORDERS,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
      };
    }

    case TypeKeys.LOAD_CUSTOMER_ORDERS_FULFILLED: {
      return {
        ...state,
        loading: false,
        total: action.total,
        resultCountAggregations: action.resultCountAggregations,
        searchResults: action.searchResults,
        items: sortArray(
          mergeArrays<CustomerOrder>('customerOrderId', 'lastModified', state!.items, action.customerOrders),
          'created',
          'desc'
        ),
        actions: updateActionStatePhase(
          TypeKeys.LOAD_CUSTOMER_ORDERS,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
      };
    }

    case TypeKeys.LOAD_CUSTOMER_ORDER_ADDITIONAL_INFO: {
      return {
        ...state,
        ...reduceCrudAction(action, state),
      };
    }

    case TypeKeys.LOAD_CUSTOMER_ORDER_ADDITIONAL_INFO_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.LOAD_CUSTOMER_ORDER_ADDITIONAL_INFO,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
      };
    }

    case TypeKeys.LOAD_CUSTOMER_ORDER_ADDITIONAL_INFO_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.LOAD_CUSTOMER_ORDER_ADDITIONAL_INFO,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        additionalInfo: {
          ...state?.additionalInfo,
          [action.customerOrderId]: action.additionalInfo,
        },
      };
    }

    case TypeKeys.REVIEW_FULFILLED: {
      if (action.reviewType === PostReviewRequest.ReviewTypeEnum.ONLINE_ORDER) {
        // This is needed as order status change does not reflect immediately in elasticsearch,
        // SF platform event triggers elastic updates, which takes some more minutes to update elasticsearch
        const updatedState = { ...state };
        const searchResults = updatedState?.searchResults || [];
        for (const res of searchResults) {
          if (res.result.customerOrderDisplayId === action.customerOrderDisplayId) {
            res.result.status = CustomerOrderStatus.PENDING;
            break;
          }
        }
        updatedState.items = undefined;
        return updatedState;
      }
      // This is done to re-call the customer-orders
      return null;
    }

    case TypeKeys.LOG_OUT: {
      return null;
    }

    case TypeKeys.RESET_ERRORS: {
      return {
        ...state,
        errors: undefined,
      };
    }

    default:
      return state;
  }
}
