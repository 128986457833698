/**
 * SFDC Community API
 * # Summary Elisa B2B API for an administrator of a company to query and modify the company information.  # Usage This API is used in the `https://yrityksille.elisa.fi/omaelisa` pages, but it is possible to later on expose this documentation publicly and make the endpoint queryable via external clients.  # Identifiers All entities in the API have a globally unique, internal identifier field named with the format [object_type]Id, e.g. `invoiceId`, which is also used in some of the API endpoint urls, e.g. `/api/v1/selfservice/invoices/{invoiceId}`. This value is not meaningful to the end user but is only used as an internally in the API.  Most entities also have a displayable identifier named in the format [object_type]DisplayId. This value can and should be shown to the end user, as it is also printed in relevant documents. It is also used in some of the public URLs, e.g. `https://yrityksille.elisa.fi/omaelisa/laskut/{invoiceDisplayId}`. Because of the user might land directly into a URL of a single entity with a display id, some of the API endpoints here also support querying via the display id, e.g. `/api/v1/selfservice/invoices?invoiceDisplayId={invoiceDisplayId}`.  The reason for using two identifiers instead of only one display id, is to make sure that every object will always have exactly one REST API URL that will never collide with another. While collisions of display ids should not happen in production, the id fields are guaranteed to be unique even across different test environments. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EppModificationPrices } from './eppModificationPrices.js';
import { OmaLaiteLaskuPrices } from './omaLaiteLaskuPrices.js';
import { EppDeviceStatusType } from './eppDeviceStatusType.js';
import { EnrollmentProgram } from './enrollmentProgram.js';


export interface SubscriptionDetailsDevice { 
    /**
     * Serial number (IMEI or other) of the device to be delivered. SFDC: csord__Subscription.Serial_Number__c.
     */
    serialNumber?: string;
    /**
     * True if mentioned SFDC field\'s value is \'EPP Device\'. SFDC: cspmb__Price_Item__c.Sub_Type__c.
     */
    eppSubscription: boolean;
    eppModificationPrices?: EppModificationPrices;
    /**
     * Indicates whether this device subscription has OmaLaiteLasku service or not
     */
    omaLaiteLasku?: boolean;
    omaLaiteLaskuPrices?: OmaLaiteLaskuPrices;
    /**
     * Image of device
     */
    image?: string;
    enrollmentProgram?: EnrollmentProgram;
    eppDeviceStatus?: EppDeviceStatusType;
    /**
     * The action user selected at checkout for this old EPP device
     */
    replacementAction?: SubscriptionDetailsDevice.ReplacementActionEnum;
}
export namespace SubscriptionDetailsDevice {
    export type ReplacementActionEnum = 'NONE' | 'REDEEM' | 'RETURN_TO_ELISA';
    export const ReplacementActionEnum = {
        NONE: 'NONE' as ReplacementActionEnum,
        REDEEM: 'REDEEM' as ReplacementActionEnum,
        RETURN_TO_ELISA: 'RETURN_TO_ELISA' as ReplacementActionEnum
    };
}


