import * as CL from '@design-system/component-library';
import { dsClass } from '../../common/constants/dsClasses.js';
import { t } from '../../common/i18n/index.js';

import './ApproveOrDeny.scss';

export interface ApproveOrDenyAttrs {
  isSaving?: boolean;
  approveButtonLabel?: string;
  denyButtonLabel?: string;
  disabled?: boolean;
}

export interface ApproveOrDenyDispatchers {
  onApprove: () => void;
  onDeny: () => void;
}

export type ApproveOrDenyProps = ApproveOrDenyAttrs & ApproveOrDenyDispatchers;

export const ApproveOrDeny = ({
  isSaving,
  approveButtonLabel,
  denyButtonLabel,
  onApprove,
  onDeny,
  disabled = false,
}: ApproveOrDenyProps) => {
  return (
    <div className="of-approve-or-deny">
      <CL.Button id="approve-order-button" size="l" disabled={disabled || isSaving || false} onClick={onApprove}>
        {approveButtonLabel || t.P5RQ('Accept')}
      </CL.Button>
      <CL.Button
        color="light"
        size="l"
        id="reject-order-button"
        className={dsClass.MARGIN_RIGHT_3}
        disabled={isSaving || false}
        onClick={onDeny}
      >
        {denyButtonLabel || t.ONSX('Reject')}
      </CL.Button>
    </div>
  );
};
