import { DialogWrapper } from '../DialogWrapper/index.js';
import { employeeOmaElisaMsg, logOutMsg, t } from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useAuth } from '../../public/site/AuthProvider.js';

import './EmployeeForbiddenDialog.scss';

export const EmployeeForbiddenDialog = () => {
  const { logout } = useAuth();

  return (
    <DialogWrapper
      header={t.LQ3X('Confirm logout')}
      onCloseDialog={() => {}}
      buttons={[{ onClick: () => logout(), text: t.LQ3X(logOutMsg) }]}
    >
      <p>
        {t.BDQD(
          'Employee access to Corporate Customers OmaElisa is denied. If you want to manage your company subscriptions or other services, you can do it in'
        )}
        <a href={paths.EMPLOYEE_HOME}>{` ${t.ZPZR(employeeOmaElisaMsg)}`}</a>.
      </p>
      <p>
        {t.WN51('If you are an administrator of the company, sign out and log in to Corporate Customers OmaElisa.')}
      </p>
    </DialogWrapper>
  );
};
