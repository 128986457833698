import { Dropdown } from '../../../../components/Dropdown/Dropdown.js';
import { Field, useFormikContext } from 'formik';
import { billingAccountInvoiceTypes } from '../../../../common/utils/billingAccountUtils.js';
import { invoiceTypeMsg, selectMsg, t } from '../../../i18n/index.js';
import { requiredValidation } from '../../validation.js';
import type * as CLT from '@design-system/component-library';
import type { Address } from 'cluster';
import type { BillingAccount } from '../../../../generated/api/billingAccount.js';
import type { BillingAccountStatus } from '../../../../generated/api/billingAccountStatus.js';

export interface BillingAccountFormValues {
  billingAccountId?: string;
  billingAccountName?: string;
  billingAccountStatus?: BillingAccountStatus;
  billingAccountExtensionName?: string;
  accountBalance?: number;
  payerName?: string;
  payerNameExtension?: string;
  payerBusinessId?: string;
  payerAddress?: Address;
  billingContactId?: string;
  billFormatType?: BillingAccount.BillFormatTypeEnum;
  billingContactName?: string;
}
export const BillingFormatDropdown = () => {
  const { setFieldValue, values, errors } = useFormikContext<BillingAccountFormValues>();

  const billFormatTypeFieldName = 'billFormatType';

  const billingAccountInvoiceTypeOptions: CLT.DropDownItem[] = billingAccountInvoiceTypes.map(type => ({
    label: type.displayValue(),
    value: type.value,
  }));

  const setBillingFormatType = (evt: HTMLLIElement) => {
    const newValue = evt.dataset.value;
    if (newValue) {
      setFieldValue(billFormatTypeFieldName, newValue);
    }
  };

  return (
    <Field
      name={billFormatTypeFieldName}
      id={billFormatTypeFieldName}
      label={t.UW4H(invoiceTypeMsg)}
      items={billingAccountInvoiceTypeOptions}
      selectedValue={values.billFormatType}
      onValueChange={setBillingFormatType}
      validate={requiredValidation}
      i18n_dropdown_placeholderText={t.QRYV(selectMsg)}
      component={Dropdown}
      i18n_dropdown_errorMessage={errors.billFormatType}
    />
  );
};
