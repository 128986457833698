import { PunchoutContentBlockWrapper } from '../../../../components/Punchout/PunchoutContentBlockWrapper.js';
import { getPublicPage } from '../../../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import type { Category } from '../../../../common/types/category.js';
import type { DeviceName } from '../../../../common/constants/pathInterfaces.js';

interface PunchoutStoreProductProps {
  category: Category;
}

export const PunchoutStoreProduct = ({ category }: PunchoutStoreProductProps) => {
  const dispatch = useDispatch();
  const { deviceName } = useParams<DeviceName>();
  useEffect(() => {
    dispatch(getPublicPage(`/${deviceName}`));
  }, [dispatch, deviceName]);
  return <PunchoutContentBlockWrapper category={category} />;
};
