import { FormProvider, useForm } from 'react-hook-form';
import { cartItemIndex } from '../DeviceCheckoutCartProduct/deviceCheckoutCartProductUtil.js';
import React from 'react';
import type { FieldErrors } from 'react-hook-form';
import type { OrderConfiguration } from '../OrderSubscriptionConfiguration/FormWrapper.js';
import type { ShoppingCartItemForCheckout } from '../../common/types/checkout.js';

export interface FormWrapperProps {
  children: JSX.Element;
  cartItems: ShoppingCartItemForCheckout[];
  onSuccess: (data: OrderConfiguration, e?: React.BaseSyntheticEvent) => void;
  onError: (errors: FieldErrors, e?: React.BaseSyntheticEvent) => void;
}

export const FormWrapper = ({ children, cartItems, onSuccess, onError }: FormWrapperProps) => {
  const methods = useForm({
    shouldFocusError: true,
    // default values do not work when changing amount as initialisation happens only once
    // that's why components need to have good default values as well
    defaultValues: {
      configuration: cartItems
        .flatMap(cartItem => {
          return Array.from({ length: cartItem.quantity }, (_, productItemIndex) => {
            return {
              index: cartItemIndex(cartItem.id, productItemIndex),
              value: {
                numberPublicity: 'true',
              },
            };
          });
        })
        .reduce((a, v) => ({ ...a, [v.index]: v.value }), {}),
    },
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSuccess, onError)} noValidate>
        {children}
      </form>
    </FormProvider>
  );
};
