import * as React from 'react';
import { isInBrowser } from '../utils/ssrUtils.js';
import { scrollTo } from '../utils/browserUtils.js';
import type {
  FieldInputProps,
  FormikComputedProps,
  FormikErrors,
  FormikHelpers,
  FormikState,
  FormikValues,
} from 'formik';

export const createFieldClassName = (field: FieldInputProps<string>) => `formik-input formik-input-${field.name}`;
export const createFieldError = (
  field: FieldInputProps<string>,
  { touched, errors }: FormikState<FormikValues> & FormikHelpers<FormikValues> & FormikComputedProps<FormikValues>
) => (touched[field.name] && errors[field.name] ? errors[field.name] : '');

export const usePrevious = <T extends object>(value: T): T | undefined => {
  const ref = React.useRef<T>();
  React.useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export const useDeepEffect = (fn: () => void, deps: React.DependencyList) => {
  const isFirst = React.useRef(true);
  const prevDeps = React.useRef(deps);

  React.useEffect(() => {
    const isFirstEffect = isFirst.current;

    const isSame = prevDeps.current.every((obj, index: number) => JSON.stringify(obj) === JSON.stringify(deps[index]));

    isFirst.current = false;
    prevDeps.current = deps;

    if (isFirstEffect || !isSame) {
      return fn();
    }
  }, deps); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps
};

export const enum FormStateEnum {
  FULFILLED,
  FAILED,
  LOADING,
}
export const isLoading = (e?: FormStateEnum) => e === FormStateEnum.LOADING;

// works for input type elements and combobox only
export const scrollToFirstError = (formId: string, errors: FormikErrors<object>) => {
  const errorFields = Object.keys(errors || {});
  if (errorFields.length > 0 && isInBrowser()) {
    const firstFiledWithError = document
      .getElementById(formId)
      ?.querySelector(`input[name='${errorFields[0]}'], .of-formik-combobox--${errorFields[0]}`);
    if (firstFiledWithError) {
      scrollTo(firstFiledWithError as HTMLElement);
    }
  }
};

export const resetField = (
  fieldId: string,
  defaultValue: string,
  {
    setFieldValue,
    setFieldTouched,
    setFieldError,
  }: Pick<FormikHelpers<object>, 'setFieldValue' | 'setFieldTouched' | 'setFieldError'>
) => {
  setFieldValue(fieldId, defaultValue);
  setFieldTouched(fieldId, false);
  setFieldError(fieldId, undefined);
};
