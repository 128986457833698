import * as CL from '@design-system/component-library';
import {
  eppDeviceDisclaimerBodyMsg,
  eppDeviceDisclaimerHeaderMsg,
  returnToSubscriptionPageMsg,
  t,
} from '../../common/i18n/index.js';

import './EppDestroyedOrMissingDevicePending.scss';

interface EppDestroyedOrMissingDevicePendingProps {
  onClose: () => void;
}

export const EppDevicePendingActionsDisclaimer = ({ onClose }: EppDestroyedOrMissingDevicePendingProps) => (
  <div className="of-epp-maintenance-disclaimer">
    <CL.Disclaimer title={t.V09R(eppDeviceDisclaimerHeaderMsg)} text={t.O7DE(eppDeviceDisclaimerBodyMsg)} />
    <CL.Button color="link" className="of-epp-maintenance-disclaimer--link" onClick={onClose}>
      {t.D30R(returnToSubscriptionPageMsg)}
    </CL.Button>
  </div>
);
