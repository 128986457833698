import { ContactType } from '../../../../generated/api/models.js';
import { DialogType } from '../../../enums.js';
import { DropdownSearchComponent } from '../../FieldComponents/DropdownSearchComponent.js';
import { Field, useFormikContext } from 'formik';
import { createNewMsg, fieldCantBeEmptyMsg, t } from '../../../i18n/index.js';
import { deepEqual } from '../../../../common/utils/objectUtils.js';
import { dsClass } from '../../../../common/constants/dsClasses.js';
import { showDialog } from '../../../../selfservice/actions/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import type { BasicAutoCompleteItem } from '../../../../components/AutoComplete/AutoComplete.js';
import type { Contact, ContactHeader, GenericErrorDuplicateContact } from '../../../../generated/api/models.js';
import type { FormikValues } from 'formik';
import type { State } from '../../../../selfservice/common/store.js';

export interface ContactDropdownProps {
  className?: string;
  contacts: Contact[] | ContactHeader[];
  labelText?: string;
  name: string;
  canAddNewContacts: boolean;
  tooltipText?: string;
  labelClass?: string;
  onChangeContact?: (selectedContactId: string) => void;
  placeHolderText?: string;
  // In some cases we do not want to open a dialog to create a new contact (checkout)
  createNewDialog?: boolean;
  required?: boolean;
}

export const CREATE_NEW_CONTACT_OPTION_VALUE = 'ADD_NEW';

const getContactOption = (contact: Contact & ContactHeader): BasicAutoCompleteItem => {
  const email = contact.person?.email || contact.email || '';
  const phoneNumber = contact.person?.phoneNumber || contact.phoneNumber || '';
  const firstName = contact.person?.firstName || contact.firstName || '';
  const lastName = contact.person?.lastName || contact.lastName || '';

  return {
    label: `${firstName} ${lastName} ${phoneNumber || ''} ${email}`,
    value: contact.contactId || '',
    html: (
      <>
        <div>{`${firstName} ${lastName}`}</div>
        <div className={dsClass.FONT_SIZE_SMALL}>
          {email && phoneNumber ? `${email} | ${phoneNumber}` : email || phoneNumber}
        </div>
      </>
    ),
  };
};

export const getContactOptions = (
  contacts: Contact[] | ContactHeader[],
  canAddNewContacts?: boolean
): Array<BasicAutoCompleteItem> => {
  const createNewContactOption: Array<BasicAutoCompleteItem> = canAddNewContacts
    ? [
        {
          label: t.GFN5(createNewMsg),
          value: CREATE_NEW_CONTACT_OPTION_VALUE,
          html: <div className={dsClass.COLOR_BLUE_600}>{t.GFN5(createNewMsg)}</div>,
        },
      ]
    : [];
  const contactOptions: Array<BasicAutoCompleteItem> = contacts
    .filter(contact => contact.contactType === ContactType.PERSON)
    .filter(c => {
      const contact = c as Contact;
      const contactHeader = c as ContactHeader;
      if (contact?.person) {
        return contact.person?.firstName || contact.person?.lastName;
      } else {
        return contactHeader?.firstName || contactHeader?.lastName;
      }
    })
    .map(getContactOption);
  return contactOptions ? createNewContactOption.concat(contactOptions) : createNewContactOption;
};

export const ContactDropdown = ({
  className,
  contacts,
  labelText,
  name,
  canAddNewContacts,
  tooltipText,
  labelClass,
  onChangeContact,
  placeHolderText,
  createNewDialog = true,
  required = true,
}: ContactDropdownProps) => {
  const { setFieldError, setFieldValue, values } = useFormikContext<FormikValues>();
  const dispatch = useDispatch();

  const useDuplicateContact: GenericErrorDuplicateContact | undefined = useSelector(
    (state: State) => state.selfservice?.subscriptionActions?.useDuplicateContact,
    deepEqual
  );
  const contactCreated = useSelector(
    (state: State) => state.selfservice?.subscriptionActions?.contactCreated?.contactId
  );

  useEffect(() => {
    const selectedDuplicateContactId = useDuplicateContact?.contactId;
    if (selectedDuplicateContactId) {
      setFieldValue(name, selectedDuplicateContactId);
    }
  }, [useDuplicateContact, setFieldValue, name]);

  useEffect(() => {
    if (contactCreated) {
      setFieldValue(name, contactCreated);
      setFieldError(name, undefined);
    }
  }, [setFieldValue, setFieldError, contactCreated, name]);

  const contactOptions = getContactOptions(contacts, canAddNewContacts).map(option => ({
    ...option,
    id: option.value,
  }));
  const selectedContact = contacts?.find(({ contactId }) => contactId === values[name]);

  const validateContact = (value: string) => {
    if (
      required &&
      (value === '' || value === undefined || (createNewDialog === true && value === CREATE_NEW_CONTACT_OPTION_VALUE))
    ) {
      return t.VPVR(fieldCantBeEmptyMsg);
    }
    return undefined;
  };

  const onSelectContact = (evt: HTMLLIElement) => {
    const newValue = evt.dataset.value || '';
    onChangeContact?.(newValue);
    if (newValue === CREATE_NEW_CONTACT_OPTION_VALUE && createNewDialog === true) {
      setFieldValue(name, CREATE_NEW_CONTACT_OPTION_VALUE);
      dispatch(
        showDialog({
          billingAccountContactIdRef: name,
          type: DialogType.ADD_CONTACT,
        })
      );
      setFieldError(name, t.VPVR(fieldCantBeEmptyMsg));
    } else {
      setFieldValue(name, newValue);
    }
  };

  const isValueAvailable =
    (selectedContact?.contactType === ContactType.PERSON && values[name]) ||
    values[name] === CREATE_NEW_CONTACT_OPTION_VALUE;
  const addNewContact = canAddNewContacts ? CREATE_NEW_CONTACT_OPTION_VALUE : '';
  const addNewOrEmptyIfPlaceHolderSet = placeHolderText ? '' : addNewContact;

  // If no selected value, We show 'Add new' as default value, unless placeholder is set
  const selectedOrAddNewContact = isValueAvailable ? values[name] : addNewOrEmptyIfPlaceHolderSet;

  return (
    <Field
      id={name}
      className={className}
      name={name}
      items={contactOptions}
      selectedValue={selectedOrAddNewContact}
      labelText={labelText || t.VYZS('Contact person')}
      labelClass={labelClass}
      tooltipText={tooltipText}
      placeHolderText={placeHolderText}
      onValueChange={onSelectContact}
      validate={validateContact}
      component={DropdownSearchComponent}
    />
  );
};
