import { AddOnManagementButtons } from '../SubscriptionAdditionalService/AddOnManagementButtons.js';
import { AddOnRow } from './AddOnRow.js';
import { AddOnVisibilityOperationType, SubscriptionAddonCode } from '../../common/enums.js';
import { CATEGORY_URL } from '../../common/utils/categoryUtils.js';
import { Loading } from '../Loading';
import {
  MOBIILITURVA_ADDON,
  calculateBaseDependency,
  checkCurrentCombination,
  determineSelectedLanguage,
  getAddOnsByCodes,
  getDefaultSelectedLanguage,
  isAddonVisible,
  isMobileVoiceAddOn,
  isMobileVoiceLanguageUpdated,
  reCalculateBaseDependency,
  updateVisibilitiesIfRing,
} from './addOnDependencyUtils.js';
import { MobileIdAdditionalService } from '../SubscriptionAdditionalService/MobileIdAdditionalService.js';
import { SourceSystem } from '../../generated/api/models.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { fetchAddOnsVisibilities } from '../../common/fetch.js';
import { isMobileIdAddOn, mobiilivarmenneNotInUseAddOn } from '../../common/utils/subscriptionUtils.js';
import { noAdditionalServicesMsg, t } from '../../common/i18n';
import { paths } from '../../common/constants/pathVariables.js';
import { shouldNotShowMobileId } from '../SubscriptionAdditionalService/subscriptionAddOnUtils.js';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSearchParams } from '../../common/hooks/useSearchParams';
import type { AddOnVisibility, Subscription, SubscriptionAction } from '../../generated/api/models.js';
import type { AssociationRecord, DependencyRecord } from '@onlinefirst/cloudsense-add-on-dependency-engine';
import type { BaseRules } from './addOnDependencyUtils.js';
import type { CategoryKey } from '../../common/utils/categoryUtils.js';

export interface AdditionalServicesContentProps {
  category: CategoryKey;
  subscription: Subscription;
  subscriptionActions?: SubscriptionAction[];
  isMobileIdEnabledForCompany: boolean;
  isMobileIdEnabledForEoeUser: boolean;
  baseRules: BaseRules;
  isEmployee: boolean;
  isAssetWithMobiiliturva: boolean;
  addOnVisibilities: AddOnVisibility[];
}

interface AdditionalServicesAccordionContentProps {
  category: CategoryKey;
  subscription: Subscription;
  isEmployee: boolean;
  subscriptionActions?: SubscriptionAction[];
  isMobileIdEnabledForCompany?: boolean;
  isMobileIdEnabledForEoeUser?: boolean;
  addOnAssociations?: AssociationRecord[];
  addOnDependencies?: DependencyRecord[];
  pbxSolutions?: Subscription[];
}

const buildAddOnDetailsPath = (addOnCode: string, category: CategoryKey, subscription: Subscription) =>
  `${paths.PS_HOME}/${CATEGORY_URL[category]}/${subscription.subscriptionDisplayId}/${addOnCode}`;

// Use function to get elements (instead of wrapper component) to improve null/undefined check clarity
const getMobileIdRows = (
  subscription: Subscription,
  isEmployee: boolean,
  showEditView: boolean,
  category: CategoryKey,
  isMobileIdEnabledForCompany = false,
  isMobileIdEnabledForEoeUser = false
): JSX.Element[] => {
  if (shouldNotShowMobileId(isEmployee, isMobileIdEnabledForCompany, isMobileIdEnabledForEoeUser)) {
    return [];
  }
  const mobileIdAddOns = subscription.details?.selectedAddOns?.filter(selectedAddOn =>
    isMobileIdAddOn(selectedAddOn.addOnGroup)
  );
  // Always show Mobiilivarmenne for employees. If there is no active one, show not in use alternative.
  if (mobileIdAddOns?.length === 0 && isEmployee) {
    mobileIdAddOns.push(mobiilivarmenneNotInUseAddOn);
  }

  return (
    mobileIdAddOns?.map(mobileIdAddOn => (
      <MobileIdAdditionalService
        key={`mobileid-addon-${mobileIdAddOn.addOnCode}`}
        selectedAddOn={mobileIdAddOn}
        additionalServicePath={buildAddOnDetailsPath(mobileIdAddOn.addOnCode, category, subscription)}
        showEditView={showEditView}
        isEmployee={isEmployee}
      />
    )) || []
  );
};

const AdditionalServicesContent = ({
  category,
  subscription,
  subscriptionActions,
  addOnVisibilities,
  baseRules,
  isEmployee,
  isAssetWithMobiiliturva,
  isMobileIdEnabledForCompany,
  isMobileIdEnabledForEoeUser,
}: AdditionalServicesContentProps) => {
  const subscriptionAddOns = subscription.details?.selectedAddOns;
  const subscriptionAddOnCodes = subscriptionAddOns?.map(addOn => addOn.addOnCode) || [];
  const { search } = useLocation();
  const [showEditView, setShowEditView] = useState(false);
  const [addOnCodesToAdd, setAddOnCodesToAdd] = useState<string[]>([]);
  const [addOnCodesToRemove, setAddOnCodesToRemove] = useState<string[]>([]);
  const [linkedAddOnCodesToRemove, setLinkedAddOnCodesToRemove] = useState<string[]>([]);
  const [isValidCombination, setIsValidCombination] = useState(false);
  const [editabilityRecords, setEditabilityRecords] = useState(
    calculateBaseDependency(baseRules, subscriptionAddOnCodes, addOnVisibilities, isAssetWithMobiiliturva, isEmployee)
  );
  const [selectedLanguage, setSelectedLanguage] = useState(determineSelectedLanguage(subscriptionAddOns));

  const resetEditView = () => {
    setAddOnCodesToAdd([]);
    setAddOnCodesToRemove([]);
    setShowEditView(false);
    setEditabilityRecords(
      calculateBaseDependency(baseRules, subscriptionAddOnCodes, addOnVisibilities, isAssetWithMobiiliturva, isEmployee)
    );
    setLinkedAddOnCodesToRemove([]);
    setIsValidCombination(false);
    setSelectedLanguage(determineSelectedLanguage(subscriptionAddOns));
  };

  const handleAddOnSelectionChange = (checked: boolean, addOnVisibility: AddOnVisibility) => {
    // Assets doesn't have addons included like the normal SF based subs
    const isAddOnIncludedInSubscription =
      subscriptionAddOnCodes.includes(addOnVisibility.addOnGuidCode) || isAssetWithMobiiliturva;
    const addedItemsWhenChecked = isAddOnIncludedInSubscription
      ? addOnCodesToAdd
      : [...addOnCodesToAdd, addOnVisibility.addOnGuidCode];
    const addedItemsWhenUnchecked = isAddOnIncludedInSubscription
      ? addOnCodesToAdd
      : addOnCodesToAdd.filter(item => item !== addOnVisibility.addOnGuidCode);
    const addedItems = checked ? addedItemsWhenChecked : addedItemsWhenUnchecked;

    const removedItemsWhenChecked = isAddOnIncludedInSubscription
      ? addOnCodesToRemove.filter(item => item !== addOnVisibility.addOnGuidCode)
      : addOnCodesToRemove;
    const removedItemsWhenUnchecked = isAddOnIncludedInSubscription
      ? [...addOnCodesToRemove, addOnVisibility.addOnGuidCode]
      : addOnCodesToRemove;
    const removedItems = checked ? removedItemsWhenChecked : removedItemsWhenUnchecked;

    const editability = reCalculateBaseDependency(
      baseRules,
      addedItems,
      removedItems,
      addOnVisibility.addOnGuidCode,
      subscriptionAddOnCodes,
      addOnVisibilities,
      isAssetWithMobiiliturva,
      isEmployee
    );
    const currentCombination = checkCurrentCombination(
      baseRules,
      addedItems,
      removedItems,
      subscriptionAddOnCodes,
      addOnVisibilities
    );
    const linkedItemsToRemove = currentCombination.openingFeeCodesToDelete;

    if (isMobileVoiceAddOn(addOnVisibility.addOnGuidCode)) {
      const languageIfChecked = isAddOnIncludedInSubscription
        ? determineSelectedLanguage(subscriptionAddOns)
        : getDefaultSelectedLanguage();
      setSelectedLanguage(checked ? languageIfChecked : undefined);
    }

    setAddOnCodesToAdd(addedItems);
    setAddOnCodesToRemove(removedItems);
    setLinkedAddOnCodesToRemove(linkedItemsToRemove);
    setEditabilityRecords(editability);
    setIsValidCombination(currentCombination.isValid);
  };

  const addOnsByRole: AddOnVisibility[] = addOnVisibilities
    .filter(addOnVisibility =>
      isAddonVisible(isAssetWithMobiiliturva, subscriptionAddOnCodes, addOnVisibility, isEmployee)
    )
    .filter(addOnVisibility => !isMobileIdAddOn(addOnVisibility.addOn?.addOnGroup));

  const addOnRows = addOnsByRole?.map((addOnVisibility, i) => (
    <AddOnRow
      key={`addl-service-addon-${i}`}
      addOnVisibility={addOnVisibility}
      showEditView={showEditView}
      editabilityRecords={editabilityRecords}
      subscriptionAddOnCodes={subscriptionAddOnCodes}
      addOnCodesToAdd={addOnCodesToAdd}
      addOnCodesToRemove={addOnCodesToRemove}
      isAssetWithMobiiliturva={isAssetWithMobiiliturva}
      isEmployee={isEmployee}
      handleAddOnSelectionChange={handleAddOnSelectionChange}
      baseRules={baseRules}
      additionalServicePath={buildAddOnDetailsPath(addOnVisibility.addOnGuidCode, category, subscription) + search}
      selectedLanguage={selectedLanguage}
      onChangeLanguage={(language?: string) => {
        setSelectedLanguage(language);
        const currentCombination = checkCurrentCombination(
          baseRules,
          addOnCodesToAdd,
          addOnCodesToRemove,
          subscriptionAddOnCodes,
          addOnVisibilities
        );
        setIsValidCombination(currentCombination.isValid);
      }}
    />
  ));

  const mobileIdRows = getMobileIdRows(
    subscription,
    isEmployee,
    showEditView,
    category,
    isMobileIdEnabledForCompany,
    isMobileIdEnabledForEoeUser
  );

  const isMobileVoiceUpdated = isMobileVoiceLanguageUpdated(
    [...addOnCodesToAdd, ...addOnCodesToRemove],
    subscriptionAddOns,
    selectedLanguage
  );
  const updatedAddOns = isMobileVoiceUpdated
    ? getAddOnsByCodes([SubscriptionAddonCode.MOBILE_VOICE], addOnVisibilities, subscriptionAddOns)
    : [];
  const shouldDisableConfirm = () => {
    if (addOnCodesToAdd.length === 0 && addOnCodesToRemove.length === 0 && updatedAddOns.length === 0) {
      return true;
    }
    return !isValidCombination;
  };
  const removalAddOnCodesToDisplay = [...addOnCodesToRemove, ...linkedAddOnCodesToRemove];
  const managementButtons = (
    <AddOnManagementButtons
      showEditView={showEditView}
      resetEditView={() => resetEditView()}
      setShowEditView={show => setShowEditView(show)}
      shouldDisableConfirm={shouldDisableConfirm()}
      addedAddOns={getAddOnsByCodes(addOnCodesToAdd, addOnVisibilities, subscriptionAddOns)}
      removedAddOns={getAddOnsByCodes(removalAddOnCodesToDisplay, addOnVisibilities, subscriptionAddOns)}
      updatedAddOns={updatedAddOns}
      selectedLanguage={selectedLanguage}
      subscription={subscription}
      category={category}
      subscriptionActions={subscriptionActions}
      isEmployee={isEmployee}
    />
  );

  return addOnRows?.length > 0 || mobileIdRows.length > 0 ? (
    <>
      {!showEditView && managementButtons}
      {addOnRows}
      {mobileIdRows}
      {showEditView && managementButtons}
    </>
  ) : (
    <div className={dsClass.MARGIN_TOP_4}>{t.EIYK(noAdditionalServicesMsg)}</div>
  );
};

export const AdditionalServicesAccordionContent = ({
  category,
  subscription,
  subscriptionActions,
  isMobileIdEnabledForCompany = false,
  addOnAssociations,
  addOnDependencies,
  isEmployee = false,
  pbxSolutions,
  isMobileIdEnabledForEoeUser = false,
}: AdditionalServicesAccordionContentProps) => {
  const [addOnVisibilities, setAddOnVisibilities] = useState<AddOnVisibility[] | undefined>(undefined);
  const [loadingAddOnVisibility, setLoadingAddOnVisibility] = useState(true);
  const isAssetWithMobiiliturva = Boolean(subscription.details?.asset?.hasMobiiliturva);
  const { mdmId } = useSearchParams<{ mdmId?: string }>();

  useEffect(() => {
    if (!addOnVisibilities) {
      // Assets (Tellus based subscriptions) may have Mobiiliturva addon but no commercial product, which could be used to fetch the addon data,
      // so put a hard coded version to visibility object list.
      if (subscription.sourceSystem === SourceSystem.TELLUS && !subscription.migrated) {
        setAddOnVisibilities([MOBIILITURVA_ADDON]);
        setLoadingAddOnVisibility(false);
      } else {
        setLoadingAddOnVisibility(true);
        fetchAddOnsVisibilities(
          subscription.commercialProductCode || '',
          AddOnVisibilityOperationType.CHANGE,
          mdmId
        ).then(res => {
          setAddOnVisibilities(res.addOnVisibilities);
          setLoadingAddOnVisibility(false);
        });
      }
    }
  }, [
    addOnVisibilities,
    subscription.commercialProductCode,
    subscription.details?.selectedAddOns,
    isAssetWithMobiiliturva,
    subscription.sourceSystem,
    subscription.migrated,
    mdmId,
  ]);

  const isAllDataLoaded = Boolean(!loadingAddOnVisibility && addOnAssociations && addOnDependencies);
  const baseRules: BaseRules = {
    commercialProductId: subscription.commercialProductId || '',
    commercialProductCode: subscription.commercialProductCode || '',
    addOnAssociations: addOnAssociations || [],
    addOnDependencies: addOnDependencies || [],
  };
  const updatedVisibilities = updateVisibilitiesIfRing(subscription, pbxSolutions, addOnVisibilities) || [];

  return isAllDataLoaded ? (
    <AdditionalServicesContent
      category={category}
      subscriptionActions={subscriptionActions}
      subscription={subscription}
      isMobileIdEnabledForCompany={isMobileIdEnabledForCompany}
      isMobileIdEnabledForEoeUser={isMobileIdEnabledForEoeUser}
      addOnVisibilities={updatedVisibilities}
      baseRules={baseRules}
      isEmployee={isEmployee}
      isAssetWithMobiiliturva={isAssetWithMobiiliturva}
    />
  ) : (
    <Loading />
  );
};
