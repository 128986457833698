import * as CL from '@design-system/component-library';
import { ButtonGroupForSubmitAndBack } from '../ButtonGroupForSubmitAndBack/ButtonGroupForSubmitAndBack.js';
import { ExternalLink } from '../ExternalLink/ExternalLink.js';
import { Loading } from '../Loading/index.js';
import { OrderEsimConfirmationDialog } from './OrderEsimConfirmationDialog.js';
import { SimType } from '../../generated/api/models.js';
import {
  billableChangesDisabledMsg,
  orderEsimMsg,
  priceMsg,
  returnToSubscriptionPageMsg,
  t,
} from '../../common/i18n/index.js';
import { changeSimCard } from '../../selfservice/actions/subscriptionActionsActions.js';
import { formatSum } from '../../common/utils/priceUtils.js';
import { showDialog } from '../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import type { CategoryKey } from '../../common/utils/categoryUtils.js';
import type { Subscription } from '../../generated/api/models.js';

import './OrderEsim.scss';

export interface OrderEsimProps {
  category: CategoryKey;
  disallowBillableSimChanges: boolean;
  subscription: Subscription;
  onCancel: () => void;
  simChangeServiceFee?: number;
  isEmployee?: boolean;
}

export const OrderEsim = (props: OrderEsimProps) => {
  const dispatch = useDispatch();

  const { subscription, category, disallowBillableSimChanges, onCancel, simChangeServiceFee, isEmployee } = props;

  if (!subscription.details || !subscription.details.mobile) {
    return <Loading />;
  }

  if (subscription.details.mobile.esimAllowed === false) {
    return (
      <>
        <div className="of-order-esim__info">
          <p>{t.CJOF('eSIM is not available for this subscription.')}</p>
        </div>
        <div className="of-order-esim__actions">
          <CL.Button color="link" onClick={onCancel}>
            {t.D30R(returnToSubscriptionPageMsg)}
          </CL.Button>
        </div>
      </>
    );
  }

  if (disallowBillableSimChanges) {
    return (
      <>
        <div className="of-order-esim__info">
          <p>{t.UQ4L(billableChangesDisabledMsg)}</p>
        </div>
        <div className="of-order-esim__actions">
          <CL.Button color="link" onClick={onCancel}>
            {t.D30R(returnToSubscriptionPageMsg)}
          </CL.Button>
        </div>
      </>
    );
  }

  const onConfirm = () => {
    dispatch(
      changeSimCard(
        subscription.subscriptionId,
        SimType.ESIM,
        undefined,
        subscription.subscriptionDisplayId,
        category,
        undefined
      )
    );
  };

  return (
    <>
      <div className="of-order-esim of-order-esim__info">
        <p>
          {t.SL51(
            'eSIM is a built-in feature in newer phones and functions like a traditional SIM card. You can activate eSIM immediately after ordering. Activating eSIM requires that your device is connected to the Internet (wifi). Please note that the eSIM can only be used on the first device on which it is activated.'
          )}
        </p>
        <p>{t.SMM7('Current SIM card will stop working immediately after ordering.')}</p>
        <p>
          {t.SMPX('Please verify that your ')}
          <ExternalLink href="https://elisa.fi/esim">{t.TXXM('device supports eSIM')}</ExternalLink>
          {t.U63I(' prior to ordering.')}
        </p>
        <p>{t.UCPM('If your subscription has a Mobile Certificate it will be removed during eSIM order.')}</p>
        {!isEmployee && (
          <p>
            {t.V72N(priceMsg)}:{' '}
            <strong>
              {formatSum(simChangeServiceFee)} ({t.UZ6X('VAT')} 0%)
            </strong>
          </p>
        )}
      </div>

      <ButtonGroupForSubmitAndBack
        className="of-order-esim__actions"
        onCancel={onCancel}
        submitButtonText={t.DD3Q(orderEsimMsg)}
        onSubmit={() =>
          OrderEsimConfirmationDialog({ onConfirm, onShowDialog: params => dispatch(showDialog(params)) })
        }
      />
    </>
  );
};
