import * as CL from '@design-system/component-library';
import { type BillingAccountData, type Context } from '../../OpenFormAnswers.js';
import { BillingAccountDeliveryMethod } from '../../../../generated/api/billingAccountDeliveryMethod.js';
import { CompanySearch } from '../../../../common/react-hook-form/fields/index.js';
import { ELECTRONIC_ADDRESS_REGEX } from '../../../../common/utils/validationUtils.js';
import { NEW_BILLING_ACCOUNT } from '../../OpenFormHooks/useOpenFormSummary.js';
import { type OpenFormAccount } from '../../OpenFormHooks/useOpenFormAccount.js';
import {
  OpenFormBillingInvoiceElectronic,
  OpenFormBillingInvoiceEmail,
  OpenFormBillingInvoicePaper,
} from '../../OpenFormComponents/OpenFormFormatted.js';
import { OpenFormComboboxBillingAccount } from '../../OpenFormComponents/OpenFormComboboxBillingAccount.js';
import { OpenFormComboboxContact } from '../../OpenFormComponents/OpenFormComboboxContact.js';
import { type OpenFormDispatcher } from '../../OpenFormHooks/useOpenFormDispatcher.js';
import { OpenFormDropdown } from '../../OpenFormComponents/OpenFormDropdown.js';
import { OpenFormGridBlock, OpenFormGridCol } from '../../OpenFormComponents/OpenFormGrid.js';
import { OpenFormQuestionInput } from './OpenFormQuestionInput.js';
import { type UseFormReturn, useFormContext } from 'react-hook-form';
import {
  billingAccountExtensionNameMsg,
  billingAccountNameMsg,
  cityMsg,
  contactInfoMsg,
  deliveryMethodMsg,
  eInvoicingAddressMsg,
  eInvoicingOperatorMsg,
  emailInvoiceMsg,
  invalidEInvoiceAddressMsg,
  invoiceLanguageMsg,
  payerDetailsMsg,
  payerNameAndBusinessIdMsg,
  postalCodeMsg,
  referenceMsg,
  searchWithCompanyNameOrBusinessIdMsg,
  selectMsg,
  streetAddressMsg,
  t,
} from '../../../../common/i18n/index.js';
import {
  getDeliveryMethodDropDownOptions,
  getElectronicInvoiceOperatorDropDownOptions,
  getEmptyBillingAccount,
  getLanguageOptions,
} from '../../../../common/utils/billingAccountUtils.js';
import { getValue } from '../../OpenFormUtils.js';
import { useCallback, useEffect, useMemo } from 'react';
import { useOpenFormBillChannels } from '../../OpenFormHooks/useOpenFormBillChannels.js';
import { useOpenFormBillingAccountHeaders } from '../../OpenFormHooks/useOpenFormBillingAccountHeaders.js';
import { useOpenFormBillingAccounts } from '../../OpenFormHooks/useOpenFormBillingAccounts.js';
import { useOpenFormContactHeaders } from '../../OpenFormHooks/useOpenFormContactHeaders.js';
import { useOutletContext } from 'react-router-dom';
import classNames from 'classnames';
import type { BillChannel } from '../../../../generated/api/billChannel.js';
import type { BillingAccount } from '../../../../generated/api/billingAccount.js';
import type { ContactHeader } from '../../../../generated/api/contactHeader.js';

const OpenFormQuestionNewBillingAccount = ({
  channels,
  contacts,
  methods,
  data,
  disabled,
  setData,
}: {
  channels: BillChannel[] | undefined;
  contacts: ContactHeader[] | undefined;
  methods: UseFormReturn;
  data: BillingAccountData;
  disabled: boolean;
  setData: (data: BillingAccountData) => void;
}) => {
  useEffect(() => {
    methods.setValue('billingAccountData', data);
    const { unsubscribe } = methods.watch(({ billingAccountData }, { name }) => {
      if (name?.split('.', 1)[0] === 'billingAccountData') {
        setData(billingAccountData);
      }
    });
    return () => unsubscribe();
  }, [methods, data, setData]);

  return (
    <>
      <OpenFormGridBlock>
        <OpenFormGridCol>
          <CompanySearch
            label={t.FDE2(payerNameAndBusinessIdMsg)}
            disabled={disabled}
            fieldNames={{
              businessId: 'billingAccountData.payerBusinessId',
              businessName: 'billingAccountData.payerName',
              masterId: 'billingAccountData.accountMasterId',
              postalAddress: 'billingAccountData.payerAddress.line1',
              postCode: 'billingAccountData.payerAddress.postalCode',
              postOffice: 'billingAccountData.payerAddress.postOffice',
            }}
            helpText="&shy;"
            noResultsText={t.VRG9(searchWithCompanyNameOrBusinessIdMsg)}
            placeholder={data.payerName ? [data.payerName, data.payerBusinessId].filter(Boolean).join(', ') : undefined}
          />
        </OpenFormGridCol>
      </OpenFormGridBlock>
      <OpenFormGridBlock>
        <OpenFormQuestionInput
          label={t.YLAI(payerDetailsMsg)}
          name="billingAccountData.payerNameExtension"
          required={false}
          disabled={disabled}
          wrapGridRow={false}
          type="field"
        />
        <OpenFormQuestionInput
          label={t.DD38(streetAddressMsg)}
          name="billingAccountData.payerAddress.line1"
          required={true}
          disabled={disabled}
          wrapGridRow={false}
          type="field"
        />
        <OpenFormQuestionInput
          label={t.RUAW(postalCodeMsg)}
          name="billingAccountData.payerAddress.postalCode"
          required={true}
          disabled={disabled}
          wrapGridRow={false}
          type="field"
        />
        <OpenFormQuestionInput
          label={t.J0YE(cityMsg)}
          name="billingAccountData.payerAddress.postOffice"
          required={true}
          disabled={disabled}
          wrapGridRow={false}
          type="field"
        />
      </OpenFormGridBlock>
      <OpenFormGridBlock>
        <OpenFormDropdown
          label={t.VYZS(contactInfoMsg)}
          disabled={disabled}
          items={(contacts ?? []).map(OpenFormComboboxContact)}
          selectedValue={data.billingContactId}
          onChange={(billingContactId?: string) => {
            const contact = contacts?.find(c => c.contactId === billingContactId);
            setData({
              ...data,
              billingContactEmail: contact?.email,
              billingContactId: contact?.contactId,
              billingContactName: contact ? `${contact.firstName} ${contact.lastName}` : undefined,
              billingContactPhone: contact?.phoneNumber,
            });
          }}
        />
      </OpenFormGridBlock>
      <OpenFormGridBlock>
        <OpenFormQuestionInput
          label={t.RH6T(billingAccountNameMsg)}
          name="billingAccountData.billingAccountName"
          required={true}
          disabled={disabled}
          wrapGridRow={false}
          type="field"
        />
        <OpenFormQuestionInput
          label={t.KUTS(billingAccountExtensionNameMsg)}
          name="billingAccountData.billingAccountExtensionName"
          required={false}
          disabled={disabled}
          wrapGridRow={false}
          type="field"
        />
      </OpenFormGridBlock>
      <OpenFormGridBlock>
        <OpenFormQuestionInput
          label={`${t.ZLAU(referenceMsg)} 1`}
          name="billingAccountData.customerReference1"
          required={false}
          disabled={disabled}
          wrapGridRow={false}
          type="field"
        />
        <OpenFormQuestionInput
          label={`${t.ZLAU(referenceMsg)} 2`}
          name="billingAccountData.customerReference2"
          required={false}
          disabled={disabled}
          wrapGridRow={false}
          type="field"
        />
      </OpenFormGridBlock>
      <OpenFormGridBlock>
        <OpenFormDropdown
          label={t.G0QN(deliveryMethodMsg)}
          disabled={disabled}
          colWidth={6}
          items={getDeliveryMethodDropDownOptions(getEmptyBillingAccount(data?.payerName ?? ''), channels).filter(
            c => c.value !== BillingAccountDeliveryMethod.ELECTRONIC || channels?.length
          )}
          selectedValue={data.deliveryMethod}
          onChange={(deliveryMethod?: BillingAccountDeliveryMethod) => setData({ ...data, deliveryMethod })}
        />
        <OpenFormDropdown
          label={t.A7DR(invoiceLanguageMsg)}
          disabled={disabled}
          colWidth={6}
          items={getLanguageOptions()}
          selectedValue={data.billLanguage}
          onChange={(billLanguage?: 'FI' | 'SV' | 'EN') => setData({ ...data, billLanguage })}
        />
        {data.deliveryMethod === BillingAccountDeliveryMethod.ELECTRONIC && channels?.length ? (
          <>
            <OpenFormDropdown
              label={t.WVLB(eInvoicingOperatorMsg)}
              disabled={disabled}
              colWidth={6}
              items={getElectronicInvoiceOperatorDropDownOptions(channels)}
              selectedValue={data.billElectronicOperator}
              onChange={(billElectronicOperator?: string) => setData({ ...data, billElectronicOperator })}
            />
            <OpenFormQuestionInput
              label={t.OL7B(eInvoicingAddressMsg)}
              name="billingAccountData.billElectronicAddress"
              required={true}
              disabled={disabled}
              wrapGridRow={false}
              type="field"
              validate={value => (ELECTRONIC_ADDRESS_REGEX.test(value) ? undefined : t.NMXU(invalidEInvoiceAddressMsg))}
            />
          </>
        ) : data.deliveryMethod === BillingAccountDeliveryMethod.EMAIL ? (
          <OpenFormQuestionInput
            label={t.W1PP(emailInvoiceMsg)}
            name="billingAccountData.billReceiverEmail"
            required={true}
            disabled={disabled}
            wrapGridRow={false}
            type="field"
          />
        ) : null}
      </OpenFormGridBlock>
    </>
  );
};

const OpenFormQuestionOldBillingAccount = ({ billing }: { billing: BillingAccount }) => (
  <OpenFormGridBlock>
    <OpenFormGridCol>
      <div>{billing.billingAccountName}</div>
      {billing.deliveryMethod === BillingAccountDeliveryMethod.ELECTRONIC ? (
        <OpenFormBillingInvoiceElectronic billing={billing} />
      ) : billing.deliveryMethod === BillingAccountDeliveryMethod.EMAIL ? (
        <OpenFormBillingInvoiceEmail billing={billing} />
      ) : billing.deliveryMethod === BillingAccountDeliveryMethod.PAPER ? (
        <OpenFormBillingInvoicePaper address={billing.payerAddress} />
      ) : null}
    </OpenFormGridCol>
  </OpenFormGridBlock>
);

export const OpenFormQuestionBillingAccount = ({
  dispatcher,
  disabled,
  required,
  label,
  context,
}: {
  dispatcher: OpenFormDispatcher;
  disabled: boolean;
  required: boolean;
  label: string;
  context: Context | undefined;
}) => {
  const outlet = useOutletContext<{ account: OpenFormAccount }>();
  const methods = useFormContext();
  const headers = useOpenFormBillingAccountHeaders();
  const accounts = useOpenFormBillingAccounts();
  const contacts = useOpenFormContactHeaders();
  const channels = useOpenFormBillChannels();
  const selectedValue = useMemo(() => getValue(context?.choices), [context?.choices]);

  return (
    <OpenFormGridBlock colWidth={6} className="of-openform__view__choices__billing" justifyCenterOuter={true}>
      <OpenFormGridCol className={classNames({ ['label--mandatory']: required })}>
        <CL.Combobox
          label={label}
          disabled={disabled}
          items={[NEW_BILLING_ACCOUNT, ...(headers ?? [])].map(OpenFormComboboxBillingAccount)}
          i18n_combobox_placeholderText={t.QRYV(selectMsg)}
          selectedItemFormat="label"
          selectedValue={selectedValue}
          onValueSelect={useCallback(
            (el?: HTMLLIElement) => {
              methods.reset({ billingAccountData: undefined, billingAccount: undefined });
              el?.dataset.value === NEW_BILLING_ACCOUNT
                ? dispatcher.setAnswer(
                    el.dataset.value,
                    'billingAccountData',
                    Object.assign(outlet.account.billingAccountData, context?.billingAccountData)
                  )
                : dispatcher.setAnswer(
                    el?.dataset.value,
                    'billingAccount',
                    accounts?.find(ba => ba.billingAccountId === el?.dataset.value) ??
                      (headers?.find(ba => ba.billingAccountId === el?.dataset.value) as BillingAccount)
                  );
            },
            [dispatcher, context, outlet, methods, accounts, headers]
          )}
        />
      </OpenFormGridCol>
      {context?.billingAccountData && selectedValue === NEW_BILLING_ACCOUNT ? (
        <OpenFormQuestionNewBillingAccount
          channels={channels}
          contacts={contacts}
          methods={methods}
          data={context.billingAccountData}
          disabled={disabled}
          setData={data => dispatcher.setContext('billingAccountData', data)}
        />
      ) : context?.billingAccount && selectedValue ? (
        <OpenFormQuestionOldBillingAccount billing={context.billingAccount} />
      ) : null}
    </OpenFormGridBlock>
  );
};
