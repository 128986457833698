import { EmployeeContentBlockWrapper } from '../../../../../components/EmployeeDeviceStore/EmployeeContentBlockWrapper.js';
import {
  getCustomerLevelDiscountedPrices,
  getPublicPage,
  loadVirtualCatalogs,
} from '../../../../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import type { CategoryProps } from '../../../../../common/types/category.js';
import type { DeviceName } from '../../../../../common/constants/pathInterfaces.js';

export const EmployeeStoreProduct = ({ category }: CategoryProps) => {
  const dispatch = useDispatch();
  const { deviceName } = useParams<DeviceName>();
  useEffect(() => {
    dispatch(loadVirtualCatalogs());
    dispatch(getCustomerLevelDiscountedPrices());
    dispatch(getPublicPage('/' + deviceName));
  }, [dispatch, deviceName]);
  return <EmployeeContentBlockWrapper category={category} />;
};
