import { Grid } from '../Grid/Grid.js';
import { InvoiceDisclaimer } from '../InvoiceDisclaimer/InvoiceDisclaimer.js';
import { OpenCasesNotification } from '../OpenCasesNotification/OpenCasesNotification.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import type { SupportCaseHeader } from '../../generated/api/models.js';

export interface SupportCaseNotificationProps {
  openSupportCases?: SupportCaseHeader[];
}

export const SupportCaseNotification = ({ openSupportCases = [] }: SupportCaseNotificationProps) => {
  return (
    <Grid colWidthL={12}>
      <InvoiceDisclaimer className={dsClass.MARGIN_TOP_4} />
      {openSupportCases.length > 0 && <OpenCasesNotification supportCases={openSupportCases} />}
    </Grid>
  );
};
