export enum TableUrlParams {
  FEATURE = 'feature',
  LIMIT = 'limit',
  ORDER = 'order',
  PAGE = 'page',
  SEARCH = 'search',
  SORT = 'sort',
  STATUS = 'status',
  OFFSET = 'offset',
  CURSOR = 'cursor',
}

export enum TableSortOrder {
  ASC = 'asc',
  DESC = 'desc',
}
