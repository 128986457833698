import * as CL from '@design-system/component-library';
import * as RP from '../../legacy/react-patterns/source/index.js';
import { PureComponent } from 'react';
import { onEnterOrSpaceKeyPress } from '../../common/utils/handlerUtils.js';
import { t } from '../../common/i18n/index.js';
import classNames from 'classnames';
import type { KeyboardEvent } from 'react';

import './CompositeListHeader.scss';

export interface CompositeListSort {
  columnId: string;
  order: string;
}

// Defines the column, used by both this header component and the CompositeListRow component
export interface CompositeListColumn<T extends object> {
  columnClasses?: string[]; // classes that are applied both CompositeListHeader and CompositeListRow
  columnId: string;
  heading?: string | JSX.Element;
  headingHideOnMobile?: boolean; // if the heading should be hidden on mobile
  headingOptionMenu?: {
    options: string[];
    onChange: (value: string) => void;
    value?: string;
  };
  multiLine?: {
    max: number;
    maxExceededPlural: string;
    maxExceededSingular: string;
  };
  postfixIcon?: string | ((item: T) => string | undefined);
  ref?: string; // used to identify a field in the object of type T
  // string or number value for key 'ref' is not always printable as is, but needs to be formatting
  refFormatNumber?: (value: number | undefined, ref: string, item: T) => string | undefined;
  refFormatString?: (value: string | undefined, ref: string, item: T) => string | undefined;
  sortable?: boolean;
  // in case 'ref' points at a object or array, or the cells should have a something completely different as content
  // then value can also be set explicitly with this field. NOTE: Prefer 'ref' to this as it results in a more readable
  // code
  value?: string | string[] | JSX.Element[] | ((item: T) => string | string[] | JSX.Element[]);
  valueColumnClasses?: string[] | ((item: T) => string[]);
  valueLineClasses?: string[] | ((item: T, lineIndex: number) => string[]);
  wide?: boolean;
  semiWide?: boolean;
  highlightSearchKeywords?: boolean;
}

export interface CompositeListHeaderProps<T extends object> {
  classes?: string[];
  sort?: CompositeListSort;
  onSort?: (params: CompositeListSort) => void;
  columns: CompositeListColumn<T>[];
  mobileTableLayout?: boolean;
  tight?: boolean;
  checkListLayout?: boolean;
  onClickSelectAll?: (selected: boolean) => void;
  selectAll?: boolean;
  showSelectAll?: boolean;
}

export class CompositeListHeader<T extends object> extends PureComponent<CompositeListHeaderProps<T>> {
  render() {
    const {
      classes,
      mobileTableLayout,
      sort,
      onSort,
      columns,
      tight,
      checkListLayout,
      onClickSelectAll,
      selectAll,
      showSelectAll,
    } = this.props;
    const content = (
      <div
        className={classNames(
          'ea-composite-list-header',
          (mobileTableLayout || showSelectAll) && 'ea-composite-list-header--mobile-table-layout',
          tight && 'ea-composite-list-header--tight',
          checkListLayout && 'ea-composite-list-header--checkListLayout',
          showSelectAll && 'ea-composite-list-header--selectAll',
          classes
        )}
      >
        {showSelectAll && (
          <div className="ea-composite-list-header__column ea-composite-list-header__column--mobile-table-layout">
            <CL.Checkbox
              id="select_all"
              name="select_all"
              checked={selectAll}
              key={`${selectAll}`}
              onChange={e => onClickSelectAll?.(e.target.checked)}
            >
              <div className="ea-composite-list-header__select-all-label ea-composite-list-header__column-heading">
                {t.A8VA('Select all')}
              </div>
            </CL.Checkbox>
          </div>
        )}
        {columns.map(({ columnClasses, columnId, heading, headingOptionMenu, sortable, wide, semiWide }) => (
          <div
            className={classNames(
              'ea-composite-list-header__column',
              columnClasses,
              sortable && 'ea-composite-list-header__column--sortable',
              wide && 'ea-composite-list-header__column--wide',
              semiWide && 'ea-composite-list-header__column--semi-wide',
              mobileTableLayout && 'ea-composite-list-header__column--mobile-table-layout'
            )}
            key={`clh_${columnId}`}
            id={`clh-${columnId}`}
            onClick={() =>
              sortable &&
              onSort &&
              onSort({
                columnId: columnId,
                order: sort && sort.columnId === columnId && sort.order === 'desc' ? 'asc' : 'desc',
              })
            }
            onKeyPress={(event: KeyboardEvent) => {
              onEnterOrSpaceKeyPress(
                event,
                () =>
                  sortable &&
                  onSort &&
                  onSort({
                    columnId: columnId,
                    order: sort && sort.columnId === columnId && sort.order === 'desc' ? 'asc' : 'desc',
                  })
              );
            }}
            role="button"
            aria-label="order by"
            tabIndex={sortable ? 0 : -1}
          >
            {headingOptionMenu && (
              <CL.Dropdown
                className="light"
                items={headingOptionMenu.options.map(item => ({ label: item, value: item }))}
                selectedValue={headingOptionMenu.value}
                onValueChange={el => headingOptionMenu.onChange(el.innerText)}
              />
            )}
            {!headingOptionMenu && (
              <div
                className={classNames(
                  'ea-composite-list-header__column-heading',
                  sortable && 'ea-composite-list-header__column-heading--sortable'
                )}
              >
                {heading}
                {sortable && (
                  <span
                    className={`ea-composite-list-header__column-postfix-icon ea-icon ea-icon--font-reset ea-icon--table-order ${
                      !sort || sort.columnId !== columnId ? 'ea-hidden' : ''
                    } ${sort && sort.order && sort.order === 'desc' ? 'ea-icon--flipped' : ''}`}
                  />
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    );

    const gridProps: RP.FluidGridItemProps = showSelectAll
      ? { phone: 6, tablet: 12 }
      : { phonePush: 1, tabletPush: 1, phone: 5, tablet: 11 };

    return checkListLayout ? (
      <RP.FluidGrid>
        <RP.FluidGridItem {...gridProps}>{content}</RP.FluidGridItem>
      </RP.FluidGrid>
    ) : (
      content
    );
  }
}
