import type { OFQuestionType } from '../../generated/api/oFQuestionType.js';
import type { OpenFormQuestion } from '../../generated/api/openFormQuestion.js';

import './OpenForm.scss';

export type OFFieldApiName = (typeof OF)[keyof typeof OF];

export const OF = {
  BILLING_ACCOUNT: 'Billing_Account__c',
  BILLING_ACCOUNT_DATA: 'Billing_Account_Data__c',
  DESCRIPTION: 'description__c',
  DESIRED_DELIVERY_DATE: 'Desired_Delivery_Date__c',
  DESIRED_DELIVERY_TIME: 'Desired_Delivery_Time__c',
  INSTALLATION_ADDRESS: 'Installation_Address__c',
  INSTALLATION_CONTACT: 'Installation_Contact__c',
  ONE_TIME_CHARGE: 'one_time_charge__c',
  ORDERING_CONTACT: 'Ordering_Contact__c',
  PRODUCT_LINE: 'Product_line__c',
  RECURRING_CHARGE: 'recurring_charge__c',
  TECHNICAL_CONTACT: 'Technical_Contact__c',
} as const;

/**
 * Check if `guid` matches `{ guid?: string }` in `target`.
 * Or if `guid` is `undefined`, check if `{ guid?: string }` is truthy.
 * @param guid
 */
export const hasGUID =
  (guid?: string) =>
  <T extends { guid?: string }>(target?: T): target is T =>
    guid ? guid === target?.guid : !!target?.guid;

export const hasFieldApiName = (name: OFFieldApiName, guid?: string) => (question?: OpenFormQuestion) =>
  hasGUID(guid)(question) && name === question.fieldApiName;

export const hasQuestionType = (type: OFQuestionType, guid?: string) => (question?: OpenFormQuestion) =>
  hasGUID(guid)(question) && type === question.type;
