import { FormProvider, useForm } from 'react-hook-form';
import { SelectedPurposeOfUseOrContact } from '../../common/enums.js';
import { getPbxDirectoryDetails, getVoiceDirectoryDetails } from './UserNumberPublicityUtils.js';
import React from 'react';
import type { CompanyInfoState } from '../../common/types/states.js';
import type { DirectoryDetails } from '../../generated/api/directoryDetails.js';
import type {
  DirectoryListingCorporateNumberPublicity,
  DirectoryListingMobileNumberPublicity,
  PurposeOfUseOrContact,
} from '../../common/types/subscription.js';
import type { SubscriptionDetailsMobile } from '../../generated/api/subscriptionDetailsMobile.js';

export interface FormWrapperProps {
  children: JSX.Element;
  purposeOfUseOrContact: PurposeOfUseOrContact;
  onSuccess: (data: UserNumberAndPublicityFormValues, e?: React.BaseSyntheticEvent) => void;
  numberPrivacyMobile?: SubscriptionDetailsMobile.DirectoryListingEnum;
  numberPrivacyCorporate?: DirectoryDetails.NumberDirectoryEnum;
  companyInfo?: CompanyInfoState | null;
}

export type UserNumberAndPublicityFormValues = {
  contactId?: string;
  purposeOfUse?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  costCenter?: string;
  employeeNumber?: string;
  selected?: SelectedPurposeOfUseOrContact;
  directoryListingMobileNumberPublicity?: DirectoryListingMobileNumberPublicity;
  directoryListingCorporateNumberPublicity?: DirectoryListingCorporateNumberPublicity;
  numberPrivacyMobile?: DirectoryDetails.NumberDirectoryEnum;
  numberPrivacyCorporate?: DirectoryDetails.NumberDirectoryEnum;
};

export const UserNumberAndPublicityFormWrapper = ({
  children,
  purposeOfUseOrContact,
  onSuccess,
  numberPrivacyMobile,
  numberPrivacyCorporate,
  companyInfo,
}: FormWrapperProps) => {
  const selectedDefaultValue =
    purposeOfUseOrContact.purposeOfUse && !purposeOfUseOrContact.contactId
      ? SelectedPurposeOfUseOrContact.PURPOSEOFUSE
      : SelectedPurposeOfUseOrContact.CONTACT;
  const methods = useForm<UserNumberAndPublicityFormValues>({
    mode: 'all',
    shouldFocusError: true,
    defaultValues: {
      selected: purposeOfUseOrContact.selected ?? selectedDefaultValue,
      contactId: purposeOfUseOrContact.contactId,
      purposeOfUse: purposeOfUseOrContact.contactId ? undefined : purposeOfUseOrContact.purposeOfUse,
      firstName: purposeOfUseOrContact.firstName,
      lastName: purposeOfUseOrContact.lastName,
      phoneNumber: purposeOfUseOrContact.phoneNumber,
      email: purposeOfUseOrContact.email,
      costCenter: purposeOfUseOrContact.costCenter,
      employeeNumber: purposeOfUseOrContact.employeeNumber,
      directoryListingMobileNumberPublicity: getVoiceDirectoryDetails(purposeOfUseOrContact, companyInfo),
      directoryListingCorporateNumberPublicity: getPbxDirectoryDetails(purposeOfUseOrContact, companyInfo),
      numberPrivacyMobile: numberPrivacyMobile,
      numberPrivacyCorporate: numberPrivacyCorporate,
    },
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSuccess)} noValidate>
        {children}
      </form>
    </FormProvider>
  );
};
