import * as CL from '@design-system/component-library';
import { InputComponent } from '../components/InputComponent.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { fieldCantBeEmptyMsg, passwordMsg, t } from '../../i18n/index.js';
import { useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import type { OptionalInputComponentProps } from '../components/InputComponent.js';

interface TogglePasswordButtonProps {
  isHidden: boolean;
  setIsHidden: Dispatch<SetStateAction<boolean>>;
}

const TogglePasswordButton = ({ isHidden, setIsHidden }: TogglePasswordButtonProps) => {
  return (
    <button
      onClick={e => {
        e.preventDefault();
        setIsHidden(value => !value);
      }}
      className={dsClass.INPUT_INPUTAREA_VIEWBUTTON}
      style={{
        position: 'absolute',
        right: 0,
        top: 0,
        padding: '12px',
        width: '48px',
        height: '48px',
        border: 'none',
        background: 'none',
      }}
    >
      {isHidden && <CL.Icon icon="view" type="filled" size="m" />}
      {!isHidden && <CL.Icon icon="view-off" type="filled" size="m" />}
    </button>
  );
};

export const Password = ({
  name = 'password',
  type = 'password',
  label = t.HCCZ(passwordMsg),
  placeholder = t.HCCZ(passwordMsg),
  required = true,
  disabled = false,
  ...rest
}: OptionalInputComponentProps) => {
  const [isHidden, setIsHidden] = useState(true);
  const validate = (value: string) => {
    if (required && !value) {
      return t.VPVR(fieldCantBeEmptyMsg);
    }
    return undefined;
  };

  return (
    <InputComponent
      autocomplete="off"
      validate={validate}
      name={name}
      type={type === 'password' ? (isHidden ? 'password' : 'text') : type}
      label={label}
      placeholder={placeholder}
      required={required}
      disabled={disabled}
      {...rest}
    >
      {type === 'password' && <TogglePasswordButton isHidden={isHidden} setIsHidden={setIsHidden} />}
    </InputComponent>
  );
};
