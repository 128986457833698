import { fieldCantBeEmptyMsg, invalidValueMsg, t } from '../i18n/index.js';
import type { FormikErrors, FormikValues } from 'formik';

export interface ValidationMessages {
  required?: () => string;
  pattern?: () => string;
  validationFn?: () => string;
}

export interface FieldValidations {
  required?: boolean;
  pattern?: RegExp;
  validationFn?: (value: string, values: object) => boolean;
  messages?: ValidationMessages;
}

const emptyErrorMessage = () => t.VPVR(fieldCantBeEmptyMsg);
const patternErrorMessage = () => t.DUPA(invalidValueMsg);

export const validateForm = (values: FormikValues, fieldValidations: { [s: string]: FieldValidations }) => {
  const errors: FormikErrors<FormikValues> = {};
  for (const [key, value] of Object.entries(values)) {
    const validationRules = fieldValidations[key];
    if (validationRules) {
      const messageFns = fieldValidations[key]?.messages || {};
      if (validationRules.required && !value) {
        errors[key] = messageFns.required ? messageFns.required() : emptyErrorMessage();
      } else {
        if (validationRules.validationFn && !validationRules.validationFn(value, values)) {
          errors[key] = messageFns.validationFn ? messageFns.validationFn() : patternErrorMessage();
        } else if (validationRules.pattern && !validationRules.pattern?.test(value)) {
          errors[key] = messageFns.pattern ? messageFns.pattern() : patternErrorMessage();
        }
      }
    }
  }
  return errors;
};

export const requiredValidation = (value: string) => (!value ? emptyErrorMessage() : undefined);
