import { isInBrowser } from '../../common/utils/ssrUtils.js';
import { useEffect, useRef } from 'react';
import type { PropsWithChildren } from 'react';

export interface ImpressionProps {
  onImpression: () => void;
  multiple?: boolean;
  timeThreshold?: number; // milliseconds
  visibilityThreshold?: number; // between 0 and 1
}

/* Unfortunately this creates a wrapper DOM element that might have side-effects.
 * This can't really be avoided, as doing this as a HOF that would apply the
 * impression tracking to the existing component would require us to add ref-
 * handling to each function component that we'd want to apply it to. On top
 * of that, it would fail silently if we forgot to handle the forwarded ref.
 */
export const Impression = ({
  children,
  onImpression,
  multiple,
  timeThreshold,
  visibilityThreshold,
  ...divProps
}: PropsWithChildren<ImpressionProps> & Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>) => {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const sentRef = useRef<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const visibilityStartRef = useRef<null | number>(null);
  const timeoutRef = useRef<null | number>(null);
  const handleObservation = ([entry]: IntersectionObserverEntry[]) => {
    if (sentRef.current) {
      return;
    }
    const now = new Date().getTime();
    if (entry.isIntersecting) {
      if (visibilityStartRef.current == null) {
        visibilityStartRef.current = now;
        timeoutRef.current = window.setTimeout(() => {
          if (!multiple) {
            sentRef.current = true;
          }
          onImpression();
        }, timeThreshold || 500);
      }
    } else {
      if (timeoutRef.current != null) {
        window.clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
      visibilityStartRef.current = null;
    }
  };

  useEffect(() => {
    if (observerRef.current != null) {
      observerRef.current.disconnect();
    }
    if (isInBrowser() && 'IntersectionObserver' in window) {
      const currentObserver = new IntersectionObserver(handleObservation, {
        threshold: visibilityThreshold || 0.5,
      });
      observerRef.current = currentObserver;
      const currentWrapper = wrapperRef.current;
      if (currentWrapper != null) {
        currentObserver.observe(currentWrapper);
      }
    }

    return () => {
      if (observerRef.current != null) {
        observerRef.current.disconnect();
      }
    };
  }, [wrapperRef]); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div {...divProps} ref={wrapperRef}>
      {children}
    </div>
  );
};
