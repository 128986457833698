import { OpenFormButton } from './OpenFormButton.js';
import { OpenFormError } from './OpenFormError.js';
import { OpenFormGrid, OpenFormGridCol, OpenFormGridRow } from './OpenFormGrid.js';
import { OpenFormIcon } from './OpenFormIcon.js';
import { OpenFormLoadingIndicator } from './OpenFormLoadingIndicator.js';
import { OpenFormNotifications } from './OpenFormNotifications.js';
import { type ReactNode, useMemo } from 'react';
import { cancelMsg, confirmMsg, t } from '../../../common/i18n/index.js';
import { useNavigate } from 'react-router-dom';
import { useOpenFormAsync, useOpenFormState } from '../OpenFormProvider.js';

export const OpenFormBar = ({
  back,
  backText,
  clear,
  clearText,
  children,
  critical,
  heading,
  preview,
}: {
  back?: string;
  backText?: ReactNode;
  clear?: () => void;
  clearText?: ReactNode;
  children?: ReactNode;
  critical?: string;
  heading?: string;
  preview?: boolean;
}) => {
  const { confirm, disabled, error, notifications } = useOpenFormState();
  const async = useOpenFormAsync();
  const navigate = useNavigate();
  const enabled = useMemo(() => !disabled && !heading, [disabled, heading]);

  return (
    <OpenFormGrid className="of-openform">
      <OpenFormGridRow className="of-openform__header">
        <OpenFormGridCol className="of-openform__header__content">
          {notifications.size ? null : confirm ? (
            <>
              <OpenFormButton
                className="of-openform__header__content__button"
                icon={<OpenFormIcon className="margin-baseline--right" icon="close" />}
                iconPosition="left"
                text={t.B2V1(cancelMsg)}
                color="link"
                size="m"
                disabled={false}
                onClick={() => async.actions.confirm(false)}
              />
              <OpenFormButton
                className="of-openform__header__content__button color-danger"
                icon={<OpenFormIcon className="margin-baseline--left" icon="check" />}
                iconPosition="right"
                text={t.QVYK(confirmMsg)}
                color="link"
                size="m"
                disabled={false}
                onClick={() => clear?.() || async.actions.confirm(false)}
              />
            </>
          ) : (
            <>
              <OpenFormButton
                className="of-openform__header__content__button"
                icon={<OpenFormIcon className="margin-baseline--right" icon="arrow-left" />}
                iconPosition="left"
                text={backText ?? <>&nbsp;</>}
                color="link"
                size="m"
                disabled={!enabled || !backText || preview}
                onClick={() => back && navigate(back, { replace: true })}
              />
              <OpenFormButton
                className="of-openform__header__content__button"
                icon={<OpenFormIcon className="margin-baseline--left" icon="delete" type="regular" />}
                iconPosition="right"
                text={clearText ?? <>&nbsp;</>}
                color="link"
                size="m"
                disabled={!enabled || !clearText || !clear}
                onClick={() => async.actions.confirm(true)}
              />
            </>
          )}
        </OpenFormGridCol>
      </OpenFormGridRow>
      <OpenFormGridRow className="of-openform__title">
        <OpenFormGridCol className="of-openform__title__content">
          {notifications.size ? (
            <OpenFormNotifications notifications={notifications} />
          ) : (
            <OpenFormLoadingIndicator heading={heading} />
          )}
        </OpenFormGridCol>
      </OpenFormGridRow>
      {critical ? (
        <OpenFormError back={back} critical={true} error={critical} />
      ) : error ? (
        <OpenFormError back={back} critical={false} error={error} />
      ) : (
        children
      )}
    </OpenFormGrid>
  );
};
