/**
 * Online UI API
 * # Summary Elisa B2B Online UI API  # Usage This API is used in the `https://yrityksille.elisa.fi/` pages.  # Identifiers 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Auxiliary eSim order details
 */
export interface AuxiliaryEsimOrder { 
    /**
     * String encrypted using ui-api\'s public certificate containing iccid, eid and device type. Format of decrypted string: iccid=<iccid>&eid=<eid>&deviceType=<deviceType>
     */
    subscriptionToken: string;
    /**
     * IMSI of the primary device
     */
    primaryImsi: string;
    /**
     * Mobile number (MSISDN) of the primary device
     */
    mobileNumber: string;
    /**
     * Subscription source system
     */
    sourceSystem: AuxiliaryEsimOrder.SourceSystemEnum;
}
export namespace AuxiliaryEsimOrder {
    export type SourceSystemEnum = 'TELLUS';
    export const SourceSystemEnum = {
        TELLUS: 'TELLUS' as SourceSystemEnum
    };
}


