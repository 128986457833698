import {
  AuthenticatedUserRole,
  ConflictedAuthenticationResult,
  IdentityProvider,
  PostReviewRequest,
  SubscriptionType,
} from '../../generated/api/models.js';
import { CommonErrorType, DialogType, SubscriptionCategory, SwitchAccountJourneyPoint } from '../../common/enums.js';
import { EMPTY, empty, of } from 'rxjs';
import {
  TypeKeys,
  authValidate,
  changePasswordFailedAction,
  changePasswordFulfilledAction,
  checkValidEmployeeSubscription,
  getElisaIdAccessToken,
  getEmployeeSubscriptionsFailed,
  getEmployeeSubscriptionsFulfilled,
  getUserFailed,
  getUserFulfilled,
  identifyStronglyFailed,
  identifyStronglyFulfilled,
  loadCompanyInfo,
  logoutFailed,
  logoutFulfilled,
  resendEmailVerificationLinkFailed,
  resendEmailVerificationLinkFulfilled,
  reviewFailed,
  reviewFulfilled,
  setActiveAccount,
  showDialog,
  stopPollForMyselfChanges,
  switchAccountFailed,
  switchAccountFulfilled,
  updateUserDetailsFailed,
  updateUserDetailsFulfilled,
} from '../actions/index.js';
import { actionToActionState, clearOnboardingId, getMdmIdHeaderFromUrlParams, getOnboardingId } from './epicUtils.js';
import { callUiApi, prepareUiApiRequest } from '../common/uiApiUtils.js';
import {
  changePasswordPrivateMethod,
  getMyselfUserPrivateMethod,
  getSubscriptionsPrivateMethod,
  identifyStronglyPrivateMethod,
  logOutPrivateMethod,
  resendVerifyEmailLinkPrivateMethod,
  reviewAsEmployerPrivateMethod,
  switchAccountPrivateMethod,
  updateMyselfContactPrivateMethod,
} from '../../generated/api/uiApiMethods.js';
import {
  clearElisaIdSessionData,
  getActiveAccountMasterId,
  removeActiveAccountMasterId,
  removeSwitchToAccountName,
  setActiveAccountMasterId,
  setSwitchToAccountName,
} from '../common/localStorageUtils.js';
import { combineEpics, ofType } from 'redux-observable';
import { concatMap, filter, map, mergeMap } from 'rxjs/operators';
import { isEmployeePortal } from '../../common/utils/browserUtils.js';
import { logOutFromChat } from '../../components/SnapInChat/chatUtils.js';
import { notAbleToProcessContactRequestMsg, t } from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import { push } from 'redux-first-history';
import type { Action } from 'redux';
import type { ActionAndState, EpicDependencies } from './epicUtils.js';
import type { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import type { AjaxResponse } from 'rxjs/ajax';
import type {
  AuthValidateFulfilledAction,
  ChangePasswordAction,
  GetEmployeeSubscriptionsAction,
  GetEmployeeSubscriptionsFulfilledAction,
  IdentifyStronglyAction,
  IdentifyStronglyFulfilledAction,
  LoadCompanyInfoAction,
  LogoutAction,
  LogoutFulfilledAction,
  ProcessElisaIdLoginFailedAction,
  ProcessElisaIdLoginFulfilledAction,
  ResendEmailVerificationLinkAction,
  ReviewAction,
  ReviewFulfilledAction,
  SelfServiceActionTypes,
  SetActiveAccountAction,
  SwitchAccountAction,
  UpdateUserDetailsAction,
  ValidateEmployeeSubscriptionFailedAction,
} from '../actions/index.js';
import type {
  ChangePasswordRequest,
  SubscriptionRedeemReview,
  SubscriptionsResponse,
} from '../../generated/api/models.js';
import type { CommonError } from '../../common/types/errors.js';
import type { DialogParams } from '../../common/types/dialog.js';
import type { State } from '../common/store.js';

// state key
export const AUTHENTICATED = 'authenticated';

const getEmployeeSubscriptionsQueryParams = (subscriptionType: SubscriptionCategory) => {
  const EMPLOYEE_SUBSCRIPTION_TYPES = new Map<SubscriptionCategory, SubscriptionType[]>([
    [SubscriptionCategory.DEVICE, [SubscriptionType.DEVICE]],
    [SubscriptionCategory.VOICE, [SubscriptionType.MOBILE]],
    [SubscriptionCategory.BROADBAND, [SubscriptionType.BROADBAND, SubscriptionType.MOBILE_BROADBAND]],
    [SubscriptionCategory.SERVICE, [SubscriptionType.SOFTWARE_PRODUCT]],
  ]);

  return {
    details: true,
    subscriptionType: EMPLOYEE_SUBSCRIPTION_TYPES.get(subscriptionType),
  };
};

const getjourneyPoint = (action: ValidateEmployeeSubscriptionFailedAction | ProcessElisaIdLoginFailedAction) => {
  if (action.type === TypeKeys.VALIDATE_EMPLOYEE_SUBSCRIPTION_PAIRING_FAILED) {
    return SwitchAccountJourneyPoint.MOBILE_SUB_PAIRING;
  } else if (action.type === TypeKeys.PROCESS_ELISAID_LOGIN_FAILED) {
    return SwitchAccountJourneyPoint.ONBOARDING;
  }
  return undefined;
};

export const getConflictingErrors = (
  errors?: CommonError[]
): { type: ConflictedAuthenticationResult.TypeEnum; conflictingAccountName?: string } | undefined => {
  const { conflictingAccountName, type } = (errors?.[0].source?.error || {}) as ConflictedAuthenticationResult;
  switch (type) {
    case ConflictedAuthenticationResult.TypeEnum.CONFLICTING_ACCOUNT:
      return { type, conflictingAccountName };
    case ConflictedAuthenticationResult.TypeEnum.DUPLICATE_CONTACT:
    case ConflictedAuthenticationResult.TypeEnum.POSSIBLE_CONTACT_DUPLICATE:
    case ConflictedAuthenticationResult.TypeEnum.CONTACT_SUPPORT:
    case ConflictedAuthenticationResult.TypeEnum.USER_NOT_FOUND:
      return { type };
    default:
      return undefined;
  }
};

export const getDuplicateContactDialogParams = (): DialogParams => ({
  type: DialogType.DUPLICATE_CONTACT,
  header: t.AYKR('Please contact customer service'),
  body: t.EGFA(notAbleToProcessContactRequestMsg),
});

export const getDuplicateContactDialogForCheckoutParams = (): DialogParams => ({
  type: DialogType.GENERIC_INFO_DIALOG,
  header: t.CVJF('We found a user with similar contact details'),
  body: t.RY1J(`We recommend to use unique email and phone number or select user from your company's user list.`),
});

export const getUserRightsInSalesforceDialogParams = (): DialogParams => ({
  type: DialogType.USER_RIGHTS_IN_SALESFORCE,
});

// Change User Password
const changePasswordEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.CHANGE_PASSWORD)), (action: ChangePasswordAction) =>
    actionToActionState(action, state$, AUTHENTICATED)
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const changePasswordAction = actionAndState.action as ChangePasswordAction;
      if (!actionAndState.state && !changePasswordAction.payload) {
        throw new Error('invalid action state for updating user details, payload is missing');
      }
      const changePasswordPayload: ChangePasswordRequest = {
        currentPassword: changePasswordAction.payload.currentPassword!,
        password: changePasswordAction.payload.confirmNewPassword!,
      };
      return callUiApi({
        epicDependencies,
        state$,
        method: changePasswordPrivateMethod(),
        payload: changePasswordPayload,
        successAction: () => changePasswordFulfilledAction(),
        failureAction: changePasswordFailedAction,
      });
    })
  );

// Get User
export const getUserEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.GET_USER)), (action: LoadCompanyInfoAction) =>
    actionToActionState(action, state$, AUTHENTICATED)
  ).pipe(
    concatMap(() => {
      return callUiApi({
        epicDependencies,
        state$,
        method: getMyselfUserPrivateMethod(),
        successAction: getUserFulfilled,
        failureAction: getUserFailed,
      });
    })
  );

// Update UserDetails
const updateUserDetailsEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.UPDATE_USER_DETAILS)), (action: UpdateUserDetailsAction) =>
    actionToActionState(action, state$, AUTHENTICATED)
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const updateUserDetailsAction = actionAndState.action as UpdateUserDetailsAction;
      const userState = state$.value.user;
      const isCookieConsentUpdated =
        userState && userState.authenticated && updateUserDetailsAction.payload.analyticsConsent
          ? userState.authenticated.analyticsConsent !== updateUserDetailsAction.payload.analyticsConsent
          : false;
      if (!actionAndState.state && !updateUserDetailsAction.payload) {
        throw new Error('invalid action state for updating user details, payload is missing');
      }
      const onboardingId = getOnboardingId() || undefined;
      return callUiApi({
        epicDependencies,
        state$,
        method: updateMyselfContactPrivateMethod({ onboardingId, forceUpsert: isCookieConsentUpdated }),
        payload: updateUserDetailsAction.payload,
        successAction: (ajaxResponse: AjaxResponse) => {
          clearOnboardingId();
          const isUserOnboarded: boolean = ajaxResponse?.status === 201;
          return updateUserDetailsFulfilled(updateUserDetailsAction.payload, isCookieConsentUpdated, isUserOnboarded);
        },
        failureAction: updateUserDetailsFailed,
      }).pipe(
        concatMap(action => {
          if (onboardingId && !userState?.authenticated?.companyName) {
            return [action, authValidate()];
          }
          return of(action);
        })
      );
    })
  );

// SSO Logout
const logoutEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.LOG_OUT)), (action: LogoutAction) =>
    actionToActionState(action, state$, AUTHENTICATED)
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const action = actionAndState.action as LogoutAction;
      if (state$ && state$.value.user && state$.value.user.authenticated) {
        return callUiApi({
          epicDependencies,
          state$,
          method: logOutPrivateMethod(),
          successAction: () => {
            removeActiveAccountMasterId();
            return logoutFulfilled(action.redirectTo);
          },
          failureAction: logoutFailed,
        });
      } else {
        return of(logoutFulfilled());
      }
    }),
    concatMap((action: LogoutFulfilledAction) => [stopPollForMyselfChanges(), action])
  );

const checkAndRedirectAfterLogoutEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>
) =>
  action$.ofType(TypeKeys.LOGOUT_FULFILLED).pipe(
    mergeMap((action: LogoutFulfilledAction) => {
      logOutFromChat(Boolean(action.redirectTo));
      if (action.redirectTo) {
        window.location.href = action.redirectTo;
      }
      return EMPTY;
    })
  );

const closeChatIfLogoutFailedEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>
) =>
  action$.ofType(TypeKeys.LOGOUT_FAILED).pipe(
    mergeMap((action: LogoutFulfilledAction) => {
      logOutFromChat(Boolean(action.redirectTo));
      return EMPTY;
    })
  );

// get user subscriptions
const getEmployeeSubscriptionsEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(
    action$.pipe(ofType(TypeKeys.GET_EMPLOYEE_SUBSCRIPTIONS)),
    (action: GetEmployeeSubscriptionsAction) => actionToActionState(action, state$, AUTHENTICATED)
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      if (!state$.value.user?.authenticated?.businessId) {
        return EMPTY;
      }
      const action = actionAndState.action as GetEmployeeSubscriptionsAction;
      return callUiApi({
        epicDependencies,
        state$,
        method: getSubscriptionsPrivateMethod(getEmployeeSubscriptionsQueryParams(action.subscriptionType)),
        successAction: (response: SubscriptionsResponse) =>
          getEmployeeSubscriptionsFulfilled(response, action.subscriptionType, action.subscriptionItemId),
        failureAction: getEmployeeSubscriptionsFailed,
      });
    })
  );

const completeGetEmployeeSubscriptionsEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>
) =>
  action$.ofType(TypeKeys.GET_EMPLOYEE_SUBSCRIPTIONS_FULLFILLED).pipe(
    mergeMap((action: GetEmployeeSubscriptionsFulfilledAction) => {
      if (action.subscriptionItemId !== undefined) {
        return of(checkValidEmployeeSubscription(action.subscriptionType, action.subscriptionItemId));
      }
      return empty();
    })
  );

const reviewEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.REVIEW)), (action: ReviewAction) =>
    actionToActionState(action, state$, AUTHENTICATED)
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const reviewAction = actionAndState.action as ReviewAction;
      if (!reviewAction.request) {
        throw new Error('invalid action state for review, payload is missing');
      }
      const subscriptionId =
        reviewAction.reviewType === PostReviewRequest.ReviewTypeEnum.SUBSCRIPTION_REDEEM
          ? (reviewAction.request as SubscriptionRedeemReview).subscriptionId
          : undefined;
      return callUiApi({
        epicDependencies,
        state$,
        method: reviewAsEmployerPrivateMethod(),
        headers: getMdmIdHeaderFromUrlParams(),
        payload:
          reviewAction.reviewType === PostReviewRequest.ReviewTypeEnum.ONLINE_ORDER
            ? {
                onlineOrder: reviewAction.request,
                reviewType: reviewAction.reviewType,
                approve: reviewAction.approve,
                rejectReason: reviewAction.rejectReason,
              }
            : {
                subscriptionRedeem: reviewAction.request,
                reviewType: reviewAction.reviewType,
                approve: reviewAction.approve,
                rejectReason: reviewAction.rejectReason,
              },
        successAction: () =>
          reviewFulfilled(
            reviewAction.reviewType,
            reviewAction.approve,
            subscriptionId,
            reviewAction.customerOrderDisplayId,
            reviewAction.pendingSubscriptionActions
          ),
        failureAction: reviewFailed,
      });
    })
  );

export const completeReviewFulfilledEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>
) =>
  action$.pipe(
    ofType(TypeKeys.REVIEW_FULFILLED),
    filter(() =>
      state$.value.user && state$.value.user.authenticated
        ? state$.value.user.authenticated.errors === undefined
        : false
    ),
    map((action: ReviewFulfilledAction) => {
      if (isEmployeePortal(window.location.pathname)) {
        if (action.reviewType === PostReviewRequest.ReviewTypeEnum.SUBSCRIPTION_REDEEM) {
          return push(paths.EMPLOYEE_CHANGE_REQUEST_ORDERS);
        } else {
          return push(paths.EMPLOYEE_ORDERS);
        }
      }
      if (action.reviewType === PostReviewRequest.ReviewTypeEnum.ONLINE_ORDER) {
        return push(paths.CUSTOMER_ORDER_HOME);
      } else if (action.reviewType === PostReviewRequest.ReviewTypeEnum.SUBSCRIPTION_REDEEM) {
        return push(paths.CUSTOMER_ORDER_REDEEM_REQUESTS);
      } else {
        return push(paths.SELF_SERVICE_HOME);
      }
    })
  );

export const authValidateFailedEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>
) =>
  action$.pipe(
    ofType(TypeKeys.AUTH_VALIDATE_FAILED),
    mergeMap(() => {
      removeActiveAccountMasterId();
      return EMPTY;
    })
  );

export const authValidateFulfilledEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>
) =>
  action$.pipe(
    ofType(TypeKeys.AUTH_VALIDATE_FULFILLED),
    mergeMap((action: AuthValidateFulfilledAction) => {
      const actionArrays: Action[] = [];
      if (
        action.elisaIdClient &&
        state$.value.user?.authenticated?.identityProvider === IdentityProvider.ELISA_ID &&
        getOnboardingId()
      ) {
        actionArrays.push(getElisaIdAccessToken(action.elisaIdClient));
      }
      const activeAccountMasterId = getActiveAccountMasterId();
      if (!activeAccountMasterId && action.authenticationResult.account?.accountMasterId) {
        actionArrays.push(setActiveAccount(action.authenticationResult.account.accountMasterId));
      } else if (
        activeAccountMasterId &&
        activeAccountMasterId !== state$.value.user?.authenticated?.activeAccountMasterId
      ) {
        actionArrays.push(setActiveAccount(activeAccountMasterId));
      }
      if (
        isEmployeePortal(window.location.pathname) &&
        state$.value.user?.authenticated?.identityProvider &&
        ![IdentityProvider.ELISA_ID, IdentityProvider.ELISA_ID_V2].includes(
          state$.value.user?.authenticated?.identityProvider
        )
      ) {
        actionArrays.push(
          showDialog({
            type: DialogType.SSO_FORBIDDEN,
          })
        );
      }
      if (state$.value.user?.authenticated?.userRole === AuthenticatedUserRole.KEY_USER) {
        actionArrays.push(loadCompanyInfo());
      }
      return actionArrays;
    })
  );

export const completeProcessElisaIdLoginEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>
) =>
  action$.pipe(
    ofType(TypeKeys.PROCESS_ELISAID_LOGIN_FULFILLED),
    mergeMap((action: ProcessElisaIdLoginFulfilledAction) => {
      if (action.authenticationResult.account?.businessId) {
        if (getOnboardingId()) {
          clearOnboardingId();
        }
        clearElisaIdSessionData();
      }
      return EMPTY;
    })
  );

export const onProcessElisaIdLoginFailedEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>
) =>
  action$.pipe(
    ofType(TypeKeys.PROCESS_ELISAID_LOGIN_FAILED),
    mergeMap((action: ProcessElisaIdLoginFailedAction) => {
      if (
        action.errors?.some(
          error =>
            error.type === CommonErrorType.BAD_REQUEST ||
            error.type === CommonErrorType.NOT_FOUND ||
            error.category === ConflictedAuthenticationResult.TypeEnum.ONBOARDING_LINK_OLD_OR_INVALID ||
            error.category === ConflictedAuthenticationResult.TypeEnum.ONBOARDING_LINK_USED_OR_EXPIRED
        )
      ) {
        clearOnboardingId();
        clearElisaIdSessionData();
      }
      return EMPTY;
    })
  );

// Order a new link to verify email
export const resendEmailVerificationLinkEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(
    action$.pipe(ofType(TypeKeys.RESEND_EMAIL_VERIFICATION_LINK)),
    (action: ResendEmailVerificationLinkAction) => actionToActionState(action, state$, AUTHENTICATED)
  ).pipe(
    concatMap(() => {
      return callUiApi({
        epicDependencies,
        state$,
        method: resendVerifyEmailLinkPrivateMethod(),
        successAction: resendEmailVerificationLinkFulfilled,
        failureAction: resendEmailVerificationLinkFailed,
      });
    })
  );

export const setActiveAccountEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>
) =>
  action$.pipe(
    ofType(TypeKeys.SET_ACTIVE_ACCOUNT),
    mergeMap((action: SetActiveAccountAction) => {
      setActiveAccountMasterId(action.activeAccountMasterId);
      if (action.switchToAccountName) {
        setSwitchToAccountName(action.switchToAccountName);
      }

      if (action.redirectUrl) {
        window.location.href = action.redirectUrl;
      } else if (action.reloadWindow) {
        // Reload the window, needed for multibiz users to refresh all the company data when changing account.
        // Could be done on reducer side but we would need rootReducer.
        window.location.reload();
      } else {
        removeSwitchToAccountName();
      }

      return EMPTY;
    })
  );

// Switch account
const switchAccountEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.SWITCH_ACCOUNT)), (action: SwitchAccountAction) =>
    actionToActionState(action, state$, AUTHENTICATED)
  ).pipe(
    concatMap(() => {
      return callUiApi({
        epicDependencies,
        state$,
        method: switchAccountPrivateMethod(),
        successAction: switchAccountFulfilled,
        failureAction: switchAccountFailed,
      });
    })
  );

const onCompleteSwitchAccountEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>
) =>
  action$.pipe(
    ofType(TypeKeys.SWITCH_ACCOUNT_FULFILLED),
    mergeMap(() => {
      // always trigger 'auth/validate' after switch account fulfillment to get the new account details
      const actionArrays: Action[] = [authValidate()];
      if (decodeURI(window.location.pathname) !== paths.EMPLOYEE_HOME) {
        actionArrays.push(push(paths.EMPLOYEE_HOME));
      }
      return actionArrays;
    })
  );

const handleConflictedErrorEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>
) =>
  action$.pipe(
    ofType(
      TypeKeys.VALIDATE_EMPLOYEE_SUBSCRIPTION_PAIRING_FAILED,
      TypeKeys.PROCESS_ELISAID_LOGIN_FAILED,
      TypeKeys.UPDATE_USER_DETAILS_FAILED,
      TypeKeys.LOG_IN_FAILED
    ),
    mergeMap((action: ValidateEmployeeSubscriptionFailedAction | ProcessElisaIdLoginFailedAction) => {
      const conflictingErrors = getConflictingErrors(action.errors);

      if (conflictingErrors) {
        // clear onboarding id, because it has been consumed
        clearOnboardingId();

        switch (conflictingErrors.type) {
          case ConflictedAuthenticationResult.TypeEnum.CONFLICTING_ACCOUNT:
            return of(
              showDialog({
                type: DialogType.SWITCH_ACCOUNT,
                header: `${t.NW12('Confirm pairing subscription and user details to new employer')}${
                  conflictingErrors.conflictingAccountName ? ` (${conflictingErrors.conflictingAccountName}).` : `.`
                }`,
                body: t.KJAE(
                  'After confirmation you lose access to subscription and usage details from the duration with your previous employer.'
                ),
                journeyPoint: getjourneyPoint(action),
              })
            );
          case ConflictedAuthenticationResult.TypeEnum.DUPLICATE_CONTACT:
          case ConflictedAuthenticationResult.TypeEnum.POSSIBLE_CONTACT_DUPLICATE:
            return of(showDialog(getDuplicateContactDialogParams()));
          case ConflictedAuthenticationResult.TypeEnum.USER_NOT_FOUND:
            return of(showDialog(getUserRightsInSalesforceDialogParams()));
          default:
            return EMPTY;
        }
      }
      return EMPTY;
    })
  );

const identifyStronglyEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>,
  epicDependencies: EpicDependencies
) =>
  prepareUiApiRequest(action$.pipe(ofType(TypeKeys.IDENTIFY_STRONGLY)), (action: IdentifyStronglyAction) =>
    actionToActionState(action, state$, AUTHENTICATED)
  ).pipe(
    concatMap((actionAndState: ActionAndState) => {
      const identifyStronglyAction = actionAndState.action as IdentifyStronglyAction;
      const headers = identifyStronglyAction.state.startsWith('punchout')
        ? // eslint-disable-next-line @typescript-eslint/naming-convention
          { 'X-API-User-Role': AuthenticatedUserRole.PUNCHOUT_USER }
        : undefined;
      return callUiApi({
        epicDependencies,
        state$,
        method: identifyStronglyPrivateMethod(),
        headers,
        payload: identifyStronglyAction.request,
        successAction: () => identifyStronglyFulfilled(identifyStronglyAction.state),
        failureAction: (message, status, errors, params) =>
          identifyStronglyFailed(message, status, errors, params, identifyStronglyAction.state),
      });
    })
  );

const identifyStronglyFulfilledOrFailedEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>
) =>
  action$.pipe(
    ofType(TypeKeys.IDENTIFY_STRONGLY_FULFILLED, TypeKeys.IDENTIFY_STRONGLY_FAILED),
    mergeMap((action: IdentifyStronglyFulfilledAction) => {
      if (action.state.startsWith('punchout')) {
        return of(push(paths.PUNCHOUT_CHECKOUT));
      }
      return EMPTY;
    })
  );

export const authenticatedUserEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = combineEpics(
  getUserEpic,
  getEmployeeSubscriptionsEpic,
  logoutEpic,
  changePasswordEpic,
  updateUserDetailsEpic,
  completeGetEmployeeSubscriptionsEpic,
  reviewEpic,
  completeReviewFulfilledEpic,
  authValidateFailedEpic,
  authValidateFulfilledEpic,
  completeProcessElisaIdLoginEpic,
  resendEmailVerificationLinkEpic,
  setActiveAccountEpic,
  switchAccountEpic,
  onCompleteSwitchAccountEpic,
  handleConflictedErrorEpic,
  onProcessElisaIdLoginFailedEpic,
  checkAndRedirectAfterLogoutEpic,
  closeChatIfLogoutFailedEpic,
  identifyStronglyEpic,
  identifyStronglyFulfilledOrFailedEpic
);
