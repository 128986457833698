import * as CL from '@design-system/component-library';
import { PRODUCT_DETAILS_CLASS } from '../productDetailsConstants.js';
import {
  addToCartMsg,
  monthlyChargesMsg,
  monthsMsg,
  singleFaresMsg,
  t,
  totalMsg,
  vatPercentageMsg,
} from '../../../common/i18n/index.js';
import { calculatePayments, valueOrZero } from '../utils/productDetailsUtils.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { formatSum } from '../../../common/utils/priceUtils.js';
import { getVatAsString } from '../../../common/utils/taxUtils.js';
import type { CommercialProduct, Offer } from '../../../generated/api/models.js';
import type { ProductDetailsAddOnStateProperties } from './ProductDetailsAddOn.js';

import './ProductDetailsSummary.scss';

export interface ProductDetailsPriceSummaryProps {
  commercialProduct: CommercialProduct;
  offer: Offer;
  addToCart: () => void;
  quantity: number;
  isEmployee: boolean;
  addOns?: ProductDetailsAddOnStateProperties[];
}

/**
 * Show price summary always for employees. Price can be 0 even if the item is purchased
 * Normally Offer should not be needed here, but special conditions with subscription require it
 * as subscription price is created from at least two CommercialProducts
 */
export const ProductDetailsSummary = ({
  addToCart,
  commercialProduct,
  offer,
  quantity,
  isEmployee,
  addOns,
}: ProductDetailsPriceSummaryProps) => {
  const { payments, monthlyRecurringCharge, oneTimeCharge } = calculatePayments(
    commercialProduct,
    offer,
    quantity,
    addOns,
    isEmployee
  );

  const billingPeriod = commercialProduct.billingPeriod;

  return (
    <div
      className={`${PRODUCT_DETAILS_CLASS}-summary ${dsClass.FONT_SIZE_LARGE} ${dsClass.PADDING_HORIZONTAL_3} ${dsClass.PADDING_VERTICAL_3}`}
    >
      <h3 className={dsClass.H4}>{`${t.CEQ2(totalMsg)} (${t.A0OJ(
        vatPercentageMsg,
        isEmployee ? getVatAsString() : '0'
      )})`}</h3>
      {valueOrZero(monthlyRecurringCharge) > 0 || isEmployee ? (
        <div className={`${PRODUCT_DETAILS_CLASS}-summary__price ${dsClass.MARGIN_TOP_4}`}>
          <span>
            <span className={dsClass.DISPLAY_BLOCK}>
              {isEmployee ? t.HQC3('Monthly charges to you') : t.P6BC(monthlyChargesMsg)}
            </span>
            <span className={dsClass.DISPLAY_BLOCK}>
              {isEmployee && payments ? `(${payments}${t.XXVX(monthsMsg)})` : ''}
            </span>
          </span>
          <span className={`${dsClass.H4} ${dsClass.WHITE_SPACE_NOWRAP}`}>
            {billingPeriod
              ? `${formatSum(monthlyRecurringCharge)}/${billingPeriod} ${t.XXVX(monthsMsg)}`
              : formatSum(monthlyRecurringCharge)}
          </span>
        </div>
      ) : null}
      {valueOrZero(oneTimeCharge) > 0 ? (
        <div className={`${PRODUCT_DETAILS_CLASS}-summary__price ${dsClass.MARGIN_TOP_4}`}>
          <span>{t.GOBY(singleFaresMsg)}</span>
          <span className={`${dsClass.H4} ${dsClass.WHITE_SPACE_NOWRAP}`}>{formatSum(oneTimeCharge)}</span>
        </div>
      ) : null}
      {isEmployee ? (
        <div className={`${PRODUCT_DETAILS_CLASS}-summary__price ${dsClass.MARGIN_TOP_4}`}>
          <span>{t.CEQ2(totalMsg)}</span>
          <span className={`${dsClass.H4} ${dsClass.WHITE_SPACE_NOWRAP}`}>
            {formatSum(valueOrZero(monthlyRecurringCharge) * payments!)}
          </span>
        </div>
      ) : null}
      <div className={dsClass.MARGIN_TOP_4}>
        <CL.Button onClick={addToCart} className={`${PRODUCT_DETAILS_CLASS}-summary__add-to-cart`}>
          {t.JFOS(addToCartMsg)}
        </CL.Button>
      </div>
    </div>
  );
};
