import * as CL from '@design-system/component-library';
import { CommonErrorType } from '../../common/enums.js';
import { ConflictedAuthenticationResult } from '../../generated/api/models.js';
import { CustomerServiceCallLink } from '../CustomerServicePhoneCallLink/CustomerServiceCallLink.js';
import {
  backMsg,
  customerServiceNumberOpenShortMsg,
  failedToCreateDuplicateContactMsg,
  goBackHomeMsg,
  t,
} from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import type { CommonError } from '../../common/types/errors.js';

import './EmptyOrError.scss';

export interface EmptyOrErrorProps {
  bottomElement?: JSX.Element;
  buttonText?: string;
  critical?: boolean; // if true, renders deflated hot air balloon
  text: string | string[];
  id: string;
  onButtonClick?: () => void;
}

export const getNotFoundError = (): EmptyOrErrorProps => ({
  critical: false,
  id: 'not-found-error',
  text: t.TLCF('Oops… the page you searched could not be found.'),
  onButtonClick: () => window.history.back(),
  buttonText: t.D1E9(backMsg),
});

export const getGenericSystemError = (
  displayText?: string | string[],
  onButtonClick?: () => void
): EmptyOrErrorProps => ({
  bottomElement: (
    <div>
      <span>
        {t.BN1I('If the error persists, please contact customer service')}
        <strong>
          {' '}
          <CustomerServiceCallLink />
        </strong>
        .
      </span>
      <br />
      <span>{t.GVMV('Open {0}.', t.HQWU(customerServiceNumberOpenShortMsg))}</span>
    </div>
  ),
  buttonText: t.G24H(goBackHomeMsg),
  critical: true,
  id: 'system-error',
  onButtonClick,
  text: displayText ?? t.LW2K('Oops… An error occurred in the system'),
});

export const getErrorPropsFromCommonErrors = (
  errors: CommonError[] | null | undefined,
  includeValidationErrors = false,
  onToFrontPage?: () => void
): EmptyOrErrorProps | undefined => {
  if (errors) {
    const filteredErrors = includeValidationErrors
      ? errors
      : errors.filter(error => error.type !== CommonErrorType.VALIDATION);
    if (filteredErrors) {
      const systemError = filteredErrors.find(
        error => error.type === CommonErrorType.SYSTEM || error.type === CommonErrorType.BAD_REQUEST
      );
      if (systemError) {
        if (systemError.category === ConflictedAuthenticationResult.TypeEnum.ONBOARDING_LINK_USED_OR_EXPIRED) {
          systemError.displayText = t.ZT0K(
            'This link is already used or expired, please obtain a new link from administrator.'
          );
        } else if (systemError.category === ConflictedAuthenticationResult.TypeEnum.ONBOARDING_LINK_OLD_OR_INVALID) {
          systemError.displayText = t.WQCL('This link is too old or invalid, please use correct link.');
        } else if (systemError.message === failedToCreateDuplicateContactMsg) {
          return undefined;
        }
        return getGenericSystemError(systemError.displayText, onToFrontPage);
      }
      const forbiddenError = filteredErrors.find(error => error.type === CommonErrorType.FORBIDDEN);
      if (forbiddenError) {
        return {
          buttonText: t.G24H(goBackHomeMsg),
          id: 'forbidden-error',
          onButtonClick: onToFrontPage,
          text: t.F3ZQ('Oops… you do not have permission to view this feature'),
        };
      }
      const notFoundError = filteredErrors.find(error => error.type === CommonErrorType.NOT_FOUND);
      if (notFoundError) {
        return {
          buttonText: t.G24H(goBackHomeMsg),
          id: 'not-found-error',
          onButtonClick: onToFrontPage,
          text: notFoundError.displayText
            ? notFoundError.displayText
            : [t.TLCF('Oops… the page you were looking for was not found.'), t.QDTC('Please check the URL.')],
        };
      }
    }
  }
  return undefined;
};

export const EmptyOrError = ({ bottomElement, buttonText, critical, text, id, onButtonClick }: EmptyOrErrorProps) => {
  const art = critical ? 'server-error' : 'emptystage';
  const buttonModifier = onButtonClick ? 'has-button' : 'no-button';
  const textLines = Array.isArray(text) ? text : [text];
  return (
    <div id={id} className={`of-empty-or-error of-empty-or-error--${buttonModifier}`}>
      <div className={`of-empty-or-error__top of-empty-or-error__top--${art}`}>
        {textLines.map((textLine, index) => (
          <h4 className={dsClass.MARGIN_VERTICAL_2} key={`text-line-${index}`}>
            {textLine}
          </h4>
        ))}
      </div>
      {onButtonClick && buttonText && (
        <div className="of-empty-or-error__middle">
          <CL.Button size="l" onClick={onButtonClick}>
            {buttonText}
          </CL.Button>
        </div>
      )}
      {bottomElement ? <div className="of-empty-or-error__bottom">{bottomElement}</div> : undefined}
    </div>
  );
};
