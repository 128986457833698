import { Highlight } from '../Highlight/Highlight.js';
import type { PageSearchHit } from '../../generated/api/models.js';

import './PublicPagesSearchDisplayedResultItem.scss';

export interface SearchDisplayedResultItemProps {
  pageSearchHit: PageSearchHit;
  queryString: string;
}

export const PublicPagesSearchDisplayedResultItem = (props: SearchDisplayedResultItemProps) => (
  <li className="of-public-pages-search-item-row">
    <a
      className="of-public-pages-search-item-row-item"
      href={props.pageSearchHit.link}
      target="_self" // TODO: note, this can be removed after Angular & DS's navi is gone
    >
      {props.pageSearchHit.thumbnailUrl && (
        <div aria-hidden="true" className="of-public-pages-search-item-row-thumbnail">
          <img alt="" src={props.pageSearchHit.thumbnailUrl} />
        </div>
      )}
      <div className="of-public-pages-search-item-row-item-container">
        <div className="of-public-pages-search-item-row-item-title">{props.pageSearchHit.title}</div>
        <div className="of-public-pages-search-item-row-item-snippet">
          <Highlight text={props.pageSearchHit.snippet} highlights={props.queryString.split(' ')} />
        </div>
        <div className="of-public-pages-search-item-row-item-url">{props.pageSearchHit.link}</div>
      </div>
    </a>
  </li>
);
