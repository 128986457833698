import { OpenFormAction, type OpenFormDispatch, type OpenFormState } from '../OpenFormReducer.js';
import { type UseFormReturn, useForm } from 'react-hook-form';
import { useEffect } from 'react';

export const useOpenFormMethods = (state: OpenFormState, dispatch: OpenFormDispatch): UseFormReturn => {
  const methods = useForm({
    defaultValues: state.answers
      .entriesOf('choices')
      .reduce((acc: { [k: string]: string }, [guid, choices]) => Object.assign(acc, { [guid]: String(choices) }), {}),
  });

  useEffect(() => {
    state.answers.forEach(({ choices }, guid) => choices && methods.setValue(guid, String(choices)));
    methods.trigger().then(isValid => dispatch(OpenFormAction.valid(isValid)));
    const { unsubscribe } = methods.watch((data, { name, type }) => {
      if (type === 'change' && name?.includes('.') === false) {
        dispatch(OpenFormAction.choices({ guid: name, value: data[name] }));
      }
    });
    return () => unsubscribe();
  }, [state.answers, state.index, dispatch, methods]);

  return methods;
};
