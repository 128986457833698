import { TypeKeys, generateActionId } from './actionsUtils.js';
import { convertToCommonErrors } from '../../common/utils/errorUtils.js';
import type { Action } from 'redux';
import type { CrudAction, ErrorAction, ErrorActionCreator } from './actionsUtils.js';
import type {
  GenericError,
  ResetPasswordRequest,
  SendOtpToUserRequest,
  ValidateOtpRequest,
} from '../../generated/api/models.js';

export interface ForgotPasswordStepNumberAction extends Action {
  stepNumber: number;
  type: TypeKeys.FORGOT_PASSWORD_STEP_NUMBER;
}

export const updateForgotPasswordStepNumber = (stepNumber: number): ForgotPasswordStepNumberAction => {
  return {
    stepNumber: stepNumber,
    type: TypeKeys.FORGOT_PASSWORD_STEP_NUMBER,
  };
};

export interface SendOtpToUserAction extends CrudAction<TypeKeys.SEND_OTP_TO_USER> {
  data: SendOtpToUserRequest;
}

export const sendOtpToUser = (data: SendOtpToUserRequest): SendOtpToUserAction => {
  return {
    data,
    id: generateActionId(),
    type: TypeKeys.SEND_OTP_TO_USER,
  };
};

export interface SendOtpToUserFulfilledAction {
  type: TypeKeys.SEND_OTP_TO_USER_FULFILLED;
}

export const sendOtpToUserFulfilled = (): SendOtpToUserFulfilledAction => {
  return {
    type: TypeKeys.SEND_OTP_TO_USER_FULFILLED,
  };
};

export type SendOtpToUserFailedAction = ErrorAction<TypeKeys.SEND_OTP_TO_USER_FAILED>;

export const sendOtpToUserFailed: ErrorActionCreator<TypeKeys.SEND_OTP_TO_USER_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): SendOtpToUserFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    params,
    type: TypeKeys.SEND_OTP_TO_USER_FAILED,
  };
};

export interface ValidateOtpAction extends CrudAction<TypeKeys.VALIDATE_OTP> {
  data: ValidateOtpRequest;
}

export const validateOtp = (data: ValidateOtpRequest): ValidateOtpAction => {
  return {
    data,
    id: generateActionId(),
    type: TypeKeys.VALIDATE_OTP,
  };
};

export interface ValidateOtpFulfilledAction {
  type: TypeKeys.VALIDATE_OTP_FULFILLED;
}

export const validateOtpFulfilled = (): ValidateOtpFulfilledAction => {
  return {
    type: TypeKeys.VALIDATE_OTP_FULFILLED,
  };
};

export type ValidateOtpFailedAction = ErrorAction<TypeKeys.VALIDATE_OTP_FAILED>;

export const validateOtpFailed: ErrorActionCreator<TypeKeys.VALIDATE_OTP_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): ValidateOtpFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    params,
    type: TypeKeys.VALIDATE_OTP_FAILED,
  };
};

export interface ResetPasswordAction extends CrudAction<TypeKeys.RESET_PASSWORD> {
  data: ResetPasswordRequest;
}

export const resetPassword = (data: ResetPasswordRequest): ResetPasswordAction => {
  return {
    id: generateActionId(),
    data,
    type: TypeKeys.RESET_PASSWORD,
  };
};

export interface ResetPasswordFulfilledAction {
  type: TypeKeys.RESET_PASSWORD_FULFILLED;
}

export const resetPasswordFulfilled = (): ResetPasswordFulfilledAction => {
  return {
    type: TypeKeys.RESET_PASSWORD_FULFILLED,
  };
};

export interface ResetPasswordFailedAction {
  type: TypeKeys.RESET_PASSWORD_FAILED;
}

export const resetPasswordFailed = (): ResetPasswordFailedAction => {
  return {
    type: TypeKeys.RESET_PASSWORD_FAILED,
  };
};

export type AnonymousUserActionTypes =
  | ForgotPasswordStepNumberAction
  | SendOtpToUserAction
  | SendOtpToUserFulfilledAction
  | SendOtpToUserFailedAction
  | ValidateOtpAction
  | ValidateOtpFulfilledAction
  | ValidateOtpFailedAction
  | ResetPasswordAction
  | ResetPasswordFulfilledAction
  | ResetPasswordFailedAction;
