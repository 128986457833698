import * as CL from '@design-system/component-library';
import { dsClass } from '../../common/constants/dsClasses.js';
import { t } from '../../common/i18n/index.js';
import type { Offer } from '../../generated/api/models.js';

import './ProductCard.scss';

export interface ProductCardProps {
  body: JSX.Element;
  className?: string;
  footer: JSX.Element;
  headerContent?: JSX.Element;
  imgSrc?: string;
  priceHighlight?: boolean;
  priceText: string;
  productName: JSX.Element;
  title?: string;
  priceBeforeDiscount?: string;
  offer: Offer;
  showSelectButton: boolean;
  isMultipleCampaigns: boolean;
}

export const ProductCard = ({
  body,
  className,
  footer,
  headerContent,
  imgSrc,
  priceHighlight,
  priceText,
  productName,
  title,
  priceBeforeDiscount,
  offer,
  showSelectButton,
  isMultipleCampaigns,
}: ProductCardProps) => (
  <div
    className={`${className || ''} ${showSelectButton ? 'ea-product-card__big-footer' : 'ea-product-card'}`}
    id={`offer-${offer.offerCode}`}
  >
    <div className={`ea-product-card__header ${dsClass.PADDING_TOP_4}`}>{headerContent}</div>
    {imgSrc && (
      <div className="ea-product-card__image">
        <img alt="" src={imgSrc} />
      </div>
    )}
    {title && (
      <div className="ea-product-card__title">
        <h1 className="ea-h1 ea-h1--thick">{title}</h1>
      </div>
    )}
    <div className="ea-product-card__product-name">{productName}</div>
    {body}
    <div className="ea-product-card__footer ea-disclaimertext">
      {isMultipleCampaigns && <div>{t.XT3B('From')}</div>}
      <h3 className={`price-text ea-h3 ea-h3--thick ${priceHighlight ? 'price-text--highlight' : ''}`}>{priceText}</h3>
      {priceBeforeDiscount && (
        <>
          &nbsp;&nbsp;<h3 className="price-text--line-through ea-h4">{priceBeforeDiscount}</h3>
        </>
      )}
      <div className="ea-disclaimertext">{footer}</div>
      {showSelectButton && (
        <CL.Button size="l" color="linkblue" className="ea-product-card__button">
          {t.QRYV('Choose')}
        </CL.Button>
      )}
    </div>
  </div>
);
