import { PopoverContent } from './CommercialProductContent.js';
import { t } from '../../../common/i18n/index.js';

export const ReissaajaPopoverContent = () => (
  <PopoverContent>
    <div>
      <strong>Elisa Reissaaja </strong>
      {t.TIZ1(
        '500 MB is activated automatically in the target countries and offers a fast data transfer connection using a mobile terminal or laptop computer at an affordable price in countries outside the EU/EEA. The service has no monthly charge. Instead, pricing is activated based on usage at €10.90 per 1 day.'
      )}
    </div>
  </PopoverContent>
);
