import * as CL from '@design-system/component-library';
import { BreadCrumbs } from '../BreadCrumbs/index.js';
import { DetailsWrapper } from '../DetailsWrapper/index.js';
import { LinkableAccordion } from '../LinkableAccordion/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { generatePath } from 'react-router-dom';
import { omaElisaForEmployeeMsg, t, voiceSubscriptionsMsg } from '../../common/i18n/index.js';
import { parseMobileSubscriptionAttrs } from '../EmployeeSubscriptionDetails/employeeSubscriptionUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useSelector } from 'react-redux';
import { useTitle } from '../../common/hooks/useTitle.js';
import type { State } from '../../selfservice/common/store.js';
import type { SubscriptionId } from '../../common/constants/pathInterfaces.js';

import './UserCallDetails.scss';

const DisabledUserCallDetailsContent = () => {
  return (
    <div>
      <LinkableAccordion defaultOpen={false} heading={t.ORN1('Phone calls')} headingLevel="h3" id="local-call-details">
        <div className="of-user-call-details__roaming">
          <CL.Description
            id="local-call-details-no-details"
            className={`${dsClass.MARGIN_HORIZONTAL_0} ${dsClass.MARGIN_VERTICAL_3}`}
            items={[
              {
                title: t.HBUV('Phone calls temporarily unavailable due to maintenance. Sorry for the inconvenience.'),
                description: '',
              },
            ]}
          />
        </div>
      </LinkableAccordion>
      <LinkableAccordion
        defaultOpen={false}
        heading={t.MXR5('Text Messages')}
        headingLevel="h3"
        id="local-text-messages-details"
      >
        <div className="of-user-call-details__roaming">
          <CL.Description
            id="local-text-messages-details-bill-period"
            className={`${dsClass.MARGIN_HORIZONTAL_0} ${dsClass.MARGIN_VERTICAL_3}`}
            items={[
              {
                title: t.KSKI('Text messages temporarily unavailable due to maintenance. Sorry for the inconvenience.'),
                description: '',
              },
            ]}
          />
        </div>
      </LinkableAccordion>
    </div>
  );
};

export const DisabledUserCallDetails = ({ subscriptionId }: SubscriptionId) => {
  const subscriptions = useSelector((state: State) => state.user?.authenticated?.subscriptions?.voice, deepEqual);
  const subscription = subscriptions?.find(s => s.subscriptionId === subscriptionId, deepEqual);
  const mobileNumber = subscription ? parseMobileSubscriptionAttrs(subscription).mobileNumber : undefined;

  useTitle([
    t.H6JV(omaElisaForEmployeeMsg),
    t.AR0B(voiceSubscriptionsMsg),
    subscription?.subscriptionDisplayId || subscription?.subscriptionId || '',
    t.ORN1('Phone calls'),
  ]);

  return (
    <DetailsWrapper
      classes={['of-user-call-details']}
      detailsTop={
        <BreadCrumbs
          breadCrumbPaths={
            mobileNumber
              ? [
                  { name: t.H6JV(omaElisaForEmployeeMsg), path: paths.EMPLOYEE_HOME },
                  { name: t.AR0B(voiceSubscriptionsMsg), path: paths.EMPLOYEE_SUBSCRIPTIONS },
                  { name: mobileNumber, path: generatePath(paths.EMPLOYEE_VOICE_SUBSCRIPTION, { subscriptionId }) },
                  { name: t.ORN1('Phone calls') },
                ]
              : [{ name: t.H6JV(omaElisaForEmployeeMsg) }]
          }
        />
      }
      id="user-call-details"
      heading={t.T4FT('Call details')}
      headingBottom={mobileNumber}
      heroPicto="sim"
    >
      <DisabledUserCallDetailsContent />
    </DetailsWrapper>
  );
};
