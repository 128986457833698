import {
  CompanyInfoContainer,
  CompanyInfoSceneTab,
} from '../../../../../components/CompanyInfoScene/CompanyInfoScene.js';
import { ContactDetails } from '../../../../../components/ContactDetails/ContactDetails.js';
import { Loading } from '../../../../../components/Loading/index.js';
import { SystemError } from '../../../../../components/SystemError/SystemError.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useContact } from '../../../../../common/hooks/useContact.js';

export const CompanyInfoContactPath = () => {
  const { contact, errors, loading } = useContact();

  if (errors.length > 0) {
    return <SystemError errors={errors} homePath={paths.SELF_SERVICE_HOME} />;
  }

  return loading ? (
    <Loading />
  ) : (
    <CompanyInfoContainer tab={CompanyInfoSceneTab.CONTACTS} isSingular={true}>
      <ContactDetails contact={contact} />
    </CompanyInfoContainer>
  );
};
