import { ActiveStep, PunchoutCheckoutSteps } from './partials/PunchoutCheckoutSteps.js';
import { DeviceCheckoutBillSummaryHeader } from '../DeviceCheckoutBillSummaryHeader/DeviceCheckoutBillSummaryHeader.js';
import { DialogType } from '../../common/enums.js';
import { Loading } from '../Loading/index.js';
import { PunchoutCheckoutConfirmation } from './partials/PunchoutCheckoutConfirmation.js';
import { PunchoutCheckoutDialog } from './partials/PunchoutCheckoutDialog.js';
import { corporateMessagePopUpHeaderMsg, t } from '../../common/i18n/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { findPersonBillingAccount } from '../../common/utils/stateUtils.js';
import { getConfiguredCommercialProducts } from '../../common/utils/cartProductUtils.js';
import {
  getDeliveryCharges,
  getDeliveryDetailsObj,
  hasEmployeePayables,
  isHideDeliveryMethodSelection,
} from '../../common/utils/checkoutUtils.js';
import { getPublishedCatalogs } from '../../common/utils/catalogUtils.js';
import {
  getPunchoutDeviceChangeRequest,
  removePunchoutDeviceChangeRequest,
  setPunchoutDeviceChangeRequest,
} from '../../selfservice/common/localStorageUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { personalBillingAddressDetailsObjToAddress } from '../PersonalBillingDetails/PersonalBillingDetails.js';
import { processDeliveryDetails, showDialog, upsertPersonBillingAccount } from '../../selfservice/actions/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type { DeviceChangeRequest } from '../../common/types/device.js';
import type { DeviceCheckoutDeliveryDetailsType } from '../DeviceCheckoutDeliveryDetails/DeviceCheckoutDeliveryDetailsUtils.js';
import type { PersonalBillingDetailsData } from '../PersonalBillingDetails/PersonalBillingDetails.js';
import type { State } from '../../selfservice/exports.js';

import './PunchoutCheckout.scss';

export const PunchoutCheckout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { authenticatedUser } = useAuth();
  const isStronglyAuthenticated = authenticatedUser?.isStronglyAuthenticated;
  const deviceSubs = useSelector((state: State) => state.user?.authenticated?.subscriptions?.device, deepEqual) || [];
  const {
    cartItems,
    catalogs,
    catalogCode,
    companyInfo,
    deliveryDetails,
    discountsLoaded,
    personBillingAccount,
    selectedCatalogCode,
    deliveryMethods,
  } = useSelector(
    (state: State) => ({
      dialog: state.dialog || undefined,
      catalogs: getPublishedCatalogs(state.selfservice?.virtualCatalogs?.items),
      selectedCatalogCode: state.selfservice?.virtualCatalogs?.selectedCatalogCode,
      cartItems: state.deviceCheckout?.cartItems || [],
      catalogCode: state.selfservice?.virtualCatalogs?.selectedCatalogCode,
      companyInfo: state.selfservice?.companyInfo || undefined,
      discountsLoaded: Boolean(state.selfservice?.companyInfo?.discountedPrices),
      deliveryDetails: state.deviceCheckout?.deliveryDetails,
      personBillingAccount: findPersonBillingAccount(state.selfservice?.billingAccounts || undefined),
      deliveryMethods: state.deviceCheckout?.deliveryMethods?.lisOfDeliveryMethods,
    }),
    deepEqual
  );
  const corporateMsg = catalogs?.find(catalog => catalog.catalogCode === selectedCatalogCode)?.corporateMessage;
  useEffect(() => {
    if (corporateMsg && !authenticatedUser?.isStronglyAuthenticated) {
      dispatch(
        showDialog({
          body: corporateMsg,
          header: t.WHAA(corporateMessagePopUpHeaderMsg),
          type: DialogType.GENERIC_INFO_DIALOG,
        })
      );
    }
  }, [dispatch, authenticatedUser?.isStronglyAuthenticated, corporateMsg]);

  const skipDeliveryDetails = authenticatedUser?.enabledFeatureFlags?.includes('skipDeliveryDetails') || false;
  const hideDeviceChangeStep =
    authenticatedUser?.enabledFeatureFlags?.includes('hideDeviceChangeStep') || !deviceSubs.length || false;

  const deviceChangeRequest = getPunchoutDeviceChangeRequest();
  const [moveToCheckoutConfirmation, setMoveToCheckoutConfirmation] = useState(false);
  const [billingInfoConfirmed, setBillingInfoConfirmed] = useState(false);
  const [isBillingTermsAccepted, setBillingTermsAccepted] = useState<boolean>();
  const isOmalaiteLasku = useMemo(
    () => hasEmployeePayables(cartItems || [], true, deviceChangeRequest),
    [cartItems, deviceChangeRequest]
  );

  const [deliveryDetailsObj, setDeliveryDetailsObj] = useState<DeviceCheckoutDeliveryDetailsType>(
    deliveryDetails ?? ({} as DeviceCheckoutDeliveryDetailsType)
  );
  const [isDeviceChangeStepDone, setDeviceChangeStepDone] = useState(false);

  const [activeStep, setActiveStep] = useState(hideDeviceChangeStep ? ActiveStep.AUTH : ActiveStep.DEVICE_CHANGE);

  const onDeviceChangeNextStep = (updatedDeviceChangeRequest: DeviceChangeRequest): void => {
    setPunchoutDeviceChangeRequest(updatedDeviceChangeRequest);
    setDeviceChangeStepDone(true);
  };
  useEffect(() => {
    if (deviceChangeRequest || hideDeviceChangeStep) {
      setDeviceChangeStepDone(true);
      if (
        (!isOmalaiteLasku && (isDeviceChangeStepDone || hideDeviceChangeStep)) ||
        (isOmalaiteLasku && billingInfoConfirmed && isDeviceChangeStepDone)
      ) {
        setActiveStep(ActiveStep.DELIVERY_DETAILS);
      } else {
        setActiveStep(isStronglyAuthenticated ? ActiveStep.BILLING : ActiveStep.AUTH);
      }
    }
  }, [
    billingInfoConfirmed,
    deviceChangeRequest,
    hideDeviceChangeStep,
    isDeviceChangeStepDone,
    isOmalaiteLasku,
    isStronglyAuthenticated,
  ]);

  useEffect(() => {
    if (billingInfoConfirmed && skipDeliveryDetails && (isDeviceChangeStepDone || hideDeviceChangeStep)) {
      setMoveToCheckoutConfirmation(true);
    }
  }, [billingInfoConfirmed, hideDeviceChangeStep, isDeviceChangeStepDone, skipDeliveryDetails]);

  useEffect(() => {
    if (deliveryMethods) {
      setDeliveryDetailsObj(getDeliveryDetailsObj(deliveryMethods, companyInfo, authenticatedUser, deliveryDetails));
    }
  }, [deliveryMethods, companyInfo, authenticatedUser, deliveryDetails]);

  const onSaveDeliveryDetailsAndNext = (updatedDeliveryDetails: DeviceCheckoutDeliveryDetailsType) => {
    dispatch(
      processDeliveryDetails(
        updatedDeliveryDetails,
        undefined,
        undefined,
        undefined,
        undefined,
        true,
        isOmalaiteLasku,
        true
      )
    );
    setMoveToCheckoutConfirmation(true);
  };

  const onSaveBillingDetailsAndNext = (values: PersonalBillingDetailsData) => {
    const moveToNextState = () => {
      setBillingInfoConfirmed(true);
    };

    if (!isBillingTermsAccepted) {
      // if terms not accepted, show error
      setBillingTermsAccepted(false);
      return;
    }

    const address = personalBillingAddressDetailsObjToAddress(values);
    const email = values.email;
    if (
      !personBillingAccount ||
      !deepEqual(address, personBillingAccount.payerAddress) ||
      email !== personBillingAccount.billReceiverEmail
    ) {
      // if person ba needs to be created or updated, then dispatch upsert action. move to next step after action is fulfilled
      dispatch(
        upsertPersonBillingAccount({ address, email }, personBillingAccount?.billingAccountId, undefined, () => {
          moveToNextState();
        })
      );
      return;
    }
    // if person ba exists, user clicks on edit but doesn't modify ba details, then move to next step
    moveToNextState();
  };

  if (!cartItems || cartItems.length === 0 || !catalogCode || !discountsLoaded || !authenticatedUser) {
    return <Loading big />;
  }

  return (
    <div className="of-punchout-checkout">
      <div className="of-punchout-checkout--header">
        <DeviceCheckoutBillSummaryHeader
          commercialProducts={getConfiguredCommercialProducts(cartItems)}
          deliveryCharges={getDeliveryCharges(deliveryDetails, isHideDeliveryMethodSelection(cartItems))}
          priceIncludesVAT={true}
          buttonText={t.PLAW('Return to the shop')}
          redirectToWebShopButton={() => {
            navigate(paths.PUNCHOUT_STORE);
            removePunchoutDeviceChangeRequest();
          }}
        />
      </div>
      <PunchoutCheckoutDialog />
      {!moveToCheckoutConfirmation ? (
        <PunchoutCheckoutSteps
          user={authenticatedUser}
          onSaveDeliveryDetailsAndNext={onSaveDeliveryDetailsAndNext}
          onSaveBillingDetailsAndNext={onSaveBillingDetailsAndNext}
          onSetBillingTerms={setBillingTermsAccepted}
          setDeliveryDetailsObj={setDeliveryDetailsObj}
          deliveryDetails={deliveryDetailsObj}
          billingInfoConfirmed={billingInfoConfirmed}
          isBillingTermsAccepted={isBillingTermsAccepted}
          isStronglyAuthenticated={isStronglyAuthenticated}
          isOmalaiteLasku={isOmalaiteLasku}
          hideDeviceChangeStep={hideDeviceChangeStep}
          skipDeliveryDetails={skipDeliveryDetails}
          skipDeliveryDetailsAndNext={() => setMoveToCheckoutConfirmation(true)}
          onDeviceChangeNextStep={onDeviceChangeNextStep}
          isDeviceChangeStepDone={isDeviceChangeStepDone}
          activeStep={activeStep}
          deviceSubs={deviceSubs}
        />
      ) : (
        <PunchoutCheckoutConfirmation
          cartItems={cartItems}
          catalogCode={catalogCode}
          deliveryDetails={deliveryDetailsObj}
          user={authenticatedUser}
          personBillingAccount={billingInfoConfirmed ? personBillingAccount : undefined}
          skipDeliveryDetails={skipDeliveryDetails}
          deviceChangeRequest={deviceChangeRequest}
        />
      )}
    </div>
  );
};
