import * as CL from '@design-system/component-library';
import { PRODUCT_DETAILS_CLASS } from '../productDetailsConstants.js';
import { chooseModelMsg, t } from '../../../common/i18n/index.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { getDefaultPaymentTerm } from '../utils/productDetailsUtils.js';
import type { Offer } from '../../../generated/api/models.js';

export interface ProductDetailsOfferSelectProps {
  offers: Offer[];
  offer: Offer;
  setOffer: (offer: Offer) => void;
  paymentMethod: number;
  setPaymentMethod: (priceOption: number) => void;
  onlineModelCategory?: string;
}

export const ProductDetailsOffer = (props: ProductDetailsOfferSelectProps) => {
  const {
    offers,
    offer: { offerCode },
    setOffer,
    paymentMethod,
    setPaymentMethod,
    onlineModelCategory,
  } = props;

  const items: CL.DropDownItem[] = offers.map(offer => ({
    id: offer.offerCode,
    label: offer.offerName,
    value: offer.offerCode,
  }));

  return (
    <div className={`${PRODUCT_DETAILS_CLASS}-offer ${dsClass.MARGIN_TOP_5}`}>
      <CL.Dropdown
        items={items}
        label={t.PUHZ(chooseModelMsg)}
        selectedValue={offerCode}
        onValueChange={listElement => {
          const itemValue = listElement.dataset.value;
          const selectedOffer = offers.find(offer => offer.offerCode === itemValue);

          if (!selectedOffer) {
            throw new Error(`Unable to find Offer with itemValue: "${itemValue}"`);
          }

          const paymentMethodExistsInOffer = selectedOffer.commercialProducts.find(p => p.payments === paymentMethod);

          if (!paymentMethodExistsInOffer) {
            setPaymentMethod(getDefaultPaymentTerm(onlineModelCategory));
          }

          setOffer(selectedOffer);
        }}
      />
    </div>
  );
};
