import { OnlineModelCategory } from '../../generated/api/onlineModelCategory.js';
import { accessoriesMsg, computersMsg, networkingHardwareMsg, phonesMsg, t, tabletsMsg } from '../i18n/index.js';

export type CategoryKey =
  | 'broadband'
  | 'device'
  | 'domain'
  | 'elisadns'
  | 'service'
  | 'voice'
  | 'subscriptions'
  | 'contract'
  | 'licenses';
export type CategoryKeyLite = 'broadband' | 'device' | 'domain' | 'service' | 'voice';
export const CATEGORY_URL = {
  broadband: 'nettiliittymät',
  device: 'laitteet',
  domain: 'domain',
  elisadns: 'elisadns',
  service: 'palvelut',
  voice: 'puhelinliittymät',
  subscriptions: 'liittymat',
  contract: '',
  licenses: 'lisenssit',
};
export const categories = {
  ACCESSORY: {
    code: OnlineModelCategory.ACCESSORIES,
    displayText: () => t.J954(accessoriesMsg),
    iconClass: 'headphones',
    order: 5,
    urlPath: 'lisatarvikkeet',
  },
  COMPUTER: {
    code: OnlineModelCategory.COMPUTERS,
    displayText: () => t.MCW3(computersMsg),
    iconClass: 'laptop',
    order: 3,
    urlPath: 'tietokoneet',
  },
  NETWORK: {
    code: OnlineModelCategory.NETWORK_EQUIPMENT,
    displayText: () => t.DMM5(networkingHardwareMsg),
    iconClass: 'wirelessdevice',
    order: 4,
    urlPath: 'verkkolaitteet',
  },
  PHONE: {
    code: OnlineModelCategory.PHONE,
    displayText: () => t.E9IF(phonesMsg),
    iconClass: 'phone',
    order: 1,
    urlPath: 'puhelimet',
  },
  TABLET: {
    code: OnlineModelCategory.TABLET,
    displayText: () => t.XS42(tabletsMsg),
    iconClass: 'tablet',
    order: 2,
    urlPath: 'tabletit',
  },
};
