import { BreadCrumbs } from '../../../../../components/BreadCrumbs';
import {
  CompanyInfoContainer,
  CompanyInfoSceneTab,
} from '../../../../../components/CompanyInfoScene/CompanyInfoScene.js';
import { CreateContactForm } from '../../../../../components/CreateContactForm/CreateContactForm';
import { DetailsWrapper } from '../../../../../components/DetailsWrapper';
import { dsClass } from '../../../../../common/constants/dsClasses';
import { omaElisaForCompaniesMsg, t, usersMsg } from '../../../../../common/i18n';
import { paths } from '../../../../../common/constants/pathVariables';
import { useStore } from 'react-redux';
import type { State } from '../../../../../selfservice/common/store.js';

export const CompanyInfoNewContactPath = () => {
  const config = useStore<State>().getState().config;
  const breadCrumbs = (
    <BreadCrumbs
      breadCrumbPaths={[
        { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
        { name: t.BE4A(usersMsg), path: paths.COMPANY_INFO_CONTACTS },
        { name: t.QQSJ('Add new user') },
      ]}
    />
  );

  return (
    <CompanyInfoContainer tab={CompanyInfoSceneTab.CONTACTS} isSingular={true}>
      <DetailsWrapper
        classes={['of-create-new-contact']}
        detailsTop={breadCrumbs}
        id="contact-details"
        heading={t.QQSJ('Add new user')}
        heroPicto="cl-user"
        styledHeading={
          <h1 className={`of-create-new-contact__h1 ${dsClass.COLOR_BLUE_800}`}>{t.QQSJ('Add new user')}</h1>
        }
      >
        <CreateContactForm config={config} />
      </DetailsWrapper>
    </CompanyInfoContainer>
  );
};
