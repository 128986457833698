import * as CL from '@design-system/component-library';
import { DialogWrapper } from '../DialogWrapper/index.js';
import { PureComponent } from 'react';
import { closeMsg, continueMsg, t } from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import type { CommonError } from '../../common/types/errors.js';
import type { DialogWrapperButton } from '../DialogWrapper/index.js';

export interface SelectUsernameDialogProps {
  onCloseDialog: () => void;
  isLoading?: boolean;
  errors?: CommonError[];
  usernames: string[];
  onContinue: (selected: string) => void;
}

export interface SelectUsernameDialogStateInternal {
  showMore: boolean;
  selected: string;
}

// Our current CL version's SelectGroup's logic for checking what item is
// selected is broken: it just checks if the current selection _contains_ the
// tested ID (apparently to support multiselect). We get around this by padding
// the username with spaces: this is safe as usernames can't have surrounding
// whitespace, and SelectGroup doesn't care what characters the IDs have.
const idFromUsername = (username: string) => `   ${username}   `;

const usernameFromId = (id: string) => id.substring(3, id.length - 3);

const createUserNameButton = (username: string, selected?: string) => ({
  id: idFromUsername(username),
  selected: username === selected,
  title: username,
});

export class SelectUsernameDialog extends PureComponent<SelectUsernameDialogProps, SelectUsernameDialogStateInternal> {
  constructor(props: SelectUsernameDialogProps) {
    super(props);
    this.state = { showMore: false, selected: '' };
  }

  toggleShowMore = () => {
    this.setState({ showMore: !this.state.showMore });
  };

  setSelectedById = (id: string) => {
    if (id) {
      this.setState({ ...this.state, selected: usernameFromId(id) });
    }
  };

  render() {
    const { onCloseDialog, isLoading, errors, onContinue, usernames } = this.props;
    const { showMore, selected } = this.state;

    const buttons: DialogWrapperButton[] = [
      errors
        ? {
            id: 'select-username-dialog-close',
            isLoading: isLoading,
            onClick: () => {
              onCloseDialog();
            },
            text: t.WOYD(closeMsg),
          }
        : {
            disabled: selected === '',
            id: 'select-username-dialog-continue',
            isLoading: isLoading,
            onClick: () => {
              onContinue(this.state.selected);
            },
            text: t.I62A(continueMsg),
          },
    ];

    const products = showMore
      ? usernames.map(username => createUserNameButton(username, selected))
      : [createUserNameButton(usernames[0], selected)];
    const value = products.find(i => i.selected)?.id;

    return (
      <DialogWrapper buttons={buttons} header={t.ZX0L('Select a username')} onCloseDialog={onCloseDialog}>
        <p>
          {t.BCFF(
            'You have more than one username for OmaElisa. Select the ID you want to use to log into OmaElisa. For security reasons, we recommend that you select an ID in the form of an email address.'
          )}
        </p>
        <CL.SelectGroup
          className={dsClass.PADDING_0}
          onChange={this.setSelectedById}
          products={products}
          value={value}
        />
        {!showMore && (
          <CL.Button color="link" className={dsClass.MARGIN_TOP_4} onClick={() => this.toggleShowMore()} size="s">
            {t.OM8N('View other options')}
          </CL.Button>
        )}
        <p>{t.ESF8('You will be logged off automatically after you select a user ID.')}</p>
        <p>
          <b>{t.X0V6('NB! Please use the ID you selected to sign in from now on.')}</b>
        </p>
        {errors &&
          errors.map((error, i) => (
            <CL.Disclaimer className={dsClass.TEXT_M} icon={<CL.Icon icon="warning" />} key={i} text={error.message} />
          ))}
      </DialogWrapper>
    );
  }
}
