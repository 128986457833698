import { HeaderSectionContent, SectionContent } from '../SectionContent.js';
import { InfoText, Separator } from '../InfoText.js';
import { SearchResultRow } from '../SearchResultRow.js';
import { costCenterMsg, idNumberMsg, imeiSlashSerialNumberMsg, referenceIdMsg, t } from '../../../common/i18n/index.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { formatTimestampToDDMMYYYY } from '../../../common/utils/dateUtils.js';
import { generatePath } from 'react-router-dom';
import { getResultObjectWithHighlightedValues } from '../highlightFunctions.js';
import {
  isEndDateExpiring,
  isEppDeviceSubscriptionExpiring,
} from '../../EmployeeSubscriptionDetails/eppSubscriptionUtils.js';
import { isSubscriptionInSfdc } from '../../../common/utils/subscriptionUtils.js';
import { paths } from '../../../common/constants/pathVariables.js';
import type { ClassicSiteResultProps, SearchResultBaseProps } from '../SearchResultRow.js';
import type { SubscriptionHeader } from '../../../generated/api/subscriptionHeader.js';

export interface DeviceProps extends SearchResultBaseProps, ClassicSiteResultProps {
  subscription: SubscriptionHeader;
  isEppDevice: boolean;
}

interface EppDeviceUpperSectionContentProps {
  isEppDevice: boolean;
  subscriptionName?: string | JSX.Element;
  subscriptionContactName?: string | JSX.Element;
  subscriptionPurposeOfUse?: string | JSX.Element;
}

interface LowerSectionContentProps {
  subscription: SubscriptionHeader;
  billingAccountDisplayId?: string | JSX.Element;
}

const UpperSectionContent = ({
  isEppDevice,
  subscriptionName,
  subscriptionContactName,
  subscriptionPurposeOfUse,
}: EppDeviceUpperSectionContentProps) =>
  isEppDevice ? (
    <div>
      <div className="info-text">
        <span className="header">{subscriptionName}</span>
        <span className={`epp-badge ${dsClass.BACKGROUND_COLOR_NEUTRAL_200}`}>EPP</span>
      </div>
      {(subscriptionContactName || subscriptionPurposeOfUse) && (
        <>
          <Separator />
          <InfoText info={subscriptionContactName || subscriptionPurposeOfUse || ''} />
        </>
      )}
    </div>
  ) : (
    <HeaderSectionContent left={subscriptionName || ''} right={subscriptionContactName || subscriptionPurposeOfUse} />
  );

const LowerSectionContent = ({ subscription, billingAccountDisplayId }: LowerSectionContentProps) => {
  const isExpiring = subscription.eppSubscription
    ? isEppDeviceSubscriptionExpiring(subscription)
    : isEndDateExpiring(subscription.contractEndDate || 0);
  const statusColor = isExpiring ? 'light-orange' : 'green-600';
  const isContractDevice = subscription.eppSubscription || !subscription.oneTimeCharge;
  const leftSideContent = isContractDevice
    ? `${t.UTIR('Contract ends')}: ${formatTimestampToDDMMYYYY(subscription.contractEndDate) || ''}`
    : t.QYSL('Single fare');
  return (
    <SectionContent
      statusColor={isContractDevice ? statusColor : undefined}
      left={leftSideContent}
      right={
        billingAccountDisplayId && (
          <>
            {t.IFT9('Billing account')}: {billingAccountDisplayId}
          </>
        )
      }
    />
  );
};

export const Device = ({
  subscription,
  classicSiteUrl,
  isEppDevice,
  searchTerms,
  matchedFields,
  ...rest
}: DeviceProps) => {
  const {
    subscriptionName,
    subscriptionContactName,
    subscriptionPurposeOfUse,
    billingAccountDisplayId,
    serialNumber,
    costCenter,
    subscriptionReference,
    subscriptionDisplayId,
  } = getResultObjectWithHighlightedValues(subscription, matchedFields, searchTerms);
  const displayClassicSiteLink = !isSubscriptionInSfdc(subscription);
  const classicSiteLinkTarget = `${classicSiteUrl}/muutos-ja-poisto/`;
  return (
    <SearchResultRow
      icon="smartphone"
      upperSection={
        <UpperSectionContent
          isEppDevice={isEppDevice}
          subscriptionName={subscriptionName}
          subscriptionContactName={subscriptionContactName}
          subscriptionPurposeOfUse={subscriptionPurposeOfUse}
        />
      }
      lowerSection={
        <LowerSectionContent subscription={subscription} billingAccountDisplayId={billingAccountDisplayId} />
      }
      additionalHighlightableFields={[
        { title: t.OE45(imeiSlashSerialNumberMsg), value: serialNumber, key: 'serialNumber' },
        { title: t.QJUW(costCenterMsg), value: costCenter, key: 'costCenter' },
        { title: t.CWR5(referenceIdMsg), value: subscriptionReference, key: 'subscriptionReference' },
        { title: t.YTH3(idNumberMsg), value: subscriptionDisplayId, key: 'subscriptionDisplayId' },
      ]}
      matchedFields={matchedFields}
      displayClassicSiteLink={displayClassicSiteLink}
      linkHref={
        displayClassicSiteLink
          ? classicSiteLinkTarget
          : `${generatePath(paths.PS_DEVICE, { subscriptionId: subscription.subscriptionDisplayId })}?mdmId=${subscription.accountMasterId}`
      }
      {...rest}
    />
  );
};
