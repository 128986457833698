import { AdditionalServicesAccordionContent } from '../SubscriptionDetails/AdditionalServicesAccordionContent.js';
import { LinkableAccordion } from '../LinkableAccordion/index.js';
import { additionalServicesMsg, t } from '../../common/i18n/index.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { loadSubscriptionAddOnRules } from '../../selfservice/actions/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch, useSelector } from 'react-redux';
import type { State } from '../../selfservice/common/store.js';
import type { Subscription } from '../../generated/api/subscription.js';
import type { SubscriptionCategory } from '../../common/enums.js';

export const EmployeeAdditionalServicesAccordion = ({
  subscription,
  category,
}: {
  subscription: Subscription;
  category: SubscriptionCategory;
}) => {
  const { authenticatedUser } = useAuth();
  const { pendingSubscriptionActions, subscriptionAddOnRules } = useSelector(
    (state: State) => ({
      pendingSubscriptionActions: state?.selfservice?.pendingSubscriptionActions,
      subscriptionAddOnRules: state?.selfservice?.subscriptionAddOnRules,
    }),
    deepEqual
  );
  const isMobileIdEnabledForEoEUser = authenticatedUser?.enabledFeatureFlags?.includes('mobileIdEoe') || false;
  const dispatch = useDispatch();

  return (
    <LinkableAccordion
      id="additonal-services"
      heading={t.LXSR(additionalServicesMsg)}
      headingLevel="h3"
      onOpen={() => dispatch(loadSubscriptionAddOnRules())}
    >
      <AdditionalServicesAccordionContent
        subscription={subscription}
        category={category}
        addOnAssociations={subscriptionAddOnRules?.associations}
        addOnDependencies={subscriptionAddOnRules?.dependencies}
        isMobileIdEnabledForEoeUser={isMobileIdEnabledForEoEUser}
        subscriptionActions={pendingSubscriptionActions?.items}
        isEmployee={true}
      />
    </LinkableAccordion>
  );
};
