import * as CL from '@design-system/component-library';
import { Anchor } from '../Anchor/Anchor.js';
import { ButtonGroupForSubmitAndBack } from '../ButtonGroupForSubmitAndBack/ButtonGroupForSubmitAndBack.js';
import { ConfirmationModal } from '../Modal/ConfirmationModal.js';
import { closeSuspendSubscriptionOptions } from '../../selfservice/actions/index.js';
import {
  confirmMsg,
  customerServiceNumberLabelMsg,
  customerServiceNumberOpenLongMsg,
  priceMsg,
  returnToSubscriptionPageMsg,
  subscriptionSuspendPageHeaderMsg,
  subscriptionSuspendTabToHyllyliittymaMsg,
  subscriptionSuspendTabToSuspendMsg,
  subscriptionSuspendTemporarilyParagraph1Msg,
  subscriptionSuspendTemporarilyParagraph2Msg,
  subscriptionSuspendTemporarilySmallHeaderMsg,
  subscriptionSuspendToHyllyliittymaFixedTermMsg,
  subscriptionSuspendToHyllyliittymaParagraph1Msg,
  subscriptionSuspendToHyllyliittymaParagraph2Msg,
  subscriptionSuspendToHyllyliittymaSmallHeaderMsg,
  subscriptionSuspendVakioTabToHyllyliittymaBackToSubMsg,
  subscriptionSuspendVakioTabToHyllyliittymaMsg,
  subscriptionSuspendVakioTabToHyllyliittymaParagraph1Msg,
  subscriptionSuspendVakioTabToHyllyliittymaParagraph2Msg,
  sumPerMonthMsg,
  t,
} from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { formatSum } from '../../common/utils/priceUtils.js';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import './SuspendSubscriptionOptions.scss';

export interface SuspendSubscriptionOptionsProps {
  onCloseSuspendSubscriptionOptions: () => void;
  onConfirmSuspend: () => void;
  onConfirmHyllyliittyma: () => void;
  hasMobiiliVarmenne: boolean;
  isVakio: boolean;
  isFixedTermSubscription: boolean;
}

export interface SectionProps {
  onClose: () => void;
  onConfirm: () => void;
  isFixedTermSubscription: boolean;
}

export interface VakioSectionProps {
  onClose: () => void;
}

const SHELF_SUBSCRIPTION_MONTLHY_FEE = 195;

const TemporarilyCloseSubscription = ({ onConfirm, onClose }: SectionProps) => (
  <div>
    <h4 className="ea-h4 ea-m-t-4">{t.LYW5(subscriptionSuspendTemporarilySmallHeaderMsg)}</h4>
    <p>{t.VAJN(subscriptionSuspendTemporarilyParagraph1Msg)}</p>
    <p className={dsClass.PADDING_BOTTOM_4}>{t.IKLM(subscriptionSuspendTemporarilyParagraph2Msg)}</p>
    <ButtonGroupForSubmitAndBack
      className={dsClass.TEXT_ALIGN_RIGHT}
      onCancel={onClose}
      onSubmit={onConfirm}
      submitButtonText={t.QVYK(confirmMsg)}
    />
  </div>
);

const ChangeToHyllyliittyma = ({ onConfirm, onClose, ...props }: SectionProps) => {
  return props.isFixedTermSubscription ? (
    <>
      <div className="of-suspend-subscription-options--notebox">
        <h4 className="ea-h4-fixed-term">{t.QOWZ('Subscription cannot be inactivated temporarily')}</h4>
        <p>{t.RQ67(subscriptionSuspendToHyllyliittymaFixedTermMsg)}</p>
      </div>
      <Anchor className="of-suspend-subscription-options--link" onClick={onClose}>
        {t.D30R(returnToSubscriptionPageMsg)}
      </Anchor>
    </>
  ) : (
    <div className="ea-m-t-4">
      <h4 className="ea-h4">{t.Q27O(subscriptionSuspendToHyllyliittymaSmallHeaderMsg)}</h4>
      <p>{t.HQ5P(subscriptionSuspendToHyllyliittymaParagraph1Msg)}</p>
      <p>
        <strong>{t.V72N(priceMsg)}:</strong> {t.YO7F(sumPerMonthMsg, formatSum(SHELF_SUBSCRIPTION_MONTLHY_FEE) || '')}
      </p>
      <p className={dsClass.PADDING_BOTTOM_4}>{t.K1TA(subscriptionSuspendToHyllyliittymaParagraph2Msg)}</p>
      <ButtonGroupForSubmitAndBack
        className={dsClass.TEXT_ALIGN_RIGHT}
        onCancel={onClose}
        onSubmit={onConfirm}
        submitButtonText={t.QVYK(confirmMsg)}
      />
    </div>
  );
};

const VakioChangeToHyllyliittyma = (props: VakioSectionProps) => (
  <div>
    <div className="of-suspend-subscription-options--notebox">
      <h4 className="ea-h4">{t.ZNNI(subscriptionSuspendVakioTabToHyllyliittymaMsg)}</h4>
      <p>
        {t.S0H2(subscriptionSuspendVakioTabToHyllyliittymaParagraph1Msg)}
        <br />
        {t.V5HK(
          subscriptionSuspendVakioTabToHyllyliittymaParagraph2Msg,
          customerServiceNumberLabelMsg,
          t.HQWV(customerServiceNumberOpenLongMsg)
        )}
      </p>
    </div>
    <Anchor className="of-suspend-subscription-options--link" onClick={props.onClose}>
      {t.ONRF(subscriptionSuspendVakioTabToHyllyliittymaBackToSubMsg)}
    </Anchor>
  </div>
);

export const SuspendSubscriptionOptions = ({
  onCloseSuspendSubscriptionOptions,
  onConfirmSuspend,
  onConfirmHyllyliittyma,
  hasMobiiliVarmenne,
  isVakio,
  isFixedTermSubscription,
}: SuspendSubscriptionOptionsProps) => {
  const dispatch = useDispatch();
  enum SuspendOptions {
    CLOSE = 'CLOSE',
    SHELF = 'SHELF',
  }
  const [suspendOption, setSuspendOption] = useState(SuspendOptions.CLOSE);
  const [confirmServiceRemoval, setConfirmServiceRemoval] = useState(false);

  useEffect(
    () => () => {
      dispatch(closeSuspendSubscriptionOptions());
    },
    [dispatch]
  );

  const onConfirm = () => {
    switch (suspendOption) {
      case SuspendOptions.CLOSE:
        return onConfirmSuspend();
      case SuspendOptions.SHELF:
        return onConfirmHyllyliittyma();
    }
  };

  const onConfirmFunction = hasMobiiliVarmenne ? () => setConfirmServiceRemoval(true) : () => onConfirm();

  return (
    <div className="of-suspend-subscription-options">
      <h3 className="ea-h3 ea-h3--thick">{t.SF6S(subscriptionSuspendPageHeaderMsg)}</h3>
      <CL.SelectGroup
        className={dsClass.PADDING_0}
        onChange={(selectedId: string) => {
          if (selectedId) {
            setSuspendOption(selectedId as SuspendOptions);
          }
        }}
        products={[
          {
            id: SuspendOptions.CLOSE,
            name: t.JR24(subscriptionSuspendTabToSuspendMsg),
          },
          {
            id: SuspendOptions.SHELF,
            name: t.HPT9(subscriptionSuspendTabToHyllyliittymaMsg),
          },
        ]}
        value={SuspendOptions.CLOSE}
      />

      {suspendOption === SuspendOptions.CLOSE ? (
        <TemporarilyCloseSubscription
          onConfirm={onConfirmFunction}
          onClose={() => onCloseSuspendSubscriptionOptions()}
          isFixedTermSubscription={isFixedTermSubscription}
        />
      ) : isVakio ? (
        <VakioChangeToHyllyliittyma onClose={() => onCloseSuspendSubscriptionOptions()} />
      ) : (
        <ChangeToHyllyliittyma
          onConfirm={onConfirmFunction}
          onClose={() => onCloseSuspendSubscriptionOptions()}
          isFixedTermSubscription={isFixedTermSubscription}
        />
      )}

      {confirmServiceRemoval ? (
        <ConfirmationModal
          heading={t.N6FA('Additional services are removed from the subscription')}
          onConfirm={onConfirm}
          onCancel={() => setConfirmServiceRemoval(false)}
        >
          <div>
            <strong>{t.PKEZ('Mobile Certificate')}:</strong>
            <p>
              {t.KZ5L(
                'The subscription has been linked to Mobiilivarmenne, which will cease to function due to the change. The user of the subscription must personally reactivate the mobile certificate.'
              )}
            </p>
          </div>
        </ConfirmationModal>
      ) : null}
    </div>
  );
};
