import * as CL from '@design-system/component-library';
import { Modal } from '../../Modal/Modal.js';
import { ModalCloseAndSubmitButtons } from '../../Modal/ModalCloseAndSubmitButtons.js';
import {
  customerServiceNumberLabelMsg,
  customerServiceNumberOpenLongMsg,
  customerServiceNumberPriceMsg,
  logOutMsg,
  t,
} from '../../../common/i18n/index.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { enableMfa } from '../../../common/fetch.js';
import { logoutFulfilled } from '../../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

import './MfaEnableConfirmation.scss';

interface MfaEnableConfirmationProps {
  onCancel: () => void;
  saId: string;
}

export const MfaEnableConfirmation = ({ onCancel, saId }: MfaEnableConfirmationProps) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [submissionError, setSubmissionError] = useState(false);
  const dispatch = useDispatch();

  const onConfirm = async () => {
    return await enableMfa(saId)
      .then(() => dispatch(logoutFulfilled('/omaelisa')))
      .catch((_e: Error) => {
        setSubmissionError(true);
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <Modal closeable onClose={onCancel} className="of-mfa-enable-confirmation" heading="">
      <>
        <div>
          <div className="picto-lock"></div>
          <strong>{t.EAVJ('We log you out for the authentication')}</strong>
          <p>
            {t.TYQZ(
              'You are about to activate two-factor authentication, which will now require a one-time authentication code sent to your mobile phone in addition to your ID and password when you log in.'
            )}
          </p>
        </div>
        {submissionError && (
          <CL.Disclaimer
            className={dsClass.TEXT_M}
            icon={<CL.Icon icon="warning" />}
            text={t.JYLX(
              'Something went wrong, please try signing in again. If the problem persists, please call our customer service on {0} ({1}). Our customer service is open {2}.',
              customerServiceNumberLabelMsg,
              t.HQWW(customerServiceNumberPriceMsg),
              t.HQWV(customerServiceNumberOpenLongMsg)
            )}
          />
        )}
        <ModalCloseAndSubmitButtons
          submitting={isSubmitting}
          onCancel={onCancel}
          onSubmit={async () => {
            setIsSubmitting(true);
            await onConfirm();
          }}
          submitButtonText={t.LQ3X(logOutMsg)}
        />
      </>
    </Modal>
  );
};
