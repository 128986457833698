import { SubscriptionCategory } from '../../../common/enums.js';
import { SubscriptionType } from '../../../generated/api/models.js';
import type { CategoryKey } from '../../../common/utils/categoryUtils.js';

export const getSubscriptionTypes = (category: CategoryKey): SubscriptionType[] => {
  switch (category) {
    case SubscriptionCategory.BROADBAND:
      return [
        SubscriptionType.BROADBAND,
        SubscriptionType.CORPORATE_NETWORK,
        SubscriptionType.HOUSING_COMPANY_BROADBAND,
        SubscriptionType.MOBILE_BROADBAND,
        SubscriptionType.MOBILE_M2M,
      ];

    case SubscriptionCategory.DEVICE:
      return [SubscriptionType.DEVICE];

    case SubscriptionCategory.SERVICE:
      return [
        SubscriptionType.CABLE_TV,
        SubscriptionType.E_FAX,
        SubscriptionType.FIREWALL,
        SubscriptionType.LAN,
        SubscriptionType.NEGOTIATION,
        SubscriptionType.SOFTWARE_PRODUCT,
        SubscriptionType.MOBILE_PBX_LITE,
        SubscriptionType.MOBILE_PBX,
      ];

    case SubscriptionCategory.VOICE:
      return [
        SubscriptionType.LANDLINE,
        SubscriptionType.MOBILE,
        SubscriptionType.SPECIAL_NUMBER,
        SubscriptionType.MOBILE_PBX_END_USER_SERVICE,
      ];

    case SubscriptionCategory.SUBSCRIPTIONS:
      return [
        SubscriptionType.LANDLINE,
        SubscriptionType.MOBILE,
        SubscriptionType.SPECIAL_NUMBER,
        SubscriptionType.MOBILE_PBX_END_USER_SERVICE,
      ];

    case SubscriptionCategory.DOMAIN:
      return [SubscriptionType.DOMAIN];

    case SubscriptionCategory.CONTRACT:
      return [SubscriptionType.MOBILE_CERTIFICATE];

    default:
      return [];
  }
};

export const getCategoryBySubscriptionType = (subscriptionType: SubscriptionType): SubscriptionCategory => {
  const category: SubscriptionCategory | undefined = Object.values(SubscriptionCategory).find(cat =>
    getSubscriptionTypes(cat).includes(subscriptionType)
  );
  if (category) {
    return category;
  }
  throw new Error(`Not supported new subscription type ${subscriptionType}`);
};
