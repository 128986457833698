import { TypeKeys, generateActionId } from './actionsUtils.js';
import { convertToCommonErrors } from '../../common/utils/errorUtils.js';
import type { Action } from 'redux';
import type {
  Address,
  CheckUserExistsResponse,
  Company,
  GenericError,
  RegisterUserRequestUserDetails,
  ValidateUserAuthorityResponse,
} from '../../generated/api/models.js';
import type { CommonError } from '../../common/types/errors.js';
import type { CrudAction, ErrorAction, ErrorActionCreator } from './actionsUtils.js';

interface ExternalAuthenticationFailedAction extends Action {
  error: CommonError;
  type: TypeKeys.EXTERNAL_AUTHENTICATION_FAILED;
}

export const externalAuthenticationFailed = (error: CommonError): ExternalAuthenticationFailedAction => {
  return { error, type: TypeKeys.EXTERNAL_AUTHENTICATION_FAILED };
};

export interface ValidateUserAuthorityFulfilledAction extends Action {
  response: ValidateUserAuthorityResponse;
  redirectPath?: string;
  type: TypeKeys.VALIDATE_USER_AUTHORITY_FULFILLED;
}

export const validateUserAuthorityFulfilled = (
  response: ValidateUserAuthorityResponse,
  redirectPath?: string
): ValidateUserAuthorityFulfilledAction => {
  return {
    response,
    redirectPath,
    type: TypeKeys.VALIDATE_USER_AUTHORITY_FULFILLED,
  };
};

export interface ValidateUserAuthorityFailedAction extends Action {
  errors: CommonError[];
  redirectPath?: string;
  type: TypeKeys.VALIDATE_USER_AUTHORITY_FAILED;
}

export type ValidateUserAuthorityErrorActionCreator<T extends TypeKeys> = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string },
  redirectPath?: string
) => ErrorAction<T>;

export const validateUserAuthorityFailed: ValidateUserAuthorityErrorActionCreator<
  TypeKeys.VALIDATE_USER_AUTHORITY_FAILED
> = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string },
  redirectPath?: string
): ValidateUserAuthorityFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    redirectPath,
    type: TypeKeys.VALIDATE_USER_AUTHORITY_FAILED,
  };
};

export interface ValidateUserAuthorityAction extends CrudAction<TypeKeys.VALIDATE_USER_AUTHORITY> {
  authorizationCode: string;
  businessId: string;
  redirectPath?: string;
}

export const validateUserAuthority = (
  authorizationCode: string,
  businessId: string,
  redirectPath?: string
): ValidateUserAuthorityAction => {
  return {
    authorizationCode,
    businessId,
    redirectPath,
    id: generateActionId(),
    type: TypeKeys.VALIDATE_USER_AUTHORITY,
  };
};

export interface RegisterUserAction extends CrudAction<TypeKeys.REGISTER_USER> {
  businessId: string;
  companyAddress: Address;
  companyName: string;
  identityCertificate: string;
  userDetails: RegisterUserRequestUserDetails;
  newUser: boolean;
}

export const registerUser = (
  identityCertificate: string,
  company: Company,
  userDetails: RegisterUserRequestUserDetails,
  newUser: boolean
): RegisterUserAction => ({
  businessId: company.businessId,
  companyAddress: {
    line1: company.postalAddress!,
    postalCode: company.postCode!,
    postOffice: company.postOffice!,
    countryCode: company.countryCode!,
  },
  companyName: company.name,
  identityCertificate,
  userDetails,
  id: generateActionId(),
  type: TypeKeys.REGISTER_USER,
  newUser,
});

type RegisterUserFailedAction = ErrorAction<TypeKeys.REGISTER_USER_FAILED>;

export const registerUserFailed: ErrorActionCreator<TypeKeys.REGISTER_USER_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[]
): RegisterUserFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    type: TypeKeys.REGISTER_USER_FAILED,
  };
};

export interface RegisterUserFulfilledAction extends Action {
  type: TypeKeys.REGISTER_USER_FULFILLED;
  newUser: boolean;
}

export const registerUserFulfilled = (newUser: boolean): RegisterUserFulfilledAction => {
  return {
    type: TypeKeys.REGISTER_USER_FULFILLED,
    newUser: newUser,
  };
};

export interface RegisterUserErrorFulfilledAction extends Action {
  type: TypeKeys.REGISTER_USER_ERROR_FULFILLED;
}

export const registerUserErrorFulfilled = (): RegisterUserErrorFulfilledAction => {
  return {
    type: TypeKeys.REGISTER_USER_ERROR_FULFILLED,
  };
};

export interface UserExistsAction extends CrudAction<TypeKeys.USER_EXISTS> {
  identityCertificate: string;
  businessId: string;
  email: string;
}

export const checkUserExists = (identityCertificate: string, businessId: string, email: string): UserExistsAction => ({
  identityCertificate,
  businessId,
  email,
  id: generateActionId(),
  type: TypeKeys.USER_EXISTS,
});

type UserExistsFailedAction = ErrorAction<TypeKeys.USER_EXISTS_FAILED>;

export const userExistsFailed: ErrorActionCreator<TypeKeys.USER_EXISTS_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[]
): UserExistsFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    type: TypeKeys.USER_EXISTS_FAILED,
  };
};

interface UserExistsFulfilledAction extends Action {
  exists: boolean;
  type: TypeKeys.USER_EXISTS_FULFILLED;
}

export const userExistsFulfilled = (response: CheckUserExistsResponse): UserExistsFulfilledAction => {
  return {
    exists: response.exists,
    type: TypeKeys.USER_EXISTS_FULFILLED,
  };
};

export type ExternalAuthenticationActionTypes =
  | ExternalAuthenticationFailedAction
  | ValidateUserAuthorityAction
  | ValidateUserAuthorityFulfilledAction
  | ValidateUserAuthorityFailedAction
  | RegisterUserAction
  | RegisterUserFailedAction
  | RegisterUserFulfilledAction
  | RegisterUserErrorFulfilledAction
  | UserExistsAction
  | UserExistsFailedAction
  | UserExistsFulfilledAction;
