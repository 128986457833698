import * as CL from '@design-system/component-library';
import { CompanySearch } from '../../../common/react-hook-form/fields/CompanySearch.js';
import { Controller, useFormContext } from 'react-hook-form';
import { TextInput } from '../../../common/react-hook-form/fields/TextInput.js';
import { ToggleWithSubComponent } from '../../ToggleWithSubComponent/ToggleWithSubComponent.js';
import {
  areYouMakingTheUpdateOnBehalfOfAnotherCompanyOrHousingCompanyMsg,
  businessIdOrCompanyMsg,
  customerNumberMsg,
  t,
} from '../../../common/i18n/index.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { validateCustomerNumber } from './utils.js';
import type { FormEditProps } from './EOperatorChangeRequestForm.js';

const COMPANY_FIELD_NAME = 'actingOnBehalfOfCompanySearch';
const COMPANY_BUSINESS_ID_FIELD_NAME = 'actingOnBehalfOfCompanyBusinessId';
const COMPANY_BUSINESS_NAME_FIELD_NAME = 'actingOnBehalfOfCompanyBusinessName';
const CUSTOMER_NUMBER_FIELD_NAME = 'actingOnBehalfOfCompanyCustomerNumber';

const FormFields = ({ isEditing }: FormEditProps) => {
  return (
    <CL.Grid>
      <CL.GridRow>
        <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
          <CompanySearch
            name={COMPANY_FIELD_NAME}
            placeholder=""
            helpText={t.A00M('affected by the change')}
            fieldNames={{
              businessId: COMPANY_BUSINESS_ID_FIELD_NAME,
              businessName: COMPANY_BUSINESS_NAME_FIELD_NAME,
              masterId: 'actingOnBehalfOfCompanyMasterId',
              postalAddress: 'actingOnBehalfOfCompanyPostalAddress',
              postCode: 'actingOnBehalfOfCompanyPostCode',
              postOffice: 'actingOnBehalfOfCompanyPostOffice',
            }}
            readOnlyContent={
              !isEditing
                ? ({ name }) => (
                    <>
                      <div className={dsClass.INPUT_LABELAREA}>
                        <label className={dsClass.INPUT_LABELAREA_LABEL}>{t.GSAD(businessIdOrCompanyMsg)}</label>
                      </div>
                      <div className={dsClass.INPUT_INPUTAREA}>
                        <span>{name}</span>
                      </div>
                      <p className={dsClass.INPUTHELP}>{t.A00M('affected by the change')}</p>
                    </>
                  )
                : undefined
            }
          />
        </CL.GridCol>
        <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
          <TextInput
            name={CUSTOMER_NUMBER_FIELD_NAME}
            label={t.A00L(customerNumberMsg)}
            tooltip={t.A00N(
              'You can find your customer number on your latest invoice. If your company has more than one customer number, you can add any of them.'
            )}
            hint={t.A00M('affected by the change')}
            validate={validateCustomerNumber}
            readonly={!isEditing}
          />
        </CL.GridCol>
      </CL.GridRow>
      <CL.GridRow>
        <CL.GridCol colWidthXS={4} colWidthS={6} colWidthL={12}>
          <CL.Disclaimer
            className={`${dsClass.MARGIN_TOP_2} ${dsClass.MARGIN_BOTTOM_3}`}
            icon={<CL.Icon icon="information" type="filled" aria-hidden="true" />}
            text={t.A00P(
              'Unfortunately, making the update for several companies at once is not yet possible in our service. However, you can fill in the change form for each company separately.'
            )}
          />
        </CL.GridCol>
      </CL.GridRow>
    </CL.Grid>
  );
};

export const ActingOnBehalfOfCompany = ({ isEditing = true }: FormEditProps) => {
  const { setValue, clearErrors } = useFormContext();
  const handleToggle = (value: boolean) => {
    if (!value) {
      setValue(COMPANY_FIELD_NAME, '');
      setValue(CUSTOMER_NUMBER_FIELD_NAME, '');
      setValue(COMPANY_BUSINESS_ID_FIELD_NAME, '');
      setValue(COMPANY_BUSINESS_NAME_FIELD_NAME, '');
      clearErrors([COMPANY_FIELD_NAME, CUSTOMER_NUMBER_FIELD_NAME]);
    }
  };

  return (
    <Controller
      name="actingOnBehalfOfCompany"
      render={({ field: { onChange } }) => (
        <ToggleWithSubComponent
          name="actingOnBehalfOfCompany"
          title={t.A0OK(areYouMakingTheUpdateOnBehalfOfAnotherCompanyOrHousingCompanyMsg)}
          infoText={t.A00O(
            'Select yes if you are the company’s accountant or the housing company’s property manager, for example.'
          )}
          onToggle={value => {
            onChange(value);
            handleToggle(value);
          }}
          subComponent={<FormFields isEditing={isEditing} />}
          disabled={!isEditing}
        />
      )}
    />
  );
};
