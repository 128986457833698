import * as CL from '@design-system/component-library';
import * as React from 'react';
import { CommonErrorType } from '../../common/enums.js';
import { CustomerServiceCallLink } from '../CustomerServicePhoneCallLink/CustomerServiceCallLink.js';
import { Icon } from '../Icon/Icon.js';
import { Link, useLocation } from 'react-router-dom';
import { LoginDialog } from './LoginDialogs.js';
import { MfaOtp } from '../MFA/MfaOtp.js';
import { REDIRECT_PATH_KEY } from '../../common/constants/commonConstants';
import {
  closeMsg,
  consumerOmaElisaMsg,
  createIdMsg,
  customerServiceNumberOpenShortMsg,
  customerServiceNumberPriceMsg,
  emailAddressOrIdMsg,
  employeeOmaElisaMsg,
  forgottenPasswordMsg,
  logInMsg,
  loginToOmaElisaForCompaniesMsg,
  passwordMsg,
  t,
} from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useSelector } from 'react-redux';
import type { AnonymousUserState, DialogState } from '../../common/types/states.js';
import type { State } from '../../selfservice/common/store.js';

import './Login.scss';

interface LoginProps {
  dialog?: DialogState | null;
}

const getPossibleErrorMessage = (
  anonymousUserState?: AnonymousUserState | null,
  dialogState?: DialogState | null
): JSX.Element | undefined => {
  if (dialogState?.submitInProgress) {
    return undefined;
  }
  if (anonymousUserState) {
    if (anonymousUserState.ssoSessionValid) {
      return undefined;
    } else if (anonymousUserState.errors && anonymousUserState.errors[0].type) {
      switch (anonymousUserState.errors[0].type) {
        case CommonErrorType.BAD_REQUEST:
        case CommonErrorType.FORBIDDEN:
        case CommonErrorType.VALIDATION:
          return (
            <>
              {t.O7AN('Login failed.')} <br />
              {t.CLP6('Please check your username and password.')}
            </>
          );
        case CommonErrorType.SYSTEM:
          if (anonymousUserState.errors[0].source?.error) {
            // These are JIT's, missing-mobiles and such, will be handled with Dialogs, no need to show error.
            return undefined;
          }
          if (anonymousUserState.errors[0].source?.statusCode === 204) {
            // These are OEC redirects, no need to show error.
            return undefined;
          }
          return <>{t.T4OU('Technical error, please try again later.')}</>;
        default:
          return undefined;
      }
    }
  }
  return undefined;
};

const showErrorPopover = (
  anonymousUserState?: AnonymousUserState | null,
  dialogState?: DialogState | null
): boolean => {
  if (dialogState?.submitInProgress) {
    return false;
  }
  if (anonymousUserState) {
    if (anonymousUserState.ssoSessionValid) {
      return false;
    } else {
      switch (anonymousUserState.errors?.[0]?.type) {
        case CommonErrorType.BAD_REQUEST:
        case CommonErrorType.FORBIDDEN:
        case CommonErrorType.VALIDATION:
          return true;
        default:
          return false;
      }
    }
  }
  return false;
};

/**
 * NOTE, this was written just using PL styles, reasons not to use react-patterns login -component:
 * 1. It's missing tests
 * 2. Passing any redux state changes (e.g. auth ok, failed auth...) seems to be really hard without any nasty hacks
 * 3. Does not seem to have any loading spinner / indicator
 *
 * AnonymousUser and dialog states are mandatory to handle all login related problems and features!
 */
export const Login = ({ dialog }: LoginProps) => {
  const { pathname } = useLocation();
  const { login, anonymousUser } = useAuth();
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [usernameMissing, setUsernameMissing] = React.useState(false);
  const [passwordMissing, setPasswordMissing] = React.useState(false);
  const [passwordAsClearText, setPasswordAsClearText] = React.useState(false);

  // Do not show spinner if dialog is loading
  const submitInProgress = dialog?.submitInProgress && dialog?.params?.type === undefined;

  const consumerPortalUrl =
    useSelector((s: State) => s.config.consumerMobiilivarmenneUrl) || 'https://verkkoasiointi.elisa.fi';

  const usernameHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setUsername(e.currentTarget.value || '');
    setUsernameMissing(!e.currentTarget.value);
  };

  const passwordHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setPassword(e.currentTarget.value || '');
    setPasswordMissing(!e.currentTarget.value);
  };

  if (anonymousUser?.mfaInfo) {
    return <MfaOtp phoneNumber={anonymousUser.mfaInfo.maskedPhone} />;
  }

  return (
    <>
      {dialog && (
        <LoginDialog dialog={dialog} userName={username} setUserName={setUsername} setPassword={setPassword} />
      )}
      <div className="of-login">
        <form
          onSubmit={e => {
            e.stopPropagation();
            e.preventDefault();
            login(username, password);
          }}
        >
          <div>
            <h3>{t.JOM2(loginToOmaElisaForCompaniesMsg)}</h3>
            <div className={`${dsClass.MARGIN_VERTICAL_3} ${dsClass.INPUTERROR} ${dsClass.DISPLAY_UNSET}`}>
              {getPossibleErrorMessage(anonymousUser, dialog)}{' '}
              {showErrorPopover(anonymousUser, dialog) && (
                <CL.Popover
                  triggerElement={<Icon color="red-600" icon="information" size="s" type="regular" />}
                  placement="right"
                  defaultOpen={true}
                  className="of-login-error-popover"
                  i18n_popover_closeButtonTitle={t.WOYD(closeMsg)}
                >
                  <p className={dsClass.FONT_SIZE_LARGE}>
                    <strong>{`${t.I3A5(
                      'Unfortunately, there was an error during the login process. Would any of the following help?'
                    )}`}</strong>
                  </p>
                  <ol className={dsClass.FONT_SIZE_LARGE}>
                    <li>
                      <a href={paths.FORGOT_PASSWORD}>{t.Z3W8('Reset your password')}</a>.
                    </li>
                    <li>
                      <a href={paths.REGISTER_PAGE}>{t.CKF5('Register for OmaElisa for companies')}</a>{' '}
                      {t.EZYP('again. Your OmaElisa ID may have expired.')}
                    </li>
                    <li>
                      {t.ZRR4(`Your company's services may also be available`)}{' '}
                      <a href="https://verkkoasiointi.elisa.fi/">{t.D1YH('on the consumer side')}</a>
                      {t.UZFU(`, e.g. Saunalahti's services for business customers can be found there.`)}
                    </li>
                    <li>
                      {t.C3XX(`You can always contact our customer service on`)} <CustomerServiceCallLink />
                      .
                      <br />
                      <small>
                        ({t.HQWU(customerServiceNumberOpenShortMsg)}, {t.HQWW(customerServiceNumberPriceMsg)})
                      </small>
                    </li>
                  </ol>
                </CL.Popover>
              )}
            </div>
            <div id="username-input" className={dsClass.INPUT}>
              <div className={dsClass.INPUT_LABELAREA}>
                <label className={dsClass.INPUT_LABELAREA_LABEL} htmlFor="username">
                  {t.TFV4(emailAddressOrIdMsg)}
                </label>
              </div>
              <div className={dsClass.INPUT_INPUTAREA}>
                <input
                  id="username"
                  className={dsClass.INPUT_INPUTAREA_INPUT}
                  name="username"
                  type="text"
                  placeholder={t.TFV4(emailAddressOrIdMsg)}
                  onChange={usernameHandler}
                  disabled={submitInProgress}
                  value={username}
                />
              </div>
              {usernameMissing && <div className={dsClass.INPUTERROR}>{t.SMI6('User ID is required')}</div>}
            </div>

            <div className={`${dsClass.INPUT} ${dsClass.INPUT_PASSWORD}`}>
              <div className={dsClass.INPUT_LABELAREA}>
                <label className={dsClass.INPUT_LABELAREA_LABEL} htmlFor="password-input">
                  {t.HCCZ(passwordMsg)}
                </label>
              </div>
              <div className={`${dsClass.INPUT_INPUTAREA} of-password-input`}>
                <input
                  id="password-input"
                  className={dsClass.INPUT_INPUTAREA_INPUT}
                  name="password"
                  type={passwordAsClearText ? 'text' : 'password'}
                  placeholder={t.HCCZ(passwordMsg)}
                  onChange={passwordHandler}
                  disabled={submitInProgress}
                  value={password}
                />
                <button
                  className={dsClass.INPUT_INPUTAREA_VIEWBUTTON}
                  tabIndex={0}
                  type="button"
                  aria-label={passwordAsClearText ? t.S4FM('Hide password') : t.F09D('Show password')}
                  onClick={() => setPasswordAsClearText(!passwordAsClearText)}
                >
                  <span
                    role="img"
                    title={passwordAsClearText ? t.S4FM('Hide password') : t.F09D('Show password')}
                    aria-hidden
                  />
                  {passwordAsClearText ? (
                    <CL.Icon key="view" icon="view" type="filled" />
                  ) : (
                    <CL.Icon key="view-off" icon="view-off" type="filled" />
                  )}
                </button>
              </div>
              {passwordMissing && <div className={dsClass.INPUTERROR}>{t.ATDL('Password required')}</div>}
            </div>
            <CL.Button
              id="login-submit-button"
              size="l"
              block
              disabled={!username || !password}
              type="submit"
              loading={submitInProgress}
            >
              {t.Z16I(logInMsg)}
            </CL.Button>
            <div className={`of-login-secondary-actions ${dsClass.PADDING_TOP_4} ${dsClass.DISPLAY_FLEX}`}>
              <Link to={paths.FORGOT_PASSWORD}>{t.EAFO(forgottenPasswordMsg)}</Link>
              <Link to={`${paths.REGISTER_PAGE}?${REDIRECT_PATH_KEY}=${encodeURIComponent(pathname)}`}>
                {t.CTMX(createIdMsg)}
              </Link>
            </div>
            <div className={dsClass.PADDING_TOP_4}>
              <b>
                {t.L88I(
                  `As a corporate customer of Elisa's consumer subscriptions (former Saunalahti corporate customer)`
                )}
              </b>
              <div>
                <span>{t.GY5T('you login using the')} </span>
                <a href={consumerPortalUrl}>{t.QE2O(consumerOmaElisaMsg)}</a>.
              </div>
            </div>
            <hr className={`${dsClass.MARGIN_TOP_4} ${dsClass.MARGIN_BOTTOM_4}`} />
            <div className={`${dsClass.MARGIN_TOP_4} ${dsClass.MARGIN_BOTTOM_4}`}>
              <b>{t.RKNP('Are you looking for Consumer OmaElisa?')}</b>
            </div>
            <div className={dsClass.PADDING_TOP_5}>
              <div className={`${dsClass.DISPLAY_FLEX} ${dsClass.JUSTIFY_CONTENT_SPACE_BETWEEN} of-other-logins`}>
                <div className={dsClass.PADDING_2}>
                  <button
                    className={`${dsClass.BUTTON} ${dsClass.BUTTON_COLOR_LINK} ${dsClass.BUTTON_SIZE_M}`}
                    onClick={() => location.assign(paths.EMPLOYEE_HOME)}
                  >
                    <span className="of-button-content">
                      <svg className="of-other-login-icon of-other-login-icon--home"></svg>
                      <span className={`${dsClass.PADDING_TOP_2} ${dsClass.TEXT_S}`}>
                        {t.S0FN(employeeOmaElisaMsg)}
                      </span>
                    </span>
                  </button>
                </div>
                <div className={dsClass.PADDING_2}>
                  <button
                    className={`${dsClass.BUTTON} ${dsClass.BUTTON_COLOR_LINK} ${dsClass.BUTTON_SIZE_M}`}
                    onClick={() => location.assign(consumerPortalUrl)}
                  >
                    <span className="of-button-content">
                      <svg className="of-other-login-icon of-other-login-icon--self-service" />
                      <span className={`${dsClass.PADDING_TOP_2} ${dsClass.TEXT_S}`}>
                        {t.PX2Z(consumerOmaElisaMsg)}
                      </span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
