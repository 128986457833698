import { FileInput } from '../../common/react-hook-form/components/FileInput.js';
import {
  addAttachmentMsg,
  fileSizeTooLargeMsg,
  fileTypeNotAllowedMsg,
  numberOfFilesLimitMsg,
  t,
  tooManyFilesMsg,
} from '../../common/i18n/index.js';
import { useState } from 'react';
import type { UploadStatus } from '../../common/react-hook-form/components/FileInput.js';

export interface AttachmentsProps {
  maxFiles: number;
  maxFileSizeMB: number;
  allowedFileTypes: string[];
  uploadStatus: UploadStatus;
}

const fieldName = 'attachments';
const kiloByte = 1024;

export const Attachments = ({ maxFiles, maxFileSizeMB, allowedFileTypes, uploadStatus }: AttachmentsProps) => {
  const [invalidFiles, setInvalidFiles] = useState<string[]>([]);

  const validate = (value: FileList): string | undefined => {
    if (!value) {
      return undefined;
    }
    const errors: string[] = [];
    const invalidFileNames: string[] = [];
    if (value.length > maxFiles) {
      errors.push(t.HPTO(tooManyFilesMsg, value.length.toString(), maxFiles.toString()));
    }
    Array.from(value).map(v => {
      if (v.size > maxFileSizeMB * kiloByte * kiloByte) {
        errors.push(t.LUAI(fileSizeTooLargeMsg, (v.size / kiloByte / kiloByte).toFixed(2).toString()));
        invalidFileNames.push(v.name);
      }
      const fileType = v.name.toLowerCase().substring(v.name.lastIndexOf('.'));
      if (!allowedFileTypes.includes(fileType)) {
        errors.push(t.K3VK(fileTypeNotAllowedMsg, fileType));
        invalidFileNames.push(v.name);
      }
    });
    setInvalidFiles(invalidFileNames);
    return errors.length > 0 ? [...new Set(errors)].join(', ') : undefined;
  };

  return (
    <FileInput
      name={fieldName}
      buttonColor="light"
      buttonIcon="attachment"
      chooseFileButtonText={t.WFZ0(addAttachmentMsg)}
      infoText={t.TVKW(numberOfFilesLimitMsg, maxFiles.toString(), maxFileSizeMB.toString())}
      allowedFileTypes={allowedFileTypes}
      invalidFiles={invalidFiles}
      validate={validate}
      uploadStatus={uploadStatus}
    />
  );
};
