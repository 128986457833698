import { DialogWrapper, buttonsForSubmitAndBack } from '../DialogWrapper/index.js';
import { SimType } from '../../generated/api/models.js';
import { changeSimCard } from '../../selfservice/actions/index.js';
import { closeMsg, t, yourOldSIMCardWillNowStopWorkingMsg } from '../../common/i18n/index.js';
import { useDispatch } from 'react-redux';
import type { CategoryKey } from '../../common/utils/categoryUtils.js';
import type { CommonError } from '../../common/types/errors.js';

export interface ChangeSimCardConfirmDialogAttrs {
  category?: CategoryKey;
  errors?: CommonError[];
  isLoading?: boolean;
  onCloseDialog: () => void;
  simCardNumber: string;
  subscriptionDisplayId?: string;
  subscriptionId: string;
}

export type ChangeSimCardConfirmDialogProps = ChangeSimCardConfirmDialogAttrs;

export const ChangeSimCardConfirmDialog = (props: ChangeSimCardConfirmDialogProps) => {
  const dispatch = useDispatch();

  const { category, errors, isLoading, onCloseDialog, simCardNumber, subscriptionDisplayId, subscriptionId } = props;

  return (
    <DialogWrapper
      buttons={
        errors
          ? buttonsForSubmitAndBack(onCloseDialog, undefined, t.WOYD(closeMsg))
          : buttonsForSubmitAndBack(
              onCloseDialog,
              () =>
                dispatch(
                  changeSimCard(
                    subscriptionId,
                    SimType.PHYSICAL, // We always use `PHYSICAL`, even for Laitenetti (M2M) which could be MINI_MICRO or NANO, since we have no way of knowing what the customer actually has. So here `PHYSICAL` doesn't mean it's the "classic" `PHYSICAL`, but just any kind of physical, as opposed to eSIM.
                    simCardNumber,
                    subscriptionDisplayId,
                    category,
                    undefined
                  )
                ),
              undefined,
              undefined,
              'OK',
              undefined,
              isLoading
            )
      }
      header={errors ? t.WBQT('SIM card activation failed') : t.SJ3Y(yourOldSIMCardWillNowStopWorkingMsg)}
      onCloseDialog={onCloseDialog}
    >
      {errors ? (
        <p>
          {t.WD0I(
            'Please double check you used the correct SIM card number. If the problem persists, please contact Elisa customer service.'
          )}
        </p>
      ) : (
        <p>{t.WHE6('New SIM card gets activated immediately after change.')}</p>
      )}
    </DialogWrapper>
  );
};
