import * as CL from '@design-system/component-library';
import { OpenFormGridCol, OpenFormGridRow } from '../../OpenFormComponents/OpenFormGrid.js';
import {
  eurosPerMonthMsg,
  monthlyChargeMsg,
  oneTimePaymentMsg,
  productsMsg,
  t,
} from '../../../../common/i18n/index.js';
import { formatSumToString } from '../../../../common/utils/priceUtils.js';
import type { OpenFormProductSummary } from '../../../../generated/api/openFormProductSummary.js';

export const OpenFormSummaryProductTable = ({
  products = [],
  services = [],
}: {
  products?: OpenFormProductSummary[];
  services?: OpenFormProductSummary[];
}) =>
  !products.length && !services.length ? null : (
    <OpenFormGridRow>
      <OpenFormGridCol colWidth={9}>
        <CL.Table
          className="of-openform__view__summary__product-table"
          columns={[
            { key: 'product', label: t.OT3E(productsMsg) },
            { key: 'oneOffCharge', label: t.ASEI(oneTimePaymentMsg) },
            { key: 'recurringCharge', label: t.P0PS(monthlyChargeMsg) },
          ]}
          rows={products.concat(services).map(({ oneOffCharge, productName, recurringCharge }) => ({
            product: <div className="of-openform__view__summary__product-table__bold">{productName}</div>,
            oneOffCharge: <span>{formatSumToString(oneOffCharge)}</span>,
            recurringCharge: <span>{`${formatSumToString(recurringCharge, true)} ${t.H37G(eurosPerMonthMsg)}`}</span>,
          }))}
        />
      </OpenFormGridCol>
    </OpenFormGridRow>
  );
