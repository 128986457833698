import { TypeKeys, generateActionId } from './actionsUtils.js';
import { convertToCommonErrors } from '../../common/utils/errorUtils.js';
import type { BillChannel, GenericError } from '../../generated/api/models.js';
import type { CrudAction, ErrorAction, ErrorActionCreator } from './actionsUtils.js';

export type LoadBillChannelsAction = CrudAction<TypeKeys.LOAD_BILL_CHANNELS>;

export const loadBillChannels = (): LoadBillChannelsAction => ({
  id: generateActionId(),
  type: TypeKeys.LOAD_BILL_CHANNELS,
});

export interface LoadBillChannelsFulfilledAction {
  billChannels?: BillChannel[];
  type: TypeKeys.LOAD_BILL_CHANNELS_FULFILLED;
}

export const loadBillChannelsFulfilled = (response: BillChannel[]): LoadBillChannelsFulfilledAction => {
  return {
    billChannels: response,
    type: TypeKeys.LOAD_BILL_CHANNELS_FULFILLED,
  };
};

export type LoadBillChannelsFailedAction = ErrorAction<TypeKeys.LOAD_BILL_CHANNELS_FAILED>;

export const loadBillChannelsFailed: ErrorActionCreator<TypeKeys.LOAD_BILL_CHANNELS_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): LoadBillChannelsFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    params,
    type: TypeKeys.LOAD_BILL_CHANNELS_FAILED,
  };
};

export type BillChannelsActionTypes =
  | LoadBillChannelsAction
  | LoadBillChannelsFailedAction
  | LoadBillChannelsFulfilledAction;
