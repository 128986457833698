import { t } from '../../common/i18n/index.js';

import './CardPaymentDisabledMessages.scss';

export const CardPaymentDisabledMessages = ({
  isCategoryValid,
  isPriceTypeValid,
  isStockValid,
  cardPaymentAllowed,
}: {
  isCategoryValid: boolean;
  isPriceTypeValid: boolean;
  isStockValid: boolean;
  cardPaymentAllowed: boolean;
}): JSX.Element => {
  if (!isCategoryValid || !isPriceTypeValid) {
    return (
      <div className="of-card-payment-disabled-message" id="invalid-categories-or-price-for-card-payment-message">
        <span>
          {t.QHJ4(
            'The Payment method for products with a monthly fee is always an invoice. Card payment is possible only for one-time fee devices and accessories.'
          )}
        </span>
      </div>
    );
  } else if (!isStockValid) {
    return (
      <div className="of-card-payment-disabled-message" id="invalid-stock-availability-for-card-payment-message">
        <span>
          {t.JXN2(
            'The Payment method for backordered products is always an invoice. Card payment is possible only when devices and accessories are in stock.'
          )}
        </span>
      </div>
    );
  } else if (!cardPaymentAllowed) {
    return (
      <div className="of-card-payment-disabled-message" id="card-payment-not-allowed-message">
        <span>{t.OEAI('Card payment is not possible for selected products.')}</span>
      </div>
    );
  }
  return <></>;
};
