import * as CL from '@design-system/component-library';
import { dsClass } from '../../common/constants/dsClasses.js';
import { t } from '../../common/i18n/index.js';

import './CookieConsent.scss';

export interface CookieConsentAttrs {
  hasAcceptedAnalyticsCookies: boolean;
}

export interface CookieConsentDispatchers {
  saveAnalyticsConsent: (data: boolean) => void;
}

export type CookieConsentProps = CookieConsentAttrs & CookieConsentDispatchers;

const HEADING_ID = 'of-cookie-consent-label';

// This is only for SFDC cookie consent, other cookies are handled via OneTrust
export const CookieConsent = ({ saveAnalyticsConsent, hasAcceptedAnalyticsCookies }: CookieConsentProps) => {
  return (
    <div className="of-cookie-consent">
      {hasAcceptedAnalyticsCookies ? null : (
        <section aria-labelledby={HEADING_ID} className="of-cookie-consent__content">
          <div className="of-cookie-consent__text">
            <h2 className={dsClass.H4} id={HEADING_ID}>
              {t.ESQW('May we combine browsing data with your customer information?')}
            </h2>
            {t.HAN0(
              'Combining data allows us to provide relevant content and promotions specifically to you on Elisa website. You may change your preferences at any time.'
            )}
            &nbsp;
            <a href="//elisa.fi/tietosuoja/" rel="noreferrer" target="_blank">
              {t.FDK6('More information on data protection at Elisa')}
            </a>
          </div>
          <div className="of-cookie-consent__buttons">
            <CL.Button color="light-inverse" onClick={() => saveAnalyticsConsent(false)} size="l">
              {t.HS4R('Deny')}
            </CL.Button>
            <CL.Button color="linkblue" onClick={() => saveAnalyticsConsent(true)} size="l">
              {t.O2VG('Accept')}
            </CL.Button>
          </div>
        </section>
      )}
    </div>
  );
};
