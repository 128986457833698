import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

// bordered, animated, ...

const MOBILE_PUSH_OPTIONS = [1, 2, 3, 4, 5, 6];
const MOBILE_GRID_OPTIONS = [0, ...MOBILE_PUSH_OPTIONS];
const NON_MOBILE_PUSH_OPTIONS = [...MOBILE_PUSH_OPTIONS, 6, 7, 8, 9, 10, 11, 12];
const NON_MOBILE_GRID_OPTIONS = [...MOBILE_GRID_OPTIONS, 6, 7, 8, 9, 10, 11, 12];
const RATIOS = ["1-1", "3-2", "8-9", "16-9", "3-1", "2-1", "5-2", "dynamic-33", "dynamic-66", "dynamic-100"];

const convertToKebabCase = (string) => {
  return string
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/\s+/g, "-")
    .toLowerCase();
};

const getGridItemClass = (prop, value) =>
  value !== undefined && value !== null ? `ea-fgrid__item--${convertToKebabCase(prop)}-${value}`.trim() : "";

class FluidGridItem extends React.Component {
  static get propTypes() {
    return {
      /** Class names you want to give to the component */
      className: PropTypes.string,
      /** Component's content. Automatically detected. */
      children: PropTypes.node,
      phone: PropTypes.oneOf(MOBILE_GRID_OPTIONS),
      phoneLarge: PropTypes.oneOf(MOBILE_GRID_OPTIONS),
      tablet: PropTypes.oneOf(NON_MOBILE_GRID_OPTIONS),
      laptop: PropTypes.oneOf(NON_MOBILE_GRID_OPTIONS),
      desktop: PropTypes.oneOf(NON_MOBILE_GRID_OPTIONS),
      phonePush: PropTypes.oneOf(MOBILE_PUSH_OPTIONS),
      phoneLargePush: PropTypes.oneOf(MOBILE_PUSH_OPTIONS),
      tabletPush: PropTypes.oneOf(NON_MOBILE_PUSH_OPTIONS),
      laptopPush: PropTypes.oneOf(NON_MOBILE_PUSH_OPTIONS),
      desktopPush: PropTypes.oneOf(NON_MOBILE_PUSH_OPTIONS),
      ratio: PropTypes.oneOf(RATIOS),
      phoneRatio: PropTypes.oneOf(RATIOS),
      phoneLargeRatio: PropTypes.oneOf(RATIOS),
      tabletRatio: PropTypes.oneOf(RATIOS),
      laptopRatio: PropTypes.oneOf(RATIOS),
      desktopRatio: PropTypes.oneOf(RATIOS),
      align: PropTypes.oneOf(["left", "center", "right"]),
    };
  }

  static get getDefaultProps() {
    return {
      phone: null,
      phoneLarge: null,
      tablet: null,
      laptop: null,
      desktop: null,
      phonePush: null,
      phoneLargePush: null,
      tabletPush: null,
      laptopPush: null,
      desktopPush: null,
      align: null,
    };
  }

  render() {
    const {
      className,
      align,
      phone,
      phoneLarge,
      tablet,
      laptop,
      desktop,
      phonePush,
      phoneLargePush,
      tabletPush,
      laptopPush,
      desktopPush,
      ratio,
      phoneRatio,
      phoneLargeRatio,
      tabletRatio,
      laptopRatio,
      desktopRatio,
      ...otherProps
    } = this.props;

    const breakpointTypes = { phone, phoneLarge, tablet, laptop, desktop };
    let breakpoints = [];
    Object.keys(breakpointTypes).map((prop) => {
      breakpoints.push(getGridItemClass(prop, breakpointTypes[prop]));
    });
    let breakpointClasses = breakpoints.join(" ");

    const offsetTypes = {
      phonePush,
      phoneLargePush,
      tabletPush,
      laptopPush,
      desktopPush,
    };
    let offsets = [];
    Object.keys(offsetTypes).map((prop) => {
      offsets.push(getGridItemClass(prop, offsetTypes[prop]));
    });
    let offsetClasses = offsets.join(" ");

    const ratioTypes = {
      ratio,
      phoneRatio,
      phoneLargeRatio,
      tabletRatio,
      laptopRatio,
      desktopRatio,
    };
    let ratios = [];
    Object.keys(ratioTypes).map((prop) => {
      ratios.push(getGridItemClass(prop, ratioTypes[prop]));
    });
    let ratioClasses = ratios.join(" ");

    const alignmentClass = align ? `ea-fgrid__item--${align}` : "";
    const classes = classnames([
      "ea-fgrid__item",
      className,
      alignmentClass,
      breakpointClasses,
      offsetClasses,
      ratioClasses,
    ]);

    return <div className={classes} {...otherProps} />;
  }
}

export default FluidGridItem;
