import { type BillingAccountData } from '../OpenFormAnswers.js';
import { eInvoiceMsg, emailInvoiceMsg, paperInvoiceMsg, t } from '../../../common/i18n/index.js';
import type { Address } from '../../../generated/api/address.js';
import type { BillingAccount } from '../../../generated/api/billingAccount.js';
import type { ContactHeader } from '../../../generated/api/contactHeader.js';

export const OpenFormBillingAccount = ({ billing }: { billing: BillingAccount | BillingAccountData }) => (
  <>
    <div>{billing.billingAccountName || billing.payerName}</div>
    <div>{billing.payerNameExtension}</div>
    <div>{billing.billingAccountExtensionName}</div>
  </>
);

export const OpenFormBillingInvoiceElectronic = ({ billing }: { billing: BillingAccount | BillingAccountData }) => (
  <>
    <div>
      {billing.billElectronicOperator} {billing.billElectronicAddress}
    </div>
    <div>{t.IK1D(eInvoiceMsg)}</div>
  </>
);

export const OpenFormBillingInvoiceEmail = ({ billing }: { billing: BillingAccount | BillingAccountData }) => (
  <>
    <div>{billing.billReceiverEmail || (billing as BillingAccountData).billingContactEmail}</div>
    <div>{t.NNV9(emailInvoiceMsg)}</div>
  </>
);

export const OpenFormBillingInvoicePaper = ({ address }: { address?: Address }) => (
  <>
    {!address ? null : (
      <>
        <div>{[address.line1, address.line2].filter(Boolean).join(' ')}</div>
        <div>
          {address.postalCode} {address.postOffice}
        </div>
      </>
    )}
    <div>{t.TER4(paperInvoiceMsg)}</div>
  </>
);

export const OpenFormContact = ({
  billing,
  contact,
}: {
  billing?: BillingAccount | BillingAccountData;
  contact?: ContactHeader;
}) =>
  contact ? (
    <>
      <div>
        {contact.firstName} {contact.lastName}
      </div>
      <div>{contact.email}</div>
      <div>{contact.phoneNumber}</div>
    </>
  ) : billing ? (
    <>
      <div>{billing.billingContactName}</div>
      <div>{(billing as BillingAccountData).billingContactEmail}</div>
      <div>{billing.billingContactPhone}</div>
    </>
  ) : null;
