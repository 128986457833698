import * as CL from '@design-system/component-library';
import { dsClass } from '../../common/constants/dsClasses.js';
import { nameMsg, purposeOfUseMsg, t } from '../../common/i18n/index.js';

interface DeliveryOrderDetailsUserProps {
  userContactName?: string;
  purposeOfUse?: string;
}

export const DeliveryOrderDetailsUser = ({ userContactName, purposeOfUse }: DeliveryOrderDetailsUserProps) => {
  return userContactName || purposeOfUse ? (
    <div className="of-delivery-order-details--section" key="of-delivery-order--section-user">
      <h3 className={dsClass.H3}>{t.NKJ8('User or purpose')}</h3>
      <CL.Description
        items={[
          {
            title: t.VGFI(nameMsg),
            description: userContactName,
          },
          {
            title: t.Y8KX(purposeOfUseMsg),
            description: purposeOfUse,
          },
        ].filter(item => !!item && item.description)}
      />
    </div>
  ) : null;
};
