import { TurbonappiFrontPageLink } from './TurbonappiFrontPageLink.js';
import { getVatAsString } from '../../common/utils/taxUtils.js';
import { t, turbonappiGeneralErrorMsg } from '../../common/i18n/index.js';

import './TurbonappiError.scss';

export const NO_TIME_LEFT_ERROR = 'Not allowed: Turbo time left';
export const BARRING_ACTIVE = 'Not allowed: Barring active';
export const NO_LTE_SERVICE = 'Not allowed: No LTE';
export const NOT_CORPORATE = 'Not allowed: Not corporate';
export const NOT_ELISA_SUBSCRIPTION = 'Not Elisa subscription';
export const NOT_ELISA_SUBSCRIPTION_ERROR = 'ERROR_CODE: 3503';

const parseErrorText = (validationFailureMessage: string): string => {
  if (validationFailureMessage.includes(NOT_ELISA_SUBSCRIPTION_ERROR)) {
    return NOT_ELISA_SUBSCRIPTION;
  }
  return validationFailureMessage;
};

const getValidationErrorMessage = (validationFailureMessage: string): string => {
  const validationError = parseErrorText(validationFailureMessage);

  switch (validationError) {
    case NO_TIME_LEFT_ERROR:
      return t.JZDY(
        'Your order was not successful because your Turbonappi service is still active, and previous order you placed is already waiting in queue. The new order will be activated automatically once the previous one ends. You will receive a text message whenever your Turbonappi service is about to expire.'
      );
    case NOT_ELISA_SUBSCRIPTION:
      return t.LEEP(
        'Your order was not successful because your subscription is not an Elisa corporate customer subscription.'
      );
    case BARRING_ACTIVE:
      return t.CFVF(
        'Your order was not successful because the domestic data usage on your subscription has been blocked. The block can be removed through the OmaElisa service or by contacting customer service.'
      );

    case NO_LTE_SERVICE:
      return t.JQSV(
        'Your order was not successful because your subscription does not have access to 4G or 5G features.'
      );
    case NOT_CORPORATE:
      return t.EYBW('Your subscription is not compatible with the service you ordered.');

    default:
      return t.KC2Q(turbonappiGeneralErrorMsg, getVatAsString());
  }
};

const getErrorMessage = (validationFailureMessage?: string): string => {
  if (validationFailureMessage) {
    return getValidationErrorMessage(validationFailureMessage);
  }
  return t.KC2Q(turbonappiGeneralErrorMsg, getVatAsString());
};

const getErrorHeader = (validationFailureMessage?: string): string => {
  if (validationFailureMessage) {
    return t.OPBC('The order was not successful');
  }
  return t.V0VJ('Oops, something went wrong');
};

interface TurbonappiErrorProps {
  validationFailureMessage?: string;
}

export const TurbonappiError = ({ validationFailureMessage }: TurbonappiErrorProps) => (
  <>
    <h1>{getErrorHeader(validationFailureMessage)}</h1>
    <div className="of-turbonappi-order-error__info-block">
      <div className="of-turbonappi-order-error__info-block__text-container">
        <div>
          <p>{getErrorMessage(validationFailureMessage)}</p>
        </div>
        <TurbonappiFrontPageLink />
      </div>
      <div className="of-turbonappi-order-error__info-block__icon-sim-error" />
    </div>
  </>
);

export const TurbonappiAlignedError = (props: TurbonappiErrorProps) => (
  <div className="of-turbonappi-order-error">
    <div className="of-turbonappi-order-error__details">
      <TurbonappiError {...props} />
    </div>
  </div>
);
