import { SubscriptionDetailsDevice } from '../../../generated/api/subscriptionDetailsDevice.js';
import { t } from '../../../common/i18n/index.js';
import type { DeliveryOrder } from '../../../generated/api/deliveryOrder.js';
import type { Subscription } from '../../../generated/api/subscription.js';

interface DeviceChange {
  newEppProduct: DeliveryOrder;
  currentEppProduct?: Subscription;
}

const getDeviceChange = (deliveryOrder: DeliveryOrder, deviceSubscriptions?: Subscription[]): DeviceChange => {
  const currentEppProduct = deviceSubscriptions?.find(
    s => s.subscriptionDisplayId === deliveryOrder?.replacedSubscriptionDisplayId
  );

  return {
    newEppProduct: deliveryOrder,
    currentEppProduct,
  };
};

export const getDeviceChanges = (
  deliveryOrders: DeliveryOrder[],
  deviceSubscriptions?: Subscription[]
): DeviceChange[] => {
  if (!Array.isArray(deviceSubscriptions)) {
    return [];
  }

  return deliveryOrders
    .map(deliveryOrder => getDeviceChange(deliveryOrder, deviceSubscriptions))
    .filter(deviceChange => deviceChange.currentEppProduct);
};

export const getEppActionLocalization = (replacementAction?: SubscriptionDetailsDevice.ReplacementActionEnum) => {
  switch (replacementAction) {
    case SubscriptionDetailsDevice.ReplacementActionEnum.RETURN_TO_ELISA:
      return t.JLB0('Will be returned to Elisa');
    case SubscriptionDetailsDevice.ReplacementActionEnum.REDEEM:
      return t.J3YS('User redeems');
    default:
      return '';
  }
};
