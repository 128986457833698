import * as CL from '@design-system/component-library';
import { SubscriptionStatusType } from '../../generated/api/models.js';
import {
  billingAccountMsg,
  durationOfContractMsg,
  idNumberMsg,
  listPriceMsg,
  openMsg,
  subscriptionStatusMsg,
  subscriptionTypeMsg,
  t,
} from '../../common/i18n/index.js';
import { doActionIfNotPendingSubscription } from '../../common/utils/subscriptionUtils.js';
import { getCommitmentText } from '../EmployeeSubscriptionDetails/eppSubscriptionUtils.js';
import { getPeriodicPriceAsText, getSubscriptionStatusFieldCL } from './subscriptionDetailsCommon.js';
import type { DialogParams } from '../../common/types/dialog.js';
import type { Subscription, SubscriptionDetails } from '../../generated/api/models.js';
import type { SubscriptionStatus } from '../../common/types/subscription.js';

export const getSubscriptionDetailsBroadband = (
  onShowDialog: (params: DialogParams) => void,
  subscription: Subscription,
  subscriptionStatus: SubscriptionStatus,
  details: SubscriptionDetails,
  onResumeSubscription: (subscriptionId: string) => void,
  billingAccountSelection?: JSX.Element
): JSX.Element => (
  <CL.Description
    items={[
      getSubscriptionStatusFieldCL(
        subscriptionStatus,
        t.LROP(subscriptionStatusMsg),
        subscription.subscriptionStatus === SubscriptionStatusType.SUSPENDED && !subscriptionStatus.pendingActions
          ? {
              link: () =>
                doActionIfNotPendingSubscription(subscriptionStatus, onShowDialog, () =>
                  onResumeSubscription(subscription.subscriptionId)
                ),
              text: t.K9K7(openMsg),
            }
          : undefined
      ),
      {
        title: t.CAA9(durationOfContractMsg),
        description: getCommitmentText(details),
      },
      {
        title: t.YTH3(idNumberMsg),
        description: subscription.subscriptionDisplayId,
      },
      {
        title: t.IFT9(billingAccountMsg),
        description: billingAccountSelection,
      },
      {
        title: t.N68W(listPriceMsg),
        description: getPeriodicPriceAsText(details.monthlyRecurringCharge),
      },
      {
        title: t.QH8P(subscriptionTypeMsg),
        description: subscription.subscriptionName,
      },
    ]}
  />
);
