import { deepEqual } from '../utils/objectUtils.js';
import { getCustomerLevelDiscountedPrices } from '../../selfservice/actions/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import type { State } from '../../selfservice/common/store.js';

export const useDiscountedPrices = () => {
  const dispatch = useDispatch();
  const companyInfo = useSelector((state: State) => state.selfservice?.companyInfo, deepEqual);
  const discountedPrices = companyInfo?.discountedPrices;
  const { ssoSessionValid } = useAuth();

  useEffect(() => {
    if (!discountedPrices && ssoSessionValid) {
      dispatch(getCustomerLevelDiscountedPrices());
    }
  }, [dispatch, discountedPrices, ssoSessionValid]);
};
