import { CommonErrorType } from '../../common/enums.js';
import { PHONE_NUMBER_REGEX } from '../../common/utils/phoneNumberUtils.js';
import { PbxNumberSettingsEditor } from './PbxNumberSettingsEditor.js';
import { PbxNumberSettingsView } from './PbxNumberSettingsView.js';
import { SubscriptionPbxConfiguration } from '../../generated/api/models.js';
import { changeSubscriptionPbxConfiguration, loadNumberRange } from '../../selfservice/actions/index.js';
import { convertStringMapToCommonErrors } from '../../common/utils/errorUtils.js';
import { fromKludgyConfig, toKludgyConfig } from './PbxNumberSettingsUtil.js';
import { invalidPhoneNumberMsg, t } from '../../common/i18n/index.js';
import { useDispatch } from 'react-redux';
import type { CommonError } from '../../common/types/errors.js';
import type { ConfigState, RangeNumbersState } from '../../common/types/states.js';
import type { DropdownKludgeSubscriptionPbxConfiguration } from './PbxNumberSettingsUtil.js';
import type { Subscription } from '../../generated/api/models.js';

import './PbxNumberSettingsAccordionContent.scss';

const getErrorsFromPbxNumberSettings = (
  pbxConfig: SubscriptionPbxConfiguration,
  validationErrors?: { [s: string]: string }
) => {
  const errors: CommonError[] = [];
  if (
    pbxConfig.busyAction === SubscriptionPbxConfiguration.BusyActionEnum.CONNECT_TO_NUMBER &&
    (!pbxConfig.connectToNumber || !PHONE_NUMBER_REGEX.test(pbxConfig.connectToNumber))
  ) {
    errors.push({
      field: 'connectToNumber',
      message: t.YLCX(invalidPhoneNumberMsg),
      parent: 'pbxSettings',
      type: CommonErrorType.VALIDATION,
    });
  }

  const convertedValidationErrors = convertStringMapToCommonErrors(validationErrors);
  if (convertedValidationErrors) {
    errors.push(...convertedValidationErrors);
  }

  return errors.length > 0 ? errors : undefined;
};

interface PbxNumberSettingsAccordionContentAttrs {
  config: ConfigState;
  extensionNumberRange?: RangeNumbersState;
  subscriptionPbxConfiguration: SubscriptionPbxConfiguration;
  numberRange?: RangeNumbersState;
  subscriptionId: string;
  subscriptionDisplayId: string;
  editing?: boolean;
  errors?: CommonError[];
  pbxSolution: Subscription;
  saving?: boolean;
}

export interface PbxNumberSettingsAccordionContentDispatchers {
  onCancel: () => void;
  onEdit: () => void;
}

export type PbxNumberSettingsAccordionContentProps = PbxNumberSettingsAccordionContentAttrs &
  PbxNumberSettingsAccordionContentDispatchers;

export const PbxNumberSettingsAccordionContent = ({
  editing,
  extensionNumberRange,
  subscriptionPbxConfiguration: properConfig,
  numberRange,
  onCancel,
  onEdit: onEditPbxNumberSettings,
  subscriptionId,
  subscriptionDisplayId,
  saving,
}: PbxNumberSettingsAccordionContentProps) => {
  const subscriptionPbxConfiguration = toKludgyConfig(properConfig);
  const dispatch = useDispatch();

  const onStartEdit = () => {
    if (subscriptionPbxConfiguration.corporateNumberRangeId && !numberRange) {
      dispatch(loadNumberRange(subscriptionPbxConfiguration.corporateNumberRangeId));
    }

    if (subscriptionPbxConfiguration.extensionRangeId && !extensionNumberRange) {
      dispatch(loadNumberRange(subscriptionPbxConfiguration.extensionRangeId));
    }

    onEditPbxNumberSettings();

    return true;
  };

  const onConfirmEdit = (kludgyConfig: DropdownKludgeSubscriptionPbxConfiguration) => {
    const pbxConfig = fromKludgyConfig(kludgyConfig);
    pbxConfig.subscriptionDisplayId = subscriptionDisplayId;

    dispatch(changeSubscriptionPbxConfiguration(subscriptionId, pbxConfig, getErrorsFromPbxNumberSettings));
  };

  if (editing) {
    return (
      <PbxNumberSettingsEditor
        subscriptionPbxConfiguration={subscriptionPbxConfiguration}
        numberRange={numberRange}
        extensionNumberRange={extensionNumberRange}
        onConfirm={onConfirmEdit}
        onCancel={onCancel}
        saving={saving}
      />
    );
  }

  return (
    <PbxNumberSettingsView subscriptionPbxConfiguration={subscriptionPbxConfiguration} onStartEdit={onStartEdit} />
  );
};
