import { HeaderSectionContent, SectionContent } from '../SectionContent.js';
import { SearchResultRow } from '../SearchResultRow.js';
import {
  approverContactMsg,
  customerMsg,
  idNumberMsg,
  imeiSlashSerialNumberMsg,
  installationAddressMsg,
  simCardNumberMsg,
  subscriptionNumberMsg,
  t,
} from '../../../common/i18n/index.js';
import { formatTimestampToDDMMYYYY } from '../../../common/utils/dateUtils.js';
import { generatePath } from 'react-router-dom';
import { getCustomerOrderStatusInfoDS } from '../../CustomerOrderDetails/CustomerOrderDetailsUtil.js';
import { getResultObjectWithHighlightedValues } from '../highlightFunctions.js';
import { getSearchSnippetText, truncateText } from '../../../common/utils/stringUtils.js';
import { paths } from '../../../common/constants/pathVariables.js';
import type { CustomerOrderHeader } from '../../../generated/api/customerOrderHeader.js';
import type { SearchResultBaseProps } from '../SearchResultRow.js';

export interface OrderProps extends SearchResultBaseProps {
  customerOrder: CustomerOrderHeader;
}

const shorterTruncateMaxChars = 45;
const longerTruncateMaxChars = 50;

export const Order = ({ customerOrder, matchedFields, searchTerms, ...rest }: OrderProps) => {
  const {
    customerOrderDisplayId,
    deliveryOrderContactNames,
    deliveryOrderPurposeOfUses,
    deliveryOrders,
    deliveryOrderSerialNumbers,
    deliveryOrderVoiceSubscriptionNumbers,
    deliveryOrderSimNumbers,
    deliveryOrderMSISDNs,
    deliveryOrderInstallationAddresses,
    selectedApproverName,
    customerOrderContactName,
  } = getResultObjectWithHighlightedValues(
    {
      ...customerOrder,
      deliveryOrderContactNames:
        getSearchSnippetText(customerOrder.deliveryOrderContactNames, searchTerms) ||
        truncateText(customerOrder.deliveryOrderContactNames, shorterTruncateMaxChars),
      deliveryOrderPurposeOfUses:
        getSearchSnippetText(customerOrder.deliveryOrderPurposeOfUses, searchTerms) ||
        truncateText(customerOrder.deliveryOrderPurposeOfUses, shorterTruncateMaxChars),
      deliveryOrderSerialNumbers: getSearchSnippetText(customerOrder.deliveryOrderSerialNumbers, searchTerms),
      deliveryOrders:
        customerOrder.deliveryOrders?.length === 1
          ? customerOrder.deliveryOrders
          : getSearchSnippetText(customerOrder.deliveryOrders, searchTerms) ||
            truncateText(customerOrder.deliveryOrders, longerTruncateMaxChars),
      deliveryOrderVoiceSubscriptionNumbers: getSearchSnippetText(
        customerOrder.deliveryOrderVoiceSubscriptionNumbers,
        searchTerms
      ),
      deliveryOrderSimNumbers: getSearchSnippetText(customerOrder.deliveryOrderSimNumbers, searchTerms),
      deliveryOrderMSISDNs: getSearchSnippetText(customerOrder.deliveryOrderMSISDNs, searchTerms),
      deliveryOrderInstallationAddresses:
        customerOrder.deliveryOrderInstallationAddresses?.length === 1
          ? customerOrder.deliveryOrderInstallationAddresses[0]
          : getSearchSnippetText(customerOrder.deliveryOrderInstallationAddresses, searchTerms),
    },
    matchedFields,
    searchTerms
  );
  const { color, text } = getCustomerOrderStatusInfoDS(customerOrder.status);
  return (
    <SearchResultRow
      icon="delivery"
      upperSection={
        <HeaderSectionContent
          left={
            <>
              {t.C001('Order')}: {customerOrderDisplayId}
            </>
          }
          right={deliveryOrderContactNames || deliveryOrderPurposeOfUses}
        />
      }
      lowerSection={
        <SectionContent
          statusColor={color}
          left={`${text} ${formatTimestampToDDMMYYYY(customerOrder.lastModified)}`}
          right={deliveryOrders}
        />
      }
      additionalHighlightableFields={[
        { title: t.GOZ7(approverContactMsg), value: selectedApproverName, key: 'selectedApproverName' },
        {
          title: t.ZC7D(customerMsg),
          value: customerOrderContactName || '',
          key: 'customerOrderContactName',
        },
        {
          title: t.OE45(imeiSlashSerialNumberMsg),
          value: deliveryOrderSerialNumbers,
          key: 'deliveryOrderSerialNumbers',
        },
        {
          title: t.YTH3(idNumberMsg),
          value: deliveryOrderVoiceSubscriptionNumbers,
          key: 'deliveryOrderVoiceSubscriptionNumbers',
        },
        {
          title: t.VLQ3(simCardNumberMsg),
          value: deliveryOrderSimNumbers,
          key: 'deliveryOrderSimNumbers',
        },
        {
          title: t.DP44(subscriptionNumberMsg),
          value: deliveryOrderMSISDNs,
          key: 'deliveryOrderMSISDNs',
        },
        {
          title: t.DI4V(installationAddressMsg),
          value: deliveryOrderInstallationAddresses,
          key: 'deliveryOrderInstallationAddresses',
        },
      ]}
      matchedFields={matchedFields}
      linkHref={`${generatePath(paths.CUSTOMER_ORDER, { orderId: customerOrder.customerOrderDisplayId })}?mdmId=${customerOrder.accountMasterId}`}
      {...rest}
    />
  );
};
