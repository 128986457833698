import * as CL from '@design-system/component-library';
import { ConfirmationModal } from '../../../Modal/ConfirmationModal.js';
import {
  DEFAULT_ITEMS_PER_PAGE,
  type DefaultListSearchParams,
  ListPagination,
  ListSearch,
  Table,
} from '../../../Table/index.js';
import { DnsRecordCreate } from './DnsRecordCreate.js';
import { DnsRecordDisclaimerReadOnly } from './DnsRecordDisclaimerReadOnly.js';
import { DnsRecordEditableFields, getDnsRecordRequest, isDnsRecordEditable } from '../../dnsManagementUtils.js';
import { DnsRecordSaveConfirmation } from './DnsRecordListRow/DnsRecordSaveConfirmation.js';
import { DnsRecordSupportedType } from '../../../../generated/api/models.js';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { GridWide } from '../../../Grid/Grid.js';
import { SearchFilters } from '../../../Table/index.js';
import { dsClass } from '../../../../common/constants/dsClasses.js';
import { formatRecordListEditRow } from './DnsRecordListRow/DnsRecordListRowEdit.js';
import { formatRecordListViewRow } from './DnsRecordListRow/DnsRecordListRowView.js';
import { getArrayPropertySorter } from '../../../../common/utils/arrayUtils.js';
import { isInBrowser } from '../../../../common/utils/ssrUtils.js';
import { resetPagingParams, setDefaultItemsPerPage } from '../../../Table/tableUtils.js';
import { t, typeMsg, valueMsg } from '../../../../common/i18n/index.js';
import { tableSearchRow } from '../../../Table/TableDataUtil.js';
import { useEffect, useState } from 'react';
import { useSearchParams } from '../../../../common/hooks/useSearchParams.js';
import type {
  DnsRecordEditable,
  DnsRecordEditableForm,
  DnsRecordSupported,
  DnsRecordsSearchParams,
} from '../../dnsManagementUtils.js';
import type {
  DnsRecordEditableValue,
  DnsRecordRequest,
  DnsRecordSupportedValue,
  DnsRecordsResponse,
} from '../../../../generated/api/models.js';
import type { SearchFilterGroup } from '../../../Table/SearchFilters.js';

import './DnsRecordList.scss';

const DISCLAIMER_ID = 'dns-record-edit-disclaimer';

export const DnsRecordList = ({
  recordsData: { dnsManagementDisallowed, domainName, nextCursor, prevCursor, records, total },
  onCreate,
  onDelete,
  onUpdate,
}: {
  recordsData: DnsRecordsResponse;
  onCreate: (payload: DnsRecordRequest) => void;
  onDelete: (recordId: number) => void;
  onUpdate: (recordId: number, payload: DnsRecordRequest) => void;
}) => {
  const { sort, search, order, recordType } = useSearchParams<DnsRecordsSearchParams>();
  const methods = useForm<DnsRecordEditableForm>({ mode: 'all' });
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: DnsRecordEditableFields.values,
  });
  const searchParams = useSearchParams<DefaultListSearchParams>();

  // Id of the item the user is currently editing (undefined if no editor open)
  const [editingItemId, setEditingItemId] = useState<number>();

  // Item that the user is currently deleting (for delete confirmation modal)
  const [deletingItem, setDeletingItem] = useState<DnsRecordSupported>();

  // Item that the user is currently saving (for save confirmation modal)
  const [savingItem, setSavingItem] = useState<DnsRecordRequest>();

  // We allow only one inline-editor to be open at time
  // If the user tries to open up a second one we show the notification at the top of the table
  const [showOnlyOneEditMessage, setShowOnlyOneEditMessage] = useState(false);

  const [filtersOpen, setFiltersOpen] = useState(false);

  useEffect(() => {
    // If the banner is out of the view, scroll to it so that the user can see the warning
    if (showOnlyOneEditMessage && isInBrowser()) {
      const disclaimerElement = document.getElementById(DISCLAIMER_ID);
      const disclaimerTop = disclaimerElement?.getBoundingClientRect()?.top;
      if (disclaimerTop && disclaimerTop < 0) {
        disclaimerElement?.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [showOnlyOneEditMessage]);

  // It is enough to add an empty object to the field array, react-hook-form initialises the correct fields for us
  const addValue = () => append({} as DnsRecordEditableValue);

  const confirmModifications = () => {
    if (editingItemId) {
      setSavingItem(getDnsRecordRequest(methods.getValues()));
    }
  };

  const onCancelEdit = () => setEditingItemId(undefined);

  const onDeleteItem = () => {
    if (deletingItem) {
      setDeletingItem(undefined);
      onDelete(deletingItem.id);
    }
  };

  const onOpenEdit = (row: DnsRecordEditable) => {
    if (editingItemId !== undefined) {
      setShowOnlyOneEditMessage(true);
    } else {
      methods.setValue(DnsRecordEditableFields.name, row.name);
      methods.setValue(DnsRecordEditableFields.type, row.type);
      methods.setValue(DnsRecordEditableFields.ttl, row.ttl);
      methods.setValue(DnsRecordEditableFields.values, row.rawValues);

      // Trigger here clears the validation errors that were caused by the previous values
      methods.trigger().then();
      setEditingItemId(row.id);
    }
  };

  const onSubmitRow = () => {
    if (editingItemId) {
      setEditingItemId(undefined);
      setSavingItem(undefined);
      setShowOnlyOneEditMessage(false);
      onUpdate(editingItemId, getDnsRecordRequest(methods.getValues()));
    }
  };

  const typeFilter = recordType?.split('|');

  const recordTypeFilter = {
    label: () => t.F9G1('Type'),
    value: 'recordType',
    items: Object.values(DnsRecordSupportedType).map(type => ({
      label: () => type,
      value: type,
      checked: typeFilter?.includes(type),
    })),
  } satisfies SearchFilterGroup;

  const columns = [
    { key: 'name', label: t.PNS1('Hostname'), sortable: true },
    { key: 'type', label: t.F9G1('Type'), sortable: true },
    { key: 'values', label: t.RPL9(valueMsg), sortable: false },
    { key: 'ttl', label: 'TTL (s)', sortable: true },
    { key: 'actionButtons', label: '', sortable: false },
  ] satisfies CL.Column[];

  const rows = records
    .map(row => ({
      id: row.id,
      name: row.name,
      type: row.type,
      values: row.values.flatMap(Object.values).join(' '),
      rawValues: row.values as Array<DnsRecordSupportedValue>,
      ttl: row.ttl,
    }))
    .filter(row => (typeFilter?.includes(row.type) ?? true) && tableSearchRow(search, row))
    .sort(getArrayPropertySorter(sort, order))
    .map(row =>
      editingItemId === row.id && isDnsRecordEditable(row)
        ? formatRecordListEditRow(row.type, fields, dnsManagementDisallowed, onCancelEdit, addValue, remove)
        : formatRecordListViewRow(row, domainName, dnsManagementDisallowed, onOpenEdit, setDeletingItem)
    ) satisfies CL.KeyRow[];

  return (
    <GridWide>
      <div className={dsClass.MARGIN_VERTICAL_3}>
        {dnsManagementDisallowed ? (
          <DnsRecordDisclaimerReadOnly email="hostmaster@elisa.fi" />
        ) : (
          <DnsRecordCreate domainName={domainName} onCreate={onCreate} />
        )}
        <div className="of-dns-record-list">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(confirmModifications)}>
              {showOnlyOneEditMessage && (
                <CL.Disclaimer
                  id={DISCLAIMER_ID}
                  className={dsClass.MARGIN_VERTICAL_3}
                  icon={<CL.Icon icon="information" type="filled" aria-hidden="true" />}
                  disclaimerType="warning"
                  showCloseButton
                  onDisclaimerHide={() => setShowOnlyOneEditMessage(false)}
                >
                  <strong>{t.NH88('Record editing in progress')}</strong>
                  <br />
                  <p>{t.SNOO('You can only edit one record at a time. Save or cancel current changes.')}</p>
                </CL.Disclaimer>
              )}
              <ListSearch
                className={dsClass.MARGIN_TOP_4}
                filtersOpen={filtersOpen}
                onSearch={() => setDefaultItemsPerPage(new URLSearchParams(searchParams), DEFAULT_ITEMS_PER_PAGE)}
                setFiltersOpen={setFiltersOpen}
              />
              <SearchFilters
                displayActiveFiltersAsTiles={!filtersOpen}
                filterGroups={[recordTypeFilter]}
                onModalClose={() => setFiltersOpen(false)}
              />
              <Table
                columns={columns}
                noItemsText={t.VA1O('No records')}
                onSortChange={() => resetPagingParams(new URLSearchParams(searchParams), true)}
                rows={rows}
                valignText="top"
              />
              <ListPagination
                totalItems={total}
                cursorNavigation={true}
                prevCursor={prevCursor}
                nextCursor={nextCursor}
              />
              {deletingItem ? (
                <ConfirmationModal
                  confirmText={t.R3VE('Delete')}
                  heading={t.WWYF('Are you sure you want to delete the record?')}
                  onCancel={() => setDeletingItem(undefined)}
                  onConfirm={onDeleteItem}
                >
                  <div>
                    <p>
                      {t.MOAZ(
                        'You are about to delete the record below. It is not possible to undo this action. Are you sure you want to delete the record?'
                      )}
                    </p>
                    <p>
                      {deletingItem.name}, {t.VGFF(typeMsg)}: {deletingItem.type}
                    </p>
                  </div>
                </ConfirmationModal>
              ) : null}
              {savingItem && (
                <DnsRecordSaveConfirmation
                  row={savingItem}
                  onCancel={() => setSavingItem(undefined)}
                  onConfirm={onSubmitRow}
                />
              )}
            </form>
          </FormProvider>
        </div>
      </div>
    </GridWide>
  );
};
