export enum AuthorizationRequestType {
  OWNER_CHANGE = 'OWNER_CHANGE',
}

// If resource loading has not yet started status is 'undefined'
export enum ChatResourceLoadStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  READY = 'READY',
}

export enum CommonErrorType {
  VALIDATION = 'VALIDATION',
  NOT_FOUND = 'NOT_FOUND',
  FORBIDDEN = 'FORBIDDEN',
  SYSTEM = 'SYSTEM',
  BAD_REQUEST = 'BAD_REQUEST',
  UNPROCESSABLE_ENTITY = 'UNPROCESSABLE_ENTITY',
}

export enum CreditDecision {
  AcceptedDeposit = 'ACCEPTED_DEPOSIT',
  AcceptedInternal = 'ACCEPTED_INTERNAL',
  Accepted = 'ACCEPTED',
  DeniedBlacklist = 'DENIED_BLACKLIST',
  DeniedCreditLimit = 'DENIED_CREDIT_LIMIT',
  DeniedExternal = 'DENIED_EXTERNAL',
  DeniedInternal = 'DENIED_INTERNAL',
  DeniedCompanyRegistrationPending = 'DENIED_COMPANY_REGISTRATION_PENDING',
  Error = 'INTERNAL_ERROR',
}

export enum DeviceChangeOption {
  REDEEM = 'REDEEM',
  RETURN = 'RETURN',
  NONE = 'NONE',
}

export enum DialogType {
  ADD_CONTACT = 'ADD_CONTACT',
  ASK_ABOUT_INVOICE = 'ASK_ABOUT_INVOICE',
  DAY_PICKER_DIALOG = 'DAY_PICKER_DIALOG',
  DUPLICATE_CONTACT = 'DUPLICATE_CONTACT',
  DUPLICATE_CONTACTS_CHECK = 'DUPLICATE_CONTACTS_CHECK',
  DUPLICATE_CONTACT_INFORMATION = 'DUPLICATE_CONTACT_INFORMATION',
  POSSIBLE_DUPLICATE_CONTACT_INFORMATION = 'POSSIBLE_DUPLICATE_CONTACT_INFORMATION',
  GENERIC_CONFIRMATION_DIALOG = 'GENERIC_CONFIRMATION_DIALOG',
  NUMBER_PRIVACY_INSTRUCTIONS = 'NUMBER_PRIVACY_INSTRUCTIONS',
  GENERIC_INFO_DIALOG = 'GENERIC_INFO_DIALOG',
  ORDER_SIM_CARD_NUMBER_HELP = 'ORDER_SIM_CARD_NUMBER_HELP',
  REVIEW_ONLINE_ORDER = 'REVIEW_ONLINE_ORDER',
  CHANGE_BILLING_ACCOUNT = 'CHANGE_BILLING_ACCOUNT',
  CHANGE_SUBSCRIPTION_CONTACT = 'CHANGE_SUBSCRIPTION_CONTACT',
  CHANGE_NETTI_LITE_SUBSCRIPTION = 'CHANGE_NETTI_LITE_SUBSCRIPTION',
  UPDATE_LIIKKUVA_WIFI_SUBSCRIPTION = 'UPDATE_LIIKKUVA_WIFI_SUBSCRIPTION',
  SUBSCRIPTION_CHANGE_SUBSCRIPTION = 'SUBSCRIPTION_CHANGE_SUBSCRIPTION',
  SUBSCRIPTION_SUPPORT_REQUEST = 'SUBSCRIPTION_SUPPORT_REQUEST',
  SUBSCRIPTION_SUBMIT_SUPPORT_CASE = 'SUBSCRIPTION_SUPPORT_CASE',
  SUBSCRIPTION_RESUME_FROM_TEMPORARILY_CLOSE = 'SUBSCRIPTION_RESUME_FROM_TEMPORARILY_CLOSE',
  SUBSCRIPTION_TERMINATE_REQUEST = 'SUBSCRIPTION_TERMINATE_REQUEST',
  SUBSCRIPTION_TERMINATE_DEVICE_SERVICE = 'SUBSCRIPTION_TERMINATE_DEVICE_SERVICE',
  SUBSCRIPTION_TERMINATION_FORBIDDEN = 'SUBSCRIPTION_TERMINATION_FORBIDDEN',
  SUBSCRIPTION_ACTION_PENDING = 'SUBSCRIPTION_ACTION_PENDING',
  MACD_FORBIDDEN_SUBSCRIPTION_IN_ACTIVATION = 'MACD_FORBIDDEN_SUBSCRIPTION_IN_ACTIVATION',
  SUBSCRIPTION_CHANGE_SPECIAL_NUMBER = 'SUBSCRIPTION_CHANGE_SPECIAL_NUMBER',
  RING_SUBSCRIPTION_UPDATE_USER = 'RING_SUBSCRIPTION_UPDATE_USER',
  SELECT_USERNAME = 'SELECT_USERNAME',
  SUBMIT_ORDER = 'SUBMIT_ORDER',
  SSO_FORBIDDEN = 'SSO_FORBIDDEN',
  EXTERNAL_AUTHENTICATION_FAIL = 'EXTERNAL_AUTHENTICATION_FAIL',
  CHANGE_SIM_CARD_CONFIRMATION = 'CHANGE_SIM_CARD_CONFIRMATION',
  UPSERT_CONTACT = 'UPSERT_CONTACT',
  UPDATE_CONTACT_DUPLICATE = 'UPDATE_CONTACT_DUPLICATE',
  UPDATE_CONTACT_YTT_WARNING = 'UPDATE_CONTACT_YTT_WARNING',
  UPDATE_USER_YTT_WARNING = 'UPDATE_USER_YTT_WARNING',
  ONBOARD_EMPLOYEE = 'ONBOARD_EMPLOYEE',
  BANK_BUTTONS = 'BANK_BUTTONS',
  EPP_DEVICE_CHANGE = 'EPP_DEVICE_CHANGE',
  EPP_REDEEM = 'EPP_REDEEM',
  EPP_REDEEM_REJECT = 'EPP_REDEEM_REJECT',
  EPP_TERMINATE = 'EPP_TERMINATE',
  SELECT_PRIMARY_COMPANY = 'SELECT_PRIMARY_COMPANY',
  MISSING_MOBILE_NUMBER = 'MISSING_MOBILE_NUMBER',
  ADD_RING_ADMIN = 'ADD_RING_ADMIN',
  NEW_RING_USERNAME = 'NEW_RING_USERNAME',
  REMOVE_RING_ADMIN = 'REMOVE_RING_ADMIN',
  SWITCH_ACCOUNT = 'SWITCH_ACCOUNT',
  CHANGE_OFFER = 'CHANGE_OFFER',
  CHANGE_OFFER_NOT_AVAILABLE = 'CHANGE_OFFER_NOT_AVAILABLE',
  DETACH_RING = 'DETACH_RING',
  COPY_CATALOG_MULTI_BUSINESS = 'COPY_CATALOG_MULTI_BUSINESS',
  SENDING_ONBOARDING_REQUEST = 'SENDING_ONBOARDING_REQUEST',
  BB_SUB_EMPLOYEE_INVITATION = 'BB_SUB_EMPLOYEE_INVITATION',
  BB_SUB_EMPLOYEE_NOT_FOUND = 'BB_SUB_EMPLOYEE_NOT_FOUND',
  SUPPORT_CASE_SENT_INFO_DIALOG = 'SUPPORT_CASE_SENT_INFO_DIALOG',
  USER_RIGHTS_IN_SALESFORCE = 'USER_RIGHTS_IN_SALESFORCE',
  MOVE_PAYMENT_DATE = 'MOVE_PAYMENT_DATE',
  GENERIC_EMPTY_DIALOG = 'GENERIC_EMPTY_DIALOG',
  MFA_REQUIRED_PHONE = 'MFA_REQUIRED_PHONE',
  NEW_INTERWORKS_ACCOUNT_DIALOG = 'NEW_INTERWORKS_ACCOUNT_DIALOG',
}

export enum DirectoryDetailsType {
  MOBILE = 'MOBILE',
  CORPORATION = 'CORPORATION',
}

// TODO: Remove the extra fieldType when formik replaces compositeFieldGrid completely.
export enum FieldType {
  STRING = 'string',
  COMBO_BOX = 'combo-box',
  RADIO = 'radio',
  NODE = 'node',
  BUTTON = 'button',
  SEPARATOR = 'separator',
  CHECKBOX = 'checkbox',
  SEPARATE_CHARACTER = 'separate-character',
  DATEPICKER = 'DATEPICKER',
}

export enum InProgressCatalogAction {
  DELETE_VIRTUAL_CATALOG_DRAFT = 'DELETE_VIRTUAL_CATALOG_DRAFT',
  PUBLISH_VIRTUAL_CATALOG_DRAFT = 'PUBLISH_VIRTUAL_CATALOG_DRAFT',
  UPSERT_VIRTUAL_CATALOG_DRAFT = 'UPSERT_VIRTUAL_CATALOG_DRAFT',
}

// In order to get test data for all of these, these GUIDS are also repeated in:
// /test-data/src/main/resources/uiapi/ui-api-test-data-paths.yaml
export enum ModelType {
  MobileBroadband = '02903874-007d-29ad-d3ea-9eb5924ba8bf',
  MobileM2M = '9b86020b-b6d7-0658-5bc6-f13fba724643',
  Ring = '9e7bf5da-c7c2-484c-c95b-5fc60d0f5011',
  Voice = '3365478d-9bc5-4efb-206c-19ed3b4b2803',
  VoicePerusliittyma4g = 'b4ff3f8d-a687-bc07-52b4-1c9fab4e6fad',
  VoiceYrityspuhe = 'f7f899e2-c489-a59f-f2a0-cfd6f320fcb7',
  Yritystupla = 'f563e704-decf-4a6c-b1a3-144a254dac27',
  VoiceSME = '79fb1a8a-27ce-4dc3-b945-ab6d3c5b765f',
  Yrityspaketti = '5c40499d-cd17-5b6a-e861-08427765f5d7',
}

export enum PhoneNumberOwnerType {
  PERSON = 'PERSON',
  COMPANY = 'COMPANY',
}

export enum PhoneNumberType {
  NEW = 'NEW',
  EXISTING = 'EXISTING',
}

export enum ReduxStateEnum {
  FULFILLED = 'FULFILLED',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export enum SelectedPurposeOfUseOrContact {
  CONTACT = 'CONTACT',
  PURPOSEOFUSE = 'PURPOSEOFUSE',
}

export enum SimCardSelection {
  ORDER_NEW = 'ORDER_NEW',
  ACTIVATE_EXISTING = 'ACTIVATE_EXISTING',
}

export enum SubscriptionCategory {
  BROADBAND = 'broadband',
  VOICE = 'voice',
  DEVICE = 'device',
  SUBSCRIPTIONS = 'subscriptions',
  SERVICE = 'service',
  DOMAIN = 'domain',
  CONTRACT = 'contract',
  LICENSES = 'licenses',
  ELISA_DNS = 'elisadns',
}

export enum SubscriptionFilterValue {
  ALL = 'All',
  MOBILE_SUBSCRIPTIONS = 'Mobile subscriptions',
  FIXED_LINE_SUBSCRIPTIONS = 'Fixed line subscriptions',
  FIXED_BROADBAND = 'Fixed broadband',
  MOBILE_BROADBANDS = 'Mobile broadbands',
  PHONE = 'Phone',
  TABLET = 'Tablet',
  COMPUTERS = 'Computers',
  ACCESSORIES = 'Accessories',
  NETWORK_EQUIPMENT = 'Network equipment',
  OTHERS = 'Elisa Yritystietoturva,CPE,Elisa Yritysgurun asiantuntijapalvelut',
}

export enum SubscriptionDeviceEppFilter {
  All = 'All',
  BasicContractPeriod = 'BasicContractPeriod',
  ExtensionPeriod = 'ExtensionPeriod',
}

export enum SubscriptionDeviceExpiringFilter {
  ExpiringIn2Weeks = 'ExpiringIn2Weeks',
}

export enum SubscriptionDevicePaymentTypeFilter {
  Onetime = 'Onetime',
  Monthly = 'Monthly',
}

export enum SubscriptionDeviceCommitmentPeriodFilter {
  Period12Months = '12',
  Period24Months = '24',
  Period36Months = '36',
}

export enum SubscriptionDeviceUserFilter {
  Shared = 'Shared',
  Personal = 'Personal',
}

export enum SupportCaseFields {
  SUPPORT_CASE_DISPLAY_ID = 'supportCaseDisplayId',
  CREATED_AT = 'createdAt',
  FEATURE = 'feature',
  CONTACT_DETAILS = 'contactDetails',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  LAST_MODIFIED = 'lastModified',
  STATUS = 'status',
  FEATURE_TYPE = 'featureType',
  INVOICE_NUMBER = 'invoiceNumber',
  DESCRIPTION = 'description',
  RESOLUTION = 'resolution',
  COMPANY = 'company',
}

export enum SwitchAccountJourneyPoint {
  ONBOARDING = 'ONBOARDING',
  MOBILE_SUB_PAIRING = 'MOBILE_SUB_PAIRING',
}

export enum TerminateType {
  ALL = 'ALL',
  VOICE = 'VOICE',
  RING = 'RING',
  RING_CONNECTION = 'RING_CONNECTION',
}

export enum WizardType {
  CHANGE_SUBSCRIPTION_BILLING_ACCOUNT = 'CHANGE_SUBSCRIPTION_BILLING_ACCOUNT',
  CHANGE_CATALOG_BILLING_ACCOUNT = 'CHANGE_CATALOG_BILLING_ACCOUNT',
  CHANGE_EPP_OMALAITELASKU_BILLING_ACCOUNT = 'CHANGE_EPP_OMALAITELASKU_BILLING_ACCOUNT',
  CHANGE_EPP_REDEEM_BILLING_ACCOUNT = 'CHANGE_EPP_REDEEM_BILLING_ACCOUNT',
  NEW_MOBILE_BROADBAND = 'NEW_MOBILE_BROADBAND',
}

export enum CustomerOrderListFields {
  CUSTOMER_ORDER_DISPLAY_ID = 'customerOrderDisplayId',
  CREATED = 'created',
  DELIVERY_ORDER_CONTACT_NAMES = 'deliveryOrderContactNames',
  DELIVERY_ORDERS = 'deliveryOrders',
  STATUS = 'status',
  COMPANY = 'company',
}

export enum ContactListFields {
  CONTACT_NAME = 'name',
  CONTACT_FIRST_NAME = 'firstName',
  CONTACT_LAST_NAME = 'lastName',
  CONTACT_PHONE_NUMBER = 'phoneNumber',
  CONTACT_EMAIL = 'email',
  CONTACT_EOE_IN_USE = 'eoeInUse',
  COMPANY = 'company',
}

export enum SubscriptionAdditionalServiceStatusEnum {
  IN_USE = 'IN_USE',
  NOT_IN_USE = 'NOT_IN_USE',
  CHANGE_REQUESTED = 'CHANGE_REQUESTED',
}

export enum AddOnRulesSubscriptionType {
  ELISA_NETTI = 'ELISA_NETTI',
  ELISA_NETTI_4G = 'ELISA_NETTI_4G',
}

export enum SubscriptionAddOnGroup {
  PUBLIC_IP = 'Public Dynamic IP',
  FIXED_PUBLIC_IP = 'Kiinteä IP - Vain MOR-tiimi',
}

export enum SubscriptionAddonCode {
  MOBILE_VOICE = '463ba484-042f-799c-f706-7f9cce737580',
  MOBIILITURVA = '6744cb06-0bf8-0f6c-9cf6-da4d91d39d3e',
  REISSUNETTI1 = '721120d8-4ae7-bcd9-9786-888ab5549e1e',
  REISSUNETTI2 = '8ded730b-aa92-6651-38f6-dd71a0c71dc1',
  REISSAAJA = '95eb2c18-55fc-47c4-9993-5b7688f7bf32',
  PUHELUN_EDELLEENYHDISTAMINEN = 'fe2c9c53-ec86-d774-0ba2-d6c70b63ca87',
}

export enum AddOnVisibilityOperationType {
  NEW_SALES = 'NEW_SALES',
  CHANGE = 'CHANGE',
}

export enum FiveGVoiceOfferCode {
  Voice5gPlus1000Mbps = 'f31d8831-149b-4519-91f2-c294d7fafb2f',
  Voice5gPlus600Mbps = '1c8e135b-f46d-dc5f-75e5-727077eec8d3',
  Voice5gPlus300Mbps = '76d463e0-a759-b05b-e775-c6af2144f7d5',
  Voice300Mbps = '687b1fd4-c1ce-2b7d-398b-18ac9c941a0d',
  Voice50Mbps = 'b03eb93b-5b89-63d2-e5c1-20c9cf78a2ec',
  Voice10Mbps = '6fe27af9-0139-dcd9-317f-4c78b9259ca0',
}

export enum MobileBroadbandFourGOfferCodes {
  ONE_FIFTY = '942378bd-dbdd-a8a2-9c07-e5556ae760f2',
  FIFTY = '6655bafe-4eb5-76c8-21e5-bcc3f3053ceb',
  TEN = '67ecc03c-3b77-9da6-3fa7-8c27b3b11a53',
}

export enum OldVoiceOfferCode {
  VOICE_1000_M = 'a1e13276-caf3-7c8b-709c-4026bdc04bed',
  VOICE_600_M = '945c1ec9-a53b-8f09-b330-0cf033bf7dba',
  VOICE_300_M = '0f6a0350-daf4-854d-f0b9-b532df78eb51',
  VOICE_100_M = '0dd20ed5-51ce-ff8c-16ac-582599416b0f',
  VOICE_10_M = '96afd01c-4573-660a-cad0-9a42197d14c2',
}

export enum YrityspakettiOfferCode {
  VOICE_1000_M = 'db272d85-47a6-1cec-56fc-596ad6ce6ba1',
  VOICE_600_M = '06337470-6860-bdd8-b3eb-6bcb1a61fc81',
  VOICE_300_M = 'e9c207e1-4497-6249-c6e7-11109b153fb9',
  VOICE_200_M = 'bbf713d0-7b75-6ad3-9f3e-5213fcb3fb3c',
  VOICE_100_M = 'd92d099a-5139-4427-19ba-087a18595d69',
  VOICE_50_M = '2ee43890-0273-fc9c-712a-604b2bbe12d7',
  VOICE_4G_50_M = '1105d565-ce44-ba45-4c94-3b8c27fffdce',
  VOICE_10_M = '603d1fc6-9fb5-2d25-63be-a6db49e6d9f8',
  VOICE_2_M = 'b3d9af15-9589-26fd-93a1-f6cc067288d3',
}

export enum FiveGMobileBroadbandOfferCode {
  MBB_300_M = 'd57895b4-abe3-6d85-278c-1c771aecce9c',
  MBB_100_M = '026acb30-8f07-845b-6eb3-42e8d3bbef22',
  MBB_50_M = '2f2f537b-749a-486f-e2f5-3b129e51bd9d',
  MBB_10_M = '2fd89894-10cf-65a9-ff49-33dfdb232ac4',
  MBB_5G_PLUS_1000_M = '5230ad2e-3f96-8e8f-2de3-bc78e3ecb250',
  MBB_5G_PLUS_600_M = '4e2a5022-12e2-3b01-3ab6-2c9ae42cab6f',
  MBB_5G_PLUS_300_M = 'a66bd6ea-d023-9259-5e5c-30034a7e86c0',
  MBB_5G_PLUS_100_M = '78bc7361-8f48-2008-c273-c8a41ebb4ca2',
}

export enum MobileM2MAddonCode {
  S = '06c6d401-3f57-f27f-4726-5b9d0edd84bb',
  M = '21127eef-7290-1d21-bf10-02d608e96adf',
  L = 'a22f20d3-0ba4-d874-22d6-dbf71627a6d0',
  fiveG = '497aa153-9530-0665-abaa-3fd0224d2c00',
}

export enum SfToggles {
  SHOW_YRITYSPUHE = 'ShowYrityspuhe',
  SHOW_PERUSLIITTYMA_4G = 'ShowPerusliittymä4G',
  SHOW_YRITYSDATA_4G = 'ShowYritysdata4G',
  SHOW_YRITYSLIITTYMA_OLD = 'ShowYritysliittymä5GOld',
  SHOW_YRITYSPAKETTI = 'ShowYrityspaketti',
  HIDE_YRITYSLIITTYMA = 'HideYritysliittymä',
  HIDE_YRITYSLIITTYMA_NEW = 'HideYritysliittymä5GNew',
}

export enum SubscriptionCardType {
  VOICE = 'VOICE',
  MOBILE_BROADBAND = 'MOBILE_BROADBAND',
  MOBILE_M2M = 'MOBILE_M2M',
}

export enum MobiilivarmenneAddonGroups {
  CERTIFICATE = 'Certificate',
  VARMENNE = 'Varmenne',
}
