import * as CL from '@design-system/component-library';
import { EditingSection } from '../billingAccountDetailsEnums.js';
import { GridColumn } from './GridColumn.js';
import { deepEqual } from '../../../common/utils/objectUtils.js';
import { editInOldOmaElisaMsg, editMsg, t } from '../../../common/i18n/index.js';
import { useSelector } from 'react-redux';
import type { ConfigState } from '../../../common/types/states.js';
import type { State } from '../../../selfservice/common/store.js';

export interface EditButtonProps {
  setNewEditingSection: () => void;
  editingSection: EditingSection;
  editableInClassic?: boolean;
  name: string;
}

export const EditBillingAccountButton = ({
  setNewEditingSection,
  editableInClassic,
  name,
  editingSection,
}: EditButtonProps) => {
  const config: ConfigState = useSelector((state: State) => state.config, deepEqual);
  const classicSiteBillingDetailsUrl = `${config.classicSiteUrl}'/laskutustiedot`;

  return editableInClassic ? (
    <GridColumn className="of-billing-account-details form-column view-field edit-button">
      <CL.Button color="light" onClick={() => (window.location.href = classicSiteBillingDetailsUrl)}>
        {t.NQ5K(editInOldOmaElisaMsg)}
      </CL.Button>
    </GridColumn>
  ) : (
    <GridColumn className="of-billing-account-details form-column view-field edit-button">
      <CL.Button
        id={`${name}Edit`}
        color="light"
        onClick={() => setNewEditingSection()}
        disabled={editingSection !== EditingSection.NONE}
      >
        {t.NVPK(editMsg)}
      </CL.Button>
    </GridColumn>
  );
};
