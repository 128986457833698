import { FINNISH_PHONE_NUMBER_MAX_LENGTH, PHONE_NUMBER_REGEX } from '../../../common/utils/phoneNumberUtils.js';
import { Field } from 'formik';
import { InputComponent } from '../FieldComponents/index.js';
import { fieldCantBeEmptyMsg, invalidValueMsg, phoneNumberMsg, t } from '../../i18n/index.js';
import type { OptionalInputComponentProps } from '../FieldComponents/index.js';

export const PhoneNumber = (props: OptionalInputComponentProps) => {
  const translation = t.AGIJ(phoneNumberMsg);
  const validate = (value: string) => {
    if (!value) {
      if (props.required !== false) {
        return t.VPVR(fieldCantBeEmptyMsg);
      }
    } else if (!PHONE_NUMBER_REGEX.test(value)) {
      return t.DUPA(invalidValueMsg);
    }
    return undefined;
  };

  return (
    <Field
      {...{
        name: 'phoneNumber',
        label: translation,
        placeholder: translation,
        disabled: false,
        required: props.required ?? true,
        maxLength: FINNISH_PHONE_NUMBER_MAX_LENGTH,
        validate,
        ...props,
      }}
      component={InputComponent}
    />
  );
};
