import { InfiniteScrollCompositeList } from '../InfiniteScrollCompositeList/InfiniteScrollCompositeList.js';
import { SubscriptionAction } from '../../generated/api/models.js';
import {
  cancelledMsg,
  contentMsg,
  customerMsg,
  deliveredMsg,
  openMsg,
  orderDateMsg,
  orderMsg,
  pendingApprovalMsg,
  statusMsg,
  t,
} from '../../common/i18n/index.js';
import { formatTimestampToDDMMYYYY } from '../../common/utils/dateUtils.js';
import type { CommonError } from '../../common/types/errors.js';
import type { CompositeListColumn, CompositeListSort } from '../CompositeListHeader/CompositeListHeader.js';
import type { InfiniteScrollCompositeListProps } from '../InfiniteScrollCompositeList/InfiniteScrollCompositeList.js';
import type { SubscriptionActionHeader, SubscriptionActionSearchResponse } from '../../generated/api/models.js';

import './SubscriptionActionList.scss';

export interface SubscriptionActionListAttrs {
  errors?: CommonError[];
  items?: SubscriptionActionHeader[];
  search?: string;
  sort?: CompositeListSort;
  total?: number;
  loadMore?: boolean;
  type: SubscriptionActionListType;
  searchResults?: SubscriptionActionSearchResponse[];
}

export enum SubscriptionActionListType {
  APPROVE_SUBSCRIPTIONS = 'APPROVE_SUBSCRIPTIONS',
  MY_SUBSCRIPTIONS = 'MY_SUBSCRIPTIONS',
}

interface SubscriptionActionListRoutes {
  onClickSubscriptionAction: (id: string) => void;
}

export interface SubscriptionActionListDispatchers {
  onSortSubscriptionActions: (newSort: CompositeListSort) => void;
  onLoadMoreSubscriptionActions: () => void;
  onSearchSubscriptionActions: (newSearchValue: string) => void;
}

export type SubscriptionActionListProps = SubscriptionActionListAttrs & SubscriptionActionListDispatchers;

export const getSubscriptionActionStatusInfo = (subscriptionActionStatus?: string): { color: string; text: string } => {
  switch (subscriptionActionStatus) {
    case SubscriptionAction.StatusEnum.COMPLETED.toString():
      return {
        color: 'green',
        text: t.PQGG(deliveredMsg),
      };
    case SubscriptionAction.StatusEnum.PENDING.toString():
      return {
        color: 'linkblue',
        text: t.TJ54(openMsg),
      };
    case SubscriptionAction.StatusEnum.PENDING_APPROVAL.toString():
      return {
        color: 'linkblue',
        text: t.ZXA6(pendingApprovalMsg),
      };
    case SubscriptionAction.StatusEnum.CANCELLED.toString():
      return {
        color: 'red',
        text: t.PEKP(cancelledMsg),
      };
    case undefined:
    default:
      return {
        color: 'transparent',
        text: '',
      };
  }
};

const getApproveSubscriptionActionColumns = (): CompositeListColumn<SubscriptionActionHeader>[] => [
  {
    columnId: 'subscriptionDisplayId',
    columnClasses: ['subscription-display-id'],
    heading: t.C001(orderMsg),
    sortable: true,
    value: (subActionDetails: SubscriptionActionHeader) => {
      return [
        <>
          <span>{subActionDetails.subscriptionDisplayId}</span>
          <span className="request-status">{t.B951('Redemption Request')}</span>
        </>,
      ];
    },
  },
  {
    columnId: 'created',
    heading: t.ZTPC(orderDateMsg),
    ref: 'created',
    refFormatNumber: formatTimestampToDDMMYYYY,
    sortable: true,
  },
  {
    columnId: 'subscriptionContactName',
    heading: t.ZC7D(customerMsg),
    ref: 'subscriptionContactName',
    sortable: true,
  },
  {
    columnId: 'subscriptionName',
    heading: t.NBNH(contentMsg),
    sortable: false,
    ref: 'subscriptionName',
  },
  {
    columnId: 'status',
    heading: t.ASQT(statusMsg),
    ref: 'status',
    refFormatString: (subscriptionActionStatus: string) =>
      getSubscriptionActionStatusInfo(subscriptionActionStatus?.toString()).text,
    sortable: false,
    valueLineClasses: (subscriptionAction: SubscriptionActionHeader) => [
      'ea-disc',
      'ea-disc--small',
      `ea-disc--${getSubscriptionActionStatusInfo(subscriptionAction.status?.toString()).color}`,
    ],
  },
];

const getMySubscriptionsActionColumns = (): CompositeListColumn<SubscriptionActionHeader>[] => [
  {
    columnId: 'subscriptionDisplayId',
    columnClasses: ['subscription-display-id'],
    heading: t.C001(orderMsg),
    sortable: true,
    value: (subscriptionAction: SubscriptionActionHeader) => {
      return [
        <>
          <span>{subscriptionAction.subscriptionDisplayId}</span>
          <span className="request-status">{t.B951('Redemption Request')}</span>
        </>,
      ];
    },
  },
  {
    columnId: 'created',
    heading: t.ZTPC(orderDateMsg),
    ref: 'created',
    refFormatNumber: formatTimestampToDDMMYYYY,
    sortable: true,
  },
  {
    columnId: 'subscriptionName',
    heading: t.NBNH(contentMsg),
    sortable: false,
    ref: 'subscriptionName',
  },
  {
    columnId: 'status',
    heading: t.ASQT(statusMsg),
    ref: 'status',
    refFormatString: (subscriptionActionStatus: string) =>
      getSubscriptionActionStatusInfo(subscriptionActionStatus ? subscriptionActionStatus.toString() : undefined).text,
    sortable: false,
    valueLineClasses: (subscriptionAction: SubscriptionActionHeader) => [
      'ea-disc',
      'ea-disc--small',
      `ea-disc--${
        getSubscriptionActionStatusInfo(subscriptionAction.status ? subscriptionAction.status.toString() : undefined)
          .color
      }`,
    ],
  },
];

const getColumns = (type: SubscriptionActionListType) => {
  switch (type) {
    case SubscriptionActionListType.APPROVE_SUBSCRIPTIONS:
      return getApproveSubscriptionActionColumns();
    case SubscriptionActionListType.MY_SUBSCRIPTIONS:
      return getMySubscriptionsActionColumns();
  }
};

export const SubscriptionActionList = ({
  items,
  onClickSubscriptionAction,
  onSortSubscriptionActions,
  onLoadMoreSubscriptionActions,
  onSearchSubscriptionActions,
  search,
  sort,
  loadMore,
  type,
  searchResults,
}: SubscriptionActionListProps & SubscriptionActionListRoutes) => {
  const listProps: InfiniteScrollCompositeListProps<SubscriptionActionHeader> = {
    classes: ['of-subscription-action-list'],
    columns: getColumns(type),
    emptyListText: t.SXJ5('No redeem requests'),
    enableInfiniteScroll: loadMore || false,
    getRowId: (subscriptionAction: SubscriptionActionHeader) => subscriptionAction.subscriptionActionDisplayId,
    items: items,
    onInfiniteScrollLoadMore: () => onLoadMoreSubscriptionActions(),
    onSelectRow: onClickSubscriptionAction,
    onSort: onSortSubscriptionActions,
    rowIcon: 'order-filled',
    search: {
      onChange: onSearchSubscriptionActions,
      placeholder: t.G3WD('Search redeem request...'),
      value: search || '',
    },
    sort: sort,
    itemsSearchResultsMatchedFields: searchResults?.map(res => res.matchedFields || []),
  };

  return <InfiniteScrollCompositeList {...listProps} />;
};
