/**
 * SFDC Community API
 * # Summary Elisa B2B API for an administrator of a company to query and modify the company information.  # Usage This API is used in the `https://yrityksille.elisa.fi/omaelisa` pages, but it is possible to later on expose this documentation publicly and make the endpoint queryable via external clients.  # Identifiers All entities in the API have a globally unique, internal identifier field named with the format [object_type]Id, e.g. `invoiceId`, which is also used in some of the API endpoint urls, e.g. `/api/v1/selfservice/invoices/{invoiceId}`. This value is not meaningful to the end user but is only used as an internally in the API.  Most entities also have a displayable identifier named in the format [object_type]DisplayId. This value can and should be shown to the end user, as it is also printed in relevant documents. It is also used in some of the public URLs, e.g. `https://yrityksille.elisa.fi/omaelisa/laskut/{invoiceDisplayId}`. Because of the user might land directly into a URL of a single entity with a display id, some of the API endpoints here also support querying via the display id, e.g. `/api/v1/selfservice/invoices?invoiceDisplayId={invoiceDisplayId}`.  The reason for using two identifiers instead of only one display id, is to make sure that every object will always have exactly one REST API URL that will never collide with another. While collisions of display ids should not happen in production, the id fields are guaranteed to be unique even across different test environments. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PbxType } from './pbxType.js';
import { SubscriptionType } from './subscriptionType.js';
import { Service } from './service.js';
import { ChangeRequest } from './changeRequest.js';
import { SubscriptionDetails } from './subscriptionDetails.js';
import { SubscriptionStatusType } from './subscriptionStatusType.js';


export interface DeviceSubscription { 
    /**
     * Actual start date of the subscription that is migrated to SFDC. UTC midnight of the original start date. SFDC: csord__Subscription__c.Original_Start_Date__c
     */
    originalStartDate?: number;
    /**
     * MDM Id of subscription\'s account. SFDC: csord__Subscription__c.csord__Account__r.MDM_Id__c
     */
    accountMdmId?: string;
    /**
     * Name of subscription\'s account. SFDC: csord__Subscription__c.csord__Account__r.Name
     */
    accountName?: string;
    /**
     * MDM Id of the user contact of the subscription. SFDC: csord__Subscription__c.User_Contact__r.MDM_Id__c
     */
    contactMdmId?: string;
    /**
     * Employee Number of the user contact of the subscription. SFDC: csord__Subscription__c.User_Contact__r.Employee_Number__c
     */
    contactEmployeeNumber?: string;
    /**
     * Mobile Phone of the user contact of the subscription. SFDC: csord__Subscription__c.User_Contact__r.MobilePhone
     */
    contactPhoneNumber?: string;
    /**
     * Email of the user contact of the subscription. SFDC: csord__Subscription__c.User_Contact__r.Email
     */
    contactEmail?: string;
    /**
     * Extension period start date of the subscription. SFDC: csord__Subscription__c.Extension_Start_Date__c
     */
    extensionStartDate?: number;
    /**
     * Extension period end date of the subscription. SFDC: csord__Subscription__c.Extension_End_Date__c
     */
    extensionEndDate?: number;
    /**
     * Array of change requests related to the subscription, defaults to 0 element array
     */
    subscriptionChangeRequests?: Array<ChangeRequest>;
    /**
     * Array of services related to the subscription, defaults to 0 element array
     */
    services?: Array<Service>;
    /**
     * Internal, unique id of the subscription. SFDC: csord__Subscription__c.Id
     */
    subscriptionId: string;
    /**
     * Subscription id (SUBnnnnnnnnn) shown to the user. SFDC: csord__Subscription__c.csordtelcoa_Subscription_Number__c or Asset__c.Order_Number__c
     */
    subscriptionDisplayId: string;
    subscriptionType: SubscriptionType;
    /**
     * More specific type of the subscription. For DEVICE this is the category of the device, say TABLET. SFDC: cscfga__Attribute__c.cscfga__Value__c, found with Attribute name \'Type\', found with csord__Subscription__c.csordtelcoa_Product_Configuration__c
     */
    subscriptionSubType?: string;
    /**
     * User friendly identifier of the subscription. Varies depending on subscriptionType: phone number, address (fixed line) sub-id (software product), serial number (device, fixed internet). Not required because e.g. not all accessories have any user friendly id. SFDC: See separate table in Confluence.
     */
    subscriptionUserFriendlyId?: string;
    /**
     * Name shown to the user of the subscription. SFDC: csord__Subscription__c.Name, Asset.Name
     */
    subscriptionName: string;
    /**
     * Internal ID of the delivery order this subscription was ordered with. SFDC: csord__Order__c.Id, found with csord__Subscription.csord__Order__c.
     */
    deliveryOrderId?: string;
    /**
     * Displayable ID of the delivery order this subscription was ordered with. SFDC: csord__Order__c.Id, found with csord__Subscription.csord__Order__c.
     */
    deliveryOrderDisplayId?: string;
    /**
     * Id for user of the subscription. If this is missing but contact name is given, the contact is not linkable. SFDC: csord__Subscription__c.User_Contact__c.
     */
    subscriptionContactId?: string;
    /**
     * Name of the contact that this subscription is assigned to. For SOFTWARE_PRODUCT, this is the admin user, and contact id is for now missing. SFDC: csord__Subscription__c.User_Contact__c, Asset.User_Contact__c.
     */
    subscriptionContactName?: string;
    /**
     * Earliest date when user history should be shown (e.g. call details history). Field value is computed in SFDC API.
     */
    userHistoryStartDate?: number;
    /**
     * Purpose of use of the subscription, used when the subscription isn\'t attached to any person but instead to e.g. a location or organizational unit. SFDC: csord__Subscription__c.Purpose_of_Use__c.  NOTE: \'Online-tilaus\' is the default name which should never be sent out but instead interpreted as empty value! UI field \'tunniste\' in BROADBAND subpage.
     */
    subscriptionPurposeOfUse?: string;
    subscriptionStatus?: SubscriptionStatusType;
    /**
     * Indicates the change type performed on the subscription. It can have the values :New, Extension Period, EPP Device Change, Others. SFDC: csord__subscription__c.Subscription_Type__c.
     */
    changeType?: string;
    /**
     * Internal, unique id of the billing account. This id is missing for Employee profile users. SFDC: csord__Subscription__c.Billing_Account__c.Id
     */
    billingAccountId?: string;
    /**
     * Billing account id shown to the user. This id is missing for Employee profile users. SFDC: csord__Subscription__c.Billing_Account__c.Billing_Account_Number__c
     */
    billingAccountDisplayId?: string;
    /**
     * Source system if the subscription was migrated, or SFDC if not. Or source system of assets. SFDC: csord__Subscription__c.Migration_Source__c or asset.Source_System__c.
     */
    sourceSystem: DeviceSubscription.SourceSystemEnum;
    /**
     * Boolean flag to indicate if the subscription is migrated from other sources or not. Undefined in case of assets
     */
    migrated?: boolean;
    /**
     * Id for management console source system linking. SFDC: csord__Subscription__c.Migration_Source_Reference__c.
     */
    sourceSystemId?: string;
    /**
     * Date when customer started to use the subscription. UTC midnight of the start date. SFDC: csord__Subscription__c.Start_Date__c.
     */
    startDate?: number;
    /**
     * Date when usage of the subscription was/will be terminated. UTC midnight of the end date. SFDC: csord__Subscription__c.End_Date__c.
     */
    endDate?: number;
    /**
     * Whether or not this subscription is commitment type.
     */
    isCommitmentSubscription?: boolean;
    /**
     * SFDC internal ID of the subscription that this subscription has replaced (e.g. due to change-features request). SFDC: csord__Subscription__c.csordtelcoa__Replaced_Subscription__c.
     */
    replacedSubscriptionId?: string;
    /**
     * List of PBX types for subscription to identify compatibility with PBX etc. SFDC: csord__Subscription__c.csord__Service__c.cspmb__Price_Item__c.IsVakioCompatible__c.
     */
    compatiblePbxTypes?: Array<PbxType>;
    /**
     * ID of the commercial product. SFDC: csord__Subscription__c.Root_Service__c.Price_Item__c.Id.
     */
    commercialProductId?: string;
    /**
     * GUID of commercial product for subscription. SFDC: csord__Subscription__c.Root_Service__c.Price_Item__c.GUID__c.
     */
    commercialProductCode?: string;
    details?: SubscriptionDetails;
    /**
     * Unix timestamp in milliseconds of creation. SFDC: csord__Subscription__c.CreatedDate
     */
    created: number;
    /**
     * Unix timestamp of last modification. SFDC: csord__Subscription__c.LastModifiedDate
     */
    lastModified: number;
    /**
     * Optional cost centre for this subscription. SFDC: csord__Subscription__c.Cost_Center__c
     */
    costCenter?: string;
    /**
     * Optional reference number for this subscription. SFDC: csord__Subscription__c.Subscription_Reference__c
     */
    subscriptionReference?: string;
}
export namespace DeviceSubscription {
    export type SourceSystemEnum = 'SFDC' | 'MIPA' | 'TELLUS' | 'EPILVI';
    export const SourceSystemEnum = {
        SFDC: 'SFDC' as SourceSystemEnum,
        MIPA: 'MIPA' as SourceSystemEnum,
        TELLUS: 'TELLUS' as SourceSystemEnum,
        EPILVI: 'EPILVI' as SourceSystemEnum
    };
}


