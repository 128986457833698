import * as CL from '@design-system/component-library';
import { DnsRecordEdit } from './DnsRecordEdit.js';
import {
  DnsRecordEditableDropdown,
  DnsRecordEditableFields,
  isDnsRecordTypeValueSingular,
} from '../../../dnsManagementUtils.js';
import { Dropdown } from '../../../../../common/react-hook-form/components/Dropdown.js';
import { TextInput } from '../../../../../common/react-hook-form/fields/index.js';
import { cancelMsg, nameMsg, saveMsg, t } from '../../../../../common/i18n/index.js';
import { dsClass } from '../../../../../common/constants/dsClasses.js';
import { validateTtl } from '../Records/fieldValidation.js';
import type { DnsRecordEditableForm } from '../../../dnsManagementUtils.js';
import type { DnsRecordEditableType } from '../../../../../generated/api/dnsRecordEditableType.js';
import type { FieldArrayWithId } from 'react-hook-form';

export const AddValueButton = ({ type, onAddValue }: { type: DnsRecordEditableType; onAddValue: () => void }) =>
  isDnsRecordTypeValueSingular(type) ? null : (
    <CL.Button onClick={onAddValue} color="link" className={dsClass.WHITE_SPACE_NOWRAP}>
      <CL.Icon icon="add" />
      <span>{t.K5E2('Add value')}</span>
    </CL.Button>
  );

export const TTLColumn = () => (
  <TextInput name={DnsRecordEditableFields.ttl} label="TTL (s)" required validate={validateTtl} type="number" />
);

export const TypeColumn = ({
  type,
  disabled,
  onValueChange,
}: {
  type: DnsRecordEditableType;
  disabled?: boolean;
  onValueChange?: (type: DnsRecordEditableType) => void;
}) => (
  <div className={dsClass.WHITE_SPACE_NOWRAP}>
    <Dropdown
      name={DnsRecordEditableFields.type}
      label={t.F9G1('Type')}
      selectedValue={type}
      items={DnsRecordEditableDropdown}
      onValueChange={onValueChange}
      disabled={disabled}
    />
  </div>
);

export const formatRecordListEditRow = (
  type: DnsRecordEditableType,
  fields: FieldArrayWithId<DnsRecordEditableForm, 'values'>[],
  dnsManagementDisallowed: boolean,
  onCancelEdit: () => void,
  addValueRow: () => void,
  removeValueRow: (index: number) => void
) =>
  ({
    cssclass: 'of-dns-record-list-row-edit',
    name: (
      <>
        <TextInput name={DnsRecordEditableFields.name} label={t.VGFI(nameMsg)} disabled />
        <AddValueButton type={type} onAddValue={addValueRow} />
      </>
    ),
    ttl: <TTLColumn />,
    type: <TypeColumn type={type} disabled />,
    values: <DnsRecordEdit type={type} fields={fields} onRemoveValueRow={removeValueRow} />,
    actionButtons: !dnsManagementDisallowed && (
      <div key="edit" className={`${dsClass.PADDING_TOP_4} of-dns-record-list-row-edit__actions`}>
        <CL.Button id="submit" type="submit" className={dsClass.MARGIN_BOTTOM_3}>
          {t.NIWE(saveMsg)}
        </CL.Button>
        <CL.Button color="link" onClick={onCancelEdit} className={dsClass.MARGIN_TOP_3}>
          {t.B2V1(cancelMsg)}
        </CL.Button>
      </div>
    ),
  }) satisfies CL.KeyRow;
