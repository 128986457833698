/**
 * Online UI API
 * # Summary Elisa B2B Online UI API  # Usage This API is used in the `https://yrityksille.elisa.fi/` pages.  # Identifiers 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Custom model for radio button or checkbox
 */
export interface ClickableInputField { 
    /**
     * Input id
     */
    id: string;
    /**
     * Input type
     */
    type: ClickableInputField.TypeEnum;
    /**
     * Input label
     */
    label: string;
    /**
     * Input value as string
     */
    value?: string;
    /**
     * Input description
     */
    description?: string;
}
export namespace ClickableInputField {
    export type TypeEnum = 'radio' | 'checkbox';
    export const TypeEnum = {
        radio: 'radio' as TypeEnum,
        checkbox: 'checkbox' as TypeEnum
    };
}


