import { addToCart, getShoppingCart, setShoppingCart, updateCartItem } from '../../selfservice/exports.js';
import { useDispatch } from 'react-redux';
import type { CartConfig } from '../../selfservice/actions/index.js';
import type { CartItemContainer } from '../../components/ProductDetails/ProductDetails.js';
import type { CartItemType, State } from '../../selfservice/exports.js';
import type { ShoppingCartItemForCheckout } from '../../common/types/checkout.js';
import type { Store } from 'redux';

interface ExposedFunctionsProps {
  store: Store<State>;
}

export const ExposedFunctions: React.FC<ExposedFunctionsProps> = (props: ExposedFunctionsProps) => {
  const dispatch = useDispatch();
  const store = props.store;
  (
    window as typeof window & {
      app: {
        getStore: () => { getState: () => State };
        shoppingCart: {
          addItemToCart: (
            cartItemContainer: CartItemContainer,
            onAddToCart?: () => void,
            cartConfig?: CartConfig
          ) => void;
          getCart: object;
          updateItemInCart: (
            cartItem: ShoppingCartItemForCheckout,
            newQuantity: number,
            addOnsToBeRemoved: string[],
            onUpdateCart?: () => void
          ) => void;
          setCart: (cart?: CartItemType[]) => void;
        };
      };
    }
  ).app = {
    getStore: () => {
      return {
        getState: () => {
          const { ...restState } = store.getState();
          return restState;
        },
      };
    },
    shoppingCart: {
      addItemToCart: (cartItemContainer: CartItemContainer, onAddToCart?: () => void, cartConfig?: CartConfig) => {
        dispatch(addToCart(cartItemContainer, onAddToCart, cartConfig));
      },
      getCart: () => {
        return JSON.parse(getShoppingCart());
      },
      updateItemInCart: (
        cartItem: ShoppingCartItemForCheckout,
        newQuantity: number,
        addOnsToBeRemoved: string[],
        onUpdateCart?: () => void
      ) => {
        dispatch(updateCartItem(cartItem, newQuantity, addOnsToBeRemoved, onUpdateCart));
      },
      setCart: (cart?: CartItemType[]) => {
        setShoppingCart(JSON.stringify(cart as CartItemType[]));
      },
    },
  };
  return null;
};
