import { AnalyticsConsent } from '../../../generated/api/analyticsConsent.js';
import { CookieConsent } from '../../../components/CookieConsent/CookieConsent.js';
import { getContactInfo } from '../../../common/utils/stateUtils.js';
import { updateUserDetails } from '../../../selfservice/exports.js';
import { useAuth } from '../AuthProvider.js';
import { useDispatch } from 'react-redux';

export const CookieConsentWrapper = () => {
  const { authenticatedUser } = useAuth();
  const dispatch = useDispatch();

  const saveAnalyticsConsent = (flag: boolean) => {
    if (authenticatedUser?.businessId !== undefined) {
      dispatch(
        updateUserDetails({
          ...getContactInfo(authenticatedUser),
          analyticsConsent: flag ? AnalyticsConsent.ALLOWED : AnalyticsConsent.DENIED,
        })
      );
    }
  };

  return (
    <CookieConsent
      saveAnalyticsConsent={saveAnalyticsConsent}
      hasAcceptedAnalyticsCookies={authenticatedUser?.analyticsConsent !== AnalyticsConsent.UNSET}
    />
  );
};
