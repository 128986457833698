import * as CL from '@design-system/component-library';
import * as File from '../../../components/File/index.js';
import { Controller, useFormContext } from 'react-hook-form';
import { chooseFileButtonMsg, t } from '../../i18n/index.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { useRef } from 'react';

export interface UploadStatus {
  started: string[];
  finished: string[];
}

export interface FileInputProps {
  name: string;
  chooseFileButtonText?: string;
  buttonIcon?: string;
  buttonColor?: CL.ButtonProps['color'];
  infoText?: string;
  allowedFileTypes?: string[];
  invalidFiles: string[];
  validate: (value: FileList) => string | undefined;
  uploadStatus: UploadStatus;
}

export const FileInput = ({
  name,
  chooseFileButtonText,
  buttonIcon,
  buttonColor,
  infoText,
  allowedFileTypes,
  invalidFiles,
  validate,
  uploadStatus,
}: FileInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { control, setValue, getValues } = useFormContext();
  const fileArr = getValues(name);

  const onSelectFiles = (files: FileList | null) => {
    if (files) {
      setValue(
        name,
        [
          ...fileArr,
          ...Array.from(files).filter(file => {
            return !fileArr.some((f: File) => f.name === file.name);
          }),
        ],
        { shouldValidate: true }
      );
    }
  };

  const onRemoveFile = (fileName: string) => {
    setValue(
      name,
      fileArr.filter((file: File) => file.name !== fileName),
      { shouldValidate: true }
    );
  };

  const chooseFileButtonDefaultText = t.ELQI(chooseFileButtonMsg);
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: {
          custom: value => (validate ? validate(value) : true),
        },
      }}
      render={({ fieldState: { error } }) => (
        <>
          <div className="of-file-input">
            <div className="of-file-input--controls-and-text">
              <div className="of-file-input--controls">
                <CL.Button type="button" color={buttonColor || 'link'} onClick={() => inputRef?.current?.click()}>
                  <span>
                    <CL.Icon icon={buttonIcon || 'attachment'} size="s" />{' '}
                    <span className="of-file-input--controls--button-text">
                      {chooseFileButtonText || chooseFileButtonDefaultText}
                    </span>
                  </span>
                </CL.Button>
                <input
                  name={name}
                  type="file"
                  multiple
                  ref={inputRef}
                  className={dsClass.DISPLAY_NONE}
                  onChange={event => onSelectFiles(event.target.files)}
                />
              </div>
              <div className={`of-file-input--file-limits-info ${dsClass.COLOR_NEUTRAL_600}`}>
                {infoText && (
                  <p className={`of-file-input--file-limits-info--info-text ${dsClass.MARGIN_0}`}>{infoText}</p>
                )}
                {allowedFileTypes && (
                  <p className={`of-file-input--file-limits-info--info-text ${dsClass.MARGIN_0}`}>
                    {t.BDCT('Allowed file types: {}', allowedFileTypes.join(', '))}
                  </p>
                )}
              </div>
            </div>
            <div className="of-file-input--selected-files" title={t.HP5R('Selected files')}>
              {fileArr?.length > 0 && (
                <File.EditableList
                  files={fileArr}
                  invalidFiles={invalidFiles}
                  onRemove={onRemoveFile}
                  uploadStatus={uploadStatus}
                />
              )}
            </div>
          </div>
          {error && <p className={dsClass.INPUTERROR}>{error?.message}</p>}
        </>
      )}
    />
  );
};
