import './ProgressBar.scss';

export interface ProgressBarProps {
  progressPercentage: number; // Good to have in between 0-100
}

const defaultProgressPercentage = 10;

export const ProgressBar = ({ progressPercentage }: ProgressBarProps) => {
  const styles = {
    maxWidth:
      progressPercentage > 0
        ? progressPercentage < defaultProgressPercentage
          ? `${defaultProgressPercentage}%`
          : `${progressPercentage}%`
        : `0%`,
  };
  return (
    <div className="of-progress-bar">
      <div style={styles} />
    </div>
  );
};
