import { ActionPhase } from '../common/storeUtils.js';
import { TypeKeys } from '../actions/index.js';
import { mergeArrays, reduceDisplayItemsLoadAction } from './reducerUtils.js';
import { updateActionStatePhase } from '../common/index.js';
import type { ActionsHistory } from '../common/store.js';
import type { Document } from '../../generated/api/models.js';
import type { InvoiceDocumentsState } from '../../common/types/states.js';
import type { SelfServiceActionTypes } from '../actions/index.js';

export function invoiceDocumentsReducers(
  state: (InvoiceDocumentsState & ActionsHistory) | null,
  action: SelfServiceActionTypes
): (InvoiceDocumentsState & ActionsHistory) | null {
  if (typeof state === 'undefined') {
    return null;
  }

  switch (action.type) {
    case TypeKeys.LOAD_INVOICE_DOCUMENTS: {
      const itemsState = reduceDisplayItemsLoadAction<TypeKeys.LOAD_INVOICE_DOCUMENTS, Document>(
        action,
        state,
        'invoiceDocumentDisplayId',
        !action.limit,
        action.offset
      );
      return {
        ...itemsState,
        loading: true,
      };
    }

    case TypeKeys.LOAD_INVOICE_DOCUMENTS_FULFILLED: {
      return {
        ...state,
        totalItems: action.totalItems,
        items: mergeArrays<Document>('invoiceDocumentId', 'lastModified', state!.items, action.invoiceDocuments),
        loading: false,
        actions: updateActionStatePhase(
          TypeKeys.LOAD_INVOICE_DOCUMENTS,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
      };
    }

    case TypeKeys.LOAD_INVOICE_DOCUMENTS_FAILED: {
      return {
        ...state,
        loading: false,
        actions: updateActionStatePhase(
          TypeKeys.LOAD_INVOICE_DOCUMENTS,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
      };
    }

    default:
      return state;
  }
}
