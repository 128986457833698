import { JsonLd } from 'react-schemaorg';
import { Link, useLocation } from 'react-router-dom';
import { SiteContext } from '../../public/site/SiteContext.js';
import { atContext, atType } from '../../common/constants/namingConventionVariables.js';
import { corporateCustomersMsg, t } from '../../common/i18n/index.js';
import { useContext } from 'react';
import type { BreadcrumbList } from 'schema-dts';
import type { SiteStructureNode } from '../../generated/api/models.js';

import './BreadCrumbs.scss';

export interface BreadCrumbName {
  name: string;
}
export interface BreadCrumbNameAndPath extends BreadCrumbName {
  path: string;
}

export type BreadCrumbList = [...BreadCrumbNameAndPath[], BreadCrumbName] | [];

export interface BreadCrumbsProps {
  breadCrumbPaths: BreadCrumbList;
}

export const getBreadCrumbLinks = (breadcrumbIds: number[], structure: SiteStructureNode[]): BreadCrumbList => {
  return breadcrumbIds
    .map((id, index) => {
      const item = structure.find(s => s.id === id);
      if (item) {
        const { name, path } = item;
        if (index !== breadcrumbIds.length - 1) {
          return { name, path };
        }
        return { name };
      }
      return;
    })
    .filter(Boolean) as BreadCrumbList;
};

// TODO /en and /sv pages still use this hard-coding, if that can be fixed -> this can be removed.
const getRootBreadCrumbName = (name: string, path?: string) => (path === '/' ? t.KBLX(corporateCustomersMsg) : name);

export const BreadCrumbsJsonLd = ({ breadCrumbPaths: namePathPairs }: BreadCrumbsProps) => {
  const { siteBaseUrl } = useContext(SiteContext);
  const { pathname } = useLocation();
  return (
    <JsonLd<BreadcrumbList>
      item={{
        [atContext]: 'https://schema.org',
        [atType]: 'BreadcrumbList',
        itemListElement: namePathPairs.map((namePathPair, index) => {
          return {
            [atType]: 'ListItem',
            position: index + 1,
            name: getRootBreadCrumbName(namePathPair.name, 'path' in namePathPair ? namePathPair.path : undefined),
            item: `${siteBaseUrl}${
              'path' in namePathPair ? (namePathPair.path === '/' ? '' : namePathPair.path) : pathname
            }`,
          };
        }),
      }}
    />
  );
};

export const BreadCrumbs = (props: BreadCrumbsProps) => {
  const { breadCrumbPaths = [] } = props;
  const currentPage = breadCrumbPaths[breadCrumbPaths.length - 1];
  const paths = breadCrumbPaths.slice(0, -1) as BreadCrumbNameAndPath[];

  if (breadCrumbPaths.length > 1) {
    return (
      <nav>
        <ol className="of-breadcrumbs">
          {paths.map(({ name, path }) => (
            <li key={name}>
              <Link to={path}>{getRootBreadCrumbName(name, path)}</Link>
            </li>
          ))}
          <li>{currentPage.name}</li>
        </ol>
        <BreadCrumbsJsonLd {...props} />
      </nav>
    );
  }
  return null;
};
