import { ContactCreationType } from '../ContactOrPurposeOfUse/ContactsOrPurposeOfUseUtils.js';
import { DuplicateContact } from '../../generated/api/models.js';
import { ProvidedRadio } from '../../common/formik/Fields/ProvidedRadio.js';
import { contactDetailsYouAddedMsg, foundUserMsg, t } from '../../common/i18n/index.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { getContactLabel } from './duplicateContactCheckDialogUtils.js';
import type { ContactPerson } from '../../generated/api/models.js';

export interface SelectContactToUseProps {
  newContact: ContactPerson;
  duplicateContact: DuplicateContact;
  title: string;
}

const getRadioButtonProps = (label: string, childrenMessage: string, disabled: boolean, value: string) => ({
  label,
  children: (
    <>
      <div className={`of-duplicate-contact-check__radios-radio-children ${dsClass.FONT_SIZE_SMALL}`}>
        {childrenMessage}
      </div>
      <div className="of-duplicate-contact-check__radios-radio-compensator" />
    </>
  ),
  disabled,
  value,
});

export const SelectContactToUse = (props: SelectContactToUseProps) => {
  const userEnteredContactOption = getRadioButtonProps(
    getContactLabel(props.newContact),
    t.OAGG(contactDetailsYouAddedMsg),
    props.duplicateContact.duplicateType === DuplicateContact.DuplicateTypeEnum.DUPLICATE_CONTACT,
    ContactCreationType.CREATE_NEW_CONTACT_FORCE_UPSERT
  );
  const duplicateContactOptions =
    props.duplicateContact.contacts?.map(contact =>
      getRadioButtonProps(getContactLabel(contact), t.N0UD(foundUserMsg), false, contact.contactId || '')
    ) || [];

  return (
    <>
      <h3 className={`${dsClass.FONT_SIZE_LARGE} ${dsClass.FONT_WEIGHT_400}`}>{props.title}</h3>
      <ProvidedRadio
        className="of-duplicate-contact-check__radios-radio"
        name={props.duplicateContact.identifier}
        radioButtons={[userEnteredContactOption, ...duplicateContactOptions]}
        value={userEnteredContactOption.disabled ? undefined : userEnteredContactOption.value}
        withError={true}
      />
    </>
  );
};
