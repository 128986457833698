import { AppStoreBadges } from '../AppStoreBadges/AppStoreBadges.js';
import { BreadCrumbs } from '../BreadCrumbs';
import { FaqAccordion } from '../FaqAccordion/FaqAccordion.js';
import { Picture } from '../Picture/Picture';
import { PromotionBlockContainer } from '../../content-blocks/PromotionBlock/PromotionBlockContainer';
import { PublicSelfServiceBanner } from '../PublicSelfServiceBanner/PublicSelfServiceBanner.js';
import { SubscriptionCards } from '../SubscriptionCards/SubscriptionCards.js';
import { addToCart, eCommerceClickEvent, eCommerceImpressionEvent } from '../../selfservice/actions';
import {
  addToCartMsg,
  corporateCustomersMsg,
  corporateOmaElisaMsg,
  frequentlyAskedQuestionsMsg,
  internetSubscriptionsMsg,
  mobileM2MMsg,
  subscriptionsMsg,
  t,
} from '../../common/i18n';
import { dsClass } from '../../common/constants/dsClasses.js';
import { isSubCardWithImpression } from '../SubscriptionCards/subscriptionCardsAnalyticsUtils';
import {
  mobileM2MSubscriptionCards4G,
  mobileM2MSubscriptionCards5G,
} from '../OrderSubscriptionSelection/content/SubscriptionCardContent.js';
import { paths } from '../../common/constants/pathVariables.js';
import { pictureStorageBaseUrl } from '../Picture/contentfulImageProxy';
import { useDispatch } from 'react-redux';
import { useLoaderData } from 'react-router-dom';
import classNames from 'classnames';
import type { CommercialProduct } from '../../generated/api/commercialProduct';
import type { MobileBroadbandSubCardProps, SubCardProps } from '../SubscriptionCard/SubscriptionCard';
import type { OnlineModel } from '../../generated/api/onlineModel.js';
import type { OnlineModelHeader } from '../../generated/api/onlineModelHeader';
import type { PublicM2MBroadbandLoaderData } from '../../common/loaders';
import type { SubCardWithImpressionProps } from '../SubscriptionCards/subscriptionCardsAnalyticsUtils';

import './PublicM2MSubscriptions.scss';

const BASE_CLASS = 'of-public-m2m-subscriptions';
const BLEED_GREY = `${BASE_CLASS}__bleed-grey`;

const APP_COMPATIBILITY_FOOTNOTE_ID = 'app_compatibility_footnote';
const IOT_DEVICES_ID = 'iot_devices';
const LAITENETTI_APP_ID = 'laitenetti_app';
const LAITENETTI_SUBSCRIPTION_FEATURES_ID = 'laitenetti_subscription_features';

const Breadcrumbs = () => (
  <BreadCrumbs
    breadCrumbPaths={[
      { name: t.KBLX(corporateCustomersMsg), path: '/' },
      { name: t.XQ5R(subscriptionsMsg), path: paths.SUBSCRIPTIONS_PUBLIC },
      { name: t.SEYV(internetSubscriptionsMsg), path: paths.INTERNET_SUBSCRIPTIONS_PUBLIC },
      { name: t.A7CA(mobileM2MMsg) },
    ]}
  />
);

const Header = () => (
  <header>
    <h1>{t.OD27('Laitenetti for businesses – Internet for remotely controlled smart devices')}</h1>
    <div className={dsClass.TEXT_LEAD}>
      {t.OD28(
        'Elisa Laitenetti enables data collection and remote control over the Internet. It allows you to connect via the internet to your company’s remotely controlled smart devices, such as surveillance cameras, the alarm system or heating devices, and collect data remotely.'
      )}
    </div>
    <div>
      {t.OD29(
        'Do you want to receive a video image from the surveillance cameras or control the air-source heat pump remotely?'
      )}
    </div>
    <div>
      {t.OD30(
        'Elisa Laitenetti is quick and easy to deploy. Choose a suitable subscription for your company and order Laitenetti with fast delivery!'
      )}
    </div>
    <div>
      <a href={`#${IOT_DEVICES_ID}`}>{t.OD31('Also check out IoT devices')}</a>.
    </div>
  </header>
);

const enrichSub = (sub: MobileBroadbandSubCardProps, onlineModels: OnlineModel[], recommended?: boolean) => {
  const onlineModel = onlineModels?.find(om => om.onlineModelCode === sub.salesProduct.onlineModelCode);
  const offer = onlineModel?.offers.find(o => o.offerCode === sub.salesProduct.offerCode);
  return Object.assign(sub, {
    isLoading: offer == null,
    monthlyPrice: offer?.commercialProducts.reduce(
      (a: number, c: CommercialProduct) => a + (c.monthlyRecurringCharge || 0),
      0
    ),
    offer: offer,
    oneTimePrice: offer?.commercialProducts.reduce((a: number, c: CommercialProduct) => a + (c.oneTimeCharge || 0), 0),
    onlineModel: onlineModel,
    recommended,
  });
};

const Subscriptions = () => {
  const dispatch = useDispatch();
  const { m2mSubscriptions } = useLoaderData() as PublicM2MBroadbandLoaderData;
  const onSubButtonClick = (subData: SubCardProps | SubCardWithImpressionProps) => {
    if (!isSubCardWithImpression(subData)) {
      return;
    }
    const { offer, onlineModel } = subData;
    dispatch(
      addToCart({
        additionalFields: [],
        commercialProduct: offer.commercialProducts[0],
        isPublicSite: true,
        offer: offer,
        onlineModel: onlineModel,
        productPath: onlineModel.pagePath ?? '',
        quantity: 1,
        selectedAddOnBundles: [],
      })
    );
  };
  const subs4g = mobileM2MSubscriptionCards4G().map((sub, idx) => enrichSub(sub, m2mSubscriptions ?? [], idx === 1));
  const subs5g = mobileM2MSubscriptionCards5G().map(sub => enrichSub(sub, m2mSubscriptions ?? []));
  return (
    <section className={`${BASE_CLASS}__subscriptions`}>
      <h2>{t.OD24('4G Laitenetti packages for companies (VAT 0%)')}</h2>
      <SubscriptionCards
        buttonText={t.JFOS(addToCartMsg)}
        flex
        listName="Laitenetti4G"
        onButtonClick={onSubButtonClick}
        subscriptions={subs4g}
      />
      <p>{t.OD26('No fixed-term agreements. You can terminate your subscription at any time.')}</p>

      <h2>{t.OD25('5G Laitenetti package for companies (VAT 0%)')}</h2>
      <SubscriptionCards
        buttonText={t.JFOS(addToCartMsg)}
        flex
        listName="Laitenetti5G"
        onButtonClick={onSubButtonClick}
        subscriptions={subs5g}
      />
    </section>
  );
};

const NORMAL_PRICED_CALLS_PRICE = '0,113';
const INCLUDED_SMS_MMS = '100';
const EXTRA_SMS = '0,073';
const EXTRA_MMS = '0,155';
const ROAMING_MONTHS = '3';

const LaitenettiSubscriptionFeatures = () => {
  return (
    <section id={LAITENETTI_SUBSCRIPTION_FEATURES_ID} className={BLEED_GREY}>
      <h2>{t.B104('Laitenetti subscription features')}</h2>
      <div>
        <h3>{t.B105('Calls and texts')}</h3>
        <ul>
          <li>
            {t.B106(
              'Calls to normal-priced domestic landline and mobile subscriptions {} € per minute.',
              NORMAL_PRICED_CALLS_PRICE
            )}
          </li>
          <li>{t.B107('The monthly subscription price includes {} SMS/MMS messages', INCLUDED_SMS_MMS)}</li>
          <li>{t.B108('Text messages beyond the amount included in the package {} € each', EXTRA_SMS)}</li>
          <li>{t.B109('MMS messages beyond the amount included in the package {} € each', EXTRA_MMS)}</li>
          <li>{t.B10A('For call, SMS and mobile payment blocking, general block P1 is enabled by default.')}</li>
        </ul>
      </div>
      <div>
        <h3>{t.B10B('Internet connection')}</h3>
        <ul>
          <li>{t.B10C('Unlimited Internet in Finland')}</li>
          <li>{t.B10D('Works in the 2G and 4G networks, Laitenetti 5G also works in the 5G network')}</li>
          <li>
            {t.B10E(
              'Elisa Laitenetti subscriptions are only intended for temporary roaming abroad for up to {} months. Elisa has the right to monitor the use of subscriptions and, if necessary, prevent permanent use abroad. ',
              ROAMING_MONTHS
            )}
            <a href={paths.USING_YOUR_PHONE_ABROAD}>{t.B0MB('Read more about prices and access abroad')}</a>.
          </li>
        </ul>
        <h3>{t.B10F('Terms and conditions')}</h3>
        <ul>
          <li>{t.B10G('No fixed-term agreements')}</li>
          <li>{t.B10H('You can terminate your subscription at any time')}</li>
        </ul>
      </div>
    </section>
  );
};

const ElisaLaitenettiApp = () => {
  return (
    <section id={LAITENETTI_APP_ID}>
      <div>
        <h2>{t.OD32('The free of charge Elisa Laitenetti app tracks your car or belongings')}</h2>
        <h3 className={dsClass.H4}>
          {t.OD33('The Laitenetti app is compatible with your Elisa Laitenetti subscription and a tracker.')}
        </h3>
        <ul>
          <li>
            {t.OD34(
              'View the location of your trackers on the map – the information is updated in your phone in real time regardless of the distance'
            )}
          </li>
          <li>{t.OD35('View your location history and the speed data of vehicles, for example')}</li>
          <li>{t.OD36('Share your location data with other people if you want')}</li>
          <li>{t.OD37('You can choose from several map layouts, such as a terrain map')}</li>
          <li>{t.OD38('All data is forwarded securely to Elisa')}</li>
        </ul>
        <h3 aria-describedby={APP_COMPATIBILITY_FOOTNOTE_ID} className={dsClass.H4}>
          {t.OD39('Download the app for free from your app store!')}
        </h3>
        <p className={`${BASE_CLASS}__footnote`} id={APP_COMPATIBILITY_FOOTNOTE_ID}>
          {t.OD40(
            'The app is only compatible with trackers that use Elisa’s Laitenetti subscription. See the list of compatible tracking devices below.'
          )}
        </p>
        <AppStoreBadges
          apple={{ href: 'https://apps.apple.com/fi/app/elisa-paikannus/id1605935517' }}
          google={{ href: 'https://play.google.com/store/apps/details?id=com.elisa.paikannus' }}
        />
      </div>
      <Picture
        alt=""
        offerWidthAlternatives={[223, 288, 351, 400, 800]}
        renderedImageSize={{
          onPhone: 'min(400px, 100vw - 32px)',
          onPhoneLarge: '400px',
          onLaptop: 'min(400px, (100vw - 64px - 136px) * 33 / 113)',
          onDesktop: 'calc((1320px - 136px) * 33) / 113)',
        }}
        src={`${pictureStorageBaseUrl}s3GaA0iWh8Ff2oB3QtEM4/i.jpg`}
      />
    </section>
  );
};

// These are all OnlineModelCategory.ACCESSORIES
const TRACKER_ONLINE_MODEL_CODES: OnlineModelHeader['onlineModelCode'][] = [
  // https://yrityksille.elisa.fi/teltonika-fmm230-kiintea-gps-paikannin
  '46e48caf-e907-98ad-b70d-de81b4db0e12',
  // https://yrityksille.elisa.fi/teltonika-tmt250tstaa0-gps-paikannin-yleiskayttoon
  '6b7fe863-ae72-4695-bd79-5f5d6ee550a7',
  // https://yrityksille.elisa.fi/tat100tstaa0-gps-paikannin-yleiskayttoon
  '9c12a572-837c-53ca-fdcc-f10ef3c61df1',
  // https://yrityksille.elisa.fi/teltonika-fmp100-gps-paikannin-ajoneuvoihin
  'f31f5424-f53c-5d4f-6e7c-2e98b08066dc',
];

const TrackingDevices = () => {
  const dispatch = useDispatch();
  const { accessoriesHeaders } = useLoaderData() as PublicM2MBroadbandLoaderData;
  if (!accessoriesHeaders?.headers) {
    return null;
  }
  return (
    <aside>
      <PromotionBlockContainer
        eCommerceEvents={{
          onClickListProduct: (type, position, omhs) => dispatch(eCommerceClickEvent(type, position, omhs)),
          onSeeListProduct: (type, position, omhs) => dispatch(eCommerceImpressionEvent(type, position, omhs)),
        }}
        onAnalyticsEvent={() => {
          /* NOOP, we don't need this */
        }}
        onlineModelHeaders={accessoriesHeaders.headers}
        promotions={TRACKER_ONLINE_MODEL_CODES.map(onlineModelCode => ({
          productGuid: onlineModelCode,
          type: 'small',
        }))}
        title={t.B102('Tracking devices')}
      />
    </aside>
  );
};

// These are all OnlineModelCategory.ACCESSORIES
const IOT_ONLINE_MODEL_CODES: OnlineModelHeader['onlineModelCode'][] = [
  // https://yrityksille.elisa.fi/simpal-s260-energiamittari-ja-etaohjattava-pistorasia
  '1772000f-84d3-968c-66a7-79def4df4a2b',
  // https://yrityksille.elisa.fi/burrel-s22-wa-black-edition-riistakamera
  '4ef6592b-1fb2-d157-bf7b-4be4e3ac2c6e',
  // https://yrityksille.elisa.fi/simpal-t2-etaluettava-lampomittari
  '58728ae1-4ee5-a8b9-9d8d-8af4c36741da',
  // https://yrityksille.elisa.fi/reolink-duo-2-lte-plus-sim-ulkokamera
  'd13ce0d4-075e-0cb8-157b-b675b81a9009',
];

const IoTDevices = () => {
  const dispatch = useDispatch();
  const { accessoriesHeaders } = useLoaderData() as PublicM2MBroadbandLoaderData;
  if (!accessoriesHeaders?.headers) {
    return null;
  }
  return (
    <aside id={IOT_DEVICES_ID}>
      <PromotionBlockContainer
        eCommerceEvents={{
          onClickListProduct: (type, position, omhs) => dispatch(eCommerceClickEvent(type, position, omhs)),
          onSeeListProduct: (type, position, omhs) => dispatch(eCommerceImpressionEvent(type, position, omhs)),
        }}
        onAnalyticsEvent={() => {
          /* NOOP, we don't need this */
        }}
        onlineModelHeaders={accessoriesHeaders.headers}
        promotions={IOT_ONLINE_MODEL_CODES.map(onlineModelCode => ({
          productGuid: onlineModelCode,
          type: 'small',
        }))}
        title={t.B103('Also view other IoT devices')}
      />
      <a
        className={classNames(
          dsClass.LINK,
          dsClass.LINK_STYLE_LINK_BUTTON,
          dsClass.LINK_BUTTON_COLOR_LIGHT,
          dsClass.LINK_BUTTON_SIZE_L,
          dsClass.MARGIN_BOTTOM_8,
          dsClass.MARGIN_TOP_4
        )}
        href={paths.IOT_DEVICES}
      >
        {t.OD23('View all IoT devices')}
      </a>
    </aside>
  );
};

const DisjointedGenericInformationAboutLaitenetti = () => {
  return (
    <section className={classNames(BLEED_GREY, dsClass.TEXT_ALIGN_LEFT)}>
      {t.OD22(
        'The Laitenetti subscription allows you to access and control your smart devices from any device with an internet connection, such as a smartphone or desktop computer. You can view and collect data over the Internet and control and manage your devices remotely. Access Point Name (APN) for Laitenetti business subscriptions is APN internet, cannot connect other APNs.'
      )}
    </section>
  );
};

const FAQ = () => (
  <FaqAccordion
    heading={t.SDWG(frequentlyAskedQuestionsMsg)}
    items={[
      {
        content: (
          <>
            <p>
              {t.OD1O(
                'Laitenetti is an internet subscription for the management of remotely controlled devices, such as surveillance cameras and trackers or smart sockets and thermometers.'
              )}
            </p>
            <p>
              {t.OD1P(
                'Laitenetti works like a conventional subscription, establishing a connection with a device. A device subscription enables you to monitor and remotely control devices or view the images or video sent by a camera. Laitenetti is intended for a data connection between devices.'
              )}
            </p>
          </>
        ),
        heading: t.OD1N('What is Laitenetti?'),
      },
      {
        content: (
          <p>
            {t.OD1R(
              'The Laitenetti subscription is compatible with devices such as alarms, air source heat pumps, GPS trackers, condition monitoring sensors, warning devices, surveillance and trail cameras as well as mobile routers and network devices.'
            )}
          </p>
        ),
        heading: t.OD1Q('What devices are compatible with the Laitenetti subscription?'),
      },
      {
        content: (
          <p>
            {t.OD1T(
              'Trail and surveillance cameras require slightly faster upstream and downstream speeds, which is why we recommend an Elisa Laitenetti M, Laitenetti L or Laitenetti 5G subscriptions. Laitenetti L and Laitenetti 5G are suitable for a camera that streams live video while Laitenetti M is sufficient for sending images.'
            )}
          </p>
        ),
        heading: t.OD1S('Which subscription is compatible with a surveillance or trail camera?'),
      },
      {
        content: (
          <p>
            {t.OD1V(
              'When you need to stream high-quality live video, we recommend using the Laitenetti 5G subscription.'
            )}
          </p>
        ),
        heading: t.OD1U('Which interface is suitable for streaming high-quality live video?'),
      },
      {
        content: (
          <>
            <p>
              {t.OD1X(
                'With the Elisa Laitenetti app, you can do things like view the locations of your trackers on the map or monitor the speed data of vehicles. If you want, you can share your location data with other people.'
              )}
            </p>
            <p>
              {t.OD1Y(
                'The Laitenetti app works together with a device subscription and tracking device. You can download the app for free from your app store. Please note that the app only works with trackers that use Elisa’s Laitenetti subscription.'
              )}
            </p>
          </>
        ),
        heading: t.OD1W('How can I track my device?'),
      },
      {
        content: (
          <>
            <p>
              {t.OD20('You can order a new subscription from this page or order it in ')}
              <a href={paths.PS_BROADBAND_SUBSCRIPTION_NEW_M2M}>{t.SF15(corporateOmaElisaMsg)}</a>.
            </p>
            <p>
              {t.OD21(
                'When you have made the order, we will send you a new SIM card by post that you can install in your smart device. The delivery of the SIM card will take approximately 2 to 4 business days.'
              )}
            </p>
          </>
        ),
        heading: t.OD1Z('How to get Elisa’s Elisa Laitenetti subscription'),
      },
    ]}
  />
);

const AreYouAConsumerCustomer = () => (
  <section className={BLEED_GREY}>
    <h2 className={dsClass.H3}>{t.B100('Are you a consumer customer?')}</h2>
    <a className={dsClass.TEXT_L} href="https://elisa.fi/laitesim/">
      {t.B101('See the Laitenetti options for consumers')}
    </a>
  </section>
);

export const PublicM2MSubscriptions = () => {
  return (
    <section className={`${BASE_CLASS} of-public-page`}>
      <Breadcrumbs />
      <Header />
      <Subscriptions />
      <PublicSelfServiceBanner />
      <LaitenettiSubscriptionFeatures />
      <ElisaLaitenettiApp />
      <TrackingDevices />
      <IoTDevices />
      <DisjointedGenericInformationAboutLaitenetti />
      <FAQ />
      <AreYouAConsumerCustomer />
    </section>
  );
};
