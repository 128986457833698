/**
 * SFDC Community API
 * # Summary Elisa B2B API for an administrator of a company to query and modify the company information.  # Usage This API is used in the `https://yrityksille.elisa.fi/omaelisa` pages, but it is possible to later on expose this documentation publicly and make the endpoint queryable via external clients.  # Identifiers All entities in the API have a globally unique, internal identifier field named with the format [object_type]Id, e.g. `invoiceId`, which is also used in some of the API endpoint urls, e.g. `/api/v1/selfservice/invoices/{invoiceId}`. This value is not meaningful to the end user but is only used as an internally in the API.  Most entities also have a displayable identifier named in the format [object_type]DisplayId. This value can and should be shown to the end user, as it is also printed in relevant documents. It is also used in some of the public URLs, e.g. `https://yrityksille.elisa.fi/omaelisa/laskut/{invoiceDisplayId}`. Because of the user might land directly into a URL of a single entity with a display id, some of the API endpoints here also support querying via the display id, e.g. `/api/v1/selfservice/invoices?invoiceDisplayId={invoiceDisplayId}`.  The reason for using two identifiers instead of only one display id, is to make sure that every object will always have exactly one REST API URL that will never collide with another. While collisions of display ids should not happen in production, the id fields are guaranteed to be unique even across different test environments. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BillingAccountStatus } from './billingAccountStatus.js';
import { ContactType } from './contactType.js';
import { Address } from './address.js';
import { BillingAccountDeliveryMethod } from './billingAccountDeliveryMethod.js';


export interface BillingAccount { 
    /**
     * Internal, unique id of the billing account. SFDC: Billing_Account__c.Id
     */
    billingAccountId?: string;
    /**
     * Billing account id shown to the user. SFDC: Billing_Account__c.Billing_Account_Number__c
     */
    billingAccountDisplayId?: string;
    billingAccountStatus?: BillingAccountStatus;
    /**
     * Id of the payer, i.e. Y-tunnus. SFDC: Billing_Account__c.Payer_Business_Id__c
     */
    payerBusinessId?: string;
    /**
     * Name of the payer for this account, typically company name. SFDC: Billing_Account__c.Payer__c (lookup from Account)
     */
    payerName?: string;
    /**
     * Extension to the name of the payer for this account, typically company name. SFDC: Billing_Account__c.Payer_Name_Extension__c
     */
    payerNameExtension?: string;
    payerAddress: Address;
    /**
     * Id of the contact person for this billing account. If sourceSystem is SFDC then required(while edit and create new BA). SFDC: Billing_Account__c.Contact__c
     */
    billingContactId?: string;
    billingContactType?: ContactType;
    /**
     * Name of the contact person or common function for this billing account. SFDC: Billing_Account__c.Contact__c.Name
     */
    billingContactName?: string;
    /**
     * Bill language as two-letter ISO 639-1 code. If bill language is missing for BA then UNKNOWN will be returned. SFDC: Billing_Account__r.Recsconta__Billing_Language__c
     */
    billLanguage: string;
    /**
     * Bill format. Either this or the below custom type is given. SFDC: Billing_Account__r.Bill_Format_Type__c
     */
    billFormatType?: BillingAccount.BillFormatTypeEnum;
    /**
     * Bill layout custom type. If this is given, billFormatType is not given. Initially SUMMARY is a possible value. The user can not input this field when editing or creating billing accounts, but this could be returned. SFDC: Billing_Account__r.Bill_Format_Type_To__c
     */
    billFormatCustomType?: string;
    deliveryMethod: BillingAccountDeliveryMethod;
    /**
     * Custom type of delivery method. Initially for PAPER this can be DIRECT, but reserved also for future use. The user can not input this field when creating new or editing billing accounts, but this could be returned. SFDC: Billing_Account__c.Billing_Channel_To__c
     */
    deliveryMethodCustomType?: string;
    /**
     * Id of the contact person or common function receiving the email. Required if deliveryMethod is EMAIL, optional if PAPER. Editable. SFDC: Billing_Account__c.Bill_Receiver__c
     */
    billReceiverId?: string;
    billReceiverType?: ContactType;
    /**
     * Name of the contact person or common function receiving the email. Required if deliveryMethod is EMAIL, optional if PAPER. SFDC: Billing_Account__c.Bill_Receiver__c.Name
     */
    billReceiverName?: string;
    /**
     * Email of the contact person. Required if deliveryMethod is EMAIL, optional if PAPER. SFDC: Billing_Account__c.Bill_Receiver__c.Email
     */
    billReceiverEmail?: string;
    /**
     * Price of single bill given in cents, if deliveryMethod PAPER, or default price if omitted. SFDC: Billing_Account__c.Billing_Channel__c
     */
    billPrice?: number;
    /**
     * Electronic operator name if deliveryMethod is ELECTRONIC. Format varies, for example HELSFIHH. SFDC: Billing_Account__r.eOperator__c
     */
    billElectronicOperator?: string;
    /**
     * Electronic operator address if deliveryMethod is ELECTRONIC. SFDC: Billing_Account__r.Electronic_Address__c
     */
    billElectronicAddress?: string;
    /**
     * Customer given optional reference text 1. SFDC: Billing_Account__c.Reference_Id_1__c
     */
    customerReference1?: string;
    /**
     * Customer given optional reference text 2. SFDC: Billing_Account__c.Reference_Id_2__c
     */
    customerReference2?: string;
    /**
     * Customer given optional reference text 3. SFDC: Billing_Account__c.Reference_Id_3__c
     */
    customerReference3?: string;
    /**
     * Customer given optional reference text 4. SFDC: Billing_Account__c.Reference_Id_4__c
     */
    customerReference4?: string;
    /**
     * Account balance in cents, can be positive or negative. SFDC: Billing_Account__c.Balance__c
     */
    accountBalance?: number;
    /**
     * Source system. SFDC: Billing_Account__c.RecordType.Name
     */
    sourceSystem?: string;
    /**
     * Unix timestamp in milliseconds of creation. SFDC: Billing_Account__c.CreatedDate
     */
    created?: number;
    /**
     * Unix timestamp in milliseconds of last modification. SFDC: Billing_Account__c.LastModifiedDate
     */
    lastModified?: number;
    /**
     * Source system. SFDC: Billing_Account__c.Contact.Phone
     */
    billingContactPhone?: string;
    /**
     * Count of subscriptions available under a Billing Account.
     */
    subscriptionCount?: number;
    /**
     * Name of the billing account. SFDC: Billing_Account__c.Billing_Account_Name__c
     */
    billingAccountName?: string;
    /**
     * Extension to the name of the billing account. SFDC: Billing_Account__c.BA_Name_Extension__c
     */
    billingAccountExtensionName?: string;
    /**
     * Additional email addresses of the bill recipients, if deliveryMethod is EMAIL. SFDC: Bill_Receiver_Email_address_User1__c upto Bill_Receiver_Email_address_User5__c
     */
    additionalBillReceiverEmails?: Array<string>;
}
export namespace BillingAccount {
    export type BillFormatTypeEnum = 'CONSOLIDATED_INVOICE_WITH_ITEMIZATION' | 'CONSOLIDATED_INVOICE_WITHOUT_ITEMIZATION' | 'COST_CENTRE_INVOICE' | 'COST_CENTRE_INVOICE_WITH_ITEMIZATION';
    export const BillFormatTypeEnum = {
        CONSOLIDATED_INVOICE_WITH_ITEMIZATION: 'CONSOLIDATED_INVOICE_WITH_ITEMIZATION' as BillFormatTypeEnum,
        CONSOLIDATED_INVOICE_WITHOUT_ITEMIZATION: 'CONSOLIDATED_INVOICE_WITHOUT_ITEMIZATION' as BillFormatTypeEnum,
        COST_CENTRE_INVOICE: 'COST_CENTRE_INVOICE' as BillFormatTypeEnum,
        COST_CENTRE_INVOICE_WITH_ITEMIZATION: 'COST_CENTRE_INVOICE_WITH_ITEMIZATION' as BillFormatTypeEnum
    };
}


