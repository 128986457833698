import { EmployeeOrdersWrapper } from '../../../../../components/EmployeeOrdersWrapper/EmployeeOrdersWrapper.js';
import { EmployeeSceneTab, getFilteredSubscriptionAction } from '../../../../../common/utils/employeeUtils.js';
import { SubscriptionActionDetailsView } from '../../../../../components/SubscriptionActionDetails/SubscriptionActionDetailsView.js';
import { findItemById } from '../../../../../common/utils/stateUtils.js';
import { loadSubscriptionActions } from '../../../../../selfservice/actions/index.js';
import { omaElisaForEmployeeMsg, orderRequestsMsg, t } from '../../../../../common/i18n/index.js';
import { onLoadBillingAccounts } from '../../../../../common/utils/dispatcherUtils.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTitle } from '../../../../../common/hooks/useTitle.js';
import type { State } from '../../../../../selfservice/common/store.js';
import type { SubscriptionId } from '../../../../../common/constants/pathInterfaces.js';

export const ChangeRequestPath = () => {
  const { subscriptionId } = useParams<SubscriptionId>();
  const dispatch = useDispatch();
  const subActions = useSelector((state: State) => state.selfservice?.subscriptionActions);
  const filteredSubAction = getFilteredSubscriptionAction(subActions);
  const selectedSubAction = findItemById('subscriptionActionDisplayId', subscriptionId, filteredSubAction);

  useTitle([t.H6JV(omaElisaForEmployeeMsg), t.Y29L(orderRequestsMsg)]);

  useEffect(() => {
    onLoadBillingAccounts(dispatch);
    dispatch(loadSubscriptionActions({ displayId: subscriptionId }));
  }, [dispatch, subscriptionId]);

  return (
    <EmployeeOrdersWrapper sceneTab={EmployeeSceneTab.ORDERS_SUBSCRIPTION_ACTIONS}>
      <SubscriptionActionDetailsView
        breadCrumbPaths={[
          { name: t.H6JV(omaElisaForEmployeeMsg), path: paths.EMPLOYEE_HOME },
          { name: t.Y29L(orderRequestsMsg) },
        ]}
        subscriptionAction={selectedSubAction}
      />
    </EmployeeOrdersWrapper>
  );
};
