import { Input } from '../../components/Input/Input.js';
import { phoneNumberMsg, t } from '../../common/i18n/index.js';

export interface PhoneNumberInputProps {
  error?: string;
  onChange: (simCardNumber: string) => void;
  onBlur: () => void;
  onFocus: () => void;
  value: string;
  setRefCallback?: (domElement: HTMLElement | null) => void;
  label?: string;
}

export const PhoneNumberInput = (props: PhoneNumberInputProps): JSX.Element => {
  const phoneNumberLabel = props.label || t.AGIJ(phoneNumberMsg);
  return (
    <div ref={(domElement: HTMLElement | null) => props.setRefCallback?.(domElement)}>
      <Input
        type="tel"
        required={true}
        placeholder={phoneNumberLabel}
        error={props.error}
        onChange={event => props.onChange(event.currentTarget.value)}
        name="phoneNumber"
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        value={props.value}
      />
    </div>
  );
};
