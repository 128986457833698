import { TypeKeys, generateActionId } from './actionsUtils.js';
import { convertToCommonErrors } from '../../common/utils/errorUtils.js';
import type { CampaignsResponse, GenericError, OnlineModel, OnlineModelsResponse } from '../../generated/api/models.js';
import type { CrudAction, DisplayItemsLoadAction, ErrorAction, ErrorActionCreator } from './actionsUtils.js';
import type { ModelType } from '../../common/enums.js';

export type LoadOnlineModelAction = DisplayItemsLoadAction<TypeKeys.LOAD_ONLINE_MODEL, OnlineModel>;

export const loadOnlineModelWithId = (onlineModelId: string): LoadOnlineModelAction => {
  return {
    displayId: onlineModelId,
    id: generateActionId(),
    type: TypeKeys.LOAD_ONLINE_MODEL,
  };
};

export type LoadOnlineModelByModelTypeAction = DisplayItemsLoadAction<
  TypeKeys.LOAD_ONLINE_MODEL_BY_MODEL_TYPE,
  OnlineModel
>;

export const loadOnlineModelByModelType = (modelType: ModelType): LoadOnlineModelByModelTypeAction => {
  return {
    displayId: modelType,
    id: generateActionId(),
    type: TypeKeys.LOAD_ONLINE_MODEL_BY_MODEL_TYPE,
  };
};

export type LoadOnlineModelWithEffectivePricesAction = DisplayItemsLoadAction<
  TypeKeys.LOAD_ONLINE_MODEL_WITH_EFFECTIVE_PRICES,
  OnlineModel
>;

export const loadOnlineModelWithEffectivePrices = (onlineModelId: string): LoadOnlineModelWithEffectivePricesAction => {
  return {
    displayId: onlineModelId,
    id: generateActionId(),
    type: TypeKeys.LOAD_ONLINE_MODEL_WITH_EFFECTIVE_PRICES,
  };
};

export type LoadOnlineModelFailedAction = ErrorAction<TypeKeys.LOAD_ONLINE_MODEL_FAILED>;

export const loadOnlineModelFailed: ErrorActionCreator<TypeKeys.LOAD_ONLINE_MODEL_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[]
): LoadOnlineModelFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  type: TypeKeys.LOAD_ONLINE_MODEL_FAILED,
});

export interface LoadOnlineModelFulfilledAction {
  onlineModel: OnlineModel;
  type: TypeKeys.LOAD_ONLINE_MODEL_FULFILLED;
}

export const loadOnlineModelFulfilled = (response: OnlineModel): LoadOnlineModelFulfilledAction => ({
  onlineModel: response,
  type: TypeKeys.LOAD_ONLINE_MODEL_FULFILLED,
});

export interface LoadOnlineModelsAction extends CrudAction<TypeKeys.LOAD_ONLINE_MODELS> {
  category: string;
  headersOnly: boolean;
}

export const loadOnlineModels = (category: string, headersOnly: boolean): LoadOnlineModelsAction => ({
  category: category,
  headersOnly: headersOnly,
  id: generateActionId(),
  type: TypeKeys.LOAD_ONLINE_MODELS,
});

export type LoadOnlineModelsFailedAction = ErrorAction<TypeKeys.LOAD_ONLINE_MODELS_FAILED>;

export const loadOnlineModelsFailed: ErrorActionCreator<TypeKeys.LOAD_ONLINE_MODELS_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[]
): LoadOnlineModelsFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  type: TypeKeys.LOAD_ONLINE_MODELS_FAILED,
});

export interface LoadOnlineModelsFulfilledAction {
  category: string;
  headersOnly: boolean;
  response: OnlineModelsResponse;
  type: TypeKeys.LOAD_ONLINE_MODELS_FULFILLED;
}

export const loadOnlineModelsFulfilled = (
  category: string,
  headersOnly: boolean,
  response: OnlineModelsResponse
): LoadOnlineModelsFulfilledAction => ({
  category: category,
  headersOnly: headersOnly,
  response: response,
  type: TypeKeys.LOAD_ONLINE_MODELS_FULFILLED,
});

export type ClearCampaignsAction = CrudAction<TypeKeys.CLEAR_CAMPAIGNS>;

export interface GetCampaignsQueryParams {
  onlineModel: string;
  salesType: string;
  voucher: string;
}

export interface GetCampaignsAction extends CrudAction<TypeKeys.GET_CAMPAIGNS> {
  params: GetCampaignsQueryParams;
}

export const clearCampaigns = (): ClearCampaignsAction => ({
  id: generateActionId(),
  type: TypeKeys.CLEAR_CAMPAIGNS,
});

export const getCampaigns = (params: GetCampaignsQueryParams): GetCampaignsAction => ({
  params,
  id: generateActionId(),
  type: TypeKeys.GET_CAMPAIGNS,
});

export type GetCampaignsFailedAction = ErrorAction<TypeKeys.GET_CAMPAIGNS_FAILED>;

export const getCampaignsFailed: ErrorActionCreator<TypeKeys.GET_CAMPAIGNS_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[]
): GetCampaignsFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  type: TypeKeys.GET_CAMPAIGNS_FAILED,
});

export interface GetCampaignsFulfilledAction {
  params: GetCampaignsQueryParams;
  response: CampaignsResponse;
  type: TypeKeys.GET_CAMPAIGNS_FULFILLED;
}

export const getCampaignsFulfilled = (
  params: GetCampaignsQueryParams,
  response: CampaignsResponse
): GetCampaignsFulfilledAction => ({
  params,
  response,
  type: TypeKeys.GET_CAMPAIGNS_FULFILLED,
});

export type OnlineModelsActionTypes =
  | LoadOnlineModelAction
  | LoadOnlineModelFailedAction
  | LoadOnlineModelFulfilledAction
  | LoadOnlineModelByModelTypeAction
  | LoadOnlineModelWithEffectivePricesAction
  | LoadOnlineModelsAction
  | LoadOnlineModelsFailedAction
  | LoadOnlineModelsFulfilledAction
  | ClearCampaignsAction
  | GetCampaignsAction
  | GetCampaignsFulfilledAction
  | GetCampaignsFailedAction;
