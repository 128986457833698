import * as React from 'react';
import { onEnterOrSpaceKeyPress } from '../../common/utils/handlerUtils.js';

import './Tooltip.scss';

export interface TooltipProps {
  children: React.ReactNode;
  alignTooltipIconRight?: boolean;
  forceDisplayed?: boolean; // Mainly for testing purposes
}

/**
 * @deprecated Use CL.Tooltip or CL.Popover instead
 *
 **/
export const Tooltip = ({ alignTooltipIconRight, children, forceDisplayed }: TooltipProps) => {
  const [isDisplayed, setDisplayed] = React.useState(false);
  const [tooltipRef, setTooltipRef] = React.useState<HTMLDivElement | undefined | null>();
  const [tooltipContentRef, setTooltipContentRef] = React.useState<HTMLSpanElement | undefined | null>();

  let overrideLeftPos;
  // Smart position relative to the viewport edges requires JavaScrip DOM magic
  // Only case currently covered is that when the tooltip icon is near the left edge
  // Other cases should be added here when required.
  if (tooltipRef && tooltipContentRef) {
    const toolTipLeft = tooltipRef.getBoundingClientRect().left;
    const toolTipContentWidth = tooltipContentRef.getBoundingClientRect().width;
    if (toolTipLeft < 375) {
      overrideLeftPos = `${toolTipContentWidth / 2 + 12 - toolTipLeft}px`;
    }
  }

  return (
    <div className="ea-responsive-tooltip">
      <div
        className={`ea-tooltip ${forceDisplayed || isDisplayed ? 'ea-tooltip--visible' : ''}`}
        onMouseEnter={() => {
          setDisplayed(true);
        }}
        onMouseLeave={() => {
          setDisplayed(false);
        }}
        ref={setTooltipRef}
      >
        <i
          className="ea-icon ea-icon--info-nega"
          onClick={() => {
            setDisplayed(true);
          }}
          onKeyPress={(event: React.KeyboardEvent) =>
            onEnterOrSpaceKeyPress(event, () => {
              setDisplayed(true);
            })
          }
          role="button"
          tabIndex={0}
        />
        <span
          className={`ea-disclaimertext ea-tooltip__text ea-tooltip__text--top ${
            alignTooltipIconRight === true ? 'ea-tooltip__text--align-right' : ''
          }`}
          style={{
            left: overrideLeftPos,
          }}
          ref={setTooltipContentRef}
        >
          {children}
          <span
            className="ea-notification__close ea-icon ea-icon--cross"
            onClick={() => {
              setDisplayed(false);
            }}
            onKeyPress={(event: React.KeyboardEvent) =>
              onEnterOrSpaceKeyPress(event, () => {
                setDisplayed(false);
              })
            }
            role="button"
            tabIndex={0}
          />
        </span>
      </div>
    </div>
  );
};
