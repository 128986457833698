import { EmployeeOneTimePasswordScene } from './EmployeeOneTimePasswordScene.js';
import { getSubCategoryFromPath } from '../../common/utils/employeeUtils.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useLocation } from 'react-router-dom';

export const EmployeeOneTimePasswordPath = () => {
  const { pathname } = useLocation();
  const category = getSubCategoryFromPath(pathname);
  const { authenticatedUser } = useAuth();

  return (
    <EmployeeOneTimePasswordScene
      category={category}
      errors={authenticatedUser?.errors}
      saving={authenticatedUser?.saving || false}
    />
  );
};
