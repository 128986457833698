import * as CL from '@design-system/component-library';
import { AddOrSelectBillingAccounts } from '../AddOrSelectBillingAccountsV2/AddOrSelectBillingAccounts.js';
import {
  CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE,
  filterAndGetDefaultBillingAccountId,
  getEmptyBillingAccount,
  getReceiverType,
  prepareBillingAccountSave,
} from '../../common/utils/billingAccountUtils.js';
import { CommonErrorType } from '../../common/enums.js';
import { OrderSummary, OrderSummaryType } from '../OrderSummary/OrderSummary.js';
import { SalesType } from '../../generated/api/models.js';
import { Voucher } from '../OrderSubscriptionSelection/Voucher.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { fieldCantBeEmptyMsg, submitOrderMsg, t } from '../../common/i18n/index.js';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { validCampaignsPresent } from '../../common/utils/campaignUtils.js';
import type {
  BillChannel,
  BillingAccount,
  CampaignAssociation,
  Contact,
  OnlineModel,
} from '../../generated/api/models.js';
import type { BillingAccountOrErrorSupplier, CommonError } from '../../common/types/errors.js';
import type { ConfiguredOffer } from '../../common/types/commercialProduct.js';
import type { Dispatch, SetStateAction } from 'react';
import type { State } from '../../selfservice/common/store.js';

export interface FixedBroadbandBillingInformationProps {
  onSubmit: (billingAccount: BillingAccount, isNew: boolean, newBillingAccountCommonFunction?: Contact) => void;
  fixedBroadBandOnlineModel: OnlineModel;
  configuredOffer: ConfiguredOffer;
  selectedCampaign?: CampaignAssociation;
  setCampaingAutoSelect: Dispatch<SetStateAction<boolean>>;
  billChannels: BillChannel[];
}

const getBillingAccountErrors = (billingAccountsErrors: CommonError[] = [], noBillingAccountError?: CommonError) =>
  noBillingAccountError ? [...billingAccountsErrors, noBillingAccountError] : billingAccountsErrors;

export const FixedBroadbandBillingInformation = ({
  onSubmit,
  configuredOffer,
  fixedBroadBandOnlineModel,
  selectedCampaign,
  setCampaingAutoSelect,
}: FixedBroadbandBillingInformationProps) => {
  const [selectedBaId, setSelectedBaId] = useState<string | undefined>();
  const [handleBAValues, setHandleBAValues] = useState<BillingAccountOrErrorSupplier>();
  const [noBillingAccountError, setNoBillingAccountError] = useState<CommonError>();

  const billingAccounts = useSelector((state: State) => state.selfservice?.billingAccounts || undefined, deepEqual);
  const billingAccountsErrors = useMemo(
    () => getBillingAccountErrors(billingAccounts?.errors, noBillingAccountError),
    [billingAccounts, noBillingAccountError]
  );
  const campaigns = useSelector((state: State) => state.campaigns, deepEqual);
  const campaignErrors = campaigns?.errors || [];
  const campaignContextsFromVoucher = campaigns?.campaignContexts || [];
  const activeCampaignWithFixedPeriod =
    campaignErrors.length === 0 &&
    validCampaignsPresent(campaignContextsFromVoucher) &&
    selectedCampaign?.fixedTermPeriod;

  // Set default Billing Id from fetched billing accounts or select CREATE_NEW if no billing accounts available
  useEffect(() => {
    if (!selectedBaId && billingAccounts?.searchResults) {
      setSelectedBaId(
        billingAccounts?.searchResults?.length
          ? filterAndGetDefaultBillingAccountId(billingAccounts)
          : CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE
      );
    }
  }, [selectedBaId, billingAccounts]);

  const handleSubmit = () => {
    if (selectedBaId === undefined) {
      setNoBillingAccountError({
        type: CommonErrorType.VALIDATION,
        field: 'selectBillingAccount',
        message: t.VPVR(fieldCantBeEmptyMsg),
      });
    } else if (handleBAValues && selectedBaId === CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE) {
      const { obj } = handleBAValues();
      const values = obj as BillingAccount;
      const receiverType = getReceiverType(obj);
      const updatedBillingAccount = prepareBillingAccountSave(getEmptyBillingAccount('company'), values, receiverType);
      onSubmit(updatedBillingAccount, true);
    } else {
      const billingAccount = billingAccounts?.items?.find((ba: BillingAccount) => ba.billingAccountId === selectedBaId);
      // `!` because above `.find()` can return `undefined`. In practice that should never happen as the `selectedBaId`
      // is one of the ID's from the `billingAccounts` list. In theory it could happen if state is somehow messed up
      // after the `selectedBaId` is set. In any case there's really nothing we can do about it anyway.
      onSubmit(billingAccount!, false);
    }
  };

  return (
    <div className="of-selfservice">
      {billingAccounts?.searchResults && (
        <AddOrSelectBillingAccounts
          addNewBA={true}
          isSaving={false}
          selectedBaId={selectedBaId}
          getBillingAccountSaveValuesFn={selectedBaData => {
            const { selectedBaId: selBaId, prepareNewBillingAccountSaveValues } = selectedBaData();
            setHandleBAValues(() => prepareNewBillingAccountSaveValues);
            if (selBaId && selBaId !== CREATE_NEW_BILLING_ACCOUNT_OPTION_VALUE) {
              setNoBillingAccountError(undefined);
            }
            if (selBaId) {
              setSelectedBaId(selBaId);
            }
          }}
          createBillingAccountErrors={billingAccountsErrors}
        />
      )}
      <Voucher
        onlineModelCode={fixedBroadBandOnlineModel.onlineModelCode}
        salesType={SalesType.NEW_SALE}
        onClear={() => setCampaingAutoSelect(true)}
        onSubmit={() => setCampaingAutoSelect(true)}
      />
      {activeCampaignWithFixedPeriod && campaigns?.voucher && (
        <div className={dsClass.MARGIN_BOTTOM_4}>
          {t.H0N7(
            'Please note that the voucher code "{}" will make the subscription fixed-term and thus it can not be terminated in the middle of the contract period.',
            campaigns.voucher
          )}
        </div>
      )}
      <OrderSummary
        commercialProducts={configuredOffer.selectedCommercialProducts}
        summaryType={OrderSummaryType.DETAILS_CLOSED}
      />
      <CL.Button size="l" className={`${dsClass.MARGIN_TOP_4} of-next-button`} onClick={handleSubmit}>
        {t.RZU4(submitOrderMsg)}
      </CL.Button>
    </div>
  );
};
