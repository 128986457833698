import { TableSortOrder } from './tableConstants.js';

export function tableSort<T extends object>(column: string, order: TableSortOrder, items: T[]) {
  return [...items].sort((a, b) => {
    // @ts-ignore
    const first = a[column];
    // @ts-ignore
    const second = b[column];
    if (order === TableSortOrder.ASC) {
      return first < second ? -1 : first > second ? 1 : 0;
    }
    return second < first ? -1 : second > first ? 1 : 0;
  });
}

export function tablePaginate<T extends object>(items: T[], offset: string, itemPerPage: number) {
  return items.slice(Number(offset), Number(offset) + itemPerPage);
}

function formatToSearchable(item: string): string {
  const itemWithoutSpaces = item.valueOf().replace(/\s/g, '');
  // is just numbers (first replace +358 with 0)
  if (/^\d+$/.test(itemWithoutSpaces.replace('+358', '0'))) {
    return itemWithoutSpaces;
  }
  return item;
}

export const findChildrenAsText = (childNode: JSX.Element): string => {
  if (typeof childNode?.props?.children === 'string') {
    return formatToSearchable(childNode.props?.children);
  }
  if (Array.isArray(childNode?.props?.children)) {
    const plainText: string[] = [''];
    childNode.props?.children.forEach((value: string | JSX.Element) => {
      plainText.push(typeof value === 'string' ? formatToSearchable(value) : findChildrenAsText(value));
    });
    return plainText.join('');
  }
  return childNode?.props?.children ? findChildrenAsText(childNode?.props?.children) : '';
};

export function tableSearchRow<T extends object>(searchWords: string | undefined, row: T): boolean {
  if (!searchWords) {
    return true;
  }

  // First make the table row as single string...
  const searchString: string[] = [''];
  if (typeof row === 'object') {
    Object.keys(row).forEach(key => {
      // @ts-ignore
      const rowData = row[key];
      if (typeof rowData === 'string') {
        searchString.push(formatToSearchable(rowData));
      } else if (Array.isArray(rowData?.props?.items)) {
        Object.keys(rowData.props.items[0]).forEach(value => {
          searchString.push(formatToSearchable(rowData.props.items[0][value]));
        });
      } else if (rowData?.props?.children && typeof rowData?.props?.children === 'string') {
        searchString.push(formatToSearchable(rowData.props.children));
      } else if (rowData?.props?.children && Array.isArray(rowData?.props?.children)) {
        rowData?.props?.children.forEach((value: string | JSX.Element) => {
          searchString.push(typeof value === 'string' ? formatToSearchable(value) : findChildrenAsText(value));
        });
      }
    });
  }

  // ...and then match that with every search word.
  return searchWords.split(' ').every(word => {
    return searchString.some(columnValue => columnValue.toLowerCase().includes(word.toLowerCase()));
  });
}

export function tableSearch<T extends object>(searchWords: string, rows: T[]): T[] {
  return rows.filter(row => tableSearchRow(searchWords, row));
}
