import { ConsumerBA } from './billingAccountUtils.js';
import { EppSolutionResponse } from '../../generated/api/models.js';
import { fieldCantBeEmptyMsg, t } from '../i18n/index.js';
import type { ActionState } from '../../selfservice/common/store.js';
import type {
  AuthenticatedUserState,
  BillingAccountsState,
  CompanyInfoState,
  DisplayItemsState,
  ResourcesState,
} from '../../common/types/states.js';
import type { BillingAccount, ContactPerson } from '../../generated/api/models.js';

export const getContactInfo = (user: AuthenticatedUserState): ContactPerson => {
  return {
    firstName: user.firstName,
    lastName: user.lastName || '',
    email: user.email || '',
    phoneNumber: user.mobile || '',
    analyticsConsent: user.analyticsConsent,
    preferredLanguage: user.preferredLanguage,
    costCenter: user.costCenter,
    employeeNumber: user.employeeNumber,
    roles: user.contact?.person?.roles,
  };
};
// todo --> this should handle essearch results. Note -> header does not currently have all the needed fields
export const findPersonBillingAccount = (billingAccounts?: BillingAccountsState): BillingAccount | undefined =>
  billingAccounts?.items?.find(billingAccount => billingAccount.sourceSystem === ConsumerBA);
export const isEppSolutionActive = (companyInfo?: CompanyInfoState | null) =>
  companyInfo?.eppSolution?.eppSolutionStatus === EppSolutionResponse.EppSolutionStatusEnum.ACTIVE;
export const isCompanyInfoLoading = (companyInfo?: CompanyInfoState | null) => companyInfo?.companyInfoLoading === true;

export function findItemById<T>(idField: string, idValue?: string, itemsState?: DisplayItemsState<T>): T | undefined {
  if (itemsState?.items && idValue) {
    return itemsState.items.find(item => item[idField as keyof T] === idValue);
  }
  return undefined;
}

export const resourcesInitialState = (): ResourcesState => ({
  numbers: [],
  numbersLoading: false,
  validatedPhoneNumbers: [{ portable: false, error: t.VPVR(fieldCantBeEmptyMsg), phoneNumber: '', valid: false }],
});
// We strip the `actions`, otherwise when `LOAD_SUBSCRIPTIONS` is triggered the `actions` change and the components
// are re-rendered, including the `InfiniteScroll`, which will dispatch `LOAD_SUBSCRIPTIONS` again, causing multiple
// requests to be sent to backend.
export const stripActions = <T extends { actions?: ActionState[] }>(prop?: T | null) =>
  (prop && { ...prop, actions: [] }) || undefined;

// We strip the `latestChangeRef`, otherwise `authenticatedUser` will change on every myself-changes polling interval
// this will cause effects to be triggered that might end up dispatching actions unintentionally.
export const stripLatestChangeRef = <T>(prop?: T | null) =>
  (prop && { ...prop, latestChangeRef: undefined }) || undefined;

// Allows setting the history state without triggering RRD re-rendering and triggering loaders.
// Generally speaking it's probably good idea to avoid this. We shouldn't generally need to change the history state
// and if you think you do, there's probably better design available that would not require usage of this func.
export const setHistoryState = (state: object) => {
  window.history.replaceState({ ...window.history.state, usr: state }, '');
};
