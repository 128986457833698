import { TypeKeys, generateActionId } from './actionsUtils.js';
import { convertToCommonErrors } from '../../common/utils/errorUtils.js';
import type { Action } from 'redux';
import type { CommonError } from '../../common/types/errors.js';
import type { CrudAction, ErrorAction, ErrorActionCreator } from './actionsUtils.js';
import type { GenericError, Lead, PostAnonymousLeadRequest } from '../../generated/api/models.js';

export interface AddAnonymousLeadAction extends CrudAction<TypeKeys.ADD_ANONYMOUS_LEAD> {
  lead: PostAnonymousLeadRequest;
  errors?: CommonError[];
}

export interface AddAnonymousLeadFulfilledAction extends Action {
  type: TypeKeys.ADD_ANONYMOUS_LEAD_FULFILLED;
  formType: Lead.LeadTypeEnum;
}

export type AddAnonymousLeadFailedAction = ErrorAction<TypeKeys.ADD_ANONYMOUS_LEAD_FAILED>;

export type AddAnonymousLeadClearAction = ErrorAction<TypeKeys.ADD_ANONYMOUS_LEAD_CLEAR>;

export const addAnonymousLead = (lead: PostAnonymousLeadRequest, errors?: CommonError[]): AddAnonymousLeadAction => ({
  errors: errors,
  id: generateActionId(),
  lead,
  type: TypeKeys.ADD_ANONYMOUS_LEAD,
});

export const addAnonymousLeadFulfilled = (payload: PostAnonymousLeadRequest): AddAnonymousLeadFulfilledAction => ({
  type: TypeKeys.ADD_ANONYMOUS_LEAD_FULFILLED,
  formType: payload.lead.leadType,
});

export const clearAnonymousLead = (): AddAnonymousLeadClearAction => ({
  type: TypeKeys.ADD_ANONYMOUS_LEAD_CLEAR,
});

export const addAnonymousLeadFailed: ErrorActionCreator<TypeKeys.ADD_ANONYMOUS_LEAD_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): AddAnonymousLeadFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.ADD_ANONYMOUS_LEAD_FAILED,
});

export type AnonymousLeadsActionTypes =
  | AddAnonymousLeadAction
  | AddAnonymousLeadFulfilledAction
  | AddAnonymousLeadFailedAction
  | AddAnonymousLeadClearAction;
