/**
 * SFDC Community API
 * # Summary Elisa B2B API for an administrator of a company to query and modify the company information.  # Usage This API is used in the `https://yrityksille.elisa.fi/omaelisa` pages, but it is possible to later on expose this documentation publicly and make the endpoint queryable via external clients.  # Identifiers All entities in the API have a globally unique, internal identifier field named with the format [object_type]Id, e.g. `invoiceId`, which is also used in some of the API endpoint urls, e.g. `/api/v1/selfservice/invoices/{invoiceId}`. This value is not meaningful to the end user but is only used as an internally in the API.  Most entities also have a displayable identifier named in the format [object_type]DisplayId. This value can and should be shown to the end user, as it is also printed in relevant documents. It is also used in some of the public URLs, e.g. `https://yrityksille.elisa.fi/omaelisa/laskut/{invoiceDisplayId}`. Because of the user might land directly into a URL of a single entity with a display id, some of the API endpoints here also support querying via the display id, e.g. `/api/v1/selfservice/invoices?invoiceDisplayId={invoiceDisplayId}`.  The reason for using two identifiers instead of only one display id, is to make sure that every object will always have exactly one REST API URL that will never collide with another. While collisions of display ids should not happen in production, the id fields are guaranteed to be unique even across different test environments. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AddOn } from './addOn.js';
import { TaapiTechnology } from './taapiTechnology.js';
import { DeliveryType } from './deliveryType.js';


export interface DeliveryOrderDetails { 
    /**
     * Mobile number (MSISDN) of the ordered subscription. MOBILE orders only. SFDC: csord__Order.MSISDN__c.
     */
    mobileNumber?: string;
    /**
     * SIM Card number (ICC) of the ordered subscription. MOBILE orders only. SFDC: csord__Order.ICCID__c.
     */
    simCardNumber?: string;
    /**
     * Total length of the contract term in months. SFDC: csord__Order_Product__c.Commitment_period__c.
     */
    contractTermMonths?: number;
    /**
     * Unix timestamp of the end date of the contract, if it has fixed term. UTC midnight of the contract term end date. SFDC: csord__Order_Product__c.Commitment_end_date__c.
     */
    fixedTermContractEndDate?: number;
    /**
     * Shipping address concatenated to one string. SFDC: csord__Order.Shipping_Address_Formula__c
     */
    shippingAddress?: string;
    /**
     * Tracking code for the shipped package. SFDC: csord__Order.Tracking_Code__c
     */
    trackingCode?: string;
    deliveryType?: DeliveryType;
    /**
     * Serial number (IMEI or other) of the device to be delivered. SFDC: csord__Order.Serial_Number__c.
     */
    serialNumber?: string;
    taapiTechnology?: TaapiTechnology;
    /**
     * Fixed broadband download speed, displayable value. SFDC: csord__Order.Download_Speed__c.
     */
    downloadSpeed?: string;
    /**
     * Fixed broadband upload speed, displayable value. SFDC: csord__Order.Upload_Speed__c.
     */
    uploadSpeed?: string;
    /**
     * Fixed broadband installation address concatenated in one string. SFDC: csord__Order.Installation_Address_Formula__c.
     */
    installationAddress?: string;
    /**
     * Fixed broadband installation type. SFDC: csord__Order.Installation_Type__c.
     */
    installationType?: DeliveryOrderDetails.InstallationTypeEnum;
    /**
     * Name of the product in context of software products. SFDC: csord__Order.Software_Product_Name__c.
     */
    softwareProductName?: string;
    /**
     * Name of the edition in context of software products. SFDC: csord__Order.Software_Edition_Name__c.
     */
    softwareEditionName?: string;
    /**
     * E-mail address of the administrator of the software product ordered. SFDC: csord__Order.Contact_Email__c.
     */
    adminEmailAddress?: string;
    /**
     * Domain name for Office 365 service, either Elisa-hosted or Microsoft-hosted. SFDC: Service.Domain__c or Service.Verkkotunnus_Domain__c.
     */
    domainName?: string;
    /**
     * Number of licences allowed by the product or edition in this delivery order. SFDC: csord__Service_Line_Item__c.Product_Quantity__c found via csord__Order__c.Root_Service__c.
     */
    licenceCount?: number;
    selectedAddOns?: Array<AddOn>;
    /**
     * An estimate when the product will be delivered as a unix timestamp. SFDC: csord__Order_Product__c.Provison_Planned_Date__c
     */
    estimatedDeliveryDate?: number;
    /**
     * Name of the model of the device
     */
    deviceModel?: string;
    /**
     * Has the device been sent to the recipient SFDC: csord__Subscription__c.Material_Sent__c
     */
    deviceSent?: boolean;
    /**
     * Estimated date when device will be installed as a unix timestamp, SFDC: Required_Delivery_Date_Formula__c
     */
    estimatedInstallationDate?: number;
}
export namespace DeliveryOrderDetails {
    export type InstallationTypeEnum = 'SELF' | 'SERVICE';
    export const InstallationTypeEnum = {
        SELF: 'SELF' as InstallationTypeEnum,
        SERVICE: 'SERVICE' as InstallationTypeEnum
    };
}


