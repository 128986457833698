import { fetchContacts } from '../../../common/fetch.js';
import { loadingContactsMsg, t } from '../../../common/i18n/index.js';
import { useEffect, useState } from 'react';
import { useOpenFormAsync } from '../OpenFormProvider.js';
import type { ContactHeader } from '../../../generated/api/contactHeader.js';

const query = { useSearchService: true } as const;

export const useOpenFormContactHeaders = () => {
  const [data, setData] = useState<ContactHeader[] | undefined>(undefined);
  const async = useOpenFormAsync();

  useEffect(() => {
    const { ctrl } = new async(({ signal }) => fetchContacts(query, { signal }))
      .resolved(({ searchResults }) => setData(searchResults?.map(({ result }) => result)))
      .rejected(text => async.actions.notification({ text, type: 'error' }))
      .cache(query.useSearchService)
      .execute(t.Z77W(loadingContactsMsg));

    return () => ctrl.abort();
  }, [async]);

  return data;
};
