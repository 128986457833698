import { ActionPhase } from '../common/storeUtils.js';
import { ReduxStateEnum } from '../../common/enums.js';
import { TypeKeys } from '../actions/index.js';
import { getOnlineModelFilteringOutNonOfferAddOns, mergeArrays, reduceDisplayItemsLoadAction } from './reducerUtils.js';
import { updateActionStatePhase } from '../common/index.js';
import type { ActionsHistory } from '../common/store.js';
import type { OnlineModel } from '../../generated/api/models.js';
import type { OnlineModelsState } from '../../common/types/states.js';
import type { SelfServiceActionTypes } from '../actions/index.js';

export function onlineModelsReducer(
  state: (OnlineModelsState & ActionsHistory) | null,
  action: SelfServiceActionTypes
): (OnlineModelsState & ActionsHistory) | null {
  if (state === undefined) {
    return null;
  }

  switch (action.type) {
    case TypeKeys.LOAD_ONLINE_MODEL:
    case TypeKeys.LOAD_ONLINE_MODEL_BY_MODEL_TYPE:
    case TypeKeys.LOAD_ONLINE_MODEL_WITH_EFFECTIVE_PRICES: {
      const itemsState = reduceDisplayItemsLoadAction(action, state, 'onlineModelCode', true);
      return {
        ...itemsState,
        state: ReduxStateEnum.IN_PROGRESS,
        loading: true,
      };
    }

    case TypeKeys.LOAD_ONLINE_MODEL_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.LOAD_ONLINE_MODEL,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
        state: ReduxStateEnum.FAILED,
        loading: false,
      };
    }

    case TypeKeys.LOAD_ONLINE_MODEL_FULFILLED: {
      // we need to filter only addons which are linked to offer, currently SF sends only these offers but as part of
      // OFI-48002 SF will send all the addons even which are not linked to offer. So filtering out not-required addons
      // There will be bigger refactoring for change-offer scenarios which would allow to remove the filtering addons
      const filteredModel = getOnlineModelFilteringOutNonOfferAddOns(action.onlineModel);
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.LOAD_ONLINE_MODEL,
          state!,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        items: mergeArrays<OnlineModel>('onlineModelCode', 'lastModified', state!.items, [filteredModel]),
        state: ReduxStateEnum.FULFILLED,
        loading: false,
      };
    }

    // TODO: handle LOAD_ONLINE_MODELS crud Action per category
    case TypeKeys.LOAD_ONLINE_MODELS: {
      return {
        ...state,
        errors: undefined,
      };
    }

    case TypeKeys.LOAD_ONLINE_MODELS_FAILED: {
      return {
        ...state,
        errors: action.errors,
      };
    }

    case TypeKeys.LOAD_ONLINE_MODELS_FULFILLED: {
      if (action.headersOnly) {
        return state;
      }
      return {
        ...state,
        errors: undefined,
        items: mergeArrays<OnlineModel>('onlineModelCode', 'lastModified', state!.items, action.response.models || []),
        loading: false,
      };
    }

    case TypeKeys.RESET_ERRORS: {
      return {
        ...state,
        errors: undefined,
      };
    }

    default: {
      return state;
    }
  }
}
