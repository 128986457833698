import { EmployeeMyOrdersWrapper } from '../../../../../components/EmployeeMyOrdersWrapper/EmployeeMyOrdersWrapper.js';
import { SubscriptionActionDetailsView } from '../../../../../components/SubscriptionActionDetails/SubscriptionActionDetailsView.js';
import { deepEqual } from '../../../../../common/utils/objectUtils.js';
import { findItemById, stripActions } from '../../../../../common/utils/stateUtils.js';
import { loadEmployeeSubscriptionActions } from '../../../../../selfservice/actions/index.js';
import { myOrdersMsg, omaElisaForEmployeeMsg, t } from '../../../../../common/i18n/index.js';
import { paths } from '../../../../../common/constants/pathVariables.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTitle } from '../../../../../common/hooks/useTitle.js';
import type { State } from '../../../../../selfservice/common/store.js';
import type { SubscriptionId } from '../../../../../common/constants/pathInterfaces.js';

export const OwnChangeRequestPath = () => {
  const dispatch = useDispatch();
  const { subscriptionId } = useParams<SubscriptionId>();
  const empSubActions = useSelector(
    (s: State) => stripActions(s.user?.authenticated?.employeeSubscriptionActions),
    deepEqual
  );
  const empSubAction = findItemById('subscriptionActionDisplayId', subscriptionId, empSubActions);

  useTitle([t.H6JV(omaElisaForEmployeeMsg), t.EG6F(myOrdersMsg)]);

  useEffect(() => {
    dispatch(loadEmployeeSubscriptionActions({ displayId: subscriptionId }));
  }, [dispatch, subscriptionId]);

  return (
    <EmployeeMyOrdersWrapper>
      <SubscriptionActionDetailsView
        breadCrumbPaths={[
          { name: t.H6JV(omaElisaForEmployeeMsg), path: paths.EMPLOYEE_HOME },
          { name: t.EG6F(myOrdersMsg) },
        ]}
        subscriptionAction={empSubAction}
        isEmployee={true}
      />
    </EmployeeMyOrdersWrapper>
  );
};
