import * as CL from '@design-system/component-library';
import { Grid } from '../Grid/Grid.js';
import { SubscriptionCategory } from '../../common/enums.js';
import { addYourDeviceMsg, t } from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useNavigate } from 'react-router-dom';

interface MissingSubscriptionInfoProps {
  onClickEmployeeDetails: () => void;
  subscriptionCategory: SubscriptionCategory;
}

const MissingDeviceInfoText = () => {
  const navigate = useNavigate();
  return (
    <>
      <strong>{t.VDQI(`Can't see the device you're using? `)}</strong>
      {` ${t.TZNK(
        `If your employer's administrator has ordered the device on behalf of you, it will not appear on the list. Device must be added separately so you can be confirmed as the user.`
      )} `}
      <CL.Button
        color="link"
        onClick={() => {
          navigate(paths.EMPLOYEE_DEVICE_ADD);
        }}
      >
        {t.W18C(addYourDeviceMsg)}.
      </CL.Button>
    </>
  );
};

const MissingVoiceOrBroadbandInfoText = ({ onClickEmployeeDetails }: { onClickEmployeeDetails: () => void }) => (
  <>
    <strong>{t.H9L5('Can’t find your subscription here?')}</strong>
    {` ${t.ST4J(
      'You can add your phone or internet subscription by entering your work phone number using the buttons above. Both subscriptions must be added separately. If you still do not see your subscription, check that your personal contact information is correct in'
    )} `}
    <CL.Link onClick={() => onClickEmployeeDetails()}>{t.DHHN('your personal account')}</CL.Link>
    {t.ZC46(
      `. If necessary, contact your company's OmaElisa administrator to verify that the user information in your subscription is correct.`
    )}
  </>
);

export const MissingSubscriptionInfo = ({
  onClickEmployeeDetails,
  subscriptionCategory,
}: MissingSubscriptionInfoProps) => (
  <Grid>
    <div className="of-employee-subscriptions__info-subscriptions">
      {subscriptionCategory === SubscriptionCategory.DEVICE ? (
        <MissingDeviceInfoText />
      ) : (
        <MissingVoiceOrBroadbandInfoText onClickEmployeeDetails={onClickEmployeeDetails} />
      )}
    </div>
  </Grid>
);
