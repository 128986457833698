import { TypeKeys, generateActionId } from './actionsUtils.js';
import { convertToCommonErrors } from '../../common/utils/errorUtils.js';
import type { Action } from 'redux';
import type { CrudAction, ErrorAction } from './actionsUtils.js';
import type { GenericError } from '../../generated/api/models.js';

export interface ApplyAnalyticsEventAction extends Action {
  eventData: object;
  type: TypeKeys.APPLY_ANALYTICS_EVENT;
}

export const applyAnalyticsEvent = (eventData: object): ApplyAnalyticsEventAction => ({
  eventData,
  type: TypeKeys.APPLY_ANALYTICS_EVENT,
});

export interface ApplyWizardEventAction extends Action {
  step: number;
  name: string;
  type: TypeKeys.APPLY_WIZARD_EVENT;
}

export const applyWizardEvent = (step: number, name: string): ApplyWizardEventAction => ({
  step,
  name,
  type: TypeKeys.APPLY_WIZARD_EVENT,
});

export interface LogoutAction extends CrudAction<TypeKeys.LOG_OUT> {
  redirectTo: string;
}

export interface LogoutFulfilledAction {
  type: TypeKeys.LOGOUT_FULFILLED;
  redirectTo: string;
}

export type LogoutFailedAction = ErrorAction<TypeKeys.LOGOUT_FAILED>;

export const logOut = (redirectTo = ''): LogoutAction => ({
  id: generateActionId(),
  type: TypeKeys.LOG_OUT,
  redirectTo,
});

export const logoutFulfilled = (redirectTo = ''): LogoutFulfilledAction => ({
  type: TypeKeys.LOGOUT_FULFILLED,
  redirectTo,
});

export const logoutFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): LogoutFailedAction => ({
  errors: convertToCommonErrors(message, status, errors),
  params,
  type: TypeKeys.LOGOUT_FAILED,
});

export interface HardRedirectAction {
  type: TypeKeys.HARD_REDIRECT;
  path: string;
}

export const hardRedirect = (path: string): HardRedirectAction => ({
  path,
  type: TypeKeys.HARD_REDIRECT,
});

export interface ResetErrorsAction {
  type: TypeKeys.RESET_ERRORS;
}

export const resetErrors = (): ResetErrorsAction => ({
  type: TypeKeys.RESET_ERRORS,
});

export type GeneralActionTypes =
  | ApplyAnalyticsEventAction
  | ApplyWizardEventAction
  | LogoutAction
  | LogoutFulfilledAction
  | LogoutFailedAction
  | ResetErrorsAction;
