import { EmployeeSubscriptionPairingScene } from '../../../../../../components/EmployeeSubscriptionPairingScene/EmployeeSubscriptionPairingScene.js';
import { getSubCategoryFromPath } from '../../../../../../common/utils/employeeUtils.js';
import { loadCompanyInfo } from '../../../../../../selfservice/actions/index.js';
import { useAuth } from '../../../../AuthProvider.js';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const SubAddPath = () => {
  const dispatch = useDispatch();
  const { authenticatedUser } = useAuth();
  const { pathname } = useLocation();
  const category = getSubCategoryFromPath(pathname);

  useEffect(() => {
    if (authenticatedUser?.businessId) {
      dispatch(loadCompanyInfo());
    }
  }, [dispatch, authenticatedUser?.businessId]);

  return <EmployeeSubscriptionPairingScene mdmId={authenticatedUser?.mdmId || ''} category={category} />;
};
