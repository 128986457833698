import { CompanyInfo } from '../../../../../components/CompanyInfo/CompanyInfo.js';
import {
  CompanyInfoContainer,
  CompanyInfoSceneTab,
} from '../../../../../components/CompanyInfoScene/CompanyInfoScene.js';
import { useLoaderData } from 'react-router-dom';
import type { CompanyInfoResponse } from '../../../../../generated/api/companyInfoResponse';

export const CompanyInfoHomePath = () => {
  const companyInfos = useLoaderData() as CompanyInfoResponse[];
  return (
    <CompanyInfoContainer tab={CompanyInfoSceneTab.BASIC}>
      <CompanyInfo companyInfos={companyInfos} />
    </CompanyInfoContainer>
  );
};
