import { type MutableRefObject } from 'react';
import { type OpenFormActions, type OpenFormState } from './OpenFormReducer.js';
import { type OpenFormStorage } from './OpenFormStorage.js';

export class OpenFormAsync<Resolved = unknown> {
  static readonly cache = new Map<string, Promise<unknown>>();
  static actions: OpenFormActions;
  static storage: OpenFormStorage;
  private static ref: MutableRefObject<OpenFormState>;

  private readonly promise: () => Promise<void | Resolved>;
  private resolve?: (value?: Resolved) => void | Resolved;
  private reject?: (reason?: Response) => void;
  private settle?: (result?: Resolved) => void;

  private fulfilled?: Promise<void | Resolved> | Resolved;
  private id: string;
  private save = false;
  private text?: string;

  constructor(
    callback: (self: OpenFormAsync) => Promise<void | Resolved>,
    readonly ctrl = new AbortController(),
    readonly signal = ctrl.signal
  ) {
    this.id = String(callback);
    this.promise = () => {
      this.text && OpenFormAsync.actions.loading({ id: this.id, text: this.text });
      return (this.fulfilled = callback(this)
        .then(this.resolved().resolve)
        .catch(this.rejected().reject)
        .finally(this.settled().settle)) as Promise<void | Resolved>;
    };
  }

  static get state(): OpenFormState {
    return this.ref.current;
  }

  static set state(ref: MutableRefObject<OpenFormState>) {
    this.ref = ref;
  }

  readonly evict = () => this.save && OpenFormAsync.cache.delete(this.id);

  cache(...key: (boolean | null | number | string)[]) {
    this.id += String(key.length && key);
    this.save = true;
    return this;
  }

  execute(text?: string) {
    this.text = text;
    this.save
      ? OpenFormAsync.cache.has(this.id)
        ? OpenFormAsync.cache.get(this.id)!.then(this.resolve)
        : OpenFormAsync.cache.set(this.id, this.promise())
      : this.promise();
    return this;
  }

  resolved(resolve?: (value: Resolved) => void) {
    this.resolve ??= (value?: Resolved) => {
      resolve && !this.signal.aborted && value !== undefined && resolve(value);
      return this.fulfilled ? (this.fulfilled = value) : value;
    };
    return this;
  }

  rejected(reject?: (reason: string) => void) {
    this.reject ??= (reason?: Response) => {
      reject && !this.signal.aborted && (reason?.text?.().then(reject) || reject(String(reason)));
      this.evict();
    };
    return this;
  }

  settled(settle?: (result?: Resolved) => void) {
    this.settle ??= () => {
      settle?.(this.fulfilled as Resolved) || delete this.fulfilled;
      this.text && OpenFormAsync.actions.loading({ id: this.id, text: undefined });
    };
    return this;
  }
}
