import { TypeKeys } from '../actions/index.js';
import type { ActionsHistory } from '../common/store.js';
import type { ReportsState } from '../../common/types/states.js';
import type { SelfServiceActionTypes } from '../actions/index.js';

export function reportsReducer(
  state: (ReportsState & ActionsHistory) | undefined,
  action: SelfServiceActionTypes
): (ReportsState & ActionsHistory) | null {
  if (typeof state === 'undefined') {
    return null;
  }

  switch (action.type) {
    case TypeKeys.POLL_FOR_MYSELF_CHANGES_FULFILLED: {
      if (action.reportStatusChange) {
        return {
          ...state,
          latestReportStatusChange: action.reportStatusChange,
        };
      }
      return state;
    }

    default:
      return state;
  }
}
