import { EMAIL_REGEX } from '../../utils/emailUtils.js';
import { InputComponent } from '../components/InputComponent.js';
import { emailMsg, fieldCantBeEmptyMsg, invalidValueMsg, t } from '../../i18n/index.js';
import type { OptionalInputComponentProps } from '../components/InputComponent.js';

export const Email = (props: OptionalInputComponentProps) => {
  const {
    name = 'email',
    label = t.ZVQ5(emailMsg),
    placeholder = t.ZVQ5(emailMsg),
    required = true,
    disabled = false,
    hint,
    readonly,
  } = props;
  const validate = (value: string) => {
    if (!value) {
      if (required) {
        return t.VPVR(fieldCantBeEmptyMsg);
      }
    } else if (!EMAIL_REGEX.test(value)) {
      return t.DUPA(invalidValueMsg);
    }
    return undefined;
  };
  return (
    <InputComponent
      validate={validate}
      name={name}
      type="text"
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      required={required}
      hint={hint}
      readonly={readonly}
      {...props}
    />
  );
};
