/**
 * Online UI API
 * # Summary Elisa B2B Online UI API  # Usage This API is used in the `https://yrityksille.elisa.fi/` pages.  # Identifiers 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SubscriptionActionHeader { 
    /**
     * Internal, unique id of the subscription action, or mass order line item. SFDC: Non_MACD_Subscription_Change_Request__c.Id OR Mass_Order_Line_Item__c.Id
     */
    subscriptionActionId: string;
    /**
     * User-readable id of the subscription action, or mass order line item. SFDC: Non_MACD_Subscription_Change_Request__c.Name OR Mass_Order_Line_Item__c.Name.
     */
    subscriptionActionDisplayId: string;
    /**
     * Subscription name shown to the user. SFDC: csord__Subscription__c.Name
     */
    subscriptionName?: string;
    /**
     * SFDC: Subscription__r.Serial_Number__c
     */
    subscriptionSerialNumber?: string;
    /**
     * Display ID (SUBnnnnnnnnn) of the subscription related to this change Request. SFDC: csord__Subscription__c.csordtelcoa_Subscription_Number__c
     */
    subscriptionDisplayId?: string;
    /**
     * Contact that this subscription is assigned to. SFDC: csord__Subscription__c.User_Contact__c
     */
    subscriptionContactName?: string;
    /**
     * Type of subscription change. OTHER value is used for internally initiated actions. SFDC: Non_MACD_Subscription_Change_Request__c.RecordTypeId.
     */
    subscriptionActionType?: SubscriptionActionHeader.SubscriptionActionTypeEnum;
    /**
     * SFDC: Employee_Contact__r.Id
     */
    requestCreatorContactId?: string;
    /**
     * SFDC: Approver_Contact__r.Id
     */
    requesterContactId?: string;
    /**
     * SFDC: Approver_Contact__r.Name
     */
    requesterContactName?: string;
    /**
     * SFDC: Subscription__r.csord__Account__r.MDM_Id__c
     */
    accountMasterId?: string;
    /**
     * Status of this subscription change request. SFDC: Non_MACD_Subscription_Change_Request__c.Status__c
     */
    status: SubscriptionActionHeader.StatusEnum;
    /**
     * Unix timestamp in milliseconds of creation. SFDC: Non_MACD_Subscription_Change_Request__c.CreatedDate
     */
    created: number;
    /**
     * Unix timestamp of last modification. SFDC: Mass_Order_Line_Item__r.LastModifiedDate OR Mass_Order_Line_Item__r.Subscription_Change_Request__r.LastModifiedDate
     */
    lastModified?: number;
}
export namespace SubscriptionActionHeader {
    export type SubscriptionActionTypeEnum = 'CANCEL' | 'CHANGE_ADDRESS' | 'CHANGE_BILLING_ACCOUNT' | 'CHANGE_CONTACTS' | 'CHANGE_DEVICE' | 'CHANGE_FEATURES' | 'CHANGE_MOBILE_PBX' | 'CHANGE_OFFER' | 'CHANGE_SIM' | 'CHANGE_USER_INFORMATION' | 'EPP_REDEEM_TERMINATE' | 'OTHER' | 'RESUME' | 'SUSPEND' | 'TERMINATE_DEVICE_SERVICE' | 'TERMINATE_SUBSCRIPTION' | 'CHANGE_MOBILE_PBX_SOLUTION';
    export const SubscriptionActionTypeEnum = {
        CANCEL: 'CANCEL' as SubscriptionActionTypeEnum,
        CHANGE_ADDRESS: 'CHANGE_ADDRESS' as SubscriptionActionTypeEnum,
        CHANGE_BILLING_ACCOUNT: 'CHANGE_BILLING_ACCOUNT' as SubscriptionActionTypeEnum,
        CHANGE_CONTACTS: 'CHANGE_CONTACTS' as SubscriptionActionTypeEnum,
        CHANGE_DEVICE: 'CHANGE_DEVICE' as SubscriptionActionTypeEnum,
        CHANGE_FEATURES: 'CHANGE_FEATURES' as SubscriptionActionTypeEnum,
        CHANGE_MOBILE_PBX: 'CHANGE_MOBILE_PBX' as SubscriptionActionTypeEnum,
        CHANGE_OFFER: 'CHANGE_OFFER' as SubscriptionActionTypeEnum,
        CHANGE_SIM: 'CHANGE_SIM' as SubscriptionActionTypeEnum,
        CHANGE_USER_INFORMATION: 'CHANGE_USER_INFORMATION' as SubscriptionActionTypeEnum,
        EPP_REDEEM_TERMINATE: 'EPP_REDEEM_TERMINATE' as SubscriptionActionTypeEnum,
        OTHER: 'OTHER' as SubscriptionActionTypeEnum,
        RESUME: 'RESUME' as SubscriptionActionTypeEnum,
        SUSPEND: 'SUSPEND' as SubscriptionActionTypeEnum,
        TERMINATE_DEVICE_SERVICE: 'TERMINATE_DEVICE_SERVICE' as SubscriptionActionTypeEnum,
        TERMINATE_SUBSCRIPTION: 'TERMINATE_SUBSCRIPTION' as SubscriptionActionTypeEnum,
        CHANGE_MOBILE_PBX_SOLUTION: 'CHANGE_MOBILE_PBX_SOLUTION' as SubscriptionActionTypeEnum
    };
    export type StatusEnum = 'PENDING_APPROVAL' | 'PENDING' | 'COMPLETED' | 'CANCELLED' | 'ERROR';
    export const StatusEnum = {
        PENDING_APPROVAL: 'PENDING_APPROVAL' as StatusEnum,
        PENDING: 'PENDING' as StatusEnum,
        COMPLETED: 'COMPLETED' as StatusEnum,
        CANCELLED: 'CANCELLED' as StatusEnum,
        ERROR: 'ERROR' as StatusEnum
    };
}


