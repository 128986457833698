import * as CL from '@design-system/component-library';
import { type Choices } from '../../OpenFormAnswers.js';
import { DatePicker } from '../../../../common/react-hook-form/components/DatePicker.js';
import { OpenFormGridCol, OpenFormGridRow } from '../../OpenFormComponents/OpenFormGrid.js';
import { addDays, isWeekend } from 'date-fns';
import { getValue } from '../../OpenFormUtils.js';
import { useOpenFormPublicHolidays } from '../../OpenFormHooks/useOpenFormPublicHolidays.js';
import classNames from 'classnames';

const getBlockedDatesWithOffset = (holidays: Date[] | undefined, offset: number) => {
  const blockedDates = holidays ?? [];
  let date = new Date();
  let amountOfBlocked = 0;

  // We add `offset` amount of weekdays into the blockedDates array
  while (amountOfBlocked < offset) {
    if (!isWeekend(date)) {
      blockedDates.push(date);
      amountOfBlocked++;
    }

    date = addDays(date, 1);
  }

  return blockedDates;
};

export const OpenFormQuestionDate = ({
  disabled,
  required,
  label,
  name,
  offset = 0,
  selected,
}: {
  disabled: boolean;
  required: boolean;
  label: string;
  name: string;
  offset?: number;
  selected: Choices | undefined;
}) => {
  const holidays = useOpenFormPublicHolidays();
  const blockedDates = offset > 0 ? getBlockedDatesWithOffset(holidays, offset) : holidays;

  return (
    <OpenFormGridRow>
      <OpenFormGridCol colWidth={6} className={classNames({ ['label--mandatory']: required })}>
        {disabled ? (
          <CL.Input disabled={disabled} value={getValue(selected)} />
        ) : (
          <DatePicker
            blockedDates={blockedDates}
            defaultValue={getValue(selected)}
            disableAllPastDays={true}
            disablePreviousMonths={true}
            disableWeekends={true}
            label={label}
            name={name}
            required={required}
            optionalText=""
          />
        )}
      </OpenFormGridCol>
    </OpenFormGridRow>
  );
};
