import { Dropdown } from '../../../../components/Dropdown/Dropdown.js';
import { Field, useFormikContext } from 'formik';
import { getLanguageOptions } from '../../../../common/utils/billingAccountUtils.js';
import { languageMsg, selectMsg, t } from '../../../i18n/index.js';
import { requiredValidation } from '../../validation.js';
import { useAuth } from '../../../../public/site/AuthProvider.js';
import type { FormikValues } from 'formik';

export interface LanguageDropdownProps {
  name: string;
  label?: string;
}

export const LanguageDropdown = ({ name, label }: LanguageDropdownProps) => {
  const { authenticatedUser } = useAuth();
  const { setFieldValue, values, errors } = useFormikContext<FormikValues>();

  const selectLanguage = (evt: HTMLLIElement) => {
    const newValue = evt.dataset.value;
    if (newValue) {
      setFieldValue(name, newValue);
    }
  };

  return (
    <Field
      name={name}
      id={name}
      label={label || t.L3C5(languageMsg)}
      items={getLanguageOptions()}
      selectedValue={values[name] || authenticatedUser?.preferredLanguage}
      i18n_dropdown_errorMessage={errors[name]}
      i18n_dropdown_placeholderText={t.QRYV(selectMsg)}
      validate={requiredValidation}
      onValueChange={selectLanguage}
      component={Dropdown}
    />
  );
};
