import { TypeKeys, generateActionId } from './actionsUtils.js';
import { convertToCommonErrors } from '../../common/utils/errorUtils.js';
import type { Action } from 'redux';
import type { BillingAccount, Contact, GenericError } from '../../generated/api/models.js';
import type { CommonError } from '../../common/types/errors.js';
import type { ConfiguredOffer } from '../../common/types/commercialProduct.js';
import type { CrudAction, ErrorAction, ErrorActionCreator } from './actionsUtils.js';
import type { EcommerceItem } from '../../common/analytics.js';
import type { FixedBroadbandOrder } from '../../components/FixedBroadbandCheckout/FixedBroadbandSupportCaseUtils.js';

export interface SubmitFixedBroadbandOrderParams {
  fixedBroadbandOrder: FixedBroadbandOrder;
  newBillingAccount?: BillingAccount;
  newBillingAccountCommonFunction?: Contact;
  newBillingAccountValidationErrors?: CommonError[];
  overridePreviousAction?: boolean;
  configuredOffer: ConfiguredOffer;
  eCommerceEventProducts: EcommerceItem[];
}

export interface SubmitFixedBroadbandOrderAction
  extends CrudAction<TypeKeys.SUBMIT_FIXED_BROADBAND_ORDER>,
    SubmitFixedBroadbandOrderParams {}

export const submitFixedBroadbandOrder = (
  params: SubmitFixedBroadbandOrderParams
): SubmitFixedBroadbandOrderAction => ({
  id: generateActionId(),
  type: TypeKeys.SUBMIT_FIXED_BROADBAND_ORDER,
  ...params,
});

export type SubmitFixedBroadbandOrderFailedAction = ErrorAction<TypeKeys.SUBMIT_FIXED_BROADBAND_ORDER_FAILED>;

export const submitFixedBroadbandOrderFailed: ErrorActionCreator<TypeKeys.SUBMIT_FIXED_BROADBAND_ORDER_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): SubmitFixedBroadbandOrderFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    params,
    type: TypeKeys.SUBMIT_FIXED_BROADBAND_ORDER_FAILED,
  };
};

export interface SubmitFixedBroadbandOrderFulfilledAction extends Action {
  type: TypeKeys.SUBMIT_FIXED_BROADBAND_ORDER_FULFILLED;
}

export const submitFixedBroadbandOrderFulfilled = (): SubmitFixedBroadbandOrderFulfilledAction => {
  return {
    type: TypeKeys.SUBMIT_FIXED_BROADBAND_ORDER_FULFILLED,
  };
};

export type FixedBroadbandActionTypes =
  | SubmitFixedBroadbandOrderAction
  | SubmitFixedBroadbandOrderFailedAction
  | SubmitFixedBroadbandOrderFulfilledAction;
