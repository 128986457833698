import { ButtonGroupForSubmitAndBack } from '../ButtonGroupForSubmitAndBack/ButtonGroupForSubmitAndBack.js';
import { backMsg, nextMsg, t } from '../../common/i18n/index.js';
import classNames from 'classnames';

import './WizardActions.scss';

export interface WizardActionsProps {
  classes?: string[];
  isLoading?: boolean;
  submitting?: boolean;
  forwardButtonText?: string;
  forwardButtonWideOnPhone?: boolean;
  backButtonText?: string;
  onBackClick: () => void;
  onForwardClick: () => void;
}

export const WizardActions = ({
  classes,
  isLoading,
  submitting,
  forwardButtonText,
  forwardButtonWideOnPhone,
  backButtonText,
  onBackClick,
  onForwardClick,
}: WizardActionsProps) => {
  return (
    <div
      className={classNames(
        'ea-wizard-actions',
        classes,
        forwardButtonWideOnPhone ? 'ea-wizard-actions--forward-wide-on-phone' : undefined
      )}
    >
      <ButtonGroupForSubmitAndBack
        onSubmit={onForwardClick}
        onCancel={onBackClick}
        submitButtonText={forwardButtonText || t.F0MY(nextMsg)}
        cancelButtonText={backButtonText || t.D1E9(backMsg)}
        submitDisabled={isLoading}
        cancelDisabled={isLoading}
        submitting={submitting}
      />
    </div>
  );
};
