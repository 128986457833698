import * as CL from '@design-system/component-library';
import { DeviceChangeOption } from '../../../common/enums.js';
import { submitPunchoutOrder } from '../../../selfservice/actions/punchoutActions.js';
import { t } from '../../../common/i18n/index.js';
import { useDispatch, useSelector } from 'react-redux';
import type {
  DeliveryAddress,
  OnlineModelCategory,
  PunchoutOrder,
  PunchoutOrderItem,
} from '../../../generated/api/models.js';
import type { DeviceChangeRequest } from '../../../common/types/device.js';
import type { ShoppingCartItemForCheckout } from '../../../common/types/checkout.js';
import type { State } from '../../../selfservice/common/store.js';

const buildPunchoutOrderItems = (
  cartItems: ShoppingCartItemForCheckout[],
  deviceChangeRequest?: DeviceChangeRequest
): PunchoutOrderItem[] => {
  return cartItems.map(cartItem => {
    const getDeviceChangeAttribute = (cpCode: string) =>
      deviceChangeRequest?.deviceChangeOption === DeviceChangeOption.RETURN &&
      cpCode === deviceChangeRequest.replacementDeviceCommercialProductCode
        ? {
            replacedSubscriptionId: deviceChangeRequest.replacedSubscriptionId || '',
            changeType: deviceChangeRequest.deviceChangeOption,
          }
        : undefined;

    const punchoutOrderItem: PunchoutOrderItem = {
      commercialProductCode: cartItem.commercialProductCodes[0],
      offerCode: cartItem.offerCode,
      commercialProductName: cartItem.productName,
      category: cartItem.category as OnlineModelCategory,
      orderItemQuantity: cartItem.quantity,
      price: {
        oneTimeCharge: cartItem.productPrice.onetime?.price || 0,
        monthlyRecurringCharge: cartItem.productPrice.periodic?.price || 0,
        payments: cartItem.productPrice.periodic?.payments || 0,
      },
      employeePrice: {
        oneTimeCharge: cartItem.price.onetime?.price || 0,
        monthlyRecurringCharge: cartItem.price.periodic?.price || 0,
        payments: cartItem.price.periodic?.payments || 0,
      },
      addOns: cartItem.selectedAddOns.map(addOn => ({
        addOnAssociationCode: addOn.addOnAssociationCode,
        addOnCode: addOn.addOnCode,
      })),
      deviceChange: getDeviceChangeAttribute(cartItem.commercialProductCodes[0]),
    };

    return punchoutOrderItem;
  });
};

const buildPunchoutOrder = (
  cartItems: ShoppingCartItemForCheckout[],
  catalogCode: string,
  personBillingAccountId: string | undefined,
  deliveryAddress: DeliveryAddress | undefined,
  deviceChangeRequest?: DeviceChangeRequest
): PunchoutOrder => {
  const punchoutOrderItems = buildPunchoutOrderItems(cartItems, deviceChangeRequest);

  const punchoutRedeem =
    deviceChangeRequest?.deviceChangeOption === DeviceChangeOption.REDEEM
      ? {
          subscriptionId: deviceChangeRequest.subscriptionDisplayId || '',
          employeeRedeemPrice: {
            oneTimeCharge: deviceChangeRequest.deviceChangeRedeemPrice,
            monthlyRecurringCharge: 0,
            payments: 0,
          },
        }
      : undefined;

  const punchoutOrder: PunchoutOrder = {
    catalogCode,
    orderItems: punchoutOrderItems,
    personBillingAccountId,
    deliveryAddress,
    punchoutRequestId: '',
    punchoutRedeem,
  };

  return punchoutOrder;
};

type PunchoutButtonProps = {
  cartItems: ShoppingCartItemForCheckout[];
  catalogCode: string;
  personBillingAccountId?: string;
  className?: string;
  deliveryAddress?: DeliveryAddress;
  deviceChangeRequest?: DeviceChangeRequest;
};

export const PunchoutButton = ({
  cartItems,
  catalogCode,
  personBillingAccountId,
  className,
  deliveryAddress,
  deviceChangeRequest,
}: PunchoutButtonProps) => {
  const dispatch = useDispatch();
  const submitInProgress = useSelector((state: State) => state.selfservice?.punchoutOrders?.saving);

  const punchoutOrder = buildPunchoutOrder(
    cartItems,
    catalogCode,
    personBillingAccountId,
    deliveryAddress,
    deviceChangeRequest
  );

  return (
    <div className={`of-punchout-button ${className || ''}`}>
      <CL.Button
        className="of-confirm-button"
        loading={submitInProgress}
        onClick={() => dispatch(submitPunchoutOrder(punchoutOrder))}
        size="l"
      >
        {t.UGH3('Proceed to confirmation')}
      </CL.Button>
    </div>
  );
};
