import { Dropdown } from '../../../../components/Dropdown/Dropdown.js';
import { Field, useFormikContext } from 'formik';
import { deliveryMethodMsg, selectMsg, t } from '../../../i18n/index.js';
import { requiredValidation } from '../../validation.js';
import type * as CLT from '@design-system/component-library';
import type { FormikValues } from 'formik';

export interface DeliveryMethodDropdownProps {
  deliveryMethodOptions: CLT.DropDownItem[];
  setDeliveryType: (deliveryType: string) => void;
}

const deliveryMethodFieldName = 'deliveryMethod';

export const DeliveryMethodDropdown = ({ deliveryMethodOptions, setDeliveryType }: DeliveryMethodDropdownProps) => {
  const { setFieldValue, values } = useFormikContext<FormikValues>();

  const setDeliveryMethod = (evt: HTMLLIElement) => {
    const newValue = evt.dataset.value;
    if (newValue) {
      setFieldValue(deliveryMethodFieldName, newValue);
      setDeliveryType(newValue);
    }
  };

  return (
    <Field
      name={deliveryMethodFieldName}
      id={deliveryMethodFieldName}
      label={t.G0QN(deliveryMethodMsg)}
      items={deliveryMethodOptions}
      selectedValue={values.deliveryMethod}
      i18n_dropdown_placeholderText={t.QRYV(selectMsg)}
      onValueChange={setDeliveryMethod}
      validate={requiredValidation}
      component={Dropdown}
    />
  );
};
