import * as CL from '@design-system/component-library';
import { cancelMsg, confirmMsg, t } from '../../../common/i18n/index.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { useFormContext } from 'react-hook-form';

export interface SaveOrCancelProps {
  onCancel: () => void;
  isSaving: boolean;
}

export const SaveOrCancel = ({ onCancel, isSaving }: SaveOrCancelProps) => {
  const { reset } = useFormContext();

  return (
    <CL.Grid className={dsClass.MARGIN_TOP_6}>
      <CL.GridRow className="of-purpose-of-use__grid_row of-usernumber-and-publicity__save_cancel">
        <CL.Button className={dsClass.MARGIN_RIGHT_2} loading={isSaving} type="submit">
          {t.QVYK(confirmMsg)}
        </CL.Button>
        <CL.Button
          color="light"
          type="button"
          onClick={() => {
            reset();
            onCancel();
          }}
        >
          {t.B2V1(cancelMsg)}
        </CL.Button>
      </CL.GridRow>
    </CL.Grid>
  );
};
