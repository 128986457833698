import { AuthorizationScene } from '../../../../components/AuthorizationScene/AuthorizationScene.js';
import { getAuthorizationRequest } from '../../../../selfservice/actions/index.js';
import { useAuth } from '../../AuthProvider.js';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import type { Token } from '../../../../common/constants/pathInterfaces.js';

export const AuthorizeNumberPortingPath = () => {
  const dispatch = useDispatch();
  const { token = '' } = useParams<Token>();
  const { authorizationRequest } = useAuth();

  useEffect(() => {
    dispatch(getAuthorizationRequest(token));
  }, [dispatch, token]);

  return <AuthorizationScene authorizationRequest={authorizationRequest!} />;
};
