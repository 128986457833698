import { ActionPhase } from '../common/storeUtils.js';
import { TypeKeys } from '../actions/index.js';
import { reduceCrudAction } from './reducerUtils.js';
import { updateActionStatePhase } from '../common/index.js';
import type { ActionsHistory } from '../common/store.js';
import type { SelfServiceActionTypes } from '../actions/index.js';
import type { SubscriptionAddOnRulesState } from '../../common/types/states.js';

export const subscriptionAddOnRulesReducer = (
  state: (SubscriptionAddOnRulesState & ActionsHistory) | undefined,
  action: SelfServiceActionTypes
) => {
  if (typeof state === 'undefined') {
    return null;
  }

  switch (action.type) {
    case TypeKeys.LOAD_SUBSCRIPTION_ADD_ON_RULES: {
      // Load add-on-rules only when they are not defined or they are empty
      if (
        state === null ||
        ((state.associations === undefined || state.associations.length === 0) &&
          (state.dependencies === undefined || state.dependencies.length === 0))
      ) {
        return {
          ...state,
          ...reduceCrudAction(action, state),
        };
      }
      return state;
    }

    case TypeKeys.LOAD_SUBSCRIPTION_ADD_ON_RULES_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.LOAD_SUBSCRIPTION_ADD_ON_RULES,
          state,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        associations: (action.associations && [...action.associations]) || [],
        dependencies: (action.dependencies && [...action.dependencies]) || [],
      };
    }

    case TypeKeys.LOAD_SUBSCRIPTION_ADD_ON_RULES_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.LOAD_SUBSCRIPTION_ADD_ON_RULES,
          state,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
      };
    }

    case TypeKeys.LOAD_SUBSCRIPTION_ADD_ON_RULES_MOBILE_PBX: {
      // Load add-on-rules only when they are not defined or they are empty
      if (
        state === null ||
        ((state.associationsMobilePbx === undefined || state.associationsMobilePbx.length === 0) &&
          (state.dependenciesMobilePbx === undefined || state.dependenciesMobilePbx.length === 0))
      ) {
        return {
          ...state,
          ...reduceCrudAction(action, state),
        };
      }
      return state;
    }

    case TypeKeys.LOAD_SUBSCRIPTION_ADD_ON_RULES_MOBILE_PBX_FULFILLED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.LOAD_SUBSCRIPTION_ADD_ON_RULES_MOBILE_PBX,
          state,
          ActionPhase.IN_PROGRESS,
          ActionPhase.SUCCESS
        ),
        associationsMobilePbx: (action.associationsMobilePbx && [...action.associationsMobilePbx]) || [],
        dependenciesMobilePbx: (action.dependenciesMobilePbx && [...action.dependenciesMobilePbx]) || [],
      };
    }

    case TypeKeys.LOAD_SUBSCRIPTION_ADD_ON_RULES_MOBILE_PBX_FAILED: {
      return {
        ...state,
        actions: updateActionStatePhase(
          TypeKeys.LOAD_SUBSCRIPTION_ADD_ON_RULES_MOBILE_PBX,
          state,
          ActionPhase.IN_PROGRESS,
          ActionPhase.FAILED
        ),
        errors: action.errors,
      };
    }

    default:
      return state;
  }
};
