import type { AddOn } from '../../../generated/api/addOn.js';
import type { EntityPrice } from '../../../generated/api/entityPrice.js';

function getMockPrice(): EntityPrice {
  return {
    listPrice: {
      oneTimeCharge: 1234,
      monthlyRecurringCharge: 2345,
    },
    effectivePrice: {
      oneTimeCharge: 3456,
      monthlyRecurringCharge: 4567,
    },
  };
}

export const asennusPalveluAddOn: AddOn = Object.freeze({
  addOnGroup: 'Asennuspalvelu',
  addOnType: 'SERVICE',
  addOnCode: '83cda4e8-7131-737a-924d-db1d082019d2',
  addOnProductName: 'Online asennuspalvelu',
  price: getMockPrice(),
});

export const fixedIpAddOn: AddOn = Object.freeze({
  addOnType: 'SERVICE',
  addOnGroup: 'Kiinteä IP - Vain MOR-tiimi',
  addOnCode: '59f7d519-13f9-95b3-41bc-ac21f2049288',
  addOnProductName: 'Kiinteä julkinen IP-osoite IPv4',
  price: getMockPrice(),
});

export const publicIpAddOn: AddOn = Object.freeze({
  addOnType: 'SERVICE',
  addOnGroup: 'Public Dynamic IP',
  addOnCode: '8bce627a-83b4-d62f-f429-be64b57edf36',
  addOnProductName: 'Julkinen IP-osoite',
  price: getMockPrice(),
});

export const addOnRules = [
  {
    addOnAssociationCode: 'c9269263-6388-639c-bb7c-298226355f85',
    addOnCode: '83cda4e8-7131-737a-924d-db1d082019d2',
    addOnGroup: 'Asennuspalvelu',
    addOnId: 'a205r000000ZxwtAAC',
    addOnProductName: 'Online asennuspalvelu',
    commercialProductCode: '0f25d245-01d6-0ed9-9292-424945c48d45',
    price: getMockPrice(),
    addOn: {
      addOnType: 'SERVICE',
      addOnGroup: 'Asennuspalvelu',
      addOnCode: '83cda4e8-7131-737a-924d-db1d082019d2',
      addOnProductName: 'Online asennuspalvelu',
      price: getMockPrice(),
    },
  },
  {
    addOnAssociationCode: '99c0bf15-0135-5efe-648b-96dcbb922442',
    addOnCode: 'fe31a18a-9934-0505-4df0-ace57c65d37a',
    addOnGroup: 'kertamaksu',
    addOnId: 'a205r000000YDRwAAO',
    addOnProductName: 'Avaus- / kertamaksu',
    commercialProductCode: '0f25d245-01d6-0ed9-9292-424945c48d45',
    price: getMockPrice(),
    addOn: {
      addOnType: 'SERVICE',
      addOnGroup: 'kertamaksu',
      addOnCode: 'fe31a18a-9934-0505-4df0-ace57c65d37a',
      addOnProductName: 'Avaus- / kertamaksu',
      price: getMockPrice(),
    },
  },
  {
    addOnAssociationCode: '3097855a-fedb-a357-c210-11f2a712eb5f',
    addOnCode: 'd7a5cf1a-d277-31a2-4134-4bca335c6dad',
    addOnGroup: 'Toimivuus SLA',
    addOnId: 'a205r000000YDSDAA4',
    addOnProductName: 'Elisa Netti Lite SLA P0V8h',
    commercialProductCode: '73427915-25d8-a5e3-3d3b-124599f1267e',
    price: getMockPrice(),
    addOn: {
      addOnType: 'SERVICE',
      addOnGroup: 'Toimivuus SLA',
      addOnCode: 'd7a5cf1a-d277-31a2-4134-4bca335c6dad',
      addOnProductName: 'Elisa Netti Lite SLA P0V8h',
      price: getMockPrice(),
    },
  },
  {
    addOnAssociationCode: '6c2177ea-c747-1489-5811-e5f547adba6d',
    addOnCode: '9c955e38-f561-7fa2-3f6c-f94e763f9fcc',
    addOnGroup: 'Roaming',
    addOnId: 'a205r000000YCnpAAG',
    addOnProductName: 'Liittymän käyttö vain Suomen alueella',
    commercialProductCode: '8fba8e86-170d-3478-ffbb-25da7209f836',
    price: getMockPrice(),
    addOn: {
      addOnType: 'SERVICE',
      addOnGroup: 'Roaming',
      addOnCode: '9c955e38-f561-7fa2-3f6c-f94e763f9fcc',
      addOnProductName: 'Liittymän käyttö vain Suomen alueella',
      price: getMockPrice(),
    },
  },
  {
    addOnAssociationCode: 'dd306368-b6c8-492e-a1ae-4032a838005c',
    addOnCode: 'b5e6f490-0b41-a56c-f3aa-a482caf5dc97',
    addOnGroup: 'Päätelaite',
    addOnId: 'a205r000000YDSJAA4',
    addOnProductName: 'Huawei 5G CPE Pro (kunnostettu)',
    commercialProductCode: '0f25d245-01d6-0ed9-9292-424945c48d45',
    price: getMockPrice(),
    addOn: {
      addOnType: 'SERVICE',
      addOnGroup: 'Päätelaite',
      addOnCode: 'b5e6f490-0b41-a56c-f3aa-a482caf5dc97',
      addOnProductName: 'Huawei 5G CPE Pro (kunnostettu)',
      price: getMockPrice(),
    },
  },
  {
    addOnAssociationCode: 'd978ff8f-48c6-4730-bf71-081e2a2e073d',
    addOnCode: 'a1c6bf23-0eaa-48ec-bf10-fda89c7de84d',
    addOnGroup: 'Kiinteä IP - Vain MOR-tiimi',
    addOnId: 'a205r000000YCoZAAW',
    addOnProductName: 'Kiinteä julkinen IP-osoite IPv6',
    commercialProductCode: '0f25d245-01d6-0ed9-9292-424945c48d45',
    price: getMockPrice(),
    addOn: {
      addOnType: 'SERVICE',
      addOnGroup: 'Kiinteä IP - Vain MOR-tiimi',
      addOnCode: 'a1c6bf23-0eaa-48ec-bf10-fda89c7de84d',
      addOnProductName: 'Kiinteä julkinen IP-osoite IPv6',
      price: getMockPrice(),
    },
  },
  {
    addOnAssociationCode: '104357cd-9371-1d67-cde0-c64743886878',
    addOnCode: '33-b8d6-bcd0-7851-a3a15e47e7f0',
    addOnGroup: 'Luotonhallinnan estot',
    addOnId: 'a205r000000YCoBAAW',
    addOnProductName: 'Luotonhallinnan lähtevän liikenteen esto',
    commercialProductCode: '0f25d245-01d6-0ed9-9292-424945c48d45',
    price: getMockPrice(),
    addOn: {
      addOnType: 'SERVICE',
      addOnGroup: 'Luotonhallinnan estot',
      addOnCode: '33-b8d6-bcd0-7851-a3a15e47e7f0',
      addOnProductName: 'Luotonhallinnan lähtevän liikenteen esto',
      price: getMockPrice(),
    },
  },
  {
    addOnAssociationCode: '9719c3a8-87dc-4de6-2564-2d9dea876b00',
    addOnCode: '59f7d519-13f9-95b3-41bc-ac21f2049288',
    addOnGroup: 'Kiinteä IP - Vain MOR-tiimi',
    addOnId: 'a205r000000YCpKAAW',
    addOnProductName: 'Kiinteä julkinen IP-osoite IPv4',
    commercialProductCode: '0f25d245-01d6-0ed9-9292-424945c48d45',
    price: getMockPrice(),
    addOn: {
      addOnType: 'SERVICE',
      addOnGroup: 'Kiinteä IP - Vain MOR-tiimi',
      addOnCode: '59f7d519-13f9-95b3-41bc-ac21f2049288',
      addOnProductName: 'Kiinteä julkinen IP-osoite IPv4',
      price: getMockPrice(),
    },
  },
  {
    addOnAssociationCode: '841c5217-6369-888a-8fbf-d63cad306821',
    addOnCode: 'b74b4311-c99e-b0a7-fef5-e14ebaf51abb',
    addOnGroup: 'Internet',
    addOnId: 'a205r000000YDSaAAO',
    addOnProductName: 'Elisa Netti Lite 5G (1000M)',
    commercialProductCode: '0f25d245-01d6-0ed9-9292-424945c48d45',
    price: getMockPrice(),
    addOn: {
      addOnType: 'SERVICE',
      addOnGroup: 'Internet',
      addOnCode: 'b74b4311-c99e-b0a7-fef5-e14ebaf51abb',
      addOnProductName: 'Elisa Netti Lite 5G (1000M)',
      price: getMockPrice(),
    },
  },
  {
    addOnAssociationCode: 'e35e4ae4-1d06-65b1-019d-cde6adb969bf',
    addOnCode: '683197609-c294-9fda-2a79-611919bbda17',
    addOnGroup: 'Luotonhallinnan estot',
    addOnId: 'a205r000000YCo6AAG',
    addOnProductName: 'Luotonhallinnan esto 1 (U)',
    commercialProductCode: '0f25d245-01d6-0ed9-9292-424945c48d45',
    price: getMockPrice(),
    addOn: {
      addOnType: 'SERVICE',
      addOnGroup: 'Luotonhallinnan estot',
      addOnCode: '683197609-c294-9fda-2a79-611919bbda17',
      addOnProductName: 'Luotonhallinnan esto 1 (U)',
      price: getMockPrice(),
    },
  },
  {
    addOnAssociationCode: '768b9316-79d2-edbd-a270-793b54b28d13',
    addOnCode: 'fd46fbf4-f6e3-f793-88a9-46160ccafc11',
    addOnGroup: 'Päätelaite',
    addOnId: 'a205r000000YDSEAA4',
    addOnProductName: 'ZTE MC801A1',
    commercialProductCode: '0f25d245-01d6-0ed9-9292-424945c48d45',
    price: getMockPrice(),
    addOn: {
      addOnType: 'SERVICE',
      addOnGroup: 'Päätelaite',
      addOnCode: 'fd46fbf4-f6e3-f793-88a9-46160ccafc11',
      addOnProductName: 'ZTE MC801A1',
      price: getMockPrice(),
    },
  },
  {
    addOnAssociationCode: 'a633cffa-7e31-98cb-93ac-0c0cfb2db51f',
    addOnCode: 'e0c4841f-2bb1-c306-00a2-a915fcf859c7',
    addOnGroup: 'Lähtevien operaattoriestot',
    addOnId: 'a205r000000YCpaAAG',
    addOnProductName: 'Kaikkien lähtevien tekstiviestien esto (BAOC)',
    commercialProductCode: '0f25d245-01d6-0ed9-9292-424945c48d45',
    price: getMockPrice(),
    addOn: {
      addOnType: 'SERVICE',
      addOnGroup: 'Lähtevien operaattoriestot',
      addOnCode: 'e0c4841f-2bb1-c306-00a2-a915fcf859c7',
      addOnProductName: 'Kaikkien lähtevien tekstiviestien esto (BAOC)',
      price: getMockPrice(),
    },
  },
  {
    addOnAssociationCode: '8cda0ff5-0970-d131-11df-ab38a7a802df',
    addOnCode: '322e5bc0-b323-5e3d-1b8f-ca0c1bcb2927',
    addOnGroup: 'Asennuspalvelu',
    addOnId: 'a205r000000YCqNAAW',
    addOnProductName: 'Ilmainen asennuspalvelu',
    commercialProductCode: '0f25d245-01d6-0ed9-9292-424945c48d45',
    price: getMockPrice(),
    addOn: {
      addOnType: 'SERVICE',
      addOnGroup: 'Asennuspalvelu',
      addOnCode: '322e5bc0-b323-5e3d-1b8f-ca0c1bcb2927',
      addOnProductName: 'Ilmainen asennuspalvelu',
      price: getMockPrice(),
    },
  },
  {
    addOnAssociationCode: '4c52d658-147e-10a2-2756-b75d7dc3616b',
    addOnCode: '8bce627a-83b4-d62f-f429-be64b57edf36',
    addOnGroup: 'Public Dynamic IP',
    addOnId: 'a205r000000YCp6AAG',
    addOnProductName: 'Julkinen IP-osoite',
    commercialProductCode: '0f25d245-01d6-0ed9-9292-424945c48d45',
    price: getMockPrice(),
    addOn: {
      addOnType: 'SERVICE',
      addOnGroup: 'Public Dynamic IP',
      addOnCode: '8bce627a-83b4-d62f-f429-be64b57edf36',
      addOnProductName: 'Julkinen IP-osoite',
      price: getMockPrice(),
    },
  },
  {
    addOnAssociationCode: '8bad410f-bd44-f8ee-c85b-d5b3f01993bd',
    addOnCode: '6d411b4e-cd62-751b-e842-f6611e4e1725',
    addOnGroup: 'Asennuspalvelu',
    addOnId: 'a205r000000YCqQAAW',
    addOnProductName: 'Maksullinen asennuspalvelu',
    commercialProductCode: '0f25d245-01d6-0ed9-9292-424945c48d45',
    price: getMockPrice(),
    addOn: {
      addOnType: 'SERVICE',
      addOnGroup: 'Asennuspalvelu',
      addOnCode: '6d411b4e-cd62-751b-e842-f6611e4e1725',
      addOnProductName: 'Maksullinen asennuspalvelu',
      price: getMockPrice(),
    },
  },
];

export const addOnVisibilitiesWithCommercialProductCodes = [
  {
    commercialProductCode: '0f25d245-01d6-0ed9-9292-424945c48d45',
    addOnVisibilities: [
      {
        addOnGuidCode: 'a1c6bf23-0eaa-48ec-bf10-fda89c7de84d',
        selfServiceEditable: true,
      },
      {
        addOnGuidCode: '83cda4e8-7131-737a-924d-db1d082019d2',
        selfServiceEditable: true,
      },
      {
        addOnGuidCode: '59f7d519-13f9-95b3-41bc-ac21f2049288',
        selfServiceEditable: true,
      },
      {
        addOnGuidCode: '8bce627a-83b4-d62f-f429-be64b57edf36',
        selfServiceEditable: true,
      },
    ],
  },
];

export const expectedCommercialProductAddOnVisibilitiesMap = Object.freeze({
  ['0f25d245-01d6-0ed9-9292-424945c48d45']: {
    ['Asennuspalvelu']: [
      {
        addOnType: 'SERVICE',
        addOnGroup: 'Asennuspalvelu',
        addOnCode: '83cda4e8-7131-737a-924d-db1d082019d2',
        addOnProductName: 'Online asennuspalvelu',
        price: getMockPrice(),
      },
    ],
    ['IP-osoite']: [
      {
        addOnType: 'SERVICE',
        addOnGroup: 'Public Dynamic IP',
        addOnCode: '8bce627a-83b4-d62f-f429-be64b57edf36',
        addOnProductName: 'Julkinen IP-osoite',
        price: getMockPrice(),
      },
      {
        addOnType: 'SERVICE',
        addOnGroup: 'Kiinteä IP - Vain MOR-tiimi',
        addOnCode: '59f7d519-13f9-95b3-41bc-ac21f2049288',
        addOnProductName: 'Kiinteä julkinen IP-osoite IPv4',
        price: getMockPrice(),
      },
      {
        addOnType: 'SERVICE',
        addOnGroup: 'Kiinteä IP - Vain MOR-tiimi',
        addOnCode: 'a1c6bf23-0eaa-48ec-bf10-fda89c7de84d',
        addOnProductName: 'Kiinteä julkinen IP-osoite IPv6',
        price: getMockPrice(),
      },
    ],
  },
});
