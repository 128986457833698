import { SiteContext } from '../../../public/site/SiteContext.js';
import { blockCallsToForeignNumbersMsg, t, viewBlockedNumbersMsg } from '../../../common/i18n/index.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import { useContext } from 'react';

export const CallAbroadBarringTooltipBody = () => (
  <>
    <h4 className="ea-h4 ea-h4--no-fluid">{t.RYY6(blockCallsToForeignNumbersMsg)}</h4>
    {t.KRTP('Blocks calls to numbers abroad and foreign directory enquiry and connection service numbers.')}
    <div className={dsClass.PADDING_VERTICAL_4}>
      <a
        className="of-barrings-content__link"
        href={`${useContext(SiteContext).siteBaseUrl}/ohje/lisamaksulliset-palvelut-ja-estoluokat`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t.FVU9(viewBlockedNumbersMsg)}
        <i className="ea-icon ea-icon--northeast-arrow" />
      </a>
    </div>
  </>
);
