import * as CL from '@design-system/component-library';
import {
  accordingToTheBrandMsg,
  cheapestFirstMsg,
  mostExpensiveFirstMsg,
  newestFirstMsg,
  t,
} from '../../common/i18n/index.js';
import type { ProductGridItem } from './ProductGrid.js';

type SortOrders = { [key in SortType]: SortOrder };

export enum SortType {
  ORDER_NEWEST = 'ORDER_NEWEST',
  ORDER_ALPHABETICAL = 'ORDER_ALPHABETICAL',
  ORDER_CHEAPEST = 'ORDER_CHEAPEST',
  ORDER_COSTLIEST = 'ORDER_COSTLIEST',
}

type SortOrder = {
  displayText: () => string;
  sortFn: (x: ProductGridItem, y: ProductGridItem) => number;
};

const sortByTotalPrice = (x: ProductGridItem, y: ProductGridItem) => {
  const xP = x.oneTimeCharge || (x.monthlyRecurringCharge || 0) * (x.payments || 0);
  const yP = y.oneTimeCharge || (y.monthlyRecurringCharge || 0) * (y.payments || 0);
  return xP - yP;
};

const sortOrders: SortOrders = {
  ORDER_NEWEST: {
    displayText: () => t.Z6SX(newestFirstMsg),
    sortFn: (x: ProductGridItem, y: ProductGridItem) => y.created - x.created,
  },
  ORDER_ALPHABETICAL: {
    displayText: () => t.VLDA(accordingToTheBrandMsg),
    sortFn: (x: ProductGridItem, y: ProductGridItem) =>
      `${x.manufacturer}${x.name}`.localeCompare(`${y.manufacturer}${y.name}`),
  },
  ORDER_CHEAPEST: {
    displayText: () => t.MA8D(cheapestFirstMsg),
    sortFn: (x: ProductGridItem, y: ProductGridItem) => sortByTotalPrice(x, y),
  },
  ORDER_COSTLIEST: {
    displayText: () => t.FVI5(mostExpensiveFirstMsg),
    sortFn: (x: ProductGridItem, y: ProductGridItem) => sortByTotalPrice(y, x),
  },
};

export const sortFn = (sortType: SortType) => sortOrders[sortType].sortFn;

const sortOrderOptions = () =>
  Object.entries(sortOrders).map(entry => ({ value: entry[0], label: entry[1].displayText() }));

export const ProductGridSort = ({
  onChange,
  selectedValue,
}: {
  onChange: (newValue: SortType) => void;
  selectedValue?: SortType;
}) => (
  <CL.Dropdown
    className="of-product-grid__sort-dropdown"
    items={sortOrderOptions()}
    selectedValue={selectedValue ?? SortType.ORDER_NEWEST}
    onValueChange={listElement => {
      onChange(listElement.getAttribute('data-value') as SortType);
    }}
  />
);
