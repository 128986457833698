import * as CL from '@design-system/component-library';
import { AuxiliaryEsimOrderPhaseEnum } from '../auxiliaryEsimOrderEnums.js';
import { auxiliaryEsimProductPriceMsg, confirmMsg, confirmOrderMsg, t } from '../../../common/i18n/index.js';
import { deepEqual } from '../../../common/utils/objectUtils.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import {
  pollForMyselfChanges,
  sendAuxiliaryEsimOrder,
  setAuxiliaryEsimOrderPhase,
} from '../../../selfservice/actions/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import type { State } from '../../../selfservice/common/store.js';

export const AuxiliaryEsimOrderProductDetails = () => {
  const dispatch = useDispatch();
  const subscription = useSelector((state: State) => state.selfservice?.auxiliaryEsimOrder?.subscription, deepEqual);
  const [isTermsOfAgreementAccepted, setTermsOfAgreementAccepted] = useState(false);

  const phoneNumber = subscription?.details?.mobile?.mobileNumber || '';
  const fullName = subscription?.subscriptionContactName || '';

  // NOTE: Hardcoded some values for now that suit all languages, the KelloSIM product should be fetched from CRM?
  return (
    <div className="ea-color-container ea-color-container--stretch">
      <h3 className={dsClass.H3}>{t.RZU4(confirmOrderMsg)}</h3>
      <CL.Description
        columns={1}
        items={[
          {
            title: t.TTFH('Subscription name and number'),
            description: (
              <>
                {subscription?.subscriptionName}
                <br />
                {phoneNumber}
              </>
            ),
          },
          {
            title: t.HM82('User of subscription'),
            description: fullName,
          },
        ]}
      />
      <hr className={`${dsClass.BORDER_BOTTOM_WIDTH_1} ${dsClass.BORDER_TOP_WIDTH_0}`} />
      <CL.Description
        columns={1}
        items={[
          {
            title: t.XPAQ('Chosen additional service'),
            description: (
              <>
                <span>{t.KF0J('Elisa KelloSIM')}</span>
                <br />
                <span>
                  <b>{t.FKVB(auxiliaryEsimProductPriceMsg)}</b>
                  {t.GDH2(', the contract is valid until further notice')}
                </span>
              </>
            ),
          },
        ]}
      />
      <div className={dsClass.PADDING_VERTICAL_2}>
        <CL.Checkbox
          id="termsOfAgreementCheckbox"
          checked={isTermsOfAgreementAccepted}
          key={String(isTermsOfAgreementAccepted)}
          onChange={() => setTermsOfAgreementAccepted(!isTermsOfAgreementAccepted)}
          name="termsOfAgreementCheckbox"
          label={t.FIRO('I accept the terms of agreement')}
        ></CL.Checkbox>
        <span className={dsClass.TEXT_S}>
          {t.TSLW('The terms and conditions of the main subscription apply to the service.')}
        </span>
        <CL.Button
          block
          className="of-next-button"
          disabled={!isTermsOfAgreementAccepted}
          onClick={() => {
            dispatch(setAuxiliaryEsimOrderPhase(AuxiliaryEsimOrderPhaseEnum.PROGRESS));
            dispatch(sendAuxiliaryEsimOrder());
            dispatch(pollForMyselfChanges());
          }}
          size="m"
        >
          {t.F0MY(confirmMsg).toUpperCase()}
        </CL.Button>
      </div>
    </div>
  );
};
