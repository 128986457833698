import { FiveGVoiceOfferCode, SubscriptionCardType } from '../../../common/enums';
import { PuhePopoverContent } from './PuhePopoverContent';
import { SUBSCRIPTION_CHANGE_SERVICE_FEE } from './SubscriptionCardContent';
import {
  euDataMsg,
  finlandNordicsAndBalticsMsg,
  gBMoMsg,
  mBpsMsg,
  pcsMsg,
  selectMsg,
  t,
  textMessagesMsg,
  unlimitedMsg,
  uploadSpeedMsg,
  voiceMsg,
} from '../../../common/i18n';
import {
  getMatkapuhelinvastaajaService,
  getMobiiliturvaService,
  getReissaajaService,
  getReissunetti1Service,
  getReissunetti2Service,
} from './ServiceContentCreator';
import type { SubscriptionCardProps } from '../../SubscriptionCard/SubscriptionCard';

const voiceSubscriptionCards = (inPublicStore?: boolean): Omit<SubscriptionCardProps, 'name'>[] => [
  {
    description: t.CVSQ('Efficient remote work, sending and receiving large files, streaming.'),
    cardName: 'premium',
    mainHeader: 'Premium 1000 M',
    monthlyPrice: 4895,
    oneTimePrice: 899,
    recommended: false,
    selectedOffer: FiveGVoiceOfferCode.Voice5gPlus1000Mbps,
    offers: [
      {
        guid: FiveGVoiceOfferCode.Voice5gPlus1000Mbps,
        icon: 'speed-meter-XL',
        salesProduct: {
          oldNumberOfferCode: 'e7da2162-1308-7491-e612-c4a4a34495db',
          onlineModelCode: '49c6bdfb-8e6d-3794-2228-009b1a08b987',
          newNumberOfferCode: 'ae2c6088-4d6b-5f18-30b4-f92c5148795c',
        },
        text: t.IJNS(mBpsMsg, '1000'),
      },
    ],
    specifications: [
      {
        name: t.M4JX(finlandNordicsAndBalticsMsg),
        value: { [FiveGVoiceOfferCode.Voice5gPlus1000Mbps]: t.MVTD(unlimitedMsg) },
      },
      {
        name: euDataMsg,
        value: { [FiveGVoiceOfferCode.Voice5gPlus1000Mbps]: `70 ${t.U54O(gBMoMsg)}` },
      },
      {
        name: t.CQQ7(uploadSpeedMsg),
        value: { [FiveGVoiceOfferCode.Voice5gPlus1000Mbps]: t.IJNS(mBpsMsg, '100') },
      },
      {
        name: t.PV8D(voiceMsg),
        value: { [FiveGVoiceOfferCode.Voice5gPlus1000Mbps]: '5000 min' },
      },
      {
        name: t.MXR5(textMessagesMsg),
        value: { [FiveGVoiceOfferCode.Voice5gPlus1000Mbps]: `500 ${t.B3MG(pcsMsg)}` },
      },
    ],
    services: [
      getMatkapuhelinvastaajaService(true, inPublicStore),
      getMobiiliturvaService(true, inPublicStore),
      getReissunetti2Service(true),
    ],
  },
  {
    description: t.SE2D('Office environments, heavier data transmission and 4K videos'),
    cardName: 'perus600',
    mainHeader: 'Perus 600 M',
    monthlyPrice: 4095,
    oneTimePrice: 899,
    recommended: false,
    selectedOffer: FiveGVoiceOfferCode.Voice5gPlus600Mbps,
    offers: [
      {
        guid: FiveGVoiceOfferCode.Voice5gPlus600Mbps,
        icon: 'speed-meter-L',
        salesProduct: {
          oldNumberOfferCode: 'eb6ef5b3-4128-b02b-611c-961513b04e46',
          onlineModelCode: '413e6376-a3de-7c24-6037-d1462365d92d',
          newNumberOfferCode: '8d121d67-4d4f-11db-abf9-68bf252dfd07',
        },
        text: t.IJNS(mBpsMsg, '600'),
      },
    ],
    specifications: [
      {
        name: t.M4JX(finlandNordicsAndBalticsMsg),
        value: {
          [FiveGVoiceOfferCode.Voice5gPlus600Mbps]: t.MVTD(unlimitedMsg),
        },
      },
      {
        name: euDataMsg,
        value: {
          [FiveGVoiceOfferCode.Voice5gPlus600Mbps]: `58 ${t.U54O(gBMoMsg)}`,
        },
      },
      {
        name: t.CQQ7(uploadSpeedMsg),
        value: {
          [FiveGVoiceOfferCode.Voice5gPlus600Mbps]: t.IJNS(mBpsMsg, '75'),
        },
      },
      {
        name: t.PV8D(voiceMsg),
        value: {
          [FiveGVoiceOfferCode.Voice5gPlus600Mbps]: '5000 min',
        },
      },
      {
        name: t.MXR5(textMessagesMsg),
        value: {
          [FiveGVoiceOfferCode.Voice5gPlus600Mbps]: `200 ${t.B3MG(pcsMsg)}`,
        },
      },
    ],
    services: [
      getMatkapuhelinvastaajaService(true, inPublicStore),
      getReissunetti1Service(true),
      getMobiiliturvaService(false, inPublicStore),
    ],
  },
  {
    description: t.SE2C('Active surfing, video meetings and browser-based tools'),
    cardName: 'perus300',
    mainHeader: 'Perus 300 M',
    monthlyPrice: 3799,
    oneTimePrice: 899,
    recommended: true,
    selectedOffer: FiveGVoiceOfferCode.Voice5gPlus300Mbps,
    offers: [
      {
        guid: FiveGVoiceOfferCode.Voice5gPlus300Mbps,
        icon: 'speed-meter-M',
        salesProduct: {
          oldNumberOfferCode: '6ec53da5-29f1-c446-efec-59a18da8cb8f',
          onlineModelCode: 'a4931975-489f-667c-d798-369736c05fa9',
          newNumberOfferCode: '16e5c287-fd8d-8312-2957-c19f3f47572c',
        },
        text: t.IJNS(mBpsMsg, '300'),
      },
    ],
    specifications: [
      {
        name: t.M4JX(finlandNordicsAndBalticsMsg),
        value: {
          [FiveGVoiceOfferCode.Voice5gPlus300Mbps]: t.MVTD(unlimitedMsg),
        },
      },
      {
        name: euDataMsg,
        value: {
          [FiveGVoiceOfferCode.Voice5gPlus300Mbps]: `55,5 ${t.U54O(gBMoMsg)}`,
        },
      },
      {
        name: t.CQQ7(uploadSpeedMsg),
        value: {
          [FiveGVoiceOfferCode.Voice5gPlus300Mbps]: t.IJNS(mBpsMsg, '50'),
        },
      },
      {
        name: t.PV8D(voiceMsg),
        value: {
          [FiveGVoiceOfferCode.Voice5gPlus300Mbps]: '5000 min',
        },
      },
      {
        name: t.MXR5(textMessagesMsg),
        value: {
          [FiveGVoiceOfferCode.Voice5gPlus300Mbps]: `200 ${t.B3MG(pcsMsg)}`,
        },
      },
    ],
    services: [
      getMatkapuhelinvastaajaService(true, inPublicStore),
      getReissunetti1Service(true),
      getMobiiliturvaService(false, inPublicStore),
    ],
  },
  {
    description: t.KVG8('Video calls, cloud services use and light remote working'),
    cardName: 'mini50',
    mainHeader: 'Mini 50 M',
    monthlyPrice: 2895,
    oneTimePrice: 899,
    pricePreamble: t.OD1L('From'),
    selectedOffer: FiveGVoiceOfferCode.Voice50Mbps,
    offers: [
      {
        guid: FiveGVoiceOfferCode.Voice50Mbps,
        icon: 'speed-meter-S',
        salesProduct: {
          oldNumberOfferCode: '93085047-604c-cc8a-228a-863b23ee3e5e',
          onlineModelCode: '34d8ba65-2c65-3440-996c-909be24d2520',
          newNumberOfferCode: 'f0ea75cf-4bf6-d2f4-d444-575c663d6d22',
        },
        text: t.IJNS(mBpsMsg, '50'),
      },
    ],
    specifications: [
      {
        name: t.M4JX(finlandNordicsAndBalticsMsg),
        value: {
          [FiveGVoiceOfferCode.Voice50Mbps]: t.MVTD(unlimitedMsg),
        },
      },
      {
        name: euDataMsg,
        value: {
          [FiveGVoiceOfferCode.Voice50Mbps]: `38 ${t.U54O(gBMoMsg)}`,
        },
      },
      {
        name: t.CQQ7(uploadSpeedMsg),
        value: {
          [FiveGVoiceOfferCode.Voice50Mbps]: t.IJNS(mBpsMsg, '25'),
        },
      },
      {
        name: `${t.PV8D(voiceMsg)}**`,
        value: {
          [FiveGVoiceOfferCode.Voice50Mbps]: '100 min',
        },
        popoverContent: <PuhePopoverContent />,
      },
      {
        name: t.MXR5(textMessagesMsg),
        value: {
          [FiveGVoiceOfferCode.Voice50Mbps]: `100 ${t.B3MG(pcsMsg)}`,
        },
      },
    ],
    services: [
      getReissaajaService(true),
      getMatkapuhelinvastaajaService(false, inPublicStore),
      getMobiiliturvaService(false, inPublicStore),
    ],
  },
  {
    description: t.KVG9('Light internet use, reading emails, sending messages'),
    cardName: 'mini10',
    mainHeader: 'Mini 10 M',
    monthlyPrice: 2895,
    oneTimePrice: 899,
    pricePreamble: t.OD1L('From'),
    selectedOffer: FiveGVoiceOfferCode.Voice10Mbps,
    offers: [
      {
        guid: FiveGVoiceOfferCode.Voice10Mbps,
        icon: 'speed-meter-XS',
        salesProduct: {
          oldNumberOfferCode: '8831a2e6-d8dc-791c-c915-2002ae27d2b3',
          onlineModelCode: 'd9bd1acc-ecc4-c601-3c61-d81165e49c51',
          newNumberOfferCode: 'dc631fd4-375a-63bf-5cb5-38b52ce9e119',
        },
        text: t.IJNS(mBpsMsg, '10'),
      },
    ],
    specifications: [
      {
        name: t.M4JX(finlandNordicsAndBalticsMsg),
        value: {
          [FiveGVoiceOfferCode.Voice10Mbps]: t.MVTD(unlimitedMsg),
        },
      },
      {
        name: euDataMsg,
        value: {
          [FiveGVoiceOfferCode.Voice10Mbps]: `35 ${t.U54O(gBMoMsg)}`,
        },
      },
      {
        name: t.CQQ7(uploadSpeedMsg),
        value: {
          [FiveGVoiceOfferCode.Voice10Mbps]: t.IJNS(mBpsMsg, '5'),
        },
      },
      {
        name: `${t.PV8D(voiceMsg)}**`,
        value: {
          [FiveGVoiceOfferCode.Voice10Mbps]: '100 min',
        },
        popoverContent: <PuhePopoverContent />,
      },
      {
        name: t.MXR5(textMessagesMsg),
        value: {
          [FiveGVoiceOfferCode.Voice10Mbps]: `100 ${t.B3MG(pcsMsg)}`,
        },
      },
    ],
    services: [
      getReissaajaService(true),
      getMatkapuhelinvastaajaService(false, inPublicStore),
      getMobiiliturvaService(false, inPublicStore),
    ],
  },
];

export const getVoiceSubscriptionCardContents = (
  inPublicStore?: boolean,
  isChangeOffer?: boolean
): SubscriptionCardProps[] =>
  voiceSubscriptionCards(inPublicStore).map(cardContent => ({
    ...cardContent,
    serviceFee: isChangeOffer ? SUBSCRIPTION_CHANGE_SERVICE_FEE : undefined,
    buttonText: t.QRYV(selectMsg),
    cardType: SubscriptionCardType.VOICE,
  }));
