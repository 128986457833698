import { BillingAccountStatus } from '../../generated/api/models.js';
import { TypeKeys, generateActionId } from './actionsUtils.js';
import { convertToCommonErrors } from '../../common/utils/errorUtils.js';
import type {
  BillingAccount,
  BillingAccountSearchResponse,
  BillingAccountsResponse,
  GenericError,
  PutBillingAccountResponse,
  Subscription,
  SubscriptionsResponse,
} from '../../generated/api/models.js';
import type { ChangeSubscriptionBillingAccountWizardParams, WizardParams } from '../../common/types/wizard.js';
import type { CommonError } from '../../common/types/errors.js';
import type { CompositeListSort } from '../../components/CompositeListHeader/CompositeListHeader.js';
import type { CrudAction, DisplayItemsLoadAction, ErrorAction, ErrorActionCreator } from './actionsUtils.js';
import type { ItemsQuery } from '../common/store.js';
import type { UpsertPersonBillingAccountRequest } from '../../common/utils/billingAccountUtils.js';

/**
 * BillingAccounts.
 */

export interface LoadBillingAccountsAction
  extends DisplayItemsLoadAction<TypeKeys.LOAD_BILLING_ACCOUNTS, BillingAccount> {
  getAllItems?: boolean;
  sourceSystem?: string;
  forceLoad?: boolean;
  billingContactId?: string;
  offset?: number;
  limit?: number;
}

export const loadBillingAccounts = (
  displayId?: string,
  sort?: CompositeListSort,
  search?: string,
  getAllItems?: boolean,
  sourceSystem?: string,
  forceLoad?: boolean,
  billingContactId?: string
): LoadBillingAccountsAction => {
  return {
    displayId,
    getAllItems,
    id: generateActionId(),
    search,
    billingContactId,
    sort,
    sourceSystem,
    forceLoad,
    reporting: false,
    offset: undefined,
    limit: undefined,
    type: TypeKeys.LOAD_BILLING_ACCOUNTS,
  };
};

export interface LoadBillingAccountsFulfilledAction {
  billingAccounts?: BillingAccount[];
  searchResults?: BillingAccountSearchResponse[];
  useSearchService?: boolean;
  mergeHeaders?: boolean;
  total: number;
  type: TypeKeys.LOAD_BILLING_ACCOUNTS_FULFILLED;
  billingContactId?: string;
}

export const loadBillingAccountsFulfilled = (
  response: BillingAccountsResponse,
  billingContactId?: string,
  useSearchService?: boolean,
  mergeHeaders?: boolean
): LoadBillingAccountsFulfilledAction => {
  return {
    billingAccounts: response.billingAccounts,
    searchResults: response.searchResults,
    useSearchService,
    mergeHeaders,
    total: response.total,
    type: TypeKeys.LOAD_BILLING_ACCOUNTS_FULFILLED,
    billingContactId,
  };
};

export type LoadBillingAccountsFailedAction = ErrorAction<TypeKeys.LOAD_BILLING_ACCOUNTS_FAILED>;

export const loadBillingAccountsFailed: ErrorActionCreator<TypeKeys.LOAD_BILLING_ACCOUNTS_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[]
): LoadBillingAccountsFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    type: TypeKeys.LOAD_BILLING_ACCOUNTS_FAILED,
  };
};

export interface EditBillingAccountAction {
  editingSection: string;
  redirectFromUrl?: string;
  redirectToUrl?: string;
  type: TypeKeys.EDIT_BILLING_ACCOUNT;
}

export const editBillingAccount = (
  editingSection: string,
  redirectToUrl?: string,
  redirectFromUrl?: string
): EditBillingAccountAction => ({
  editingSection,
  redirectFromUrl,
  redirectToUrl,
  type: TypeKeys.EDIT_BILLING_ACCOUNT,
});

export interface UpsertBillingAccountAction extends CrudAction<TypeKeys.UPSERT_BILLING_ACCOUNT> {
  billingAccount: BillingAccount;
  orderActionId?: number;
  validationErrors?: CommonError[];
  wizardParams?: ChangeSubscriptionBillingAccountWizardParams;
  editedSection?: string;
}

export const upsertBillingAccount = (
  billingAccount: BillingAccount,
  validationErrors?: CommonError[],
  wizardParams?: ChangeSubscriptionBillingAccountWizardParams,
  orderActionId?: number,
  editedSection?: string
): UpsertBillingAccountAction => {
  return {
    billingAccount,
    id: generateActionId(),
    orderActionId,
    type: TypeKeys.UPSERT_BILLING_ACCOUNT,
    validationErrors,
    wizardParams,
    editedSection,
  };
};

export interface UpsertBillingAccountFulfilledAction {
  type: TypeKeys.UPSERT_BILLING_ACCOUNT_FULFILLED;
  billingAccount: BillingAccount;
  isCreate: boolean;
  wizardParams?: WizardParams;
  editedSection?: string;
}

export type UpsertBillingAccountFailedAction = ErrorAction<TypeKeys.UPSERT_BILLING_ACCOUNT_FAILED>;

export const upsertBillingAccountFulfilled = (
  billingAccount: BillingAccount,
  response: PutBillingAccountResponse,
  isCreate: boolean,
  wizardParams?: WizardParams,
  editedSection?: string
): UpsertBillingAccountFulfilledAction => ({
  billingAccount: {
    billingAccountStatus: BillingAccountStatus.OPEN,
    sourceSystem: 'SFDC',
    ...billingAccount,
    ...response,
  },
  isCreate,
  type: TypeKeys.UPSERT_BILLING_ACCOUNT_FULFILLED,
  wizardParams,
  editedSection,
});

export interface UpsertBillingAccountScheduledChangeFulfilled {
  type: TypeKeys.UPSERT_BILLING_ACCOUNT_SCHEDULED_CHANGE_FULFILLED;
}

export const upsertBillingAccountScheduledChangeFulfilled = (): UpsertBillingAccountScheduledChangeFulfilled => ({
  type: TypeKeys.UPSERT_BILLING_ACCOUNT_SCHEDULED_CHANGE_FULFILLED,
});

export interface UpsertBillingAccountScheduledChangeFailed {
  type: TypeKeys.UPSERT_BILLING_ACCOUNT_SCHEDULED_CHANGE_FAILED;
}

export const upsertBillingAccountScheduledChangeFailed = (): UpsertBillingAccountScheduledChangeFailed => ({
  type: TypeKeys.UPSERT_BILLING_ACCOUNT_SCHEDULED_CHANGE_FAILED,
});

export const upsertBillingAccountFailed: ErrorActionCreator<TypeKeys.UPSERT_BILLING_ACCOUNT_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[]
): UpsertBillingAccountFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    type: TypeKeys.UPSERT_BILLING_ACCOUNT_FAILED,
  };
};

export interface CancelBillingAccountEdit {
  type: TypeKeys.CANCEL_BILLING_ACCOUNT_EDIT;
}

export const cancelBillingAccountEdit = (): CancelBillingAccountEdit => ({
  type: TypeKeys.CANCEL_BILLING_ACCOUNT_EDIT,
});

export interface UpsertPersonBillingAccountAction extends CrudAction<TypeKeys.UPSERT_PERSON_BILLING_ACCOUNT> {
  request: UpsertPersonBillingAccountRequest;
  billingAccountId?: string;
  validationErrors?: CommonError[];
  successAction?: () => void;
}

export const upsertPersonBillingAccount = (
  request: UpsertPersonBillingAccountRequest,
  billingAccountId?: string,
  validationErrors?: CommonError[],
  successAction?: () => void
): UpsertPersonBillingAccountAction => {
  return {
    billingAccountId,
    request,
    successAction,
    validationErrors,
    id: generateActionId(),
    type: TypeKeys.UPSERT_PERSON_BILLING_ACCOUNT,
  };
};

export interface UpsertPersonBillingAccountFulfilledAction {
  billingContactId?: string;
  isCreate: boolean;
  payerName?: string;
  request: UpsertPersonBillingAccountRequest;
  response: PutBillingAccountResponse;
  successAction?: () => void;
  type: TypeKeys.UPSERT_PERSON_BILLING_ACCOUNT_FULFILLED;
}

export const upsertPersonBillingAccountFulfilled = (
  request: UpsertPersonBillingAccountRequest,
  response: PutBillingAccountResponse,
  isCreate: boolean,
  billingContactId?: string,
  payerName?: string,
  successAction?: () => void
): UpsertPersonBillingAccountFulfilledAction => ({
  request,
  response,
  isCreate,
  billingContactId,
  payerName,
  successAction,
  type: TypeKeys.UPSERT_PERSON_BILLING_ACCOUNT_FULFILLED,
});

export type UpsertPersonBillingAccountFailedAction = ErrorAction<TypeKeys.UPSERT_PERSON_BILLING_ACCOUNT_FAILED>;

export const upsertPersonBillingAccountFailed: ErrorActionCreator<TypeKeys.UPSERT_PERSON_BILLING_ACCOUNT_FAILED> = (
  message: string,
  status: number,
  errors?: GenericError[]
): UpsertPersonBillingAccountFailedAction => {
  return {
    errors: convertToCommonErrors(message, status, errors),
    type: TypeKeys.UPSERT_PERSON_BILLING_ACCOUNT_FAILED,
  };
};

export interface LoadBillingAccountSubscriptionsAction
  extends DisplayItemsLoadAction<TypeKeys.LOAD_BILLING_ACCOUNT_SUBSCRIPTIONS, Subscription> {
  billingAccountId: string;
  getAllItems?: boolean;
  limit?: number;
  offset?: number;
}

export const loadBillingAccountSubscriptions = (
  action: Omit<LoadBillingAccountSubscriptionsAction, 'id' | 'type'>
): LoadBillingAccountSubscriptionsAction => {
  return {
    ...action,
    id: generateActionId(),
    type: TypeKeys.LOAD_BILLING_ACCOUNT_SUBSCRIPTIONS,
  };
};

export interface LoadBillingAccountSubscriptionsFulfilledAction {
  type: TypeKeys.LOAD_BILLING_ACCOUNT_SUBSCRIPTIONS_FULLFILLED;
  billingAccountId: string;
  query: ItemsQuery;
  getAllItems?: boolean;
  totalSubscriptions: number;
  subscriptions?: Subscription[];
}

export const loadBillingAccountSubscriptionsFulfilled = (
  { subscriptions, total }: SubscriptionsResponse,
  billingAccountId: string,
  query: ItemsQuery,
  getAllItems?: boolean
): LoadBillingAccountSubscriptionsFulfilledAction => ({
  subscriptions,
  totalSubscriptions: total,
  type: TypeKeys.LOAD_BILLING_ACCOUNT_SUBSCRIPTIONS_FULLFILLED,
  query,
  billingAccountId,
  getAllItems,
});

export type LoadBillingAccountSubscriptionsFailedAction =
  ErrorAction<TypeKeys.LOAD_BILLING_ACCOUNT_SUBSCRIPTIONS_FAILED>;

export const loadBillingAccountSubscriptionsFailed = (
  message: string,
  status: number,
  errors?: GenericError[],
  params?: { [s: string]: string }
): LoadBillingAccountSubscriptionsFailedAction => {
  const commonErrors = convertToCommonErrors(message, status, errors);
  return {
    errors: commonErrors,
    params,
    type: TypeKeys.LOAD_BILLING_ACCOUNT_SUBSCRIPTIONS_FAILED,
  };
};

export interface PrepareBillingAccountEdit {
  type: TypeKeys.PREPARE_BILLING_ACCOUNT_EDIT;
}

export const prepareBillingAccountEdit = (): PrepareBillingAccountEdit => ({
  type: TypeKeys.PREPARE_BILLING_ACCOUNT_EDIT,
});

export type BillingAccountsActionTypes =
  | LoadBillingAccountsAction
  | LoadBillingAccountsFailedAction
  | LoadBillingAccountsFulfilledAction
  | EditBillingAccountAction
  | UpsertBillingAccountAction
  | UpsertBillingAccountFailedAction
  | UpsertBillingAccountFulfilledAction
  | CancelBillingAccountEdit
  | UpsertPersonBillingAccountAction
  | UpsertPersonBillingAccountFulfilledAction
  | UpsertPersonBillingAccountFailedAction
  | LoadBillingAccountSubscriptionsAction
  | LoadBillingAccountSubscriptionsFailedAction
  | LoadBillingAccountSubscriptionsFulfilledAction
  | PrepareBillingAccountEdit
  | UpsertBillingAccountScheduledChangeFulfilled
  | UpsertBillingAccountScheduledChangeFailed;
