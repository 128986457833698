import * as CL from '@design-system/component-library';
import { ModelType } from '../../common/enums.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { mobileBroadbandMsg, mobileM2MMsg, t, voiceSubscriptionsMsg } from '../../common/i18n/index.js';
import type { ReactNode } from 'react';

import './CardSelectionHeader.scss';

const VoiceDescription = () => (
  <>
    <p>
      {t.RDAU(
        `With Elisa's 5G+ and 5G voice subscriptions, everything just works better. Efficient and ultra-fast voice subscriptions for business use ensure smooth work performance. The fast 5G network guarantees a reliable connection and brings speed to the daily routine of your business.`
      )}
    </p>
    <p>
      <span>{t.NLR1('We have packaged services that make everyday life easier into our voice subscriptions as ')}</span>
      <CL.Link linkHref="https://yrityksille.elisa.fi/ohjeet/lisapalvelut" linkTarget="_blank">
        {t.CFD2('additional services')}
      </CL.Link>
      <span>
        {t.K7KB(
          '. Mobile Security, Mobile Voicemail, and Roaming package make your daily life smoother and safer. Choose from our Premium, Basic, or Mini subscriptions that suit you best and add the services that are right for you.'
        )}
      </span>
    </p>
  </>
);

export const getIconDataByOnlineModelType = (type: ModelType) => {
  switch (type) {
    case ModelType.MobileBroadband:
      return {
        icon: 'picto-mobilebb',
        header: t.EQKJ(mobileBroadbandMsg),
        description: t.AZ26(
          `The mobile broadband travels along with you, whether you're working remotely or on the go`
        ),
      };
    case ModelType.VoiceSME:
      return {
        icon: 'picto-sim',
        header: t.ARE0(voiceSubscriptionsMsg),
        description: <VoiceDescription />,
      };
    case ModelType.MobileM2M:
      return {
        icon: 'picto-broadband-m2m',
        header: t.A7CA(mobileM2MMsg),
        description: t.A7CB('Laitenetti is a good choice for your IP cameras, security systems and heating devices.'),
      };
    default:
      return undefined;
  }
};

interface CardSelectionHeaderProps {
  data?: {
    description: ReactNode;
    header: string;
    icon: string;
  };
}

export const CardSelectionHeader = ({ data }: CardSelectionHeaderProps) => {
  if (!data) {
    return null;
  }
  return (
    <div className="card-selection-header">
      <div className="icon">
        <div className={`picto ${data.icon}`}></div>
      </div>
      <div className="text">
        <h1 className={`${dsClass.MARGIN_VERTICAL_0} ${dsClass.PADDING_BOTTOM_2}`}>{data.header}</h1>
        <p className={dsClass.MARGIN_VERTICAL_0}>{data.description}</p>
      </div>
    </div>
  );
};
