import {
  coverageDisclaimerMsgPartOne,
  coverageDisclaimerMsgPartTwo,
  coverageMapMsg,
  t,
} from '../../common/i18n/index.js';

import './CoverageDisclaimer.scss';

export const CoverageDisclaimer = () => {
  return (
    <div>
      <span>{`${t.ZPN3(coverageDisclaimerMsgPartOne)}`}</span>
      <a href="https://elisa.fi/kuuluvuus/" target="_blank" rel="noopener noreferrer">
        {t.IWD6(coverageMapMsg)}
      </a>
      <span>{`${t.OVO4(coverageDisclaimerMsgPartTwo)}`}</span>
    </div>
  );
};
