import * as CL from '@design-system/component-library';
import { MfaAlreadyEnabledNotification, SupportCaseNotification } from './common.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { isMfaAlreadyEnabled, isMfaManagementAllowed } from '../../common/utils/mfaUtils.js';
import { mfaEnableAllCompaniesInSaMsg, mfaEnableOECUsersMsg, t } from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useNavigate } from 'react-router-dom';
import type { MfaDetailsResponse } from '../../generated/api/mfaDetailsResponse.js';

const getActionPart = (mfaDetails: MfaDetailsResponse, onEnableMfa: () => void): JSX.Element => {
  if (isMfaManagementAllowed(mfaDetails)) {
    return (
      <CL.Button color="light" onClick={() => onEnableMfa()}>
        {t.XFA9('Activate')}
      </CL.Button>
    );
  } else if (isMfaAlreadyEnabled(mfaDetails)) {
    return <MfaAlreadyEnabledNotification />;
  } else {
    return <SupportCaseNotification />;
  }
};

export const MfaSetting = ({ mfaDetails }: { mfaDetails: MfaDetailsResponse }) => {
  const navigate = useNavigate();

  return (
    <>
      <p className={dsClass.MARGIN_TOP_3}>{t.QGS0(mfaEnableOECUsersMsg)}</p>

      <p className={dsClass.MARGIN_TOP_3}>{t.A30I(mfaEnableAllCompaniesInSaMsg)}</p>
      <div className={dsClass.MARGIN_TOP_3}>
        {getActionPart(mfaDetails, () => {
          navigate(paths.COMPANY_INFO_MFA_SELF_SERVICE);
        })}
      </div>
    </>
  );
};
