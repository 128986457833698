export const FaqAccordionItemContent = ({
  children,
  heading,
}: React.PropsWithChildren<{ heading: string }>): JSX.Element => (
  <div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
    <meta itemProp="name" content={heading} />
    <div itemProp="acceptedAnswer" itemScope itemType="https://schema.org/Answer">
      <div itemProp="text">{children}</div>
    </div>
  </div>
);
