import { ThankYouContent } from '../../../../components/FixedBroadbandOrder/ThankYouContent.js';
import { applyWizardEvent } from '../../../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

export const FixedBroadbandThankyouPath = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(applyWizardEvent(3, 'FIXED_BROADBAND_ORDER'));
  }, [dispatch]);

  return <ThankYouContent />;
};
