import * as CL from '@design-system/component-library';
import { ContactCreationType } from '../ContactsOrPurposeOfUseUtils.js';
import { ContactDropdown } from '../../../common/formik/Fields/Dropdown/ContactDropdown.js';
import { Email, Name, PhoneNumber } from '../../../common/formik/index.js';
import { GridColumn } from '../../BillingAccountDetails/components/GridColumn.js';
import { Loading } from '../../Loading/index.js';
import { SelectedPurposeOfUseOrContact } from '../../../common/enums.js';
import { dsClass } from '../../../common/constants/dsClasses.js';
import {
  emailMsg,
  firstNameMsg,
  lastNameMsg,
  notMandatoryMsg,
  phoneNumberMsg,
  selectMsg,
  t,
} from '../../../common/i18n/index.js';
import { getActiveAccountMasterId } from '../../../selfservice/common/localStorageUtils';
import { getCompanyName } from '../../../common/utils/accountUtils';
import { useAuth } from '../../../public/site/AuthProvider';
import { useFormikContext } from 'formik';
import type { Contact } from '../../../generated/api/contact.js';
import type { ContactOrPurposeOfUseFormValues } from '../ContactsOrPurposeOfUseUtils.js';

interface CreateOrSelectContactFormProps {
  isEmailAndPhoneRequired?: boolean;
  onChangeContact?: (selectedContactId?: string) => void;
  contacts?: Contact[];
  required?: boolean;
}

const CreateContact = (props: { isEmailAndPhoneRequired?: boolean }) => {
  return (
    <>
      <CL.Grid className="of-contact-or-purpose-of-use__grid">
        <CL.GridRow className="of-contact-or-purpose-of-use__grid_column">
          <GridColumn>
            <Name name="firstName" label={t.AIK7(firstNameMsg)} />
          </GridColumn>
          <GridColumn>
            <Name name="lastName" label={t.Y41S(lastNameMsg)} />
          </GridColumn>
        </CL.GridRow>
        <CL.GridRow className="of-contact-or-purpose-of-use__grid_column">
          <GridColumn>
            <PhoneNumber
              required={props.isEmailAndPhoneRequired ?? false}
              label={`${t.AGIJ(phoneNumberMsg)}${!props.isEmailAndPhoneRequired ? ' ' + t.NFRH(notMandatoryMsg) : ''}`}
            />
          </GridColumn>
          <GridColumn>
            <Email
              required={props.isEmailAndPhoneRequired ?? false}
              label={`${t.ZVQ5(emailMsg)}${!props.isEmailAndPhoneRequired ? ' ' + t.NFRH(notMandatoryMsg) : ''}`}
            />
          </GridColumn>
        </CL.GridRow>
      </CL.Grid>
    </>
  );
};

export const CreateOrSelectContactForm = ({
  isEmailAndPhoneRequired,
  onChangeContact,
  contacts,
  required = true,
}: CreateOrSelectContactFormProps) => {
  const {
    values: { selectionTab, selectContact },
  } = useFormikContext<ContactOrPurposeOfUseFormValues>();
  const { authenticatedUser } = useAuth();
  const companyName = getCompanyName(authenticatedUser, getActiveAccountMasterId() || undefined);

  if (selectionTab !== SelectedPurposeOfUseOrContact.CONTACT) {
    return null;
  }

  if (!contacts) {
    return <Loading />;
  }
  return (
    <>
      <div className="of-contact-or-purpose-of-use__contact_dropdown">
        <ContactDropdown
          contacts={contacts}
          name="selectContact"
          createNewDialog={false}
          labelText={companyName + t.S6U5(`'s users`)}
          labelClass={`${dsClass.COLOR_NEUTRAL_600} ${dsClass.FONT_SIZE_SMALLER} ${dsClass.FONT_STYLE_NORMAL}`}
          onChangeContact={onChangeContact}
          placeHolderText={t.QRYV(selectMsg)}
          canAddNewContacts={true}
          required={required}
        />
      </div>
      {selectContact === ContactCreationType.CREATE_NEW_CONTACT && (
        <>
          <CreateContact isEmailAndPhoneRequired={isEmailAndPhoneRequired} />
          <p className={dsClass.PADDING_TOP_2}>
            {t.W447('Selected company: ')} <b>{companyName}</b>
            {'. '}
            {t.BQO3(
              'Please create a new contact under the company where the person is working. You can choose the right company from the upper right corner of the Corporate OmaElisa main page.'
            )}
          </p>
        </>
      )}
    </>
  );
};
