import * as CL from '@design-system/component-library';
import * as React from 'react';
import {
  ProductGridFilter,
  ProductGridFilterHeader,
  ProductGridFilterStatus,
  hasActiveFilters,
} from './ProductGridFilter.js';
import { ProductGridSearchCL } from './ProductGridSearch.js';
import { ProductGridSort } from './ProductGridSort.js';
import { searchMsg, t } from '../../common/i18n/index.js';
import type { Filters } from './ProductGridFilter.js';
import type { SortType } from './ProductGridSort.js';

interface ProductGridHeaderProps {
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  setSort: React.Dispatch<React.SetStateAction<SortType>>;
  filters: Filters;
  search: string;
  sort: SortType;
}

export const ProductGridHeader = ({
  setSearch,
  search,
  filters,
  setFilters,
  setSort,
  sort,
}: ProductGridHeaderProps) => {
  const [isFilterExpanded, setIsFilterExpanded] = React.useState(false);
  return (
    <>
      <CL.Grid>
        <CL.GridRow className="of-product-grid-header">
          <CL.GridCol colWidthL={3} colWidthM={2} colWidthS={6} colWidthXS={4}>
            <ProductGridSearchCL placeholder={t.VQE6(searchMsg)} onChange={setSearch} value={search} />
          </CL.GridCol>
          {hasActiveFilters(filters) && (
            <>
              <CL.GridCol
                colWidthL={3}
                colWidthM={2}
                colWidthS={6}
                colWidthXS={4}
                className="of-product-grid__filter-header"
              >
                <ProductGridFilterHeader onChange={setIsFilterExpanded} value={isFilterExpanded} />
              </CL.GridCol>
              <CL.GridCol
                colWidthL={3}
                colWidthM={2}
                colWidthS={6}
                colWidthXS={4}
                className="of-product-grid__filter-status"
              >
                <ProductGridFilterStatus filters={filters} setFilters={setFilters} />
              </CL.GridCol>
            </>
          )}
          <CL.GridCol colWidthL={3} colWidthM={2} colWidthS={6} colWidthXS={4} className="of-product-grid__sort">
            <ProductGridSort onChange={setSort} selectedValue={sort} />
          </CL.GridCol>
        </CL.GridRow>
      </CL.Grid>

      {isFilterExpanded && (
        <div className="of-product-grid__filter-content">
          <ProductGridFilter filters={filters} onChange={setFilters} onClose={() => setIsFilterExpanded(false)} />
        </div>
      )}
    </>
  );
};
