import { Field } from 'formik';
import { FieldType } from '../../enums.js';
import { InputComponent } from '../FieldComponents/index.js';
import { referenceIdExampleMsg, referenceIdMsg, referenceToolTipMsg, t } from '../../i18n/index.js';
import type { OptionalInputComponentProps } from '../FieldComponents/index.js';

type Props = OptionalInputComponentProps & {
  alwaysShowLabel?: boolean;
  labelTooltip?: string;
};

export const Reference = (props: Props) => {
  return (
    <Field
      {...{
        name: 'employeeNumber',
        label: t.CWR5(referenceIdMsg),
        placeholder: props.placeholder || ' ',
        disabled: false,
        required: props.required,
        helpText: t.FKO8(referenceIdExampleMsg),
        labelTooltip: props.labelTooltip || t.HBBD(referenceToolTipMsg),
        maxLength: 80,
        type: props.type ? props.type : FieldType.STRING,
        ...props,
      }}
      component={InputComponent}
    />
  );
};
