import * as CL from '@design-system/component-library';
import { Picture } from '../Picture/Picture.js';
import { Ribbon } from '../Ribbon/Ribbon.js';
import { Sticker } from '../Sticker/Sticker.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { loginToOmaElisaMsg, t } from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import { pictureStorageBaseUrl } from '../Picture/contentfulImageProxy.js';

import './YjaOfferBlock.scss';

const suomenYrittajatMemberBenefitMsg = 'Suomen Yrittäjät member benefit';
const SAVING = '15 %';
export const YjaOfferBlock = () => (
  <section aria-label={t.SDUW(suomenYrittajatMemberBenefitMsg)} className="of-yja-offer-block">
    <div className="of-yja-offer-block__section">
      <Picture
        alt="Suomen yrittäjät"
        offerWidthAlternatives={[167]}
        renderedImageSize={{ onPhone: '167px' }}
        src={`${pictureStorageBaseUrl}6t5KcJe0TquST0ywEtGXe4/i.jpg`}
      />
      <Sticker saving={`-${SAVING}`} />
    </div>
    <div className="of-yja-offer-block__section">
      <h2 className={dsClass.MARGIN_VERTICAL_2}>{t.SDUW(suomenYrittajatMemberBenefitMsg)}</h2>
      <p>
        {t.SDUX('Members of Suomen Yrittäjät get a {0} discount on voice and mobile broadband subscriptions.', SAVING)}
        <br />
        <a
          href="https://jasenpalvelu.yrittajat.fi/?_gl=1*l2642l*_ga*NTA2NzI3NjAwLjE2NjE3ODA0ODg.*_ga_Q7EWFZBXCK*MTY3MTE4OTM5Ni43MS4xLjE2NzExODk0MjcuMC4wLjA"
          rel="noreferrer"
          target="_blank"
        >
          {t.SDUY('Get the discount code')}
        </a>
        {` ${t.SDUZ('and redeem the offer in OmaElisa.')}`}
      </p>
      <CL.Link buttonColor="primary" buttonSize="l" linkHref={paths.SELF_SERVICE_HOME} linkStyle="link-button">
        {t.JOM3(loginToOmaElisaMsg)}
      </CL.Link>
    </div>
    <Ribbon side="left" text={[t.SDU0('Discounted price')]} />
  </section>
);
