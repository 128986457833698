import { EmptyOrError } from '../EmptyOrError/index.js';
import { goBackHomeMsg, t } from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useNavigate } from 'react-router-dom';

export const MobileIdDisabled = () => {
  const navigate = useNavigate();
  return (
    <EmptyOrError
      id="mobileid-page-not-found"
      text={t.TLCF('Oops… the page you were looking for was not found.')}
      onButtonClick={() => navigate(paths.SELF_SERVICE_HOME)}
      buttonText={t.G24H(goBackHomeMsg)}
    />
  );
};
