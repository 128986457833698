import { ModelType } from '../../../../../common/enums.js';
import { OrderSubscriptionLayout } from '../../../../../components/OrderSubscription/OrderSubscriptionLayout.js';
import { applyWizardEvent } from '../../../../../selfservice/actions/index.js';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

export const BroadbandSubNewPath = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(applyWizardEvent(1, 'NEW_MOBILE_M2M'));
  }, [dispatch]);

  return <OrderSubscriptionLayout modelType={ModelType.MobileBroadband} />;
};
