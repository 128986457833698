import * as CL from '@design-system/component-library';
import { Controller, useFormContext } from 'react-hook-form';
import { FULL_NAME_MAX_LENGTH } from '../../../common/utils/nameUtils.js';
import { Name } from '../../../common/react-hook-form/fields/Name.js';
import { POSTAL_CODE_MAX_LENGTH } from '../../../common/utils/validationUtils.js';
import { PostalCode } from '../../../common/react-hook-form/fields/PostalCode.js';
import { TextInput } from '../../../common/react-hook-form/fields/TextInput.js';
import { ToggleWithSubComponent } from '../../ToggleWithSubComponent/ToggleWithSubComponent.js';
import {
  payerAdditionalDetailsInfoMsg,
  payerDetailsMsg,
  payerMsg,
  payerNameInfoMsg,
  payerNameOrAddressChangeInfoMsg,
  postOfficeMsg,
  postalAddressInfoMsg,
  postalAddressMsg,
  postalCodeMsg,
  t,
  willPayersNameOrAddressChangeMsg,
} from '../../../common/i18n/index.js';
import type { FormEditProps } from './EOperatorChangeRequestForm.js';

const PAYER_NAME_FIELD_NAME = 'payerName';
const PAYER_ADDITIONAL_INFO_FIELD_NAME = 'payerAdditionalInfo';
const PAYER_POSTAL_ADDRESS_FIELD_NAME = 'payerPostalAddress';
const PAYER_POSTAL_CODE_FIELD_NAME = 'payerPostalCode';
const PAYER_POST_OFFICE_FIELD_NAME = 'payerPostOffice';

const FormFields = ({ isEditing = true }: FormEditProps) => (
  <>
    <h4>{t.SIPZ('Fill in details for the payer')}</h4>
    <CL.Grid>
      <CL.GridRow>
        <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
          <Name
            tooltip={t.SEZ9(payerNameInfoMsg)}
            label={t.PB6S(payerMsg)}
            maxLength={FULL_NAME_MAX_LENGTH}
            name={PAYER_NAME_FIELD_NAME}
            placeholder=""
            readonly={!isEditing}
          />
        </CL.GridCol>
        <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
          <TextInput
            tooltip={t.O83E(payerAdditionalDetailsInfoMsg)}
            label={t.YLAI(payerDetailsMsg)}
            maxLength={100}
            name={PAYER_ADDITIONAL_INFO_FIELD_NAME}
            required={false}
            readonly={!isEditing}
          />
        </CL.GridCol>
      </CL.GridRow>
      <CL.GridRow>
        <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
          <TextInput
            tooltip={t.JJRJ(postalAddressInfoMsg)}
            label={t.NDWK(postalAddressMsg)}
            maxLength={100}
            name={PAYER_POSTAL_ADDRESS_FIELD_NAME}
            readonly={!isEditing}
          />
        </CL.GridCol>
        <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
          <PostalCode
            allowPoBox={true}
            label={t.RUAW(postalCodeMsg)}
            maxLength={POSTAL_CODE_MAX_LENGTH}
            name={PAYER_POSTAL_CODE_FIELD_NAME}
            placeholder=""
            readonly={!isEditing}
          />
        </CL.GridCol>
      </CL.GridRow>
      <CL.GridRow>
        <CL.GridCol colWidthXS={4} colWidthS={3} colWidthL={6}>
          <TextInput
            label={t.Z7S5(postOfficeMsg)}
            maxLength={100}
            name={PAYER_POST_OFFICE_FIELD_NAME}
            readonly={!isEditing}
          />
        </CL.GridCol>
      </CL.GridRow>
    </CL.Grid>
  </>
);

export const PayerNameOrAddressChange = ({ isEditing = true }: FormEditProps) => {
  const { setValue, clearErrors } = useFormContext();
  const handleToggle = (value: boolean) => {
    if (!value) {
      setValue(PAYER_NAME_FIELD_NAME, '');
      setValue(PAYER_ADDITIONAL_INFO_FIELD_NAME, '');
      setValue(PAYER_POSTAL_ADDRESS_FIELD_NAME, '');
      setValue(PAYER_POSTAL_CODE_FIELD_NAME, '');
      setValue(PAYER_POST_OFFICE_FIELD_NAME, '');
      clearErrors([
        PAYER_NAME_FIELD_NAME,
        PAYER_POSTAL_ADDRESS_FIELD_NAME,
        PAYER_POSTAL_CODE_FIELD_NAME,
        PAYER_POST_OFFICE_FIELD_NAME,
      ]);
    }
  };
  return (
    <Controller
      name="payerNameOrAddressChange"
      render={({ field: { onChange } }) => (
        <ToggleWithSubComponent
          name="payerNameOrAddressChange"
          title={t.GMMI(willPayersNameOrAddressChangeMsg)}
          infoText={t.ZHSZ(payerNameOrAddressChangeInfoMsg)}
          onToggle={value => {
            onChange(value);
            handleToggle(value);
          }}
          subComponent={<FormFields isEditing={isEditing} />}
          disabled={!isEditing}
        />
      )}
    />
  );
};
