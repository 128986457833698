import type { SubscriptionsDeviceMultiFilter } from '../../components/SubscriptionLists/SubscriptionTable.js';

export const replacePipeWithCommaInQueryParams = (
  params: SubscriptionsDeviceMultiFilter,
  keysToExclude: string[] = []
): SubscriptionsDeviceMultiFilter => {
  return Object.fromEntries(
    Object.entries(params)
      .filter(([_, value]) => Boolean(value))
      .filter(([key, _value]) => !keysToExclude.includes(key))
      .map(([key, value]) => [key, value.replace(/\|/g, ',')])
  );
};
