export interface CheckedListProps {
  items: string[];
  showIfEmpty: JSX.Element;
}

export const CheckedList = ({ items, showIfEmpty }: CheckedListProps) => (
  <ul className="ea-checked-list">
    {items.length < 1 && showIfEmpty}
    {items.length > 0 && items.map(item => <li key={item}>{item}</li>)}
  </ul>
);
